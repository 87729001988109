<template>
  <swiper :options="swiperOption" class="position-relative">
    <swiper-slide v-for="p in products" :key="p.id">
      <product-item
        v-bind="p"
        :show-actions="false"
        @onRemoveItem="$emit('onRemoveItem', p.id)"
      />
    </swiper-slide>
    <div slot="button-prev" class="swiper-button-prev" />
    <div slot="button-next" class="swiper-button-next" />
  </swiper>
</template>

<script>
import ProductItem from './product-item';

export default {
  components: {
    ProductItem
  },

  props: {
    products: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 10,
        freeMode: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.msg-product-preview {
  /deep/ .card-img {
    height: 120px !important;
  }

  /deep/ .product-title {
    font-size: 10px;
    color: #000 !important;
  }

  /deep/ .product-item {
    &:first-child {
      margin-left: 20px;
    }
    margin-left: 15px;
  }
}

.swiper-container {
  /deep/ .swiper-wrapper {
    .swiper-slide {
      max-width: 160px;
    }
  }
}
</style>
