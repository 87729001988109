<template>
  <div
    data-role="content"
    class="scrollable-content campaign-feed-list"
    style="border: 0.0625rem solid rgb(221, 221, 221);"
  >
    <nav
      v-if="scenarios.length > 0"
      v-b-toggle="toggleName"
      class="navbar navbar-expand-lg navbar-dark bg-info"
    >
      <div class="container p-0">
        <div class="channel-header">
          <span
            v-if="scenarios.length > 0"
            class="badge badge-pill text-white bg-secondary"
            >{{ scenarios.length }}</span
          >
          <span class="navbar-brand">{{ campaignNameShow }}</span>
        </div>
        <i :class="['text-white', collapseIcon]" />
      </div>
    </nav>
    <b-collapse
      :id="toggleName"
      :visible="true"
      accordion="sidebar-campaign-feed"
      @show="collapsed = false"
      @hide="collapsed = true"
    >
      <div v-for="scenario in scenarios" :key="scenario.id" class="scenario-item-list">
        <div class="scenario_item">
          <b-card
            class="drag"
            bg-variant="white"
            :style="{
              width: 'calc(100% - 55px)'
            }"
          >
            <span>{{ scenario.name.substring(0, 300) }}</span>
          </b-card>
          <b-button
            v-b-tooltip.hover
            :title="
              $t('src.modules.chat.components.ConversationalPanel.FAQ.modify_and_send')
            "
            class="custom-btn-css"
            variant="outline-secondary"
            @click="openScenarioTemplateBox(scenario)"
          >
            <i class="fas fa-edit" style="font-size: 0.875rem" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            :title="$t('common.text.send')"
            :disabled="sendingRef[scenario.ref] === true"
            class="custom-btn-css"
            variant="outline-secondary"
            @click="clickButSendScenario(scenario.ref, scenario.name)"
          >
            <i
              v-if="sendingRef[scenario.ref] === true"
              class="fa fa-spinner fa-spin fa-lg"
              style="font-size: 0.875rem;"
            ></i>
            <i v-else class="fas fa-paper-plane" style="font-size: 0.875rem;" />
          </b-button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
export default {
  components: {},

  props: {
    scenarios: {
      type: Array,
      default: () => []
    },
    campaignId: {
      type: String,
      default: ''
    },
    campaignName: {
      type: String,
      default: ''
    },
    sendingRef: {
      type: Object,
      default: () => {}
    },
    usedLang: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      collapsed: false
    };
  },

  computed: {
    ...mapGetters('chat', ['selectedConversation']),
    ...mapState('session', ['channelsMap']),

    collapseIcon() {
      return this.collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up';
    },

    toggleName() {
      return `scenarios-of-${this.campaignId}`;
    },

    campaignNameShow() {
      return this.campaignName;
    }
  },

  created() {},

  methods: {
    clickButSendScenario(ref, scenarioName = 'SCENARIO') {
      const { channelId = '', userId = '' } = this.selectedConversation;
      const { platform } = this.channelsMap[channelId];
      const params = {
        scenarioId: ref,
        recipientId: userId,
        channelId,
        platform,
        scenarioName,
        conversation: JSON.stringify(this.selectedConversation)
      };
      this.$emit('clickButSendScenario', params);
    },
    openScenarioTemplateBox(scenario) {
      EventBus.$emit('scenarioTemplateBox', { ...scenario, usedLang: this.usedLang });
      this.$root.$emit('bv::show::modal', 'ScenarioTemplateBox', '#scenarioTemplateBox');
    }
  }
};
</script>

<style lang="scss" scoped>
.scenario-item-list {
  margin: 20px 30px;
  line-height: 2px !important;
}
nav.navbar {
  cursor: pointer;
}

.channel-header {
  display: inline-block;
  width: 100%;
  .avatar-xs {
    width: 30px;
    height: 30px;
  }

  .avatar {
    .badge {
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 8px;
    }
  }

  .navbar-brand {
    display: inline-block;
    position: absolute;
    left: 60px;
    top: 13px;
  }
}

.h--0 {
  height: 0 !important;
}

.h--100vh {
  height: 100% !important;
}

ul.list-group {
  overflow: hidden;
  transition: all 0.1s ease;
  -moz-transition: all 0.1s ease; /* Firefox 4 */
  -webkit-transition: all 0.1s ease; /* Safari and Chrome */
  -o-transition: all 0.1s ease; /* Opera */

  .cf-item {
    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.waiting-height {
  height: calc(100vh - 235px) !important;
  overflow: scroll;
}

.campaign-feed-list {
  .card {
    .card-body {
      padding: 3px 0px 5px 15px !important;
      line-height: 24px;
    }
  }
}

.scenario_item {
  display: flex;

  .card {
    border-radius: 0;
  }

  .custom-btn-css {
    border: 1px solid rgba(136, 152, 170, 0.5) !important;
    margin-right: 0 !important;
    padding: 1px 6px !important;
    border-radius: 0 !important;
  }
}
</style>
