<template>
  <div v-if="responses.length > 0">
    <div
      v-for="(response, index) in responses"
      :key="`${response.url}_${index}`"
      class="m-b-10"
    >
      <a :href="response.url" target="_blank" rel="noopener noreferrer">
        <div :title="response.url" class="chat-type link-preview">
          <img v-if="response.image" :src="response.image" class="cover-image" />
          <div class="second-section">
            <div v-if="response.title" class="title one-line-text">
              {{ response.title }}
            </div>
            <div class="description-container">
              <div v-if="response.description" class="description two-line-text">
                {{ response.description }}
              </div>
            </div>
            <div class="url-container">
              <img v-if="response.icon" :src="response.icon" class="icon" />
              <div v-if="response.url" class="url one-line-text">{{ response.url }}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import fetchAPI from 'core/fetchAPI';

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      responses: [],
      validUrl: false,
      apiUrl: process.env.VUE_APP_WEBCHAT_API_URL
    };
  },
  watch: {
    url: function() {
      this.responses = [];
      this.createLinkPreview();
    }
  },
  created() {
    this.createLinkPreview();
  },

  methods: {
    getURLsFromText(text) {
      // eslint-disable-next-line
      const words = text.split(/(.*?\ )/g).map(w => w.trim());
      const urls = words
        .filter(w => {
          w = w.trim().toLowerCase();
          return (w.startsWith('http') || w.startsWith('https')) && this.isValidUrl(w);
        })
        .slice(0, 2); // Skype limit 2 link preview per message

      return {
        urls: urls.map(url => {
          return 'https' + url.slice(5);
        }),
        isURLOnly: words.length === urls.length
      };
    },

    async createLinkPreview() {
      const { urls } = await this.getURLsFromText(this.url);
      if (urls.length > 0) {
        const promises = urls.map(url => this.getLinkPreview(url));
        const results = await Promise.all(promises.map(p => p.catch(e => e)));
        const validResults = results.filter(
          result => result && !(result instanceof Error)
        );

        if (validResults.length > 0) {
          this.responses = validResults;
        }
      }
    },

    isValidUrl: function(url) {
      // eslint-disable-next-line
      const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
      return regex.test(url);
    },

    getLinkPreview: async function(url) {
      if (this.isValidUrl(url)) {
        const resLinkReview = await this.request(url);
        return resLinkReview;
      }
    },

    async request(url) {
      const fetch = new fetchAPI({
        baseURL: this.apiUrl,
        headers: {
          'x-api-key': process.env.VUE_APP_WEBCHAT_API_KEY
        }
      });
      return fetch
        .get('/og', {
          url: url
        })
        .then(res => {
          return res.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.link-pre {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0 20px 10px 0;
  clear: both;
  border-radius: 0.286rem;
}
.linkprevue-right {
  float: right;
}
.linkprevue-left {
  float: left;
}

a {
  color: #000000;
}
.link-preview {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 250px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eeeeee;

  .second-section {
    padding: 8px;
    background-color: #eeeeee;
  }

  .cover-image {
    height: 110px;
    width: 100%;
    background-size: 100% 110px;
    background-color: #e0e0e0;
    background-repeat: no-repeat;
    background-position: top left;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .one-line-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .two-line-text {
    overflow: hidden;
    position: relative;
    line-height: 20px;
    max-height: 40px;
  }

  .description {
    font-size: 14px;
  }

  .url-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: auto;
      height: 16px;
      margin-right: 5px;
    }

    .url {
      font-size: 14px;
    }
  }
}
</style>
