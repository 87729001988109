<template>
  <div class="chat-type chat-type-list">
    <div class="card-wrapper">
      <div class="item-card">
        <!-- Header -->
        <div class="item-card-content cover" v-if="hasHeader">
          <!--  -->
          <div class="info">
            <div
              v-if="headerElements.title"
              :class="{ 'text-dark': !headerElements.title }"
              class="text-left font-weight-bold title-cut-text"
            >
              {{ headerElements.title }}
            </div>
            <div v-if="headerElements.desc" class="subtitle text-left subtitle-cut-text">
              {{ headerElements.desc }}
            </div>
          </div>
          <!--  -->
          <div v-if="headerElements.cover_img" class="img-wrapper">
            <div class="mask" />
            <div
              :style="{ background: `url(${headerElements.cover_img})` }"
              class="img"
            />
          </div>
        </div>

        <!-- render card -->
        <div
          v-for="(elm, index) in elements"
          :key="`elm_${index}`"
          :class="{ cover: isCover(elm, index) }"
          class="item-card-content"
        >
          <!--  -->
          <div class="info">
            <div
              v-if="elm.title"
              :class="{ 'text-dark': !isCover(elm, index) }"
              class="text-left font-weight-bold title-cut-text"
            >
              {{ elm.title }}
            </div>
            <div v-if="elm.subtitle" class="subtitle text-left subtitle-cut-text">
              {{ elm.subtitle }}
            </div>
            <div class="url text-left url-cut-text">
              <b-button
                v-if="elm.url"
                v-b-tooltip.hover="extractHostname(elm.url)"
                variant="outline-primary"
                size="sm"
                :href="elm.url"
                target="_blank"
                class="item-card-action card-item-button"
                >{{ truncate(extractHostname(elm.url), 20) }}</b-button
              >
            </div>
            <template v-for="(button, i) in elm.buttons">
              <b-button
                v-if="button.type == 'postback'"
                v-b-tooltip.hover="button.title"
                :key="i"
                variant="outline-primary"
                size="sm"
                disabled
                class="item-card-action card-item-button"
                >{{ button.title }}</b-button
              >
              <b-button
                v-if="button.type == 'web_url'"
                v-b-tooltip.hover="button.title"
                :key="i"
                :href="!isForm(button.url) ? button.url : ''"
                :disabled="isForm(button.url)"
                target="_blank"
                variant="outline-primary"
                size="sm"
                class="item-card-action card-item-button"
                >{{ button.title }}</b-button
              >
            </template>
          </div>
          <!--  -->
          <div v-if="elm.image_url" class="img-wrapper">
            <div class="mask" />
            <div :style="{ background: `url(${elm.image_url})` }" class="img" />
          </div>
        </div>
        <template v-for="(button, i) in this.buttons">
          <div :key="i" class="btn-footer-div">
            <b-button
              v-if="button.type == 'postback'"
              :key="i"
              variant="outline-primary"
              size="sm"
              disabled
              class="item-card-buttom-bottom text-center btn-footer-list"
              >{{ button.title }}</b-button
            >
            <b-button
              v-if="button.type == 'web_url'"
              :key="i"
              :href="!isForm(button.url) ? button.url : ''"
              :disabled="isForm(button.url)"
              target="_blank"
              variant="outline-primary"
              size="sm"
              class="item-card-action card-item-button btn-footer-list"
              >{{ button.title }}</b-button
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },

    channelPlatform: {
      type: String,
      default: () => 'facebook'
    },

    domainWebview: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      elements: [],
      buttons: [],
      topElementStyle: 'large', // compact || large
      hasButtons: false,
      headerElements: {}
    };
  },

  computed: {
    hasHeader() {
      return !isEmpty(this.headerElements);
    }
  },

  created() {
    this.parseInputData();
  },

  methods: {
    parseInputData() {
      if (!this.message) {
        this.elements = [];
        this.buttons = [];
      } else {
        let {
          elements,
          buttons,
          top_element_style = 'large',
          title = '',
          desc = '',
          cover_img = ''
        } = this.message;
        if (title !== '' || cover_img !== '' || desc !== '') {
          this.headerElements.title = title;
          this.headerElements.desc = desc;
          this.headerElements.cover_img = cover_img;
        }

        this.topElementStyle = top_element_style;
        if (elements && Array.isArray(elements)) {
          // Limit 10 items maximum
          this.elements = elements.slice(0, 10);
        }
        if (buttons && Array.isArray(buttons)) {
          this.buttons = buttons;
          this.hasButtons = true;
        }
      }
    },

    redirect2Url(url) {
      window.open(url);
    },

    isCover(item, index) {
      switch (this.channelPlatform) {
        case 'facebook':
          return index === 0;
        case 'line':
          return item.cover_image;
        case 'webchat':
          if (this.topElementStyle === 'large') {
            if (this.hasHeader) return false;
            else return index === 0;
          }
          return false;
        default:
          return index === 0;
      }
    },
    extractHostname(url) {
      if (!url) return '';

      let hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname
      if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }

      //find & remove port number
      hostname = hostname.split(':')[0];
      //find & remove "?"
      hostname = hostname.split('?')[0];

      return hostname;
    },

    truncate(chars, length) {
      if (chars && chars.length > length) {
        return (chars = chars.substring(0, length) + '...');
      }
      return chars;
    },

    isForm(url) {
      return this.domainWebview.includes((new URL(url)).hostname);
    }
  }
};
</script>

<style scoped>
.title-cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  width: 100%;
  word-break: break-all;
}

.subtitle-cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
}

.url-cut-text {
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
}

.chat-type-list {
  float: none !important;
}
.chat-type-list .card-wrapper {
  position: relative;
}
.chat-type-list .item-card {
  width: 360px;
  border: 1px solid #dddddd;
}
.chat-type-list .subtitle {
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
}
.chat-type-list .url {
  font-size: 11px;
  color: rgba(0, 0, 0, 1);
  line-height: 15px;
  cursor: pointer;
}
.chat-type-list .item-card-content {
  padding: 8px;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.chat-type-list .item-card-content .info {
  flex: 1;
}
.chat-type-list .item-card-content .img-wrapper {
  position: relative;
  height: 60px;
  width: 60px;
  /* margin-left: 20px; */
}
.chat-type-list .item-card-content .img-wrapper .mask {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: none;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
}
.chat-type-list .item-card-content .img-wrapper .img {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.chat-type-list .item-card-content.cover {
  height: 150px;
  padding: 0;
  position: relative;
}
.chat-type-list .item-card-content.cover .url,
.chat-type-list .item-card-content.cover .subtitle {
  color: #ffffff;
}
.chat-type-list .item-card-content.cover .info {
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 10px;
  color: #ffffff;
}
.chat-type-list .item-card-content.cover .mask {
  display: block;
}
.chat-type-list .item-card-content.cover .img-wrapper {
  width: 100%;
  height: 100%;
  margin-left: 0;
}
.chat-type-list .item-card-buttom-bottom {
  color: #fb6340;
}
.chat-type-list .item-card-buttom-bottom .button {
  font-size: 15px;
  background-color: #fff;
  padding: 4px 7px;
  padding-left: 10px;
  cursor: pointer;
}
.chat-type-list .item-card-buttom-bottom .button:hover {
  color: #ef8412;
}
.chat-type-list .item-card-action .button {
  padding: 1px 7px;
  color: #fb6340;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
}
.chat-type-list .item-card-action .button:hover {
  color: #ef8412;
}
.chat-type-list .item-card-action.card-item-button {
  width: 170px;
  border: 1px solid #fb6340;
  margin-top: 4px;
  border-radius: 5px;
  color: #fb6340;
}
.chat-type-list .item-card-action.card-item-button:hover {
  border: 1px solid #ef8412;
  color: #fff;
  background-color: #fb6340;
}
.card-item-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-footer-list {
  width: 100% !important;
}
.btn-footer-div {
  background-color: #fff !important;
}
</style>
