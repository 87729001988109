import Service from 'core/service';

import { ScenarioService } from './service';
import { GET_CAMPAIGNS, SEARCH_SCENARIO, SET_CAMPAIGNS } from './types';
import pick from 'lodash/pick';

const scnService = new ScenarioService();
const coreService = new Service();

export const getCampainData = async ({ commit }, params) => {
  const { campaignId, campaignName, platform, language } = params;
  const resData = [];
  const campaign = {
    id: campaignId,
    name: campaignName,
    scenarios: []
  };
  const scenarioData = await scnService.getScenarios(campaignId, platform, language);

  if (scenarioData.length > 0) {
    campaign.scenarios = scenarioData;
    resData.push(campaign);
  }
  commit(GET_CAMPAIGNS, resData);
};

export const getCampaignBot = async (_, params) => {
  const { campaignId, platform, language } = params;
  return scnService.getBotScenarios(campaignId, platform, language);
};

export const setEmptyCampaign = async ({ commit }) => {
  commit(SET_CAMPAIGNS, []);
};

export const getCampainList = async (_, params) => {
  let { projectId, groupId, type } = params;
  if (!groupId) groupId = '';
  return await scnService.getCampaigns(projectId, groupId, type);
};
export const getScenarioContent = async (_, params) => {
  let { campaignId, platform, language } = params;
  return coreService.getScenarioContent(campaignId, platform, language);
};
export const getParserScenario = async (_, params) => {
  const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;
  const userService = new Service(URL_USER);
  let { campaignId, platform, language } = params;
  return userService
    .get(`scenarios/parserScenario/${campaignId}/${platform}/${language}`)
    .catch(() => 'error');
};
export const getSurveyDataByTicket = async (_, params) => {
  const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;
  const userService = new Service(URL_USER);
  let { ticketId, language, includeNoAnswer = false } = params;
  return userService
    .get(
      `survey/getSurveyDataByTicket?ticketId=${ticketId}&language=${language}&includeNoAnswer=${includeNoAnswer}`
    )
    .then(surveyData => {
      const surveyDataObj = Array.isArray(surveyData)
        ? surveyData.reduce((acc, i) => {
            const { id: templateId, questions = [] } = i;
            questions.map(q => {
              let { id, question = '', answer = '', type, attributes } = q;
              if (['radio', 'dropdown'].includes(type)) {
                const { options = [] } = attributes || {};
                for (let i = 0; i < options.length; i++) {
                  const { value, label } = options[i];
                  if (answer !== value) continue;
                  answer = label;
                }
              } else if (type === 'checkbox') {
                let nAnswer = [];
                const { options = [] } = attributes || {};
                for (let i = 0; i < answer.length; i++) {
                  for (let j = 0; j < options.length; j++) {
                    const ans = answer[i];
                    const { value, label } = options[j];
                    if (ans !== value) continue;
                    nAnswer.push(label);
                  }
                }
                answer = nAnswer.join(', ');
              }
              answer = typeof answer === 'boolean' ? answer.toString() : answer || '';
              acc[`{sf.${templateId}.${id}.question}`] = question;
              acc[`{sf.${templateId}.${id}.answer}`] = answer;
            });
            return acc;
          }, {})
        : {};
      return { surveyData, surveyDataObj };
    })
    .catch(() => {});
};

export const searchScenarioName = async ({ commit }, scenarioName) => {
  commit(SEARCH_SCENARIO, scenarioName);
};

export const sendScenario = async (_, params) => {
  const nParams = buildDataSendScenario(params);
  return coreService.post('messages/sendScenarioFromAT', nParams).catch(() => 'error');
};

const buildDataSendScenario = params => {
  const { conversation } = params;
  try {
    const parseData = JSON.parse(conversation);
    const pConv = pick(parseData, [
      'pk',
      'sk',
      'id',
      'platform',
      'ticketId',
      'channelId',
      'projectId',
      'userId',
      'agentId',
      'name',
      'createdAt',
      'ticket'
    ]);
    const pTicket = pick(pConv.ticket || {}, [
      'id',
      'conversationId',
      'channelId',
      'userId',
      'groupId',
      'projectId',
      'agentId',
      'assignee',
      'state',
      'status',
      'tags',
      'pk',
      'sk',
      'platform'
    ]);
    return { ...params, conversation: JSON.stringify({ ...pConv, ticket: pTicket }) };
  } catch (error) {
    console.log('buildDataSendScenario ~ error', { params }, error);
    return params;
  }
};
