import {
  SET_REQUEST_LIST,
  DELETE_REQUEST,
  UPDATE_REQUEST_LIST,
  MESSAGES_USER_TICKET
} from './types';
import * as actions from './actions';

const state = {
  requests: [],
  messagesUserTicket: []
};

const mutations = {
  [SET_REQUEST_LIST](state, list = []) {
    state.requests = [...list];
  },

  [MESSAGES_USER_TICKET](state, list) {
    state.messagesUserTicket = [...list];
  },

  [UPDATE_REQUEST_LIST](state, request) {
    const { id = '', createdAt = '' } = request;
    const { requests: curRequests = [] } = state;
    const newRequests = curRequests.map(i => {
      if (i.id === id && i.createdAt === createdAt) {
        return request;
      }
      return i;
    });
    state.requests = [...newRequests];
  },

  [DELETE_REQUEST](state, request) {
    const { id = '', createdAt = '' } = request;
    const { requests: curRequests = [] } = state;
    const newRequests = curRequests.filter(i => i.id !== id && i.createdAt !== createdAt);
    state.requests = [...newRequests];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
