import Service from 'core/service';

const service = new Service();
const maintainService = new Service(process.env.VUE_APP_MAINTAIN_SERVICE_ENDPOINT);

export const createLabel = async (_, val) => {
  return maintainService.post('labels/createNewLabel', val);
};
export const updateLabel = async (_, val) => {
  return maintainService.post('labels/updateLabel', val);
};

export const updateUsersLabelValue = async (_, { projectId, label }) => {
  const rtl = await service.rest('labels/updateUsersLabelValue', {
    projectId,
    label
  });
  let { scrollId } = { ...rtl };
  while (scrollId) {
    const rtlScrollId = await service.rest('labels/updateUsersLabelValue', {
      projectId,
      label,
      oldScrollId: scrollId
    });
    scrollId = rtlScrollId.scrollId || null;
  }
  return true;
};

export const deleteUserLabel = (_, { id, pk }) => {
  if (!id || !pk) return true;
  return maintainService.rest('labels/deleteLabel', { id, pk }, { method: 'POST' });
};
