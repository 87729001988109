<template>
  <b-modal
    id="InstantSettingPage"
    ref="instantSettingPage"
    v-model="show"
    size="lg"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    :title="$t('src.components.AppLeftMenu.index.settings')"
    @hidden="resetAllState"
  >
    <div v-loading="isLoading" class="instantSettingPage-body">
      <!-- Status settings -->
      <div class="card">
        <div class="card-header text-left">
          {{ $t('src.modules.profile.components.setting.status_settings') }}
        </div>
        <div class="card-body">
          <el-row>
            <el-col
              v-for="(item, index) in Object.keys(agentStatusOptions)"
              :id="'agent-status-' + index"
              :key="'agent-status-' + index"
              :span="6"
              class="mvt-el-col-6"
            >
              <el-radio
                v-model="agentStatus"
                :label="item"
                :class="item.replace(/ /g, '-').toLowerCase()"
                border
                >{{ agentStatusOptions[item] }}</el-radio
              >
              <BootstrapTooltipCustom
                :id="'agent-status-' + index"
                :content="agentStatusOptions[item]"
              />
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- Notification settings -->
      <div class="card">
        <div class="card-header text-left">
          {{ $t('src.modules.profile.components.setting.notification_settings') }}
        </div>
        <div class="card-body">
          <el-row>
            <el-col :span="12" class="custom-color">____</el-col>
            <el-col :span="6" class="mb-1 text-left">
              <div class="sub-title">
                <b>{{ $t('src.modules.profile.components.setting.notification') }}</b>
              </div>
            </el-col>
            <el-col :span="6" class="mb-1 text-left">
              <div class="sub-title">
                <b>{{ $t('src.modules.profile.components.setting.sound') }}</b>
              </div>
            </el-col>
          </el-row>
          <el-row v-for="(item, index) in notificationSettings" :key="index">
            <el-col :span="12">
              <div class="form-group">
                <div class="sub-title">
                  {{ notificationSettingsTitle[item.KEY] }}
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <el-switch v-model="item.NOTIFY_ENABLE" @change="setSoundSwitch(item)" />
            </el-col>
            <el-col :span="6">
              <el-switch
                v-model="item.SOUND_ENABLE"
                :disabled="item.NOTIFY_ENABLE ? false : true"
              ></el-switch>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- Action settings -->
      <div class="card">
        <div class="card-header text-left">
          {{ $t('src.modules.profile.components.setting.action_settings') }}
        </div>
        <div class="card-body">
          <el-row v-for="(item, index) in actionSettings" :key="index">
            <el-col :span="12">
              <div class="form-group">
                <div class="sub-title">
                  {{ actionSettingsTitle[item.KEY] }}
                  <span :id="`hint-text-send-with-a-confirmation-${index}`">
                    <i
                      v-b-tooltip.hover
                      :title="actionSettingsTooltip[item.TOOLTIP]"
                      class="far fa-question-circle"
                    ></i>
                  </span>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <el-switch v-model="item.CONFIRM_ENABLE"></el-switch>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-btn
        class="float-right"
        variant="primary"
        style="margin-left: 7px"
        data-testid="save"
        @click="saveBtn"
        :disabled="disableAllBtn"
      >
        {{ $t('src.modules.chat.components.ManageLabels.index.save') }}
      </b-btn>
      <b-btn
        class="float-right"
        variant="default"
        data-testid="logout"
        @click="logoutAction"
        :disabled="disableAllBtn"
      >
        {{ $t('src.components.AppLeftMenu.index.logout') }}</b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  AGENT_STATUS,
  ACTION_SETTINGS_TITLE,
  ACTION_SETTINGS_TOOLTIP,
  ACTION_SETTINGS_DEFAULT,
  DEFAULT_AGENT_STATUS_LABEL,
  NOTIFICATION_SETTINGS_TITLE,
  NOTIFICATION_SETTINGS_DEFAULT
} from 'core/constants';
import { requestNotificationPermission, isDisplayUI } from 'core/helpers';

import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';

function defaultState() {
  return {
    show: false,
    isLoading: false,
    agentStatus: 'ONLINE',
    disableAllBtn: false,
    sendWithAConfirmation: false,
    actionSettings: ACTION_SETTINGS_DEFAULT,
    actionSettingsTitle: ACTION_SETTINGS_TITLE,
    actionSettingsTooltip: ACTION_SETTINGS_TOOLTIP,
    notificationSettings: NOTIFICATION_SETTINGS_DEFAULT,
    notificationSettingsTitle: NOTIFICATION_SETTINGS_TITLE,
    agentStatusOptions: DEFAULT_AGENT_STATUS_LABEL,
    displayLanguage: 'en_US'
  };
}

export default {
  components: {
    BootstrapTooltipCustom
  },

  data: defaultState,

  computed: {
    ...mapState('session', ['user', 'channelsMap']),
    ...mapGetters('session', ['configs']),

    displayInitialSettingsDialog() {
      return isDisplayUI(this.configs, 'initialSettingsDialog');
    }
  },

  watch: {
    user(val) {
      if (val) {
        this.updateState(val);
      }
    },

    channelsMap(newValue) {
      if (!isEmpty(newValue) && this.show) {
        this.clearProjectsChannels();
      }
    }
  },

  created() {
    if (this.user) {
      this.updateSettingsWithRole();
    }
  },

  destroyed() {
    this.resetAllState();
  },

  mounted() {
    const lang = localStorage.getItem('language') || 'en_US';
    this.displayLanguage = lang.replace('-', '_');
    if (this.user) {
      this.updateState(this.user);
    }
  },

  methods: {
    ...mapActions('profile', ['submitForm']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('session', ['getChannels', 'getProjectMaps']),
    ...mapActions('session', [
      'logout',
      'setUser',
      'getSessionUser',
      'clearProjectsChannels',
      'updateAgentStatus'
    ]),

    async load() {
      const { email, id } = this.user;
      if (!this.displayInitialSettingsDialog) {
        await this.updateAgentStatus({ agentId: id, status: AGENT_STATUS.ONLINE });
        localStorage.setItem('instant-settings', 'hasSetup');
        return location.reload();
      }
      if (email === 'manage_jhmaoxi_user@tfbnw.net') {
        await this.updateAgentStatus({ agentId: id, status: AGENT_STATUS.ONLINE });
        localStorage.setItem('instant-settings', 'hasSetup');
        history.pushState(null, null, '/com/17841405860502099');
        return location.reload();
      }

      this.show = true;
      this.isLoading = true;

      const tid = localStorage.getItem('user-tid');
      const token = localStorage.getItem('user-token');
      const authStatus = localStorage.getItem('auth-status');
      if ((token && authStatus === 'ERROR') || !tid) return this.resetAllState();
      this.disableAllBtn = true;
      await new Promise(resolve =>
        setTimeout(() => {
          this.isLoading = false;
          this.disableAllBtn = false;
          resolve(true);
        }, 3000)
      );
    },

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    async saveBtn() {
      this.isLoading = true;
      this.disableAllBtn = true;
      await this.notificationPermission();
      await this.saveSettings();
    },

    async saveSettings() {
      const formUserUpdate = {
        id: this.user.id,
        status: this.agentStatus,
        pictureUrl: this.user.pictureUrl,
        notificationSettings: this.notificationSettings,
        actionSettings: this.actionSettings
      };

      await this.submitForm(formUserUpdate).then(async response => {
        if (response) {
          localStorage.setItem('instant-settings', 'hasSetup');
          location.replace('/');
          location.reload();
        } else {
          this.isLoading = false;
          this.disableAllBtn = false;
          this.setGlobalReady(false);
        }
      });
    },

    logoutAction() {
      this.$baseConfirm({
        message: this.$t('src.components.AppLeftMenu.index.are_you_sure_you_want_to_logout'),
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false
      })
        .then(() => {
          this.show = false;
          this.logout(this.user).then(() => this.$router.replace({ path: '/login' }));
        })
        .catch(() => {});
    },

    setSoundSwitch(item) {
      if (!item.NOTIFY_ENABLE) item.SOUND_ENABLE = false;
    },

    updateState(userData) {
      const { actionSettings = ACTION_SETTINGS_DEFAULT } = userData;
      const { notificationSettings = NOTIFICATION_SETTINGS_DEFAULT } = userData;
      this.actionSettings = cloneDeep(actionSettings);
      this.notificationSettings = cloneDeep(notificationSettings);
      const status = userData.statusLabel
        ? userData.statusLabel[this.displayLanguage] || DEFAULT_AGENT_STATUS_LABEL
        : DEFAULT_AGENT_STATUS_LABEL;
      this.agentStatusOptions = pick({ ...DEFAULT_AGENT_STATUS_LABEL, ...(status || {}) }, [
        'ONLINE',
        'BUSY',
        'AWAY',
        'LUNCH',
        'BREAK'
      ]);
      this.updateSettingsWithRole();
    },

    updateSettingsWithRole() {
      const { role = '' } = this.user;
      const settings = NOTIFICATION_SETTINGS_DEFAULT;
      const nt = this.notificationSettings;
      const oldSettings = nt.reduce((acc, i) => ({ ...acc, [i.KEY]: i }), {});
      const newSeetings = settings
        .map(i => {
          if (['Regular'].includes(role) && i.KEY === 'WARNING_WORD') {
            return false;
          } else if (oldSettings[i.KEY]) {
            return { ...i, ...oldSettings[i.KEY] };
          } else return { ...i };
        })
        .filter(i => i);
      this.notificationSettings = newSeetings;

      const actSettings = ACTION_SETTINGS_DEFAULT;
      const ac = this.actionSettings;
      const oldActSettings = ac.reduce((acc, i) => ({ ...acc, [i.KEY]: i }), {});
      const newActSettings = actSettings
        .map(i => {
          if (oldActSettings[i.KEY]) {
            return { ...i, ...oldActSettings[i.KEY] };
          } else return { ...i };
        })
        .filter(i => i);
      this.actionSettings = newActSettings;
    },

    async notificationPermission() {
      let isRunning = true;
      setTimeout(() => (isRunning ? this.saveSettings() : null), 5000);

      const permission = await requestNotificationPermission();
      isRunning = false;
      return permission;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-radio__label {
  font-weight: 400 !important;
  color: #606266;
  font-size: 12px;
}

/deep/.el-switch.is-checked {
  .el-switch__core {
    border-color: #fb6340 !important;
    background: #fb6340 !important;
  }
}

.online.is-checked {
  border-color: green !important;

  /deep/.el-radio__inner {
    border-color: green !important;
    background: green !important;
  }

  /deep/.el-radio__label {
    color: green !important;
  }
}

.busy.is-checked {
  border-color: red !important;

  /deep/.el-radio__inner {
    border-color: red !important;
    background: red !important;
  }

  /deep/.el-radio__label {
    color: red !important;
  }
}

.lunch.is-checked {
  border-color: orange !important;

  /deep/.el-radio__inner {
    border-color: orange !important;
    background: orange !important;
  }

  /deep/.el-radio__label {
    color: orange !important;
  }
}

.break.is-checked {
  border-color: blue !important;

  /deep/.el-radio__inner {
    border-color: blue !important;
    background: blue !important;
  }

  /deep/.el-radio__label {
    color: blue !important;
  }
}

.custom-color {
  color: white;
}

.card {
  margin-bottom: 20px;
}

.sub-title {
  margin-bottom: 4px;
  text-align: left;
  color: #606266;
  font-size: 0.875rem;
  font-weight: 100;
}

.mvt-el-col-6 {
  label {
    width: 90%;

    /deep/.el-radio__label {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<style lang="scss">
#InstantSettingPage {
  background: white;
}
</style>
