<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group search-box shadow">
        <div class="input-group mb-4">
          <div class="input-group-prepend nat-search-input-icon">
            <span class="input-group-text">
              <i class="fa fa-search text-primary" />
            </span>
          </div>
          <drop class="drop position-relative nat-search-input" @drop="handleDrop">
            <b-input-group>
              <b-form-input
                v-model="conversationName"
                :style="{ 'margin-right': '2px' }"
                :placeholder="
                  $t('src.modules.chat.components.ConversationalPanel.search-box.search')
                "
                class="form-control placeholder-input-search"
                type="text"
                @input="handleSearchConversation"
                @keypress.enter="handleSearchConversation"
              ></b-form-input>
              <b-dropdown
                slot="append"
                :text="langText"
                :size="dropdownSize"
                class="b-dropdown-convsersation-panel"
                variant="default"
              >
                <b-dropdown-item
                  v-for="lang in listFaqLanguage"
                  :key="lang.key"
                  :value="lang.key"
                  @click="filterDataBylanguage(lang.key)"
                  :active="lang.key.toLowerCase() === selectedLang.toLowerCase()"
                  >{{ lang.label }}</b-dropdown-item
                >
              </b-dropdown>
            </b-input-group>
          </drop>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    listFaqLanguage: {
      type: Array,
      default: () => []
    },
    pinned: {
      type: Boolean,
      default: false
    },
    usedLanguage: {
      type: String,
      default: 'en_US'
    }
  },

  data() {
    return {
      conversationName: '',
      selectedLang: ''
    };
  },

  computed: {
    dropdownSize() {
      return this.pinned ? 'sm' : '';
    },

    langText() {
      const lang = this.listFaqLanguage.find(
        lang => lang.key.toLowerCase() === this.selectedLang.toLowerCase()
      );
      if (lang) return lang.label;
      return '';
    }
  },

  async created() {
    this.handleSearchConversation();
    this.selectedLang = this.usedLanguage;
  },

  methods: {
    ...mapActions('chat', ['setConvSearchText', 'setSearchFaqWithLanguage']),

    handleSearchConversation() {
      this.setConvSearchText(this.conversationName);
    },

    handleDrop(data) {
      const { answer } = data;
      if (answer) {
        this.conversationName = answer;
        this.handleSearchConversation();
      }
    },

    filterDataBylanguage(language) {
      this.selectedLang = language;
      this.setSearchFaqWithLanguage(language);
      this.$emit('filterDataBylanguage', language);
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-search-input {
  width: calc(100% - 46px);
}

.nat-search-input-icon {
  width: 46px;
}

.placeholder-input-search  {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.input-group-append {
  .dropdown {
    display: inline-flex;
  }
}
</style>
