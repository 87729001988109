<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'vertical'">
      <div class="nat-checkbox-verti">
        <el-checkbox
          :id="`${name}-${prefix}`"
          :name="`${name}-${prefix}`"
          v-model="content"
          :disabled="disabled"
          @change="handleChange"
          @input="handleInput"
        >
          <span v-if="required" class="nat-required">*</span>
          {{ label }}
          <base-tooltip-icon
            :id="`tooltip-${name}-${prefix}`"
            :name="`tooltip-${name}-${prefix}`"
            :content="tooltipText"
            :icon="tooltipIcon"
          />
        </el-checkbox>
      </div>
      <div class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate';
export default {
  name: 'base-checkbox',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    optionsAlign: {
      type: String,
      default: 'horizontal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Boolean, Number],
      default: null
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    ignoreValidate: {
      type: Boolean,
      default: false
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  computed: {
    c_rules() {
      let allRule = {};
      if (this.ignoreValidate) return allRule;
      if (this.required) allRule.baseCheckRequired = { label: this.label };
      return allRule;
    },

    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    }
  },

  watch: {
    value(value) {
      this.content = value;
    }
  },

  methods: {
    handleChange(selected) {
      this.$emit('change', selected);
    },
    handleInput(selected) {
      this.content = selected;
      this.$emit('input', this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-checkbox-hori {
  /deep/.el-checkbox__label {
    font-size: 0.875rem;
  }
}
.nat-checkbox-verti {
  /deep/.el-checkbox__label {
    font-size: 0.875rem;
  }
}

.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
</style>
