import Vue from 'vue';
import Vuex from 'vuex';

import global from 'modules/global/store';
import session from 'modules/session/store';
import chat from 'modules/chat/store';
// Add new store for comment module
import comment from 'modules/chat/components/CommentBox/store';
import phoneAgentChatBox from 'modules/chat/components/PhoneAgentChatBox/store';
import profile from 'modules/profile/store';
import password from 'modules/password/store';
import personal from 'modules/personal/store';
import customerSupportHistory from 'modules/customer-support-history/store';
import searchManagement from 'modules/search-management/store';
import report from 'modules/report/store';
import customerSupport from 'modules/chat/components/CustomerSupport/store';
import actionLogs from 'modules/chat/components/StateOverview/store';
import manageLabels from 'modules/chat/components/ManageLabels/store';
import manageUserLabels from 'modules/chat/components/ManageUserLabels/store';
import conversationMng from 'modules/chat/components/ConversationalPanel/store';
import file from 'modules/file-management/store';
import replacingMessage from 'modules/replacing-message/store';
import accessLog from 'modules/access-log/store';
Vue.use(Vuex);

// Enable dev tools
if (process.env.VUE_APP_DEVTOOLS_ENABLED) {
  Vue.config.devtools = true;
}

export default new Vuex.Store({
  modules: {
    global,
    session,
    chat,
    comment,
    profile,
    password,
    personal,
    customerSupportHistory,
    customerSupport,
    actionLogs,
    report,
    manageLabels,
    manageUserLabels,
    searchManagement,
    conversationMng,
    file,
    replacingMessage,
    accessLog,
    phoneAgentChatBox
  },
  strict: process.env.NODE_ENV !== 'production'
});
