import { escapeHtmlChar } from 'core/helpers';

const warningId = makeId();
const startSpanTagId = `spanTag${makeId()}spanTag`;
const endSpanTagId = `spanTag${makeId()}spanTag`;
const startSpanTag = '<span class="badge-pill badge-warning" style="padding: 0;">';
const endSpanTag = '</span>';

function makeId() {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 36; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const indexesWithCaseInsensitiveMatch = (message, keyword) => {
  keyword = addBackslash(keyword);
  const regex = RegExp('(?:' + keyword + ')', 'ig');
  let value;
  let indexes = [];

  while ((value = regex.exec(message)) !== null) {
    indexes.push(value['index']);
  }

  return indexes;
};

const addBackslash = keyword => {
  return keyword
    .replace(/\\/g, '\\\\')
    .replace(/\?/g, '\\?')
    .replace(/\$/g, '\\$')
    .replace(/\^/g, '\\^')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/\+/g, '\\+')
    .replace(/\[/g, '\\[')
    .replace(/\|/g, '\\|')
    .replace(/\./g, '\\.')
    .replace(/\//g, '\\/')
    .replace(/\*/g, '\\*');
};

const judgeKeyword = (message, keyword) => {
  keyword = keyword.replace(/\s/g, '');
  let keyResult;
  if (keyword.match(new RegExp('^[0-9]+$', 'ig')) != null) {
    // Only number
    keyResult = 'Part';
  } else if (keyword.match(new RegExp('^[a-zA-Z]+$', 'ig')) != null) {
    // Only Alphabet
    keyResult = 'Block';
    const indexes = indexesWithCaseInsensitiveMatch(message, keyword);
    for (let i = 0; i < indexes.length; i++) {
      const nextChatAt = message.charAt(indexes[i] + keyword.length);
      const preChatAt = message.charAt(indexes[i] - 1);
      if ((!nextChatAt || nextChatAt == ' ') && (!preChatAt || preChatAt == ' ')) {
        keyResult = 'Block';
      } else if (
        nextChatAt.match(new RegExp('^[a-zA-Z]+$', 'ig')) == null &&
        preChatAt.match(new RegExp('^[a-zA-Z]+$', 'ig')) == null
      ) {
        keyResult = 'Part';
        break;
      }
    }
  } else if (keyword.match(new RegExp('^[a-zA-Z0-9]+$', 'ig')) != null) {
    // Alphabet and Number
    keyResult = 'Part';
  } else if (keyword.match(new RegExp('^[a-zA-Z0-9\u00C0-\u00FF]+$', 'ig')) != null) {
    // Alphabet and Number and Special Character
    keyResult = 'Part';
  } else {
    // Japanese, Korean, Chinese
    keyResult = 'Part';
  }

  return keyResult;
};

/**
 * Validate whether the keyword exists in the message
 * @param {*} message
 * @param {*} keyword
 */
export const validateKeyword = (message, keyword) => {
  keyword = keyword.trim();

  let result;
  const keyResult = judgeKeyword(message, keyword);

  if (keyResult == 'Part') {
    keyword = addBackslash(keyword);
    result = message.match(new RegExp('(?:' + keyword + ')', 'ig'));
  } else if (keyResult == 'Block') {
    result = message.match(
      new RegExp(
        `(?:\\b|(?=[^a-zA-Z0-9\u00C0-\u00FF]))(?:${keyword})(?:\\b|(?<=[^a-zA-Z0-9\u00C0-\u00FF]))`,
        'ig'
      )
    );
  }

  return result ? true : false;
};

/**
 * Change style css for message when the keyword exists in the message
 * @param {*} message
 * @param {*} keywords
 */
export const handleReplaceMessage = (message, keywords) => {
  if (keywords.length === 0) return message;

  let listWarningWordsIndex = [];
  for (let i = 0; i < keywords.length; i++) {
    const keyword = escapeHtmlChar(keywords[i]);

    const keyResult = judgeKeyword(message, keyword);
    if (keyResult === 'Part') {
      const params = {
        message,
        keyword,
        listWordsIndex: listWarningWordsIndex
      };
      const [messageResult, listWordsIndex] = recursiveReplacePartMessage(params);
      listWarningWordsIndex = [...listWordsIndex];
      message = messageResult;
    } else {
      const params = {
        message,
        keyword,
        listWordsIndex: listWarningWordsIndex
      };
      const [messageResult, listWordsIndex] = recursiveReplaceBlockMessage(params);
      listWarningWordsIndex = [...listWordsIndex];
      message = messageResult;
    }
  }

  if (listWarningWordsIndex && listWarningWordsIndex.length > 0) {
    for (let i = 0; i < listWarningWordsIndex.length; i++) {
      for (let key in listWarningWordsIndex[i]) {
        const k = `${warningId}${key}`;
        const index = message.indexOf(k);
        message =
          message.substring(0, index) +
          listWarningWordsIndex[i][key] +
          message.substring(index + k.length, message.length);
      }
    }
  }

  return message
    .replace(new RegExp(startSpanTagId, 'g'), startSpanTag)
    .replace(new RegExp(endSpanTagId, 'g'), endSpanTag);
};

const recursiveReplaceBlockMessage = params => {
  let { message, keyword, listWordsIndex } = params;

  let hasReplace = false;
  const id = makeId();

  const defineRegex = `(?:\\b|(?=[^a-zA-Z0-9\u00C0-\u00FF]))(?:${keyword})(?:\\b|(?<=[^a-zA-Z0-9\u00C0-\u00FF]))`;
  const regexp = new RegExp(defineRegex, 'i');
  let result = regexp.exec(message);

  if (result) {
    const index = result['index'];
    const replaceTo = startSpanTagId + warningId + id + endSpanTagId;
    const originWord = message.substring(index, index + keyword.length);
    message =
      message.substring(0, index) +
      replaceTo +
      message.substring(index + originWord.length, message.length);
    listWordsIndex.push({ [id]: originWord });
    hasReplace = true;

    if (hasReplace)
      return recursiveReplaceBlockMessage({
        message,
        keyword,
        listWordsIndex
      });
  }

  return [message, listWordsIndex];
};

const recursiveReplacePartMessage = params => {
  let { message, keyword, listWordsIndex, currentIndex = -1 } = params;
  let indexes = indexesWithCaseInsensitiveMatch(message, keyword);
  indexes = indexes.filter(i => i >= currentIndex);

  const firstIndex = indexes && indexes.length ? indexes[0] : -1;
  if (firstIndex < 0) return [message, listWordsIndex];

  // keyword is only alphabet
  if (keyword.match(new RegExp('^[a-zA-Z]+$', 'ig')) != null) {
    const nextChatAt = message.charAt(firstIndex + keyword.length);
    const preChatAt = message.charAt(firstIndex - 1);
    if (
      nextChatAt.match(new RegExp('^[a-zA-Z]+$', 'ig')) != null ||
      preChatAt.match(new RegExp('^[a-zA-Z]+$', 'ig')) != null
    ) {
      return recursiveReplacePartMessage({
        message,
        keyword,
        listWordsIndex,
        currentIndex: firstIndex + keyword.length
      });
    }
  }

  const id = makeId();
  const replaceTo = startSpanTagId + warningId + id + endSpanTagId;
  const originWord = message.substring(firstIndex, firstIndex + keyword.length);
  message =
    message.substring(0, firstIndex) +
    replaceTo +
    message.substring(firstIndex + keyword.length, message.length);
  listWordsIndex.push({ [id]: originWord });

  return recursiveReplacePartMessage({
    message,
    keyword,
    listWordsIndex,
    currentIndex:
      firstIndex +
      startSpanTagId.length +
      warningId.length +
      id.length +
      endSpanTagId.length
  });
};
