import Service from 'core/service';
const service = new Service();
import firebaseAuth from 'core/firebase/auth';
import { i18n } from 'locales';
import { CNT_RECENT_PASSWORDS } from 'core/constants';
import baseNotification from 'components/BaseComponents/BaseNotification';
export const submitForm = (_, formUserUpdate) => {
  if (!formUserUpdate) return;

  const { email = '', curPass = '', firstName = '', lastName = '' } = formUserUpdate;
  return firebaseAuth
    .signIn(email, curPass)
    .then(() => {
      const subject = i18n.t('agent.sendmail.change.password.subject');
      const htmlContent = i18n.t('agent.sendmail.change.password.description', {
        first_name: firstName,
        last_name: lastName
      });


      formUserUpdate = { ...formUserUpdate, mailOptions };

      const mailOptions = {
        from: i18n.t('src.modules.session.store.actions.des_support'),
        to: email,
        subject,
        html: htmlContent
      };

      formUserUpdate = { ...formUserUpdate, mailOptions };
      return service
        .rest('authorizer/changePassword', formUserUpdate)
        .then(res => {
          const { errors } = res;
          if (errors) {
            const { code } = errors;
            switch (code) {
              case 'recent-password':
                baseNotification.warning({
                  title: i18n.t('src.core.App.warning'),
                  message: i18n.t(
                    'src.modules.password.store.actions.cannot_reuse_recent_passwords',
                    { recentValue: CNT_RECENT_PASSWORDS }
                  )
                });
                return false;
              case 'store-recent-passwords-fail':
                baseNotification.warning({
                  title: i18n.t('src.core.App.warning'),
                  message: i18n.t(
                    'src.modules.password.store.actions.current_password_is_invalid'
                  )
                });
                return false;
              default:
                break;
            }
          }
          return true;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log('[C+ Debug] >> [Error] >> SignIn >> changePassWord >> error >>', {
            error
          });
          baseNotification.warning({
            title: i18n.t('src.core.App.warning'),
            message: i18n.t(
              'src.modules.password.store.actions.current_password_is_invalid'
            )
          });
          return false;
        });
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[C+ Debug] >> [Error] >> SignIn >> ', { error });
      baseNotification.warning({
        title: i18n.t('src.core.App.warning'),
        message: i18n.t('src.modules.password.store.actions.current_password_is_invalid')
      });
      return false;
    });
};
