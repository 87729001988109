<template>
  <div class="survey-form-item">
    <div class="survey-title-item">
      <span v-if="item.rules && item.rules.required" class="icon-require">*</span>
        {{ item.question }}
    </div>
    <label class="d-block">
      {{ item.description }}
    </label>
    <base-textarea
      v-model="item.answer"
      :disabled="true"
    />
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss"></style>
