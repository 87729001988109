import { SET_PROFILE_INFO } from './types';

import * as getters from './getters';
import * as actions from './actions';

const state = {
  usersInfo: {}
};

const mutations = {
  [SET_PROFILE_INFO](state, users) {
    state.usersInfo = users;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
