<template>
  <ValidationObserver ref="messageLogSchedule">
    <div id="modal-cnv-schedule-export" class="modal-content div">
      <div class="modal-body">
        <b-tabs content-class="mt-3">
          <b-tab
            :title="
              $t('src.modules.report.components.cnv-scheduled-export.index.schedule_tab_title')
            "
            active
          >
            <el-row>
              <el-table
                :data="messageLogSchedules"
                :empty-text="
                  $t(
                    'src.modules.report.components.cnv-scheduled-export.index.schedule-table.empty-schedule'
                  )
                "
                style="width: 100%"
                max-height="500"
              >
                <el-table-column
                  :label="
                    $t('src.modules.report.components.cnv-scheduled-export.index.create_time')
                  "
                >
                  <template slot-scope="scope">
                    <span>{{ formatTime(scope.row.createdAt) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_by')"
                  prop="agentName"
                ></el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.type')"
                  prop="type"
                >
                  <template slot-scope="scope">
                    <span>{{ formatType(scope.row.type) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.detail')"
                >
                  <template slot-scope="scope">
                    <template v-if="scope.row.type == 'immediate'">
                      <el-tag class="responsive-tag" effect="plain">{{
                        convertFormatTime(scope.row.fromDate)
                      }}</el-tag
                      >-
                      <el-tag class="responsive-tag" effect="plain">{{
                        convertFormatTime(scope.row.toDate)
                      }}</el-tag>
                    </template>
                    <el-tag
                      class="responsive-tag"
                      v-if="scope.row.type == 'weekly'"
                      effect="plain"
                      type="success"
                      >{{ getWeeklyMonthlyDetail(scope.row.weekDay, scope.row.type) }}</el-tag
                    >
                    <el-tag
                      class="responsive-tag"
                      v-if="scope.row.type == 'monthly'"
                      effect="plain"
                      type="warning"
                      >{{ getWeeklyMonthlyDetail(scope.row.monthDay, scope.row.type) }}</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.search-management.index.status')"
                  prop="status"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.status == 'error'"
                      type="danger"
                      disable-transitions
                      v-b-tooltip.hover
                      :title="scope.row.exportError"
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.error'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'success'"
                      type="success"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.success'
                        )
                      }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.status == 'open'" type disable-transitions>
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.open'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'running'"
                      type="warning"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.running'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'stop'"
                      type="danger"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.stop'
                        )
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.text.action')" width="200">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.type == 'immediate' && scope.row.status != 'running'"
                      style="margin-left: -20px"
                      type="text"
                      size="small"
                      @click.native.prevent="clickDeleteScheduleBut(scope.row)"
                      >{{ $t('common.text.delete') }}</el-button
                    >
                    <template v-if="scope.row.type == 'weekly' || scope.row.type == 'monthly'">
                      <el-button
                        v-if="scope.row.status != 'running'"
                        type="text"
                        size="12px"
                        @click.native.prevent="clickDeleteScheduleBut(scope.row)"
                        >{{ $t('common.text.delete') }}</el-button
                      >
                      <span
                        v-if="
                          scope.row.status == 'open' ||
                          scope.row.status == 'stop' ||
                          scope.row.status == 'error'
                        "
                        >|</span
                      >
                      <el-button
                        v-if="scope.row.status == 'open'"
                        type="text"
                        size="small"
                        style="color: red"
                        @click="updateStatusSchedule(scope.row, 'stop')"
                      >
                        {{
                          $t(
                            'src.modules.report.components.cnv-scheduled-export.index.schedule.status.stop'
                          )
                        }}
                      </el-button>
                      <el-button
                        v-if="scope.row.status == 'stop' || scope.row.status == 'error'"
                        type="text"
                        size="small"
                        @click="updateStatusSchedule(scope.row, 'open')"
                      >
                        {{
                          $t(
                            'src.modules.report.components.cnv-scheduled-export.index.schedule.status.open'
                          )
                        }}
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </b-tab>
          <b-tab :title="$t('src.modules.report.components.index.title.files')" @click="clickTab">
            <el-table
              :data="messageLogScheduledFiles"
              :empty-text="
                $t(
                  'src.modules.report.components.cnv-scheduled-export.index.history-table.empty-history'
                )
              "
              style="width: 100%"
              max-height="500"
            >
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_time')"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.createdAt) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_by')"
                prop="agentName"
              ></el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.from_date')"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.fromDate, 'dateRange') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.to_date')"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.toDate, 'dateRange') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.type')"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.type == 'child_schedule'">{{
                    scope.row.parentType
                  }}</template>
                  <template v-else>{{ formatType(scope.row.type) }}</template>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.common.index.file_format')"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.exportFormat.toUpperCase() }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="150"
                :label="
                  $t('src.modules.report.components.cnv-scheduled-export.index.download_link')
                "
              >
                <template slot-scope="scope">
                  <el-tag
                    class="responsive-tag"
                    v-if="!scope.row.exportedFileLink"
                    type="warning"
                    disable-transitions
                    >{{ $t('common.text.no_data') }}</el-tag
                  >
                  <el-tag class="responsive-tag" v-else type="success" disable-transitions>
                    <a @click="downloadReport(scope.row.id)" style="cursor: pointer">{{
                      $t('src.modules.report.components.index.button.download')
                    }}</a>
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import * as defaultParams from '../../store/define';
import { weeklyMonthlyTitle } from '../../helper';
import { getAgentName, getTzByLocalFile } from 'core/helpers';
import { FORMAT_TIME_IMMEDIATE_REPORT, LOG_REPORT_TYPE, RESULT_REPORT_TYPE } from 'core/constants';
import { EventBus } from 'core/eventBus';

export default {
  name: 'CnvScheduledModal',

  components: {},

  props: {
    messageLogSchedules: {
      type: Array,
      default: () => []
    },
    messageLogScheduledFiles: {
      type: Array,
      default: () => []
    },
    channelName: {
      type: String,
      default: ''
    },
    channelId: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    platform: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'en-US'
    },
    selectScheduleType: {
      type: String,
      default: 'immediate'
    },
    selectDayNameOfWeek: {
      type: String,
      default: '0'
    },
    selectDayOfMonth: {
      type: String,
      default: '1'
    },
    scheduleDateRange: {
      type: Array,
      default: () => []
    },
    selectFileFormat: {
      type: String,
      default: 'excel'
    }
  },

  data() {
    return {
      dayOfMonthRow: defaultParams.schedule.dayOfMonthRow,
      dayOfWeekRow: defaultParams.schedule.dayOfWeekRow,
      selectedScheduleToDeleted: {}
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapState('report', ['channelsFilterMap']),
    getUser() {
      return this.user;
    },
    popupTitle() {
      return `${this.$t(
        'src.modules.report.components.cnv-scheduled-export.index.converstion_report_schedule'
      )} - ${this.channelName.toUpperCase()}`;
    },
    channelTimezone() {
      const { advancedConfigs = {} } = this.channelsFilterMap[this.channelId] || {};
      let tz = advancedConfigs && advancedConfigs.general && advancedConfigs.general.timezone;
      return tz;
    }
  },

  watch: {
    channelId() {
      this.clickOkBut();
    }
  },

  async created() {
    await this.clickOkBut();
    EventBus.$on('syncAllDataReport', () => this.clickOkBut());
    EventBus.$on('setExportCustomerSchedule', () => {
      switch (this.selectScheduleType) {
        case 'immediate':
          this.clickAddScheduleBut();
          break;

        case 'weekly':
          this.clickAddWeeklyScheduleBut();
          break;

        case 'monthly':
          this.clickAddMonthlyScheduleBut();
          break;
      }
    });
  },

  destroyed() {
    EventBus.$off('syncAllDataReport');
    EventBus.$off('setExportCustomerSchedule');
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('report', [
      'getMessageLogReportSchedules',
      'getMessageLogReportScheduleFiles',
      'addWeeklyMessageLogReportSchedule',
      'addMonthlyMessageLogReportSchedule',
      'removeMessageLogReportSchedule',
      'updateMessageLogReportScheduleStatus',
      'getDownloadUrl',
      'createLogForExport'
    ]),

    clickTab() {
      this.clickOkBut();
    },

    async downloadReport(id) {
      this.setGlobalReady(false);
      await this.getDownloadUrl({
        id: `${id}`,
        userId: this.user.id
      })
        .then(async res => {
          if (res.status === 'success') {
            window.open(res.downloadUrl);
          } else {
            this.$baseNotification.warning({
              title: this.$t('src.modules.report.component.report_not_found_title'),
              message: this.$t('src.modules.report.component.report_not_found_message')
            });
          }
        })
        .catch(() => {
          this.errorMessage();
        })
        .finally(() => {
          this.setGlobalReady(true);
        });
    },

    async updateStatusSchedule(schedule, status) {
      this.setGlobalReady(false);

      const { id, userId } = schedule;
      const data = { id };
      data['status'] = status;
      data['userId'] = userId;

      const result = await this.updateMessageLogReportScheduleStatus(data);
      if (result) {
        await this.getMessageLogReportSchedules({
          userId: this.user.id,
          channelId: this.channelId,
          language: this.locale
        });
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.schedule.update_status.sccessful'
          )
        });
      } else {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.schedule.update_status.occur_error'
          )
        });
      }
      this.setGlobalReady(true);
    },

    getSelectDayOfMonth(monthDay) {
      this.dayOfMonthRow = monthDay;
    },

    getSelectDay(weekDay) {
      this.dayOfWeekRow = weekDay;
    },

    show() {
      this.$refs.modalCnvScheduleExport.show();
    },

    clickAddScheduleBut() {
      const cnvScheduleRange = this.scheduleDateRange;
      this.$refs.messageLogSchedule.validate().then(async submit => {
        if (!submit) {
          this.errorMessage();
        } else {
          if (cnvScheduleRange.length >= 2) {
            const fromDate = moment(cnvScheduleRange[0]);
            const toDate = moment(cnvScheduleRange[1]);
            const diffDays = toDate.diff(fromDate, 'days');
            if (diffDays >= 31 || diffDays < 0) {
              this.$baseNotification.warning({
                title: this.$t('src.core.App.warning'),
                message: this.$t(
                  'src.modules.report.components.cnv-scheduled-export.index.date_range_warning.can_not_beyond'
                )
              });
              return false;
            }
            this.$emit('addMsgLogExportSchedule', cnvScheduleRange);
          }
        }
      });
    },

    getWeeklyMonthlyDetail: (day, type) => weeklyMonthlyTitle(day, type),

    async updateWeeklySchedule() {
      this.setGlobalReady(false);
      const agent_id = this.getUser.id;
      const week_day = this.dayOfWeekRow;
      if (isNaN(week_day)) return false;
      const result = await this.addWeeklyMessageLogReportSchedule({
        userId: agent_id,
        userName: getAgentName(this.user),
        system: 'at',
        channelId: this.channelId,
        projectId: this.projectId,
        weekDay: week_day,
        type: 'weekly'
      });
      if (result) {
        await this.getMessageLogReportSchedules({
          userId: this.user.id,
          channelId: this.channelId,
          language: this.locale
        });
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.weekly_schedule.add_sccessful'
          )
        });
      } else {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.weekly_schedule.occur_error'
          )
        });
      }
      this.setGlobalReady(true);
    },

    async updateMonthlySchedule() {
      this.setGlobalReady(false);
      const agent_id = this.getUser.id;
      const month_day = this.dayOfMonthRow;
      if (isNaN(month_day)) return false;
      const result = await this.addMonthlyMessageLogReportSchedule({
        userId: agent_id,
        userName: getAgentName(this.user),
        system: 'at',
        channelId: this.channelId,
        projectId: this.projectId,
        monthDay: month_day,
        type: 'monthly'
      });
      if (result) {
        await this.getMessageLogReportSchedules({
          userId: this.user.id,
          channelId: this.channelId,
          language: this.locale
        });
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.add_sccessful'
          )
        });
      } else {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.occur_error'
          )
        });
      }
      this.setGlobalReady(true);
    },

    async clickAddWeeklyScheduleBut() {
      this.setGlobalReady(false);
      const agent_id = this.getUser.id;
      const week_day = this.selectDayNameOfWeek;
      if (isNaN(week_day)) return false;

      const data = {
        userId: agent_id,
        userName: getAgentName(this.user),
        system: 'at',
        channelId: this.channelId,
        projectId: this.projectId,
        weekDay: week_day,
        language: this.locale,
        type: 'weekly',
        exportFormat: this.selectFileFormat
      };

      const { email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
      const actionLogCreate = {
        userId: agent_id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        channelId: this.channelId,
        projectId: this.projectId,
        type: this.selectScheduleType,
        exportFormat: this.selectFileFormat,
        channelName: this.channelName,
        platform: this.platform,
        reportType: LOG_REPORT_TYPE.MESSAGE_LOG,
        system: 'at'
      };

      const result = await this.addWeeklyMessageLogReportSchedule(data);
      if (result) {
        if (result.status) {
          await this.getMessageLogReportSchedules({
            userId: this.user.id,
            channelId: this.channelId,
            language: this.locale
          });
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t(
              'src.modules.report.components.cnv-scheduled-export.index.weekly_schedule.add_sccessful'
            )
          });
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.SUCCESS
          });
        } else if (!result.status && result.error_key === 'schedule_exist') {
          const messErr = this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.this_schedule_already_exists_please_select_another_schedule'
          );
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: messErr
          });
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.ERROR,
            exportError: messErr
          });
        }
      } else {
        const messErr = this.$t(
          'src.modules.report.components.cnv-scheduled-export.index.weekly_schedule.occur_error'
        );
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: messErr
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: messErr
        });
      }
      this.setGlobalReady(true);
    },

    async clickAddMonthlyScheduleBut() {
      this.setGlobalReady(false);
      const agent_id = this.getUser.id;
      const month_day = this.selectDayOfMonth;
      if (isNaN(month_day)) return false;

      const data = {
        userId: agent_id,
        userName: getAgentName(this.user),
        system: 'at',
        channelId: this.channelId,
        projectId: this.projectId,
        monthDay: month_day,
        language: this.locale,
        type: 'monthly',
        exportFormat: this.selectFileFormat
      };

      const { email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
      const actionLogCreate = {
        userId: agent_id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        channelId: this.channelId,
        projectId: this.projectId,
        type: this.selectScheduleType,
        exportFormat: this.selectFileFormat,
        channelName: this.channelName,
        platform: this.platform,
        reportType: LOG_REPORT_TYPE.MESSAGE_LOG,
        system: 'at'
      };

      const result = await this.addMonthlyMessageLogReportSchedule(data);
      if (result) {
        if (result.status) {
          await this.getMessageLogReportSchedules({
            userId: this.user.id,
            channelId: this.channelId,
            language: this.locale
          });
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t(
              'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.add_sccessful'
            )
          });
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.SUCCESS
          });
        } else if (!result.status && result.error_key === 'schedule_exist') {
          const messErr = this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.this_schedule_already_exists_please_select_another_schedule'
          );
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: messErr
          });
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.ERROR,
            exportError: messErr
          });
        }
      } else {
        const messErr = this.$t(
          'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.occur_error'
        );
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: messErr
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: messErr
        });
      }
      this.setGlobalReady(true);
    },

    clickDeleteScheduleBut(schedule) {
      this.selectedScheduleToDeleted = schedule;
      this.$baseConfirm({
        message: this.$t(
          'src.modules.report.components.cnv-scheduled-export.index.are_you_sure_you_want_to_remove_this_schedule'
        )
      })
        .then(() => {
          this.okRemoveExportCustomerSchedule();
        })
        .catch(() => {});
    },

    async okRemoveExportCustomerSchedule() {
      const schedule = this.selectedScheduleToDeleted;
      const { id: scheduleId = '' } = schedule;

      this.$emit('deleteMsgLogExportSchedule', scheduleId);
    },

    async clickOkBut() {
      this.setGlobalReady(false);
      await this.getMessageLogReportSchedules({
        userId: this.user.id,
        channelId: this.channelId,
        language: this.locale
      });
      await this.getMessageLogReportScheduleFiles({
        userId: this.user.id,
        channelId: this.channelId
      });
      this.setGlobalReady(true);
    },

    formatTime(value, type) {
      if (!value) return null;
      if (type === 'dateRange')
        return value.split('T').length > 1
          ? moment(value.split('T')[0]).format('YYYY/MM/DD')
          : moment(value).format('YYYY/MM/DD');
      return getTzByLocalFile({ value, name: this.channelTimezone });
    },

    formatType(value) {
      return value == 'immediate'
        ? this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.schedule_type.immediate'
          )
        : value == 'weekly'
        ? this.$t('src.modules.report.components.cnv-scheduled-export.index.schedule_type.weekly')
        : this.$t('src.modules.report.components.cnv-scheduled-export.index.schedule_type.monthly');
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    convertFormatTime(time) {
      return moment(time).format(FORMAT_TIME_IMMEDIATE_REPORT.DISPLAY);
    }
  }
};
</script>
<style lang="scss" scoped>
.nat-btn-add_schedule {
  float: left;
  height: 40px;
  margin-top: 5px;
  margin-left: 8px;
}
.nat-btn-refresh {
  float: left;
  height: 40px;
  margin-top: 5px;
}
.nat-date-range-schedule {
  float: left;
  /deep/ .el-range-editor {
    width: 100%;
  }
}
</style>
