<template>
  <div v-if="invalid" class="py-3 text-danger">
    {{ $t('src.modules.session.store.actions.email_is_invalid') }}
  </div>
  <div v-else>
    {{ $t('src.modules.session.components.adfsCallback.login_with_adfs') }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      invalid: false
    };
  },

  computed: {
    ...mapGetters('session', ['isAuthenticated'])
  },

  mounted() {
    if (this.isAuthenticated) return this.$router.push('/');
    const clientId = localStorage.getItem('client_id');
    const { code } = this.$route.query;
    if (!clientId || !code) this.$router.push('/');
    // eslint-disable-next-line
    this.handleSignInWithOAuth(clientId, code);
  },

  methods: {
    ...mapActions('session', ['loginOAuth']),
    ...mapActions('global', ['setGlobalReady']),

    handleSignInWithOAuth(clientId, code) {
      this.setGlobalReady(false);
      this.loginOAuth({ clientId, code })
        .then(loginStatus => {
          if (loginStatus === false) {
            this.setGlobalReady(true);
            this.invalid = true;
            setTimeout(() => window.location.replace('/login'), 2000);
          }
        })
        .catch(() => this.setGlobalReady(true));
    }
  }
};
</script>
