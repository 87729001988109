<template>
  <div>
    <b-row v-if="valueString !== ''" class="my-1">
      <b-col sm="3">
        <label for="input-small">{{ label }} :</label>
      </b-col>
      <b-col class="line-break" sm="9">
        <label for="input-small">{{ valueString }}</label>
      </b-col>
    </b-row>
    <b-row v-if="valueArray.length > 0" class="my-1">
      <b-col sm="3">
        <label for="input-small">{{ label }} :</label>
      </b-col>
      <b-col sm="9">
        <div v-for="(item, index) in valueArray" :key="index">
          <label for="input-small"
            >{{ item }}
            <span v-if="index != valueArray.length - 1">, </span></label
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    valueString: {
      type: String,
      default: ''
    },
    valueArray: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },

  computed: {}
};
</script>

<style lang="scss" scoped>
.line-break {
  word-break: break-word;
  white-space: break-spaces;
}
</style>
