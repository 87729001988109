import { i18n } from 'locales';

const weeklyMonthlyTitle = (day, type) => {
  let returnStr = '';
  if (type == 'weekly') {
    const weekday = [
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.sunday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.monday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.tuesday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.wednesday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.thursday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.friday'
      ),
      i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.saturday'
      )
    ];
    returnStr = day < 0 ? weekday[0] : weekday[day];
  }
  if (type == 'monthly') {
    returnStr = day ? i18n.t(
      'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.display_day_schedule',
      { '0': day }
    ) : i18n.t(
      'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.last_day_of_every_month'
    )
  }
  return returnStr;
};

export { weeklyMonthlyTitle };
