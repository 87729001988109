<template>
  <div id="searchBox" class="form-group search-box">
    <div id="filter" class="input-group" variant="primary">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa fa-search" />
        </span>
      </div>
      <input
        v-model="searchText"
        :placeholder="
          $t(
            'src.modules.chat.components.AsideLeft.SearchBox.search-comment.search_channel_name'
          )
        "
        class="form-control"
        type="text"
        @keyup.enter="onSearchtext"
      />
      <div class="input-group-prepend">
        <span class="input-group-text last">
          <i class="fa fa-sort-amount-down" />
        </span>
      </div>
    </div>
    <div>
      <ul class="nav nav-pills">
        <li
          v-for="(badgeItem, index) in listFilterBadges"
          :key="`${badgeItem}-${index}`"
          :type="badgeItem"
          style="white-space: pre-wrap;"
        >
          <filter-badge-item
            :badge-name="badgeItem.badgeName"
            :badge-type="badgeItem.badgeType"
            :badge-name-translate="badgeItem.badgeNameTranslate || ''"
            @onRemoveFilterBadge="onRemoveFilterBadge(badgeItem)"
          />
        </li>
      </ul>
    </div>
    <b-popover
      :show="show"
      class="ppvFilter"
      target="filter"
      triggers="focus"
      placement="bottom"
      width="300"
      @hidden="onHidden"
    >
      <!-- Languages (S) -->
      <base-radio-group
        :label="
          $t('src.modules.chat.components.AsideLeft.SearchBox.search-comment.platform')
        "
        v-model="platform"
        :name="platform"
        :options="platformOptions"
        :option-lable="'text'"
        :option-value="'value'"
        :value-type="'String'"
        :default-first-option="false"
      />
    </b-popover>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FilterBadgeItem from '../SearchBox/filter-badge-item';

export default {
  components: {
    FilterBadgeItem
  },

  props: {
    langsupports: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      lstSearchText: [],
      searchText: '',

      platform: '',
      platformOptions: [
        {
          text: this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.search-comment.facebook'
          ),
          value: 'Facebook'
        },
        {
          text: this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.search-comment.instagram'
          ),
          value: 'Instagram'
        }
      ],
      listFilterBadges: []
    };
  },

  beforeDestroy() {
    this.platform = '';
    this.listFilterBadges = [];
    this.lstSearchText = [];
    this.searchText = '';
    this.onOk();
  },

  methods: {
    ...mapActions('chat', ['setSearchBoxHeight']),

    updateSearchBoxHeight() {
      let height = 95;
      if (this.listFilterBadges && this.listFilterBadges.length > 0) {
        const searchBoxHeight = document.getElementById('searchBox');
        height = searchBoxHeight ? searchBoxHeight.offsetHeight : 0;
      }
      this.setSearchBoxHeight(height);
    },

    async onOk() {
      // Build filter badges
      let allBadges = [];
      let filters = {};
      if (this.lstSearchText && this.lstSearchText.length > 0) {
        filters.userName = this.lstSearchText;
        this.lstSearchText.map(text => {
          allBadges.push({
            badgeName: text,
            badgeType: 'text'
          });
        });
      }

      if (this.platform !== '') {
        let badgeNameTranslate = '';
        this.platformOptions.map(i => {
          if (i.value === this.platform) {
            badgeNameTranslate = i.text;
          }
        });
        allBadges.push({
          badgeNameTranslate,
          badgeName: this.platform,
          badgeType: 'platform'
        });
      }
      this.listFilterBadges = allBadges;
      this.$emit('onSearchChange', {
        text: this.lstSearchText,
        platform: this.platform
      });
    },

    onRemoveFilterBadge(badgeItem) {
      let removeKey = badgeItem.badgeName;
      switch (badgeItem.badgeType) {
        case 'text':
          this.lstSearchText = this.lstSearchText.filter(item => {
            return item !== removeKey;
          });
          break;
        case 'platform':
          this.platform = '';
          break;
        default:
          break;
      }

      this.onOk();
    },

    onSearchtext() {
      this.searchText = this.searchText ? this.searchText.trim() : '';
      if (this.searchText === '') {
        this.searchText = '';
        return;
      }

      if (this.searchText.length < 2) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.search-comment.please_input_more_than_1_character'
          )
        });
        return;
      }

      if (!this.lstSearchText.includes(this.searchText)) {
        this.lstSearchText = [...this.lstSearchText, this.searchText];
        // add to filter badges

        this.listFilterBadges = [
          ...this.listFilterBadges,
          {
            badgeName: this.searchText,
            badgeType: 'text'
          }
        ];
      }

      this.searchText = '';
    },

    onHidden() {
      this.onOk();
    },

    clearFilterCondition() {
      this.lstSearchText = [];
      this.listFilterBadges = [];
    }
  }
};
</script>

<style>
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 300px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.popover > .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.app-message .page-aside .search-box a {
  padding: 3px 3px 2px 3px;
  margin: 5px;
  margin-top: 15px;
  padding-right: 15px;
  border: none;
  position: relative;
  text-transform: inherit;
  background-color: #f9f9fc;
  color: #172b4d;
}
.app-message .page-aside .search-box .badge {
  position: absolute;
  background: #f9f9fc;
  top: -6px;
  right: -15px;
  padding: 4px;
  color: #172b4d;
  border-radius: 50%;
}
.app-message .page-aside .search-box .badge i {
  font-size: 0.6rem;
}
.app-message .page-aside .search-box .custom-checkbox {
  width: 100%;
}
#searchBox .last > i:nth-child(1) {
  display: none;
}
</style>
