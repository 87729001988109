<template>
  <div class="container-fluid p-0 page">
    <AppLeftMenu show-name="support" />

    <main class="nat-main-ctnr">
      <AppCover />
      <div class="nat-sub-ctnr">
        <section>
          <div class="nat-cover-ctnr">
            <!-- Title -->
            <b-row class="nat-title-wrap">
              <b-col xl="12">
                <h2>
                  {{ $t('src.modules.customer-support-history.index.customer_support_history') }}
                </h2>
              </b-col>
            </b-row>
            <!-- Content -->
            <b-row class="nat-content-wrap">
              <b-col ld="12" xl="3" class="nat-condition-bar">
                <!-- Project Selection -->
                <div class="nat-condition-item">
                  <base-select
                    :label-width="labelWidth"
                    :align="'horizontal'"
                    :required="true"
                    v-model="projectSel"
                    :options="projectList"
                    :option-value="'value'"
                    :option-lable="'text'"
                    :name="'projectSel'"
                    :label="$t('src.modules.customer-support-history.index.project')"
                    :value-type="'String'"
                  />
                </div>
                <div class="nat-condition-item">
                  <!-- Group Selection -->
                  <base-select
                    :label-width="labelWidth"
                    :align="'horizontal'"
                    v-if="groupList.length > 0"
                    v-model="groupSel"
                    :options="groupList"
                    :option-value="'value'"
                    :option-lable="'text'"
                    :name="'groupSel'"
                    :label="$t('src.modules.customer-support-history.index.group')"
                    :value-type="'String'"
                    :required="true"
                    :using-for="'select-group'"
                  />
                </div>
                <div class="nat-condition-item">
                  <!-- Template Selection -->
                  <base-select
                    :label-width="labelWidth"
                    :align="'horizontal'"
                    v-model="templateSel"
                    :options="templateList"
                    :option-value="'value'"
                    :option-lable="'text'"
                    :name="'templateSel'"
                    :label="$t('src.modules.customer-support-history.index.template')"
                    :value-type="'String'"
                    :required="true"
                  />
                </div>
                <div class="nat-condition-item">
                  <!-- Platform Selection -->
                  <base-select
                    :label-width="labelWidth"
                    :align="'horizontal'"
                    v-model="platformSel"
                    :options="platformList"
                    :option-value="'value'"
                    :option-lable="'text'"
                    :name="'platformSel'"
                    :label="$t('src.modules.customer-support-history.index.platform')"
                    :value-type="'String'"
                  />
                </div>
                <div class="nat-condition-item">
                  <!-- Channel Selection -->
                  <base-select
                    :label-width="labelWidth"
                    :align="'horizontal'"
                    v-model="channelSel"
                    :options="channelList"
                    :option-value="'value'"
                    :option-lable="'text'"
                    :name="'platformSel'"
                    :label="$t('src.modules.customer-support-history.index.channel')"
                    :value-type="'String'"
                  />
                </div>
                <div class="nat-condition-item">
                  <b-card class="mb-3 tree-content-wrap">
                    <!-- Catelogy -->

                    <b-input-group>
                      <b-input-group-text slot="prepend" class="search-icon">
                        <i class="fas fa-search"></i>
                      </b-input-group-text>
                      <b-form-input
                        v-model="treeFilter"
                        :placeholder="
                          $t('src.modules.customer-support-history.index.search_category')
                        "
                      />
                    </b-input-group>
                    <tree
                      ref="tree"
                      v-model="selected"
                      :data="categoryTree"
                      :options="categoryTreeOptions"
                      :filter="treeFilterValue"
                    >
                      <span slot-scope="{ node }" class="tree-text">
                        <template v-if="!node.hasChildren() && node.text !== '...'">
                          <i class="ion-md-document"></i>
                          {{ node.text }}
                        </template>

                        <template v-if="!node.hasChildren() && node.text === '...'">
                          <i class="ion-md-close-circle"></i>
                          {{ node.text }}
                        </template>

                        <template v-if="node.hasChildren()">
                          <i
                            :class="[node.expanded() ? 'ion-md-folder-open' : 'ion-md-folder']"
                          ></i>
                          {{ node.text }}
                        </template>
                      </span>
                    </tree>
                  </b-card>
                </div>
              </b-col>

              <!-- Main table -->
              <b-col ld="12" xl="9" class="nat-grid-result">
                <b-row class="nat-condition-row">
                  <b-col xl="5">
                    <div class="nat-condition-item">
                      <base-date-picker-range
                        v-model="dateRange"
                        :rules="'birthday'"
                        :picker-options="pickerOptions"
                      />
                    </div>
                  </b-col>
                  <b-col xl="2"></b-col>
                  <b-col xl="5">
                    <div class="nat-condition-item-search">
                      <base-input
                        v-model="searchText"
                        :name="'filter-file'"
                        :placeholder="
                          $t(
                            'src.modules.customer-support-history.index.search_by_category_customer_name_or_title'
                          )
                        "
                        @input="handleSearchCustomer"
                        @keyup.enter="handleSearchCustomer"
                        :icon="'search'"
                        :align="'horizontal'"
                        :maxlength="-1"
                      />
                    </div>
                  </b-col>
                  <b-col xl="12">
                    <b-button
                      variant="secondary"
                      class="mb-5"
                      style="float: right"
                      @click="handleBtnAddOptionalColumns"
                    >
                      <i class="el-icon-set-up" style="font-size: large"></i>
                      {{ $t('src.modules.search-management.index.add_optional_columns') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- element -->
                <div class="nat-condition-item-table">
                  <b-table
                    :items="paginatedData"
                    :fields="customerSupportHistoryHeader"
                    :current-page="pageNumber"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    class="nat-table m-b-30 table-content-wrap"
                    responsive
                    striped
                    hover
                    thead-class="nat-table-header"
                    show-empty
                  >
                    <!-- Show row empty data  -->
                    <template slot="empty">
                      <div v-if="isBusy" class="text-center">
                        <spinner />
                      </div>
                      <div v-else class="text-center">
                        {{ $t('common.text.no_data') }}
                      </div>
                    </template>

                    <!-- No. -->
                    <template slot="index" slot-scope="data">
                      <p class="nat-cell-text">{{ data.index + 1 + perPage * (pageNumber - 1) }}</p>
                    </template>

                    <!-- Ticket Id-->
                    <template slot="ticketId" slot-scope="data">
                      <p class="nat-cell-text">{{ data.value }}</p>
                    </template>

                    <!-- createdAt -->
                    <template slot="createdAt" slot-scope="data">
                      <p class="nat-cell-text">{{ formatTime(data.value) }}</p>
                    </template>

                    <!-- updatedAt -->
                    <template slot="updatedAt" slot-scope="data">
                      <p class="nat-cell-text">{{ formatTime(data.value) }}</p>
                    </template>

                    <template slot="createdByAgent" slot-scope="data">
                      <p class="nat-cell-text">{{ data.value }}</p>
                    </template>

                    <template slot="updatedByAgent" slot-scope="data">
                      <p class="nat-cell-text">{{ data.value }}</p>
                    </template>

                    <!-- categoryPath -->
                    <template slot="categoryPath" slot-scope="data">
                      <div v-b-tooltip.hover :title="data.value">
                        <p class="nat-cell-text">{{ data.value | truncate(30) }}</p>
                      </div>
                    </template>

                    <!-- userId -->
                    <template slot="userId" slot-scope="data">
                      <p class="nat-cell-text">{{ data.value }}</p>
                    </template>

                    <!-- userName -->
                    <template slot="userName" slot-scope="data">
                      <p class="nat-cell-text">{{ data.value }}</p>
                    </template>

                    <!-- title -->
                    <template slot="title" slot-scope="data">
                      <span class="nat-cell-text" v-b-tooltip.hover :title="data.value">
                        {{ data.value | truncate(30) }}
                      </span>
                    </template>

                    <!-- platform -->
                    <template slot="platform" slot-scope="data">
                      <p class="nat-cell-text">
                        {{ standardizePlatformName[data.value.toLowerCase()] || data.value }}
                      </p>
                    </template>

                    <!-- channel -->
                    <template slot="channelName" slot-scope="data">
                      <p class="nat-cell-text">{{ channelsMap[data.item.channelId].name }}</p>
                    </template>

                    <!-- action -->
                    <template slot="action" slot-scope="data">
                      <!-- icon search -->
                      <a
                        v-if="
                          data.item.status !== 'draft' ||
                          (data.item.status === 'draft' && !data.item.isAllowEdit)
                        "
                        :id="`v-${data.index + 1 + perPage * (pageNumber - 1)}`"
                        v-b-toggle.clRequestHistory
                        href="javascript:void(0)"
                        @click="handleSupportItemClick(data.item, 'view')"
                      >
                        <i class="fa fa-search"></i>
                        <BootstrapTooltipCustom
                          :id="`v-${data.index + 1 + perPage * (pageNumber - 1)}`"
                          :content="$t('common.text.view_form')"
                        />
                      </a>

                      <!-- icon edit -->
                      <a
                        v-if="data.item.status === 'draft' && data.item.isAllowEdit"
                        :id="`e-${data.index + 1 + perPage * (pageNumber - 1)}`"
                        v-b-toggle.clRequestHistory
                        href="javascript:void(0)"
                        @click="handleSupportItemClick(data.item, 'edit')"
                      >
                        <i class="fa fa-edit"></i>
                        <BootstrapTooltipCustom
                          :id="`e-${data.index + 1 + perPage * (pageNumber - 1)}`"
                          :content="$t('common.text.edit')"
                        />
                      </a>

                      <!-- icon trash -->
                      <a
                        v-if="data.item.status === 'draft' && data.item.isAllowEdit"
                        :id="`d-${data.index + 1 + perPage * (pageNumber - 1)}`"
                        href="javascript:void(0)"
                        class="ml-2"
                        @click="handleDeleteItemClick(data.item)"
                      >
                        <i class="fa fa-trash-alt"></i>
                        <BootstrapTooltipCustom
                          :id="`d-${data.index + 1 + perPage * (pageNumber - 1)}`"
                          :content="$t('common.text.delete')"
                        />
                      </a>

                      <!-- icon comments -->
                      <a
                        v-b-toggle.clRequestHistory
                        :id="`m-${data.index + 1 + perPage * (pageNumber - 1)}`"
                        href="javascript:void(0)"
                        class="ml-2"
                        @click="handleShowTicket(data.item)"
                      >
                        <i class="fa fa-comments"></i>
                        <BootstrapTooltipCustom
                          :id="`m-${data.index + 1 + perPage * (pageNumber - 1)}`"
                          :content="$t('common.text.message_history')"
                        />
                      </a>
                    </template>
                  </b-table>
                </div>
                <!-- pagging -->
                <div v-if="totalRows > 0" class="bt-paging mt-3">
                  <b-pagination
                    v-model="pageNumber"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0 pull-right"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
      <AppFooter />
    </main>

    <!-- Click icon search in row => Open Customer support popup  -->
    <CustomerSupport
      v-if="showSupportModal"
      :is-chat="false"
      :conversation="selectedConversation"
      :project-id="this.projectSel"
      @close="hideSupportModal"
    />

    <!--Click icon comments in row  => Open Message History popup -->
    <b-modal
      id="TicketHistory"
      ref="ticketHistoryModal"
      v-model="showTicketHistoryModal"
      :title="$t('common.text.message_history')"
      modal-class="integrated-audio"
      size="lg"
      scrollable
      @shown="scrollToBottomAppCardChat"
      @hidden="onCancel"
    >
      <div
        v-if="messages.length > 0"
        id="appCardChat"
        ref="appCardChat"
        class="message-list-card"
        @scroll="handleScroll"
      >
        <message-list
          :messages="messages"
          :loading="loadingMore"
          :conversation="selectedConversation"
        />
      </div>
      <div slot="modal-footer" class="w-100">
        <b-btn class="float-right" variant="default" @click="onCancel">
          {{ $t('common.confirmModal.close') }}
        </b-btn>
      </div>
    </b-modal>
    <!-- Column Filter Modal -->
    <ColumnsFilterModal
      :filter-columns="optionalColumns"
      @finishFilterColumns="handleEmitFinishFilterColumns"
    />
  </div>
</template>

<script>
import { pick, reduce, filter } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import store from 'core/store';
import { EventBus } from 'core/eventBus';
import { CustomerSupport } from '../chat/components';
import MessageList from './MessageList';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import SubscribeUpdateConversation from 'gql/SubscribeUpdateConversation.gql';
import { AGENT_ROLE, TICKET_STATE, STANDARDIZE_PLATFORM_NAME } from 'core/constants';
import { addMessageStatus } from '../chat/helpers';
import { convertTimeUTC } from 'core/helpers';
import ColumnsFilterModal from './components/column-filter';

// Event click tree Catelogy
const eventsList = [
  { name: 'tree:mounted', args: ['Tree Component'] },
  { name: 'tree:filtered', args: ['Matches', 'Filter String'] },
  { name: 'tree:data:fetch', args: ['Parent Node'] },
  { name: 'tree:data:received', args: ['Parent Node'] },
  { name: 'node:disabled', args: ['Node'] },
  { name: 'node:enabled', args: ['Node'] },
  { name: 'node:shown', args: ['Node'] },
  { name: 'node:hidden', args: ['Node'] },
  { name: 'node:dblclick', args: ['Node'] },
  { name: 'node:selected', args: ['Node'] },
  { name: 'node:unselected', args: ['Node'] },
  { name: 'node:checked', args: ['Node'] },
  { name: 'node:unchecked', args: ['Node'] },
  { name: 'node:expanded', args: ['Node'] },
  { name: 'node:collapsed', args: ['Node'] },
  { name: 'node:added', args: ['Node', 'New Node'] },
  { name: 'node:removed', args: ['Node'] },
  { name: 'node:text:changed', args: ['Node', 'New Text', 'Old Text'] },
  { name: 'node:editing:start', args: ['Node'] },
  { name: 'node:editing:stop', args: ['Node', 'isTextChanged'] }
];

export default {
  components: {
    CustomerSupport,
    AppLeftMenu,
    AppFooter,
    AppCover,
    MessageList,
    BootstrapTooltipCustom,
    ColumnsFilterModal
  },

  data() {
    return {
      // Define col customer support gird
      customerSupportHistoryHeader: {
        index: {
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        updatedAt: {
          label: this.$t('src.modules.customer-support-history.index.updated'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        updatedByAgent: {
          label: this.$t('src.modules.customer-support-history.index.updatedBy'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        categoryPath: {
          label: this.$t('src.modules.customer-support-history.index.category'),
          class: 'nat-table-col-category'
        },
        title: {
          label: this.$t('src.modules.customer-support-history.index.title'),
          class: 'nat-table-col-title'
        },
        channelName: {
          label: this.$t('src.modules.customer-support-history.index.channel_name'),
          class: 'nat-table-col-channel-name'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action'
        }
      },

      optionalColumns: [
        {
          key: 'ticketId',
          label: this.$t('src.modules.customer-support-history.index.ticket-id'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        {
          key: 'createdAt',
          label: this.$t('src.modules.customer-support-history.index.created'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        {
          key: 'createdByAgent',
          label: this.$t('src.modules.customer-support-history.index.createdBy'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        {
          key: 'userId',
          label: this.$t('src.modules.search-management.index.user_id'),
          sortable: true,
          class: 'nat-table-col-customer-id'
        },
        {
          key: 'userName',
          label: this.$t('src.modules.search-management.index.user_name'),
          class: 'nat-table-col-customer-name'
        },
        {
          key: 'platform',
          label: this.$t('src.modules.customer-support-history.index.platform'),
          class: 'nat-table-col-flatform'
        }
      ],
      mandatoryColumns: [
        {
          key: 'index',
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        {
          key: 'updatedAt',
          label: this.$t('src.modules.customer-support-history.index.updated'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        {
          key: 'updatedByAgent',
          label: this.$t('src.modules.customer-support-history.index.updatedBy'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        {
          key: 'categoryPath',
          label: this.$t('src.modules.customer-support-history.index.category'),
          class: 'nat-table-col-category'
        },
        {
          key: 'title',
          label: this.$t('src.modules.customer-support-history.index.title'),
          class: 'nat-table-col-title'
        },
        {
          key: 'channelName',
          label: this.$t('src.modules.customer-support-history.index.channel_name'),
          class: 'nat-table-col-channel-name'
        },
        {
          key: 'action',
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action'
        }
      ],

      totalDataHeader: {
        index: {
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        ticketId: {
          label: this.$t('src.modules.customer-support-history.index.ticket-id'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        createdAt: {
          label: this.$t('src.modules.customer-support-history.index.created'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        updatedAt: {
          label: this.$t('src.modules.customer-support-history.index.updated'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        createdByAgent: {
          label: this.$t('src.modules.customer-support-history.index.createdBy'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        updatedByAgent: {
          label: this.$t('src.modules.customer-support-history.index.updatedBy'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        categoryPath: {
          label: this.$t('src.modules.customer-support-history.index.category'),
          class: 'nat-table-col-category'
        },
        userId: {
          label: this.$t('src.modules.search-management.index.user_id'),
          sortable: true,
          class: 'nat-table-col-customer-id'
        },
        userName: {
          label: this.$t('src.modules.search-management.index.user_name'),
          class: 'nat-table-col-customer-name'
        },
        title: {
          label: this.$t('src.modules.customer-support-history.index.title'),
          class: 'nat-table-col-title'
        },
        platform: {
          label: this.$t('src.modules.customer-support-history.index.platform'),
          class: 'nat-table-col-flatform'
        },
        channelName: {
          label: this.$t('src.modules.customer-support-history.index.channel_name'),
          class: 'nat-table-col-channel-name'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action'
        }
      },

      dateRange: null,
      dateFrom: null,
      dateTo: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },

      categoryTreeOptions: {
        store: {
          store: store,
          getter: () => {
            return store.getters['customerSupportHistory/tree'];
          },
          dispatcher(tree) {
            store.dispatch('customerSupportHistory/updateTree', tree);
          }
        },
        filter: {
          emptyText: this.$t('common.text.no_data'),
          matcher(query, node) {
            const queryTrim = query.trim();
            return node.text.toLowerCase().indexOf(queryTrim.toLowerCase()) >= 0;
          }
        }
      },
      isBusy: false,
      sortBy: 'updatedAt',
      sortDesc: true,
      eventObj: {},
      events: [],
      selected: null,
      customerDelteteItem: {},
      searchText: '',
      pageNumber: 1,
      showSupportModal: false,
      perPage: 5,
      projectSel: '',
      channelSel: '',
      platformSel: '',
      groupSel: '',
      templateSel: '',
      groupList: [],
      categoryTree: [],
      treeFilter: '',
      selectedConversation: null,
      conversationSubscriber: null,
      channelsFilter: [],
      showTicketHistoryModal: false,
      messageData: [],
      loadingMore: false,
      agentGroups: [],
      itemSelectedLoadMessages: {},
      labelWidth: 0,
      itemSelectedEditForm: {},
      standardizePlatformName: STANDARDIZE_PLATFORM_NAME
    };
  },

  computed: {
    ...mapState('customerSupportHistory', [
      'categories',
      'treeStore',
      'ticketsFilterMap',
      'templates',
      'templateIdMaps',
      'userFilterMap'
    ]),
    ...mapState('chat', ['messagesOfTicketId']),
    ...mapState('session', ['user', 'channelsMap', 'projectMaps']),
    ...mapGetters('customerSupportHistory', ['tree', 'customerSupportSearch']),
    ...mapGetters('session', ['groupMaps']),
    ...mapState('customerSupport', ['customerSupportData']),

    pageCount() {
      let l = this.customerSupportSearch.length,
        s = this.perPage;
      return Math.ceil(l / s);
    },

    totalRows() {
      return this.customerSupportSearch.length;
    },

    paginatedData() {
      if (this.customerSupportSearch.length > 0) {
        return this.customerSupportSearch;
      }
      return [];
    },

    messages() {
      const msg = this.messageData.slice(0);
      return addMessageStatus(msg, this.selectedConversation);
    },

    isAllowAllView() {
      if (this.projectSel === '') {
        return true;
      }
      const project = this.projectMaps[this.projectSel];
      return project && project.config?.accessPermission
        ? project.config.accessPermission.isAllowAllView
        : true;
    },

    projectList() {
      if (!this.projectMaps || typeof this.projectMaps !== 'object') return [];

      let list = Object.keys(this.projectMaps).map(key => ({
        value: key,
        text: this.projectMaps[key].name
      }));

      return this.sortProperties(list);
    },

    platformList() {
      let list = [];
      if (this.projectSel === '') return [];

      const channels = filter(this.channelsMap, {
        projectId: this.projectSel
      }).filter(
        ({ platform, configs }) => !(platform === 'instagram' && !configs.instagramMessenger)
      );
      if (channels.length < 1) return [];

      list = reduce(
        channels,
        (lst, c) =>
          lst.map(i => i.value).includes(c.platform)
            ? lst
            : [...lst, { value: c.platform, text: c.platform }],
        list
      );

      // Standardize platform name
      list = list.map(i => ({
        ...i,
        text: STANDARDIZE_PLATFORM_NAME[i.text.toLowerCase()] || i.text
      }));

      return this.sortProperties(list);
    },

    channelList() {
      let list = [];
      if (this.projectSel === '' || this.platformSel === '') return list;

      const channels = filter(this.channelsMap, {
        projectId: this.projectSel,
        platform: this.platformSel
      });
      if (channels.length < 1) return list;

      list = reduce(
        channels,
        (res, channel) => [...res, { value: channel.id, text: channel.name }],
        list
      );
      return this.sortProperties(list);
    },

    templateList() {
      if (this.projectSel === '' && this.groupSel === '') return [];
      let templates = {};
      if (this.groupSel !== '') {
        templates = this.templates[this.groupSel] || {};
      } else {
        templates = this.templates[this.projectSel] || {};
      }
      const list = Object.keys(templates).map(id => ({
        value: id,
        text: templates[id]
      }));
      return this.sortProperties(list);
    },

    treeFilterValue() {
      return this.treeFilter ? this.treeFilter.trim() : '';
    }
  },

  watch: {
    dateRange(newValue) {
      this.dateFrom = newValue ? moment(newValue[0]).format('YYYY-MM-DD') : null;
      this.dateTo = newValue ? moment(newValue[1]).format('YYYY-MM-DD') : null;

      const { event = {} } = this.eventObj;
      const { data = {}, name } = event;
      if (['node:selected', 'node:expanded', 'node:collapsed'].includes(name) && data.id) {
        this.handleTreeNodeAction(event);
      } else {
        this.getCustomerSupportHistoryByAllcategory(true);
      }
    },
    messagesOfTicketId(newvalue) {
      const { ticketId, conversationId: id } = this.itemSelectedLoadMessages || {};
      if (ticketId)
        this.messageData = newvalue[id] && newvalue[id][ticketId] ? newvalue[id][ticketId] : [];
    },

    projectList(data) {
      const obj = data;
      if (obj && obj.length > 0) {
        this.projectSel = obj[0].value;
      }
    },
    platformList() {
      this.platformSel = '';
    },
    channelList() {
      this.channelSel = '';
    },
    groupList(data) {
      if (data && data.length > 0) {
        this.groupSel = data[0].value;
      } else {
        this.groupSel = '';
      }
    },
    async templateList(data) {
      if (
        data &&
        data.length > 0 &&
        (this.templateSel === '' || !data.some(tp => tp.value === this.templateSel))
      ) {
        this.templateSel = data[0].value;
        await this.getCustomerSupportHistoryByAllcategory(true);
      }
    },

    async projectSel(value) {
      let list = [];
      if (value !== '') {
        const channelIds = reduce(
          this.channelsMap,
          (res, c) => (!res.includes(c.id) ? [...res, c.id] : res),
          []
        );
        this.subscribeUpdateConversation(channelIds);
        const groups = this.groupMaps[value] || [];
        if (groups.length < 1) this.groupList = list;
        else {
          // Filter role
          let groupsRole = groups;
          if (!this.isAllowAllView) {
            if ([AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR].includes(this.user.role)) {
              groupsRole = groups.filter(item => {
                const agentGroupsRole =
                  this.user.role === AGENT_ROLE.REGULAR ? item.agents || [] : item.moderators || [];
                const exits = agentGroupsRole.find(element => element === this.user.id);
                if (exits !== undefined) {
                  return item;
                }
              });
            }
          }

          list = reduce(
            groupsRole,
            (res, group) => [
              ...res,
              { value: group.id, text: group.name, isEnable: group.isEnable }
            ],
            list
          );
          this.groupList = this.sortProperties(list);
        }
      } else {
        this.groupList = list;
      }
    },
    templateSel(value) {
      // reset history data
      this.selected = null;
      this.resetHistoryGrid();
      if (value !== '') {
        this.updateTree([...this.categories[value]]);
      }

      const { event } = this.eventObj;
      this.handleTreeNodeAction(event);
    },
    platformSel() {
      const { event = {} } = this.eventObj;
      const { data = {}, name } = event;

      if (['node:selected', 'node:expanded', 'node:collapsed'].includes(name) && data.id) {
        this.handleTreeNodeAction(event);
      } else {
        this.getCustomerSupportHistoryByAllcategory(true);
      }
    },
    channelSel() {
      const { event = {} } = this.eventObj;
      const { data = {}, name } = event;

      if (['node:selected', 'node:expanded', 'node:collapsed'].includes(name) && data.id) {
        this.handleTreeNodeAction(event);
      } else {
        this.getCustomerSupportHistoryByAllcategory(true);
      }
    },

    treeStore() {
      const { event } = this.eventObj;
      this.handleTreeNodeAction(event);
    },

    async customerSupportData() {
      const { event } = this.eventObj;
      await this.handleTreeNodeAction(event, false);
    },

    projectMaps() {
      this.getCustomerSupportSettings().then(() => {
        if (this.projectMaps && Object.keys(this.projectMaps).length > 0) this.setGlobalReady(true);
      });
    }
  },

  async created() {
    this.setGlobalReady(false);
    const user = await this.getSessionUser();
    const language = localStorage.getItem('language');
    language === 'ja-JP' ? (this.labelWidth = 120) : (this.labelWidth = 95);
    if (!user) {
      this.setGlobalReady(true);
      return;
    }
    this.channelsFilter = await this.getChannels();
    await this.setChannelsFilterMap(this.channelsFilter);
    if (user.role === AGENT_ROLE.REGULAR || user.role === AGENT_ROLE.MODERATOR) {
      this.agentGroups = await this.getAgentGroups(user.id);
    }
    // reset history grid
    this.resetHistoryGrid();
  },

  async mounted() {
    this.setGlobalReady(false);
    eventsList.forEach(e => {
      this.$refs.tree.$on(e.name, this.initEventViewer(e));
    });
    await this.$apollo.provider.defaultClient.hydrated();
    await this.getCustomerSupportSettings();
    if (this.projectList.length > 0 && this.projectSel === '') {
      this.projectSel = this.projectList[0].value;
    }
    if (this.projectMaps && Object.keys(this.projectMaps).length > 0) this.setGlobalReady(true);
  },

  beforeDestroy() {
    EventBus.$off('hideBaseConfirmCsh');
  },

  methods: {
    ...mapActions('session', ['getSessionUser', 'logout', 'getChannels']),
    ...mapActions('global', ['setGlobalReady', 'pinRightPanel']),
    ...mapActions('customerSupportHistory', [
      'getCustomerSupportSettings',
      'updateTree',
      'getCustomerSupportHistoryByCategory',
      'setSearchText',
      'deleteCustomerSupports',
      'getConversationTicketById',
      'updateConversationById',
      'setChannelsFilterMap',
      'getAgentGroupsByAgentId',
      'resetHistoryGrid',
      'getActionClickCSH'
    ]),
    ...mapActions('customerSupport', ['getCustomerSupportById', 'setCSFMode']),
    ...mapActions('chat', [
      'getMessagesByTicketId',
      'loadMoreMessage',
      'loadMoreMessageOfTicketId'
    ]),

    handleEmitFinishFilterColumns(multipleSelection) {
      const selectedColumns = [];
      this.optionalColumns = multipleSelection;
      this.optionalColumns.forEach(column => {
        if (column.selected) {
          selectedColumns.push(column);
        }
      });
      const filterColumns = [...selectedColumns, ...this.mandatoryColumns];
      const newDataHeader = {};
      for (const column in this.totalDataHeader) {
        let tempArr = filterColumns;
        if (tempArr.filter(tmpColumn => tmpColumn.key === column).length > 0) {
          newDataHeader[column] = this.totalDataHeader[column];
        }
      }
      this.customerSupportHistoryHeader = newDataHeader;
    },

    handleBtnAddOptionalColumns() {
      EventBus.$emit('filterColumnsModal', true);
    },

    sortProperties(arrValue) {
      if (!arrValue || arrValue === null) return [];
      // sort items by value
      arrValue.sort(function (a, b) {
        let x = a.text.toLowerCase(),
          y = b.text.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    },

    handleSearchCustomer() {
      this.searchText = this.searchText ? this.searchText.trim() : '';
      this.setSearchText(this.searchText);
    },

    async handleSupportItemClick(item, mode) {
      const { userId, channelId } = item;
      await this.getActionClickCSH({ userId, channelId });
      await this.setCSFMode(mode);
      await this.getSelectedConversation(item);
      this.showSupportModal = this.selectedConversation !== null;
      if (mode === 'edit') this.itemSelectedEditForm = item;
    },

    async handleDeleteItemClick(item) {
      const { userId, channelId } = item;
      await this.getActionClickCSH({ userId, channelId });
      this.customerDelteteItem = item;
      await EventBus.$on('hideBaseConfirmCsh', () => {
        this.$baseConfirm.close();
      });
      this.$baseConfirm({
        message: this.$t(
          'src.modules.customer-support-history.index.do_you_want_to_delete_this_item'
        ),
        cancelButtonText: this.$t('common.confirmModal.cancel')
      })
        .then(() => {
          this.handleYes(item);
        })
        .catch(() => {
          this.getActionClickCSH({});
        });
    },

    async handleShowTicket(item) {
      const { userId, channelId } = item;
      await this.getActionClickCSH({ userId, channelId });
      await this.getSelectedConversation(item);
      const {
        ticket: { state }
      } = this.selectedConversation;
      const { projectId = '' } =
        (this.selectedConversation && this.selectedConversation.ticket) || {};
      this.itemSelectedLoadMessages = item;
      const { ticketId, conversationId: id } = item || {};
      await this.fetchMessagesByTicketId({ id, ticketId, state, projectId });

      let messages = [];
      if (
        this.messagesOfTicketId &&
        this.messagesOfTicketId[id] &&
        this.messagesOfTicketId[id][ticketId]
      )
        messages = this.messagesOfTicketId[id][ticketId];

      const messagesByTicketId = messages.filter(msg => msg.ticketId === ticketId);
      this.messageData = messagesByTicketId;
      this.$refs.ticketHistoryModal.show();
    },

    async fetchMessagesByTicketId({ id, ticketId, state, projectId }) {
      if (
        [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state) &&
        this.messagesOfTicketId[id] &&
        this.messagesOfTicketId[id][ticketId]
      ) {
        // exist messages of old ticket
      } else {
        await this.getMessagesByTicketId({ id, ticketId, projectId });
      }
    },

    async getSelectedConversation(item = {}) {
      // // Load Tickets + translations
      // If item not exist in state
      const { userId } = item;
      const pConversationTicketById = {};
      pConversationTicketById.conversationId = item.conversationId;
      pConversationTicketById.ticketId = item.ticketId;
      pConversationTicketById.channelId = item.channelId;

      // Checks userInfo for getTranslations
      if (!this.userFilterMap[userId]) pConversationTicketById.userId = item.userId;

      await this.getConversationTicketById(pConversationTicketById)
        .then(conv => {
          const { error } = conv;
          if (error) {
            this.selectedConversation = null;
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: error.message
            });
          } else {
            this.selectedConversation = conv;
            this.selectedConversation.historyEditItem = item;
          }
        })
        .catch(() => {
          this.selectedConversation = null;
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.customer-support-history.index.internal_error')
          });
        });
    },

    async hideSupportModal(b, type) {
      if (type && type === 'successToClosePopup') {
        const { event } = this.eventObj;
        await this.handleTreeNodeAction(event, false);
      }

      this.showSupportModal = false;
      this.getActionClickCSH({});
    },

    initEventViewer(event) {
      const eventObj = this.eventObj;
      return function (node) {
        eventObj.event = Object.assign({}, event, node);
      };
    },

    async handleTreeNodeAction(event, loading = true) {
      if (['node:selected', 'node:expanded', 'node:collapsed'].includes(event.name)) {
        const { id } = event.data;
        let groupId = this.groupSel;
        let agentId = null;
        if (
          this.user.role === AGENT_ROLE.REGULAR ||
          (this.user.role === AGENT_ROLE.MODERATOR && this.groupSel === '')
        ) {
          agentId = this.user.id;
        }

        loading ? this.setGlobalReady(false) : null;
        await this.getCustomerSupportHistoryByCategory({
          projectId: this.projectSel,
          platform: this.platformSel,
          channelId: this.channelSel,
          groupId: groupId,
          templateId: this.templateSel,
          categoryId: id,
          agentId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        });

        loading ? this.setGlobalReady(true) : null;
      }
    },

    async getCustomerSupportHistoryByAllcategory(loading = true) {
      loading ? this.setGlobalReady(false) : null;
      const user = this.user;
      let agentId = '';
      let groupId = this.groupSel;
      if (
        user.role === AGENT_ROLE.REGULAR ||
        (user.role === AGENT_ROLE.MODERATOR && this.groupSel === '')
      ) {
        agentId = user.id;
      }

      await this.getCustomerSupportHistoryByCategory({
        projectId: this.projectSel,
        platform: this.platformSel,
        channelId: this.channelSel,
        templateId: this.templateSel,
        groupId: groupId,
        agentId: agentId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      });
      loading ? this.setGlobalReady(true) : null;
    },

    nextPage() {
      this.pageNumber++;
    },

    prevPage() {
      this.pageNumber--;
    },

    async handleYes(item) {
      this.setGlobalReady(false);
      const params = { id: this.customerDelteteItem.id };
      const checkData = await this.getCustomerSupportById(params);
      let type = '';
      if (this.itemSelectedEditForm.id === item.id) {
        type = 'itemSelectDeleted';
        this.showSupportModal = false;
      }
      if (checkData.status !== 'submited') {
        this.deleteCustomerSupports(this.customerDelteteItem);
        EventBus.$emit('deleteFromHistory', type);
        this.setGlobalReady(true);
      } else {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'common.CustomerSupport.the_request_was_submitted_you_can_not_change_or_delete_it'
          )
        });
      }
      this.customerDelteteItem = {};
    },

    handleNo(e) {
      e.preventDefault();
    },

    getFullChild() {
      const fullChild = [];
      this.selected[0].recurseDown(p => {
        if (p.data.id) {
          fullChild.push(p.data.id);
        }
      });
      return fullChild;
    },

    subscribeUpdateConversation(channelIds) {
      const _this = this;
      if (this.conversationSubscriber) return;
      _this.conversationSubscriber = _this.$apollo.subscribe({
        query: SubscribeUpdateConversation
      });
      _this.conversationSubscriber.subscribe({
        next({ data }) {
          const conv = data && data.subscribeUpdateConversation;
          if (conv && channelIds.includes(conv.channelId)) _this.updateConversationById(conv);
        },
        error(error) {
          _this.handleSubscribeConversationError(error);
        }
      });
    },

    async handleScroll(evt) {
      if (evt.target.id === 'appCardChat') {
        if (evt.target.scrollTop === 0) {
          const { ticketId, conversationId: id, projectId } = this.itemSelectedLoadMessages || {};
          const messageOfTicket =
            this.messagesOfTicketId[id] && this.messagesOfTicketId[id][ticketId]
              ? this.messagesOfTicketId[id][ticketId]
              : [];
          if (messageOfTicket === 0) return;
          const [msg] = messageOfTicket;
          await this._loadMoreMessage({
            ticketId,
            conversationId: id,
            projectId,
            nextKey: pick(msg, ['sk', 'conversationId'])
          });
        }
      }
    },

    scrollToBottomAppCardChat() {
      this.$nextTick(() => {
        if (!this.$refs.appCardChat) return;
        const { scrollHeight } = this.$refs.appCardChat;
        this.$refs.appCardChat.scrollTop = scrollHeight;
      });
    },

    async _loadMoreMessage({ ticketId, conversationId, projectId, nextKey }) {
      this.loadingMore = true;
      try {
        await this.loadMoreMessageOfTicketId({
          ticketId,
          conversationId,
          projectId,
          nextKey
        });
      } catch (e) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: `${this.$t(
            'src.modules.customer-support-history.index.could_not_load_more_message'
          )}, ${e.message}`
        });
      }
      this.loadingMore = false;
    },

    onCancel() {
      this.showTicketHistoryModal = false;
      this.getActionClickCSH({});
    },

    async getAgentGroups(agentId) {
      let groups = [];
      if (!agentId) {
        return groups;
      }
      await this.getAgentGroupsByAgentId({
        agentId: agentId
      })
        .then(conv => {
          const { error } = conv;
          if (error) {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: error.message
            });
          } else {
            for (let o in conv) {
              groups.push(conv[o].groupId);
            }
          }
          return true;
        })
        .catch(() => {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.customer-support-history.index.internal_error')
          });
        });
      return groups;
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-main-ctnr {
  height: 100%;
  font-size: 14px !important;
  overflow-y: auto !important;
  .nat-sub-ctnr {
    overflow: auto;
    padding-left: 65px;
    padding-top: 30px;
    .nat-cover-ctnr {
      margin: 0 50px 50px 65px;
      .nat-title-wrap {
        display: block;
      }
      .nat-content-wrap {
        z-index: 1;
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 0px;
        .nat-condition-bar {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: 0px;

          .nat-condition-item {
            display: block;
            padding-right: 10px;
            /deep/ label {
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
        .nat-grid-result {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: 0px;
          .nat-condition-row {
            margin-top: -16px;
            .nat-condition-item {
              display: block;
              padding-right: 10px;
            }
            .nat-condition-item-search {
              display: block;
              padding-top: 15px;
              padding-right: 0px;
            }
            .nat-condition-item-table {
              padding-top: 3px;
            }
          }
        }
      }
    }
  }
}

.tree-content-wrap {
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  .card-body {
    width: fit-content;
  }
}
.table-content-wrap {
  z-index: 1;
  max-height: 500px;
  overflow-y: auto;
  /deep/.table {
    min-width: 1500px;
  }
}
.content-wrap {
  z-index: 1;
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.customer-support-history {
  font-size: 14px !important;
  overflow-y: auto !important;
}
.section-profile-cover {
  height: 100px !important;
}

.page-header {
  text-align: center;
}

.page-cover-container {
  margin: 0 50px 50px 50px;
}

.search-icon {
  width: 30px !important;
  min-width: 30px !important;
}

.table-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bt-paging {
  bottom: 20px;
}

.navigation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

body {
  overflow-y: auto !important;
  font-size: 12px !important;
}

tbody td {
  width: 12.5%;
  float: left;
  overflow-wrap: break-word;
}
tbody {
  overflow-wrap: auto;
}

thead th {
  width: 12.5%;
  float: left;
  height: 70px;
}

.card-body {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

.tree {
  overflow: unset !important;

  /deep/.tree-anchor {
    color: #525f7f !important;
  }

  /deep/.tree-node.selected > .tree-content {
    background-color: #c9daef;
  }
}

/deep/.customer-support-history .input-group-text {
  min-width: 100px;
}
/deep/.customer-support-history .form-control .custom-select {
  padding-left: 10px !important;
}
/deep/.customer-support-history .input-group .form-control:not(:first-child) {
  padding-left: 10px !important;
}

/deep/ .nat-table-col-created {
  text-align: center;
  width: 165px;
}
/deep/ .nat-table-col-updated {
  text-align: center;
  width: 165px;
}

.custom-date {
  .el-date-editor {
    border: 1px solid #cad1d7 !important;

    /deep/.el-range-input {
      color: #8898aa !important;
      font-size: 0.875rem !important;
    }
  }
}
</style>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';
.message-list-card {
  height: 500px !important;
  overflow: auto !important;
  padding: 0 30px !important;
}

.message-list-card .chats {
  margin-top: 15px !important;
}

.message-list-card .chat.chat-right {
  margin-left: 100px !important;
}
.message-list-card .chat-avatar .avatar {
  width: 50px !important;
  margin-top: -5px !important;
}

.nat-table {
  tbody {
    display: block !important;
    overflow: auto !important;
    background: white;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* even columns width , fix width of table too*/
  }
}

.nat-table-header {
  background-color: #2196f3;
  color: #ffffff;
  text-align: center !important;
  .nat-table-col-no {
    text-align: center !important;
  }
  .nat-table-col-action {
    text-align: center !important;
  }
}
.nat-table-col-no {
  text-align: center;
  width: 61px;
}

.nat-table-col-category {
  text-align: center;
  width: 150px;
}
.nat-table-col-action {
  width: 100px;
  text-align: center !important;
}
.nat-table-col-customer-id {
  width: 350px;
  text-align: center;
}
.nat-table-col-customer-name {
  width: 350px;
  text-align: center;
}
.nat-table-col-title {
  width: 200px;
  text-align: center;
}
.nat-table-col-flatform {
  width: 140px;
  text-align: center;
}
.nat-table-col-channel-name {
  width: 150px;
  text-align: center;
}
.btnHistory button {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.pageHistory {
  overflow-y: initial !important;
}
.nat-cell-text {
  overflow-wrap: break-word;
}
</style>
