<template>
  <div class="app-message-input">
    <drop class="drop position-relative" @drop="handleDrop">
      <div :style="msgPreviewStyle" class="msg-preview shadow-sm">
        <span class="badge badge-info badge-pill badge-lg product-count">
          {{ this.products.length }}
        </span>
        <msg-product-preview :products="products" @onRemoveItem="handleOnRemoveItem" />
      </div>
      <!-- Messsage Input (S) -->
      <div class="msg-input-group-nat">
        <div class="msg-action-nat">
          <!-- Send a file (S) -->
          <div v-if="allowDisplaySendFile" class="btn-action left">
            <b-tooltip
              v-if="tooltipBtnAttach"
              target="dropdown-attach"
              :delay="100"
              boundary="viewport"
              placement="top"
              triggers="hover"
            >
              {{ $t('src.modules.chat.components.ChatBox.message-input.send_a_file') }}
            </b-tooltip>
            <b-dropdown
              id="dropdown-attach"
              class="type-dropdown-attach"
              no-caret
              dropup
              @shown="tooltipBtnAttach = false"
              @hidden="tooltipBtnAttach = true"
            >
              <template v-slot:button-content>
                <i class="fas fa-paperclip" />
              </template>
              <b-dropdown-item @click="openFile">
                {{ $t('src.modules.chat.components.ChatBox.message-input.your_computer') }}
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.fileManager>
                {{ $t('src.modules.chat.components.ChatBox.message-input.your_project_folder') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            v-else
            class="btn-action left"
            v-b-tooltip.hover
            :title="$t('src.modules.chat.components.ChatBox.message-input.send_a_file')"
          >
            <button
              data-testid="btn-chat-attach"
              class="btn btn-icon btn-2 btn-sm"
              type="button"
              v-b-modal.fileManager
            >
              <span class="btn-inner--icon">
                <i class="fa fa-paperclip" />
              </span>
            </button>
          </div>

          <!-- Send a file (E) -->

          <!-- Chat Assistant (S) -->
          <div
            class="btn-action left"
            v-if="permission && (permission.faq || permission.agentScenario)"
            v-b-tooltip.hover
            :title="$t('src.modules.chat.components.AsideRight.index.conversation_feeds')"
          >
            <button
              data-testid="btn-chat-assitant"
              :class="['btn btn-icon btn-2 btn-sm', showConversationalPanel ? 'active' : '']"
              type="button"
              @click="$emit('onConversationalItemClick')"
            >
              <span class="btn-inner--icon">
                <i class="fa fa-comments" />
              </span>
            </button>
          </div>
          <!-- Chat Assistant (E) -->

          <!-- Product Catalog (S) -->
          <div
            class="btn-action left"
            v-if="permission && permission.facebookProductCatalog"
            v-b-tooltip.hover
            :title="$t('src.modules.chat.components.AsideRight.index.product_feeds')"
          >
            <button
              data-testid="btn-product-feeds"
              :class="['btn btn-icon btn-2 btn-sm', showProductPanel ? 'active' : '']"
              type="button"
              @click="$emit('onProductItemClick')"
            >
              <span class="btn-inner--icon">
                <i class="fa fa-shopping-bag" />
              </span>
            </button>
          </div>
          <!-- Product Catalog (E) -->

          <!-- Proofreading (S) -->
          <div
            class="btn-action left"
            v-if="isProofreadingEnable"
            v-b-tooltip.hover
            :title="$t('src.modules.chat.components.ChatBox.message-input.proofreading')"
          >
            <button
              :disabled="!proofreading_number"
              :class="['btn btn-icon btn-2 btn-sm', proofreading ? 'active' : '']"
              type="button"
              style="padding: 2px"
              @click="proofreading = !proofreading"
            >
              <el-badge :value="proofreading_number" :hidden="!proofreading_number" class="item">
                <span class="btn-inner--icon">
                  <i class="material-icons" style="font-size: 1.5rem">spellcheck</i>
                </span>
              </el-badge>
            </button>
          </div>
          <!-- Proofreading (E) -->

          <!-- Send Msg (S)-->
          <div class="btn-action right">
            <button
              data-testid="sendMsg"
              v-b-tooltip.hover
              :title="$t('common.text.send')"
              class="btn btn-outline btn-fea shadow-none send-msg"
              type="button "
              @click="emitMessage"
            >
              <i class="fas fa-paper-plane" style="transform: rotate(60deg)" />
              <span>{{ $t('common.text.send') }}</span>
            </button>
          </div>
          <!-- Send Msg (E)-->
        </div>
        <div style="clear: both"></div>
        <div
          :class="this.products.length ? 'msg-input-nat msg-input-nat-disable' : 'msg-input-nat'"
          :style="{
            height: `calc(52px + (${messageInputHeight - initialMessageInputHeight.height}px))`
          }"
        >
          <!-- Icon (S) -->
          <div class="icon-avatar">
            <a
              :data-img="avatar"
              class="avatar shadow"
              data-toggle="tooltip"
              data-placement="right"
              title
              data-original-title
              @click.prevent.stop
            >
              <img :src="avatar" :alt="userName" @error="imageLoadError" />
            </a>
          </div>

          <!-- Icon (E) -->

          <!-- Input Msg (S)-->
          <div class="input-text">
            <vue-tribute :options="tributeOptions" :key="tributeKeys">
              <textarea
                v-model="message"
                data-testid="chat-area"
                ref="messageInput"
                :placeholder="
                  $t('src.modules.chat.components.ChatBox.message-input.type_message_here_')
                "
                class="form-control form-control-alternative"
                :style="{
                  ...messageInputStyle,
                  resize: 'none',
                  maxHeight: '108px',
                  minHeight: '40px'
                }"
                @keypress.enter.exact="handleEnter"
                @keydown.enter.shift.exact="handleShiftEnter"
                @keyup="throttled"
                :disabled="this.products.length ? true : false"
              />
            </vue-tribute>
          </div>
          <!-- Input Msg (E)-->
        </div>
        <div ref="refProofReading" class="proofreading-t-class" v-show="proofreading">
          <!-- Icon (S) -->

          <div class="icon-avatar">
            <i class="material-icons" style="margin-top: 10px; font-size: 3.5rem">spellcheck</i>
          </div>
          <div class="proofreading-t-body">
            <div class="overflow-css">
              <div v-for="(m, k) in proofreading_messages" :key="k">
                <div>
                  <p class="proofreading-position">
                    {{
                      $t(
                        'src.modules.chat.components.ChatBox.message-input.proofreading-popover-body.line'
                      )
                    }}
                    {{ m.line }}
                    {{
                      $t(
                        'src.modules.chat.components.ChatBox.message-input.proofreading-popover-body.character'
                      )
                    }}
                    {{ m.column }}:
                    <span class="proofreading-description">{{ m.message }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="close-proofreading">
            <i
              class="fa fa-times float-right"
              @click="proofreading = !proofreading"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>
      <!-- Messsage Input (E) -->
      <input id="fileInput" hidden type="file" multiple @change="fileUpload" />
    </drop>
    <FileManager :project-id="projectId" @onSendFileManager="onSendFileManager" />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MsgProductPreview from './MsgProductPreview';
import { FILE_MANAGEMENT_DEFAULT, PLATFORM } from 'core/constants';
import { IMAGE_EXTENSIONS_SUPPORTED } from 'core/constants';
import { getURLLstFrmTxt } from 'core/helpers';
import { EventBus } from 'core/eventBus';
import FileManager from './file-manager';
import fetchAPI from 'core/fetchAPI';
import noAvatar from 'assets/images/no-avatar.png';
import { orderBy } from 'lodash';
import throttle from 'lodash/throttle';
import VueTribute from 'vue-tribute';
import Params from 'modules/chat/helpers/params';

export default {
  components: {
    MsgProductPreview,
    FileManager,
    VueTribute
  },

  props: {
    products: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      initialMessageInputHeight: { inital: false, height: '0' },
      messageInputHeight: '0',
      proofReadingHeight: '0',
      permission: {},
      imageUrl: noAvatar,
      userName: '',
      tooltipBtnAttach: true,
      proofreading: false,
      proofreading_number: 0,
      proofreading_messages: [],
      message: '',
      showExtra: false,
      translateData: {},
      apiUrl: process.env.VUE_APP_WEBCHAT_API_URL,
      linkPreviews: [],
      tributeOptions: {
        values: [],
        selectTemplate: item => this.selectTemplate(item)
      },
      tributeKeys: 0,
      throttled: throttle(this.agentTyping, 5000, { leading: true, trailing: false }),
      typeOfNotify: ''
    };
  },

  computed: {
    ...mapState('session', ['user', 'people', 'channelsMap', 'projectMaps']),
    ...mapState('chat', [
      'curAgentChatMessage',
      'butSendClick',
      'selectedConversation',
      'showConversationalPanel',
      'showProductPanel'
    ]),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', ['actionSettings']),

    messageInputStyle() {
      return { height: this.messageInputHeight + 'px' };
    },

    avatar() {
      return this.imageUrl || noAvatar;
    },

    slideInClass() {
      return this.showExtra ? 'slide-in' : '';
    },

    btnShowExtraIcon() {
      return this.showExtra ? 'rotate-180' : '';
    },

    allowDisplaySendFile() {
      const { projectId = '' } =
        (this.selectedConversation && this.selectedConversation.ticket) || {};
      const { config = {} } = this.projectMaps[projectId] || {};
      const { fileManagement = {} } = config;
      const { isSendFileFromComputer = true } = fileManagement;

      return isSendFileFromComputer;
    },

    msgPreviewStyle() {
      return this.products.length > 0
        ? {
            height: '160px',
            top: '-160px',
            display: 'block'
          }
        : { display: 'none' };
    },

    isProofreadingEnable() {
      return (
        (this.actionSettings &&
          this.actionSettings.PROOFREADING &&
          this.actionSettings.PROOFREADING.CONFIRM_ENABLE) ||
        false
      );
    },

    isSendMessageWithShiftEnter() {
      return (
        (this.actionSettings &&
          this.actionSettings.SHIFT_ENTER &&
          this.actionSettings.SHIFT_ENTER.CONFIRM_ENABLE) ||
        false
      );
    }
  },
  watch: {
    butSendClick() {
      this.message = this.curAgentChatMessage;
    },

    message() {
      this.resizeMessageInput();
      this.checkProofreading(this.message);
    },

    products(newVal) {
      if (newVal.length) this.message = '';
    },

    proofreading() {
      this.resizeWithTimeout();
    },

    proofreading_number() {
      this.resizeWithTimeout();
    }
  },
  async created() {
    const { channelId } = this.selectedConversation;
    const { projectId = '' } =
      (this.selectedConversation && this.selectedConversation.ticket) || {};
    const { config = {} } = this.projectMaps[projectId] || {};
    const { agentScenario = {} } = config;
    const { enabled = true } = agentScenario;

    if (channelId) {
      const channel = this.channelsMap[channelId];
      const { advancedConfigs = {} } = channel;
      const { modulesActivation = {} } = advancedConfigs;
      this.permission = { ...modulesActivation, agentScenario: enabled };
    }

    if (this.user.pictureUrl) this.imageUrl = this.user.pictureUrl;
    if (this.user.name) this.userName = this.user.name;
    const params = {
      userId: this.selectedConversation.userId,
      channelId: this.selectedConversation.channelId,
      projectId: this.selectedConversation.projectId,
      platform: this.selectedConversation.platform
    };
    await this.pushPersonalDataToPeople(params);
  },
  async mounted() {
    EventBus.$on('isSendMessageWithConfirm', value => {
      if (value) this.message = ''; 
    });
    this.resizeMessageInput();

    const { channelId } = this.selectedConversation;
    const { projectId = '' } =
      (this.selectedConversation && this.selectedConversation.ticket) || {};
    const { config = {} } = this.projectMaps[projectId] || {};
    const { agentScenario = {} } = config;
    const { enabled = true } = agentScenario;

    if (channelId) {
      const channel = this.channelsMap[channelId];
      const { advancedConfigs = {} } = channel;
      const { modulesActivation = {} } = advancedConfigs;
      this.permission = { ...modulesActivation, agentScenario: enabled };
    }

    if (this.user.pictureUrl) this.imageUrl = this.user.pictureUrl;
    this.tributeConfig();
  },

  destroyed() {
    EventBus.$off('isSendMessageWithConfirm');
  },

  methods: {
    ...mapActions('session', ['addAgentToPeople', 'pushPersonalDataToPeople']),
    ...mapActions('personal', ['getPersonalSetting', 'getPersonalInfo']),
    ...mapActions('chat', ['setAgentChatMessage', 'sendAgentTyping']),

    resizeMessageInput() {
      if (!this.message && this.initialMessageInputHeight.inital) {
        this.messageInputHeight = this.initialMessageInputHeight.height;
        return this.$emit('resizeChatBox', {
          initialMessageInputHeight: this.initialMessageInputHeight.height,
          messageInputHeight: this.messageInputHeight,
          proofReadingHeight: this.$refs.refProofReading.clientHeight
        });
      }

      if (this.$refs.messageInput.scrollHeight > 108) return null;
      this.messageInputHeight = this.$refs.messageInput.scrollHeight;

      if (!this.initialMessageInputHeight.inital) {
        this.initialMessageInputHeight = {
          inital: true,
          height: this.$refs.messageInput.scrollHeight
        };
      }

      this.$emit('resizeChatBox', {
        initialMessageInputHeight: this.initialMessageInputHeight.height,
        messageInputHeight: this.messageInputHeight,
        proofReadingHeight: this.$refs.refProofReading.clientHeight
      });
    },

    resizeWithTimeout() {
      setTimeout(() => {
        this.proofReadingHeight = this.$refs.refProofReading.clientHeight;
        this.$emit('resizeChatBox', {
          initialMessageInputHeight: this.initialMessageInputHeight.height,
          messageInputHeight: this.messageInputHeight,
          proofReadingHeight: this.proofReadingHeight
        });
      }, 100);
    },

    checkProofreading(message) {
      if (!this.isProofreadingEnable) return;
      this.textLint
        .lintText(message)
        .then(result => {
          // eslint-disable-next-line
          console.log('checkProofreading result', result);
          const { messages = [] } = result || {};
          this.proofreading_number = messages.length;
          this.proofreading_messages = messages;
          if (!this.proofreading_number) this.proofreading = false;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.log('checkProofreading error', e);
        });
    },
    imageLoadError() {
      // eslint-disable-next-line
      console.log('Show image error: ', this.avatar);
      this.imageError = true;
    },

    openFile() {
      const fileInput = document.getElementById('fileInput');
      fileInput.value = '';
      fileInput.click();
    },

    fileUpload(event, sendWithConfirm = false) {
      if (
        this.actionSettings &&
        this.actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
        !sendWithConfirm
      ) {
        return this.$baseConfirm({
          message: this.$t(
            'src.modules.chat.store.actions.are_you_sure_to_send_this_message_to_user'
          )
        })
          .then(() => {
            this.fileUpload(event, (sendWithConfirm = true));
          })
          .catch(() => {});
      }
      this.$emit('onLoadingFileUpload');
      let { files } = event && event.target;
      if (files && files.length > 0) {
        this.$emit('onLoadingFileUpload', files.length);
        files = [...files];
        const fileInput = document.getElementById('fileInput');
        fileInput.value = '';
        const { ticket = {} } = this.selectedConversation || {};
        const { projectId = '', platform = '' } = ticket;
        const { config = {} } = this.projectMaps[projectId] || {};
        const fileManagement = config.fileManagement || FILE_MANAGEMENT_DEFAULT;
        const { blockedExtensions = [] } = fileManagement;
        const getExtension = f => f.name.split('.').pop();

        // Check file extension and size
        let errors = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          // Check extensions
          if (blockedExtensions.length) {
            const ext = getExtension(file);
            if (
              blockedExtensions.includes(ext.toLowerCase()) ||
              blockedExtensions.includes('.' + ext.toLowerCase())
            ) {
              errors.push(
                `[${file.name}] ${this.$t('src.modules.file-management.index.invalid_extension')}`
              );
              break;
            }
          }

          // Check filesize
          const typeOfFile = this.typeOfFile(file);
          if (typeOfFile.type === 'File' && PLATFORM.INSTAGRAM === platform) {
            const message = this.$t(
              'src.modules.chat.store.actions.the_platform_does_not_support_this_format'
            );
            this.typeOfNotify = 'error';
            errors.push(message);
            break;
          }

          if (file.size > fileManagement[typeOfFile.uploadSizeOf] * 1024 * 1024) {
            const message = `[${file.name}] ${this.$t(
              'src.modules.file-management.index.error.size_exceeds_maximum_allowable_size'
            )} (${fileManagement[typeOfFile.uploadSizeOf]}MB).`;
            errors.push(message);
            break;
          }
        }

        if (errors.length) {
          if (this.typeOfNotify === 'error') {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: errors.join('<br/>')
            });
          } else {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: errors.join('<br/>')
            });
          }
          return this.$emit('onLoadingFileUpload', 0);
        }

        this.$emit('onFileUpload', files);
      }
    },

    convertToByte(mb) {
      return mb * 1024 * 1024;
    },

    typeOfFile(file) {
      let typeOfFile = {
        type: 'File',
        uploadSizeOf: 'fileUploadSize'
      };
      if (file) {
        if (file.type.startsWith('image')) {
          const extensionsSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
            file.name.toLowerCase().endsWith(ex)
          );
          if (extensionsSupported) {
            typeOfFile.type = 'Image';
            typeOfFile.uploadSizeOf = 'imageUploadSize';
          }
        } else if (
          file.type === 'audio/mp3' ||
          file.type === 'audio/mpeg' ||
          file.type === 'audio/ogg' ||
          file.type === 'audio/wav' ||
          file.type === 'audio/flac'
        ) {
          typeOfFile.uploadSizeOf = 'audioUploadSize';
        } else if (
          file.type === 'video/mp4' ||
          file.type === 'video/ogg' ||
          file.type === 'video/webm'
        ) {
          typeOfFile.uploadSizeOf = 'videoUploadSize';
        }
      }
      return typeOfFile;
    },

    errorUploadFileOverLimit(file, typeOfFile, fileManagement) {
      this.$emit('onLoadingFileUpload', 0);
      this.$baseNotification.error({
        title: this.$t('src.core.App.error'),
        message: `[${file.name}]: ${typeOfFile.type} ${this.$t(
          'src.modules.chat.components.ChatBox.message-input.size_exceeds_maximum_allowable_size'
        )} (${fileManagement[typeOfFile.uploadSizeOf]}MB).`
      });
    },

    newline() {
      this.message = `${this.message}`;
    },

    agentTyping() {
      const message = this.message.trim();
      const isTyping = !!message;
      const { ticketId = '' } = this.selectedConversation || {};
      this.sendAgentTyping({ ticketId, isTyping });
    },

    async emitMessage(options) {
      options.preventDefault();
      this.linkPreviews = [];
      const { urls, isURLOnly } = await getURLLstFrmTxt(this.message);
      if (urls.length > 0) {
        const promises = urls.slice(0, 2).map(url => this.getLinkPreview(url));
        const results = await Promise.all(promises.map(p => p.catch(e => e)));
        const validResults = results.filter(result => result && !(result instanceof Error));

        if (validResults.length > 0) {
          this.linkPreviews = validResults;
        }
      }
      this.$emit('onSend', this.message, {
        ...options,
        products: this.products,
        objLinkPreviews: { linkPreviews: this.linkPreviews, isURLOnly }
      });
      if (this.actionSettings && !this.actionSettings.CONFIRMATION.CONFIRM_ENABLE) {
        this.message = '';
      }
      if (this.products.length > 0) {
        this.$emit('productChange', { type: 'REMOVE_ALL' });
      }
    },

    showExtraOptions() {
      this.showExtra = !this.showExtra;
    },

    handleOnRemoveItem(id) {
      this.$emit('productChange', { type: 'REMOVE_ITEM', id });
    },

    handleDrop(data) {
      const { answer, defaultAnswer } = data || {};
      if (answer) {
        this.setAgentChatMessage(answer);
      } else if (defaultAnswer) {
        this.setAgentChatMessage(defaultAnswer);
      }
    },

    onSendFileManager(node, sendWithConfirm = false) {
      if (
        this.actionSettings &&
        this.actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
        !sendWithConfirm
      ) {
        return this.$baseConfirm({
          message: this.$t(
            'src.modules.chat.store.actions.are_you_sure_to_send_this_message_to_user'
          )
        })
          .then(() => {
            this.onSendFileManager(node, (sendWithConfirm = true));
          })
          .catch(() => {});
      }
      const options = {
        text: node.data.text,
        msgType: node.data.file_type
      };

      const { ticket: { platform = '' } = {} } = this.selectedConversation || {};
      const { file_type = '', payload } = node.data;
      if (PLATFORM.INSTAGRAM === platform && ['file', 'audio', 'video'].includes(file_type)) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.chat.store.actions.the_platform_does_not_support_this_format'
          )
        });
        return;
      }

      if (file_type === 'video') {
        options.payload = payload;
      }

      this.$emit('onSend', node.data.link, options);
    },

    getAgentParamsFromText(text) {
      const words = text.split(' ');
      const displayAgentHasTrue = this.getAgentParamsChannelId();
      const agentParams = words.filter(w => {
        return w.startsWith('@') && displayAgentHasTrue.hasOwnProperty(w.substr(1));
      });

      return { agentParams };
    },

    async getLinkPreview(url) {
      if (this.isValidUrl(url)) {
        const resLinkReview = await this.request(url);
        return resLinkReview;
      }
      return false;
    },
    isValidUrl(url) {
      // eslint-disable-next-line
      const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm; // eslint-disable-line
      return url.indexOf('https') === 0 && regex.test(url);
    },
    async request(url) {
      const fetch = new fetchAPI({
        baseURL: this.apiUrl,
        headers: {
          'x-api-key': process.env.VUE_APP_WEBCHAT_API_KEY
        }
      });
      return fetch
        .get('/og', {
          url: url
        })
        .then(res => {
          return res.data;
        });
    },
    getAgentParamsChannelId() {
      return new Params().getAgentParams();
    },

    tributeConfig() {
      this.tributeOptions.values = [];
      let displayAgentHasTrue = this.getAgentParamsChannelId();
      let displayAgentKeys = Object.keys(displayAgentHasTrue).map(item => {
        return {
          value: item,
          key: '{' + item + '}'
        };
      });

      // Use Lodash to sort array by 'key'
      let displayAgentKeysSorted = orderBy(displayAgentKeys, ['key'], ['asc']);
      displayAgentKeysSorted.map(item => {
        this.tributeOptions.values.push(item);
        this.tributeKeys += 1;
      });
    },

    selectTemplate(item) {
      const { original } = item;
      const { value } = original;
      return new Params().getValueFromKey(value);
    },

    handleEnter(event) {
      return this.isSendMessageWithShiftEnter ? this.newline() : this.emitMessage(event);
    },

    handleShiftEnter(event) {
      return this.isSendMessageWithShiftEnter ? this.emitMessage(event) : this.newline();
    }
  }
};
</script>

<style lang="scss" scoped>
.msg-input-nat-disable {
  background-color: rgba(233, 236, 239, 0.6) !important;
  cursor: not-allowed;

  textarea {
    cursor: not-allowed;
  }
}

.msg-preview {
  position: absolute;
  height: 100px;
  width: 100%;
  z-index: 999;
  background-image: linear-gradient(to top, #e6e6e6, transparent) !important;

  .product-count {
    position: absolute;
    top: -15px;
    left: -10px;
    z-index: 99;
  }
}

.drop-highlight {
  border: dashed 1px #017eba !important;
}

.btn-show-extra {
  i {
    transition: all 250ms ease;
    -webkit-transition: all 250ms ease;
    &.rotate-180 {
      transform: rotate(180deg);
      transition: all 250ms ease;
      -webkit-transition: all 250ms ease;
    }
  }
}

.btn-group-extra {
  position: absolute;
  top: 0;
  right: -55px;
  z-index: -1;

  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;

  &.slide-in {
    position: relative;
    z-index: 1 !important;
    right: 0 !important;
  }

  .btn {
    box-shadow: none;
    height: 100%;
    border-radius: 0 !important;
  }
}

.msg-input-group-nat {
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  .msg-action-nat {
    height: 45px;
    background: rgba(233, 236, 239, 0.6);
    border-radius: calc(0.25rem - 0.0625rem) calc(0.25rem - 0.0625rem) 0 0;
    margin-bottom: 0;
    border-bottom: 0.06rem solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    .btn-action {
      padding: 5px;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
      .send-msg {
        border-radius: 1.25rem;
        background-color: #adb5bd;
        line-height: 12px;
        text-transform: capitalize;
      }
      .btn-icon {
        line-height: 22px;
        width: 40px;
        background-color: transparent;
      }

      .active {
        line-height: 22px !important;
        width: 40px !important;
        background-color: #2196f3 !important;
        color: #fff !important;
      }
    }

    .btn {
      &:active,
      &:focus {
        box-shadow: none;
      }
    }

    .btn-action:hover {
      .btn-icon {
        line-height: 22px;
        width: 40px;
        background-color: #ced4da;
      }
      /deep/.type-dropdown-attach {
        .dropdown-toggle {
          line-height: 16px;
          width: 40px;
          background-color: #ced4da;
          box-shadow: none;
          border: 0px solid;
          border-radius: 0.25rem;
        }
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        background-color: #ced4da;
        border-radius: 0.25rem;
      }
    }
  }
  .msg-input-nat {
    position: relative;
    display: flex;
    padding-top: 5px;
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    .icon-avatar {
      display: block;
      padding: 3px;
      .avatar {
        height: 40px;
        width: 40px;
        border: 1px solid #2dce89;
      }
    }
    .input-text {
      display: block;
      width: 100%;
      padding-right: 15px;
      padding-left: 5px;
      .form-control {
        width: 100%;
        resize: none;
        box-shadow: none;
        background-color: transparent;
        border-bottom: 3px solid #2dce89;
        border-radius: 0rem;
      }
    }
  }

  /deep/.type-dropdown-attach {
    &.show {
      .btn {
        &.dropdown-toggle {
          background-color: #ced4da;
          color: #212529;
          border-radius: 0.25rem;
        }
      }
    }
    .dropdown-toggle {
      line-height: 16px;
      width: 40px;
      background-color: transparent;
      box-shadow: none;
      border: 0px solid;
    }
    .btn-secondary {
      color: #212529;
    }
  }
}

.proofreading-position {
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
}

.proofreading-t-class {
  display: flex;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .proofreading-t-body {
    overflow: hidden;
    display: block;
    width: 100%;
    padding-right: 15px;
    padding-left: 5px;

    .overflow-css {
      overflow: auto;
      max-height: 150px;
      padding: 0 0.75rem;
      padding-right: 25px;
    }
  }

  .close-proofreading {
    position: absolute;
    background-color: white;
    margin-top: 10px;
    right: 30px;
  }

  .proofreading-description {
    font-size: 0.875rem;
    color: #8898aa;
  }
}
</style>

<style lang="scss">
.type-dropdown-attach {
  height: 100%;

  > .btn.dropdown-toggle {
    color: #212529;
    height: 100%;
    background-color: #f8f8f8;
    border: none;

    &:active,
    &:focus {
      color: #212529 !important;
    }
  }

  &.show {
    > .btn.dropdown-toggle {
      color: #212529;
      background-color: #f8f8f8;
    }

    > .dropdown-menu.show {
      -webkit-box-shadow: none;
      box-shadow: none;
      top: -5px !important;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}

.tribute-container {
  position: absolute;
  height: auto;
  width: 190px;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  bottom: 80px !important;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: rgba(233, 236, 239, 1);
  }

  li {
    padding: 5px 5px;
    cursor: pointer;

    &.highlight {
      background: #2196f3;
    }

    span {
      font-weight: bold;
    }

    &.no-match {
      cursor: default;
    }
  }

  .menu-highlighted {
    font-weight: bold;
  }
}
</style>
