import Service from 'core/service';
import { SET_FILES, REMOVE_FILE_LOCAL, ADD_FILE_LOCAL, SET_LOADING } from './types';
const service = new Service();

const fetch = async ({ commit, state }, path) => {
  if (!path) return [];
  if (state.fileMap[path] && state.fileMap[path].length == 0) {
    commit(SET_LOADING, true);
  }
  const files = await service.get(`project/file/list?id=${path}`);
  commit(SET_FILES, { path, files });
  commit(SET_LOADING, false);
  return files;
};

const remove = ({ commit }, { id, project_id }) => {
  return service.post('project/file/delete', { id, project_id }).then(() => {
    commit(REMOVE_FILE_LOCAL, { project_id, id });
    return true;
  });
};

/**
 * @param {*} payload
 * { project_id, parent_id, file_name, file_type, created_by, type, payload = null, file_src = '' }
 */
const create = async ({ commit }, payload) => {
  const result = await service.post('project/file/create', payload);
  const { status = true, message = '', row } = result;
  if (status) {
    commit(ADD_FILE_LOCAL, row);
    return result;
  } else throw new Error(message);
};

export { fetch, remove, create };
