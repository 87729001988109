<template>
  <div :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper">
      <div class="card-body">
        <PanelTools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="handleClose"
        />
        <h3 class="card-title">
          <span>
            {{ $t('src.modules.chat.components.ProductPanel.index.product_feeds') }}
          </span>

          <br />
          <small class="text-muted small">
            {{ productLen }}
            {{ $t('src.modules.chat.components.ProductPanel.index.products_available') }}
          </small>
        </h3>

        <!-- Sort Combo (S) -->
        <sort-combo />
        <!-- Sort Combo (E) -->
        <!-- Search Box (S) -->
        <search-box />
        <!-- Search Box (E) -->
        <!-- Product Set Combo (S) -->
        <!-- <product-set-combo/> -->
        <!-- Product Set Combo (E) -->
        <!-- Product List (S) -->
        <vue-element-loading :active="!ready" spiner="line-scale" />
        <div v-bar class="scroll-container">
          <div class="product-feed-group">
            <product-list
              v-for="p in productKeys"
              :key="p"
              :product-set="productSetsByChannel[p]"
              :products="products[p]"
              :ready="ready"
              @onSelectedItem="handleOnSelectedItem($event, p)"
            />
          </div>
        </div>
        <!-- Product List (E) -->
        <product-view v-bind="product" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ProductList from './ProductList';
import SearchBox from './search-box';
import SortCombo from './sort-combo';
import ProductView from './product-view';
import PanelTools from 'modules/chat/components/PanelTools';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    ProductList,
    SearchBox,
    SortCombo,
    ProductView,
    PanelTools
  },

  data() {
    return {
      pinned: false,
      count: 0,
      product: {},
      ready: false
    };
  },

  computed: {
    ...mapGetters('chat', ['productFeedsSearch']),
    ...mapState('global', ['rightProductPanelPinned']),
    ...mapState('chat', ['productSets', 'productTypes']),
    ...mapGetters('chat', ['selectedConversation']),

    products() {
      if (!this.productFeedsSearch) return [];
      return this.productFeedsSearch;
    },

    productKeys() {
      if (!this.productFeedsSearch) return [];
      const productList = this.productFeedsSearch;
      const keys = Object.keys(productList);
      if (keys.length === 0) return [];
      return keys;
    },

    productSetsByChannel() {
      const { channelId } = this.selectedConversation || {};
      return this.productSets[channelId];
    },
    productTypesByChannel() {
      const { channelId } = this.selectedConversation || {};
      return this.productTypes[channelId];
    },

    productLen() {
      const obj = this.products;
      let len = 0;
      Object.entries(obj).forEach(([key]) => {
        if (
          this.productSetsByChannel[key] === 'All Products' ||
          this.productTypesByChannel[key] === 'all-products'
        ) {
          len = len + obj[key].length;
        }
      });
      return len;
    },

    shadowClass() {
      return this.rightProductPanelPinned ? 'shadow-sm' : 'shadow-lg unpinned';
    },

    pinPanelStatus() {
      const pinStatus = this.rightProductPanelPinned || this.pinned;
      return pinStatus;
    }
  },

  watch: {
    selectedConversation(newValue) {
      if (!newValue || isEmpty(newValue)) {
        this.$emit('hideAllPanel');
      }
    }
  },

  async mounted() {
    await this.getProductFeeds();
  },

  methods: {
    ...mapActions('global', ['pinRightProductPanel']),
    ...mapActions('chat', ['getProductFeedsByChannel']),

    handleOnSelectedItem(id, key) {
      const obj = this.products;
      this.product = obj[key].find(p => p.id == id);
    },

    async getProductFeeds() {
      try {
        this.ready = false;
        const product = await this.getProductFeedsByChannel();
        if (product && product.data.error) {
          if (product.data.error.type === 'GraphMethodException') {
            let errorMessage = this.$t('src.modules.chat.components.ProductPanel.productsets');
            errorMessage = errorMessage.replace('{product_catalog_id}', product.product_catalog_id);
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: errorMessage
            });
          } else {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t('src.modules.chat.components.ProductPanel.productsets.notfound')
            });
          }

          this.ready = true;
          return;
        }
      } catch (_) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.chat.components.ProductPanel.productsets.failed')
        });
        this.ready = true;
      }
      this.ready = true;
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.pinRightProductPanel(this.pinned);
    },

    handleClose() {
      this.$emit('onProductItemClick');
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  i {
    cursor: pointer;

    &.fa-custom {
      color: #e9ecef;
      transform: rotate(-45deg);
    }
  }
}

.product-feed-group {
  height: calc(100vh - 280px) !important;
  overflow: scroll;
}
</style>
