export const PIN_RIGHT_PANEL = 'PIN_RIGHT_PANEL';
export const PIN_RIGHT_PRODUCT_PANEL = 'PIN_RIGHT_PRODUCT_PANEL';
export const PIN_RIGHT_CONVERSATION_PANEL = 'PIN_RIGHT_CONVERSATION_PANEL';
export const PIN_RIGHT_CUSTOMER_SUPPORT_PANEL = 'PIN_RIGHT_CUSTOMER_SUPPORT_PANEL';
export const PIN_RIGHT_CUSTOMER_SURVEY_PANEL = 'PIN_RIGHT_CUSTOMER_SURVEY_PANEL';
export const PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL = 'PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL';
export const PIN_RIGHT_STATUS_HISTORY_PANEL = 'PIN_RIGHT_STATUS_HISTORY_PANEL';
export const SET_GLOBAL_READY = 'SET_GLOBAL_READY';
export const SET_OFFLINE = 'SET_OFFLINE';
export const SET_TERMINATE = 'SET_TERMINATE';
export const SET_SYNC_TIME = 'SET_SYNC_TIME';
export const SET_SYNCING = 'SET_SYNCING';
export const SET_SYNC_ERROR = 'SET_SYNC_ERROR';
export const SET_GLOBAL_LOADING_MAP = 'SET_GLOBAL_LOADING_MAP';
