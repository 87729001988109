import Service from 'core/service';
import store from 'core/store';
import baseNotification from 'components/BaseComponents/BaseNotification';
import { i18n } from 'locales';
import flatten from 'lodash/flatten';

export class CommentService extends Service {
  constructor() {
    const commentApi = process.env.VUE_APP_FACEBOOK_COMMENT_API;
    const apiKey = process.env.VUE_APP_FACEBOOK_COMMENT_API_KEY;
    if (!commentApi) throw new Error('Comment service require API before go through');
    super(commentApi);
    this.axios.defaults.headers.common['x-api-key'] = apiKey;
  }

  getChannelSelected() {
    const { state } = store;
    const { selectedChannel } = state.comment;
    if (!selectedChannel) return {};
    return selectedChannel;
  }

  getCommentConfig() {
    const { id: page_id, accessToken: access_token, platform: type } = this.getChannelSelected();
    return {
      page_id,
      access_token,
      type
    };
  }

  /**
   * Build formData
   *
   * @param {*} params
   */
  buildFormData(params = {}) {
    const configs = this.getCommentConfig();
    const formData = new FormData();
    params = { ...configs, ...params };
    Object.keys(params).forEach(k => formData.append(k, params[k]));
    return formData;
  }

  buildJsonData(params = {}) {
    const configs = this.getCommentConfig();
    return { ...configs, ...params };
  }

  /**
   * Get Comments
   *
   * @param {*} params
   */
  getComments(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      platform: configs.type,
      ...params
    });
    return this.axios
      .get(`/fbCommentApi/comments?${query}`)
      .then(({ data }) => data)
      .then(({ comments = [], next = '' }) => [comments, next]);
  }

  reloadCommentAndPost(params) {
    return this.axios
      .post('/fbCommentApi/reload_comment_and_post', params)
      .then(({ data }) => data);
  }

  /**
   * get a post
   * @param {*} params
   */
  getAPost(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      platform: configs.type,
      ...params
    });
    return this.axios
      .get(`/fbCommentApi/get-post?${query}`)
      .then(({ data }) => data)
      .then(({ post }) => (typeof post === 'object' ? post : {}));
  }

  /**
   * get a comment
   * @param {*} params
   */
  getAComment(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      platform: configs.type,
      ...params
    });
    return this.axios
      .get(`/fbCommentApi/get-comment?${query}`)
      .then(({ data }) => data)
      .then(({ comment }) => (typeof comment === 'object' ? comment : {}));
  }

  /**
   * get a Reply
   * @param {*} params
   */
  getReply(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      platform: configs.type,
      ...params
    });
    return this.axios
      .get(`/fbCommentApi/get-reply?${query}`)
      .then(({ data }) => data)
      .then(({ reply }) => (typeof reply === 'object' ? reply : {}));
  }

  /**
   * get Replies
   * @param {*} params
   */
  getReplies(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      platform: configs.type,
      ...params
    });
    return this.axios
      .get(`/fbCommentApi/replies?${query}`)
      .then(({ data }) => data)
      .then(({ replies }) => (Array.isArray(replies) ? replies : []));
  }

  /**
   * Filter Comments
   *
   * @param {*} params
   */
  commentFilter(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({ ...configs, ...params });
    return this.axios
      .get(`/api/filter-comment?${query}`)
      .then(({ data }) => data)
      .then(({ data = [], next = '' }) => [data, next])
      .catch(() => []);
  }

  /**
   * Find if any update from remote server
   * @author Son Pham
   * @param {*} params
   */
  checkUpdate(params) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({ ...configs, ...params });
    return this.axios.get(`/fbCommentApi/new_comments?${query}`).then(({ data }) => data);
  }

  /**
   * Reply To Comment
   *
   * @param {*} params
   */
  replyToComment(params) {
    const {
      page_id,
      parent_comment_id,
      message,
      platform,
      attachment_url,
      agent_id,
      agent_name
    } = params;
    return this.axios
      .post('/fbCommentApi/comment', {
        page_id,
        parent_comment_id,
        message,
        attachment_url,
        agent_id,
        agent_name,
        platform
      })
      .then(({ data }) => {
        if (data.hasOwnProperty('id')) return data;
        alert('Some error occurred');
        return false;
      })
      .catch(error => {
        const responseData = error.response.data;
        const { errors } = responseData;
        const errorMessages = flatten(Object.values(errors)).filter(x => x);
        const firstMessage = errorMessages[0] || '';

        const mappingErrors = {
          message_is_required: 'src.modules.facebook.error.invalid_parameter',
          type_is_required: 'src.modules.facebook.error.invalid_parameter',
          type_is_not_supported: 'src.modules.facebook.error.invalid_parameter',
          page_id_is_required: 'src.modules.facebook.error.invalid_parameter',
          channel_is_not_exist: 'src.modules.facebook.error.invalid_parameter',
          access_token_is_not_exist: 'src.modules.facebook.error.access_token_expired_or_invalid',
          platform_comment_is_not_exist: 'src.modules.facebook.error.object_not_exist',
          internal_error: 'src.modules.session.store.actions.internal_error'
        };
        const key = mappingErrors[firstMessage] || firstMessage;
        const message = i18n.t(key);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message
        });
        return false;
      });
  }

  /**
   * Delete reply
   *
   * @param { reply_id, comment_id, page_id, platform } params
   */
  deleteReply({ comment_id: reply_id, parent_comment_id: comment_id, page_id, platform }) {
    return this.axios
      .post('/fbCommentApi/deleteComment', { reply_id, comment_id, page_id, platform })
      .catch(error => {
        const responseData = error.response.data;
        const { errors } = responseData;
        const errorMessages = flatten(Object.values(errors)).filter(x => x);

        if (errorMessages.includes('platform_reply_is_not_exist')) {
          return true;
        }

        const mappingErrors = {
          platform_is_required: 'src.modules.facebook.error.invalid_parameter',
          platform_is_invalid: 'src.modules.facebook.error.invalid_parameter',
          page_id_is_required: 'src.modules.facebook.error.invalid_parameter',
          channel_is_not_exist: 'src.modules.facebook.error.invalid_parameter',
          access_token_is_not_exist: 'src.modules.facebook.error.access_token_expired_or_invalid',
          platform_comment_is_not_exist: 'src.modules.facebook.error.object_not_exist',
          internal_error: 'src.modules.session.store.actions.internal_error'
        };
        const errorKey = Object.keys(mappingErrors).find(k => errorMessages.includes(k)) || 'internal_error';
        const message = i18n.t(mappingErrors[errorKey]);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message
        });
        return false;
      });
  }

  /**
   *  Update Reply
   *
   * @param {*} params
   */
  updateReply(params) {
    const {
      comment_id: reply_id,
      parent_comment_id: comment_id,
      page_id,
      post_id,
      message,
      platform,
      agent_id,
      agent_name
    } = params;
    return this.axios
      .post('/api/update-reply', {
        reply_id,
        comment_id,
        page_id,
        post_id,
        message,
        platform,
        agent_id,
        agent_name
      })
      .then(({ data }) => data)
      .catch(error => {
        const responseData = error.response.data;
        const { errors } = responseData;
        const errorMessages = flatten(Object.values(errors)).filter(x => x);
        const firstMessage = errorMessages[0] || '';

        const mappingErrors = {
          message_is_required: 'src.modules.facebook.error.invalid_parameter',
          platform_is_required: 'src.modules.facebook.error.invalid_parameter',
          platform_is_not_supported: 'src.modules.facebook.error.invalid_parameter',
          page_id_is_required: 'src.modules.facebook.error.invalid_parameter',
          channel_is_not_exist: 'src.modules.facebook.error.invalid_parameter',
          access_token_is_not_exist: 'src.modules.facebook.error.access_token_expired_or_invalid',
          platform_comment_is_not_exist: 'src.modules.facebook.error.object_not_exist',
          internal_error: 'src.modules.session.store.actions.internal_error'
        };
        const key = mappingErrors[firstMessage] || firstMessage;
        const message = i18n.t(key);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message
        });
        return false;
      });
  }

  /**
   *  Set Read Comment
   *
   * @param {*} params
   */
  readComment({ comment_id }) {
    const configs = this.getCommentConfig();
    const query = this.toQueries({
      page_id: configs.page_id,
      comment_id
    });
    return this.axios.get(`/fbCommentApi/read/comment?${query}`).then(({ data }) => data);
  }
  /**
   *  Set Like Comment
   *
   * @param {*} params
   */
  likeComment({ comment_id }) {
    const { access_token } = this.getCommentConfig();
    const params = { comment_id, access_token };
    return this.axios
      .post('/fbCommentApi/like', this.buildJsonData(params))
      .then(({ data }) => data)
      .catch(error => {
        const message = error.response.data.errors.messageId
          ? i18n.t(error.response.data.errors.messageId)
          : error.response.data.errors.message;
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message
        });
        return false;
      });
  }
  /**
   *  Set Dis Like Comment
   *
   * @param {*} params
   */
  disLikeComment({ comment_id }) {
    const { access_token } = this.getCommentConfig();
    const params = { comment_id, access_token };
    return this.axios
      .post('/fbCommentApi/dislike', this.buildJsonData(params))
      .then(({ data }) => data)
      .catch(error => {
        const message = error.response.data.errors.messageId
          ? i18n.t(error.response.data.errors.messageId)
          : error.response.data.errors.message;
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message
        });
        return false;
      });
  }
}
