<template>
  <div class="media shadow-sm bg-white mb-10">
    <div class="p-10 pr-0">
      <a class="avatar" href="" @click.prevent.stop>
        <img
          v-b-tooltip.hover="sender_name"
          :src="userpicture"
          :alt="sender_name"
          class="img-fluid avatar shadow-sm"
          @error="$event.target.src = noAvatar"
        />
      </a>
    </div>
    <div class="body-wrapper ml-10 w-100">
      <div class="media-body bg-white">
        <div class="row">
          <div class="col-12 pl-10">
            <h6 class="mt-0 mb-0 text-left pt-5">
              {{ sender_name }}
              <small class="text-grey">
                -
                <el-tooltip
                  :content="createTime"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <span class="text-lowercase">{{ timeAgo }}</span>
                </el-tooltip>
              </small>
            </h6>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 text-left mh-48 pr-10 pl-10 emoji-font"
            style="white-space: pre-line;"
          >
            <span v-html="urlify(escapeHtmlChar(message))"></span>
          </div>
          <div class="post-media">
            <div v-if="isImage">
              <app-img :src="attachmentSrc" class="img-comment" alt="" />
            </div>
            <iframe
              v-if="isMedia"
              :src="attachmentSrc"
              frameborder="0"
              data-width="300"
              height="100%"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-5 pl-10">
            <small>
              <i :class="['ml-10', likeClass, likeActive]" @click="like({})" />
              <span>{{ likesCount }}</span>
              <i class="far fa-comment ml-10" />
              <span>{{ repCount }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import noAvatar from 'assets/images/no-avatar.png';
import { urlify, escapeHtmlChar, getParamsFromUrl, convertTimeUTC } from 'core/helpers';

export default {
  data() {
    return {
      noAvatar,
      likeUnlikeLoading: false
    };
  },

  computed: {
    ...mapState('comment', ['selectedComment']),

    likesCount() {
      return this.selectedComment.likes_count || '0';
    },

    isLike() {
      return this.selectedComment.is_like ? true : false;
    },

    reply_list() {
      return this.selectedComment.reply_list || [];
    },

    repCount() {
      return this.reply_list.length;
    },

    message() {
      return this.selectedComment.message;
    },

    userpicture() {
      return this.selectedComment.userpicture;
    },

    sender_name() {
      return this.selectedComment.sender_name;
    },

    timeAgo() {
      const at = convertTimeUTC(this.selectedComment.create_time, 'comment');
      const now = moment();
      return now.from(at, true);
    },

    createTime() {
      return convertTimeUTC(this.selectedComment.create_time);
    },

    attachmentData() {
      return this.selectedComment.attachment &&
        typeof this.selectedComment.attachment === 'string'
        ? JSON.parse(this.selectedComment.attachment)
        : this.selectedComment.attachment;
    },

    isMedia() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.video ? true : false;
    },

    isImage() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.photo || attachment.type === 'sticker' ? true : false;
    },

    attachmentSrc() {
      const attachment = this.attachmentData;
      if (this.isMedia) {
        return attachment.video;
      } else if (this.isImage) {
        const photo = attachment.type === 'sticker' ? attachment.url : attachment.photo;
        const params = this.getParamsFromUrl(photo);
        const { url = null } = params;
        return url || photo;
      }
      return '';
    },

    likeClass() {
      return this.isLike ? 'fas fa-thumbs-up fa-thumbs-up-custom' : 'far fa-thumbs-up';
    },

    can_like() {
      return this.selectedComment.can_like;
    },

    likeActive() {
      return this.selectedComment.can_like ? '' : 'like-disabled';
    }
  },

  methods: {
    ...mapActions('comment', ['setLikeComment', 'setDisLikeComment']),

    like() {
      if (!this.can_like || this.likeUnlikeLoading) return;
      if (this.isLike) {
        this.likeUnlikeLoading = true;
        this.setDisLikeComment(this.selectedComment.comment_id).then(
          () => (this.likeUnlikeLoading = false)
        );
      } else {
        this.likeUnlikeLoading = true;
        this.setLikeComment(this.selectedComment.comment_id).then(
          () => (this.likeUnlikeLoading = false)
        );
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getParamsFromUrl(url) {
      return getParamsFromUrl(url);
    },
    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.media-body {
  border: none;
}

.img-comment {
  max-height: 300px;
  margin: 0 auto;
}

i {
  cursor: pointer;

  &.fa-thumbs-up-custom {
    color: #365899;
  }
}
.emoji-font {
  font-family: 'Open Sans', sans-serif, 'Noto Color Emoji', 'Apple Color Emoji',
    'Segoe UI Emoji', Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica,
    serif;
}
.like-disabled {
  cursor: default;
}
</style>
