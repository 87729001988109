// The Browser API key obtained from the Google API Console.
// Replace with your own Browser API key, or your own key.
// const developerKey = process.env.VUE_APP_FIREBASE_API_KEY;

// The Client ID obtained from the Google API Console. Replace with your own Client ID.
const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

// Replace with your own project number from console.developers.google.com.
// See "Project number" under "IAM & Admin" > "Settings"

// Scope to use to access user's Drive items.
const scope = 'https://www.googleapis.com/auth/drive';

let pickerApiLoaded = false;

let oauthToken = false;

let callback = null;

// Use the Google API Loader script to load the google.picker script.
const showPicker = cb => {
  !oauthToken && window.gapi.load('auth', { callback: _onAuthApiLoad });
  !pickerApiLoaded && window.gapi.load('picker', { callback: _onPickerApiLoad });
  _createPicker();
  callback = cb;
};

const _onAuthApiLoad = () => {
  window.gapi.auth2.authorize(
    {
      client_id: clientId,
      scope: scope
    },
    _handleAuthResult
  );
};

const _onPickerApiLoad = () => {
  pickerApiLoaded = true;
  _createPicker();
};

const _handleAuthResult = authResult => {
  if (authResult && !authResult.error) {
    oauthToken = authResult.access_token;
    oauthToken = authResult.access_token;
    window.gapi.load('client', {
      callback: function() {
        window.gapi.client.setToken({
          access_token: oauthToken
        });
        window.gapi.client.load('drive', 'v2', function() {
          _createPicker();
        });
      }
    });
  }
};

// Create and render a Picker object for searching images.
const _createPicker = () => {
  if (pickerApiLoaded && oauthToken) {
    const view = new window.google.picker.View(
      window.google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS
    );
    const uploadView = new window.google.picker.DocsUploadView();
    view.setMimeTypes('image/png,image/jpeg,image/jpg,video/mp4');
    const picker = new window.google.picker.PickerBuilder()
      .setOrigin(window.location.protocol + '//' + window.location.host)
      .setOAuthToken(oauthToken)
      .addView(view)
      .addView(uploadView)
      .setCallback(_pickerCallback)
      .build();
    picker.setVisible(true);
  }
};

// A simple callback implementation.
const _pickerCallback = data => {
  if (data.action == window.google.picker.Action.PICKED) {
    if (callback) {
      const file = data.docs[0];
      let type = 'file';
      if (file.type === 'photo') {
        type = 'image';
      } else if (['audio', 'video'].includes(file.type)) {
        type = file.type;
      }
      _insertPermission(file.id, 'default', 'anyone', 'reader').then(() =>
        callback({ type, url: 'https://drive.google.com/uc?id=' + file.id })
      );
    }
  }
  if (callback) callback(false);
};

/**
 * Insert a new permission.
 *
 * @param {String} fileId ID of the file to insert permission for.
 * @param {String} value User or group e-mail address, domain name or
 *                       {@code null} "default" type.
 * @param {String} type The value "user", "group", "domain" or "default".
 * @param {String} role The value "owner", "writer" or "reader".
 * @return {void}
 */
const _insertPermission = (fileId, value, type, role) => {
  var body = {
    value: value,
    type: type,
    role: role
  };
  var request = window.gapi.client.drive.permissions.insert({
    fileId: fileId,
    resource: body
  });
  return new Promise(resolve => request.execute(resolve));
};

export default showPicker;
