import {
  CHANNELS_FILTER_MAP,
  GET_CONVERSATION_LOG_REPORT,
  GET_MESSAGE_LOG_REPORT,
  MESSAGE_LOG_SCHEDULES,
  MESSAGE_LOG_SCHEDULE_FILES,
  GET_COMMENT_REPORT
} from './types';
import { ReportService } from './service';
import baseNotification from 'components/BaseComponents/BaseNotification';
import { i18n } from 'locales';

const reportService = new ReportService();

export const setChannelsFilterMap = async ({ commit }, channelsFilter) => {
  commit(CHANNELS_FILTER_MAP, channelsFilter);
};

// list function #Message log
export const getMessageLogReportFile = async ({ commit }, params) => {
  const { year, month, channel_id, timezone } = params;
  const args = [year, month, channel_id, 'AGENTMESSAGELOG', [], timezone];
  const result = await reportService.getReportFile(...args);
  if (result) commit(GET_MESSAGE_LOG_REPORT, result);
};

export const getMessageLogReportSchedules = async ({ commit }, { userId, channelId, language }) => {
  let data = await reportService.getSchedulesList(userId, channelId, language, 'AGENTMESSAGELOG');
  if (data) commit(MESSAGE_LOG_SCHEDULES, data);
};

export const getMessageLogReportScheduleFiles = async ({ commit }, params) => {
  const { userId, channelId } = params;
  let data = await reportService.getScheduleHistory(userId, channelId, 'AGENTMESSAGELOG');
  if (data) commit(MESSAGE_LOG_SCHEDULE_FILES, data);
};

export const createLogForExport = async (_, data) => {
  return reportService.exportLogCreate(data);
};

export const addNewMessageLogReportSchedule = async (_, data) => {
  return reportService.createSchedule(data, 'AGENTMESSAGELOG');
};

export const addWeeklyMessageLogReportSchedule = async (_, data) => {
  return reportService.createSchedule(data, 'AGENTMESSAGELOG');
};

export const addMonthlyMessageLogReportSchedule = async (_, data) => {
  return reportService.createSchedule(data, 'AGENTMESSAGELOG');
};

export const removeMessageLogReportSchedule = async (_, data) => {
  return reportService.removeSchedule(data);
};

export const updateMessageLogReportScheduleStatus = async (_, data) => {
  if (!data || !data.status) return false;
  return reportService.startStopSchedule(data);
};
// END #Message log

// list function #conversation log
export const getConversationLogReportFile = async ({ commit, dispatch }, params) => {
  const { year, month, channel_id, reportType } = params;
  const { selected_columns, timezone, language } = params;
  const args1 = [year, month, channel_id, reportType];
  const args2 = [selected_columns, timezone, language];
  const args = [...args1, ...args2];

  let isRunning = true;
  setTimeout(function () {
    if (isRunning) {
      dispatch('global/setGlobalReady', true, { root: true });
      return baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t(
          'src.modules.report.components.index.the_amount_of_data_is_too_large_now_please_use_schedule_setting_of_export'
        )
      });
    }
  }, 30000);

  const result = await reportService.getReportFile(...args).then(res => {
    isRunning = false;
    return res;
  });

  if (result) commit(GET_CONVERSATION_LOG_REPORT, result);
};

export const addConversationLogReportSchedule = async (_, params) => {
  const { data, reportType } = params;
  return reportService.createSchedule(data, reportType);
};

export const listConversationLogReportSchedule = async (_, params) => {
  const { userId, channelId, language, reportType } = params;
  return reportService.getSchedulesList(userId, channelId, language, reportType);
};

export const listConversationLogReportFiles = async (_, params) => {
  const { userId, channelId, reportType } = params;
  return reportService.getScheduleHistory(userId, channelId, reportType);
};

export const removeConversationLogReportSchedule = async (_, data) => {
  return reportService.removeSchedule(data);
};

export const updateConversationLogReportSchedule = async (_, data) => {
  if (!data || !data.status) return false;
  return reportService.startStopSchedule(data);
};
// END #conversation log

// List function #CommentLog
export const getCommentReportFile = async ({ commit }, params) => {
  const { channel_id, year, month, timezone } = params;
  const args = [year, month, channel_id, 'COMMENTLOG', [], timezone];
  const result = await reportService.getReportFile(...args);
  if (result) commit(GET_COMMENT_REPORT, result);
};

export const addCommentLogSchedule = async (_, data) => {
  return reportService.createSchedule(data, 'COMMENTLOG');
};

export const listCommentLogSchedule = async (_, { userId, channelId, language }) => {
  return reportService.getSchedulesList(userId, channelId, language, 'COMMENTLOG');
};

export const listCommentLogFiles = async (_, { userId, channelId }) => {
  return reportService.getScheduleHistory(userId, channelId, 'COMMENTLOG');
};

export const removeCommentLogSchedule = async (_, data) => {
  return reportService.removeSchedule(data);
};

export const updateCommentLogSchedule = async (_, data) => {
  if (!data || !data.status) return false;
  return reportService.startStopSchedule(data);
};

export const getDownloadUrl = async (_, data) => {
  return reportService.getDownloadUrl(data);
};
// END #CommentLog

// List function #SurveyLog
export const addSurveyLogSchedule = async (_, data) => {
  return reportService.createSchedule(data, 'SURVEYLOG');
};

export const listSurveyLogSchedule = async (_, { userId, channelId, language }) => {
  return reportService.getSchedulesList(userId, channelId, language, 'SURVEYLOG');
};

export const listSurveyLogFiles = async (_, { userId, channelId }) => {
  return reportService.getScheduleHistory(userId, channelId, 'SURVEYLOG');
};

export const removeSurveyLogSchedule = async (_, data) => {
  return reportService.removeSchedule(data);
};

export const updateSurveyLogSchedule = async (_, data) => {
  if (!data || !data.status) return false;
  return reportService.startStopSchedule(data);
}
// END #SurveyLog