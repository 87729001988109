/** * Can not use el-iput beacause When input Japanese character can not count maxlength
**/

<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-input-group-prepend">
        <div
          class="nat-input-group-text"
          :style="c_labelWidthStyle"
          v-if="label !== '' || icon !== ''"
        >
          <label v-if="label !== '' && showLabel == true">
            <span v-if="required" class="nat-required">*</span>
            {{ label }}
          </label>
          <template v-if="icon !== ''">
            <i v-if="icon === 'search'" class="fas fa-search"></i>
          </template>

          <div class="nat-input-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}-${prefix}`"
              :name="`tooltip-${name}-${prefix}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-input-group-value">
          <div class="nat-input-group-value-maxlength">
            <span
              v-if="c_contentLength > 0 && showWordLimit && maxlength > 0 && !disabled"
              :class="['nat-maxlengthTag']"
            >
              <span
                :class="[
                  `${
                    maxlength - c_contentLength < 0 || c_contentLength - minlength <= 0
                      ? 'error'
                      : ''
                  } `
                ]"
                >{{ c_contentLength }}</span
              >
              &nbsp;/&nbsp;{{ maxlength }}
            </span>
          </div>
          <input
            ref="input"
            class="nat-input-hori"
            v-bind:data-testid="dataTestId"
            :disabled="disabled"
            :maxlength="maxlength"
            :name="`${name}-${prefix}`"
            :placeholder="disabled ? '' : c_placeholder"
            :id="`${name}-${prefix}`"
            :type="inputType"
            :autocomplete="autocomplete"
            v-model="content"
            @compositionupdate="handecomposeUpdate"
            @compositionend="handecomposeEnd"
            @input="handleInput(content)"
            @blur="handleBlur"
            @focus="handleFocus"
            @keyup="handleKeyup"
            @keypress.enter="handleKeyPressEnter"
          />
        </div>
      </div>
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="c_hintText !== '' && showHintText" class="nat-hint-text">
        {{ c_hintText }}
      </div>
    </div>
    <div v-if="align === 'vertical'">
      <label
        v-if="(c_contentLength > 0 && showWordLimit) || showLabel === true || tooltipText !== ''"
      >
        <span v-if="required" class="nat-required">*</span>
        <span v-if="label !== '' && showLabel == true"> {{ label }}</span>

        <base-tooltip-icon
          :id="`tooltip-${name}-${prefix}`"
          :name="`tooltip-${name}-${prefix}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
        <span
          v-if="c_contentLength > 0 && showWordLimit && maxlength > 0 && !disabled"
          :class="['nat-maxlengthTag']"
        >
          <span
            :class="[
              `${
                maxlength - c_contentLength < 0 || c_contentLength - minlength < 0 ? 'error' : ''
              } `
            ]"
            >{{ c_contentLength }}</span
          >
          &nbsp;/&nbsp;{{ maxlength }}
        </span>
      </label>
      <input
        ref="input"
        class="nat-input-verti"
        v-bind:data-testid="dataTestId"
        :disabled="disabled"
        :maxlength="maxlength"
        :name="`${name}-${prefix}`"
        :placeholder="disabled ? '' : c_placeholder"
        :id="`${name}-${prefix}`"
        :type="inputType"
        :autocomplete="autocomplete"
        v-model="content"
        @compositionupdate="handecomposeUpdate"
        @compositionend="handecomposeEnd"
        @input="handleInput(content)"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup="handleKeyup"
        @keypress.enter="handleKeyPressEnter"
      />
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="c_hintText !== '' && showHintText" class="nat-hint-text">
        {{ c_hintText }}
      </div>
    </div>
    <!-- No label-->
    <div v-if="align === 'vertical-no-label'">
      <span
        v-if="c_contentLength > 0 && showWordLimit && maxlength > 0"
        :class="['nat-maxlengthTag']"
      >
        <span
          :class="[
            `${maxlength - c_contentLength < 0 || c_contentLength - minlength < 0 ? 'error' : ''} `
          ]"
          >{{ c_contentLength }}</span
        >
        &nbsp;/&nbsp;{{ maxlength }}
      </span>
      <input
        ref="input"
        v-bind:data-testid="dataTestId"
        :class="errors.length > 0 ? 'red-border' : 'nat-input-verti-no-label'"
        :disabled="disabled"
        :maxlength="maxlength"
        :name="`${name}-${prefix}`"
        :placeholder="disabled ? '' : c_placeholder"
        :id="`${name}-${prefix}`"
        :type="inputType"
        :autocomplete="autocomplete"
        v-model="content"
        @compositionupdate="handecomposeUpdate"
        @compositionend="handecomposeEnd"
        @input="handleInput(content)"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup="handleKeyup"
        @keypress.enter="handleKeyPressEnter"
      />
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="c_hintText !== '' && showHintText" class="nat-hint-text">
        {{ c_hintText }}
      </div>
    </div>
    <!-- -->
  </ValidationProvider>
</template>

<script>
import './vee-validate.js';
import { convert2OneByte } from './vee-validate';

export default {
  name: 'base-input',

  props: {
    /* eslint-disable vue/require-default-prop */
    dataTestId: {
      type: String,
      required: false
    },
    /* eslint-enable vue/require-default-prop */
    align: {
      type: String,
      default: 'vertical'
    },
    inputType: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    maxlength: {
      type: Number,
      default: 100
    },
    minlength: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    placeholderType: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    showWordLimit: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    showHintText: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    ignoreValidate: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      content: this.value,
      compositionData: ''
    };
  },
  computed: {
    c_arrRule() {
      return this.rules.split('|');
    },
    c_contentLength() {
      return Number(this.content.length) + Number(this.compositionData.length);
    },
    c_isEmail() {
      return this.c_arrRule.includes('email');
    },
    c_isPhonenumber() {
      return this.c_arrRule.includes('phoneNumber');
    },
    c_isPostCode() {
      return this.c_arrRule.includes('postCode');
    },
    c_isCountryCode() {
      return this.c_arrRule.includes('countryCode');
    },
    c_isPasswordCode() {
      return this.c_arrRule.includes('password');
    },
    c_rules() {
      let allRule = {};
      if (this.ignoreValidate) return allRule;
      if (this.required) {
        if (this.error.length) allRule.baseInputCustomRequired = { label: this.error };
        else allRule.baseRequired = { label: this.label };
      }

      if (
        !this.c_arrRule.includes('phoneNumber') &&
        !this.c_arrRule.includes('postCode') &&
        !this.c_arrRule.includes('countryCode')
      ) {
        if (this.maxlength && this.maxlength > 0) {
          allRule.baseMaxLength = { length: this.maxlength, label: this.label };
        }
        if (this.minlength && this.minlength > 0) {
          allRule.baseMinLength = { length: this.minlength, label: this.label };
        }
      }

      this.c_arrRule.map(rule => {
        switch (rule) {
          case 'email':
            allRule.baseEmail = { label: this.label };
            break;
          case 'phoneNumber':
            allRule.basePhoneNumber = { label: this.label };
            break;
          case 'numeric':
            allRule.baseNumeric = { label: this.label };
            break;
          case 'postCode':
            allRule.basePostCode = { label: this.label };
            break;
          case 'countryCode':
            allRule.baseCountryCode = { label: this.label };
            break;
          case 'folderName':
            allRule.baseFolderName = { label: this.label };
            break;

          default:
            break;
        }
      });
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else {
        if (this.placeholderType === 'search')
          return this.$t('veevalidate.search.placeholder', { fieldName: this.label });
        else return this.$t('veevalidate.placeholder', { fieldName: this.label });
      }
    },
    c_hintText() {
      if (this.hintText !== '') return this.hintText;
      else {
        if (this.c_arrRule.includes('phoneNumber')) return this.$t('veevalidate.phoneNumber.ex');
        if (this.c_arrRule.includes('postCode')) return this.$t('veevalidate.postCode.ex');
        if (this.c_arrRule.includes('countryCode')) return this.$t('veevalidate.countryCode.ex');
        if (this.c_arrRule.includes('email')) return this.$t('veevalidate.email.ex');
        return this.hintText;
      }
    },

    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    }
  },
  watch: {
    value(value) {
      this.content = value;
    },
    content(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

  methods: {
    handleInput(value) {
      let valiValue = value;
      /**
       * fixed rules
       */
      // 1. Accept to input one space between 2 words/characters only
      if (/\s\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\s\s+/g, ' ');
      }
      // 2. Must not accept tab
      if (/\t+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\t+/g, ' ');
      }
      // 2.Must not accept space only without EN, JA, CH characters
      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      this.content = valiValue;
    },
    handecomposeUpdate(compositionEvent) {
      this.compositionData = compositionEvent.data || '';
    },
    handecomposeEnd() {
      this.compositionData = '';
    },
    handleBlur(event) {
      let valiValue = this.content;
      if (this.c_isPhonenumber) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isPostCode) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isCountryCode) {
        valiValue = valiValue.toUpperCase();
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isEmail) {
        valiValue = convert2OneByte(valiValue);

        //1. Not accept to input emojis in local part and domain name
      }
      if (this.c_isPasswordCode) {
        valiValue = convert2OneByte(valiValue);

        //1. Not accept to input emojis in local part and domain name
      }

      this.content = valiValue;
      event.target.value = valiValue;
      this.$emit('blur', event);
    },
    handleFocus(event) {
      event.target.value = this.content;
      this.$emit('focus', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    handleKeyPressEnter(event) {
      this.$emit('keypressEnter', event);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-input-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-input-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-input-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-input-group-value-maxlength {
  position: absolute;
  top: -14px;
}
.nat-input-hori {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}
.nat-input-verti {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.nat-input-verti-no-label {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.red-border {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid red;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid red;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.nat-required {
  color: red;
}

.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 10.5px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: 0.7;
}
.nat-maxlengthTag .error {
  color: red;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
}
</style>
