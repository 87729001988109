<template>
  <button
    ref="signinBtn"
    data-testid="btnLoginGoogle"
    :disabled="loading"
    class="btn btn-secondary btn-cons btn-block"
    type="button"
    @click="signinWithGoogle"
  >
    <span v-if="!loading" class="btn-inner--icon m-r-5">
      <img style="height: 25px;" src="~assets/images/icons/common/google.svg" />
    </span>
    <i v-else class="fas fa-spinner fa-spin fa-lg m-r-5" />

    <span class="btn-inner--text">{{
      $t('src.components.GoogleSignin.index.sign_in_with_google')
    }}</span>
  </button>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    btnTitle: {
      type: String,
      default: 'Google'
    },
    btnLinked: {
      type: String,
      default: ''
    },
    linkAllowed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      googleUser: {}
    };
  },

  methods: {
    async signinWithGoogle() {
      try {
        this.$emit('loading', true);
        const gapi = window.gapi;
        if (!gapi) {
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: this.$t(
              'src.components.GoogleSignin.index.internet_error_please_try_again'
            )
          });
          return;
        }

        if (!gapi.auth2) {
          await new Promise(resolve => gapi.load('auth2', resolve));
          const btn = this.$refs.signinBtn;
          btn.click();
          return;
        }

        const googleAuth = await gapi.auth2.init({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          fetch_basic_profile: false,
          scope: 'email'
        });

        if (googleAuth.isSignedIn.get()) {
          await googleAuth.currentUser.get().disconnect();
          const btn = this.$refs.signinBtn;
          btn.click();
          return;
        }

        const googleUser = await googleAuth.signIn();

        const { id_token } = googleUser.getAuthResponse();
        this.$emit('signIn', id_token);
        this.$emit('loading', false);
      } catch (e) {
        // eslint-disable-next-line
        console.log('GoogleSignin >> error.', e);
        this.$emit('error', e);
        const { error } = e;
        if (
          error === 'popup_closed_by_user' ||
          error === 'access_denied' ||
          error === 'immediate_failed'
        )
          return;

        if (error === 'popup_blocked_by_browser') {
          alert(
            this.$t(
              'src.components.GoogleSignin.index.the_popup_is_blocked_by_your_browser_please_turn_it_on'
            )
          );
          return;
        }
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: JSON.stringify(e)
        });
      }
    }
  }
};
</script>
