import { SET_PROFILE_INFO, AUTH_ERROR } from './types';
import Service from 'core/service';
import baseNotification from 'components/BaseComponents/BaseNotification';
import { i18n } from 'locales';
const service = new Service();

export const getUserProfile = async ({ commit }) => {
  return await service
    .get('agents/me')
    .then(({ me }) => {
      commit(SET_PROFILE_INFO, me.agentInfo);
    })
    .catch(() => {
      commit(AUTH_ERROR);
    });
};
export const submitForm = ({ commit }, formUserUpdate) => {
  if (!formUserUpdate) return;
  return service
    .rest('agents/updateAgents', {
      agents: [formUserUpdate]
    })
    .then(agentInfo => {
      commit(SET_PROFILE_INFO, agentInfo[0].Items[0]);
      return agentInfo;
    })
    .catch(() => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: submitForm -> formUserUpdate', formUserUpdate);
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.profile.store.actions.data_is_invalid')
      });
    });
};
