<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-date-picker-range-group-prepend">
        <div class="nat-date-picker-range-group-text" :style="c_labelWidthStyle">
          <label v-if="label !== ''">
            <span v-if="required" class="nat-required">*</span>
            {{ label }}
          </label>
          <div class="nat-date-picker-range-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}`"
              :name="`tooltip-${name}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-date-picker-range-hori">
          <el-date-picker
            :clearable="!required"
            :disabled="disabled"
            v-model="content"
            popper-class="responsive-date-range-picker"
            :type="type"
            :format="c_formatDate"
            :editable="false"
            :picker-options="c_pickerOptions"
            :range-separator="'-'"
            :start-placeholder="c_startPlaceholder"
            :end-placeholder="c_endPlaceholder"
            :default-time="c_defaultTime"
            @change="handeChange()"
            @input="handleInput()"
          ></el-date-picker>
        </div>
      </div>
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
    <div v-if="align === 'vertical'">
      <label>
        <span v-if="required" class="nat-required">*</span>
        {{ label }}
        <base-tooltip-icon
          :id="`tooltip-${name}`"
          :name="`tooltip-${name}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
      </label>
      <div class="nat-date-picker-range-verti">
        <el-date-picker
          :clearable="!required"
          :disabled="disabled"
          v-model="content"
          popper-class="responsive-date-range-picker"
          :type="type"
          :format="c_formatDate"
          :editable="false"
          :picker-options="c_pickerOptions"
          :range-separator="'-'"
          :start-placeholder="c_startPlaceholder"
          :end-placeholder="c_endPlaceholder"
          :default-time="c_defaultTime"
          @change="handeChange()"
          @input="handleInput()"
        ></el-date-picker>
      </div>
      <div class="nat-invalid-data">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import { uuid } from 'vue-uuid';
import moment from 'moment';
export default {
  name: 'base-date-picker-range',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: uuid.v4()
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'daterange'
    },
    value: {
      type: Array,
      default: () => []
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    startPlaceholder: {
      type: String,
      default: null
    },
    endPlaceholder: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      content: this.value
    };
  },
  computed: {
    c_formatDate() {
      switch (this.type) {
        case 'datetimerange':
          return 'yyyy/MM/dd HH:mm';
        case 'daterange':
          return 'yyyy/MM/dd';
        case 'monthrange':
          return 'yyyy/MM/dd';
        default:
          return 'yyyy/MM/dd';
      }
    },
    c_defaultTime() {
      switch (this.type) {
        case 'datetimerange':
          return ['00:00:00', '23:59:00'];
        case 'daterange':
        case 'monthrange':
          return ['00:00:00'];
        default:
          return ['00:00:00'];
      }
    },
    c_pickerOptions() {
      if (this.c_arrRule.includes('birthday'))
        return {
          disabledDate(time) {
            const date = moment(time).format('YYYY-MM-DD');
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            return moment(date).isAfter(currentDate);
          }
        };
      else return {};
    },
    c_arrRule() {
      return this.rules.split('|');
    },
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseSelectRequired = { label: this.label };
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.placeholder', { fieldName: this.label });
    },
    c_startPlaceholder() {
      if (this.disabled === true) return '';
      if (this.startPlaceholder) return this.startPlaceholder;
      else return this.$t('veevalidate.placeholder.start_date');
    },
    c_endPlaceholder() {
      if (this.disabled === true) return '';
      if (this.endPlaceholder) return this.endPlaceholder;
      else return this.$t('veevalidate.placeholder.end_date');
    },
    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    }
  },
  watch: {
    value(value) {
      this.content = value;
    }
  },
  methods: {
    handeChange() {
      this.$emit('change', this.content);
    },
    handleInput() {
      this.$emit('input', this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-date-picker-range-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-date-picker-range-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-date-picker-range-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-date-picker-range-group-value {
  flex: 1 1 auto;
  width: 1%;
}

.nat-date-picker-range-hori {
  /deep/.el-date-editor.el-input {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }
}

.nat-date-picker-range-verti {
  /deep/.el-date-editor.el-input {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}

.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
</style>
