import {
  GET_AGENT_GROUPS,
  ADD_CONVERSATION,
  SET_USER_INFO,
  SET_CUSTOMER_SUPPORT,
  SET_FILES_TICKET_MAP,
  ADD_LOAD_MORE_SCROLL_ID,
  ADD_LOAD_SCROLL_ID,
  UPDATE_ALL_TICKET,
  GET_ACTION_CLICK_SEARCH
} from './types';
import Service from 'core/service';
import { getTicketsScroll, getTicketsIndexTickets, getFileList } from 'elastic/api';
const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;

const service = new Service();
const SERACH_SIZE = 100;

/**
 * @author Cong Pham
 * @param {String} agentId
 * @returns {Array} data
 */
export const getAgentGroupsByAgentId = async ({ commit }, { agentId }) => {
  if (!agentId || agentId.length === 0) {
    commit(GET_AGENT_GROUPS, []);
    return [];
  }

  const params = {};
  params.agentId = agentId;
  const data = await service.post('agentGroups/getAgentGroups', params);
  if (data) commit(GET_AGENT_GROUPS, data);
  return data;
};

export const getTicketsBySearch = async ({ commit }, params) => {
  const { channelIds, conditions } = params;
  const size = SERACH_SIZE;
  try {
    let allTicket = [];
    let channelScrollIds = {};
    let countAll = 0;
    if (channelIds.length > 0) {
      await Promise.all(
        channelIds.map(async channelId => {
          let rltDataObj = null;
          rltDataObj = (await getTicketsIndexTickets(channelId, size, conditions)) || {};
          const { tickets = [], scrollId = '', count = 0 } = { ...rltDataObj };
          if (tickets.length > 0) {
            channelScrollIds[channelId] = scrollId;
            allTicket = [...allTicket, ..._transformTickets(tickets)];
            countAll = countAll + count;
          }
        })
      );
    }
    commit(ADD_LOAD_SCROLL_ID, {
      allTicket,
      channelScrollIds,
      countAll
    });
  } catch (error) {
    // eslint-disable-next-line
    console.log('[C+ Debug] >>> getTicketsBySearch params, err', params, error);
  }
};

export const getLoadMoreTicketByScrollId = async ({ commit }, params) => {
  const { channelScrollIds } = params;
  const size = SERACH_SIZE;
  let newAllTicket = [];
  let newChannelScrollIds = {};
  const channelExistScroll = Object.keys(channelScrollIds).filter(
    item => channelScrollIds[item] !== ''
  );
  let cntChannelNotExistScroll = 0;
  if (channelExistScroll.length > 0) {
    await Promise.all(
      channelExistScroll.map(async channelId => {
        const scrllId = channelScrollIds[channelId];
        const rltDataObj = (await getTicketsScroll(scrllId, size)) || {};

        const { tickets = [], scrollId = '' } = { ...rltDataObj };
        if (tickets.length > 0) {
          newChannelScrollIds[channelId] = scrollId;
          newAllTicket = [...newAllTicket, ..._transformTickets(tickets)];
        } else {
          newChannelScrollIds[channelId] = '';
          cntChannelNotExistScroll++;
        }
      })
    );
    commit(ADD_LOAD_MORE_SCROLL_ID, {
      allTicket: newAllTicket,
      channelScrollIds: newChannelScrollIds,
      isLoadMoreSearchTickets: channelExistScroll.length !== cntChannelNotExistScroll
    });
  }
};

const _transformTickets = data => {
  return data.map(item => {
    if (item.hasOwnProperty('userData')) {
      item.labels = item.userData.labels || [];
      return item;
    }
    if (item.hasOwnProperty('ticketInfo')) {
      const newItem = { ...item.ticketInfo } || {};
      if (newItem.hasOwnProperty('userData')) {
        newItem.labels = newItem.userData.labels || [];
      }
      return newItem;
    }
    return item;
  });
};

export const getConversationTicketById = async ({ commit }, { conversationId, ticketId }) => {
  const conversation = await service.post('conversations/getConversationTicketById', {
    conversationId,
    ticketId
  });
  const { error } = conversation;
  if (error)
    return {
      error
    };
  commit(ADD_CONVERSATION, conversation);
  return conversation;
};

export const getPersonalInfo = ({ commit }, params) => {
  const userService = new Service(URL_USER);
  return userService
    .post('personal/getPersonal', params)
    .then(data => {
      commit(SET_USER_INFO, data);
      return data;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: getPersonalInfo -> params, error: ', params, error);
      return {};
    });
};

export const getUserInfo = ({ commit }, params) => {
  const userService = new Service(URL_USER);
  return userService
    .post('personal/getUserById', params)
    .then(data => {
      commit(SET_USER_INFO, data);
      return data;
    })
    .catch(() => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: getUserInfo -> params', params);
      return {};
    });
};

export const getCustomerSupportByTicketId = async ({ commit }, params) => {
  const data = await service.post('customerSupports/getCustomerSupportByTicketId', {
    ...params
  });
  if (data) commit(SET_CUSTOMER_SUPPORT, data);
  return data;
};

export const getFileListTicket = async ({ commit }, params) => {
  const { channelId, ticketIds, size } = params;
  const data = await getFileList(channelId, size, ticketIds);
  if (data) commit(SET_FILES_TICKET_MAP, data);
  return data;
};

export const getExternalUserInfo = (_, params) => {
  const userService = new Service(URL_USER);
  return userService
    .rest('personal/getExternalUserInfo', params)
    .then(data => {
      if (!data.length || !data[0]['label'] || !data[0]['value']) {
        return 'data error';
      } else {
        return data;
      }
    })
    .catch(() => {
      return 'error';
    });
};

export const updateSearchData = async ({ commit }, data) => {
  commit(UPDATE_ALL_TICKET, data);
};

export const getActionSearchClick = async ({ commit }, params) => {
  const { userId = '', channelId = '' } = params;
  commit(GET_ACTION_CLICK_SEARCH, { userId, channelId });
};
