<template>
  <p v-if="isEndUser" v-html="answer" />
  <b-card v-else no-body>
    <div class="chat-header p-10 pre-formatted">{{ title }}</div>
    <b-list-group flush>
      <b-list-group-item>
        <b-badge
          v-for="(btn, index) in replies"
          :key="`fb_tmpl_btn_${index + 1}`"
          variant="light"
          pill
          class="mr-10 text-primary"
        >{{ btn.title }}</b-badge>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { getTextFromMessageContent, urlify } from 'core/helpers';
import { END_USER_PLATFORM } from 'core/constants';

export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    platform: {
      type: String,
      default: () => 'agent'
    },
    text: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    title() {
      return this.message.text;
    },

    replies() {
      return this.message.quick_replies || [];
    },

    answer() {
      const { content, text } = this.message;
      return urlify(
        getTextFromMessageContent(content, 'quick_reply', text, this.selectedConversation)
      );
    },

    isEndUser() {
      return END_USER_PLATFORM.includes(this.platform);
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-header {
  background-color: #2196f3;
  border-radius: calc(0.25rem - 0.0625rem) calc(0.25rem - 0.0625rem) 0 0;
  margin-bottom: 0;
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.05);
}
.list-group-item {
  margin-bottom: 0;
  border: 0;
  border-bottom: 0.06rem solid #e9ecef;
}
/deep/.badge {
  text-transform: unset !important;
}
.pre-formatted {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
