<template>
  <div class="post">
    <p class="p-10 pt-5" style="white-space: pre-line">{{ message }}</p>

    <div v-if="attachments" class="post-media">
      <div v-if="type === 'photo' || type === 'profile_media' || type === 'cover_photo'">
        <img :src="imageUrl" class="img-fuild" alt="" @error="$event.target.src = noAvatar" />
      </div>
      <iframe v-if="type === 'video_inline'" :src="media.source" frameborder="0" height="100%" />
      <div v-if="isCarousel" class="carousels">
        <carousel :carousels="carousels" />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '../Carousel';
import noAvatar from 'assets/images/no-avatar.png';

export default {
  components: { Carousel },

  props: {
    attachments: { type: String, default: '' },
    creator_id: { type: String, default: '' },
    creator_name: { type: String, default: '' },
    is_carousel: { type: Number, default: 0 },
    message: { type: String, default: '' },
    picture: { type: String, default: '' },
    post_id: { type: String, default: '' }
  },

  data() {
    return {
      noAvatar
    };
  },

  computed: {
    attachmentsData() {
      if (!this.attachments) return {};
      const att = JSON.parse(this.attachments);
      return att.data[0];
    },

    type() {
      let type = this.attachmentsData.type;
      if (type == 'video_inline') {
        const media = this.media;
        const { source = '' } = media;
        const url = source.split('&')[0];
        const ext = url.split('.').pop().split(/#|\?/)[0];
        return ext == 'mpd' ? 'photo' : 'video_inline';
      }
      return type;
    },

    title() {
      return this.attachmentsData.title;
    },

    media() {
      return this.attachmentsData.media || {};
    },

    image() {
      return this.media && this.media.image;
    },

    imageUrl() {
      return this.image && this.image.src;
    },

    url() {
      return this.attachmentsData.url;
    },

    isCarousel() {
      return this.is_carousel === 1 || this.type === 'new_album' || this.type === 'album';
    },

    carousels() {
      const data =
        this.isCarousel &&
        this.attachmentsData.subattachments &&
        this.attachmentsData.subattachments.data;
      if (!data || !Array.isArray(data)) return [];
      return data;
    }
  },

  watch: {
    attachments() {
      this.$nextTick(() => this.$fb && this.$fb.XFBML && this.$fb.XFBML.parse());
    }
  }
};
</script>

<style lang="scss" scoped>
.post-media {
  img {
    max-width: 100%;
    cursor: pointer;
  }
}
</style>
