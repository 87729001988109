<template>
  <b-modal
    id="StateOverview"
    ref="modal"
    v-model="show"
    :title="$t('src.modules.chat.components.StateOverview.index.status_overview')"
    size="lg"
    scrollable
  >
    <div v-loading="loading">
      <b-table
        id="myTable"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        hover
      >
        <template slot="createdAt" slot-scope="data">{{
          formatTime(data.value)
        }}</template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="myTable"
      />
    </div>
    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right" variant="default" @click="onCancel">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { EventBus } from 'core/eventBus';
import { getHandler, convertTimeUTC } from 'core/helpers';

export default {
  props: {
    conversation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: 'status',
          label: this.$t('src.modules.chat.components.StateOverview.index.status'),
          sortable: true
        },
        {
          key: 'state',
          label: this.$t('common.text.phase'),
          sortable: false
        },
        {
          key: 'createdAt',
          label: this.$t('src.modules.chat.components.StateOverview.index.date_time'),
          sortable: true
        },
        {
          key: 'handledBy',
          label: this.$t('src.modules.chat.components.StateOverview.index.handled_by'),
          sortable: true
        }
      ],
      items: []
    };
  },

  computed: {
    rows() {
      return this.items.length;
    }
  },
  watch: {
    async conversation(newValue) {
      if (this.show && newValue && newValue.ticketId) {
        const params = { ticketId: newValue.ticketId };
        const data = await this.getTicketActionLogs(params);
        this.items = this.processItems(data);
      }
    }
  },
  created() {
    EventBus.$on('openStatusHistory', async () => {
      if (this.conversation && this.conversation.ticketId) {
        this.loading = true;
        const params = { ticketId: this.conversation.ticketId };
        const data = await this.getTicketActionLogs(params);
        this.items = this.processItems(data);
        this.loading = false;
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('openStatusHistory');
  },
  methods: {
    ...mapActions('actionLogs', ['getTicketActionLogs']),
    onCancel() {
      this.show = false;
    },
    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    },
    processItems(data) {
      return data.map(item => {
        item.handledBy = getHandler(item);
        return item;
      });
    }
  }
};
</script>

<style scoped></style>
