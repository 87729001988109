<template>
  <video-player
    v-if="['zalo', 'instagram'].every(p => p !== platform)"
    ref="videoPlayer"
    :options="playerOptions"
    :playsinline="true"
    class="vjs-custom-skin"
  />

  <video v-else style="width: 100%; height: 360px" controls>
    <source :src="videoUrl" type="video/mp4" />
    <source :src="videoUrl" type="video/webm" />
    <p>
      Your browser doesn't support HTML5 video. Here is a
      <a :href="videoUrl">link to the video</a> instead.
    </p>
  </video>
</template>

<script>
import 'assets/scss/vue-video-player.scss';

export default {
  props: {
    videoUrl: {
      type: String,
      default: null
    },
    platform: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      playerOptions: {
        height: '360',
        autoplay: false,
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            src: this.videoUrl
          }
        ]
      }
    };
  },

  computed: {
    player() {
      return this.$refs.videoPlayer && this.$refs.videoPlayer.player;
    }
  },

  mounted() {
    setTimeout(() => {
      if (!this.player) return;
      this.player.muted(false);
    }, 5000);
  }
};
</script>

<style></style>
