<template>
  <div class="survey-form-item">
    <div class="survey-title-item">
      <span v-if="item.rules && item.rules.required" class="icon-require">*</span>
        {{ item.question }}
    </div>
    <label class="d-block">
      {{ item.description }}
    </label>

    <b-form-group
      v-slot="{ ariaDescribedby }"
      :disabled="true"
    >
      <b-form-checkbox
        v-for="(val, index) in (item.attributes && item.attributes.options) || []"
        :key="index"
        v-model="item.answer"
        :value="val.value"
        :aria-describedby="ariaDescribedby"
        :name="val.label"
        class="mt-2"
      >
        {{ val.label }}
        <div
          v-if="item.answer && item.answer.includes(val.value)" 
          class="sv-custom-answer"
        >
          {{ item.answerCustom && item.answerCustom[val.value] }}
        </div>
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss"></style>