<template>
  <div>
    <div class="m-b-10 stories">
      <div class="str-border"></div>
      <div class="str-block fit-content">
        <div class="str-text-action fit-content">
          <h1 class="text-action">{{ title }}</h1>
        </div>
        <button class="str-button">
          <div class="str-media">
            <img
              v-if="mediaType === 'image'"
              class="w-100"
              sizes="164px"
              decoding="auto"
              :srcset="story"
              @error="storyLoadError"
            />
            <video v-if="mediaType === 'video'" controls @error="storyLoadError">
              <source :src="story" />
            </video>
          </div>
        </button>
      </div>
    </div>
    <div v-if="type === 'story_reply'" class="text-reply">
      <p>{{ messageReply }}</p>
    </div>
  </div>
</template>

<script>
import { i18n } from 'locales';

export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'story_mention'
    }
  },

  data() {
    return {
      title: i18n.t('src.modules.chat.components.ChatBox.index.mention_story'),
      storyError: false,
    };
  },

  computed: {
    mediaType() {
      return this.message.fileContent.type || 'image';
    },
    story() {
      if (this.storyError) return '';
      return this.message.fileContent.url;
    },
    messageReply() {
      return this.message.text;
    }
  },

  watch: {
    storyError(value) {
      if (value && this.type === 'story_mention') {
        this.title = this.$t(
          'src.modules.chat.components.ChatBox.index.mention_story_has_expired_or_deleted'
        );
      }
    }
  },

  async created() {
    if (this.type === 'story_reply') {
      this.title = this.$t('src.modules.chat.components.ChatBox.index.reply_story');
    }
  },

  methods: {
    storyLoadError() {
      this.storyError = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.stories {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}
.str-border {
  margin-top: 33px;
  margin-right: 15px;
  width: 0;
  border: 2px solid rgba(var(--b38, 219, 219, 219), 1);
  border-radius: 2px;
}
.fit-content {
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
}
.str-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
}
.str-text-action {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
.text-action {
  text-align: left;
  display: block;
  color: rgba(var(--f52, 142, 142, 142), 1);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: -2px 0 -3px;
}
.str-button {
  border: 0;
  color: #0095f6;
  color: rgba(var(--d69, 0, 149, 246), 1);
  display: inline;
  padding: 0;
  position: relative;
}
.str-media {
  height: auto;
  width: 164px;
  border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
  border-radius: 22px;
  justify-content: center;
  overflow: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
video {
  width: 100%;
  height: auto;
}
.text-reply {
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
