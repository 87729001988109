import * as actions from './actions';
import { SET_USER_LABELS } from './types';

const mutations = {
  [SET_USER_LABELS]() {}
};

export default {
  namespaced: true,
  actions,
  mutations
};
