<template>
  <div class="w-100 ml--10">
    <comment-item
      v-for="comment in comments"
      :key="comment.id"
      :detail="comment_id"
      v-bind="comment"
      @click="handleCommentItemClick(comment)"
    />
  </div>
</template>

<script>
import CommentItem from './CommentItem';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    CommentItem
  },

  data() {
    return {
      comment_id: null
    };
  },

  computed: {
    ...mapState('comment', ['comments', 'inFilterMode', 'replies'])
  },

  methods: {
    ...mapActions('comment', ['setSelectedComment', 'setReadComment', 'getRepliesByCommentId']),

    handleCommentItemClick(comment) {
      const { replies_loaded = false, comment_id, is_read = 0, new_reply = 0 } = comment;
      if (!replies_loaded) {
        this.getRepliesByCommentId({ comment_id });
      }
      this.comment_id = comment_id;
      this.setSelectedComment(comment);
      if (!is_read || new_reply) {
        this.setReadComment(this.comment_id);
      }
      this.$emit('selected', comment);
    }
  }
};
</script>

<style scoped></style>
