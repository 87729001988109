/* global window, FB, document */

export const loadFbSdk = (appId, version) => {
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      FB.init({
        appId,
        xfbml: false,
        version,
        cookie: true
      });
      FB.AppEvents.logPageView();
      resolve('SDK Loaded!');
    };
    const fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById('facebook-jssdk')) {
      return;
    }
    const js = document.createElement('script');
    js.id = 'facebook-jssdk';
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  });
};

export const getFbLoginStatus = () => {
  return new Promise(resolve => {
    window.FB.getLoginStatus(responseStatus => {
      resolve(responseStatus);
    });
  });
};

export const fbLogin = options => {
  return new Promise(resolve => {
    window.FB.login(response => resolve(response), options);
  });
};

export const fbLogout = () => {
  return new Promise(resolve => {
    window.FB.logout(response => resolve(response));
  });
};
