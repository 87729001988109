import Service from 'core/service';

const service = new Service();

const maintainService = new Service(process.env.VUE_APP_MAINTAIN_SERVICE_ENDPOINT);

/** new code */

// removeConversationLabel
export const removeConversationLabel = (_, input) => {
  return maintainService.rest('labels/deleteLabel', input, {
    method: 'POST'
  });
};

// removeConversationLabel
export const createConversationLabel = (_, input) => {
  return maintainService.rest('labels/createNewLabel', input, {
    method: 'POST'
  });
};
export const updateLabel = (_, input) => {
  return maintainService.rest('labels/updateLabel', input, {
    method: 'POST'
  });
};

export const updateTag = async (_, { projectId, tag }) => {
  const rtl = await service.rest('tags/updateTagById', {
    projectId,
    id: tag.id,
    value: tag.value,
    type: tag.type
  });
  return rtl;
};
export const updateTicketsLabelValue = async (_, { projectId, tag }) => {
  const rtl = await service.rest('tags/updateTicketsLabelValue', {
    projectId,
    tag
  });
  let { scrollId } = { ...rtl };
  while (scrollId) {
    const rtlScrollId = await service.rest('tags/updateTicketsLabelValue', {
      projectId,
      tag,
      oldScrollId: scrollId
    });
    scrollId = rtlScrollId.scrollId || null;
  }
  return true;
};

export const updateTagToTicket = async (_, { ticket, tags }) => {
  const payload = { ...ticket };
  payload.tags = JSON.stringify(tags);
  await service.rest('conversations/exec', {
    payload
  });
};
