<template>
  <div class="sort-product-panel">
    <label style="font-weight: 600; font-size: 1rem">
      <span class="nat-required">* </span
      >{{ $t('src.modules.chat.components.ProductPanel.sort-combo.sort_by') }}</label
    >
    <b-input-group>
      <el-select v-model="selected" class="el-project">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        ></el-option>
      </el-select>

      <b-input-group-text slot="append">
        <span>
          <i
            v-if="sortType"
            class="fas fa-sort-amount-up"
            style="cursor: pointer"
            @click="sort(false)"
          />
        </span>
        <span>
          <i
            v-if="!sortType"
            class="fas fa-sort-amount-down"
            style="cursor: pointer"
            @click="sort(true)"
          />
        </span>
      </b-input-group-text>
    </b-input-group>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      selected: 'name',
      sortFlag: true,
      options: [
        {
          value: 'availability',
          text: this.$t(
            'src.modules.chat.components.ProductPanel.sort-combo.availability'
          )
        },
        {
          value: 'brand',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.brand')
        },
        {
          value: 'condition',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.condition')
        },
        {
          value: 'description',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.description')
        },
        {
          value: 'gender',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.gender')
        },
        {
          value: 'name',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.name')
        },
        {
          value: 'price',
          text: this.$t('src.modules.chat.components.ProductPanel.sort-combo.price')
        }
      ]
    };
  },

  computed: {
    sortType() {
      return this.sortFlag;
    }
  },

  watch: {
    selected() {
      this.handleProductType();
    }
  },

  beforeDestroy() {
    this.setProdSearchType('name');
    this.setProdSearchSort(1);
  },

  methods: {
    ...mapActions('chat', ['setProdSearchSort', 'setProdSearchType']),

    handleProductType() {
      this.setProdSearchType(this.selected);
    },

    sort(flag) {
      const _order = flag ? 1 : -1;
      this.setProdSearchSort(_order);
      this.sortFlag = flag;
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-required {
  color: red;
}

.input-group {
  margin-bottom: 10px;
  /deep/ .input-group-text {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 1px solid #cad1d7 !important;
  }

  .select-pro-dth {
    width: calc(100% - 36px);
    /deep/ .nat-select-verti {
      /deep/ .el-input__inner {
        height: 40px !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1;
      }
    }

    /deep/ label {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .sort-pro-dth {
    padding-top: 21px;
  }
}
</style>
