var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":(_vm.name + "-" + _vm.prefix),"name":(_vm.name + "-" + _vm.prefix),"rules":_vm.c_rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.align === 'horizontal')?_c('div',[_c('div',{staticClass:"nat-input-select-group-prepend"},[_c('div',{staticClass:"nat-input-select-group-text",style:(_vm.c_labelWidthStyle)},[(_vm.label !== '')?_c('label',[(_vm.required)?_c('span',{staticClass:"nat-required"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"nat-input-select-group-tooltip"},[_c('base-tooltip-icon',{attrs:{"id":("tooltip-" + _vm.name),"name":("tooltip-" + _vm.name),"content":_vm.tooltipText,"icon":_vm.tooltipIcon}})],1)]),_c('div',{staticClass:"nat-input-select-group-value"},[_c('div',{staticClass:"nat-input-select-group-value-maxlength"},[(_vm.c_contentLength > 0 && _vm.showWordLimit)?_c('span',{class:['nat-maxlengthTag']},[_c('span',{class:[
                ((_vm.maxlength - _vm.c_contentLength < 0 || _vm.c_contentLength - _vm.minlength <= 0
                    ? 'error'
                    : '') + " ")
              ]},[_vm._v(" "+_vm._s(_vm.c_contentLength))]),_vm._v(" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-input-select-hori",attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxlength,"name":_vm.name,"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"id":_vm.name,"type":"text"},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"change":function($event){return _vm.handleChange(_vm.content)},"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}]}})])]),(errors[0] !== 'hidden')?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.hintText !== '')?_c('div',{staticClass:"nat-hint-text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]):_vm._e(),(_vm.align === 'vertical')?_c('div',[(_vm.label !== '')?_c('label',[(_vm.required)?_c('span',{staticClass:"nat-required"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" "),_c('base-tooltip-icon',{attrs:{"id":("tooltip-" + _vm.name),"name":("tooltip-" + _vm.name),"content":_vm.tooltipText,"icon":_vm.tooltipIcon}}),(_vm.c_contentLength > 0 && _vm.showWordLimit)?_c('span',{class:['nat-maxlengthTag']},[_c('span',{class:[
            ((_vm.maxlength - _vm.c_contentLength < 0 || _vm.c_contentLength - _vm.minlength < 0
                ? 'error'
                : '') + " ")
          ]},[_vm._v(" "+_vm._s(_vm.c_contentLength))]),_vm._v(" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"nat-input-select-verti-append"},[_c('div',{style:(_vm.c_selectWidthStyle)},[_c('el-select',{on:{"change":_vm.handleChangeSelect},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item[_vm.optionValue],attrs:{"label":item[_vm.optionLable],"value":item[_vm.optionValue]}})}),1)],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-input-select-verti",attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxlength,"name":_vm.name,"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"id":_vm.name,"type":"text"},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"change":function($event){return _vm.handleChange(_vm.content)},"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}]}})]),(errors[0] !== 'hidden')?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.hintText !== '')?_c('div',{staticClass:"nat-hint-text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }