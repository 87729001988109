import {
  SET_PROJECTS_SETTING,
  SET_CUSTOMER_SUPPORT_HISTORY,
  UPDATE_TREE_STORE,
  SET_SEARCHTEXT,
  DELETE_CUSTOMER_SUPPORT_HISTORY,
  UPDATE_CUSTOMER_SUPPORT_HISTORY,
  CHANNELS_FILTER_MAP,
  ADD_CONVERSATION,
  GET_AGENT_GROUPS,
  UPDATE_CUSTOMER_DATA,
  GET_ACTION_CLICK_CSH
} from './types';
import { i18n } from 'locales';
import flatten from 'lodash/flatten';
import baseNotification from 'components/BaseComponents/BaseNotification';
import Service from 'core/service';
import { convertTreeData } from 'core/helpers';
const service = new Service();
const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;
/**
 * @author Phuong.Nguyen
 * @param {String} projectId
 * @returns {Object} data
 */
export const getCustomerSupportSettings = async ({ commit, state, rootState }) => {
  const {
    session: { projectMaps }
  } = rootState;
  if (!projectMaps) return {};
  const projectsGroups = Object.keys(projectMaps).reduce((res, key) => {
    const project = projectMaps[key];
    if (project.hasGroup) {
      Object.values(project.groups).forEach(group => {
        res = [...res, { projectId: group.projectId, groupId: group.id }];
      });
    } else {
      res = [...res, { projectId: key }];
    }
    return res;
  }, []);

  const projectsSetting = {
    categories: {},
    customerFields: {},
    templates: {},
    templateIdMaps: {}
  };
  if (state.settingReady) {
    Promise.all(
      projectsGroups.map(({ projectId, groupId }) =>
        service
          .post('customerSupports/getCustomerSupportSettingsByKey', {
            projectId,
            groupId,
            mode: true
          })
          .then(response => {
            const { templateHistoryMap, customerSupportSettings } = response;
            if (customerSupportSettings && Object.keys(customerSupportSettings).length > 0) {
              const { content, id: templateId, name } = customerSupportSettings;
              const mapId = groupId || projectId;
              if (templateId && mapId) {
                let { category, customerFields } = content || {};
                category = category ? category : {};
                projectsSetting.templateIdMaps[mapId] = templateId;
                projectsSetting.categories[templateId] = _biuldTreeData(category);
                projectsSetting.customerFields[templateId] = customerFields;
                projectsSetting.templates[mapId] = {
                  ...(projectsSetting.templates[mapId] || {}),
                  ...{ [templateId]: name }
                };
              }
            }

            if (templateHistoryMap && Object.keys(templateHistoryMap).length > 0) {
              Object.keys(templateHistoryMap).map(item => {
                if (item && templateHistoryMap[item]) {
                  const { content, id: templateId, name } = templateHistoryMap[item];
                  const mapId = groupId || projectId;
                  if (templateId && mapId) {
                    let { category, customerFields } = content || {};
                    category = category ? category : {};
                    projectsSetting.templateIdMaps[mapId] = templateId;
                    projectsSetting.categories[templateId] = _biuldTreeData(category);
                    projectsSetting.customerFields[templateId] = customerFields;
                    projectsSetting.templates[mapId] = {
                      ...(projectsSetting.templates[mapId] || {}),
                      ...{ [templateId]: name }
                    };
                  }
                }
              });
            }
          })
      )
    ).then(() => {
      commit(SET_PROJECTS_SETTING, projectsSetting);
    });
    return null;
  } else {
    await Promise.all(
      projectsGroups.map(({ projectId, groupId }) =>
        service
          .post('customerSupports/getCustomerSupportSettingsByKey', {
            projectId,
            groupId,
            mode: true
          })
          .then(response => {
            const { templateHistoryMap, customerSupportSettings } = response || {};
            if (customerSupportSettings && Object.keys(customerSupportSettings).length > 0) {
              const { content, id: templateId, name } = customerSupportSettings || {};
              const mapId = groupId || projectId;
              if (templateId && mapId) {
                let { category, customerFields } = content || {};
                category = category ? category : {};
                projectsSetting.templateIdMaps[mapId] = templateId;
                projectsSetting.categories[templateId] = _biuldTreeData(category);
                projectsSetting.customerFields[templateId] = customerFields;
                projectsSetting.templates[mapId] = {
                  ...(projectsSetting.templates[mapId] || {}),
                  ...{ [templateId]: name }
                };
              }
            }

            if (templateHistoryMap && Object.keys(templateHistoryMap).length > 0) {
              Object.keys(templateHistoryMap).map(item => {
                if (item && templateHistoryMap[item]) {
                  const { content, id: templateId, name } = templateHistoryMap[item] || {};
                  const mapId = groupId || projectId;
                  if (templateId && mapId) {
                    let { category, customerFields } = content || {};
                    category = category ? category : {};
                    projectsSetting.templateIdMaps[mapId] = templateId;
                    projectsSetting.categories[templateId] = _biuldTreeData(category);
                    projectsSetting.customerFields[templateId] = customerFields;
                    projectsSetting.templates[mapId] = {
                      ...(projectsSetting.templates[mapId] || {}),
                      ...{ [templateId]: name }
                    };
                  }
                }
              });
            }
          })
      )
    );

    if (projectsSetting) commit(SET_PROJECTS_SETTING, projectsSetting);
    return projectsSetting;
  }
};

const _sortObject = obj => {
  return Object.keys(obj)
    .sort()
    .reduce((a, v) => {
      a[v] = obj[v];
      return a;
    }, {});
};

const _biuldTreeData = category => {
  const { treeData } = category;

  if (!treeData || !treeData.length) return {};
  const newTreeData = convertTreeData(treeData, null);
  return newTreeData;
};

const _traverse = (obj, parentId, expandedLvl) => {
  let parent = [];
  for (let k in obj) {
    let child = new Object();
    if (obj[k] && typeof obj[k] === 'object') {
      if (Object.keys(obj[k]).length > 0) {
        child.data = {};
        child.text = k;
        child.state = {
          expanded: false
        };
        if (expandedLvl === 1) {
          //eslint-disable-next-line
          console.log('[_traverse] expandedLvl', expandedLvl);
        }

        if (parentId) {
          child.data.id = `${parentId}::${k}`;
        } else {
          child.data.id = k;
        }
        child.children = _traverse(_sortObject(obj[k]), child.data.id);
      } else {
        child.text = k;
        child.children = [{ text: '...' }];
      }
    } else {
      child.data = {
        activeNode: true
      };
      if (parentId) {
        child.data.id = `${parentId}::${k}`;
      } else {
        child.data.id = k;
      }
      child.text = obj[k];
    }
    parent.push(child);
  }
  return parent;
};

/**
 * @author Phuong.Nguyen
 * @param {String} projectId
 * @param {String} categoryId
 * @returns {Array} data
 */
export const getCustomerSupportHistoryByCategory = async (
  { commit },
  { projectId, platform, channelId, categoryId, templateId, groupId, dateFrom, dateTo, agentId }
) => {
  try {
    if (!projectId) {
      commit(SET_CUSTOMER_SUPPORT_HISTORY, []);
      return [];
    }

    const params = {};
    params.projectId = projectId;
    params.categoryId = categoryId || null;
    if (platform !== '') params.platform = platform;
    if (channelId !== '') params.channelId = channelId;
    if (groupId !== '') params.groupId = groupId;
    if (templateId !== '') params.templateId = templateId;
    if (dateFrom !== '') params.dateFrom = dateFrom;
    if (dateTo !== '') params.dateTo = dateTo;
    if (agentId) params.agentId = agentId;

    let customerSupports = [];
    let nextKey = null;

    do {
      const data = await service.post('customerSupports/getCustomerSupports', {
        ...params,
        nextKey
      });

      if (data) {
        customerSupports.push(data.results || []);
        nextKey = data.nextKey || null;
      }
    } while (nextKey);

    customerSupports = flatten(customerSupports);
    commit(SET_CUSTOMER_SUPPORT_HISTORY, customerSupports);
    return customerSupports;
  } catch (error) {
    // eslint-disable-next-line
    console.log('getCustomerSupportHistoryByCategory >> error', error);
    return [];
  }
};

export const deleteCustomerSupports = ({ commit }, { id }) => {
  return service
    .post('customerSupports/deleteCustomerSupports', { id: id })
    .then(data => {
      commit(DELETE_CUSTOMER_SUPPORT_HISTORY, id);
      baseNotification.success({
        title: i18n.t('src.core.App.success'),
        message: i18n.t('src.modules.customer.support.history.store.actions.item_was_deleted')
      });
      return data;
    })
    .catch(() => {
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.customer.support.history.store.actions.deleted_failed_item')
      });
      return [];
    });
};

export const resetHistoryGrid = ({ commit }) => {
  commit(SET_CUSTOMER_SUPPORT_HISTORY, []);
};

export const updateTree = ({ commit }, tree) => {
  commit(UPDATE_TREE_STORE, tree);
};

export const setSearchText = ({ commit }, text) => {
  commit(SET_SEARCHTEXT, text);
};

export const getConversationTicketById = async (
  { commit },
  { conversationId, ticketId, userId, channelId }
) => {
  let userInfo = null;
  let conversationInfo = null;
  const userService = new Service(URL_USER);
  if (userId) {
    const [rtl1, rtl2] = await Promise.all([
      userService.post('personal/getUserById', { userId, channelId }),
      service.post('conversations/getConversationTicketById', {
        conversationId,
        ticketId
      })
    ]);
    userInfo = rtl1;
    conversationInfo = rtl2;
    if (!userInfo || !conversationInfo)
      return {
        userInfo,
        conversationInfo
      };
  } else {
    conversationInfo = await service.post('conversations/getConversationTicketById', {
      conversationId,
      ticketId
    });
    if (!conversationInfo) return { conversationInfo };
  }
  commit(ADD_CONVERSATION, { conversationInfo, userInfo });

  return conversationInfo;
};

export const updateConversationById = async ({ commit }, conversation) => {
  commit(UPDATE_CUSTOMER_SUPPORT_HISTORY, conversation);
};

export const setChannelsFilterMap = async ({ commit }, channelsFilter) => {
  commit(CHANNELS_FILTER_MAP, channelsFilter);
};

/**
 * @author Cong Pham
 * @param {String} agentId
 * @returns {Array} data
 */
export const getAgentGroupsByAgentId = async ({ commit }, { agentId }) => {
  if (!agentId || agentId.length === 0) {
    commit(GET_AGENT_GROUPS, []);
    return [];
  }

  const params = {};
  params.agentId = agentId;
  const data = await service.post('agentGroups/getAgentGroups', params);
  if (data) commit(GET_AGENT_GROUPS, data);
  return data;
};

export const updateCustomerData = async ({ commit }, data) => {
  commit(UPDATE_CUSTOMER_DATA, data);
};

export const getActionClickCSH = async ({ commit }, params) => {
  const { userId = '', channelId = '' } = params;
  commit(GET_ACTION_CLICK_CSH, { userId, channelId });
};
