<template>
  <div class="input-group">
    <div v-b-tooltip.hover :title="user.firstName" class="input-group-prepend">
      <img :src="user.pictureUrl" class="avatar shadow-sm" />
    </div>
    <div class="input-reply-dth">
      <base-textarea
        v-model="text"
        :align="align"
        :show-word-limit="false"
        :rows="5"
        :placeholder="placeholder"
        :disabled="!can_reply()"
        :maxlength="-1"
        @keyup="handleKeyUp"
      />
    </div>
    <div v-if="can_reply()" class="input-group-append">
      <span
        class="input-group-text"
        style="border-right: 1px solid #cad1d7; cursor: pointer"
        @click="handleBtnSendClick"
      >
        <button class="btn btn-sm btn-link text-capitalize p-0 pr-10">
          {{ $t('common.text.send') }}
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapState, mapActions, mapGetters } from 'vuex';
import { CHANNEL_MODE } from 'core/constants';

export default {
  data() {
    return {
      text: '',
      label: '',
      align: 'vertical',
      placeholder: ' '
    };
  },

  computed: {
    ...mapState('session', ['user', 'channelsMap']),
    ...mapState('comment', ['selectedChannel', 'selectedComment']),
    ...mapGetters('comment', ['selectedPost']),

    isMaintain() {
      return (
        this.channelsMap &&
        this.selectedChannel &&
        this.channelsMap[this.selectedChannel.id].mode === CHANNEL_MODE.MAINTAIN
      );
    },

    channelName() {
      return this.selectedChannel && this.selectedChannel.name;
    },

    channelPlatform() {
      return this.selectedChannel && this.selectedChannel.platform;
    },

    postId() {
      return this.selectedPost && this.selectedPost.post_id;
    },

    commentId() {
      return this.selectedComment.comment_id;
    }
  },

  methods: {
    ...mapActions('comment', ['replyToComment']),

    handleBtnSendClick() {
      if (this.isMaintain) {
        EventBus.$emit('maintenanceModeModal');
        return true;
      }

      if (!this.can_reply()) return;
      const message = `${this.text}`.replace(/\s/g, '');
      if (message.length === 0) return;
      this.replyToComment({
        post_id: this.postId,
        sender: this.channelName,
        message: this.text,
        parent_comment_id: this.commentId,
        page_id: this.selectedChannel.id,
        userpicture: this.selectedChannel.pictureUrl,
        agent_id: this.user.id,
        agent_name: `${this.user.firstName} ${this.user.lastName}`,
        platform: this.channelPlatform
      });
      this.text = '';
    },

    handleKeyUp(event) {
      const { ctrlKey, key } = event;
      if (ctrlKey && key === 'Enter') {
        this.handleBtnSendClick();
      }
    },
    can_reply() {
      return this.selectedComment.can_reply;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group {
  .input-group-prepend {
    background-color: #fff;
    border: 1px solid #cad1d7;
    padding: 3px;
    border-radius: 5px 0 0 5px;
    border-right: 0;
    padding-top: 35px;
    img {
      max-width: 38px;
      max-height: 38px;
    }
  }

  /deep/ .input-group-text {
    border-left: 0;
    width: 100%;
    height: 100%;
  }

  button {
    &:active,
    &:focus {
      outline: 0;
      -moz-outline-style: none;
    }
  }

  .input-reply-dth {
    width: calc(100% - 107px);
    /deep/ .nat-texarea-verti {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      border-left: 0;
    }

    /deep/ textarea.nat-texarea-verti {
      padding-left: 5px !important;
      resize: none;
    }

    /deep/ .nat-invalid-data {
      margin-top: 0px;
    }

    /deep/ textarea.nat-texarea-verti:focus {
      border-top: 1px solid #cad1d7;
      border-bottom: 1px solid #cad1d7;
    }
  }

  .input-group-append {
    border-left: solid 1px #cad1d7;
    button {
      padding-right: 5px !important;
      padding-left: 5px !important;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style></style>
