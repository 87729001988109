<template>
  <b-container fluid>
    <div class="table-content-wrap">
      <b-row style="margin-bottom: 5px;">
        <b-col ld="12" xl="12">
          <!-- Search -->
          <b-input-group>
            <b-input-group-text slot="prepend" class="search-icon">
              <i class="fas fa-search"></i>
            </b-input-group-text>
            <b-form-input
              v-model="searchText"
              :placeholder="
                $t('src.modules.search-management.file.index.search_by.file_name')
              "
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Main table -->
        <b-col ld="12" xl="12">
          <div class="table-wrap">
            <b-table
              :items="c_detectFile"
              :fields="fileList"
              :current-page="pageNumber"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sticky-header="true"
              class="nat-table m-b-30"
              responsive
              striped
              hover
              thead-class="nat-table-header"
              show-empty
            >
              <!-- Show row empty data  -->
              <template slot="empty">
                <div class="text-center">
                  {{ $t('common.text.no_data') }}
                </div>
              </template>

              <!-- No. -->
              <template slot="index" slot-scope="data">{{
                data.index + 1 + perPage * (pageNumber - 1)
              }}</template>

              <!-- preview -->
              <template slot="preview" slot-scope="data">
                <img
                  v-if="data.item.type === 'image'"
                  :src="data.item.content"
                  alt="..."
                  height="100"
                  width="100"
                  @error="$event.target.src = imageNotFound"
                />
                <audio
                  v-else-if="data.item.type === 'audio'"
                  ref="audioPlayer"
                  :src="data.item.content"
                  controls="true"
                  style="height:50px;width:140px"
                />
                <video
                  v-else-if="data.item.type === 'video'"
                  style="height:100px;width:100px"
                  controls
                >
                  <source :src="data.item.content" type="video/mp4" />
                  <source :src="data.item.content" type="video/webm" />
                  <p>
                    Your browser doesn't support HTML5 video. Here is a
                    <a :href="data.item.content">link to the video</a> instead.
                  </p>
                </video>
                <p v-else-if="data.item.type === 'file'">
                  <i
                    class="fa fa-file fa-3"
                    aria-hidden="true"
                    style="font-size: 50px;"
                  ></i>
                </p>
              </template>

              <!-- createdAt -->
              <template slot="createdAt" slot-scope="data">{{
                formatTime(data.value)
              }}</template>

              <!-- File Name -->
              <template slot="text" slot-scope="data">
                <p
                  :id="data.value + data.index + 1 + perPage * (pageNumber - 1)"
                  style="cursor: pointer;"
                >
                  {{ _getFileNameFromUrl(data.value) | truncate(20) }}
                </p>
                <b-tooltip
                  triggers="hover"
                  :target="data.value + data.index + 1 + perPage * (pageNumber - 1)"
                  :delay="100"
                  boundary="viewport"
                  placement="top"
                  >{{ _getFileNameFromUrl(data.value) }}</b-tooltip
                >
              </template>

              <!-- URL -->
              <template slot="content" slot-scope="data">
                <a
                  :id="data.value + data.index + 1 + perPage * (pageNumber - 1)"
                  :href="data.value"
                  target="_blank"
                  >{{ data.value | truncate(45) }}</a
                >
                <b-tooltip
                  triggers="hover"
                  :target="data.value + data.index + 1 + perPage * (pageNumber - 1)"
                  :delay="100"
                  boundary="viewport"
                  placement="top"
                  >{{ data.value }}</b-tooltip
                >
              </template>
            </b-table>
          </div>
          <!-- pagging -->
          <b-row>
            <b-col v-if="totalRows > 0" md="12" class="bt-paging">
              <b-pagination
                v-model="pageNumber"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0 pull-right"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import path from 'path';
import moment from 'moment';
import flattenDeep from 'lodash/flattenDeep';
import imageNotFound from 'assets/images/image.png';

export default {
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      fileList: {
        index: {
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        preview: {
          label: this.$t('src.modules.search-management.file.index.preview'),
          class: 'nat-table-col-preview'
        },
        text: {
          label: this.$t('src.modules.search-management.file.index.file_name'),
          sortable: true,
          class: 'nat-table-col-file-name'
        },
        content: {
          label: this.$t('src.modules.search-management.file.index.url'),
          sortable: true,
          class: 'nat-table-col-url'
        }
      },
      sortBy: 'createdAt',
      sortAsc: true,
      searchText: '',
      pageNumber: 1,
      perPage: 5,
      imageNotFound
    };
  },

  computed: {
    totalRows() {
      return this.c_detectFile.length;
    },

    c_detectFile() {
      if (!this.files || this.files.length === 0) return [];

      let data = this.files.map(item => {
        if (item.type === 'template') {
          return this.m_detectFileTypeTemplate(item);
        } else {
          return { ...item, text: this.m_getTextToURL(item.content) };
        }
      });
      data = flattenDeep(data);
      data = data.filter(i =>
        i.text.toLowerCase().includes(this.searchText.toLowerCase())
      );
      return data;
    }
  },

  methods: {
    // Check image_multiple
    m_detectFileTypeTemplate(data) {
      if (data && data.content) {
        const content = JSON.parse(data.content);
        if (content.template_type === 'image_multiple') {
          const { elements = [] } = content;
          return elements.map(item => {
            return {
              type: item.media_type,
              content: item.url,
              text: this.m_getTextToURL(item.url)
            };
          });
        }
      }
      return [];
    },

    m_getTextToURL(url) {
      if (!url) return '';
      return path.basename(url) || '';
    },

    formatTime(value) {
      if (value) return moment(value).format('DD MMM YYYY LT');
      return null;
    },

    _getFileNameFromUrl(url) {
      if (!url) return '';
      let urlDecoded = decodeURIComponent(url);
      let arrayPath = decodeURIComponent(urlDecoded).split('?') || [];
      let imagePath = arrayPath[0] || '';
      let imageFileName = imagePath.split('/').pop();
      return imageFileName;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-wrap {
  height: 400px;
  overflow-y: auto;
}

.page-header {
  text-align: center;
}

.page-cover-container {
  margin: 0 50px 50px 50px;
}

.search-icon {
  width: 30px !important;
  min-width: 30px !important;
}

.table-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bt-paging {
  padding-top: 15px;
}

.navigation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

body {
  overflow-y: auto !important;
  font-size: 12px !important;
}

tbody td {
  width: 12.5%;
  float: left;
  overflow-wrap: break-word;
}
tbody {
  overflow-wrap: auto;
}

thead th {
  width: 12.5%;
  float: left;
  height: 70px;
}

@media only screen and (min-width: 1200px) {
  /* For mobile phones: */
  .pd-r-unset {
    padding-right: unset;
  }

  .pd-l-unset {
    padding-left: unset;
  }
}

.nat-table {
  tbody {
    display: block !important;
    overflow: auto !important;
    background: white;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* even columns width , fix width of table too*/
  }
}
.nat-table-header {
  background-color: #2196f3;
  color: #ffffff;
  text-align: center !important;
  .nat-table-col-no {
    text-align: center !important;
  }
  .nat-table-col-action {
    text-align: center !important;
  }
}
/deep/.nat-table-col-no {
  text-align: center !important;
  width: 20px;
}
/deep/.nat-table-col-preview {
  width: 80px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.nat-table-col-file-name {
  width: 80px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.nat-table-col-url {
  width: 100px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.nat-table-col-action {
  width: 40px;
  text-align: center !important;
}
</style>
