<template>
  <b-modal
    id="ForwardGroup"
    ref="forwardGroup"
    v-model="show"
    size="lg"
    :title="
      $t(
        'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.forward_group'
      )
    "
  >
    <div class="forwardGroup-body">
      <!-- Title Channel Nane (S) -->
      <div class="form-group">
        <label class="text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold">
          <i class="fas fa-grip-vertical m-r-10"></i>
          {{ channelName }}
        </label>
      </div>
      <el-row>
        <el-col :span="11"
          ><div class="grid-content">
            <el-card class="box-card">
              <base-select
                v-model="currentGroup"
                :align="'vertical'"
                :label="$t('src.components.Modal.ForwardGroup.index.current_group')"
                :options="groups"
                :name="'currentGroup'"
                :id="'currentGroup'"
                :value-type="'String'"
                :disabled="true"
                :required="true"
              />
            </el-card></div
        ></el-col>
        <el-col :span="2"
          ><div class="grid-content arrow-right"><i class="el-icon-right"></i></div
        ></el-col>
        <el-col :span="11"
          ><div class="grid-content">
            <el-card class="box-card">
              <base-select
                v-model="newGroup"
                :align="'vertical'"
                :label="$t('src.components.Modal.ForwardGroup.index.new_group')"
                :options="groups.filter(i => i.key !== currentGroup && i.isEnable)"
                :name="'newGroup'"
                :id="'newGroup'"
                :value-type="'String'"
                :required="true"
              />
            </el-card></div
        ></el-col>
      </el-row>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-btn
        class="float-right"
        variant="primary"
        style="margin-left: 7px"
        :disabled="disableConfirm"
        @click="confirmForwardGroup"
      >
        {{ $t('BaseConfirm.yes') }}
      </b-btn>
      <b-btn class="float-right" variant="default" @click="resetAllState">{{
        $t('BaseConfirm.no')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import { compareName, getActions } from 'core/helpers';
import { TICKET_STATUS, TICKET_STATE, SERVING_STATE, ACTION } from 'core/constants';

function defaultState() {
  return {
    show: false,
    ticket: {},
    channelName: '',
    groups: [],
    newGroup: '',
    currentGroup: '',
    disableConfirm: false
  };
}

export default {
  components: {},

  data: defaultState,

  computed: {
    ...mapState('session', ['user', 'channelsMap', 'projectMaps']),
    ...mapGetters('chat', ['selectedConversation'])
  },

  created() {
    EventBus.$on('forwardGroup', async ticket => this.handlerOpenPopup(ticket, true));
    EventBus.$on('ticketAlreadyUpdated', newTicket => {
      const { id, state } = newTicket || {};
      const { id: oldId } = this.ticket || {};
      if (id === oldId) {
        if ([TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state))
          return (this.show = false);
        this.ticket = { ...this.ticket, ...newTicket };

        const actions = getActions(this.user, this.ticket, this.projectMaps);
        if (!actions[ACTION.FORWARD_GROUP]) return (this.show = false);
      }
    });
  },

  destroyed() {
    this.resetAllState();
    EventBus.$off('forwardGroup', () => (this.show = false));
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('session', ['setWaitingMode']),
    ...mapActions('chat', [
      'setShowView',
      'updateAndEmitTicket',
      'setSelectedGroupConversation'
    ]),

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handlerOpenPopup(ticket, bool) {
      this.show = bool;
      if (!bool || !ticket) return null;
      const { channelId = '', projectId = '', groupId = '' } = ticket;
      const { name: channelName = '' } = this.channelsMap && this.channelsMap[channelId];

      let { groups = {} } = this.projectMaps && this.projectMaps[projectId];
      groups = Object.values(groups).map(g => ({ key: g.id, name: g.name, isEnable: g.isEnable }));
      groups = groups.sort((a, b) => compareName(a, b, 'name'));

      const groupFilter = groups.filter(i => i.key !== groupId && i.isEnable);
      const defaultNewGroup = groupFilter.shift();
      if (!defaultNewGroup) this.disableConfirm = true;
      
      this.ticket = ticket;
      this.groups = groups;
      this.currentGroup = groupId;
      this.channelName = channelName;
      this.newGroup = defaultNewGroup && defaultNewGroup.key || '';
    },

    confirmForwardGroup() {
      this.setGlobalReady(false);
      const params = {
        meId: this.user.id,
        ticket: this.ticket,
        groupId: this.newGroup,
        agentId: TICKET_STATUS.FORWARD_GROUP,
        status: TICKET_STATUS.FORWARD_GROUP
      };

      return this.updateAndEmitTicket(params)
        .then(data => {
          if (!data) return this.setGlobalReady(true);

          const { id: ticketId = '' } = this.ticket;
          const { ticket: { id: selectedTicketId = '' } = {} } =
            this.selectedConversation || {};
          if (ticketId === selectedTicketId) {
            this.setShowView('Chat');
            this.setWaitingMode('COMMON');
            this.setSelectedGroupConversation(SERVING_STATE.WAITING);
          }

          this.show = false;
          this.setGlobalReady(true);
          return true;
        })
        .catch(() => {
          this.setGlobalReady(true);
          return false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
#ForwardGroup {
  .arrow-right {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
