<template>
  <div data-role="container">
    <!-- Message type tab (S) -->
    <group-nav
      :is-search="isSearch"
      :has-project-in-bot-handle="hasProjectInBotHandle"
      :has-project-in-monitoring="hasProjectInMonitoring"
    />
    <!-- Message type tab (E) -->

    <!-- Waiting tab (S)  -->
    <b-tabs v-show="isStateWaiting" class="tab-waiting">
      <b-tab :active="waitingMode === 'COMMON'" @click="handleOnClickTab('COMMON')">
        <template slot="title">
          <i
            v-show="c_existTicketCommon"
            style="color: #f5365c ;font-size: 16px;"
            class="material-icons"
            >priority_high</i
          >
          {{ $t('src.modules.chat.components.AsideLeft.index.common') }}
        </template>
      </b-tab>
      <b-tab :active="waitingMode === 'TOME'" @click="handleOnClickTab('TOME')">
        <template slot="title">
          <i
            v-show="c_existTicketToMe"
            style="color: #f5365c;font-size: 16px;"
            class="material-icons"
            >priority_high</i
          >
          {{ $t('src.modules.chat.components.AsideLeft.index.to_me') }}
        </template>
      </b-tab>
    </b-tabs>
    <!-- Waiting tab (E)  -->

    <!-- Project List (S)  -->
    <div
      v-bar
      :style="{
        'max-height': `calc(100vh - ${getSearchBoxHeight + tabHeight}px)`,
        height: `calc(100vh - ${getSearchBoxHeight + tabHeight}px)`
      }"
    >
      <div class="nat-project-list">
        <div>
          <el-menu
            :default-openeds="dflOpnPjtLst"
            :default-active="defaultActive"
            :collapse="false"
            active-text-color="#409EFF"
            @open="handleOpenNavPjt"
            @close="handleCloseNavPjt"
          >
            <c-project
              v-for="(p, index) in this.projectList"
              :key="index"
              :channels="projectMapList[p.key]"
              :id="p.key"
              :is-search="isSearch"
              :name="p.name"
              :tab-index="tabIndex"
              :selected-conversation="selectedConversation"
              :keep-alive="keepAlive"
              @onSelectConversation="handleOnSelectConversation"
            />
          </el-menu>
        </div>
      </div>
    </div>
    <!-- Project List (E)  -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { SERVING_STATE, TABS } from 'core/constants';
import { hasButtonNew, getTabIndex } from 'core/helpers';
import isEmpty from 'lodash/isEmpty';
import GroupNav from './GroupNav';
import CProject from './Project';

export default {
  components: {
    GroupNav,
    CProject
  },
  data() {
    return {
      SERVING_STATE: SERVING_STATE,
      testCount: 0,
      commentFilter: {},
      projectMapList: {},
      dflOpnPjtLstMap: {},
      dflOpnPjtLst: null
    };
  },

  computed: {
    ...mapState('session', [
      'channels',
      'user',
      'waitingMode',
      'commonWaitingCountsMap',
      'commonWaitingLoadedCountsMap',
      'meWaitingLoadedCountsMap',
      'meWaitingCountsMap',
      'agentHandleCountsMap',
      'botHandleCountsMap',
      'ticketsBySearchChannelMap',
      'ticketCountBySearchChannelMap',
      'projectMaps',
      'channelsMap',
      'hasUpdateTicketCome',
      'selectedChannel'
    ]),
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'monitoringList',
      'meWaitingList',
      'isSearch',
      'channelsMessenger'
    ]),
    ...mapState('chat', [
      'selectedGroupConv',
      'localesFilter',
      'searchTextConversation',
      'searchBoxFilter',
      'searchBoxHeight',
      'chatViewingGroup'
    ]),
    ...mapGetters('chat', ['selectedConversation']),

    hasProjectInBotHandle() {
      const projectMapList = this.filterProjectMaps(SERVING_STATE.BOT);
      return isEmpty(projectMapList) ? false : true;
    },

    hasProjectInMonitoring() {
      const projectMapList = this.filterProjectMaps(SERVING_STATE.MONITORING);
      return isEmpty(projectMapList) ? false : true;
    },

    tabIndex() {
      switch (this.selectedGroupConv) {
        case SERVING_STATE.BOT:
          return TABS.BOT;
        case SERVING_STATE.WAITING:
          return this.waitingMode === 'COMMON' ? TABS.WAITING_COMMON : TABS.WAITING_ME;
        case SERVING_STATE.MONITORING:
          return TABS.MONITORING;
        default:
          return TABS.ME;
      }
    },

    keepAlive() {
      let tabIndex = TABS.ME;
      switch (this.chatViewingGroup) {
        case SERVING_STATE.BOT:
          tabIndex = TABS.BOT;
          break;
        case SERVING_STATE.WAITING:
          tabIndex = this.waitingMode === 'COMMON' ? TABS.WAITING_COMMON : TABS.WAITING_ME;
          break;
        case SERVING_STATE.MONITORING:
          tabIndex = TABS.MONITORING;
          break;
        default:
          tabIndex = TABS.ME;
          break;
      }
      let channelId = this.selectedChannel;
      if (this.selectedConversation && isEmpty(this.searchBoxFilter)) {
        channelId = this.selectedConversation.channelId;
        tabIndex = getTabIndex({ conv: this.selectedConversation, user: this.user });
        if (this.chatViewingGroup == SERVING_STATE.MONITORING) {
          tabIndex = TABS.MONITORING;
        }
      }
      return { tabIndex, channelId };
    },

    defaultActive() {
      if (this.keepAlive) {
        const channel = this.channelsMap[this.keepAlive.channelId];
        return channel && channel.projectId;
      }
      return null;
    },

    projectList() {
      this.getProjectMapList();
      const ordered = [];
      const arrayKey = Object.keys(this.projectMapList) || [];
      for (let i = 0; i < arrayKey.length; i++) {
        const key = arrayKey[i];
        ordered.push({ key, name: this.getProjectName(key) });
      }

      return ordered.sort((ca, cb) => ca.name.localeCompare(cb.name));
    },

    channelList() {
      switch (this.selectedGroupConv) {
        case SERVING_STATE.COMMENTS: {
          return this.channels
            .filter(channel => {
              if (Object.keys(this.commentFilter).length > 0) {
                const lstText = this.commentFilter.text || [];
                if (
                  lstText.length > 0 &&
                  !lstText.some(text => channel.name.toLowerCase().includes(text.toLowerCase()))
                )
                  return false;

                const platform = this.commentFilter.platform;
                if (platform && platform.toLowerCase() !== channel.platform) return false;
              }
              return (
                (channel.platform === 'facebook' && channel.facebookComment === true) ||
                channel.platform === 'instagram'
              );
            })
            .sort((ca, cb) => ca.name.localeCompare(cb.name));
        }
        default: {
          return this.channels
            .filter(channel => {
              return channel.platform !== 'instagram';
            })
            .sort((ca, cb) => ca.name.localeCompare(cb.name));
        }
      }
    },

    commonTotal() {
      let sum = 0;
      Object.values(this.commonWaitingCountsMap).forEach(count => count && (sum += count));
      return sum;
    },

    meTotal() {
      let sum = 0;
      Object.values(this.meWaitingCountsMap).forEach(count => count && (sum += count));
      return sum;
    },

    commonWaitingCount() {
      return this.commonWaitingList.length;
    },
    commonWaitingLoadedCount() {
      const obj = this.commonWaitingLoadedCountsMap;
      return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
    },
    meWaitingLoadedCount() {
      const obj = this.meWaitingLoadedCountsMap;
      return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
    },

    meWaitingCount() {
      return this.meWaitingList.length;
    },

    isStateWaiting() {
      return this.selectedGroupConv === SERVING_STATE.WAITING;
    },

    getSearchBoxHeight() {
      return this.searchBoxHeight + 65;
    },

    tabHeight() {
      switch (this.selectedGroupConv) {
        case SERVING_STATE.BOT:
          return 0;
        case SERVING_STATE.WAITING:
          return 40;
        case SERVING_STATE.MONITORING:
          return 0;
        default:
          return 0;
      }
    },

    c_existTicketToMe() {
      const hasNewToMe = hasButtonNew({
        tabIndex: TABS.WAITING_ME,
        hasUpdateTicketCome: this.hasUpdateTicketCome
      });
      if (this.meWaitingCount > 0 || this.meTotal > 0 || hasNewToMe === true) {
        return true;
      }
      return false;
    },
    c_existTicketCommon() {
      const hasNewCommon = hasButtonNew({
        tabIndex: TABS.WAITING_COMMON,
        hasUpdateTicketCome: this.hasUpdateTicketCome
      });
      if (this.commonWaitingCount > 0 || this.commonTotal > 0 || hasNewCommon === true) {
        return true;
      }
      return false;
    }
  },

  watch: {
    selectedConversation(newVal, oldVal) {
      if (oldVal && newVal.ticket && oldVal.ticket && newVal.ticket.id === oldVal.ticket.id) return;
      this.updateWaitingMode();
    },

    selectedGroupConv() {
      this.updateWaitingMode();
    }
  },

  methods: {
    ...mapActions('session', ['setWaitingMode']),
    ...mapActions('chat', ['setSearchBoxFilter', 'setSearchBoxHeight']),

    handleOnClickTab(value) {
      if (Object.keys(this.searchBoxFilter).length > 0) {
        this.setSearchBoxFilter({});
        this.setSearchBoxHeight(95);
      }
      this.setWaitingMode(value);
    },

    handleOnSelectConversation(conversation) {
      this.$emit('onSelectConversation', 'CHAT_LIST', conversation);
    },

    handleOnSelectedChannel(channelId) {
      this.$emit('onSelectedChannel', channelId);
    },

    setWaitingSearchMode(value) {
      this.setWaitingMode(value);
      this.setSearchBoxHeight(95);
      this.setSearchBoxFilter({});
    },

    updateWaitingMode() {
      if (
        this.chatViewingGroup === this.selectedGroupConv &&
        this.selectedGroupConv === SERVING_STATE.WAITING
      ) {
        const { ticket } = this.selectedConversation || {};
        const isCommon = this.commonWaitingList.some(
          conversation => conversation.ticket && ticket && conversation.ticket.id === ticket.id
        );
        if (isCommon) {
          this.setWaitingMode('COMMON');
        } else {
          const isTome = this.meWaitingList.some(
            conversation => conversation.ticket && ticket && conversation.ticket.id === ticket.id
          );
          if (isTome) {
            this.setWaitingMode('TOME');
          }
        }
      }
    },

    handleCommentFilter(filter) {
      this.commentFilter = filter || {};
    },

    getProjectName(projectId) {
      if (this.projectMaps[projectId] && this.projectMaps[projectId].name)
        return this.projectMaps[projectId].name;
      return 'undefined';
    },

    getProjectMapList() {
      switch (this.selectedGroupConv) {
        case SERVING_STATE.COMMENTS: {
          this.projectMapList = this.channels.reduce((pjts, chnl) => {
            const { projectId, configs = {} } = chnl;
            if (!pjts[projectId]) pjts[projectId] = [];

            if (Object.keys(this.commentFilter).length > 0) {
              const lstText = this.commentFilter.text || [];
              if (
                lstText.length > 0 &&
                !lstText.some(text => chnl.name.toLowerCase().includes(text.toLowerCase()))
              ) {
                pjts[projectId] = pjts[projectId] || [];
                return pjts;
              }

              const platform = this.commentFilter.platform;
              if (platform && platform.toLowerCase() !== chnl.platform) {
                pjts[projectId] = pjts[projectId] || [];
                return pjts;
              }
            }
            if (
              (chnl.platform === 'facebook' && configs.facebookComment) ||
              (chnl.platform === 'instagram' && configs.instagramComment)
            ) {
              pjts[projectId] = [...(pjts[projectId] || []), ...[chnl]];
            }
            return pjts;
          }, {});
          break;
        }
        default: {
          this.projectMapList = this.filterProjectMaps(this.selectedGroupConv);
          break;
        }
      }

      if (this.dflOpnPjtLst == null && Object.keys(this.projectMapList).length > 0) {
        this.dflOpnPjtLst = Object.keys(this.projectMapList);
      }
    },

    filterProjectMaps(selectedGroupConv) {
      const projectMapList = this.channelsMessenger.reduce((pjts, chnl) => {
        const { projectId = '', advancedConfigs = {} } = chnl;
        const { modulesActivation = {} } = advancedConfigs;
        const { bot = false } = modulesActivation;
        if (SERVING_STATE.BOT === selectedGroupConv && !bot) return pjts;

        if (!pjts[projectId]) pjts[projectId] = [];
        pjts[projectId] = [...(pjts[projectId] || []), ...[chnl]];
        return pjts;
      }, {});

      return projectMapList;
    },

    handleOpenNavPjt(key) {
      this.dflOpnPjtLst.push(key);
    },
    handleCloseNavPjt(key) {
      this.dflOpnPjtLst = [...this.dflOpnPjtLst.filter(item => item != key)];
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-project-list {
  /deep/.el-submenu__title {
    background: rgba(233, 236, 239, 0.6);
  }

  /deep/.el-menu {
    width: 100% !important;
  }
}

.count-common {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 33px;
}
.count-tome {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 224px;
}
</style>
