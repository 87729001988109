<template>
  <b-modal
    id="ReplacingMessageViewForm"
    ref="replacingMessageViewForm"
    v-model="show"
    :title="$t('common.text.view_form')"
    @hidden="onCloseModel"
    size="lg"
  >
    <div v-loading="isLoading" class="details-request">
      <b-row v-for="(item, index) in rowData" :key="index" class="my-1">
        <b-col sm="3">
          <label for="input-small">{{ item.label }} :</label>
        </b-col>
        <b-col v-if="item.type === 'text'" sm="9">
          <label for="input-small">{{ item.value }}</label>
        </b-col>
        <b-col v-else-if="item.type === 'date'" sm="9">
          <label for="input-small">{{ formatTimeData(item.value) }}</label>
        </b-col>
        <b-col v-else-if="item.type === 'switch'" sm="9">
          <el-switch v-model="form.replaceAllMessages" :disabled="true"></el-switch>
        </b-col>
        <b-col v-else-if="item.type === 'tag'" sm="9">
          <div class="no-has-role-edit-tag">
            <el-tag
              v-for="tag in item.value.map(i => {
                return { name: i, type: '' };
              })"
              :id="tag.name"
              :key="tag.name"
              :closable="false"
              :type="tag.type"
              :disable-transitions="true"
            >
            {{ form.status === requestStatus.APPROVED ? '* * *' :tag.name }}
              <b-tooltip
                :target="tag.name"
                :delay="100"
                boundary="viewport"
                placement="top"
                triggers="hover"
                >{{
                  form.status === requestStatus.APPROVED
                    ? $t(
                        'src.modules.replacing-message.index.keyword_hided_contact_admin'
                      )
                    : tag.name
                }}</b-tooltip
              >
            </el-tag>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="3">
          <label for="input-small"
            >{{
              $t('src.modules.replacing-message.components.RequestForm.index.message')
            }}
            :</label
          >
        </b-col>
        <b-col sm="9">
          <label v-if="form.replacingKeywords && form.replacingKeywords.length > 0">
            {{
              $t(
                'src.modules.replacing-message.components.RequestForm.index.message_will_be_affected',
                { number: msgUserTicket.length }
              )
            }}
          </label>
          <ul v-loading="isLoadingMessage" class="infinite-list" style="overflow: auto">
            <li
              v-for="(msg, index) in msgUserTicket"
              :key="index"
              class="infinite-list-item"
            >
              <span>
                <p
                  style="margin-bottom: 0px"
                  v-html="formatWarnings(escapeHtmlChar(msg.content || msg))"
                ></p
              ></span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-btn
        v-if="isPermission && !isApproved"
        class="float-right"
        variant="primary"
        @click="onSubmit('REJECTED')"
        >{{
          $t('src.modules.replacing-message.components.RequestForm.index.reject')
        }}</b-btn
      >
      <b-btn
        v-if="isPermission && !isApproved"
        class="float-right"
        variant="primary"
        style="margin-left: 10px"
        @click="onSubmit('APPROVED')"
        >{{
          $t('src.modules.replacing-message.components.RequestForm.index.approve')
        }}</b-btn
      >
      <b-btn class="float-right" variant="default" @click="onCloseModel">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { EventBus } from 'core/eventBus';
import { mapActions, mapState } from 'vuex';
import { AGENT_ROLE } from 'core/constants';
import { getGroupIdsOfAgent } from 'core/helpers';
import { urlify, escapeHtmlChar } from 'core/helpers';
import { validateKeyword } from '../../store/helpers';
import { handleReplaceMessage } from '../../store/helpers';
import { REPLACING_MESSAGE_REQUEST_STATUS } from 'core/constants';
import { convertTimeUTC } from 'core/helpers';

function defaultState() {
  return {
    show: false,
    msgUserTicket: [],
    rowData: [],
    projectId: '',
    channelId: '',
    firstLoadMessages: true,
    isLoading: false,
    isLoadingMessage: false,
    isApproved: false,
    isPermission: false,
    form: {},
    requestStatus: REPLACING_MESSAGE_REQUEST_STATUS || {}
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('session', ['user', 'channelsMap', 'projectMaps']),
    ...mapState('replacingMessage', ['messagesUserTicket']),

    hasApproveRejectRole() {
      const { role = '' } = this.user;
      const projectId = this.projectId || '';
      if (AGENT_ROLE.LEADER === role) return true;
      else if (AGENT_ROLE.MODERATOR === role) {
        const groupIds = getGroupIdsOfAgent(projectId, this.user);
        if (groupIds.length > 0) return true;
      }

      return false;
    },

    sortKeyWord() {
      const form = cloneDeep(this.form);
      const { replacingKeywords = [] } = form;
      if (replacingKeywords.length === 0) return [];
      return replacingKeywords.sort((a, b) => b.length - a.length);
    }
  },

  watch: {
    async form(newValue) {
      if (isEmpty(newValue)) return;

      this.isLoading = true;
      const { title, replaceAllMessages, replacingKeywords, createdAt, updatedAtBy, createdByAgent, updatedByAgent, channelId, projectId, status } = newValue;
      const { messageReplaced, selectedMessage } = newValue;
      const { name: channelName } = this.channelsMap && this.channelsMap[channelId];
      const { name: projectName } = this.projectMaps && this.projectMaps[projectId];

      if (REPLACING_MESSAGE_REQUEST_STATUS.APPROVED === status) {
        if (!replaceAllMessages && !messageReplaced) {
          await this.getMessagesUserTicket({
            ticketId: newValue.ticketId,
            sender: newValue.userId,
            channelId: newValue.channelId,
            projectId: newValue.projectId,
            type: 'text',
            conversationId: newValue.conversationId,
            messageId: newValue.messageId
          });
          this.msgUserTicket = this.messagesUserTicket;
        } else {
          this.msgUserTicket = messageReplaced || [];
        }
      } else {
        if (!replaceAllMessages) {
          this.msgUserTicket = [selectedMessage];
        } else {
          await this.getMessagesUserTicket({
            ticketId: newValue.ticketId,
            sender: newValue.userId,
            channelId: newValue.channelId,
            projectId: newValue.projectId,
            type: 'text',
            conversationId: newValue.conversationId
          });
          this.filterMsgUserTicket(replacingKeywords);
        }
      }

      const rowData = [];
      rowData.push({
        label: this.$t('src.modules.profile.components.user-project.project'),
        value: projectName,
        type: 'text'
      });
      rowData.push({
        label: this.$t('src.modules.customer-support-history.index.channel'),
        value: channelName,
        type: 'text'
      });
      rowData.push({
        label: this.$t('src.modules.search-management.index.title'),
        value: title,
        type: 'text',
        required: true
      });
      rowData.push({
        label: this.$t(
          'src.modules.replacing-message.components.RequestForm.index.replacing_keywords'
        ),
        value: replacingKeywords,
        type: 'tag',
        required: true
      });
      rowData.push({
        label: this.$t('src.modules.replacing-message.index.requested-date'),
        value: createdAt,
        type: 'date'
      });
      rowData.push({
        label: this.$t(
          'src.modules.replacing-message.index.requested-agent'
        ),
        value: createdByAgent,
        type: 'text'
      });
      rowData.push({
        label: this.$t('src.modules.replacing-message.index.approved-rejected-date'),
        value: updatedAtBy,
        type: 'date'
      });
      rowData.push({
        label: this.$t(
          'src.modules.replacing-message.index.approved-rejected-agent'
        ),
        value: updatedByAgent,
        type: 'text'
      });
      rowData.push({
        label: this.$t(
          'src.modules.replacing-message.components.RequestForm.index.replace_all_messages'
        ),
        value: replaceAllMessages,
        type: 'switch'
      });
      this.rowData = rowData;
      this.projectId = projectId;
      this.channelId = channelId;
      this.isLoading = false;
    }
  },

  created() {
    EventBus.$on('replacingMessageViewForm', (data = null) => {
      this.handlerOpenPopup(true, data);
    });
  },

  destroyed() {
    EventBus.$off('replacingMessageViewForm', (data = null) =>
      this.handlerOpenPopup(false, data)
    );
  },

  methods: {
    ...mapActions('replacingMessage', [
      'handleRequestReplaceMessage',
      'updateRequests',
      'getMessagesUserTicket',
      'getAllRequest'
    ]),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('session', ['addAgentToPeople']),

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    async onSubmit(status) {
      try {
        this.setGlobalReady(false);
        if (
          REPLACING_MESSAGE_REQUEST_STATUS.APPROVED === status ||
          REPLACING_MESSAGE_REQUEST_STATUS.REJECTED === status
        ) {
          const { createdByAgent } = this.form;
          const request = { ...this.form, status };
          delete request.updatedAtBy;
          delete request.updatedByAgent;
          delete request.updatedBy;
          delete request.isPermission;
          const res = await this.handleRequestReplaceMessage(request);
          if(res && res.error) {
            if(res.error.code === 'NO_PERMISSION_APPROVED') {
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: this.$t(
                  'src.modules.replacing-message.index.error.permission.approved'
                )
              });
            } else if(res.error.code === 'NO_PERMISSION_REJECTED'){
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: this.$t(
                  'src.modules.replacing-message.index.error.permission.rejected'
                )
              });
            }
          } else {
            const { isNew } = request;
            if (!isNew) {
              this.updateRequests({ ...res, createdByAgent });
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t(
                  'src.modules.replacing-message.components.RequestForm.index.update_successful'
                )
              });
            } else {
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t(
                  'src.modules.replacing-message.components.RequestForm.index.create_successful'
                )
              });

            }
            
            await this.getAllRequest({
              agentId: this.user.id,
              projectId: this.projectId,
              channelId: this.channelId || null
            });
          }
          
        }
        this.onCloseModel();
        this.setGlobalReady(true);
      } catch (error) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: error.message
        });
        this.setGlobalReady(true);
      }
    },

    onCloseModel() {
      this.resetAllState();
      this.show = false;
    },

    async handlerOpenPopup(bool, data) {
      this.show = bool;
      if (!bool || !data) {
        this.resetAllState();
        return;
      }
      const { isNew } = data;
      this.isPermission = this.user.role !== AGENT_ROLE.CLIENT && data.curItem.isPermission || false;
      if (!isNew) {
        this.isApproved = REPLACING_MESSAGE_REQUEST_STATUS.NEW !== data.curItem.status;
        const { curItem } = data;
        this.form = {
          ...this.form,
          ...curItem,
          isNew
        };
      }
    },

    filterMsgUserTicket(keyword) {
      if (keyword.length <= 0) return (this.msgUserTicket = this.messagesUserTicket);

      const newMsgUserTicket = this.messagesUserTicket.filter(item => {
        let valid = false;
        for (let i = 0; i < keyword.length; i++) {
          valid = validateKeyword(item.content, keyword[i]);
          if (valid) break;
        }

        if (valid) return item;
      });

      this.msgUserTicket = newMsgUserTicket;
    },

    formatTimeData(date) {
      return date ? convertTimeUTC(date) : '';
    },

    formatWarnings(message) {
      return handleReplaceMessage(message, this.sortKeyWord);
    },

    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/a {
  color: #7dbcfc !important;
}
/deep/.badge-warning {
  /deep/a {
    color: #ff3709 !important;
  }
}
.no-has-role-edit-tag {
  /deep/.el-tag--light {
    cursor: pointer;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.infinite-list {
  padding-left: 0px;
  max-height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;

  .infinite-list-item {
    display: flex;
    align-items: center;
    background: #e8f3fe;
    color: #7dbcfc;
    padding-left: 10px;
    margin-bottom: 4px;
    margin-right: 4px;

    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #d9ecff;

    span {
      background: #e8f3fe;
      color: #7dbcfc;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
</style>
