<template>
  <button
    data-testid="btnLoginFb"
    :disabled="loading"
    class="btn btn-secondary btn-cons btn-block"
    type="button"
    @click="signinWithFacebook"
  >
    <span v-if="!loading" class="btn-inner--icon m-r-5">
      <img style="height: 25px;" src="~assets/images/icons/common/facebook.svg" />
    </span>
    <i v-else class="fas fa-spinner fa-spin fa-lg m-r-5" />
    <span class="btn-inner--text">{{ $t('src.components.FacebookSignin.index.facebook') }}</span>
  </button>
</template>

<script>
import { loadFbSdk, getFbLoginStatus, fbLogout, fbLogin } from './helpers.js';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  methods: {
    async signinWithFacebook() {
      this.$emit('loading', true);

      if (!window.FB) {
        await loadFbSdk(process.env.VUE_APP_FACEBOOK_APP_ID, 'v3.1');
      }
      const { status } = await getFbLoginStatus().catch(error =>
        this.$log('TCL: signinWithFacebook -> getFbLoginStatus -> error', error)
      );

      if (status === 'connected') {
        await fbLogout().catch(error =>
          this.$log('TCL: signinWithFacebook -> fbLogout -> error', error)
        );
      }

      await fbLogin({
        scope: 'email,instagram_basic,instagram_manage_comments,instagram_manage_messages',
        auth_type: 'reauthenticate',
        auth_nonce: '' + Math.random()
      })
        .then(response => {
          if (response && response.authResponse) {
            const { accessToken } = response.authResponse;
            accessToken && this.$emit('signIn', accessToken);
          } else {
            this.$emit(
              'error',
              this.$t(
                'src.components.FacebookSignin.index.facebook_aunthentication_error'
              )
            );
          }
        })
        .catch(error => {
          this.$emit('error', error);
        });
    }
  }
};
</script>
