import { render, staticRenderFns } from "./changePasswordFirstLogin.vue?vue&type=template&id=3f70e98a&scoped=true&"
import script from "./changePasswordFirstLogin.vue?vue&type=script&lang=js&"
export * from "./changePasswordFirstLogin.vue?vue&type=script&lang=js&"
import style0 from "./changePasswordFirstLogin.vue?vue&type=style&index=0&id=3f70e98a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f70e98a",
  null
  
)

export default component.exports