import fetchAPI from 'core/fetchAPI';
import moment from 'moment';

export class ReportService extends fetchAPI {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_REPORT_API_URL}/`,
      headers: {
        'x-api-key': process.env.VUE_APP_REPORT_API_KEY
      },
      _options: {}
    });
  }

  async getReportFile(
    year,
    month,
    channelId,
    reportType,
    selectedColumns = [],
    timezone = null,
    language
  ) {
    const range = getRangeFromYearMonth(year, month);
    const result = await this.post('report/export', {
      channelId,
      fromDate: range.fromDate,
      toDate: range.toDate,
      reportType,
      columns: selectedColumns,
      timezone,
      language
    }).then(res => {
      if (res.data.data) {
        const data = res.data.data;
        if (data.downloadUrl) {
          return {
            status: 'success',
            downloadUrl: data.downloadUrl
          };
        }
      }
      return null;
    });
    return result;
  }

  async createSchedule(data, reportType) {
    const result = await this.post('schedule/create', { ...data, reportType })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { status, error_key = '' } = res.data.data;
          return { status, error_key };
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    return result;
  }

  async exportLogCreate(data) {
    const result = await this.post('exportLog/create', { ...data })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { status, id } = res.data.data;
          return { status, id };
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    return result;
  }

  async removeSchedule({ id, userId }) {
    const result = await this.post('schedule/remove', { id, userId })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { status } = res.data.data;
          return status;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    return result;
  }

  async startStopSchedule({ id, userId, status }) {
    const path = status === 'stop' ? 'schedule/stop' : 'schedule/start';
    const result = await this.post(path, { id, userId })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { status } = res.data.data;
          return status;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    return result;
  }

  async getSchedulesList(userId, channelId, language, reportType) {
    const result = await this.get('schedule/list', {
      userId,
      channelId,
      language,
      reportType
    })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { data = [] } = res.data;
          return data.map(item => {
            const { exportCondition, userName } = item;
            const { type } = exportCondition;
            const { fromDate, toDate } = exportCondition;
            const { monthDay, weekDay } = exportCondition;

            // Convert data to display UI
            item['type'] = type;
            item['agentName'] = userName || '';
            fromDate ? (item['fromDate'] = fromDate) : null;
            toDate ? (item['toDate'] = toDate) : null;
            weekDay ? (item['weekDay'] = weekDay) : null;
            monthDay ? (item['monthDay'] = monthDay) : null;

            // Delete fields
            delete item.userName;

            return item;
          });
        }
        return [];
      })
      .catch(() => {
        return [];
      });
    return result;
  }

  async getScheduleHistory(userId, channelId, reportType) {
    const result = await this.get('schedule/history', {
      userId,
      channelId,
      reportType
    })
      .then(res => {
        if (res && res.data && res.data.data) {
          const { data = [] } = res.data;
          return data.map(item => {
            const { period, userName, exportResult = '' } = item;

            // Convert data to display UI
            item['type'] = period;
            item['agentName'] = userName || '';

            item['exportedFileName'] =
              exportResult && exportResult !== 'empty_link' ? exportResult : '';
            item['exportedFileLink'] =
              exportResult && exportResult !== 'empty_link' ? exportResult : '';

            // Delete fields
            delete item.userName;

            return item;
          });
        }
        return [];
      })
      .catch(() => {
        return [];
      });
    return result;
  }

  async getDownloadUrl(data) {
    window.console.log(data);
    return await this.post('report/download_url', data).then(res => {
      if (res.data && res.data.data && res.data.data.status === 'success') {
        return res.data.data;
      }
      return { status: 'failed' };
    });
  }
}

function addDay(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

function getRangeFromYearMonth(year, month) {
  const monthIndex = month - 1;
  let fromDate = new Date(year, monthIndex, 1);
  let toDate = addDay(new Date(year, monthIndex + 1, 1), -1);
  fromDate = moment(fromDate).format('YYYY-MM-DD');
  toDate = moment(toDate).format('YYYY-MM-DD');
  return { fromDate, toDate };
}
