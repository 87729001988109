<template>
  <b-tooltip
    :target="id"
    :delay="delay"
    :boundary="boundary"
    :placement="placement"
    :triggers="triggers"
    :custom-class="customClass"
    >{{ content }}</b-tooltip
  >
</template>

<script>
function defaultState() {
  return {};
}

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 100
    },
    boundary: {
      type: String,
      default: 'viewport'
    },
    placement: {
      type: String,
      default: 'top'
    },
    triggers: {
      type: String,
      default: 'hover'
    },
    content: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },

  data: defaultState,

  computed: {},

  created() {},

  destroyed() {}
};
</script>
<style lang="scss" scoped>
</style>
