var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":"","header-tag":"header"}},[_c('h6',{directives:[{name:"b-toggle",rawName:"v-b-toggle.clRequestInformation",modifiers:{"clRequestInformation":true}}],staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_c('i',{class:_vm.showCollapse ? "fa fa-chevron-down" : "fa fa-chevron-right"}),_vm._v(" "+_vm._s(_vm.$t( 'src.modules.chat.components.CustomerSupportPanel.requestInformation.request_information' ))+" ")]),_c('b-collapse',{attrs:{"id":"clRequestInformation"},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('b-card-body',[(_vm.categoryPath && _vm.categoryPath.length > 0)?_c('b-breadcrumb',{attrs:{"items":_vm.categoryPath}}):_vm._e(),_c('b-row',[(!_vm.item)?_c('b-col',{attrs:{"cols":"md-12"}},[_c('div',{staticClass:"info-container"},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" "+_vm._s(_vm.labelCategory)+" "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('base-input',{attrs:{"name":'filter-file',"label":_vm.labelCategory,"icon":'search',"align":'horizontal',"maxlength":-1,"show-label":false,"placeholder-type":'search',"autocomplete":'off'},model:{value:(_vm.treeFilter),callback:function ($$v) {_vm.treeFilter=$$v},expression:"treeFilter"}}),_c('tree',{ref:"tree",staticClass:"zxczxczxc",attrs:{"options":_vm.categoryTreeOptions,"filter":_vm.treeFilter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
return _c('span',{staticClass:"tree-text"},[(!node.hasChildren() && node.text !== '...')?[_c('i',{staticClass:"ion-md-document"}),_vm._v(" "+_vm._s(node.text)+" ")]:_vm._e(),(!node.hasChildren() && node.text === '...')?[_c('i',{staticClass:"ion-md-close-circle"}),_vm._v(" "+_vm._s(node.text)+" ")]:_vm._e(),(node.hasChildren())?[_c('i',{class:[node.expanded() ? 'ion-md-folder-open' : 'ion-md-folder']}),_vm._v(" "+_vm._s(node.text)+" ")]:_vm._e()],2)}}],null,false,1207626528),model:{value:(_vm.selectedNode),callback:function ($$v) {_vm.selectedNode=$$v},expression:"selectedNode"}}),(!_vm.isSelectedNode)?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(" "+_vm._s(_vm.hintTextCategory)+" ")]):_vm._e()],1)]):_vm._e(),_c('b-col',{attrs:{"cols":"md-12"}},[_c('ValidationObserver',{ref:"CSPRequestInformation"},[_c('form',{attrs:{"role":"form","autocomplete":"off"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.customerAllFields),function(caf){return _vm._l((caf),function(c,idx){return _c('div',{key:c.key,class:[
                      'col-6',
                      c.label && c.label[_vm.displayLanguage] ? c.label[_vm.displayLanguage] : c.label,
                      'nat-h-center'
                    ]},[(c.type === 'label')?_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(c.label && c.label[_vm.displayLanguage] ? c.label[_vm.displayLanguage] : c.label)+" ")]):_vm._e(),(c.type !== 'label')?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[(c.type === 'text' && c.key)?_c('base-input',{attrs:{"data-test-id":("csf-" + (c.rules)),"prefix":'rqtInfo',"required":c.required,"rules":c.rules,"name":c.key,"placeholder":c.placeholder,"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"maxlength":c.maxlength,"disabled":_vm.disabledForm,"autocomplete":'off'},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e(),(c.type === 'text' && !c.key)?_c('base-input',{attrs:{"data-test-id":("csf-" + (c.rules)),"prefix":'rqtInfo',"required":c.required,"rules":c.rules,"name":idx,"placeholder":c.placeholder,"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"maxlength":c.maxlength,"disabled":_vm.disabledForm,"autocomplete":'off'},model:{value:(_vm.formData[idx]),callback:function ($$v) {_vm.$set(_vm.formData, idx, $$v)},expression:"formData[idx]"}}):_vm._e(),(c.type === 'textarea')?_c('base-textarea',{attrs:{"prefix":'rqtInfo',"required":c.required,"rules":c.rules,"name":c.key,"id":c.key,"placeholder":c.placeholder,"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"maxlength":c.maxlength,"disabled":_vm.disabledForm},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e(),(['datepicker', 'datetimepicker'].includes(c.type))?_c('base-date-picker',{attrs:{"prefix":'rqtInfo',"required":c.required,"rules":c.rules,"name":c.key,"id":c.key,"locale":_vm.getLocale,"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"disabled":_vm.disabledForm},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e(),(c.type === 'radio')?_c('base-radio-group',{ref:c.prop === 'sex' ? c.key : '',refInFor:true,attrs:{"prefix":'rqtInfo',"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"name":c.prop === 'sex' ? c.prop : c.key,"options":_vm.optionsDataRadio(
                              c.options && c.options[_vm.displayLanguage]
                                ? c.options[_vm.displayLanguage]
                                : c.options
                            ),"max-other-value":100,"allow-show-word-limit":true,"err-mess-other-value":c.prop === 'sex'
                              ? _vm.$t(
                                  'src.modules.profile.components.user-information.please_enter_your_gender'
                                )
                              : '',"option-lable":'value',"option-value":'key',"disabled":_vm.disabledForm,"required":c.required,"radio-other-value-error":_vm.radioOtherValueError,"full-width-radio":c.prop === 'sex' ? true : false},on:{"inputOtherValue":_vm.inputOtherValue},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e(),(c.type === 'checkbox')?_c('base-checkbox',{attrs:{"prefix":'rqtInfo',"required":c.required,"name":c.key,"disabled":_vm.disabledForm,"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e(),(c.type === 'combobox')?_c('base-select',{attrs:{"data-test-id":("csf-" + (c.rules)),"prefix":'rqtInfo',"options":_vm.optionsData(c),"name":c.key,"id":c.key,"value-type":'String',"option-type":'Object',"option-lable":'value',"option-value":'key',"label":c.label && c.label[_vm.displayLanguage]
                              ? c.label[_vm.displayLanguage]
                              : c.label,"required":c.required,"disabled":_vm.disabledForm},on:{"change":function($event){return _vm.changeCombo(c, $event)}},model:{value:(_vm.formData[c.key]),callback:function ($$v) {_vm.$set(_vm.formData, c.key, $$v)},expression:"formData[c.key]"}}):_vm._e()],1)]):_vm._e()])})})],2)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }