import Service from 'core/service';
import {
  SET_REQUEST_LIST,
  UPDATE_REQUEST_LIST,
  DELETE_REQUEST,
  MESSAGES_USER_TICKET
} from './types';
const service = new Service();

const handleRequestReplaceMessage = async (_, params) => {
  delete params['createdByAgent'];
  return service.rest('messages/replace/handleRequest', params).then(response => {
    return response;
  });
};

const deleteRequestReplaceMessage = async ({ commit }, params) => {
  return service.rest('messages/replace/deleteRequest', params).then(response => {
    commit(DELETE_REQUEST, params);
    return response;
  });
};

const getAllRequest = async ({ commit }, params) => {
  const { agentId = '', projectId = '', channelId = '' } = params;
  return service
    .rest('messages/replace/getAllRequest', {
      agentId,
      projectId,
      channelId: channelId || null
    })
    .then(response => {
      setRequests({ commit }, response);
      return response;
    });
};

const getLatestRequest = async (_, params) => {
  const { conversationId = null, ticketId = null } = params;
  const { projectId = '', channelId = null, messageId = null, status = null } = params;
  return service
    .rest('messages/replace/getLatestRequest', {
      projectId,
      channelId,
      conversationId,
      ticketId,
      messageId,
      status
    })
    .then(response => {
      return response;
    });
};

const getMessagesUserTicket = async ({ commit }, params) => {
  const {
    projectId = '',
    ticketId = '',
    channelId = '',
    sender,
    type = '',
    conversationId = '',
    keywords,
    messageId
  } = params;
  return service
    .rest('messages/replace/getMessagesUserTicket', {
      projectId,
      ticketId,
      sender,
      channelId,
      type,
      conversationId,
      keywords,
      messageId
    })
    .then(response => {
      commit(MESSAGES_USER_TICKET, response);
      return response;
    });
};

const setRequests = async ({ commit }, list) => {
  commit(SET_REQUEST_LIST, list);
};

const updateRequests = async ({ commit }, request) => {
  commit(UPDATE_REQUEST_LIST, request);
};

export {
  handleRequestReplaceMessage,
  deleteRequestReplaceMessage,
  getAllRequest,
  getLatestRequest,
  getMessagesUserTicket,
  setRequests,
  updateRequests
};
