<template>
  <div class="page-aside theme-clean">
    <div class="page-aside-inner">
      <template v-if="user.id">
        <!-- Chat tab: Phone Agent (S) -->
        <template v-if="isPhoneAgent">
          <phone-agent-search-box
            @hideAllPanel="() => this.$emit('hideAllPanel')" 
          />
          <phone-agent-project />
        </template>
        <!-- Chat tab: Phone Agent (E) -->

        <!-- Comment tab (S) -->
        <template v-else-if="selectedGroupConv === 3">
          <search-comment @onSearchChange="handleCommentFilter" />
          <comment-list :comment-filter="commentFilter" @onClick="handleOnSelectedChannel" />
        </template>
        <!-- Comment tab (E) -->

        <template v-else>
          <!-- Search component for chat tab (S) -->
          <search-box :style="isProd ? 'display: none;' : ''" :langsupports="user.langSupports || []" />
          <!-- Search component for chat tab (E) -->
          <div :style="isProd ? 'padding-top: 25px;' : ''" class="app-message-list scrollable">
            <chat-list
              :is-search="isSearch"
              @onSelectConversation="
                (type, conversation) => $emit('onSelectConversation', { type, conversation })
              "
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SERVING_STATE } from 'core/constants';
import SearchBox from './SearchBox';
import SearchComment from './SearchComment';
import PhoneAgentSearchBox from './PhoneAgent/search-box';
import PhoneAgentProject from './PhoneAgent/project';
import ChatList from './chat-list';
import CommentList from './Comment';
export default {
  components: {
    SearchBox,
    SearchComment,
    ChatList,
    CommentList,
    PhoneAgentSearchBox,
    PhoneAgentProject
  },
  data() {
    return {
      SERVING_STATE: SERVING_STATE,
      commentFilter: {}
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapGetters('session', ['isSearch', 'isPhoneAgent']),
    ...mapState('chat', ['selectedGroupConv', 'searchBoxFilter']),

    isProd() {
      return process.env.VUE_APP_STAGE === 'prod';
    }
  },

  methods: {
    handleCommentFilter(filter) {
      this.commentFilter = filter || {};
    },
    handleOnSelectedChannel(event) {
      this.$emit('onSelectedChannel', event);
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-project-list {
  /deep/.el-submenu__title {
    background: rgba(233, 236, 239, 0.6);
  }

  /deep/.el-menu {
    width: 100% !important;
  }
}

.count-common {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 33px;
}
.count-tome {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 224px;
}
</style>
