import isEmpty from 'lodash/isEmpty';

class HighlightUserPI {
  constructor(message) {
    this._message = message;
    this._keywords = [];
  }

  makeId() {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < 36; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  getIndex(regex, currentIndex) {
    let value;
    let indexes = [];
    while ((value = regex.exec(this._message)) !== null && value[0] !== null) {
      indexes.push({ index: value['index'], value: value[0] });
    }

    indexes = indexes.filter(i => i.index >= currentIndex);
    const firstObj = indexes && indexes.length ? indexes[0] : { index: -1, value: '' };
    return firstObj;
  }

  getHighlightTag(id, type) {
    return `<span class="badge-pill badge-info user_personal_information ${type}">${id}</span>`;
  }

  indexZipCodeGlobal(currentIndex) {
    const regexp = /(([0-9]{2,5}([-]?[0-9]{3,4}))|([A-Z0-9]{2,5}([ ]?[A-Z0-9]{1,4})))/g; // eslint-disable-line
    return { ...this.getIndex(regexp, currentIndex), type: 'highlight-zip-code-global' };
  }

  indexZipCodeJapan(currentIndex) {
    const regexp = /([0-9]{3}-?[0-9]{4})|(〒\s?[0-9]{3}-?[0-9]{4})/g; // eslint-disable-line
    return { ...this.getIndex(regexp, currentIndex), type: 'highlight-zip-code-japan' };
  }

  indexAddressJapan(currentIndex) {
    const regexp = /((北海|青森|岩手|宮城|秋田|山形|福島|茨城|栃木|群馬|埼玉|千葉|東京|神奈川|新潟|富山|石川|福井|山梨|長野|岐阜|静岡|愛知|三重|滋賀|京都|大阪|兵庫|奈良|和歌山|鳥取|島根|岡山|広島|山口|徳島|香川|愛媛|高知|福岡|佐賀|長崎|熊本|大分|宮崎|鹿児島|沖縄)[都道府県])((?:旭川|伊達|石狩|盛岡|奥州|田村|南相馬|那須塩原|東村山|武蔵村山|羽村|十日町|上越|富山|野々市|大町|蒲郡|四日市|姫路|大和郡山|廿日市|下松|岩国|田川|大村|宮古|富良野|別府|佐伯|黒部|小諸|塩尻|玉野|周南)市|(?:余市|高市|[^市]{2,3}?)郡(?:玉村|大町|.{1,5}?)[町村]|(?:.{1,4}市)?[^町]{1,4}?区|.{1,7}?[市町村])(.+)/g; // eslint-disable-line
    return { ...this.getIndex(regexp, currentIndex), type: 'highlight-address-japan' };
  }

  indexTelephoneGlobal(currentIndex) {
    const regexp = /^(((\(.[( ]{0,1}[+ ]{0,1}[0-9]+[)]{0,1}?\)|[+ ]{0,1}[0-9]+)[-―]{0,1}[0-9]{1,4}[-―]{0,1}[0-9]{1,4}[-―]{0,1}[0-9]{1,4})*((?![*]{2}|[#]{2}|[#]{1}[*]{1}|[*]{1}[#]{1})((#{1}|[*]{1})((\d)+(#{1}|[*]{1}))*)(?![*]{2}|[#]{2}|[#]{1}[*]{1}|[*]{1}[#]{1}))*)$/g; // eslint-disable-line
    return { ...this.getIndex(regexp, currentIndex), type: 'highlight-telephone-global' };
  }

  indexTelephoneJapan(currentIndex) {
    const regexp = /(((0(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}\-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})/g; // eslint-disable-line
    return { ...this.getIndex(regexp, currentIndex), type: 'highlight-telephone-japan' };
  }

  indexCreditCardGlobal(currentIndex) {
    const regexp = /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6011[0-9]{12}|65[0-9]{14}|3(?:0[0-5]|[68][0-9])[0-9]{11}|3[47][0-9]{13}|(?:2131|1800|35[0-9]{3})[0-9]{11})/g; // eslint-disable-line
    return {
      ...this.getIndex(regexp, currentIndex),
      type: 'highlight-credit-card-global'
    };
  }

  indexEmailAddressGlobal(currentIndex) {
    const regexp = /(?:[-!#-'*+/-9=?A-Z^-~]+(?:\.[-!#-'*+/-9=?A-Z^-~]+)*)@[-!#-'*+/-9=?A-Z^-~]+(?:\.[-!#-'*+/-9=?A-Z^-~]+)+/g; // eslint-disable-line
    return {
      ...this.getIndex(regexp, currentIndex),
      type: 'highlight-email-address-global'
    };
  }

  async runRegexp(currentIndex = -1) {
    const message = this._message;

    const indexes = [
      this.indexTelephoneJapan(currentIndex),
      this.indexTelephoneGlobal(currentIndex),
      this.indexAddressJapan(currentIndex),
      this.indexZipCodeJapan(currentIndex),
      this.indexZipCodeGlobal(currentIndex),
      this.indexCreditCardGlobal(currentIndex),
      this.indexEmailAddressGlobal(currentIndex)
    ];

    if (indexes.every(i => i.index < 0)) return this._message;
    let obj = {};
    for (let i = 0; i < indexes.length; i++) {
      const o = indexes[i];
      if (o.index < 0) continue;

      const { index, value } = o;
      if (isEmpty(obj)) obj = o;
      else if (obj.index > index) obj = o;
      else if (obj.index == index && obj.value.length < value.length) obj = o;
    }

    const index = obj.index || '';
    const value = obj.value || '';
    const type = obj.type || '';
    if (!value) return this._message;
    const id = this.makeId();
    const highlightTag = this.getHighlightTag(id, type);
    this._keywords.push({ id, value });
    this._message =
      message.substring(0, index) +
      highlightTag +
      message.substring(index + value.length, message.length);

    return this.runRegexp(index + highlightTag.length);
  }

  decode() {
    if (!this._keywords || !this._keywords.length) return this._message;
    for (let i = 0; i < this._keywords.length; i++) {
      const { id, value } = this._keywords[i];
      this._message = this._message.replace(id, value);
    }

    return this._message;
  }
}

export default HighlightUserPI;
