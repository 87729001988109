<template>
  <section class="section-shaped my-0 page-cover">
    <div class="shape shape-style-1 shape-default shape-skew alpha-4" />
  </section>
</template>

<script>
export default {};
</script>

<style></style>
