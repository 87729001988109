import { get } from 'lodash';
import moment from 'moment';
import store from 'core/store';
import { MAPPING_ROLE } from 'core/constants';

// mapping with Agent user object
const agentParamsMaps = {
  agent_first_name: 'firstName', //fixed now
  agent_native_first_name: 'firstNameNativeLanguage', //fixed now
  agent_last_name: 'lastName', //fixed now
  agent_email: 'email', //fixed now
  agent_operation: 'operator.name', //fixed now
  agent_company: 'company.name', //fixed now
  agent_division: 'division.name', //fixed now
  agent_phone_number: 'contactNumber', //fixed now
  agent_role: 'role' //fixed now
};
// mapping with People user object
const userParamsMaps = {
  user_email: 'email', //fixed now
  user_platform_name: 'userPlatformName', //fixed now
  user_full_name: 'fullName', //fixed now
  user_first_name: 'firstName', //fixed now
  user_last_name: 'lastName', //fixed now
  user_birthday: 'birthday', //fixed now
  user_gender: 'gender', //fixed now
  user_phone: 'phoneNumber', //fixed now
  user_address: 'address', //fixed now
  user_city: 'city', //fixed now
  user_country: 'country', //fixed now
  user_province: 'province', //fixed now
  user_postal_code: 'postCode', //fixed now
  user_marital_status: 'maritalStatus', //fixed now
  user_relationship: 'relationship', //fixed now
  user_military_status: 'militaryStatus', //fixed now
  user_occupation: 'occupation', //fixed now
  user_work_phone: 'workPhoneNumber', //fixed now
  user_work_email: 'workEmail', //fixed now
  user_company_name: 'companyName', //fixed now
  user_privacy_policy: 'privacyPolicy', //fixed now
  user_tc: 'tc' //fixed now
};
const userCustomParamsMaps = {
  user_custom_1: 'FIELD1', //fixed now
  user_custom_2: 'FIELD2', //fixed now
  user_custom_3: 'FIELD3' //fixed now
};

class Params {
  constructor() {
    const { state = {}, getters = {} } = store;
    const { session: { user = {}, people = {}, channelsMap = {}, projectMaps = {} } = {} } = state;
    this.user = user;
    this.people = people;
    this.channelsMap = channelsMap;
    this.projectMaps = projectMaps;
    this.getAgentUser = getters['session/getAgentUser'] || {};
    this.selectedConversation = getters['chat/selectedConversation'] || {};
    this.userId = this.selectedConversation.userId || '';
    this.channelId = this.selectedConversation.channelId || '';
    this.projectId = this.selectedConversation.projectId || '';
  }

  getParamsDisplayAgent() {
    const { advancedConfigs: { params: { displayAgent = {} } = {} } = {} } =
      this.channelsMap[this.channelId] || {};
    return displayAgent;
  }

  getCustomizeFields() {
    const { config: { person: { customizeFields = {} } = {} } = {} } =
      this.projectMaps[this.projectId] || {};
    return customizeFields;
  }

  convertUserKeys2Values(message, invalidParams = []) {
    if (!this.userId || !this.channelId) return message;
    const { advancedConfigs: { params: { paramFields = {} } = {} } = {} } =
      this.channelsMap[this.channelId] || {};
    const userInfo = this.people[`${this.userId}_${this.channelId}`];
    Object.assign(paramFields, {
      FIELD1: '{user_custom_1}',
      FIELD2: '{user_custom_2}',
      FIELD3: '{user_custom_3}'
    });
    if (!userInfo) return message;
    Object.keys(paramFields).forEach(attribute => {
      if (message.includes(paramFields[attribute])) {
        let val = userInfo[attribute];
        if (attribute.includes('FIELD')) {
          const { customizeInfo = {} } = userInfo;
          val = customizeInfo[attribute];
        }
        val = typeof val === 'boolean' ? val.toString() : val;
        const reg = new RegExp(paramFields[attribute], 'g');
        if (val) {
          if (val.name) val = val.name;
          if (val.value) val = val.value;
          if (attribute === 'birthday') {
            val = moment(val).format('YYYY/MM/DD');
          }
          message = message.replace(reg, val);
        } else {
          invalidParams.push(paramFields[attribute]);
        }
      }
    });

    return message;
  }

  convertAgentKeys2Values(message, invalidParams = []) {
    if (!this.user || !this.user.id) return message;
    const agentInfo = {
      ...this.user,
      role: MAPPING_ROLE[this.user.role]
    };
    const agentParamFields = {
      firstName: '{agent_first_name}',
      firstNameNativeLanguage: '{agent_native_first_name}',
      lastName: '{agent_last_name}',
      email: '{agent_email}',
      operator: '{agent_operation}',
      company: '{agent_company}',
      division: '{agent_division}',
      contactNumber: '{agent_phone_number}',
      role: '{agent_role}'
    };
    Object.keys(agentParamFields).forEach(attribute => {
      if (message.includes(agentParamFields[attribute])) {
        let val = agentInfo[attribute];
        const reg = new RegExp(agentParamFields[attribute], 'g');
        if (val) {
          if (val.name) val = val.name;
          message = message.replace(reg, val);
        } else {
          invalidParams.push(agentParamFields[attribute]);
        }
      }
    });

    return message;
  }

  getAgentParams() {
    const displayAgent = this.getParamsDisplayAgent();
    const customizeFields = this.getCustomizeFields();

    const results = Object.keys(displayAgent).reduce((p, c) => {
      if (displayAgent[c]) p[c] = displayAgent[c];
      return p;
    }, {});

    Object.keys(customizeFields).map(key => {
      const { display: { agent } = {} } = customizeFields[key] || {};
      if (!agent) return;
      results[key] = true;
    });

    return results;
  }

  getValueFromKey(key) {
    let value = '';
    if (key.includes('agent_')) {
      // Dynamically access Agent object property
      if (key === 'agent_role') {
        value = MAPPING_ROLE[get(this.getAgentUser, agentParamsMaps[key])];
      } else {
        value = get(this.getAgentUser, agentParamsMaps[key]);
      }
    } else if (key.includes('user_')) {
      // Dynamically access User object property
      if (key.includes('user_custom_')) {
        const { customizeInfo = {} } = this.people[`${this.userId}_${this.channelId}`];
        value = get(customizeInfo, userCustomParamsMaps[key]);
      } else if (key === 'user_birthday') {
        value = get(this.people[`${this.userId}_${this.channelId}`], userParamsMaps[key]);
        if (value !== '' && value !== undefined) {
          value = moment(value).format('YYYY/MM/DD');
        }
      } else if (
        key === 'user_gender' ||
        key === 'user_province' ||
        key === 'user_country' ||
        key === 'user_marital_status'
      ) {
        let obj = get(this.people[`${this.userId}_${this.channelId}`], userParamsMaps[key]);

        obj = obj || {};
        value = obj.name || obj.key || '';
      } else {
        value = get(this.people[`${this.userId}_${this.channelId}`], userParamsMaps[key]);
      }
    }
    value = typeof value === 'boolean' ? value.toString() : value;
    return value || '';
  }
}

export default Params;
