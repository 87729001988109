import excel from 'excel4node';
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import Blob from 'blob';
import * as download from 'downloadjs';

export const exportFile = async (data, exportType, fileName) => {
  switch (exportType) {
    case 'excel': {
      const workbook = new excel.Workbook();
      data.forEach(sheet => {
        const wb = workbook.addWorksheet(sheet.sheetName);
        let excelRowIndex = 1;
        for (let indexTitle = 0; indexTitle < sheet.title.length; indexTitle++) {
          wb.cell(excelRowIndex, indexTitle + 1).string(sheet.title[indexTitle]);
        }
        excelRowIndex += 1;
        for (let indexData = 0; indexData < sheet.data.length; indexData++) {
          let rowData = sheet.data[indexData];
          for (let indexRowData = 0; indexRowData < rowData.length; indexRowData++) {
            let data = rowData[indexRowData].toString();
            wb.cell(excelRowIndex + indexData, indexRowData + 1).string(data);
          }
        }
      });

      const file = await workbook.writeToBuffer().then(buffer => buffer);
      const blob = new Blob([file], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      });
      return download(blob, fileName);
    }

    case 'csv': {
      const workbook = XLSX.utils.book_new();
      data.forEach(sheet => {
        const rows = [];
        rows.push(sheet.title);
        if (sheet.data) {
          sheet.data.forEach(item => {
            rows.push(item);
          });
        }
        let ws = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, ws, sheet.sheetName);
      });

      const wss = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
      //UTF-8 with BOM - for MS Excel - https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
      const csv =
        '\uFEFF' +
        XLSX.utils.sheet_to_csv(wss, {
          blankrows: false,
          dateNF: 'yyyy"/"MM"/"dd',
          bookSheets: true
        });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      return download(blob, fileName);
    }

    default:
      break;
  }
};
