import baseNotification from 'components/BaseComponents/BaseNotification';
import { i18n } from 'locales';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import Service from 'core/service';
import {
  CREATE_CUSTOMER_SUPPORT,
  UPDATE_CUSTOMER_SUPPORT,
  SET_CUSTOMER_SUPPORTS,
  SET_SUPPORTFORM_SEARCHTEXT,
  SET_CUSTOMER_SUPPORT,
  DELETE_CUSTOMER_SUPPORT,
  SET_CUSTOMER_SUPPORT_PANEL_SETTING,
  UPDATE_CUSTOMER_SUPPORT_TREE_STORE,
  CLEAR_TREE_STORE,
  SET_CSF_MODE,
  SET_COUNTRIES,
  SET_PROVINCES,
  SET_TEMPLATE_CONTENT
} from './types';
import { convertTreeData } from 'core/helpers';
const service = new Service();

export const setCSFMode = ({ commit }, mode) => {
  commit(SET_CSF_MODE, mode);
};

export const clearTree = ({ commit }) => {
  commit(CLEAR_TREE_STORE);
};

export const getCustomerSupports = async ({ commit }, params) => {
  try {
    let customerSupports = [];
    let nextKey = null;

    do {
      const data = await service.post('customerSupports/getCustomerSupports', {
        ...params,
        nextKey
      });

      if (data) {
        customerSupports.push(data.results || []);
        nextKey = data.nextKey || null;
      }
    } while (nextKey);

    customerSupports = flatten(customerSupports);
    commit(SET_CUSTOMER_SUPPORTS, customerSupports);
    return customerSupports;
  } catch (error) {
    // eslint-disable-next-line
    console.log('getCustomerSupports >> error', error);
    return [];
  }
};

export const getCustomerSupportById = async ({ commit }, params) => {
  const data = await service.post('customerSupports/getCustomerSupportById', {
    ...params
  });
  if (data) commit(SET_CUSTOMER_SUPPORT, data);
  return data;
};

export const submitForm = ({ commit, dispatch, rootState }, params) => {
  const { formCustomerSupport, isUpdate, updatedByAgent } = params;
  if (!formCustomerSupport) return {};
  if (isUpdate) {
    return service
      .post('customerSupports/updateCustomerSupport', formCustomerSupport)
      .then(data => {
        if (updatedByAgent) {
          data.updatedByAgent = updatedByAgent;
        }
        commit(UPDATE_CUSTOMER_SUPPORT, data);
        const params = {
          userId: formCustomerSupport.userId,
          channelId: formCustomerSupport.channelId,
          projectId: formCustomerSupport.projectId,
          platform: formCustomerSupport.platform
        };
        dispatch('session/pushPersonalDataToPeople', params, { root: true });
        return data;
      })
      .catch(() => {
        return {};
      });
  }
  return service
    .post('customerSupports/createCustomerSupport', formCustomerSupport)
    .then(data => {
      if (updatedByAgent) {
        data.updatedByAgent = updatedByAgent;
      }
      commit(CREATE_CUSTOMER_SUPPORT, data);
      const { selectedConversation } = rootState.chat;
      if (selectedConversation) {
        const params = {
          userId: selectedConversation.userId,
          channelId: selectedConversation.channelId,
          projectId: selectedConversation.projectId,
          platform: selectedConversation.platform
        };
        dispatch('session/pushPersonalDataToPeople', params, { root: true });
      }
      return data;
    })
    .catch(() => {
      return {};
    });
};

export const setSearchText = ({ commit }, text) => {
  commit(SET_SUPPORTFORM_SEARCHTEXT, text);
};

export const deleteCustomerSupport = ({ commit }, { id }) => {
  return service
    .post('customerSupports/deleteCustomerSupports', {
      id: id
    })
    .then(data => {
      commit(DELETE_CUSTOMER_SUPPORT, id);
      return data;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: deleteCustomerSupport -> id', id, error);
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: 'Data is invalid!'
      });
      return [];
    });
};

const _sortObject = obj => {
  return Object.keys(obj)
    .sort()
    .reduce((a, v) => {
      a[v] = obj[v];
      return a;
    }, {});
};

const _biuldTreeData = category => {
  const { treeData } = category;
  if (!treeData || !treeData.length) return {};
  const newTreeData = convertTreeData(treeData, null);
  return newTreeData;
};

const _traverse = (obj, parentId, expandedLvl) => {
  let parent = [];
  for (let k in obj) {
    let child = new Object();
    if (obj[k] && typeof obj[k] === 'object') {
      if (Object.keys(obj[k]).length > 0) {
        child.data = {};
        child.text = k;
        child.state = {
          expanded: false
        };
        if (expandedLvl === 1) {
          //eslint-disable-next-line
          console.log('[_traverse], expandedLvl', expandedLvl);
        }

        if (parentId) {
          child.data.id = `${parentId}::${k}`;
        } else {
          child.data.id = k;
        }
        child.children = _traverse(_sortObject(obj[k]), child.data.id);
      } else {
        child.text = k;
        child.children = [{ text: '...' }];
      }
    } else {
      child.data = {
        activeNode: true
      };
      if (parentId) {
        child.data.id = `${parentId}::${k}`;
      } else {
        child.data.id = k;
      }
      child.text = obj[k];
      child.id = k;
    }
    parent.push(child);
  }
  return parent;
};

export const updateTree = ({ commit }, tree) => {
  commit(UPDATE_CUSTOMER_SUPPORT_TREE_STORE, tree);
};

export const getProjectByChannelId = async (_, channelId) => {
  return service.post('customerSupports/getProjectByChannel', {
    channelId
  });
};

export const getCustomerSupportSettingsByKey = async (
  { commit },
  { projectId, groupId, templateId }
) => {
  const { customerSupportSettings } = await service.post(
    'customerSupports/getCustomerSupportSettingsByKey',
    {
      projectId,
      groupId,
      templateId
    }
  );
  if (customerSupportSettings && Object.keys(customerSupportSettings).length > 0) {
    const { content, id, name } = customerSupportSettings;
    if (!content) return {};
    if (content) {
      const { category, customerFields, fixedFields } = content;
      const { label } = category;
      const template = { id, name };
      commit(SET_CUSTOMER_SUPPORT_PANEL_SETTING, {
        categories: _biuldTreeData(category),
        categoryLabel: label || {},
        customerFields: customerFields || {},
        fixedFields: fixedFields || {},
        template: template || {}
      });
    }
    return content;
  }

  return {};
};

export const getCountries = async ({ commit }, { language }) => {
  const serviceCountry = new Service(process.env.VUE_APP_COUNTRIES_API_URL);
  const params = {};
  params.language = language;
  return serviceCountry
    .post('countries/getCountries', params)
    .then(result => {
      commit(SET_COUNTRIES, result);
      return result;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('getCountries -> error', error);
      return [];
    });
};

export const getProvinces = async ({ commit }, { code, language }) => {
  const serviceCountry = new Service(process.env.VUE_APP_COUNTRIES_API_URL);
  let params = {};
  params.code = code;
  params.language = language;
  return serviceCountry
    .post('countries/getProvinces', params)
    .then(provinces => {
      commit(SET_PROVINCES, provinces);
      return provinces;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('getProvinces -> error', error);
      return [];
    });
};

export const getTemplateContent = async ({ commit }, data) => {
  if (isEmpty(data)) return false;

  const { templateContent, currentTemplateContent, templateId: id } = data;
  const { category, customerFields, fixedFields } = templateContent;
  const { label } = category;

  // build templateContent to satify requirement of SGCPLUS-4002
  const { customerFields: currentCustomerFields } = currentTemplateContent;
  let currentDisplayFields = Object.values(currentCustomerFields).reduce((objs, obj) => {
    const { display, key } = obj;
    if (display) {
      objs[key] = obj;
    }
    return objs;
  }, {});
  const displayFields = Object.values(customerFields).reduce((objs, obj) => {
    const { key } = obj;
    const currentField = currentDisplayFields[key] || {};
    const { display } = currentField;
    if (display) {
      objs[key] = { ...obj, display };
    }
    return objs;
  }, {});

  const template = { id };
  commit(SET_TEMPLATE_CONTENT, {
    categories: _biuldTreeData(category),
    categoryLabel: label || {},
    customerFields: { ...currentDisplayFields, ...displayFields },
    fixedFields: fixedFields || {},
    template: template || {}
  });
  return data;
};
