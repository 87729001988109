<template>
  <div
    ref="chatHeader"
    :class="['chats-header position-relative mb-40 fixed shadow-sm', isHeaderCloseClass, minHeight]"
  >
    <div
      v-if="(isPhoneAgent && selectedUserInfo.userInfo) || !isPhoneAgent"
      class="chat-header-wrapper"
    >
      <div class="media">
        <!-- collpase tool (S) -->
        <b-btn
          variant="link"
          style="position: absolute; right: 0; top: 0; padding: 10px"
          @click="handleToggleHeaderClose"
        >
          <i class="fas fa-angle-down" v-if="isHeaderClose"></i>
          <i class="fas fa-angle-up" v-if="!isHeaderClose"></i>
        </b-btn>
        <!-- collapse Tool (E) -->

        <div class="pl-0">
          <!-- Follow up User (S) -->
          <div class="follow" v-if="!isPhoneAgent">
            <i
              v-if="actions[ACTION.FOLLOW]"
              v-b-tooltip="
                !isFeatureClass
                  ? $t('src.modules.chat.components.ChatBox.chat-header.mark_as_follow_up')
                  : $t('src.modules.chat.components.ChatBox.chat-header.remove_follow_up')
              "
              :class="['fa fa-star follow', isFeatureClass]"
              aria-hidden="true"
              @click="handleFollow()"
            />
          </div>
          <!-- Follow up User (E) -->

          <!-- Avatar User (S) -->
          <a class="avatar avatar-online avatar-header" @click.prevent.stop>
            <img :src="avatar" class="img-fluid shadow-lg" alt="..." @error="imageLoadError" />
            <i />
          </a>
          <!-- Avatar User (E) -->
        </div>

        <!-- User Info -->
        <div class="media">
          <div class="media-body pl-10">
            <h5 class="mt-0 mb-0">
              <!-- User Name (S) -->
              <span class="nat-user-name">
                {{ name }}
              </span>
              <!-- User Name (E) -->

              <!-- Channel Name (S) -->
              <span class="badge badge-pill badge-danger role text-uppercase">{{
                channelName
              }}</span>
              <!-- Channel Name (E) -->
            </h5>

            <!-- User Language (S) -->
            <span class="media-time">
              <i class="fa fa-map-marker-alt" />
              {{
                displayLanguage
                  ? displayLanguage
                  : `${splitLocale(locale).conutryName} (${displayLanguageName[locale] ||
                      splitLocale(locale).langName})`
              }}
            </span>
            <!-- User Language (S) -->

            <div v-if="!isPhoneAgent">
              <!-- Dropdown action menu -->
              <b-dropdown
                data-testid="toogleUserLabel"
                variant="default"
                size="sm"
                toggle-class="d-flex align-items-center dropdown-toggle"
                no-caret
                @shown="dropdownUserLabelAction"
              >
                <template slot="button-content">
                  <i class="fa fa-ellipsis-h w-100 d-block w-auto text-center"></i>
                </template>
                <b-dropdown-header>{{ $t('common.text.action') }}</b-dropdown-header>
                <!--  Add User Label (S) -->
                <b-dropdown-item
                  data-testid="add-usr-lbl"
                  :disabled="
                    isMaintain ||
                      userLabelByAgentCount > 4 ||
                      (![
                        TICKET_STATE.REQUEST,
                        TICKET_STATE.FINISH,
                        TICKET_STATE.START,
                        TICKET_STATE.SUPPORTING
                      ].includes(ticketState) &&
                        !allowAddLabelInComplete)
                  "
                  class="disable-item"
                  @click="showInputLabel"
                >
                  {{ $t('src.modules.chat.components.ChatBox.chat-header.add_user_label') }}
                </b-dropdown-item>
                <!--  Add User Label (E) -->

                <!--  Management  User Label (S) -->
                <b-dropdown-item
                  data-testid="mng-usr-lbl"
                  :disabled="
                    isMaintain ||
                      ![
                        TICKET_STATE.REQUEST,
                        TICKET_STATE.FINISH,
                        TICKET_STATE.START,
                        TICKET_STATE.SUPPORTING
                      ].includes(ticketState) ||
                      !actions[ACTION.MANAGE_LABLE]
                  "
                  v-b-modal.ManageUserLabels
                  class="disable-item"
                  @click="openManageUserLabels"
                >
                  {{ $t('src.modules.chat.components.ChatBox.chat-header.manage_user_labels') }}
                </b-dropdown-item>
                <!--  Management  User Label (E) -->

                <!-- Register User (S) -->
                <b-dropdown-item
                  v-if="
                    allowDisplayRegisteredInfo &&
                      ((isRegister && !isCurrentAgentHandle) || !isRegister)
                  "
                  v-b-tooltip.hover
                  :title="
                    $t('src.modules.chat.components.ChatBox.chat-header.register_user_to_the_list')
                  "
                  class="disable-item"
                  @click="confirmModalRegister"
                >
                  {{
                    $t('src.modules.chat.components.ChatBox.chat-header.register_user_to_the_list')
                  }}
                </b-dropdown-item>
                <!-- Register User (S) -->

                <!-- Remove User (S) -->
                <b-dropdown-item
                  v-if="allowDisplayRegisteredInfo && isCurrentAgentHandle && isRegister"
                  v-b-tooltip.hover
                  :title="
                    $t('src.modules.chat.components.ChatBox.chat-header.remove_user_form_the_list')
                  "
                  class="disable-item"
                  @click="confirmModalRemoveUserRegistered"
                >
                  {{
                    $t('src.modules.chat.components.ChatBox.chat-header.remove_user_form_the_list')
                  }}
                </b-dropdown-item>
                <!-- Remove User (S) -->

                <!-- User Profile (S) -->
                <b-dropdown-item data-testid="usr-profile" @click="handleUserProfileClick">
                  {{ $t('src.modules.chat.components.ChatBox.chat-header.user_profile') }}
                </b-dropdown-item>
                <!-- User Profile (E) -->
              </b-dropdown>
              <!-- Dropdown action menu -->

              <!-- User Status (S) -->
              <el-tag
                v-if="userStatusInSupporting.display"
                v-b-tooltip="$t('common.text.user_status')"
                :type="userStatusInSupporting.userStatus ? 'success' : 'info'"
                effect="dark"
                style="margin-left: 5px; height: 20px; line-height: 18px"
              >
                {{
                  userStatusInSupporting.userStatus
                    ? $t('common.text.available')
                    : $t('common.text.not_available')
                }}
              </el-tag>
              <el-tag
                v-if="isCurrentAgentHandle && isRegister"
                v-b-tooltip="registerItem && registerItem.reason"
                type="warning"
                :hit="true"
                color="#FBBC04"
                effect="dark"
                style="margin-left: 5px; height: 20px; line-height: 18px; border-color: #fbbc04"
              >
                {{ $t('src.modules.chat.components.ChatBox.chat-header.registered') }}
              </el-tag>
              <!-- User Status (E) -->
            </div>
          </div>
        </div>
        <!-- User Info -->

        <!-- User Might Be Not Available (S) -->
        <span
          ref="userMightNotAvailable"
          v-if="userMightNotAvailable && !isPhoneAgent"
          class="user-status-wc-css"
        >
          {{ $t('common.text.user_might_be_not_available') }}
        </span>
        <!-- User Might Be Not Available (E) -->

        <support-ticket
          :show="showSupportTicket"
          :conversation="selectedConversation"
          :agent-groups="agentGroups"
          @onClose="handleCloseSupportTicket"
        />
        <!-- confirm Modal -->
        <b-modal
          id="confirmModal"
          ref="confirmModal"
          v-model="showModelconfirm"
          :title="$t('src.modules.chat.components.ChatBox.chat-header.confirmation')"
        >
          <p class="my-2" v-html="titleConfirmModal" />
          <div slot="modal-footer" class="w-100">
            <b-btn
              v-if="supportWithUserConsent && !isStoryConversation"
              class="float-right"
              variant="primary"
              size="sm"
              @click="handleSupportWithoutUserAgreement()"
            >
              {{ $t('src.modules.chat.components.ChatBox.chat-header.without_users_agreement') }}
            </b-btn>
            <b-btn
              v-if="isStoryConversation"
              class="float-right"
              variant="primary"
              size="sm"
              style="margin-left: 7px"
              @click="handleSupportWithoutUserAgreement()"
            >
              {{
                $t(
                  'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.support'
                )
              }}
            </b-btn>
            <b-btn
              v-if="!isStoryConversation"
              :size="supportWithUserConsent ? 'sm' : ''"
              class="float-right"
              variant="primary"
              style="margin-left: 7px"
              @click="handleYes(typeConfirmModal)"
            >
              {{
                supportWithUserConsent
                  ? $t('src.modules.chat.components.ChatBox.chat-header.with_users_agreement')
                  : $t('common.confirmModal.yes')
              }}
            </b-btn>
            <b-btn
              :size="supportWithUserConsent || isStoryConversation ? 'sm' : ''"
              class="float-right"
              variant="default"
              @click="handleNo"
            >
              {{
                supportWithUserConsent || isStoryConversation
                  ? $t('common.confirmModal.cancel')
                  : $t('common.confirmModal.no')
              }}
            </b-btn>
          </div>
        </b-modal>
        <!-- confirm Modal -->
      </div>

      <!-- User Labels (Phone agent) (S)  -->
      <div
        class="media mb-5"
        v-if="
          isPhoneAgent &&
            selectedConversation.userInfo &&
            selectedConversation.userInfo.labels &&
            selectedConversation.userInfo.labels.length > 0
        "
      >
        <div class="chat-status-tag">
          <div class="chat-tag-label">
            <span class="float-left">
              {{ $t('src.modules.chat.components.ChatBox.chat-header.title_user_labels') }}
            </span>
          </div>
          <div class="chat-tag-item">
            <template
              v-for="(label, idx) in selectedConversation.userInfo.labels.filter(
                l => l.sourceType === 'system'
              )"
            >
              <el-tag
                :key="label.id + idx"
                slot="reference"
                effect="dark"
                size="medium"
                color="#FF0000"
                class="user-label-item"
                style="border-color: #ff0000"
              >
                <el-tooltip
                  :content="userLabelSysValue(label.value)"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>
                    {{ userLabelSysValue(label.value) }}
                  </p>
                </el-tooltip>
              </el-tag>
            </template>
            <template
              v-for="label in selectedConversation.userInfo.labels.filter(
                l => l.sourceType !== 'system'
              )"
            >
              <el-tag
                v-if="label.sourceType === 'bot' || label.sourceType === 'admin'"
                :key="label.id"
                slot="reference"
                effect="dark"
                size="medium"
                class="user-label-item nat-tag-bot"
              >
                <el-tooltip
                  :content="label.value"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>{{ label.value }}</p>
                </el-tooltip>
              </el-tag>
              <el-tag
                v-else
                :key="label.id"
                slot="reference"
                :type="label.type"
                effect="dark"
                size="medium"
                class="user-label-item"
              >
                <el-tooltip
                  :content="label.value"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>{{ label.value }}</p>
                </el-tooltip>
              </el-tag>
            </template>
          </div>
        </div>
      </div>
      <!-- User Labels (Phone agent) (E)  -->

      <!-- User Labels (S)  -->
      <div
        class="media mb-5"
        v-if="
          !isPhoneAgent &&
            (userLabelsAssigning.length > 0 ||
              userLabelsSystemAssigning.length > 0 ||
              inputVisibleLabel ||
              isAddingNewLabels)
        "
      >
        <div class="chat-status-tag">
          <div class="chat-tag-label">
            <span class="float-left">
              {{ $t('src.modules.chat.components.ChatBox.chat-header.title_user_labels') }}
            </span>
          </div>

          <!-- input tag autocomplete -->
          <div
            class="chat-tag-input"
            v-if="
              inputVisibleLabel &&
                ((dynamicLabels.length < 5 &&
                  ([
                    TICKET_STATE.REQUEST,
                    TICKET_STATE.FINISH,
                    TICKET_STATE.START,
                    TICKET_STATE.SUPPORTING
                  ].includes(ticketState) ||
                    allowAddLabelInComplete)) ||
                  !isAddingNewLabels)
            "
          >
            <base-input-autocomplete
              ref="saveLabelInput"
              :placeholder="
                $t('src.modules.chat.components.ChatBox.chat-header.please_input_label')
              "
              v-model="inputValueLabel"
              @select="handleSelectLabel"
              @keypress.enter="handleInputConfirmLabel"
              @blur="handleInputBlurLabel"
              :maxlength="25"
              :items="c_labelsProjectValue"
            />
          </div>
          <div class="chat-tag-loading">
            <i
              v-if="isAddingNewLabels"
              class="fas fa-spinner fa-spin fa-lg float-left mr-10 mt-5"
              style="height: 13px"
            />
          </div>
          <div class="chat-tag-item">
            <template v-for="(label, idx) in userLabelsSystemAssigning">
              <el-tag
                v-if="label.sourceType === 'system'"
                :key="label.id + idx"
                slot="reference"
                effect="dark"
                size="medium"
                color="#FF0000"
                class="user-label-item"
                style="border-color: #ff0000"
              >
                <el-tooltip
                  :content="userLabelSysValue(label.value)"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>
                    {{ userLabelSysValue(label.value) }}
                  </p>
                </el-tooltip>
              </el-tag>
            </template>
            <template v-for="(label, idx) in userLabelsAssigning">
              <el-tag
                v-if="label.sourceType === 'bot' || label.sourceType === 'admin'"
                :key="label.id"
                slot="reference"
                :closable="false"
                effect="dark"
                size="medium"
                class="user-label-item nat-tag-bot"
              >
                <el-tooltip
                  :content="label.value"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>{{ label.value }}</p>
                </el-tooltip>
              </el-tag>
              <el-popover
                v-else
                class="float-left"
                :data-testid="`usrLabel${idx + 1}`"
                :ref="label.id"
                :key="label.id"
                :disabled="
                  showModelconfirm ||
                    waiting[label.id] ||
                    (![
                      TICKET_STATE.REQUEST,
                      TICKET_STATE.FINISH,
                      TICKET_STATE.START,
                      TICKET_STATE.SUPPORTING
                    ].includes(ticketState) &&
                      !allowAddLabelInComplete)
                "
                placement="bottom"
                width="50"
                trigger="click"
                @show="showChangeColor(label.id)"
              >
                <div class="row color-tag-row">
                  <div v-if="label.type !== 'primary'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[label.id]"
                      type="primary"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeLabelColor('primary', label)"
                    ></el-button>
                  </div>
                  <div v-if="label.type !== 'success'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[label.id]"
                      type="success"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeLabelColor('success', label)"
                    ></el-button>
                  </div>
                  <div v-if="label.type !== 'info'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[label.id]"
                      type="info"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeLabelColor('info', label)"
                    ></el-button>
                  </div>
                  <div v-if="label.type !== 'warning'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[label.id]"
                      type="warning"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeLabelColor('warning', label)"
                    ></el-button>
                  </div>
                  <div v-if="label.type !== 'danger'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[label.id]"
                      type="danger"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeLabelColor('danger', label)"
                    ></el-button>
                  </div>
                </div>
                <el-tag
                  slot="reference"
                  :type="label.type"
                  :closable="
                    ([
                      TICKET_STATE.REQUEST,
                      TICKET_STATE.FINISH,
                      TICKET_STATE.START,
                      TICKET_STATE.SUPPORTING
                    ].includes(ticketState) ||
                      allowAddLabelInComplete) &&
                      !isMaintain
                  "
                  effect="dark"
                  size="medium"
                  class="user-label-item"
                  @close="handleCloseLabel(label)"
                >
                  <el-tooltip
                    :content="label.value"
                    :open-delay="50"
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <p>{{ label.value }}</p>
                  </el-tooltip>
                </el-tag>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
      <!-- User Labels (E)  -->

      <!-- Conversation Label (S)  -->
      <div
        class="media"
        v-if="
          !isPhoneAgent &&
            (conversationLabels.length > 0 ||
              conversationLabelsSystem.length > 0 ||
              inputVisibleTag ||
              isAddingNewTags)
        "
      >
        <div class="chat-status-tag">
          <span class="chat-tag-label">
            {{ $t('src.modules.chat.components.ChatBox.chat-header.title_conversation_labels') }}
          </span>
          <div
            class="chat-tag-input"
            v-if="
              inputVisibleTag &&
                ((conversationLabels.length < 5 &&
                  ([
                    TICKET_STATE.REQUEST,
                    TICKET_STATE.FINISH,
                    TICKET_STATE.START,
                    TICKET_STATE.SUPPORTING
                  ].includes(ticketState) ||
                    allowAddLabelInComplete)) ||
                  !isAddingNewTags)
            "
          >
            <base-input-autocomplete
              ref="saveTagInput"
              :placeholder="
                $t('src.modules.chat.components.ChatBox.chat-header.please_input_label')
              "
              v-model="inputValueTag"
              @select="handleSelectTag"
              @keypress.enter="handleInputConfirmTag"
              @blur="handleInputBlurTag"
              :maxlength="25"
              :items="c_tagsProjectValue"
            />
          </div>

          <div class="chat-tag-loading">
            <i
              v-if="isAddingNewTags"
              class="fas fa-spinner fa-spin fa-lg float-left mr-10 mt-5"
              style="height: 13px"
            />
          </div>
          <!-- tag array -->
          <div class="chat-tag-item">
            <template v-for="(tag, idx) in conversationLabelsSystem">
              <el-tag
                v-if="tag.sourceType === 'system'"
                :key="tag.id + idx"
                slot="reference"
                effect="dark"
                size="medium"
                color="#FF0000"
                class="user-label-item"
                style="border-color: #ff0000"
              >
                <el-tooltip
                  :content="convLabelSysValue(tag.value)"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>
                    {{ convLabelSysValue(tag.value) }}
                  </p>
                </el-tooltip>
              </el-tag>
            </template>
            <template v-for="(tag, idx) in conversationLabels">
              <el-tag
                v-if="tag.sourceType === 'bot'"
                :key="tag.id"
                slot="reference"
                :closable="false"
                effect="dark"
                size="medium"
                class="user-label-item nat-tag-bot"
              >
                <el-tooltip
                  :content="tag.value"
                  :open-delay="50"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <p>{{ tag.value }}</p>
                </el-tooltip>
              </el-tag>
              <el-popover
                v-if="tag.sourceType === 'agent'"
                :data-testid="`cvsLabel${idx + 1}`"
                :ref="tag.id"
                :key="tag.id"
                :disabled="
                  waiting[tag.id] ||
                    (![
                      TICKET_STATE.REQUEST,
                      TICKET_STATE.FINISH,
                      TICKET_STATE.START,
                      TICKET_STATE.SUPPORTING
                    ].includes(ticketState) &&
                      !allowAddLabelInComplete)
                "
                placement="bottom"
                width="50"
                trigger="click"
                @show="showChangeColor(tag.id)"
              >
                <div class="row color-tag-row">
                  <div v-if="tag.type !== 'primary'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[tag.id]"
                      type="primary"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('primary', tag)"
                    ></el-button>
                  </div>
                  <div v-if="tag.type !== 'success'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[tag.id]"
                      type="success"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('success', tag)"
                    ></el-button>
                  </div>
                  <div v-if="tag.type !== 'info'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[tag.id]"
                      type="info"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('info', tag)"
                    ></el-button>
                  </div>
                  <div v-if="tag.type !== 'warning'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[tag.id]"
                      type="warning"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('warning', tag)"
                    ></el-button>
                  </div>
                  <div v-if="tag.type !== 'danger'" class="col-3 color-tag-col">
                    <el-button
                      :disabled="waiting[tag.id]"
                      type="danger"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('danger', tag)"
                    ></el-button>
                  </div>
                </div>

                <el-tag
                  slot="reference"
                  :type="tag.type"
                  :closable="
                    ([
                      TICKET_STATE.REQUEST,
                      TICKET_STATE.FINISH,
                      TICKET_STATE.START,
                      TICKET_STATE.SUPPORTING
                    ].includes(ticketState) ||
                      allowAddLabelInComplete) &&
                      !isMaintain
                  "
                  effect="dark"
                  size="medium"
                  class="label-item"
                  :class="isBotColor(tag.type) ? 'nat-tag-bot' : ''"
                  @close="handleCloseTag(tag)"
                >
                  <el-tooltip
                    :content="tag.value"
                    :open-delay="50"
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <p>{{ tag.value }}</p>
                  </el-tooltip>
                </el-tag>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
      <!-- Conversation Label (E)  -->
    </div>

    <!-- List button status (S)   -->
    <div class="chat-status-tools clearfix" v-if="!isPhoneAgent">
      <div class="float-right">
        <!-- Button Support [User] (S) -->
        <b-button
          v-if="actions[ACTION.SUPPORT]"
          :disabled="assigning"
          variant="primary"
          size="sm"
          class="p-l-10 p-r-10"
          @click="confirmSupportTicket"
        >
          <i v-if="assigning" class="fas fa-spinner fa-spin fa-lg" />
          <span v-else>
            {{ $t('src.modules.chat.components.ChatBox.chat-header.support') }}
          </span>
        </b-button>
        <!-- Button Support [User] (E) -->

        <!-- Button Finish|Hold [Un Hold] -->
        <b-button-group
          v-if="actions[ACTION.FINISH] || actions[ACTION.HOLD] || actions[ACTION.UN_HOLD]"
          class="m-r-10"
        >
          <!-- Button Finish (S) -->
          <b-button
            data-testid="finish"
            v-if="actions[ACTION.FINISH]"
            v-b-modal.CloseConversation
            variant="success"
            size="sm"
            :disabled="disableBtnWhenUpdateTicket"
            >{{ $t('src.modules.chat.components.ChatBox.chat-header.finish') }}</b-button
          >
          <!-- Button Finish (E) -->

          <!-- Button Hold || Un Hold (S) -->
          <b-button
            id="hold-unhold-conv-btn"
            data-testid="hold"
            v-if="actions[ACTION.HOLD] || actions[ACTION.UN_HOLD]"
            :variant="!getHold ? 'default' : 'danger'"
            size="sm"
            :disabled="disableBtnWhenUpdateTicket"
            @click="holdConversation"
          >
            <span v-if="actions[ACTION.HOLD]">{{
              $t('src.modules.chat.components.ChatBox.chat-header.hold')
            }}</span>
            <span v-if="actions[ACTION.UN_HOLD]">
              {{ $t('src.modules.chat.components.ChatBox.chat-header.unhold') }}
            </span>
          </b-button>
          <BootstrapTooltipCustom
            id="hold-unhold-conv-btn"
            :content="
              !getHold
                ? $t('src.modules.chat.components.ChatBox.chat-header.hold_the_conversation')
                : $t('src.modules.chat.components.ChatBox.chat-header.unhold_the_conversation')
            "
          />
          <!-- Button Hold || Un Hold (E) -->
        </b-button-group>

        <!-- Button Status Submit (S) -->
        <span
          v-if="actions[ACTION.SUBMIT_FORM] && !isMonitoring"
          v-b-tooltip.hover
          :title="$t('src.modules.chat.components.ChatBox.chat-header.please_submit_form')"
          class="p-r-10"
        >
          <b-button
            :disabled="handleDisabled"
            variant="danger"
            size="sm"
            class="p-l-10 p-r-10"
            @click="$emit('onSupportItemClick')"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.submit_form') }}
          </b-button>
        </span>
        <!-- Button Status Submit (E) -->

        <!-- Dropdown action menu -->
        <b-dropdown
          data-testid="msg-setting"
          variant="default"
          size="sm"
          toggle-class="d-flex align-items-center dropdown-toggle"
          menu-class="header-action-menu"
          no-caret
        >
          <template slot="button-content">
            <i class="material-icons">more_vert</i>
          </template>
          <b-dropdown-header>{{ $t('common.text.action') }}</b-dropdown-header>
          <!-- Transfer To (S) -->
          <b-dropdown-item
            class="disable-item"
            :disabled="!actions[ACTION.TRANSFER]"
            @click="handleChatOptMenu('transfer-to')"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.transfer_to') }}
          </b-dropdown-item>
          <!-- Transfer To (E) -->

          <!-- Assign To (S) -->
          <b-dropdown-item
            class="disable-item"
            :disabled="!actions[ACTION.ASSIGN]"
            @click="handleChatOptMenu('assign-to')"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.assign_to') }}
          </b-dropdown-item>
          <!-- Assign To (E) -->

          <!-- Escalate To (S) -->
          <b-dropdown-item
            class="disable-item"
            :disabled="!actions[ACTION.ESCALATE]"
            @click="handleChatOptMenu('escalate-to')"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.escalate_to') }}
          </b-dropdown-item>
          <!-- Escalate To (E) -->

          <!-- Forward group (S) -->
          <b-dropdown-item
            class="disable-item"
            :disabled="!actions[ACTION.FORWARD_GROUP]"
            @click="handleChatOptMenu('forward-group')"
          >
            {{
              $t(
                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.forward_group'
              )
            }}
          </b-dropdown-item>
          <!-- Forward group (E) -->

          <b-dropdown-divider />

          <!-- Support (S) -->
          <b-dropdown-item v-if="actions[ACTION.SUPPORT]" @click="confirmSupportTicket">
            {{ $t('src.modules.chat.components.ChatBox.chat-header.support') }}
          </b-dropdown-item>
          <!-- Support (E) -->

          <!-- Re-Support (S) -->
          <b-dropdown-item
            v-if="actions[ACTION.RE_SUPPORT]"
            data-testid="msg-resupport"
            @click="reSupportThisUser"
          >
            {{
              $t(
                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.re_support'
              )
            }}
          </b-dropdown-item>
          <!-- Re-Support (E) -->

          <!-- Don't Support (S) -->
          <b-dropdown-item
            class="disable-item"
            :disabled="!actions[ACTION.NO_SUPPORT]"
            @click="dontSupportThisUser"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.dont_support') }}
          </b-dropdown-item>
          <!-- Don't Support (E) -->

          <b-dropdown-divider />

          <!-- Add Conversation Label (S) -->
          <b-dropdown-item
            :disabled="
              isMaintain ||
                isAddingNewTags ||
                conversationLabelCount > 4 ||
                (![
                  TICKET_STATE.REQUEST,
                  TICKET_STATE.FINISH,
                  TICKET_STATE.START,
                  TICKET_STATE.SUPPORTING
                ].includes(ticketState) &&
                  !allowAddLabelInComplete)
            "
            class="disable-item"
            @click="showInputTag"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.add_conversation_label') }}
          </b-dropdown-item>
          <!-- Add Conversation Label (E) -->

          <!-- Management  Conversation Label (S) -->
          <b-dropdown-item
            :disabled="
              isMaintain ||
                ![
                  TICKET_STATE.REQUEST,
                  TICKET_STATE.FINISH,
                  TICKET_STATE.START,
                  TICKET_STATE.SUPPORTING
                ].includes(ticketState) ||
                !actions[ACTION.MANAGE_LABLE]
            "
            v-b-modal.ManageLabels
            class="disable-item"
            @click="openManageLabels"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.manage_conversation_labels') }}
          </b-dropdown-item>
          <!-- Management Conversation Label (E) -->

          <b-dropdown-divider />
        </b-dropdown>
      </div>

      <!-- Selected ticket (S) -->
      <span
        id="selected-ticket-id"
        v-if="!userMightNotAvailable"
        class="badge badge-pill badge-primary shadow date"
        @mouseleave="mouseLeave"
        @click="copyTicketId"
      >
        {{ selectedConversation && selectedConversation.ticket && selectedConversation.ticket.id }}
      </span>
      <BootstrapTooltipCustom id="selected-ticket-id" :content="messageTooltipTicketId" />
      <!-- Selected ticket (E) -->
    </div>
    <!-- List button status (E) -->

    <!-- Component dialog box -->
    <b-modal
      id="dialogBox"
      ref="dialogBox"
      v-model="showDialogBox"
      :title="titleDialogBox"
      modal-class="integrated-audio"
      size="lg"
      scrollable
      @hidden="handleBtnCancelDialogBox"
    >
      <div v-if="typeDialogBox === 'user-info'">
        <Profile
          :profiles="userInfo"
          :external-user-info="externalUserInfo"
          :enable-external-user-info="enableExternalUserInfo"
          :external-no-data="externalNoData"
          :external-error="externalError"
        />
      </div>

      <div class="form-register" v-if="typeDialogBox === 'register-user'">
        <p
          v-if="oldRegisteredAt"
          class="my-4"
          v-html="
            $t(
              'src.modules.chat.components.ChatBox.chat-header.this_user_is_registered_to_the_agent_name_on_date_with_the_following_reason',
              {
                agentName: oldAgentName,
                registeredDateTime: rg_formatTime(oldRegisteredAt)
              }
            )
          "
        />
        <b-form-input
          v-if="oldRegisteredAt"
          id="old-reason"
          class="fr-old-reason"
          type="text"
          v-model="oldReason"
          :readonly="true"
        />

        <p class="my-4">
          {{
            $t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_register_this_user_to_your_list'
            )
          }}
        </p>
        <base-input
          v-model="newReason"
          :id="'reason'"
          :name="'reason'"
          :label="$t('src.modules.chat.components.ChatBox.chat-header.reason')"
          :placeholder="$t('src.modules.chat.components.ChatBox.chat-header.enter_reason')"
        />
      </div>

      <div slot="modal-footer" class="w-100">
        <b-btn
          v-if="typeDialogBox === 'user-info'"
          class="float-right"
          variant="default"
          @click="handleBtnCancelDialogBox"
        >
          {{ $t('common.confirmModal.close') }}
        </b-btn>
        <div class="footer-register-us" v-if="typeDialogBox === 'register-user'">
          <b-btn variant="default" @click="cancelRegister">
            {{ $t('src.modules.chat.components.ChatBox.chat-header.back') }}
          </b-btn>
          <b-btn class="float-right" variant="primary" @click="newHandleRegister">
            {{ $t('src.modules.chat.components.ChatBox.chat-header.register') }}
          </b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  TICKET_STATUS,
  TICKET_STATE,
  ACTION,
  CHANNEL_MODE,
  AGENT_ROLE,
  PERSONAL_OPTIONS
} from 'core/constants';
import { DISPLAY_LANGUAGE_NAME, SERVING_STATE } from 'core/constants';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import noAvatar from 'assets/images/no-avatar.png';
import { EventBus } from 'core/eventBus';
import SupportTicket from '../SupportTicket';
import {
  reSupporting,
  userStatusInSupportingWC,
  waiting,
  isContainingSystemDefinedWord,
  convertTimeUTC
} from 'core/helpers';
import Profile from '../../../search-management/profile';
import { language } from '../../../search-management/store/listLanguage';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import { i18n } from 'locales';
import concat from 'lodash/concat';

export default {
  components: {
    SupportTicket,
    Profile,
    BootstrapTooltipCustom
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: ''
    },
    channelName: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    pictureUrl: {
      type: String,
      default: noAvatar
    },
    displayLanguage: {
      type: String,
      default: ''
    },
    isBeingSupported: {
      type: Boolean,
      default: false
    },
    showSupport: {
      type: Boolean,
      default: false
    },
    showWaitingSupportForm: {
      type: Boolean,
      default: false
    },
    showSupportPanel: {
      type: Boolean,
      default: false
    },
    isSubmitSupported: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default: () => {}
    },
    showStatusConversation: {
      type: String,
      default: ''
    },
    isHeaderClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      locales: [],
      currentDate: new Date(),
      imageUrl: noAvatar,
      imageError: false,
      assigning: false,
      hold: false,
      disableBtnWhenUpdateTicket: false,
      transferBoxData: {},
      escalateBoxData: {},
      assignBoxData: {},
      translateData: {},
      ACTION: ACTION,
      TICKET_STATE: TICKET_STATE,
      showSupportTicket: false,
      agentGroups: [],
      showModelconfirm: false,
      titleConfirmModal: '',
      typeConfirmModal: '',
      inputVisibleTag: false,
      inputValueTag: '',
      tagsProject: [],
      visible: false,
      maxlengthTag: 25,
      isAddingNewTags: false,
      isCloseTags: false,
      currenttag: {},
      follow: false,
      tagColors: ['primary', 'success', 'info', 'warning', 'danger'],
      waiting: {},
      inputValueLabel: '',
      inputVisibleLabel: false,
      isAddingNewLabels: false,
      isCloseLabels: false,
      currentlabel: {},
      displayLanguageName: DISPLAY_LANGUAGE_NAME || {},
      chooseLabelToChangeColor: '',
      showDialogBox: false,
      titleDialogBox: '',
      typeDialogBox: '',
      userInfo: [],
      externalUserInfo: [],
      enableExternalUserInfo: false,
      externalNoData: false,
      externalError: false,
      languageList: language,
      messageTooltipTicketId: i18n.t('src.modules.search-management.index.conversation_id'),
      isRegister: false,
      // LTV
      conversationLabels: [],
      userLabelsAssigning: [],
      userLabelByAgentCount: 0,
      conversationLabelCount: 0,
      conversationLabelsSystem: [],
      userLabelsSystemAssigning: [],
      isCurrentAgentHandle: false,
      oldReason: '',
      newReason: '',
      registerItem: {},
      oldAgentName: '',
      oldRegisteredAt: '',
      sameGroup: null
    };
  },

  computed: {
    ...mapState('chat', [
      'chatViewingGroup',
      'selectedGroupConv',
      'maybeNotAvailable',
      'systemDefinedWord'
    ]),
    ...mapState('session', [
      'user',
      'people',
      'waitingMode',
      'projectMaps',
      'channelsMap',
      'projectLabelMaps',
      'projectTagMaps'
    ]),
    ...mapState('searchManagement', ['usersFilterMap']),
    ...mapState('phoneAgentChatBox', ['selectedUserInfo']),
    ...mapGetters('chat', ['selectedConversation', 'actions', 'userLabels', 'convsLabels']),
    ...mapGetters('session', [
      'conversations',
      'tagMaps',
      'tickets',
      'labelMaps',
      'conversationsMap',
      'isPhoneAgent',
      'meGroups'
    ]),

    userMightNotAvailable() {
      const { ticket = {} } = this.selectedConversation || {};
      const { id: ticketId = '' } = ticket;
      return this.maybeNotAvailable[ticketId] || false;
    },

    userStatusInSupporting() {
      const { ticket = {} } = this.selectedConversation || {};
      return userStatusInSupportingWC(ticket, this.channelsMap);
    },

    isMaintain() {
      return (
        this.conversation.channelId &&
        this.channelsMap &&
        this.channelsMap[this.conversation.channelId].mode === CHANNEL_MODE.MAINTAIN
      );
    },

    enableRequestUser() {
      const { advancedConfigs = {} } = this.channelsMap[this.$props.conversation.channelId];
      const { conversationDisplay = {} } = advancedConfigs;
      const { requestUser = {} } = conversationDisplay;
      return requestUser ? true : false;
    },

    isStoryConversation() {
      return (
        this.selectedConversation &&
        this.selectedConversation.ticket &&
        this.selectedConversation.ticket.status === TICKET_STATUS.USER_MENTION
      );
    },

    channelHasBot() {
      const { advancedConfigs = {} } = this.channelsMap[this.$props.conversation.channelId];
      const { modulesActivation = {} } = advancedConfigs;
      const { bot = false } = modulesActivation;
      return bot;
    },

    currentDateFormatted() {
      return moment(this.currentDate).format('DD MMM YYYY');
    },

    currentTime() {
      return moment(this.currentDate).format('HH:mm:ss');
    },

    avatar() {
      return this.imageUrl;
    },

    handleClass() {
      let btnClass = 'default';
      if (!this.isBeingSupported && !this.showSupport && !this.showWaitingSupportForm) {
        btnClass = 'success';
      } else if (this.showSupport && !this.showWaitingSupportForm) {
        btnClass = 'primary';
      } else if (this.showWaitingSupportForm && this.isSubmitSupported) {
        btnClass = 'danger';
      }
      return btnClass;
    },

    handleDisabled() {
      return this.showWaitingSupportForm && this.showSupportPanel;
    },

    selectUsername() {
      let { name = '' } =
        this.selectedConversation ||
        (this.selectedConversation && this.selectedConversation.userInfo);
      if (name.length > 30) name = name.substring(0, 30) + '...';
      return name;
    },

    isBotGroup() {
      const { ticket = null } = this.selectedConversation || {};
      if (!ticket) return false;

      const { status = '' } = ticket;
      return [TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.REQUEST_USER].includes(status);
    },

    agentName() {
      const { assignee } = (this.selectedConversation && this.selectedConversation.ticket) || {};
      if (!assignee) return '';
      let name = '';
      const agent = this.people[assignee];
      if (!agent) {
        this.addAgentToPeople(assignee);
        return '';
      }
      if (agent && agent.name) name = agent.name;
      if (name.toString().trim() === '') name = `${agent.firstName || ''} ${agent.lastName || ''}`;
      return name;
    },

    hasGroup() {
      const { ticket } = this.getTicketFix();
      if (ticket) {
        const { groupId } = ticket;
        if (groupId) return true;
      }
      return false;
    },

    getHold() {
      const { ticket } = this.getTicketFix();
      if (!ticket) return false;
      const { status } = ticket;
      if (status === TICKET_STATUS.HOLDING) return true;
      return false;
    },

    dynamicTags() {
      try {
        const { ticket } = this.getTicketFix();
        if (!this.projectId || Object.keys(this.projectTagMaps).length === 0) {
          return [];
        }

        const { id } = ticket || {};

        const { ticketsTags = [] } = this.projectTagMaps[this.projectId] || {};

        const tMaps = this.tagMaps[this.projectId] || {};

        let arrTagsAgentFix = [];

        ticketsTags
          .filter(ul => ul.ticketId === id && ul.projectId === this.projectId)
          .map(ul => {
            if (!tMaps[ul.tagId]) {
              //eslint-disable-next-line
              this.$log('[C+ Debug] User Labels >> Label not exist >> ', {
                tagId: ul.tagId,
                ticketId: id
              });
            } else {
              if (
                tMaps[ul.tagId] &&
                (!tMaps[ul.tagId].agentId || tMaps[ul.tagId].agentId !== 'bot')
              ) {
                arrTagsAgentFix.push(tMaps[ul.tagId] || null);
              }
            }
          });
        return arrTagsAgentFix;
      } catch (error) {
        //eslint-disable-next-line
        this.$log('[C+ Debug] > dynamicTags -> error', { error }, this.getTicketFix());
        return [];
      }
    },

    dynamicLabels() {
      try {
        if (!this.projectId || Object.keys(this.projectLabelMaps).length == 0 || !this.conversation)
          return [];
        const userId = this.conversation.userId;
        const { usersLabels = [] } = this.projectLabelMaps[this.projectId] || {};
        const lMaps = this.labelMaps[this.projectId] || {};
        return usersLabels
          .filter(ul => ul.userIdProjectId === `${userId}#${this.projectId}`)
          .map(ul => {
            if (!lMaps[ul.labelId]) {
              //eslint-disable-next-line
              this.$log('[C+ Debug] User Labels >> Label not exist >> ', {
                labelId: ul.labelId,
                userId
              });
            }
            return lMaps[ul.labelId] || null;
          })
          .filter(i => i && (!i.sourceType || (i.sourceType && i.sourceType !== 'bot')));
      } catch (error) {
        //eslint-disable-next-line
        this.$log('[C+ Debug Error] dynamicLabels -> error', {
          error,
          projectLabelMaps: this.projectLabelMaps,
          projectId: this.projectId,
          ticket: this.conversation
        });
        return [];
      }
    },

    getTagsProjectId() {
      return this.tagMaps[this.projectId] || {};
    },

    getLabelsProjectId() {
      return this.labelMaps[this.projectId];
    },

    dynamicTagsValue() {
      const { ticket } = this.getTicketFix();
      const { tags = '[]' } = ticket || {};
      if (tags) return JSON.parse(tags).map(item => item.value);
      return [];
    },
    dynamicLabelsValue() {
      return this.dynamicLabels.map(lb => lb.value);
    },
    ticketState() {
      const { ticket } = this.getTicketFix();
      const { state = '' } = ticket || {};
      return state;
    },
    isFeature() {
      const { ticket } = this.getTicketFix();
      const { feature } = ticket || {};
      if (feature) return true;
      return false;
    },
    isFeatureClass() {
      return this.isFeature ? 'follow-active' : '';
    },
    isHeaderCloseClass() {
      return this.isHeaderClose ? 'header-minimize' : '';
    },
    minHeight() {
      return this.isPhoneAgent && this.selectedUserInfo.userInfo ? '' : 'header-minheight';
    },

    hasReSupporting() {
      const { ticket } = this.getTicketFix();
      return reSupporting(ticket, this.conversationsMap, this.tickets);
    },

    isMonitoring() {
      if (this.chatViewingGroup === SERVING_STATE.MONITORING) return true;
      return false;
    },

    c_tagsProjectValue() {
      return this.convsLabels
        .map(item => item.value)
        .filter(item => !this.conversationLabels.some(i => i.value === item));
    },

    c_labelsProjectValue() {
      return this.userLabels
        .map(item => item.value)
        .filter(item => !this.userLabelsAssigning.some(i => i.value === item))
        .sort();
    },

    allowDisplayRegisteredInfo() {
      const { role } = this.user;
      const { projectId } = this.conversation || {};
      const project = this.projectMaps[projectId] || {};
      const { config: { general: { systemAutoAssign } = {} } = {} } = project;
      return systemAutoAssign && [AGENT_ROLE.MODERATOR, AGENT_ROLE.REGULAR].includes(role);
    },

    supportWithUserConsent() {
      return (
        (this.typeConfirmModal === 'support-user' ||
          (this.typeConfirmModal === 're-support' && this.channelHasBot)) &&
        this.enableRequestUser
      );
    },

    allowAddLabelInComplete() {
      const { role } = this.user;
      const { ticket } = this.getTicketFix();
      const { state = '' } = ticket || {};
      const { projectId } = this.selectedConversation || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
      return (
        (role === AGENT_ROLE.LEADER || (role === AGENT_ROLE.MODERATOR && projectHasGroup)) &&
        state === TICKET_STATE.COMPLETE
      );
    }
  },

  watch: {
    pictureUrl() {
      if (this.pictureUrl) this.imageUrl = this.pictureUrl;
    },

    dynamicTags() {
      this.isAddingNewTags = false;
      this.isCloseTags = false;
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    dynamicLabels() {
      this.isAddingNewLabels = false;
      this.isCloseLabels = false;
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    selectedConversation(value) {
      const { role } = this.user;
      if (role && ![AGENT_ROLE.LEADER, AGENT_ROLE.PHONE_AGENT].includes(role)) {
        this.getRegisteredUser(value);
      }
    },

    conversation(newV) {
      if (newV.ticket) {
        this.conversationLabels = newV.ticket.labels
          ? newV.ticket.labels.filter(l => l.sourceType !== 'system')
          : [];
        this.conversationLabelsSystem = newV.ticket.labels
          ? newV.ticket.labels.filter(l => l.sourceType === 'system')
          : [];
        this.userLabelByAgentCount =
          newV.userInfo && newV.userInfo.labels
            ? newV.userInfo.labels.filter(l => l.sourceType === 'agent').length
            : 0;
        this.conversationLabelCount =
          newV.ticket && newV.ticket.labels
            ? newV.ticket.labels.filter(l => l.sourceType === 'agent').length
            : 0;
        this.userLabelsAssigning =
          newV.userInfo && newV.userInfo.labels
            ? newV.userInfo.labels.filter(l => l.sourceType !== 'system')
            : [];
        this.userLabelByAgentCount =
          newV.userInfo && newV.userInfo.labels
            ? newV.userInfo.labels.filter(l => l.sourceType === 'agent').length
            : 0;
        this.conversationLabelCount =
          newV.ticket && newV.ticket.labels
            ? newV.ticket.labels.filter(l => l.sourceType === 'agent').length
            : 0;
        this.userLabelsSystemAssigning =
          newV.userInfo && newV.userInfo.labels
            ? newV.userInfo.labels.filter(l => l.sourceType === 'system')
            : [];
      }
    }
  },

  async created() {
    this.setLocalesToStorage();
    let _locales = localStorage.getItem('locales');
    let locales = JSON.parse(_locales);
    this.locales = locales;
    if (this.pictureUrl) {
      this.imageUrl = this.pictureUrl;
    }
    this.isAddingNewTags = false;
    this.isCloseTags = false;
    this.isAddingNewUserTags = false;
    this.isCloseUserTags = false;

    this.isAddingNewLabels = false;
    this.isCloseLabels = false;
    const data = await this.getTicketFix();
    const { role } = this.user;
    if (role && ![AGENT_ROLE.LEADER, AGENT_ROLE.PHONE_AGENT].includes(role))
      this.getRegisteredUser(data);
  },

  mounted() {
    this.currentDate = new Date();
  },

  methods: {
    ...mapActions('chat', [
      'updateAndEmitTicket',
      'noSupportTicket',
      'supportTicket',
      'removeTicketInTickets',
      'getCreateLabel',
      'updateUserLabels',
      'updateTicketTags',
      'setReady',
      'setSupportWithoutUserAgreement',
      'registerUserAutoAssign',
      'getRegisteredUserAutoAssign',
      'getLabelsByProjectId',
      'getConvLabelsByProjectId',
      'getConversationTicketById',
      'setLocalesToStorage',
      'removeUserRegistered',
      'newHandleRegisterUser',
      'getAgentById'
    ]),
    ...mapActions('session', [
      'addAgentToPeople',
      'getGroupsOpts',
      'setTagsToTicket',
      'updateTagById',
      'setFollowToTicket',
      'addNewTag',
      'addNewTicketTag',
      'addNewLabel',
      'addNewUserLabel',
      'setLabelsToUser',
      'deleteLabel',
      'removeUserLabel',
      'updateLabelById',
      'allowReSupport',
      'getPersonal'
    ]),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('searchManagement', ['getPersonalInfo', 'getUserInfo', 'getExternalUserInfo']),
    ...mapActions('customerSupport', ['getCountries', 'getProvinces']),
    ...mapActions('manageLabels', [
      'getUserLabelsByPersonId',
      'getConversationLabelsByTicketId',
      'updateLabel'
    ]),

    userLabelSysValue(value) {
      if (value === 'virus-sender') {
        return this.$t('src.modules.chat.components.ChatBox.chat-header.virus_sender');
      }
      return value;
    },

    convLabelSysValue(value) {
      if (value === 'virus') {
        return this.$t('src.modules.chat.components.ChatBox.chat-header.virus');
      }
      return value;
    },

    handleToggleHeaderClose() {
      this.$emit('onToggleHeaderClose');
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    handleRegisterUserClick() {
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.chat-header.message-confirm-popup.register_user'
        )
      })
        .then(() => {
          this.handleYesRegisterUser();
        })
        .catch(() => {});
    },

    async confirmModalRegister() {
      this.setGlobalReady(false);
      this.resetDataRegister();

      const { projectId } = this.selectedConversation || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;

      if ((projectHasGroup && this.sameGroup) || !projectHasGroup) {
        if (this.isRegister && !this.isCurrentAgentHandle) {
          const { agentId = '', reason = '', registeredAt: oldRegisteredAt = '' } =
            this.registerItem || {};

          const agent = await this.getAgentById(agentId).catch(err => {
            console.error('err', err); //eslint-disable-line
            return {};
          });
          this.oldReason = reason;
          this.oldAgentName = agent.name;
          this.oldRegisteredAt = oldRegisteredAt;
        }
      }

      // Show Dialog Box
      this.showDialogBox = true;
      this.titleDialogBox = this.$t(
        'src.modules.chat.components.ChatBox.chat-header.register_user_to_the_list'
      );
      this.typeDialogBox = 'register-user';
      this.setGlobalReady(true);
    },

    confirmModalRemoveUserRegistered() {
      this.setGlobalReady(false);
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_remove_this_user_form_your_list'
        )
      })
        .then(() => {
          this.removeRegister();
        })
        .catch(() => {});
      this.setGlobalReady(true);
    },

    async removeAndRegisterUser(agentId) {
      this.setGlobalReady(false);
      try {
        const { userId, channelId, projectId, platform, ticketId, ticket = {} } =
          this.selectedConversation || {};

        const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
        let personalInfo = {
          userId,
          channelId,
          ticketId
        };

        if (projectHasGroup && ticket.groupId) {
          personalInfo['groupId'] = ticket.groupId;
        }

        const hadSucceeded = await this.removeUserRegistered({ ...personalInfo, agentId });
        if (typeof hadSucceeded === 'object' && !hadSucceeded.errors) {
          let personalUpdate = {
            ...personalInfo,
            projectId,
            platform,
            agentId: this.user.id
          };

          if (this.newReason) {
            personalUpdate['reason'] = this.newReason;
          }

          const registeredSuccess = await this.newHandleRegisterUser(personalUpdate);
          if (typeof registeredSuccess === 'object' && !registeredSuccess.errors) {
            this.$baseNotification.success({
              title: this.$t('src.core.App.success'),
              message: this.$t(
                'src.modules.chat.components.ChatBox.chat-header.updated_successfully'
              )
            });
            this.updateStatusAssign(registeredSuccess);
            EventBus.$emit('reloadUserRegistered', { ...registeredSuccess, key: 'REGISTERED' });
            this.showDialogBox = false;
            this.resetDataRegister();
          }
        }
      } catch (_) {
        this.setGlobalReady(true);
      }
      this.setGlobalReady(true);
    },

    async newHandleRegister() {
      const { userId, channelId, projectId, platform, ticketId, ticket = {} } =
        this.selectedConversation || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;

      if ((projectHasGroup && this.sameGroup) || !projectHasGroup) {
        if (this.isRegister && !this.isCurrentAgentHandle) {
          const agentId = this.registerItem && this.registerItem.agentId;
          if (agentId) {
            this.removeAndRegisterUser(agentId);
          }
          return;
        }
      }

      this.setGlobalReady(false);
      let personalUpdate = {
        userId,
        channelId,
        projectId,
        ticketId,
        platform,
        agentId: this.user.id
      };

      if (this.newReason) {
        personalUpdate['reason'] = this.newReason;
      }
      if (projectHasGroup && ticket.groupId) {
        personalUpdate['groupId'] = ticket.groupId;
      }

      try {
        const hadSucceeded = await this.newHandleRegisterUser(personalUpdate);
        if (typeof hadSucceeded === 'object' && !hadSucceeded.errors) {
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t('src.modules.chat.components.ChatBox.chat-header.updated_successfully')
          });
          this.updateStatusAssign(hadSucceeded);
          EventBus.$emit('reloadUserRegistered', { ...hadSucceeded, key: 'REGISTERED' });
          this.showDialogBox = false;
          this.resetDataRegister();
        }
        this.setGlobalReady(true);
      } catch (err) {
        this.setGlobalReady(true);
      }
    },

    async removeRegister() {
      this.setGlobalReady(false);

      const { userId, channelId, projectId, ticketId, ticket = {} } =
        this.selectedConversation || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
      let personalUpdate = {
        userId,
        channelId,
        ticketId,
        agentId: this.user.id
      };

      if (projectHasGroup && ticket.groupId) {
        personalUpdate['groupId'] = ticket.groupId;
      }

      try {
        const hadSucceeded = await this.removeUserRegistered(personalUpdate);
        if (typeof hadSucceeded === 'object' && !hadSucceeded.errors) {
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t('src.modules.chat.components.ChatBox.chat-header.updated_successfully')
          });
          this.updateStatusAssign(hadSucceeded);
          EventBus.$emit('reloadUserRegistered', { ...hadSucceeded, key: 'REMOVED' });
          this.showDialogBox = false;
          this.resetDataRegister();
        }
        this.setGlobalReady(true);
      } catch (err) {
        this.setGlobalReady(true);
      }
    },

    handleYesRegisterUser() {
      const { userId, channelId, projectId, platform } = this.selectedConversation || {};
      if (!userId || !channelId || !projectId || !platform) return false;
      const params = { userId, channelId, projectId, platform, registeredAgentId: this.user.id };
      this.registerUserAutoAssign(params);
    },

    async getRegisteredUser(data) {
      const { userId, channelId, projectId, platform } = data || {};

      if (!userId || !channelId || !projectId || !platform) return false;
      const params = { userId, channelId, projectId, platform, claims: ['registeredInfo'] };

      const res = await this.getRegisteredUserAutoAssign(params).catch(err => {
        console.error('err', err); //eslint-disable-line
        return {};
      });
      this.updateStatusAssign(res);
    },

    updateStatusAssign(res) {
      const { projectId, ticket: { groupId = '' } = {} } = this.selectedConversation || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
      const { registeredInfo = [] } = res || {};

      this.isRegister = !!(registeredInfo && registeredInfo.length > 0);
      if (this.isRegister) {
        if (projectHasGroup && groupId) {
          this.isCurrentAgentHandle = registeredInfo.some(
            val => val.agentId === this.user.id && val.groupId === groupId
          );

          const data = registeredInfo.find(val => {
            if (this.isCurrentAgentHandle) {
              return val.groupId === groupId && val.agentId === this.user.id;
            } else {
              return val.groupId === groupId;
            }
          });

          this.sameGroup = !!data;
          if (this.sameGroup) {
            this.registerItem = data;
          }
        } else {
          this.isCurrentAgentHandle = registeredInfo.some(val => val.agentId === this.user.id);
          this.registerItem = registeredInfo[0];
        }
      }
    },

    mouseLeave() {
      setTimeout(
        () =>
          (this.messageTooltipTicketId = i18n.t(
            'src.modules.search-management.index.conversation_id'
          )),
        500
      );
    },

    copyTicketId() {
      const copyText = document.getElementById('selected-ticket-id');
      const input = document.createElement('input');
      input.value = copyText.textContent.trim();
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      input.remove();

      this.messageTooltipTicketId = i18n.t('common.text.copied');
    },

    detectHeightOfChatHeader() {
      this.$nextTick(() => {
        let height = this.$refs.chatHeader?.clientHeight || 0;
        if (this.userMightNotAvailable) {
          const userMightNotAvailable = this.$refs.userMightNotAvailable.clientHeight;
          height += userMightNotAvailable;
        }
        this.$emit('heightOfChatHeader', height);
      });
    },

    splitLocale(_locale) {
      _locale = _locale.replace('-', '_');
      let split = _locale.split('_');
      let data = {
        langCode: split[0],
        conutryCode: split[1] || '',
        localeCode: `${split[0]}_${split[1]}`,
        conutryName:
          split[1] && split[1] != undefined
            ? (this.locales.countrys[split[1]] && this.locales.countrys[split[1]].c) || _locale
            : _locale,
        langName:
          split[0] && split[0] != undefined
            ? (this.locales.isoLangs[split[0]] && this.locales.isoLangs[split[0]].name) || _locale
            : _locale
      };
      return data;
    },
    imageLoadError() {
      // eslint-disable-next-line
      console.log('Show image error: ', this.avatar);
      return (this.imageUrl = noAvatar);
    },

    async confirmSupportTicket() {
      this.setGlobalReady(false);
      try {
        const { id: conversationId = '', ticketId = '' } = this.$props.conversation;
        const coversationTicket = await this.getConversationTicketById({
          conversationId,
          ticketId
        });
        const { ticket = {} } = coversationTicket || {};
        const { role = '', id } = this.user || {};
        const { projectId = ' ' } = this.selectedConversation;
        const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
        if (
          [AGENT_ROLE.LEADER].includes(role) ||
          (projectHasGroup && [AGENT_ROLE.MODERATOR].includes(role))
        ) {
          if (
            [TICKET_STATE.START, TICKET_STATE.SUPPORTING].includes(ticket.state) &&
            ![id, ticket.id, TICKET_STATUS.FORWARD_GROUP].includes(ticket.assignee)
          ) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_take_over_the_conversation'
            );
            this.typeConfirmModal = 'validate-state-ticket';
            this.showModelconfirm = true;
          } else {
            this.supportThisUser();
          }
        } else {
          this.supportThisUser();
        }
        this.setGlobalReady(true);
      } catch (err) {
        this.setGlobalReady(true);
      }
    },

    async supportThisUser() {
      this.setGlobalReady(false);
      try {
        await new Promise(resolve => setTimeout(() => resolve(true), 0));
        this.assigning = true;
        const groups = await this.getGroupsOpts(this.$props.conversation);
        this.agentGroups = groups.filter(i => i.isEnable);
        const { status } = this.selectedConversation.ticket;
        if ([TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.USER_MENTION].includes(status)) {
          if (this.enableRequestUser || !this.agentGroups || this.agentGroups.length <= 1) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
            );
            this.typeConfirmModal = 'support-user';
            this.showModelconfirm = true;
            this.setSupportWithoutUserAgreement(this.enableRequestUser ? false : true);
          } else {
            this.showSupportTicket = true;
            this.setSupportWithoutUserAgreement(true);
          }
        } else {
          this.setSupportWithoutUserAgreement(false);
          if (!this.agentGroups || this.agentGroups.length === 0) {
            await this.supportTicket(this.selectedConversation);
          } else this.showSupportTicket = true;
        }
      } finally {
        this.assigning = false;
      }
      this.setGlobalReady(true);
    },

    async reSupportThisUser() {
      try {
        this.setReady(false);

        const { id: conversationId = '', ticketId = '' } = this.$props.conversation;
        const result = await this.allowReSupport({ conversationId, id: ticketId });
        if (result && result.error) {
          this.setReady(true);
          return EventBus.$emit('reSupportWarningModal', result);
        }

        const groups = await this.getGroupsOpts(this.$props.conversation);
        this.agentGroups = groups.filter(i => i.isEnable);
        if (!this.channelHasBot) {
          this.setSupportWithoutUserAgreement(true);
          if (!this.agentGroups || this.agentGroups.length <= 1) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
            );
            this.typeConfirmModal = 're-support';
            this.showModelconfirm = true;
          } else this.showSupportTicket = true;
        } else {
          if (this.enableRequestUser || !this.agentGroups || this.agentGroups.length <= 1) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
            );
            this.typeConfirmModal = 're-support';
            this.showModelconfirm = true;
            this.setSupportWithoutUserAgreement(this.enableRequestUser ? false : true);
          } else {
            this.showSupportTicket = true;
            this.setSupportWithoutUserAgreement(true);
          }
        }
      } finally {
        this.setReady(true);
      }

    },

    async dontSupportThisUser() {
      this.setGlobalReady(false);
      await new Promise(resolve => setTimeout(() => resolve(true), 0));
      this.typeConfirmModal = 'no-support-user';
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.chat-header.the_action_will_remove_this_user_from_the_system_are_you_sure_you_want_to_not_support_this_user'
        )
      })
        .then(() => {
          this.handleYes(this.typeConfirmModal);
        })
        .catch(() => {});
      this.setGlobalReady(true);
    },

    async handleYes(type) {
      this.showModelconfirm = false;
      switch (type) {
        case 'support-user':
          this.handleSupportTicket();
          break;

        case 're-support':
          this.handleSupportTicket();
          break;

        case 'no-support-user':
          await this.noSupportTicket({
            ticket: {
              ...this.selectedConversation.ticket,
              state: TICKET_STATE.COMPLETE,
              status: TICKET_STATUS.NO_SUPPORT
            }
          });
          break;

        case 'delete-tag':
          {
            this.isCloseTags = true;
            const { ticket } = this.getTicketFix();
            const { id, projectId } = ticket;
            this.isAddingNewTags = true;
            this.updateTicketTags({
              labels: {
                $remove: [{ id: this.currenttag.id }]
              },
              projectId,
              ticketId: id
            })
              .then(res => {
                const { errors } = res;
                if (errors) {
                  // eslint-disable-next-line
                  this.$log('[C+ Debug] Delete User Label >>> errors', errors);
                  // eslint-disable-next-line
                  console.log('[C+ Debug] Delete User Label >>> labelId', this.currentlabel.id);
                } else {
                  this.$baseNotification.success({
                    title: this.$t('src.core.App.success'),
                    message: this.$t(
                      'src.modules.chat.components.ManageLabels.index.item_was_deleted'
                    )
                  });
                }
              })
              .finally(() => {
                this.isAddingNewTags = false;
              });
          }
          break;

        case 'delete-label':
          {
            this.isCloseLabels = true;
            const { channelId = '' } =
              this.selectedConversation && this.selectedConversation.ticket;
            const userId = this.selectedConversation.userInfo.id;
            const { platform = '', projectId = '' } = this.selectedConversation;
            this.isAddingNewLabels = true;
            this.updateUserLabels({
              labels: {
                $remove: [{ id: this.currentlabel.id }]
              },
              userId,
              channelId,
              projectId,
              platform
            })
              .then(res => {
                const { errors } = res;
                if (errors) {
                  // eslint-disable-next-line
                  this.$log('[C+ Debug] Delete User Label >>> errors', errors);
                  // eslint-disable-next-line
                  console.log('[C+ Debug] Delete User Label >>> labelId', this.currentlabel.id);
                } else {
                  this.setReady(true);
                  this.$baseNotification.success({
                    title: this.$t('src.core.App.success'),
                    message: this.$t(
                      'src.modules.chat.components.ManageLabels.index.item_was_deleted'
                    )
                  });
                }
              })
              .finally(() => {
                this.isAddingNewLabels = false;
              });
          }
          break;

        case 'validate-state-ticket':
          this.supportThisUser();
          break;

        default:
          break;
      }
    },

    async handleSupportWithoutUserAgreement() {
      await this.setSupportWithoutUserAgreement(true);
      this.showModelconfirm = false;
      this.handleSupportTicket();
    },

    async handleNo() {
      this.showModelconfirm = false;
    },

    async handleSupportTicket() {
      this.setReady(false);
      if (!this.agentGroups || this.agentGroups.length === 0) {
        await this.supportTicket(this.$props.conversation);
      } else {
        this.showSupportTicket = true;
      }
      this.setReady(true);
    },

    async holdConversation() {
      this.disableBtnWhenUpdateTicket = true;
      const { ticket } = this.getTicketFix();
      const params = {
        ticket: ticket,
        status: this.getHold == false ? TICKET_STATUS.HOLDING : TICKET_STATUS.AGENT_SUPPORTING
      };
      await this.updateAndEmitTicket(params);
      await waiting(500).then(() => (this.disableBtnWhenUpdateTicket = false));
    },

    async handleChatOptMenu(command) {
      this.setGlobalReady(false);
      await new Promise(resolve => setTimeout(() => resolve(true), 0));
      const { role, id } = this.user;
      const servingState = this.chatViewingGroup;
      const waitingState = this.waitingMode;
      const { ticket } = this.getTicketFix();
      switch (command) {
        case 'transfer-to':
          this.transferBoxData = {
            meId: id,
            servingState,
            waitingState,
            role,
            channelId: this.conversation.channelId,
            projectId: this.projectId,
            ticket
          };
          if (ticket) {
            EventBus.$emit('transferBox', this.transferBoxData);
            this.$root.$emit('bv::show::modal', 'TransferBox', '#transferBox');
          } else {
            // eslint-disable-next-line
            console.log('TCL: handleChatOptMenu -> this.transferBoxData', this.transferBoxData);
          }
          break;
        case 'assign-to':
          this.assignBoxData = {
            meId: id,
            servingState,
            waitingState,
            role,
            channelId: this.conversation.channelId,
            projectId: this.projectId,
            ticket
          };
          if (ticket) {
            EventBus.$emit('assignBox', this.assignBoxData);
            this.$root.$emit('bv::show::modal', 'AssignBox', '#assignBox');
          } else {
            // eslint-disable-next-line
            console.log('TCL: handleChatOptMenu -> this.assignBoxData', this.assignBoxData);
          }

          break;
        case 'escalate-to':
          this.escalateBoxData = {
            meId: id,
            servingState,
            waitingState,
            role,
            channelId: this.conversation.channelId,
            projectId: this.projectId,
            ticket
          };
          if (ticket) {
            EventBus.$emit('escalateBox', this.escalateBoxData);
            this.$root.$emit('bv::show::modal', 'EscalateBox', '#escalateBox');
          } else {
            // eslint-disable-next-line
            console.log('TCL: handleChatOptMenu -> this.escalateBoxData', this.escalateBoxData);
          }
          break;

        case 'forward-group':
          EventBus.$emit('forwardGroup', ticket);
          break;

        default:
          break;
      }
      this.setGlobalReady(true);
    },

    getTicketFix() {
      if (this.selectedConversation && this.selectedConversation.ticket) {
        const ticket = this.tickets[this.selectedConversation.ticket.id];
        return { ...this.selectedConversation, ticket };
      }
      const { id, ticket: { id: ticketId } = {} } = this.conversation || {};
      const ticket = (this.tickets && this.tickets[ticketId]) || {};
      const conversation = this.conversations.find(c => c.id === id);
      return { ...conversation, ticket };
    },

    openStatusHistory() {
      EventBus.$emit('openStatusHistory');
    },

    async openManageLabels() {
      this.setGlobalReady(false);
      await new Promise(resolve => setTimeout(() => resolve(true), 0));
      EventBus.$emit('openManageLabels');
      this.setGlobalReady(true);
    },

    openManageUserLabels() {
      EventBus.$emit('openManageUserLabels');
    },

    handleCloseSupportTicket() {
      this.showSupportTicket = false;
    },

    async handleCloseTag(tag) {
      this.currenttag = tag;
      this.typeConfirmModal = 'delete-tag';
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_delete_this_label'
        )
      })
        .then(() => {
          this.handleYes(this.typeConfirmModal);
        })
        .catch(() => {});
    },

    async showInputTag() {
      this.inputVisibleTag = true;
      this.isAddingNewTags = true;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      }, 200);
      await this.getConvLabelsByProjectId({
        projectIds: [this.projectId || ''],
        entity: 'tickets',
        sourceType: 'agent'
      }).finally(() => {
        this.isAddingNewTags = false;
      });
      this.tagsProject = Object.keys(this.getTagsProjectId)
        .map(key => {
          return this.getTagsProjectId[key];
        })
        .filter(i => !i.agentId || i.agentId !== 'bot');

      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    async handleInputConfirmTag(e) {
      if (e) e.preventDefault();
      if (this.inputValueTag.length > this.maxlengthTag) return;
      this.isAddingNewTags = true;
      this.inputValueTag = this.inputValueTag ? this.inputValueTag.trim() : '';
      const inputValueTag = this.inputValueTag;
      if (inputValueTag) {
        if (isContainingSystemDefinedWord(inputValueTag)) {
          this.$refs.saveTagInput.content = '';
          this.inputValueTag = '';
          this.isAddingNewTags = false;
          this.inputVisibleTag = false;
          return true;
        }

        const tagExist = Object.keys(this.getTagsProjectId)
          .map(key => {
            return this.getTagsProjectId[key];
          })
          .filter(i => (!i.agentId || i.agentId !== 'bot') && i.value === inputValueTag);
        const { ticket } = this.getTicketFix();
        const { id, projectId } = ticket;

        this.inputVisibleTag = false;
        if (this.dynamicTagsValue.some(i => i === inputValueTag)) {
          this.inputValueTag = '';
          this.isAddingNewTags = false;
          return true;
        }
        const tagDetail = this.convsLabels.find(item => item.value === inputValueTag);
        const payload = {
          value: inputValueTag,
          type: tagDetail ? tagDetail.type : this.getTagColor(),
          sourceType: 'agent'
        };
        if (tagExist.length) payload.id = tagExist[0].id;
        this.disableBtnWhenUpdateTicket = true;
        await this.updateTicketTags({
          projectId: projectId,
          labels: {
            $add: [{ ...payload }]
          },
          ticketId: id
        })
          .then(data => {
            const { duplicates } = data;
            if (duplicates && duplicates.length > 0) {
              if (duplicates[0].sourceType === 'agent') {
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t(
                    'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_label',
                    {
                      labelValue: inputValueTag
                    }
                  )
                });
              } else {
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t(
                    'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_added_by_bot',
                    {
                      labelValue: inputValueTag
                    }
                  )
                });
              }
            }
          })
          .catch(async res => {
            const { errorCode } = res;
            if (errorCode === 'ExcessLimit') {
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: this.$t(
                  'src.modules.chat.components.ChatBox.chat-header.can_not_add_more_than_5_labels_per_ticket'
                )
              });
            }
          })
          .finally(async () => {
            this.inputValueTag = '';
            this.isAddingNewTags = false;
            await waiting(500).then(() => (this.disableBtnWhenUpdateTicket = false));
          });
      } else {
        this.inputVisibleTag = false;
        this.isAddingNewTags = false;
      }
    },

    handleInputBlurTag() {
      this.inputValueTag = this.inputValueTag ? this.inputValueTag.trim() : '';
      if (this.inputValueTag === '') {
        this.inputVisibleTag = false;
        this.isAddingNewTags = false;
      }
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    async updateTagsToTicket(newTags) {
      const { ticket } = this.getTicketFix();
      await this.setTagsToTicket({ ticket, tags: newTags });
    },

    querySearchTags(query, cb) {
      const links = this.tagsProject;
      const resultsSearch = query ? links.filter(this.createFilterTags(query)) : links;
      const resultsNotDuplicate = resultsSearch
        ? resultsSearch.filter(item => {
            return !this.dynamicTagsValue.includes(item.value);
          })
        : resultsSearch;
      const top = resultsNotDuplicate.slice(0, 10);
      cb(top); // number of things returned
    },

    createFilterTags(query) {
      return link => {
        return link.value.toLowerCase().includes(query.toLowerCase());
      };
    },

    handleSelectTag(item) {
      this.inputValueTag = item;
      this.handleInputConfirmTag();
    },

    async changeTagColor(type, tag) {
      this.isAddingNewTags = true;
      this.updateLabel({
        labels: [
          {
            type,
            id: tag.id,
            value: tag.value,
            oldType: tag.type,
            oldValue: tag.value,
            projectId: this.projectId,
            entityType: 'tickets'
          }
        ]
      }).finally(() => {
        this.isAddingNewTags = false;
      });
    },

    async handleFollow() {
      const follow = !this.isFeature;
      const { ticket } = this.getTicketFix();
      await this.setFollowToTicket({ ticket, follow });
    },

    findTag(value) {
      let result = Object.keys(this.getTagsProjectId).map(key => {
        return this.getTagsProjectId[key];
      });
      return result.find(item => {
        if (item.value === value) return item;
      });
    },

    getTagColor() {
      return this.tagColors[Math.floor(Math.random() * this.tagColors.length)];
    },

    // ************* Functions for "User Label" feature ************* //
    async showInputLabel() {
      this.inputVisibleLabel = true;
      this.isAddingNewLabels = true;
      await this.getLabelsByProjectId({
        projectIds: [this.projectId || ''],
        entity: 'personals',
        sourceType: 'agent'
      }).finally(() => {
        this.isAddingNewLabels = false;
      });
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.saveLabelInput.$refs.input.focus();
        });
      }, 200);

      if (this.getLabelsProjectId) {
        this.labelsProject = Object.keys(this.getLabelsProjectId)
          .map(key => {
            return this.getLabelsProjectId[key];
          })
          .filter(i => !i.sourceType || i.sourceType !== 'bot');
      }
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    handleInputBlurLabel() {
      this.inputValueLabel = this.inputValueLabel ? this.inputValueLabel.trim() : '';
      if (this.inputValueLabel === '') {
        this.inputVisibleLabel = false;
        this.isAddingNewLabels = false;
      }
      this.$nextTick(() => {
        this.detectHeightOfChatHeader();
      });
    },

    async handleInputConfirmLabel(e) {
      if (e) e.preventDefault();
      if (this.inputValueLabel.length > this.maxlengthTag) return;
      this.inputValueLabel = this.inputValueLabel ? this.inputValueLabel.trim() : '';
      const inputValueLabel = this.inputValueLabel;
      if (inputValueLabel) {
        if (isContainingSystemDefinedWord(inputValueLabel)) {
          this.$refs.saveLabelInput.content = '';
          this.inputValueLabel = '';
          this.isAddingNewLabels = false;
          this.inputVisibleLabel = false;
          return true;
        }

        const { userId, channelId, projectId, platform } = this.getTicketFix();

        this.inputVisibleLabel = false;

        if (this.dynamicLabelsValue.some(i => i === inputValueLabel)) {
          this.inputValueLabel = '';
          this.isAddingNewLabels = false;
          return true;
        }
        const labelDetail = this.userLabels.find(item => item.value === inputValueLabel);
        this.isAddingNewLabels = true;
        this.updateUserLabels({
          labels: {
            $add: [
              {
                value: inputValueLabel,
                type: labelDetail ? labelDetail.type : this.getTagColor(),
                sourceType: 'agent'
              }
            ]
          },
          userId,
          channelId,
          projectId,
          platform
        })
          .then(data => {
            const { duplicates } = data;
            if (duplicates && duplicates.length > 0) {
              if (duplicates[0].sourceType === 'agent') {
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t(
                    'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_label',
                    {
                      labelValue: inputValueLabel
                    }
                  )
                });
              } else {
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t(
                    'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_added_by_admin_and_bot',
                    {
                      labelValue: inputValueLabel
                    }
                  )
                });
              }
            }
          })
          .catch(res => {
            const { errors } = res;
            if (errors) {
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: this.$t(
                  'src.modules.chat.components.ChatBox.chat-header.can_not_add_more_than_5_labels_per_ticket'
                )
              });
              this.isAddingNewLabels = false;
            } else {
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t('src.modules.chat.components.ManageLabels.index.item(s)_was_saved')
              });
            }
          })
          .finally(() => {
            this.inputValueLabel = '';
            this.isAddingNewLabels = false;
            this.setReady(true);
          });
      } else {
        this.inputVisibleLabel = false;
      }
    },

    findLabel(value) {
      let result = Object.keys(this.getLabelsProjectId).map(key => {
        return this.getLabelsProjectId[key];
      });
      return result.find(item => {
        if (item.value === value) return item;
      });
    },

    querySearchLabels(query, cb) {
      const links = this.labelsProject;
      const resultsSearch = query ? links.filter(this.createFilterLabels(query)) : links;
      const resultsNotDuplicate = resultsSearch
        ? resultsSearch.filter(item => {
            return !this.dynamicLabelsValue.includes(item.value);
          })
        : resultsSearch;
      const top = resultsNotDuplicate.slice(0, 10);
      cb(top); // number of things returned
    },

    createFilterLabels(query) {
      return link => {
        return link.value.toLowerCase().includes(query.toLowerCase());
      };
    },

    handleSelectLabel(item) {
      this.inputValueLabel = item;
      this.handleInputConfirmLabel();
    },

    toggleHeaderCollapse() {
      this.$emit('statusHeaderIsClose');
    },

    async handleCloseLabel(label) {
      this.currentlabel = label;
      this.typeConfirmModal = 'delete-label';
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_delete_this_label'
        )
      })
        .then(() => {
          this.handleYes(this.typeConfirmModal);
        })
        .catch(() => {});
    },

    async updateLabelsToUser(newLabels) {
      const { userId, channelId } = this.selectedConversation || {};
      const user = this.people[`${userId}_${channelId}`] || {};

      await this.setLabelsToUser({ user, labels: newLabels });
    },

    async changeLabelColor(type, label) {
      this.isAddingNewLabels = true;
      this.updateLabel({
        labels: [
          {
            type,
            id: label.id,
            value: label.value,
            oldType: label.type,
            oldValue: label.value,
            projectId: this.projectId,
            entityType: 'personals'
          }
        ]
      }).finally(() => {
        this.isAddingNewLabels = false;
      });
    },

    dropdownUserLabelAction() {
      if (this.chooseLabelToChangeColor) {
        this.$refs &&
          this.$refs[this.chooseLabelToChangeColor] &&
          this.$refs[this.chooseLabelToChangeColor][0].doClose();

        this.chooseLabelToChangeColor = '';
      }
    },

    showChangeColor(id) {
      this.chooseLabelToChangeColor = id;
    },

    // ************* Functions for "User Profile" --START-- feature ************* //
    async handleUserProfileClick() {
      this.setGlobalReady(false);
      try {
        const { userId, channelId, projectId, platform } = this.getTicketFix();
        const personalParams = { userId, channelId, projectId, platform };
        const personalInfoRaw = await this.getPersonalInfo(personalParams);
        const channel = this.channelsMap[channelId];
        if (Object.keys(personalInfoRaw).length === 0) {
          const userParams = { userId, channelId };
          let userInfoRaw = await this.getUserInfo(userParams);
          if (Object.keys(userInfoRaw).length > 0 && userInfoRaw.constructor === Object) {
            this.userInfo = this.m_transformUserInfo(userInfoRaw);
          } else {
            this.userInfo = [];
          }
        } else {
          const {
            advancedConfigs: { displayAgent = {} }
          } = channel;
          this.userInfo = await this.m_transformPersonalInfo(personalInfoRaw, displayAgent);
        }

        const externalUserSystem =
          (channel &&
            channel.advancedConfigs &&
            channel.advancedConfigs.person &&
            channel.advancedConfigs.person.externalUserSystem) ||
          {};
        const { displayExternalUserSystem: enabled = {} } = externalUserSystem;
        if (enabled) {
          this.enableExternalUserInfo = true;
          const externalUserInfoRaw = await this.getExternalUserInfo({
            channel,
            userId
          });
          if (externalUserInfoRaw === 'error') {
            this.externalError = true;
          } else if (externalUserInfoRaw === 'data error') {
            this.externalNoData = true;
          } else if (externalUserInfoRaw.length > 0) {
            this.externalUserInfo = this.m_transformExternalUserInfo(externalUserInfoRaw);
          }
        } else {
          this.enableExternalUserInfo = false;
        }

        // Show Dialog Box
        this.showDialogBox = true;
        this.titleDialogBox = this.$t('src.modules.search-management.index.user_info');
        this.typeDialogBox = 'user-info';
        this.setGlobalReady(true);
      } catch (error) {
        //eslint-disable-next-line
        console.log('handleUserProfileClick -> handleUserProfileClick', error);
        this.setGlobalReady(true);
      }
    },

    resetDataRegister() {
      this.newReason = '';
      this.oldReason = '';
      this.oldAgentName = '';
      this.oldRegisteredAt = '';
      this.newReasonErr = '';
    },

    cancelRegister() {
      this.resetDataRegister();
      this.showDialogBox = false;
    },

    handleBtnCancelDialogBox() {
      this.resetExternalInfoData();
      this.showDialogBox = false;
    },

    resetExternalInfoData() {
      this.externalUserInfo.length = 0;
      this.enableExternalUserInfo = false;
      this.externalNoData = false;
      this.externalError = false;
    },

    async m_transformPersonalInfo(rawData, displayAgent) {
      const lang = localStorage.getItem('language');
      const displayLanguage = lang.replace('-', '_');
      let provinces = {};
      let countries = {};

      if (rawData.country && rawData.country.key) {
        /**[{code: "AF", name: "Afghanistan"}, {}, ...] */
        const countryList = await this.getCountries({ language: displayLanguage });
        countries = countryList.reduce((obj, { code, name }) => {
          obj[code] = name;
          return obj;
        }, {});
      }

      if (rawData.province && rawData.province.key) {
        const provinceList = await this.getProvinces({
          code: rawData.country.key,
          language: displayLanguage
        });
        provinces = provinceList.reduce((obj, { code, name }) => {
          obj[code] = name;
          return obj;
        }, {});
      }

      const personalInfo = [];

      personalInfo.push({
        id: 'userId',
        label: this.$t('src.modules.search-management.index.user_id'),
        valueString: rawData.id || ''
      });
      personalInfo.push({
        id: 'name',
        label: this.$t('src.modules.search-management.index.user_name'),
        valueString: rawData.name || ''
      });
      personalInfo.push({
        id: 'locale',
        label: this.$t('src.modules.search-management.index.locale'),
        valueString: this.languageList[rawData.locale] || ''
      });
      personalInfo.push({
        id: 'platform',
        label: this.$t('src.modules.search-management.index.platform'),
        valueString: rawData.platform || ''
      });
      personalInfo.push({
        id: 'email',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.email'),
        valueString: rawData.email || ''
      });
      personalInfo.push({
        id: 'fullName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.full_name'
        ),
        valueString: rawData.fullName || ''
      });
      personalInfo.push({
        id: 'firstName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.first_name'
        ),
        valueString: rawData.firstName || ''
      });
      personalInfo.push({
        id: 'lastName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.last_name'
        ),
        valueString: rawData.lastName || ''
      });
      personalInfo.push({
        id: 'birthday',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.birthday'
        ),
        valueString: this.m_formatTime(rawData.birthday, 'YYYY/MM/DD') || rawData.birthday || ''
      });
      personalInfo.push({
        id: 'gender',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.gender'
        ),
        valueString:
          rawData.gender && rawData.gender.key
            ? PERSONAL_OPTIONS[displayLanguage][rawData.gender.key] +
              `${rawData.gender.key === 'other' ? `: ${rawData.gender.value}` : ''}`
            : ''
      });
      personalInfo.push({
        id: 'phoneNumber',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.phone_number'
        ),
        valueString: rawData.phoneNumber || ''
      });
      personalInfo.push({
        id: 'address',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.address'
        ),
        valueString: rawData.address || ''
      });
      personalInfo.push({
        id: 'city',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.city'),
        valueString: rawData.city || ''
      });
      personalInfo.push({
        id: 'province',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.province'
        ),
        valueString:
          rawData.province && rawData.province.key ? provinces[rawData.province.key] || '' : ''
      });
      personalInfo.push({
        id: 'country',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.country'
        ),
        valueString:
          rawData.country && rawData.country.key ? countries[rawData.country.key] || '' : ''
      });
      personalInfo.push({
        id: 'postCode',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.post_code'
        ),
        valueString: rawData.postCode || ''
      });
      personalInfo.push({
        id: 'maritalStatus',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.marital_status'
        ),
        valueString:
          rawData.maritalStatus && rawData.maritalStatus.key
            ? PERSONAL_OPTIONS[displayLanguage][
                rawData.maritalStatus.key === 'S' ? 'single' : rawData.maritalStatus.key
              ]
            : ''
      });
      personalInfo.push({
        id: 'relationship',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.relationship'
        ),
        valueString: rawData.relationship || ''
      });
      personalInfo.push({
        id: 'militaryStatus',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.military_status'
        ),
        valueString: rawData.militaryStatus || ''
      });
      personalInfo.push({
        id: 'occupation',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.occupation'
        ),
        valueString: rawData.occupation || ''
      });
      personalInfo.push({
        id: 'companyName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.company_name'
        ),
        valueString: rawData.companyName || ''
      });
      personalInfo.push({
        id: 'workPhoneNumber',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_phone_number'
        ),
        valueString: rawData.workPhoneNumber || ''
      });
      personalInfo.push({
        id: 'workEmail',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_email'
        ),
        valueString: rawData.workEmail || ''
      });
      personalInfo.push({
        id: 'privacyPolicy',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.privacy_policy'
        ),
        valueString: rawData.privacyPolicy || ''
      });
      personalInfo.push({
        id: 'tc',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.tc'),
        valueString: rawData.tc || ''
      });

      const customFields = [];
      if (rawData.customizeInfo && Object.keys(rawData.customizeInfo).length > 0) {
        const {
          advancedConfigs: { customizeFields = {}, defaultLanguage } = {}
        } = this.channelsMap[rawData.channelId];

        Object.keys(customizeFields).sort().map(k => {
          if (!rawData.customizeInfo[customizeFields[k].prop]) return k;
          customFields.push({
            id: customizeFields[k].prop,
            label:
              customizeFields[k].label[displayLanguage] ||
              customizeFields[k].label[defaultLanguage] ||
              '',
            valueString: String(rawData.customizeInfo[customizeFields[k].prop]) || ''
          });
          return k;
        });
      }

      if (displayAgent && Object.keys(displayAgent).length > 0) {
        const filterDisplayAgent = Object.keys(displayAgent).filter(
          key => displayAgent[key] === true
        );
        filterDisplayAgent.unshift('name', 'locale');
        return concat(
          personalInfo.filter(item => {
            return filterDisplayAgent.indexOf(item.id) >= 0;
          }),
          customFields
        );
      } else return concat(personalInfo, customFields);
    },

    m_transformExternalUserInfo(rawData) {
      return rawData
        .map(item => {
          const { label = '', value = '' } = item;
          if (!label || !value) return false;

          return {
            id: label,
            label,
            valueString: value
          };
        })
        .filter(i => i);
    },

    m_formatTime(value, formatString = 'DD MMM YYYY LT') {
      if (value) return moment(value).format(formatString);
      return null;
    },

    m_gender(key) {
      if (key === 'male') return this.$t('src.modules.profile.components.user-information.male');
      else return this.$t('src.modules.profile.components.user-information.female');
    },
    // ************* Functions for "User Profile" --END-- feature ************* //
    isBotColor(type) {
      return ['primary', 'success', 'info', 'danger', 'warning'].indexOf(type) === -1;
    },

    rg_formatTime(val) {
      return convertTimeUTC(val);
    }
  }
};
</script>

<style lang="scss">
.header-action-menu {
  transform: translate3d(-135px, 34px, 0px) !important;
}
.form-register {
  .my-4 b {
    font-weight: bold !important;
  }
}
</style>
<style lang="scss" scoped>
.header-minheight {
  min-height: 93px;
}
.user-status-wc-css {
  color: #fff;
  font-weight: 300;
  position: absolute;
  bottom: -28px;
  background-color: #fb6340;
  width: 100%;
  margin-left: -12px;
  line-height: 2;
  text-align: center;
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 8px;
  vertical-align: middle;
  margin-left: 95px;
  margin-top: 23px;
  position: absolute;
  font-weight: 900;
}

.nat-maxlengthTag.error {
  color: red;
}
.nat-maxlengthLabel {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 8px;
  vertical-align: middle;
  margin-left: 150px;
  margin-top: 23px;
  position: absolute;
  font-weight: 900;
}

.nat-maxlengthLabel.error {
  color: red;
}

/deep/.disable-item {
  /deep/.disabled {
    cursor: not-allowed;
  }
}
.follow {
  position: absolute;
  top: 4px;
  left: 5px;
  color: #8898aa;
}

.follow-active {
  position: absolute;
  top: 4px;
  left: 5px;
  color: #fb6340;
}

.chat-tag {
  /deep/.el-tag__close.el-icon-close {
    top: 3px !important;
  }
}

.chat-label {
  /deep/.el-input input {
    height: 23px !important;
    line-height: 25px !important;
    width: 350px;
    padding-right: 40px;
  }
}
.chat-status-tag {
  align-items: flex-end;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  .chat-tag-label {
    padding-right: 5px;
    float: left;
  }
  .chat-tag-loading {
    padding-right: 5px;
    float: left;
  }
  .chat-tag-input {
    padding-right: 5px;
    float: left;
    display: block;
    width: 350px;
  }

  .chat-tag-item {
    padding-right: 5px;
    float: left;
  }
  /deep/.el-input input {
    height: 23px !important;
    line-height: 25px !important;
    width: 350px;
    padding-right: 40px;
  }
}

.chats-header {
  z-index: 11;
  &.fixed {
    position: absolute !important;
    width: 100%;
    background-color: #ffffff;
    padding: 10px 12px !important;
  }

  .chat-status-tag {
    display: flex;
    .chat-tag {
      display: flex;
      span {
        display: flex;
      }
      button {
        height: 28px;
      }
    }
  }

  .badge {
    &.date {
      cursor: pointer;
    }
  }

  .add-label-button {
    cursor: pointer;
    float: left;
  }

  .label-item {
    float: left;
    padding: 0 5px;
    line-height: 20px;
    height: 22px;
  }

  .label-item > p {
    font-size: 10px;
    line-height: 20px;
  }

  .user-label-item {
    float: left;
    padding: 0 5px;
    line-height: 20px;
    height: 22px;
  }

  .user-label-item > p {
    font-size: 10px;
    line-height: 20px;
  }
}

// tags
.el-tag p {
  height: 100%;
  line-height: 25px;
  font-size: 12px;
  white-space: nowrap;
  max-width: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 0px;
}

.el-input--mini .el-input__inner {
  height: 23px !important ;
  line-height: 25px;
}

.input-new-tag {
  margin-right: 5px;
}

.color-tag-col {
  padding-left: 1px !important ;
  padding-right: 1px !important ;
  & /deep/.el-button {
    padding: unset !important;
  }
}

.color-tag-row {
  margin-left: 3px !important ;
  margin-right: 3px !important ;
}

.avatar-header {
  height: 67px;
  width: 67px;
}

.footer-register-us {
  float: right;
}
.form-register {
  .fr-old-reason {
    color: black !important;
  }
}
</style>
