<template>
  <div class="chat-type-image-map">
    <img
      :src="imageUrl"
      :usemap="`#imagemap_${idImageMap}`"
      class="base-size-image-map"
      alt="ImageMap"
    />
    <map :name="`imagemap_${idImageMap}`">
      <area
        v-for="action in actions"
        :key="action.id"
        :alt="action.label"
        :coords="
          `${action.area.x},${action.area.y},${action.area.width},${action.area.height}`
        "
        shape="rect"
        target="_blank"
        @click="redirect2Url(action)"
      />
    </map>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';

export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },

  computed: {
    imageUrl() {
      return this.message ? this.message.image_url : '';
    },

    idImageMap() {
      return uuid.v4();
    },

    actions() {
      if (!this.message) {
        return [];
      } else {
        let { actions, baseSize } = this.message;
        if (actions && Array.isArray(actions)) {
          const { width, height } = baseSize;
          actions = actions.map(action => {
            const area = {};
            area.x = parseInt((400 * action.area.x) / width);
            area.y = parseInt((400 * action.area.y) / height);
            area.width = parseInt(
              (400 * (action.area.width + action.area.x)) / width
            );
            area.height = parseInt(
              (400 * (action.area.height + action.area.y)) / height
            );
            action.area = area;
            action.id = uuid.v4();
            return action;
          });
          return actions;
        }
        return [];
      }
    }
  },

  methods: {
    redirect2Url(action) {
      if (action.type === 'uri') window.open(action.linkUri);
    }
  }
};
</script>

<style scoped>
.base-size-image-map {
  height: 400px;
  width: 400px;
}
</style>
