<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group search-box shadow">
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-search text-primary" @click="handleSearchProduct()" />
            </span>
          </div>
          <input
            v-model="productName"
            :placeholder="
              $t('src.modules.chat.components.ProductPanel.search-box.search')
            "
            class="form-control"
            type="text"
            @input="handleSearchProduct"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      productName: ''
    };
  },

  created() {
    this.handleSearchProduct();
  },

  methods: {
    ...mapActions('chat', ['setProdSearchText']),

    handleSearchProduct() {
      this.setProdSearchText(this.productName);
    }
  }
};
</script>

<style></style>
