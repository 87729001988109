import { i18n } from 'locales';

export const KEY_APP_TOKEN = 'app_token';
export const KEY_APP_TOKEN_EXP = 'app_token_exp';

export const COUNTRY_DATA_URL = 'https://cdn.jsdelivr.net/npm/country-state-city-data@1.2.0/dist';

export const SERVING_STATE = {
  INACTIVE: -1,
  BOT: 0,
  WAITING: 1,
  AGENT: 2,
  COMMENTS: 3,
  MONITORING: 4
};

export const STANDARDIZE_PLATFORM_NAME = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  line: 'LINE',
  webchat: 'WebChat',
  liffchat: 'LIFFChat',
  zalo: 'Zalo'
};

export const END_USER_PLATFORM = [
  'facebook',
  'line',
  'whatsapp',
  'webchat',
  'zalo',
  'instagram',
  'liffchat'
];

export const THIRDPARTY_PLATFORM = ['bot'];

export const READED_MESSAGE_USER_PLATFORM = ['facebook', 'webchat', 'liffchat'];
export const REQ_AGNT_REP_AGNT = 'REQ_AGNT_REP_AGNT';
export const REQ_AGNT_FEEDBACK = 'REQ_AGNT_FEEDBACK';
export const USER_SELECT_ROOM = 'USER_SELECT_ROOM';
export const USER_SELECT_GROUP = 'USER_SELECT_GROUP';
export const GROUP_CONVERSATIONS = [
  {
    id: SERVING_STATE.BOT,
    icon: 'fab fa-android',
    title: i18n.t('src.core.constants.conversations_with_bots'),
    count: 0,
    index: 0
  },
  {
    id: SERVING_STATE.WAITING,
    icon: 'fa fa-hourglass',
    title: i18n.t('src.core.constants.waiting_conversations'),
    count: 0,
    index: 1
  },
  {
    id: SERVING_STATE.AGENT,
    icon: 'fa fa-users',
    title: i18n.t('src.core.constants.conversations_with_agents'),
    count: 0,
    index: 2
  },
  {
    id: SERVING_STATE.COMMENTS,
    icon: 'fa fa-comments',
    title: i18n.t('src.core.constants.comments'),
    count: 0,
    index: 3
  },
  {
    id: SERVING_STATE.MONITORING,
    icon: 'far fa-clock',
    title: i18n.t('src.core.constants.monitoring'),
    count: 0,
    index: 4
  }
];

export const COMMENT_INPUT_TYPE = {
  EDIT: 1,
  ADD: 0
};

export const MSG_STATE_COULD_NOT_SEND = -1;
export const OK_NEW_FOR_TESTING = -1;

export const TICKET_STATE = {
  REFER: 'REFER',
  REQUEST: 'REQUEST',
  START: 'START',
  SUPPORTING: 'SUPPORTING',
  FINISH: 'FINISH',
  COMPLETE: 'COMPLETE'
};

export const TICKET_STATUS = {
  USER_MENTION: 'USER_MENTION',
  NO_SUPPORT: 'NO_SUPPORT',
  BOT_HANDLE: 'BOT_HANDLE',
  REQUEST_AGENT: 'REQUEST_AGENT',
  REQUEST_USER: 'REQUEST_USER',
  ASSIGN_TO: 'ASSIGN_TO',
  USER_IDLE: 'USER_IDLE',
  AGENT_IDLE: 'AGENT_IDLE',
  HOLDING: 'HOLDING',
  TRANSFER_TO: 'TRANSFER_TO',
  ESCALATE_TO: 'ESCALATE_TO',
  FORWARD_GROUP: 'FORWARD_GROUP',
  WAITING_TIMEOUT: 'WAITING_TIMEOUT',
  RESPONSE_TIMEOUT: 'RESPONSE_TIMEOUT',
  FINISH_TIMEOUT: 'FINISH_TIMEOUT',
  AGENT_START: 'AGENT_START',
  AGENT_SUPPORTING: 'AGENT_SUPPORTING',
  AGENT_FINISH: 'AGENT_FINISH',
  USER_FINISH: 'USER_FINISH',
  BOT_TIMEOUT: 'BOT_TIMEOUT',
  BOT_COMPLETE: 'BOT_COMPLETE',
  COMPLETE: 'COMPLETE',
  SYSTEM_TERMINATE: 'SYSTEM_TERMINATE',
  SYSTEM_MAINTENANCE: 'SYSTEM_MAINTENANCE',
  MAINTENANCE_FINISH: 'MAINTENANCE_FINISH',
  HOLIDAY: 'HOLIDAY',
  NON_WORKING: 'NON_WORKING',
  BOT_TERMINATE: 'BOT_TERMINATE',
  NO_ONLINE_AGENT: 'NO_ONLINE_AGENT'
};

export const AGENT_ROLE = {
  LEADER: 'Leader',
  MODERATOR: 'Moderator',
  REGULAR: 'Regular',
  CLIENT: 'Client',
  PHONE_AGENT: 'PhoneAgent'
};

export const ACTION = {
  NO_SUPPORT: 'NO_SUPPORT',
  SUPPORT: 'SUPPORT',
  RE_SUPPORT: 'RE_SUPPORT',
  ASSIGN: 'ASSIGN',
  TRANSFER: 'TRANSFER',
  ESCALATE: 'ESCALATE',
  SUBMIT_FORM: 'SUBMIT_FORM',
  HOLD: 'HOLD',
  UN_HOLD: 'UN_HOLD',
  FINISH: 'FINISH',
  FOLLOW: 'FOLLOW',
  MANAGE_LABLE: 'MANAGE_LABLE',
  FORWARD_GROUP: 'FORWARD_GROUP'
};

export const SEARCH_FILTER = {
  FIRST_LOAD_TICKET_QTY: 50,
  LOAD_MORE_TICKET_QTY: 50
};

export const AGENT_STATUS = {
  // Grey: Offline
  OFFLINE: 'OFFLINE',
  // Green: Online
  ONLINE: 'ONLINE',
  // Red: Busy
  BUSY: 'BUSY',
  // Yellow: Idle
  AWAY: 'AWAY',
  // Orange: Lunch Time
  LUNCH: 'LUNCH',
  // Blue: Break Time
  BREAK: 'BREAK'
};

export const DEFAULT_AGENT_STATUS_LABEL = {
  // Grey: Offline
  OFFLINE: i18n.t('src.modules.profile.components.setting.offline'),
  // Green: Online
  ONLINE: i18n.t('src.modules.profile.components.setting.online'),
  // Red: Busy
  BUSY: i18n.t('src.modules.profile.components.setting.busy'),
  // Yellow: Idle
  AWAY: i18n.t('src.modules.profile.components.setting.away'),
  // Orange: Lunch Time
  LUNCH: i18n.t('src.modules.profile.components.setting.lunch'),
  // Blue: Break Time
  BREAK: i18n.t('src.modules.profile.components.setting.break')
};

export const AGENT_STATUS_COLOR = {
  // Grey: Offline
  OFFLINE: 'grey',
  // Green: Online
  ONLINE: 'green',
  // Red: Busy
  BUSY: 'red',
  // Yellow: Idle
  AWAY: 'yellow',
  // Orange: Lunch Time
  LUNCH: 'orange',
  // Blue: Break Time
  BREAK: 'blue'
};

export const AGENT_STATUS_PLACEHOLDER = {
  // Grey: Offline
  OFFLINE: i18n.t('src.modules.profile.components.setting.offline_label'),
  // Green: Online
  ONLINE: i18n.t('src.modules.profile.components.setting.online_label'),
  // Red: Busy
  BUSY: i18n.t('src.modules.profile.components.setting.busy_label'),
  // Yellow: Idle
  AWAY: i18n.t('src.modules.profile.components.setting.away_label'),
  // Orange: Lunch Time
  LUNCH: i18n.t('src.modules.profile.components.setting.lunch_label'),
  // Blue: Break Time
  BREAK: i18n.t('src.modules.profile.components.setting.break_label')
};

export const NOTIFICATION_SETTINGS_DEFAULT = [
  {
    // When receive a new message
    KEY: 'NEW_MESSAGE',
    TITLE: 'When receive a new message in supporting',
    NOTIFY_ENABLE: false,
    SOUND_ENABLE: false,
    SOUND_FILE: 'Sound_1'
  },
  {
    // When a user sends a file
    KEY: 'SEND_FILE',
    TITLE: 'When a user sends a file to bot',
    NOTIFY_ENABLE: false,
    SOUND_ENABLE: false,
    SOUND_FILE: 'Sound_1'
  },
  {
    // When receive the warning words
    KEY: 'WARNING_WORD',
    TITLE: 'When receiving the warning words',
    NOTIFY_ENABLE: false,
    SOUND_ENABLE: false,
    SOUND_FILE: 'Sound_1'
  },
  {
    // When a user is in waiting list
    KEY: 'WAITING_LIST',
    TITLE: 'When a user is in waiting list',
    NOTIFY_ENABLE: false,
    SOUND_ENABLE: false,
    SOUND_FILE: 'Sound_1'
  },
  {
    // When a user is in waiting list
    KEY: 'USER_MENTION',
    TITLE: 'When a user mention or reply to instagram story',
    NOTIFY_ENABLE: false,
    SOUND_ENABLE: false,
    SOUND_FILE: 'Sound_1'
  }
];

export const ACTION_SETTINGS_DEFAULT = [
  {
    KEY: 'SHIFT_ENTER',
    CONFIRM_ENABLE: false,
    TOOLTIP: 'TOOLTIP_SHIFT_ENTER'
  },
  {
    KEY: 'CONFIRMATION',
    CONFIRM_ENABLE: false,
    TOOLTIP: 'TOOLTIP_CONFIRMATION'
  },
  {
    KEY: 'PROOFREADING',
    CONFIRM_ENABLE: true,
    TOOLTIP: 'TOOLTIP_PROOFREADING'
  }
];

export const ACTION_SETTINGS_TITLE = {
  SHIFT_ENTER: i18n.t('src.core.constants.sending_with_shift_enter'),
  CONFIRMATION: i18n.t('src.core.constants.sending_with_a_confirmation'),
  PROOFREADING: i18n.t('src.core.constants.sending_with_a_proofreading')
};

export const ACTION_SETTINGS_TOOLTIP = {
  TOOLTIP_SHIFT_ENTER: i18n.t('src.core.constants.send_a_message_with_shift_enter'),
  TOOLTIP_CONFIRMATION: i18n.t(
    'src.core.constants.display_a_confirmation_dialog_when_the_agent_send_anything_to_a_user'
  ),
  TOOLTIP_PROOFREADING: i18n.t(
    'src.core.constants.allow_system_to_help_you_check_japanese_sentences_before_sending_the_message_to_user'
  )
};

export const NOTIFICATION_SETTINGS_TITLE = {
  WARNING_WORD: i18n.t('src.core.constants.when_receiving_the_warning_words'),
  WAITING_LIST: i18n.t('src.core.constants.when_a_user_is_in_waiting_list'),
  NEW_MESSAGE: i18n.t('src.core.constants.when_receive_a_new_message_in_supporting'),
  SEND_FILE: i18n.t('src.core.constants.when_a_user_sends_a_file_to_bot'),
  USER_MENTION: i18n.t('src.core.constants.when_a_user_mention_or_reply')
};

export const NOTIFICATION_SETTINGS_KEY = {
  WARNING_WORD: 'WARNING_WORD',
  WAITING_LIST: 'WAITING_LIST',
  NEW_MESSAGE: 'NEW_MESSAGE',
  SEND_FILE: 'SEND_FILE',
  USER_MENTION: 'USER_MENTION',
  VIRUS_DETECTION: 'VIRUS_DETECTION'
};

export const SOUND_LIST = [
  'Sound_1',
  'Sound_2',
  'Sound_3',
  'Sound_4',
  'Sound_5',
  'Sound_6',
  'Sound_7',
  'Sound_8',
  'Sound_9',
  'Sound_10'
];

export const SORT_BY = {
  LAST_MESSAGE: 'LAST_MESSAGE',
  REQUEST_TIME: 'REQUEST_TIME',
  SUPPORT_TIME: 'SUPPORT_TIME'
};

export const MAPPING_ROLE = {
  Leader: i18n.t('src.modules.profile.components.setting.supervisor'),
  Moderator: i18n.t('src.modules.profile.components.setting.leader'),
  Regular: i18n.t('src.modules.profile.components.setting.regular'),
  Client: i18n.t('src.modules.profile.components.setting.client'),
  PhoneAgent: i18n.t('src.modules.profile.components.setting.phone_agent')
};

export const FILE_MANAGEMENT_DEFAULT = {
  fileUploadSize: 25,
  imageUploadSize: 10,
  videoUploadSize: 25,
  audioUploadSize: 25
};

export const TRANSLATE_LANGUAGE_NAME = {
  en_US: i18n.t('src.core.constants.English'),
  zh_CN: i18n.t('src.core.constants.Chinese'),
  cs_CZ: i18n.t('src.core.constants.Czech'),
  th_PH: i18n.t('src.core.constants.Filipino'),
  fr_FR: i18n.t('src.core.constants.French'),
  id_ID: i18n.t('src.core.constants.Indonesia'),
  ja_JP: i18n.t('src.core.constants.Japanese'),
  ko_KR: i18n.t('src.core.constants.Korean'),
  ms_MY: i18n.t('src.core.constants.Malay'),
  zh_TW: i18n.t('src.core.constants.Mandarin'),
  es_ES: i18n.t('src.core.constants.Spanish'),
  th_TH: i18n.t('src.core.constants.Thai'),
  vi_VN: i18n.t('src.core.constants.Vietnamese')
};

export const DISPLAY_LANGUAGE_NAME = {
  vi_VN: 'Tiếng Việt',
  en_US: 'English',
  ja_JP: '日本語',
  id_ID: 'Bahasa indonesia',
  th_TH: 'ภาษาไทย',
  zh_CN: '中国語',
  zh_TW: '台灣語',
  ms_MY: 'Melayu',
  ko_KR: '한국어',
  tl_PH: 'Pilipino',
  cb_IQ: 'کوردیی ناوەندی',
  tz_MA: 'ⵜⴰⵎⴰⵣⵉⵖⵜ',
  sz_PL: 'ślōnskŏ gŏdka'
};

export const CNT_RECENT_PASSWORDS = 2;

export const STATE_COLOR = {
  [TICKET_STATE.REFER]: 'refer',
  [TICKET_STATE.REQUEST]: 'danger',
  [TICKET_STATE.START]: 'primary',
  [TICKET_STATE.SUPPORTING]: 'info',
  [TICKET_STATE.FINISH]: 'secondary',
  [TICKET_STATE.COMPLETE]: 'success'
};

export const TABS = {
  BOT: 0,
  WAITING_COMMON: 1,
  WAITING_ME: 2,
  ME: 3,
  MONITORING: 4
};

export const REPLACING_MESSAGE_REQUEST_STATUS = {
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const CHANNEL_MODE = {
  ACTIVE: 'active',
  MAINTAIN: 'maintain'
};

export const AUDIO_UN_PLAYABLE = ['.wma', '.mka', '.mid', '.midi', '.au', '.aiff', '.ac3'];
export const IMAGE_EXTENSIONS_SUPPORTED = ['.png', '.gif', '.jpg', '.jpeg', '.bmp'];
export const FILE_EXTENSIONS_SUPPORTED_ZALO = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword'
];

// export const IMAGE_YOUTUBE_URL = 'http://img.youtube.com/vi/'; Tuấn Webchat Support
// export const ICON_YOUTUBE_URL = 'https://s.ytimg.com/yts/img/favicon_144-vfliLAfaB.png'; Tuấn Webchat Support
export const MARITAL_STATUS = {
  single: {
    key: 'single',
    value: 'Single'
  },
  married: {
    key: 'married',
    value: 'Married'
  },
  separated: {
    key: 'separated',
    value: 'Separated'
  },
  divorced: {
    key: 'divorced',
    value: 'Divorced'
  },
  widowed: {
    key: 'widowed',
    value: 'Widowed'
  }
};

export const GENDER = {
  male: {
    key: 'male',
    value: 'Male'
  },
  female: {
    key: 'female',
    value: 'Female'
  },
  other: {
    key: 'other',
    value: 'Others'
  },
  no_say: {
    key: 'no_say',
    value: 'Prefer not to say'
  }
};

export const PERSONAL_OPTIONS = {
  vi_VN: {
    male: 'Nam',
    female: 'Nữ',
    single: 'Độc thân',
    married: 'Kết hôn',
    divorced: 'Đã ly hôn',
    separated: 'Ly thân',
    widowed: 'Góa vợ/chồng',
    no_say: 'Không muốn trả lời',
    other: 'Khác'
  },
  en_US: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed',
    no_say: 'Prefer not to say',
    other: 'Others'
  },
  ja_JP: {
    male: '男性',
    female: '女性',
    single: '未婚',
    married: '既婚',
    divorced: '離婚',
    separated: '離別',
    widowed: '死別',
    no_say: '回答しない',
    other: 'その他'
  },
  zh_TW: {
    male: '男性',
    female: '女性',
    single: '單身',
    married: '已婚',
    divorced: '離婚',
    separated: '分居',
    widowed: '寡人',
    no_say: '不作回答',
    other: '其他'
  },
  zh_CN: {
    male: '男性',
    female: '女性',
    single: '单身',
    married: '已婚',
    divorced: '离婚',
    separated: '分居',
    widowed: '寡人',
    no_say: '不作回答',
    other: '其他'
  },
  th_TH: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  id_ID: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  cs_CZ: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  es_ES: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  th_PH: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  ko_KR: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  fr_FR: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  },
  ms_MY: {
    male: 'Male',
    female: 'Female',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed'
  }
};

export const TICKET_SEARCH_TYPE = {
  CURRENT_TICKET: 0,
  CURRENT_TICKET_SEARCH: 1,
  TICKET_SEARCH: 2
};

export const PAGING_DIRECTION = {
  NEXT: 1,
  PREV: -1
};

export const STATUS_SUBMIT_CSF = {
  SUBMITTED: 'submited',
  DRAFT: 'draft'
};

export const TAG_SOURCE_TYPE = {
  agent: i18n.t('src.core.constants.tagSourceType.agent'),
  bot: i18n.t('src.core.constants.tagSourceType.bot')
};

export const SCENARIO_TEMPLATE_TYPE_NAME = {
  TEXT_MESSAGE: i18n.t('src.core.constants.scenarioTemplateTypeName.text_message'),
  IMAGE: i18n.t('src.core.constants.scenarioTemplateTypeName.image'),
  VIDEO: i18n.t('src.core.constants.scenarioTemplateTypeName.video'),
  AUDIO: i18n.t('src.core.constants.scenarioTemplateTypeName.audio'),
  FILE: i18n.t('src.core.constants.scenarioTemplateTypeName.file'),
  QUICK_REPLY: i18n.t('src.core.constants.scenarioTemplateTypeName.quick_reply'),
  BUTTON_LIST: i18n.t('src.core.constants.scenarioTemplateTypeName.button_list'),
  CAROUSEL: i18n.t('src.core.constants.scenarioTemplateTypeName.carousel'),
  LIST: i18n.t('src.core.constants.scenarioTemplateTypeName.list'),
  LOCATION: i18n.t('src.core.constants.scenarioTemplateTypeName.location'),
  STICKER: i18n.t('src.core.constants.scenarioTemplateTypeName.sticker'),
  IMAGE_MAP: i18n.t('src.core.constants.scenarioTemplateTypeName.image_map')
};

export const FORMAT_TIME_IMMEDIATE_REPORT = {
  EXPORT: {
    START_DATE: 'YYYY-MM-DD HH:mm:00',
    END_DATE: 'YYYY-MM-DD HH:mm:59'
  },
  DISPLAY: 'YYYY/MM/DD HH:mm'
};

export const LOADING_ACTION = {
  SUPPORT_USER: 'SUPPORT_USER'
};

export const TELEWORK = {
  NAME: 'telework-agent-evidence',
  UNIT: {
    GB: 'GB',
    MB: 'MB'
  },
  BYTES_GB: 1024 * 1024 * 1024, // from bytes to gb,
  BYTES_MB: 1024 * 1024, // from bytes to mb
  BYTES_KB: 1024, // from bytes to kb
  GB_TO_KB: 1024 * 1024, // from gb to kb
  MB_TO_KB: 1024, // from mb to kb
  MAXIMUM_STORAGE: 300, // 300K kb
  REVOLUTION_VIDEO: {
    width: 480,
    height: 270,
    default_width: 1280,
    default_height: 720
  }
};

export const TICKET_TYPE_CODE = {
  BOT: '0', // Tab Bot
  COMMON: '1', // Tab Waiting Common
  TOME: '2', // Tab Waiting Tome
  AGENT: '3', // Tab Agent
  MONITORING: '5', // Tab Monitoring
  SEARCH: '9' // Ticket Search
};

export const FOLLOW_UP_TICKET = {
  ALL: 'all',
  FOLLOW_UP: 'follow-up',
  NON_FOLLOW_UP: 'non-follow-up'
};

export const PLATFORM = {
  WEBCHAT: 'webchat',
  FACEBOOK: 'facebook',
  ZALO: 'zalo',
  LINE: 'line',
  INSTAGRAM: 'instagram',
  LIFFCHAT: 'liffchat'
};

export const AGENT_ROLE_CORE = {
  NONE: 10,
  BOT: 20,
  REGULAR: 30,
  MODERATOR: 40,
  LEADER: 50
};

export const ITEM_QUESTION_TYPE = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  RANGE: 'range'
};

export const TYPE_DIALOG_BOX = {
  MESSAGE_LIST: 'message-list',
  AGENT_INFO: 'agent-info',
  USER_INFO: 'user-info',
  SURVEY_LIST: 'survey-list',
  CUSTOMER_SUPPORT: 'customer-support',
  FILE_LIST: 'file-list'
};

export const MAPPING_LABEL_COLOR = {
  danger: '#F5365C',
  info: '#909399',
  primary: '#409EFF',
  success: '#67C23A',
  warning: '#E6A23C',
  auto: '#9933ff'
};

export const REPORT_SCHEDULE_TYPE = {
  IMMEDIATE: 'immediate',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

export const LOG_REPORT_TYPE = {
  MESSAGE_LOG: 'MESSAGELOG',
  COMMENT_LOG: 'COMMENTLOG',
  CONVERSATION_LOG: 'CONVERSATIONLOG',
  SURVEY_LOG: 'SURVEYLOG',
  ACCESS_LOG: 'ACCESSLOG',
  AGENT_STATUS_LOG: 'AGENTSTATUSLOG',
  CONVERSATION_LIST: 'CONVERSATIONLIST'
};

export const RESULT_REPORT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error'
};
