<template>
  <div
    class="chats"
    :style="loading ? { minHeight: 'calc(100% - 0px + 21px)' } : { minHeight: 'calc(100% - 0px)' }"
  >
    <div v-if="loading" class="text-center w-100">
      <i class="fas fa-spinner fa-spin fa-lg" />
    </div>
    <message-item
      v-for="msg in msgData"
      :key="msg.id"
      v-bind="msg"
      :conversation="selectedConversation"
      :read-date="readDate"
      :platform="platform"
      :in-chat-box="inChatBox"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { END_USER_PLATFORM, SERVING_STATE, TICKET_STATE } from 'core/constants';
import MessageItem from './message-item';
import moment from 'moment';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    MessageItem
  },

  props: {
    messages: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    platform: {
      type: String,
      default: 'facebook'
    },
    inChatBox: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      readDate: '',
      isScrollBottomMessage: false
    };
  },
  asyncComputed: {
    async msgData() {
      if (!this.messages.length) return [];
      let msg = this.messages[0];
      let isAgent = END_USER_PLATFORM.indexOf(msg.platform) === -1;
      let groupMsg = msg.isSystem
        ? {
            ...msg,
            messages: [msg]
          }
        : {
            id: msg.sk,
            sender: msg.sender,
            messages: [msg],
            isAgent: isAgent
          };
      let messages = [groupMsg];
      let i = 1,
        sender = msg.sender;
      for (i = 1; i < this.messages.length; i++) {
        msg = this.messages[i];
        if (msg.deleted) continue;
        if (msg.sender === sender) {
          groupMsg.messages.push(msg);
        } else {
          isAgent = END_USER_PLATFORM.indexOf(msg.platform) === -1;
          sender = msg.sender;
          groupMsg = {
            id: msg.sk,
            sender: msg.sender,
            messages: [msg],
            isAgent,
            isSystem: msg.isSystem || false,
            text: msg.text || ''
          };
          messages.push(groupMsg);
        }
        if (!isAgent) {
          const { sender: userId, channelId } = msg || {};
          let people = this.people[`${userId}_${channelId}`] || this.people[sender] || null;
          if (!people) {
            const { projectId, platform } = this.selectedConversation;
            if (userId && channelId && projectId && platform) {
              await this.addUserToPeople({ userId, channelId, projectId, platform });
            }
          }
        }
      }

      if (this.isPhoneAgent) return messages;

      // Get typing text if exist
      let userId = '';
      let typingText = '';
      const { ticket: { state = '' } = {} } = this.selectedConversation;
      if (this.chatViewingGroup === SERVING_STATE.AGENT && TICKET_STATE.SUPPORTING === state) {
        userId = this.selectedConversation && this.selectedConversation.userId;
        typingText = (userId && this.typing[userId]) || '';
      }

      // create object of typing message
      let typingMessage = null;
      if (typingText) {
        const { channelId = '', ticketId = '', id = '' } = this.selectedConversation;
        const message = {
          content: typingText,
          createdAt: moment().toISOString(),
          text: typingText,
          conversationId: id,
          state: 0,
          sender: userId,
          channelId,
          platform: this.platform,
          ticketId,
          type: 'typing_text'
        };
        typingMessage = {
          sender: userId,
          isAgent: false,
          messages: [message]
        };
      }

      // Append typing message to messages
      if (typingMessage) {
        messages = [...messages, typingMessage];
      }

      return messages;
    }
  },
  computed: {
    ...mapState('session', ['user', 'servingUsers', 'people']),
    ...mapState('chat', ['typing', 'chatViewingGroup']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', ['isPhoneAgent']),
    ...mapGetters('PhoneAgentChatBox', ['selectedUserInfo'])
  },
  watch: {
    selectedConversation(newVal) {
      let { read } = newVal || {};
      if (read) this.readDate = read;
    },

    chatViewingGroup(nVal, oVal) {
      this.isScrollBottomMessage = nVal !== oVal;
    },

    messages(newMessages, oldMessage) {
      if (Array.isArray(newMessages) && newMessages.length > 0) {
        if (Array.isArray(oldMessage) && oldMessage.length < newMessages.length) {
          this.isScrollBottomMessage =
            newMessages[newMessages.length - 1]?.id !== oldMessage[oldMessage.length - 1]?.id;
        }
        const msg = newMessages[newMessages.length - 1];
        if (END_USER_PLATFORM.includes(msg.platform)) {
          this.readDate = msg.createdAt;
        }
      }
      if (this.readDate !== '') return;

      if (newMessages.length < 1) return new Date().toISOString();
      for (let i = newMessages.length - 1; i >= 0; i--) {
        if (END_USER_PLATFORM.indexOf(newMessages[i].platform)) {
          this.readDate = newMessages[i].createdAt;
          return;
        }
      }
    }
  },
  updated() {
    if (this.isScrollBottomMessage) {
      setTimeout(() => {
        EventBus.$emit('scrollBottomMessage');
        this.isScrollBottomMessage = false;
      }, 0);
    }
  },
  methods: {
    ...mapActions('session', ['addUserToPeople'])
  }
};
</script>

<style lang="scss" scoped></style>
