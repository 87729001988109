<template>
  <ValidationObserver ref="agentInfo">
    <form role="form">
      <!-- Agent  name -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.first_name')"
            :name="'firstName'"
            v-model="formData.firstName"
            :maxlength="30"
          />
        </div>
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.last_name')"
            :name="'lastName'"
            v-model="formData.lastName"
            :maxlength="30"
          />
        </div>
      </div>
      <!-- Agent  name navite language -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :label="
              $t('src.modules.profile.components.user-information.first_name_(native_language)')
            "
            :name="'firstNameNativeLanguage'"
            v-model="formData.firstNameNativeLanguage"
            :maxlength="30"
          />
        </div>
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :label="
              $t('src.modules.profile.components.user-information.last_name_(native_language)')
            "
            :name="'lastNameNativeLanguage'"
            v-model="formData.lastNameNativeLanguage"
            :maxlength="30"
          />
        </div>
      </div>
      <!-- Gender -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <label>{{
            $t('src.modules.chat.components.CustomerSupportPanel.contactInformation.gender')
          }}</label>
          <div
            class="input-group input-group-alternative custom-radio"
            style="border: none; box-shadow: none"
          >
            <el-radio v-model="formData.gender" label="male">{{
              $t('src.modules.profile.components.user-information.male')
            }}</el-radio>
            <el-radio v-model="formData.gender" label="female">{{
              $t('src.modules.profile.components.user-information.female')
            }}</el-radio>
            <el-radio v-model="formData.gender" label="no_say">{{
              $t('src.modules.profile.components.user-information.no_say')
            }}</el-radio>
            <el-radio
              v-model="formData.gender"
              label="other"
              style="width: 100%; padding-bottom: 10px"
              >{{ $t('src.modules.profile.components.user-information.others') }}
              <template v-if="showInput">
                <el-input
                  v-model="otherValue"
                  class="w-50 m-2 gender-other"
                  size="small"
                  maxlength="100"
                  show-word-limit
                  @blur="handleBlur"
                ></el-input>
                <div v-if="otherValueError" class="nat-invalid-data" style="margin-left: 75px">
                  {{
                    $t('src.modules.profile.components.user-information.please_enter_your_gender')
                  }}
                </div>
              </template>
            </el-radio>
          </div>
        </div>
      </div>
      <!-- Open group - Region -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.operation_group')"
            :name="'operationGroup'"
            v-model="orcc.operation"
            :show-word-limit="false"
          />
        </div>
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.region')"
            :name="'region'"
            v-model="orcc.operation"
            :show-word-limit="false"
          />
        </div>
      </div>
      <!-- Country - Company -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.country')"
            :name="'country'"
            v-model="orcc.country"
            :show-word-limit="false"
          />
        </div>
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-input
            :disabled="true"
            :label="$t('src.modules.profile.components.user-information.company')"
            :name="'company'"
            v-model="orcc.company"
            :show-word-limit="false"
          />
        </div>
      </div>
      <!-- Primary Languages - Secondary Languages -->
      <div class="row">
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-select-multi
            :disabled="true"
            :value="c_primaryLanguage"
            :options="c_primaryLanguage"
            :name="'primaryLanguage'"
            :label="$t('src.modules.profile.components.user-information.primary_languages')"
          />
        </div>
        <div class="col-lg-6 col-sm-6 mb-1">
          <base-select-multi
            :disabled="true"
            :value="c_secondaryLanguage"
            :options="c_secondaryLanguage"
            :name="'secondaryLanguage'"
            :label="$t('src.modules.profile.components.user-information.secondary_languages')"
          />
        </div>
      </div>
      <!-- Contact number -->
      <div class="row">
        <div class="col-lg-12 col-sm-12 mb-1">
          <base-input
            :label="$t('src.modules.profile.components.user-information.contact_number')"
            :name="'contactNumber'"
            :minlength="3"
            :maxlength="25"
            :rules="'phoneNumber'"
            v-model="formData.contactNumber"
          />
        </div>
      </div>
      <!-- Agent role -->
      <div class="row">
        <div class="col-lg-12 col-sm-12 mb-1">
          <base-input
            :label="$t('src.modules.profile.components.user-information.agent_role')"
            :name="'agentRole'"
            :disabled="true"
            v-model="mappingRole[formData.role]"
          />
        </div>
      </div>
      <user-projects ref="userProject" :assigned-projects="assignedProjects" />
      <div class="text-center">
        <button type="button" class="btn btn-primary my-4" @click="handleFormSubmit">
          {{ $t('common.text.save') }}
          <i v-if="submitFormLoading" class="fas fa-spinner fa-spin fa-lg" />
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import userProjects from './user-project.vue';
import { MAPPING_ROLE } from 'core/constants';
import { DISPLAY_LANGUAGE_NAME } from 'core/constants';

export default {
  components: {
    userProjects
  },

  props: {
    pictureUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      showInput: false,
      otherValue: '',
      otherValueError: false,
      locales: [],
      submitFormLoading: false,
      formData: {},
      mappingRole: MAPPING_ROLE || {},
      displayLanguageName: DISPLAY_LANGUAGE_NAME || {}
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapState('global', ['ready']),
    ...mapGetters('session', ['socialLinks']),

    assignedProjects() {
      return this.user.assignedProjects || [];
    },
    orcc() {
      return {
        operation:
          this.formData.operator && this.formData.operator.name
            ? this.formData.operator.name
            : 'operation group',
        region:
          this.formData.region && this.formData.region.name ? this.formData.region.name : 'region',
        country:
          this.formData.country && this.formData.country.name
            ? this.formData.country.name
            : 'country',
        company:
          this.formData.company && this.formData.company.name
            ? this.formData.company.name
            : 'company'
      };
    },
    c_secondaryLanguage() {
      if (this.formData.secondaryLanguage) {
        return this.formData.secondaryLanguage.map(item => {
          return {
            key: item,
            name: this.displayLanguageName[item] || this.splitLocale(item).conutryName
          };
        });
      }
      return [];
    },
    c_primaryLanguage() {
      if (this.formData.primaryLanguage) {
        return this.formData.primaryLanguage.map(item => {
          return {
            key: item,
            name: this.displayLanguageName[item] || this.splitLocale(item).conutryName
          };
        });
      }
      return [];
    }
  },

  watch: {
    pictureUrl(val) {
      this.formData.pictureUrl = val;
    },
    formData: {
      handler(nVal) {
        this.showInput = false;
        this.otherValueError = false;
        if (nVal.gender && !['male', 'female', 'no_say'].includes(nVal.gender)) {
          this.showInput = true;
        }
      },
      deep: true
    }
  },

  async created() {
    this.setLocalesToStorage();
    let _locales = localStorage.getItem('locales');
    let locales = JSON.parse(_locales);
    this.locales = locales;
    this.setGlobalReady(false);
    const user = await this.getSessionUser();
    if (!user) {
      this.setGlobalReady(true);
      return;
    }
    this.formData = { ...user };
    this.setOtherValue(user);
    this.setGlobalReady(true);
  },

  methods: {
    ...mapActions('session', ['getSessionUser']),
    ...mapActions('chat', ['setLocalesToStorage']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('profile', ['submitForm']),

    setOtherValue(user) {
      const { gender } = user;
      if (gender && gender.includes('other#')) {
        this.formData.gender = 'other';
        this.otherValue = gender.slice(6, gender.length);
      }
    },

    async handleFormSubmit() {
      const { gender } = this.formData;
      this.$refs.agentInfo &&
        this.$refs.agentInfo.validate().then(async result => {
          if (!result || (gender === 'other' && !this.otherValue)) {
            this.errorMessage();
            gender === 'other' && !this.otherValue ? (this.otherValueError = true) : null;
          } else {
            this.submitFormLoading = true;
            const user = await this.getSessionUser();
            const formUserUpdate = {
              id: this.formData.id,
              firstName: this.formData.firstName,
              lastName: this.formData.lastName,
              firstNameNativeLanguage: this.formData.firstNameNativeLanguage || null,
              lastNameNativeLanguage: this.formData.lastNameNativeLanguage || null,
              gender: this.formData.gender || 'male',
              pictureUrl: this.formData.pictureUrl,
              contactNumber: this.formData.contactNumber || null
            };

            if (formUserUpdate.gender === 'other') {
              formUserUpdate.gender = `other#${this.otherValue}`;
            } else {
              this.otherValue = '';
            }

            this.submitForm(formUserUpdate)
              .then(res => {
                this.submitFormLoading = false;
                // revert old user data in case of error
                if (!res) {
                  this.formData = { ...user };
                  this.setOtherValue(user);
                } else {
                  this.getSessionUser(true);
                  this.successMessage();
                }
              })
              .catch(() => (this.submitFormLoading = false));
          }
        });
    },

    splitLocale(_locale) {
      _locale = _locale.replace('-', '_');
      let split = _locale.split('_');
      let data = {
        langCode: split[0],
        conutryCode: split[1] || '',
        localeCode: `${split[0]}_${split[1]}`,
        conutryName:
          split[1] && split[1] != undefined
            ? (this.locales.countrys[split[1]] && this.locales.countrys[split[1]].c) || _locale
            : _locale,
        langName:
          split[0] && split[0] != undefined
            ? (this.locales.isoLangs[split[0]] && this.locales.isoLangs[split[0]].name) || _locale
            : _locale
      };
      return data;
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    successMessage() {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: this.$t('src.modules.profile.components.user-information.update_successful')
      });
    },

    handleBlur(event) {
      const value = event.target.value.trim();
      this.otherValue = value;
      if (!value) this.otherValueError = true;
      else this.otherValueError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.gender-other {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  /deep/.el-input__suffix {
    right: 0px !important;
  }

  /deep/input {
    height: 14px !important;
    padding: 0 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.custom-radio {
  /deep/.el-radio__label {
    font-size: 0.875rem !important;
    font-weight: 100 !important;
    color: #606266 !important;
  }

  /deep/.is-checked .el-radio__inner {
    border-color: #606266 !important;
    background: #606266 !important;
  }
}
</style>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';
</style>
