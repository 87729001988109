<template>
  <div class="adfs-select-component">
    <div v-if="loading" class="adfs-select-loading">
      <span>
        {{ $t('src.modules.session.components.adfsSelect.loading') }}
        <i class="fas fa-spinner fa-spin fa-lg" />
      </span>
    </div>
    <div v-else>
      <p class="p-t-25">
        {{ $t('src.modules.session.components.adfsSelect.domain') }}
      </p>
      <b-input-group class="mt-3">
        <b-form-input v-model="domain" v-on:keyup.enter="goLogin"></b-form-input>
        <b-input-group-append>
          <b-button
            :v-b-tooltip="$t('src.modules.session.components.adfsSelect.go_to_sign_in')"
            :disabled="domain === ''"
            variant="primary"
            class="btn-icon"
            @click="goLogin"
          >
            <i class="fas fa-arrow-circle-right"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="float-left adfs-select-redirect">
        {{ $t('src.modules.session.components.adfsSelect.go_back_to_sign_in') }}
        <a href="/login" @click="redirectSignInPage">{{
          $t('src.modules.session.components.adfsSelect.sign_in')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      domain: ''
    };
  },

  computed: {
    ...mapGetters('session', ['isAuthenticated']),
    ...mapState('session', ['ldapList']),

    items() {
      if (!this.ldapList) return [];
      return this.ldapList;
    }
  },

  mounted() {
    if (this.isAuthenticated) return this.$router.push('/');
    this.loading = true;
    this.getLDAPList().then(() => {
      this.loading = false;
    });
  },

  methods: {
    ...mapActions('session', ['loginOAuth', 'getLDAPList']),
    ...mapActions('global', ['setGlobalReady']),

    handleSignInWithOAuth(clientId, code) {
      this.loginOAuth({ clientId, code })
        .then(() => {
          this.setGlobalReady(true);
        })
        .catch(() => this.setGlobalReady(true));
    },

    handleClose() {
      window.parent.location.reload();
    },

    goLogin() {
      if (!this.domain || this.domain === '') return;

      if (!this.items || this.items.length === 0)
        return this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.session.components.adfsSelect.domain_not_found')
        });

      const domain = this.domain;
      const item = this.items.find(itm => itm.domain === domain);
      if (!item)
        return this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.session.components.adfsSelect.domain_not_found')
        });
      this.handleAuth(item);
    },

    handleAuth(item) {
      this.setGlobalReady(false);
      window.localStorage.setItem('client_id', item.clientId);
      const redirectLink = window.location.origin + '/adfs/cb';
      const url =
        item.adfsEndpoint +
        '/authorize?client_id=' +
        item.clientId +
        '&response_type=code&scope=openid+profile+email&redirect_uri=' +
        redirectLink;
      window.location.href = url;
    },

    redirectSignInPage(e) {
      e.preventDefault();
      this.$router.push({ path: '/login' });
    },

    openFullScreen(isLoading) {
      const loading = this.$loading({
        lock: true,
        background: 'rgba(255,255,255,0.9)'
      });

      if (!isLoading) loading.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.p-t-25 {
  font-size: 1.2rem;
  font-weight: 600;
}

.adfs-select-loading {
  padding-top: 20px;
}

.adfs-select-ul {
  list-style-type: none;
  padding-left: 0;
  max-height: 500px;
  overflow: auto;

  li {
    .adfs-select-div {
      .row {
        flex-wrap: none;
      }
    }

    .adfs-select-div:hover {
      cursor: pointer;
    }
  }

  li:hover {
    background-color: #ebeef5;
  }
}

.el-divider {
  margin: 15px 0 !important;
}

.adfs-select-redirect {
  font-size: 0.8rem;
  padding-bottom: 5px;
  & > a {
    color: #007bff !important;
  }
  & > a:hover {
    text-decoration: underline;
  }
}
</style>
