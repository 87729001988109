import {
  SET_SELECTED_CHANNEL,
  SET_COMMENT_LIST,
  SET_COMMENT,
  SET_READY,
  SET_SELECTED_COMMENT,
  REPLY_COMMENT,
  DELETE_REPLY,
  UPDATE_REPLY,
  MARK_READ_COMMENT,
  MARK_LIKE_COMMENT,
  MARK_UNLIKE_COMMENT,
  MARK_LIKE_REPLY,
  MARK_UNLIKE_REPLY,
  SET_COMMENT_NEXT_PAGING,
  SET_FIRST_PAGING,
  REALTIME_EDIT_POST,
  REALTIME_DELETE_POST,
  REALTIME_NEW_COMMENT,
  REALTIME_EDIT_COMMENT,
  REALTIME_DELETE_COMMENT,
  REALTIME_NEW_REPLY,
  REALTIME_EDIT_REPLY,
  REALTIME_DELETE_REPLY,
  SET_LOAD_TIME,
  SET_FILTER_MODE,
  SET_FILTER_CONDITION,
  SET_REPLIES_FOR_COMMENT,
  LOAD_COMMENT_TOKEN,
  LOAD_COMMENT_FILTER_TOKEN,
  UPDATE_RELOAD_COMMENT_LIST
} from './types';

import * as getters from './getters';
import * as actions from './actions';

const state = {
  ready: false,
  selectedChannel: {},
  selectedComment: {},
  comments: [],
  commentPaging: {},
  loadTime: null,
  inFilterMode: false,
  filterCondition: {},
  loadCommentToken: '',
  loadCommentFilterToken: ''
};

const updateCommentInList = (state, comment) => {
  const { comments } = state;
  const len = comments.length;
  for (let i = 0; i < len; i++) {
    if (comments[i].comment_id === comment.comment_id) {
      comments[i] = { ...comment };
      break;
    }
  }
  state.comments = [...comments];
};

const mutations = {
  [SET_COMMENT_LIST](state, { comments, loadMore }) {
    comments =
      comments && comments.length ? comments.filter(({ post_detail }) => post_detail) : comments;
    comments = comments.map(c => ({
      ...c,
      attachment: typeof c.attachment !== 'string' ? JSON.stringify(c.attachment) : c.attachment
    }));

    if (loadMore) {
      if (!comments || comments.length === 0) return;
      state.comments = [...state.comments, ...comments];
    } else {
      state.comments.splice(0);
      state.comments = [...comments];
    }
  },

  [UPDATE_RELOAD_COMMENT_LIST](state, { data }) {
    const { selectedComment } = state;
    const { comment, post } = data || {};
    const comments = [...state.comments];
    comments.map(val => {
      if (val.comment_id === comment.comment_id) {
        val.attachment = comment.attachment;
        val.post_detail = post;
        selectedComment.attachment = comment.attachment;
        selectedComment.post_detail = post;
      }
      if (val.post_id === post.post_id) {
        val.post_detail = post;
      }
    });
    state.comments = [...comments];
  },

  [SET_REPLIES_FOR_COMMENT](state, { action, replies, comment_id }) {
    let { selectedComment, comments } = state;
    comments = comments.map(comment => {
      if (comment.comment_id == comment_id) {
        comment['reply_list'] = replies;
        comment['replies_loaded'] = true;
        ['edited', 'add'].includes(action)
          ? (comment['system_updated_time'] = new Date().toISOString())
          : null;

        if (comment_id !== selectedComment.comment_id) {
          comment['new_reply'] =
            !replies || replies.length <= 0 ? 0 : replies.filter(i => !i.is_read).length;
        }
      }
      return comment;
    });
    if (['edited', 'add'].includes(action)) {
      comments = comments.sort(
        (a, b) => -a.system_updated_time.localeCompare(b.system_updated_time)
      );
    }
    state.comments = [...comments];

    if (comment_id === selectedComment.comment_id) {
      selectedComment['reply_list'] = replies;
      selectedComment['replies_loaded'] = true;
      ['edited', 'add'].includes(action)
        ? (selectedComment['system_updated_time'] = new Date().toISOString())
        : null;
      state.selectedComment = { ...selectedComment };
    }
  },

  [SET_FILTER_MODE](state, boolean) {
    state.inFilterMode = boolean;
  },

  [SET_FILTER_CONDITION](state, condition) {
    state.filterCondition = { ...condition };
  },

  [SET_SELECTED_CHANNEL](state, channel) {
    state.selectedChannel = { ...channel };
  },

  [SET_COMMENT](state, data) {
    state.comment = { ...data };
  },

  [SET_READY](state, flag) {
    state.ready = flag;
  },

  [SET_SELECTED_COMMENT](state, comment) {
    state.selectedComment = { ...comment };
  },

  [REPLY_COMMENT](state, reply) {
    const { selectedComment } = state;
    const { reply_list = [] } = selectedComment;
    selectedComment.reply_list = reply_list;
    selectedComment.reply_list.push(reply);
    state.selectedComment = { ...selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [UPDATE_REPLY](state, reply) {
    const { temp_comment_id, comment_id } = reply;
    const { selectedComment } = state;
    const { reply_list = [] } = selectedComment;
    const repLen = reply_list.length;
    for (let i = 0; i < repLen; i++) {
      if (reply_list[i].comment_id === temp_comment_id || reply_list[i].comment_id === comment_id) {
        reply_list[i] = { ...reply };
        break;
      }
    }
    state.selectedComment = { ...selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [DELETE_REPLY](state, reply) {
    const { selectedComment } = state;
    selectedComment.reply_list = [
      ...selectedComment.reply_list.filter(r => r.comment_id !== reply.comment_id)
    ];

    state.selectedComment = { ...state.selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [MARK_READ_COMMENT](state) {
    const { selectedComment } = state;
    selectedComment.is_read = 1;
    selectedComment.new_reply = 0;
    state.selectedComment = { ...state.selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [MARK_LIKE_COMMENT](state) {
    const { selectedComment } = state;
    if (selectedComment.likes_count === undefined) selectedComment.likes_count = 0;
    selectedComment.likes_count = Number(selectedComment.likes_count) + 1;
    selectedComment.is_like = true;
    state.selectedComment = { ...state.selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [MARK_UNLIKE_COMMENT](state) {
    const { selectedComment } = state;
    if (selectedComment.likes_count === undefined) selectedComment.likes_count = 0;
    selectedComment.likes_count =
      Number(selectedComment.likes_count) - 1 < 0 ? 0 : Number(selectedComment.likes_count) - 1;
    selectedComment.is_like = false;
    state.selectedComment = { ...state.selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [MARK_LIKE_REPLY](state, comment_id) {
    const { selectedComment } = state;
    const { reply_list = [] } = selectedComment;
    const repLen = reply_list.length;
    for (let i = 0; i < repLen; i++) {
      if (reply_list[i].comment_id === comment_id) {
        if (reply_list[i].likes_count === undefined) reply_list[i].likes_count = 0;
        reply_list[i].likes_count = Number(reply_list[i].likes_count) + 1;
        reply_list[i].is_like = true;
        break;
      }
    }
    state.selectedComment = { ...selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [MARK_UNLIKE_REPLY](state, comment_id) {
    const { selectedComment } = state;
    const { reply_list = [] } = selectedComment;
    const repLen = reply_list.length;
    for (let i = 0; i < repLen; i++) {
      if (reply_list[i].comment_id === comment_id) {
        if (reply_list[i].likes_count === undefined) reply_list[i].likes_count = 0;
        reply_list[i].likes_count =
          Number(reply_list[i].likes_count) - 1 < 0 ? 0 : Number(reply_list[i].likes_count) - 1;
        reply_list[i].is_like = false;
        break;
      }
    }
    state.selectedComment = { ...selectedComment };
    updateCommentInList(state, state.selectedComment);
  },

  [SET_FIRST_PAGING](state, channelId) {
    if (!channelId) return;
    const { commentPaging } = state;
    commentPaging[channelId] = 1;
    state.commentPaging = { ...commentPaging };
  },

  [SET_COMMENT_NEXT_PAGING](state, channelId) {
    if (!channelId) return;
    const { commentPaging } = state;
    if (commentPaging[channelId]) {
      commentPaging[channelId] = commentPaging[channelId] + 1;
    } else {
      commentPaging[channelId] = 1;
    }
    state.commentPaging = { ...commentPaging };
  },

  [REALTIME_EDIT_POST](state, { post, post_id }) {
    let { comments, selectedComment } = state;

    if (selectedComment.post_detail && selectedComment.post_detail.post_id === post_id) {
      selectedComment.post_detail = { ...selectedComment.post_detail, ...post };
      selectedComment = { ...selectedComment };
    }

    comments = comments.map(comment => {
      if (comment.post_detail && comment.post_detail.post_id === post_id) {
        comment.post_detail = { ...comment.post_detail, ...post };
      }
      return comment;
    });
    state.comments = [...comments];
  },

  [REALTIME_DELETE_POST](state, record) {
    let { comments, selectedComment } = state;
    let { post_id } = record;

    if (selectedComment.post_detail && selectedComment.post_detail.post_id === post_id) {
      selectedComment = {};
      state.selectedComment = { ...selectedComment };
    }

    comments = comments.filter(({ post_detail }) => post_detail && post_id != post_detail.post_id);
    state.comments = [...comments];
  },

  [REALTIME_NEW_COMMENT](state, record) {
    let { comments, selectedComment } = state;

    if (selectedComment.comment_id == record.comment_id) return;
    let findingComments = comments.filter(({ comment_id }) => comment_id == record.comment_id);
    if (findingComments.length) {
      // comment exist in the list
      comments = comments.filter(({ comment_id }) => comment_id != record.comment_id);
      state.comments = [...findingComments, ...comments];
    } else {
      state.comments = [record, ...comments];
    }
  },

  [REALTIME_EDIT_COMMENT](state, record) {
    let { selectedComment, comments } = state;
    if (!comments.filter(c => c.comment_id === record.comment_id).length) {
      comments = [record, ...comments];
    } else {
      comments = comments.map(c => {
        if (c.comment_id == record.comment_id) c = { ...c, ...record };
        return c;
      });
    }
    comments = comments.sort((a, b) => -a.system_updated_time.localeCompare(b.system_updated_time));
    state.comments = [...comments];

    if (record.comment_id == selectedComment.comment_id) {
      selectedComment = { ...selectedComment, ...record };
      state.selectedComment = { ...selectedComment };
    }
  },

  [REALTIME_DELETE_COMMENT](state, record) {
    const comment_id = record.comment_id;
    let { selectedComment, comments } = state;
    comments = comments.filter(x => x.comment_id != comment_id);
    state.comments = [...comments];
    if (selectedComment.comment_id == comment_id) state.selectedComment = {};
  },

  [REALTIME_NEW_REPLY](state, record) {
    let { selectedComment, comments } = state;
    // check whether reply is in current list of comments
    if (!comments.filter(x => x.comment_id == record.parent_comment_id).length) return;
    // Add new reply to comment list
    comments = comments.map(x => {
      if (x.comment_id == record.parent_comment_id) {
        // If new reply is exist in the current reply list, do nothing
        if (x.reply_list.filter(r => r.comment_id == record.comment_id).length) return x;
        // else : add the new reply to reply list
        x.reply_list = [...x.reply_list, record];
        // If checking comment is not selected comment, increase new reply number
        if (x.comment_id != selectedComment.comment_id) {
          let new_reply = x.new_reply ? parseInt(x.new_reply) : 0;
          x.new_reply = new_reply + 1;
        }
      }
      return x;
    });
    state.comments = [...comments];

    // Add new reply to current comment
    if (record.parent_comment_id != selectedComment.comment_id) return;
    if (selectedComment.reply_list.filter(x => x.comment_id == record.comment_id).length) return;
    selectedComment.reply_list = [...selectedComment.reply_list, record];
    state.selectedComment = { ...selectedComment };
  },

  [REALTIME_EDIT_REPLY](state, record) {
    let { selectedComment, comments } = state;
    // check whether reply is in current list of comments
    if (!comments.filter(x => x.comment_id == record.parent_comment_id).length) return;
    // Add new reply to reply list
    comments = comments.map(x => {
      if (x.comment_id == record.parent_comment_id) {
        let rlist = x.reply_list.map(r => {
          if (r.comment_id == record.comment_id) r.message = record.message;
          return r;
        });
        x.reply_list = [...rlist];
      }
      return x;
    });

    if (record.parent_comment_id != selectedComment.comment_id) return;
    let rlist = selectedComment.reply_list.map(r => {
      if (r.comment_id == record.comment_id) r.message = record.message;
      return r;
    });
    selectedComment.reply_list = [...rlist];
    state.selectedComment = { ...selectedComment };
  },

  [REALTIME_DELETE_REPLY](state, record) {
    const parent_comment_id = record.parent_comment_id;
    const comment_id = record.comment_id;
    let { selectedComment, comments } = state;
    // check whether reply is in current list of comments
    if (!comments.filter(x => x.comment_id == parent_comment_id).length) return;
    // Add new reply to reply list
    comments = comments.map(x => {
      if (x.comment_id == parent_comment_id) {
        let rlist = x.reply_list.filter(r => r.comment_id != comment_id);
        x.reply_list = [...rlist];
      }
      return x;
    });

    if (parent_comment_id != selectedComment.comment_id) return;
    let rlist = selectedComment.reply_list.filter(x => x.comment_id != comment_id);
    selectedComment.reply_list = [...rlist];
    state.selectedComment = { ...selectedComment };
  },

  [SET_LOAD_TIME](state, time) {
    state.loadTime = time;
  },

  [LOAD_COMMENT_TOKEN](state, next) {
    state.loadCommentToken = next;
  },

  [LOAD_COMMENT_FILTER_TOKEN](state, next) {
    state.loadCommentFilterToken = next;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
