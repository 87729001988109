import { SERVING_STATE, CHANNEL_MODE, TELEWORK, AGENT_ROLE_CORE } from 'core/constants';
import moment from 'moment';
import fireStorage from './firebase/storage';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkifyjs/html';
import { IMAGE_EXTENSIONS_SUPPORTED } from './constants';
import { TICKET_STATUS, ACTION, AGENT_ROLE, TICKET_STATE, TABS } from './constants';
import { REQ_AGNT_FEEDBACK, USER_SELECT_ROOM, USER_SELECT_GROUP } from 'core/constants';
import store from 'core/store';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import { i18n } from 'locales';
import fetchAPI from 'core/fetchAPI';
import timezones from 'core/timezones';
import cSecurity from 'core/cSecurity';
import excel from 'excel4node';
import baseNotification from 'components/BaseComponents/BaseNotification';
import router from 'core/router';
import { EventBus } from 'core/eventBus';

export const waiting = async time => new Promise(res => setTimeout(() => res(true), time));

export const getAgentName = info => {
  const { firstName = '', lastName = '' } = info;

  const name =
    firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName
        ? firstName
        : lastName
          ? lastName
          : '';
  return name;
};

//Just use to update tab of the selected conversation
export const getGroupConvId = (conv, user) => {
  if (!conv) return -1;
  const { role, id } = user;
  const { agentId, botId, ticket } = conv;

  if (ticket) {
    const { id: ticketId, status = '', state = '', projectId } = ticket;
    const { assignee = '', agentId = '' } = ticket;

    const { getters, state: _state = {} } = store;
    const { session: { projectMaps = {} } = {} } = _state;
    const meGroups = getters['session/meGroups'] || [];
    const projectHasGroup = projectMaps[projectId] && projectMaps[projectId].hasGroup;
    const projectConfig = projectMaps[projectId] && projectMaps[projectId].config;
    const { accessPermission: { isAllowAllView } = {} } = projectConfig || {};

    const groupId =
      TICKET_STATE.COMPLETE === state ||
        (assignee !== id &&
          assignee !== ticketId &&
          !['FORWARD_GROUP', 'AUTO_ASSIGN'].includes(assignee))
        ? SERVING_STATE.MONITORING
        : TICKET_STATUS.BOT_HANDLE === status || TICKET_STATUS.REQUEST_USER === status
          ? SERVING_STATE.BOT
          : (agentId === ticketId && assignee === ticketId) ||
            (assignee === id && assignee !== agentId) ||
            assignee === TICKET_STATUS.FORWARD_GROUP
            ? SERVING_STATE.WAITING
            : assignee === id && agentId === id
              ? SERVING_STATE.AGENT
              : SERVING_STATE.MONITORING;

    if (groupId === SERVING_STATE.MONITORING) {
      if (
        (!isAllowAllView &&
          [AGENT_ROLE.REGULAR].includes(role) &&
          ticket.assignee !== id &&
          ticket.assignee !== ticket.id) ||
        (ticket?.groupId && !meGroups.includes(ticket?.groupId))
      )
        return -1;

      if ([AGENT_ROLE.MODERATOR].includes(role)) {
        if (!projectHasGroup && ticket.assignee !== id && ticket.assignee !== ticket.id) return -1;
        if (projectHasGroup && !isAllowAllView && ticket.agentRole === AGENT_ROLE_CORE.LEADER)
          return -1;
        if (ticket?.groupId && !meGroups.includes(ticket?.groupId)) return -1;
      }
    }

    return groupId;
  }
  if (!botId && !agentId) return SERVING_STATE.WAITING;
  else if (botId && !agentId) return SERVING_STATE.BOT;
  else if (agentId && !botId) return SERVING_STATE.AGENT;
  else if (botId && agentId) return SERVING_STATE.MONITORING;
};

export const convertTabIndexToGroupNav = tabIndex => {
  switch (tabIndex) {
    case TABS.BOT:
      return SERVING_STATE.BOT;
    case TABS.WAITING_COMMON:
    case TABS.WAITING_ME:
      return SERVING_STATE.WAITING;
    case TABS.ME:
      return SERVING_STATE.AGENT;
    case TABS.MONITORING:
      return SERVING_STATE.MONITORING;
    default:
      return -1;
  }
};

export const convertGroupNavToTabIndex = (groupId, waitingMode) => {
  switch (groupId) {
    case SERVING_STATE.BOT:
      return TABS.BOT;
    case SERVING_STATE.WAITING:
      if (waitingMode === 'TOME') {
        return TABS.WAITING_ME;
      } else {
        return TABS.WAITING_COMMON;
      }
    case SERVING_STATE.AGENT:
      return TABS.ME;
    case SERVING_STATE.MONITORING:
      return TABS.MONITORING;
    default:
      return -1;
  }
};

export const getTabIndex = ({ conv, user }) => {
  if (!conv || !conv.ticket) return -1;
  const { agentId, botId, ticket } = conv;

  const { projectId = '' } = ticket || {};
  const { getters, state = {} } = store;
  const { session: { projectMaps = {} } = {} } = state;
  const meGroups = getters['session/meGroups'] || [];
  const projectHasGroup = projectMaps[projectId] && projectMaps[projectId].hasGroup;
  const projectConfig = projectMaps[projectId] && projectMaps[projectId].config;
  const { accessPermission: { isAllowAllView } = {} } = projectConfig || {};

  const commonStatus = [TICKET_STATUS.REQUEST_AGENT, TICKET_STATUS.WAITING_TIMEOUT];
  const waitingState = [TICKET_STATE.REQUEST];

  const toMeStatus = [
    TICKET_STATUS.ASSIGN_TO,
    TICKET_STATUS.TRANSFER_TO,
    TICKET_STATUS.ESCALATE_TO,
    TICKET_STATUS.WAITING_TIMEOUT,
    TICKET_STATUS.RESPONSE_TIMEOUT
  ];

  const states = [TICKET_STATE.REQUEST, TICKET_STATE.SUPPORTING, TICKET_STATE.START];

  const supportStatus = [
    TICKET_STATUS.AGENT_SUPPORTING,
    TICKET_STATUS.HOLDING,
    TICKET_STATUS.AGENT_IDLE,
    TICKET_STATUS.USER_IDLE,
    TICKET_STATUS.USER_FINISH,
    TICKET_STATUS.AGENT_FINISH,
    TICKET_STATUS.AGENT_START,
    TICKET_STATUS.FINISH_TIMEOUT,
    TICKET_STATUS.RESPONSE_TIMEOUT,
    TICKET_STATUS.MAINTENANCE_FINISH
  ];
  const noneState = [TICKET_STATE.COMPLETE];

  if (!isAllowAllView) {
    if (projectHasGroup && user.role === AGENT_ROLE.MODERATOR) {
      if (
        ticket.agentRole === AGENT_ROLE_CORE.LEADER &&
        ![...commonStatus, ...toMeStatus].includes(ticket.status)
      ) {
        return -2;
      }
    }

    if (
      user.role === AGENT_ROLE.REGULAR &&
      ticket.assignee !== user.id &&
      ticket.assignee !== ticket.id
    ) {
      return -2;
    }
  }

  if (
    (!ticket && botId) ||
    (ticket &&
      [TICKET_STATUS.REQUEST_USER, TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.USER_MENTION].includes(
        ticket.status
      ))
  )
    return TABS.BOT;
  if (
    (!ticket && !agentId && !botId) ||
    (ticket.assignee === TICKET_STATUS.FORWARD_GROUP && //SGCPLUS-4665
      ticket.state !== TICKET_STATE.COMPLETE &&
      ticket.groupId) ||
    (ticket &&
      ticket.id === ticket.assignee &&
      ticket.id === ticket.agentId &&
      commonStatus.includes(ticket.status) &&
      waitingState.includes(ticket.state) &&
      (ticket.groupId || !ticket.groupId))
  )
    return TABS.WAITING_COMMON;
  if (
    (!ticket && !botId && agentId === user.id) ||
    (ticket &&
      ticket.assignee === user.id &&
      ticket.assignee !== ticket.agentId &&
      toMeStatus.includes(ticket.status) &&
      states.includes(ticket.state))
  )
    return TABS.WAITING_ME;
  if (
    ticket &&
    ticket.assignee == user.id &&
    ticket.agentId == user.id &&
    supportStatus.includes(ticket.status) &&
    !noneState.includes(ticket.state)
  )
    return TABS.ME;

  if (ticket && ![TICKET_STATUS.BOT_HANDLE].includes(ticket.status)) {
    if ([AGENT_ROLE.LEADER].includes(user.role)) return TABS.MONITORING;
    if (
      projectHasGroup &&
      AGENT_ROLE.MODERATOR === user.role &&
      (!ticket.groupId || (ticket.groupId && meGroups.includes(ticket.groupId)))
    )
      return TABS.MONITORING;
    if (
      !projectHasGroup &&
      AGENT_ROLE.MODERATOR === user.role &&
      (ticket.assignee === user.id || ticket.assignee === ticket.id)
    )
      return TABS.MONITORING;
    if (
      AGENT_ROLE.REGULAR === user.role &&
      (ticket.assignee === user.id ||
        ticket.assignee === ticket.id ||
        (ticket.groupId && meGroups.includes(ticket.groupId)))
    )
      return TABS.MONITORING;
  }

  return -1;
};

export const buildProductMessage = products => {
  let titleBtn = i18n.t('src.modules.chat.components.ProductPanel.product-view.view_in_website');
  titleBtn = titleBtn && titleBtn.length > 20 ? titleBtn.substring(0, 17) + '...' : titleBtn;
  return {
    template_type: 'generic',
    image_aspect_ratio: 'square',
    elements: products.map(({ image_url, name: title, price: subtitle, url }) => {
      return {
        title,
        image_url,
        subtitle,
        default_action: {
          type: 'web_url',
          url
        },
        buttons: [
          {
            type: 'web_url',
            url,
            title: titleBtn
          }
        ]
      };
    })
  };
};

export const buildLinkReviewMessage = linkReviews => {
  return {
    template_type: 'generic',
    elements: linkReviews.map(({ image, title, url, description, icon }) => {
      return {
        title,
        image_url: image,
        subtitle: description,
        default_action: {
          type: 'web_url',
          url,
          webview_height_ratio: 'COMPACT'
        },
        icon: icon
      };
    })
  };
};

export const buildSurveyMessage = (text, survey) => {
  return {
    template_type: 'button',
    text,
    buttons: [
      {
        type: 'web_url',
        title: survey.textLink
      }
    ]
  };
};

const feedBackEnum = {
  'Very Satisfied': 'C_FEEDBACK_VERY_SATISFIED',
  Satisfied: 'C_FEEDBACK_SATISFIED',
  OK: 'C_FEEDBACK_OK',
  'Very Dissatisfied': 'C_FEEDBACK_VERY_DISSATISFIED',
  Dissatisfied: 'C_FEEDBACK_DISSATISFIED',
  'Other feedback': 'C_FEEDBACK_OTHER',
  'No feedback': 'C_NO_FEEDBACK_MESSAGE'
};

export const getTextFromMessageContent = (content, type, text, selectedConversation) => {
  if (type === 'text') return content || text;

  if (type === 'image')
    return i18n.t(
      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_an_image'
    );

  if (type === 'sticker')
    return i18n.t(
      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_a_sticker'
    );

  if (type === 'video')
    return i18n.t(
      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_a_video'
    );

  if (type === 'audio')
    return i18n.t(
      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_an_audio'
    );

  if (type === 'file')
    return i18n.t(
      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_a_file'
    );

  if (type === 'referral') return text || '';

  let objContent = null;
  if (typeof content === 'object') {
    objContent = content;
  } else {
    try {
      objContent = JSON.parse(content);
      if (typeof objContent !== 'object') throw 'No object';
    } catch (error) {
      return text || content || '';
    }
  }

  if (!objContent) {
    if (text && text !== '') return text;
    return content;
  }

  if (objContent.type === REQ_AGNT_FEEDBACK) {
    if (selectedConversation) {
      try {
        const key = feedBackEnum[objContent.content.answer];
        const tls = translations(selectedConversation, key);
        if (tls === key && text) return text;
        else if (tls === key) return objContent.content.answer;
        return tls;
      } catch (error) {
        // eslint-disable-next-line
        console.log('[getTextFromMessageContent] > translate > error', error);
      }
    } else if (text) {
      return text;
    }
  }

  if ([USER_SELECT_ROOM, USER_SELECT_GROUP].includes(objContent.type)) {
    if (objContent.context && objContent.context.title) return objContent.context.title;
  }

  if (type === 'template') {
    const { template_type } = objContent;
    if (template_type === 'button') return objContent.text;
    if (template_type === 'generic' || template_type === 'list') {
      const { elements } = objContent;
      const [elm] = elements;
      return elm.title;
    }
    if (['imagemap', 'image_multiple'].includes(template_type)) {
      return i18n.t(
        'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.sent_an_image'
      );
    } else return template_type;
  }

  if (objContent.text) return objContent.text;

  if (objContent.content && objContent.content.answer)
    return objContent.content.text || objContent.content.answer;

  if (objContent.context && objContent.context.title) return objContent.context.title;

  if (typeof objContent.ref !== 'undefined')
    return 'User used ' + (objContent.source || objContent.ref);

  if (text && text !== '') return text;
  return `${type} message`;
};

export const urlify = text => {
  if (!text) return '';
  // eslint-disable-next-line
  const regex = /(line)[:\/\/]+|#/g;
  const url = linkifyHtml(text, {
    defaultProtocol: 'http'
  });
  return text.match(regex) ? text : url;
};

export const escapeHtmlChar = text => {
  if (!text) return '';
  text = String(text)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
  return text;
};

export const iframeHidden = text => {
  const txt = text.toString();
  return txt.includes('iframe');
};

/**
 *
 * @param {Object} selectedConversation
 * @param {String} key
 * @param {Object} params
 */
export const translations = (selectedConversation, key, mappingObj) => {
  const { translations } = selectedConversation;
  if (!translations) return key || '';
  const value = translations[key];
  let dataOut = value || key || '';
  if (mappingObj && Object.keys(mappingObj).length > 0) {
    Object.keys(mappingObj).forEach(k => {
      dataOut = dataOut.split(k).join(mappingObj[k]);
    });
  }
  return dataOut;
};

export const formatTime = value => {
  try {
    if (value) return moment(value).format('DD MMM YYYY LT');
  } catch {
    return value;
  }
  return null;
};

export const getVideoImage = (file, path = 'videos/thumbnails/') => {
  var URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.onloadedmetadata = function () {
      this.currentTime = Math.min(1, this.duration);
    };

    video.onloadeddata = function () {
      var canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        fireStorage.upload(blob, path + file.name).then(url => {
          resolve({ url, error: null });
        });
      });
    };

    video.onerror = function (error) {
      reject(error);
    };

    var fileURL = URL.createObjectURL(file);
    video.src = fileURL;
  }).catch(error => Promise.resolve({ url: null, error }));
};

export const getTranslationsMapping = (userInfo, channelId, channelsFilterMap) => {
  const { state } = store;
  let channelsMap = channelsFilterMap;
  if (!channelsMap) {
    const { channelsMap: channelsMapState } = state.session;
    channelsMap = channelsMapState;
  }
  const { translations, defaultLanguage } = channelsMap[channelId];
  let tlt = {};
  if (translations) {
    if (!userInfo || !userInfo.locale) {
      tlt = Object.keys(translations).reduce(function (result, key) {
        if (!defaultLanguage) return undefined;
        result[key] = translations[key][defaultLanguage];
        return result;
      }, {});
    } else {
      const { locale } = userInfo;
      tlt = Object.keys(translations).reduce(function (result, key) {
        if (typeof translations[key] === 'object' && translations[key][locale]) {
          result[key] = translations[key][locale];
          return result;
        }
        if (!defaultLanguage) return undefined;
        result[key] = translations[key][defaultLanguage];
        return result;
      }, {});
    }
  }
  return tlt;
};

export const translationsMapping = (translations, key, mappingObj) => {
  if (!translations) return key || '';
  const value = translations[key];
  let dataOut = value || key || '';
  if (mappingObj && Object.keys(mappingObj).length > 0) {
    Object.keys(mappingObj).forEach(k => {
      dataOut = dataOut.split(k).join(mappingObj[k]);
    });
  }
  return dataOut;
};

export const canReSupport = ticket => {
  const { state } = store;
  if (!ticket || !ticket.id) return false;
  const channel = state.session.channelsMap[ticket.channelId];

  return (
    channel &&
    channel.mode !== CHANNEL_MODE.MAINTAIN &&
    [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(ticket.state)
  );
};

export const getActions = (agent, ticket, projectMaps) => {
  const me = agent;
  if (!ticket || !me) return {};
  const { state, status, assignee, agentId } = ticket;

  const isAssignMe = assignee === me.id;
  const isAgent = agentId === me.id;
  const botHandle = [
    TICKET_STATUS.BOT_HANDLE,
    TICKET_STATUS.REQUEST_USER,
    TICKET_STATUS.USER_MENTION
  ].includes(ticket.status);

  const commonSupport = [
    TICKET_STATUS.REQUEST_AGENT,
    TICKET_STATUS.BOT_HANDLE,
    TICKET_STATUS.USER_MENTION,
    TICKET_STATUS.WAITING_TIMEOUT
  ].includes(status);

  const assigning = [
    TICKET_STATUS.ASSIGN_TO,
    TICKET_STATUS.ESCALATE_TO,
    TICKET_STATUS.TRANSFER_TO
  ].includes(status);

  const supporting =
    TICKET_STATE.SUPPORTING === state &&
    [
      TICKET_STATUS.AGENT_SUPPORTING,
      TICKET_STATUS.AGENT_IDLE,
      TICKET_STATUS.USER_IDLE,
      TICKET_STATUS.RESPONSE_TIMEOUT,
      TICKET_STATUS.HOLDING
    ].includes(status);

  const finished = [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state);

  const group =
    ticket.groupId &&
    projectMaps &&
    projectMaps[ticket.projectId] &&
    projectMaps[ticket.projectId].groups[ticket.groupId];

  const isTimeout = [TICKET_STATUS.WAITING_TIMEOUT, TICKET_STATUS.RESPONSE_TIMEOUT].includes(
    status
  );

  const isMod = group && group.moderators.includes(me.id);

  const actions = Object.values(ACTION).reduce((carryOut, action) => {
    switch (action) {
      case ACTION.SUBMIT_FORM: {
        carryOut[action] =
          isAssignMe &&
          [
            TICKET_STATUS.AGENT_FINISH,
            TICKET_STATUS.USER_FINISH,
            TICKET_STATUS.FINISH_TIMEOUT,
            TICKET_STATUS.MAINTENANCE_FINISH
          ].includes(status) &&
          ![TICKET_STATE.COMPLETE].includes(state);
        break;
      }

      case ACTION.HOLD: {
        carryOut[action] =
          isAssignMe &&
          supporting &&
          ![TICKET_STATE.COMPLETE].includes(state) &&
          status !== TICKET_STATUS.HOLDING;
        break;
      }

      case ACTION.UN_HOLD: {
        carryOut[action] = isAssignMe && status === TICKET_STATUS.HOLDING;
        break;
      }

      case ACTION.FINISH: {
        carryOut[action] =
          isAssignMe && isAgent && [TICKET_STATE.START, TICKET_STATE.SUPPORTING].includes(state);
        break;
      }

      case ACTION.FOLLOW: {
        carryOut[action] = [
          TICKET_STATE.REQUEST,
          TICKET_STATE.START,
          TICKET_STATE.SUPPORTING,
          TICKET_STATE.FINISH
        ].includes(state);
        break;
      }

      case ACTION.MANAGE_LABLE: {
        carryOut[action] = [AGENT_ROLE.LEADER].includes(me.role);
        break;
      }

      default: {
        carryOut[action] = false;
        break;
      }
    }

    return carryOut;
  }, {});

  if (finished) {
    actions[ACTION.RE_SUPPORT] = canReSupport(ticket);
    return actions;
  }
  // REGULAR
  else if (me.role === AGENT_ROLE.REGULAR) {
    actions[ACTION.SUPPORT] =
      commonSupport ||
      (isAssignMe && (assigning || status === TICKET_STATUS.WAITING_TIMEOUT)) ||
      (isAssignMe && agentId !== me.id && status === TICKET_STATUS.RESPONSE_TIMEOUT) ||
      assignee === TICKET_STATUS.FORWARD_GROUP;
    actions[ACTION.ASSIGN] = false;
    actions[ACTION.TRANSFER] = isAssignMe && !botHandle;
    actions[ACTION.FORWARD_GROUP] = group && !botHandle; //SGCPLUS-4665
    actions[ACTION.ESCALATE] = isAssignMe && !botHandle;
  }
  // MODERATOR
  else if (me.role === AGENT_ROLE.MODERATOR) {
    actions[ACTION.NO_SUPPORT] =
      projectMaps[ticket.projectId] &&
      projectMaps[ticket.projectId].hasGroup &&
      [TICKET_STATE.REQUEST].includes(state) &&
      ![TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.REQUEST_USER].includes(status);
    actions[ACTION.FORWARD_GROUP] = group && !botHandle; //SGCPLUS-4665

    if (group && isMod) {
      actions[ACTION.ASSIGN] = !botHandle;
      actions[ACTION.SUPPORT] =
        commonSupport ||
        assigning ||
        (isTimeout && [TICKET_STATE.REQUEST].includes(state)) ||
        ((supporting ||
          [TICKET_STATUS.AGENT_START, TICKET_STATUS.RESPONSE_TIMEOUT].includes(status)) &&
          !isAssignMe) ||
        (isAssignMe && agentId !== me.id && status === TICKET_STATUS.RESPONSE_TIMEOUT) ||
        assignee === TICKET_STATUS.FORWARD_GROUP;
      actions[ACTION.TRANSFER] = !botHandle;
      actions[ACTION.ESCALATE] = !botHandle;
    } else {
      actions[ACTION.SUPPORT] =
        commonSupport ||
        (isAssignMe && (assigning || status === TICKET_STATUS.WAITING_TIMEOUT)) ||
        (isAssignMe && agentId !== me.id && status === TICKET_STATUS.RESPONSE_TIMEOUT) ||
        assignee === TICKET_STATUS.FORWARD_GROUP;
      actions[ACTION.ASSIGN] = false;
      actions[ACTION.TRANSFER] = isAssignMe && !botHandle;
      actions[ACTION.ESCALATE] = isAssignMe && !botHandle;
    }
  }
  // LEADER
  else if (me.role === AGENT_ROLE.LEADER) {
    actions[ACTION.SUPPORT] =
      commonSupport ||
      assigning ||
      (isTimeout && [TICKET_STATE.REQUEST].includes(state)) ||
      ((supporting ||
        [TICKET_STATUS.AGENT_START, TICKET_STATUS.RESPONSE_TIMEOUT].includes(status)) &&
        !isAssignMe) ||
      (isAssignMe && agentId !== me.id && status === TICKET_STATUS.RESPONSE_TIMEOUT) ||
      assignee === TICKET_STATUS.FORWARD_GROUP;
    actions[ACTION.NO_SUPPORT] =
      [TICKET_STATE.REQUEST].includes(state) &&
      ![TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.REQUEST_USER].includes(status);
    actions[ACTION.ASSIGN] = !botHandle;
    actions[ACTION.TRANSFER] = !botHandle;
    actions[ACTION.FORWARD_GROUP] = group && !botHandle; //SGCPLUS-4665
    actions[ACTION.ESCALATE] = false;
  }

  return actions;
};

// Get string of field 'Handled by'
export const getHandler = (activity, type) => {
  const agentHandledStatus = [
    TICKET_STATUS.HOLDING,
    TICKET_STATUS.AGENT_START,
    TICKET_STATUS.AGENT_SUPPORTING,
    TICKET_STATUS.ASSIGN_TO,
    TICKET_STATUS.ESCALATE_TO,
    TICKET_STATUS.TRANSFER_TO,
    TICKET_STATUS.AGENT_FINISH,
    TICKET_STATUS.USER_FINISH,
    TICKET_STATUS.MAINTENANCE_FINISH,
    TICKET_STATUS.FORWARD_GROUP,
    TICKET_STATUS.COMPLETE,
    TICKET_STATUS.NO_SUPPORT
  ];

  let handler = 'System';
  if (
    activity.status === TICKET_STATUS.BOT_HANDLE ||
    activity.status === TICKET_STATUS.REQUEST_USER
  ) {
    handler = 'Bot';
  } else if (
    Object.keys(activity.agentInfo).length > 0 &&
    (agentHandledStatus.includes(activity.status) ||
      (activity.isInterrupted && type === 'monitoring'))
  ) {
    handler = activity.agentInfo.fullName;
  }
  return handler;
};

export const sortByName = (sort, firstChannel, secondChannel) => {
  let firstChannelName = firstChannel.name.toUpperCase();
  let secondChannelName = secondChannel.name.toUpperCase();
  let result;
  if (firstChannelName > secondChannelName) {
    result = 1;
  } else if (firstChannelName < secondChannelName) {
    result = -1;
  } else {
    result = 0;
  }
  return sort * result;
};

export const sortByCount = (conversations, sort, firstChannel, secondChannel) => {
  let firstChannelAgentCnv = conversations.filter(c => c.channelId === firstChannel.id).length;
  let secondChannelAgentCnv = conversations.filter(c => c.channelId === secondChannel.id).length;
  return sort * (firstChannelAgentCnv - secondChannelAgentCnv);
};

export const sortConversations = conversations => {
  conversations.sort((a, b) => {
    if (a.message && a.message.createdAt && b.message && b.message.createdAt) {
      return b.message.createdAt.localeCompare(a.message.createdAt);
    }
    return 1;
  });

  return uniqBy(conversations, 'ticketId');
};

export const filterConversations = (
  conversations,
  channelId,
  localesFilter,
  searchTextConversation
) => {
  let list = conversations.filter(cons => {
    return (
      (localesFilter && localesFilter.length > 0
        ? cons.channelId === channelId && localesFilter.includes(cons.userInfo.locale)
        : cons.channelId === channelId) &&
      (searchTextConversation && searchTextConversation.length > 0
        ? cons.channelId === channelId &&
        some(searchTextConversation, text => {
          if (cons.name) {
            return cons.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
          } else if (cons.userInfo.name) {
            return cons.userInfo.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
          } else return false;
        })
        : cons.channelId === channelId)
    );
  });
  return list;
};

export const buildMailOptions = (toAddr, subject, content) => {
  const auth = 'appmaker.developer@social-gear.jp';
  return {
    mailOptions: {
      from: `"${i18n.t('src.modules.session.store.actions.des_support')}"${auth}"`,
      to: toAddr,
      subject: subject,
      text: content,
      html: content
    }
  };
};

export const handleInternalErrorMessageFireBase = message => {
  try {
    const messageRaw = 'An internal error has occurred. Raw server response: ';
    const checkExist = message.includes(messageRaw);
    if (checkExist) {
      message = message.replace(messageRaw, '');
      let objectMss = JSON.parse(message.slice(1, -1));
      let newMessage = objectMss.error && objectMss.error.message;
      newMessage = 'Error: ' + newMessage.replace(/_/g, ' ').toLowerCase();
      return newMessage;
    } else return message;
  } catch (error) {
    return message;
  }
};

/**
 * Get all url form message
 *
 * @param {String} message text
 * @returns {Object} {urls: urls list in message,isURLOnly: only is url  }
 */
export const getURLLstFrmTxt = text => {
  if (typeof text !== 'string') return false;
  // remove script text
  const urlRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  const newText = text.replace(urlRegex, '');
  /**
   * Get all url form message
   *
   * @param {String} message text.
   * @returns {Array} [{type: 'url', value: 'github.com',href: 'http://github.com'},{type: 'email',value: 'test@example.com',href: 'mailto:test@example.com'}]
   */
  let urls = linkify.find(newText);

  var digitJapan = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;

  if (digitJapan.test(text) && urls.length === 0) {
    var _digitJapan = '\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f';

    var urlRegexAllProtocol = new RegExp(
      'https?://(www.)?[-a-zA-Z0-9@:%._+~#=' +
      _digitJapan +
      ']{1,256}.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
      'g'
    );

    var urlRegexAllNotProtocol = new RegExp(
      '[-a-zA-Z0-9@:%._+~#=' +
      _digitJapan +
      ']{1,256}.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
      'g'
    );
    let matches = text.match(urlRegexAllProtocol) || [];
    if (matches.length === 0) {
      matches = text.match(urlRegexAllNotProtocol) || [];
      urls = matches.map(w => {
        return {
          href: 'http://' + w.replace('"', ''),
          type: 'url',
          value: w.replace('"', '')
        };
      });
    } else {
      urls = matches.map(w => {
        return {
          href: w.replace('"', ''),
          type: 'url',
          value: w.replace('"', '')
        };
      });
    }
  }
  return {
    urls: urls.map(url => {
      return url.href;
    }),
    isURLOnly: urls.length === 1 && newText === urls[0].value
  };
};

export const getURLsFromText = text => {
  // eslint-disable-next-line
  const matches =
    text.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g
    ) || [];

  const urls = matches.map(w => w.replace('"', '')).slice(0, 2); // Skype limit 2 link preview per message

  return {
    urls,
    isURLOnly: urls.length > 0 && urls[0].length === text.length
  };
};

export const getMaxUsersPerAgent = (channelId, hasGroup, role) => {
  const { state } = store;
  const { channelsMap } = state.session || {};
  const channel = channelsMap[channelId] || {};
  const {
    advancedConfigs: {
      conversationQuota: {
        numberLeader = 0,
        numberRegular = 0,
        numberRegularLeader = 0,
        numberSupervisor = 0
      } = {}
    } = {}
  } = channel;
  if (hasGroup) {
    switch (role) {
      case AGENT_ROLE.REGULAR:
        return numberRegular === 0 ? i18n.t('src.core.helper.unlimit') : numberRegular;
      case AGENT_ROLE.MODERATOR:
        return numberLeader === 0 ? i18n.t('src.core.helper.unlimit') : numberLeader;
      case AGENT_ROLE.LEADER:
        return numberSupervisor === 0 ? i18n.t('src.core.helper.unlimit') : numberSupervisor;
      default:
        return i18n.t('src.core.helper.unlimit');
    }
  } else {
    switch (role) {
      case AGENT_ROLE.REGULAR:
      case AGENT_ROLE.MODERATOR:
        return numberRegularLeader === 0 ? i18n.t('src.core.helper.unlimit') : numberRegularLeader;
      case AGENT_ROLE.LEADER:
        return numberSupervisor === 0 ? i18n.t('src.core.helper.unlimit') : numberSupervisor;
      default:
        return i18n.t('src.core.helper.unlimit');
    }
  }
};

export const getGroupIdsOfAgent = (projectId, user) => {
  const { assignedProjects = [] } = user;
  return assignedProjects.reduce((acc, pj) => {
    const { id = '', assignedGroups = [] } = pj;
    if (projectId === id && assignedGroups.length > 0) {
      const ids = Object.values(assignedGroups).map(i => i.id);
      acc = [...acc, ...ids];
    }
    return acc;
  }, []);
};

export const compareName = (a, b, field = false) => {
  if (!field) field = 'name';
  const _a = a[field] ? a[field].toLowerCase() : '';
  const _b = b[field] ? b[field].toLowerCase() : '';
  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
};

/**
 *
 * @param {*} a
 * @param {*} b
 * @param {*} description 1: ascending; -1 descending
 */
export const compareDateStr = (a, b, description = 1) => {
  if (!a || !b) return 0;
  return description > 0 ? a.localeCompare(b) : b.localeCompare(a);
};

export const findAllIndex = (content, word, lowerCase = false) => {
  if (lowerCase) {
    content = content.toLowerCase();
    word = word.toLowerCase();
  }

  let indices = [];
  let idx = content.indexOf(word);
  while (idx != -1) {
    indices.push(idx);
    idx = content.indexOf(word, idx + 1);
  }
  return indices;
};

/**
 * Parse all parameters exist in url
 * @param {string} url
 * @returns object {param1: content1, params2: content2}
 */
export const getParamsFromUrl = url => {
  if (!url) url = location.href;
  var question = url.indexOf('?');
  var hash = url.indexOf('#');
  if (hash == -1 && question == -1) return {};
  if (hash == -1) hash = url.length;
  var query =
    question == -1 || hash == question + 1
      ? url.substring(hash)
      : url.substring(question + 1, hash);
  var result = {};
  query.split('&').forEach(function (part) {
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    var eq = part.indexOf('=');
    var key = eq > -1 ? part.substr(0, eq) : part;
    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    var from = key.indexOf('[');
    if (from == -1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf(']', from);
      var index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

// Verify Japanese or Chinese characters
export const hasSpecialCharacters = string => {
  // Verify character more than 1 byte
  for (let i = 0, n = string.length; i < n; i++) {
    if (string.charCodeAt(i) > 255) {
      return true;
    }
  }
  return false;
};

export const reSupporting = (ticket, conversationsMap, tickets) => {
  let reSupporting = false;
  try {
    const { conversationId, id } = ticket;
    const { ticketId: curTicketId } = conversationsMap[conversationId];
    if (curTicketId !== id) {
      const { state: stateTicket } = tickets[curTicketId];

      reSupporting = stateTicket !== TICKET_STATE.FINISH && stateTicket !== TICKET_STATE.COMPLETE;
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log('[C+ Debug]: reSupporting -> error', error);
    return reSupporting;
  }
  return reSupporting;
};

export const validatePhone = phoneNumber => {
  const regexPhone = /^[+]*[(+]{0,2}[0-9]{0,4}[)]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}([-]{0,1}\d)*$/;
  return !phoneNumber ||
    !regexPhone.test(phoneNumber) ||
    phoneNumber.indexOf('()') !== -1 ||
    phoneNumber.indexOf('(+)') !== -1 ||
    (phoneNumber.split('(').length - 1 === 1 && phoneNumber.split(')').length - 1 < 1) ||
    (phoneNumber.split(')').length - 1 === 1 && phoneNumber.split('(').length - 1 < 1) ||
    phoneNumber.split(')').length - 1 > 1 ||
    phoneNumber.split('(').length - 1 > 1 ||
    phoneNumber.split('+').length - 1 > 1 ||
    phoneNumber.length < 3
    ? false
    : true;
};

export const convertPhoneNumber2OneByte = phoneNumber => {
  phoneNumber = phoneNumber.trim();
  return !phoneNumber
    ? phoneNumber
    : phoneNumber
      .replace(/[\uff01-\uff5e]/g, fullWidthChar =>
        String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
      )
      .replace(/ー|ｰ/g, '-')
      .substring(0, 20);
};

export const isRequireAuth = () => {
  const { dispatch } = store;
  const tid = localStorage.getItem('user-tid');
  const token = localStorage.getItem('user-token');
  const authStatus = localStorage.getItem('auth-status');
  if ((token && authStatus === 'ERROR') || !tid) {
    const action = 'global/setGlobalReady';
    dispatch(action, true, { root: true });
    return true;
  }
  return false;
};

export const getFileType = f => {
  const { type, name } = f;
  const audioType = ['audio/mp3', 'audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/flac'];
  const videoType = ['video/mp4', 'video/ogg', 'video/webm'];
  if (audioType.includes(type)) return 'audioUploadSize';
  else if (videoType.includes(type)) return 'videoUploadSize';
  else if (type.startsWith('image')) {
    const extensionsSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
      name.toLowerCase().endsWith(ex)
    );
    if (extensionsSupported) return 'imageUploadSize';
  }
  return 'fileUploadSize';
};

export const userStatusInSupportingWC = (ticket = {}, channelsMap = {}) => {
  const { channelId = '' } = ticket || {};
  const { agentId = '', assignee = '' } = ticket || {};
  const { state = '', userStatus = '{}' } = ticket || {};
  const { status = 1 } = JSON.parse(userStatus || '{}');
  const { platform = '' } = (channelsMap && channelsMap[channelId]) || {};
  return {
    userStatus: status,
    display:
      TICKET_STATE.SUPPORTING === state && agentId === assignee && platform === 'webchat'
        ? true
        : false
  };
};

export const stringifyZone = (zone, offset) => {
  const ensure2Digits = num => (num > 9 ? `${num}` : `0${num}`);
  return `(${offset}${zone.offset < 0 ? '-' : '+'}${ensure2Digits(
    Math.floor(Math.abs(zone.offset))
  )}:${ensure2Digits(Math.abs((zone.offset % 1) * 60))}) ${zone.label}`;
};

export const stringifyUTC = zone => {
  const ensure2Digits = num => (num > 9 ? `${num}` : `0${num}`);
  return `${zone.offset < 0 ? '-' : '+'}${ensure2Digits(
    Math.floor(Math.abs(zone.offset))
  )}:${ensure2Digits(Math.abs((zone.offset % 1) * 60))}`;
};

export const checkPostCode = value => {
  const regexPostCode = /^[A-Za-z0-9-\s]+$/;
  if (value.toString().indexOf('-') === 0) {
    return false;
  } else if (value.toString().indexOf('-') === value.toString().length - 1) {
    return false;
  }
  if (value.toString().indexOf(' ') === 0) {
    return false;
  } else if (value.toString().indexOf(' ') === value.toString().length - 1) {
    return false;
  }

  const duplicated = value
    .toLowerCase()
    .split('')
    .sort()
    .join('')
    .match(/(-)\1+/g);
  if (duplicated !== null) return false;
  const duplicatedSpace = value
    .toLowerCase()
    .split('')
    .sort()
    .join('')
    .match(/(\s)\1+/g);
  if (duplicatedSpace !== null) return false;

  if (!regexPostCode.test(value)) {
    return false;
  }
  return true;
};

export const convertOneByte = value => {
  return !value
    ? value
    : value
      .replace(/[\uff01-\uff5e]/g, fullWidthChar =>
        String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
      )
      .replace(/ー|ｰ/g, '-');
};

export const convertTimeUTC = (value, type) => {
  let timeUtc = moment.utc(value).toDate();
  if (type === 'comment') {
    timeUtc = moment(timeUtc).format('YYYY/MM/DD HH:mm:ss');
    return timeUtc;
  } else if (type === 'export') {
    timeUtc = moment(timeUtc).format('YYYY-MM-DD HH:mm:ss') + ` UTC ${getTimeZone()}`;
    return timeUtc;
  } else {
    timeUtc = moment(timeUtc).format('YYYY/MM/DD HH:mm:ss') + ` [UTC ${getTimeZone()}]`;
    return timeUtc;
  }
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
};

export const getLinkPreview = async url => {
  if (isValidUrl(url)) {
    const resLinkReview = await request(url);
    return resLinkReview;
  }
  return false;
};

const isValidUrl = url => {
  // eslint-disable-next-line
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  return regex.test(url);
};

const request = async url => {
  const fetch = new fetchAPI({
    baseURL: process.env.VUE_APP_WEBCHAT_API_URL,
    headers: {
      'x-api-key': process.env.VUE_APP_WEBCHAT_API_KEY
    }
  });
  return fetch
    .get('/og', {
      url: url
    })
    .then(res => {
      return res.data;
    });
};

export const getTimezoneDateTimeByName = params => {
  const { value = '', name = '' } = params;
  const timeZone = moment()
    .tz(name)
    .format('Z');
  return (
    moment(value)
      .tz(name)
      .format('YYYY/MM/DD HH:mm:ss') + ` [UTC ${timeZone}]`
  );
};

const timezoneNameMaps = timezones.reduce((tzMaps, item) => {
  tzMaps[item.name] = {
    name: stringifyZone(item, 'UTC'),
    utc: stringifyUTC(item)
  };
  return tzMaps;
}, {});

export const getTzByLocalFile = params => {
  const { value = '', name = '' } = params;
  if (value === '')
    return (
      moment()
        .utcOffset('00:00')
        .format('YYYY/MM/DD HH:mm:ss') + ' [UTC 00:00]'
    );
  if (name === '')
    return (
      moment(value)
        .utcOffset('00:00')
        .format('YYYY/MM/DD HH:mm:ss') + ' [UTC 00:00]'
    );
  const timeZone = timezoneNameMaps[name].utc;
  return (
    moment(value)
      .utcOffset(timeZone)
      .format('YYYY/MM/DD HH:mm:ss') + ` [UTC ${timeZone}]`
  );
};

export const hasButtonNew = params => {
  const { tabIndex = '0', hasUpdateTicketCome = '' } = params;

  const filter = Object.keys(hasUpdateTicketCome).filter(item => {
    if (
      item.lastIndexOf(tabIndex) === item.length - 1 &&
      item.lastIndexOf('-1') !== item.length - 2 &&
      hasUpdateTicketCome[item] === true
    ) {
      return true;
    }
    return false;
  });
  return filter.length > 0;
};

export const isSupportServiceWorker = async () => {
  if (!('serviceWorker' in navigator)) {
    // eslint-disable-next-line
    console.log('No Service Worker support!');
    return false;
  }
  if (!('PushManager' in window)) {
    // eslint-disable-next-line
    console.log('No Push API Support!');
    return false;
  }
  return true;
};

export const registerSW = async () => {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    if (!registrations.length) return navigator.serviceWorker.register('./sw.js');
  });
};

export const unregisterSW = () => {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
};

export const readySW = async (action = 'initial-data-user') => {
  return navigator.serviceWorker.ready.then(async registration => {
    const security = new cSecurity();
    let cplusToken = await security.getToken();
    if (action === 'initial-data-user') {
      registration.active.postMessage({
        action: 'initial-data-user',
        tokenId: localStorage.getItem('user-tid'),
        token: localStorage.getItem('user-token'),
        cplusToken: cplusToken,
        apiEndPoint: process.env.VUE_APP_SERVICE_ENDPOINT,
        title: i18n.t('src.core.router.agent_terminal'),
        haveNewMessages: i18n.t('src.core.helper.have_new_messages'),
        inWaitingList: i18n.t('src.core.helper.in_waiting_list'),
        storyReply: i18n.t('src.core.helper.notification.story_reply'),
        storyMention: i18n.t('src.core.helper.notification.story_mention')
      });
    }
  });
};

export const cameraCapture = async (projectId, agentId) => {
  return navigator.serviceWorker.ready.then(async registration => {
    const security = new cSecurity();
    let cplusToken = await security.getToken();
    const cameraCaptureImageBase64 = await getUserMedia().catch(error => {
      console.error('getUserMedia error', error);
    });

    return registration.active.postMessage({
      action: TELEWORK.NAME,
      agentId,
      projectId,
      token: localStorage.getItem('user-token'),
      cplusToken: cplusToken,
      cameraCaptureImageBase64: cameraCaptureImageBase64,
      apiSysEndPoint: process.env.VUE_APP_USER_SERVICE_ENDPOINT
    });
  });
};

export const desktopCapture = async (projectId, agentId, mediaStream, frequency) => {
  return navigator.serviceWorker.ready.then(async registration => {
    const security = new cSecurity();
    let cplusToken = await security.getToken();
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');
    video.autoplay = true;

    if ('srcObject' in video) {
      video.srcObject = mediaStream;
    } else {
      // Avoid using this in new browsers, as it is going away.
      video.src = URL.createObjectURL(mediaStream);
    }
    let interval = setInterval(() => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (
        video.videoWidth > TELEWORK.REVOLUTION_VIDEO.default_width &&
        video.videoHeight > TELEWORK.REVOLUTION_VIDEO.default_height
      ) {
        canvas.width = TELEWORK.REVOLUTION_VIDEO.default_width;
        canvas.height = TELEWORK.REVOLUTION_VIDEO.default_height;
      }
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      const base64 = canvas.toDataURL();
      return registration.active.postMessage({
        action: TELEWORK.NAME,
        agentId,
        projectId,
        token: localStorage.getItem('user-token'),
        cplusToken: cplusToken,
        desktopCaptureImageBase64: base64,
        apiSysEndPoint: process.env.VUE_APP_USER_SERVICE_ENDPOINT
      });
    }, parseInt(frequency) * 60000);
    mediaStream.getVideoTracks()[0].addEventListener('ended', () => clearInterval(interval));
  });
};

const getUserMedia = async () => {
  try {
    let constraints = { video: { width: 9999 } };
    const video = document.createElement('video');
    video.autoplay = true;
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    if ('srcObject' in video) {
      video.srcObject = stream;
    } else {
      // Avoid using this in new browsers, as it is going away.
      video.src = URL.createObjectURL(stream);
    }
    await new Promise(resolve => (video.onloadedmetadata = resolve));
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    if (
      video.videoWidth > TELEWORK.REVOLUTION_VIDEO.default_width &&
      video.videoHeight > TELEWORK.REVOLUTION_VIDEO.default_height
    ) {
      canvas.width = TELEWORK.REVOLUTION_VIDEO.default_width;
      canvas.height = TELEWORK.REVOLUTION_VIDEO.default_height;
    }
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  } catch (error) {
    console.error('getUserMedia error', error);
  }
};

export const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission();
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  if (permission !== 'granted') {
    // eslint-disable-next-line
    console.log('Permission not granted for Notification');
  }
  return permission;
};

export const buildTemplate = async excelData => {
  const workbook = new excel.Workbook();
  excelData.forEach(sheet => {
    // Config styles
    const _options = {
      sheetFormat: { defaultColWidth: 16 }
    };
    const font = { color: '#222222', size: 11, bold: true };
    const alignment = { horizontal: 'center', vertical: 'center' };
    const border = {
      left: { style: 'thin', color: '#222222' },
      right: { style: 'thin', color: '#222222' },
      top: { style: 'thin', color: '#222222' },
      bottom: { style: 'thin', color: '#222222' },
      outline: true
    };
    const fill = {
      type: 'pattern',
      patternType: 'gray125',
      bgColor: '#CCCCCC',
      fgColor: '#CCCCCC'
    };
    const styleTitle = workbook.createStyle({ font: { ...font, size: 18 } });
    const styleSubTitle = workbook.createStyle({ font });
    const styleDataSubTitle = workbook.createStyle({
      font: { ...font, bold: false },
      alignment: { horizontal: 'right' }
    });
    const styleColTitle = workbook.createStyle({
      font,
      border,
      alignment: { ...alignment, wrapText: true },
      fill
    });
    const styleData = workbook.createStyle({
      font: { ...font, bold: false },
      alignment: { readingOrder: 'contextDependent' },
      border
    });

    // Config datas
    const ws = workbook.addWorksheet(sheet.sheetName, _options);
    const isConfigs =
      sheet.configs &&
      sheet.configs.sheetTitle != '' &&
      sheet.configs.subTitle.length &&
      sheet.configs.dataSubTitle.length &&
      sheet.configs.indexRowTitle != '' &&
      sheet.configs.indexRowSubTitle != '';

    if (isConfigs) {
      const row = sheet.configs.indexRowTitle;
      ws.cell(row, 1, row, 2, true)
        .string(sheet.configs.sheetTitle)
        .style(styleTitle);
      ws.row(sheet.configs.indexRowData).setHeight(50);
      ws.column(2).setWidth(30);

      let subTitleIndex = sheet.configs.indexRowSubTitle;
      for (let indexSubTitle = 0; indexSubTitle < sheet.configs.subTitle.length; indexSubTitle++) {
        ws.cell(subTitleIndex + indexSubTitle, 1)
          .string(sheet.configs.subTitle[indexSubTitle])
          .style(styleSubTitle);
      }

      for (
        let indexDataSubTitle = 0;
        indexDataSubTitle < sheet.configs.dataSubTitle.length;
        indexDataSubTitle++
      ) {
        let row = subTitleIndex + indexDataSubTitle;
        let data = sheet.configs.dataSubTitle[indexDataSubTitle];
        const isWH = typeof data === 'number' ? true : false;
        if (moment(data, 'YYYY-MM-DD', true).isValid()) {
          ws.cell(row, 2)
            .date(data)
            .style(styleDataSubTitle)
            .style({ font: { bold: true }, numberFormat: 'YYYY-MM-DD' });
        } else if (isWH) {
          ws.cell(row, 2)
            .string(`${data} hours`)
            .style(styleDataSubTitle)
            .style({ font: { bold: true } });
        } else {
          ws.cell(row, 2)
            .string(data)
            .style(styleDataSubTitle);
        }
      }
    }

    let excelRowIndex =
      sheet.configs && sheet.configs.indexRowData ? sheet.configs.indexRowData : 1;
    for (let indexTitle = 0; indexTitle < sheet.title.length; indexTitle++) {
      ws.cell(excelRowIndex, indexTitle + 1)
        .string(sheet.title[indexTitle])
        .style(isConfigs ? styleColTitle : {});
    }

    excelRowIndex += 1;
    for (let indexData = 0; indexData < sheet.data.length; indexData++) {
      let rowData = sheet.data[indexData];
      for (let indexRowData = 0; indexRowData < rowData.length; indexRowData++) {
        let row = excelRowIndex + indexData;
        let col = indexRowData + 1;
        let data = rowData[indexRowData];
        let cellValue = '';
        if ([undefined, null].includes(data)) {
          cellValue = '';
        } else if (typeof data === 'object') {
          cellValue = JSON.stringify(data);
        } else {
          cellValue = data;
        }
        if (typeof cellValue === 'number' && !isNaN(cellValue)) {
          ws.cell(row, col)
            .number(cellValue)
            .style(isConfigs ? styleData : {});
        } else {
          ws.cell(row, col)
            .string(cellValue)
            .style(isConfigs ? styleData : {});
        }
      }
    }
  });
  return workbook.writeToBuffer().then(function (buffer) {
    return buffer;
  });
};

export const fetchDataAndGotoTicket = async ({ conversationId, id }) => {
  console.log('Start ~ fetchDataAndGotoTicket ', { conversationId, id });
  const { state = {}, dispatch } = store;
  const { session: { user = {} } = {} } = state;
  try {
    dispatch('chat/setShowView', 'Chat');
    if (conversationId && id) {
      const hasConversation = await dispatch('chat/getConversationTicketById', {
        conversationId,
        ticketId: id
      }).then(conversation => {
        if (!conversation) {
          console.log('FetchDataAndGotoTicket ~ conversation', conversation);
          baseNotification.error({
            title: i18n.t('src.core.App.error'),
            message: i18n.t('src.modules.chat.index.data_of_the_conversation_was_removed')
          });
          return false;
        }

        const { error } = conversation;
        if (error) {
          dispatch('chat/setSelectedGroupConversation', SERVING_STATE.WAITING);
          baseNotification.warning({
            title: i18n.t('src.core.App.warning'),
            message: error.message
          });
          router.push('/');
          return false;
        }
        return conversation;
      });

      if (!hasConversation) return false;

      const { ticket, channelId } = hasConversation || {};
      const { projectId } = ticket || {};
      const tabIndex = getTabIndex({ conv: { ticket }, user });
      const status = tabIndex == 4 ? 5 : tabIndex;

      await dispatch('session/addNewConversation', hasConversation);
      await dispatch('chat/setSelectedConversation', {
        conversation: hasConversation,
        groupId: convertTabIndexToGroupNav(tabIndex),
        eventType: 'url'
      });

      dispatch('session/setChannelTabReload', {
        channelStatus: channelId + status,
        flag: true
      });

      if (tabIndex === TABS.WAITING_ME) {
        dispatch('session/setWaitingMode', 'TOME');
      }

      if (tabIndex === TABS.WAITING_COMMON) {
        dispatch('session/setWaitingMode', 'COMMON');
      }

      await dispatch('chat/getMessagesByConversationId', {
        id: conversationId,
        projectId
      }).catch(error => {
        console.log('TCL: FetchDataAndGotoTicket -> getMessages -> error', error);
      });

      const path = `/c/${conversationId}/${id}`;
      history.pushState(null, null, path);

      EventBus.$emit('scrollBottomMessage');
    }
  } catch (e) {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t('src.core.App.system_could_not_find_the_selected_conversation')
    });

    console.log('FetchDataAndGotoTicket e: ', e);
    router.push('/');
    return false;
  }

  return true;
};

export const isMatchSearchCondition = (
  conditions,
  { ticket, userInfo, tags = [], ticketsTags = [], agent = {} }
) => {
  if (isEmpty(conditions) || isEmpty(ticket)) return false;

  const { follow, state, status, tagName, userName, agentName, userLocal } = conditions;

  if (!isEmpty(follow) && follow[0] && !ticket.feature) {
    return false;
  }

  if (!isEmpty(state) && state[0] && ticket.state !== state[0]) {
    return false;
  }

  if (!isEmpty(status) && status[0] && ticket.status !== status[0]) {
    return false;
  }

  if (tagName && tagName.length > 0) {
    if (isEmpty(tags) || isEmpty(ticketsTags)) return false;

    const filterTags = tags.filter(tag => ticketsTags.find(tkTag => tkTag.tagId === tag.id));
    const found = tagName.some(objTagName => filterTags.some(v => v.value === objTagName));

    // Return false when none of search tags match
    if (!found) return false;
  }

  if (
    !isEmpty(userLocal) &&
    !isEmpty(userInfo) &&
    userLocal.every(key => userInfo.locale !== key)
  ) {
    return false;
  }

  if (
    !isEmpty(userName) &&
    !isEmpty(userInfo) &&
    userInfo.name &&
    userName.every(key => {
      const lowKey = key.toLowerCase();
      const userName = userInfo.name.toLowerCase();
      return userName.indexOf(lowKey) < 0;
    })
  ) {
    return false;
  }

  if (!isEmpty(agentName)) {
    if (isEmpty(agent)) return false;

    const lowAgentKey = agentName[0].toLowerCase();
    const lowAgentName = `${agent.firstName} ${agent.lastName}`.toLowerCase();

    if (lowAgentName.indexOf(lowAgentKey) < 0) {
      return false;
    }
  }

  return true;
};

export const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export const isContainingSystemDefinedWord = label => {
  const { state = {} } = store;
  const { chat: { systemDefinedWord = [] } = {} } = state;

  const words = systemDefinedWord.reduce((acc, i) => [...acc, ...i.words], []);
  if (words.some(w => label.toLowerCase().includes(w.toLowerCase()))) {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t(
        'src.modules.chat.components.ChatBox.chat-header.failed_to_add_the_label_because_it_s_containing_the_system_defined_word',
        { systemDefinedWord: words.join(', ') }
      )
    });
    return true;
  }
  return false;
};

export const isDisplayUI = (configs, element, hasGroup = null) => {
  const { state = {} } = store;
  const { session: { user: { role } = {} } = {} } = state;
  if (!configs[element]) return false;
  if (hasGroup === null) return configs[element].includes(role);

  const { projectWithGroup = [], projectWithoutGroup = [] } = configs[element];
  return hasGroup ? projectWithGroup.includes(role) : projectWithoutGroup.includes(role);
};

export const convertTreeData = (data, parentId) => {
  const parent = [];
  data.map(item => {
    let child = new Object();
    child.data = {};
    child.text = item.title;
    child.state = {
      expanded: false
    };

    child.data.id = parentId ? `${parentId}::${item.id}` : item.id;
    if (item.children && item.children.length) {
      child.children = convertTreeData(item.children, child.data.id);
    } else {
      child.id = item.id;
      child.data.activeNode = true;
    }
    parent.push(child);
  });
  return parent;
};
