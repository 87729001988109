import {
  SET_PERSONAL_INFO,
  SET_PERSONAL_GLOBAL,
  SET_PERSONAL_INFO_FB,
  SET_CHANNEL_NAME,
  SET_PERSONAL_SETTING
} from './types';
import * as getters from './getters';
import * as actions from './actions';
import { GENDER, MARITAL_STATUS, PERSONAL_OPTIONS } from 'core/constants';

const state = {
  personalInfo: {},
  userInfo: {},
  displayConfig: {},
  requiredAgent: {},
  personalConfig: {},
  personalGlobal: {},
  personalCustomize: {},
  personalCustomizeDefault: {},
  translations: {},
  translationsDefault: {},
  defaultLanguage: '',
  supportLanguages: [],
  personalFormOption: {},
  personalInfoActive: false
};

const mutations = {
  [SET_PERSONAL_INFO](state, users) {
    const { customizeInfo, maritalStatus } = { ...users } || {};
    delete users[customizeInfo];
    const { personalInfo } = state;

    if (maritalStatus?.key === 'S') {
      users.maritalStatus = MARITAL_STATUS.single;
    }

    users.userPlatformName = users.userPlatformName || users.name;
    if (personalInfo.userId === users.userId) {
      state.personalInfo = { ...personalInfo, ...users, ...customizeInfo };
    } else {
      state.personalInfo = {
        ...users,
        ...customizeInfo
      };
    }
    state.personalGlobal = {
      ...{ country: state.personalInfo.country },
      ...{ state: state.personalInfo.state },
      ...{ province: state.personalInfo.province }
    };
  },
  [SET_PERSONAL_INFO_FB](state, users) {
    state.personalInfo = {
      ...users,
      userPlatformName: users.userPlatformName || users.name,
      firstName: users.firstName || users.fullName || '',
      lastName: users.lastName || users.firstName || users.fullName || ''
    };
    state.personalGlobal = {
      ...{ country: state.personalInfo.country },
      ...{ state: state.personalInfo.state },
      ...{ province: state.personalInfo.province }
    };
  },
  [SET_PERSONAL_SETTING](state, data) {
    const {
      displayAgent,
      requiredAgent,
      customizeFields,
      person,
      translations: tlts,
      defaultLanguage,
      userInfo,
      supportLanguages,
      personalInfo
    } = data;
    state.displayConfig = { ...displayAgent };
    state.requiredAgent = { ...requiredAgent };
    state.defaultLanguage = defaultLanguage;
    state.personalConfig = { ...person };
    state.userInfo = { ...userInfo };
    state.supportLanguages = supportLanguages;
    state.personalInfoActive = personalInfo;
    const { locale } = userInfo;

    const lang = localStorage.getItem('language');
    const operationLanguage = lang.replace('-', '_');
    const displayLanguage = supportLanguages.includes(operationLanguage)
      ? operationLanguage
      : defaultLanguage;
    state.personalFormOption = {
      ...state.personalFormOption,
      [GENDER.male.key]: PERSONAL_OPTIONS[displayLanguage][GENDER.male.key],
      [GENDER.female.key]: PERSONAL_OPTIONS[displayLanguage][GENDER.female.key],
      [MARITAL_STATUS.divorced.key]: PERSONAL_OPTIONS[displayLanguage][MARITAL_STATUS.divorced.key],
      [MARITAL_STATUS.married.key]: PERSONAL_OPTIONS[displayLanguage][MARITAL_STATUS.married.key],
      [MARITAL_STATUS.separated.key]:
        PERSONAL_OPTIONS[displayLanguage][MARITAL_STATUS.separated.key],
      [MARITAL_STATUS.single.key]: PERSONAL_OPTIONS[displayLanguage][MARITAL_STATUS.single.key],
      [MARITAL_STATUS.widowed.key]: PERSONAL_OPTIONS[displayLanguage][MARITAL_STATUS.widowed.key]
    };

    if (tlts) {
      const tlt = Object.keys(tlts).reduce(function(result, key) {
        if (typeof tlts[key] === 'object' && tlts[key][locale]) {
          result[key] = tlts[key][locale];
          return result;
        } else {
          result[key] = tlts[key][defaultLanguage];
          return result;
        }
      }, {});
      const tlt_default = Object.keys(tlts).reduce(function(result, key) {
        result[key] = tlts[key][displayLanguage];
        return result;
      }, {});
      state.translations = tlt;
      state.translationsDefault = tlt_default;
    }

    if (state.translations.personalHeader)
      state.personalConfig.header = state.translations.personalHeader;
    else {
      const tltsHeader = state.personalConfig.title;
      state.personalConfig.header = tltsHeader[defaultLanguage];
    }

    if (customizeFields) {
      const customizePersonalComponent = Object.keys(customizeFields).reduce((acc = {}, key) => {
        if (customizeFields[key].display && customizeFields[key].display.agent) {
          customizeFields[key].isCustomize = true;
          acc[customizeFields[key].prop] = customizeFields[key];
          customizeFields[key].show = true;
          customizeFields[key].required = customizeFields[key].required.agent;
        }
        return acc;
      }, {});
      state.personalCustomize = { ...customizePersonalComponent };
      state.personalCustomizeDefault = { ...customizePersonalComponent };
      if (Object.keys(state.personalCustomize).length > 0) {
        Object.keys(state.personalCustomize).forEach(cKey => {
          const pcTranslations = { ...state.personalCustomize[cKey].label };
          let val = cKey;
          let val_default = cKey;
          if (typeof pcTranslations === 'object' && pcTranslations[locale]) {
            val = pcTranslations[locale];
          } else {
            if (defaultLanguage) val = pcTranslations[defaultLanguage];
          }
          if (displayLanguage) val_default = pcTranslations[displayLanguage];
          state.personalCustomizeDefault[cKey] = {
            ...state.personalCustomizeDefault[cKey],
            label: val_default
          };
          state.personalCustomize[cKey] = {
            ...state.personalCustomize[cKey],
            label: val
          };
        });
      }
    }
  },
  [SET_PERSONAL_GLOBAL](state, { type, value, formData }) {
    if (type === 'country') {
      if (formData)
        state.personalInfo = {
          ...state.personalInfo,
          ...formData
        };
      state.personalInfo = {
        ...state.personalInfo,
        ...{ country: value }
      };
    }
    const temp = { ...state.personalGlobal };
    state.personalGlobal = { ...temp, ...{ [type]: value } };
  },

  [SET_CHANNEL_NAME](state, channelName) {
    state.channelName = channelName;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
