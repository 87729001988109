import {
  CHANNELS_FILTER_MAP,
  GET_CONVERSATION_LOG_REPORT,
  GET_MESSAGE_LOG_REPORT,
  GET_COMMENT_REPORT,
  MESSAGE_LOG_SCHEDULES,
  MESSAGE_LOG_SCHEDULE_FILES
} from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
  projects: {},
  channels: {},
  platforms: {},
  conversationLogReportFile: {},
  messageLogReportData: [],
  channelsFilterMap: {},
  commentReportData: [],
  messageLogSchedules: [],
  messageLogScheduledFiles: []
};

const mutations = {
  [CHANNELS_FILTER_MAP](state, data) {
    const channels = data || [];
    state.channelsFilterMap = channels.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [GET_CONVERSATION_LOG_REPORT](state, file) {
    state.conversationLogReportFile = file;
  },

  [GET_MESSAGE_LOG_REPORT](state, reportData) {
    state.messageLogReportData = reportData;
  },

  [GET_COMMENT_REPORT](state, reportData) {
    state.commentReportData = reportData;
  },

  [MESSAGE_LOG_SCHEDULES](state, messageLogSchedules) {
    state.messageLogSchedules = messageLogSchedules;
  },

  [MESSAGE_LOG_SCHEDULE_FILES](state, messageLogScheduledFiles) {
    state.messageLogScheduledFiles = messageLogScheduledFiles;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
