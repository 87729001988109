import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import axios from 'axios';
import ElementUI from 'element-ui';
import BootstrapVue from 'bootstrap-vue';
import Vuebar from 'vuebar';
import Sortable from 'vue-sortable';
import Notifications from 'vue-notification';
import VueElementLoading from 'vue-element-loading';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueDragDrop from 'vue-drag-drop';
import VueCarousel from 'vue-carousel';
import VueImg from 'v-img';
import UUID from 'vue-uuid';
import VueClipboard from 'vue-clipboard2';

import router from 'core/router';
import Filters from 'core/filters';
import store from 'core/store';
import App from 'core/App';
import updateData from 'core/mixins/updateData';
import { i18n, loadLanguageAsync } from 'locales';
import FlagIcon from 'vue-flag-icon';
import { createProvider } from 'core/vue-apollo';
import VueGAPI from 'vue-gapi';
import VueVideoPlayer from 'vue-video-player';
import timeago from 'core/timeago';
import { AppImg } from 'components';
import VueFBSDK from 'core/fb-sdk';
import LiquorTree from 'liquor-tree';
import datePicker from 'vuejs-datepicker';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as VeeValidate from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
VeeValidate.setInteractionMode('custom', () => ({ on: ['blur'] }));

// No message specified.
extend('required', required);
extend('email', email);

import vSelect from 'vue-select';
import VueScrollTo from 'vue-scrollto';
import { loadFbSdk } from './components/FacebookSignin/helpers.js';
import locale from 'element-ui/lib/locale/lang/en';
import JsonExcel from 'vue-json-excel';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'blueimp-canvas-to-blob/js/canvas-to-blob.min.js';
import AsyncComputed from 'vue-async-computed';

const textLint = window.Textlint;
Vue.prototype.textLint = new textLint();

Vue.config.productionTip = false;

const requireComponentVue = require.context(
  'components/BaseComponents',
  false,
  /Base[A-Z]\w+\.(vue)$/
);
const requireComponentProp = require.context(
  'components/BaseComponents',
  false,
  /Base[A-Z]\w+\.(js)$/
);

requireComponentVue.keys().forEach(fileName => {
  const componentConfig = requireComponentVue(fileName);
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));
  Vue.component(componentName, componentConfig.default || componentConfig);
});
requireComponentProp.keys().forEach(fileName => {
  const componentConfig = requireComponentProp(fileName);
  const componentName = camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'));
  Object.defineProperty(Vue.prototype, '$' + componentName, {
    value: componentConfig.default || componentConfig
  });
});

// Enable dev tools
if (process.env.VUE_APP_DEVTOOLS_ENABLED) {
  Vue.config.devtools = true;
}

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDjvM5vfXm_ookQiONd1T2ZdH0TpoFTgOo',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  autobindAllEvents: true,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true
});

Vue.use(UUID);
const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: true
};
Vue.use(VueClipboard);
Vue.use(VueImg, vueImgConfig);
Vue.use(VeeValidate);
Vue.use(datePicker);
Vue.use(Filters);
Vue.use(BootstrapVue);
Vue.use(ElementUI, { locale });

Vue.use(Vuebar);
Vue.mixin(updateData);
Vue.use(Sortable);
Vue.use(Notifications);
Vue.use(FlagIcon);
Vue.component('VueElementLoading', VueElementLoading);
Vue.use(VueGAPI);
Vue.use(VueAwesomeSwiper);
Vue.use(VueGAPI, {});
Vue.use(
  VueVideoPlayer
  /* {
  options: global default options,
  events: global videojs events
} */
);
Vue.use(VueDragDrop);
Vue.component('app-img', AppImg);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueFBSDK, {
  appId: process.env.VUE_APP_FACEBOOK_APP_ID,
  autoLogAppEvents: true,
  xfbml: true,
  version: 'v2.5'
});
Vue.use(VueCarousel);
Vue.use(LiquorTree);
Vue.component(LiquorTree.name, LiquorTree);
Vue.component('v-select', vSelect);
Vue.use(VueScrollTo);
Vue.component('download-excel', JsonExcel);
Vue.use(VueVirtualScroller);
Vue.use(AsyncComputed);

timeago.install();
// Import css
import 'swiper/dist/css/swiper.css';
import 'video.js/dist/video-js.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

axios.defaults.headers.post['Content-Type'] = 'application/json';

// loadLanguageAsync
router.beforeEach((to, from, next) => {
  let lang = '';
  if (to.params.lang) {
    lang = to.params.lang;
  } else if (localStorage.getItem('language')) {
    lang = localStorage.getItem('language');
  }

  if (lang === '') {
    //https://www.metamodpro.com/browser-language-codes
    const navlang = navigator.language || navigator.userLanguage;
    const browserLanguagesMapping = [
      { key: 'ja', value: 'ja-JP' },
      { key: 'en-us', value: 'en-US' },
      { key: 'zh-tw', value: 'zh-TW' }
    ];
    const navLanguage = browserLanguagesMapping.filter(item => {
      return item.key.toLowerCase() == navlang.toLowerCase();
    });
    if (navLanguage.length > 0) lang = navLanguage[0].value;
    else lang = 'en-US';
  }

  localStorage.setItem('language', lang);
  loadLanguageAsync(lang).then(() => next());
});

// Sync login and logout on multi tab
window.loggedInUserEmail = localStorage.getItem('user-email');
// eslint-disable-next-line
window.addEventListener('storage', () => {
  // eslint-disable-next-line
  const email = localStorage.getItem('user-email');
  if (!window.location.pathname.startsWith('/personal-info') && email != window.loggedInUserEmail) {
    if (!email) {
      location.replace('/login');
    } else {
      window.loggedInUserEmail = email;
      window.location.reload(true);
    }
  }
});

if (!window.FB) {
  loadFbSdk(process.env.VUE_APP_FACEBOOK_APP_ID, 'v3.0');
}

// eslint-disable-next-line
Vue.prototype.$log = console.log.bind(console);

new Vue({
  // eslint-disable-line no-new
  el: '#app',

  components: {
    App
  },

  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h('app')
});
