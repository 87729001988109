<template>
  <b-modal
    id="detailed-info-modal"
    ref="detailedInfoModal"
    v-model="show"
    size="lg"
    :title="`${$t('src.modules.access-log.index.log_details')} (${jsonData.accessLogId})`"
    @hidden="closeModal"
  >
    <json-viewer
      :value="jsonData"
      :expand-depth="5"
      expanded
      copyable
      boxed
      sort>
    </json-viewer>
    <div slot="modal-footer" class="w-100">
      <b-btn
        class="float-right"
        variant="default"
        @click="closeModal"
      >
        {{ $t('common.confirmModal.close') }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from 'core/eventBus';
import JsonViewer from 'vue-json-viewer'

function defaultState() {
  return {
    show: false,
    jsonData: {}
  };
}

export default {
  name: 'DetailedInfo',
  components: {
    JsonViewer
  },

  data: defaultState,

  created() {
    EventBus.$on('detailedInfoModal', ({ bool = false, data }) => {
      this.jsonData = data;
      this.handlerOpenPopup(bool)
    });
  },

  destroyed() {
    EventBus.$off('detailedInfoModal', (bool = false) => this.handlerOpenPopup(bool));
  },

  methods: {
    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handlerOpenPopup(bool) {
      this.show = bool;
      if (!bool) {
        this.resetAllState();
        return;
      }
    },

    closeModal() {
      this.resetAllState();
      this.show = false;
    }
  }
};
</script>
<style lang="scss">
</style>
