<template>
  <div class="chat">
    <div class="chat-body chat-left">
      <div
        v-if="
          !['text', 'template_button', 'template_generic', 'template_list', 'quick_reply'].includes(
            scnr.typeClass
          )
        "
        class="read-only"
      ></div>
      <!-- Message Type (S) -->
      <div class="text-uppercase d-block m-b-10 m-t-20 pb-2 font-weight-bold">
        <span>
          {{ messageType }}
        </span>
      </div>

      <!-- Message Type (E)  -->

      <!-- Message Content (S) -->
      <div :class="['chat-content', 'shadow-sm', msgClass(scnr)]">
        <!-- Text Message (S) -->
        <div class="text-style">
          <base-textarea
            v-if="scnr.typeClass === 'text'"
            :show-label="false"
            :rows="5"
            :maxlength="2000"
            :label="$t('src.core.constants.scenarioTemplateTypeName.text_message')"
            v-model="textMessage"
            :show-word-limit="true"
            @change="handleOnChangeBaseTextarea"
            :tribute-options="tributeOptions"
            :tribute-keys="tributeKeys"
            :vue-tribute="true"
            :required="true"
          />
        </div>
        <!-- Text Message (E) -->

        <!--  Image (S) -->
        <div
          v-if="scnr.typeClass === 'image' && scnr.content && _isImagesSupported(scnr.content)"
          class="message-image"
        >
          <img v-img :src="scnr.content" :style="{ 'max-width': '300px' }" />
        </div>
        <div v-if="scnr.typeClass === 'sticker'" class="message-image">
          <img v-img :src="scnr.content" :style="{ 'max-width': '300px' }" />
        </div>
        <msg-image-map v-if="scnr.typeClass === 'template_imagemap'" :message="scnr.content" />
        <msg-image-multiple
          v-if="scnr.typeClass === 'template_image_multiple'"
          :message="scnr.content"
        />
        <!--  Image (E) -->

        <!--  Video (S) -->
        <msg-video
          v-if="scnr.typeClass === 'video'"
          :video-url="scnr.content"
          :platform="scnr.platform"
        />
        <!--  Video (E) -->

        <!-- Audio (S) -->
        <msg-audio v-if="scnr.typeClass === 'audio'" :audio-url="scnr.content" />

        <!-- Audio (E) -->

        <!-- File (S) -->
        <msg-file
          v-if="
            ['file'].includes(scnr.typeClass) ||
            (scnr.typeClass === 'image' &&
              scnr.content &&
              typeof scnr.content === 'string' &&
              !_isImagesSupported(scnr.content))
          "
          :message="scnr"
        />
        <!-- File (E) -->

        <!-- Quick Reply (S) -->
        <msg-quickrep
          v-if="
            scnr.typeClass === 'quick_reply' && scnr.content.type === 'USER_REQUEST_AGENT_CONFIRM'
          "
          :text="scnr.text"
          :message="scnr.content"
          :platform="scnr.platform"
          :selected-conversation="conversation"
        />
        <msg-quickrep
          v-if="
            scnr.typeClass === 'quick_reply' && scnr.content.type !== 'USER_REQUEST_AGENT_CONFIRM'
          "
          :text="scnr.text"
          :message="scnr.content"
          :platform="scnr.platform"
          :selected-conversation="conversation"
        />
        <!-- Quick Reply (E) -->

        <!-- Button List (S)-->
        <fb-template-button v-if="scnr.typeClass === 'template_button'" :message="scnr.content" />
        <!-- Button List (E)-->

        <!-- Carousel (S) -->
        <fb-template-product v-if="scnr.typeClass === 'template_generic'" :message="scnr.content" />
        <!-- Carousel (E) -->

        <!-- List (S) -->
        <msg-list
          v-if="scnr.typeClass === 'template_list'"
          :message="scnr.content"
          :channel-platform="channelPlatform"
        />
        <!-- List (E) -->

        <!-- Location (S)-->
        <div v-if="scnr.typeClass === 'location'" class="location-style">
          <label>{{ $t('src.core.constants.scenarioTemplateTypeName.location.URL') }}</label>
          <base-textarea
            :show-label="false"
            :rows="5"
            v-model="scnr.content"
            :show-word-limit="false"
          />
        </div>
        <!-- Location (E)-->
      </div>
      <!-- Message Content (E) -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { IMAGE_EXTENSIONS_SUPPORTED, SCENARIO_TEMPLATE_TYPE_NAME } from 'core/constants';

import { TemplateButton as FbTemplateButton } from 'components/Message/facebook';
import { TemplateProduct as FbTemplateProduct } from 'components/Message/facebook';
import MsgAudio from '../../ChatBox/MessageList/msg-audio';
import MsgVideo from '../../ChatBox/MessageList/msg-video';
import MsgFile from '../../ChatBox/MessageList/msg-file';
import MsgQuickrep from '../../ChatBox/MessageList/msg-quickrep';
import MsgList from '../../ChatBox/MessageList/msg-list';
import MsgImageMap from '../../ChatBox/MessageList/msg-image-map';
import MsgImageMultiple from '../../ChatBox/MessageList/msg-image-multiple';
import Params from 'modules/chat/helpers/params';
import { orderBy } from 'lodash';

const MSG_TYPE_CLASSES = {
  text: ['w-95-p'],
  image: ['fresh-bg', 'has-img'],
  sticker: ['fresh-bg', 'has-img'],
  template: ['fresh-bg', 'w-70-p'],
  template_button: ['fresh-bg', 'w-40-p'],
  template_generic: ['fresh-bg', 'w-85-p'],
  template_generic_1: ['fresh-bg', 'w-300-px'],
  template_generic_2: ['fresh-bg', 'max-w-480-px'],
  template_generic_3: ['fresh-bg', 'w-75-p'],
  template_image_multiple: ['fresh-bg', 'max-w-420-px'],
  quick_reply: ['fresh-bg', 'max-w-85-p'],
  quick_reply_simple: [],
  file: [],
  audio: [],
  video: ['fresh-bg', 'w-70-p'],
  fallback: [],
  location: [],
  quickreply: [],
  postback: [],
  referral: [],
  template_list_1: ['fresh-bg'],
  location_style: ['location-style']
};

export default {
  components: {
    FbTemplateButton,
    FbTemplateProduct,
    MsgAudio,
    MsgVideo,
    MsgFile,
    MsgQuickrep,
    MsgList,
    MsgImageMap,
    MsgImageMultiple
  },

  props: {
    id: {
      type: Number,
      default: 0
    },
    scenario: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      textMessage: '',
      conversation: this.selectedConversation,
      tributeOptions: {
        values: [],
        selectTemplate: item => this.selectTemplate(item)
      },
      tributeKeys: 0
    };
  },

  computed: {
    ...mapState('session', ['people', 'channels', 'user', 'channelsMap']),
    ...mapGetters('session', ['me']),
    ...mapGetters('chat', ['selectedConversation']),

    messageType() {
      switch (this.scnr.typeClass) {
        case 'text':
          return SCENARIO_TEMPLATE_TYPE_NAME.TEXT_MESSAGE;

        case 'image':
          if (this.isImagesSupported(this.scnr.content)) return SCENARIO_TEMPLATE_TYPE_NAME.IMAGE;
          else return SCENARIO_TEMPLATE_TYPE_NAME.FILE;
        case 'template_imagemap':
          return SCENARIO_TEMPLATE_TYPE_NAME.IMAGE_MAP;
        case 'template_image_multiple':
          return SCENARIO_TEMPLATE_TYPE_NAME.IMAGE;
        case 'video':
          return SCENARIO_TEMPLATE_TYPE_NAME.VIDEO;
        case 'audio':
          return SCENARIO_TEMPLATE_TYPE_NAME.AUDIO;
        case 'file':
          return SCENARIO_TEMPLATE_TYPE_NAME.FILE;
        case 'quick_reply':
          return SCENARIO_TEMPLATE_TYPE_NAME.QUICK_REPLY;
        case 'template_button':
          return SCENARIO_TEMPLATE_TYPE_NAME.BUTTON_LIST;
        case 'template_generic':
          return SCENARIO_TEMPLATE_TYPE_NAME.CAROUSEL;
        case 'template_list':
          return SCENARIO_TEMPLATE_TYPE_NAME.LIST;
        case 'location':
          return SCENARIO_TEMPLATE_TYPE_NAME.LOCATION;
        case 'sticker':
          return SCENARIO_TEMPLATE_TYPE_NAME.STICKER;
        default:
          break;
      }

      return this.scnr.typeClass;
    },

    scnr() {
      if (!this.scenario.content) return null;
      const isTemplate = this.scenario.type === 'template';
      const content =
        isTemplate || this.scenario.type === 'quick_reply'
          ? this.scenario.content
          : this.scenario.content;
      const cusMsg = {
        content,
        typeClass: isTemplate ? `template_${content.template_type}` : this.scenario.type
      };
      return cusMsg;
    },

    channelPlatform() {
      let channel = this.channels.find(
        channel => channel.id == this.selectedConversation.channelId
      );
      return channel && channel.platform;
    }
  },

  watch: {},

  created() {
    this.tributeConfig();
    if (typeof this.scenario.content === 'string') {
      this.textMessage = this.convertKeys2Values(this.scenario.content);
    } else {
      this.textMessage = this.scenario.content;
    }
  },

  methods: {
    handleOnChangeBaseTextarea(value) {
      this.$emit('onChangeData', { id: this.id, text: value });
    },

    msgClass({ typeClass, content, text }) {
      let msgType = '';
      if (['template_generic'].includes(typeClass)) {
        const items = content ? content.buttons || content.elements || [] : [];
        const itmCount = items.length;
        msgType = itmCount;
        if (itmCount === 1) {
          msgType = `${typeClass}_1`;
        } else if (itmCount === 2) {
          msgType = `${typeClass}_2`;
        } else if (itmCount === 3) {
          msgType = `${typeClass}_3`;
        } else {
          msgType = typeClass;
        }
      } else if (
        msgType === 'quick_reply' &&
        (content.type === 'REQ_AGNT_FEEDBACK' || content.type === 'USER_REQUEST_AGENT_CONFIRM')
      ) {
        msgType = 'quick_reply_simple';
      } else if (typeClass === 'location') {
        msgType = `${typeClass}_style`;
      } else {
        msgType = typeClass;
      }

      let clz = MSG_TYPE_CLASSES[msgType] || [];
      if (text && text.toLowerCase().endsWith('.tiff')) {
        clz = MSG_TYPE_CLASSES['file'];
      }
      return clz.join(' ');
    },

    _isImagesSupported(url) {
      let urlDecoded = decodeURIComponent(url);
      let arrayPath = decodeURIComponent(urlDecoded).split('?') || [];
      let imagePath = arrayPath[0] || '';
      let imageFileName = imagePath.split('/').pop();
      const isSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
        imageFileName.toLowerCase().endsWith(ex)
      );
      return isSupported;
    },

    convertKeys2Values(message) {
      const invalidParams = [];
      const params = new Params();
      message = params.convertUserKeys2Values(message, invalidParams);
      if (invalidParams.length) {
        const altMsg = this.$t(
          'src.modules.chat.components.ChatBox.message-input.params_can_not_convert',
          {
            list_params: invalidParams.join(', ')
          }
        );
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: altMsg
        });
      }
      return message;
    },

    selectTemplate(item) {
      const { original } = item;
      const { value } = original;
      return new Params().getValueFromKey(value);
    },

    tributeConfig() {
      this.tributeOptions.values = [];
      let displayAgentHasTrue = this.getAgentParamsChannelId();
      let displayAgentKeys = Object.keys(displayAgentHasTrue).map(item => {
        return {
          value: item,
          key: '{' + item + '}'
        };
      });

      // Use Lodash to sort array by 'key'
      let displayAgentKeysSorted = orderBy(displayAgentKeys, ['key'], ['asc']);
      displayAgentKeysSorted.map(item => {
        this.tributeOptions.values.push(item);
        this.tributeKeys += 1;
      });
    },

    getAgentParamsChannelId() {
      return new Params().getAgentParams();
    }
  }
};
</script>

<style lang="scss" scoped>
.read-only {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.input-sizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  border: solid 1px;
  padding: 0.25em 0.5em;
  margin: 5px;

  &.stacked {
    padding: 0.5em;
    align-items: stretch;

    &::after,
    input,
    textarea {
      grid-area: 2 / 1;
    }
  }

  &::after,
  input,
  textarea {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0.25em;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }

  span {
    padding: 0.25em;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  &:focus-within {
    outline: solid 1px blue;
    box-shadow: 4px 4px 0px blue;

    > span {
      color: blue;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }
}

.input-sizer {
  box-shadow: 4px 4px 0px #000;
  > span {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
  }
}

.chat {
  .chat-body {
    position: relative;
    .chat-name {
      font-size: 11px;
      float: left;
      margin-left: 20px;
    }
  }

  &.chat-left {
    .has-img {
      text-align: left;
    }
  }
  &.chat-center {
    position: relative;

    .has-img {
      text-align: center;
    }
  }
  .chat-avatar {
    .avatar {
      width: 48px !important;
      margin-top: 0px !important;
    }
  }

  .chat-content {
    text-align: left;

    &.no-bg {
      background-color: transparent;
      border: 0px;
    }

    & > p:last-child {
      word-break: break-all;
    }

    &.fresh-bg {
      background-color: transparent;
      border: none;
      box-shadow: none !important;
      padding: 0 !important;
    }

    &.max-w-85-p {
      max-width: 85%;
    }

    &.w-100-p {
      width: 100%;
    }

    &.w-95-p {
      width: 95%;
    }

    &.w-70-p {
      width: 70%;
    }

    &.w-30-p {
      width: 30%;
    }

    &.max-w-480-px {
      max-width: 480px;
    }

    &.max-w-420-px {
      max-width: 420px;
    }

    &.w-300-px {
      width: 300px;
    }

    &.w-85-p {
      width: 85%;
    }

    &.w-75-p {
      width: 75%;
    }

    &.w-40-p {
      width: 40%;
    }

    &.w-50-p {
      width: 50%;
    }

    &.w-60-p {
      width: 60%;
    }

    &.has-img {
      img {
        max-width: 70%;
        cursor: pointer;
      }
    }

    .message-created-date {
      font-size: 11px;
      float: right;
      color: #adb5bd;
      margin-bottom: -5px;
    }
  }
}

.text-style {
  white-space: pre-wrap;
  word-break: break-word;
  width: 100%;
}

.location-style {
  float: inherit !important;
  word-break: break-word;
}
</style>
