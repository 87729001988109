import 'toastr/build/toastr.css';
import toastr from 'toastr/build/toastr.min.js';

toastr.options = {
  debug: false,
  newestOnTop: true,
  timeOut: 5000
};

/**
 *  Toast new message
 *
 * @param {String} pictureUrl
 * @param {String} sender
 * @param {String} message
 */
toastr.newMessage = ({ sender, message }) => {
  toastr.success({
    title: sender,
    message
  });
};

export default toastr;
