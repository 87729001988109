import * as actions from './actions';
import * as getters from './getters';
import { GET_CAMPAIGNS, SEARCH_SCENARIO, SET_CAMPAIGNS } from './types';

const state = {
  campaigns: null,
  searchScenarioName: ''
};

const mutations = {
  [GET_CAMPAIGNS](state, campaignsData) {
    state.campaigns = campaignsData;
  },

  [SET_CAMPAIGNS](state, campaignsData) {
    state.campaigns = campaignsData;
  },

  [SEARCH_SCENARIO](state, scenarioName) {
    state.searchScenarioName = scenarioName;
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
