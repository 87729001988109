var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":(_vm.name + "-" + _vm.prefix),"name":(_vm.name + "-" + _vm.prefix),"rules":_vm.c_rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.align === 'vertical')?_c('div',[(
        (_vm.c_contentLength > 0 && _vm.showWordLimit) ||
          _vm.showLabel === true ||
          _vm.tooltipText !== ''
      )?_c('label',[(_vm.required)?_c('span',{staticClass:"nat-required"},[_vm._v("*")]):_vm._e(),(_vm.label !== '' && _vm.showLabel == true)?_c('span',[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),_c('base-tooltip-icon',{attrs:{"id":("tooltip-" + _vm.name),"name":("tooltip-" + _vm.name),"content":_vm.tooltipText,"icon":_vm.tooltipIcon}}),(_vm.c_contentLength > 0 && _vm.showWordLimit)?_c('span',{class:['nat-maxlengthTag']},[_c('span',{class:[
            ((_vm.maxlength - _vm.c_contentLength < 0 || _vm.c_contentLength - _vm.minlength < 0
                ? 'error'
                : '') + " ")
          ]},[_vm._v(" "+_vm._s(_vm.c_contentLength))]),_vm._v(" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()],1):_vm._e(),_c('div',{class:['nat-input-tag-verti', _vm.c_focus, _vm.c_disabled],style:(_vm.c_maxRows),on:{"click":_vm.m_selectInputTag}},[_vm._l((_vm.tags),function(tag){return _c('el-tag',{key:tag,attrs:{"slot":"reference","type":"primary","effect":"dark","closable":!_vm.disabled,"size":"small"},on:{"close":function($event){return _vm.handleBtnCloseTag(tag)}},slot:"reference"},[_vm._v(_vm._s(tag)+" ")])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tagInput),expression:"tagInput"}],ref:"inputTag",staticClass:"nat-input-tag-input",attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxlength,"name":_vm.name,"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"id":_vm.name,"type":"text"},domProps:{"value":(_vm.tagInput)},on:{"blur":_vm.handleInputTagBlur,"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"input":[function($event){if($event.target.composing){ return; }_vm.tagInput=$event.target.value},function($event){return _vm.handleInput(_vm.tagInput)}],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputTag.apply(null, arguments)}}})],2),(errors[0] !== 'hidden')?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.hintText !== '')?_c('div',{staticClass:"nat-hint-text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }