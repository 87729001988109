<template>
  <div>
    <li
      :class="['list-group-item', activeClass, hasNewMsgClass, IdleStatusClass]"
      @click="handleOnItemClick"
    >
      <div class="media">
        <div class="p-10">
          <div class="follow">
            <i
              v-if="actions[ACTION.FOLLOW]"
              v-b-tooltip="
                !isFeatureClass
                  ? $t(
                      'src.modules.chat.components.AsideLeft.SearchList.SearchItem.index.mark_as_follow_up'
                    )
                  : $t(
                      'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.remove_follow_up'
                    )
              "
              :class="['fa fa-star follow', isFeatureClass]"
              aria-hidden="true"
              @click="handleFollow()"
            />
          </div>
          <a class="avatar avatar-online shadow" href @click.prevent.stop>
            <img :src="avatar" class="img-fluid" alt="..." @error="imageLoadError" />
            <div
              v-if="stateLabel && stateLabel !== ''"
              :data-state="stateLabel.label"
              :class="['badge text-white text-9 p-5 state-label', 'bg-' + stateLabel.color]"
            >
              {{ stateLabel.label }}
            </div>
          </a>
        </div>
        <div class="media-body">
          <div class="row">
            <div class="col-9">
              <h6 class="mt-0 mb-0">
                {{ userName }}
                <span v-if="hasNewMessage" class="badge m-r-5 badge-danger">{{
                  $t('src.modules.report.components.cnv-scheduled-export.index.schedule.status.new')
                }}</span>
              </h6>
            </div>
            <div class="col-3 p-0 pr-5 text-align-right right-10">
              <timeago
                v-if="timeAgo"
                :time="timeAgo"
                class="time-ago text-muted font-weight-normal"
              />
              <action-menu
                :actions="actions"
                :ticket="ticket"
                :channel-id="channelId"
                :conversation="conversation"
                :hover="hover"
                @setPreventClick="() => (preventClick = true)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="media-time">{{ messageText | truncate(30) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :data-status="statusLabel.label"
        :class="['badge text-9 p-5 status-label', 'text-' + statusLabel.color]"
      >
        {{ statusLabel.label.replace('_', ' ') }}
      </div>
      <div>
        <div class="col-12 user-status-wc-l">
          <el-tag
            v-if="userStatusInSupporting.display"
            v-b-tooltip="$t('common.text.user_status')"
            :type="userStatusInSupporting.userStatus ? 'success' : 'info'"
            effect="dark"
            style="height: 20px; line-height: 18px"
            >{{
              userStatusInSupporting.userStatus
                ? $t('common.text.available')
                : $t('common.text.not_available')
            }}
          </el-tag>
          <el-tag
            v-if="allowRegistered"
            v-b-tooltip="reasonRegistered"
            type="warning"
            color="#FBBC04"
            effect="dark"
            style="height: 20px; line-height: 18px; border-color: #fbbc04"
          >
            {{ $t('src.modules.chat.components.ChatBox.chat-header.registered') }}
          </el-tag>
        </div>
      </div>
      <div v-if="labels.length > 0 || labelsSystem.length > 0" class="row">
        <div class="col-12 conv-label-l-tag">
          <el-tag
            v-for="label in labelsSystem"
            :key="label.value"
            :disable-transitions="false"
            size="mini"
            effect="dark"
            class="c-tag"
            color="#FF0000"
            style="border-color: #ff0000"
          >
            <el-tooltip
              :content="convLabelSysValue(label.value)"
              class="item"
              effect="dark"
              placement="top"
            >
              <p>{{ convLabelSysValue(label.value) }}</p>
            </el-tooltip>
          </el-tag>

          <el-tag
            v-for="label in labels"
            :key="label.value"
            :disable-transitions="false"
            :type="label.type"
            size="mini"
            effect="dark"
            class="c-tag"
            :class="isBotColor(label.sourceType) ? 'nat-tag-bot' : ''"
          >
            <el-tooltip :content="label.value" class="item" effect="dark" placement="top">
              <p>{{ label.value }}</p>
            </el-tooltip>
          </el-tag>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import { Timeago } from 'components';
import ActionMenu from './action-menu';
import noAvatar from 'assets/images/no-avatar.png';
import { mapState, mapGetters, mapActions } from 'vuex';
import { userStatusInSupportingWC } from 'core/helpers';
import { getTextFromMessageContent } from 'core/helpers';
import { STATE_COLOR, TICKET_STATUS, END_USER_PLATFORM, ACTION } from 'core/constants';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    Timeago,
    ActionMenu
  },

  props: {
    id: {
      type: String,
      default: ''
    },
    channelId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    ticket: {
      type: Object,
      default: () => {}
    },
    ticketId: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: () => {}
    },
    message: {
      type: Object,
      default: () => {}
    },
    translations: {
      type: Object,
      default: () => {}
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    conversation: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      imageUrl: noAvatar,
      ACTION,
      preventClick: false,
      hover: false,
      reasonRegistered: '',
      allowRegistered: false
    };
  },

  computed: {
    ...mapState('chat', ['messages']),
    ...mapState('session', [
      'channelsMap',
      'agentMaps',
      'unreadMessagesTicketIds',
      'people',
      'projectMaps',
      'user'
    ]),
    ...mapGetters('chat', ['actionTicketMaps']),
    ...mapGetters('session', ['ticketTagMaps', 'tickets']),
    hasNewMessage() {
      const { agentId, assignee } = this.ticket || {};
      return (
        agentId === this.user.id &&
        assignee === this.user.id &&
        this.unreadMessagesTicketIds.includes(this.ticketId)
      );
    },

    userStatusInSupporting() {
      return userStatusInSupportingWC(this.ticket, this.channelsMap);
    },

    labels() {
      return this.ticket && this.ticket.labels
        ? this.ticket.labels.filter(l => l.sourceType !== 'system')
        : [];
    },

    labelsSystem() {
      return this.ticket && this.ticket.labels
        ? this.ticket.labels.filter(l => l.sourceType === 'system')
        : [];
    },

    actions() {
      const ticketId = (this.ticket && this.ticket.id) || this.ticketId;
      return this.actionTicketMaps[ticketId] || {};
    },

    isFeature() {
      const { feature } = this.ticket || {};
      if (feature) return true;
      return false;
    },

    isFeatureClass() {
      return this.isFeature ? 'follow-active' : '';
    },

    activeClass() {
      return this.active ? 'active' : '';
    },

    timeAgo() {
      const { lastMessageContent = {} } = this.ticket || {};
      const { lastMessageTime = '', createdAt = '' } = this.ticket || {};
      return (lastMessageContent && lastMessageContent.createdAt) || lastMessageTime || createdAt;
    },

    messageText() {
      if (!this.ticket || !this.ticket.lastMessageContent) return '';
      const { lastMessageContent } = this.ticket;
      const { type, content, text, platform, sender, channelId } = lastMessageContent;

      const { id: agentId = '' } = this.user || {};
      if (agentId !== sender && platform === 'agent' && !this.people[sender]) {
        this.addAgentToPeople(sender);
      }

      const senderInfo = this.people[`${sender}_${channelId}`] || this.people[sender] || {};
      if ((!senderInfo.firstName || !senderInfo.firstName.trim()) && senderInfo.name) {
        senderInfo.firstName = senderInfo.name.split(' ')[0];
      }
      let senderName = END_USER_PLATFORM.includes(platform)
        ? senderInfo.firstName
        : ['bot'].includes(platform)
        ? this.$t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.bot'
          )
        : ['system', 'session', 'keyword'].includes(platform)
        ? this.channelsMap[channelId] && this.channelsMap[channelId].name
        : agentId !== sender
        ? senderInfo.firstName
        : this.$t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.latest-msg.you'
          );

      senderName = senderName ? `${senderName}: ` : '';
      return (
        senderName +
        getTextFromMessageContent(content, type, text, this.selectedConversation, this.$t)
      );
    },

    avatar() {
      return this.imageUrl || noAvatar;
    },

    active() {
      const selectedTicketId =
        this.selectedConversation &&
        ((this.selectedConversation.ticket && this.selectedConversation.ticket.id) ||
          this.selectedConversation.ticketId);
      return (
        (this.ticket && this.ticket.id === selectedTicketId) || this.ticketId === selectedTicketId
      );
    },

    IdleStatusClass() {
      if (this.ticket && this.ticket.status) {
        switch (this.ticket.status) {
          case TICKET_STATUS.AGENT_IDLE:
            return 'idle-status';
          case TICKET_STATUS.USER_IDLE:
            return 'user-idle-status';
          default:
            return '';
        }
      }
      return '';
    },

    hasNewMsgClass() {
      return this.hasNewMessage ? 'has-new-msg' : '';
    },

    stateLabel() {
      if (!this.ticket || !this.ticket.state) return null;
      return { label: this.ticket.state, color: STATE_COLOR[this.ticket.state] };
    },

    statusLabel() {
      return { label: (this.ticket && this.ticket.status) || '', color: 'warning' };
    },

    userName() {
      let name = (this.userInfo && this.userInfo.name) || this.name || '';
      if (name.length > 25) name = name.substring(0, 23) + '...';
      return name;
    }
  },

  watch: {
    userInfo(newVal, oldVal) {
      if (newVal.pictureUrl !== oldVal.pictureUrl) this.imageUrl = newVal.pictureUrl || noAvatar;
    },

    conversation(newVal) {
      const { userInfo = {} } = newVal || {};
      const { registeredInfo = [] } = userInfo;
      const { ticket: { projectId, groupId } = {} } = newVal || {};
      const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
      const item = registeredInfo.find(val => {
        if (projectHasGroup && groupId) {
          return val.agentId === this.user.id && val.groupId === groupId;
        } else {
          return val.agentId === this.user.id;
        }
      });
      this.reasonRegistered = item && item.reason ? item.reason : '';
      this.allowRegistered = !!(item && item.agentId);
    }
  },

  created() {
    if (this.userInfo && this.userInfo.pictureUrl) this.imageUrl = this.userInfo.pictureUrl;
    else this.imageUrl = noAvatar;

    const { registeredInfo = [] } = this.userInfo || {};
    const { ticket: { projectId, groupId } = {} } = this.conversation || {};
    const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
    const item = registeredInfo.find(val => {
      if (projectHasGroup && groupId) {
        return val.agentId === this.user.id && val.groupId === groupId;
      } else {
        return val.agentId === this.user.id;
      }
    });
    this.reasonRegistered = item && item.reason ? item.reason : '';
    this.allowRegistered = !!(item && item.agentId);

    EventBus.$on('reloadUserRegistered', val => {
      const { userId, channelId, registeredInfo = [], key = '' } = val || {};
      const {
        userId: convUserId,
        channelId: convChannelId,
        id: conversationId,
        ticket: { projectId, groupId } = {}
      } = this.conversation || {};
      if (userId === convUserId && channelId === convChannelId) {
        const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;

        const item = registeredInfo.find(val => {
          if (projectHasGroup && groupId) {
            return val.agentId === this.user.id && val.groupId === groupId;
          } else {
            return val.agentId === this.user.id;
          }
        });
        this.reasonRegistered = item && item.reason ? item.reason : '';
        this.allowRegistered = !!(key === 'REGISTERED');
        this.updateStatusRegistered({ ...val, conversationId });
      }
    });
  },

  methods: {
    ...mapActions('session', ['setFollowToTicket', 'addAgentToPeople', 'updateStatusRegistered']),
    convLabelSysValue(value) {
      if (value === 'virus') {
        return this.$t('src.modules.chat.components.ChatBox.chat-header.virus');
      }
      return value;
    },
    handleOnItemClick() {
      if (this.preventClick) {
        this.preventClick = false;
        return;
      }
      this.$emit('onClick', {
        id: this.id
      });
    },
    imageLoadError() {
      this.imageUrl = noAvatar;
    },
    handleFollow() {
      this.preventClick = true;
      const follow = !this.isFeature;
      this.setFollowToTicket({ ticket: this.ticket, follow }).then(() => {
        this.preventClick = false;
      });
    },
    isBotColor(sourceType) {
      return sourceType === 'bot' || sourceType === 'admin';
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-danger {
  color: #f80031 !important;
}

.user-status-wc-l {
  margin-left: -25px;
}

.conv-label-l {
  margin-left: -10px;
}
.conv-label-l {
  margin-left: -15px;
}

.follow {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #8898aa;
  cursor: pointer;
}

.follow-active {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fb6340;
}

.app-message-list {
  .list-group {
    .list-group-item {
      .media {
        margin-left: -10px !important;
      }
      .media-body {
        padding-left: 0px;

        .col-4 {
          margin-top: -3px;
        }
        .col-8 {
          max-width: 200px;
          h6 {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .col-12 {
          max-width: 280px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &.has-new-msg {
        .avatar {
          &::before {
            position: absolute;
            width: 5px;
            height: 5px;
            left: 5px;
            z-index: 10;
            border-radius: 5px;
            background-color: #2196f3;
            content: ' ';
          }
        }

        h6,
        span {
          font-weight: bold;
          color: #fff;
        }
      }

      .right-10 .chat-opt-menu {
        right: 10px;
      }
    }
  }
}

.media-body {
  .row {
    .col-9 {
      max-width: 100%;
    }
  }
}

.text-align-right {
  text-align: right;
  right: 0;
  position: absolute;
}

.remove-ticket-action {
  right: 10px !important;

  img {
    height: 25px;
    width: 25px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

// tags
.el-tag p {
  display: inline-block;
  height: 18px;
  line-height: 17px;
  font-size: 10px;
  white-space: nowrap;
  max-width: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.el-tag {
  margin-left: 0px;
}
.el-tag--mini {
  padding: 0px 4px !important;
}

.idle-status {
  background-color: #ee9b88 !important;
}

.user-idle-status {
  background-color: #adb5bd !important;
}

.bg-refer {
  background-color: #fbe626 !important;
}
</style>
