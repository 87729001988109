<template>
  <div v-loading="isLoading" :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper cs-form">
      <div class="card-header">
        <PanelTools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="handleClose"
        />
        <h3 class="card-title mb-0">
          {{ $t('src.modules.chat.components.ChatBox.chat-header.status_history') }}
        </h3>
      </div>
      <div class="card-body">
        <div id="status-history" class="cs-container block" v-on:scroll.passive="handleScroll">
          <el-timeline :reverse="true" style="margin-right: 40px">
            <el-timeline-item
              v-for="(activity, index) in items"
              :key="index"
              :color="'#0bbd87'"
              :icon="'el-icon-arrow-up'"
              :timestamp="formatTime(activity.createdAt)"
              placement="top"
            >
              <el-card class="text-center" shadow="hover">
                <h6>
                  {{ `[${activity.state}] ${activity.status}` }}
                  <b-badge
                    v-if="
                      activity.projectId &&
                        activity.groupId &&
                        projectMaps[activity.projectId] &&
                        projectMaps[activity.projectId].groups[activity.groupId] &&
                        projectMaps[activity.projectId].groups[activity.groupId].name
                    "
                  >
                    {{ projectMaps[activity.projectId].groups[activity.groupId].name }}
                  </b-badge>
                </h6>
                <p v-if="!(activity.isInterrupted && interruptStatus.includes(activity.status))">
                  {{ $t('src.modules.chat.components.ChatBox.message-monitoring.handled_by') }} {{ getHandler(activity) }}
                </p>
                <p v-if="activity.isInterrupted">
                  {{ $t('src.modules.chat.components.ChatBox.message-monitoring.interrupted_by') }} {{ getHandler(activity, 'monitoring') }}
                </p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { getHandler, convertTimeUTC } from 'core/helpers';
import { TICKET_STATUS, TICKET_STATE } from 'core/constants';
import PanelTools from 'modules/chat/components/PanelTools';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    PanelTools
  },

  props: {
    conversation: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      pinned: false,
      items: [],
      BOT_HANDLE: TICKET_STATUS.BOT_HANDLE,
      isLoading: false,
      interruptStatus: [TICKET_STATUS.AGENT_START, TICKET_STATUS.AGENT_SUPPORTING]
    };
  },

  computed: {
    ...mapState('global', ['rightStatusHistoryPanelPinned']),
    ...mapState('session', ['projectMaps']),
    ...mapState('actionLogs', ['actionLogsTicket']),
    ...mapGetters('chat', ['selectedConversation']),

    shadowClass() {
      return this.rightStatusHistoryPanelPinned ? 'shadow-sm' : 'shadow-lg unpinned';
    },

    pinPanelStatus() {
      return this.rightStatusHistoryPanelPinned || this.pinned;
    }
  },

  watch: {
    async conversation(newValue, oldValue) {
      if (!newValue || isEmpty(newValue)) {
        this.$emit('hideAllPanel');
      }

      await this.fetchTicketActionLogs(newValue, oldValue);
    }
  },

  async mounted() {
    await this.fetchTicketActionLogs(this.conversation);
  },

  methods: {
    ...mapActions('global', ['pinRightStatusHistoryPanel']),
    ...mapActions('actionLogs', ['getTicketActionLogs']),

    async fetchTicketActionLogs(newConv, oldConv) {
      const { ticket: { id: oldTicketId = '' } = {} } = oldConv || {};
      const { ticket: { id: newTicketId = '' } = {} } = newConv || {};
      if (!this.isLoading && newTicketId) {
        oldTicketId !== newTicketId ? (this.isLoading = true) : null;
        const actionLogsTicket = this.actionLogsTicket[newTicketId] || null;
        const { state: latestState = '' } =
          (actionLogsTicket && actionLogsTicket[0]) || {};

        if (latestState !== TICKET_STATE.COMPLETE || !actionLogsTicket) {
          
          const data = await this.getTicketActionLogs({
            ticketId: newTicketId
          });
          this.items = data;
        } else {
          this.items = this.actionLogsTicket[newTicketId];
        }
        this.isLoading = false;
      }
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    },

    clickCard() {
      this.$emit('onClickCard');
    },

    getHandler(activity, type) {
      return getHandler(activity, type);
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.pinRightStatusHistoryPanel(this.pinned);
    },

    handleClose() {
      this.$emit('handleStatusHistoryItemClick');
    },

    handleScroll() {
      const contentElement = document.getElementById('status-history');
      if (!contentElement) return;
      contentElement.setAttribute('style', 'transform: translate3d(0px, 0px, 0px);');
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  height: calc(100vh - 115px) !important;
  overflow: auto;
}
.text-center {
  text-align: center !important;
}
</style>
