<template>
  <b-modal
    id="ManageUserLabels"
    ref="modal"
    v-model="show"
    :title="$t('src.modules.chat.components.ManageUserLabels.index.manage_user_labels')"
    size="lg"
    @hidden="onCancel"
    scrollable
  >
    <div class="nat-condition-search">
      <b-col>
        <b-row class="nat-condition-item-search">
          <base-input
            data-test-id="searchUsrLabel"
            v-model="searchText"
            :name="'filter-file'"
            :placeholder="
              $t('src.modules.chat.components.ManageUserLabels.index.search_by_label_name')
            "
            @input="handleSearch"
            @keyup.enter="handleSearch"
            :icon="'search'"
            :align="'horizontal'"
            :maxlength="-1"
          />
        </b-row>
      </b-col>
    </div>
    <div class="form-group">
      <label class="text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold">
        <i class="fas fa-grip-vertical m-r-10"></i>
        {{ getProjectName }}
      </label>
    </div>
    <div class="nat-hint-text">
      {{
        $t(
          'src.modules.chat.components.ManageLabels.index.user_label_assigned_by_bot_will_be_managed_in_audience_management'
        )
      }}
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="getDataPagination"
        stripe="stripe"
        @sort-change="m_sortChange"
        :default-sort="{ prop: 'value', order: 'ascending' }"
        v-loading="loadingTable"
      >
        <el-table-column
          :min-width="70"
          :label="$t('src.modules.chat.components.ManageLabels.index.label')"
          prop="value"
          sortable="custom"
        >
          <template slot-scope="scope">
            <base-input
              v-if="scope.row.edit"
              :label="$t('src.modules.search-management.index.user_labels')"
              :name="'inputNewTag'"
              v-model="scope.row.value"
              :show-label="false"
              :maxlength="25"
            />
            <span v-if="!scope.row.edit">{{ scope.row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :min-width="10"
          :label="$t('src.modules.chat.components.ManageLabels.index.color')"
          prop="type"
        >
          <template slot-scope="scope">
            <div class="nat-chat-tag">
              <el-popover :disabled="!scope.row.edit" placement="bottom" width="50" trigger="click">
                <div class="row nat-color-tag-row">
                  <div v-if="scope.row.type !== 'primary'" class="col-3 nat-color-tag-col">
                    <el-button
                      type="primary"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('primary', scope.$index)"
                    ></el-button>
                  </div>
                  <div v-if="scope.row.type !== 'success'" class="col-3 nat-color-tag-col">
                    <el-button
                      type="success"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('success', scope.$index)"
                    ></el-button>
                  </div>
                  <div v-if="scope.row.type !== 'info'" class="col-3 nat-color-tag-col">
                    <el-button
                      type="info"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('info', scope.$index)"
                    ></el-button>
                  </div>
                  <div v-if="scope.row.type !== 'warning'" class="col-3 nat-color-tag-col">
                    <el-button
                      type="warning"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('warning', scope.$index)"
                    ></el-button>
                  </div>
                  <div v-if="scope.row.type !== 'danger'" class="col-3 nat-color-tag-col">
                    <el-button
                      type="danger"
                      style="text-align: right; margin: 0; width: 100%; height: 20px"
                      @click="changeTagColor('danger', scope.$index)"
                    ></el-button>
                  </div>
                </div>

                <el-button
                  slot="reference"
                  v-model="scope.row.type"
                  :class="[!scope.row.edit ? 'default-cursor' : '']"
                  :type="scope.row.type"
                ></el-button>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :min-width="17"
          :label="$t('src.modules.chat.components.ManageLabels.index.total_used')"
          prop="count"
          align="center"
        >
          <template slot-scope="scope" controls-position="left">{{
            scope.row.count || 0
          }}</template>
        </el-table-column>
        <el-table-column :min-width="35" align="left">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-4"></div>
              <div v-if="!scope.row.edit" class="col-4">
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-edit"
                  @click.native.prevent="editRow(scope.$index, getDataPagination)"
                ></el-button>
              </div>
              <div v-if="scope.row.edit" class="col-4">
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-close"
                  @click.native.prevent="removeEditRow(scope.$index, getDataPagination)"
                ></el-button>
              </div>
              <div
                v-if="
                  (scope.row.hasOwnProperty('id') &&
                    scope.row.hasOwnProperty('count') &&
                    scope.row.count == 0) ||
                    !scope.row.hasOwnProperty('count')
                "
                class="col-4"
              >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  @click.native.prevent="deleteRow(scope.$index, getDataPagination)"
                ></el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <el-pagination
        ref="pagination"
        :total="total"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <div slot="modal-footer" class="w-100">
      <b-btn
        data-testid="add-usr-lbl"
        class="float-left"
        variant="primary"
        style="margin-left: 10px"
        @click="addRow"
      >
        {{ $t('src.modules.chat.components.ManageUserLabels.index.add_user_label') }}
      </b-btn>
      <b-button
        data-testid="saveUsrLabel"
        :disabled="cntAdd === 0 && cntEdit === 0"
        class="float-right"
        variant="primary"
        style="margin-left: 10px"
        @click="saveData"
        >{{ $t('src.modules.chat.components.ManageLabels.index.save') }}</b-button
      >
      <b-btn data-testid="closeUsrLabel" class="float-right" variant="default" @click="onCancel">
        {{ $t('common.confirmModal.close') }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { EventBus } from 'core/eventBus';
import cloneDeep from 'lodash/cloneDeep';
import { isContainingSystemDefinedWord } from 'core/helpers';

function defaultState() {
  return {
    show: false,
    total: 0,
    pagesize: 5,
    currentPage: 1,
    projectName: null,
    tagColors: ['primary', 'success', 'info', 'warning', 'danger'],
    cntAdd: 0,
    cntEdit: 0,
    maxlengthTag: 25,
    dataConfirm: {},
    searchText: '',
    items: [],
    sortOptions: {},
    itemsChangeColor: [],
    loadingTable: false
  };
}

export default {
  props: {
    conversation: {
      type: Object,
      default: () => {}
    }
  },

  data: defaultState,

  computed: {
    ...mapState('session', ['projectMaps', 'projectLabelMaps']),
    ...mapGetters('session', ['labelMaps', 'usersLabelsMNSearch']),
    ...mapGetters('chat', ['userLabels', 'selectedConversation']),

    rows() {
      return this.items.length;
    },

    getDataPagination() {
      const data = this.items.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      const dataPre = this.items.slice(
        (this.currentPage - 1) * this.pagesize - 5,
        this.currentPage * this.pagesize - 5
      );
      return data.length === 0 ? dataPre : data;
    },

    getProjectName() {
      return this.projectName;
    }
  },
  watch: {
    userLabels: {
      deep: true,
      handler() {
        const { order, prop } = this.sortOptions;
        this.items = cloneDeep(this.processItems(this.userLabels, prop, order));
      }
    },
    searchText: {
      handler() {
        const { order, prop } = this.sortOptions;
        this.items = cloneDeep(this.processItems(this.userLabels, prop, order));
      }
    }
  },
  created() {
    EventBus.$on('openManageUserLabels', async () => {
      this.loadingTable = true;
      this.reloadList();
      if (this.conversation && this.conversation.ticket) {
        const { projectId } = this.conversation.ticket;
        const { name } = this.projectMaps[projectId] || {};
        this.projectName = name || '';
      }
      this.cntAdd = 0;
      this.cntEdit = 0;
    });
  },

  async mounted() {
    if (this.conversation && this.conversation.ticket) {
      const { projectId } = this.conversation.ticket;
      const { name } = this.projectMaps[projectId] || {};
      this.projectName = name || '';
      this.cntAdd = 0;
      this.cntEdit = 0;
    }
  },

  beforeDestroy() {
    EventBus.$off('openManageUserLabels');
  },
  methods: {
    ...mapActions('manageUserLabels', [
      'createLabel',
      'updateUsersLabelValue',
      'deleteUserLabel',
      'updateLabel'
    ]),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('chat', ['getLabelsByProjectId', 'updConversationInUserInfo']),

    changeTagColor(value, index) {
      const currentIndex = this.pagesize * (this.currentPage - 1) + index;
      this.items[currentIndex].type = value;
      if (this.items[currentIndex].id) this.itemsChangeColor.push(this.items[currentIndex]);
    },

    getTagColor() {
      return this.tagColors[Math.floor(Math.random() * this.tagColors.length)];
    },

    onCancel() {
      Object.assign(this.$data, defaultState());
      this.show = false;
    },

    formatTime(value) {
      if (value) return moment(value).format('DD MMM YYYY LT');
      return null;
    },

    processItems(data, prop, order) {
      data = data.filter(label =>
        label.value.toLowerCase().includes(this.searchText.toLowerCase())
      );
      const result = cloneDeep(data);
      this.total = result.length;
      switch (order) {
        case 'descending':
          return result.sort((a, b) => b[prop].localeCompare(a[prop]));
        case 'ascending':
          return result.sort((a, b) => a[prop].localeCompare(b[prop]));
        default:
          return result.sort(
            (a, b) => new Date(b.updatedAt || b.createdAt) - new Date(a.updatedAt || a.createdAt)
          );
      }
    },

    currentChange: function(currentPage) {
      this.currentPage = currentPage;
    },

    async saveData() {
      this.setGlobalReady(false);
      let isAddUpdate = false;
      const listAdd = [];
      const listUpdate = [];
      let stopSaveData = false;
      let cntEmptyLabel = 0;
      let cntDuplicate = 0;
      let cntMaxLength = 0;
      const { projectId } = this.conversation.ticket;
      this.items.map(item => {
        item.value = item.value.replace('\b', '');

        if (isContainingSystemDefinedWord(item.value)) {
          stopSaveData = true;
        }

        if (!item.hasOwnProperty('id')) {
          if (item.value.trim() == '') {
            this.setGlobalReady(true);
            cntEmptyLabel++;
          }
          if (this.checkDuplicate(item)) {
            cntDuplicate++;
          }

          if (item.value.length > this.maxlengthTag) {
            cntMaxLength++;
          }

          listAdd.push({
            value: item.value,
            type: item.type || this.getTagColor(),
            sourceType: 'agent',
            entityType: 'personals',
            projectId
          });
        } else {
          if (item.hasOwnProperty('edit') && item.edit === true) {
            if (item.value.trim() == '') {
              this.setGlobalReady(true);
              cntEmptyLabel++;
            }
            if (this.checkDuplicate(item)) {
              cntDuplicate++;
            }
            if (item.value.length > this.maxlengthTag) {
              cntMaxLength++;
            }
            const { entityType, value, type, oldData, id } = item;
            listUpdate.push({
              value,
              type,
              oldType: oldData.type,
              oldValue: oldData.value,
              id,
              projectId,
              entityType
            });
          }
        }
      });
      isAddUpdate = listAdd.length > 0 || listUpdate.length > 0;

      if (stopSaveData) {
        this.setGlobalReady(true);
        return;
      }

      if (cntEmptyLabel > 0) {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.chat.components.ManageLabels.index.label_is_required')
        });
        return;
      }

      if (cntMaxLength > 0) {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.chat.components.ManageLabels.index.max_length', {
            max_length: this.maxlengthTag
          })
        });
        return;
      }

      if (cntDuplicate > 0) {
        this.setGlobalReady(true);
        return;
      }

      try {
        if (listAdd.length > 0) {
          await this.createLabel({
            labels: listAdd
          });
          this.loadingTable = true;
          this.reloadList();
        }
        if (listUpdate.length > 0) {
          await this.updateLabel({
            labels: listUpdate
          });
          this.loadingTable = true;
          await Promise.all([
            this.reloadList(),
            this.updConversationInUserInfo(this.selectedConversation)
          ]);
        }
      } catch (e) {
        const message = JSON.parse(e.message);
        const { errors = null, label = null } = message;
        if (errors && errors.code === 'LABEL_EXISTING') {
          if (label.sourceType === 'agent') {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t(
                'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_label',
                {
                  labelValue: label.value
                }
              )
            });
          } else {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t(
                'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_added_by_admin_and_bot',
                {
                  labelValue: label.value
                }
              )
            });
          }
        } else {
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: e.message
          });
        }
        !isAddUpdate ? this.cntAdd = 0 : null;
        !isAddUpdate ? this.cntEdit = 0 : null;
        this.setGlobalReady(true);
        return;
      }

      if (listAdd.length == 0 && listUpdate.length == 0) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.chat.components.ManageLabels.index.nothing_to_save')
        });
      } else {
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t('src.modules.chat.components.ManageLabels.index.item(s)_was_saved')
        });
      }
      this.cntAdd = 0;
      this.cntEdit = 0;
      this.setGlobalReady(true);
    },

    reloadList() {
      this.getLabelsByProjectId({
        projectIds: [this.conversation?.projectId || ''],
        entity: 'personals',
        sourceType: 'agent'
      }).finally(() => (this.loadingTable = false));
    },

    addRow() {
      const currentIndex = this.pagesize * (this.currentPage - 1);
      const newRow = {
        selection: true,
        value: '',
        type: this.getTagColor(),
        count: 0,
        edit: true
      };
      this.items.splice(currentIndex, 0, newRow);
      this.cntAdd++;
      this.total = this.items.length;
    },

    editRow(index) {
      const currentIndex = this.pagesize * (this.currentPage - 1) + index;
      const cloneData = this.items;
      cloneData.oldValue = cloneData[currentIndex].value;
      cloneData[currentIndex].oldData = { ...cloneData[currentIndex] };
      cloneData[currentIndex].edit = true;
      this.items = [...cloneData];
      this.cntEdit++;
    },
    removeEditRow(index, rows) {
      if (rows[index].hasOwnProperty('id')) {
        const currentIndex = this.pagesize * (this.currentPage - 1) + index;
        const cloneData = this.items;
        const { oldData } = { ...cloneData[currentIndex] };
        cloneData[currentIndex] = oldData;
        this.items = [...cloneData];
        this.cntEdit--;
      } else {
        const currentIndex = this.pagesize * (this.currentPage - 1) + index;
        this.items.splice(currentIndex, 1);
        this.cntAdd--;
      }
      this.total = this.items.length;
    },

    deleteRow(index, rows) {
      if (rows[index].hasOwnProperty('count') && rows[index].count > 0) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.chat.components.ManageLabels.index.the_label_is_in_use_it_can_not_be_deleted'
          )
        });
        return;
      }
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ManageLabels.index.are_you_sure_you_want_to_remove_this_label'
        )
      })
        .then(() => {
          this.handleYes();
        })
        .catch(() => {});
      this.dataConfirm = { index, rows };
    },
    checkDuplicate(row) {
      const duplicate = this.items.filter(
        label => label.value.replace('\b', '') === row.value.replace('\b', '')
      );
      if ((duplicate.length === 1 && duplicate[0].id !== row.id) || duplicate.length > 1) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.chat.components.ChatBox.chat-header.can_not_add_duplicate_label',
            {
              labelValue: row.value
            }
          )
        });
        return true;
      }
    },

    async handleYes() {
      this.setGlobalReady(false);
      const { rows, index } = this.dataConfirm;
      if (rows[index].hasOwnProperty('id')) {
        const { id, pk } = rows[index];
        try {
          const data = await this.deleteUserLabel({ id, pk });

          if (data && data.errors && data.errors.code === 'LABEL_IN_USED') {
            this.setGlobalReady(true);
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t(
                'src.modules.chat.components.ManageLabels.index.the_label_is_in_use_it_can_not_be_deleted'
              )
            });
            return true;
          }
        } catch (e) {
          this.setGlobalReady(true);
          return false;
        }
      } else {
        const currentIndex = this.pagesize * (this.currentPage - 1) + index;
        this.items.splice(currentIndex, 1);
        this.cntAdd--;
      }
      this.setGlobalReady(true);
      this.loadingTable = true;

      this.getLabelsByProjectId({
        projectIds: [this.conversation?.projectId || ''],
        entity: 'personals',
        sourceType: 'agent'
      }).finally(() => (this.loadingTable = false));
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: this.$t('src.modules.chat.components.ManageLabels.index.item_was_deleted')
      });
    },

    handleSearch() {
      this.currentPage = 1;
      this.searchText = this.searchText ? this.searchText.trim() : '';
    },

    m_sortChange(sortProps) {
      const { order, prop } = sortProps;
      this.sortOptions = sortProps;
      this.items = cloneDeep(this.processItems(this.userLabels, prop, order));
    }
  }
};
</script>
<style scoped lang="scss">
.nat-color-tag-col {
  padding-left: 1px !important ;
  padding-right: 1px !important ;
  & /deep/.el-button {
    padding: unset !important;
  }
}

.nat-color-tag-row {
  margin-left: 3px !important ;
  margin-right: 3px !important ;
}
.nat-chat-tag {
  display: flex;
  span {
    display: flex;
    margin-top: 2px;
  }
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 8px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -4px;
  position: absolute;
  font-weight: 900;
}

.nat-maxlengthTag.error {
  color: red;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
  font-style: italic;
  text-align: center;
}
.nat-condition-search {
  display: -webkit-box;
  & .nat-condition-item-search {
    float: right;
  }
}
.default-cursor {
  cursor: default;
}
</style>
