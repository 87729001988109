<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-texarea-group-prepend">
        <div class="nat-texarea-group-text" :style="c_labelWidthStyle">
          <label
            v-if="
              (c_contentLength > 0 && showWordLimit) ||
                showLabel === true ||
                tooltipText !== ''
            "
          >
            <span v-if="required" class="nat-required">*</span>
            <span v-if="label !== '' && showLabel === true"> {{ label }}</span>
          </label>
          <div class="nat-texarea-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}-${prefix}`"
              :name="`tooltip-${name}-${prefix}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-texarea-group-value">
          <div class="nat-texarea-group-value-maxlength">
            <span
              v-if="c_contentLength > 0 && showWordLimit"
              :class="['nat-maxlengthTag']"
            >
              <span
                :class="[
                  `${
                    maxlength - c_contentLength < 0 || c_contentLength - minlength <= 0
                      ? 'error'
                      : ''
                  } `
                ]"
              >
                {{ c_contentLength }}</span
              >&nbsp;/&nbsp;{{ maxlength }}
            </span>
          </div>
          <vue-tribute v-if="vueTribute" :options="tributeOptions" :key="tributeKeys">
            <textarea
              type="textarea"
              :name="`${name}-${prefix}`"
              :disabled="disabled"
              :id="`${name}-${prefix}`"
              class="nat-texarea-hori"
              v-model="content"
              :placeholder="disabled ? '' : c_placeholder"
              :maxlength="maxlength"
              :rows="rows"
              @compositionupdate="handecomposeUpdate"
              @compositionend="handecomposeEnd"
              @input="handleInput(content)"
              @change="handleChange(content)"
              @keyup="handleKeyup"
            />
          </vue-tribute>
          <textarea
            v-else
            type="textarea"
            :name="`${name}-${prefix}`"
            :disabled="disabled"
            :id="`${name}-${prefix}`"
            class="nat-texarea-hori"
            v-model="content"
            :placeholder="disabled ? '' : c_placeholder"
            :maxlength="maxlength"
            :rows="rows"
            @compositionupdate="handecomposeUpdate"
            @compositionend="handecomposeEnd"
            @input="handleInput(content)"
            @change="handleChange(content)"
            @keyup="handleKeyup"
          />
        </div>
      </div>
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
    <div v-if="align === 'vertical'">
      <label
        v-if="
          (c_contentLength > 0 && showWordLimit) ||
            showLabel === true ||
            tooltipText !== ''
        "
      >
        <span v-if="required" class="nat-required">*</span>
        <span v-if="label !== '' && showLabel === true"> {{ label }}</span>
        <base-tooltip-icon
          :id="`tooltip-${name}-${prefix}`"
          :name="`tooltip-${name}-${prefix}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
        <span v-if="c_contentLength > 0 && showWordLimit" :class="['nat-maxlengthTag']">
          <span
            :class="[
              `${
                maxlength - c_contentLength < 0 || c_contentLength - minlength <= 0
                  ? 'error'
                  : ''
              } `
            ]"
          >
            {{ c_contentLength }}</span
          >&nbsp;/&nbsp;{{ maxlength }}
        </span>
      </label>
      <vue-tribute v-if="vueTribute" :options="tributeOptions" :key="tributeKeys">
        <textarea
          type="textarea"
          :disabled="disabled"
          :name="`${name}-${prefix}`"
          :id="`${name}-${prefix}`"
          class="nat-texarea-verti"
          v-model="content"
          :placeholder="disabled ? '' : c_placeholder"
          :maxlength="maxlength"
          :rows="rows"
          @compositionupdate="handecomposeUpdate"
          @compositionend="handecomposeEnd"
          @input="handleInput(content)"
          @change="handleChange(content)"
          @keyup="handleKeyup"
        />
      </vue-tribute>
      <textarea
        v-else
        type="textarea"
        :disabled="disabled"
        :name="`${name}-${prefix}`"
        :id="`${name}-${prefix}`"
        class="nat-texarea-verti"
        v-model="content"
        :placeholder="disabled ? '' : c_placeholder"
        :maxlength="maxlength"
        :rows="rows"
        @compositionupdate="handecomposeUpdate"
        @compositionend="handecomposeEnd"
        @input="handleInput(content)"
        @change="handleChange(content)"
        @keyup="handleKeyup"
      />
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate.js';
import VueTribute from 'vue-tribute';

export default {
  name: 'base-textarea',
  components: {
    VueTribute
  },
  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    maxlength: {
      type: Number,
      default: 2000
    },
    minlength: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 1
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    showWordLimit: {
      type: Boolean,
      default: true
    },
    tributeOptions: {
      type: Object,
      default: () => {}
    },
    tributeKeys: {
      type: Number,
      default: 0
    },
    vueTribute: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.value,
      compositionData: ''
    };
  },
  computed: {
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseRequired = { label: this.label };
      if (this.maxlength && this.maxlength > 0) {
        allRule.baseMaxLength = { length: this.maxlength, label: this.label };
      }
      if (this.minlength && this.minlength > 0) {
        allRule.baseMinLength = { length: this.minlength, label: this.label };
      }
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.placeholder', { fieldName: this.label });
    },
    c_contentLength() {
      return Number(this.content.length) + Number(this.compositionData.length);
    },
    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    }
  },
  watch: {
    value(value) {
      this.content = value;
    }
  },

  methods: {
    handleInput(value) {
      let valiValue = value;
      /**
       * fixed rules
       */
      // 1. Accept to input one space between 2 words/characters only
      if (/\s\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\s\n+/g, '\n');
        valiValue = valiValue.replace(/\s\s+/g, ' ');
      }
      // 2. Must not accept tab
      if (/\t+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\t+/g, ' ');
      }
      // 2.Must not accept space only without EN, JA, CH characters
      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }
      this.content = valiValue;
      this.$emit('input', this.content);
    },
    handleChange() {
      this.$emit('change', this.content);
    },
    handecomposeUpdate(compositionEvent) {
      this.compositionData = compositionEvent.data || '';
    },
    handecomposeEnd() {
      this.compositionData = '';
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  display: inline;
  margin-bottom: 0px !important;
}
.nat-texarea-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-texarea-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-texarea-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-texarea-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-texarea-group-value-maxlength {
  position: absolute;
  top: -14px;
}
.nat-texarea-hori {
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}
.nat-texarea-verti {
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}
.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 10.5px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: 0.7;
}
.nat-maxlengthTag .error {
  color: red;
}

.nat-textarea {
  height: 46px;
}
</style>
