<template>
  <el-tooltip
    :content="convertTimeTooltip(time)"
    class="item"
    effect="dark"
    placement="top"
  >
    <span
      :class="{ 'just-now-brighter': activeJustNowBrighter }"
      class="badge badge-pill text-lowercase"
      >{{ timeago }}</span
    >
  </el-tooltip>
</template>

<script>
import moment from 'moment';
import { convertTimeUTC } from 'core/helpers';

export default {
  props: {
    time: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      mTime: new Date().getTime()
    };
  },

  computed: {
    timeago() {
      return moment(this.mTime).fromNow();
    },

    activeJustNowBrighter() {
      return moment(this.mTime).fromNow() === 'just now' ? true : false;
    }
  },

  watch: {
    time(value) {
      this.mTime = value;
    }
  },

  created() {
    this.mTime = this.time;
    this.timeagoInterval = setInterval(() => {
      this.reTimeago();
    }, 60000);
  },

  beforeDestroy() {
    if (this.timeagoInterval) {
      clearInterval(this.timeagoInterval);
    }
  },

  methods: {
    reTimeago() {
      this.mTime = null;
      this.mTime = this.time;
    },

    convertTimeTooltip(value) {
      return convertTimeUTC(value);
    }
  }
};
</script>

<style>
.just-now-brighter {
  color: #008eff !important;
  font-weight: bold !important;
}
</style>
