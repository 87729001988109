import axios from 'axios';
import store from 'core/store';
import fetchAPI from './fetchAPI';

export default class Service {
  /**
   * Creates an instance of Service.
   *
   * @memberOf Service
   */
  constructor(url = null) {
    this.baseURL = url || process.env.VUE_APP_SERVICE_ENDPOINT || '';
    this.axios = axios.create({
      baseURL: `${this.baseURL}/`,
      responseType: 'json'
    });
    this.showDialog = false;
  }

  toQueries(params = {}) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');
  }

  getChannelConfigsChat() {
    const { state } = store;
    const { channelsMap } = state.session;
    const { selectedConversation } = state.chat;
    if (!selectedConversation || !channelsMap) return {};
    const { configs } = channelsMap[selectedConversation.channelId];
    return configs || {};
  }

  getChannelAdvancedConfigs() {
    const { state } = store;
    const { channelsMap } = state.session;
    const { selectedConversation } = state.chat;
    if (!selectedConversation || !channelsMap) return {};
    const { advancedConfigs } = channelsMap[selectedConversation.channelId];
    return advancedConfigs || {};
  }

  /**
   * Call a service action via REST API
   *
   * @param {any} action  name of action
   * @param {any} params  parameters to request
   * @returns  {Promise}
   *
   * @memberOf Service
   */
  async rest(action, params, options = { method: 'post' }, requireAuth = 1) {
    const { state } = store;
    if (state.global.isOffline) throw new Error('No internet connect.');
    const token = localStorage.getItem('user-token');
    const isConversationFeedsFaqApi =
      this.baseURL === process.env.VUE_APP_FACEBOOK_CONVERSATIONFEEDS_FAQ_API;
    let headers = {
      'x-api-key': process.env.VUE_APP_CPLUS_CORE_API
    };
    if (token && !isConversationFeedsFaqApi) {
      headers['Authorization'] = `Token ${token}`;
    }
    if (isConversationFeedsFaqApi) {
      headers['x-api-key'] = process.env.VUE_APP_CR_API_KEY;
    }
    const fetch = new fetchAPI({
      baseURL: `${this.baseURL}/`,
      headers
    });

    try {
      const requestParam = options.method === 'get' ? { params } : { data: params };

      const response = await fetch.run(action, options.method, requestParam, requireAuth);

      const { data = {} } = response;
      if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 406 ||
        data.statusCode === 401 ||
        data.statusCode === 403 ||
        data.statusCode === 406 ||
        data.error === 'Unauthorized'
      ) {
        return this.handleError();
      }

      if (isConversationFeedsFaqApi) return data;

      return data.data;
    } catch (err) {
      // eslint-disable-next-line
      console.log('[axios] >> [rest] -> {err, response}', {
        err,
        response: err.response
      });

      let error = err;
      if (
        error &&
        (error.message === 'Unauthorized' ||
          (action === 'authorizer/renewCPlusToken' && error.message === 'Forbidden') ||
          (error.response &&
            (error.response.status === 403 ||
              error.response.status === 401 ||
              error.response.status === 406 ||
              error.response.message === 'Unauthorized')))
      ) {
        await this.handleError();
        return;
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.errors
      ) {
        error = error.response.data.data.errors;
      } else if (error.response && error.response.data && error.response.data.errors) {
        error = error.response.data.errors;
      }
      throw error;
    }
  }

  get(action, params, requireAuth = 1) {
    return this.rest(action, params, { method: 'get' }, requireAuth);
  }

  async post(action, params, requireAuth = 1) {
    return this.rest(action, params, { method: 'post' }, requireAuth);
  }

  handleError() {
    localStorage.setItem('auth-status', 'ERROR');

    const event = new Event('authStatusChange');
    window.dispatchEvent(event);
    return Promise.resolve(false);
  }

  async getExcelFile(action, params) {
    return await axios({
      url: process.env.VUE_APP_SERVICE_ENDPOINT + '/' + action,
      method: 'POST',
      responseType: 'blob',
      data: params
    })
      .then(function(response) {
        const Blob = require('blob');
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        });
        return blob;
      })
      .catch(function(error) {
        throw error;
      });
  }
}
