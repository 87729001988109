<template>
  <div class="container-fluid p-0 page">
    <vue-element-loading :active="ready && loading" :is-full-screen="true" spiner="line-scale" />
    <!-- Left Menu -->
    <AppLeftMenu show-name="access-log" />

    <!-- Main Container -->
    <main class="nat-main-ctnr">
      <AppCover />

      <div class="nat-sub-ctnr">
        <section>
          <div class="nat-cover-ctnr">
            <!-- Title (S)-->
            <b-row class="nat-title-wrap">
              <b-col xl="12">
                <h2>{{ $t('src.components.AppLeftMenu.index.access_log') }}</h2>
              </b-col>
            </b-row>
            <!-- Title (E)-->

            <b-row class="nat-content-wrap">
              <b-col xl="12">
                <!-- Condition bar (S) -->
                <b-row class="nat-condition-bar">
                  <b-col xl="12">
                    <b-row class="nat-condition-row">
                      <b-col xl="3">
                        <b-row class="nat-condition-item">
                          <base-select
                            v-model="projectSel"
                            :options="projectList"
                            :option-value="'value'"
                            :option-lable="'text'"
                            :name="'projectSel'"
                            :label="$t('src.modules.search-management.index.project')"
                            :value-type="'String'"
                          />
                        </b-row>
                      </b-col>

                      <b-col xl="3">
                        <b-row class="nat-condition-item">
                          <base-select
                            v-model="loginStatusSel"
                            :options="loginStatusList"
                            :option-value="'value'"
                            :option-lable="'text'"
                            :name="'loginStatusSel'"
                            :label="$t('src.modules.access-log.index.status')"
                            :value-type="'String'"
                          />
                        </b-row>
                      </b-col>

                      <b-col xl="3"></b-col>
                      <b-col xl="3">
                        <b-row class="nat-condition-item">
                          <label></label>
                          <b-input-group>
                            <b-input-group-text slot="prepend" class="search-icon">
                              <i class="fas fa-search"></i>
                            </b-input-group-text>
                            <b-form-input
                              v-model="searchText"
                              :placeholder="
                                $t('src.modules.access-log.index.search_by_loginId_ipAddress')
                              "
                            />
                          </b-input-group>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row class="nat-condition-row">
                      <b-col xl="3">
                        <b-row class="nat-condition-item">
                          <base-date-picker-range
                            class="nat-date-range-schedule"
                            :align="'vertical'"
                            v-model="scheduleDateRange"
                            :label="
                              $t(
                                'src.modules.report.components.cnv-scheduled-export.index.date_range'
                              )
                            "
                            :picker-options="pickerOptions"
                            :rules="'birthday'"
                            :required="true"
                          />
                        </b-row>
                      </b-col>

                      <b-col xl="6"></b-col>
                      <b-col xl="3">
                        <span style="float: right">
                          <b-row class="nat-condition-item" style="margin-top: 30px">
                            <el-dropdown trigger="click" @command="handleExport">
                              <b-button variant="secondary">
                                <i class="el-icon-download" style="font-size: large"></i>
                                {{ $t('src.modules.report.components.index.button.download') }}
                              </b-button>
                              <el-dropdown-menu
                                slot="dropdown"
                                style="padding: 0px; margin-top: 7px"
                              >
                                <el-dropdown-item command="excel">EXCEL</el-dropdown-item>
                                <el-dropdown-item divided command="csv">CSV</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </b-row>
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Condition bar (E) -->

                <!-- Grid Result (S) -->
                <b-row class="nat-grid-result">
                  <b-col xl="12">
                    <b-table
                      :items="c_paginatedData"
                      :fields="accessLogList"
                      :current-page="pageNumber"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :fixed="true"
                      ref="tableRef"
                      responsive
                      striped
                      hover
                      thead-class="nat-table-header"
                      tbody-tr-class="nat-tbody-tr"
                      show-empty
                      style="background-color: #ffffff"
                    >
                      <!-- Show row empty data (S) -->
                      <template slot="empty">
                        <div v-if="isBusy" class="text-center">
                          <spinner />
                        </div>
                        <div v-else class="text-center">
                          {{ $t('common.text.no_data') }}
                        </div>
                      </template>
                      <!-- Show row empty data (E) -->

                      <template slot="HEAD_selected">
                        <b-form-checkbox
                          :id="`head-${pageNumber}`"
                          class="display-selected"
                          v-model="allSelected[pageNumber]"
                          @click.native.stop
                          @change="handleSelectAll"
                        >
                        </b-form-checkbox>
                      </template>

                      <template slot="selected" slot-scope="data">
                        <b-form-checkbox
                          :id="`row-${pageNumber}-${data.index + 1}`"
                          class="display-selected"
                          v-model="selected[`row-${pageNumber}-${data.index + 1}`]"
                          @click.native.stop
                          @change="handleSelect(`row-${pageNumber}-${data.index + 1}`, data.item)"
                        >
                        </b-form-checkbox>
                      </template>

                      <template slot="loginDate" slot-scope="data">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content">
                            <span>
                              {{ m_formatTime(data.value) }}
                              <br />
                              <span>{{ getTimeZone() }}</span>
                            </span>
                          </div>
                          <span>
                            {{ m_formatTime(data.value) }}
                            <br />
                            <span style="color: gray; font-size: 12px">{{ getTimeZone() }}</span>
                          </span>
                        </el-tooltip>
                      </template>

                      <template slot="loginAgent" slot-scope="data">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content">
                            <span>
                              {{ data.value }}
                              <br />
                              <span>{{ data.item.loginId }}</span>
                            </span>
                          </div>
                          <span>
                            {{ data.value }}
                            <br />
                            <span style="color: gray; font-size: 12px">{{
                              data.item.loginId
                            }}</span>
                          </span>
                        </el-tooltip>
                      </template>

                      <template slot="loginAgentRole" slot-scope="data">
                        <span>{{ data.value }}</span>
                      </template>

                      <template slot="ipAddress" slot-scope="data">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <span slot="content">{{ data.value }}</span>
                          <span>{{ data.value }}</span>
                        </el-tooltip>
                      </template>

                      <template slot="country" slot-scope="data">
                        {{ data.value }}
                      </template>

                      <template slot="loginStatus" slot-scope="data">
                        <template v-if="data.value === 'success'">
                          {{ 'Success' }}
                        </template>
                        <template v-else>
                          {{ 'Fail' }}
                        </template>
                      </template>

                      <template slot="detailedInfo" slot-scope="data">
                        <el-tooltip
                          :content="$t('src.modules.access-log.index.detailed_info')"
                          class="item"
                          effect="dark"
                          placement="top"
                        >
                          <a
                            href="javascript:void(0)"
                            class="ml-2"
                            @click="handleDetailedInfo(data.item)"
                          >
                            <i class="fas fa-info-circle"></i>
                          </a>
                        </el-tooltip>
                      </template>
                    </b-table>

                    <!-- Paging (S) -->
                    <b-row>
                      <b-col></b-col>
                      <b-col v-if="c_totalRows > 0" md="12" class="bt-paging">
                        <b-pagination
                          v-model="pageNumber"
                          :total-rows="c_totalRows"
                          :per-page="perPage"
                          class="my-0 pull-right"
                        />
                      </b-col>
                    </b-row>
                    <!-- Pagging (E) -->
                  </b-col>
                </b-row>
                <!-- Grid Result (E) -->
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
      <AppFooter />
    </main>

    <DetailedInfo />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import DetailedInfo from './components/detailed-info';
import { convertTimeUTC, getAgentName, getTimeZone } from 'core/helpers';
import { EventBus } from 'core/eventBus';
import {
  AGENT_ROLE,
  LOG_REPORT_TYPE,
  REPORT_SCHEDULE_TYPE,
  RESULT_REPORT_TYPE
} from 'core/constants';
import { exportFile } from './store/helper';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    DetailedInfo
  },

  data() {
    return {
      scheduleDateRange: [
        moment().set({ hour: 0, minute: 0, second: 0 }).subtract(6, 'days'),
        moment().set({ hour: 23, minute: 59, second: 59 })
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      loginStatusList: [
        {
          text: 'Success',
          value: 'success'
        },
        {
          text: 'Fail',
          value: 'fail'
        }
      ],
      searchText: '',
      projectSel: '',
      loginStatusSel: '',
      // Table
      accessLogList: {
        selected: {
          label: '',
          class: 'nat-table-col-selected'
        },
        loginDate: {
          label: this.$t('src.modules.access-log.index.login_date'),
          sortable: true,
          class: 'nat-table-col-login-date text-left'
        },
        loginAgent: {
          label: this.$t('src.modules.access-log.index.login_agent'),
          sortable: true,
          class: 'nat-table-col-login-agent text-left'
        },
        loginAgentRole: {
          label: this.$t('src.modules.access-log.index.agent_role'),
          sortable: true,
          class: 'nat-table-col-agent-role text-left'
        },
        ipAddress: {
          label: this.$t('src.modules.access-log.index.ip_address'),
          sortable: true,
          class: 'nat-table-col-ip-address text-left'
        },
        country: {
          label: this.$t('src.modules.access-log.index.country'),
          sortable: true,
          class: 'nat-table-col-country text-left'
        },
        loginStatus: {
          label: this.$t('src.modules.access-log.index.status'),
          sortable: true,
          class: 'nat-table-col-status text-left'
        },
        detailedInfo: {
          label: '',
          class: 'nat-table-col-detailed-info'
        }
      },
      accessLogListExport: {
        accessLogId: this.$t('src.modules.access-log.index.access_log_id'),
        loginDate: this.$t('src.modules.access-log.index.login_date'),
        loginAgent: this.$t('src.modules.search-management.index.agent_name'),
        loginId: this.$t('src.modules.search-management.index.login_id'),
        loginAgentRole: this.$t('src.modules.access-log.index.agent_role'),
        ipAddress: this.$t('src.modules.access-log.index.ip_address'),
        country: this.$t('src.modules.access-log.index.country'),
        agentDevice: this.$t('src.modules.access-log.index.device'),
        providerType: this.$t('src.modules.access-log.index.provider_type'),
        loginStatus: this.$t('src.modules.access-log.index.status'),
        loginErrorKey: this.$t('src.modules.access-log.index.error')
      },
      isBusy: false,
      sortBy: 'loginDate',
      sortDesc: true,
      pageNumber: 1,
      perPage: 10,
      allSelected: {},
      selected: {},
      selectedItems: [],
      dataSearch: this.accessLogs
    };
  },

  computed: {
    ...mapState('accessLog', ['accessLogs']),
    ...mapState('session', ['projectMaps', 'user']),
    ...mapGetters('session', ['me']),
    ...mapState('global', ['ready']),

    c_totalRows() {
      return this.c_paginatedData.length || 0;
    },

    c_paginatedData() {
      let customTextSearch = this.searchText.trim();
      if (!this.accessLogs.length || !customTextSearch) return this.accessLogs;
      customTextSearch = customTextSearch.toLowerCase();
      return this.accessLogs.filter(i => {
        const { loginId = '', ipAddress = '' } = i;
        if (loginId.toLowerCase().indexOf(customTextSearch) >= 0) return true;
        if (ipAddress.indexOf(customTextSearch) >= 0) return true;
        return false;
      });
    },

    projectList() {
      if (!this.projectMaps || typeof this.projectMaps !== 'object') return [];
      let list = [];
      if (this.me.role === AGENT_ROLE.LEADER) {
        list = Object.keys(this.projectMaps);
      } else if (this.me.role === AGENT_ROLE.MODERATOR) {
        list = Object.keys(this.projectMaps).filter(key => this.projectMaps[key].hasGroup);
      } else list = [];
      list = list.map(key => ({
        value: key,
        text: this.projectMaps[key].name
      }));

      return this.m_sortProperties(list);
    },

    loading() {
      return isEmpty(this.projectMaps) || isEmpty(this.me);
    }
  },

  watch: {
    async projectSel(value) {
      this.resetAccessLogs();
      await this.fetchData({ projectId: value, loginStatus: this.loginStatusSel });
    },

    async loginStatusSel(value) {
      this.resetAccessLogs();
      await this.fetchData({ projectId: this.projectSel, loginStatus: value });
    },

    async scheduleDateRange() {
      this.resetAccessLogs();
      await this.fetchData({ projectId: this.projectSel, loginStatus: this.loginStatusSel });
    },

    async projectMaps(old, val) {
      if (!isEqual(Object.keys(old), Object.keys(val))) {
        this.resetAccessLogs();
        await this.fetchData({ projectId: this.projectSel, loginStatus: this.loginStatusSel });
      }
    },

    c_paginatedData(data) {
      this.resetAccessLogs();
      this.dataSearch = data;
    }
  },

  async mounted() {
    await this.fetchData({});
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('accessLog', ['fetchAccessLogs']),
    ...mapActions('report', ['createLogForExport']),

    async fetchData(data) {
      const { projectId = null, loginStatus = null } = data;
      this.setGlobalReady(false);
      const fromDate = moment(this.scheduleDateRange[0]);
      const toDate = moment(this.scheduleDateRange[1]).set({ hour: 23, minute: 59, second: 59 });
      const diffDays = toDate.diff(fromDate, 'days');
      if (diffDays > 6) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.access-log.index.limit_date_range_7_days')
        });
        this.setGlobalReady(true);
        return false;
      }

      const assignedProjects =
        this.me.role === AGENT_ROLE.LEADER
          ? Object.keys(this.projectMaps)
          : Object.keys(this.projectMaps).filter(key => this.projectMaps[key].hasGroup);

      const params = {
        agentRole: this.me.role,
        fromDate,
        toDate,
        assignedProjects,
        projectId: projectId ? projectId : null,
        loginStatus: loginStatus ? loginStatus : null
      };
      await this.fetchAccessLogs(params);
      this.setGlobalReady(true);
    },

    handleDetailedInfo(data) {
      EventBus.$emit('detailedInfoModal', { bool: true, data });
    },

    m_formatTime(value) {
      const timeUTC = moment.utc(value).toDate();
      return moment(timeUTC).format('YYYY/MM/DD HH:mm:ss');
    },

    getTimeZone() {
      return `[UTC${getTimeZone()}]`;
    },

    m_sortProperties(arrValue) {
      if (!arrValue || arrValue === null) return [];
      // sort items by value
      arrValue.sort(function (a, b) {
        let x = a.text.toLowerCase(),
          y = b.text.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue || []; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    },

    handleSelectAll(value) {
      const tableRef = this.$refs.tableRef;
      const currentPageItems = tableRef.paginatedItems;
      let selectedItems = this.selectedItems;

      if (value) {
        for (let i = 1; i <= this.perPage; i++) {
          this.selected[`row-${this.pageNumber}-${i}`] = true;
        }
        selectedItems = new Set([...selectedItems, ...currentPageItems]);
        this.selectedItems = [...selectedItems];
      } else {
        for (let i = 1; i <= this.perPage; i++) {
          delete this.selected[`row-${this.pageNumber}-${i}`];
        }
        this.handleRemoveData(currentPageItems);
      }
    },

    handleSelect(row, item) {
      let selectedItems = this.selectedItems;
      if (!this.selected[row]) {
        selectedItems = new Set([...selectedItems, item]);
        this.selectedItems = [...selectedItems];
      } else {
        const data = [item];
        this.handleRemoveData(data);
      }
    },

    handleRemoveData(data) {
      const logId = data.map(({ accessLogId }) => accessLogId);
      this.selectedItems = this.selectedItems.filter(
        ({ accessLogId }) => !logId.includes(accessLogId)
      );
    },

    convertStructureData(accessLogs) {
      const structureData = [];
      const sheetData = {
        sheetName: this.$t('src.components.AppLeftMenu.index.access_log')
      };
      const title = [];
      for (const prop in this.accessLogListExport) {
        title.push(this.accessLogListExport[prop]);
      }
      const sheetDataArr = [];
      accessLogs.forEach(data => {
        const itemData = [];
        for (const prop in this.accessLogListExport) {
          const value = data[prop] ? data[prop] : '';
          if (prop === 'loginDate') {
            itemData.push(value ? convertTimeUTC(value) : '');
          } else if (prop === 'providerType') {
            itemData.push(this.renderProviderType(value));
          } else if (prop === 'loginStatus') {
            itemData.push(value === 'success' ? 'Success' : 'Fail');
          } else {
            itemData.push(value);
          }
        }
        sheetDataArr.push(itemData);
      });
      sheetData.title = title;
      sheetData.data = sheetDataArr;
      structureData.push(sheetData);
      return structureData;
    },

    async handleExport(command) {
      const accessLogs = this.selectedItems.length ? this.selectedItems : this.dataSearch;

      const fromDate = moment(this.scheduleDateRange[0]).format('YYYY-MM-DD 00:00:00');
      const toDate = moment(this.scheduleDateRange[1]).format(' YYYY-MM-DD 23:59:59');
      const { id, email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};

      const actionLogCreate = {
        userId: id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        projectId: this.projectSel,
        fromDate,
        toDate,
        type: REPORT_SCHEDULE_TYPE.IMMEDIATE,
        reportType: LOG_REPORT_TYPE.ACCESS_LOG,
        system: 'at',
        exportFormat: command
      };

      if (accessLogs.length) {
        const data = this.convertStructureData(accessLogs);
        switch (command) {
          case 'excel': {
            const fileName = `agent_access_log_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
            return exportFile(data, command, fileName)
              .then(res => {
                if (res) {
                  this.createLogForExport({
                    ...actionLogCreate,
                    exportResult: RESULT_REPORT_TYPE.SUCCESS
                  });
                }
              })
              .catch(err => {
                if (err) {
                  this.createLogForExport({
                    ...actionLogCreate,
                    exportResult: RESULT_REPORT_TYPE.ERROR,
                    exportError: (err && err.message) || 'Export Failed'
                  });
                }
              });
          }

          case 'csv': {
            const fileName = `agent_access_log_${moment().format('YYYYMMDDHHmmss')}.csv`;
            return exportFile(data, command, fileName)
              .then(res => {
                if (res) {
                  this.createLogForExport({
                    ...actionLogCreate,
                    exportResult: RESULT_REPORT_TYPE.SUCCESS
                  });
                }
              })
              .catch(err => {
                if (err) {
                  this.createLogForExport({
                    ...actionLogCreate,
                    exportResult: RESULT_REPORT_TYPE.ERROR,
                    exportError: (err && err.message) || 'Export Failed'
                  });
                }
              });
          }

          default:
            break;
        }
      } else {
        this.$baseNotification.info({
          title: this.$t('src.core.App.info'),
          message: this.$t('src.modules.report.components.index.no_data_to_export')
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: 'No data'
        });
      }
    },

    renderProviderType(type) {
      let providerType = '';
      switch (type) {
        case 'email':
          providerType = 'Email & Password';
          break;
        case 'google':
          providerType = 'Google';
          break;
        case 'facebook':
          providerType = 'Facebook';
          break;
        case 'oauth':
          providerType = 'LDAP';
          break;
        default:
          break;
      }
      return providerType;
    },

    resetAccessLogs() {
      this.selected = {};
      this.allSelected = {};
      this.selectedItems = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/chat/chat.scss';

/deep/.msg-success {
  background-color: #f0f9eb !important;
  border-color: #e1f3d8 !important;
}

.nat-main-ctnr {
  height: 100%;
  font-size: 14px !important;
  overflow-y: auto !important;
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .nat-sub-ctnr {
    overflow: auto;
    padding-left: 65px;
    padding-top: 30px;
    .nat-cover-ctnr {
      margin: 0 50px 50px 65px;
      .nat-title-wrap {
        display: block;
      }
      .nat-content-wrap {
        z-index: 1;
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
        .nat-condition-bar {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: 0px;
          .nat-condition-row {
            .nat-condition-item {
              display: block;
              padding-right: 10px;
              margin-bottom: 1rem;
              /deep/ label {
                font-weight: bold;
                text-transform: uppercase;
              }
              .nat-date-range-schedule {
                /deep/.el-input__inner {
                  width: 100% !important;
                }
              }
            }
            .nat-condition-checkbox-group {
              display: block;
              padding-right: 10px;
              margin-bottom: 1rem;
              padding-left: 0px;
              /deep/ legend {
                font-weight: bold;
                text-transform: uppercase;
                padding-bottom: 0px;
              }
            }
            .nat-condition-item-checkbox {
              display: flex;
              padding-right: 10x !important;
              margin-bottom: 1rem;
              padding-top: 28px;
              div {
                padding-right: 10px;
              }
              /deep/ label {
                font-weight: bold;
                text-transform: uppercase;
              }
            }
          }
        }
        .nat-action-bar {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: -15px;
        }
        .nat-grid-result {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: -15px;

          /deep/.nat-tbody-tr {
            td {
              word-wrap: break-word;
            }
          }

          .nat-grid-tag-item {
            background-color: orange;
            padding: 5px;
            color: white;
            margin-right: 5px;
            line-height: 40px;
            white-space: nowrap;
            border-radius: 0.25rem;
          }
          .nat-cell-text {
            overflow-wrap: break-word;
          }
          .display-selected {
            display: flex !important;
          }

          /deep/.nat-table-header {
            background-color: #2196f3;
            color: #ffffff;
            th {
              text-align: center !important;
            }
          }

          /deep/.nat-table-col-selected {
            text-align: center !important;
            width: 40px;
          }
          /deep/.nat-table-col-login-date {
            text-align: center;
            width: 230px;
          }
          /deep/.nat-table-col-login-agent {
            text-align: center;
            width: 230px;
          }
          /deep/.nat-table-col-agent-role {
            width: 150px;
            text-align: center;
          }
          /deep/.nat-table-col-ip-address {
            width: 200px;
            text-align: center;
          }
          /deep/.nat-table-col-country {
            width: 150px;
            text-align: center;
          }
          /deep/.nat-table-col-status {
            width: 150px;
            text-align: center;
          }
          /deep/.nat-table-col-detailed-info {
            width: 100px;
            text-align: center !important;
          }
        }
      }
    }
  }
}
</style>
