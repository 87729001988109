import { SET_ACCESS_LOGS } from './types';
import { AccessLogService } from './service';

const accessLogService = new AccessLogService();

const fetchAccessLogs = async ({ commit }, params) => {
  const result = await accessLogService.getAccessLogs(params);
  commit(SET_ACCESS_LOGS, result);
  return result;
};

export { fetchAccessLogs };
