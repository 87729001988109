<template>
  <div class="change-password-page container-fluid p-0 page">
    <AppLeftMenu show-name />
    <main class="profile-page wrapper-content">
      <AppCover />
      <div class="page-sub">
        <section class="section section-skew">
          <div class="container">
            <div class="card card-profile shadow mt-300">
              <div class="px-4">
                <div class="row justify-content-center">
                  <div class="col-lg-4 order-lg-2">
                    <div class="card-profile-image">
                      <a href="#">
                        <img :src="avatar" class="rounded-circle" @error="imageLoadError" />
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                    <div class="card-profile-actions py-4 mt-lg-0">
                      <button class="btn btn-sm btn-default float-right" @click="goEditProfile">
                        {{ $t('src.modules.password.index.edit_profile') }}
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-1"></div>
                </div>
                <div class="text-center mt-100">
                  <h3>{{ fullName }}</h3>
                  <h6>{{ mappingRole[user.role] }}</h6>
                </div>
                <!-- -->
                <div class="login-component">
                  <div class="p-t-15">
                    <div class="mb-5 py-5 border-top">
                      <form role="form">
                        <!-- Current Password(S) -->
                        <div class="form-group mb-3">
                          <label class="change-password-label">
                            <span class="nat-required">*</span>
                            {{ $t('src.modules.password.index.current_password') }}
                          </label>
                          <ValidationObserver ref="currentPasswordBaseInput">
                            <base-input
                              v-model="formData.curPass"
                              :align="'vertical-no-label'"
                              :prefix="'changePassword'"
                              :required="true"
                              :maxlength="128"
                              :rules="'password'"
                              :name="'password'"
                              :label="$t('src.modules.password.index.current_password')"
                              :input-type="'password'"
                              :show-hint-text="false"
                              :show-word-limit="false"
                              :error="'src.modules.password.index.please_input_the_current_password'"
                            />
                          </ValidationObserver>
                        </div>
                        <!-- Current Password (E) -->

                        <!-- New Password (S) -->
                        <div class="form-group mt-10">
                          <!-- <div class=""> -->
                          <label class="change-password-label">
                            <span class="nat-required">*</span>
                            {{ $t('src.modules.password.index.new_password') }}
                          </label>
                          <label class="change-password-label-policy">
                            {{
                              $t(
                                'src.modules.session.components.changePasswordFirstLogin.password_policy'
                              )
                            }}
                          </label>
                          <AppPasswordStrength
                            v-model="formData.newPass"
                            :default-class="'form-control form-group-default'"
                            :placeholder="$t('src.modules.password.index.enter_new_password')"
                            :toggle="true"
                            :secure-length="secureLength"
                            @secure="checkSecure"
                            @blur="handleBlurNewPassword"
                          />
                        </div>
                        <div v-if="newPasswordError !== ''" class="error-input">
                          {{ newPasswordError }}
                        </div>
                        <!-- </div> -->
                        <!-- New Password (E) -->

                        <!-- Confirm New Password (S) -->
                        <div class="form-group fixed-mr">
                          <label class="change-password-label">
                            <span class="nat-required">*</span>
                            {{ $t('src.modules.password.index.confirm_new_password') }}
                          </label>
                          <div class="form-group-default">
                            <input
                              v-model="formData.conPass"
                              :placeholder="$t('src.modules.password.index.enter_confirm_password')"
                              class="form-control"
                              type="password"
                              :maxlength="128"
                              @keyup="checkValidConfirmNewPassword"
                              @keypress.enter="handleFormSubmit"
                              @blur="handleBlurConPassword"
                            />
                          </div>
                          <div v-if="confirmNewPasswordError !== ''" class="error-input">
                            {{ confirmNewPasswordError }}
                          </div>
                        </div>
                        <!-- Confirm New Password (E) -->
                        <!-- Btn Submit (S) -->
                        <div class="text-center">
                          <button
                            :disabled="submitFormLoading"
                            type="button"
                            class="btn btn-primary my-4"
                            @click="handleFormSubmit"
                          >
                            {{ $t('src.modules.password.index.save_password') }}
                            <i v-if="submitFormLoading" class="fas fa-spinner fa-spin fa-lg" />
                          </button>
                        </div>
                        <!-- Btn Submit (E) -->
                      </form>
                      <div class="row justify-content-center">
                        <div class="col-lg-9" />
                      </div>
                    </div>
                  </div>
                  <!-- -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import googleDrivePicker from 'modules/chat/components/GoogleDrivePicker';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import AppPasswordStrength from 'components/AppPasswordStrength';
import noAvatar from 'assets/images/no-avatar.png';
import { MAPPING_ROLE } from 'core/constants';
import {
  convert2OneByte,
  hasSpecialCharacter,
  hasEmoji,
  hasWhiteSpace
} from 'components/BaseComponents/vee-validate';

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    AppPasswordStrength
  },

  data() {
    return {
      submitFormLoading: false,
      formData: {},
      imageUrl: noAvatar,
      mappingRole: MAPPING_ROLE || {},
      newPassSecure: false,
      secureLength: 8,
      newPasswordError: '',
      confirmNewPasswordError: ''
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapGetters('session', ['socialLinks']),
    ...mapState('profile', ['userInfo']),

    fullName() {
      return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    },

    avatar() {
      return this.imageUrl;
    },

    validConfirmNewPasswordMatch() {
      if (this.formData.conPass && this.formData.newPass !== this.formData.conPass)
        return this.$t(
          'src.modules.password.store.actions.new_password_and_confirm_password_do_not_match'
        );

      return '';
    }
  },

  async created() {
    this.setGlobalReady(false);
    const user = await this.getSessionUser();
    if (!user) {
      this.setGlobalReady(true);
      return;
    }
    this.formData = { ...user };
    if (this.formData.pictureUrl) this.imageUrl = this.formData.pictureUrl;
    this.setGlobalReady(true);
  },

  methods: {
    ...mapActions('session', ['getSessionUser', 'logout']),
    ...mapActions('global', ['setGlobalReady', 'pinRightPanel']),
    ...mapActions('password', ['submitForm']),

    handleBlurNewPassword(newValue, isSecure) {
      this.newPassSecure = isSecure;
      this.formData.newPass = newValue;
      this.validateNewPassword();
    },
    validateNewPassword() {
      this.newPasswordError = '';
      const fieldName = this.$t('src.modules.password.index.new_password');
      if (!this.formData.newPass)
        this.newPasswordError = this.$t('src.modules.password.index.please_input_the_new_password');
      if (hasWhiteSpace(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });

      if (hasEmoji(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });

      if (hasSpecialCharacter(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });
      if (this.formData.newPass && !this.newPassSecure)
        this.newPasswordError = this.$t(
          'src.modules.password.index.please_input_the_new_password_secure',
          { fieldName }
        );

      if (
        this.formData.newPass &&
        this.newPassSecure &&
        this.formData.newPass === this.formData.curPass
      )
        this.newPasswordError = this.$t(
          'src.modules.password.store.actions.new_password_different_old_password'
        );
    },

    handleBlurConPassword() {
      const newValue = convert2OneByte(this.formData.conPass);
      this.formData.conPass = newValue;
      this.confirmNewPasswordError = '';
      const fieldName = this.$t('src.modules.password.index.confirm_new_password');
      if (!this.formData.conPass)
        this.confirmNewPasswordError = this.$t(
          'src.modules.password.index.please_input_the_confirm_new_password'
        );
      if (hasWhiteSpace(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (hasEmoji(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (hasSpecialCharacter(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (this.formData.conPass && this.formData.newPass !== this.formData.conPass)
        this.confirmNewPasswordError = this.$t(
          'src.modules.password.store.actions.new_password_and_confirm_password_do_not_match'
        );
    },

    imageLoadError() {
      // eslint-disable-next-line
      console.log('Show image error: ', this.avatar);
      return (this.imageUrl = noAvatar);
    },

    goHome() {
      this.$router.push({ path: '/' });
    },

    goEditProfile() {
      this.$router.push('/profile/userInformation');
    },

    checkValidConfirmNewPassword() {
      this.validConfirmNewPasswordMatch;
    },

    handleFormSubmit() {
      this.validateNewPassword();
      this.handleBlurConPassword();
      this.$refs.currentPasswordBaseInput &&
        this.$refs.currentPasswordBaseInput.validate().then(result => {
          if (!result || this.newPasswordError || this.confirmNewPasswordError) {
            return this.errorMessage();
          }

          this.submitFormLoading = true;
          const formUserUpdate = {
            id: this.formData.id,
            email: this.formData.email,
            curPass: this.formData.curPass,
            newPass: this.formData.newPass,
            conPass: this.formData.conPass,
            name: this.fullName,
            firstName: this.user.firstName,
            lastName: this.user.lastName
          };
          this.submitForm(formUserUpdate).then(result => {
            this.submitFormLoading = false;
            if (result === true) {
              const _this = this;
              this.$alert(
                this.$t(
                  'src.modules.password.index.your_password_has_been_changed_you_have_to_login_again'
                ),
                this.$t('src.modules.password.index.password_change'),
                {
                  confirmButtonText: this.$t('src.core.App.login'),
                  customClass: 'nat-base-confirm-box',
                  callback: () => {
                    _this.logout(_this.user);
                  },
                  showClose: false
                }
              );
            }
          });
        });
    },

    showGoogleDrive() {
      try {
        googleDrivePicker(file => {
          if (file && file.type === 'image') {
            this.formData.pictureUrl = file.url;
            this.imageUrl = this.formData.pictureUrl;
          }
        });
      } catch (e) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.password.index.get_image_from_google_drive_error')
        });
      }
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t('src.modules.password.index.please_check_the_invalid_data')
      });
    },

    checkSecure(secure) {
      this.newPassSecure = secure;
    }
  }
};
</script>
<style lang="scss" scoped>
main {
  overflow: auto;
  height: calc(100vh - 0px);
  padding-bottom: 30px;
}

.error-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-required {
  color: red;
}

.change-password-label {
  display: -webkit-box;
}
.change-password-label-policy {
  display: -webkit-box;
  text-align: left !important ;
  font-size: 0.75rem !important;
  color: #8898aa !important ;
}

.form-group {
  margin-bottom: 0px;
}

.fixed-mr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';

.mr-10 {
  margin-right: 10px;
}

.mt-100 {
  margin-top: -60px;
}

.card-profile-image {
  position: relative;
  margin: auto;
  height: 200px;
  width: 257px;
}
.card-profile-image {
  .rounded-circle {
    min-width: 180px !important ;
    min-height: 180px !important;
  }
}

.img-avatar-layer {
  position: absolute;
  top: -54px;
  bottom: 0;
  left: 38px;
  right: 0;
  background: rgba(36, 62, 206, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.card-profile-image:hover .img-avatar-layer {
  visibility: visible;
  opacity: 1;
}

.img-avatar {
  transition: 0.2s;
  transform: translateY(1em);
}

.card-profile-image:hover .img-avatar {
  transform: translateY(0);
}
.btnHistory button {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.pageHistory {
  overflow-y: initial !important;
}

.page-cover {
  height: 450px !important;
  max-height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
}

.change-password-page .footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 65px);
  z-index: 100;
}
</style>
