import fetchAPI from 'core/fetchAPI';

export class WebChatService extends fetchAPI {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_WEBCHAT_API_URL}/`,
      headers: {
        'x-api-key': process.env.VUE_APP_WEBCHAT_API_KEY
      },
      _options: {}
    });
  }
  async getUserStatus(userId, channelId) {
    return this.get(`channels/${channelId}/users/${userId}?callFromAT=1`)
      .then(res => res && res.data)
      .catch(() => {});
  }
}
