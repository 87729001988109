<template>
  <change-password-first-login v-if="pathSession.includes('/change-password')" />
  <div v-else class="login-wrapper">
    <div class="row no-gutters">
      <div class="d-none d-sm-none d-md-block col-md-5 col-lg-7">
        <div
          v-bind:style="{ 'background-image': 'url(' + bgUrl + ')' }"
          class="background"
        >
          <div class="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
            <h3 class="semi-bold text-white">
              {{ $t('src.modules.session.index.easy_management_its_as_simple_as_that') }}
            </h3>
            <p class="small">
              {{
                $t(
                  'src.modules.session.index.copyright_transcosmos_inc_all_rights_reserved',
                  { fullYear: new Date().getFullYear() }
                )
              }}
            </p>
          </div>
          <div class="backdrop" />
        </div>
      </div>
      <div class="col-sm-12 col-md-7 col-lg-5">
        <div class="login-container bg-white">
          <div
            class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-0 sm-p-l-15 sm-p-r-15 sm-p-t-40"
          >
            <div class="row">
              <div class="col-6">
                <img :src="logoUrl" :alt="brand" />
              </div>
              <div class="col-6 text-right">
                <img :src="logoConnectPlus" :alt="brand" class="img-fluid" />
              </div>
            </div>
            <login-page v-if="pathSession.includes('/login')" />
            <forgot-password-page v-if="pathSession.includes('/forgot/password')" />
            <adfs-select v-if="pathSession.includes('/adfs/ls')" />
            <adfs-callback v-if="pathSession.includes('/adfs/cb')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginPage from './components/login';
import ForgotPasswordPage from './components/forgotPassword';
import ChangePasswordFirstLogin from './components/changePasswordFirstLogin';
import AdfsSelect from './components/adfsSelect';
import AdfsCallback from './components/adfsCallback';
import loginBg from 'assets/images/login/bg.jpg';
import logo from 'assets/images/logo.png';
import logoDec from 'assets/images/logo-dec-support.png';
import 'assets/scss/login.scss';

export default {
  components: {
    LoginPage,
    ForgotPasswordPage,
    AdfsSelect,
    AdfsCallback,
    ChangePasswordFirstLogin
  },

  data() {
    return {
      bgUrl: loginBg,
      logoUrl: logo,
      logoConnectPlus: logoDec,
      brand: 'DEC Support'
    };
  },

  computed: {
    pathSession() {
      return this.$route.path || '';
    }
  },

  mounted() {
    this.setGlobalReady(true);
  },

  methods: {
    ...mapActions('global', ['setGlobalReady'])
  }
};
</script>

<style lang="scss" scoped>
section.section {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh !important;
}
</style>
