<template>
  <!-- Resize Popoup -->
  <vue-draggable-resizable
    v-bind:class="{ fullScreen: isFull }"
    :w="drag.w"
    :h="drag.h"
    :minh="drag.minh"
    :minw="drag.minw"
    :x="drag.x"
    :y="drag.y"
    :drag-handle="'.drag'"
    :parent="true"
    style="z-index: 102"
    v-loading="isLoadingCSF"
  >
    <b-card header-tag="header" class="dragHeader" style="height: 100%" no-body>
      <!-- Resize popup button  -->
      <h6 slot="header" class="mb-0 drag">
        {{ $t('src.modules.chat.components.CustomerSupport.index.customer_support_form') }}
        <button
          style="margin-left: 12px"
          type="button"
          aria-label="Close"
          class="close"
          @click="handleClose"
        >
          <i class="fa fa-times"></i>
        </button>
        <button type="button" class="close" @click="handleResize">
          <i v-if="!isFull" class="fa fa-square"></i>
          <i v-if="isFull" class="fa fa-window-restore"></i>
        </button>
      </h6>

      <b-card-body id="abcd" ref="toolbarChat">
        <b-card v-if="!item && !isCustomerSupportPanel">
          <h6 slot="header" v-b-toggle.clContactInformation class="mb-0">
            <i :class="showCollapse ? `fa fa-chevron-down` : `fa fa-chevron-right`"></i>
            {{ $t('src.modules.chat.components.CustomerSupport.index.user_profile') }}
          </h6>
          <b-collapse id="clContactInformation" v-model="showCollapse">
            <contact-information
              id="contactInformation"
              ref="contactInformation"
              :user-id="conversation.userId"
              :channel-id="conversation.channelId"
              :project-id="conversation.projectId"
              :platform="conversation.platform"
              :user-info-cvs="conversation.userInfo"
              :is-support-form="true"
              @onSeclectRequestInformation="handleRequestInformation"
              @saveSuccess="handleSuccessContact"
              @invalidData="handleInvalidContact"
              @changeContactInfo="changeContactInfo"
              @submitContactSuccess="checkSubmitContact"
            />
          </b-collapse>
        </b-card>

        <RequestInformation
          v-if="isRequestInformation || item"
          id="requestInformation"
          ref="requestInformation"
          :information="itemSelected"
          :conversation="conversation"
          :item="item"
          :project-id="projectId"
          @success="handleSuccessRequest"
          @invalidData="handleInvalidRequest"
          @checkEditData="handleEditData"
          @submitToClose="handleSuccessToClose"
          @checkHandleSubmitToClose="checkHandleSubmitToClose"
          @checkHandleSubmitForm="checkHandleSubmitForm"
        />

        <div class="text-right form-group">
          <b-button
            v-if="!disabledFormInViewMode && selectedTicket.state === 'FINISH'"
            type="button"
            variant="primary"
            class="mt-3"
            @click="handleSubmitToClose"
          >
            {{ $t('common.text.submit') }}
          </b-button>
          <b-button
            v-if="!disabledFormInViewMode && selectedTicket.state === 'COMPLETE'"
            type="button"
            variant="primary"
            class="mt-3"
            @click="handleSaveSubmitForm"
          >
            {{ $t('common.text.submit') }}
          </b-button>
          <b-button
            v-if="!disabledFormInViewMode && selectedTicket.state !== 'COMPLETE'"
            type="button"
            variant="primary"
            class="mt-3"
            @click="handleSubmitForm"
          >
            {{ $t('src.modules.chat.components.CustomerSupport.index.save_draft') }}
          </b-button>
          <b-button
            v-if="isAllowEditSubmittedForm && !isEditSubmittedForm"
            type="button"
            variant="primary"
            class="mt-3"
            @click="handleEditSubmitForm"
          >
            {{ $t('common.text.edit') }}
          </b-button>
          <b-button
            v-if="isAllowEditSubmittedForm && isEditSubmittedForm"
            type="button"
            variant="primary"
            class="mt-3"
            @click="handleSaveSubmitForm"
          >
            {{ $t('common.text.save') }}
          </b-button>
        </div>
        <RequestHistory
          v-if="!item && !isCustomerSupportPanel"
          :conversation="conversation"
          :project-id="projectId"
          @deleteSubmitedBefore="handleInvalidHistory"
          @deleteSuccess="handleSuccessHistory"
          @select="getSelectItem"
          @initFormData="handleSupportFormData"
        />
        <div v-if="!isCustomerSupportPanel" class="text-right">
          <button type="button" class="btn btn-default mt-3" @click="handleClose">
            {{ $t('common.confirmModal.close') }}
          </button>
        </div>
      </b-card-body>
    </b-card>
  </vue-draggable-resizable>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import VueDraggableResizable from 'vue-draggable-resizable';
import RequestHistory from './RequestHistory';
import RequestInformation from './RequestInformation';
import { waiting } from 'core/helpers';
import { EventBus } from 'core/eventBus';
import find from 'lodash/find';
import ContactInformation from '../CustomerSupportPanel/contactInformation';
import { setTimeout } from 'timers';

export default {
  components: {
    VueDraggableResizable,
    RequestHistory,
    RequestInformation,
    ContactInformation
  },
  props: {
    isChat: {
      type: Boolean,
      default: true
    },
    conversation: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    isCustomerSupportPanel: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      drag: {
        w: 800,
        h: 500,
        minh: 500,
        minw: 600,
        x: 0,
        y: 100
      },
      isFull: false,
      formData: {},
      formDataClone: {},
      isRequestInformation: false,
      itemSelected: {},
      selectedTicket: {},
      isUpdate: false,
      showCollapse: true,
      savedData: false,
      editData: false,
      supportFormData: [],
      isView: true,
      isLoadingCSF: false,
      submitCustomerSupportClose: false,
      isEditSubmittedForm: false,
      updateCustomerSupport: false
    };
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapGetters('session', ['conversations']),
    ...mapState('customerSupport', ['openCSFMode']),

    disabledFormInViewMode() {
      return this.openCSFMode === 'view' ? true : false;
    },
    isAllowEditSubmittedForm() {
      return this.disabledFormInViewMode && this.itemSelected.isAllowEdit;
    }
  },
  watch: {
    savedData() {
      this.checkSavedData();
    },
    isRequestInformation() {
      this.checkSavedData();
    },
    item() {
      this.checkSavedData();
    },
    customerSupportData(newValue) {
      this.supportFormData = [...newValue];
    }
  },
  async created() {
    this.xPanelFunc();

    const { ticket = {} } = this.conversation || {};
    this.selectedTicket = ticket;
    if (this.conversation.historyEditItem && !this.isChat) {
      this.isRequestInformation = true;
      this.itemSelected = { ...this.conversation.historyEditItem };
      this.showCollapse = false;
    }

    EventBus.$on('confirmSavedData', () => {
      this.openSaved();
    });
    EventBus.$on('deleteFromHistory', type => {
      if (type === 'itemSelectDeleted') this.isRequestInformation = false;
    });
  },
  beforeDestroy() {
    EventBus.$off('confirmSavedData');
    EventBus.$off('deleteFromHistory');
    EventBus.$off('expandProfileCSP');
  },
  methods: {
    ...mapActions('personal', ['setPersonalGlobal']),
    ...mapActions('chat', ['getConversationTicketById']),

    handleSubmitForm() {
      this.isLoadingCSF = true;
      EventBus.$emit('submitCustomerSupport');
      this.eventSubmitForm();
    },

    handleSubmitToClose() {
      this.eventSubmitForm();
      this.isLoadingCSF = true;
      this.submitCustomerSupportClose = true;
    },

    handleSupportFormData(data) {
      this.supportFormData = data;
      const { ticketId } = this.conversation || {};
      const draftData = find(this.supportFormData, {
        status: 'draft',
        ticketId
      });
      if (draftData) {
        if (this.isChat) {
          this.showCollapse = false;
          this.getSelectItem(draftData);
        }
      }
    },
    changeContactInfo(value) {
      this.savedData = value;
    },

    openSaved() {
      const msgProfile = this.savedData
        ? `${this.$t('src.components.AppLeftMenu.index.user_profile')}`
        : '';
      const msgAnd =
        this.savedData && this.editData
          ? ` ${this.$t('src.modules.chat.components.CustomerSupport.index.and')} `
          : '';
      const msgRequestInfo = this.editData
        ? `${this.$t('src.modules.chat.components.CustomerSupportPanel.index.request_information')}`
        : '';
      this.$baseConfirm({
        message: `${this.$t(
          'src.modules.chat.components.CustomerSupportPanel.index.unsaved_changes_in',
          { params: ` ${msgProfile}${msgAnd}${msgRequestInfo}` }
        )}${this.$t(
          'src.modules.chat.components.CustomerSupportPanel.index.will_be_lost_if_you_decide_to_continue_are_you_sure_you_want_to_leave_this_form'
        )}`
      })
        .then(() => {
          this.handleYes();
        })
        .catch(() => {});
    },

    handleEditData(editData) {
      this.editData = editData;
    },

    checkSavedData() {
      if (this.savedData || this.editData) {
        this.$emit('savedData', true);
      } else {
        this.$emit('savedData', false);
      }
    },

    checkSubmitContact(status) {
      if (this.submitCustomerSupportClose) {
        EventBus.$emit('submitCustomerSupportClose', status);
        this.submitCustomerSupportClose = false;
      }

      if (this.updateCustomerSupport) {
        EventBus.$emit('updateCustomerSupport', status);
        this.updateCustomerSupport = false;
      }
    },

    handleInvalidContact(message) {
      const { msgUnFill, msgInvalid } = message;
      EventBus.$on('expandProfileCSP', () => {
        if (!this.showCollapse) this.showCollapse = true;
      });
      if (msgUnFill) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgUnFill,
          multi: true
        });
      }
      setTimeout(() => {
        if (msgInvalid) {
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: msgInvalid,
            multi: true
          });
        }
      }, 10);
    },
    handleSuccessContact(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });
      this.isRequestInformation = true;
    },
    async handleInvalidRequest(message) {
      const { msgUnFill, msgInvalid, msgError } = message;
      if (msgError)
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgError,
          multi: true
        });
      if (msgUnFill)
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgUnFill,
          multi: true
        });

      setTimeout(() => {
        if (msgInvalid)
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: msgInvalid,
            multi: true
          });
      }, 10);
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },
    async handleSuccessRequest(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });

      this.isRequestInformation = true;
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },
    handleInvalidHistory(error) {
      this.$baseNotification.error({
        title: this.$t('src.core.App.error'),
        message: error,
        multi: true
      });
    },
    handleSuccessHistory(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });
      this.isRequestInformation = true;
      this.$emit('close', false);
    },
    getValidateRule(component) {
      let rule = '';
      if (component.required) rule = 'required';

      if (component.rules && component.rules !== '') {
        rule = `${rule}|${component.rules}`;
      }
      return rule;
    },
    xPanelFunc() {
      let x = window.innerWidth;
      this.drag.x = x / 2 - this.drag.w / 2;
    },
    handleResize() {
      this.isFull = !this.isFull;
    },
    optionsData(key) {
      if (key === 'gender') {
        return [
          { value: 'Male', key: 'male' },
          { value: 'Female', key: 'female' }
        ];
      }
      return [];
    },
    handleRequestInformation() {
      const checkConversation = find(this.conversations, {
        id: this.conversation.id
      });
      if (checkConversation) {
        this.isRequestInformation = true;
        this.itemSelected = {};
        setTimeout(() => {
          EventBus.$emit('newRequest');
        }, 500);
      }
    },

    clearData(obj) {
      delete obj.updatedAt;
      delete obj.createdAt;
      if (this.isUpdate)
        for (let propName in obj) {
          if (obj[propName] === '') {
            obj[propName] = null;
          }
        }
      else
        for (let propName in obj) {
          if (obj[propName] === '' || obj[propName] === null) {
            delete obj[propName];
          }
        }
      return obj;
    },
    async getSelectItem(data) {
      this.isLoadingCSF = true;
      this.isRequestInformation = true;
      this.itemSelected = { ...data };

      const { ticket = {} } = this.conversation || {};
      const { ticketId, conversationId } = data;
      if (ticketId === ticket.id && conversationId === ticket.conversationId) {
        this.selectedTicket = ticket;
      } else {
        const conv = await this.getConversationTicketById({ ticketId, conversationId });
        this.selectedTicket = (conv && conv.ticket) || {};
      }
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },
    handleClose() {
      if (this.openCSFMode === 'view' && !this.isEditSubmittedForm) {
        EventBus.$emit('editSubmittedFormMode', false);
        this.$emit('close', false);
      } else if (this.savedData || this.editData) {
        const msgProfile = this.savedData
          ? `${this.$t('src.components.AppLeftMenu.index.user_profile')}`
          : '';
        const msgAnd =
          this.savedData && this.editData
            ? ` ${this.$t('src.modules.chat.components.CustomerSupport.index.and')} `
            : '';
        const msgRequestInfo = this.editData
          ? `${this.$t(
              'src.modules.chat.components.CustomerSupportPanel.index.request_information'
            )}`
          : '';
        this.$baseConfirm({
          message: `${this.$t(
            'src.modules.chat.components.CustomerSupportPanel.index.unsaved_changes_in',
            { params: ` ${msgProfile}${msgAnd}${msgRequestInfo}` }
          )}${this.$t(
            'src.modules.chat.components.CustomerSupportPanel.index.will_be_lost_if_you_decide_to_continue_are_you_sure_you_want_to_leave_this_form'
          )}`
        })
          .then(() => {
            this.handleYes();
          })
          .catch(() => {});
      } else {
        this.$emit('close', false);
      }
    },
    handleNo() {
      this.$refs.savedConfirmModal.hide();
    },
    handleYes() {
      this.$emit('close', false);
    },
    handleSuccessToClose() {
      this.$emit('close', false, 'successToClosePopup');
    },
    checkHandleSubmitToClose({ isView }) {
      this.isView = isView;
    },
    checkHandleSubmitForm({ isView }) {
      this.isView = isView;
    },
    handleEditSubmitForm() {
      this.isEditSubmittedForm = true;
      EventBus.$emit('editSubmittedFormMode', true);
    },
    handleSaveSubmitForm() {
      this.eventSubmitForm();
      this.isLoadingCSF = true;
      this.updateCustomerSupport = true;
    },
    eventSubmitForm() {
      const contactInformation = this.$refs['contactInformation'];
      contactInformation && contactInformation.listenEventSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.draggable > .card > .card-body {
  overflow: auto;
}
.fullScreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
}
.draggable {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  .dragHeader > .card-header:first-child {
    padding: 10px;
    background-color: #2b2a3c;
    cursor: grabbing;
    h6,
    .close {
      color: #fff;
      font-weight: 600;
    }
  }
  .card-header {
    padding: 0.8rem 1.5rem;
  }
  .card-body {
    padding: 0.5rem;
  }
}
</style>
