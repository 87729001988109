<template>
  <div>
    <b-carousel
      id="carousel1"
      v-model="slide"
      :interval="4000"
      style="text-shadow: 1px 1px 2px #333; background: #000"
      controls
      indicators
      background="#ababab"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(carousel, index) in carousels" :key="index">
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            alt="image slot"
            :src="carousel.media.image.src"
            @error="$event.target.src = noImage"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import noImage from 'assets/images/no-image.png';
export default {
  props: {
    carousels: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      slide: 0,
      sliding: null,
      noImage
    };
  },

  methods: {
    onSlideStart() {},
    onSlideEnd() {}
  }
};
</script>

<style lang="scss" scoped>
.carousel {
  max-height: 400px;
  /deep/ .w-100 {
    width: auto !important;
  }

  /deep/ img {
    max-height: 400px;
    margin: 0 auto;
  }
}
</style>
