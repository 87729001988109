<template>
  <div :name="this.showName" class="page-aside-menu">
    <div class="page-aside-inner">
      <a class="brand">
        <img src="~assets/images/at-logo.png" />
      </a>
      <div
        class="scrollable m-t-10 vb vb-light-blue"
        v-bar
        :style="{
          'max-height': `calc(100% - 200px)`,
          height: `calc(100% - 200px)`
        }"
      >
        <b-nav>
          <b-nav-item v-if="displayChatBoxPhoneAgentIcon" @click="goPhoneAgentChatBox">
            <i class="fas fa-comments"></i>
          </b-nav-item>
          <b-nav-item
            v-if="displayDashboardIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'chat' && this.selectedGroupConv === -1"
            :title="$t('src.components.AppLeftMenu.index.dashboard')"
            @click="goDashBoard"
          >
            <i class="material-icons">dashboard</i>
          </b-nav-item>
          <b-nav-item
            v-if="displayChatBoxIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="
              this.showName === 'chat' &&
                this.selectedGroupConv !== 3 &&
                this.selectedGroupConv !== -1
            "
            :title="$t('src.components.AppLeftMenu.index.chat')"
            @click="goHome"
          >
            <i class="fas fa-comments"></i>
          </b-nav-item>
          <b-nav-item
            v-if="displayCommentsIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'chat' && this.selectedGroupConv === 3"
            :title="$t('src.components.AppLeftMenu.index.comments')"
            @click="handleBtnComment"
          >
            <i class="material-icons">comment</i>
          </b-nav-item>
          <b-nav-item
            v-if="displayCustomerSupportHistoryIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'support'"
            :title="$t('src.components.AppLeftMenu.index.customer_support_history')"
            @click="handleBtnCustomerSupportHistory"
          >
            <i class="material-icons">history</i>
          </b-nav-item>
          <b-nav-item
            v-if="displayReportIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'report'"
            :disabled="agentWorkAtHome"
            :title="
              agentWorkAtHome
                ? $t('src.components.AppLeftMenu.index.workFromHome')
                : $t('src.components.AppLeftMenu.index.report')
            "
            @click="handleBtnReport"
          >
            <i class="material-icons">receipt</i>
          </b-nav-item>
          <b-nav-item
            v-if="displaySearchIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'search'"
            :title="$t('src.components.AppLeftMenu.index.search')"
            @click="handleBtnSearch"
          >
            <i class="material-icons">search</i>
          </b-nav-item>
          <b-nav-Item
            v-if="displayMessageReplaceIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'replacingMessages'"
            :title="$t('src.components.AppLeftMenu.index.replacing_messages')"
            @click="handleBtnReplacingMessages"
          >
            <i class="material-icons">find_replace</i>
          </b-nav-Item>
          <b-nav-item
            v-if="displayFileManagementIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'file-management'"
            :title="$t('src.components.AppLeftMenu.index.file')"
            @click="handleBtnFile"
          >
            <i class="material-icons">folder</i>
          </b-nav-item>
          <b-nav-item
            v-if="displayAccessLogIcon"
            v-b-tooltip.hover.right.viewport
            v-bind:active="this.showName === 'access-log'"
            :title="$t('src.components.AppLeftMenu.index.access_log')"
            @click="handleBtnAccessLog"
          >
            <i class="material-icons">api</i>
          </b-nav-item>
        </b-nav>
      </div>

      <!-- NOTIFICATION -->
      <div v-if="displayBellIcon" class="app-notification" v-click-outside="closeNotifyScreen">
        <el-badge
          :hidden="!numberNotifications"
          :value="numberNotifications"
          :max="99"
          class="item"
        >
          <i
            id="notification-for-agent"
            :class="'fas fa-bell notify-icon' + (!numberNotifications ? ' none' : '')"
            @click="() => (numberNotifications ? _displayNotifyScreen() : null)"
          ></i>
          <BootstrapTooltipCustom
            v-if="!numberNotifications"
            id="notification-for-agent"
            :content="$t('src.components.AppLeftMenu.index.no_notification')"
          />
        </el-badge>
        <div v-if="displayNotifyScreen" v-loading="loadingNotify" class="notification-scroll">
          <div class="notification-header">
            <p class="notification-header-title">
              {{ $t('src.components.AppLeftMenu.index.notifications') }}
            </p>
            <label v-if="hasNewNotificationCame" style="margin-top: 10px">
              {{ $t('src.components.AppLeftMenu.index.you_have_new_notifications') }}
              <el-link class="elink-notify-refresh" type="primary" @click="loadNewNotification">{{
                $t('src.components.AppLeftMenu.index.refresh')
              }}</el-link>
            </label>
            <button
              type="button"
              class="close"
              aria-label="Close"
              style="width: 60px"
              @click="_displayNotifyScreen"
            >
              <span class="close-btn-notification" aria-hidden="true">&times;</span>
            </button>
          </div>
          <DynamicScroller
            id="notification-dynamicScroller"
            :items="listNotifyTicket"
            :min-item-size="50"
            :prerender="10"
            :emit-update="true"
            @scroll.native.passive="handleScroll"
            key-field="ticketId"
            class="scroller"
          >
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem
                v-if="item.scroll"
                :item="item"
                :active="active"
                :data-index="index"
                :size-dependencies="[item.ticket]"
              >
                <div
                  v-if="item.scroll === 'up'"
                  v-loading="true"
                  element-loading-spinner="el-icon-loading"
                  style="height: 50px; margin-top: 10px"
                >
                </div>
                <div
                  v-else
                  v-loading="true"
                  element-loading-spinner="el-icon-loading"
                  style="height: 50px"
                >
                </div>
              </DynamicScrollerItem>
              <DynamicScrollerItem
                v-else
                :item="item"
                :active="active"
                :size-dependencies="[item.ticket]"
                :data-index="index"
                class="dynamic-scroller-item-mvt"
              >
                <!-- Avatar User -->
                <div class="container" @click="handleMessageNotification(item)">
                  <div class="row">
                    <a class="avatar notify" href @click.prevent.stop>
                      <img
                        :src="
                          (item && item.agentInfo && item.agentInfo.pictureUrl) ||
                            (item && item.userInfo && item.userInfo.pictureUrl) ||
                            noAvatar
                        "
                        class="img-fluid"
                        alt="..."
                        @error="$event.target.src = noAvatar"
                      />
                    </a>
                    <div class="pl-2 notify-message">
                      <div v-if="item.link && item.link.type === 'VIRUS_DETECTION'">
                        <span v-html="item.notifyMessage"></span>
                      </div>
                      <div v-else-if="!item.agentInfo">
                        <b>{{
                          item.userInfo.name || item.userInfo.fullName || item.userInfo.firstName
                        }}</b>
                        {{ item.notifyMessage }}
                      </div>
                      <div v-else>
                        <b>
                          {{ $t('src.modules.chat.components.OverviewBox.index.agent') }}
                          [{{ `${item.agentInfo.firstName} ${item.agentInfo.lastName}` }}]
                        </b>
                        {{ item.notifyMessage }}
                      </div>
                      <div class="dropdown-notification-createdAt">
                        {{ formatTimeData(item && item.message && item.message.createdAt) }}
                      </div>
                    </div>
                  </div>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
      <!-- END NOTIFICATION -->

      <div class="profile">
        <b-dropdown
          id="ddown-right"
          text="Profile"
          variant="link"
          class="w-100"
          dropright
          no-caret
          @hide="handleHideProfile"
        >
          <template slot="button-content">
            <i
              class="fa fa-ellipsis-h w-100 m-b-5 d-block w-auto text-center text-white p-0 m-r-0"
            ></i>
            <a class="avatar avatar-online shadow">
              <img
                v-b-tooltip.hover
                :title="user.firstName"
                :src="avatar"
                :alt="user.firstName"
                class="img-fluid"
                @error="$event.target.src = noAvatar"
              />
            </a>
          </template>
          <b-dropdown-item @click="handleAvatarClick">
            <div>
              <div>
                <a class="avatar avatar-online shadow">
                  <img
                    :src="avatar"
                    :alt="user.firstName"
                    class="img-fluid"
                    @error="$event.target.src = noAvatar"
                  />
                </a>
              </div>
              <div>
                <span>{{ user.firstName }}</span>
                <br />
                <small>{{ user.email }}</small>
                <br />
                <small>{{ mappingRole[user.role] }}</small>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-form class="item-status">
            <button
              id="btn-status"
              :style="statusStyle"
              class="btn btn-status"
              @click="e => e.preventDefault()"
            >
              <i v-if="updating" class="fa fa-spinner fa-spin fa-lg" />
              <span v-if="!updating">{{ truncate(statusLabel[currentStatus], 30) }}</span>
            </button>
            <b-popover
              ref="popover"
              target="btn-status"
              triggers="click blur"
              placement="auto"
              @hidden="preventClose = false"
              @show="preventClose = true"
            >
              <div class="px-3">
                <button
                  v-for="(status, index) in lstStatus"
                  :key="index"
                  :style="{
                    backgroundColor: statusColor[status],
                    color: status === agentStatus.AWAY ? 'black' : 'white',
                    fontSize: '11px'
                  }"
                  :disabled="status === currentStatus"
                  class="btn col-12 mb-2"
                  @click="handleChangeStatus(status)"
                >
                  {{ truncate(statusLabel[status], 30) }}
                </button>
              </div>
            </b-popover>
          </b-dropdown-form>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="handleBtnProfileClick">{{
            $t('src.components.AppLeftMenu.index.user_profile')
          }}</b-dropdown-item>
          <b-dropdown-item v-if="displayProfileSettingsPage" @click="handleBtnSettingClick">{{
            $t('src.components.AppLeftMenu.index.settings')
          }}</b-dropdown-item>
          <b-dropdown-item @click="handleBtnLogoutClick">{{
            $t('src.components.AppLeftMenu.index.logout')
          }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import noAvatar from 'assets/images/no-avatar.png';
import {
  SERVING_STATE,
  AGENT_STATUS,
  AGENT_STATUS_COLOR,
  DEFAULT_AGENT_STATUS_LABEL,
  TICKET_STATUS,
  MAPPING_ROLE,
  NOTIFICATION_SETTINGS_KEY
} from 'core/constants';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { getGroupConvId } from 'core/helpers';
import { convertTimeUTC } from 'core/helpers';
import { fetchDataAndGotoTicket, isDisplayUI } from 'core/helpers';
import { BDropdownForm } from 'bootstrap-vue';
import { loadLanguageAsync } from 'locales';
import fetchAPI from 'core/fetchAPI';
import ClickOutside from 'vue-click-outside';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    'b-dropdown-form': BDropdownForm,
    BootstrapTooltipCustom
  },

  directives: {
    ClickOutside
  },

  props: {
    showName: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {
      noAvatar,
      displayLanguage: 'en_US',
      statusColor: AGENT_STATUS_COLOR,
      agentStatus: AGENT_STATUS,
      preventClose: false,
      updating: false,
      mappingRole: MAPPING_ROLE || {},
      scrollUpObject: {},
      scrollDownObject: {},
      listNotifyTicket: [],
      loadingNotify: false,
      clickRefreshNotification: false
    };
  },

  computed: {
    ...mapState('session', [
      'user',
      'channelsMap',
      'agentWorkAtHome',
      'notificationsMap',
      'numberNotifications',
      'displayNotifyScreen',
      'firstTimeInNotifications',
      'lastTimeInNotifications',
      'hasNewNotificationCame',
      'projectMaps'
    ]),
    ...mapGetters('session', [
      'me',
      'unreadMap',
      'conversationsMap',
      'tickets',
      'notificationSettings',
      'projectsExportPermission',
      'configs'
    ]),
    ...mapState('chat', ['selectedGroupConv', 'messages']),

    menusConfigs() {
      return this.configs?.menus || {};
    },

    profileConfigs() {
      return this.configs?.profile || {};
    },

    displayDashboardIcon() {
      return isDisplayUI(this.menusConfigs, 'dashboardIcon');
    },

    displayChatBoxPhoneAgentIcon() {
      return isDisplayUI(this.menusConfigs, 'chatBoxPhoneAgentIcon');
    },

    displayChatBoxIcon() {
      return isDisplayUI(this.menusConfigs, 'chatBoxIcon');
    },

    displayCommentsIcon() {
      return this.me?.permission?.commentMgnt && isDisplayUI(this.menusConfigs, 'commentsIcon');
    },

    displayCustomerSupportHistoryIcon() {
      return isDisplayUI(this.menusConfigs, 'customerSupportHistoryIcon');
    },

    displayReportIcon() {
      return (
        this.projectsExportPermission.length > 0 && isDisplayUI(this.menusConfigs, 'reportIcon')
      );
    },

    displaySearchIcon() {
      return isDisplayUI(this.menusConfigs, 'searchIcon');
    },

    displayMessageReplaceIcon() {
      return isDisplayUI(this.menusConfigs, 'messageReplaceIcon');
    },

    displayFileManagementIcon() {
      if (isEmpty(this.projectMaps)) return false;
      const hasGroup = Object.values(this.projectMaps).findIndex(({ hasGroup }) => hasGroup) !== -1;
      return isDisplayUI(this.menusConfigs, 'fileManagementIcon', hasGroup);
    },

    displayAccessLogIcon() {
      if (isEmpty(this.projectMaps)) return false;
      const hasGroup = Object.values(this.projectMaps).findIndex(({ hasGroup }) => hasGroup) !== -1;
      return isDisplayUI(this.menusConfigs, 'accessLogIcon', hasGroup);
    },

    displayBellIcon() {
      return isDisplayUI(this.configs, 'bellIcon');
    },

    displayProfileSettingsPage() {
      return isDisplayUI(this.profileConfigs, 'settingsPage');
    },

    avatar() {
      if (this.user && this.user.pictureUrl) return this.user.pictureUrl;
      return this.noAvatar;
    },

    lstStatus() {
      return [
        this.agentStatus.ONLINE,
        this.agentStatus.BUSY,
        this.agentStatus.AWAY,
        this.agentStatus.LUNCH,
        this.agentStatus.BREAK
      ];
    },

    statusLabel() {
      const { statusLabel = DEFAULT_AGENT_STATUS_LABEL } = this.me || {};
      const status = statusLabel[this.displayLanguage]
        ? statusLabel[this.displayLanguage]
        : DEFAULT_AGENT_STATUS_LABEL;
      return status;
    },

    currentStatus() {
      const status = (this.me && this.me.status) || AGENT_STATUS.ONLINE;
      return status;
    },

    statusStyle() {
      return `border-color: ${this.statusColor[this.currentStatus]}`;
    }
  },

  watch: {
    displayNotifyScreen(newValue) {
      if (!newValue) return true;
      this.loadNewNotification();
    },
    notificationsMap(newValue) {
      if (isEmpty(newValue)) this.listNotifyTicket = [];

      let list = [];
      Object.values(this.notificationsMap).forEach(notify => {
        const { link = {}, user = {}, agent = {}, ticket = {} } = notify || {};
        const { lastMessageAt = '' } = link;
        const { type = null, message = null } = link;

        if (!this.channelsMap[ticket.channelId])
          return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

        const item = { link, ticket };
        const SEND_FILE = NOTIFICATION_SETTINGS_KEY.SEND_FILE;
        const WAITING_LIST = NOTIFICATION_SETTINGS_KEY.WAITING_LIST;
        const WARNING_WORD = NOTIFICATION_SETTINGS_KEY.WARNING_WORD;
        const USER_MENTION = NOTIFICATION_SETTINGS_KEY.USER_MENTION;
        const VIRUS_DETECTION = NOTIFICATION_SETTINGS_KEY.VIRUS_DETECTION;
        const { id, assignee, agentId: agId, channelId } = ticket;
        const { name: channelName = '' } = this.channelsMap[channelId] || {};
        item['channelName'] = channelName;
        item['ticketId'] = ticket.id;
        switch (type) {
          case SEND_FILE:
            {
              const settings = this.notificationSettings.SEND_FILE;
              if (!settings.NOTIFY_ENABLE)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

              item.userInfo = { ...user };

              const key = 'src.components.AppLeftMenu.index.has_sent_a_file_to_bot';
              item.notifyMessage = ' ' + this.$t(key, { channelName });
              item.message = { ...JSON.parse(message), createdAt: lastMessageAt };
            }
            break;

          case WAITING_LIST:
            {
              const settings = this.notificationSettings.WAITING_LIST;
              if (!settings.NOTIFY_ENABLE)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

              item.userInfo = { ...user };
              item.message = { ...JSON.parse(message), createdAt: lastMessageAt };
              if ((assignee === id && agId === id) || assignee === TICKET_STATUS.FORWARD_GROUP) {
                const key = 'src.components.AppLeftMenu.index.is_waiting_in_common_list_in';
                item.notifyMessage = ' ' + this.$t(key, { channelName });
              } else {
                const key = 'src.components.AppLeftMenu.index.is_waiting_in_your_list_in';
                item.notifyMessage = ' ' + this.$t(key, { channelName });
              }
            }
            break;

          case WARNING_WORD:
            {
              const settings = this.notificationSettings.WARNING_WORD;
              if (!settings.NOTIFY_ENABLE)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

              item.userInfo = { ...user };

              const key = 'src.components.AppLeftMenu.index.has_sent_a_warning_word';
              item.notifyMessage = ' ' + this.$t(key, { channelName });
              item.message = { ...JSON.parse(message), createdAt: lastMessageAt };
              const { platform } = item.message;
              if (['agent'].includes(platform)) {
                item.agentInfo = { ...agent };
              }
            }
            break;
          case USER_MENTION:
            {
              const translateKeys = {
                story_mention: 'src.components.AppLeftMenu.index.has_mention_us_instory',
                story_reply: 'src.components.AppLeftMenu.index.has_reploy_to_our_story'
              };
              const settings = this.notificationSettings.USER_MENTION;
              if (!settings.NOTIFY_ENABLE)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

              item.userInfo = { ...user };

              item.message = { ...JSON.parse(message), createdAt: lastMessageAt };
              const { platform, type: messageType = '' } = item.message;
              const key = translateKeys[messageType];
              item.notifyMessage = ' ' + this.$t(key, { channelName });
              if (['agent'].includes(platform)) {
                item.agentInfo = { ...agent };
              }
            }
            break;

          case VIRUS_DETECTION:
            {
              item.userInfo = { ...user };
              const userName =
                item.userInfo.name || item.userInfo.fullName || item.userInfo.firstName;
              const key = 'src.components.AppLeftMenu.index.has_sent_a_file_or_url_with_a_virus';
              item.notifyMessage = this.$t(key, { userName, channelName });
              item.message = { ...JSON.parse(message), createdAt: lastMessageAt };
            }
            break;

          default:
            {
              const settings = this.notificationSettings.NEW_MESSAGE;
              if (!settings.NOTIFY_ENABLE)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));
              if (agId !== this.user.id || assignee !== this.user.id)
                return this.deleteNotification(pick(link, ['ticketId', 'agentId']));

              item.userInfo = { ...user };

              const key = 'src.components.AppLeftMenu.index.sent_you_a_message';
              item.notifyMessage = ' ' + this.$t(key, { channelName });
              item.message = { createdAt: lastMessageAt };
            }
            break;
        }
        list.push(item);
      });

      if (list.length > 1)
        list = list.sort((a, b) => {
          return -a.message.createdAt.localeCompare(b.message.createdAt);
        });

      this.listNotifyTicket = list;
    }
  },

  async created() {
    const user = await this.getSessionUser();
    let language = localStorage.getItem('language');
    if (language === 'default') {
      language = user.primaryLanguage[0].replace(/_/g, '-');
      localStorage.setItem('language', language);
      loadLanguageAsync(language);
    } else loadLanguageAsync(language);
    this.displayLanguage = language.replace('-', '_');
  },

  methods: {
    ...mapActions('session', [
      'setUser',
      'logout',
      'getSessionUser',
      'pushReadToWebChat',
      'addUserToPeople',
      'setWaitingMode',
      'addNewConversation',
      'addTicketLocal',
      'updateAgentStatus',
      'loadNotifications',
      'setDisplayNotifyScreen',
      'setFirstTimeInNotifications',
      'setLastTimeInNotifications',
      'setNewNotificationsCame',
      'setTicketsSearchClear',
      'deleteNotification'
    ]),
    ...mapActions('global', ['pinRightPanel', 'setGlobalReady', 'setGlobalLoadingMap']),
    ...mapActions('chat', [
      'getMessagesByConversationId',
      'setShowView',
      'setSelectedConversation',
      'setSelectedGroupConversation',
      'setChatViewingGroup',
      'setReady',
      'getConversationTicketById',
      'setShowProductPanel',
      'setShowConversationalPanel',
      'setSearchBoxFilter',
      'setShowSupportPanel'
    ]),
    ...mapActions('comment', ['setSelectedChannel']),

    closeNotifyScreen() {
      if (this.displayNotifyScreen === false) return null;
      if (this.clickRefreshNotification) return null;
      this.setDisplayNotifyScreen(false);
      this.setNewNotificationsCame([]);
    },

    _displayNotifyScreen() {
      const boolean = !this.displayNotifyScreen;
      this.setDisplayNotifyScreen(boolean);
      if (!boolean) this.setNewNotificationsCame([]);
    },

    truncate(chars, length) {
      if (chars && chars.length > length) {
        return (chars = chars.substring(0, length) + '...');
      }
      return chars;
    },

    handleBtnProfileClick() {
      this.pinRightPanel(false);
      this.$router.push('/profile/userInformation');
    },

    handleBtnSettingClick() {
      this.pinRightPanel(false);
      this.$router.push('/profile/setting');
    },

    handleBtnPasswordClick() {
      this.pinRightPanel(false);
      this.$router.push('/password');
    },

    handleAvatarClick() {
      this.pinRightPanel(false);
      this.$router.push('/profile/userInformation');
    },

    handleBtnLogoutClick() {
      this.pinRightPanel(false);
      this.$baseConfirm({
        message: this.$t('src.components.AppLeftMenu.index.are_you_sure_you_want_to_logout'),
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false
      })
        .then(() => {
          this.logout(this.user).then(() => this.$router.replace({ path: '/login' }));
        })
        .catch(() => {});
    },

    handleBtnCustomerSupportHistory() {
      this.pinRightPanel(false);
      this.$router.push('/customer-support-history');
    },

    handleBtnReport() {
      this.pinRightPanel(false);
      this.$router.push('/report');
    },

    handleBtnSearch() {
      this.pinRightPanel(false);
      this.$router.push('/search-management');
    },

    handleBtnReplacingMessages() {
      this.pinRightPanel(false);
      this.$router.push('/replacing-messages');
    },

    handleBtnFile() {
      this.pinRightPanel(false);
      this.$router.push('/file-management');
    },

    handleBtnAccessLog() {
      this.pinRightPanel(false);
      this.$router.push('/access-log');
    },

    handleBtnComment() {
      this.pinRightPanel(false);
      this.setShowView('Overview');
      this.setSelectedConversation({
        conversation: null,
        groupId: SERVING_STATE.COMMENTS
      });
      this.setSelectedChannel({});
      if (this.$route.name !== 'Chat') {
        this.$router.push('/').catch(() => {});
        return;
      }

      if (this.selectedGroupConv !== SERVING_STATE.COMMENTS) {
        this.setSelectedChannel({});
        this.setSelectedGroupConversation(SERVING_STATE.COMMENTS);
      }
    },

    goDashBoard() {
      this.pinRightPanel(false);
      this.setShowView('DashBoard');
      this.setSelectedConversation({
        conversation: null,
        groupId: SERVING_STATE.INACTIVE
      });
    },

    goHome() {
      this.pinRightPanel(false);
      this.setShowView('Overview');
      this.setSelectedConversation({
        conversation: null,
        groupId: SERVING_STATE.AGENT
      });
    },

    goPhoneAgentChatBox() {
      this.$router.push('/');
      this.setShowView('PhoneAgentChatBox');
      EventBus.$emit('resetDataPhoneAgentChatBox');
    },

    handleHideProfile(event) {
      this.preventClose && event.preventDefault();
    },

    handleChangeStatus(status) {
      this.$refs.popover.$emit('close');
      this.updating = true;
      this.updateAgentStatus({ agentId: this.user.id, status })
        .then(() => (this.updating = false))
        .catch(() => (this.updating = false));
    },

    formatTimeData(date) {
      return convertTimeUTC(date) || '';
    },

    async handleMessageNotification(item) {
      await this.setSearchBoxFilter({});
      await this.setTicketsSearchClear({});
      try {
        let { link = {}, ticket = {} } = item || {};
        const { id: ticketId = '', conversationId: id = '' } = ticket;
        if (!id || !ticketId || isEmpty(ticket)) return true;
        this._displayNotifyScreen();

        // set loading full view
        this.setGlobalReady(false);
        const conv = await this.getConversationTicketById({
          conversationId: id,
          ticketId
        }).catch(() => null);
        if (!conv) return this.setGlobalReady(true);
        ticket = { ...ticket, ...conv.ticket };
        this.setGlobalReady(true);
        // close loading full view

        if (item) {
          const groupId = getGroupConvId({ ticket }, this.user);
          if (groupId === -1) {
            // Hide all right pannel
            this.setShowProductPanel(false);
            this.setShowConversationalPanel(false);
            this.setShowSupportPanel(false);
            this.pinRightPanel(false);
            this.setShowView('Overview');
            return false;
          }

          if (!['Chat', 'Conversation'].includes(this.$route.name)) {
            await this.deleteNotification(pick(link, ['ticketId', 'agentId']));
            this.$router.push('/');
          } else this.deleteNotification(pick(link, ['ticketId', 'agentId']));
          this.setGlobalLoadingMap({ action: 'CLICK_NOTIFICATION_GOTO_TICKET', flag: true });
          await fetchDataAndGotoTicket({ conversationId: id, id: ticketId });
          this.setGlobalLoadingMap({ action: 'CLICK_NOTIFICATION_GOTO_TICKET', flag: false });
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log('handleMessageNotification -> e', e);
        this.setGlobalReady(true);
        return false;
      }
    },

    async handleSupportLink() {
      // set loading full view
      this.setGlobalReady(false);
      const { email, firstName, lastName } = this.user;
      // TM operation mapping Id
      const mappingId = process.env.VUE_APP_TM_OPERATION_MAPPING_ID;
      // get api from ENV
      const url = process.env.VUE_APP_TM_API + '/auth/generate';
      // call api
      const fetch = new fetchAPI({});
      const result = await fetch
        .post(url, { loginId: email, firstName, lastName, mappingId }, 1)
        .then(({ data = null }) => data)
        .catch(err => {
          const { data, status } = err.response;
          const { errorCode } = data;
          if (status === 400) {
            this.redirectSupport(null, errorCode);
            return;
          } else {
            this.$log('[C+ Debug] >>[handleSupportLink] >> [FetchAPI Error] >> error: ', err);
          }
        });
      if (result) {
        this.redirectSupport(result.token, null);
      }
      // close loading full view
      this.setGlobalReady(true);
    },

    redirectSupport(token, errorCode) {
      let url = process.env.VUE_APP_TM + '/login/' + token;
      if (errorCode) {
        url = process.env.VUE_APP_TM + '/login?error=' + errorCode;
      }
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.style = 'display: none';
      link.target = '_blank';
      link.href = url;
      link.click();
    },

    scrollTopNotifyItem(value) {
      if (!value) {
        let el = document.getElementById('notification-dynamicScroller');
        el ? (el.scrollTop = 0) : null;
      }
    },

    async loadNewNotification() {
      this.loadingNotify = true;
      this.clickRefreshNotification = true;
      this.setNewNotificationsCame([]);
      await this.loadNotifications({ agentId: this.user.id });
      this.loadingNotify = false;
      this.clickRefreshNotification = false;
      this.scrollTopNotifyItem();
    },

    async handleScroll(e) {
      const { target } = e;
      const { scrollTop, clientHeight, scrollHeight } = target;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.listNotifyTicket.some(i => i.scroll === 'down')) return true;
        this.listNotifyTicket = [
          ...this.listNotifyTicket,
          { scroll: 'down', ticketId: 'scroll-down', ticket: {} }
        ];

        await this.loadNotifications({
          agentId: this.user.id,
          action: 'scroll-down',
          time: this.lastTimeInNotifications
        });

        this.loadingNotify = false;
        if (this.listNotifyTicket.some(i => i.scroll === 'down')) this.listNotifyTicket.pop();
      } else if (scrollTop === 0) {
        if (this.listNotifyTicket.some(i => i.scroll === 'up')) return true;
        this.listNotifyTicket = [
          { scroll: 'up', ticketId: 'scroll-up', ticket: {} },
          ...this.listNotifyTicket
        ];

        await this.loadNotifications({
          agentId: this.user.id,
          action: 'scroll-up',
          time: this.firstTimeInNotifications
        });

        this.loadingNotify = false;
        if (this.listNotifyTicket.some(i => i.scroll === 'up')) this.listNotifyTicket.shift();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.max-height {
  max-height: 500px;
  overflow-y: auto;
}

.fas {
  font-size: x-large;
}
.btn-status {
  border-radius: 100px;
  border-width: 2.5px;
  background-color: white;
  padding: 2px 8px;
  font-size: x-small;
  color: black;
}
.item-status {
  display: flex;
  justify-content: center;
}
.popover {
  width: auto;
}

.notify-icon {
  cursor: pointer;
  color: #fff;
}
.notify-icon.none {
  cursor: not-allowed;
  color: #adb5bd;
}

.notify-icon:hover {
  color: whitesmoke;
}
.avatar.notify {
  width: 32px;
  height: 32px;
}
.notify-message {
  text-align: left;
  max-width: calc(100% - 32px);
}

a.nav-link.disabled {
  cursor: not-allowed;
}
.notification-scroll {
  position: absolute !important;
  background-color: white;
  width: 600px;
  z-index: 9999;
  margin-left: 6px;
  bottom: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;

  .notification-header {
    text-align: center;
    height: 40px;
    border-bottom: 1px solid #ddd;
  }

  .elink-notify-refresh {
    font-size: 0.875rem;
    color: #409eff !important;
    font-style: italic;
  }

  .scroller {
    max-height: 60vh;
    background-color: white;
    width: 598px;
    border-radius: 5px;
  }

  /deep/.vue-recycle-scroller__item-view:hover {
    background-color: #f7f7f7;
    z-index: 10;
    cursor: pointer;
  }

  /deep/.dynamic-scroller-item-mvt {
    min-height: 60px;
    padding-top: 10px;
    padding-left: 15px;
  }

  .notification-header-title {
    float: left;
    margin-top: 6px;
    margin-left: 10px;
    font-weight: bold;
  }

  .close-btn-notification {
    margin-top: 10px;
    font-size: 1.5rem;
    padding-right: 20px;
    width: 100px;
  }
}
</style>
