<template>
  <b-container fluid>
    <h5 v-if="enableExternalUserInfo">{{ $t('src.core.router.personal_info') }}</h5>
    <template v-if="userInfoNoData">
      <br />
      <h6 class="text-center">{{ $t('common.text.no_data') }}</h6>
    </template>
    <profile-item
      v-for="(profile, index) in profiles"
      :key="$uuid.v4() + '-' + index"
      v-bind="profile"
    />
    <template v-if="enableExternalUserInfo">
      <br />
      <h5>{{ $t('src.modules.search-management.profile.index.external_system_data') }}</h5>
      <profile-item
        v-for="(profile, index) in externalUserInfo"
        :key="$uuid.v4() + '-' + index"
        v-bind="profile"
      />
      <template v-if="externalNoData">
        <br />
        <h6 class="text-center">{{ $t('common.text.no_data') }}</h6>
      </template>
      <template v-if="externalError">
        <br />
        <h6
          class="text-center"
        >{{ $t('src.modules.search-management.profile.index.not_available') }}</h6>
      </template>
    </template>
  </b-container>
</template>

<script>
import ProfileItem from './profile-item';

export default {
  components: {
    ProfileItem
  },

  props: {
    profiles: {
      type: Array,
      default: () => []
    },
    enableExternalUserInfo: {
      type: Boolean,
      default: false
    },
    externalUserInfo: {
      type: Array,
      default: () => []
    },
    externalNoData: {
      type: Boolean,
      default: false
    },
    externalError: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {
    userInfoNoData() {
      return !(this.profiles && this.profiles.length);
    }
  }
};
</script>

<style lang="scss" scoped></style>
