<template>
  <b-modal
    id="ReplacingMessageRequestForm"
    ref="replacingMessageRequestForm"
    v-model="show"
    :title="$t('src.modules.replacing-message.components.RequestForm.index.request_form')"
    @hidden="onCloseModel"
    size="lg"
  >
    <div v-loading="isLoadingForm" class="form-group">
      <el-form
        label-position="right"
        :model="form"
        :label-width="setLabelWidth()"
        @submit.prevent.native="() => {}"
      >
        <!-- Title field -->
        <div class="request-form-title pd-bottom-15-px">
          <base-textarea
            :rows="2"
            v-model="form.title"
            :value="form.title"
            :required="true"
            :name="'title'"
            :label="$t('src.modules.replacing-message.components.RequestForm.index.title')"
            :maxlength="150"
            @change="onChange"
          />
        </div>
        <!-- end title -->

        <!-- Selected Message field -->
        <div class="request-form-selected-msg pd-bottom-15-px">
          <label>{{
            $t('src.modules.replacing-message.components.RequestForm.index.selected_message')
          }}</label>
          <div class="selected-message">
            <p v-html="formatWarnings(escapeHtmlChar(form.selectedMessage))"></p>
          </div>
        </div>
        <!-- end Selected Message -->

        <!-- Replacing Keywords field -->
        <el-form-item
          :label="
            $t('src.modules.replacing-message.components.RequestForm.index.replacing_keywords')
          "
          prop="selectedMessage"
          :rules="{
            required: true,
            message: $t(
              'src.modules.replacing-message.components.RequestForm.index.keyword_is_required'
            )
          }"
        >
          <el-tag
            v-for="(tag, index) in form.replacingKeywords"
            :key="tag + '_' + index"
            :id="tag + '_' + index"
            closable
            :disable-transitions="true"
            @close="handleDeleteKeyWord(tag)"
          >
            <p :id="index" class="keyword-tag">{{ tag }}</p>
            <b-tooltip
              :target="tag + '_' + index"
              :delay="100"
              boundary="viewport"
              placement="top"
              triggers="hover"
              >{{ tag }}</b-tooltip
            >
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="mini"
            maxlength="100"
            show-word-limit
            autofocus
            @keypress.enter.native="handleAddNewKeyWord"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInputKeyWord"
            >+
            {{
              $t('src.modules.replacing-message.components.RequestForm.index.new_keyword')
            }}</el-button
          >
        </el-form-item>
        <!-- end -->

        <!-- Replace all messages field -->
        <el-form-item
          :label="
            $t('src.modules.replacing-message.components.RequestForm.index.replace_all_messages')
          "
        >
          <el-switch v-model="form.replaceAllMessages" @change="onChangeReplaceAll"></el-switch>
        </el-form-item>
        <!-- end -->

        <el-form-item
          v-if="displayListMessage"
          :label="$t('src.modules.replacing-message.components.RequestForm.index.message')"
        >
          <label v-if="form.replacingKeywords.length > 0">
            {{
              $t(
                'src.modules.replacing-message.components.RequestForm.index.message_will_be_affected',
                { number: msgUserTicket.length }
              )
            }}
          </label>
          <ul v-loading="isLoading" class="infinite-list" style="overflow: auto">
            <li v-for="(msg, index) in msgUserTicket" :key="index" class="infinite-list-item">
              <span
                ><p
                  style="margin-bottom: 0px"
                  v-html="formatWarnings(escapeHtmlChar(msg.content))"
                ></p
              ></span>
            </li>
          </ul>
        </el-form-item>
      </el-form>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-btn
        class="float-right"
        variant="primary"
        @click="onSubmit('NEW')"
        :disabled="disableSaveBtn"
        >{{ $t('common.text.submit') }}</b-btn
      >
      <b-btn
        class="float-right"
        variant="primary"
        style="margin-left: 10px"
        @click="onSubmit('DRAFT')"
        :disabled="disableSaveBtn"
        >{{ $t('src.modules.chat.components.CustomerSupport.index.save_draft') }}</b-btn
      >
      <b-btn class="float-right" variant="default" @click="onCloseModel">{{
        $t('common.confirmModal.cancel')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import uniq from 'lodash/uniq';
import { uuid } from 'vue-uuid';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { EventBus } from 'core/eventBus';
import { mapActions, mapState, mapGetters } from 'vuex';
import { urlify, escapeHtmlChar } from 'core/helpers';
import { validateKeyword } from '../../store/helpers';
import { handleReplaceMessage } from '../../store/helpers';
import { REPLACING_MESSAGE_REQUEST_STATUS } from 'core/constants';

function defaultState() {
  return {
    show: false,
    disableSaveBtn: false,
    inputVisible: false,
    inputValue: '',
    displayListMessage: false,
    firstLoadMessages: true,
    isLoadingForm: true,
    loadLatestDraft: true,
    msgUserTicket: [],
    isLoading: false,
    selectedMsg: '',
    warningId: uuid.v4(),
    form: {
      title: '',
      selectedMessage: '',
      replacingKeywords: [],
      replaceAllMessages: false
    }
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('session', ['user', 'channelsMap']),
    ...mapState('replacingMessage', ['messagesUserTicket']),
    ...mapGetters('chat', ['selectedConversation']),

    sortKeyWord() {
      const form = cloneDeep(this.form);
      const { replacingKeywords = [] } = form;
      if (replacingKeywords.length === 0) return [];
      return replacingKeywords.sort((a, b) => b.length - a.length);
    }
  },

  watch: {
    async form(newValue) {
      const { replaceAllMessages, isNew } = newValue;

      /**
       * Load lastest request of message
       * check whether request is DRAFT
       */
      if (isNew && this.loadLatestDraft) {
        const { projectId, channelId } = newValue;
        const { conversationId, ticketId, messageId } = newValue;
        this.isLoadingForm = true;
        const response = await this.getLatestRequest({
          projectId,
          channelId,
          conversationId,
          ticketId,
          messageId,
          status: REPLACING_MESSAGE_REQUEST_STATUS.DRAFT
        });

        if (!isEmpty(response)) {
          this.form = { ...this.form, ...response, isNew: !isNew };
        }
        this.isLoadingForm = false;
        this.loadLatestDraft = false;
      }
      // Get messages of ticket and user when replaceAllMessages is TRUE
      else if (replaceAllMessages) {
        this.displayListMessage = true;
        if (this.firstLoadMessages) {
          this.isLoading = true;
          await this.getMessagesUserTicket({
            ticketId: newValue.ticketId,
            sender: newValue.userId,
            channelId: newValue.channelId,
            projectId: newValue.projectId,
            type: 'text',
            conversationId: newValue.conversationId
          });
          this.filterMsgUserTicket(newValue.replacingKeywords);
          this.isLoading = false;
          this.firstLoadMessages = false;
        }
      }

      this.handleDisableButton();
    },

    messagesUserTicket(newValue) {
      this.msgUserTicket = newValue;
    }
  },

  created() {
    EventBus.$on('replacingMessageRequestForm', (data = null) => this.handlerOpenPopup(true, data));
  },

  destroyed() {
    EventBus.$off('replacingMessageRequestForm', (data = null) =>
      this.handlerOpenPopup(false, data)
    );
  },

  methods: {
    ...mapActions('replacingMessage', [
      'handleRequestReplaceMessage',
      'updateRequests',
      'getMessagesUserTicket',
      'getLatestRequest',
      'getAllRequest'
    ]),
    ...mapActions('global', ['setGlobalReady']),

    setLabelWidth() {
      const language = localStorage.getItem('language');
      return language === 'ja-JP' ? '170px' : '155px';
    },

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    onChange() {
      this.handleDisableButton();
    },

    async onChangeReplaceAll() {
      const { replaceAllMessages } = this.form;
      if (replaceAllMessages) {
        this.displayListMessage = true;

        if (this.firstLoadMessages) {
          this.isLoading = true;
          await this.getMessagesUserTicket({
            ticketId: this.form.ticketId,
            sender: this.form.userId,
            channelId: this.form.channelId,
            projectId: this.form.projectId,
            type: 'text',
            conversationId: this.form.conversationId
          });
          this.filterMsgUserTicket(this.form.replacingKeywords);
          this.isLoading = false;
          this.firstLoadMessages = false;
        }
      } else this.displayListMessage = false;
    },

    async onSubmit(status) {
      try {
        this.setGlobalReady(false);
        if (
          REPLACING_MESSAGE_REQUEST_STATUS.DRAFT === status ||
          REPLACING_MESSAGE_REQUEST_STATUS.NEW === status
        ) {
          const request = {
            ...this.form,
            status
          };

          // validate keyword before submit. One more time
          const { selectedMessage, replaceAllMessages, replacingKeywords } = request;
          if (!replaceAllMessages) {
            let notMatch = false;
            for (let i = 0; i < replacingKeywords.length; i++) {
              const exists = validateKeyword(selectedMessage, replacingKeywords[i]);
              if (!exists) notMatch = true;
            }

            if (notMatch) {
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: this.$t(
                  'src.modules.replacing-message.components.RequestForm.index.input_correct_keyword'
                )
              });
              this.setGlobalReady(true);
              return;
            }
          }
          // end validate keyword before submit
          delete request.updatedAtBy;
          delete request.updatedByAgent;
          delete request.updatedBy;
          delete request.isPermission;
          const res = await this.handleRequestReplaceMessage(request);

          const { isNew } = request;
          if (!isNew) {
            this.updateRequests(res);
            this.$baseNotification.success({
              title: this.$t('src.core.App.success'),
              message: this.$t(
                'src.modules.replacing-message.components.RequestForm.index.update_successful'
              )
            });
          } else {
            this.$baseNotification.success({
              title: this.$t('src.core.App.success'),
              message: this.$t(
                'src.modules.replacing-message.components.RequestForm.index.create_successful'
              )
            });
          }

          await this.getAllRequest({
            agentId: this.user.id,
            projectId: this.form.projectId,
            channelId: this.form.channelId || null
          });
        }
        this.onCloseModel();
        this.setGlobalReady(true);
      } catch (error) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: error.message
        });
        this.setGlobalReady(true);
      }
    },

    onCloseModel() {
      this.resetAllState();
      this.show = false;
    },

    async handlerOpenPopup(bool, data) {
      this.show = bool;
      if (!bool || !data) {
        this.resetAllState();
        return;
      }
      const { isNew } = data;
      if (isNew) {
        const { message } = data;
        const { ticket: { groupId = null } = {} } = this.selectedConversation;
        this.selectedMsg = message.content || message.text || '';
        this.form = {
          ...this.form,
          groupId,
          selectedMessage: message.content || message.text || '',
          ticketId: message.ticketId,
          messageId: message.id,
          conversationId: message.conversationId,
          channelId: message.channelId,
          projectId: this.channelsMap[message.channelId].projectId,
          createdBy: this.user.id,
          userId: message.sender,
          createdAt: null,
          updatedAt: null,
          id: null,
          isNew
        };
      } else {
        this.isLoadingForm = false;
        const { curItem } = data;
        this.form = {
          ...this.form,
          ...curItem,
          isNew
        };
      }
    },

    handleDeleteKeyWord(tag) {
      let { replacingKeywords } = this.form;
      this.form = {
        ...this.form,
        replacingKeywords: replacingKeywords.filter(i => i !== tag)
      };
      this.filterMsgUserTicket(this.form.replacingKeywords);
    },

    async showInputKeyWord() {
      this.inputVisible = true;

      await new Promise(() =>
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
        }, 100)
      );
    },

    handleAddNewKeyWord() {
      let inputValue = this.inputValue.trim();
      if (inputValue.trim() === '') {
        this.inputVisible = false;
        this.inputValue = '';
        return;
      }

      let exists = false;
      const { replaceAllMessages, selectedMessage } = this.form;
      if (replaceAllMessages) {
        const { replacingKeywords: curKeyword } = this.form;
        const newKeyword = uniq([...curKeyword, inputValue].filter(i => i));
        for (let i = 0; i < newKeyword.length; i++) {
          exists = this.messagesUserTicket.reduce((acc, item) => {
            const isValid = validateKeyword(item.content, newKeyword[i]);
            if (isValid) acc = true;
            return acc;
          }, false);

          if (exists) {
            this.filterMsgUserTicket(newKeyword);
          }
        }
      } else {
        exists = validateKeyword(selectedMessage, inputValue);
      }

      if (!exists) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.replacing-message.components.RequestForm.index.input_correct_keyword'
          )
        });
        return;
      }

      if (inputValue) {
        this.form = {
          ...this.form,
          replacingKeywords: uniq([...this.form.replacingKeywords, inputValue])
        };
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    filterMsgUserTicket(keyword) {
      if (keyword.length <= 0) return (this.msgUserTicket = this.messagesUserTicket);

      const newMsgUserTicket = this.messagesUserTicket.filter(item => {
        let valid = false;
        for (let i = 0; i < keyword.length; i++) {
          valid = validateKeyword(item.content, keyword[i]);
          if (valid) break;
        }

        if (valid) return item;
      });

      this.msgUserTicket = newMsgUserTicket;
    },

    handleDisableButton() {
      const { title = '', replacingKeywords = [] } = this.form;
      if (!title || replacingKeywords.length <= 0) {
        this.disableSaveBtn = true;
      } else {
        this.disableSaveBtn = false;
      }
    },

    formatWarnings(message) {
      return handleReplaceMessage(message, this.sortKeyWord);
    },

    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/a {
  color: #7dbcfc !important;
}
/deep/.badge-warning {
  /deep/a {
    color: #ff3709 !important;
  }
}
.padding-right {
  /deep/.el-input__inner {
    padding-right: 55px;
  }
}
/deep/.el-tag {
  /deep/.el-icon-close {
    top: -5px !important;
    right: -8px !important;
  }
}
.keyword-tag {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  padding-top: 2px;
}
.button-new-tag {
  height: 32px !important;
  line-height: 30px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;

  /deep/.el-input__suffix {
    right: unset;
  }
}
.infinite-list {
  padding-left: 0px;
  min-height: 100px;
  max-height: 250px;
  padding: 0;
  margin: 0;
  list-style: none;

  .infinite-list-item {
    display: flex;
    align-items: center;
    background: #e8f3fe;
    color: #7dbcfc;
    padding-left: 10px;
    margin-bottom: 4px;
    margin-right: 4px;

    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #d9ecff;

    span {
      background: #e8f3fe;
      color: #7dbcfc;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
.selected-message {
  height: 100px;
  overflow: auto;
  background: #e8f3fe;
  color: #7dbcfc;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: #d9ecff;

  p {
    white-space: pre-wrap;
    word-break: break-word;
    padding-left: 15px;
  }
}

.request-form-title,
.request-form-selected-msg {
  /deep/label {
    font-size: 14px !important;
    color: #606266 !important;
  }
}

.request-form-title {
  /deep/.nat-texarea-verti {
    font-size: 1rem;
  }
}

.pd-bottom-15-px {
  padding-bottom: 15px;
}
</style>
