<template>
  <div class="chat-box">
    <vue-element-loading :active="isChatBoxLoading" :text-style="textStyle" spiner="line-scale" />
    <!-- Chat Header (S) -->
    <chat-header
      v-bind="userInfo"
      :is-being-supported="isBeingSupported"
      :show-support="showSupport"
      :show-waiting-support-form="showWaitingSupportForm"
      :show-support-panel="showSupportPanel"
      :is-submit-supported="isSubmitSupported"
      :show-status-conversation="showStatusConversation"
      :is-view="isView"
      :conversation="conversation"
      :is-header-close="isHeaderClose"
      @onSupportItemClick="handleSupportItemClick"
      @handleOnSelectedConversation="handleOnSelectedConversation"
      @heightOfChatHeader="detectHeightOfChatHeader"
      @onToggleHeaderClose="toggleHeaderClose"
    />
    <!-- Chat Header (E) -->
    <div
      v-if="showMessageBox"
      id="appMsgChat"
      ref="appMsgChat"
      :class="['app-message-chats']"
      :style="{
        height: `${
          nonChatInputClass
            ? `-webkit-calc(100% - ${heightChatHeader}px - 30px)`
            : `-webkit-calc(100% - ${heightChatHeader}px - 121px - 30px - ${
                messageInputHeight - initialMessageInputHeight
              }px - ${proofReadingHeight}px - 60*${countFileUploading}px)`
        }`,
        height: `${
          nonChatInputClass
            ? `calc(100% - ${heightChatHeader}px - 30px)`
            : `calc(100% - ${heightChatHeader}px - 121px - 30px - ${
                messageInputHeight - initialMessageInputHeight
              }px - ${proofReadingHeight}px - 60*${countFileUploading}px)`
        }`,
        marginTop: `calc(${heightChatHeader}px + 15px)`,
        visibility
      }"
      @scroll="handleScroll"
    >
      <!-- Message List (S) -->
      <drop class="drop position-relative" @drop="handleDrop">
        <message-list
          :messages="messageDataSort"
          :loading="loading"
          :platform="platform"
          :in-chat-box="true"
        />
      </drop>
      <!-- Message List (E) -->
    </div>

    <!-- Message upload (S) PhuongNd moved it back here -->
    <div class="app-message-upload">
      <file-upload
        v-for="(file, idx) in files"
        :key="idx"
        :file="file.file"
        :path="(conversation && 'c/' + conversation.id) || ''"
        :platform="platform"
        :group="file.group"
        :channel="channel"
        @onSuccess="handleOnSuccess"
      />
    </div>
    <!-- Message upload (E) -->

    <!-- Message Input (S) -->
    <message-input
      v-if="showMessageInput"
      :key="conversation && conversation.ticketId"
      :products="selectedProducts"
      :project-id="projectId"
      :show-support="showSupport"
      :show-waiting-support-form="showWaitingSupportForm"
      :is-being-supported="isBeingSupported"
      :is-submit-supported="isSubmitSupported"
      :show-support-panel="showSupportPanel"
      @productChange="handleProductChange"
      @onSend="handleOnPressSendMessage"
      @onFileUpload="handleOnFileUpload"
      @onLoadingFileUpload="handleLoadingFileUpload"
      @onSupportItemClick="handleSupportItemClick"
      @onProductItemClick="$emit('onProductItemClick')"
      @onConversationalItemClick="$emit('onConversationalItemClick')"
      @resizeChatBox="resizeChatBox"
    />
    <!-- Message Input (E) -->

    <close-conversation />
    <state-overview :conversation="conversation" />
    <manage-labels :conversation="conversation" />
    <manage-user-labels :conversation="conversation" />
    <scenario-template-Box />
    <f-a-q-template-box />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  SERVING_STATE,
  TICKET_STATUS,
  TICKET_STATE,
  AGENT_ROLE,
  IMAGE_EXTENSIONS_SUPPORTED
} from 'core/constants';
import { MessageType } from 'core/message';
import ChatHeader from './chat-header';
import MessageList from './MessageList';
import MessageInput from './message-input';
import CloseConversation from '../CloseConversation';
import StateOverview from '../StateOverview';
import ManageLabels from '../ManageLabels';
import ManageUserLabels from '../ManageUserLabels';
import FAQTemplateBox from '../FAQTemplateBox';
import ScenarioTemplateBox from '../ScenarioTemplateBox';
import FileUpload from './file-upload';
import { buildProductMessage, buildLinkReviewMessage } from 'core/helpers';
import SubscribeUpdatePersonal from 'gql/SubscribeUpdatePersonal.gql';
import SubscribeUpdatePersonalLabel from 'gql/SubscribeUpdatePersonalLabel.gql';
import SubscribeUpdateTicketLabel from 'gql/SubscribeUpdateTicketLabel.gql';
import isEmpty from 'lodash/isEmpty';
import { addMessageStatus } from '../../helpers';
import { EventBus } from 'core/eventBus';
import { findIndex, pick, cloneDeep } from 'lodash';

const statusInput = [
  TICKET_STATUS.AGENT_START,
  TICKET_STATUS.AGENT_SUPPORTING,
  TICKET_STATUS.AGENT_IDLE,
  TICKET_STATUS.USER_IDLE,
  TICKET_STATUS.WAITING_TIMEOUT,
  TICKET_STATUS.RESPONSE_TIMEOUT
];
const statesInput = [TICKET_STATE.START, TICKET_STATE.SUPPORTING];
const cacheSubscribe = [];

export default {
  components: {
    ChatHeader,
    MessageList,
    MessageInput,
    CloseConversation,
    StateOverview,
    ManageLabels,
    ManageUserLabels,
    FileUpload,
    FAQTemplateBox,
    ScenarioTemplateBox
  },

  data() {
    return {
      messageInputHeight: '0',
      initialMessageInputHeight: '0',
      proofReadingHeight: '0',
      showMonitoringModal: false,
      textStyle: {},
      currentDate: new Date(),
      selectedProducts: [],
      files: [],
      isView: true,
      isLoadView: false,
      countFileUploading: 0,
      loading: false,
      SERVING_STATE,
      curentTicket: null,
      ticketHasNewMessage: [],
      fileGroup: {},
      intervalId: null,
      updatePersonalSubscriber: null,
      heightChatHeader: 0,
      hasScrollToBottom: false,
      isHeaderClose: false,
      showMessageInput: false
    };
  },

  computed: {
    ...mapState('chat', [
      'messages',
      'messagesOfTicketId',
      'ready',
      'chatViewingGroup',
      'showSupportPanel',
      'selectedGroupConv',
      'showView',
      'typing',
      'ticketActionLogMap'
    ]),
    ...mapState('global', { globalReady: ['ready'] }),
    ...mapState('global', [
      'rightPanelPinned',
      'rightProductPanelPinned',
      'rightConversationPanelPinned',
      'rightCustomerSupportPanelPinned',
      'rightCustomerSurveyPanelPinned'
    ]),
    ...mapState('session', [
      'user',
      'usersInfo',
      'servingUsers',
      'channelsMap',
      'people',
      'waitingMode',
      'chatSearchBoxFilter',
      'unreadMessagesTicketIds',
      'projectMaps'
    ]),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', [
      'conversationsByBot',
      'commonWaitingList',
      'meWaitingList',
      'conversationsByAgents',
      'meGroups'
    ]),
    isChatBoxLoading() {
      return this.user && this.user.id && !this.ready && this.globalReady;
    },
    visibility() {
      return this.isChatBoxLoading ? 'hidden' : '';
    },
    conversation() {
      return this.selectedConversation || {};
    },
    typingText() {
      if (this.chatViewingGroup !== SERVING_STATE.AGENT) return '';
      const userId = this.selectedConversation && this.selectedConversation.userId;
      return (userId && this.typing[userId]) || '';
    },
    platform() {
      const { channelId } = this.selectedConversation;
      if (!channelId) return '';
      const channel = this.channelsMap[channelId];
      if (!channel) return '';
      return channel.platform || '';
    },
    channel() {
      const { channelId } = this.selectedConversation;
      if (!channelId) return '';
      const channelSelected = this.channelsMap[channelId];
      if (!channelSelected) return '';
      return channelSelected.accessToken;
    },
    // Get user info from col sender in Conversation.
    userInfo() {
      const { userInfo = {}, channelId } = this.conversation || {};
      const channelName = this.channelsMap[channelId] && this.channelsMap[channelId].name;
      const projectId = this.channelsMap[channelId] && this.channelsMap[channelId].projectId;
      return {
        ...userInfo,
        channelName,
        projectId,
        location: null
      };
    },

    projectId() {
      const { projectId } = this.userInfo;
      return projectId;
    },

    nonChatInputClass() {
      if (!this.showMessageInput) return true;
      return false;
    },

    // Get messages from State [messages] with object key = Conversation ID
    messageData() {
      if (!this.conversation) return [];

      const { id: conversationId } = this.conversation;
      const messages = [...(this.messages[conversationId] || [])];
      if (messages.length < 1) return [];
      return messages;
    },

    messageDataSort() {
      if (!this.conversation) return [];

      const messageDataSort = cloneDeep(this.messageData).sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      return addMessageStatus(messageDataSort, this.conversation);
    },

    messageDataMonitoring() {
      if (!this.conversation) return;
      const { id: conversationId, ticketId } = this.conversation;
      let messages = [];
      if (
        this.messagesOfTicketId &&
        this.messagesOfTicketId[conversationId] &&
        this.messagesOfTicketId[conversationId][ticketId]
      )
        messages = this.messagesOfTicketId[conversationId][ticketId];
      const messagesByTicketId = messages.filter(msg => msg.ticketId === ticketId);
      return addMessageStatus(messagesByTicketId, this.conversation);
    },

    showMessageBox() {
      return !!this.selectedConversation;
    },

    isSupporting() {
      return (
        this.selectedConversation &&
        this.selectedConversation.ticket &&
        [
          TICKET_STATUS.AGENT_SUPPORTING,
          TICKET_STATUS.AGENT_START,
          TICKET_STATUS.AGENT_IDLE,
          TICKET_STATUS.USER_IDLE,
          TICKET_STATUS.RESPONSE_TIMEOUT
        ].includes(this.selectedConversation.ticket.status) &&
        this.selectedConversation.ticket.assignee === this.user.id
      );
    },

    showSupport() {
      const { ticket, agentId } = this.selectedConversation || {};
      if (!ticket) return !!agentId;

      const { status, assignee } = ticket;
      const supportStatusMe = [
        TICKET_STATUS.ASSIGN_TO,
        TICKET_STATUS.TRANSFER_TO,
        TICKET_STATUS.ESCALATE_TO
      ];

      if (supportStatusMe.includes(status) && assignee === this.user.id) return true;

      const supportStatusCommon = [TICKET_STATUS.REQUEST_AGENT, TICKET_STATUS.BOT_HANDLE];

      if (supportStatusCommon.includes(status)) return true;

      return false;
    },

    showWaitingSupportForm() {
      const statusShowSubmit = [
        TICKET_STATUS.USER_FINISH,
        TICKET_STATUS.AGENT_FINISH,
        TICKET_STATUS.FINISH_TIMEOUT
      ];
      const { ticket } = this.selectedConversation || {};
      const waitingSupportForm = ticket && ticket.status === TICKET_STATUS.AGENT_FINISH;
      if (!ticket) return !!waitingSupportForm;
      const { status } = ticket;
      if (statusShowSubmit.includes(status)) return true;

      return false;
    },

    isBeingSupported() {
      const { ticket, agentId } = this.selectedConversation || {};
      if (!ticket) {
        return agentId && agentId !== this.user.id;
      }

      const { status } = ticket;

      return status === TICKET_STATUS.AGENT_SUPPORTING && ticket.agentId !== this.user.id;
    },

    isSubmitSupported() {
      const { ticket, agentId } = this.selectedConversation || {};
      const waitingSupportForm = ticket && ticket.status === TICKET_STATUS.AGENT_FINISH;
      if (!ticket) {
        return waitingSupportForm && agentId === this.user.id;
      }

      const { state } = ticket;
      if (state === TICKET_STATE.FINISH && ticket.agentId === this.user.id) {
        return true;
      }
      return false;
    },

    showStatusConversation() {
      // check data
      const { ticket } = this.selectedConversation || {};
      if (!ticket) return '';
      const { id, status, assignee, state } = ticket;
      if (!status) return '';
      if (!assignee) return '';
      const itMe = assignee === this.user.id;

      // get Agent name
      let name = this.$t('src.modules.chat.components.ChatBox.index.agent');
      const agent = this.people[assignee];
      if (id !== assignee) {
        if (agent) {
          if (agent && agent.name) name = agent.name;
          if (name.toString().trim() === '')
            name = `${agent.firstName || ''} ${agent.lastName || ''}`;
          if (name.toString().trim() === '') {
            this.addAgentToPeople(assignee);
          }
        } else {
          this.addAgentToPeople(assignee);
        }
      }

      // get status show in button
      switch (status) {
        case TICKET_STATUS.ASSIGN_TO:
          return itMe
            ? ''
            : `${this.$t('src.modules.chat.components.ChatBox.index.assign_to')} ` + name;
        case TICKET_STATUS.TRANSFER_TO:
          return itMe
            ? ''
            : `${this.$t('src.modules.chat.components.ChatBox.index.transfer_to')} ` + name;
        case TICKET_STATUS.ESCALATE_TO:
          return itMe
            ? ''
            : `${this.$t('src.modules.chat.components.ChatBox.index.escalate_to')} ` + name;
        case TICKET_STATUS.COMPLETE:
          return itMe
            ? this.$t('src.modules.chat.components.ChatBox.index.completed')
            : `${this.$t('src.modules.chat.components.ChatBox.index.completed_by')} ` + name;
        case TICKET_STATUS.AGENT_FINISH:
          return itMe
            ? ''
            : `${this.$t('src.modules.chat.components.ChatBox.index.finished_by')} ` + name;
        case TICKET_STATUS.USER_FINISH:
          return itMe
            ? ''
            : `${this.$t('src.modules.chat.components.ChatBox.index.user_finished_with')} ` + name;
        case TICKET_STATUS.AGENT_START:
          return itMe
            ? this.$t('src.modules.chat.components.ChatBox.index.start')
            : `${this.$t('src.modules.chat.components.ChatBox.index.start_by')} ` + name;
        default:
          if (state === TICKET_STATE.SUPPORT)
            return itMe
              ? this.$t('src.modules.chat.components.ChatBox.index.supporting')
              : `${this.$t('src.modules.chat.components.ChatBox.index.supporting_by')} ` + name;
          return '';
      }
    },

    meWaitingListMap() {
      return this.meWaitingList.reduce((acc, conv) => {
        if (conv.ticketId) acc[conv.ticketId] = conv;
        return acc;
      }, {});
    },

    commonWaitingListMap() {
      return this.commonWaitingList.reduce((acc, conv) => {
        if (conv.ticketId) acc[conv.ticketId] = conv;
        return acc;
      }, {});
    }
  },

  watch: {
    async chatViewingGroup(newValue) {
      if (newValue === SERVING_STATE.MONITORING) {
        this.isView = false;
        this.isLoadView = false;
      } else {
        let checkList = null;
        switch (newValue) {
          case SERVING_STATE.BOT:
            checkList = this.conversationsByBot;
            break;
          case SERVING_STATE.WAITING:
            if (this.waitingMode === 'TOME') {
              checkList = this.meWaitingList;
            } else {
              checkList = this.commonWaitingList;
            }

            break;
          case SERVING_STATE.AGENT:
            checkList = this.conversationsByAgents;
            break;
        }
        if (checkList) {
          const { ticketId: curTicketId = '' } = this.selectedConversation || {};
          const isExist =
            checkList.filter(item => item.ticketId === curTicketId).length > 0 ? true : false;
          if (isExist) this.isView = true;
        }
      }
    },

    async selectedConversation(newValue, oldValue) {
      // select another ticket
      const { id: agId = '', role: agentRole = '' } = this.user;
      const { ticket: { id = '' } = {} } = newValue || {};
      const { agentId = '' } = newValue || {};
      const { ticket: { projectId = '', groupId = '' } = {} } = newValue || {};
      const { ticket: { state = '', status = '', assignee = '' } = {} } = newValue || {};
      const projectConfig = this.projectMaps[projectId] && this.projectMaps[projectId].config;
      const { accessPermission: { isAllowAllView = true } = {} } = projectConfig || {};

      this.handleShowMessageInput(newValue);

      if (!this.curentTicket || this.curentTicket !== id) {
        this.curentTicket = id;
        this.selectedProducts.splice(0);
      }

      if (state === TICKET_STATE.REQUEST) {
        this.setShowSupportPanel(false);
      }

      if (status === TICKET_STATUS.AGENT_FINISH && agentId === this.user.id) {
        this.setShowSupportPanel(false);
        this.handleSupportItemClick();
      }

      if (assignee === TICKET_STATUS.FORWARD_GROUP) {
        if (AGENT_ROLE.REGULAR === agentRole && TICKET_STATE.COMPLETE === state)
          this.gotoOverView();
        else if (!this.meGroups.includes(groupId)) await this.gotoOverView();
      } else if (![TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.REQUEST_USER].includes(status)) {
        // selectedConversation handle by another agent.
        const groupIds = this.getGroupIds(projectId);
        const isGroup = groupIds && groupIds.length > 0;
        if (!isGroup) {
          if (
            [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR].includes(agentRole) &&
            ![agId, id].includes(assignee) &&
            !isAllowAllView
          ) {
            this.gotoOverView();
          }
        } else {
          if (
            AGENT_ROLE.REGULAR === agentRole &&
            ![agId, id].includes(assignee) &&
            !isAllowAllView
          ) {
            this.gotoOverView();
          } else if (
            assignee !== agId &&
            assignee !== id &&
            AGENT_ROLE.MODERATOR === agentRole &&
            TICKET_STATUS.REQUEST_USER !== status &&
            SERVING_STATE.MONITORING !== this.chatViewingGroup
          ) {
            if (groupIds.includes(groupId)) {
              this.gotoMonitoring(newValue);
            } else {
              this.gotoOverView();
            }
          }
        }
        if (
          assignee !== agId &&
          assignee !== id &&
          AGENT_ROLE.LEADER === agentRole &&
          TICKET_STATUS.REQUEST_USER !== status &&
          SERVING_STATE.MONITORING !== this.chatViewingGroup
        ) {
          this.gotoMonitoring(newValue);
        }
        // ----------end----------------//
      }

      this.userStatusWC(newValue);
      this.subscribeUpdatePersonal(newValue.userId, newValue.channelId);
      if (
        !oldValue ||
        newValue.ticketId !== oldValue.ticketId ||
        newValue.userId !== oldValue.userId ||
        (!this.uTicketSubscriber && !this.uPersonalSubscriber)
      ) {
        this.unsubscribeUpdateLabel();
        this.subscribeUpdatePersonalLabel(newValue.projectId, newValue.userId);
        this.subscribeUpdateTicketLabel(newValue.projectId, newValue.ticketId);
      }

      if (state === TICKET_STATE.START || state === TICKET_STATE.SUPPORTING) {
        const { userId, channelId, projectId, platform } = newValue;
        const paramsPesonalInfo = { userId, channelId, projectId, platform };
        this.getPersonalInfo(paramsPesonalInfo);
      }
    },

    async messages(newValue) {
      if (isEmpty(newValue)) return;
      Object.keys(newValue).map(conversationId => {
        const length = newValue[conversationId].length;
        const firstMss = newValue[conversationId][length - 1];
        if (firstMss) {
          const { ticketId = '' } = firstMss;
          let lt = this.ticketHasNewMessage;
          lt.push(ticketId);

          this.ticketHasNewMessage = [...new Set(lt)];
        }
      });
    }
  },

  created() {
    EventBus.$on('scrollBottomMessage', () => {
      this.scrollToBottom();
    });
    this.handleShowMessageInput(this.selectedConversation);
    this.isLoadView = false;
    if (this.chatViewingGroup === SERVING_STATE.MONITORING) {
      this.isView = false;
    } else {
      this.isView = true;
    }

    if (
      this.selectedConversation &&
      this.selectedConversation.id &&
      this.selectedConversation.ticketId &&
      this.chatViewingGroup === SERVING_STATE.MONITORING
    ) {
      const {
        id,
        ticketId,
        ticket: { state }
      } = this.selectedConversation;
      const { projectId = '' } =
        (this.selectedConversation && this.selectedConversation.ticket) || {};
      this.fecthMessagesByTicketId({ id, ticketId, state, projectId });
    }
  },

  destroyed() {
    EventBus.$off('scrollBottomMessage');
  },

  methods: {
    ...mapActions('chat', [
      'sendMessage',
      'loadMoreMessage',
      'loadMoreMessageOfTicketId',
      'setShowSupportPanel',
      'setShowSurveyPanel',
      'getMessagesByTicketId',
      'setSelectedConversation',
      'setShowProductPanel',
      'setShowConversationalPanel',
      'setShowView',
      'setReady',
      'setChatViewingGroup',
      'getMessagesByConversationId',
      'getUserStatusWC',
      'setMaybeNotAvailable'
    ]),
    ...mapActions('session', [
      'addAgentToPeople',
      'pushReadToWebChat',
      'pushReadToLIFFChat',
      'markAsRead',
      'updatePersonal',
      'syncUserLabelColor',
      'syncConversationLabelColor'
    ]),
    ...mapActions('global', ['pinRightPanel', 'setGlobalReady']),
    ...mapActions('personal', ['getPersonalInfo']),

    detectHeightOfChatHeader(height) {
      this.heightChatHeader = height;
    },

    gotoOverView() {
      if (!isEmpty(this.chatSearchBoxFilter)) return;
      // Hide all tooltips
      this.$root.$emit('bv::hide::tooltip');
      // Hide all right pannel
      this.setShowProductPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowSupportPanel(false);
      this.setShowSurveyPanel(false);
      this.pinRightPanel(false);

      // Go to overview box
      this.setShowView('Overview');

      // homepage
      history.pushState(null, null, '/');
    },

    async gotoMonitoring(conversation) {
      if (!isEmpty(this.chatSearchBoxFilter)) return;
      // Go to monitoring box
      this.setShowView('Chat');
      // set active Monitoring tab
      this.setSelectedConversation({
        conversation,
        groupId: SERVING_STATE.MONITORING
      });
    },

    getGroupIds(projectId) {
      const { assignedProjects = [] } = this.user;
      return assignedProjects.reduce((acc, pj) => {
        const { id = '', assignedGroups = [] } = pj;
        if (projectId === id && assignedGroups.length > 0) {
          const ids = Object.values(assignedGroups).map(i => i.id);
          acc = [...acc, ...ids];
        }
        return acc;
      }, []);
    },

    handleOnSelectedConversation(conversation) {
      this.$emit('onSelectedConversation', { type: 'CHAT_LIST', conversation });
    },

    fecthMessagesByTicketId({ id, ticketId, state, hasNewMessage, projectId }) {
      if (
        [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state) &&
        this.messagesOfTicketId[id] &&
        this.messagesOfTicketId[id][ticketId] &&
        !hasNewMessage
      ) {
        // exist messages of old ticket
        return Promise.resolve(true);
      } else {
        return this.getMessagesByTicketId({ id, ticketId, hasNewMessage, projectId });
      }
    },

    handleLoadingFileUpload(counts) {
      // change style CSS, re-calculate the height of "app-message-chats"
      this.countFileUploading = counts;
    },

    handleOnFileUpload(files) {
      // eslint-disable-next-line
      let newFiles = [...this.files];

      let group = '';
      let isImages = false;
      if (this.platform === 'webchat') {
        const isSupported = file =>
          IMAGE_EXTENSIONS_SUPPORTED.some(ex => file.name.toLowerCase().endsWith(ex));
        const isNotSupported = file =>
          IMAGE_EXTENSIONS_SUPPORTED.every(ex => !file.name.toLowerCase().endsWith(ex));

        const images = files.filter(file => isSupported(file));
        const other = files.filter(file => isNotSupported(file));

        files = [...images, ...other];
        isImages = images && images.length > 1;
        group = isImages ? new Date().getTime().toString() : '';
        if (group !== '')
          this.fileGroup[group] = files.reduce((res, file) => {
            return isSupported(file)
              ? {
                  ...res,
                  [file.name]: false
                }
              : res;
          }, {});

        files.forEach(file => {
          const g = isSupported(file) ? group : '';
          newFiles.push({ file, group: g });
        });
      } else {
        files.forEach(file => {
          newFiles.push({ file, group });
        });
      }

      this.files = newFiles;
    },

    handleOnSuccess({ downloadURL: url, type, name, payload, group, payloadToken }) {
      // eslint-disable-next-line
      // change style CSS, re-calculate the height of "app-message-chats"
      this.countFileUploading--;
      if (!group) {
        const body = {
          sender: this.user.id,
          type: type || MessageType.TEXT,
          body: url,
          text: name,
          payload
        };
        if (payloadToken) body.payloadToken = payloadToken;
        this.sendMessage(body);
      } else {
        this.fileGroup[group][name] = url;
        if (this.fileGroup[group] && Object.values(this.fileGroup[group]).every(url => url)) {
          this.sendMessage({
            sender: this.user.id,
            type: MessageType.TEMPLATE,
            body: {
              template_type: 'image_multiple',
              elements: Object.values(this.fileGroup[group]).map(url => ({
                media_type: 'image',
                url
              }))
            }
          });
        }
      }
    },

    // Send new message
    handleOnPressSendMessage(content, options = {}) {
      const { products, text, objLinkPreviews = {} } = options;
      const isSendingProd = products && products.length > 0;
      const { linkPreviews, isURLOnly } = objLinkPreviews;
      const isSendingLinkPreview = linkPreviews && linkPreviews.length > 0;
      if (isSendingLinkPreview) {
        const linkReviews = buildLinkReviewMessage(linkPreviews);
        const { msgType: type } = options;
        this.sendMessage({
          sender: this.user.id,
          type: type || MessageType.TEXT,
          body: content,
          text,
          payload: { link_reviews: linkReviews, isURLOnly }
        });
      } else if (isSendingProd) {
        const msgs = buildProductMessage(products);
        const ids = products.map(({ id }) => {
          return id;
        });
        const productIds = [];
        ids.filter(e => {
          productIds.push(e);
        });
        this.sendMessage({
          sender: this.user.id,
          type: MessageType.TEMPLATE,
          body: msgs,
          text,
          productIds: productIds.toString()
        });
      } else if (content && content.trim()) {
        const { msgType: type, payload = {} } = options;
        this.sendMessage({
          sender: this.user.id,
          type: type || MessageType.TEXT,
          body: content,
          text,
          payload
        });
      }
    },

    handleOnPressSendTemplateMsg(body) {
      this.sendMessage({
        sender: this.user.id,
        type: MessageType.TEMPLATE,
        body
      });
      this.scrollToBottom();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.$refs.appMsgChat) return;
        const { scrollHeight } = this.$refs.appMsgChat;
        this.$refs.appMsgChat.scrollTop = scrollHeight + 200;
      });
      const { ticketId = '' } = this.selectedConversation || {};
      this.pushReadToWebChat(ticketId);
      this.pushReadToLIFFChat(ticketId);
      this.updateLastReadAt();
    },

    async handleScroll(evt) {
      if (!this.$refs.appMsgChat) return;
      if (evt.target.scrollTop === 0) {
        const msgLen = this.messageData.length;
        if (msgLen === 0) return;
        const idx = findIndex(this.messageData, item => item.createdAt && item.conversationId);

        const msg = this.messageData[idx];

        const { ticket = {}, id } = this.selectedConversation || {};
        const { projectId = '' } = ticket || {};
        await this.handleLoadMoreMessage({
          projectId,
          id,
          nextKey: pick(msg, ['sk', 'conversationId'])
        });
        this.$refs.appMsgChat.scrollTop = 1;
      }
      if (this.chatViewingGroup !== SERVING_STATE.MONITORING) {
        if (evt.target.scrollTop + evt.target.offsetHeight >= evt.target.scrollHeight - 5) {
          const { ticketId = '' } = this.selectedConversation || {};
          this.pushReadToWebChat(ticketId);
          this.pushReadToLIFFChat(ticketId);
          this.updateLastReadAt();
        }

        if (evt.target.scrollTop + evt.target.offsetHeight >= evt.target.scrollHeight) {
          const { ticketId = '' } = this.selectedConversation || {};
          this.markAsRead(ticketId);
        }
      }
    },

    handleProductChange({ type, id }) {
      switch (type) {
        case 'REMOVE_ALL': {
          this.selectedProducts.splice(0);
          return;
        }
        case 'REMOVE_ITEM': {
          this.selectedProducts = [...this.selectedProducts.filter(p => p.id !== id)];
          return;
        }
      }
    },

    handleDrop({ product }) {
      if (product) {
        const len = this.selectedProducts.length;
        if (len >= 10)
          return this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t(
              'src.modules.chat.components.ChatBox.index.you_could_not_add_greater_than_10_products'
            )
          });
        if (!this.selectedProducts.some(p => p.id === product.id)) {
          this.selectedProducts.unshift(product);
        } else
          return this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t(
              'src.modules.chat.components.ChatBox.index.you_already_select_this_product_please_choose_other'
            )
          });
      }
    },

    toggleHeaderClose() {
      this.isHeaderClose = !this.isHeaderClose;
    },

    handleSupportItemClick() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.setShowProductPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowSurveyPanel(false);
      this.showSupportPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightCustomerSupportPanelPinned);
      this.setShowSupportPanel(!this.showSupportPanel);
    },

    async handleOnClickCard() {
      this.isLoadView = true;
      this.setReady(false);
      const {
        id = '',
        ticketId = '',
        ticket: { state = '' } = {}
      } = this.selectedConversation || {};
      const { projectId = '' } =
        (this.selectedConversation && this.selectedConversation.ticket) || {};
      const messagesOfTicketId =
        (this.messagesOfTicketId &&
          this.messagesOfTicketId[id] &&
          this.messagesOfTicketId[id][ticketId]) ||
        null;
      let fectMss = false;
      let hasNewMessage = false;
      if (id && ticketId && this.ticketHasNewMessage.includes(ticketId)) {
        fectMss = true;
        hasNewMessage = true;
      } else if (!messagesOfTicketId) {
        fectMss = true;
        hasNewMessage = true;
      }

      if (fectMss) {
        await this.fecthMessagesByTicketId({
          id,
          ticketId,
          state,
          hasNewMessage,
          projectId
        }).then(() => {
          let lt = this.ticketHasNewMessage;
          lt = lt.filter(i => i !== ticketId);

          this.ticketHasNewMessage = [...new Set(lt)];
        });
      }
      this.setReady(true);
      this.$refs.monitoringModal.show();
    },

    scrollToBottomAppCardChat() {
      this.$nextTick(() => {
        if (!this.$refs.appCardChat) return;
        const { scrollHeight } = this.$refs.appCardChat;
        this.$refs.appCardChat.scrollTop = scrollHeight;
      });
    },

    scrollToEnd() {
      const chatBox = this.$refs.appMsgChat;
      chatBox.scrollTop = chatBox.scrollHeight;
    },

    onCancel() {
      this.showMonitoringModal = false;
    },

    async handleLoadMoreMessage({ projectId, id, nextKey }) {
      this.loading = true;
      try {
        await this.loadMoreMessage({ conversationId: id, projectId, nextKey }, true); // true for loadmore
      } catch (e) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.chat.components.ChatBox.index.could_not_load_more_message')
        });
      }
      this.loading = false;
    },

    async updateLastReadAt() {
      const { ticket = {} } = this.selectedConversation || {};
      const { id: ticketId = '' } = ticket;
      if (this.hasScrollToBottom) return null;
      if (!this.unreadMessagesTicketIds.includes(ticketId)) return null;
      this.hasScrollToBottom = false;
    },

    async userStatusWC(selectedConversation) {
      clearInterval(this.intervalId);
      const { ticket = {}, userId, channelId } = selectedConversation || {};
      const { id = '', state = '' } = ticket;
      const { agentId = '', assignee = '' } = ticket;
      const { platform = '' } = this.channelsMap[channelId] || {};
      const { status: userStatus = 1 } = JSON.parse(ticket.userStatus || '{}');

      const obj = { ticketId: id, flag: false };
      if (platform !== 'webchat') return true;
      if (state !== TICKET_STATE.SUPPORTING) return true;
      if (agentId !== assignee) return true;
      if (!userId || !channelId) return true;
      if (!userStatus) return this.setMaybeNotAvailable(obj);

      this.intervalId = setInterval(async () => {
        await this.handleUserStatus(userId, channelId, obj);
      }, 15000);
    },

    async handleUserStatus(userId, channelId, obj) {
      const users = { userId, channelId };
      const userStatus = await this.getUserStatusWC(users);
      const { status = 1 } = userStatus || {};
      status ? this.setMaybeNotAvailable(obj) : this.setMaybeNotAvailable({ ...obj, flag: true });
    },

    async subscribeUpdatePersonal(userId, channelId) {
      if (!userId || !channelId) return true;
      if (cacheSubscribe.some(i => i.userId === userId && i.channelId === channelId)) return true;

      const _this = this;
      cacheSubscribe.push({ userId, channelId });
      this.updatePersonalSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdatePersonal,
        variables: { userId, channelId }
      });

      await this.updatePersonalSubscriber.subscribe({
        next({ data }) {
          const newDataPersonal = (data && data.subscribeUpdatePersonal) || {};
          _this.handleNewPersonal(newDataPersonal);
        },
        error(error) {
          _this.$log('[updatePersonalSubscriber] > error', error);
          if (
            _this.updatePersonalSubscriber &&
            typeof _this.updatePersonalSubscriber.unsubscribe === 'function'
          )
            _this.updatePersonalSubscriber.unsubscribe();
          _this.updatePersonalSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeUpdatePersonal(userId, channelId);
        }
      });
    },

    async subscribeUpdatePersonalLabel(projectId, userId) {
      if (!projectId || !userId) return true;
      const _this = this;
      this.updatePersonalSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdatePersonalLabel,
        variables: { projectId, userId }
      });

      this.uPersonalSubscriber = await this.updatePersonalSubscriber.subscribe({
        next({ data }) {
          const subscribeUpdatePersonalLabel = (data && data.subscribeUpdatePersonalLabel) || {};
          if (subscribeUpdatePersonalLabel.labels) {
            _this.syncUserLabelColor({
              projectId,
              newLabels: subscribeUpdatePersonalLabel.labels,
              userId
            });
          }
        },
        error(error) {
          _this.$log('[updatePersonalSubscriber] > error', error);
        }
      });
    },
    async subscribeUpdateTicketLabel(projectId, id) {
      if (!projectId || !id) return true;
      const _this = this;
      this.updateTicketLabelSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdateTicketLabel,
        variables: { projectId, id }
      });
      this.uTicketSubscriber = await this.updateTicketLabelSubscriber.subscribe({
        next({ data }) {
          const subscribeUpdateTicketLabel = (data && data.subscribeUpdateTicketLabel) || {};
          if (subscribeUpdateTicketLabel.labels) {
            _this.syncConversationLabelColor({
              projectId,
              newLabels: subscribeUpdateTicketLabel.labels,
              ticketId: id,
              updatedAt: subscribeUpdateTicketLabel.updatedAt
            });
          }
        },
        error(error) {
          _this.$log('[updateTicketLabelSubscriber] > error', error);
        }
      });
    },

    unsubscribeUpdateLabel() {
      if (this.uTicketSubscriber && this.uPersonalSubscriber) {
        this.uTicketSubscriber.unsubscribe();
        this.uPersonalSubscriber.unsubscribe();
      }
    },

    handleNewPersonal(data) {
      this.updatePersonal(JSON.parse(data.data));
    },

    resizeChatBox(params) {
      this.messageInputHeight = params.messageInputHeight;
      this.initialMessageInputHeight = params.initialMessageInputHeight;
      this.proofReadingHeight = params.proofReadingHeight;
    },

    handleShowMessageInput(conversation) {
      const { ticket = {} } = conversation || {};
      if (!isEmpty(ticket)) {
        this.showMessageInput =
          this.chatViewingGroup === SERVING_STATE.AGENT &&
          statusInput.includes(ticket.status) &&
          statesInput.includes(ticket.state) &&
          ticket.agentId === this.user.id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-typing {
  position: absolute;
  bottom: 65px;
  left: 40px;
  font-size: 0.8em;
}
.app-message-upload {
  bottom: 90px;
  width: 100%;
  text-align: center;
}
.message-list-card {
  /deep/.video-js {
    max-width: 490px;
  }
}
.message-list-card {
  height: 500px;
  overflow: auto;
}
.message-list-card .chats {
  margin-top: 15px;
}
</style>
