<template>
  <div :class="['chat', direction]">
    <div v-if="!isSystem && localMessages.length > 0" class="chat-avatar person_name_tooltip">
      <a
        :data-img="avatar"
        class="avatar shadow"
        data-toggle="tooltip"
        data-placement="right"
        title
        data-original-title
        @click.prevent.stop
      >
        <img :src="avatar" :alt="name" @error="imageLoadError" />
      </a>
    </div>

    <div v-if="!isSystem" class="chat-body message-tooltip">
      <div v-if="localMessages.length > 0" class="chat-name">{{ showName }}</div>
      <div v-for="(msg, idx) in localMessages" :key="`${idx}_${msg.id}`">
        <div
          :id="'message_' + `${idx}_${msg.id}`"
          :class="['chat-content', 'shadow-sm', msgClass(msg)]"
          v-b-tooltip.hover
          :title="
            msg.isBroadcastMessage
              ? $t(
                  'src.modules.chat.components.ChatBox.MessageList.message-item.tooltip_broadcast_message'
                )
              : ''
          "
        >
          <b-tooltip
            v-if="
              !isPhoneAgent &&
                isAgent &&
                inChatBox &&
                msg.sender === me.id &&
                channelPlatform === 'webchat' &&
                conversation &&
                conversation.ticket &&
                ticketState.SUPPORTING === conversation.ticket.state
            "
            :target="'message_' + `${idx}_${msg.id}`"
            :container="'message_' + `${idx}_${msg.id}`"
            :delay="100"
            variant="light"
            placement="left"
            triggers="hover"
          >
            <!-- download-file -->
            <i
              v-if="msg.content && msg.typeClass === 'audio' && _isAudioUnPlayable(msg.content)"
              :style="{ cursor: 'pointer', paddingRight: '10px' }"
              class="fas fa-download"
              v-on:click="_downloadFile(msg.content)"
            />
            <i
              :style="{ cursor: 'pointer' }"
              class="fas fa-trash"
              v-on:click="confirmDeleteMessage(msg)"
            />
          </b-tooltip>
          <b-tooltip
            v-else-if="
              !isPhoneAgent &&
                !isAgent &&
                inChatBox &&
                conversation &&
                conversation.ticket &&
                ticketState.SUPPORTING === conversation.ticket.state &&
                msg.ticketId === conversation.ticket.id &&
                msg.typeClass === 'text'
            "
            :target="'message_' + `${idx}_${msg.id}`"
            :container="'message_' + `${idx}_${msg.id}`"
            :delay="100"
            variant="light"
            placement="right"
            triggers="hover"
          >
            <i
              :style="{ cursor: 'pointer' }"
              class="fas fa-edit"
              v-on:click="replacingMessageRequestForm(msg)"
            />
          </b-tooltip>

          <!-- download-file -->
          <b-tooltip
            v-else-if="
              !isPhoneAgent &&
                msg.content &&
                msg.typeClass === 'audio' &&
                _isAudioUnPlayable(msg.content)
            "
            :target="'message_' + `${idx}_${msg.id}`"
            :container="'message_' + `${idx}_${msg.id}`"
            :delay="100"
            variant="light"
            :placement="isAgent ? 'left' : 'right'"
            triggers="hover"
          >
            <i
              :style="{ cursor: 'pointer' }"
              class="fas fa-download"
              v-on:click="_downloadFile(msg.content)"
            />
          </b-tooltip>

          <p
            v-if="msg.typeClass === 'follow'"
            class="text-style"
            v-html="escapeHtmlChar(msg.content || msg.text)"
          />
          <p
            v-if="msg.typeClass === 'text' && (!msg.isUserURLOnly || !msg.isURLOnly)"
            class="text-style"
            v-html="
              urlify(
                formatMessage(
                  escapeHtmlChar(msg.content || msg.text),
                  msg.warnings,
                  msg.ngs,
                  msg.status
                )
              )
            "
          />
          <p
            v-if="
              (msg.typeClass === 'template_image_multiple' ||
                msg.typeClass === 'video' ||
                msg.typeClass === 'file' ||
                msg.typeClass === 'audio') &&
                msg.text
            "
            class="text-style"
            v-html="
              urlify(formatMessage(escapeHtmlChar(msg.text), msg.warnings, msg.ngs, msg.status))
            "
          />
          <div
            v-if="msg.typeClass === 'template_image_multiple' && msg.text"
            class="clearfix"
          ></div>
          <p
            v-if="msg.typeClass === 'fallback' && (!msg.isUserURLOnly || !msg.isURLOnly)"
            class="text-style"
            v-html="
              urlify(
                formatMessage(
                  escapeHtmlChar(msg.content || msg.text),
                  msg.warnings,
                  msg.ngs,
                  msg.status
                )
              )
            "
          ></p>
          <p v-if="msg.typeClass === 'referral'">{{ msg.text }}</p>

          <msg-postback
            v-if="msg.typeClass === 'postback'"
            :message="msg"
            :selected-conversation="conversation"
          />
          <div
            v-if="msg.typeClass === 'image' && msg.content && _isImagesSupported(msg.content)"
            class="message-image"
          >
            <img v-img :src="msg.content" :style="{ 'max-width': '300px' }" />
          </div>
          <div v-if="msg.typeClass === 'sticker'" class="message-image">
            <img v-img :src="msg.content" :style="{ 'max-width': '300px' }" />
          </div>
          <msg-video
            v-if="msg.typeClass === 'video'"
            :video-url="msg.content"
            :platform="msg.platform"
          />
          <msg-audio v-if="msg.typeClass === 'audio'" :audio-url="msg.content" />
          <msg-quickrep
            v-if="
              msg.typeClass === 'quick_reply' && msg.content.type === 'USER_REQUEST_AGENT_CONFIRM'
            "
            :text="msg.text"
            :message="msg.content"
            :platform="msg.platform"
            :selected-conversation="conversation"
          />
          <msg-quickrep
            v-if="
              msg.typeClass === 'quick_reply' && msg.content.type !== 'USER_REQUEST_AGENT_CONFIRM'
            "
            :text="msg.text"
            :message="msg.content"
            :platform="msg.platform"
            :selected-conversation="conversation"
          />
          <msg-list
            v-if="msg.typeClass === 'template_list'"
            :message="msg.content"
            :channel-platform="channelPlatform"
            :domain-webview="formDomain"
          />
          <msg-image-map v-if="msg.typeClass === 'template_imagemap'" :message="msg.content" />
          <msg-image-multiple
            v-if="msg.typeClass === 'template_image_multiple'"
            :message="msg.content"
          />
          <fb-template-button v-if="msg.typeClass === 'template_button'" :message="msg.content" />
          <fb-template-product
            v-if="msg.typeClass === 'template_generic'"
            :message="msg.content"
            :domain-webview="formDomain"
          />
          <ins-template-story
            v-if="['story_mention', 'story_reply'].includes(msg.typeClass)"
            :message="msg.content"
            :type="msg.typeClass"
          />
          <msg-file
            v-if="
              ['file'].includes(msg.typeClass) ||
                (msg.typeClass === 'image' &&
                  msg.content &&
                  typeof msg.content === 'string' &&
                  !_isImagesSupported(msg.content))
            "
            :message="msg"
          />
          <msg-location v-if="msg.typeClass === 'location'" :message="msg" />
          <msg-typing v-if="msg.typeClass === 'typing_text'" :message="msg" />
          <div
            v-if="
              typeof (msg.content || msg.text) === 'string' &&
                msg.type !== 'file' &&
                msg.type !== 'postback' &&
                msg.isUserURLOnly &&
                (msg.typeClass === 'text' || msg.typeClass === 'fallback')
            "
            style="word-break: break-word"
          >
            <msg-link-preview
              v-if="msg.typeClass !== 'fallback'"
              :message="msg.content || msg.text"
              :slice="2"
              :is-user-url-only="msg.isUserURLOnly"
              :is-agent="isAgent"
            />
            <msg-link-preview
              v-else
              :message="msg.content || msg.text || ''"
              :slice="2"
              :is-user-url-only="msg.isUserURLOnly"
              :is-agent="isAgent"
            />
          </div>

          <p class="message-created-date">
            {{ msg.createdAtFormatted }}{{ ' ' }}
            <i
              v-if="msg.isBroadcastMessage"
              class="fa fa-podcast broadcast-message"
              aria-hidden="true"
            ></i>
            <i
              v-if="showReadMessage"
              :class="[
                'fas fa-check',
                msg.isRead && msg.status !== -1 && (!msg.ngs || msg.ngs.length === 0)
                  ? 'read-message'
                  : ''
              ]"
            ></i>
          </p>
        </div>

        <div
          v-if="
            typeof (msg.content || msg.text) === 'string' &&
              msg.type !== 'file' &&
              msg.type !== 'postback' &&
              !msg.isUserURLOnly
          "
          :class="['link-pre', directionLinkPreVue]"
        >
          <msg-link-preview
            v-if="msg.typeClass !== 'fallback'"
            :message="msg.content || msg.text"
            :slice="2"
            :is-user-url-only="msg.isUserURLOnly"
            :is-agent="isAgent"
          />
          <msg-link-preview
            v-else
            :message="msg.content || msg.text || ''"
            :slice="2"
            :is-user-url-only="msg.isUserURLOnly"
            :is-agent="isAgent"
          />
        </div>
      </div>
    </div>
    <template v-if="isSystem">
      <div v-for="(msg, idx) in messages" :key="`${idx}_${msg.id}`" class="sys-msg">
        <hr />
        <span class="msg-system badge badge-pill shadow">
          {{ $t(`src.modules.chat.components.ChatBox.MessageList.message-item.${msg.text}`) }}
        </span>
      </div>
    </template>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import { AUDIO_UN_PLAYABLE } from 'core/constants';
import { IMAGE_EXTENSIONS_SUPPORTED } from 'core/constants';
import { TICKET_STATE, READED_MESSAGE_USER_PLATFORM } from 'core/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import { TemplateButton as FbTemplateButton } from 'components/Message/facebook';
import { TemplateProduct as FbTemplateProduct } from 'components/Message/facebook';
import { TemplateStory as InsTemplateStory } from 'components/Message/instagram';
import MsgLinkPreview from './msg-link-preview';
import MsgAudio from './msg-audio';
import MsgVideo from './msg-video';
import MsgFile from './msg-file';
import MsgQuickrep from './msg-quickrep';
import MsgPostback from './msg-postback';
import MsgTyping from './msg-typing';
import MsgList from './msg-list';
import MsgImageMap from './msg-image-map';
import MsgImageMultiple from './msg-image-multiple';
import BotAvatar from 'assets/images/avatar/bot.png';
import noAvatar from 'assets/images/no-avatar.png';
import moment from 'moment';
import { urlify, escapeHtmlChar, getURLLstFrmTxt } from 'core/helpers';
import { iframeHidden } from 'core/helpers';
import { convertTimeUTC } from 'core/helpers';
import MsgLocation from './msg-location';
import { uuid } from 'vue-uuid';
import { i18n } from 'locales';
import HighlightUserPI from 'modules/chat/helpers/highlightUserPI';

const MSG_TYPE_CLASSES = {
  text: ['max-w-85-p'],
  follow: ['max-w-85-p'],
  image: ['fresh-bg', 'has-img'],
  sticker: ['fresh-bg', 'has-img'],
  template: ['fresh-bg', 'w-70-p'],
  template_button: ['fresh-bg', 'w-40-p'],
  template_generic: ['fresh-bg', 'w-85-p'],
  template_generic_1: ['fresh-bg', 'w-300-px'],
  template_generic_2: ['fresh-bg', 'w-50-p'],
  template_generic_3: ['fresh-bg', 'w-75-p'],
  template_image_multiple: ['fresh-bg', 'max-w-420-px', 'split-text-with-attachment'],
  story_mention: ['max-w-85-p'],
  story_reply: ['max-w-85-p'],
  quick_reply: ['fresh-bg', 'max-w-85-p'],
  quick_reply_simple: [],
  file: [],
  audio: [],
  video: ['fresh-bg', 'w-70-p', 'split-text-with-attachment'],
  fallback: [],
  location: [],
  quickreply: [],
  postback: [],
  referral: [],
  template_list_1: ['fresh-bg']
};

export default {
  components: {
    FbTemplateButton,
    FbTemplateProduct,
    InsTemplateStory,
    MsgAudio,
    MsgVideo,
    MsgFile,
    MsgPostback,
    MsgQuickrep,
    MsgList,
    MsgLocation,
    MsgImageMap,
    MsgLinkPreview,
    MsgImageMultiple,
    MsgTyping
  },

  props: {
    sender: {
      type: String,
      default: ''
    },
    messages: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: ''
    },
    isAgent: {
      type: Boolean,
      default: true
    },
    isSystem: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default: () => {}
    },
    readDate: {
      type: String,
      default: '2019'
    },
    platform: {
      type: String,
      default: 'facebook'
    },
    text: {
      type: String,
      default: ''
    },
    inChatBox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUrl: noAvatar,
      warningId: uuid.v4(),
      ngId: uuid.v4(),
      mTime: moment(),
      deletingMessage: {},
      ticketState: TICKET_STATE,
      loadingConfirmDelete: false,
      deleteSuccess: i18n.t('src.modules.chat.components.ChatBox.deleteMessage.success')
    };
  },

  computed: {
    ...mapState('session', ['people', 'channels', 'user', 'channelsMap', 'projectMaps']),
    ...mapGetters('session', ['me', 'isPhoneAgent', 'domainWebview']),
    ...mapGetters('chat', ['selectedConversation']),

    localMessages() {
      const msgLst = [];
      this.messages.map(m => {
        if (!m.content) return null;
        const isTemplate = m.type === 'template';
        let content = isTemplate || m.type === 'quick_reply' ? JSON.parse(m.content) : m.content;
        if (['story_reply', 'story_mention'].includes(m.type)) {
          content = {
            fileContent: JSON.parse(content),
            text: m.text
          };
        }
        const { createdAt, isBot = false } = m;
        const createdAtFormatted = this.formatTime(createdAt);
        const { isURLOnly } = getURLLstFrmTxt(content);
        const isUserURLOnly = !isBot && isURLOnly ? true : false;
        const isRead = this.readDate && createdAt && createdAt.localeCompare(this.readDate) <= 0;
        const obj = m.originMessage && JSON.parse(m.originMessage);
        const cusMsg = {
          content,
          typeClass: isTemplate ? `template_${content.template_type}` : m.type,
          createdAtFormatted,
          isRead,
          isURLOnly,
          isUserURLOnly,
          isBroadcastMessage: !!(obj && obj.isBroadcastMessage)
        };
        //Test data of warning feature: (warnings: ['hi', 'see', 'good'])
        if (m.type === 'quick_reply' && content.hasOwnProperty('quick_replies') === false) {
          // quick_reply from agent -> Show border of css 'postback'
          cusMsg.typeClass = 'postback';
        }
        msgLst.push({
          ...m,
          ...cusMsg
        });
        return {
          ...m,
          ...cusMsg
        };
      });

      return msgLst;
    },

    direction() {
      if (this.isSystem) {
        return 'chat-center';
      }
      return this.isAgent ? 'chat-right' : 'chat-left';
    },

    directionLinkPreVue() {
      if (this.isSystem) {
        return 'chat-center';
      }
      return this.isAgent ? 'link-pre-right' : 'link-pre-left';
    },

    person() {
      if (!this.messages || !this.messages.length) return {};
      const [msg] = this.messages || [null];
      if (!msg) return {};

      const { sender, channelId } = msg || {};
      let people = this.people[`${sender}_${channelId}`] || this.people[sender] || {};
      return people;
    },

    avatar() {
      return this.imageUrl || noAvatar;
    },

    channelPlatform() {
      let channel = this.channels.find(channel => channel.id == this.conversation.channelId);
      return channel && channel.platform;
    },

    name() {
      return this.person && this.person.name;
    },

    showName() {
      return !this.person
        ? ''
        : this.person.name
        ? this.person.name
        : this.person.firstName
        ? `${this.person.firstName}`
        : '';
    },

    allowDeleteMessage() {
      return this.isAgent;
    },

    showReadMessage() {
      return this.isAgent && READED_MESSAGE_USER_PLATFORM.includes(this.platform);
    },

    formDomain() {
      const { projectId = '' } = this.conversation || {};
      return this.domainWebview[projectId] || [];
    }
  },

  watch: {
    people(nVal, oVal) {
      const msg = this.messages[0];
      const { sender, channelId } = msg || {};
      const oldInfo = this.isAgent ? oVal[sender] : oVal[`${sender}_${channelId}`];
      const nInfo = this.isAgent ? nVal[sender] : nVal[`${sender}_${channelId}`];
      if (!oldInfo && nInfo) {
        this.imageUrl = nInfo.pictureUrl || noAvatar;
      } else if (nInfo && nInfo.pictureUrl && nInfo.pictureUrl !== this.imageUrl) {
        this.imageUrl = nInfo.pictureUrl || noAvatar;
      }
    }
  },

  mounted() {
    const msg = this.messages[0];
    const { sender, channelId } = msg || {};
    let people = this.people[`${sender}_${channelId}`] || this.people[sender] || null;
    if (people) {
      this.imageUrl = people.pictureUrl || noAvatar;
    } else {
      if (this.isAgent) {
        if (msg.platform === 'agent') {
          this.addAgentToPeople(msg.sender).then(p => {
            if (p && p.pictureUrl) this.imageUrl = p.pictureUrl;
          });
        } else {
          let channel = this.channels.find(c => c.id == this.conversation.channelId);
          this.imageUrl = (channel && channel.pictureUrl) || BotAvatar;
        }
      } else {
        this.imageUrl = noAvatar;
      }
    }
  },
  methods: {
    ...mapActions('session', ['addAgentToPeople']),
    ...mapActions('chat', ['deleteMessage']),
    ...mapActions('global', ['setGlobalReady']),
    msgClass({ typeClass, content, text }) {
      let msgType = '';
      if (['template_generic'].includes(typeClass)) {
        const items = content ? content.buttons || content.elements || [] : [];
        const itmCount = items.length;
        msgType = itmCount;
        if (itmCount === 1) {
          msgType = `${typeClass}_1`;
        } else if (itmCount === 2) {
          msgType = `${typeClass}_2`;
        } else if (itmCount === 3) {
          msgType = `${typeClass}_3`;
        } else {
          msgType = typeClass;
        }
      } else if (
        msgType === 'quick_reply' &&
        (content.type === 'REQ_AGNT_FEEDBACK' || content.type === 'USER_REQUEST_AGENT_CONFIRM')
      ) {
        msgType = 'quick_reply_simple';
      } else {
        msgType = typeClass;
      }
      let clz = MSG_TYPE_CLASSES[msgType] || [];
      if (text && text.toLowerCase().endsWith('.tiff')) {
        clz = MSG_TYPE_CLASSES['file'];
      }
      return clz.join(' ');
    },

    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    },

    iframeHidden(data) {
      return iframeHidden(data);
    },

    execDomElement(data) {
      return /<[^>]*>/.test(data);
    },

    hasSpecialCharacters(string) {
      // Verify Japanese or Chinese characters
      for (let i = 0, n = string.length; i < n; i++) {
        if (string.charCodeAt(i) > 255) {
          return true;
        }
      }
      return false;
    },

    formatMessage(message, warnings, ngs, status) {
      if (!message || message.length === 0) {
        return message;
      }

      if ((!ngs || ngs.length === 0) && (!warnings || warnings.length === 0) && status === -1) {
        message = `<span class="badge-pill badge-danger ng_words">${message}</span>`;
        message += '<small><i class="fas fa-exclamation-triangle text-danger"/></small>';
        return message;
      }
      message = message.replace(/&quot;/g, '"');
      let isNgSentence = false;
      let isNgWord = false;
      if (ngs && ngs.length > 0) {
        let listNgWordsIndex = [];
        for (let i = 0; i < ngs.length; i++) {
          const keyword = ngs[i] || '';
          let defineRegex;
          let regexp;
          let isSpecialCharacters = false;
          if (this.hasSpecialCharacters(keyword)) {
            // Japanese or Chinese
            isSpecialCharacters = true;
            defineRegex = `${keyword}`;
            regexp = new RegExp(defineRegex, 'gi');
          } else {
            // English and another language
            regexp = this.mapRegexWarningKeyword(keyword);
          }
          const matchNgWords = message.match(regexp);
          if (matchNgWords && matchNgWords.length > 0) {
            const type = 'ng_words';
            let params;
            if (isSpecialCharacters) {
              matchNgWords.forEach(word => {
                params = {
                  message,
                  word,
                  listWordsIndex: listNgWordsIndex
                };
                message = this.recursiveCheckSpecialWords(params, type);
              });
              isNgWord = true;
              isNgSentence = true;
            } else {
              matchNgWords.forEach(word => {
                params = {
                  message,
                  word,
                  listWordsIndex: listNgWordsIndex
                };
                const [messageResult, listWordsIndex] = this.recursiveCheckWords(params, type);
                listNgWordsIndex = [...listWordsIndex];
                message = messageResult;
              });
              isNgWord = true;
              isNgSentence = true;
            }
          }
        }
        if (listNgWordsIndex && listNgWordsIndex.length > 0) {
          for (let i = 0; i < listNgWordsIndex.length; i++) {
            for (let key in listNgWordsIndex[i]) {
              const regexp = new RegExp(`${this.ngId}_${key}`);
              message = message.replace(regexp, listNgWordsIndex[i][key]);
            }
          }
        }
      }

      if (!isNgWord && warnings && warnings.length > 0) {
        let listWarningWordsIndex = [];
        for (let i = 0; i < warnings.length; i++) {
          const keyword = warnings[i] || '';
          let defineRegex;
          let regexp;
          let isSpecialCharacters = false;
          if (this.hasSpecialCharacters(keyword)) {
            // Japanese or Chinese
            isSpecialCharacters = true;
            defineRegex = `${keyword}`;
            regexp = new RegExp(defineRegex, 'gi');
          } else {
            // English and another language
            regexp = this.mapRegexWarningKeyword(keyword);
          }

          const matchWarnWords = message.match(regexp);
          if (matchWarnWords && matchWarnWords.length > 0) {
            const type = 'warning_words';
            let params;
            if (isSpecialCharacters) {
              matchWarnWords.forEach(word => {
                params = {
                  message,
                  word,
                  listWordsIndex: listWarningWordsIndex
                };
                message = this.recursiveCheckSpecialWords(params, type);
              });
            } else {
              matchWarnWords.forEach(word => {
                params = {
                  message,
                  word,
                  listWordsIndex: listWarningWordsIndex
                };
                const [messageResult, listWordsIndex] = this.recursiveCheckWords(params, type);
                listWarningWordsIndex = [...listWordsIndex];
                message = messageResult;
              });
            }
          }
        }
        if (listWarningWordsIndex && listWarningWordsIndex.length > 0) {
          for (let i = 0; i < listWarningWordsIndex.length; i++) {
            for (let key in listWarningWordsIndex[i]) {
              const regexp = new RegExp(`${this.warningId}_${key}`);
              message = message.replace(regexp, listWarningWordsIndex[i][key]);
            }
          }
        }
      }

      if (isNgSentence) {
        message += '<small><i class="fas fa-exclamation-triangle text-danger"/></small>';
      }

      return this.highlightUserPersonalInformation(message);
    },

    mapRegexWarningKeyword(keyword = '') {
      const arr = keyword.split(' ');
      let defineRegex;
      const isNotAlphabet = arr.some(val => !/^[a-zA-Z!@#$%^&*)(+=._-]{1,}$/g.test(val));
      if (isNotAlphabet) {
        defineRegex = `${keyword}`;
      } else {
        defineRegex = `[‘“"'/\\[({#]*\\b${keyword}\\b[\\]})/?@#!'"”’,.;:]*`;
      }
      return new RegExp(defineRegex, 'gi');
    },

    recursiveCheckWords(params, type) {
      let { message, word, listWordsIndex } = params;
      const index = message.indexOf(word);
      if (index < 0) {
        return [message, listWordsIndex];
      }
      const uuidV4 = uuid.v4();
      const nextChatAt = message.charAt(index + word.length);
      const preChatAt = message.charAt(index - 1);

      // Remove symbols at begin & end.
      /*
      Ticket: https://social-gear.atlassian.net/browse/SGCPLUS-1435
      Note:
        (1) End with: " ", """, "'", ",", ";", ".", ":", "!", "?", "\n", ")", "]", "}", "@", "/", "#", "“", "‘"
        (2) Start with: " ", """, "'", "/", "[", "(", "{", "#", "”", "’"
        (3) If ng/warning words contains each other’s sub-string, show the longest one.
      */

      //Ref: https://stackoverflow.com/questions/4374822/remove-all-special-characters-with-regexp
      // Unit test of Ng
      // Test case 1: "Kill 'kIll /kiLl [kiLL (kill {kill #kill
      // Test case 2: [Kill] {kIll} (kiLl) kilL/ kill? kill@ #kill# kill! 'kill' "kill" kill, kill. kill; kill:
      // Test case 3: \Kill -kIll +kiLl *kilL &kill ^kill %kill !kill ~kill <Kill>

      // Unit test of Warning:
      // Test case 1: "Angry 'aNgry /anGry [angRy (angrY {angry #angry #agent warning test agent warning
      // Test case 2: [Angry] {aNgry} (anGry) angRy/ angrY? angry@ #angry# angry! 'angry' "angry" angry, angry. angry; angry: [agent warning]
      // Test case 3: \Angry\ -aNgry +angRy *anGry &angRy ^angrY %angry !angry ~angrY <angrY> _angrY $angrY$
      let keyword = word.replace(/[`~!@#%^&*()|+\-=?!@#;:'",.{}[\]\/]/gi, ''); // eslint-disable-line
      listWordsIndex.push({ [uuidV4]: keyword });
      if (
        (!nextChatAt || nextChatAt === ' ' || nextChatAt === '\n') &&
        (!preChatAt || preChatAt === ' ' || preChatAt === '\n')
      ) {
        let replaceTo = '';
        switch (type) {
          case 'warning_words':
            replaceTo = `<span class="badge-pill badge-warning warning_words">${this.warningId}_${uuidV4}</span>`;
            break;
          case 'ng_words':
            replaceTo = `<span class="badge-pill badge-danger ng_words">${this.ngId}_${uuidV4}</span>`;
            break;
        }
        let keywordIndex = word.indexOf(keyword);
        keywordIndex = index + keywordIndex;
        message = message.replace(
          message.substring(keywordIndex, keyword.length + keywordIndex),
          replaceTo
        );
      } else {
        let replaceTo = '';
        switch (type) {
          case 'warning_words':
            replaceTo = `${this.warningId}_${uuidV4}`;
            break;
          case 'ng_words':
            replaceTo = `${this.ngId}_${uuidV4}`;
            break;
        }
        message = message.replace(message.substring(index, word.length + index), replaceTo);
        return this.recursiveCheckWords(
          {
            message,
            word,
            listWordsIndex
          },
          type
        );
      }
      return [message, listWordsIndex];
    },

    recursiveCheckSpecialWords(params, type) {
      let { message, word, listWordsIndex } = params;
      const index = message.indexOf(word);
      if (index < 0) {
        return message;
      }
      const uuidV4 = uuid.v4();
      listWordsIndex.push({ [uuidV4]: word });
      let replaceTo = '';
      switch (type) {
        case 'warning_words':
          replaceTo = `<span class="badge-pill badge-warning warning_words">${this.warningId}_${uuidV4}</span>`;
          break;
        case 'ng_words':
          replaceTo = `<span class="badge-pill badge-danger ng_words">${this.ngId}_${uuidV4}</span>`;
          break;
      }
      message = message.replace(message.substring(index, word.length + index), replaceTo);
      return message;
    },

    highlightUserPersonalInformation(message) {
      if (this.isAgent) return message;
      const highlightUserPI = new HighlightUserPI(message);
      message = highlightUserPI.runRegexp();
      message = highlightUserPI.decode();
      return message;
    },

    imageLoadError() {
      this.imageUrl = noAvatar;
    },

    isString(value) {
      return value && typeof value === 'string';
    },

    confirmDeleteMessage(message) {
      const { channelId, conversationId, id: messageId, recipient: userId } = message;
      const channel = this.channels.find(c => c.id === channelId);
      const { projectId } = channel || {};
      this.deletingMessage = {
        projectId,
        conversationId,
        messageId,
        channelId,
        userId,
        deletedBy: this.user.id
      };
      this.$baseConfirm({
        message: i18n.t('src.modules.chat.components.ChatBox.deleteMessage.confirm.content')
      })
        .then(() => {
          this.processDeleteMessage();
        })
        .catch(() => {});
    },

    replacingMessageRequestForm(message) {
      EventBus.$emit('replacingMessageRequestForm', { isNew: true, message });
    },

    async processDeleteMessage() {
      try {
        this.setGlobalReady(false);
        await this.deleteMessage(this.deletingMessage);
        this.setGlobalReady(true);
        this.$baseNotification.success({
          title: i18n.t('src.core.App.success'),
          message: this.deleteSuccess
        });
      } catch (error) {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: error.message
        });
      }
    },

    getGroupIds(projectId) {
      const { assignedProjects = [] } = this.user;
      return assignedProjects.reduce((acc, pj) => {
        const { id = '', assignedGroups = [] } = pj;
        if (projectId === id && assignedGroups.length > 0) {
          const ids = Object.values(assignedGroups).map(i => i.id);
          acc = [...acc, ...ids];
        }
        return acc;
      }, []);
    },

    _isImagesSupported(url) {
      let urlDecoded = decodeURIComponent(url);
      let arrayPath = decodeURIComponent(urlDecoded).split('?') || [];
      let imagePath = arrayPath[0] || '';
      let imageFileName = imagePath.split('/').pop();
      const isSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
        imageFileName.toLowerCase().endsWith(ex)
      );
      return isSupported;
    },

    _isAudioUnPlayable(url) {
      let urlDecoded = decodeURIComponent(url);
      let arrayPath = urlDecoded.split('?') || [];
      let imagePath = arrayPath[0] || '';
      let audioFileName = imagePath.split('/').pop();
      const isAudioUnPlayable = AUDIO_UN_PLAYABLE.some(ex =>
        audioFileName.toLowerCase().endsWith(ex)
      );
      return isAudioUnPlayable;
    },

    _downloadFile(url) {
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.ChatBox.MessageList.message-item.confirm_to_download_file'
        ),
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false
      })
        .then(() => {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.download = 'proposed_file_name';
          anchor.click();
        })
        .catch(() => {});
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.link-pre {
  position: relative;
  display: block;
  margin: 0 20px 10px 0;
  clear: both;
  border-radius: 0.286rem;
  &:empty {
    display: none;
  }
}

.link-pre-right {
  float: right;
  margin: 0 20px 10px 0;
}
.link-pre-left {
  float: left;
  margin: 0 0 10px 20px;
}

.chat {
  .chat-body {
    .chat-name {
      font-size: 11px;
      float: left;
      margin-left: 20px;
    }
  }

  &.chat-right {
    .has-img {
      text-align: right;
    }

    .img-multiple-at {
      justify-content: flex-end;
    }

    .message-created-date {
      float: right;
    }
  }

  &.chat-left {
    .has-img {
      text-align: left;
    }
  }
  &.chat-center {
    position: relative;

    .has-img {
      text-align: center;
    }
  }
  .chat-avatar {
    .avatar {
      width: 48px !important;
      margin-top: 0px !important;
    }
  }

  .chat-content {
    text-align: left;

    & > p:last-child {
      word-break: break-all;
    }

    &.fresh-bg {
      background-color: transparent;
      border: none;
      box-shadow: none !important;
      padding: 0 !important;
    }

    &.max-w-85-p {
      max-width: 85%;
    }

    &.w-70-p {
      width: 70%;
    }

    &.w-30-p {
      width: 30%;
    }

    &.max-w-420-px {
      max-width: 420px;
    }

    &.w-300-px {
      width: 300px;
    }

    &.w-85-p {
      width: 85%;
    }

    &.w-75-p {
      width: 75%;
    }

    &.w-40-p {
      width: 40%;
    }

    &.w-50-p {
      width: 50%;
    }

    &.w-60-p {
      width: 60%;
    }

    &.has-img {
      img {
        max-width: 70%;
        cursor: pointer;
      }
    }

    .message-created-date {
      font-size: 11px;
      float: right;
      color: #adb5bd;
      margin-bottom: -5px;
    }
  }

  &.chat-right {
    .chat-content {
      .message-created-date {
        color: #e0e0e0;
      }
      &.fresh-bg {
        .message-created-date {
          color: #adb5bd;
        }
      }
    }

    .chat-body {
      .chat-name {
        float: right;
        margin-left: 0px;
        margin-right: 20px;
      }
    }
  }
}

.text-style {
  white-space: pre-wrap;
  word-break: break-word;
}

.split-text-with-attachment > p.text-style {
  color: #465053;
  background-color: #eeeeee;
  border: 1px solid #ddd;
  padding: 8px;
}

.removeMessageToolTip {
  background: white;
}

.read-message {
  color: chartreuse;
}
.broadcast-message {
  color: #fb6340;
}
</style>

<style lang="scss">
.text-style {
  .warning_words {
    color: yellow;
    padding: 0;
  }

  .ng_words {
    color: red;
    padding: 0;
  }

  .user_personal_information {
    color: #007de1;
    padding: 0;

    /deep/ a {
      color: #007de1;
    }
  }
}

.message-tooltip {
  .bs-tooltip-left {
    z-index: 9;
  }
}
</style>