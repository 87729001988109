<template>
  <b-modal
    id="ScenarioTemplateBox"
    ref="scenarioTemplateBox"
    :title="$t('src.modules.chat.components.ScenarioTemplateBox.index.agent_scenario')"
    size="lg"
    v-model="show"
    :no-close-on-backdrop="true"
    @hidden="resetAllState"
  >
    <!-- Scenario Name (S) -->
    <div class="form-group">
      <label class="text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold __title">
        <i class="fas fa-grip-vertical m-r-10"></i>
        {{ scenarioName }}
      </label>
    </div>
    <!-- Scenario Name (E) -->

    <!-- Hint text (S) -->
    <div class="nat-hint-text">
      {{
        $t(
          'src.modules.chat.components.ScenarioTemplateBox.index.please_check_the_scenario_before_sending._you_can_edit_content_in_text_message_template'
        )
      }}
    </div>
    <!-- Hint text (E) -->

    <!-- Scenario List (S) -->
    <div class="scenario-list">
      <ValidationObserver ref="scenarioListVldtn">
        <scenario-list
          :scenarios="scenarios"
          :loading="loading"
          @onChangeData="handleOnChangeData"
        />
      </ValidationObserver>
    </div>
    <!-- Scenario List  (E) -->

    <!-- Action List (S) -->
    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right ml-10" variant="primary" @click="onSend" :disabled="loading">{{
        $t('common.text.send')
      }}</b-btn>
      <b-btn class="float-right" variant="default" @click="onClose">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>

    <!-- Action List (S) -->
  </b-modal>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapActions, mapState, mapGetters } from 'vuex';
import ScenarioList from './ScenarioList';
import cloneDeep from 'lodash/cloneDeep';

function defaultState() {
  return {
    scenarioName: '',
    scenarioId: '',
    scenarioLng: 'en_US',
    scenarios: [],
    scenariosUpdt: [],
    replacements: [],
    loading: false,
    show: false
  };
}

export default {
  components: {
    ScenarioList
  },

  data: defaultState,

  computed: {
    ...mapState('session', ['channelsMap']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', ['actionSettings'])
  },

  created() {
    this.scenarios = [];
    this.scenariosUpdt = [];
    this.replacements = [];
    this.loading = false;
    EventBus.$on('scenarioTemplateBox', this.handleOpenPopup);
  },

  destroyed() {
    EventBus.$off('scenarioTemplateBox', this.handleOpenPopup);
  },

  mounted() {
    this.loading = false;
    this.scenarios = [];
    this.scenariosUpdt = [];
    this.replacements = [];
  },

  methods: {
    ...mapActions('conversationMng', [
      'sendScenario',
      'getParserScenario',
      'getSurveyDataByTicket'
    ]),

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handleOnChangeData(params) {
      const { id, text } = params;

      const foundItem = this.replacements.find(item => item.position === id);
      if (foundItem) {
        foundItem.text = text;
      } else {
        this.replacements.push({
          text: text,
          position: id
        });
      }

      if (this.scenariosUpdt[id] && this.scenariosUpdt[id].type === 'text') {
        this.scenariosUpdt[id].content = text;
      }
    },

    async handleOpenPopup(scenario) {
      this.loading = false;
      this.scenarios = [];
      this.scenariosUpdt = [];
      const { name, ref, usedLang } = scenario;

      this.scenarioLng = usedLang;
      this.scenarioName = name;
      this.scenarioId = ref;
      this.loading = true;

      const { channelId, ticket: { id } = {} } = this.selectedConversation || {};
      const { platform } = this.channelsMap[channelId];

      /**
       * Get Parser Scenario from CORE
       */
      let scenarioContent = await this.getParserScenario({
        campaignId: ref,
        platform,
        language: this.scenarioLng
      });
      // eslint-disable-next-line
      console.log(
        '[C+ Debug] [NOTIFY] ScenarioTemplateBox >>> handleOpenPopup >> getParserScenario > scenarioContent',
        scenarioContent
      );
      if (scenarioContent === 'error') {
        this.scenarios = [];
        this.scenariosUpdt = cloneDeep(this.scenarios);
        this.loading = false;
        return this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.bot_service_is_not_available'
          )
        });
      }

      const regex = new RegExp('(({sf\\.){1}[0-9a-zA-Z.-]+((\\.question}|\\.answer}){1}))', 'gm');
      if (scenarioContent.some(s => s.type === 'text' && regex.test(s.content))) {
        const { surveyDataObj } = await this.getSurveyDataByTicket({
          ticketId: id,
          language: this.scenarioLng
        });
        scenarioContent = surveyDataObj
          ? scenarioContent.map(s => {
              if (s.type !== 'text') return s;
              if (!regex.test(s.content)) return s;
              let { content } = s;
              Object.keys(surveyDataObj).map(
                k =>
                  (content = content.includes(k)
                    ? content.replace(new RegExp(k, 'g'), surveyDataObj[k])
                    : content)
              );
              
              let index = 0;
              while (index !== -1) {
                const { index: i, value } = this.getIndex(content, regex);
                content = value ? content.replace(value, '') : content;
                index = i;
              }
              return { ...s, content };
            })
          : scenarioContent;
      }
      this.scenarios = scenarioContent;
      this.scenariosUpdt = cloneDeep(this.scenarios);
      this.loading = false;
    },

    getIndex(content, regex, currentIndex = -1) {
      let value;
      let indexes = [];
      while ((value = regex.exec(content)) !== null && value[0] !== null) {
        indexes.push({ index: value['index'], value: value[0] });
      }

      indexes = indexes.filter(i => i.index >= currentIndex);
      const firstObj = indexes && indexes.length ? indexes[0] : { index: -1, value: '' };
      return firstObj;
    },

    async onSend(params, sendWithConfirm = true) {
      this.$refs.scenarioListVldtn &&
        this.$refs.scenarioListVldtn.validate().then(async result => {
          const isEmptyText = this.replacements.length > 0 && this.replacements.some(i => !i.text);
          if (!result || isEmptyText) {
            return this.errorMessage();
          }
          let paramsUpdate = params || {};
          const { channelId = '', userId = '' } = this.selectedConversation;
          const { platform } = this.channelsMap[channelId];
          paramsUpdate = {
            scenarioId: this.scenarioId,
            recipientId: userId,
            channelId,
            platform,
            scenarioName: this.scenarioName,
            conversation: JSON.stringify(this.selectedConversation),
            language: this.scenarioLng,
            replacements: this.replacements
          };

          if (
            this.actionSettings &&
            this.actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
            sendWithConfirm
          ) {
            return this.$baseConfirm({
              message: this.$t(
                'src.modules.chat.store.actions.are_you_sure_to_send_this_scenario_to_user'
              )
            })
              .then(() => {
                this.onSend(paramsUpdate, (sendWithConfirm = false));
              })
              .catch(() => {});
          }

          this.show = false;
          const data = await this.sendScenario(paramsUpdate);
          if (data === 'error') {
            return this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t(
                'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.bot_service_is_not_available'
              )
            });
          }
          return this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t(
              'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.scenarioHasSent',
              { scnName: paramsUpdate.scenarioName.substring(0, 300) }
            )
          });
        });
    },

    onClose() {
      this.show = false;
    },

    errorMessage() {
      this.$baseNotification.error({
        title: this.$t('src.core.App.error'),
        message: this.$t('veevalidate.validations.required', {
          fieldName: this.$t('src.core.constants.scenarioTemplateTypeName.text_message')
        })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.__title {
  word-wrap: break-word;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
  font-style: italic;
  text-align: center;
  margin: 10px;
}

.scenario-list {
  /deep/.video-js {
    max-width: 490px;
  }
}
.scenario-list {
  height: 500px;
  overflow: auto;
}
.scenario-list .chats {
  margin-top: 15px;
}
</style>
