<template>
  <carousel
    v-if="showCarousel"
    ref="carousel"
    :scroll-per-page="!displayFlex"
    :navigation-enabled="!displayFlex"
    :pagination-enabled="false"
    :per-page="perPage"
    :class="displayFlex ? 'display-flex-end' : ''"
    :mouse-drag="!displayFlex"
    navigation-next-label="<i class='material-icons'>navigate_next</i>"
    navigation-prev-label="<i class='material-icons'>navigate_before</i>"
  >
    <slide v-for="(p, idx) in products" :key="`fb_tmpl_prod_${idx + 1}`">
      <product-item
        v-bind="p"
        :has-subtitle="hasSubtitle"
        :has-image="hasImage"
        :has-button="hasButton"
        :domain-webview="domainWebview"
      />
    </slide>
  </carousel>
  <div v-else class="product-non-carousel" style="float:right;">
    <product-item
      v-for="(p, idx) in products"
      :key="`fb_tmpl_prod_${idx + 1}`"
      :has-subtitle="hasSubtitle"
      :has-image="hasImage"
      :has-button="hasButton"
      :domain-webview="domainWebview"
      v-bind="p"
    />
  </div>
</template>

<script>
import ProductItem from './product-item';

const slidesPerView = 2;

export default {
  components: {
    ProductItem
  },

  props: {
    message: {
      type: Object,
      default: () => {}
    },
    domainWebview: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      perPage: 0,
      displayFlex: false
    };
  },

  computed: {
    products() {
      let product = (this.message && this.message.elements) || [];

      return product.map(item => {
        if (Array.isArray(item.title) && item.title.length > 1) {
          return {
            ...item,
            title: item.title[0],
            subtitle: item.title[1]
          };
        }
        return item;
      });
    },

    hasSubtitle() {
      const items = this.products || [];
      if (!items && items.length === 0) return false;
      return items.reduce((acc, item) => {
        const { subtitle = '' } = item;
        if (subtitle) acc = true;

        return acc;
      }, false);
    },

    hasImage() {
      const items = this.products || [];
      if (!items && items.length === 0) return false;
      return items.reduce((acc, item) => {
        const { image_url = '' } = item;
        if (image_url) acc = true;

        return acc;
      }, false);
    },

    hasButton() {
      const items = this.products || [];
      if (!items && items.length === 0) return false;
      return items.reduce((acc, item) => {
        const { buttons = [] } = item;
        if (buttons && buttons.length > 0) acc = true;

        return acc;
      }, false);
    },

    showNav() {
      return this.itemCount > slidesPerView;
    },

    itemCount() {
      return this.products.length;
    },

    showCarousel() {
      return this.products && this.products.length > 1;
    }
  },

  mounted() {
    if (this.$refs.carousel) {
      const { carouselWidth } = this.$refs.carousel;
      this.detectPerPage(carouselWidth);

      this.$root.$on('bv::modal::show', bvEvent => {
        const { id: modalId } = bvEvent.target;
        const listModalIds = ['MonitoringConversation', 'TicketHistory'];
        if (listModalIds.includes(modalId) && this.$refs.carousel) {
          const { carouselWidth } = this.$refs.carousel;
          this.detectPerPage(carouselWidth);
        }
      });
    }

    this.$watch(
      () => {
        return this.$refs.carousel && this.$refs.carousel.carouselWidth;
      },
      value => {
        this.detectPerPage(value);
      }
    );
  },

  methods: {
    detectPerPage(carouselWidth) {
      this.$refs.carousel.onResize();
      this.$refs.carousel.goToPage(0);
      const maxWidthItem = 240;
      if (carouselWidth >= maxWidthItem * this.itemCount) {
        this.displayFlex = true;
        return;
      }

      if (carouselWidth <= 360) {
        this.perPage = 1;
      } else if (carouselWidth <= 480) {
        this.perPage = 1.5;
      } else if (carouselWidth <= 600) {
        this.perPage = 2;
      } else if (carouselWidth <= 720) {
        this.perPage = 2.5;
      } else if (carouselWidth <= 1024) {
        this.perPage = 3;
      } else {
        this.perPage = 3.5;
      }

      this.displayFlex = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.display-flex-end {
  /deep/ .VueCarousel-inner {
    justify-content: flex-end !important;
    margin-right: -10px;
  }
}

.VueCarousel {
  color: #32325d;
  /deep/ .VueCarousel-navigation {
    .VueCarousel-navigation-button {
      border: 1px solid #ddd;
      background-color: white;
      padding: 5px 5px 0 !important;
      &.VueCarousel-navigation-next {
        right: 20px;
      }

      &.VueCarousel-navigation-prev {
        margin-left: 40px;
      }
    }
  }

  /deep/ .VueCarousel-wrapper {
    .VueCarousel-slide {
      margin-right: 3px;
    }
  }

  /deep/ .VueCarousel-inner {
    display: flex;
    justify-content: flex-start;
  }

  .VueCarousel-slide {
    width: 240px;
    max-width: 240px;
    background-color: white;
    border: 1px solid #ddd;
  }
}
</style>
