<template>
  <div data-role="content" class="scrollable-content">
    <nav
      v-if="conversationFeeds.length > 0"
      v-b-toggle="toggleName"
      class="navbar navbar-expand-lg navbar-dark bg-info"
    >
      <div class="container p-0">
        <div class="channel-header">
          <span
            v-if="conversationFeeds.length > 0"
            class="badge badge-pill text-white bg-secondary"
          >{{ conversationFeeds.length }}</span>
          <span class="navbar-brand">{{ catelogKeyShow }}</span>
        </div>
        <i :class="['text-white', collapseIcon]" />
      </div>
    </nav>
    <b-collapse
      :id="toggleName"
      :visible="false"
      accordion="sidebar-conversationFeed"
      @show="collapsed = false"
      @hide="collapsed = true"
    >
      <ul
        :class="['list-group', !conversationFeeds.length?'':'border', !conversationFeeds.length?'':'p-20']"
      >
        <conversation-feed-item
          v-for="(conversationFeed, index) in conversationFeeds"
          :key="index"
          v-bind="conversationFeed"
          class="cf-item"
        />
      </ul>
    </b-collapse>
  </div>
</template>

<script>
import ConversationFeedItem from './ConversationFeedItem';

export default {
  components: {
    ConversationFeedItem
  },

  props: {
    conversationFeeds: {
      type: Array,
      default: () => []
    },
    catelogKey: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      collapsed: true
    };
  },

  computed: {
    collapseIcon() {
      return this.collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up';
    },

    toggleName() {
      return `conversation-feed-list-${this.catelogKey}`;
    },

    catelogKeyShow() {
      return this.catelogKey;
    }
  },

  created() {}
};
</script>

<style lang="scss" scoped>
nav.navbar {
  cursor: pointer;
}

.channel-header {
  display: inline-block;
  width: 100%;
  .avatar-xs {
    width: 30px;
    height: 30px;
  }

  .avatar {
    .badge {
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 8px;
    }
  }

  .navbar-brand {
    display: inline-block;
    position: absolute;
    left: 60px;
    top: 13px;
  }
}

.h--0 {
  height: 0 !important;
}

.h--100vh {
  height: 100% !important;
}

ul.list-group {
  overflow: hidden;
  transition: all 0.1s ease;
  -moz-transition: all 0.1s ease; /* Firefox 4 */
  -webkit-transition: all 0.1s ease; /* Safari and Chrome */
  -o-transition: all 0.1s ease; /* Opera */

  .cf-item {
    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.waiting-height {
  height: calc(100vh - 235px) !important;
  overflow: scroll;
}
</style>
