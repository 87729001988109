export class listLocales {
  getList() {
    let countrys = {
      AF: { c: 'Afghanistan' },
      AX: { c: '\u00c5land Islands' },
      AL: { c: 'Albania' },
      DZ: { c: 'Algeria' },
      AS: { c: 'American Samoa' },
      AD: { c: 'Andorra' },
      AO: { c: 'Angola' },
      AI: { c: 'Anguilla' },
      AQ: { c: 'Antarctica' },
      AG: { c: 'Antigua and Barbuda' },
      AR: { c: 'Argentina' },
      AM: { c: 'Armenia' },
      AW: { c: 'Aruba' },
      AU: { c: 'Australia' },
      AT: { c: 'Austria' },
      AZ: { c: 'Azerbaijan' },
      BS: { c: 'Bahamas' },
      BH: { c: 'Bahrain' },
      BD: { c: 'Bangladesh' },
      BB: { c: 'Barbados' },
      BY: { c: 'Belarus' },
      BE: { c: 'Belgium' },
      BZ: { c: 'Belize' },
      BJ: { c: 'Benin' },
      BM: { c: 'Bermuda' },
      BT: { c: 'Bhutan' },
      BO: { c: 'Bolivia, Plurinational State of' },
      BQ: { c: 'Bonaire, Sint Eustatius and Saba' },
      BA: { c: 'Bosnia and Herzegovina' },
      BW: { c: 'Botswana' },
      BV: { c: 'Bouvet Island' },
      BR: { c: 'Brazil' },
      IO: { c: 'British Indian Ocean Territory' },
      BN: { c: 'Brunei Darussalam' },
      BG: { c: 'Bulgaria' },
      BF: { c: 'Burkina Faso' },
      BI: { c: 'Burundi' },
      KH: { c: 'Cambodia' },
      CM: { c: 'Cameroon' },
      CA: { c: 'Canada' },
      CV: { c: 'Cape Verde' },
      KY: { c: 'Cayman Islands' },
      CF: { c: 'Central African Republic' },
      TD: { c: 'Chad' },
      CL: { c: 'Chile' },
      CN: { c: 'China' },
      CX: { c: 'Christmas Island' },
      CC: { c: 'Cocos (Keeling) Islands' },
      CO: { c: 'Colombia' },
      KM: { c: 'Comoros' },
      CG: { c: 'Congo' },
      CD: { c: 'Congo, the Democratic Republic of the' },
      CK: { c: 'Cook Islands' },
      CR: { c: 'Costa Rica' },
      CI: { c: 'C\u00f4te dIvoire' },
      HR: { c: 'Croatia' },
      CU: { c: 'Cuba' },
      CW: { c: 'Cura\u00e7ao' },
      CY: { c: 'Cyprus' },
      CZ: { c: 'Czech Republic' },
      DK: { c: 'Denmark' },
      DJ: { c: 'Djibouti' },
      DM: { c: 'Dominica' },
      DO: { c: 'Dominican Republic' },
      EC: { c: 'Ecuador' },
      EG: { c: 'Egypt' },
      SV: { c: 'El Salvador' },
      GQ: { c: 'Equatorial Guinea' },
      ER: { c: 'Eritrea' },
      EE: { c: 'Estonia' },
      ET: { c: 'Ethiopia' },
      FK: { c: 'Falkland Islands (Malvinas)' },
      FO: { c: 'Faroe Islands' },
      FJ: { c: 'Fiji' },
      FI: { c: 'Finland' },
      FR: { c: 'France' },
      GF: { c: 'French Guiana' },
      PF: { c: 'French Polynesia' },
      TF: { c: 'French Southern Territories' },
      GA: { c: 'Gabon' },
      GM: { c: 'Gambia' },
      GE: { c: 'Georgia' },
      DE: { c: 'Germany' },
      GH: { c: 'Ghana' },
      GI: { c: 'Gibraltar' },
      GR: { c: 'Greece' },
      GL: { c: 'Greenland' },
      GD: { c: 'Grenada' },
      GP: { c: 'Guadeloupe' },
      GU: { c: 'Guam' },
      GT: { c: 'Guatemala' },
      GG: { c: 'Guernsey' },
      GN: { c: 'Guinea' },
      GW: { c: 'Guinea-Bissau' },
      GY: { c: 'Guyana' },
      HT: { c: 'Haiti' },
      HM: { c: 'Heard Island and McDonald Islands' },
      VA: { c: 'Holy See (Vatican City State)' },
      HN: { c: 'Honduras' },
      HK: { c: 'Hong Kong' },
      HU: { c: 'Hungary' },
      IS: { c: 'Iceland' },
      IN: { c: 'India' },
      ID: { c: 'Indonesia' },
      IR: { c: 'Iran, Islamic Republic of' },
      IQ: { c: 'Iraq' },
      IE: { c: 'Ireland' },
      IM: { c: 'Isle of Man' },
      IL: { c: 'Israel' },
      IT: { c: 'Italy' },
      JM: { c: 'Jamaica' },
      JP: { c: 'Japan' },
      JE: { c: 'Jersey' },
      JO: { c: 'Jordan' },
      KZ: { c: 'Kazakhstan' },
      KE: { c: 'Kenya' },
      KI: { c: 'Kiribati' },
      KP: { c: 'Korea, Democratic People Republic of' },
      KR: { c: 'Korea, Republic of' },
      KW: { c: 'Kuwait' },
      KG: { c: 'Kyrgyzstan' },
      LA: { c: 'Lao Peoples Democratic Republic' },
      LV: { c: 'Latvia' },
      LB: { c: 'Lebanon' },
      LS: { c: 'Lesotho' },
      LR: { c: 'Liberia' },
      LY: { c: 'Libya' },
      LI: { c: 'Liechtenstein' },
      LT: { c: 'Lithuania' },
      LU: { c: 'Luxembourg' },
      MO: { c: 'Macao' },
      MK: { c: 'Macedonia, the Former Yugoslav Republic of' },
      MG: { c: 'Madagascar' },
      MW: { c: 'Malawi' },
      MY: { c: 'Malaysia' },
      MV: { c: 'Maldives' },
      ML: { c: 'Mali' },
      MT: { c: 'Malta' },
      MH: { c: 'Marshall Islands' },
      MQ: { c: 'Martinique' },
      MR: { c: 'Mauritania' },
      MU: { c: 'Mauritius' },
      YT: { c: 'Mayotte' },
      MX: { c: 'Mexico' },
      FM: { c: 'Micronesia, Federated States of' },
      MD: { c: 'Moldova, Republic of' },
      MC: { c: 'Monaco' },
      MN: { c: 'Mongolia' },
      ME: { c: 'Montenegro' },
      MS: { c: 'Montserrat' },
      MA: { c: 'Morocco' },
      MZ: { c: 'Mozambique' },
      MM: { c: 'Myanmar' },
      NA: { c: 'Namibia' },
      NR: { c: 'Nauru' },
      NP: { c: 'Nepal' },
      NL: { c: 'Netherlands' },
      NC: { c: 'New Caledonia' },
      NZ: { c: 'New Zealand' },
      NI: { c: 'Nicaragua' },
      NE: { c: 'Niger' },
      NG: { c: 'Nigeria' },
      NU: { c: 'Niue' },
      NF: { c: 'Norfolk Island' },
      MP: { c: 'Northern Mariana Islands' },
      NO: { c: 'Norway' },
      OM: { c: 'Oman' },
      PK: { c: 'Pakistan' },
      PW: { c: 'Palau' },
      PS: { c: 'Palestine, State of' },
      PA: { c: 'Panama' },
      PG: { c: 'Papua New Guinea' },
      PY: { c: 'Paraguay' },
      PE: { c: 'Peru' },
      PH: { c: 'Philippines' },
      PN: { c: 'Pitcairn' },
      PL: { c: 'Poland' },
      PT: { c: 'Portugal' },
      PR: { c: 'Puerto Rico' },
      QA: { c: 'Qatar' },
      RE: { c: 'R\u00e9union' },
      RO: { c: 'Romania' },
      RU: { c: 'Russian Federation' },
      RW: { c: 'Rwanda' },
      BL: { c: 'Saint Barth\u00e9lemy' },
      SH: { c: 'Saint Helena, Ascension and Tristan da Cunha' },
      KN: { c: 'Saint Kitts and Nevis' },
      LC: { c: 'Saint Lucia' },
      MF: { c: 'Saint Martin (French part)' },
      PM: { c: 'Saint Pierre and Miquelon' },
      VC: { c: 'Saint Vincent and the Grenadines' },
      WS: { c: 'Samoa' },
      SM: { c: 'San Marino' },
      ST: { c: 'Sao Tome and Principe' },
      SA: { c: 'Saudi Arabia' },
      SN: { c: 'Senegal' },
      RS: { c: 'Serbia' },
      SC: { c: 'Seychelles' },
      SL: { c: 'Sierra Leone' },
      SG: { c: 'Singapore' },
      SX: { c: 'Sint Maarten (Dutch part)' },
      SK: { c: 'Slovakia' },
      SI: { c: 'Slovenia' },
      SB: { c: 'Solomon Islands' },
      SO: { c: 'Somalia' },
      ZA: { c: 'South Africa' },
      GS: { c: 'South Georgia and the South Sandwich Islands' },
      SS: { c: 'South Sudan' },
      ES: { c: 'Spain' },
      LK: { c: 'Sri Lanka' },
      SD: { c: 'Sudan' },
      SR: { c: 'Suriname' },
      SJ: { c: 'Svalbard and Jan Mayen' },
      SZ: { c: 'Swaziland' },
      SE: { c: 'Sweden' },
      CH: { c: 'Switzerland' },
      SY: { c: 'Syrian Arab Republic' },
      TW: { c: 'Taiwan, Province of China' },
      TJ: { c: 'Tajikistan' },
      TZ: { c: 'Tanzania, United Republic of' },
      TH: { c: 'Thailand' },
      TL: { c: 'Timor-Leste' },
      TG: { c: 'Togo' },
      TK: { c: 'Tokelau' },
      TO: { c: 'Tonga' },
      TT: { c: 'Trinidad and Tobago' },
      TN: { c: 'Tunisia' },
      TR: { c: 'Turkey' },
      TM: { c: 'Turkmenistan' },
      TC: { c: 'Turks and Caicos Islands' },
      TV: { c: 'Tuvalu' },
      UG: { c: 'Uganda' },
      UA: { c: 'Ukraine' },
      AE: { c: 'United Arab Emirates' },
      GB: { c: 'United Kingdom' },
      US: { c: 'United States' },
      UM: { c: 'United States Minor Outlying Islands' },
      UY: { c: 'Uruguay' },
      UZ: { c: 'Uzbekistan' },
      VU: { c: 'Vanuatu' },
      VE: { c: 'Venezuela, Bolivarian Republic of' },
      VN: { c: 'Viet Nam' },
      VG: { c: 'Virgin Islands, British' },
      VI: { c: 'Virgin Islands, U.S.' },
      WF: { c: 'Wallis and Futuna' },
      EH: { c: 'Western Sahara' },
      YE: { c: 'Yemen' },
      ZM: { c: 'Zambia' },
      ZW: { c: 'Zimbabwe' }
    };

    let isoLangs = {
      ab: {
        name: 'Abkhaz',
        nativeName: 'аҧсуа'
      },
      aa: {
        name: 'Afar',
        nativeName: 'Afaraf'
      },
      af: {
        name: 'Afrikaans',
        nativeName: 'Afrikaans'
      },
      ak: {
        name: 'Akan',
        nativeName: 'Akan'
      },
      sq: {
        name: 'Albanian',
        nativeName: 'Shqip'
      },
      am: {
        name: 'Amharic',
        nativeName: 'አማርኛ'
      },
      ar: {
        name: 'Arabic',
        nativeName: 'العربية'
      },
      an: {
        name: 'Aragonese',
        nativeName: 'Aragonés'
      },
      hy: {
        name: 'Armenian',
        nativeName: 'Հայերեն'
      },
      as: {
        name: 'Assamese',
        nativeName: 'অসমীয়া'
      },
      av: {
        name: 'Avaric',
        nativeName: 'авар мацӀ, магӀарул мацӀ'
      },
      ae: {
        name: 'Avestan',
        nativeName: 'avesta'
      },
      ay: {
        name: 'Aymara',
        nativeName: 'aymar aru'
      },
      az: {
        name: 'Azerbaijani',
        nativeName: 'azərbaycan dili'
      },
      bm: {
        name: 'Bambara',
        nativeName: 'bamanankan'
      },
      ba: {
        name: 'Bashkir',
        nativeName: 'башҡорт теле'
      },
      eu: {
        name: 'Basque',
        nativeName: 'euskara, euskera'
      },
      be: {
        name: 'Belarusian',
        nativeName: 'Беларуская'
      },
      bn: {
        name: 'Bengali',
        nativeName: 'বাংলা'
      },
      bh: {
        name: 'Bihari',
        nativeName: 'भोजपुरी'
      },
      bi: {
        name: 'Bislama',
        nativeName: 'Bislama'
      },
      bs: {
        name: 'Bosnian',
        nativeName: 'bosanski jezik'
      },
      br: {
        name: 'Breton',
        nativeName: 'brezhoneg'
      },
      bg: {
        name: 'Bulgarian',
        nativeName: 'български език'
      },
      my: {
        name: 'Burmese',
        nativeName: 'ဗမာစာ'
      },
      ca: {
        name: 'Catalan; Valencian',
        nativeName: 'Català'
      },
      ch: {
        name: 'Chamorro',
        nativeName: 'Chamoru'
      },
      ce: {
        name: 'Chechen',
        nativeName: 'нохчийн мотт'
      },
      ny: {
        name: 'Chichewa; Chewa; Nyanja',
        nativeName: 'chiCheŵa, chinyanja'
      },
      zh: {
        name: 'Chinese',
        nativeName: '中文 (Zhōngwén), 汉语, 漢語'
      },
      cv: {
        name: 'Chuvash',
        nativeName: 'чӑваш чӗлхи'
      },
      kw: {
        name: 'Cornish',
        nativeName: 'Kernewek'
      },
      co: {
        name: 'Corsican',
        nativeName: 'corsu, lingua corsa'
      },
      cr: {
        name: 'Cree',
        nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ'
      },
      hr: {
        name: 'Croatian',
        nativeName: 'hrvatski'
      },
      cs: {
        name: 'Czech',
        nativeName: 'česky, čeština'
      },
      da: {
        name: 'Danish',
        nativeName: 'dansk'
      },
      dv: {
        name: 'Divehi; Dhivehi; Maldivian;',
        nativeName: 'ދިވެހި'
      },
      nl: {
        name: 'Dutch',
        nativeName: 'Nederlands, Vlaams'
      },
      en: {
        name: 'English',
        nativeName: 'English'
      },
      eo: {
        name: 'Esperanto',
        nativeName: 'Esperanto'
      },
      et: {
        name: 'Estonian',
        nativeName: 'eesti, eesti keel'
      },
      ee: {
        name: 'Ewe',
        nativeName: 'Eʋegbe'
      },
      fo: {
        name: 'Faroese',
        nativeName: 'føroyskt'
      },
      fj: {
        name: 'Fijian',
        nativeName: 'vosa Vakaviti'
      },
      fi: {
        name: 'Finnish',
        nativeName: 'suomi, suomen kieli'
      },
      fr: {
        name: 'French',
        nativeName: 'français, langue française'
      },
      ff: {
        name: 'Fula; Fulah; Pulaar; Pular',
        nativeName: 'Fulfulde, Pulaar, Pular'
      },
      gl: {
        name: 'Galician',
        nativeName: 'Galego'
      },
      ka: {
        name: 'Georgian',
        nativeName: 'ქართული'
      },
      de: {
        name: 'German',
        nativeName: 'Deutsch'
      },
      el: {
        name: 'Greek, Modern',
        nativeName: 'Ελληνικά'
      },
      gn: {
        name: 'Guaraní',
        nativeName: 'Avañeẽ'
      },
      gu: {
        name: 'Gujarati',
        nativeName: 'ગુજરાતી'
      },
      ht: {
        name: 'Haitian; Haitian Creole',
        nativeName: 'Kreyòl ayisyen'
      },
      ha: {
        name: 'Hausa',
        nativeName: 'Hausa, هَوُسَ'
      },
      he: {
        name: 'Hebrew (modern)',
        nativeName: 'עברית'
      },
      hz: {
        name: 'Herero',
        nativeName: 'Otjiherero'
      },
      hi: {
        name: 'Hindi',
        nativeName: 'हिन्दी, हिंदी'
      },
      ho: {
        name: 'Hiri Motu',
        nativeName: 'Hiri Motu'
      },
      hu: {
        name: 'Hungarian',
        nativeName: 'Magyar'
      },
      ia: {
        name: 'Interlingua',
        nativeName: 'Interlingua'
      },
      id: {
        name: 'Indonesian',
        nativeName: 'Bahasa Indonesia'
      },
      ie: {
        name: 'Interlingue',
        nativeName: 'Originally called Occidental; then Interlingue after WWII'
      },
      ga: {
        name: 'Irish',
        nativeName: 'Gaeilge'
      },
      ig: {
        name: 'Igbo',
        nativeName: 'Asụsụ Igbo'
      },
      ik: {
        name: 'Inupiaq',
        nativeName: 'Iñupiaq, Iñupiatun'
      },
      io: {
        name: 'Ido',
        nativeName: 'Ido'
      },
      is: {
        name: 'Icelandic',
        nativeName: 'Íslenska'
      },
      it: {
        name: 'Italian',
        nativeName: 'Italiano'
      },
      iu: {
        name: 'Inuktitut',
        nativeName: 'ᐃᓄᒃᑎᑐᑦ'
      },
      ja: {
        name: 'Japanese',
        nativeName: '日本語 (にほんご／にっぽんご)'
      },
      jv: {
        name: 'Javanese',
        nativeName: 'basa Jawa'
      },
      kl: {
        name: 'Kalaallisut, Greenlandic',
        nativeName: 'kalaallisut, kalaallit oqaasii'
      },
      kn: {
        name: 'Kannada',
        nativeName: 'ಕನ್ನಡ'
      },
      kr: {
        name: 'Kanuri',
        nativeName: 'Kanuri'
      },
      ks: {
        name: 'Kashmiri',
        nativeName: 'कश्मीरी, كشميري‎'
      },
      kk: {
        name: 'Kazakh',
        nativeName: 'Қазақ тілі'
      },
      km: {
        name: 'Khmer',
        nativeName: 'ភាសាខ្មែរ'
      },
      ki: {
        name: 'Kikuyu, Gikuyu',
        nativeName: 'Gĩkũyũ'
      },
      rw: {
        name: 'Kinyarwanda',
        nativeName: 'Ikinyarwanda'
      },
      ky: {
        name: 'Kirghiz, Kyrgyz',
        nativeName: 'кыргыз тили'
      },
      kv: {
        name: 'Komi',
        nativeName: 'коми кыв'
      },
      kg: {
        name: 'Kongo',
        nativeName: 'KiKongo'
      },
      ko: {
        name: 'Korean',
        nativeName: '한국어 (韓國語), 조선말 (朝鮮語)'
      },
      ku: {
        name: 'Kurdish',
        nativeName: 'Kurdî, كوردی‎'
      },
      kj: {
        name: 'Kwanyama, Kuanyama',
        nativeName: 'Kuanyama'
      },
      la: {
        name: 'Latin',
        nativeName: 'latine, lingua latina'
      },
      lb: {
        name: 'Luxembourgish, Letzeburgesch',
        nativeName: 'Lëtzebuergesch'
      },
      lg: {
        name: 'Luganda',
        nativeName: 'Luganda'
      },
      li: {
        name: 'Limburgish, Limburgan, Limburger',
        nativeName: 'Limburgs'
      },
      ln: {
        name: 'Lingala',
        nativeName: 'Lingála'
      },
      lo: {
        name: 'Lao',
        nativeName: 'ພາສາລາວ'
      },
      lt: {
        name: 'Lithuanian',
        nativeName: 'lietuvių kalba'
      },
      lu: {
        name: 'Luba-Katanga',
        nativeName: ''
      },
      lv: {
        name: 'Latvian',
        nativeName: 'latviešu valoda'
      },
      gv: {
        name: 'Manx',
        nativeName: 'Gaelg, Gailck'
      },
      mk: {
        name: 'Macedonian',
        nativeName: 'македонски јазик'
      },
      mg: {
        name: 'Malagasy',
        nativeName: 'Malagasy fiteny'
      },
      ms: {
        name: 'Malay',
        nativeName: 'bahasa Melayu, بهاس ملايو‎'
      },
      ml: {
        name: 'Malayalam',
        nativeName: 'മലയാളം'
      },
      mt: {
        name: 'Maltese',
        nativeName: 'Malti'
      },
      mi: {
        name: 'Māori',
        nativeName: 'te reo Māori'
      },
      mr: {
        name: 'Marathi (Marāṭhī)',
        nativeName: 'मराठी'
      },
      mh: {
        name: 'Marshallese',
        nativeName: 'Kajin M̧ajeļ'
      },
      mn: {
        name: 'Mongolian',
        nativeName: 'монгол'
      },
      na: {
        name: 'Nauru',
        nativeName: 'Ekakairũ Naoero'
      },
      nv: {
        name: 'Navajo, Navaho',
        nativeName: 'Diné bizaad, Dinékʼehǰí'
      },
      nb: {
        name: 'Norwegian Bokmål',
        nativeName: 'Norsk bokmål'
      },
      nd: {
        name: 'North Ndebele',
        nativeName: 'isiNdebele'
      },
      ne: {
        name: 'Nepali',
        nativeName: 'नेपाली'
      },
      ng: {
        name: 'Ndonga',
        nativeName: 'Owambo'
      },
      nn: {
        name: 'Norwegian Nynorsk',
        nativeName: 'Norsk nynorsk'
      },
      no: {
        name: 'Norwegian',
        nativeName: 'Norsk'
      },
      ii: {
        name: 'Nuosu',
        nativeName: 'ꆈꌠ꒿ Nuosuhxop'
      },
      nr: {
        name: 'South Ndebele',
        nativeName: 'isiNdebele'
      },
      oc: {
        name: 'Occitan',
        nativeName: 'Occitan'
      },
      oj: {
        name: 'Ojibwe, Ojibwa',
        nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
      },
      cu: {
        name:
          'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        nativeName: 'ѩзыкъ словѣньскъ'
      },
      om: {
        name: 'Oromo',
        nativeName: 'Afaan Oromoo'
      },
      or: {
        name: 'Oriya',
        nativeName: 'ଓଡ଼ିଆ'
      },
      os: {
        name: 'Ossetian, Ossetic',
        nativeName: 'ирон æвзаг'
      },
      pa: {
        name: 'Panjabi, Punjabi',
        nativeName: 'ਪੰਜਾਬੀ, پنجابی‎'
      },
      pi: {
        name: 'Pāli',
        nativeName: 'पाऴि'
      },
      fa: {
        name: 'Persian',
        nativeName: 'فارسی'
      },
      pl: {
        name: 'Polish',
        nativeName: 'polski'
      },
      ps: {
        name: 'Pashto, Pushto',
        nativeName: 'پښتو'
      },
      pt: {
        name: 'Portuguese',
        nativeName: 'Português'
      },
      qu: {
        name: 'Quechua',
        nativeName: 'Runa Simi, Kichwa'
      },
      rm: {
        name: 'Romansh',
        nativeName: 'rumantsch grischun'
      },
      rn: {
        name: 'Kirundi',
        nativeName: 'kiRundi'
      },
      ro: {
        name: 'Romanian, Moldavian, Moldovan',
        nativeName: 'română'
      },
      ru: {
        name: 'Russian',
        nativeName: 'русский язык'
      },
      sa: {
        name: 'Sanskrit (Saṁskṛta)',
        nativeName: 'संस्कृतम्'
      },
      sc: {
        name: 'Sardinian',
        nativeName: 'sardu'
      },
      sd: {
        name: 'Sindhi',
        nativeName: 'सिन्धी, سنڌي، سندھی‎'
      },
      se: {
        name: 'Northern Sami',
        nativeName: 'Davvisámegiella'
      },
      sm: {
        name: 'Samoan',
        nativeName: 'gagana faa Samoa'
      },
      sg: {
        name: 'Sango',
        nativeName: 'yângâ tî sängö'
      },
      sr: {
        name: 'Serbian',
        nativeName: 'српски језик'
      },
      gd: {
        name: 'Scottish Gaelic; Gaelic',
        nativeName: 'Gàidhlig'
      },
      sn: {
        name: 'Shona',
        nativeName: 'chiShona'
      },
      si: {
        name: 'Sinhala, Sinhalese',
        nativeName: 'සිංහල'
      },
      sk: {
        name: 'Slovak',
        nativeName: 'slovenčina'
      },
      sl: {
        name: 'Slovene',
        nativeName: 'slovenščina'
      },
      so: {
        name: 'Somali',
        nativeName: 'Soomaaliga, af Soomaali'
      },
      st: {
        name: 'Southern Sotho',
        nativeName: 'Sesotho'
      },
      es: {
        name: 'Spanish; Castilian',
        nativeName: 'español, castellano'
      },
      su: {
        name: 'Sundanese',
        nativeName: 'Basa Sunda'
      },
      sw: {
        name: 'Swahili',
        nativeName: 'Kiswahili'
      },
      ss: {
        name: 'Swati',
        nativeName: 'SiSwati'
      },
      sv: {
        name: 'Swedish',
        nativeName: 'svenska'
      },
      ta: {
        name: 'Tamil',
        nativeName: 'தமிழ்'
      },
      te: {
        name: 'Telugu',
        nativeName: 'తెలుగు'
      },
      tg: {
        name: 'Tajik',
        nativeName: 'тоҷикӣ, toğikī, تاجیکی‎'
      },
      th: {
        name: 'Thai',
        nativeName: 'ไทย'
      },
      ti: {
        name: 'Tigrinya',
        nativeName: 'ትግርኛ'
      },
      bo: {
        name: 'Tibetan Standard, Tibetan, Central',
        nativeName: 'བོད་ཡིག'
      },
      tk: {
        name: 'Turkmen',
        nativeName: 'Türkmen, Түркмен'
      },
      tl: {
        name: 'Tagalog',
        nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
      },
      tn: {
        name: 'Tswana',
        nativeName: 'Setswana'
      },
      to: {
        name: 'Tonga (Tonga Islands)',
        nativeName: 'faka Tonga'
      },
      tr: {
        name: 'Turkish',
        nativeName: 'Türkçe'
      },
      ts: {
        name: 'Tsonga',
        nativeName: 'Xitsonga'
      },
      tt: {
        name: 'Tatar',
        nativeName: 'татарча, tatarça, تاتارچا‎'
      },
      tw: {
        name: 'Twi',
        nativeName: 'Twi'
      },
      ty: {
        name: 'Tahitian',
        nativeName: 'Reo Tahiti'
      },
      ug: {
        name: 'Uighur, Uyghur',
        nativeName: 'Uyƣurqə, ئۇيغۇرچە‎'
      },
      uk: {
        name: 'Ukrainian',
        nativeName: 'українська'
      },
      ur: {
        name: 'Urdu',
        nativeName: 'اردو'
      },
      uz: {
        name: 'Uzbek',
        nativeName: 'zbek, Ўзбек, أۇزبېك‎'
      },
      ve: {
        name: 'Venda',
        nativeName: 'Tshivenḓa'
      },
      vi: {
        name: 'Vietnamese',
        nativeName: 'Tiếng Việt'
      },
      vo: {
        name: 'Volapük',
        nativeName: 'Volapük'
      },
      wa: {
        name: 'Walloon',
        nativeName: 'Walon'
      },
      cy: {
        name: 'Welsh',
        nativeName: 'Cymraeg'
      },
      wo: {
        name: 'Wolof',
        nativeName: 'Wollof'
      },
      fy: {
        name: 'Western Frisian',
        nativeName: 'Frysk'
      },
      xh: {
        name: 'Xhosa',
        nativeName: 'isiXhosa'
      },
      yi: {
        name: 'Yiddish',
        nativeName: 'ייִדיש'
      },
      yo: {
        name: 'Yoruba',
        nativeName: 'Yorùbá'
      },
      za: {
        name: 'Zhuang, Chuang',
        nativeName: 'Saɯ cueŋƅ, Saw cuengh'
      },
      cx: {
        name: 'Bisaya',
        nativeName: 'Bisaya, Binisaya'
      },
      cb: {
        name: 'کوردیی ناوەندی',
        nativeName: 'کوردیی ناوەندی'
      },
      tz: {
        name: 'ⵜⴰⵎⴰⵣⵉⵖⵜ',
        nativeName: 'ⵜⴰⵎⴰⵣⵉⵖⵜ'
      },
      sz: {
        name: 'ślōnskŏ gŏdka',
        nativeName: 'ślōnskŏ gŏdka'
      }
    };

    let locales = {
      countrys: countrys,
      isoLangs: isoLangs
    };
    return locales;
  }
}
