<template>
  <div class="container-fluid p-0 page">
    <AppLeftMenu show-name="replacingMessages" />
    <main class="replacing-message-page">
      <AppCover />
      <div class="page-sub">
        <section>
          <div class="csh-container page-cover-container">
            <!-- Title -->
            <b-row class="page-header text-left">
              <b-col ld="12" xl="12">
                <h3>
                  {{ $t('src.components.AppLeftMenu.index.request_to_replacing_messages') }}
                </h3>
              </b-col>
            </b-row>

            <b-row>
              <b-col ld="12" xl="3" class="pd-r-5-p">
                <base-select
                  v-model="projectSelected"
                  :options="projectList"
                  :option-value="'value'"
                  :option-lable="'text'"
                  :name="'projectSel'"
                  :label="$t('src.modules.search-management.index.project')"
                  :required="true"
                  :value-type="'String'"
                  :label-width="160"
                />
                <!-- end -->
              </b-col>
              <b-col ld="12" xl="3" class="pd-r-5-p pd-l-unset">
                <!-- Channel Selection -->
                <base-select
                  v-model="channelSelected"
                  :options="channelList"
                  :option-value="'value'"
                  :option-lable="'text'"
                  :name="'projectSel'"
                  :label="$t('src.modules.search-management.index.channel')"
                  :placeholder="$t('src.modules.customer-support-history.index.select_channel')"
                  :value-type="'String'"
                  :label-width="160"
                />
                <!-- end -->
              </b-col>
              <b-col ld="12" xl="1" class="pd-r-5-p pd-l-unset">
                <!-- Button load data -->
                <label></label>
                <b-input-group>
                  <b-button
                    id="refresh-button"
                    variant="primary"
                    class="mb-5"
                    style="float: right; height: 39.5px"
                    @click="fetchData"
                  >
                    <i class="fas fa-sync" style="font-size: 20px"></i>
                  </b-button>
                  <b-tooltip
                    target="refresh-button"
                    triggers="hover"
                    :delay="100"
                    boundary="viewport"
                    placement="top"
                    >{{ $t('src.modules.replacing-message.index.refresh_data') }}</b-tooltip
                  >
                </b-input-group>
                <!-- end -->
              </b-col>
              <b-col ld="12" xl="2" />
              <b-col ld="12" xl="3">
                <!-- Search -->
                <label></label>
                <b-input-group>
                  <b-input-group-text slot="prepend" class="search-icon">
                    <i class="fas fa-search"></i>
                  </b-input-group-text>
                  <b-form-input
                    v-model="searchText"
                    :placeholder="$t('src.modules.replacing-message.index.search_by_title_keyword')"
                  />
                </b-input-group>
              </b-col>
            </b-row>

            <div class="table-content-wrap">
              <b-row>
                <!-- Main table -->
                <b-col ld="12" xl="12" style="padding-right: unset">
                  <b-table
                    :items="paginatedData"
                    :fields="requestList"
                    :current-page="pageNumber"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    class="nat-table m-b-30"
                    responsive
                    striped
                    hover
                    thead-class="nat-table-header"
                    show-empty
                  >
                    <!-- Show row empty data  -->
                    <template slot="empty">
                      <div v-if="isRefreshData" class="text-center my-2">
                        <span style="cursor: pointer" @click="refreshData()">
                          {{ $t('src.modules.replacing-message.index.refresh_data') }}
                        </span>
                      </div>
                      <div v-else class="text-center">
                        {{ $t('common.text.no_data') }}
                      </div>
                    </template>

                    <!-- No. -->
                    <template slot="index" slot-scope="data">
                      {{ data.index + 1 + perPage * (pageNumber - 1) }}
                    </template>

                    <!-- createdAt -->
                    <template slot="createdAt" slot-scope="data">
                      {{ formatTime(data.value) }}
                    </template>
                    
                    <!-- updatedAt -->
                    <template slot="HEAD_updatedAt" slot-scope="data">
                      <span v-b-tooltip.hover :title="$t('src.modules.replacing-mesage.index.updated-date.hint')">{{ data.label}}</span>
                    </template>
                    <template slot="updatedAt" slot-scope="data" style="width: 150px">
                      {{ formatTime(data.value) }}
                    </template>

                    <!-- title -->
                    <template slot="title" slot-scope="data">{{ data.value }}</template>

                    <!-- keyword -->
                    <template slot="keyword" slot-scope="data">
                      <el-tag
                        v-for="(tag, indexTag) in data.item.replacingKeywords.map(i => {
                          return { name: i, type: '' };
                        })"
                        :id="
                          'replacing-keyword-' +
                            (data.index + 1 + perPage * (pageNumber - 1)) +
                            indexTag
                        "
                        :key="
                          'replacing-keyword-' +
                            (data.index + 1 + perPage * (pageNumber - 1)) +
                            indexTag
                        "
                        :closable="false"
                        :type="tag.type"
                        :disable-transitions="true"
                      >
                        {{ data.item.status === requestStatus.APPROVED ? '* * *' : tag.name }}
                        <b-tooltip
                          triggers="hover"
                          :target="
                            'replacing-keyword-' +
                              (data.index + 1 + perPage * (pageNumber - 1)) +
                              indexTag
                          "
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                          >{{
                            data.item.status === requestStatus.APPROVED
                              ? $t(
                                  'src.modules.replacing-message.index.keyword_hided_contact_admin'
                                )
                              : tag.name
                          }}</b-tooltip
                        >
                      </el-tag>
                    </template>

                    <!-- status -->
                    <template slot="status" slot-scope="data">{{ data.value }}</template>

                    <!-- action -->
                    <template slot="action" slot-scope="data">
                      <!-- icon search -->
                      <a
                        v-if="requestStatus.NEW === data.item.status || requestStatus.APPROVED === data.item.status || requestStatus.REJECTED === data.item.status"
                        :id="'view-' + data.item.id"
                        href="javascript:void(0)"
                        @click="openViewForm(data.item)"
                      >
                        <i class="fa fa-search"></i>
                        <b-tooltip
                          :target="'view-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                        >
                          {{ $t('common.text.view_form') }}
                        </b-tooltip>
                      </a>

                      <!-- icon edit -->
                      <a
                        v-if="
                          requestStatus.DRAFT === data.item.status && data.item.createdBy === user.id
                        "
                        :id="'edit-' + data.item.id"
                        href="javascript:void(0)"
                        class="ml-2"
                        @click="openRequestForm(data.item)"
                      >
                        <i class="fa fa-edit"></i>
                        <b-tooltip
                          :target="'edit-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                        >
                          {{ $t('common.text.edit') }}
                        </b-tooltip>
                      </a>

                      <!-- icon trash -->
                      <a
                        v-if="
                          requestStatus.DRAFT === data.item.status && data.item.createdBy === user.id
                        "
                        :id="'delete-' + data.item.id"
                        href="javascript:void(0)"
                        class="ml-2"
                        @click="openDeleteModal(data.item)"
                      >
                        <i class="fa fa-trash-alt"></i>
                        <b-tooltip
                          :target="'delete-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                        >
                          {{ $t('common.text.delete') }}
                        </b-tooltip>
                      </a>
                    </template>
                  </b-table>
                  <!-- pagging -->
                  <b-row>
                    <b-col v-if="totalRows > 0" md="12" class="bt-paging">
                      <b-pagination
                        v-model="pageNumber"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        class="my-0 pull-right"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </main>
    <!-- end -->

    <RequestForm />
    <ViewForm :channel-id="channelSelected" />
  </div>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapActions, mapState } from 'vuex';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import AppLeftMenu from 'components/AppLeftMenu';
import { convertTimeUTC } from 'core/helpers';
import ViewForm from 'modules/replacing-message/components/ViewForm';
import RequestForm from 'modules/replacing-message/components/RequestForm';
import { compareName } from 'core/helpers';
import {
  REPLACING_MESSAGE_REQUEST_STATUS,
  CHANNEL_MODE
} from 'core/constants';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    RequestForm,
    ViewForm
  },

  data() {
    return {
      requestList: {
        index: {
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        createdAt: {
          label: this.$t('src.modules.replacing-message.index.requested-date'),
          sortable: true,
          class: 'nat-table-col-created'
        },
        updatedAt: {
          label: this.$t('src.modules.customer-support-history.index.updated'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        title: {
          label: this.$t('src.modules.replacing-message.components.RequestForm.index.title'),
          sortable: true,
          class: 'nat-table-col-title'
        },
        keyword: {
          label: this.$t('src.modules.replacing-message.components.RequestForm.index.keyword'),
          class: 'nat-table-col-keyword'
        },
        status: {
          label: this.$t('src.modules.chat.components.StateOverview.index.status'),
          sortable: true,
          class: 'nat-table-col-status'
        },
        createdByAgent: {
          label: this.$t('src.modules.replacing-message.index.requested-agent'),
          sortable: true,
          class: 'nat-table-col-request-by'
        },
        updatedByAgent: {
          label: this.$t('src.modules.replacing-message.index.approved-rejected-agent'),
          sortable: true,
          class: 'nat-table-col-request-by'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action'
        }
      },
      isBusy: false,
      isRefreshData: false,
      sortBy: 'updatedAt',
      sortDesc: true,
      searchText: '',
      pageNumber: 1,
      perPage: 10,
      projectSelected: '',
      channelSelected: '',
      channelList: [],
      requestSelected: {},
      requestStatus: REPLACING_MESSAGE_REQUEST_STATUS || {}
    };
  },

  computed: {
    ...mapState('replacingMessage', ['requests']),
    ...mapState('session', ['user', 'projectMaps', 'channelsMap']),

    isMaintain() {
      const channelAffected = Object.keys(this.channelsMap).filter(
        i => this.channelsMap[i].projectId === this.projectSelected
      );

      let isValid = false;
      if (!channelAffected || channelAffected.length === 0) return isValid;
      for (let i = 0; i < channelAffected.length; i++) {
        const { mode = '' } = this.channelsMap[channelAffected[i]];
        if (mode === CHANNEL_MODE.MAINTAIN) isValid = true;
      }

      return isValid;
    },

    projectList() {
      if (!isEmpty(this.projectMaps)) {
        let projectsOption = [];
        for (let key in this.projectMaps) {
          projectsOption.push({
            value: key,
            text: this.projectMaps[key].name
          });
        }

        return projectsOption.sort((a, b) => compareName(a, b, 'text'));
      }
      return [];
    },

    totalRows() {
      return this.paginatedData.length || 0;
    },

    paginatedData() {
      let customTextSearch = this.searchText.trim();
      if (!this.requests.length || !customTextSearch) return this.requests;
      customTextSearch = customTextSearch.toLowerCase();
      return this.requests.filter(i => {
        let words = i.title ? [i.title.toLowerCase()] : [];

        // if status is APPROVED , we will not check keyword
        if (i.status !== this.requestStatus.APPROVED) {
          words = words.concat(i.replacingKeywords.map(i => i.toLowerCase()));
        }

        return words.some(w => w.indexOf(customTextSearch) >= 0);
      });
    }
  },

  watch: {
    projectSelected(newValue) {
      if (!isEmpty(this.channelsMap) && newValue) {
        let channelsOption = [];
        for (let key in this.channelsMap) {
          const { projectId = '', platform = '', configs = {} } = this.channelsMap[key];
          if (platform === 'instagram' && !configs.instagramMessenger) continue;
          if (projectId === newValue ) {
            channelsOption.push({
              value: key,
              text: this.channelsMap[key].name
            });
          }
        }

        this.channelSelected = '';
        this.channelList = [...channelsOption.sort((a, b) => compareName(a, b, 'text'))];
        if (!this.totalRows) this.isRefreshData = true;
        this.fetchData();
      }
    },

    channelSelected() {
      if (!this.totalRows) this.isRefreshData = true;
      this.fetchData();
    },

    async projectList(newValue) {
      this.projectSelected = newValue.length > 0 ? newValue[0].value : '';
      if (this.projectSelected) await this.fetchData();
    }
  },

  created() {
    this.setRequests([]);
  },

  async mounted() {
    this.projectSelected = this.projectList.length > 0 ? this.projectList[0].value : '';
    if (this.projectSelected) await this.fetchData(false);
  },

  methods: {
    ...mapActions('replacingMessage', [
      'getAllRequest',
      'setRequests',
      'deleteRequestReplaceMessage'
    ]),
    ...mapActions('global', ['setGlobalReady']),

    async refreshData() {
      await this.fetchData();
    },

    async fetchData(loading = true) {
      loading && this.setGlobalReady(false);
      await this.getAllRequest({
        agentId: this.user.id,
        projectId: this.projectSelected,
        channelId: this.channelSelected
      });
      this.isRefreshData = false;
      this.setGlobalReady(true);
    },

    openViewForm(item) {
      this.isMaintain
        ? EventBus.$emit('maintenanceModeModal')
        : EventBus.$emit('replacingMessageViewForm', { isNew: false, curItem: item });
    },

    openRequestForm(item) {
      this.isMaintain
        ? EventBus.$emit('maintenanceModeModal')
        : EventBus.$emit('replacingMessageRequestForm', { isNew: false, curItem: item });
    },

    openDeleteModal(item) {
      this.requestSelected = item;
      this.isMaintain ? EventBus.$emit('maintenanceModeModal') : this.handleActionDelete();
    },

    handleActionDelete() {
      return this.$baseConfirm({
        message: this.$t('src.modules.replacing-message.index.delete_this.request')
      })
        .then(() => {
          this.handleDeleteRequest();
        })
        .catch(() => {});
    },

    async handleDeleteRequest() {
      try {
        const { id = '', createdAt = '' } = this.requestSelected;
        if (id && createdAt) {
          this.setGlobalReady(false);
          await this.deleteRequestReplaceMessage({ id, createdAt });
        }

        this.setGlobalReady(true);
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.replacing-message.components.RequestForm.index.delete_successful'
          )
        });
      } catch (error) {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: error.message
        });
      }
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-content-wrap {
  z-index: 1;
  height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: auto;

  .row {
    margin-right: unset !important;
  }
}

.input-group-text {
  font-size: 0.875rem !important;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 0.875rem !important;
  line-height: inherit;
}

.replacing-message-page {
  overflow-y: auto !important;
}
.replacing-message-page .footer {
}

.page-header {
  text-align: center;
}

.page-cover-container {
  margin: 0 50px 50px 50px;
  /deep/ label {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.search-icon {
  width: 30px !important;
  min-width: 30px !important;
}

.table-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bt-paging {
  margin-bottom: 40px;
}

.navigation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

body {
  overflow-y: auto !important;
  font-size: 12px !important;
}

tbody td {
  width: 12.5%;
  float: left;
  overflow-wrap: break-word;
}
tbody {
  overflow-wrap: auto;
}

thead th {
  width: 12.5%;
  float: left;
  height: 70px;
}

/deep/.row {
  padding-bottom: 15px;
}

@media only screen and (min-width: 1200px) {
  /* For mobile phones: */
  .pd-r-5-p {
    padding-right: 5px;
  }

  .pd-l-unset {
    padding-left: unset;
  }
}

@media only screen and (max-width: 1200px) {
  .table-content-wrap {
    z-index: 1;
    height: calc(100vh - 355px);
    overflow-y: auto;
    overflow-x: auto;

    .row {
      margin-right: unset !important;
    }
  }
}

/deep/.el-tag--light {
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/deep/.el-tag {
  height: 22px;
  line-height: 20px;
}

/deep/.replacing-message-page .input-group-text {
  min-width: 100px;
}
/deep/.replacing-message-page .form-control .custom-select {
  padding-left: 10px !important;
}
/deep/.replacing-message-page .input-group .form-control:not(:first-child) {
  padding-left: 10px !important;
}

.nat-table {
  tbody {
    display: block !important;
    overflow: auto !important;
    background: white;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* even columns width , fix width of table too*/
  }
}
.nat-customer-css {
  legend {
    font-weight: 800;
  }
}
/deep/.nat-table-header {
  background-color: #2196f3;
  color: #ffffff;
  text-align: center !important;
  /deep/.nat-table-col-no {
    text-align: center !important;
  }
  /deep/.nat-table-col-action {
    text-align: center !important;
  }
  /deep/.nat-table-col-title {
    text-align: center !important;
  }
  /deep/.nat-table-col-keyword {
    text-align: center !important;
  }
  /deep/.nat-table-col-request-by {
    text-align: center !important;
  }
  
}
/deep/.nat-table-col-no {
  text-align: center !important;
  width: 20px;
}
/deep/.nat-table-col-created {
  text-align: center;
  width: 120px;
}
/deep/.nat-table-col-updated {
  text-align: center;
  width: 120px;
}
/deep/.nat-table-col-title {
  width: 120px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.nat-table-col-keyword {
  width: 100px;
  text-align: left;
}
/deep/.nat-table-col-status {
  width: 110px;
  text-align: center !important;
}
/deep/.nat-table-col-request-by {
  width: 120px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
}
/deep/.nat-table-col-action {
  width: 60px;
  text-align: center !important;
}
</style>
