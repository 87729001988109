<template>
  <ul :class="containerClass" v-show="isShowPage">
    <li data-testid="page-prev" :class="[prevClass, prevPageSelectedBlock() ? disabledClass : '']">
      <el-tooltip
        :content="$t('src.components.BaseTicketPaginate.prevBlock')"
        class="item"
        effect="dark"
        placement="top"
      >
        <a
          @click="prevBlockPage()"
          @keyup.enter="prevBlockPage()"
          :class="prevLinkClass"
          :tabindex="prevPageSelectedBlock() ? -1 : 0"
          v-html="prevBlockText"
        ></a>
      </el-tooltip>
    </li>
    <li
      data-testid="page-active"
      :key="idx"
      v-for="(page, idx) in pages"
      :class="[
        pageClass,
        page.selected ? activeClass : '',
        page.disabled ? disabledClass : '',
        page.breakView ? breakViewClass : ''
      ]"
    >
      <a
        v-show="checkShowPage(page.content)"
        v-if="page.disabled"
        :class="pageLinkClass"
        tabindex="0"
        >{{ page.content }}</a
      >
      <a
        v-show="checkShowPage(page.content)"
        v-else
        @click="handlePageSelected(page.index + 1)"
        @keyup.enter="handlePageSelected(page.index + 1)"
        :class="pageLinkClass"
        tabindex="0"
        >{{ page.content }}</a
      >
    </li>
    <li data-testid="page-next" :class="[nextClass, nextPageSelectedBlock() ? disabledClass : '']">
      <el-tooltip
        :content="$t('src.components.BaseTicketPaginate.nextBlock')"
        class="item"
        effect="dark"
        placement="top"
      >
        <a
          @click="nextBlockPage()"
          @keyup.enter="nextBlockPage()"
          :class="nextLinkClass"
          :tabindex="nextPageSelectedBlock() ? -1 : 0"
          v-html="nextBlockText"
        ></a>
      </el-tooltip>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: undefined
    },
    pageCount: {
      type: Number,
      required: true,
      default: undefined
    },
    forcePage: {
      type: Number,
      default: undefined
    },
    clickHandler: {
      type: Function,
      default: () => {}
    },
    clickNextBlockHandler: {
      type: Function,
      default: () => {}
    },
    clickPrevBlockHandler: {
      type: Function,
      default: () => {}
    },
    pageRange: {
      type: Number,
      default: 5
    },
    marginPages: {
      type: Number,
      default: 1
    },
    prevText: {
      type: String,
      default: 'Prev'
    },
    nextText: {
      type: String,
      default: 'Next'
    },
    prevBlockText: {
      type: String,
      default: '<'
    },
    nextBlockText: {
      type: String,
      default: '>'
    },
    breakViewText: {
      type: String,
      default: '…'
    },
    containerClass: {
      type: String,
      default: ''
    },
    pageClass: {
      type: String,
      default: ''
    },
    pageLinkClass: {
      type: String,
      default: ''
    },
    prevClass: {
      type: String,
      default: ''
    },
    prevLinkClass: {
      type: String,
      default: ''
    },
    nextClass: {
      type: String,
      default: ''
    },
    nextLinkClass: {
      type: String,
      default: ''
    },
    breakViewClass: {
      type: String,
      default: ''
    },
    breakViewLinkClass: {
      type: String,
      default: ''
    },
    activeClass: {
      type: String,
      default: 'active'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    },
    firstLastButton: {
      type: Boolean,
      default: false
    },
    firstButtonText: {
      type: String,
      default: 'First'
    },
    lastButtonText: {
      type: String,
      default: 'Last'
    },
    hidePrevNext: {
      type: Boolean,
      default: false
    },
    hidePrevNextBlock: {
      type: Boolean,
      default: false
    },
    dataCnt: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      innerValue: 1
    };
  },
  computed: {
    selected: {
      get: function () {
        return this.value || this.innerValue;
      },
      set: function (newValue) {
        this.innerValue = newValue;
      }
    },
    pages: function () {
      let items = {};
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1
          };
          items[index] = page;
        }
      } else {
        let setPageItem = (index) => {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1
          };
          items[index] = page;
        };

        let selectedRangeLow = Math.floor(this.selected / 5) * 5;
        if (this.selected % 5 == 0) {
          selectedRangeLow = (Math.floor(this.selected / 5) - 1) * 5;
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;

        for (
          let i = selectedRangeLow;
          i <= selectedRangeHigh && i <= this.pageCount - 1;
          i++
        ) {
          setPageItem(i);
        }
      }
      return items;
    },
    isShowPage() {
      return this.dataCnt > 0;
    }
  },
  watch: {},

  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  methods: {
    handlePageSelected(selected) {
      if (selected < 0 || selected > this.pageCount || this.selected === selected) return;
      this.innerValue = selected;
      this.$emit('input', selected);
      this.clickHandler(selected);
    },
    prevPage() {
      if (this.selected <= 1) return;
      this.handlePageSelected(this.selected - 1);
    },
    nextPage() {
      if (this.selected >= this.pageCount) return;
      this.handlePageSelected(this.selected + 1);
    },
    prevBlockPage() {
      if (this.selected <= 1) return;
      const selected = (Math.floor((this.selected - 1) / 5) - 1) * 5 + 1;
      if (selected < 0 || selected > this.pageCount || this.selected === selected) return;
      this.innerValue = selected;
      this.$emit('input', selected);
      this.clickPrevBlockHandler(selected);
    },
    nextBlockPage() {
      if ((Math.floor((this.selected - 1) / 5) + 1) * 5 === this.pageCount) return;
      const selected = (Math.floor((this.selected - 1) / 5) + 1) * 5 + 1;
      if (selected < 0 || selected > this.pageCount || this.selected === selected) return;
      this.innerValue = selected;
      this.$emit('input', selected);
      this.clickNextBlockHandler(selected);
    },
    firstPageSelected() {
      return this.selected === 1;
    },
    lastPageSelected() {
      return this.selected === this.pageCount || this.pageCount === 0;
    },

    prevPageSelectedBlock() {
      return this.selected <= this.pageRange;
    },

    nextPageSelectedBlock() {
      return (
        (Math.floor((this.selected - 1) / 5) + 1) * 5 >= this.pageCount ||
        this.pageCount === 0
      );
    },
    selectFirstPage() {
      if (this.selected <= 1) return;
      this.handlePageSelected(1);
    },
    selectLastPage() {
      if (this.selected >= this.pageCount) return;
      this.handlePageSelected(this.pageCount);
    },
    checkShowPage(page) {
      let pageByCnt = 0;
      let pageChunk = 0;
      if (this.dataCnt % 10 != 0) {
        pageByCnt = Math.floor(this.dataCnt / 10) + 1;
      } else {
        pageByCnt = Math.floor(this.dataCnt / 10);
      }

      if (page % 5 == 0) {
        pageChunk = 5;
      } else {
        pageChunk = page % 5;
      }
      return pageChunk <= pageByCnt;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 3;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #ffffff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #ffffff;
  border-color: #dddddd;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
