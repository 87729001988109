<template>
  <div v-if="!done">
    <p v-if="!error && fileUrl === ''">{{ name }}</p>
    <a v-if="!error && fileUrl !== ''" :href="fileUrl">
      <i class="fas fa-cloud-download-alt mr-10" />
      {{ name }}
    </a>
    <b-alert v-if="error" show variant="warning">
      <p>{{ name }}</p>
      {{
        $t(
          'src.modules.chat.components.ChatBox.file-upload.please_try_again_make_sure_you_are_uploading_a_valid_file'
        )
      }}
      <b-badge @click="done = true">X</b-badge>
    </b-alert>
    <progress-bar
      v-if="!error"
      :val="value"
      :text="text === '' ? value + '%' : text"
      size="tiny"
    ></progress-bar>
  </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress';
import fireStorage from 'core/firebase/storage';
import { getVideoImage } from 'core/helpers';
import { IMAGE_EXTENSIONS_SUPPORTED, FILE_EXTENSIONS_SUPPORTED_ZALO } from 'core/constants';
import axios from 'axios';

export default {
  components: {
    ProgressBar
  },

  props: {
    file: {
      type: File,
      default: () => null
    },

    path: {
      type: String,
      default: ''
    },

    platform: {
      type: String,
      default: 'facebook'
    },

    group: {
      type: String,
      default: ''
    },
    
    channel: {
      type: String,
	    default: ''
    }
  },

  data() {
    return {
      done: false,
      value: 0,
      text: '',
      sending: false,
      error: false,
      fileUrl: '',
      payload: null
    };
  },

  computed: {
    name() {
      let _name = (this.file && this.file.name) || '';
      if (this.sending && !this.error)
        _name = this.$t('src.modules.chat.components.ChatBox.file-upload.sending');
      return _name;
    },
    type() {
      let type = 'file';
      let patternType = new RegExp('^audio/[\\s\\S]+$');
      if (this.file) {
        if (this.file.type.startsWith('image')) {
          const extensionsSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
            this.file.name.toLowerCase().endsWith(ex)
          );
          if (extensionsSupported) type = 'image';
        } else if (
          this.file.type === 'audio/mp3' ||
          this.file.type === 'audio/mpeg' ||
          this.file.type === 'audio/ogg' ||
          this.file.type === 'audio/wav' ||
          this.file.type === 'audio/flac' ||
          patternType.test(this.file.type)) {
          type = 'audio';
        } else if (
          this.file.type === 'video/mp4' ||
          this.file.type === 'video/ogg' ||
          this.file.type === 'video/webm'
        ) {
          type = 'video';
        }
      }
      return type;
    }
  },

  async created() {
    const _self = this;
    const { type, name, group } = this;
    if (!this.file) return;
    // eslint-disable-next-line
    if(_self.platform === 'zalo' && type !== 'image') {
      if(FILE_EXTENSIONS_SUPPORTED_ZALO.indexOf(_self.file.type) < 0) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message:
            this.$t('File extension not supported!') +
            '\r\n' +
            this.$t(
              'Only support PDF/DOC/DOCX with max size not over 5MB'
            )
        });
        _self.done = true;
        return;
      } else if(_self.file.size > 5242880) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message:
            this.$t('File extension not supported!') +
            '\r\n' +
            this.$t(
              'Only support PDF/DOC/DOCX with max size not over 5MB'
            )
        });
        _self.done = true;
        return;
      }
    }
    if (this.file.size > 26214400) {
      this.$baseNotification.error({
        title: this.$t('src.core.App.error'),
        message:
          this.$t('src.modules.chat.components.ChatBox.file-upload.upload_failed') +
          '\r\n' +
          this.$t(
            'src.modules.chat.components.ChatBox.file-upload.the_file_you_have_selected_is_too_large_the_maximum_size_is_25mb'
          )
      });
      _self.done = true;
      return;
    }

    const uploadTask = fireStorage.put(this.file, `/${this.path}/${this.name}`);

    if (this.platform === 'line' && type === 'video') {
      await getVideoImage(this.file).then(({ url, error }) => {
        if (error == null) {
          this.payload = { thumbnail: url };
        } else {
          this.payload = null;
          this.error = true;
        }
      });
    }
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        _self.value = progress;
        switch (snapshot.state) {
          case 'paused':
            _self.text = this.$t(
              'src.modules.chat.components.ChatBox.file-upload.upload_is_paused'
            );
            break;
          case 'running':
            _self.text = '';
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message:
            this.$t('src.modules.chat.components.ChatBox.file-upload.upload_failed') +
            '\r\n' +
            error.message
        });
        _self.done = true;
        // eslint-disable-next-line
        console.log('Upload error -> file: ', _self.file);
        // eslint-disable-next-line
        console.log('Upload error -> error: ', error);
      },
      function() {
        _self.value = 100;
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          if (_self.platform === 'line' && type === 'audio') {
            _self.sending = true;
            let audio = new Audio();
            _self.fileUrl = downloadURL;
            audio.src = downloadURL;
            audio.ondurationchange = () => {
              _self.$emit('onSuccess', {
                downloadURL,
                type,
                size: _self.file.size,
                name,
                payload: {
                  duration: audio.duration
                },
                group
              });
              _self.done = true;
            };
            audio.onerror = () => {
              _self.$emit('onSuccess', {
                downloadURL,
                type,
                size: _self.file.size,
                name,
                group
              });
              _self.done = true;
            };
          } else if (_self.platform === 'line' && type === 'video') {
            _self.fileUrl = downloadURL;
            _self.sendVideo();
          } else if(_self.platform === 'zalo' && type !== 'image'){
            const res = await _self.getTokenUploadFile(_self.channel, _self.file);
            const emit = {
              downloadURL,
              type,
              size: _self.file.size,
              name,
              group
            };
            if(res && res.data && res.data.data) {
              emit.payloadToken = res.data.data.token;
            }
            _self.$emit('onSuccess', emit);
            _self.done = true;
          } else {
            _self.$emit('onSuccess', {
              downloadURL,
              type,
              size: _self.file.size,
              name,
              group
            });
            _self.done = true;
          }
        });
      }
    );
  },

  methods: {
    getTokenUploadFile(accessToken, file) {
      const formData = new FormData();
      formData.append('file', file);
	    return axios({
	      method: 'post',
	      url: `https://openapi.zalo.me/v2.0/oa/upload/file?access_token=${accessToken}`,
	      headers: {'Content-type': 'multipart/form-data'},
	      data:formData,
	      responseType: 'json'
	    }).then(res => {
	      return res;
	    });
    },
    sendFile() {
      this.$emit('onSuccess', {
        downloadURL: this.fileUrl,
        type: 'file',
        size: this.file.size,
        name: this.file.name,
        group: this.group
      });
      this.done = true;
    },
    sendVideo() {
      if (this.fileUrl !== '' && this.payload) {
        this.$emit('onSuccess', {
          downloadURL: this.fileUrl,
          type: 'video',
          size: this.file.size,
          name: this.name,
          payload: this.payload,
          group: this.group
        });
        this.done = true;
      }
    }
  }
};
</script>

<style scoped>
.badge {
  cursor: pointer;
}
</style>
