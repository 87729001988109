<template>
  <b-card header-tag="header">
    <h6 slot="header" v-b-toggle.clRequestInformation class="mb-0">
      <i :class="visible ? `fa fa-chevron-down` : `fa fa-chevron-right`"> </i>
      {{ $t('src.modules.chat.components.CustomerSupport.RequestInformation.request_information') }}
    </h6>
    <b-collapse id="clRequestInformation" v-model="visible">
      <b-breadcrumb v-if="categoryPath && categoryPath.length > 0" :items="categoryPath" />
      <b-row>
        <b-col v-if="!item" cols="md-4 col">
          <label class="d-block mb-1">
            {{ labelCategory }}
            <strong style="color: red">*</strong>
          </label>
          <tree ref="tree" v-model="selectedNode" :options="categoryTreeOptions">
            <span slot-scope="{ node }" class="tree-text">
              <template v-if="!node.hasChildren() && node.text !== '...'">
                <i class="ion-md-document"></i>
                {{ node.text }}
              </template>

              <template v-if="!node.hasChildren() && node.text === '...'">
                <i class="ion-md-close-circle"></i>
                {{ node.text }}
              </template>

              <template v-if="node.hasChildren()">
                <i :class="[node.expanded() ? 'ion-md-folder-open' : 'ion-md-folder']"></i>
                {{ node.text }}
              </template>
            </span>
          </tree>
        </b-col>
        <b-col :cols="item ? `md-12 col` : `md-8 col`">
          <ValidationObserver ref="CSRequestInformation">
            <form role="form" autocomplete="off">
              <div v-for="(caf, idx) in customerAllFields" :key="`caf_${idx}`" class="col-12">
                <div v-for="(c, idx) in caf" :key="c.key" class="row">
                  <p v-if="c.type === 'label'" class="font-weight-bold">
                    {{ c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label }}
                  </p>
                  <div v-if="c.type !== 'label'" class="col-12">
                    <div class="form-group">
                      <div v-if="c.type === 'text'">
                        <base-input
                          :prefix="'rqtInfo'"
                          v-if="c.type === 'text' && c.key"
                          v-model="formData[c.key]"
                          :required="c.required"
                          :rules="c.rules"
                          :name="c.key"
                          :id="c.key"
                          :placeholder="c.placeholder"
                          :label="
                            c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                          "
                          :maxlength="c.maxlength"
                          :disabled="disabledForm"
                          :autocomplete="'off'"
                        />
                        <base-input
                          :prefix="'rqtInfo'"
                          v-if="c.type === 'text' && !c.key"
                          v-model="formData[idx]"
                          :required="c.required"
                          :rules="c.rules"
                          :name="idx"
                          :id="idx"
                          :placeholder="c.placeholder"
                          :label="
                            c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                          "
                          :maxlength="c.maxlength"
                          :disabled="disabledForm"
                          :autocomplete="'off'"
                        />
                      </div>
                      <div v-if="c.type === 'textarea'">
                        <base-textarea
                          v-if="c.type === 'textarea'"
                          v-model="formData[c.key]"
                          :required="c.required"
                          :name="c.key"
                          :id="c.key"
                          :rules="c.rules"
                          :placeholder="c.placeholder"
                          :label="
                            c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                          "
                          :maxlength="c.maxlength"
                          :disabled="disabledForm"
                        />
                      </div>
                      <div v-if="['datepicker', 'datetimepicker'].includes(c.type)">
                        <base-date-picker
                          v-if="['datepicker', 'datetimepicker'].includes(c.type)"
                          v-model="formData[c.key]"
                          :required="c.required"
                          :rules="c.rules"
                          :name="c.key"
                          :id="c.key"
                          :locale="getLocale"
                          :label="
                            c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                          "
                          :disabled="disabledForm"
                        />
                      </div>
                      <div v-if="c.type === 'radio'">
                        <base-radio-group
                          :ref="c.prop === 'sex' ? c.key : ''"
                          v-if="c.type === 'radio'"
                          :label="c.label[displayLanguage] || c.label"
                          v-model="formData[c.key]"
                          :name="c.prop === 'sex' ? c.prop : c.key"
                          :options="
                            optionsDataRadio(
                              c.options && c.options[displayLanguage]
                                ? c.options[displayLanguage]
                                : c.options
                            )
                          "
                          :option-lable="'value'"
                          :option-value="'key'"
                          :disabled="disabledForm"
                          :max-other-value="100"
                          :allow-show-word-limit="true"
                          :err-mess-other-value="
                            c.prop === 'sex'
                              ? $t(
                                  'src.modules.profile.components.user-information.please_enter_your_gender'
                                )
                              : ''
                          "
                          :required="c.required"
                          :radio-other-value-error="radioOtherValueError"
                          :full-width-radio="c.prop === 'sex' ? true : false"
                          @inputOtherValue="inputOtherValue"
                        />
                      </div>

                      <base-checkbox
                        :required="c.required"
                        v-if="c.type === 'checkbox'"
                        :name="c.key"
                        :prefix="'rqtInfo'"
                        v-model="formData[c.key]"
                        :disabled="disabledForm"
                        :label="
                          c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                        "
                      />

                      <base-select
                        :prefix="'rqtInfo'"
                        v-if="c.type === 'combobox'"
                        v-model="formData[c.key]"
                        :options="optionsData(c)"
                        :name="c.key"
                        :combo-name="c.prop"
                        :id="c.key"
                        :value-type="'String'"
                        :option-type="'Object'"
                        :option-lable="'value'"
                        :option-value="'key'"
                        :label="
                          c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                        "
                        :required="c.required"
                        :disabled="disabledForm"
                        @change="changeCombo(c, $event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import clone from 'clone';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import store from 'core/store';
import { STATUS_SUBMIT_CSF } from 'core/constants';
import { setTimeout } from 'timers';
import 'components/BaseComponents/vee-validate.js';

export default {
  props: {
    information: { type: Object, default: () => {} },
    conversation: { type: Object, default: () => {} },
    item: { type: Object, default: () => {} },
    projectId: { type: String, default: '' }
  },
  data() {
    return {
      initCategoryPath: '',
      initFormData: {},
      categoryPath: [],
      displayMessage: '',
      itemData: {},
      isView: true,
      visible: true,
      isUpdate: false,
      selectedNode: null,
      components: {},
      formData: {},
      formatDate: 'MM/DD/YYYY',
      lstCountries: [],
      lstProvinces: [],
      categoryTreeOptions: {
        store: {
          store: this.$store,
          getter: () => {
            return store.getters['customerSupport/tree'];
          },
          dispatcher(tree) {
            store.dispatch('customerSupport/updateTree', tree);
          }
        }
      },
      templateId: '',
      validPhoneNumber: true,
      invalidPhoneErrorMsg: this.$t(
        'src.modules.profile.components.user-information.please_input_the_contact_number'
      ),
      countryKey: '',
      statePrefectureKey: '',
      editSubmittedForm: false,
      radioOtherValueError: false
    };
  },
  computed: {
    ...mapState('customerSupport', [
      'customerFields',
      'categories',
      'projects',
      'fixedFields',
      'template',
      'categoryLabel',
      'openCSFMode'
    ]),
    ...mapState('session', ['channelsMap', 'user', 'tickets']),
    ...mapState('personal', ['supportLanguages', 'defaultLanguage']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('customerSupport', ['tree']),
    ...mapGetters('session', ['conversations']),
    labelCategory() {
      return this.categoryLabel && this.categoryLabel[this.displayLanguage]
        ? this.categoryLabel[this.displayLanguage]
        : this.categoryLabel;
    },
    disabledForm() {
      if (this.openCSFMode === 'view' && !this.editSubmittedForm) return true;
      return false;
    },
    defaultLanguageChannel() {
      const { channelId = '' } = this.conversation;
      const { defaultLanguage = 'en_US' } = this.channelsMap[channelId];
      return defaultLanguage;
    },
    displayLanguage() {
      const { channelId = '' } = this.conversation;
      const channel = this.channelsMap[channelId] || {};
      const { advancedConfigs: { supportLanguages = [] } = {}, defaultLanguage = 'en_US' } = channel;
      const lang = localStorage.getItem('language');
      const operationLanguage = lang.replace('-', '_');
      return supportLanguages.includes(operationLanguage) ? operationLanguage : defaultLanguage;
    },
    customerAllFields() {
      let sortOrderFixedFields = null;
      if (isEmpty(this.fixedFields)) sortOrderFixedFields = {};
      sortOrderFixedFields = Object.keys(this.fixedFields)
        .sort((a, b) => {
          return this.fixedFields[a]['order'] - this.fixedFields[b]['order'];
        })
        .reduce((res, i) => {
          res = {
            ...res,
            [this.fixedFields[i]['order']]: { ...this.fixedFields[i], prop: i }
          };
          return res;
        }, {});
      return {
        ...[
          this.m_getFiledInUsed(sortOrderFixedFields, true),
          this.m_getFiledInUsed(this.customerFields, false)
        ]
      };
    },
    getLocale() {
      return this.displayLanguage.split('_')[0] || 'en';
    }
  },
  watch: {
    async information(newValue, oldValue) {
      //start setCFSMode
      this.setCSFMode('edit');
      if (isEqual(newValue, oldValue)) return null;
      const { status = '', isAllowEdit } = newValue || {};
      if (
        status === 'submited' ||
        (status === 'draft' && (newValue || {}).hasOwnProperty('isAllowEdit') && !isAllowEdit)
      ) {
        this.setCSFMode('view');
      }
      //end setCFSMode

      const value = { ...newValue };
      if (value.id) {
        const node = this.$refs.tree.find({ data: { id: value.categoryId } });
        if (node) node.select();
        const params = { id: value.id };
        const editData = await this.getCustomerSupportById(params);
        await this.getTemplateContent(editData);
        await this.updateTree([...this.categories]);
        setTimeout(() => this.mapEditData(editData), 200);
        this.isUpdate = true;
        if (!this.visible) this.visible = true;
      } else {
        const { projectId, groupId } = this.conversation.ticket;
        await this.getCustomerSupportSettingsByKey({ projectId, groupId });
        await this.updateTree([...this.categories]);
        this.categoryPath = 0;
        this.formData = {};
        if (this.$refs && this.$refs.tree) this.$refs.tree.selected().unselect();
        this.isUpdate = false;
      }
    },
    selectedNode() {
      if (this.selectedNode[0]) {
        const fullPath = [this.selectedNode[0].text];
        this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
        this.categoryPath = fullPath.map(category => {
          return {
            text: category,
            active: true
          };
        });
        if (fullPath.join(' ▶ ') !== this.initCategoryPath && !this.isView) {
          this.$emit('checkEditData', true);
        }
      }
    },
    formData: {
      handler(newValue) {
        if (!isEqual(this.initFormData, newValue) && !this.isView) {
          this.$emit('checkEditData', true);
        } else {
          this.$emit('checkEditData', false);
        }
      },
      deep: true
    },
    isView(value) {
      this.$emit('checkHandleSubmitForm', {
        isView: value
      });
      this.$emit('checkHandleSubmitToClose', {
        isView: value
      });
    }
  },
  async created() {
    this.categoryPath = [];
    this.selectedNode = null;
    const { templateId } = this.information;
    this.templateId = templateId;

    this.initFormData = clone(this.initForm || {});
    if (!this.item) {
      if (this.information.id) {
        this.visible = true;
        const params = { id: this.information.id };
        const editData = await this.getCustomerSupportById(params);
        await this.getTemplateContent(editData);
        await this.updateTree([...this.categories]);
        setTimeout(() => this.mapEditData(editData), 100);
        this.isUpdate = true;
      } else {
        await this.getCustomerSupportSettingsByKey({ templateId });
        await this.updateTree([...this.categories]);
        this.visible = true;
        this.formData = {};
        if (this.$refs.tree) this.$refs.tree.selected().unselect();
        this.isUpdate = false;
      }
    } else {
      this.visible = true;
      const params = { id: this.item.id };
      const editData = await this.getCustomerSupportById(params);
      this.projectId = editData.projectId;
      setTimeout(() => this.mapEditData(editData), 100);
    }
  },
  async mounted() {
    const countries = await this.getCountries({ language: this.displayLanguage });
    this.lstCountries = countries.map(country => {
      return { key: country.code, value: country.name };
    });
    EventBus.$on('newRequest', () => {
      this.isView = false;
    });
    EventBus.$on('submitCustomerSupport', () => {
      this.handleSubmitForm();
    });
    EventBus.$on('submitCustomerSupportClose', status => {
      this.handleSubmitToClose(status);
    });
    EventBus.$on('editSubmittedFormMode', value => {
      this.isView = !value;
      this.editSubmittedForm = value;
    });
    EventBus.$on('updateCustomerSupport', status => {
      this.handleUpdateSubmitForm(status);
    });
  },
  beforeDestroy() {
    EventBus.$off('newRequest');
    EventBus.$off('submitCustomerSupport');
    EventBus.$off('submitCustomerSupportClose');
    EventBus.$off('editSubmittedFormMode');
    EventBus.$off('updateCustomerSupport');
    this.categoryPath = [];
    this.selectedNode = null;
    this.clearTree();
  },
  methods: {
    ...mapActions('customerSupport', [
      'submitForm',
      'getCustomerSupportById',
      'getCustomerSupportSettingsByKey',
      'updateTree',
      'getCountries',
      'getProvinces',
      'clearTree',
      'getCustomerSupports',
      'setCSFMode',
      'getTemplateContent'
    ]),
    ...mapActions('chat', ['closeConversation', 'closeConversationByTicketId', 'setReady']),
    async changeCombo(cpn, value) {
      const { prop } = cpn;
      if (prop === 'country') {
        this.formData = { ...this.formData, ...{ [this.statePrefectureKey]: '' } };
        if (value) {
          const provinces = await this.getProvinces({
            code: value,
            language: this.displayLanguage
          });
          this.lstProvinces = provinces.map(province => {
            return { key: province.code, value: province.name };
          });
        } else {
          this.lstProvinces = [];
        }
      }
    },
    optionsData(c) {
      if (c.prop && c.prop === 'country') {
        this.countryKey = c.key;
        return this.lstCountries;
      }
      if (c.prop && c.prop === 'statePrefecture') {
        this.statePrefectureKey = c.key;
        return this.lstProvinces;
      }
      if (c.type === 'combobox') {
        const options = c.options;
        const optionsDefaultLanguage = options[this.displayLanguage];
        if (optionsDefaultLanguage && optionsDefaultLanguage.length > 0) {
          return optionsDefaultLanguage.map((item, index) => {
            return { value: item, key: index.toString() };
          });
        } else if (options && Array.isArray(options)) {
          return options.map((item, index) => {
            return { value: item, key: index.toString() };
          });
        } else if (options && typeof options === 'object') {
          return Object.keys(options).map(key => {
            return { value: options[key], key: key };
          });
        }
      }
    },
    optionsDataRadio(data) {
      if (!data && data.length <= 0) return [];
      const result = data.map((item, index) => {
        if (index.toString() === '2') {
          return { value: '', title: item, key: index.toString() };
        }
        return { value: item, key: index.toString() };
      });
      return result;
    },
    inputOtherValue(value) {
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      if (gender?.key !== '2') return (this.radioOtherValueError = true);
      this.radioOtherValueError = false;
      this.formData[key].value = value;
    },
    async handleSubmitForm() {
      this.handleDataAndSubmit('handleSubmitForm');
    },
    async handleSubmitToClose(status) {
      this.handleDataAndSubmit('handleSubmitToClose', status);
    },
    async handleDataAndSubmit(type, status) {
      /**
       * status
       * = true: when click submit and profile saved successfully
       * = false: when click submit and profile saved failed
       * = undefined: when click save draft
       */
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      this.$refs.CSRequestInformation &&
        this.$refs.CSRequestInformation.validate().then(async result => {
          if (
            !result ||
            (this.selectedNode && !this.selectedNode.length) ||
            (this.selectedNode &&
              this.selectedNode.length &&
              !this.selectedNode[0].data.activeNode) ||
            (gender && gender.key === '2' && !gender.value)
          ) {
            setTimeout(async () => {
              const fields = this.$refs.CSRequestInformation.fields;
              const { msgUnFill, msgInvalid } = await this.handleErrorMessage({
                fields,
                selectCategory: this.selectedNode
              });
              if (!this.visible) this.visible = true;
              this.$emit('invalidData', { msgUnFill, msgInvalid });
            }, 100);
          } else {
            if (this.selectedNode && this.selectedNode.length) {
              if (this.selectedNode[0].data.activeNode) {
                const fullPath = [this.selectedNode[0].text];
                this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
                const data = {
                  projectId: this.projectId,
                  categoryId: this.selectedNode[0].data.id,
                  categoryPath: fullPath.join(' ▶ '),
                  customerData: this.formData,
                  status: status ? STATUS_SUBMIT_CSF.SUBMITTED : STATUS_SUBMIT_CSF.DRAFT,
                  templateId: this.templateId
                };

                if (gender && gender.key === '2') {
                  delete data.customerData[key].title;
                } else if (gender && gender.key !== '2') {
                  this.$refs[key] ? this.$refs[key][0].otherValue = '' : null;
                }

                const title = this.getTitle();
                if (title) data.title = title;

                const handleCFSData =
                  isEmpty(this.information) ||
                  this.information.ticketId === this.conversation.ticketId
                    ? true
                    : false;
                data.conversationId = handleCFSData
                  ? this.conversation.id
                  : this.information.conversationId;
                data.agentId = handleCFSData
                  ? this.conversation.ticket.assignee
                  : this.information.agentId;
                data.userId = handleCFSData ? this.conversation.userId : this.information.userId;
                data.userName = handleCFSData
                  ? this.conversation.name || 'No name'
                  : this.information.userName;
                data.ticketId = handleCFSData
                  ? this.conversation.ticketId
                  : this.information.ticketId;
                data.channelId = handleCFSData
                  ? this.conversation.channelId
                  : this.information.channelId;

                if (this.conversation.ticket.groupId && handleCFSData) {
                  data.groupId = this.conversation.ticket.groupId;
                } else if (this.information.groupId && !handleCFSData) {
                  data.groupId = this.information.groupId;
                }

                if (this.conversation && this.conversation.channelId && handleCFSData) {
                  if (this.channelsMap[this.conversation.channelId]) {
                    data.platform = this.channelsMap[this.conversation.channelId].platform;
                  }
                } else if (this.information && this.information.channelId && !handleCFSData) {
                  if (this.channelsMap[this.information.channelId]) {
                    data.platform = this.channelsMap[this.information.channelId].platform;
                  }
                }
                let isSubmited = true;
                let isClosed = false;
                let deletedData = false;
                if (this.isUpdate) {
                  data.id = this.information.id || this.customerSupportId;
                  const params = { id: data.id };
                  const checkData = await this.getCustomerSupportById(params);
                  if (Object.keys(checkData).length === 0) {
                    deletedData = true;
                  } else if (checkData && checkData.status === 'submited') {
                    isSubmited = false;
                  }
                } else {
                  const checkConversation = find(this.conversations, {
                    id: this.conversation.id
                  });
                  if (!checkConversation) {
                    isClosed = true;
                  } else {
                    isClosed = false;
                  }
                }
                if (isSubmited) {
                  if (isClosed) {
                    const msgError = this.$t(
                      'src.modules.chat.components.CustomerSupport.RequestInformation.this_conversation_was_closed_you_can_not_add/update_request'
                    );
                    this.$emit('invalidData', { msgError });
                  } else if (deletedData) {
                    const msgError = this.$t(
                      'common.CustomerSupport.RequestInformation.this_request_was_deleted_you_can_not_update_this_request'
                    );
                    this.$emit('invalidData', { msgError });
                  } else {
                    const resultCSF = await this.submitForm({
                      formCustomerSupport: data,
                      isUpdate: this.isUpdate,
                      updatedByAgent: `${this.user.firstName} ${this.user.lastName}`
                    });

                    if (isEmpty(resultCSF)) return null;
                    this.customerSupportId = resultCSF.id;
                    this.isUpdate = true;
                    if (!status) {
                      this.$emit(
                        'success',
                        this.$t(
                          'src.modules.chat.components.CustomerSupport.RequestInformation.request_was_added'
                        )
                      );

                      this.$emit('checkEditData', false);
                      EventBus.$emit('successSubmit');
                    } else {
                      if (type !== 'handleSubmitToClose') return null;
                      this.$baseNotification.success({
                        title: this.$t('src.core.App.success'),
                        message: this.$t(
                          'src.modules.chat.components.CustomerSupport.RequestInformation.request_was_submited_and_this_conversation_was_closed'
                        )
                      });
                      this.$emit('submitToClose');
                      if (handleCFSData) {
                        const { ticketId, ticket: { updatedAt } = {} } = this.conversation;
                        this.onCloseConversation(ticketId, updatedAt);
                      } else {
                        const { ticketId, conversationId, channelId } = this.information;
                        const { updatedAt = '' } = this.tickets[ticketId];
                        this.getCustomerSupports({
                          conversationId,
                          channelId,
                          projectId: this.projectId
                        });
                        this.onCloseConversation(ticketId, updatedAt);
                      }
                    }
                  }
                } else {
                  const msgError = this.$t(
                    'common.CustomerSupport.the_request_was_submitted_you_can_not_change_or_delete_it'
                  );
                  this.$emit('invalidData', { msgError });
                }
              }
            } else {
              // eslint-disable-next-line
              console.log('handleDataAndSubmit -> false', this.selectedNode);
            }
          }
        });
    },

    handleUpdateSubmitForm(status) {
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      this.$refs.CSRequestInformation &&
        this.$refs.CSRequestInformation.validate().then(async result => {
          if (
            !result ||
            (this.selectedNode && !this.selectedNode.length) ||
            (this.selectedNode &&
              this.selectedNode.length &&
              !this.selectedNode[0].data.activeNode) ||
            (gender && gender.key === '2' && !gender.value)
          ) {
            setTimeout(async () => {
              const fields = this.$refs.CSRequestInformation.fields;
              const { msgUnFill, msgInvalid } = await this.handleErrorMessage({
                fields,
                selectCategory: this.selectedNode
              });
              if (!this.visible) this.visible = true;
              this.$emit('invalidData', { msgUnFill, msgInvalid });
            }, 100);
          } else {
            if (this.selectedNode && this.selectedNode.length) {
              if (this.selectedNode[0].data.activeNode) {
                const fullPath = [this.selectedNode[0].text];
                this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
                const data = {
                  projectId: this.projectId,
                  categoryId: this.selectedNode[0].data.id,
                  categoryPath: fullPath.join(' ▶ '),
                  customerData: this.formData,
                  status: STATUS_SUBMIT_CSF.SUBMITTED,
                  templateId: this.templateId
                };

                if (gender && gender.key === '2') {
                  delete data.customerData[key].title;
                } else if (gender && gender.key !== '2') {
                  this.$refs[key][0].otherValue = '';
                }

                const title = this.getTitle();
                if (title) data.title = title;

                const handleCFSData =
                  isEmpty(this.information) ||
                  this.information.ticketId === this.conversation.ticketId
                    ? true
                    : false;
                data.conversationId = handleCFSData
                  ? this.conversation.id
                  : this.information.conversationId;
                data.agentId = handleCFSData
                  ? this.conversation.ticket.assignee
                  : this.information.agentId;
                data.userId = handleCFSData ? this.conversation.userId : this.information.userId;
                data.userName = handleCFSData
                  ? this.conversation.name || 'No name'
                  : this.information.userName;
                data.ticketId = handleCFSData
                  ? this.conversation.ticketId
                  : this.information.ticketId;
                data.channelId = handleCFSData
                  ? this.conversation.channelId
                  : this.information.channelId;
                data.id = this.information.id;

                if (this.conversation.ticket.groupId && handleCFSData) {
                  data.groupId = this.conversation.ticket.groupId;
                } else if (this.information.groupId && !handleCFSData) {
                  data.groupId = this.information.groupId;
                }

                if (this.conversation && this.conversation.channelId && handleCFSData) {
                  if (this.channelsMap[this.conversation.channelId]) {
                    data.platform = this.channelsMap[this.conversation.channelId].platform;
                  }
                } else if (this.information && this.information.channelId && !handleCFSData) {
                  if (this.channelsMap[this.information.channelId]) {
                    data.platform = this.channelsMap[this.information.channelId].platform;
                  }
                }

                const resultCSF = await this.submitForm({
                  formCustomerSupport: data,
                  isUpdate: true,
                  updatedByAgent: `${this.user.firstName} ${this.user.lastName}`
                });

                if (isEmpty(resultCSF)) return null;
                if (status) {
                  this.$baseNotification.success({
                    title: this.$t('src.core.App.success'),
                    message: this.$t(
                      'src.modules.chat.components.CustomerSupport.RequestInformation.request_was_added'
                    ),
                    multi: true
                  });
                  this.$emit('submitToClose');
                } else {
                  this.$emit(
                    'success',
                    this.$t(
                      'src.modules.chat.components.CustomerSupport.RequestInformation.request_was_added'
                    )
                  );
                  this.$emit('checkEditData', false);
                  EventBus.$emit('successSubmit');
                }
              }
            } else {
              // eslint-disable-next-line
              console.log('handleUpdateSubmitForm >> false', this.selectedNode);
            }
          }
        });
    },

    async mapEditData(editData) {
      if (this.$refs && this.$refs.tree) this.$refs.tree.selected().unselect();
      if (editData.status === 'draft') {
        this.isView = false;
      } else {
        this.isView = true;
      }
      this.isUpdate = true;
      if (!this.item) {
        if (editData.categoryId) {
          const selection = this.$refs.tree.find({
            data: { id: editData.categoryId }
          });
          if (selection) {
            selection.select(true);

            let { parent = null } = selection[0];
            if (parent) {
              do {
                parent.expand(true);
                parent = parent.parent || null;
              } while(parent)
            }
          }
        }
      } else {
        delete editData.updatedAt;
        delete editData.createdAt;
        this.itemData = editData;
      }
      const defaultFields = Object.keys(this.customerFields).reduce((fields, field) => {
        fields[field] = '';
        return fields;
      }, {});
      this.formData = { ...defaultFields, ...editData.customerData };
      const keyCountry = (this.formData && this.formData[this.countryKey]) || '';
      if (keyCountry) {
        const provinces = await this.getProvinces({
          code: keyCountry,
          language: this.displayLanguage
        });
        this.lstProvinces = provinces.map(province => {
          return { key: province.code, value: province.name };
        });
      }
      this.initFormData = clone(this.formData || {});
      this.initCategoryPath = editData.categoryPath || '';
    },
    dateFormat(date) {
      return moment(date).format(this.formatDate);
    },

    onCloseConversation(ticketId, updatedAt) {
      this.setReady(false);
      this.closeConversationByTicketId({ ticketId, updatedAt })
        .then(() => this.setReady(true))
        .catch(() => this.setReady(true));
    },

    getTitle() {
      let titleValue = '';
      const { title } = this.fixedFields;
      const { type, key, options } = title;
      if (!this.formData[key]) return false;

      if (type === 'combobox') {
        titleValue = options[this.displayLanguage][this.formData[key]];
      } else if (type === 'text') {
        titleValue = this.formData[key];
      }
      return titleValue;
    },
    m_getFiledInUsed(raw, isFixField = true) {
      const fieldCheckDisplay = isFixField ? 'inUsed' : 'display';
      return Object.keys(raw)
        .filter(key => {
          if (this.formData && raw[key] && isEmpty(this.formData[raw[key].key])) {
            const { [fieldCheckDisplay]: display = false } = raw[key] || {};
            return display;
          }
          return true;
        })
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
    handleErrorMessage(obj) {
      const { fields, selectCategory } = obj;
      const fieldInvalid = [];
      const fieldUnFill = [];
      if (selectCategory && selectCategory.length > 0) {
        if (!selectCategory[0].data.activeNode) fieldInvalid.push(`[${this.labelCategory}]`);
      } else fieldUnFill.push(`[${this.labelCategory}]`);
      const errUnFill = Object.values(fields).filter(
        c =>
          c.required &&
          c.failedRules &&
          (c.failedRules.baseSelectRequired ||
            c.failedRules.baseRequired ||
            c.failedRules.baseCheckRequired)
      );
      const errInvalid = Object.values(fields).filter(
        c =>
          c.failedRules &&
          (c.failedRules.baseEmail || c.failedRules.basePhoneNumber || c.failedRules.basePostCode)
      );
      const components = Object.values(this.customerAllFields).reduce(
        (acc, i) => [...acc, ...Object.values(i)],
        []
      );
      errUnFill.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.lastIndexOf('-'));
        components.forEach(c => {
          if (cKey === c.key) {
            label = typeof c.label === 'object' ? c.label[this.displayLanguage] : c.label;
          }
        });
        fieldUnFill.push(`[${label}]`);
      });
      errInvalid.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.lastIndexOf('-'));
        components.forEach(c => {
          if (cKey === c.key) {
            label = typeof c.label === 'object' ? c.label[this.displayLanguage] : c.label;
          }
        });
        fieldInvalid.push(`[${label}]`);
      });

      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      if (gender && gender.key === '2' && !gender.value) {
        this.radioOtherValueError = true;
        fieldUnFill.push('[Gender]');
      }

      const paramsUnFill =
        fieldUnFill.length <= 2 ? fieldUnFill.join() : `${fieldUnFill.slice(0, 2).join()} ...`;
      const msgUnFill = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.requestInformation.missing_fulfil_required_fields',
        { fields: paramsUnFill }
      );
      const paramsInvalid =
        fieldInvalid.length <= 2 ? fieldInvalid.join() : `${fieldInvalid.slice(0, 2).join()} ...`;
      const msgInvalid = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.requestInformation.wrong_input_fields',
        { fields: paramsInvalid }
      );

      return fieldUnFill.length > 0 && fieldInvalid.length > 0
        ? { msgUnFill, msgInvalid }
        : fieldUnFill.length > 0
        ? { msgUnFill }
        : { msgInvalid };
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-left: -1rem;
  margin-right: -1rem;
}

.breadcrumb-item.active {
  color: #525f7f;
  font-weight: bold;
}

.tree {
  /deep/.tree-anchor {
    color: #525f7f !important;
  }

  /deep/.tree-node.selected > .tree-content {
    background-color: #c9daef;
  }
}

#clRequestInformation {
  .col {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

/deep/ .v-select {
  display: -webkit-box;
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    &:after {
      content: none;
    }
    .selected-tag {
      color: #8898aa;
    }
  }
  .dropdown-menu {
    margin-top: 2px;
    li {
      a {
        min-height: 23px;
        color: #8898aa;
        font-size: 0.875rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }
    .highlight {
      a {
        background: #4bd;
        color: #ffffff;
      }
    }
  }
  input {
    margin: -10px 0 0;
    width: 100%;
    height: 100%;
  }
  .no-options {
    display: none;
  }
}

/deep/ .vdp-datepicker {
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    line-height: 1.5;
    color: #8898aa;
    border: 0px;
    height: 100%;
    width: 100%;
  }
}
</style>
