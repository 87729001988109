<template>
  <div v-if="products.length > 0" data-role="content" class="scrollable-content">
    <nav
      v-if="products.length > 0"
      v-b-toggle="toggleName"
      class="navbar navbar-expand-lg navbar-dark bg-primary"
    >
      <div class="container p-0">
        <div class="channel-header">
          <span
            v-if="products.length > 0"
            class="badge badge-pill badge-info text-white bg-blue-dark"
            >{{ products.length }}</span
          >
          <span class="navbar-brand">{{ productSetName }}</span>
        </div>
        <i :class="['text-white', collapseIcon]" />
      </div>
    </nav>
    <b-collapse
      :id="toggleName"
      :visible="true"
      accordion="sidebar-conversationFeed"
      @show="collapsed = false"
      @hide="collapsed = true"
    >
      <ul :class="['list-group', 'border', 'p-20']">
        <div :class="['row', 'products', 'mt-15', 'scroll', shoeWaitingHeight]">
          <vue-element-loading
            :active="!ready"
            :text-style="textStyle"
            spiner="line-scale"
          />
          <template v-for="(prds, index) in productList">
            <drag
              v-for="p in prds"
              :key="p.id"
              :transfer-data="{ product: p }"
              :class="['drag', productChunk]"
            >
              <product-item v-bind="p" @onSelectedItem="handleOnSelectedItem" />
            </drag>
            <div v-if="index < productLen - 1" :key="index" class="w-100 h-30" />
          </template>
        </div>
      </ul>
      <div style="text-align: left; margin-top: 10px;">
        <el-pagination
          small
          ref="pagination"
          :total="totalProduct"
          :page-size="pageSize"
          :current-page="currentPage"
          layout="total, prev, pager, next"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import chunk from 'lodash/chunk';
import ProductItem from './product-item';

export default {
  components: {
    ProductItem
  },

  props: {
    products: {
      type: Array,
      default: () => []
    },
    productSet: {
      type: String,
      default: ''
    },
    ready: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      textStyle: {},
      collapsed: false,
      currentPage: 1,
      pageSize: 20
    };
  },

  computed: {
    ...mapState('global', ['rightProductPanelPinned']),
    ...mapState('chat', ['prodSearchText']),

    totalProduct() {
      return this.products.length;
    },

    productList() {
      const dataPagination = this.products.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
      if (this.rightProductPanelPinned) {
        return chunk(dataPagination, 2);
      }
      return chunk(dataPagination, 4);
    },

    productChunk() {
      if (this.rightProductPanelPinned) {
        return 'col-6';
      }
      return 'col-3';
    },

    productLen() {
      return this.productList.length;
    },

    collapseIcon() {
      return this.collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up';
    },

    toggleName() {
      return `product-feed-list-${this.productSet}`;
    },
    productSetName() {
      return this.productSet;
    },
    shoeWaitingHeight() {
      return this.ready ? '' : 'waiting-height';
    }
  },

  watch: {
    prodSearchText() {
      this.currentPage = 1;
    }
  },

  methods: {
    handleOnSelectedItem(id) {
      this.$emit('onSelectedItem', id);
    },

    currentChange(value) {
      this.currentPage = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.waiting-height {
  height: calc(100vh - 235px) !important;
}

.scroll {
  overflow-y: scroll;
  overflow-x: none;
}

nav.navbar {
  cursor: pointer;
}

.channel-header {
  display: inline-block;
  width: 100%;
  .avatar-xs {
    width: 30px;
    height: 30px;
  }

  .avatar {
    .badge {
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 8px;
    }
  }

  .navbar-brand {
    display: inline-block;
    position: absolute;
    left: 60px;
    top: 13px;
  }
}

.h--0 {
  height: 0 !important;
}

.h--100vh {
  height: 100% !important;
}

ul.list-group {
  overflow: hidden;
  transition: all 0.1s ease;
  -moz-transition: all 0.1s ease; /* Firefox 4 */
  -webkit-transition: all 0.1s ease; /* Safari and Chrome */
  -o-transition: all 0.1s ease; /* Opera */

  .cf-item {
    &:last-child {
      margin-bottom: 10px;
    }
  }
}
</style>
