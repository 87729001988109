<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'vertical'">
      <label>
        <span v-if="required" class="nat-required">*</span>
        {{ label }}
        <base-tooltip-icon
          :id="`tooltip-${name}-${prefix}`"
          :name="`tooltip-${name}-${prefix}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
      </label>
      <div class="nat-radio-verti">
        <el-radio-group
          :id="`${name}-${prefix}`"
          :name="`${name}-${prefix}`"
          :value="content"
          @change="handleChange"
          @input="handleInput"
        >
          <template v-if="optionType === 'String'">
            <el-radio :disabled="disabled" v-for="item in c_options" :label="item" :key="item">{{
              item
            }}</el-radio>
            <input />
          </template>
          <template v-if="optionType === 'Object'">
            <el-radio
              :disabled="disabled"
              v-for="item in c_options"
              :label="item[optionValue]"
              :key="item[optionValue]"
              :style="`${fullWidthRadio ? 'width: 100%; padding-bottom: 10px;' : ''}`"
              >{{ !['other', '2'].includes(item.key) ? item[optionLable] : item['title'] }}
              <template v-if="showInput && ['other', '2'].includes(item.key)">
                <el-input
                  v-model="otherValue"
                  class="w-50 m-2 gender-other"
                  size="small"
                  :maxlength="maxOtherValue"
                  :show-word-limit="allowShowWordLimit"
                  :disabled="disabled"
                  @blur="handleBlur"
                ></el-input>
                <div v-if="otherValueError" class="nat-invalid-data" style="margin-left: 75px">
                  {{ errMessOtherValue }}
                </div>
              </template>
            </el-radio>
          </template>
        </el-radio-group>
      </div>
      <div class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate';
export default {
  name: 'base-radio-group',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    optionsAlign: {
      type: String,
      default: 'horizontal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String, Number],
      default: null
    },
    valueType: {
      type: String,
      default: 'Object'
    },
    name: {
      type: String,
      default: 'name'
    },
    allowShowWordLimit: {
      type: Boolean,
      default: false
    },
    errMessOtherValue: {
      type: String,
      default: ''
    },
    maxOtherValue: {
      type: Number,
      default: 100
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    options: {
      type: Array,
      default: () => []
    },
    optionType: {
      type: String,
      default: 'Object'
    },
    optionLable: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'key'
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    defaultFirstOption: {
      type: Boolean,
      default: true
    },
    fullWidthRadio: {
      type: Boolean,
      default: false
    },
    radioOtherValueError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.c_content,
      showInput: false,
      otherValue: '',
      otherValueError: false
    };
  },

  computed: {
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseSelectRequired = { label: this.label };
      return allRule;
    },

    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    },
    c_options() {
      return this.options.map(o =>
        ['other', '2'].includes(o.key) ? { ...o, value: this.otherValue } : { ...o }
      );
    },
    c_content() {
      if ((typeof this.value === 'string' || !this.value) && this.valueType === 'String') {
        return this.c_value;
      }
      if ((typeof this.value === 'string' || !this.value) && this.valueType === 'Object') {
        return this.c_value;
      }
      if ((typeof this.value === 'object' || !this.value) && this.valueType === 'Object') {
        return this.c_value[this.optionValue];
      }
      return this.c_value;
    },
    c_value() {
      if (!this.defaultFirstOption && !this.value) return '';
      if (!this.value) {
        if (this.valueType === 'String') {
          if (this.optionType === 'String') {
            this.$emit('input', this.c_options[0].toString());
          } else {
            this.$emit('input', this.c_options[0][this.optionValue].toString());
          }
        } else {
          this.$emit('input', this.c_options[0]);
        }
        if (this.optionType === 'String') {
          return this.c_options[0].toString();
        } else {
          return this.c_options[0][this.optionValue].toString();
        }
      } else {
        return this.value;
      }
    }
  },

  watch: {
    value() {
      this.content = this.c_content;
    },
    radioOtherValueError(bool) {
      this.otherValueError = bool;
    },
    content(nval, oval) {
      if (nval !== oval && ['other', '2'].includes(nval) && ['gender', 'sex'].includes(this.name)) {
        this.showInput = true;
        this.otherValue = this.c_value.value;
      }
    }
  },

  created() {
    this.content = this.c_content;
  },
  mounted() {
    if (!this.value) {
      this.content = this.c_content;
    }
  },
  methods: {
    handleChange(selected) {
      this.showInput = false;
      if (['other', '2'].includes(selected) && ['gender', 'sex'].includes(this.name)) {
        this.showInput = true;
        this.otherValue = this.c_value.value;
      }
      if (this.valueType === 'String') {
        this.$emit('change', selected);
      } else {
        const selectedObj = this.c_options.find(x => x[this.optionValue] === selected);
        this.$emit('change', selectedObj);
      }
    },
    handleInput(selected) {
      this.content = selected;
      if (this.valueType === 'String') {
        this.$emit('input', this.content);
      } else {
        const selectedObj = this.c_options.find(x => x[this.optionValue] === selected);
        this.$emit('input', selectedObj);
      }
    },
    handleBlur(event) {
      const value = event.target.value.trim();
      this.otherValue = value;
      if (!value) this.otherValueError = true;
      else this.otherValueError = false;
      this.$emit('inputOtherValue', value);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-radio-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-radio-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-radio-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-radio-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-radio-hori {
  /deep/.el-radio__label {
    font-size: 0.875rem;
  }
}
.nat-radio-verti {
  /deep/.el-radio__label {
    font-size: 0.875rem;
  }
}

.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
.gender-other {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  /deep/input {
    height: 14px !important;
    padding: 0 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
  /deep/.el-input__suffix {
    right: 0px !important;
  }
}
</style>
