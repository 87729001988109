<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <label>
      <span v-if="required" class="nat-required">*</span>
      {{ label }}
      <base-tooltip-icon
        :id="`tooltip-${name}`"
        :name="`tooltip-${name}`"
        :content="tooltipText"
        :icon="tooltipIcon"
      />
    </label>
    <div class="nat-date-picker">
      <el-date-picker
        :clearable="!required"
        :disabled="disabled"
        v-model="content"
        :type="type"
        :placeholder="c_placeholder"
        :format="c_formatDate"
        :editable="false"
        :picker-options="c_pickerOptions"
        @change="handeChange()"
        @input="handleInput()"
      >
      </el-date-picker>
    </div>
    <div class="nat-invalid-data">{{ errors[0] }}</div>
    <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
  </ValidationProvider>
</template>

<script>
import { uuid } from 'vue-uuid';
export default {
  name: 'base-date-picker',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: uuid.v4()
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    value: {
      type: [String, Date],
      default: null
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    starPlaceholder: {
      type: String,
      default: null
    },
    endPlaceholder: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      content: this.value
    };
  },
  computed: {
    c_formatDate() {
      switch (this.type) {
        case 'year':
          return 'yyyy';
        case 'month':
          return 'yyyy/MM';
        case 'date':
          return 'yyyy/MM/dd';
        case 'dates':
          return 'yyyy/MM';
        case 'datetime':
          return 'yyyy/MM';
        case 'week':
          return 'yyyy/MM';
        default:
          return 'yyyy/MM/dd';
      }
    },
    c_pickerOptions() {
      if (this.c_arrRule.includes('birthday'))
        return {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        };
      else return {};
    },
    c_arrRule() {
      return this.rules.split('|');
    },
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseSelectRequired = { label: this.label };
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.select.placeholder', { fieldName: this.label });
    }
  },
  watch: {
    value(value) {
      this.content = value;
    }
  },
  created() {},
  methods: {
    handeChange() {
      this.$emit('change', this.content);
    },

    handleInput() {
      this.$emit('input', this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-date-picker {
  /deep/.el-date-editor.el-input {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}

.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
</style>
