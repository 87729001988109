<template>
  <div class="pin-panel-action">
    <b-button v-if="pinStatus" variant="link" @click="$emit('onPinOff')" class="btn-pin">
      <i class="material-icons">fullscreen</i>
    </b-button>
    <b-button v-if="!pinStatus" variant="link" @click="$emit('onPinOn')" class="btn-pin">
      <i class="material-icons">fullscreen_exit</i>
    </b-button>
    <b-button variant="link" @click="$emit('onClose')"
      ><i class="fas fa-times"></i
    ></b-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    pinStatus: {
      type: Boolean,
      default: () => true
    }
  },

  computed: {},

  methods: {}
};
</script>

<style lang="scss" scoped></style>
