<template>
  <b-modal
    id="filter-columns-modal"
    ref="filterColumnsModal"
    v-model="show"
    :title="
      $t('src.modules.search-management.components.column-filter.select_result_columns')
    "
    :ok-title="$t('src.modules.search-management.components.column-filter.select')"
    :cancel-title="$t('common.confirmModal.cancel')"
    no-close-on-backdrop
    @hidden="closeModal"
    @ok="$emit('finishFilterColumns', tableData)"
  >
    <el-table ref="multipleTable" :data="tableData" style="width: 100%" height="379px">
      <el-table-column
        :label="$t('src.modules.search-management.components.column-filter.select')"
        min-width="20"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.selected"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('src.modules.search-management.components.column-filter.column_name')"
        min-width="80"
      >
        <template slot-scope="scope">{{ scope.row.label }}</template>
      </el-table-column>
    </el-table>
  </b-modal>
</template>
<style lang="scss">
#filter-columns-modal {
  & button.btn.btn-secondary {
    border-color: #878282;
    color: #212529;
    background-color: #fff;
  }
}
</style>
<script>
import { EventBus } from 'core/eventBus';
import cloneDeep from 'lodash/cloneDeep';

function defaultState() {
  return {
    show: false,
    tableData: []
  };
}

export default {
  name: 'ColumnsFilterModal',

  props: {
    filterColumns: {
      type: Array,
      default: () => []
    }
  },

  data: defaultState,

  created() {
    EventBus.$on('filterColumnsModal', (bool = false) => this.handlerOpenPopup(bool));
  },

  destroyed() {
    EventBus.$off('filterColumnsModal', (bool = false) => this.handlerOpenPopup(bool));
  },

  methods: {
    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handlerOpenPopup(bool) {
      this.show = bool;
      if (!bool) {
        this.resetAllState();
        return;
      }

      this.tableData = cloneDeep(this.filterColumns) || [];
    },

    closeModal() {
      this.resetAllState();
      this.show = false;
    }
  }
};
</script>
