/** * Can not use el-iput beacause When input Japanese character can not count maxlength
**/

<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-input-select-group-prepend">
        <div class="nat-input-select-group-text" :style="c_labelWidthStyle">
          <label v-if="label !== ''">
            <span v-if="required" class="nat-required">*</span>
            {{ label }}
          </label>
          <div class="nat-input-select-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}`"
              :name="`tooltip-${name}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-input-select-group-value">
          <div class="nat-input-select-group-value-maxlength">
            <span
              v-if="c_contentLength > 0 && showWordLimit"
              :class="['nat-maxlengthTag']"
            >
              <span
                :class="[
                  `${
                    maxlength - c_contentLength < 0 || c_contentLength - minlength <= 0
                      ? 'error'
                      : ''
                  } `
                ]"
              >
                {{ c_contentLength }}</span
              >&nbsp;/&nbsp;{{ maxlength }}
            </span>
          </div>
          <input
            class="nat-input-select-hori"
            :disabled="disabled"
            :maxlength="maxlength"
            :name="name"
            :placeholder="disabled ? '' : c_placeholder"
            :id="name"
            type="text"
            v-model="content"
            @compositionupdate="handecomposeUpdate"
            @compositionend="handecomposeEnd"
            @change="handleChange(content)"
            @input="handleInput(content)"
          />
        </div>
      </div>
      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
    <div v-if="align === 'vertical'">
      <label v-if="label !== ''">
        <span v-if="required" class="nat-required">*</span>
        {{ label }}
        <base-tooltip-icon
          :id="`tooltip-${name}`"
          :name="`tooltip-${name}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
        <span v-if="c_contentLength > 0 && showWordLimit" :class="['nat-maxlengthTag']">
          <span
            :class="[
              `${
                maxlength - c_contentLength < 0 || c_contentLength - minlength < 0
                  ? 'error'
                  : ''
              } `
            ]"
          >
            {{ c_contentLength }}</span
          >&nbsp;/&nbsp;{{ maxlength }}
        </span>
      </label>
      <div class="nat-input-select-verti-append">
        <div :style="c_selectWidthStyle">
          <el-select v-model="selectedValue" @change="handleChangeSelect">
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionLable]"
              :value="item[optionValue]"
            />
          </el-select>
        </div>
        <input
          class="nat-input-select-verti"
          :disabled="disabled"
          :maxlength="maxlength"
          :name="name"
          :placeholder="disabled ? '' : c_placeholder"
          :id="name"
          type="text"
          v-model="content"
          @compositionupdate="handecomposeUpdate"
          @compositionend="handecomposeEnd"
          @change="handleChange(content)"
          @input="handleInput(content)"
        />
      </div>

      <div class="nat-invalid-data" v-if="errors[0] !== 'hidden'">{{ errors[0] }}</div>
      <div v-if="hintText !== ''" class="nat-hint-text">{{ hintText }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate.js';
import { convert2OneByte } from './vee-validate';

export default {
  name: 'base-input-select',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    maxlength: {
      type: Number,
      default: 100
    },
    minlength: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    showWordLimit: {
      type: Boolean,
      default: true
    },
    selectWidth: {
      type: Number,
      default: 0
    },
    selectedDefaultKey: {
      type: String,
      default: ''
    },
    optionLable: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'key'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      compositionData: '',
      selectedValue: this.selectedDefaultKey || (this.value && this.value.selected) || '',
      content: (this.value && this.value.content) || ''
    };
  },
  computed: {
    c_arrRule() {
      return this.rules.split('|');
    },
    c_contentLength() {
      return Number(this.content.length) + Number(this.compositionData.length);
    },
    c_isEmail() {
      return this.c_arrRule.includes('email');
    },
    c_isPhonenumber() {
      return this.c_arrRule.includes('phoneNumber');
    },
    c_isPostCode() {
      return this.c_arrRule.includes('postCode');
    },
    c_isCountryCode() {
      return this.c_arrRule.includes('countryCode');
    },
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseRequired = { label: this.label };

      if (this.maxlength) {
        allRule.baseMaxLength = { length: this.maxlength, label: this.label };
      }
      if (this.minlength) {
        allRule.baseMinLength = { length: this.minlength, label: this.label };
      }

      this.c_arrRule.map(rule => {
        switch (rule) {
          case 'email':
            allRule.baseEmail = { label: this.label };
            break;
          case 'phoneNumber':
            allRule.basePhoneNumber = { label: this.label };
            break;
          case 'numeric':
            allRule.baseNumeric = { label: this.label };
            break;
          case 'postCode':
            allRule.basePostCode = { label: this.label };
            break;
          case 'countryCode':
            allRule.baseCountryCode = { label: this.label };
            break;

          default:
            break;
        }
      });
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.placeholder', { fieldName: this.label });
    },

    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    },
    c_selectWidthStyle() {
      return this.selectWidth > 0
        ? {
            width: this.selectWidth + 'px'
          }
        : '';
    }
  },
  watch: {
    value(value) {
      this.content = (value && value.content) || '';
      this.selectedValue = (value && value.selected) || this.selectedDefaultKey || '';
    }
  },

  methods: {
    handleInput(value) {
      let valiValue = value;
      /**
       * fixed rules
       */
      // 1. Accept to input one space between 2 words/characters only
      if (/\s\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\s\s+/g, ' ');
      }
      // 2. Must not accept tab
      if (/\t+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\t+/g, ' ');
      }
      // 2.Must not accept space only without EN, JA, CH characters
      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      if (this.c_isPhonenumber) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isPostCode) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isCountryCode) {
        valiValue = valiValue.toUpperCase();
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isEmail) {
        valiValue = convert2OneByte(valiValue);

        //1. Not accept to input emojis in local part and domain name
      }

      this.content = valiValue;
      this.m_emitInput();
    },
    handleChange() {
      this.m_emitInput();
    },
    handleChangeSelect(selected) {
      this.selectedValue = selected;
      this.m_emitInput();
    },
    m_emitInput() {
      const objRtnData = {};
      objRtnData.content = this.content;
      objRtnData.selected = this.selectedValue;
      this.$emit('input', objRtnData);
    },

    handecomposeUpdate(compositionEvent) {
      this.compositionData = compositionEvent.data || '';
    },
    handecomposeEnd() {
      this.compositionData = '';
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-input-select-verti-append {
  display: flex;
  .nat-input-select-verti {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }
  .nat-input-select-hori {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }
  /deep/.el-select {
    height: 100%;
  }
  /deep/.el-select > .el-input {
    height: 100%;
  }
  /deep/.el-input--suffix .el-input__inner {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-right: 0px solid #cad1d7;
    height: 100%;
  }
}

.nat-input-select-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-input-select-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-input-select-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-input-select-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-input-select-group-value-maxlength {
  position: absolute;
  top: -14px;
}
.nat-input-select-hori {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}
.nat-input-select-verti {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.nat-required {
  color: red;
}

.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 10.5px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: 0.7;
}
.nat-maxlengthTag .error {
  color: red;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
}
</style>
