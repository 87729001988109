<template>
  <div class="app-message-list scrollable">
    <div data-role="container">
      <div
        v-bar
        :style="{
          'max-height': `calc(100vh - ${getSearchBoxHeight}px)`,
          height: `calc(100vh - ${getSearchBoxHeight}px)`
        }"
      >
        <div class="nat-project-list">
          <div>
            <el-menu
              :default-openeds="dflOpnPjtLst"
              :collapse="false"
              @open="handleOpenNavPjt"
              @close="handleCloseNavPjt"
            >
              <el-submenu v-for="(p, index) in projectList" :key="index" :index="p.key">
                <template slot="title">
                  <i class="el-icon-s-cooperation"></i>
                  <span>{{ p.name }}</span>
                  <span class="nat-project-id">{{ p.key }}</span>
                </template>
                <div class="channel-group">
                  <channel
                    v-for="c in channelList.filter(c => c.projectId === p.key)"
                    :key="c.channelId"
                    :user-info="c"
                    :name="channelsMap[c.channelId] && channelsMap[c.channelId].name"
                    :picture-url="channelsMap[c.channelId] && channelsMap[c.channelId].pictureUrl"
                    :platform="c.platform"
                    :selected="selectedUserInfo && selectedUserInfo.channelId === c.channelId"
                  />
                </div>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import noAvatar from 'assets/images/no-avatar.png';
import uniq from 'lodash/uniq';
import Channel from './channel';

export default {
  components: {
    Channel
  },

  props: {
    commentFilter: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      projectMapList: {},
      dflOpnPjtLst: [],
      noAvatar
    };
  },

  computed: {
    ...mapState('chat', ['searchBoxHeight']),
    ...mapState('session', ['channelsMap', 'projectMaps']),
    ...mapState('phoneAgentChatBox', ['usersInfo', 'selectedUserInfo']),

    projectList() {
      const projectIds = uniq(this.usersInfo.map(i => i.projectId).filter(i => this.projectMaps[i]));
      const projects = projectIds.map(id => ({ key: id, name: this.getProjectName(id) }));
      return projects.sort((ca, cb) => ca.name.localeCompare(cb.name));
    },

    channelList() {
      return this.usersInfo
        .filter(i => i && this.channelsMap[i.channelId])
        .sort((ca, cb) =>
          this.channelsMap[ca.channelId].name.localeCompare(this.channelsMap[cb.channelId].name)
        );
    },

    getSearchBoxHeight() {
      return this.searchBoxHeight + 65;
    }
  },

  watch: {
    projectList(nValue) {
      this.dflOpnPjtLst = nValue.map(i => i.key);
    }
  },

  methods: {
    ...mapActions('chat', ['setSearchBoxFilter', 'setSearchBoxHeight']),

    getProjectName(projectId) {
      if (this.projectMaps[projectId] && this.projectMaps[projectId].name)
        return this.projectMaps[projectId].name;
      return 'undefined';
    },

    handleOpenNavPjt(key) {
      this.dflOpnPjtLst.push(key);
    },
    handleCloseNavPjt(key) {
      this.dflOpnPjtLst = [...this.dflOpnPjtLst.filter(item => item != key)];
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-project-list {
  /deep/.el-submenu__title {
    background: rgba(233, 236, 239, 0.6);
  }

  .nat-project-id {
    position: absolute;
    left: 52px;
    top: 16px;
    font-size: 10px;
    color: darkgray;
  }
  /deep/.el-menu {
    width: 100% !important;
  }
}

.count-common {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 33px;
}
.count-tome {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 224px;
}
</style>
