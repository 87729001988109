import moment from 'moment';
import { i18n } from 'locales';

export const MessageType = {
  TEXT: 'text',
  TEMPLATE: 'template',
  IMAGE: 'image',
  PRODUCT: 'product',
  QUICK_REPLY: 'quick_reply',
  REFERRAL: 'referral'
};

export const TemplateType = {
  BUTTON: 'button'
};

export class Message {
  _type = '';
  _content = '';

  constructor() {
    this._type = MessageType.TEXT;
  }

  type(msgType) {
    this._type = msgType;
    return this;
  }

  channel(id) {
    this._channelId = id;
    return this;
  }

  conversation(id) {
    this._conversationId = id;
    return this;
  }

  ticket(id) {
    this._ticketId = id;
    return this;
  }

  recipient(recipient) {
    this._recipient = recipient;
    return this;
  }

  text(txt) {
    this._text = txt;
    return this;
  }

  payload(payload) {
    if (this.payload && this.payload !== '') this._payload = payload;
    return this;
  }

  faqId(faqId) {
    this._faqId = faqId;
    return this;
  }

  productIds(productIds) {
    this._productIds = productIds;
    return this;
  }

  body(data = '') {
    if (!data) return;
    switch (this._type) {
      case MessageType.TEXT:
      default: {
        this._content = data;
        break;
      }
      case MessageType.TEMPLATE: {
        this._content = JSON.stringify(data);
        break;
      }
      case MessageType.IMAGE: {
        this._content = `${data}`.trim();
        break;
      }
      case MessageType.QUICK_REPLY: {
        this._content = JSON.stringify(data);
        break;
      }
    }

    return this;
  }

  render() {
    if (!this._conversationId || !this._conversationId || !this._recipient) {
      throw new Error(i18n.t('src.core.message.message_is_invalid'));
    }
    return {
      conversationId: this._conversationId,
      ticketId: this._ticketId,
      channelId: this._channelId,
      recipient: this._recipient,
      platform: 'agent',
      type: this._type,
      content: this._content,
      createdAt: moment().toISOString(),
      text: this._text,
      ...((this.payload && { payload: this._payload }) || {}),
      faqId: this._faqId,
      productIds: this._productIds
    };
  }
}
