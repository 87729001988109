<template>
  <b-modal
    :id="`conversationDetailBox`"
    :ref="`conversationDetailBox`"
    size="lg"
    :no-close-on-backdrop="true"
    @hidden="onClose"
    modal-class="nat-conversations-detail"
  >
    <template #modal-title>
      <!-- Breadcrumb (S) -->
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>{{ projectName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ channelName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ boxTitle }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- Breadcrumb (E) -->
    </template>

    <!-- Conversation table (S) -->
    <div class="form-group">
      <div>
        <el-table
          v-loading="loading"
          ref="singleTable"
          style="width: 100%"
          highlight-current-row
          stripe="stripe"
          @sort-change="m_sortChange"
          :data="c_conversationPagination"
        >
          <div slot="empty">
            {{ $t('common.text.no_data') }}
          </div>
          <el-table-column
            sortable="custom"
            width="300"
            :label="
              $t('src.modules.chat.components.OverviewBox.conversationDetailBox.conversation_id')
            "
            property="id"
          >
            <template slot-scope="scope" v-if="scope.row">
              <a href="javascript:void(0)" @click="m_gotoTicket(scope.row)"> {{ scope.row.id }}</a>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            width="165"
            :label="
              $t('src.modules.chat.components.OverviewBox.conversationDetailBox.request_time')
            "
            property="requestTime"
          >
            <template slot-scope="scope" v-if="scope.row && scope.row.requestTime">
              {{ m_convertTimeUTC(scope.row.requestTime).split(' ')[0] }}<br />{{
                m_convertTimeUTC(scope.row.requestTime).split(' ')[1] +
                  ' ' +
                  m_convertTimeUTC(scope.row.requestTime).split(' ')[2] +
                  ' ' +
                  m_convertTimeUTC(scope.row.requestTime).split(' ')[3]
              }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            width="130"
            :label="$t('src.modules.chat.components.OverviewBox.conversationDetailBox.phase')"
            property="state"
          ></el-table-column>
          <el-table-column
            sortable="custom"
            width="130"
            :label="$t('src.modules.chat.components.OverviewBox.conversationDetailBox.status')"
            property="status"
          ></el-table-column>
          <el-table-column
            sortable="custom"
            width="140"
            :label="
              $t('src.modules.chat.components.OverviewBox.conversationDetailBox.elapsed_time')
            "
            property="latestMessage"
          >
            <template slot-scope="scope" v-if="scope.row && scope.row.latestMessage">
              {{ m_elapsedTime(scope.row.latestMessage) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            width="165"
            :label="
              $t('src.modules.chat.components.OverviewBox.conversationDetailBox.finished_time')
            "
            property="finishTime"
          >
            <template slot-scope="scope" v-if="scope.row && scope.row.finishTime">
              {{ m_convertTimeUTC(scope.row.finishTime).split(' ')[0] }}<br />{{
                m_convertTimeUTC(scope.row.finishTime).split(' ')[1] +
                  ' ' +
                  m_convertTimeUTC(scope.row.finishTime).split(' ')[2] +
                  ' ' +
                  m_convertTimeUTC(scope.row.finishTime).split(' ')[3]
              }}
            </template></el-table-column
          >
          <el-table-column
            sortable="custom"
            :label="$t('src.modules.chat.components.OverviewBox.conversationDetailBox.agent')"
            property="agentName"
          ></el-table-column>
          <el-table-column
            sortable="custom"
            width="140"
            :label="
              $t('src.modules.chat.components.OverviewBox.conversationDetailBox.agent_status')
            "
            property="agentStatus"
          ></el-table-column>
        </el-table>
      </div>
      <!-- Pagination (S) -->
      <div class="nat-paging">
        <div class="nat-paging-left">
          <span
            >{{ $t('src.modules.chat.components.OverviewBox.agent.Loaded') }}&nbsp;
            {{ c_cntLoadedPage }}/{{ c_cntTotalPage }}&nbsp;
            {{ $t('src.modules.chat.components.OverviewBox.agent.page') }}</span
          >
        </div>
        <div class="nat-paging-center">
          <el-pagination
            ref="pagination"
            :total="c_cntTotalRow"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next"
            @current-change="m_currentChange"
          ></el-pagination>
        </div>
      </div>
      <!-- Pagination (E) -->
    </div>
    <!--  Conversation table  (E) -->

    <!-- Action List (S) -->
    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right" variant="default" @click="onClose">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
    <!-- Action List (S) -->
  </b-modal>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import cloneDeep from 'lodash/cloneDeep';
import { convertTimeUTC } from 'core/helpers';
import { SERVING_STATE, TABS } from 'core/constants';
import { getTabIndex, convertTabIndexToGroupNav } from 'core/helpers';

function defaultState() {
  return {
    loading: false,
    items: [],
    sortOptions: {},

    // Title Params (S)
    projectId: '',
    projectName: '',
    channelId: '',
    channelName: '',
    boxTitle: '',
    boxTitleKey: '',
    agentId: '',

    // Pagination Params (S)
    pagesize: 10,
    currentPage: 1
  };
}

export default {
  name: 'conversation-detail-box',
  components: {},
  data: defaultState,

  computed: {
    ...mapState('chat', ['conversationsBox']),
    ...mapGetters('session', ['conversations']),
    ...mapState('session', ['user', 'channelsMap']),

    c_conversations() {
      return this.items || [];
    },

    c_cntTotalRow() {
      return this.conversationsBox.total || 0;
    },

    c_cntConversaton() {
      return this.c_conversations.length || 0;
    },

    c_cntTotalPage() {
      return Math.ceil(this.c_cntTotalRow / this.pagesize) || 0;
    },
    c_cntLoadedPage() {
      return Math.ceil(this.c_cntConversaton / this.pagesize) || 0;
    },

    c_conversationPagination() {
      const data = this.c_conversations.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      if (data.length === 0) {
        if (this.c_cntConversaton === this.c_cntTotalRow) {
          //the end
          return data;
        } else if (this.c_cntConversaton < this.c_cntTotalRow) {
          // eslint-disable-next-line
          console.log(
            '🚀 ~ file: conversationDetailBox.vue ~ c_conversationPagination ~ this.c_cntConversaton',
            this.c_cntConversaton
          );
          console.log(
            '🚀 ~ file: conversationDetailBox.vue ~ c_conversationPagination ~ this.c_cntTotalRow',
            this.c_cntTotalRow
          );

          this.m_loadConversationBoxMore();
        } else {
          // eslint-disable-next-line
          console.log('[C+ Debug] [conversation-detail-box] >>> c_conversationPagination >', {
            currentPage: this.currentPage,
            c_cntTotalPage: this.c_cntTotalPage
          });
        }
      }
      return data;
    }
  },
  watch: {
    conversationsBox: {
      deep: true,
      handler(value) {
        const { tickets = [] } = cloneDeep(value);
        const { order, prop } = this.sortOptions;
        this.items = cloneDeep(this.m_sortItems(tickets, prop, order));
      }
    }
  },

  created() {},

  destroyed() {
    EventBus.$off('conversationDetailBox');
  },

  async mounted() {
    EventBus.$on('conversationDetailBox', this.handleOpenPopup);
  },

  methods: {
    ...mapActions('chat', [
      'getMessagesByConversationId',
      'setReady',
      'getConversationsBox',
      'getConversationsBoxMore',
      'clearConversationsBox',
      'getConversationTicketById',
      'setSelectedGroupConversation',
      'setShowView',
      'setSelectedConversation'
    ]),

    ...mapActions('global', ['setGlobalReady']),

    ...mapActions('session', ['setWaitingMode', 'setChannelTabReload']),

    async handleOpenPopup(params) {
      this.m_resetAllState();

      this.projectId = params.projectId || '';
      this.channelId = params.channelId || '';
      this.projectName = params.projectName || '';
      this.channelName = params.channelName || '';
      this.boxTitle = params.boxTitle || '';
      this.boxTitleKey = params.boxTitleKey || '';
      this.agentId = params.agentId || '';
      this.groupIds = params.groupIds || [];

      this.$refs['conversationDetailBox'].show();

      this.m_loadConversationBox();
    },

    m_currentChange(currentPage) {
      this.currentPage = currentPage;
    },

    m_gotoTicket({ conversationId, id }) {
      // Close popup
      this.onClose();
      this.setShowView('Chat');
      this.setGlobalReady(false);
      this.fetchData({ conversationId, id }).then(rltFetchData => {
        this.setGlobalReady(true);
        if (rltFetchData) {
          const path = `/c/${conversationId}/${id}`;
          history.pushState(null, null, path);
        }
      });
    },

    async fetchData({ conversationId, id }) {
      try {
        if (conversationId && id) {
          this.conversation = this.conversations.find(
            c => c.id === this.conversationId && c.ticketId === id
          );
          if (!this.conversation) {
            const hasConversation = await this.getConversationTicketById({
              conversationId: conversationId,
              ticketId: id
            }).then(conversation => {
              if (!conversation) {
                console.log(
                  '🚀 ~ file: conversationDetailBox.vue ~ line 354 ~ fetchData ~ conversation',
                  conversation
                );
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t('src.modules.chat.index.data_of_the_conversation_was_removed')
                });
                return false;
              }
              const { error } = conversation;
              if (error) {
                this.setSelectedGroupConversation(SERVING_STATE.WAITING);
                this.$baseNotification.warning({
                  title: this.$t('src.core.App.warning'),
                  message: error.message
                });
                return this.$router.push('/');
              }

              return conversation;
            });
            if (!hasConversation) {
              return false;
            }

            const { ticket, channelId } = hasConversation || {};
            const { projectId } = ticket || {};
            const tabIndex = getTabIndex({ conv: { ticket }, user: this.user });
            const status = tabIndex == 4 ? 5 : tabIndex;
            this.setSelectedConversation({
              conversation: hasConversation,
              eventType: 'url'
            });
            this.setChannelTabReload({
              channelStatus: channelId + status,
              flag: true
            });

            this.setSelectedConversation({
              conversation: hasConversation,
              eventType: 'url',
              groupId: convertTabIndexToGroupNav(tabIndex)
            });

            if (tabIndex === TABS.WAITING_ME) {
              this.setWaitingMode('TOME');
            }
            if (tabIndex === TABS.WAITING_COMMON) {
              this.setWaitingMode('COMMON');
            }

            await this.getMessages({ id: conversationId, projectId }).catch(error => {
              this.$log('TCL: fetchData -> getMessages -> error', error);
            });
          } else {
            const { translations = '' } = this.conversation || {};
            if (!translations) {
              await this.addTranslations(this.conversation);
            }

            const { ticket, channelId } = this.conversation || {};
            const { projectId } = ticket || {};
            const tabIndex = getTabIndex({ conv: { ticket }, user: this.user });
            const status = tabIndex == 4 ? 5 : tabIndex;
            const { grpNavTabIndex } = ticket || {};
            if (
              grpNavTabIndex &&
              grpNavTabIndex === TABS.MONITORING &&
              grpNavTabIndex !== tabIndex
            ) {
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: true
              });
            } else {
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: false
              });
            }
            this.setSelectedConversation({
              conversation: this.conversation,
              groupId: convertTabIndexToGroupNav(tabIndex),
              eventType: 'url'
            });
            await this.getMessages({ id: this.conversationId, projectId }).catch(error => {
              this.$log('TCL: fetchData -> getMessages -> error', error);
            });
          }
        }
      } catch (e) {
        this.setGlobalReady(true);
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.core.App.system_could_not_find_the_selected_conversation')
        });
        this.$log('e: ', e);
        this.$router.push('/');
        return false;
      }
      return true;
    },

    getMessages(params) {
      if (this.chatViewingGroup !== SERVING_STATE.MONITORING) {
        return this.getMessagesByConversationId(params).then(() => {
          return Promise.resolve(true);
        });
      }
      return Promise.resolve(true);
    },

    m_loadConversationBox() {
      if (this.projectId !== '' && this.channelId !== '' && this.agentId !== '') {
        this.loading = true;
        this.getConversationsBox({
          projectId: this.projectId,
          channelId: this.channelId,
          agentId: this.agentId,
          type: this.boxTitleKey,
          groupIds: this.groupIds.map(item => item.value)
        }).then(() => (this.loading = false));
      }
    },

    m_loadConversationBoxMore() {
      if (this.projectId !== '' && this.channelId !== '' && this.agentId !== '') {
        this.loading = true;
        this.getConversationsBoxMore({
          projectId: this.projectId,
          channelId: this.channelId,
          agentId: this.agentId,
          nextKey: this.conversationsBox.nextKey,
          type: this.boxTitleKey,
          groupIds: this.groupIds.map(item => item.value)
        }).then(() => (this.loading = false));
      }
    },

    m_resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    m_sortChange(sortProps) {
      const { order, prop } = sortProps;
      const { tickets = [] } = cloneDeep(this.conversationsBox);
      this.sortOptions = sortProps;

      this.items = cloneDeep(this.m_sortItems(tickets, prop, order));
    },

    m_sortItems(data, prop, order) {
      const result = data;
      this.total = result.length;
      switch (order) {
        case 'descending':
          return result.sort((a, b) => b[prop].localeCompare(a[prop]));
        case 'ascending':
          return result.sort((a, b) => a[prop].localeCompare(b[prop]));
        default:
          return result.sort((a, b) => {
            if (a.hasOwnProperty('requestTime') && b.hasOwnProperty('requestTime')) {
              return b['requestTime'].localeCompare(a['requestTime']);
            } else {
              return b['createdAt'].localeCompare(a['createdAt']);
            }
          });
      }
    },

    m_convertTimeUTC(time) {
      return convertTimeUTC(time);
    },
    m_elapsedTime(time) {
      return moment(time).fromNow(true);
    },

    onClose() {
      this.clearConversationsBox();
      this.$refs['conversationDetailBox'].hide();
    }
  }
};
</script>

<style lang="scss">
.nat-conversations-detail {
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 1300px !important;
    }
  }
  .nat-paging {
    text-align: center;
    margin-top: 30px;
    position: relative;
    .nat-paging-left {
      float: left;
    }
    .nat-paging-center {
    }
  }
}
</style>
