import fetchAPI from '../fetchAPI';

export class FirebaseFuncAPI extends fetchAPI {
  /**
   * Helps create custom "axios" instance
   * Which allow inject common options to request, response
   * @param {*} _options
   */
  constructor(_options = {}) {
    const options = {
      baseURL: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      ..._options
    };
    super(options);
    // Custom on response
    const _axios = this.getAxios();
    const resCb = res => res.data;
    const errCb = err => Promise.reject(err);
    _axios.interceptors.response.use(resCb, errCb);
  }

  getChannelConfig(platform) {
    return this.post('', {
      cmd: 'getChannelConfig',
      params: {
        platform
      }
    });
  }
}

const a = new FirebaseFuncAPI();
export default a;
