<template>
  <div :data-channelid="id" data-role="content" class="scrollable-content">
    <nav
      :key="`${tabIndex}-${id}`"
      :class="['navbar', 'navbar-expand-lg', 'navbar-dark', selected ? 'active' : '']"
      @click="toggleVisible(!visible)"
    >
      <div class="container p-0">
        <div class="channel-header">
          <a class="avatar avatar-xs shadow position-relative" href=" " @click.prevent.stop>
            <img :src="avatar" :alt="name" class="img-fluid" @error="imageLoadError" />
          </a>
          <v-clamp :class="['navbar-brand', getNavbarWith]" :max-lines="1" autoresize>{{
            name
          }}</v-clamp>
        </div>

        <div class="channel-icon-group">
          <i
            v-if="
              (tabIndex === TABS.BOT ||
                tabIndex === TABS.WAITING_COMMON ||
                tabIndex === TABS.MONITORING) &&
                refreshing
            "
            class="fa fa-spinner fa-spin"
          />

          <i
            @click="handleRefresh({ tabIndex, channelStatus })"
            v-if="!refreshing"
            v-show="c_hasUpdateTicketCome && !loadingMore"
            style="color: #f5365c; cursor: pointer"
            class="material-icons"
            >fiber_new</i
          >

          <i
            @click="handleRefresh({ tabIndex, channelStatus })"
            v-show="c_showRefresh && !refreshing"
            style="cursor: pointer; font-size: 16px"
            data-testid="btnReload"
            class="material-icons"
            >autorenew</i
          >
          <i v-show="c_existTicket" style="color: #f5365c; font-size: 16px" class="material-icons"
            >priority_high</i
          >
          <span
            :key="`loadedCount-${tabIndex}-${id}`"
            v-if="showCountAllTicketChannel"
            class="badge couter-badge"
            >{{ totalAgentChannel }}</span
          >
          <sort-ticket
            v-if="visible && showAdvance"
            :tab-index="tabIndex"
            :channel-id="id"
            :is-search="isSearch"
            :sort-by="sortBy"
            :sort-direction="sortDirection"
            @click="handleSort"
          />
          <img
            v-if="platform === 'webchat'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/webchat-logo.png"
            width="14"
            height="14"
          />
          <img
            v-else-if="platform === 'zalo'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/zalo-min.png"
            width="14"
            height="14"
          />
          <img
            v-else-if="platform === 'liffchat'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/liffchat.png"
            width="14"
            height="14"
          />
          <i v-else :class="[platformClass, 'platform']" />
          <i
            v-if="!isComment"
            :class="[!visible ? 'fa fa-angle-down' : 'fa fa-angle-up', 'toggle']"
          />
        </div>
        <i v-if="isComment && selected" class="fas fa-check toggle-icon" />
        <i v-if="isComment && !selected" style="width: 5%" />
      </div>
    </nav>
    <b-collapse
      v-if="(visible || isKeepAlive) && !isComment"
      :id="`chat-list-${id}`"
      :key="`collapse-${tabIndex}-${id}`"
      accordion="sidebar-channel"
      v-model="visible"
    >
      <div class="chat-list-inner">
        <div class="chat-list-box">
          <el-select
            v-if="selectedGroupConv > 0 && groups.length > 0"
            v-model="selectedCategory"
            :placeholder="$t('src.modules.chat.components.AsideLeft.ConversationList.index.select')"
            class="select-category d-flex justify-content-center"
            @change="m_selectedGroupConvChange"
          >
            <el-option
              v-for="item in groups"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span v-if="!item.isEnable" class="group-status-warning" style="float: right">
                <i
                  v-b-tooltip.hover
                  :title="$t('common.text.group_status')"
                  class="el-icon-warning-outline"
                ></i>
              </span>
            </el-option>
          </el-select>
          <ul :class="['list-group']" style="margin-bottom: 5px">
            <c-conversation-list
              v-if="keepAliveIndexs.includes(0)"
              v-show="TABS.BOT === tabIndex && !loadingMore"
              :tab-index="TABS.BOT"
              :id="id"
              :is-search="isSearch"
              :selected-conversation="selectedConversation"
              :selected-category="selectedCategory"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @onClick="handleOnConversationClick"
              @onLoadMoreConvs="handleOnLoadMoreConvs"
              :page="pageBot"
              :set-conversation-length="handleConversationsLength"
              :set-selected-category="handleSelectedCategory"
              :conversations-by-tab-index="conversationsByBot"
              :check-length="total < 4 ? true : false"
            />
            <c-conversation-list
              v-if="keepAliveIndexs.includes(1)"
              v-show="TABS.WAITING_COMMON === tabIndex && !loadingMore"
              :tab-index="TABS.WAITING_COMMON"
              :id="id"
              :is-search="isSearch"
              :selected-conversation="selectedConversation"
              :selected-category="selectedCategory"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @onClick="handleOnConversationClick"
              @onLoadMoreConvs="handleOnLoadMoreConvs"
              :page="pageWattingCommon"
              :set-conversation-length="handleConversationsLength"
              :set-selected-category="handleSelectedCategory"
              :conversations-by-tab-index="commonWaitingList"
              :check-length="total < 4 ? true : false"
            />
            <c-conversation-list
              v-if="keepAliveIndexs.includes(2)"
              v-show="TABS.WAITING_ME === tabIndex && !loadingMore"
              :tab-index="TABS.WAITING_ME"
              :id="id"
              :is-search="isSearch"
              :selected-conversation="selectedConversation"
              :selected-category="selectedCategory"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @onClick="handleOnConversationClick"
              @onLoadMoreConvs="handleOnLoadMoreConvs"
              :page="pageWattingMe"
              :set-conversation-length="handleConversationsLength"
              :set-selected-category="handleSelectedCategory"
              :conversations-by-tab-index="meWaitingList"
              :check-length="total < 4 ? true : false"
            />
            <c-conversation-list
              v-if="keepAliveIndexs.includes(3)"
              v-show="TABS.ME === tabIndex && !loadingMore"
              :tab-index="TABS.ME"
              :id="id"
              :is-search="isSearch"
              :selected-conversation="selectedConversation"
              :selected-category="selectedCategory"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @onClick="handleOnConversationClick"
              @onLoadMoreConvs="handleOnLoadMoreConvs"
              :page="pageMe"
              :set-conversation-length="handleConversationsLength"
              :set-selected-category="handleSelectedCategory"
              :conversations-by-tab-index="conversationsByAgents"
              :check-length="total < 4 ? true : false"
            />
            <c-conversation-list
              v-if="keepAliveIndexs.includes(4)"
              v-show="TABS.MONITORING === tabIndex && !loadingMore"
              :tab-index="TABS.MONITORING"
              :id="id"
              :is-search="isSearch"
              :selected-conversation="selectedConversation"
              :selected-category="selectedCategory"
              :sort-by="sortBy"
              :sort-direction="sortDirection"
              @onClick="handleOnConversationClick"
              @onLoadMoreConvs="handleOnLoadMoreConvs"
              :page="pageMonitoring"
              :set-conversation-length="handleConversationsLength"
              :set-selected-category="handleSelectedCategory"
              :conversations-by-tab-index="monitoringList"
              :check-length="total < 4 ? true : false"
            />
            <li
              v-if="
                visible &&
                  !syncing &&
                  !loadingMore &&
                  ((!isSearch && loadedCount === 0) ||
                    (isSearch && (totalAgentChannel === 0 || loadedCount === 0)))
              "
              class="p-2"
            >
              {{
                $t('src.modules.chat.components.AsideLeft.ConversationList.index.no_conversation')
              }}
            </li>
            <li data-testid="paging" class="li-load-more" style="list-style-type: none">
              <i v-if="loadingMore" class="fa fa-spinner fa-spin"></i>
              <div class="row">
                <div
                  class="col"
                  v-show="
                    (total > 0 || conversationsCnt > 0 || this.c_pageCount > 0) &&
                      !loadingMore &&
                      !refreshing
                  "
                >
                  <div
                    style="
                      position: absolute;
                      left: 12px;
                      top: 10px;
                      color: #f5365c;
                      margin: 15px;
                      cursor: pointer;
                    "
                    v-show="conversationsCnt > 0"
                  >
                    <i
                      @click="handleRefresh({ tabIndex, channelStatus })"
                      v-if="!refreshing"
                      v-show="c_hasUpdateTicketCome"
                      class="material-icons"
                      >fiber_new</i
                    >
                  </div>
                  <BaseTicketPaginate
                    :value="c_pageNumber"
                    :page-count="c_pageCount"
                    :container-class="'pagination'"
                    :click-handler="clickPageHandler"
                    :click-next-block-handler="clickNextBlockHandler"
                    :click-prev-block-handler="clickPrevBlockHandler"
                    :data-cnt="conversationsCnt"
                  ></BaseTicketPaginate>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import noAvatar from 'assets/images/no-avatar-channel.jpg';
import {
  TABS,
  SORT_BY,
  SERVING_STATE,
  SEARCH_FILTER,
  TICKET_SEARCH_TYPE,
  TICKET_TYPE_CODE
} from 'core/constants';
import VClamp from 'vue-clamp';
import SortTicket from './sort.vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import CConversationList from './conversation-list';

export default {
  components: {
    CConversationList,
    VClamp,
    SortTicket
  },

  props: {
    tabIndex: {
      type: Number,
      default: TABS.ME
    },
    isComment: {
      type: Boolean,
      default: () => false
    },
    isSearch: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: String,
      default: () => ''
    },
    name: {
      type: [String, Number],
      default: () => ''
    },
    platform: {
      type: String,
      default: () => 'webchat'
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    pictureUrl: {
      type: String,
      default: noAvatar
    },
    total: {
      type: Number,
      default: () => 0
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    keepAlive: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      already: false,
      hasMoreConversations: true,
      imageError: false,
      visible: this.selected,
      sortEvent: false,
      selectedCategory: 'all',
      refreshing: false,
      TABS: TABS,
      ticketCountES: 0,
      mode: false,
      firstMode: false,
      conversationsCnt: 0,
      page: 1,
      pageBot: 1,
      pageWattingMe: 1,
      pageWattingCommon: 1,
      pageMe: 1,
      pageMonitoring: 1,
      pageBotNext: false,
      pageWattingMeNext: false,
      pageWattingCommonNext: false,
      pageMeNext: false,
      pageMonitoringNext: false
    };
  },

  computed: {
    ...mapState('chat', ['selectedGroupConv', 'searchBoxFilter', 'sortBysMap', 'setSearchBox']),
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'monitoringList',
      'meWaitingList',
      'groupMaps'
    ]),
    ...mapState('session', [
      'channelsMap',
      'loadingMoreMaps',
      'waitingMode',
      'user',
      'syncTicketsMap',
      'ticketsNextFromChannelMap',
      'ticketESCount',
      'hasUpdateTicketCome',
      'channelTabReload',
      'channelTabPage',
      'goToTicketCome',
      'selectedCategoryChannelMap',
      'ticketGroupCountBySearchChannelMap'
    ]),

    showCountAllTicketChannel() {
      return (this.showCountSearchAdvance || this.showCountAdvance) && !this.refreshing;
    },

    isKeepAlive() {
      return this.keepAlive && this.keepAlive.channelId === this.id;
    },

    keepAliveIndexs() {
      const indexs = this.visible ? [this.tabIndex] : [];
      if (this.isKeepAlive && !indexs.includes(this.keepAlive.tabIndex))
        indexs.push(this.keepAlive.tabIndex);
      return indexs;
    },

    getNavbarWith() {
      const channelStatus = `${this.id}${TICKET_TYPE_CODE.SEARCH}`;
      const isSearchNew = this.hasUpdateTicketCome[channelStatus] || false;
      const isNewTicket = this.hasUpdateTicketCome[this.channelStatus] || false;
      const isSort = [TABS.WAITING_ME, TABS.WAITING_COMMON, TABS.ME].includes(this.tabIndex);

      if (this.isSearch) {
        if (TABS.BOT === this.tabIndex) {
          return 'w-185';
        }
        if (isSearchNew && isSort) {
          return 'w-140';
        }
        if (isSearchNew) {
          return 'w-165';
        }
      }

      if (isSort && isNewTicket) {
        return 'w-165';
      }

      if (this.isSearch || isNewTicket || isSort) {
        if (TABS.MONITORING === this.tabIndex || !this.isSearch) {
          return 'w-185';
        }

        return 'w-175';
      }

      return '';
    },

    showAdvance() {
      return [TABS.WAITING_ME, TABS.WAITING_COMMON, TABS.ME].includes(this.tabIndex);
    },

    showCountAdvance() {
      return [TABS.ME].includes(this.tabIndex);
    },
    showCountSearchAdvance() {
      return (
        [TABS.BOT, TABS.MONITORING, TABS.WAITING_ME, TABS.WAITING_COMMON, TABS.ME].includes(
          this.tabIndex
        ) && this.isSearch
      );
    },

    avatar() {
      return this.imageError || !this.pictureUrl ? noAvatar : this.pictureUrl;
    },

    hasMore() {
      if (this.isSearch || this.loadMoreStatus < 4) return this.loadedCount < this.total;
      return this.hasMoreConversations;
    },

    c_hasUpdateTicketCome() {
      if (this.tabIndex === TABS.BOT) {
        return false;
      } else {
        if (this.isSearch) {
          const channelStatus = `${this.id}${TICKET_TYPE_CODE.SEARCH}`;
          return this.hasUpdateTicketCome[channelStatus] || false;
        }
        return this.hasUpdateTicketCome[this.channelStatus] || false;
      }
    },

    loadingMore() {
      return this.loadingMoreMaps[this.channelStatus] || false;
    },

    syncing() {
      return this.syncTicketsMap[this.channelStatus];
    },

    /**
     * 0: botHandle
     * 1: commonWaiting
     * 2: meWaiting
     * 3: agentSupporting
     * 5: monitoring
     */
    loadMoreStatus() {
      if (this.tabIndex === TABS.MONITORING) return 5;
      return this.tabIndex;
    },

    channelStatus() {
      if (this.isSearch) return this.id + '9';
      return this.id + this.loadMoreStatus;
    },

    loadedCount() {
      if (this.visible === true) {
        if (this.tabIndex != 3 && !this.isSearch && this.c_pageNumber != 0) {
          const cvnsCnt = this.conversationsCnt > 50 ? 50 : this.conversationsCnt;
          let floor = 0;
          if (this.c_pageNumber % 5 == 0) {
            floor = Math.floor(this.c_pageNumber / 5) - 1;
          } else {
            floor = Math.floor(this.c_pageNumber / 5);
          }
          return floor * 50 + cvnsCnt;
        } else {
          return this.conversationsCnt || 0;
        }
      }
      return 0;
    },

    platformClass() {
      if (!this.platform) return 'fab';
      switch (this.platform) {
        case 'facebook':
        case 'external_facebook':
          return 'fab fa-facebook';
        case 'instagram':
          return 'fab fa-instagram';
        case 'line':
        case 'external_line':
          return 'fab fa-line';
        case 'liffchat':
        case 'webchat':
        case 'zalo':
          return 'fas fa-comment-dots';
        default:
          return 'fa-comment-alt-smile';
      }
    },

    sortKey() {
      if (!this.id) return null;
      if (this.isSearch) return this.id + '9';
      if (this.selectedGroupConv === SERVING_STATE.WAITING) {
        return this.id + '' + this.selectedGroupConv + this.waitingMode;
      }
      return this.id + '' + this.selectedGroupConv;
    },

    sortBy() {
      if (![1, 2].includes(this.selectedGroupConv) || !this.sortKey || !this.sortBysMap)
        return SORT_BY.LAST_MESSAGE; // default sort by last message time
      const sortOption = this.sortBysMap[this.sortKey];
      return (sortOption && sortOption.sortBy) || SORT_BY.LAST_MESSAGE; // default sort by last message time
    },

    sortDirection() {
      if (![1, 2].includes(this.selectedGroupConv) || !this.sortKey || !this.sortBysMap) return 0; // default 0: descending
      const sortOption = this.sortBysMap[this.sortKey];
      return sortOption && sortOption.direction > 0 ? 1 : 0; // default 0: descending
    },

    groups() {
      if (!this.channelsMap) return [];
      const { projectId } = this.channelsMap[this.id] || {};

      let cats = this.groupMaps[projectId];
      if (!cats || cats.length === 0) return [];

      const project = this.user.assignedProjects.find(p => p.id === projectId);
      if (!project) return [];

      const assignedGroups = project.assignedGroups;

      cats = cats.filter(cat =>
        assignedGroups.some(assignedGroup => assignedGroup.id === cat.id && !assignedGroup.deleted)
      );

      if (!cats || cats.length === 0) return [];
      cats.sort((a, b) => a.name.localeCompare(b.name));
      const catOpts = [
        {
          value: 'all',
          label: this.$t('src.modules.chat.components.AsideLeft.ConversationList.index.all_groups'),
          isEnable: true
        }
      ];
      cats.forEach(c => {
        catOpts.push({
          value: c.id,
          label: c.name,
          isEnable: c.isEnable
        });
      });
      return catOpts;
    },

    c_pageCount() {
      let cntAll = this.total;
      if (this.loadedCount > 0 && this.total === 0) {
        cntAll = this.loadedCount;
      } else if (this.isSearch) {
        cntAll = this.ticketGroupCountBySearchChannelMap[this.id];
      }

      if (cntAll % 10 > 0) {
        return Math.floor(cntAll / 10) + 1;
      }
      return Math.floor(cntAll / 10);
    },

    c_sortByField() {
      switch (this.sortBy) {
        case SORT_BY.LAST_MESSAGE:
          return 'lastMessageTime';
        case SORT_BY.REQUEST_TIME:
          return 'reqestTime';
        case SORT_BY.SUPPORT_TIME:
          return 'supportTime';

        default:
          return 'lastMessageTime';
      }
    },
    c_pageNumber() {
      return this.channelTabPage[this.channelStatus] || 1;
    },
    c_isLoaded() {
      const currentPage = this.channelTabPage[this.channelStatus] || 0;
      return currentPage > 0;
    },
    c_existTicket() {
      if (this.tabIndex === TABS.WAITING_COMMON || this.tabIndex === TABS.WAITING_ME) {
        if (this.hasUpdateTicketCome[this.channelStatus] === true || this.total > 0) {
          //eslint-disable-next-line
          this.$log('[C+ Debug] [NOTIFY] >> c_existTicket -> loadedCount', {
            loadedCount: this.loadedCount,
            total: this.total,
            channelId: this.id
          });
          return true;
        }

        return false;
      } else {
        return false;
      }
    },
    c_showRefresh() {
      if (this.tabIndex === TABS.BOT) return true;
      return false;
    },
    totalAgentChannel() {
      if (this.tabIndex === TABS.ME) {
        const response = this.conversationsByAgents.filter(c => {
          if (this.isSearch) {
            if (
              c.ticket &&
              c.ticket.hasOwnProperty('search') &&
              c.ticket.search !== TICKET_SEARCH_TYPE.CURRENT_TICKET &&
              c.channelId === this.id
            ) {
              return c;
            }
          } else {
            if (
              c.channelId === this.id &&
              ((c.ticket && !c.ticket.hasOwnProperty('search')) ||
                (c.ticket &&
                  c.ticket.hasOwnProperty('search') &&
                  c.ticket.search === TICKET_SEARCH_TYPE.CURRENT_TICKET))
            ) {
              return c;
            }
          }
        });
        return response.length || 0;
      }

      if (
        this.tabIndex === TABS.MONITORING ||
        this.tabIndex === TABS.WAITING_COMMON ||
        this.tabIndex === TABS.WAITING_ME ||
        this.tabIndex === TABS.BOT
      ) {
        if (this.isSearch) {
          return this.total;
        }
        return 0;
      }
      return 0;
    }
  },

  watch: {
    loadedCount(newValue) {
      switch (this.tabIndex) {
        case 0:
          break;
        case 1:
          this.setCommonWaitingLoadedCount({ channelId: this.id, count: newValue });
          break;
        case 2:
          this.setMeWaitingLoadedCount({ channelId: this.id, count: newValue });
          break;
        case 3:
          break;
        case 4:
          break;

        default:
          break;
      }
    },

    async sortBy() {
      this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
    },

    async sortDirection() {
      this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
    },

    selected(newValue) {
      this.visible = newValue;
    },

    async selectedCategory(newValue) {
      this.setSelectedCategory({
        channelStatus: this.channelStatus,
        selectedCategoryId: newValue
      });
    },

    channelTabPage() {
      let pageIndex = 1;
      switch (this.tabIndex) {
        case TABS.BOT:
          pageIndex = this.pageBot;
          break;
        case TABS.WAITING_COMMON:
          pageIndex = this.pageWattingCommon;
          break;
        case TABS.WAITING_ME:
          pageIndex = this.pageWattingMe;
          break;
        case TABS.ME:
          pageIndex = this.pageMe;
          break;
        case TABS.MONITORING:
          pageIndex = this.pageMonitoring;
          break;
        default:
          pageIndex = this.page;
          break;
      }
      if (this.channelTabPage[this.channelStatus] !== pageIndex) {
        switch (this.tabIndex) {
          case TABS.BOT:
            this.pageBot = this.channelTabPage[this.channelStatus];
            break;
          case TABS.WAITING_COMMON:
            this.pageWattingCommon = this.channelTabPage[this.channelStatus];
            break;
          case TABS.WAITING_ME:
            this.pageWattingMe = this.channelTabPage[this.channelStatus];
            break;
          case TABS.ME:
            this.pageMe = this.channelTabPage[this.channelStatus];
            break;
          case TABS.MONITORING:
            this.pageMonitoring = this.channelTabPage[this.channelStatus];
            break;
          default:
            this.page = this.channelTabPage[this.channelStatus];
            break;
        }
      }
    }
  },

  async created() {
    if (
      this.selectedCategoryChannelMap[this.channelStatus] ||
      (this.selectedCategoryChannelMap[this.channelStatus] &&
        this.selectedCategoryChannelMap[this.channelStatus] !== 'all')
    ) {
      this.selectedCategory = this.selectedCategoryChannelMap[this.channelStatus];
    }

    if (this.goToTicketCome[this.channelStatus] === true) {
      this.setGoToTicketCome({
        channelStatus: this.channelStatus,
        flag: false
      });
      await this.handleRefresh({
        tabIndex: this.tabIndex,
        channelStatus: this.channelStatus
      });
    }
  },

  async mounted() {
    switch (this.tabIndex) {
      case TABS.BOT:
        this.pageBot = this.c_pageNumber;
        break;
      case TABS.WAITING_COMMON:
        this.pageWattingCommon = this.c_pageNumber;
        break;
      case TABS.WAITING_ME:
        this.pageWattingMe = this.c_pageNumber;
        break;
      case TABS.ME:
        this.pageMe = this.c_pageNumber;
        break;
      case TABS.MONITORING:
        this.pageMonitoring = this.c_pageNumber;
        break;
      default:
        this.page = this.c_pageNumber;
        break;
    }
  },

  methods: {
    ...mapActions('session', [
      'loadMoreConversation',
      'reloadChannel',
      'getTicketsBySearch',
      'setChannelHasUpdateTicketCome',
      'setChannelTabReload',
      'setChannelTabPage',
      'setCommonWaitingLoadedCount',
      'setMeWaitingLoadedCount',
      'setTicketsSearchClear',
      'setGoToTicketCome',
      'setSelectedCategory',
      'setSelectedChannel'
    ]),
    async m_selectedGroupConvChange() {
      if (this.tabIndex !== 3) {
        await this.handleRefresh({
          tabIndex: this.tabIndex,
          channelStatus: this.channelStatus
        });
      }
    },

    handleConversationsLength({ tabIndex, channelStatus, conversationCnt }) {
      if (channelStatus === this.channelStatus && tabIndex === this.tabIndex) {
        this.conversationsCnt = conversationCnt;
      }
    },
    handleSelectedCategory({ channelStatus, selectedCategoryId }) {
      if (this.channelStatus === channelStatus) {
        this.selectedCategory = selectedCategoryId;
      }
    },

    nextDataAuto({
      projectId,
      channelId,
      status,
      page,
      oldPage,
      pagingDirection,
      nextTime,
      currentTime
    }) {
      return this.loadMoreConversation({
        projectId,
        channelId,
        status,
        page,
        oldPage,
        pagingDirection,
        nextTime,
        currentTime
      });
    },

    clickPageHandler: function(page) {
      switch (this.tabIndex) {
        case TABS.BOT:
          this.pageBot = page;
          break;
        case TABS.WAITING_COMMON:
          this.pageWattingCommon = page;
          break;
        case TABS.WAITING_ME:
          this.pageWattingMe = page;
          break;
        case TABS.ME:
          this.pageMe = page;
          break;
        case TABS.MONITORING:
          this.pageMonitoring = page;
          break;
        default:
          this.page = page;
          break;
      }
    },

    clickNextBlockHandler: function(page) {
      switch (this.tabIndex) {
        case TABS.BOT:
          this.pageBot = page;
          this.pageBotNext = true;
          break;
        case TABS.WAITING_COMMON:
          this.pageWattingCommon = page;
          this.pageWattingCommonNext = true;
          break;
        case TABS.WAITING_ME:
          this.pageWattingMe = page;
          this.pageWattingMeNext = true;
          break;
        case TABS.ME:
          this.pageMe = page;
          this.pageMeNext = true;
          break;
        case TABS.MONITORING:
          this.pageMonitoring = page;
          this.pageMonitoringNext = true;
          break;
        default:
          this.page = page;
          break;
      }
    },

    clickPrevBlockHandler: async function(page) {
      switch (this.tabIndex) {
        case TABS.BOT:
          this.pageBot = page;
          break;
        case TABS.WAITING_COMMON:
          this.pageWattingCommon = page;
          break;
        case TABS.WAITING_ME:
          this.pageWattingMe = page;
          break;
        case TABS.ME:
          this.pageMe = page;
          break;
        case TABS.MONITORING:
          this.pageMonitoring = page;
          break;
        default:
          this.page = page;
          break;
      }
    },

    imageLoadError() {
      // eslint-disable-next-line
      console.log('Show image error: ', this.avatar);
      this.imageError = true;
    },

    handleOnConversationClick(conversation) {
      this.$emit('onSelectConversation', conversation);
    },

    async handleOnLoadMoreConvs(params) {
      await this.handelLoadMoreConvs(params);
    },

    async autoLoadMore(channelId) {
      this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
      this.setChannelHasUpdateTicketCome({
        channelStatus: this.channelStatus,
        flag: false
      });
      this.handelLoadMoreConvs({ channelId, refresh: true });
    },

    handelLoadMoreConvs({
      channelId,
      pagingDirection,
      page = 1,
      oldPage = 1,
      nextTime,
      currentTime,
      refresh = false,
      selectedCategory = 'all'
    }) {
      if (this.isSearch) {
        return this.handleLoadMoreSearchResult(channelId);
      }
      const { projectId } = this.channelsMap[channelId] || {};
      if (!projectId) return;
      let status = this.loadMoreStatus;
      const _this = this;

      /**
       * 0: botHandle
       * 1: commonWaiting
       * 2: meWaiting
       * 3: agentSupporting
       * 5: monitoring
       */
      this.refreshing = true;

      return this.loadMoreConversation({
        projectId,
        channelId,
        status,
        page,
        oldPage,
        pagingDirection,
        nextTime,
        currentTime,
        refresh,
        selectedCategory
      })
        .then(count => {
          _this.already = true;
          _this.hasMoreConversations = count > 9;
          this.refreshing = false;
          let isPageNext = false;
          let pageNext = 1;
          switch (this.tabIndex) {
            case TABS.BOT:
              isPageNext = this.pageBotNext;
              pageNext = this.pageBot;
              break;
            case TABS.WAITING_COMMON:
              isPageNext = this.pageWattingCommonNext;
              pageNext = this.pageWattingCommon;
              break;
            case TABS.WAITING_ME:
              isPageNext = this.pageWattingMeNext;
              pageNext = this.pageWattingMe;
              break;
            case TABS.ME:
              isPageNext = this.pageMeNext;
              pageNext = this.pageMe;
              break;
            case TABS.MONITORING:
              isPageNext = this.pageMonitoringNext;
              pageNext = this.pageMonitoring;
              break;
          }
          if (isPageNext === true) {
            if (count > 0) {
              this.setChannelTabPage({
                channelStatus: this.channelStatus,
                page: pageNext
              });

              switch (this.tabIndex) {
                case TABS.BOT:
                  this.pageBotNext = false;
                  break;
                case TABS.WAITING_COMMON:
                  this.pageWattingCommonNext = false;
                  break;
                case TABS.WAITING_ME:
                  this.pageWattingMeNext = false;
                  break;
                case TABS.ME:
                  this.pageMeNext = false;
                  break;
                case TABS.MONITORING:
                  this.pageMonitoringNext = false;
                  break;
              }
            }
          }
          return count;
        })
        .catch(error => {
          this.refreshing = false;
          _this.$log('[ConversationList] >> [handleLoadMoreConvs] >> error: ', error);
          return 0;
        });
    },

    async handleLoadMoreSearchResult(channelId) {
      const params = {};
      params.conditions = this.searchBoxFilter;
      params.from = this.ticketsNextFromChannelMap[channelId] || 0;
      params.size = SEARCH_FILTER.LOAD_MORE_TICKET_QTY;
      params.channelId = channelId;
      params.tab = this.selectedGroupConv;
      params.waitingMode = this.waitingMode;
      params.agentId = this.user.id;
      params.groupId = this.selectedCategory;
      await this.getTicketsBySearch(params);
    },
    async handleReloadChannelIsSearch(channelId) {
      this.refreshing = true;
      this.setTicketsSearchClear({ channelId });
      const params = {};
      params.conditions = this.searchBoxFilter;
      params.from = 0;
      params.size = SEARCH_FILTER.FIRST_LOAD_TICKET_QTY;
      params.channelId = channelId;
      params.tab = this.selectedGroupConv;
      params.waitingMode = this.waitingMode;
      params.agentId = this.user.id;
      params.groupId = this.selectedCategory;
      await this.getTicketsBySearch(params);
      this.setChannelHasUpdateTicketCome({
        channelStatus: channelId + TICKET_TYPE_CODE.SEARCH,
        flag: false
      });
      this.refreshing = false;
    },

    toggleVisible(visible) {
      // Set Selected Channel
      this.setSelectedChannel(this.id);

      if (
        visible === true &&
        this.isSearch === false &&
        (this.c_isLoaded === false ||
          ([TABS.WAITING_COMMON, TABS.WAITING_ME, TABS.MONITORING].includes(this.tabIndex) &&
            this.c_hasUpdateTicketCome) ||
          (this.tabIndex === TABS.BOT && !this.refreshing))
      ) {
        this.autoLoadMore(this.id); // id => channelId
      }

      if (this.sortEvent) {
        this.sortEvent = false;
        return;
      }
      if (this.refreshing) {
        this.visible = true;
        return;
      }
      this.visible = visible;
    },

    handleSort(e) {
      e.preventDefault();
      this.sortEvent = true;
    },

    async handleRefresh({ tabIndex, channelStatus }) {
      let _channelStatus = channelStatus;
      this.refreshing = true;
      let limit = 100;
      if (!channelStatus) {
        if (this.isSearch) {
          _channelStatus = this.id + '9';
        } else {
          _channelStatus = this.id + this.loadMoreStatus;
        }
      }

      await this.setChannelTabPage({ channelStatus: _channelStatus, page: 1 });
      switch (tabIndex) {
        case 0:
          this.setTicketsSearchClear(this.id);
          this.reloadChannel({
            channelId: this.id,
            status: 0,
            limit,
            selectedCategory: this.selectedCategory
          }).then(async () => {
            if (this.isSearch) {
              this.handleReloadChannelIsSearch(this.id)
                .then(() => {
                  this.refreshing = false;
                })
                .catch(() => {
                  this.refreshing = false;
                });
            } else {
              this.reloadChannel({
                channelId: this.id,
                status: !this.isSearch ? 0 : 9,
                limit,
                selectedCategory: this.selectedCategory
              })
                .then(async () => {
                  this.setChannelHasUpdateTicketCome({
                    channelStatus: _channelStatus,
                    flag: false
                  });
                  this.refreshing = false;
                })
                .catch(() => (this.refreshing = false));
            }
          });
          break;
        case 1:
          this.reloadChannel({
            channelId: this.id,
            status: !this.isSearch ? 1 : 9,
            limit,
            selectedCategory: this.selectedCategory
          })
            .then(async () => {
              this.setChannelHasUpdateTicketCome({
                channelStatus: _channelStatus,
                flag: false
              });
              if (this.isSearch) {
                await this.handleReloadChannelIsSearch(this.id);
              }
              this.refreshing = false;
            })
            .catch(() => (this.refreshing = false));
          break;
        case 2:
          this.reloadChannel({
            channelId: this.id,
            status: !this.isSearch ? 2 : 9,
            limit,
            selectedCategory: this.selectedCategory
          })
            .then(async () => {
              this.setChannelHasUpdateTicketCome({
                channelStatus: _channelStatus,
                flag: false
              });
              if (this.isSearch) {
                await this.handleReloadChannelIsSearch(this.id);
              }
              this.refreshing = false;
            })
            .catch(() => (this.refreshing = false));
          break;
        case 3:
          this.reloadChannel({
            channelId: this.id,
            status: !this.isSearch ? 3 : 9,
            limit,
            selectedCategory: this.selectedCategory
          })
            .then(async () => {
              this.setChannelHasUpdateTicketCome({
                channelStatus: _channelStatus,
                flag: false
              });
              if (this.isSearch) {
                await this.handleReloadChannelIsSearch(this.id);
              }
              this.refreshing = false;
            })
            .catch(() => (this.refreshing = false));
          break;
        case 4:
          this.reloadChannel({
            channelId: this.id,
            status: !this.isSearch ? 5 : 9,
            limit,
            selectedCategory: this.selectedCategory
          })
            .then(async () => {
              this.setChannelHasUpdateTicketCome({
                channelStatus: _channelStatus,
                flag: false
              });
              if (this.isSearch) {
                await this.handleReloadChannelIsSearch(this.id);
              }
              this.refreshing = false;
            })
            .catch(() => (this.refreshing = false));
          break;
        default:
          //eslint-disable-next-line
          console.log('[C+ Debug] [ERROR] handleRefresh -> Error tabIndex', this.tabIndex);
          this.reloadChannel({
            channelId: this.id,
            status: !this.isSearch ? 0 : 9,
            limit,
            selectedCategory: this.selectedCategory
          })
            .then(() => (this.refreshing = false))
            .catch(() => (this.refreshing = false));
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.vue-recycle-scroller.fix.direction-vertical:not(.page-mode) {
  overflow: visible;
  .vue-recycle-scroller__item-wrapper {
    overflow: visible;
  }
}

.scroller {
  max-height: 1400px;
}
.li-load-more {
  text-align: center;
}
nav.navbar {
  cursor: pointer;
}

.channel-header {
  display: inline-block;
  width: 100%;
  .avatar-xs {
    width: 30px;
    height: 30px;
  }

  .avatar {
    .badge {
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 8px;
    }
  }
}

.h--0 {
  height: 0 !important;
}

.h--100vh {
  height: 100% !important;
}

ul.list-group {
  overflow: visible;
  transition: all 0.1s ease;
  -moz-transition: all 0.1s ease; /* Firefox 4 */
  -webkit-transition: all 0.1s ease; /* Safari and Chrome */
  -o-transition: all 0.1s ease; /* Opera */
}
.load-more {
  margin-right: auto;
  margin-left: auto;
  border: 0px;
  text-transform: capitalize;
}
.btnIco {
  margin: 3%;
}
.ico {
  width: 3.5%;
}
.fab.fa-facebook {
  color: white;
}
.fab.fa-line {
  color: #3ac521;
}
.fab.fa-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fab.fa-comment-alt-smile {
  color: #f1aa26;
}
.fas.fa-comment-dots {
  color: #2196f3;
  margin-left: 4px;
  margin-right: 4px;
}
.couter-badge {
  position: absolute;
  right: 40px;
  top: 3px;
  background-color: #ffeeee70;
}

.w-185 {
  margin-right: 0;
  max-width: 185px;
}
.w-175 {
  margin-right: 0;
  max-width: 175px;
}
.w-165 {
  margin-right: 0;
  max-width: 165px;
}
.w-140 {
  margin-right: 0;
  max-width: 140px;
}

.channel-icon-group {
  position: absolute;
  right: 0;
  flex-direction: row-reverse;

  > i,
  > span,
  > .el-dropdown {
    position: relative;
    margin: 0 5px;
    top: inherit;
    left: inherit;
    right: inherit;
  }

  .fa-sort-amount-down {
    order: 2;
  }

  .fa-sync-alt,
  .fa-spinner {
    order: 5;
  }

  .toggle {
    order: 1;
  }

  .platform {
    order: 3;
  }

  .couter-badge {
    order: 4;
  }
}
</style>
