<template>
  <ValidationObserver 
    id="CSPContactInformation"
    ref="CSPContactInformation"
  >
    <form role="form" class="row" autocomplete="nope">
      <div
        v-for="(c, idx, index) in filterComponents"
        :key="`contact_info_${idx}`"
        :class="isLabel(c.type)"
      >
        <!-- Title (S) -->
        <div v-if="c.type === 'label'" class="form-group">
          <label
            :class="[
              'text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold',
              index !== 0 ? 'm-t-40' : ''
            ]"
          >
            <i class="fas fa-grip-vertical m-r-10"></i>
            {{ c.label }}
          </label>
        </div>
        <!-- Title (E) -->

        <!-- Component (S) -->
        <div v-if="c.type !== 'label'">
          <div
            v-if="
              c.type === 'text' ||
              c.type === 'combo' ||
              c.type === 'textarea' ||
              c.type === 'date' ||
              c.type === 'checkbox' ||
              c.type === 'radio'
            "
            class="form-group"
          >
            <base-input
              :prefix="'cntInfo'"
              v-if="c.type === 'text' && !c.isCustomize"
              v-model="formData[c.prop]"
              :required="c.required"
              :maxlength="c.maxlength"
              :rules="c.rules"
              :name="c.prop"
              :placeholder="c.placeholder"
              :label="c.label"
              :hint-text="c.hintText"
              :disabled="disabledForm || c.disabled"
              :autocomplete="'nope'"
              :ignore-validate="c.ignoreValidate"
              @change="onChangeData()"
            />

            <base-input
              :prefix="'cntInfo'"
              v-if="c.type === 'text' && c.isCustomize"
              v-model="formData[c.prop]"
              :required="c.required"
              :maxlength="c.maxlength"
              :rules="c.rules"
              :name="c.prop"
              :placeholder="c.placeholder"
              :label="c.label"
              :hint-text="c.hintText"
              :disabled="disabledForm || c.disabled"
              :autocomplete="'nope'"
              :ignore-validate="c.ignoreValidate"
              @change="onChangeData()"
            />

            <base-textarea
              :prefix="'cntInfo'"
              v-if="c.type === 'textarea' && c.isCustomize"
              v-model="formData[c.prop]"
              :required="c.required"
              :maxlength="c.maxlength"
              :rules="c.rules"
              :name="c.prop"
              :placeholder="c.placeholder"
              :label="c.label"
              :disabled="disabledForm"
              @change="onChangeData()"
            />

            <base-textarea
              :prefix="'cntInfo'"
              v-if="c.type === 'textarea' && !c.isCustomize"
              v-model="formData[c.prop]"
              :required="c.required"
              :maxlength="c.maxlength"
              :rules="c.rules"
              :name="c.prop"
              :placeholder="c.placeholder"
              :label="c.label"
              :disabled="disabledForm"
              @change="onChangeData()"
            />

            <base-date-picker
              :prefix="'cntInfo'"
              v-if="c.type === 'date'"
              v-model="formData[c.prop]"
              :required="c.required"
              :rules="c.rules"
              :name="c.prop"
              :label="c.label"
              :disabled="disabledForm"
              @change="onChangeData()"
            />

            <base-checkbox
              :prefix="'cntInfo'"
              v-if="c.type === 'checkbox'"
              v-model="formData[c.prop]"
              :disabled="disabledForm || c.disabled"
              :ignore-validate="c.ignoreValidate"
              @change="onChangeData()"
              :name="c.prop"
              :id="c.prop"
              :label="c.label"
              :required="c.required"
            />

            <base-select
              :prefix="'cntInfo'"
              v-if="c.type === 'combo'"
              v-model="formData[c.prop]"
              :options="optionsData(c.prop)"
              :name="c.prop"
              :id="c.prop"
              :label="c.label"
              :required="c.required"
              :option-lable="'value'"
              :disabled="disabledForm"
              @change="changeCombo(c, $event)"
            />

            <base-radio-group
              :ref="c.prop === 'gender' ? c.prop : ''"
              :prefix="'cntInfo'"
              v-if="c.type === 'radio'"
              :label="c.label"
              v-model="formData[c.prop]"
              :name="c.prop"
              :options="optionsData(c.prop)"
              :option-lable="'value'"
              :option-value="'key'"
              :disabled="disabledForm"
              :max-other-value="100"
              :allow-show-word-limit="true"
              :err-mess-other-value="
                c.prop === 'gender'
                  ? $t('src.modules.profile.components.user-information.please_enter_your_gender')
                  : ''
              "
              :required="c.required"
              :radio-other-value-error="radioOtherValueError"
              :full-width-radio="c.prop === 'gender' ? true : false"
              @inputOtherValue="inputOtherValue"
            />
          </div>
        </div>
        <!-- Component (E) -->
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { clone } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import 'components/BaseComponents/vee-validate.js';
import { GENDER, MARITAL_STATUS, PERSONAL_OPTIONS } from 'core/constants';

export default {
  props: {
    components: {
      type: Object,
      default: () => {}
    },
    channelId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      submitFormLoading: false,
      formData: {},
      originalFormDataStr: {},
      countries: [],
      provinces: [],
      ready2EmitChange: false,
      validPhoneNumber: true,
      validWorkPhoneNumber: true,
      invalidPhoneErrorMsg: this.$t(
        'src.modules.profile.components.user-information.please_input_the_contact_number'
      ),
      editSubmittedForm: false,
      radioOtherValueError: false
    };
  },

  computed: {
    ...mapState('personal', [
      'personalInfo',
      'personalGlobal',
      'accessToken',
      'defaultLanguage',
      'userInfo',
      'supportLanguages',
      'personalFormOption'
    ]),
    ...mapState('customerSupport', ['openCSFMode']),
    ...mapState('global', ['ready']),
    ...mapState('session', ['channelsMap']),
    ...mapGetters('session', ['isPhoneAgent']),

    disabledForm() {
      if (this.openCSFMode === 'view' && !this.editSubmittedForm) return true;
      if (this.isPhoneAgent) return true;
      return false;
    },
    filterComponents() {
      if (!this.components) return [];

      return Object.keys(this.components)
        .map(key => this.components[key])
        .filter(c => {
          return c.show === true;
        });
    },
    getLocale() {
      const { locale: language } = this.userInfo;
      let locale = '';
      if (language) locale = language.split('_')[0] + '';
      const defaultLanguage = this.defaultLanguage || 'en_US';
      let defaultLocale = '';
      if (defaultLanguage) defaultLocale = defaultLanguage.split('_')[0] + '';

      let retLocale = 'en';
      if (this.supportLanguages && this.supportLanguages.includes(language)) {
        retLocale = locale || defaultLocale || 'en';
      } else {
        retLocale = defaultLocale || 'en';
      }
      return retLocale;
    },

    displayLanguage() {
      const channel = this.channelsMap[this.channelId] || {};
      const { advancedConfigs: { supportLanguages = [] } = {}, defaultLanguage = 'en_US' } = channel;
      const lang = localStorage.getItem('language');
      const operationLanguage = lang.replace('-', '_');
      return supportLanguages.includes(operationLanguage) ? operationLanguage : defaultLanguage;
    }
  },

  watch: {
    async personalInfo(data) {
      const { name = '' } = data;
      this.formData = {
        name: name || '',
        ...data
      };

      Object.keys(this.components).forEach(cKey => {
        if (this.components[cKey].type === 'text' && !this.formData[cKey]) {
          this.formData[cKey] = '';
        }
      });

      if (
        typeof this.formData.country === 'string' ||
        !this.formData.country ||
        this.formData.country === '' ||
        Object.keys(this.formData.country).length == 0
      ) {
        this.formData.country = null;
      } else {
        const provinces = await this.getProvinces({
          code: this.formData.country.key,
          language: this.displayLanguage
        });
        this.provinces = provinces.map(province => {
          return { key: province.code, value: province.name };
        });
      }
      if (
        typeof this.formData.province === 'string' ||
        !this.formData.province ||
        this.formData.province === '' ||
        Object.keys(this.formData.province).length === 0
      ) {
        this.formData.province = null;
      }
      // Set original form data
      this.originalFormDataStr = JSON.stringify(clone({ ...this.formData }));
    }
  },

  async created() {},

  async mounted() {
    const countries = await this.getCountries({ language: this.displayLanguage });
    this.countries = countries.map(country => {
      return { key: country.code, value: country.name };
    });
    this.optionsData('country');
    EventBus.$on('editSubmittedFormMode', value => {
      this.editSubmittedForm = value;
    });
  },

  updated() {
    this.ready2EmitChange = true;
  },

  beforeDestroy() {
    EventBus.$off('editSubmittedFormMode');
  },
  methods: {
    ...mapActions('personal', ['setPersonalGlobal']),
    ...mapActions('customerSupport', ['getCountries', 'getProvinces']),

    onChangeData() {
      if (this.ready2EmitChange) {
        const changedStatus = this.originalFormDataStr !== JSON.stringify(this.formData);
        this.$emit('changeContactInfo', changedStatus);
      }
    },

    isLabel(type) {
      if (type === 'label' || type === 'checkbox') {
        return 'col-12'; //col-12 pull-left
      }
      return 'col-6'; //col-6 pull-left
    },

    async handleSubmitForm() {
      const { gender } = this.formData;
      const contactInformationForm = this.$refs['CSPContactInformation'];

      contactInformationForm && contactInformationForm.validate().then(result => {
          if (!result || (gender && gender.key === 'other' && !gender.value)) {
            setTimeout(async () => {
              const fields = contactInformationForm.fields;
              const { msgUnFill, msgInvalid } = await this.handleErrorMessage(fields);
              this.$emit('invalid', { msgUnFill, msgInvalid });
              EventBus.$emit('expandProfileCSP');
            }, 100);
          } else {
            // Validation is valid
            let submitData = {};
            Object.keys(this.components).forEach(cKey => {
              if (this.components[cKey].isCustomize && this.components[cKey].type !== 'label') {
                let val = this.formData[cKey] || '';
                if (this.components[cKey].type === 'checkbox') {
                  val = this.formData[cKey] || false;
                }
                submitData.customizeInfo = {
                  ...submitData.customizeInfo,
                  ...{ [cKey]: val }
                };
              } else if (
                this.components[cKey].type !== 'label' &&
                !this.components[cKey].isCustomize
              ) {
                let val = this.formData[cKey];
                if (this.components[cKey].type === 'text') {
                  val = this.formData[cKey] || '';
                }
                if (this.components[cKey].type === 'combo') {
                  val = this.formData[cKey] || null;
                }
                if (this.components[cKey].type === 'checkbox') {
                  val = this.formData[cKey] || false;
                }
                submitData = {
                  ...submitData,
                  ...{ [cKey]: val }
                };
              }
            });
            if (!submitData.customizeInfo) {
              submitData.customizeInfo = '';
            }
            if (submitData.gender && submitData.gender.key === 'other') {
              delete submitData.gender.title;
            } else if (submitData.gender && submitData.gender.key !== 'other') {
              this.$refs['gender'] ? this.$refs['gender'][0].otherValue = '' : null;
            }
            console.log('🚀 ~ file: contactInformationForm.vue ~ line 412 ~ this.$refs.CSPContactInformation.validate ~ submitData', submitData)
            this.$emit('submit', submitData);
          }
        });
    },
    optionsData(key) {
      if (key === 'country') {
        return this.countries;
      }
      if (key === 'province') {
        return this.provinces;
      }
      if (key === 'gender') {
        return Object.keys(GENDER).map(key =>
          PERSONAL_OPTIONS[this.displayLanguage]
            ? key !== 'other'
              ? { key, value: PERSONAL_OPTIONS[this.displayLanguage][key] }
              : { key, value: '', title: PERSONAL_OPTIONS[this.displayLanguage][key] }
            : {}
        );
      }
      if (key === 'maritalStatus') {
        return Object.keys(MARITAL_STATUS).map(key =>
          PERSONAL_OPTIONS[this.displayLanguage]
            ? { key, value: PERSONAL_OPTIONS[this.displayLanguage][key] }
            : {}
        );
      }
      return [];
    },
    inputOtherValue(value) {
      const { gender } = this.formData;
      if (gender.key !== 'other') return (this.radioOtherValueError = true);
      this.radioOtherValueError = false;
      gender.value = value;
    },
    async changeCombo(cpn, objValue) {
      const { key } = objValue;
      const { prop } = cpn;
      if (prop === 'country') {
        this.formData = { ...this.formData, ...{ province: '' } };
        if (key) {
          const provinces = await this.getProvinces({ code: key, language: this.displayLanguage });
          this.provinces = provinces.map(province => {
            return { key: province.code, value: province.name };
          });
        } else {
          this.provinces = [];
        }
      }
      this.onChangeData();
    },
    handleRequestInformation() {
      this.$emit('onSeclectRequestInformation');
    },
    handleErrorMessage(fields) {
      const fieldInvalid = [];
      const fieldUnFill = [];
      const errUnFill = Object.values(fields).filter(
        c =>
          c.required &&
          c.failedRules &&
          (c.failedRules.baseSelectRequired ||
            c.failedRules.baseRequired ||
            c.failedRules.baseCheckRequired)
      );
      const errInvalid = Object.values(fields).filter(
        c =>
          c.failedRules &&
          (c.failedRules.baseEmail || c.failedRules.basePhoneNumber || c.failedRules.basePostCode)
      );
      errInvalid.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.indexOf('-'));
        if (this.components[cKey]) label = this.components[cKey].label;
        fieldInvalid.push(`[${label}]`);
      });
      errUnFill.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.indexOf('-'));
        if (this.components[cKey]) label = this.components[cKey].label;
        fieldUnFill.push(`[${label}]`);
      });

      const { gender } = this.formData;
      if (gender && gender.key === 'other' && !gender.value) {
        this.radioOtherValueError = true;
        fieldUnFill.push('[Gender]');
      }

      const paramsUnFill =
        fieldUnFill.length <= 2 ? fieldUnFill.join() : `${fieldUnFill.slice(0, 2).join()} ...`;
      const msgUnFill = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.profile.missing_fulfil_required_fields',
        { fields: paramsUnFill }
      );
      const paramsInvalid =
        fieldInvalid.length <= 2 ? fieldInvalid.join() : `${fieldInvalid.slice(0, 2).join()} ...`;
      const msgInvalid = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.profile.wrong_input_fields',
        { fields: paramsInvalid }
      );

      return fieldUnFill.length > 0 && fieldInvalid.length > 0
        ? { msgUnFill, msgInvalid }
        : fieldUnFill.length > 0
        ? { msgUnFill }
        : { msgInvalid };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/cs/form.scss';
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
/deep/ .vdp-datepicker {
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    line-height: 1.5;
    color: #8898aa;
    border: 0px;
    height: 100%;
    width: 100%;
  }
}

/deep/ .v-select {
  display: -webkit-box;
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    &:after {
      content: none;
    }
    .selected-tag {
      color: #8898aa;
      display: block;
      width: calc(100% - 50px);
      width: -webkit-calc(100% - 50px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.single .selected-tag {
    margin-top: 0;
  }
  .dropdown-menu {
    margin-top: 2px;
    padding: 0;
    li {
      a {
        padding: 10px;
        min-height: 23px;
        color: #8898aa;
        font-size: 0.875rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }
    .highlight {
      a {
        background: #4bd;
        color: #ffffff;
      }
    }
  }
  input {
    margin: -10px 0 0;
    width: 100%;
    height: 100%;
  }
  .no-options {
    display: none;
  }
}
.invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

small {
  font-size: 100% !important;
}
.invalid-data {
  font-size: 100% !important;
}
</style>
