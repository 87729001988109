<template>
  <div class="profile-page container-fluid p-0 page">
    <AppLeftMenu show-name />
    <AppFooter />
    <main class="profile-page wrapper-content">
      <AppCover />
      <div class="page-sub">
        <section class="section section-skew">
          <div class="container">
            <div class="card card-profile shadow mt-300">
              <div class="px-4">
                <div class="row justify-content-center">
                  <div class="col-lg-4 order-lg-2">
                    <div class="card-profile-image">
                      <a href="#">
                        <img
                          :src="avatar"
                          class="rounded-circle"
                          @error="imageLoadError"
                        />
                      </a>
                      <div
                        v-if="profileType === 'userInformation'"
                        class="img-avatar-layer"
                        @click="showGoogleDrive"
                      >
                        <p class="img-avatar">
                          {{ $t('src.modules.profile.index.change_avatar') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                    <div class="card-profile-actions py-4 mt-lg-0">
                      <button
                        v-if="profileType === 'userInformation'"
                        class="btn btn-sm btn-default float-right"
                        @click="goChangePassword"
                      >
                        {{ $t('src.modules.profile.index.change_password') }}
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-1"></div>
                </div>
                <div class="text-center mt-100">
                  <h3>{{ fullName }}</h3>
                  <h6>{{ mappingRole[user.role] }}</h6>
                </div>
                <div class="mt-5 py-5 border-top">
                  <user-information
                    v-if="profileType === 'userInformation'"
                    :picture-url="imageUrl"
                  />
                  <setting v-if="profileType === 'setting'" :picture-url="imageUrl" />
                  <div class="row justify-content-center">
                    <div class="col-lg-9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <input
          id="inputFileProfile"
          type="file"
          hidden
          accept="image/*"
          @change="handleFileUpload"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import noAvatar from 'assets/images/no-avatar.png';
import loadingImg from 'assets/images/loader_seq.gif';
import fireStorage from 'core/firebase/storage';
import UserInformation from './components/user-information';
import Setting from './components/setting';
import { MAPPING_ROLE } from 'core/constants';
const PROFILE_TYPE = ['setting', 'userInformation'];

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    UserInformation,
    Setting
  },

  data() {
    return {
      imageUrl: noAvatar,
      mappingRole: MAPPING_ROLE || {}
    };
  },

  computed: {
    ...mapState('session', ['user']),

    fullName() {
      return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    },
    avatar() {
      return this.imageUrl;
    },
    profileType() {
      return (this.$route.params && this.$route.params.type) || '';
    }
  },
  mounted() {
    if (!PROFILE_TYPE.includes(this.profileType)) {
      this.$router.push('/');
    }
  },
  async created() {
    const user = await this.getSessionUser();
    if (!user) {
      return;
    }
    this.imageUrl = this.user?.pictureUrl || noAvatar;
  },

  methods: {
    ...mapActions('session', ['getSessionUser']),

    imageLoadError() {
      return (this.imageUrl = noAvatar);
    },

    goChangePassword() {
      this.$router.push({ path: '/password' });
    },

    showGoogleDrive() {
      const fileInput = document.getElementById('inputFileProfile');
      fileInput.click();
    },

    handleFileUpload(event) {
      let { files } = event && event.target;
      if (files && files.length > 0) {
        this.imageUrl = loadingImg;
        fireStorage
          .upload(files[0], `avatar/${this.user.id}/${files[0].name}`)
          .then(url => {
            this.imageUrl = url;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log('[profile] > [fileUpload] > error', error);
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: error.message || 'Upload file error - Unknown exception.'
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  overflow: auto;
  height: calc(100vh - 0px);
  padding-bottom: 30px;
}
</style>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';

.mr-10 {
  margin-right: 10px;
}
.mt-100 {
  margin-top: -60px;
}

.card-profile-image {
  position: relative;
  margin: auto;
  height: 200px;
  width: 257px;
}
.card-profile-image {
  .rounded-circle {
    min-width: 180px !important ;
    min-height: 180px !important;
    max-height: 180px !important;
    max-width: 180px !important ;
  }
  img {
    cursor: default;
  }
}

.img-avatar-layer {
  position: absolute;
  top: -54px;
  bottom: 0;
  left: 38px;
  right: 0;
  background: rgba(36, 62, 206, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.card-profile-image:hover .img-avatar-layer {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.img-avatar {
  transition: 0.2s;
  transform: translateY(1em);
}

.card-profile-image:hover .img-avatar {
  transform: translateY(0);
}

.btnHistory button {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}

.pageHistory {
  overflow-y: initial !important;
  position: fixed !important;
}

.footerHistory {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.page-cover {
  height: 450px !important;
  background-size: cover !important;
  background-position: center center !important;
}

.profile-page {
  z-index: 0;
}

.profile-page .footer {
}
</style>
