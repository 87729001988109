<template>
  <div v-loading="loading" :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper cs-form">
      <div class="card-header">
        <panel-tools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="closePanel"
        />
        <h3 class="card-title mb-0">
          <span>
            {{ $t('src.modules.chat.components.SurveyFormPanel.index.survey_form') }}
          </span>
        </h3>
      </div>
      <div class="card-body">
        <survey-table
          :data="surveyDatas"
          :item-selected="itemSelected"
          :questions="questionAndAnswers"
          @onChangeSurveyForm="changeSurveyForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import PanelTools from 'modules/chat/components/PanelTools';
import SurveyTable from './SurveyTable';

export default {
  components: {
    PanelTools,
    SurveyTable
  },
  props: {
    conversation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pinned: false,
      itemSelected: {},
      selectedTicket: {},
      language: '',
      surveyDatas: [],
      questionAndAnswers: [],
      loading: true
    };
  },
  computed: {
    ...mapState('global', ['rightCustomerSurveyPanelPinned']),
    ...mapGetters('chat', ['selectedConversation']),

    shadowClass() {
      return this.rightCustomerSurveyPanelPinned ? 'shadow-sm' : 'shadow-lg unpinned full-width';
    },

    pinPanelStatus() {
      const pinStatus = this.rightCustomerSurveyPanelPinned || this.pinned;
      return pinStatus;
    }
  },

  watch: {
    conversation(newValue) {
      if (!newValue || isEmpty(newValue)) {
        this.$emit('hideAllPanel');
      }
      const { ticketId } = this.selectedConversation || {};
      const { id: oldSelectedTicketId } = this.selectedTicket || {};
      if (ticketId !== oldSelectedTicketId) {
        this.reset();
        this.load();
      }
    }
  },

  mounted() {
    this.load();
  },

  methods: {
    ...mapActions('chat', ['getSurveyDataAndTeamplateByTicket']),
    ...mapActions('global', ['pinRightCustomerSurveyPanel']),

    reset() {
      this.itemSelected = {};
      this.surveyDatas = [];
      this.questionAndAnswers = [];
    },

    async load() {
      const operationLanguage = localStorage.getItem('language');
      this.language = operationLanguage.replace('-', '_');
      const { ticketId = '', ticket = {} } = this.selectedConversation || {};
      this.selectedTicket = ticket;

      const data = await this.getSurveyDataAndTeamplateByTicket({
        ticketId,
        language: this.language
      });
      if (data && data.length > 0) {
        const list = data.map(val => {
          return {
            ...val,
            label: val.name,
            value: val.id
          };
        });
        this.surveyDatas = list;
        this.itemSelected = list[0];
        this.questionAndAnswers = list[0].questions;
      }
      this.loading = false;
    },

    changeSurveyForm(val = {}) {
      this.itemSelected = val;
      this.questionAndAnswers = val.questions;
    },

    closePanel() {
      this.$emit('onSupportItemClick', false);
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.pinRightCustomerSurveyPanel(this.pinned);
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  height: calc(100vh - 110px) !important;
  overflow: auto;
}
.draggable > .card > .card-body {
  overflow: auto;
}
.fullScreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
}
.draggable {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  .dragHeader > .card-header:first-child {
    padding: 10px;
    background-color: #2b2a3c;
    cursor: grabbing;
    h6,
    .close {
      color: #fff;
      font-weight: 600;
    }
  }
  .card-header {
    padding: 0.8rem 1.5rem;
  }
  .card-body {
    padding: 0.5rem;
  }
}
</style>
