import fetchAPI from './fetchAPI';

const fetch = new fetchAPI({
  baseURL: `${process.env.VUE_APP_LIFFCHAT_API_URL}/`,
  headers: {
    'x-api-key': process.env.VUE_APP_LIFFCHAT_API_KEY
  }
});

export default fetch;
