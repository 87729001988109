<template>
  <div class="conversation-feed-item">
    <div v-b-toggle="id" :class="['conversation-feed-item-question']">
      <i :class="[collapseIcon, 'toggle-icon']">{{ ' ' + question.trim() }}</i>
    </div>
    <b-collapse :id="id" @show="collapsed = false" @hide="collapsed = true">
      <template v-if="listAnswer.length > 0">
        <div
          v-for="answer in listAnswer"
          :key="answer + '-' + $uuid.v4()"
          class="conversation-feed-item-answer"
        >
          <div class="faq_item">
            <drag
              :transfer-data="{ answer }"
              class="drag"
              :style="{
                width: 'calc(100% - 55px)'
              }"
            >
              <b-card bg-variant="white">
                <span>{{ answer }}</span>
              </b-card>
            </drag>
            <b-button
              v-b-tooltip.hover
              :title="
                $t('src.modules.chat.components.ConversationalPanel.FAQ.modify_and_send')
              "
              class="custom-btn-css"
              variant="outline-secondary"
              @click="openFAQTemplateBox({ answer, id, question })"
            >
              <i class="fas fa-edit" style="font-size: 0.875rem" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('common.text.send')"
              class="custom-btn-css"
              variant="outline-secondary"
              @click="sendFaqMessage({ answer, id })"
            >
              <i class="fas fa-paper-plane" style="font-size: 0.875rem" />
            </b-button>
          </div>
        </div>
      </template>
      <template v-else-if="listDefaultAnswer.length > 0">
        <div
          v-for="defaultAnswer in listDefaultAnswer"
          :key="defaultAnswer + '-' + $uuid.v4()"
          class="conversation-feed-item-answer"
        >
          <div class="faq_item">
            <drag
              :transfer-data="{ defaultAnswer }"
              class="drag"
              :style="{
                width: 'calc(100% - 55px)'
              }"
            >
              <b-card bg-variant="white">
                <span>{{ defaultAnswer }}</span>
              </b-card>
            </drag>
            <b-button
              v-b-tooltip.hover
              :title="
                $t('src.modules.chat.components.ConversationalPanel.FAQ.modify_and_send')
              "
              class="custom-btn-css"
              variant="outline-secondary"
              @click="setAgentChatMessage(defaultAnswer)"
            >
              <i class="fas fa-edit" style="font-size: 0.875rem" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('common.text.send')"
              class="custom-btn-css"
              variant="outline-secondary"
              @click="sendFaqMessage({ defaultAnswer, id })"
            >
              <i class="fas fa-paper-plane" style="font-size: 0.875rem" />
            </b-button>
          </div>
        </div>
      </template>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { getURLsFromText, getLinkPreview, buildLinkReviewMessage } from 'core/helpers';
import { MessageType } from 'core/message';
import { EventBus } from 'core/eventBus';
import Params from 'modules/chat/helpers/params';

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    answer: {
      type: String,
      default: ''
    },
    defaultAnswer: {
      type: String,
      default: ''
    },
    question: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      collapsed: true
    };
  },

  computed: {
    ...mapState('session', ['user', 'actionSettings']),
    ...mapGetters('chat', ['selectedConversation']),

    collapseIcon() {
      return this.collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up';
    },

    listAnswer() {
      if (!this.answer) return [];
      return this.answer.split('|#|');
    },

    listDefaultAnswer() {
      if (!this.defaultAnswer) return [];
      return this.defaultAnswer.split('|#|');
    }
  },

  methods: {
    ...mapActions('chat', ['setAgentChatMessage', 'sendMessage']),

    async sendFaqMessage(params, sendWithConfirm = false) {
      if (
        this.actionSettings &&
        this.actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
        !sendWithConfirm
      ) {
        return this.$baseConfirm({
          message: this.$t(
            'src.modules.chat.store.actions.are_you_sure_to_send_this_message_to_user'
          )
        })
          .then(() => {
            this.sendFaqMessage(params, (sendWithConfirm = true));
          })
          .catch(() => {});
      }
      const faqId = params.id;
      let msg = params.answer;
      const { userId, channelId } = this.selectedConversation;
      if (userId && channelId) {
        const params = new Params();
        msg = params.convertUserKeys2Values(msg);
        msg = params.convertAgentKeys2Values(msg);

        let linkPreviews = [];
        const { urls, isURLOnly } = getURLsFromText(msg);
        if (urls.length > 0) {
          const promises = urls.map(url => getLinkPreview(url));
          const results = await Promise.all(promises.map(p => p.catch(e => e)));
          const validResults = results.filter(
            result => result && !(result instanceof Error)
          );

          if (validResults.length > 0) {
            linkPreviews = validResults;
          }
        }
        const isLinkPreview = linkPreviews && linkPreviews.length > 0;
        if (isLinkPreview) {
          const linkReviews = buildLinkReviewMessage(linkPreviews);
          this.sendMessage({
            sender: this.user.id,
            type: MessageType.TEXT,
            body: msg,
            payload: { link_reviews: linkReviews, isURLOnly },
            faqId,
            conversation: this.selectedConversation
          });
        } else {
          this.sendMessage({
            sender: this.user.id,
            type: MessageType.TEXT,
            body: msg,
            payload: {},
            faqId,
            conversation: this.selectedConversation
          });
        }
        return EventBus.$emit('scrollBottomMessage');
      }
    },

    openFAQTemplateBox(faq) {
      EventBus.$emit('faqTemplateBox', faq);
      this.$root.$emit('bv::show::modal', 'FAQTemplateBox', '#faqTemplateBox');
    }
  }
};
</script>

<style lang="scss" scoped>
.app-message-list {
  .list-group {
    .list-group-item {
      .media {
        margin-left: -10px !important;
      }
      .media-body {
        padding-left: 0px;

        .col-4 {
          margin-top: -3px;
        }
        .col-8 {
          max-width: 200px;
          h6 {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .col-12 {
          max-width: 280px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &.has-new-msg {
        .avatar {
          &::before {
            position: absolute;
            width: 5px;
            height: 5px;
            left: 5px;
            z-index: 10;
            border-radius: 5px;
            background-color: #2196f3;
            content: ' ';
          }
        }

        h6,
        span {
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}

.conversation-feed-item {
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;

  .conversation-feed-item-question {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    display: flex;
    word-break: break-all;
  }

  .conversation-feed-item-answer {
    width: 100%;
    .drag {
      cursor: move;
    }
  }
}

.text-align-right {
  text-align: right;
}

.faq_item {
  display: flex;

  .card {
    border-radius: 0;
  }

  .custom-btn-css {
    border: 1px solid rgba(136, 152, 170, 0.5) !important;
    margin-right: 0 !important;
    padding: 1px 6px !important;
    border-radius: 0 !important;
  }
}

.card {
  .card-body {
    padding: 3px 0px 5px 15px !important;
  }
}
</style>
