import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import { TICKET_STATE, TICKET_STATUS, END_USER_PLATFORM } from 'core/constants';

const requestBot = messages => {
  let ticketId = null;
  let idx = 0,
    idx2 = 0;
  do {
    idx = findIndex(
      messages,
      msg =>
        msg.ticketStatus === TICKET_STATUS.BOT_HANDLE &&
        END_USER_PLATFORM.includes(msg.platform) &&
        msg.ticketId !== ticketId,
      idx
    );
    if (idx < 0) break;
    ticketId = messages[idx].ticketId;
    messages.splice(idx, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'REQUEST_BOT',
      text: 'REQUEST_BOT',
      ticketId
    });
    idx2 = findIndex(
      messages,
      msg =>
        msg.ticketStatus === TICKET_STATUS.BOT_HANDLE &&
        msg.platform === 'bot' &&
        msg.ticketId === ticketId,
      idx + 1
    );
    if (idx2 > 1) {
      messages.splice(idx2, 0, {
        sender: 'status',
        platform: 'system',
        isSystem: true,
        type: 'text',
        content: 'BOT_START',
        text: 'BOT_START',
        ticketId
      });
      idx = idx2;
    }
    idx++;
  } while (idx > 0);
};

const requestAgent = messages => {
  let ticketId = null,
    idx = 0;
  do {
    idx = findIndex(
      messages,
      msg =>
        [TICKET_STATUS.REQUEST_AGENT].includes(msg.ticketStatus) &&
        END_USER_PLATFORM.includes(msg.platform) &&
        msg.ticketId !== ticketId,
      idx
    );
    if (idx < 0) break;
    ticketId = messages[idx].ticketId;
    messages.splice(idx, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'REQUEST_AGENT',
      text: 'REQUEST_AGENT',
      ticketId
    });
    idx += 2;
  } while (idx > 0);
};

const requestUser = messages => {
  let idx = 0;
  do {
    idx = findIndex(
      messages,
      msg =>
        !msg.isSystem &&
        [TICKET_STATUS.REQUEST_USER].includes(msg.ticketStatus) &&
        msg.platform === 'keyword',
      idx
    );
    if (idx < 1) break;

    messages.splice(idx, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'REQUEST_USER',
      text: 'REQUEST_USER',
      ticketId: messages[idx].ticketId
    });
    idx += 2;
    if (messages[idx] && messages[idx].ticketStatus === TICKET_STATUS.BOT_HANDLE) {
      messages.splice(idx, 0, {
        sender: 'status',
        platform: 'system',
        isSystem: true,
        type: 'text',
        content: 'REQUEST_BOT',
        text: 'REQUEST_BOT',
        ticketId: messages[idx].ticketId
      });
      idx++;
    }
  } while (idx > 0);
};

const agentStart = messages => {
  let ticketId = null;
  let idx = 0;
  do {
    idx = findIndex(
      messages,
      msg =>
        !msg.isSystem &&
        msg.ticketStatus === TICKET_STATUS.AGENT_START &&
        msg.ticketId !== ticketId,
      idx
    );
    if (idx < 1) break;
    ticketId = messages[idx].ticketId;
    messages.splice(idx, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'AGENT_START',
      text: 'AGENT_START',
      ticketId
    });
    idx++;
  } while (idx > 0);
};

const botFinishStatus = [
  TICKET_STATUS.BOT_COMPLETE,
  TICKET_STATUS.BOT_TIMEOUT,
  TICKET_STATUS.BOT_HANDLE
];
const agentFinishStatus = [
  TICKET_STATUS.COMPLETE,
  TICKET_STATUS.AGENT_FINISH,
  TICKET_STATUS.NO_SUPPORT
];
const userFinishStatus = [TICKET_STATUS.COMPLETE, TICKET_STATUS.USER_FINISH];
const agentStatus = [
  TICKET_STATUS.REQUEST_AGENT,
  TICKET_STATUS.ASSIGN_TO,
  TICKET_STATUS.USER_IDLE,
  TICKET_STATUS.AGENT_IDLE,
  TICKET_STATUS.HOLDING,
  TICKET_STATUS.TRANSFER_TO,
  TICKET_STATUS.ESCALATE_TO,
  TICKET_STATUS.AGENT_START,
  TICKET_STATUS.HOLIDAY,
  TICKET_STATUS.NON_WORKING,
  TICKET_STATUS.AGENT_SUPPORTING,
  TICKET_STATUS.NO_SUPPORT,
  TICKET_STATUS.NO_ONLINE_AGENT
];
const botStatus = [
  TICKET_STATUS.BOT_COMPLETE,
  TICKET_STATUS.BOT_HANDLE,
  TICKET_STATUS.REQUEST_USER
];
const finishBy = (messages, ticketId, index) => {
  const { ticketStatus } = messages[index];
  const isUserFinish =
    findIndex(
      messages,
      m => m.ticketStatus === TICKET_STATUS.USER_FINISH && m.ticketId === ticketId
    ) > -1;
  const isAgentFinish =
    findIndex(
      messages,
      m => m.ticketStatus === TICKET_STATUS.AGENT_FINISH && m.ticketId === ticketId
    ) > -1;
  if (botFinishStatus.includes(ticketStatus)) return 'BOT_FINISH';
  if (agentFinishStatus.includes(ticketStatus) && isAgentFinish) return 'AGENT_FINISH';
  if (userFinishStatus.includes(ticketStatus) && isUserFinish) return 'USER_FINISH';
  let idx = index;
  let msg = messages[idx];
  while (idx > 0 && msg.ticketId === ticketId) {
    idx--;
    msg = messages[idx];
    if (!msg.isSystem) {
      if (agentStatus.includes(msg.ticketStatus)) return 'AGENT_FINISH';
      if (botStatus.includes(msg.ticketStatus)) return 'BOT_FINISH';
    }
  }
  return 'AGENT_FINISH';
};
const finish = (messages, conversation) => {
  if (!messages || !messages.length) return;
  let { ticket } = conversation;
  let idx = messages.length - 1;
  let ticketId = messages[idx].ticketId;
  if (
    ticket &&
    ticket.id === ticketId &&
    [TICKET_STATE.COMPLETE, TICKET_STATE.FINISH].includes(ticket.state)
  ) {
    messages.splice(idx + 1, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'FINISH',
      text: finishBy(messages, ticketId, idx),
      ticketId
    });
  }

  do {
    idx = findLastIndex(messages, msg => !msg.isSystem && msg.ticketId !== ticketId, idx);
    if (idx < 1) break;

    ticketId = messages[idx].ticketId;
    messages.splice(idx + 1, 0, {
      sender: 'status',
      platform: 'system',
      isSystem: true,
      type: 'text',
      content: 'FINISH',
      text: finishBy(messages, ticketId, idx),
      ticketId
    });
    idx--;
  } while (idx > 0);
};

const conversationStatusWC = messages => {
  let idx = 0;
  do {
    idx = findIndex(messages, msg => msg.conversationStatus, idx);
    if (idx < 0) break;
    const message = messages[idx];
    let conversationStatus = JSON.parse(message.conversationStatus);
    conversationStatus = conversationStatus.reverse();
    for (let i = 0; i < conversationStatus.length; i++) {
      const { status } = conversationStatus[i];
      if (['minimizewebchat', 'offline'].includes(status)) {
        messages.splice(idx + 1, 0, {
          sender: 'status',
          platform: 'system',
          isSystem: true,
          type: 'text',
          content:
            status === 'minimizewebchat'
              ? 'USER_MINIIZED_WEBCHAT_TERMINAL'
              : 'USER_IS_NOT_AVAILABLE',
          text:
            status === 'minimizewebchat'
              ? 'USER_MINIIZED_WEBCHAT_TERMINAL'
              : 'USER_IS_NOT_AVAILABLE',
          ticketId: messages[idx].ticketId
        });
      }
    }
    idx++;
  } while (idx > 0);
};

export const addMessageStatus = (messages, conversation) => {
  requestBot(messages);
  requestAgent(messages);
  agentStart(messages);
  requestUser(messages);
  finish(messages, conversation);
  conversationStatusWC(messages);
  return messages;
};

export const statusMessage = {
  requestBot,
  requestAgent,
  requestUser,
  agentStart,
  finish,
  conversationStatusWC
};
