<template>
  <b-card
    :title="title | truncate(10)"
    tag="article"
    class="mb-2 img-fluid border-0"
    no-body
  >
    <div v-if="hasImage">
      <img class="image" :src="getImageUrl" />
    </div>
    <b-card-body
      :class="hasButton ? 'border-bottom nat-card-body' : 'nat-card-body'"
      :style="hasSubtitle ? 'height: 150px' : 'height: 100px'"
    >
      <div class="title-carousel">
        <div class="two-line-text">
          <b>{{ getTitle() }}</b>
        </div>
      </div>
      <div class="card-text sub-title-carousel">
        <div class="two-line-text">
          <span>{{ getSubTitle() }}</span>
        </div>
      </div>
      <div class="card-text sub-title-carousel">
        <div class="two-line-text">
          <a class="link-gray" target="_blank" :href="getUrl()">{{ getHostName() }}</a>
        </div>
      </div>
    </b-card-body>
    <template v-for="(button, i) in buttons">
      <b-button
        v-if="button.type == 'postback'"
        :key="i"
        variant="outline-primary"
        size="sm"
        disabled
        class="nat-btn-postback card-item-button"
        >{{ button.title }}</b-button
      >
      <b-button
        v-if="button.type == 'phone_number'"
        :key="i"
        variant="outline-primary"
        size="sm"
        disabled
        class="nat-btn-phone-number card-item-button"
        >{{ button.title }}</b-button
      >
      <b-button
        v-if="button.type == 'web_url'"
        :key="i"
        :href="!isForm(button.url) ? button.url : ''"
        :disabled="isForm(button.url)"
        target="_blank"
        variant="outline-primary"
        size="sm"
        class="nat-btn-web-url card-item-button"
        >{{ button.title }}</b-button
      >
    </template>
  </b-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    image_url: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    default_action: {
      type: Object,
      default: () => {}
    },
    buttons: {
      type: Array,
      default: () => []
    },
    hasSubtitle: {
      type: Boolean,
      default: false
    },
    hasImage: {
      type: Boolean,
      default: true
    },
    hasButton: {
      type: Boolean,
      default: true
    },
    domainWebview: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      imageUrl: ''
    };
  },

  computed: {
    url() {
      if (!this.default_action) return;
      return this.default_action.url;
    },

    getImageUrl() {
      return this.imageUrl;
    }
  },

  created() {
    this.imageExists(this.image_url);
  },

  methods: {
    async imageExists(src) {
      const { type } = await new Promise(resolve => {
        let img = new Image();
        img.src = src;
        img.onload = resolve;
      });

      if (type === 'load') {
        this.imageUrl = this.image_url;
      }
    },
    getTitle() {
      let str = Array.isArray(this.title) ? this.title[0] : this.title;
      return str.substring(0, 100);
    },
    getSubTitle() {
      if (this.subtitle) return this.subtitle;
      if (Array.isArray(this.title) && this.title.length > 1) return this.title[1];
      return '';
    },
    getUrl() {
      if (this.default_action && this.default_action.url) return this.default_action.url;
      return '';
    },
    getHostName() {
      if (this.default_action && this.default_action.url) {
        let hostname = new URL(this.default_action.url).hostname;
        return hostname;
      } else {
        return '';
      }
    },
    isForm(url) {
      return this.domainWebview.includes((new URL(url)).hostname);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-carousel,
.sub-title-carousel {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
}
.two-line-text {
  overflow: hidden;
  position: relative;
  line-height: 20px;
  max-height: 40px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  white-space: normal;
}
.card-item-button {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}
.card {
  >>> .card-img-top {
    max-height: 250px;
    width: auto;
  }

  /deep/ .card-title {
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 5px;
  }

  /deep/ .card-body {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  /deep/ .image {
    background-position: 50% 50%;
    background-size: cover;
    display: block;
    position: relative;
    width: 100%;
    height: 250px;
    border-bottom: 1px solid #ddd;
  }
}
.link-gray {
  color: gray !important;
}
</style>
