<template>
  <ValidationObserver ref="closeConversation">
    <b-modal
      id="CloseConversation"
      ref="CloseConversation"
      v-model="show"
      modal-class="fix-width-modal"
      :title="$t('src.modules.chat.components.CloseConversation.index.close_conversation')"
      :no-close-on-backdrop="sendingMessage"
      :no-close-on-esc="sendingMessage"
      @hidden="resetAllState"
    >
      <div>
        <label>{{ titleSelectActionFinish }}</label>
        <div class="fix-width-select">
          <base-select
            v-model="selectedFinishAction"
            :align="'vertical-no-label'"
            :options="finishActions"
            :option-value="'value'"
            :option-lable="'label'"
            :name="'selectedFinishAction'"
            :value-type="'String'"
            :option-type="'Object'"
            :required="true"
            @change="handleChangeFinishAction"
          />
        </div>
      </div>

      <div
        v-if="selectedFinishAction === 'finish_with_bot_scenario'"
        v-loading="isLoading"
        style="margin-top: 10px"
      >
        <label>{{ titleSelectBotScenario }}</label>
        <div class="fix-width-select">
          <base-select
            v-model="selectedScenario"
            :align="'vertical-no-label'"
            :label="$t('src.modules.chat.components.ConversationalPanel.index.scenario')"
            :options="scenarios"
            :option-value="'value'"
            :option-lable="'label'"
            :default-first-option="false"
            :name="'selectedScenario'"
            :value-type="'String'"
            :option-type="'Object'"
            :required="true"
            @change="handleChangeScenario"
          />
        </div>
      </div>

      <div
        v-if="selectedFinishAction === 'finish_with_survey_form'"
        v-loading="isLoading"
        style="margin-top: 10px"
      >
        <label>{{ titleSelectSurveyTemplate }}</label>
        <div class="fix-width-select">
          <base-select
            v-model="selectedSurveyTemplate"
            :align="'vertical-no-label'"
            :placeholder="$t('src.modules.chat.components.CloseConversation.index.select_template')"
            :options="surveyTemplates"
            :option-value="'value'"
            :option-lable="'label'"
            :default-first-option="false"
            :name="'selectedSurveyTemplate'"
            :value-type="'String'"
            :option-type="'Object'"
            :required="true"
            :error="'src.modules.chat.components.CloseConversation.index.please_select_a_survey_template'"
          />
        </div>
        <div class="fix-width-box">
          <base-textarea
            v-model="inputRequestSurveyMessage"
            :required="true"
            :rows="3"
            :placeholder="
              $t('src.modules.chat.components.CloseConversation.index.enter_request_survey_message')
            "
            :label="
              $t('src.modules.chat.components.CloseConversation.index.request_survey_message')
            "
            :maxlength="300"
          />
        </div>
        <div class="fix-width-box">
          <base-textarea
            v-model="inputSurveyFormLinkText"
            :required="true"
            :rows="1"
            :label="$t('src.modules.chat.components.CloseConversation.index.survey_form_link_text')"
            :maxlength="20"
          />
        </div>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-btn
          class="float-right"
          variant="primary"
          style="margin-left: 7px"
          :disabled="disabledConfirm"
          @click="handleConfirm"
        >
          {{ $t('src.modules.report.components.cnv-scheduled-export.index.confirm') }}
          <i v-if="sendingMessage" class="fas fa-spinner fa-spin fa-lg" />
        </b-btn>
        <b-btn class="float-right" variant="default" @click="handleBack">
          {{ $t('src.modules.chat.components.CustomerSupportPanel.index.back') }}
        </b-btn>
      </div>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { i18n } from 'locales';
import { MessageType } from 'core/message';
import { buildSurveyMessage } from 'core/helpers';
import { TICKET_STATE, PLATFORM } from 'core/constants';
function defaultState() {
  return {
    show: false,
    flagFinishWithoutFeedBack: false,
    isLoading: false,
    titleSelectActionFinish: i18n.t(
      'src.modules.chat.components.CloseConversation.index.you_are_going_to_close_this_conversation_are_you_sure'
    ),
    titleSelectBotScenario: i18n.t(
      'src.modules.chat.components.CloseConversation.FinishWithScenario.title.finish_with_scenario'
    ),
    titleSelectSurveyTemplate: i18n.t(
      'src.modules.chat.components.CloseConversation.index.please_select_which_survey_template_to_send_to_this_user'
    ),
    inputRequestSurveyMessage: i18n.t(
      'src.modules.chat.components.CloseConversation.index.requestSurveyMessage.content'
    ),
    inputSurveyFormLinkText: i18n.t(
      'src.modules.chat.components.CloseConversation.index.surveyFormLinkText.content'
    ),
    selectedFinishAction: '',
    selectedSurveyTemplate: '',
    selectedScenario: '',
    surveyTemplates: [],
    scenarios: [],
    scenarioName: '',
    sendingMessage: false
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('session', ['user', 'channelsMap', 'projectMaps']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', ['conversations']),

    finishActions() {
      const actions = [];

      if (!this.isAlwaysShowRatingFeedback) {
        actions.push({
          value: 'no_action',
          label: this.$t(
            'src.modules.chat.components.CloseConversation.index.finish_with_no_action'
          )
        });
      }

      if (this.isShowRatingFeedback) {
        actions.push({
          value: 'finish_with_rating_feedback',
          label: this.$t('src.modules.chat.components.CloseConversation.index.finish_with_feedback')
        });
      }

      if (this.allowSurveyFeature) {
        actions.push({
          value: 'finish_with_survey_form',
          label: this.$t(
            'src.modules.chat.components.CloseConversation.index.finish_with_survey_form'
          )
        });
      }

      if (this.isUsingBot) {
        actions.push({
          value: 'finish_with_bot_scenario',
          label: this.$t('src.modules.chat.components.CloseConversation.index.finish_with_scenario')
        });
      }
      return actions;
    },

    allowSurveyFeature() {
      const { channelId, platform } = this.selectedConversation || {};
      const { advancedConfigs: { modulesActivation: { surveyForm = false } = {} } = {} } =
        this.channelsMap[channelId] || {};

      return surveyForm && ![PLATFORM.INSTAGRAM].includes(platform);
    },

    isShowRatingFeedback() {
      const { projectId } = this.selectedConversation || {};
      const { config: { ratingFeedback = {} } = {} } = this.projectMaps[projectId] || {};
      const { active = false } = ratingFeedback;
      return active;
    },

    isAlwaysShowRatingFeedback() {
      try {
        const { projectId } = this.selectedConversation;
        const { config: { ratingFeedback = {} } = {} } = this.projectMaps[projectId];
        const { isAlwaysSend = false, active = false } = ratingFeedback;
        return active && isAlwaysSend;
      } catch (error) {
        return false;
      }
    },

    isUsingBot() {
      const { channelId = '' } = this.selectedConversation || {};
      const { advancedConfigs = {} } = this.channelsMap[channelId] || {};
      const { modulesActivation = {} } = advancedConfigs;
      const { bot = false } = modulesActivation;
      return bot;
    },

    disabledConfirm() {
      if (
        (this.selectedFinishAction === 'finish_with_bot_scenario' && !this.scenarios.length) ||
        (this.selectedFinishAction === 'finish_with_survey_form' && !this.surveyTemplates.length) ||
        this.sendingMessage
      ) {
        return true;
      }
      return false;
    }
  },

  watch: {
    selectedConversation(value) {
      const { ticket: { state } = {} } = value || {};
      if ([TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state)) {
        this.show = false;
      }
    },

    show() {
      this.selectedFinishAction = this.isAlwaysShowRatingFeedback
        ? 'finish_with_rating_feedback'
        : 'no_action';
    }
  },

  methods: {
    ...mapActions('chat', [
      'sendMessage',
      'setReady',
      'finishConversation',
      'getSurveyTemplate',
      'sendScenarioAfterFinish'
    ]),
    ...mapActions('conversationMng', ['getCampainList', 'getCampaignBot', 'sendScenario']),

    handleConfirm() {
      this.$refs.closeConversation &&
        this.$refs.closeConversation.validate().then(async result => {
          if (!result) {
            this.warningMessage();
          } else {
            switch (this.selectedFinishAction) {
              case 'no_action':
              case 'finish_with_rating_feedback':
                await this.handleFinishConversation();
                break;
              case 'finish_with_survey_form':
                await this.finishWithSurveyForm();
                break;
              case 'finish_with_bot_scenario':
                await this.finishWithBotScenario(this.selectedScenario, this.scenarioName);
                break;
              default:
                break;
            }
            return true;
          }
        });
    },

    async handleChangeFinishAction() {
      this.isLoading = true;
      switch (this.selectedFinishAction) {
        case 'no_action':
        case 'finish_with_rating_feedback':
          this.isLoading = false;
          break;
        case 'finish_with_survey_form':
          await this.getListSurveyTemplate();
          this.isLoading = false;
          break;
        case 'finish_with_bot_scenario':
          await this.getListBotScenario();
          this.isLoading = false;
          break;
        default:
          break;
      }
    },

    async getListBotScenario() {
      const { userInfo: { locale } = {} } = this.selectedConversation;
      const { ticket: { channelId, projectId } = {} } = this.selectedConversation;
      const { advancedConfigs: { supportLanguages } = {} } = this.channelsMap[channelId] || {};
      const { platform, defaultLanguage } = this.channelsMap[channelId];
      const language =
        platform === 'line' || !supportLanguages.includes(locale) ? defaultLanguage : locale;
      const listCampaignBot = await this.getCampainList({
        projectId,
        groupId: '',
        type: 'BOT'
      });
      if (listCampaignBot.length) {
        const campaignBot = listCampaignBot.filter(item => item.name === 'General Scenarios');
        const { id: campaignId } = campaignBot[0];
        const scenarioData = await this.getCampaignBot({
          campaignId,
          platform,
          language
        });
        this.scenarios = scenarioData
          .filter(scenario => scenario.allowSentAfterConversation)
          .map(scenario => {
            return {
              value: scenario.id,
              label: scenario.name
            };
          });
      } else this.scenarios = [];
    },

    async getListSurveyTemplate() {
      let listSurvey = [];
      const operationLanguage = localStorage.getItem('language');
      const language = operationLanguage.replace('-', '_');
      const { projectId = '' } = this.selectedConversation;
      const surveyTemplateData = await this.getSurveyTemplate({
        projectId,
        language
      });
      listSurvey = surveyTemplateData.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
      this.surveyTemplates = this.sortProperties(listSurvey);
    },

    handleChangeScenario(value) {
      const scenario = this.scenarios.find(i => i.value === value);
      this.scenarioName = scenario.label;
    },

    async finishWithBotScenario(scenarioId, scenarioName) {
      const { ticket: { channelId, id: ticketId, projectId } = {} } = this.selectedConversation;
      const { userId, userInfo: { locale } = {} } = this.selectedConversation;
      const { advancedConfigs: { supportLanguages } = {} } = this.channelsMap[channelId];
      const { platform, defaultLanguage } = this.channelsMap[channelId];
      const language =
        platform === 'line' || !supportLanguages.includes(locale) ? defaultLanguage : locale;
      let newConversation = this.selectedConversation;
      this.show = false;
      this.setReady(false);
      await this.finishConversation({
        ticketId,
        flagFinishWithoutFeedBack: true
      })
        .then(() => this.setReady(true))
        .catch(() => this.setReady(true));

      const ticket = await this.sendScenarioAfterFinish({
        channelId,
        ticketId,
        platform,
        projectId
      });
      const { id: newTicketId } = ticket;
      const params = {
        scenarioId,
        scenarioName,
        recipientId: userId,
        channelId,
        platform,
        conversation: JSON.stringify({
          ...newConversation,
          ticketId: newTicketId,
          ticket
        }),
        sendAfterFinish: true,
        language
      };

      await this.sendScenario(params);
    },

    async finishWithSurveyForm() {
      this.sendingMessage = true;
      const { ticket: { id: ticketId } = {} } = this.selectedConversation;
      const survey = {
        id: this.selectedSurveyTemplate,
        textLink: this.inputSurveyFormLinkText
      };
      const msgSurvey = buildSurveyMessage(this.inputRequestSurveyMessage, survey);

      await this.sendMessage({
        sender: this.user.id,
        type: MessageType.TEMPLATE,
        body: msgSurvey,
        payload: {
          type: 'survey',
          surveyId: survey.id,
          surveyName: survey.textLink
        }
      });

      await this.finishConversation({
        ticketId,
        flagFinishWithoutFeedBack: true
      });

      this.sendingMessage = false;
      this.show = false;
    },

    async handleFinishConversation() {
      let flag = true;
      if (this.selectedFinishAction === 'finish_with_rating_feedback') flag = false;
      else flag = true;
      const { ticket: { id: ticketId } = {} } = this.selectedConversation;
      this.show = false;
      this.setReady(false);
      this.finishConversation({
        ticketId,
        flagFinishWithoutFeedBack: flag
      })
        .then(() => this.setReady(true))
        .catch(() => this.setReady(true));
    },

    handleBack() {
      this.resetAllState();
    },

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    warningMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    sortProperties(arrValue) {
      if (!Array.isArray(arrValue)) return [];
      arrValue.sort(function (a, b) {
        let x = a.label.toLowerCase(),
          y = b.label.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue;
    }
  }
};
</script>

<style lang="scss">
.fix-width-modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 600px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.fix-width-select {
  width: 50%;
}

.fix-width-box {
  margin-top: 10px;

  /deep/ textarea.nat-texarea-verti {
    resize: none;
  }
}
</style>
