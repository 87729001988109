<template>
  <b-modal
    id="ReSupportWarningModal"
    ref="reSupportWarningModal"
    v-model="show"
    :title="$t('src.core.App.warning')"
    @hidden="resetAllState"
  >
    <span>{{ messageContent }}</span>
    <div slot="modal-footer" class="w-100">
      <b-btn
        v-show="showBtnGoto"
        data-testid="btnGoto"
        class="float-right"
        variant="primary"
        style="margin-left: 7px"
        @click="gotoTicktet"
        :disabled="gotoWating"
      >
        <span
          >{{ $t('common.confirmModal.go_to') }}
          <i v-if="gotoWating" class="fas fa-spinner fa-spin fa-lg" />
        </span>
      </b-btn>
      <b-btn
        data-testid="btnClose"
        class="float-right"
        variant="default"
        @click="resetAllState"
        :disabled="gotoWating"
      >
        {{ $t('common.confirmModal.close') }}</b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { i18n } from 'locales';
import isEmpty from 'lodash/isEmpty';
import { EventBus } from 'core/eventBus';
import { getGroupConvId } from 'core/helpers';
import { fetchDataAndGotoTicket } from 'core/helpers';
import { mapState, mapGetters, mapActions } from 'vuex';
import { TICKET_STATE } from 'core/constants';

function defaultState() {
  return {
    show: false,
    gotoWating: false,
    showBtnGoto: false,
    lastTicket: {},
    messageContent: '',
    messageTranslate: {
      NEW_TICKET_CREATED: i18n.t(
        'src.components.Modal.ReSupportWarning.index.a_new_conversation_has_been_created_would_you_like_to_go_to_that_conversation'
      ),
      RE_SUPPORT_BY_ME: i18n.t(
        'src.components.Modal.ReSupportWarning.index.you_resupported_this_conversation_would_you_like_to_go_to_that_conversation'
      ),
      SUPPORT_BY_ANOTHER_AGENT: i18n.t(
        'src.components.Modal.ReSupportWarning.index.this_conversation_has_been_resupported_by_another_agent'
      ),
      TICKET_NOT_FOUND: i18n.t('common.conversation_not_exists')
    },
    needToReloadPage: false
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('global', ['ready', 'rightCustomerSupportPanelPinned']),
    ...mapState('session', ['user', 'tickets']),
    ...mapState('chat', ['showSupportPanel']),
    ...mapGetters('session', ['conversationsMap'])
  },

  created() {
    EventBus.$on('reSupportWarningModal', (data = null) => {
      this.resetAllState();
      const { code = '' } = data;
      if (['SUPPORT_BY_ANOTHER_AGENT', 'TICKET_NOT_FOUND'].includes(code)) {
        this.handleResupportConfirm(code);
      } else this.handlerOpenPopup(data, true);
    });
  },

  destroyed() {
    EventBus.$off('reSupportWarningModal', (data = null) =>
      this.handlerOpenPopup(data, false)
    );
  },

  methods: {
    ...mapActions('global', ['pinRightPanel', 'setGlobalReady', 'setGlobalLoadingMap']),
    ...mapActions('session', ['setWaitingMode', 'reloadChannel']),
    ...mapActions('chat', [
      'setReady',
      'setChatViewingGroup',
      'setSelectedGroupConversation',
      'getMessagesByConversationId',
      'setShowView',
      'setShowProductPanel',
      'setShowConversationalPanel',
      'setShowSupportPanel'
    ]),
    ...mapActions('searchManagement', ['getActionSearchClick']),

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    async gotoTicktet(e) {
      e && e.preventDefault();
      this.gotoWating = !this.gotoWating;
      await new Promise(resolve => setTimeout(() => resolve(true), 50));

      if (isEmpty(this.lastTicket)) return (this.show = false);
      const { id: ticketId = '', conversationId = '', state } = this.lastTicket;
      const groupId = getGroupConvId({ ticket: this.lastTicket }, this.user);

      if (groupId === -1) {
        // Hide all right pannel
        this.setShowProductPanel(false);
        this.setShowConversationalPanel(false);
        this.setShowSupportPanel(false);
        this.pinRightPanel(false);
        this.setShowView('Overview');
        return (this.show = false);
      }

      if (!['Chat', 'Conversation'].includes(this.$route.name)) {
        this.$router.push('/');
      }

      await fetchDataAndGotoTicket({ conversationId, id: ticketId });

      if (state !== TICKET_STATE.FINISH) {
        this.setShowSupportPanel(false);
        this.pinRightPanel(false);
      }
      return (this.show = false);
    },

    handlerOpenPopup(data, bool) {
      if (!bool || !data) return null;
      this.show = bool;

      const { code = '', ticket = {} } = data;
      const { userId, channelId } = ticket;
      switch (code) {
        case 'NEW_TICKET_CREATED':
          this.messageContent = this.messageTranslate[code];
          this.lastTicket = ticket;
          this.showBtnGoto = true;
          this.getActionSearchClick({ userId, channelId });
          break;

        case 'RE_SUPPORT_BY_ME':
          this.messageContent = this.messageTranslate[code];
          this.lastTicket = ticket;
          this.showBtnGoto = true;
          this.getActionSearchClick({ userId, channelId });
          break;

        case 'DIRECT_REDIRECT_TO_TICKET':
          this.lastTicket = ticket;
          this.show = !bool;

          this.setReady(true);
          this.setGlobalLoadingMap({ action: 'DIRECT_REDIRECT_TO_TICKET', flag: true });
          this.gotoTicktet()
            .then(() => {
              !this.ready ? this.setGlobalReady(true) : null;
              this.setGlobalLoadingMap({
                action: 'DIRECT_REDIRECT_TO_TICKET',
                flag: false
              });
            })
            .catch(() => {
              !this.ready ? this.setGlobalReady(true) : null;
              this.setGlobalLoadingMap({
                action: 'DIRECT_REDIRECT_TO_TICKET',
                flag: false
              });
            });
          break;

        default:
          this.show = !bool;
          break;
      }
    },

    handleResupportConfirm(code) {
      switch (code) {
        case 'SUPPORT_BY_ANOTHER_AGENT':
          this.messageContent = this.messageTranslate[code];
          this.showDialogWarning(this.messageContent);
          break;

        case 'TICKET_NOT_FOUND':
          this.messageContent = this.messageTranslate[code];
          this.showDialogWarning(this.messageContent);
          break;

        default:
          break;
      }
    },

    showDialogWarning(message) {
      return this.$baseConfirm({
        title: i18n.t('src.core.App.warning'),
        message: message,
        showConfirmButton: false,
        cancelButtonText: i18n.t('BaseConfirm.Ok'),
        iconClass: 'el-icon-warning'
      })
        .then(() => {})
        .catch(() => {
          this.getActionSearchClick({});
        });
    }
  }
};
</script>
<style lang="scss" scoped></style>
