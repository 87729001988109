import { uuid } from 'vue-uuid';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

import {
  SET_CHAT_LIST,
  SET_HISTORIES_MESSAGES,
  ASSIGN_PRODUCTFEEDS_TO_CHANNEL,
  SET_SELECTED_CONVERSATION,
  UPDATE_SELECTED_CONVERSATION,
  ASSIGN_MESSAGES_TO_CONVERSATION,
  SEND_MESSAGE,
  ADD_NEW_MESSAGE_TO_CONVERSATION,
  CLEAR_NEW_MESSAGE,
  SET_CHAT_READY,
  SET_CONVERSATIONFEEDS,
  SET_SEARCH_FAQ_WITH_LANGUAGE,
  SET_LIST_FAQ_LANGUAGE,
  SET_SELECT_GROUP_CONV,
  SET_CONVSEARCHTEXT,
  SET_PRODSEARCHTEXT,
  SET_PRODSEARCHTYPE,
  SET_PRODSEARCHSORT,
  LOAD_MORE_MESSAGE,
  UPDATE_MESSAGE_LOCAL,
  UPDATE_MESSAGE_STATUS,
  SET_LOCALES_FILTER,
  SET_NOTIFY_MESSAGE,
  SET_SEARCH_TEXT,
  SET_INIT_STATE,
  REMOVE_INIT_STATE,
  SET_SHOW_SUPPORT_PANEL,
  SET_SHOW_SURVEY_PANEL,
  SET_SHOW_CONVERSATIONAL_PANEL,
  SET_SHOW_WEB_BROWSING_HISTORY_PANEL,
  SET_SHOW_PRODUCT_PANEL,
  SET_MONITORING,
  SET_SHOW_VIEW,
  SET_CHAT_VIEWING_GROUP,
  SET_SEARCH_BOX_FILTER,
  ASSIGN_MESSAGES_OF_TICKET_ID,
  SET_SEARCH_BOX_HEIGHT,
  SET_ACTIVITIES,
  CLEAR_LONGEST_TIME_BY_AGENT,
  SET_MESSAGES_NOTIFICATION,
  SET_SORT_METHOD,
  SUPPORT_WITHOUT_USER_AGREEMENT,
  UPDATE_MESSAGE,
  SET_AGENT_CHAT_MESSAGE,
  UPDATE_TYPING,
  ADD_TICKET_ACTION_LOG,
  SET_SEARCH_BOX,
  FINISH_CONVERSATION,
  SET_CSFP_CHANGE_DATA,
  SET_SHOW_CONFIRM_SAVED_DATA,
  SET_PREVIOUS_ACTION,
  SET_MAYBE_NOT_AVAILABLE,
  SET_CONVERSATIONS_BOX,
  ADD_CONVERSATIONS_BOX,
  CLEAR_CONVERSATIONS_BOX,
  SET_WEB_BROWSING_HISTORY_DATA,
  CLEAR_WEB_BROWSING_HISTORY_DATA,
  SET_USER_LABELS,
  SET_CONVERSATION_LABELS,
  ADD_NEW_LABEL,
  DELETE_USER_LABEL,
  UPDATE_USER_LABEL,
  UPDATE_CONV_IN_USERINFO,
  SET_SYSTEM_DEFINED_WORD,
  SET_SHOW_STATUS_HISTORY_PANEL,
  UPDATE_STATUS_FOR_SELECT_CONVRS,
  SET_IMAGE_OF_URL_PREVIEW,
} from './types';

import * as getters from './getters';
import * as actions from './actions';
import { SERVING_STATE, SORT_BY } from 'core/constants';
import { getGroupConvId } from 'core/helpers';

const state = {
  chatList: [],
  messages: {}, // {senderId: []}
  messagesOfTicketId: {}, // in monitoring
  newMessages: {}, // {senderId: []}
  messagesUpdate: {},
  firstLoaded: {},
  ready: true,
  selectedConversation: null,
  bot: {
    unread: {
      count: 0
    }
  },
  waiting: {
    unread: {
      count: 0
    }
  },
  agents: {
    unread: {
      count: 0
    }
  },
  comment: {
    unread: {
      count: 0
    }
  },
  productFeeds: {},
  productSets: {},
  productTypes: {},
  conversationFeeds: {},
  listFaqLanguage: [],
  searchFaqWithLanguage: '',
  selectedGroupConv: SERVING_STATE.AGENT,
  convSearchText: '',
  prodSearchText: '',
  prodSearchType: '',
  prodSearchSort: 1, // asc,
  localesFilter: [],
  notifyMessage: {},
  searchTextConversation: [],
  initState: false,
  showSupportPanel: false,
  showSurveyPanel: false,
  showProductPanel: false,
  showConversationalPanel: false,
  showWebBrowsingHistoryPanel: false,
  monitoring: false,
  showView: 'Overview',
  chatViewingGroup: SERVING_STATE.AGENT,
  searchBoxFilter: {},
  searchBoxHeight: 95,
  searchBox: true,
  activitiesMap: {},
  longestTimeByAgentMap: {},
  messagesNotification: {},
  sortBysMap: {},
  supportWithoutUserAgreement: false,
  curAgentChatMessage: '',
  butSendClick: 0,
  typing: {},
  ticketActionLogMap: {},
  finishCnvButClick: 0,
  csfpChangeData: false,
  showConfirmSavedData: false,
  previousAction: '',
  maybeNotAvailable: {}, //user's status of webchat in agent handle list and state supporting
  conversationsBox: {}, // {data:[{}],totalCnt:0}
  webBrowsingHistory: [],
  wbhAllDataCount: 0,
  wbhLastEvaluatedKey: '',
  userLabels: [],
  backupUsrLabels: [],
  backupConvLabels: [],
  convsLabels: [],
  systemDefinedWord: [],
  showStatusHistoryPanel: false,
  imageOfUrlPreview: {}
};

const mutations = {
  [SET_USER_LABELS](state, labels) {
    const sortedLabels = labels.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      return a.value < b.value ? -1 : 0;
    });
    state.userLabels = sortedLabels;
    state.backupUsrLabels = sortedLabels;
  },
  [SET_CONVERSATION_LABELS](state, labels) {
    const sortedLabels = labels.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      return a.value < b.value ? -1 : 0;
    });
    state.convsLabels = sortedLabels;
    state.backupConvLabels = sortedLabels;
  },
  [DELETE_USER_LABEL](state, { value }) {
    const { userLabels = [] } = state;
    state.userLabels = userLabels.filter(usrLabel => usrLabel.value !== value);
    state.backupUsrLabels = state.userLabels;
  },
  [UPDATE_USER_LABEL](state, labels) {
    const { userLabels = [] } = state;
    const newLabels = [];

    userLabels.forEach(usrLabel =>
      labels.forEach(label => {
        if (label.id === usrLabel.id) {
          usrLabel.type = label.type;
          usrLabel.value = label.value;
        }
        newLabels.push(usrLabel);
      })
    );
    state.userLabels = cloneDeep(newLabels);
    state.backupUsrLabels = cloneDeep(newLabels);
  },
  [ADD_NEW_LABEL](state, newLabels) {
    const { userLabels } = state;
    const newUserLabels = newLabels.map(label => ({ ...label, id: uuid.v4() }));
    state.userLabels = [...newUserLabels, ...userLabels];
    state.backupUsrLabels = state.userLabels;
  },

  [SET_MAYBE_NOT_AVAILABLE](state, { ticketId, flag }) {
    state.maybeNotAvailable = {
      ...state.maybeNotAvailable,
      [ticketId]: flag
    };
  },

  [FINISH_CONVERSATION](state) {
    state.finishCnvButClick++;
  },

  [SET_AGENT_CHAT_MESSAGE](state, message) {
    state.curAgentChatMessage = message;
    state.butSendClick++;
  },

  [SET_CHAT_READY](state, flag = true) {
    state.ready = flag;
  },

  [SET_CHAT_LIST](state, data) {
    state.chatList = data;
  },

  [SET_HISTORIES_MESSAGES](state, messages) {
    state.messages.splice(0);
    state.messages = [...messages];
  },

  [ASSIGN_PRODUCTFEEDS_TO_CHANNEL](state, productFeeds) {
    const newProducts = productFeeds.reduce(
      (products, product) => ({
        ...products,
        [product.id]: product.data
      }),
      {}
    );

    const newProductSets = productFeeds.reduce(
      (productSets, productSet) => ({
        ...productSets,
        [productSet.id]: productSet.name
      }),
      {}
    );
    const newProductTypes = productFeeds.reduce(
      (productTypes, productType) => ({
        ...productTypes,
        [productType.id]: productTypes.type || ''
      }),
      {}
    );

    const { productFeeds: pf, productSets: ps, productTypes: pt } = state;

    pf[state.selectedConversation.channelId] = newProducts;
    ps[state.selectedConversation.channelId] = newProductSets;
    pt[state.selectedConversation.channelId] = newProductTypes;
    state.productFeeds = { ...pf };
    state.productSets = { ...ps };
    state.newProductTypes = { ...pt };
  },

  [SET_SEARCH_FAQ_WITH_LANGUAGE](state, language) {
    state.searchFaqWithLanguage = language;
  },

  [SET_CONVERSATIONFEEDS](state, { listQuestionFAQ, listQuestionNLP, language }) {
    if (!state.selectedConversation) return;
    const { channelId } = state.selectedConversation;

    const conversationsFAQ = listQuestionFAQ.reduce((cMap, c) => {
      const convs = cMap[c.key] || [];
      cMap[c.key] = [...convs, ...c.data];
      return cMap;
    }, {});

    const conversationsNLP = listQuestionNLP.reduce((cMap, c) => {
      const convs = cMap[c.key] || [];
      cMap[c.key] = [...convs, ...c.data];
      return cMap;
    }, {});

    const { conversationFeeds: cf } = state;
    const conversation = {};
    conversation[language] = {
      conversationsFAQ,
      conversationsNLP
    };

    cf[channelId] = { ...cf[channelId], ...conversation };
    state.conversationFeeds = { ...cf };
  },

  [SET_LIST_FAQ_LANGUAGE](state, listLanguage) {
    state.listFaqLanguage = [...listLanguage];
  },

  [UPDATE_CONV_IN_USERINFO](state, conversation) {
    const { ticket: { id = '' } = {} } = conversation || {};
    const { selectedConversation } = state;
    state.selectedConversation = { ...selectedConversation, ...conversation, ticketId: id };
  },

  [SET_SELECTED_CONVERSATION](state, { conversation, groupId, user, eventType }) {
    const { ticket: { id = '' } = {} } = conversation || {};
    state.selectedConversation = { ...conversation, ...{ eventType }, ticketId: id };
    let newGroupId = getGroupConvId(conversation, user);
    newGroupId = typeof groupId === 'undefined' ? newGroupId : groupId;

    if (newGroupId !== state.selectedGroupConv) {
      state.selectedGroupConv = newGroupId;
    }
    if (newGroupId !== state.chatViewingGroup) {
      state.chatViewingGroup = newGroupId;
    }
    state.selectedConversation = { ...state.selectedConversation };
  },

  [UPDATE_SELECTED_CONVERSATION](state, conversation) {
    console.log('UPDATE_SELECTED_CONVERSATION conversation', conversation);
    state.selectedConversation = { ...conversation };
  },

  [UPDATE_STATUS_FOR_SELECT_CONVRS](state, userInfo) {
    const convs = { ...state.selectedConversation };
    convs.userInfo = userInfo;
    state.selectedConversation = { ...convs };
  },

  [ASSIGN_MESSAGES_TO_CONVERSATION](state, { id, messages: msgData }) {
    const { messages, messagesUpdate, firstLoaded } = state;
    messages[id] = msgData;
    messagesUpdate[id] = Date.now();
    firstLoaded[id] = true;

    state.messages = {
      ...messages
    };
    state.messagesUpdate = {
      ...messagesUpdate
    };
    state.firstLoaded = {
      ...firstLoaded
    };
  },

  [ASSIGN_MESSAGES_OF_TICKET_ID](state, { id, ticketId, messages, hasNewMessage = false } = {}) {
    const { messagesOfTicketId } = state;
    const ticketOfConversationId = messagesOfTicketId[id] || {
      ...messagesOfTicketId
    };

    if (hasNewMessage) {
      ticketOfConversationId[ticketId] = [...messages];
    } else if (ticketOfConversationId[ticketId]) {
      if (messages.length > 0) {
        const [firstMsg] = messages;
        const existMsg = ticketOfConversationId[ticketId].reduce((acc, msg) => {
          if (msg.id === firstMsg.id) acc = true;
          return acc;
        }, false);
        if (!existMsg)
          ticketOfConversationId[ticketId] = [...messages, ...ticketOfConversationId[ticketId]];
      }
    } else {
      ticketOfConversationId[ticketId] = [...messages];
    }
    messagesOfTicketId[id] = { ...ticketOfConversationId };

    state.messagesOfTicketId = {
      ...messagesOfTicketId
    };
  },

  [LOAD_MORE_MESSAGE](state, { conversationId, messages: msgData }) {
    const { messages } = state;
    const prevData = messages[conversationId] || [];
    messages[conversationId] = [...msgData, ...prevData];

    state.messages = {
      ...messages
    };
  },

  [SEND_MESSAGE](state, { msg, conversation }) {
    const { messages = {}, messagesUpdate = {} } = state;
    const { id: conversationId } = conversation;

    if (!messages[conversationId]) messages[conversationId] = [];

    messages[conversationId].push(msg);

    state.messages = {
      ...messages
    };

    messagesUpdate[conversationId] = Date.now();

    state.messagesUpdate = {
      ...messagesUpdate
    };
  },

  [ADD_NEW_MESSAGE_TO_CONVERSATION](state, { msg }) {
    const { conversationId } = msg;
    const { messages, newMessages, selectedConversation, messagesUpdate } = state;
    if (messages[conversationId]) {
      const hasMsg = messages[conversationId].some(m => m.id === msg.id);
      if (!hasMsg) {
        // find the latest message which have createdAt less than msg.createdAt
        let idx = messages[conversationId].length - 1;
        while (
          idx > -1 &&
          messages[conversationId][idx].createdAt.localeCompare(msg.createdAt) === 1
        )
          idx--;
        messages[conversationId].splice(idx + 1, 0, msg);
      }
    } else {
      messages[conversationId] = [msg];
    }
    state.messages = {
      ...messages
    };

    if (!selectedConversation || selectedConversation.id !== conversationId) {
      if (newMessages[conversationId]) {
        const hasMsg = newMessages[conversationId].some(m => m.id === msg.id);
        if (!hasMsg) {
          // find the latest message which have createdAt less than msg.createdAt
          let idx = newMessages[conversationId].length - 1;
          while (
            idx > -1 &&
            newMessages[conversationId][idx].createdAt.localeCompare(msg.createdAt) === 1
          )
            idx--;
          newMessages[conversationId].splice(idx + 1, 0, msg);
        }
      } else {
        newMessages[conversationId] = [msg];
      }
      state.newMessages = {
        ...newMessages
      };
    }

    messagesUpdate[conversationId] = Date.now();
    state.messagesUpdate = {
      ...messagesUpdate
    };
  },

  [UPDATE_MESSAGE_LOCAL](state, { conversationId, tempId, message }) {
    const { messages } = state;

    if (messages[conversationId]) {
      const msgs = messages[conversationId];
      const len = msgs.length;
      for (let i = len - 1; i > 0; i--) {
        if (msgs[i].id === tempId) {
          msgs[i] = { ...msgs[i], ...message };
          break;
        }
      }
      state.messages = {
        ...messages
      };
    }
  },

  [UPDATE_MESSAGE_STATUS](state, { conversationId, tempId, status }) {
    const { messages } = state;

    if (messages[conversationId]) {
      const msgs = messages[conversationId];
      const len = msgs.length;
      for (let i = len - 1; i > 0; i--) {
        if (msgs[i].id === tempId) {
          msgs[i].status = status;
          break;
        }
      }
      state.messages = {
        ...messages
      };
    }
  },

  [CLEAR_NEW_MESSAGE](state, conversationId) {
    const { newMessages } = state;
    if (newMessages[conversationId]) {
      delete newMessages[conversationId];
      state.newMessages = {
        ...newMessages
      };
    }
  },

  [SET_WEB_BROWSING_HISTORY_DATA](state, { Items = [], LastEvaluatedKey, allDataCount }) {
    const { webBrowsingHistory, wbhAllDataCount } = state;

    const filterData = Items.filter(i => i.timestamp && i.data).map(i => ({
      ...i,
      data: Object.values(i.data).sort((a, b) => a.time * 1 - b.time * 1)
    }));
    const itemRemovedCount = Items.length - filterData.length;
    state.wbhLastEvaluatedKey = LastEvaluatedKey ? LastEvaluatedKey : '';
    state.wbhAllDataCount = allDataCount
      ? allDataCount - itemRemovedCount
      : wbhAllDataCount - itemRemovedCount;

    const newWBH = [...webBrowsingHistory, ...filterData].sort(
      (a, b) => b.timestamp * 1 - a.timestamp * 1
    );
    state.webBrowsingHistory = newWBH;
  },

  [CLEAR_WEB_BROWSING_HISTORY_DATA](state) {
    state.wbhLastEvaluatedKey = '';
    state.wbhAllDataCount = 0;
    state.webBrowsingHistory = [];
  },

  [SET_SELECT_GROUP_CONV](state, group) {
    if (state.selectedGroupConv !== group) state.selectedGroupConv = group;
  },

  [SET_CONVSEARCHTEXT](state, text) {
    state.convSearchText = text;
  },

  [SET_PRODSEARCHTEXT](state, text) {
    state.prodSearchText = text;
  },

  [SET_PRODSEARCHTYPE](state, type) {
    state.prodSearchType = type;
  },

  [SET_PRODSEARCHSORT](state, sort) {
    state.prodSearchSort = sort;
  },

  [SET_LOCALES_FILTER](state, { localesFilter }) {
    state.localesFilter = localesFilter;
  },

  [SET_SEARCH_TEXT](state, { searchText }) {
    state.searchTextConversation = Object.assign([], searchText);
  },

  [SET_NOTIFY_MESSAGE](state, message) {
    state.notifyMessage = { ...message };
  },

  [SET_INIT_STATE](state) {
    state.initState = true;
  },

  [REMOVE_INIT_STATE](state) {
    state.initState = false;
  },

  [SET_SHOW_SUPPORT_PANEL](state, val) {
    state.showSupportPanel = val;
  },

  [SET_SHOW_SURVEY_PANEL](state, val) {
    state.showSurveyPanel = val;
  },

  [SET_SHOW_WEB_BROWSING_HISTORY_PANEL](state, val) {
    state.showWebBrowsingHistoryPanel = val;
  },

  [SET_SHOW_STATUS_HISTORY_PANEL](state, val) {
    state.showStatusHistoryPanel = val;
  },

  [SET_SHOW_PRODUCT_PANEL](state, val) {
    state.showProductPanel = val;
  },

  [SET_SHOW_CONVERSATIONAL_PANEL](state, val) {
    state.showConversationalPanel = val;
  },

  [SET_MONITORING](state, val) {
    state.monitoring = val;
  },

  [SET_SHOW_VIEW](state, val) {
    state.showView = val;
  },

  [SET_CHAT_VIEWING_GROUP](state, val) {
    state.chatViewingGroup = val;
  },

  [SET_SEARCH_BOX_FILTER](state, params) {
    if (params.tagName) {
      const virusTag = ['ウイルス', '病毒', '病毒'];
      const t = intersection(virusTag, params.tagName);
      params = {
        ...params,
        tagName: t.length ? uniq([...params.tagName, 'virus']) : params.tagName
      };
    }
    state.searchBoxFilter = { ...params };
  },

  [SET_SEARCH_BOX_HEIGHT](state, val) {
    state.searchBoxHeight = val;
  },
  [SET_SEARCH_BOX](state, val) {
    state.searchBox = val;
  },

  [SET_ACTIVITIES](state, { projectId, data, time }) {
    const { activitiesMap } = state;
    activitiesMap[projectId] = { ...{ data, time } };
    state.activitiesMap = { ...activitiesMap };
  },

  [CLEAR_LONGEST_TIME_BY_AGENT](state, projectId) {
    const { longestTimeByAgentMap } = state;
    delete longestTimeByAgentMap[projectId];
    state.longestTimeByAgentMap = { ...longestTimeByAgentMap };
  },

  [SET_MESSAGES_NOTIFICATION](state, message) {
    const { messagesNotification } = state;
    if (message && message.ticketId) {
      messagesNotification[message.ticketId] = { ...message };
    }

    state.messagesNotification = { ...messagesNotification };
  },

  [SET_SORT_METHOD](
    state,
    { channelId, groupId, sortBy = SORT_BY.LAST_MESSAGE, direction = 0, isSearch }
  ) {
    if (isSearch) {
      const { sortBysMap } = state;
      sortBysMap[channelId + '9'] = { sortBy, direction };
      state.sortBysMap = { ...sortBysMap };
    } else {
      const { sortBysMap } = state;
      sortBysMap[channelId + groupId] = { sortBy, direction };
      state.sortBysMap = { ...sortBysMap };
    }
  },

  [SUPPORT_WITHOUT_USER_AGREEMENT](state, value = false) {
    state.supportWithoutUserAgreement = value;
  },

  [UPDATE_MESSAGE](state, { dispatch, msg }) {
    const {
      conversationId,
      id,
      deleted,
      content = null,
      text = null,
      ticketStatus = null,
      conversationStatus = null
    } = msg;
    const { messages } = state;
    if (messages[conversationId]) {
      const updatingMsg = messages[conversationId].some(m => m.id === msg.id);
      if (updatingMsg) {
        let idx = messages[conversationId].length - 1;
        while (idx > -1 && messages[conversationId][idx].id !== id) idx--;
        messages[conversationId][idx].deleted = deleted;
        content ? (messages[conversationId][idx].content = content) : null;
        text ? (messages[conversationId][idx].text = text) : null;
        ticketStatus ? (messages[conversationId][idx].ticketStatus = ticketStatus) : null;
        conversationStatus
          ? (messages[conversationId][idx].conversationStatus = conversationStatus)
          : null;

        state.messages = {
          ...messages
        };

        if (idx == messages[conversationId].length - 1) {
          // Get Latest Msg
          let latestIdx = idx;
          while (
            latestIdx > -1 &&
            messages[conversationId][latestIdx].hasOwnProperty('deleted') &&
            messages[conversationId][latestIdx].deleted == true
          )
            latestIdx--;

          const msg = messages[conversationId][latestIdx];
          dispatch('session/updateConversationLatestMessage', msg, {
            root: true
          });
        }
      }
    }
  },

  [UPDATE_TYPING](state, { userId, text }) {
    const typing = { ...state.typing };
    if (!text || text.trim() === '') {
      delete typing[userId];
    } else {
      typing[userId] = text;
    }
    state.typing = { ...typing };
  },

  [ADD_TICKET_ACTION_LOG](state, { conversationId, ticketActionLogs }) {
    const ticketActionLogMap = state.ticketActionLogMap;
    state.ticketActionLogMap = {
      ...ticketActionLogMap,
      [conversationId]: ticketActionLogs
    };
  },
  [SET_CSFP_CHANGE_DATA](state, value) {
    state.csfpChangeData = value;
  },
  [SET_SHOW_CONFIRM_SAVED_DATA](state, value) {
    state.showConfirmSavedData = value;
  },
  [SET_PREVIOUS_ACTION](state, value) {
    state.previousAction = value;
  },

  // Overview Conversations Detail Box (S)
  [SET_CONVERSATIONS_BOX](state, value) {
    if (value && value.tickets && value.tickets.length !== 0) {
      state.conversationsBox = value;
    }
  },

  [ADD_CONVERSATIONS_BOX](state, value) {
    if (value && value.tickets) {
      let oldData = { ...state.conversationsBox };
      let newData = {};
      newData.total = value.total;
      newData.nextKey = value.nextKey;
      newData.tickets = [...oldData.tickets, ...value.tickets];
      state.conversationsBox = { ...newData };
    }
  },

  [CLEAR_CONVERSATIONS_BOX](state) {
    state.conversationsBox = {};
  },
  // Overview Conversations Detail Box (E)

  [SET_SYSTEM_DEFINED_WORD](state, data) {
    state.systemDefinedWord = data || [];
  },

  [SET_IMAGE_OF_URL_PREVIEW](state, { url, image }) {
    if (!url) return;
    const old = state.imageOfUrlPreview;
    state.imageOfUrlPreview = { ...old, [url]: image };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
