<template>
  <div class="replies mb-10">
    <div class="row">
      <div class="offset-2 col-10">
        <ul class="list-group">
          <reply-item
            v-for="(rep, idx) in replies"
            :key="idx"
            v-bind="rep"
            @edit="handleEditReply(rep)"
            @delete="handleDeleteReply(rep)"
            @onUpdateClick="handleUpdateClick(rep, $event)"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import ReplyItem from './reply-item';
import { CHANNEL_MODE } from 'core/constants';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    ReplyItem
  },

  data() {
    return {
      repMode: null,
      loading_time: null,
      newComments: null
    };
  },

  computed: {
    ...mapState('session', ['user', 'channelsMap']),
    ...mapGetters('comment', ['replies']),
    ...mapState('comment', ['selectedComment', 'selectedChannel']),

    isMaintain() {
      return (
        this.channelsMap &&
        this.selectedChannel &&
        this.channelsMap[this.selectedChannel.id].mode === CHANNEL_MODE.MAINTAIN
      );
    }
  },

  beforeDestroy() {
    clearInterval(this.newComments);
  },

  methods: {
    ...mapActions('comment', ['deleteReply', 'updateReply']),

    handleEditReply() {
      this.isMaintain ? EventBus.$emit('maintenanceModeModal') : (this.repMode = 'edit');
    },

    handleDeleteReply(rep) {
      this.isMaintain ? EventBus.$emit('maintenanceModeModal') : this.deleteReply(rep);
    },

    async handleUpdateClick(rep, { message }) {
      if (this.isMaintain) {
        EventBus.$emit('maintenanceModeModal');
        return true;
      }

      if (message.replace(/\s/g, '') === '') return;
      if (rep.sender_id === rep.page_id) { // it's the agent's reply
        Object.assign(rep, { agent_id: this.user.id, agent_name: `${this.user.firstName} ${this.user.lastName}` })
      }
      this.updateReply({ ...rep, message });
      this.repMode = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.replies {
  max-height: 400px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-group-item {
  border: none;
  border-radius: 10px;
  margin: 5px 0;

  &:last-child {
    margin-bottom: 10px;
  }
}
</style>
