<template>
  <div id="PhoneAgentSearchBox" class="form-group search-box">
    <base-select
      v-model="projectSel"
      :options="projectList"
      :option-value="'value'"
      :option-lable="'text'"
      :name="'projectSel'"
      :required="true"
      :label="$t('src.modules.search-management.index.project')"
      :value-type="'String'"
    />
    <div id="filter" class="input-group" variant="primary" style="padding-top: 10px">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa fa-search" />
        </span>
      </div>
      <input
        v-model="searchText"
        :placeholder="$t('src.modules.chat.components.AsideLeft.PhoneAgent.search-box.Search_by_Phone_Number')"
        :class="`form-control ${listFilterBadges.length > 0 ? 'not-allowed' : ''}`"
        type="text"
        :disabled="listFilterBadges.length > 0 ? true : false"
        @keyup.enter="onSearchtext"
      />
      <div class="input-group-prepend">
        <span class="input-group-text last"> </span>
      </div>
    </div>
    <div>
      <ul class="nav nav-pills">
        <li
          v-for="(badgeItem, index) in listFilterBadges"
          :key="`${badgeItem}-${index}`"
          :type="badgeItem"
          style="white-space: pre-wrap"
        >
          <filter-badge-item
            :badge-name="badgeItem.badgeName"
            :badge-type="badgeItem.badgeType"
            :badge-name-translate="badgeItem.badgeNameTranslate || ''"
            @onRemoveFilterBadge="onRemoveFilterBadge(badgeItem)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FilterBadgeItem from '../SearchBox/filter-badge-item';
import { validatePhone, convert2OneByte } from 'components/BaseComponents/vee-validate';
import { EventBus } from 'core/eventBus';
import isEqual from 'lodash/isEqual';

export default {
  components: {
    FilterBadgeItem
  },

  props: {},

  data() {
    return {
      projectSel: '',
      searchText: '',
      listFilterBadges: []
    };
  },

  computed: {
    ...mapState('session', ['projectMaps']),
    ...mapState('global', ['ready']),

    projectList() {
      if (!this.projectMaps || typeof this.projectMaps !== 'object') return [];

      let list = Object.keys(this.projectMaps).map(key => ({
        value: key,
        text: this.projectMaps[key].name
      }));

      return this.m_sortProperties(list);
    }
  },

  watch: {
    projectSel(nValue, oValue) {
      if (nValue && nValue !== oValue && this.listFilterBadges.length > 0) {
        this.enableBtnWBH(false);
        this.clearUsersInfo();
        this.clearMessagesHistory();
        this.setSelectedUserInfo({});
        this.$emit('hideAllPanel');
        history.pushState(null, null, '/');
        this.mGetUsersInfo();
      }
    },

    listFilterBadges(nValue, oValue) {
      this.updateSearchBoxHeight();
      if (nValue.length > 0 && this.projectSel) {
        this.mGetUsersInfo();
      }
      if (nValue.length === 0 && !isEqual(nValue, oValue)) {
        this.resetData();
      }
    },

    projectList(data) {
      if (data && data.length > 0 && this.projectSel === '') {
        this.projectSel = data[0].value;
      }
    },
  },

  created() {
    EventBus.$on('resetDataPhoneAgentChatBox', () => this.resetData());
  },

  mounted() {
    if (this.projectSel === '' && this.projectList.length > 0) {
      this.projectSel = this.projectList[0].value;
    }
  },

  methods: {
    ...mapActions('chat', ['setSearchBoxHeight', 'setShowWebBrowsingHistoryPanel']),
    ...mapActions('phoneAgentChatBox', [
      'enableBtnWBH',
      'getUsersInfo',
      'clearUsersInfo',
      'clearMessagesHistory',
      'setSelectedUserInfo',
      'setParamsFromSearchBox'
    ]),
    ...mapActions('global', ['setGlobalLoadingMap']),

    resetData() {
      this.searchText = '',
      this.listFilterBadges = [];
      this.enableBtnWBH(false);
      this.clearUsersInfo();
      this.clearMessagesHistory();
      this.setSelectedUserInfo({});
      this.setParamsFromSearchBox({});
      this.$emit('hideAllPanel');
      history.pushState(null, null, '/');
    },

    async mGetUsersInfo() {
      this.setGlobalLoadingMap({ action: 'PHONE_AGENT_SEARCH_USER', flag: true });
      const projectId = this.projectSel;
      const phoneNumber = this.listFilterBadges[0].badgeName;
      const usersInfo = await this.getUsersInfo({ projectId, phoneNumber });
      this.enableBtnWBH(true);
      this.setParamsFromSearchBox({ projectId, phoneNumber });
      if (usersInfo.length === 0) {
        this.setShowWebBrowsingHistoryPanel(false);
        setTimeout(() => {
          EventBus.$emit('phoneAgentHandleWebBrowsingHistoryItemClick');
        }, 0);
      }
      this.setGlobalLoadingMap({ action: 'PHONE_AGENT_SEARCH_USER', flag: false });
    },

    updateSearchBoxHeight() {
      let height = 95;
      if (this.listFilterBadges && this.listFilterBadges.length > 0) {
        const searchBoxHeight = document.getElementById('PhoneAgentSearchBox');
        height = searchBoxHeight ? searchBoxHeight.offsetHeight : 0;
      }
      this.setSearchBoxHeight(height);
    },

    onRemoveFilterBadge(badgeItem) {
      if (badgeItem.badgeType === 'phoneNumber') {
        this.listFilterBadges = [];
      }
    },

    onSearchtext() {
      this.searchText = this.searchText ? this.searchText.trim() : '';
      if (this.searchText === '') {
        this.searchText = '';
        return;
      }

      if (this.searchText.length < 2) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.search-comment.please_input_more_than_1_character'
          )
        });
        return;
      }

      if (!validatePhone(convert2OneByte(this.searchText))) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('veevalidate.validations.phoneNumber')
        });
        return;
      }

      this.listFilterBadges = [
        {
          badgeName: this.searchText,
          badgeType: 'phoneNumber'
        }
      ];

      this.searchText = '';
    },

    m_sortProperties(arrValue) {
      if (!arrValue || arrValue === null) return [];
      // sort items by value
      arrValue.sort(function (a, b) {
        let x = a.text.toLowerCase(),
          y = b.text.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue || []; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    }
  }
};
</script>

<style lang="scss" scoped>
#PhoneAgentSearchBox .last > i:nth-child(1) {
  display: none;
}

.search-box a {
  padding: 3px 3px 2px 3px;
  margin: 5px;
  margin-top: 15px;
  padding-right: 15px;
  border: none;
  position: relative;
  text-transform: inherit;
  background-color: #f9f9fc;
  color: #172b4d;
}
.search-box .badge {
  position: absolute;
  background: #f9f9fc;
  top: -6px;
  right: -15px;
  padding: 4px;
  color: #172b4d;
  border-radius: 50%;
}
.search-box .badge i {
  font-size: 0.6rem;
}
.search-box .custom-checkbox {
  width: 100%;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
