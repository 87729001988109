import { SET_ACCESS_LOGS } from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  accessLogs: []
};

const mutations = {
  [SET_ACCESS_LOGS](state, data = []) {
    state.accessLogs = [...data];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
