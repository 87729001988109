<template>
  <div class="chat-box">
    <vue-element-loading :active="user && user.id && !ready && globalReady" spiner="line-scale" />
    <!-- Chat Header (S) -->
    <chat-header
      v-bind="selectedUserInfo.userInfo"
      :is-header-close="isHeaderClose"
      :channel-name="
        (selectedUserInfo.channelId && channelsMap[selectedUserInfo.channelId].name) || ''
      "
      @heightOfChatHeader="detectHeightOfChatHeader"
      @onToggleHeaderClose="toggleHeaderClose"
    />
    <!-- Chat Header (E) -->
    <div
      id="appMsgChat"
      ref="appMsgChat"
      :class="['app-message-chats']"
      :style="{
        height: `${`-webkit-calc(100% - ${heightChatHeader}px - 30px)`}`,
        height: `${`calc(100% - ${heightChatHeader}px - 30px)`}`,
        marginTop: `calc(${heightChatHeader}px + 15px)`,
        visibility
      }"
      @scroll="handleScroll"
    >
      <!-- Message List (S) -->
      <drop class="drop position-relative">
        <div v-if="messagesHistory.length === 0" class="text-center no-data">
          <i class="fas fa-comments" style="font-size: 40px"></i>
          <p>{{ $t('common.text.no_data') }}</p>
        </div>
        <message-list
          v-else
          :loading="loading"
          :messages="messageDataSort"
          :platform="selectedUserInfo.platform"
          :in-chat-box="true"
        />
      </drop>
      <!-- Message List (E) -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChatHeader from '../ChatBox/chat-header';
import MessageList from '../ChatBox/MessageList';
import { addMessageStatus } from '../../helpers';
import pick from 'lodash/pick';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    ChatHeader,
    MessageList
  },

  data() {
    return {
      loading: false,
      isHeaderClose: false,
      heightChatHeader: 0,
      visibility: ''
    };
  },

  computed: {
    ...mapState('global', { globalReady: 'ready' }),
    ...mapState('session', ['user', 'channelsMap']),
    ...mapState('chat', ['ready']),
    ...mapState('phoneAgentChatBox', ['selectedUserInfo', 'messagesHistory']),

    messageDataSort() {
      const messageDataSort = cloneDeep(this.messagesHistory).sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      return addMessageStatus(messageDataSort, this.selectedUserInfo);
    }
  },

  watch: {},

  created() {
    EventBus.$on('scrollBottomMessagesHistory', () => {
      this.scrollToBottom();
    });
  },

  destroyed() {
    EventBus.$off('scrollBottomMessagesHistory');
  },

  methods: {
    ...mapActions('phoneAgentChatBox', ['loadMoreMessage']),
    ...mapActions('global', ['setGlobalLoadingMap']),

    detectHeightOfChatHeader(height) {
      this.heightChatHeader = height;
    },

    toggleHeaderClose() {
      this.isHeaderClose = !this.isHeaderClose;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.visibility = 'hidden';
        if (!this.$refs.appMsgChat) return this.scrollToBottom();
        return setTimeout(() => {
          const { scrollHeight } = this.$refs.appMsgChat;
          this.$refs.appMsgChat.scrollTop = scrollHeight + 200;
          if (this.$refs.appMsgChat.scrollTop === 0) this.scrollToBottom();
          this.visibility = ''
          this.setGlobalLoadingMap({ action: 'GET_MESSAGES_HISTORY', flag: false });
        }, 100);
      });
    },

    async handleScroll(evt) {
      if (!this.$refs.appMsgChat) return;
      if (evt.target.scrollTop === 0) {
        const msgLen = this.messagesHistory.length;
        if (msgLen === 0) return;
        const idx = findIndex(this.messagesHistory, item => item.createdAt && item.conversationId);

        const msg = this.messagesHistory[idx];

        const { ticket = {}, id: conversationId } = this.selectedUserInfo || {};
        const { projectId = '' } = ticket || {};
        this.loading = true;
        await this.loadMoreMessage({
          projectId,
          conversationId,
          nextKey: pick(msg, ['sk', 'conversationId'])
        });
        this.loading = false;
        this.$refs.appMsgChat.scrollTop = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-typing {
  position: absolute;
  bottom: 65px;
  left: 40px;
  font-size: 0.8em;
}
.app-message-upload {
  bottom: 90px;
  width: 100%;
  text-align: center;
}
.message-list-card {
  /deep/.video-js {
    max-width: 490px;
  }
}
.message-list-card {
  height: 500px;
  overflow: auto;
}
.message-list-card .chats {
  margin-top: 15px;
}
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}
</style>
