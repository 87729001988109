import Service from 'core/service';
import { SET_ACTION_LOGS } from './types';

const service = new Service();

export const getTicketActionLogs = async ({ commit }, params) => {
  const data = await service.post('ticketActionLogs/getTicketActionLogs', {
    ...params
  });
  commit(SET_ACTION_LOGS, { params, data });
  return data;
};
