import { TICKET_STATUS, TICKET_STATE } from 'core/constants';
import _ from 'lodash';

const commons = [
  { state: TICKET_STATE.REQUEST, status: TICKET_STATUS.REQUEST_AGENT },
  { state: TICKET_STATE.REQUEST, status: TICKET_STATUS.WAITING_TIMEOUT }
];

const meWaitingStates = [TICKET_STATE.REQUEST, TICKET_STATE.SUPPORTING, TICKET_STATE.START];

const meWaitingStatus = [
  TICKET_STATUS.WAITING_TIMEOUT,
  TICKET_STATUS.ASSIGN_TO,
  TICKET_STATUS.TRANSFER_TO,
  TICKET_STATUS.ESCALATE_TO,
  TICKET_STATUS.RESPONSE_TIMEOUT
];

const agentStatus = [
  TICKET_STATUS.AGENT_SUPPORTING,
  TICKET_STATUS.HOLDING,
  TICKET_STATUS.AGENT_IDLE,
  TICKET_STATUS.USER_IDLE,
  TICKET_STATUS.USER_FINISH,
  TICKET_STATUS.AGENT_FINISH,
  TICKET_STATUS.RESPONSE_TIMEOUT,
  TICKET_STATUS.AGENT_START,
  TICKET_STATUS.FINISH_TIMEOUT
];

const isBotHandle = ticket => {
  if (!ticket) return false;
  return ticket.status === TICKET_STATUS.BOT_HANDLE || ticket.status === TICKET_STATUS.REQUEST_USER;
};

const isCommonWaiting = (ticket, meGroups) => {
  if (!ticket) return false;
  return (
    _.findIndex(
      commons,
      c =>
        (c.state === ticket.state &&
          c.status === ticket.status &&
          ticket.assignee === ticket.id &&
          ticket.agentId === ticket.id &&
          ((ticket.groupId && meGroups.includes(ticket.groupId)) || !ticket.groupId)) ||
        (ticket.assignee === TICKET_STATUS.FORWARD_GROUP &&
          ticket.state !== TICKET_STATE.COMPLETE &&
          ticket.groupId &&
          meGroups.includes(ticket.groupId))
    ) >= 0
  );
};

const isMeWaiting = (ticket, agent) => {
  if (!ticket || !agent) return false;
  if (ticket.assignee !== agent.id || ticket.agentId === agent.id) return false;
  return meWaitingStates.includes(ticket.state) && meWaitingStatus.includes(ticket.status);
};

const isAgentHandle = (ticket, agent) => {
  if (!ticket || !agent) return false;
  if (ticket.assignee !== agent.id || ticket.agentId !== agent.id) return false;
  if (ticket.state === TICKET_STATE.COMPLETE) return false;
  return agentStatus.includes(ticket.status);
};

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

const ticketAgentHandle = (ticket, userId, channelId) => {
  if (!ticket) return false;
  const result = Object.filter(
    ticket,
    c => agentStatus.includes(c.status) && c.userId === userId && c.channelId === channelId
  );
  return Object.keys(result);
};

const ticketCommonWaiting = (ticket, userId, channelId) => {
  if (!ticket) return false;
  const result = Object.filter(
    ticket,
    c =>
      ['REQUEST_AGENT', 'WAITING_TIMEOUT'].includes(c.status) &&
      c.state === 'REQUEST' &&
      c.userId === userId &&
      c.channelId === channelId
  );
  return Object.keys(result);
};

const ticketMeWaiting = (ticket, userId, channelId) => {
  if (!ticket) return false;
  const result = Object.filter(
    ticket,
    c =>
      meWaitingStates.includes(c.state) &&
      meWaitingStatus.includes(c.status) &&
      c.userId === userId &&
      c.channelId === channelId
  );
  return Object.keys(result);
};

export const ticketHandler = {
  isBotHandle,
  isCommonWaiting,
  isMeWaiting,
  isAgentHandle,
  ticketAgentHandle,
  ticketCommonWaiting,
  ticketMeWaiting
};
