<template>
  <b-modal
    id="AgentWorkingDetails"
    ref="agentWorkingDetails"
    v-model="show"
    size="lg"
    :title="$t('src.modules.chat.components.OverviewBox.agentWorkingDetails.agent_working_details')"
    @hidden="resetAllState"
  >
    <div v-loading="isLoading" class="agentWorkingDetails-body">
      <div class="date-div">
        <el-row>
          <el-col :span="colWidthDate">
            <base-date-picker
              :prefix="'agentWorkingDetails'"
              v-model="selectedDate"
              :required="true"
              :rules="'birthday'"
              :label="
                $t(
                  'src.modules.chat.components.OverviewBox.agentWorkingDetails.please_select_a_date'
                )
              "
            />
          </el-col>
          <el-col :span="6">
            <b-btn class="search-btn" variant="primary" @click="agentWorkingDetails">
              {{ $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.search') }}</b-btn
            >
          </el-col>
        </el-row>
      </div>
      <div class="mg-t-15">
        <div class="export-title">
          <span>{{
            $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.agent_status_report')
          }}</span>
          <label>
            {{
              $t(
                'src.modules.chat.components.OverviewBox.agentWorkingDetails.click_the_export_button'
              )
            }}</label
          >
        </div>
        <b-btn class="export-btn" variant="primary" :disabled="isExporting" @click="exportData">
          <span
            >{{ $t('src.core.router.export') }}
            <i v-if="isExporting" class="fas fa-spinner fa-spin fa-lg" />
          </span>
        </b-btn>
      </div>
      <div class="mg-t-15 telework-evidence">
        <span class="telework-evidence-title">{{
          $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.telework_evidence')
        }}</span>
        <label
          v-if="msgLimitStorage.length > 0"
          :class="{ 'text-warning-over': overLimit }"
          class="telework-evidence-warning"
        >
          {{ msgLimitStorage }}
        </label>
        <div class="cameral-capture">
          <label class="cameral-capture-title">{{
            $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.cameral_capture')
          }}</label>
          <template v-if="cameralCapture.length <= 0">
            <p>
              {{ $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.no_image') }}
            </p>
          </template>
          <template v-else>
            <div id="cameral-capture-carousel">
              <VueSlickCarousel
                :key="vueSlickCarouselKey"
                v-bind="carouselSettings"
                @afterChange="afterChangeCameralCapture"
              >
                <div v-for="(item, index) in cameralCapture" :key="item.url + index">
                  <img :src="item.url" @error="$event.target.src = imageNotFound" />
                </div>
              </VueSlickCarousel>
            </div>
            <div class="detail-cameral-capture">
              <label>{{ convertTimeUTC(selectedCameralCapture.createdAt) }}</label>
              <img
                v-img
                :src="selectedCameralCapture.url"
                :style="{ 'max-width': '300px', 'padding-bottom': '10px' }"
                @error="$event.target.src = imageNotFound"
              />
              <label>{{ selectedCameralCapture.index + 1 }}/{{ cameralCapture.length }}</label>
            </div>
          </template>
        </div>
        <div class="desktop-capture">
          <label class="desktop-capture-title">{{
            $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.desktop_capture')
          }}</label>
          <template v-if="desktopCapture.length <= 0">
            <p>
              {{ $t('src.modules.chat.components.OverviewBox.agentWorkingDetails.no_image') }}
            </p>
          </template>
          <template v-else>
            <div id="desktop-capture-carousel">
              <VueSlickCarousel
                :key="vueSlickCarouselKey"
                v-bind="carouselSettings"
                @afterChange="afterChangeDesktopCapture"
              >
                <div v-for="(item, index) in desktopCapture" :key="item.url + index">
                  <img :src="item.url" @error="$event.target.src = imageNotFound" />
                </div>
              </VueSlickCarousel>
            </div>
            <div class="detail-cameral-capture">
              <label>{{ convertTimeUTC(selectedDesktopCapture.createdAt) }}</label>
              <img
                v-img
                :src="selectedDesktopCapture.url"
                :style="{ 'max-width': '300px', 'padding-bottom': '10px' }"
                @error="$event.target.src = imageNotFound"
              />
              <label>{{ selectedDesktopCapture.index + 1 }}/{{ desktopCapture.length }}</label>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right" variant="default" @click="resetAllState">
        {{ $t('common.confirmModal.close') }}</b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { EventBus } from 'core/eventBus';
import { mapActions, mapState } from 'vuex';
import { convertTimeUTC, buildTemplate, getAgentName } from 'core/helpers';
import {
  multi_language_provider,
  sub_title,
  sheet_title,
  agent_status_labels
} from '../OverviewBox/languages/AgentStatusLog';
import imageNotFound from 'assets/images/image.png';
import moment from 'moment';
import * as download from 'downloadjs';
import { isEmpty } from 'lodash';
import { LOG_REPORT_TYPE, REPORT_SCHEDULE_TYPE, RESULT_REPORT_TYPE } from 'core/constants';

function defaultState() {
  return {
    show: false,
    isLoading: false,
    agentId: '',
    projectId: '',
    agentName: '',
    projectName: '',
    colWidthDate: 6,
    selectedDate: new Date().toISOString(),
    vueSlickCarouselKey: 0,
    carouselSettings: {
      centerMode: true,
      centerPadding: '20px',
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 5,
      speed: 500
    },
    selectedCameralCapture: {},
    selectedDesktopCapture: {},
    cameralCapture: [],
    desktopCapture: [],
    imageNotFound,
    isExporting: false,
    msgLimitStorage: '',
    overLimit: false
  };
}

export default {
  components: { VueSlickCarousel },

  data: defaultState,

  computed: {
    ...mapState('session', ['projectMaps', 'user'])
  },

  created() {
    EventBus.$on('agentWorkingDetails', params => {
      const { agentId, agentName, projectId, projectName } = params || {};
      this.show = true;
      this.agentId = agentId;
      this.projectId = projectId;
      this.agentName = agentName;
      this.projectName = projectName;
      this.onResize();
      this.agentWorkingDetails();
    });
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  destroyed() {
    EventBus.$off('agentWorkingDetails', () => (this.show = false));
  },

  methods: {
    ...mapActions('chat', ['getTeleWorkEvidence', 'getAgentStatusLog', 'isOverLimitStorage']),
    ...mapActions('report', ['createLogForExport']),
    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    async agentWorkingDetails() {
      this.isLoading = true;
      this.isCheckOverLimitStorage();
      const teleworkEvidence = await this.getTeleWorkEvidence({
        agentId: this.agentId,
        projectId: this.projectId,
        selectedDate: moment(this.selectedDate).format('YYYY-MM-DD')
      });

      this.cameralCapture = teleworkEvidence.cameraCaptures || [];
      this.desktopCapture = teleworkEvidence.screenshots || [];
      this.selectedCameralCapture = this.cameralCapture.length
        ? { ...this.cameralCapture[0], index: 0 }
        : {};
      this.selectedDesktopCapture = this.desktopCapture.length
        ? { ...this.desktopCapture[0], index: 0 }
        : {};
      this.vueSlickCarouselKey += 1;
      this.isLoading = false;
    },

    async isCheckOverLimitStorage() {
      const result = await this.isOverLimitStorage({
        projectIds: [this.projectId]
      });
      if (result && result.storage && result.storage.length) {
        const { limit, volume, isUsingTeleWork } = result.storage[0];
        if (isUsingTeleWork) {
          const storaged = volume ? (volume / limit.value) * 100 : null;
          if (storaged && storaged >= 90 && storaged < 99) {
            this.msgLimitStorage = this.$t(
              'src.modules.chat.components.OverviewBox.agentWorkingDetails.telework-evidence.warning-limit-storage'
            );
          } else if (storaged >= 99) {
            this.overLimit = true;
            this.msgLimitStorage = this.$t(
              'src.modules.chat.components.OverviewBox.agentWorkingDetails.telework-evidence.warning-over-limit-storage'
            );
          } else {
            this.msgLimitStorage = '';
          }
        }
      }
    },

    async exportData() {
      const selectedDate = moment(this.selectedDate);
      this.isExporting = true;
      const statusLogs = await this.getAgentStatusLog({
        project: { id: this.projectId, name: this.projectName },
        agent: { id: this.agentId, name: this.agentName },
        startDate: selectedDate.startOf('day').toISOString(),
        endDate: selectedDate.endOf('day').toISOString()
      });

      const { id, email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
      const actionLogCreate = {
        userId: id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        projectId: this.projectId,
        type: REPORT_SCHEDULE_TYPE.IMMEDIATE,
        fromDate: moment(this.selectedDate).format('YYYY-MM-DD 00:00:00'),
        toDate: moment(this.selectedDate).format('YYYY-MM-DD 23:59:59'),
        exportFormat: 'excel',
        reportType: LOG_REPORT_TYPE.AGENT_STATUS_LOG,
        system: 'at'
      };

      if (isEmpty(statusLogs)) {
        this.$baseNotification.info({
          title: this.$t('src.core.App.info'),
          message: this.$t('src.modules.report.components.index.no_data_to_export')
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: 'No data'
        });
        return (this.isExporting = false);
      }

      try {
        const excelData = await this.buildSheetData(statusLogs);
        const file = await buildTemplate(excelData);
        const fileName = 'agent_status_log.xlsx';
        const Blob = require('blob');
        const blob = new Blob([file], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        });
        download(blob, fileName);
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.SUCCESS
        });
        return (this.isExporting = false);
      } catch (err) {
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: (err && err.message) || 'Export failed'
        });
      }
    },

    async buildSheetData(statusLogs) {
      const { agentStatus, workingHours, project, agent, date } = statusLogs || {};
      const language = localStorage.getItem('language');
      const exportTitle = multi_language_provider[language];
      const statusLabels = agent_status_labels[language];
      const sheetName = sheet_title[language].sheetName;

      const rowData = agentStatus.map((el, key) => {
        return {
          no: key + 1,
          updatedAt: convertTimeUTC(el.createdAt, 'export'),
          status: statusLabels[el.status] || el.status
        };
      });

      let title = Object.values(exportTitle);
      if (agentStatus.length) {
        let firstRowKey = Object.keys(rowData[0]);
        title = firstRowKey;
        if (exportTitle) {
          title = firstRowKey.reduce((c, k) => {
            const col = exportTitle[k] || k;
            c.push(col);
            return c;
          }, []);
        }
      }

      const arr = rowData.map(row => Object.values(row));

      const exportDataSubTitle = {
        projectName: project && project.name ? project.name : '',
        fromDate: moment(date).format('YYYY-MM-DD'),
        toDate: moment(date).format('YYYY-MM-DD'),
        agentName: agent && agent.name ? agent.name : '',
        workingHours: workingHours ? Math.round((workingHours / 3600) * 100) / 100 : 0
      };
      const configs = {
        subTitle: Object.values(sub_title[language]),
        dataSubTitle: Object.values(exportDataSubTitle),
        sheetTitle: sheet_title[language].title,
        indexRowTitle: 1,
        indexRowSubTitle: 3,
        indexRowData: 9
      };

      return [{ title, data: arr, sheetName: sheetName.slice(0, 30), configs }];
    },

    afterChangeCameralCapture(index) {
      this.selectedCameralCapture = { ...this.cameralCapture[index], index };
    },

    afterChangeDesktopCapture(index) {
      this.selectedDesktopCapture = { ...this.desktopCapture[index], index };
    },

    convertTimeUTC(time) {
      return convertTimeUTC(time);
    },

    onResize() {
      if (window.innerWidth < 992) {
        if (window.innerWidth < 450) this.colWidthDate = 10;
        else this.colWidthDate = 8;
        this.carouselSettings = { ...this.carouselSettings, slidesToShow: 3 };
      } else {
        this.colWidthDate = 6;
        this.carouselSettings = { ...this.carouselSettings, slidesToShow: 5 };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.search-btn {
  height: 40px;
  margin-left: 5px;
  margin-top: 28px;
}

.mg-t-15 {
  margin-top: 15px;
}

.export-title {
  display: flex;
  flex-direction: column;

  span {
    font-weight: 700;
    color: #7ea6e0;
  }
}

label {
  font-weight: 600;
  font-size: 0.95rem;
}

.telework-evidence-title {
  font-weight: 700;
  color: #7ea6e0;
}

.telework-evidence-warning {
  font-weight: 450;
  font-style: italic;
  color: orange;
}

.text-warning-over {
  color: red !important;
}

.detail-cameral-capture {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}
</style>
<style lang="scss">
#cameral-capture-carousel,
#desktop-capture-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;

  .slick-slider {
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px;

    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .slick-current {
      border: 1px solid #dcdfe6;
    }

    img {
      height: 80px;
      width: 80px;
    }

    .slick-next:before {
      color: #dae8fc;
    }

    .slick-prev:before {
      color: #dae8fc;
    }
  }
}
</style>
