<template>
  <div class="survey-form-item">
    <div class="survey-title-item">
      <span v-if="item.rules && item.rules.required" class="icon-require">*</span>
        {{ item.question }}
    </div>
    <label class="d-block">
      {{ item.description }}
    </label>
    <b-form-group v-slot="{ ariaDescribedby }" :disabled="true">
      <b-form-radio
        v-for="(val, index) in (item.attributes && item.attributes.options) || []"
        :key="index"
        v-model="item.answer" 
        :aria-describedby="ariaDescribedby" 
        :name="'radios-' + val.value" 
        :value="val.value"
        class="mt-2"
      >
        {{ val.label }}
        <div 
          v-if="val.value === item.answer"
          class="sv-custom-answer"
        >
          {{ item.answerCustom && item.answerCustom[val.value] }}
        </div>
      </b-form-radio>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss"></style>