import { SERVING_STATE } from 'core/constants';
import { getActions } from 'core/helpers';

const messages = state => state.chatList;
const conversationsBox = state => state.conversationsBox;
const msgCount = state => state.chatList.length;
const selectedTicketIdConv = state =>
  (state.selectedConversation && state.selectedConversation.ticketId) || '';

const selectedConversation = (state, getters, rootState, rootGetters) => {
  const { selectedUserInfo } = rootState.phoneAgentChatBox;
  const isPhoneAgent = rootGetters['session/isPhoneAgent']
  if (isPhoneAgent) {
    return selectedUserInfo;
  }

  let { selectedConversation } = state;

  const { tickets, conversations } = rootState.session;

  const { ticketId, id, ticket: ownTicket, eventType } = selectedConversation || {};
  if (!id) return selectedConversation;
  if (!ticketId) {
    if (tickets) {
      const ticketsSort = Object.values(tickets).filter(tk => tk.conversationId === id);
      ticketsSort.sort((a, b) => b.createdAt - a.createdAt);
      const ticket = ticketsSort.find(tk => tk.conversationId === id);
      if (ticket) selectedConversation = { ...selectedConversation, ticketId: ticket.id, ticket };
    }
  }

  const conv =
    (rootGetters &&
      rootGetters['session/conversationsMap'] &&
      rootGetters['session/conversationsMap'][id]) ||
    {};
  const curConversation =
    conversations.find(c => c.id === selectedConversation.id) || selectedConversation;
  const userInfo = { ...(conv.userInfo || {}), ...(curConversation.userInfo || {}) };

  if (tickets && tickets[ticketId] && !ownTicket) {
    return {
      ...conv,
      ...selectedConversation,
      ticket: tickets[ticketId],
      eventType,
      userInfo
    };
  }
  return {
    ...conv,
    ...selectedConversation,
    ticket: tickets[ticketId] || ownTicket,
    eventType,
    userInfo
  };
};

const actionTicketMaps = (state, getters, rootState) => {
  const { user, tickets, projectMaps } = rootState.session;
  return Object.values(tickets).reduce((maps, ticket) => {
    maps[ticket.id] = getActions(user, ticket, projectMaps);
    return maps;
  }, {});
};

const currentConversationHandlingBy = state => {
  const { selectedConversation } = state;
  if (!selectedConversation) return -1;
  const { botId, agentId } = selectedConversation;
  if (!botId && !agentId) return SERVING_STATE.WAITING;
  else if (botId) return SERVING_STATE.BOT;
  else if (agentId) return SERVING_STATE.AGENT;
  return -1;
};

const conversationFeedsSearch = state => {
  let object = {
    conversationsFAQ: {},
    conversationsNLP: {}
  };
  const { conversationFeeds, convSearchText, selectedConversation, searchFaqWithLanguage } = state;
  if (
    !searchFaqWithLanguage ||
    !conversationFeeds ||
    Object.keys(conversationFeeds).length === 0 ||
    !selectedConversation ||
    Object.keys(selectedConversation).length === 0
  )
    return object;
  const { channelId } = selectedConversation;
  if (!channelId) return object;
  const conversationFeedsByChannel = conversationFeeds[channelId];
  if (!conversationFeedsByChannel || !conversationFeedsByChannel[searchFaqWithLanguage])
    return object;
  const { conversationsFAQ = {}, conversationsNLP = {} } = conversationFeedsByChannel[
    searchFaqWithLanguage
  ];
  if (Object.keys(conversationsFAQ).length === 0 && Object.keys(conversationsNLP).length === 0)
    return object;

  if (convSearchText === '') return conversationFeedsByChannel[searchFaqWithLanguage];
  for (let key in conversationsFAQ) {
    if (conversationsFAQ.hasOwnProperty(key)) {
      const filter = conversationsFAQ[key].filter(
        c =>
          `${c.answer}`.toLowerCase().includes(convSearchText.toLowerCase()) ||
          `${c.question}`.toLowerCase().includes(convSearchText.toLowerCase())
      );
      object.conversationsFAQ[key] = filter;
    }
  }
  for (let key in conversationsNLP) {
    if (conversationsNLP.hasOwnProperty(key)) {
      const filter = conversationsNLP[key].filter(
        c =>
          `${c.answer}`.toLowerCase().includes(convSearchText.toLowerCase()) ||
          `${c.question}`.toLowerCase().includes(convSearchText.toLowerCase())
      );
      object.conversationsNLP[key] = filter;
    }
  }
  return object;
};

const productFeedsSearch = state => {
  let object = {};
  const {
    productFeeds,
    prodSearchText,
    selectedConversation,
    prodSearchType,
    prodSearchSort
  } = state;
  if (
    !productFeeds ||
    Object.keys(productFeeds).length === 0 ||
    !selectedConversation ||
    Object.keys(selectedConversation).length === 0
  )
    return object;
  const { channelId } = selectedConversation;
  if (!channelId) return object;
  const productFeedsByChannel = productFeeds[channelId];

  if (!productFeedsByChannel || Object.keys(productFeedsByChannel).length === 0) return object;
  for (let key in productFeedsByChannel) {
    if (productFeedsByChannel.hasOwnProperty(key)) {
      const _prodSearchType = prodSearchType ? prodSearchType : 'name';

      const filter = productFeedsByChannel[key].filter(c =>
        `${c['name']}`.toLowerCase().includes(prodSearchText.toLowerCase())
      );
      const _sort = sort({
        prop: _prodSearchType,
        order: prodSearchSort,
        data: filter
      });

      object[key] = _sort;
    }
  }
  return object;
};

const sort = ({ data, prop, order }) => {
  if (prop && order) {
    return data.sort((a, b) => {
      try {
        let left = a[prop].toLowerCase();
        let right = b[prop].toLowerCase();
        switch (prop) {
          case 'price':
            left = Number(left.replace(/[^\d]/g, ''));
            right = Number(right.replace(/[^\d]/g, ''));
            break;
          default:
            break;
        }
        if (left < right) return -1 * order;
        if (left > right) return 1 * order;
        return 0;
      } catch (err) {
        return 0;
      }
    });
  }
  return data;
};

const localesFilter = state => state.localesFilter;

const actions = (state, getters, rootState) => {
  const { projectMaps, user } = rootState.session;
  const { ticket } = getters.selectedConversation || {};
  const actions = getActions(user, ticket, projectMaps);
  return actions;
};
/**
 * new code
 */

const userLabels = state => {
  return state.userLabels;
};
const convsLabels = state => {
  return state.convsLabels;
};
const userLabelsByProject = state => {
  return state.userLabels;
};
const convsLabelsByProject = state => {
  return state.convsLabels;
};
/** end new code */

export {
  userLabelsByProject,
  convsLabelsByProject,
  userLabels,
  convsLabels,
  messages,
  msgCount,
  selectedTicketIdConv,
  selectedConversation,
  currentConversationHandlingBy,
  conversationFeedsSearch,
  productFeedsSearch,
  localesFilter,
  actions,
  actionTicketMaps,
  conversationsBox
};
