import fetchAPI from 'core/fetchAPI';

export class ScenarioService extends fetchAPI {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_SE_API_URL}/`,
      headers: {
        'x-api-key': process.env.VUE_APP_SE_API_KEY
      },
      _options: {}
    });
  }

  async getCampaigns(projectId, groupId = '', type) {
    const path = type === 'BOT' ? 'BOT' : `AT/${groupId}`
    return this.get(`campaign/getCampaigns/${projectId}/${path}`)
      .then(resData => {
        const rtrData = resData.data && Array.isArray(resData.data) ? resData.data : [];
        return rtrData;
      })
      .catch(() => []);
  }

  getScenarios(campaignId, platform, language) {
    return this.get(
      `scenario/getScenariosByCampaign/${campaignId}/${platform}/${language}`
    )
      .then(resData => {
        const rtrData = [];
        const keys = resData.data ? Object.keys(resData.data) : [];
        if (keys.length > 0) {
          keys.forEach(scenarioId => {
            rtrData.push(resData.data[scenarioId]);
          });
        }
        return rtrData;
      })
      .catch(() => []);
  }
  getScenarioContent(campaignId, platform, language) {
    return this.get(`scenario/getScenarioContent/${campaignId}/${platform}/${language}`)
      .then(resData => {
        const rtrData = [];
        const keys = resData.data ? Object.keys(resData.data) : [];
        if (keys.length > 0) {
          keys.forEach(scenarioId => {
            rtrData.push(resData.data[scenarioId]);
          });
        }
        return rtrData;
      })
      .catch(() => []);
  }
  getBotScenarios(campaignId, platform, language) {
    return this.get(
      `scenario/getScenariosByCampaign/${campaignId}/${platform}/${language}`
    )
      .then(resData => {
        const rtrData = [];
        const keys = resData.data ? Object.keys(resData.data) : [];
        if (keys.length > 0) {
          keys.forEach(scenarioId => {
            rtrData.push(resData.data[scenarioId]);
          });
        }
        return rtrData;
      })
      .catch(() => []);
  }
}
