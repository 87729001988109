import Service from 'core/service';
import {
  PIN_RIGHT_PANEL,
  PIN_RIGHT_PRODUCT_PANEL,
  PIN_RIGHT_CONVERSATION_PANEL,
  PIN_RIGHT_CUSTOMER_SUPPORT_PANEL,
  PIN_RIGHT_CUSTOMER_SURVEY_PANEL,
  SET_GLOBAL_READY,
  SET_OFFLINE,
  SET_TERMINATE,
  SET_SYNC_TIME,
  SET_SYNCING,
  SET_SYNC_ERROR,
  SET_GLOBAL_LOADING_MAP,
  PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL,
  PIN_RIGHT_STATUS_HISTORY_PANEL
} from 'modules/global/store/types';

const service = new Service();

const pinRightPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_PANEL, flag);
};

const pinRightProductPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_PRODUCT_PANEL, flag);
};

const pinRightConversationPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_CONVERSATION_PANEL, flag);
};

const pinRightCustomerSupportPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_CUSTOMER_SUPPORT_PANEL, flag);
};

const pinRightCustomerSurveyPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_CUSTOMER_SURVEY_PANEL, flag);
};

const pinRightWebBrowsingHistoryPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL, flag);
};

const pinRightStatusHistoryPanel = ({ commit }, flag) => {
  commit(PIN_RIGHT_STATUS_HISTORY_PANEL, flag);
};

const setGlobalReady = ({ commit }, flag) => {
  commit(SET_GLOBAL_READY, flag);
};

const setOffline = ({ commit }, isOffline = false) => {
  commit(SET_OFFLINE, isOffline);
};

const setTerminate = ({ commit }, isTerminate = false) => {
  commit(SET_TERMINATE, isTerminate);
};

const syncData = async (context, { time = null } = {}) => {
  const { state, commit, dispatch } = context;
  const { rootState, rootGetters } = context;
  if (state.isOffline) return false;
  try {
    const { projectMaps = {} } = rootState.session;
    commit(SET_SYNCING, true);
    await new Promise(resolve => setTimeout(resolve, 3000));
    let projects = Object.keys(projectMaps).map(projectId => ({ projectId }));
    if (projects.length > 0) {
      const to = time ? time.toISOString() : state.lastOfflineTime.toISOString();
      projects = await processSync(context, projects, to);
      while (projects && projects.length > 0) projects = await processSync(context, projects, to);
    }

    const { id = '', ticket } = rootGetters['chat/selectedConversation'] || {};
    const { projectId = '' } = ticket || {};
    if (id) {
      const action = 'chat/getMessagesByConversationId';
      dispatch(action, { id, projectId }, { root: true });
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    commit(SET_SYNC_TIME, new Date());
    commit(SET_SYNCING, false);
    commit(SET_SYNC_ERROR, false);
    return true;
  } catch (error) {
    commit(SET_SYNCING, false);
    commit(SET_SYNC_ERROR, error);
    // eslint-disable-next-line
    console.log('TCL: [syncData] >> error', error);
    return false;
  }
};

const processSync = async (context, projects, to) => {
  let rollback = false;
  return service
    .rest('conversations/syncData', { projects, to })
    .then(data => {
      let resProjects = [];
      if (Array.isArray(data)) {
        data.forEach(project => {
          const { ticketMaps, messages, conversations, projectId, nextToken } = project || {};
          if (Array.isArray(conversations)) {
            conversations.forEach(conversation => {
              context.dispatch('session/addConversationLocal', conversation, {
                root: true
              });
            });
          }
          if (typeof ticketMaps === 'object') {
            Object.values(ticketMaps).forEach(ticket => {
              context.dispatch('session/addTicketLocal', { ticket }, { root: true });
            });
          }
          if (Array.isArray(messages)) {
            messages.forEach(ticketMessages => {
              if (Array.isArray(ticketMessages)) {
                ticketMessages.forEach(message => {
                  context.dispatch('chat/addNewMessage', message, { root: true });
                });
              }
            });
          }
          if (nextToken) {
            resProjects.push({ projectId, nextToken });
          }
        });
      }
      return resProjects;
    })
    .catch(() => {
      // Call API again if has a error (just 1 times)
      if (rollback) return [];
      return new Promise(resolve => {
        setTimeout(async () => {
          rollback = true;
          const response = await processSync(context, projects, to);
          resolve(response);
        }, 5000);
      });
    });
};

const setGlobalLoadingMap = ({ commit }, { action, flag }) => {
  commit(SET_GLOBAL_LOADING_MAP, { action, flag });
};

export {
  pinRightPanel,
  pinRightProductPanel,
  pinRightConversationPanel,
  pinRightCustomerSupportPanel,
  pinRightCustomerSurveyPanel,
  pinRightWebBrowsingHistoryPanel,
  pinRightStatusHistoryPanel,
  setGlobalReady,
  setOffline,
  setTerminate,
  syncData,
  setGlobalLoadingMap
};
