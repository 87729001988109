<template>
  <div v-loading="loading" :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper cs-form">
      <div class="card-header">
        <panel-tools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="closePanel"
        />
        <h3 class="card-title mb-0">
          <span>
            {{ $t('src.modules.chat.components.AsideRight.index.web_browsing_history') }}
          </span>
        </h3>
      </div>
      <div class="card-body">
        <div v-if="loadMoreNumber > 0" ref="loadMoreBtn" class="load-more-btn m-b-5">
          <el-badge :value="loadMoreNumber" class="item">
            <el-button size="small" @click="loadMoreWebHistory">{{
              $t('src.modules.search-management.index.load_more')
            }}</el-button>
          </el-badge>
        </div>
        <div v-if="webBrowsingHistory.length <= 0" class="no-data">
          {{ $t('common.text.no_data') }}
        </div>
        <div
          ref="webhistoryBodyContent"
          class="webhistory-body-content"
          :style="`height: calc(100vh - 160px - ${heightLoadMoreBtn}px) !important;`"
        >
          <div
            v-if="
              !inViewTotal &&
                inViewPartial &&
                widthHeaderCard > 0 &&
                collapseOpened[collapseKeyOpened] &&
                collapseOpened[collapseKeyOpened].timestamp
            "
            class="header-fixed card-header"
            :style="`width: ${widthHeaderCard}px; border-right-width: 0px;`"
            @click="handleClick(collapseKeyOpened)"
          >
            <h6 class="mb-0">
              <i class="fa fa-chevron-down" :style="color(collapseOpened[collapseKeyOpened])"></i>
              <span :style="color(collapseOpened[collapseKeyOpened])">{{
                formatTime(collapseOpened[collapseKeyOpened].timestamp * 1)
              }}</span>
            </h6>
          </div>
          <b-card
            v-for="(item, index) in paginatedData"
            :key="'wbh' + item.timestamp + index"
            :id="'wbh' + item.timestamp + index"
            :ref="'wbh' + item.timestamp + index"
            no-body
            header-tag="header"
            :header-class="`${'card-header-wbh-collapse-' + item.timestamp + index}`"
            role="tab"
            class="webhistory-body-content-card"
            aria-controls
          >
            <h6
              slot="header"
              v-b-toggle="`${'wbh-collapse-' + item.timestamp + index}`"
              class="mb-0"
            >
              <i
                :style="color(item)"
                :class="
                  arrowCollapse['wbh-collapse-' + item.timestamp + index]
                    ? `fa fa-chevron-down`
                    : `fa fa-chevron-right`
                "
              ></i>
              <span :style="color(item)">{{ formatTime(item.timestamp * 1) }}</span>
            </h6>
            <b-collapse
              :id="'wbh-collapse-' + item.timestamp + index"
              :ref="'wbh-collapse-' + item.timestamp + index"
              accordion="webhistory"
              role="tabpanel"
              @show="handleOpenCollapse('wbh-collapse-' + item.timestamp + index, item, index)"
              @hide="handleCloseCollapse('wbh-collapse-' + item.timestamp + index)"
              :style="
                `max-height: calc(100vh - 163px - ${heightHeaderCard}px - ${heightLoadMoreBtn}px); overflow-y: auto`
              "
            >
              <b-card-body class="webhistory-content">
                <template v-if="item.data">
                  <div v-for="(content, i) in item.data" :key="i" style="width: 100%;">
                    <a rel="noopener noreferrer">
                      <div :title="content.url" class="chat-type link-preview">
                        <div v-loading="loadingImage[content.url]" class="image-preview">
                          <img :src="imageOfUrlPreview[content.url] || noImage" @error="$event.target.src = noImage"/>
                        </div>
                        <div class="second-section" :style="backgroundColor(content)">
                          <div v-if="content.title" class="title wrap-text">
                            <span>{{ content.title }}</span>
                            <i
                              :id="`page-info-${item.timestamp}-${i}`"
                              :style="{ cursor: 'pointer', float: 'right' }"
                              class="el-icon-copy-document"
                              @click="copyPageInfo(content, item, i)"
                            ></i>
                            <BootstrapTooltipCustom
                              :id="`page-info-${item.timestamp}-${i}`"
                              :content="tooltipCopyText[`page-info-${item.timestamp}-${i}`]"
                            />
                          </div>
                          <div class="description-container">
                            <div v-if="content.note" class="description two-line-text">
                              <span>{{ content.note }}</span>
                            </div>
                          </div>
                          <div class="url-container">
                            <img v-if="content.icon" :src="content.icon" class="icon" />
                            <div
                              @click="openNewWindow(content.url)"
                              v-if="content.url"
                              class="url wrap-text"
                            >
                              <span>{{ content.url }}</span>
                            </div>
                          </div>
                          <div v-if="content.errormessage" class="error-container">
                            <div
                              v-for="(e, i) in content.errormessage"
                              :key="i"
                              class="error-message wrap-text"
                            >
                              <span>{{ e.message }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <p class="time">{{ formatTime(content.time * 1) }}</p>
                  </div>
                </template>
              </b-card-body>
            </b-collapse>
          </b-card>
          <div class="pagination">
            <b-pagination
              v-if="!loading && webBrowsingHistory.length"
              v-model="pageNumber"
              :total-rows="webBrowsingHistory.length"
              :per-page="perPage"
              class="my-0 pull-right"
              limit="3"
              size="sm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PanelTools from 'modules/chat/components/PanelTools';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import { convertTimeUTC, getLinkPreview } from 'core/helpers';
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import noImage from 'assets/images/image.png';

export default {
  components: {
    PanelTools,
    BootstrapTooltipCustom
  },
  data() {
    return {
      pinned: false,
      loading: false,
      collapseOpened: {},
      collapseKeyOpened: null,
      collapseIndexOpened: null,
      inViewTotal: true,
      inViewPartial: false,
      arrowCollapse: {},
      pageNumber: 1,
      perPage: 10,
      heightLoadMoreBtn: 0,
      heightHeaderCard: 0,
      widthHeaderCard: 0,
      cardContentWidth: 0,
      tooltipCopyText: {},
      noImage,
      loadingImage: {}
    };
  },
  computed: {
    ...mapState('global', ['rightWebBrowsingHistoryPanelPinned']),
    ...mapState('chat', ['webBrowsingHistory', 'wbhLastEvaluatedKey', 'wbhAllDataCount', 'imageOfUrlPreview']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapState('phoneAgentChatBox', ['paramsFromPhoneAgentSearchBox']),

    shadowClass() {
      return this.rightWebBrowsingHistoryPanelPinned
        ? 'shadow-sm'
        : 'shadow-lg unpinned full-width';
    },

    pinPanelStatus() {
      const pinStatus = this.rightWebBrowsingHistoryPanelPinned || this.pinned;
      return pinStatus;
    },

    paginatedData() {
      return this.webBrowsingHistory.slice(
        (this.pageNumber - 1) * this.perPage,
        this.pageNumber * this.perPage
      );
    },

    loadMoreNumber() {
      return this.wbhAllDataCount - this.webBrowsingHistory.length;
    }
  },

  watch: {
    pageNumber() {
      const keys = Object.keys(this.arrowCollapse);
      this.arrowCollapse = { ...keys.reduce((a, k) => ({ ...a, [k]: false }), {}) };
      this.collapseOpened = {};
      this.collapseKeyOpened = null;
      this.collapseIndexOpened = null;
      this.inViewTotal = true;
    },

    selectedConversation(newValue) {
      if (!newValue || isEmpty(newValue)) {
        this.$emit('hideAllPanel');
      }
    }
  },

  async mounted() {
    await this.loadWebBrowsingHistory();
    setTimeout(() => {
      const latestWBH = this.webBrowsingHistory.length ? this.webBrowsingHistory[0] : null;
      if (latestWBH) {
        this.$root.$emit('bv::toggle::collapse', 'wbh-collapse-' + latestWBH.timestamp + 0);
        this.detectCardSize('wbh' + latestWBH.timestamp + 0);
      }

      $(document).ready(() => {
        $('.webhistory-body-content').scroll(() => {
          if (!this.collapseKeyOpened) return;
          this.inViewTotal = this.checkInView(`.card-header-${this.collapseKeyOpened}`, false);
          this.inViewPartial = this.checkInView(`#${this.collapseKeyOpened}`, true);
        });
      });

      window.addEventListener('resize', this.onResize);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  destroyed() {
    this.clearWebBrowsingHistory();
  },

  methods: {
    ...mapActions('global', ['pinRightWebBrowsingHistoryPanel']),
    ...mapActions('chat', ['getWebBrowsingHistory', 'clearWebBrowsingHistory', 'setImageOfUrlPreview']),

    loadMoreWebHistory() {
      this.loadWebBrowsingHistory(this.wbhLastEvaluatedKey);
    },

    async loadWebBrowsingHistory(wbhLastEvaluatedKey) {
      this.loading = true;
      let params = { LastEvaluatedKey: wbhLastEvaluatedKey };
      if (this.selectedConversation.projectId) {
        const { userId, channelId, projectId } = this.selectedConversation || {};
        params = {
          ...params,
          userId,
          channelId,
          projectId
        };
      } else if (this.paramsFromPhoneAgentSearchBox.phoneNumber) {
        params = {
          ...params,
          ...this.paramsFromPhoneAgentSearchBox
        };
      }

      if (!params.projectId || (!params.phoneNumber && !params.userId))
        return (this.loading = false);
      const { Items = [] } = await this.getWebBrowsingHistory(params);
      this.loading = false;

      this.loadImagePreview(Items)
    },

    async loadImagePreview(Items = []) {
      let urls = [];
      Items.map(item => {
        const { data = {} } = item;
        const urlInItem = Object.values(data).map(i => i.url);
        urls = uniq([...urls, ...urlInItem]);
      })
      urls = urls.filter(u => !Object.keys(this.imageOfUrlPreview).includes(u));
      await Promise.all(urls.map(async url => {
        this.loadingImage[url] = true;
        const data = await getLinkPreview(url).catch(() => ({}));
        const { image = '' } = data;
        this.setImageOfUrlPreview({ url, image });
        this.loadingImage[url] = false;
      }))
    },

    openNewWindow(url) {
      window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=380,height=760');
    },

    handleClick(key) {
      this.$root.$emit('bv::toggle::collapse', key);
    },

    handleOpenCollapse(key, item, index) {
      const keys = Object.keys(this.arrowCollapse);
      this.arrowCollapse = { ...keys.reduce((a, k) => ({ ...a, [k]: false }), {}), [key]: true };
      this.collapseOpened = { [key]: item };
      this.collapseKeyOpened = key;
      this.collapseIndexOpened = index;
      this.inViewTotal = true;
      this.defaultCopyText(item);
      this.scrollToTopDiv(index);
      this.scrollToBottom(key);
      $(`.card-header-wbh-collapse-${item.timestamp}${index}`).css({ 'z-index': '1' });
    },

    defaultCopyText(item) {
      const { data, timestamp } = item;
      this.tooltipCopyText = Object.keys(data).reduce(
        (a, k) => ({ ...a, [`page-info-${timestamp}-${k}`]: this.$t('common.text.copy') }),
        {}
      );
    },

    handleCloseCollapse(key) {
      this.arrowCollapse = { ...this.arrowCollapse, [key]: false };
      $(`.card-header-${key}`).css({ 'z-index': '' });
      if (this.collapseOpened[key]) {
        this.collapseOpened = {};
        this.collapseKeyOpened = null;
        this.collapseIndexOpened = null;
        this.inViewTotal = true;
      }
    },

    closePanel() {
      this.$emit('handleWebBrowsingHistoryItemClick');
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.widthHeaderCard = 0;
      this.scrollToTopDiv(this.collapseIndexOpened);
      this.pinRightWebBrowsingHistoryPanel(this.pinned);
      setTimeout(() => {
        const latestWBH = this.paginatedData.length ? this.paginatedData[0] : null;
        latestWBH ? this.detectCardSize('wbh' + latestWBH.timestamp + 0) : null;
      }, 500);
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    },

    color(item) {
      const { data = [] } = item;
      const s = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].errormessage && data[i].errormessage.length > 0) {
          s.push('color: #ff0000');
          break;
        }
      }
      return s.join(';');
    },

    backgroundColor(content) {
      const { event, errormessage } = content;
      if (errormessage && errormessage.length > 0) {
        return 'background-color: #ffcdcd; color: #ff0000';
      } else if (event) {
        return 'background-color: #ffff88';
      }
      return '';
    },

    detectCardSize(id) {
      this.$nextTick(() => {
        const ref = this.$refs && this.$refs[id];
        const { children = [] } = ref && ref.length ? ref[0] : {};
        const { offsetHeight = 0, offsetWidth = 0 } = children.length ? children[0] : {};
        this.heightHeaderCard = offsetHeight;
        this.widthHeaderCard = offsetWidth;
        this.heightLoadMoreBtn = 0;
        if (this.loadMoreNumber > 0) {
          this.heightLoadMoreBtn = this.$refs.loadMoreBtn?.offsetHeight || 0;
        }
      });
    },

    scrollToTopDiv(index) {
      if (index === null) return;
      this.$nextTick(() => {
        const marginBottom = 10;
        const borderTop = 1;
        const borderBottom = 1;
        this.$refs.webhistoryBodyContent.scrollTop =
            this.heightHeaderCard * index + marginBottom * index + (borderTop + borderBottom) * index;
        setTimeout(() => {
          this.$refs.webhistoryBodyContent.scrollTop =
            this.heightHeaderCard * index + marginBottom * index + (borderTop + borderBottom) * index;
        }, 500);
      });
    },

    scrollToBottom(key) {
      this.$nextTick(() => {
        const { scrollHeight } = this.$refs[key] && this.$refs[key][0].$el;
        this.$refs[key][0].$el.scrollTop = scrollHeight;
      });
    },

    checkInView(elem, partial) {
      const container = $('.webhistory-body-content');
      const contHeight = container.height();

      const elemTop = $(elem).offset()?.top - container.offset()?.top;
      const elemBottom = elemTop + $(elem).height();

      const isTotal = elemTop >= 0 && elemBottom <= contHeight;
      const isPart =
        ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.height())) &&
        partial;

      return isTotal || isPart;
    },

    copyPageInfo(content, item, index) {
      const dataCopy = { ...content, time: this.formatTime(content.time * 1) };
      this.$copyText(JSON.stringify(dataCopy)).then(
        () => {
          this.defaultCopyText(item);
          this.tooltipCopyText[`page-info-${item.timestamp}-${index}`] =
            this.$t('common.text.copied');
        },
        error => {
          this.defaultCopyText(item);
          console.log('copyPageInfo >> error:', error);
        }
      );
    },

    onResize() {
      this.scrollToTopDiv(this.collapseIndexOpened);
      setTimeout(() => {
        const latestWBH = this.paginatedData.length ? this.paginatedData[0] : null;
        latestWBH ? this.detectCardSize('wbh' + latestWBH.timestamp + 0) : null;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.load-more-btn {
  .el-badge {
    width: 100%;
  }

  .el-button {
    float: right;
  }
}

.no-data {
  text-align: center;
}

.pagination {
  float: right;
}

.webhistory-body-content {
  overflow: auto;

  .header-fixed {
    position: fixed;
    border: 1px solid rgba(136, 152, 170, 0.5);
    cursor: pointer;
  }

  .card-header {
    z-index: 2;
  }

  .webhistory-body-content-card {
    margin-bottom: 10px;
    margin-right: 2px;
    cursor: pointer;
  }

  .webhistory-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: default;
    min-width: 350px;
  }

  .link-pre {
    position: relative;
    display: block;
    padding: 8px;
    margin: 0 20px 10px 0;
    clear: both;
    border-radius: 0.286rem;
  }
  .linkprevue-right {
    float: right;
  }
  .linkprevue-left {
    float: left;
  }

  a,
  a:hover {
    color: #000000;
  }

  .link-preview {
    text-align: left;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #eeeeee;

    .second-section {
      padding: 8px;
      background-color: #eeeeee;
      width: inherit;
    }

    .image-preview {
      border-right: 1px solid #eeeeee;
      img {
        height: 100%;
        width: 100px;
      }
    }

    .cover-image {
      height: 110px;
      width: 100%;
      background-size: 100% 110px;
      background-color: #e0e0e0;
      background-repeat: no-repeat;
      background-position: top left;
    }

    .title {
      font-size: 18px;
      font-weight: 700;
    }

    .wrap-text {
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
    }
    .one-line-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .two-line-text {
      overflow: hidden;
      position: relative;
      line-height: 20px;
      max-height: 40px;
    }

    .description {
      font-size: 14px;
    }

    .url-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: auto;
        height: 16px;
        margin-right: 5px;
      }

      .url {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .time {
    font-size: 11px;
    float: right;
    color: #adb5bd;
    font-weight: 400;
  }
}

.fullScreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
}
</style>
