<template>
  <div class="wrapper-content">
    <AppCover />
    <div class="page-sub" v-if="ready">
      <!-- Overview Menu (S) -->
      <div class="container">
        <div class="row">
          <div class="col-12 overview-menu">
            <b-input-group class="mt-3 ">
              <!-- Sort Component (S) -->
              <b-input-group-prepend>
                <b-input-group-text
                  v-show="selectedSortList === 'ascending'"
                  class="btn"
                  @click="selectedSortList = 'descending'"
                >
                  <i class="fas fa-sort-amount-up"></i>
                </b-input-group-text>
                <b-input-group-text
                  v-show="selectedSortList === 'descending'"
                  class="btn"
                  @click="selectedSortList = 'ascending'"
                >
                  <i class="fas fa-sort-amount-down"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <!-- Sort Component (E) -->

              <!-- Combobox choose (S) -->
              <el-select
                v-model="selectedStatusGroup"
                class="el-status-group"
                popper-class="custom-popper-v1"
              >
                <el-option
                  v-for="item in statusGroups"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- Combobox choose (E) -->

              <!-- Combobox choose agent (S) -->
              <el-select
                v-if="selectedStatusGroup === 'agent'"
                v-model="selectedProject"
                class="el-project"
                popper-class="custom-popper-v1"
              >
                <el-option
                  v-for="item in projectOpts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- Combobox choose agent (E) -->

              <!-- Combobox choose conversation (S) -->
              <el-select
                v-if="selectedStatusGroup === 'conversation'"
                v-model="selectedSortType"
                :placeholder="$t('src.modules.chat.components.OverviewBox.index.select')"
                class="el-conversation"
                popper-class="custom-popper-v1"
              >
                <el-option
                  v-for="item in sortTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- Combobox choose conversation (E) -->

              <!-- Button Refresh data (S) -->
              <b-input-group-append>
                <b-button
                  id="refresh-data-overview-box"
                  v-b-tooltip="$t('src.modules.replacing-message.index.refresh_data')"
                  :disabled="selectedStatusGroup !== 'agent' || disabledReloadBtn"
                  variant="primary"
                  :class="['btn-icon', showRefresh]"
                  @click="handleRefresh"
                >
                  <i class="fas fa-sync"></i>
                </b-button>
              </b-input-group-append>
              <!-- Button Refresh data (E) -->
            </b-input-group>
          </div>
        </div>
      </div>
      <!-- Overview Menu (E) -->

      <ConversationOverview
        :key="selectedProject"
        v-if="selectedStatusGroup === 'conversation'"
        :selected-sort-type="selectedSortType"
        :selected-sort-list="selectedSortList"
        :selected-project="selectedProject"
        @onSelectedConversation="handleOnSelectedConversation"
      />
      <AgentOverview
        :key="selectedProject"
        v-if="selectedStatusGroup === 'agent'"
        ref="refAgent"
        :selected-sort-list="selectedSortList"
        :selected-project="selectedProject"
        @disableRefreshBtn="disableRefreshBtn"
      />
      <!-- Conversation Detail Popoup (S) -->
      <ConversationDetailBox />
      <!-- Conversation Detail Popoup (E) -->
    </div>
  </div>
</template>

<script>
import AppCover from 'components/AppCover';
import { mapState, mapGetters, mapActions } from 'vuex';
import { GROUP_CONVERSATIONS, AGENT_ROLE } from 'core/constants';
import ConversationOverview from './conversation';
import ConversationDetailBox from './conversationDetailBox';
import AgentOverview from './agent';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

export default {
  components: {
    AppCover,
    ConversationOverview,
    AgentOverview,
    ConversationDetailBox
  },
  data() {
    return {
      selectedSortType: 'channel_name',
      selectedSortList: 'ascending',
      sortTypes: [],
      sortLists: [
        {
          value: 'ascending',
          label: this.$t('src.modules.chat.components.OverviewBox.index.ascending_order')
        },
        {
          value: 'descending',
          label: this.$t('src.modules.chat.components.OverviewBox.index.descending_order')
        }
      ],
      statusGroups: [
        {
          value: 'agent',
          label: this.$t('src.modules.chat.components.OverviewBox.index.agent')
        },
        {
          value: 'conversation',
          label: this.$t('src.modules.chat.components.OverviewBox.index.conversation')
        }
      ],
      selectedStatusGroup: 'conversation',
      selectedProject: '',
      disabledReloadBtn: false
    };
  },
  computed: {
    ...mapGetters('session', ['me']),
    ...mapState('global', ['ready']),
    ...mapState('session', ['projectMaps', 'user']),

    projectOpts() {
      const projects = this.projectMaps || {};
      let projectOpts = Object.keys(projects).map(projectId => {
        return {
          value: projectId,
          label: projects[projectId].name || projectId
        };
      });
      const projectOptsSort = sortBy(projectOpts, 'label');
      return projectOptsSort;
    },
    showRefresh() {
      return this.selectedStatusGroup !== 'agent' ? 'hideRefresh' : '';
    }
  },
  watch: {
    me() {
      this.getContentData();
    },
    projectOpts(newVal, oldValue) {
      if (isEqual(newVal, oldValue)) return null;
      if (Array.isArray(newVal) && newVal.length > 0) {
        this.selectedProject = newVal[0].value;
      }
    }
  },

  created() {
    this.getContentData();
  },

  mounted() {
    if (this.projectOpts.length > 0) {
      this.selectedProject = this.projectOpts[0].value;
    }
    if (this.me.role === AGENT_ROLE.CLIENT) {
      this.statusGroups = this.statusGroups.filter(x => x.value === 'agent');
      this.selectedStatusGroup = 'agent';
    }
  },

  methods: {
    ...mapActions('chat', ['getActivities']),

    getContentData() {
      let sortTypes = [
        {
          value: 'channel_name',
          label: this.$t('src.modules.chat.components.OverviewBox.index.all_channels')
        },
        {
          value: 'bot',
          label: GROUP_CONVERSATIONS[0].title
        },
        {
          value: 'waiting',
          label: GROUP_CONVERSATIONS[1].title
        },
        {
          value: 'agent',
          label: GROUP_CONVERSATIONS[2].title
        }
      ];
      if (this.me.role === AGENT_ROLE.LEADER || this.me.role === AGENT_ROLE.MODERATOR) {
        sortTypes = [
          ...sortTypes,
          {
            value: 'monitoring',
            label: GROUP_CONVERSATIONS[4].title
          }
        ];
      }
      this.sortTypes = sortTypes;
    },

    handleOnSelectedConversation(conversation) {
      this.$emit('onSelectedConversation', { type: 'CHAT_LIST', conversation });
    },

    handleRefresh() {
      this.$refs.refAgent.refresh();
      this.$root.$emit('bv::hide::tooltip', 'refresh-data-overview-box');
    },

    disableRefreshBtn(boolean) {
      this.disabledReloadBtn = boolean;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/chat/chat.scss';
.fas {
  font-size: x-large;
  margin-top: auto;
  margin-bottom: auto;
}

.page-sub {
  overflow: hidden;
  padding-left: 0;
}

.overview-menu {
  padding: 0px;
}
.overview-box {
  z-index: 1;

  .section-shaped .shape.shape-skew + .shape-container {
    padding-top: 6rem;
    padding-bottom: 19rem;
  }
}

.channels-wrap {
  z-index: 1;
  height: calc(100vh - 100px);
  overflow-y: auto;

  &.mt--350 {
    margin-top: -350px !important;
  }
}
.page-cover {
  z-index: -1;
}

.row-overview {
  padding-bottom: 30px;
}
.el-status-group {
  max-width: 140px;
  & /deep/.el-input__inner {
    padding-left: 10px !important;
  }
}
.el-conversation {
  & /deep/.el-input__inner {
    padding-left: 10px !important;
  }
}

.custom-popper-v1 {
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      max-width: unset !important;
    }
  }
}

.el-project {
  & /deep/.el-input__inner {
    padding-left: 10px !important;
  }
}

.el-select-dropdown__item {
  max-width: 34vw !important;
}

.hideRefresh {
  display: none;
}
</style>
