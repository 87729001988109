import { i18n } from 'locales';
import vi_VN from './languages/vi-VN/translation.json';
import en_US from './languages/en-US/translation.json';
import ja_JP from './languages/ja-JP/translation.json';
import zh_TW from './languages/zh-TW/translation.json';
import zh_CN from './languages/zh-CN/translation.json';

const schedule = {
  dayOfMonthRow: 1,
  dayOfWeekRow: -1,
  scheduleDateRange: [],
  scheduleTypes: [
    {
      value: 'immediate',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.schedule_type.immediate'
      )
    },
    {
      value: 'weekly',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.schedule_type.weekly'
      )
    },
    {
      value: 'monthly',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.schedule_type.monthly'
      )
    }
  ],
  selectScheduleType: 'immediate',
  dayNamesOfWeek: [
    {
      value: '0',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.sunday'
      )
    },
    {
      value: '1',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.monday'
      )
    },
    {
      value: '2',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.tuesday'
      )
    },
    {
      value: '3',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.wednesday'
      )
    },
    {
      value: '4',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.thursday'
      )
    },
    {
      value: '5',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.friday'
      )
    },
    {
      value: '6',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.day_of_week.saturday'
      )
    }
  ],
  selectDayNameOfWeek: '0',
  selectDayOfMonth: '1',
  dayOfMonth: [
    {
      value: '1',
      label: i18n.t(
        'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.last_day_of_every_month'
      )
    },
  ]
};

const columnFilter = locale => {
  // eslint-disable-next-line
  console.log('columnFilter:', locale);
  let language = en_US;
  switch (locale) {
    case 'vi-VN':
      language = vi_VN;
      break;
    case 'ja-JP':
      language = ja_JP;
      break;
    case 'zh-TW':
      language = zh_TW;
      break;
    case 'zh-CN':
      language = zh_CN;
      break;
    default:
      language = en_US;
  }
  // eslint-disable-next-line
  console.log('Translate:', locale, language);
  return {
    selected: {
      tags: [],
      editable: true,
      cloneable: false,
      droppable: true,
      isDragging: true,
      delayedDragging: true
    },
    available: {
      tags: [
        {
          tag: language['conversationId'],
          slug: 'conversationId',
          fixed: true,
          disabled: true
        },
        { tag: language['platform'], slug: 'platform', fixed: false },
        { tag: language['channelId'], slug: 'channelId', fixed: true, disabled: true },
        { tag: language['channelName'], slug: 'channelName', fixed: false },
        { tag: language['userId'], slug: 'userId', fixed: true, disabled: true },
        { tag: language['userName'], slug: 'userName', fixed: false },
        { tag: language['userLanguage'], slug: 'userLanguage', fixed: false },
        { tag: language['userLabels'], slug: 'userLabels', fixed: false },
        { tag: language['userTimezone'], slug: 'userTimezone', fixed: false },
        { tag: language['userUrl'], slug: 'userUrl', fixed: false },
        { tag: language['userIpAddress'], slug: 'userIpAddress', fixed: false },
        { tag: language['userBrowserType'], slug: 'userBrowserType', fixed: false },
        { tag: language['userReferer'], slug: 'userReferer', fixed: false },
        { tag: language['labels'], slug: 'labels', fixed: false },
        { tag: language['requestedTime'], slug: 'requestedTime', fixed: false },
        { tag: language['initialGroupId'], slug: 'initialGroupId', fixed: false },
        { tag: language['initialGroupName'], slug: 'initialGroupName', fixed: false },
        { tag: language['initialAgentId'], slug: 'initialAgentId', fixed: false },
        { tag: language['initialAgentName'], slug: 'initialAgentName', fixed: false },
        { tag: language['startedDate'], slug: 'startedDate', fixed: false },
        { tag: language['startedTime'], slug: 'startedTime', fixed: false },
        { tag: language['startedAgentId'], slug: 'startedAgentId', fixed: false },
        { tag: language['startedAgentName'], slug: 'startedAgentName', fixed: false },
        { tag: language['initialMessageTime'], slug: 'initialMessageTime', fixed: false },
        {
          tag: language['initialMessageAgentId'],
          slug: 'initialMessageAgentId',
          fixed: false
        },
        {
          tag: language['initialMessageAgentName'],
          slug: 'initialMessageAgentName',
          fixed: false
        },
        { tag: language['lastMessageTime'], slug: 'lastMessageTime', fixed: false },
        { tag: language['lastScenarioId'], slug: 'lastScenarioId', fixed: false },
        {
          tag: language['lastScenarioIdSwitchAgent'],
          slug: 'lastScenarioIdSwitchAgent',
          fixed: false
        },
        { tag: language['finishedTime'], slug: 'finishedTime', fixed: false },
        { tag: language['finishedAgentId'], slug: 'finishedAgentId', fixed: false },
        { tag: language['finishedAgentName'], slug: 'finishedAgentName', fixed: false },
        { tag: language['finishedState'], slug: 'finishedState', fixed: false },
        { tag: language['completedTime'], slug: 'completedTime', fixed: false },
        { tag: language['completedState'], slug: 'completedState', fixed: false },
        {
          tag: language['completedStateError'],
          slug: 'completedStateError',
          fixed: false
        },
        { tag: language['completedMode'], slug: 'completedMode', fixed: false },
        { tag: language['userLeft'], slug: 'userLeft', fixed: false },
        { tag: language['userLeftReason'], slug: 'userLeftReason', fixed: false },
        { tag: language['notSupported'], slug: 'notSupported', fixed: false },
        {
          tag: language['rating'],
          slug: 'rating',
          fixed: false
        },
        {
          tag: language['feedback'],
          slug: 'feedback',
          fixed: false
        },
        { tag: language['numberIdle'], slug: 'numberIdle', fixed: false },
        {
          tag: language['totalIdleTime'],
          slug: 'totalIdleTime',
          fixed: false
        },
        { tag: language['numberHolds'], slug: 'numberHolds', fixed: false },
        {
          tag: language['totalHoldTime'],
          slug: 'totalHoldTime',
          fixed: false
        },
        { tag: language['scenarioIdHistory'], slug: 'scenarioIdHistory', fixed: false },
        { tag: language['groupHistory'], slug: 'groupHistory', fixed: false },
        { tag: language['agentHistory'], slug: 'agentHistory', fixed: false },
        {
          tag: language['numberTotalMessage'],
          slug: 'numberTotalMessage',
          fixed: false
        },
        {
          tag: language['numberUserMessage'],
          slug: 'numberUserMessage',
          fixed: false
        },
        {
          tag: language['numberAgentMessage'],
          slug: 'numberAgentMessage',
          fixed: false
        },
        { tag: language['numberAgentSupport'], slug: 'numberAgentSupport', fixed: false },
        { tag: language['numberRequestAgent'], slug: 'numberRequestAgent', fixed: false },
        { tag: language['numberOfTransfer'], slug: 'numberOfTransfer', fixed: false },
        { tag: language['switchToAgent'], slug: 'switchToAgent', fixed: false },
        { tag: language['engagedBy'], slug: 'engagedBy', fixed: false },
        {
          tag: language['totalBotHandlingTime'],
          slug: 'totalBotHandlingTime',
          fixed: false
        },
        {
          tag: language['totalInitialResponseTime'],
          slug: 'totalInitialResponseTime',
          fixed: false
        },
        {
          tag: language['totalRespondingTime'],
          slug: 'totalRespondingTime',
          fixed: false
        },
        {
          tag: language['totalInitialMessageTime'],
          slug: 'totalInitialMessageTime',
          fixed: false
        },
        {
          tag: language['totalTalkingTime'],
          slug: 'totalTalkingTime',
          fixed: false
        },
        {
          tag: language['totalFinishTime'],
          slug: 'totalFinishTime',
          fixed: false
        },
        {
          tag: language['totalAgentHandlingTime'],
          slug: 'totalAgentHandlingTime',
          fixed: false
        },
        {
          tag: language['totalAgentHandlingTimeNoHolding'],
          slug: 'totalAgentHandlingTimeNoHolding',
          fixed: false
        },
        {
          tag: language['totalResponseInterval'],
          slug: 'totalResponseInterval',
          fixed: false
        },
        {
          tag: language['minResponseInterval'],
          slug: 'minResponseInterval',
          fixed: false
        },
        {
          tag: language['maxResponseInterval'],
          slug: 'maxResponseInterval',
          fixed: false
        },
        {
          tag: language['avgResponseInterval'],
          slug: 'avgResponseInterval',
          fixed: false
        },
        {
          tag: language['numResponseInterval'],
          slug: 'numResponseInterval',
          fixed: false
        },
        {
          tag: language['requestedTimeUnix'],
          slug: 'requestedTimeUnix',
          fixed: false
        },
        {
          tag: language['startedTimeUnix'],
          slug: 'startedTimeUnix',
          fixed: false
        },
        {
          tag: language['finishedTimeUnix'],
          slug: 'finishedTimeUnix',
          fixed: false
        },
        {
          tag: language['completedTimeUnix'],
          slug: 'completedTimeUnix',
          fixed: false
        },
        {
          tag: language['agentInitialMessageTimeUnix'],
          slug: 'agentInitialMessageTimeUnix',
          fixed: false
        },
        {
          tag: language['lastMessageTimeUnix'],
          slug: 'lastMessageTimeUnix',
          fixed: false
        },

        { tag: language['title'], slug: 'title', fixed: false },
        { tag: language['completionState'], slug: 'completionState', fixed: false },
        { tag: language['category1'], slug: 'category1', fixed: false },
        { tag: language['category2'], slug: 'category2', fixed: false },
        { tag: language['category3'], slug: 'category3', fixed: false },
        { tag: language['category4'], slug: 'category4', fixed: false },
        { tag: language['category5'], slug: 'category5', fixed: false },
        { tag: language['category6'], slug: 'category6', fixed: false },
        { tag: language['category7'], slug: 'category7', fixed: false },
        { tag: language['category8'], slug: 'category8', fixed: false },
        { tag: language['category9'], slug: 'category9', fixed: false },
        { tag: language['categoryId'], slug: 'categoryId', fixed: true, disabled: true },
        { tag: language['categoryPath'], slug: 'categoryPath', fixed: false },
        {
          tag: language['primaryQuestion'],
          slug: 'primaryQuestion',
          fixed: false
        },
        {
          tag: language['primaryAnswer'],
          slug: 'primaryAnswer',
          fixed: false
        },
        { tag: language['agentMemo'], slug: 'agentMemo', fixed: false },
        { tag: language['internalComment'], slug: 'internalComment', fixed: false },
        { tag: language['questionFromUser'], slug: 'questionFromUser', fixed: false },
        { tag: language['answerFromUser'], slug: 'answerFromUser', fixed: false },
        { tag: language['result'], slug: 'result', fixed: false },
        { tag: language['scheduledCallBack'], slug: 'scheduledCallBack', fixed: false },
        { tag: language['callBackMemo'], slug: 'callBackMemo', fixed: false },
        { tag: language['escalated'], slug: 'escalated', fixed: false },
        { tag: language['callBack'], slug: 'callBack', fixed: false },
        { tag: language['svCheck'], slug: 'svCheck', fixed: false },
        { tag: language['called'], slug: 'called', fixed: false },
        { tag: language['mailSent'], slug: 'mailSent', fixed: false },
        { tag: language['firstName'], slug: 'firstName', fixed: false },
        { tag: language['lastName'], slug: 'lastName', fixed: false },
        {
          tag: language['firstNameLocalCharacter'],
          slug: 'firstNameLocalCharacter',
          fixed: false
        },
        {
          tag: language['lastNameLocalCharacter'],
          slug: 'lastNameLocalCharacter',
          fixed: false
        },
        { tag: language['fullName'], slug: 'fullName', fixed: false },
        {
          tag: language['fullNameLocalCharacter'],
          slug: 'fullNameLocalCharacter',
          fixed: false
        },
        { tag: language['sex'], slug: 'sex', fixed: false },
        { tag: language['birthday'], slug: 'birthday', fixed: false },
        { tag: language['email'], slug: 'email', fixed: false },
        { tag: language['telephoneNumber'], slug: 'telephoneNumber', fixed: false },
        { tag: language['address'], slug: 'address', fixed: false },
        { tag: language['building'], slug: 'building', fixed: false },
        { tag: language['country'], slug: 'country', fixed: false },
        { tag: language['statePrefecture'], slug: 'statePrefecture', fixed: false },
        { tag: language['city'], slug: 'city', fixed: false },
        { tag: language['countryCode'], slug: 'countryCode', fixed: false },
        { tag: language['zip'], slug: 'zip', fixed: false },
        { tag: language['maritalStatus'], slug: 'maritalStatus', fixed: false },
        { tag: language['relationship'], slug: 'relationship', fixed: false },
        { tag: language['occupation'], slug: 'occupation', fixed: false },
        { tag: language['companyName'], slug: 'companyName', fixed: false },
        { tag: language['workPhoneNumber'], slug: 'workPhoneNumber', fixed: false },
        { tag: language['workEmail'], slug: 'workEmail', fixed: false },
        {
          tag: language['customer_fields'],
          slug: 'customerFields',
          fixed: false
        }
      ],
      editable: true,
      cloneable: false,
      droppable: true
    },
    isDragging: true,
    delayedDragging: true
  };
};

export { schedule, columnFilter };
