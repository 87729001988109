<template>
  <div v-if="selectedGroupConv !== 3" class="chat-group">
    <ul
      class="nav nav-pills nav-pills-circle mb-3 mx-auto justify-content-center"
      role="tablist"
    >
      <nav-item
        v-for="(item, index) in items"
        :data-testid="mappingTab(index)"
        :key="item.id"
        v-b-tooltip.hover
        v-bind="item"
        :title="item.title"
        :active="activeState[item.id]"
        @onNavItemClick="handleOnNavItemClick"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { SERVING_STATE } from 'core/constants';
import NavItem from './nav-item';

export default {
  components: {
    NavItem
  },
  props: {
    isSearch: {
      type: Boolean,
      default: () => false
    },
    hasProjectInBotHandle: {
      type: Boolean,
      default: () => true
    },
    hasProjectInMonitoring: {
      type: Boolean,
      default: () => true
    }
  },

  computed: {
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'meWaitingList',
      'me'
    ]),
    ...mapState('chat', [
      'selectedGroupConv',
      'localesFilter',
      'messagesNotification',
      'searchBoxFilter'
    ]),
    ...mapState('session', [
      'numberUnreadMessages',
      'commonWaitingCountsMap',
      'meWaitingCountsMap',
      'user',
      'channelsMap',
      'isRefreshing'
    ]),

    activeState() {
      const idx = this.selectedGroupConv;
      const as = [false, false, false, false];
      as[idx] = true;
      return as;
    },

    showRefresh() {
      return this.selectedGroupConv === SERVING_STATE.BOT;
    },

    waitingCount() {
      return (
        (Object.values(this.commonWaitingCountsMap).reduce((sum, i) => sum + i, 0) || 0) +
        (Object.values(this.meWaitingCountsMap).reduce((sum, i) => sum + i, 0) || 0)
      );
    },

    items() {
      const navs = [
        {
          id: SERVING_STATE.BOT,
          icon: 'fab fa-android',
          title: this.$t(
            'src.modules.chat.components.AsideLeft.GroupNav.index.conversations_with_bots'
          ),
          count: 0,
          index: 0
        },
        {
          id: SERVING_STATE.WAITING,
          icon: 'fa fa-hourglass',
          title: this.$t(
            'src.modules.chat.components.AsideLeft.GroupNav.index.waiting_conversations'
          ),
          count: this.waitingCount || 0,
          index: 1
        },
        {
          id: SERVING_STATE.AGENT,
          icon: 'fa fa-users',
          title: this.$t(
            'src.modules.chat.components.AsideLeft.GroupNav.index.conversations_with_agents'
          ),
          count: this.numberUnreadMessages,
          index: 2
        }
      ];

      if (!this.hasProjectInBotHandle) navs.shift();


      navs.push({
        id: SERVING_STATE.MONITORING,
        icon: 'far fa-clock',
        title: this.$t('src.modules.chat.components.AsideLeft.GroupNav.index.monitoring'),
        count: 0,
        index: 4
      });

      return navs;
    }
  },

  created() {
    this.activeState[this.selectedGroupConv] = true;
  },

  methods: {
    ...mapActions('chat', [
      'setSelectedGroupConversation',
      'setSearchBoxFilter',
      'setSearchBoxHeight'
    ]),
    ...mapActions('session', ['reloadBotList', 'reloadMonitoringList']),

    m_reloadList() {
      switch (this.selectedGroupConv) {
        case SERVING_STATE.BOT:
          this.reloadBotList();
          break;
        case SERVING_STATE.MONITORING:
          this.reloadMonitoringList();
          break;

        default:
          break;
      }
    },

    handleOnNavItemClick(id) {
      if (id === this.selectedGroupConv) return;
      this.setSelectedGroupConversation(id);
      if (Object.keys(this.searchBoxFilter).length > 0) {
        this.setSearchBoxFilter({});
        this.setSearchBoxHeight(95);
      }
    },

    mappingTab(index) {
      let tab = '';
      switch(index) {
        case 0:
          tab = 'bot';
          break;
        case 1:
          tab = 'waiting';
          break;
        case 2:
          tab = 'agent';
          break;
        case 3:
          tab = 'monitoring';
          break;
        default:
          break;
      }
      return `conversation-tab-${tab}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.g-refresh {
  position: absolute;
  top: 9px;
  right: 15px;
  height: 32px;
  width: 32px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  .fas {
    line-height: 1;
    font-size: 14px;
  }
}
.chat-group {
  position: relative;
  padding-right: 20px;
}
</style>
