import 'firebase/auth';
import 'firebase/storage';
import firebase from 'firebase/app';

export class Firebase {
  constructor(conf) {
    firebase.initializeApp(conf, 'loginApp');
    this.auth = firebase.app('loginApp').auth();
  }

  signIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
}

const fbConf = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
export default new Firebase(fbConf);
