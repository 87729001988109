<template>
  <div v-if="isOpenConversation && !isPhoneAgent" class="page-aside-right">
    <ul class="list-group">
      <li
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.ChatBox.chat-header.status_history')"
        class="list-group-item text-center"
      >
        <button
          :class="['btn btn-icon btn-2 btn-sm', showStatusHistoryPanel ? 'active' : '']"
          type="button"
          @click="$emit('onStatusHistoryItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="material-icons">timeline</i>
          </span>
        </button>
      </li>
      <li
        v-if="displayProductFeedsIcon"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.product_feeds')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-product-feeds"
          :class="['btn btn-icon btn-2 btn-sm', showProductPanel ? 'active' : '']"
          type="button"
          @click="$emit('onProductItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-shopping-bag" />
          </span>
        </button>
      </li>
      <li
        v-if="displayConversationFeedsIcon"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.conversation_feeds')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-chat-assitant"
          :class="['btn btn-icon btn-2 btn-sm', showConversationalPanel ? 'active' : '']"
          type="button"
          @click="$emit('onConversationalItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-comments" />
          </span>
        </button>
      </li>
      <li
        v-if="displayWebBrowsingHistoryIcon"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.web_browsing_history')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-web-browsing-history"
          :class="['btn btn-icon btn-2 btn-sm', showWebBrowsingHistoryPanel ? 'active' : '']"
          type="button"
          @click="$emit('onWebBrowsingHistoryItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-history" />
          </span>
        </button>
      </li>
      <li
        v-if="displaySurveyFormIcon"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.survey_form')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-csf"
          :class="['btn btn-icon btn-2 btn-sm', showSurveyPanel ? 'active' : '']"
          type="button"
          @click="$emit('onSurveyItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-list-alt" />
          </span>
        </button>
      </li>
      <li
        v-if="displayCustomerSupportIcon"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.customer_support')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-csf"
          :class="['btn btn-icon btn-2 btn-sm', showSupportPanel ? 'active' : '']"
          type="button"
          @click="$emit('onSupportItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-life-ring" />
          </span>
        </button>
      </li>
    </ul>
  </div>
  <div v-else-if="isPhoneAgent" class="page-aside-right">
    <ul class="list-group">
      <li
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.web_browsing_history')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-web-browsing-history"
          :disabled="!enableBtnWebBrowsingHistory"
          :class="['btn btn-icon btn-2 btn-sm', showWebBrowsingHistoryPanel ? 'active' : '']"
          type="button"
          @click="$emit('onWebBrowsingHistoryItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-history" />
          </span>
        </button>
      </li>
      <li
        v-if="selectedUserInfo.userInfo && allowPlatformSurvey"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.AsideRight.index.survey_form')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-csf"
          :class="['btn btn-icon btn-2 btn-sm', showSurveyPanel ? 'active' : '']"
          type="button"
          @click="$emit('onSurveyItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-list-alt" />
          </span>
        </button>
      </li>
      <li
        v-if="selectedUserInfo.userInfo"
        v-b-tooltip.hover
        :title="$t('src.modules.chat.components.CustomerSupportPanel.index.user_info')"
        class="list-group-item text-center"
      >
        <button
          data-testid="menu-csf"
          :class="['btn btn-icon btn-2 btn-sm', showSupportPanel ? 'active' : '']"
          type="button"
          @click="$emit('onSupportItemClick')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-life-ring" />
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PLATFORM, SERVING_STATE, TICKET_STATE, TICKET_STATUS } from 'core/constants';
import noAvatar from 'assets/images/no-avatar.png';
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    conversation: {
      type: Object,
      default: () => true
    },
    showOverviewBox: {
      type: Boolean,
      default: () => true
    },
    showSupportPanel: {
      type: Boolean,
      default: () => false
    },
    showSurveyPanel: {
      type: Boolean,
      default: () => false
    },
    showProductPanel: {
      type: Boolean,
      default: () => false
    },
    showConversationalPanel: {
      type: Boolean,
      default: () => false
    },
    showWebBrowsingHistoryPanel: {
      type: Boolean,
      default: () => false
    },
    showStatusHistoryPanel: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      imageUrl: noAvatar,
      noAvatar: noAvatar,
      permission: {}
    };
  },

  computed: {
    ...mapState('session', ['user', 'channelsMap', 'projectMaps']),
    ...mapState('chat', ['chatViewingGroup', 'messagesNotification']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('session', ['isPhoneAgent']),
    ...mapState('phoneAgentChatBox', ['selectedUserInfo', 'enableBtnWebBrowsingHistory']),

    displayProductFeedsIcon() {
      return (
        this.permission &&
        this.permission.facebookProductCatalog &&
        !this.showOverviewBox &&
        !this.isMonitoringHandle &&
        !this.isFinishTicket &&
        [TICKET_STATE.SUPPORTING, TICKET_STATE.START].includes(this.ticket.state) &&
        !this.isTicketStatusAllow
      );
    },

    displayConversationFeedsIcon() {
      return (
        this.permission &&
        (this.permission.faq || this.permission.agentScenario) &&
        !this.showOverviewBox &&
        !this.isMonitoringHandle &&
        !this.isFinishTicket &&
        [TICKET_STATE.SUPPORTING, TICKET_STATE.START].includes(this.ticket.state) &&
        !this.isTicketStatusAllow
      );
    },

    displayWebBrowsingHistoryIcon() {
      return (
        this.isStateAllow &&
        !this.showOverviewBox &&
        !this.isMonitoringHandle &&
        this.platform === PLATFORM.WEBCHAT
      );
    },

    displaySurveyFormIcon() {
      return (
        this.isStateAllow &&
        !this.showOverviewBox &&
        !this.isMonitoringHandle &&
        this.allowPlatformSurvey
      );
    },

    displayCustomerSupportIcon() {
      return (
        !this.showOverviewBox &&
        !this.isMonitoringHandle &&
        [TICKET_STATE.SUPPORTING, TICKET_STATE.START, TICKET_STATE.FINISH].includes(
          this.ticket.state
        ) &&
        !this.isTicketStatusAllow
      );
    },

    ticket() {
      return (this.selectedConversation && this.selectedConversation.ticket) || {};
    },

    platform() {
      return this.ticket.platform;
    },

    isMonitoringHandle() {
      return this.chatViewingGroup === SERVING_STATE.MONITORING;
    },

    isStateAllow() {
      return [
        TICKET_STATE.SUPPORTING,
        TICKET_STATE.START,
        TICKET_STATE.REQUEST,
        TICKET_STATE.FINISH
      ].includes(this.ticket.state);
    },

    isFinishTicket() {
      return [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(this.ticket.state);
    },

    isTicketStatusAllow() {
      return [
        TICKET_STATUS.ESCALATE_TO,
        TICKET_STATUS.ASSIGN_TO,
        TICKET_STATUS.TRANSFER_TO
      ].includes(this.ticket.status);
    },

    isOpenConversation() {
      return !this.showOverviewBox;
    },

    allowPlatformSurvey() {
      return ![PLATFORM.ZALO, PLATFORM.INSTAGRAM].includes(this.platform);
    },

    avatar() {
      return this.imageUrl;
    },

    listMessagesNotification() {
      let messages = [];
      if (!isEmpty(this.messagesNotification)) {
        for (let key in this.messagesNotification) {
          if (!this.messagesNotification[key].marked) {
            messages.push(this.messagesNotification[key]);
          }
        }
      }
      if (messages.length <= 0) return messages;
      messages = messages.sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      });
      return messages;
    }
  },

  watch: {
    user(user) {
      if (user && user.pictureUrl) this.imageUrl = user.pictureUrl;
    },

    conversation(conversation) {
      const { channelId } = conversation;
      const { projectId = '' } = (conversation && conversation.ticket) || {};
      const { config = {} } = this.projectMaps[projectId] || {};
      const { agentScenario = {} } = config;
      const { enabled = true } = agentScenario;

      if (channelId) {
        const channel = this.channelsMap[channelId];
        const { advancedConfigs = {} } = channel;
        const { modulesActivation = {} } = advancedConfigs;
        this.permission = { ...modulesActivation, agentScenario: enabled };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-aside-right {
  overflow: visible;
}

.list-group {
  .list-group-item {
    &:first-child {
      cursor: pointer;
    }

    #ddown-right {
      /deep/ button {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
      }

      /deep/ .dropdown-menu {
        &.show {
          top: -30px !important;
          left: -10px !important;
        }
      }
    }
  }
}
.btn {
  width: 40px;
  height: 40px;
}

.dropdown-notification-avatar {
  height: 70px;
  padding-right: 5px;

  .dropdown-notification-avatar-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
}

.fa-bell:hover {
  color: #2196f3;
}

.fa-bell {
  color: #adb5bd;
}

.fa-bell:before {
  cursor: pointer;
}

.custom-notification {
  outline: none;
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
}

.channel-notification {
  font-size: 0.8rem;
}

.text-notification {
  font-size: 0.77rem;
}
</style>

<style lang="scss" scoped>
.dropdown-notification {
  max-height: calc(100vh - 180px);
  overflow-y: overlay;

  .el-dropdown-menu__item {
    border-bottom: 1px solid #ebeef5;
    width: 380px;
    line-height: unset;
    padding-top: 10px;

    .dropdown-notification-flex {
      display: flex;
    }

    .dropdown-notification-createdAt {
      font-size: 0.7rem;
      font-weight: 600;
    }
  }
}

.custom-notification {
  .el-dropdown {
    .el-badge {
      outline: none;
    }
  }
}
</style>
