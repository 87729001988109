import Service from 'core/service';
import fetchAPI from 'core/fetchAPI';
export class ChatService extends Service { }
export class ProductFeedService extends Service {
  constructor() {
    const productFeedApi = process.env.VUE_APP_FACEBOOK_PRODUCTFEEDS_API;
    if (!productFeedApi) throw new Error('Product feed service require API before go through');
    super(productFeedApi);
  }

  buildQuery(params) {
    const { productFeed } = this.getChannelConfigsChat();
    if (!productFeed) return [];
    const { accessToken: access_token, catalogId: product_catalog_id } = productFeed || {};

    const toBeParams = {
      access_token,
      product_catalog_id,
      ...params
    };
    const query = this.toQueries(toBeParams);
    return { query: `?${query}`, product_catalog_id };
  }

  async getProducts(params) {
    const { query, product_catalog_id } = this.buildQuery(params || {});

    if (!query) return [];
    try {
      const url = `${process.env.VUE_APP_FACEBOOK_PRODUCTFEEDS_API}/productsets/${query}`;
      const fetch = new fetchAPI({ baseURL: '' });

      return await fetch.get(url).catch(error => {
        if (error && error.response && error.response.data) {
          return { ...error.response.data, product_catalog_id };
        }
        throw error;
      });
    } catch (error) {
      if (error && error.response && error.response.data) {
        return { ...error.response.data, product_catalog_id };
      }
      throw error;
    }
  }
}

export class ConversationFeedService extends Service {
  constructor() {
    const conversationFeedApi = process.env.VUE_APP_FACEBOOK_CONVERSATIONFEEDS_FAQ_API;
    if (!conversationFeedApi)
      throw new Error('Conversation feed service require API before go through');
    super(conversationFeedApi);
  }

  getConversationsNLP(params) {
    const { modulesActivation = {} } = this.getChannelAdvancedConfigs();
    const { chatResponser = true, nlp = true } = modulesActivation;
    const { page_id = '', language = '' } = params;
    if (!chatResponser || !nlp) return [];
    if (!page_id || !language) return [];

    return this.post('api/question/list', {
      page_id: page_id.toLowerCase(),
      language,
      type: '_doc',
      mode: 'api'
    })
      .then(data => data)
      .catch(() => []);
  }

  getConversationsFAQ(params) {
    const { modulesActivation = {} } = this.getChannelAdvancedConfigs();
    const { chatResponser = true, faq = true } = modulesActivation;
    const { page_id = '', language = '', category = '', subcategory = '' } = params;
    if (!chatResponser || !faq) return [];
    if (!page_id || !language) return [];

    return this.post('api/manual/list', {
      page_id: page_id.toLowerCase(),
      language,
      type: '_doc',
      mode: 'api',
      category,
      subcategory,
      usedFor: 'agent'
    })
      .then(data => data)
      .catch(() => []);
  }

  getListLanguage() {
    return this.get('api/config/listLanguage')
      .then(data => data)
      .catch(() => []);
  }

  getlistFAQCategory(params) {
    const { modulesActivation = {} } = this.getChannelAdvancedConfigs();
    const { chatResponser = true, faq = true } = modulesActivation;
    const { pageId = '', language = '' } = params;
    if (!chatResponser || !faq) return [];
    if (!pageId || !language) return [];

    return this.post('api/manual/listCategory', {
      page_id: pageId.toLowerCase(),
      language,
      type: '_doc',
      mode: 'api',
      usedFor: 'agent'
    })
      .then(data => data)
      .catch(() => []);
  }

  getlistFAQSubCategory(params) {
    const { modulesActivation = {} } = this.getChannelAdvancedConfigs();
    const { chatResponser = true, faq = true } = modulesActivation;
    const { pageId = '', language = '', category = '' } = params;
    if (!chatResponser || !faq) return [];
    if (!pageId || !language) return [];
    return this.post('api/manual/listSubCategory', {
      page_id: pageId.toLowerCase(),
      language,
      type: '_doc',
      mode: 'api',
      category,
      usedFor: 'agent'
    })
      .then(data => data)
      .catch(() => []);
  }
}
