<template>
  <div id="surveyTable" class="cs-container" v-on:scroll.passive="handleScroll">
    <div>
      <div class="sv-title">
        <label class="sv-select-title">{{
          $t('src.modules.chat.components.SurveyFormPanel.SurveyTable.survey_teamplate')
        }}</label>
      </div>
      <el-select class="sv-form-select" :value="itemSelected" @change="handleSelect">
        <el-option v-for="item in data" :key="item.value" :label="item.label" :value="item">
          <span class="sv-sl-label">{{ item.label }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="survey-form-body">
      <div v-for="(item, index) in questions" :key="index">
        <input-item v-if="item.type === ITEM_QUESTION_TYPE.TEXT" :item="item" />
        <radio-item v-else-if="item.type === ITEM_QUESTION_TYPE.RADIO" :item="item" />
        <checkbox-item v-else-if="item.type === ITEM_QUESTION_TYPE.CHECKBOX" :item="item" />
        <textbox-item v-else-if="item.type === ITEM_QUESTION_TYPE.TEXTAREA" :item="item" />
        <dropdown-item v-else-if="item.type === ITEM_QUESTION_TYPE.DROPDOWN" :item="item" />
        <range-item
          v-else-if="item.type === ITEM_QUESTION_TYPE.RANGE"
          :item="item"
          :is-search="isSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ITEM_QUESTION_TYPE } from 'core/constants';
import InputItem from './InputItem';
import RadioItem from './RadioItem';
import CheckboxItem from './CheckboxItem';
import TextboxItem from './TextboxItem';
import DropdownItem from './DropdownItem';
import RangeItem from './RangeItem';

export default {
  components: {
    InputItem,
    RadioItem,
    CheckboxItem,
    TextboxItem,
    DropdownItem,
    RangeItem
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    questions: {
      type: Array,
      default: () => []
    },
    itemSelected: {
      type: Object,
      default: () => {}
    },
    isSearch: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      ITEM_QUESTION_TYPE
    };
  },

  methods: {
    handleSelect(val) {
      this.$emit('onChangeSurveyForm', val);
    },

    handleScroll() {
      const contentElement = document.getElementById('surveyTable');
      if (!contentElement) return;
      contentElement.setAttribute('style', 'transform: translate3d(0px, 0px, 0px);');
    }
  }
};
</script>
<style lang="scss" scoped></style>