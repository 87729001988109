import moment from 'moment';
import { i18n } from 'locales';

export default {
  install() {
    moment.locale('en', {
      relativeTime: {
        future: '%s',
        past: '%s',
        s: i18n.t('src.core.timeago.just_now'),
        ss: i18n.t('src.core.timeago.s', { val: '%s' }),
        m: i18n.t('src.core.timeago.a_min'),
        mm: i18n.t('src.core.timeago.m', { val: '%d' }),
        h: i18n.t('src.core.timeago.an_hour'),
        hh: i18n.t('src.core.timeago.h', { val: '%d' }),
        d: i18n.t('src.core.timeago.a_day'),
        dd: i18n.t('src.core.timeago.d', { val: '%d' }),
        M: i18n.t('src.core.timeago.a_month'),
        MM: i18n.t('src.core.timeago.M', { val: '%d' }),
        y: i18n.t('src.core.timeago.a_year'),
        yy: i18n.t('src.core.timeago.y', { val: '%d' })
      }
    });
  }
};
