<template>
  <b-modal
    id="modalProductFeeds"
    v-model="show"
    :title="$t('src.modules.chat.components.ProductPanel.product-view.product_detail')"
    size="lg"
  >
    <b-container fluid>
      <div class="row">
        <div class="col-sm image-section">
          <img :src="image_url" />
        </div>
        <div class="col-sm product-section">
          <h3>{{ name }}</h3>
          <p class="price">{{ currencyFormatting }}</p>
          <p class="availability">
            <span class="badge badge-primary">{{ availability }}</span>
          </p>
          <div class="description">{{ description }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm product-main">
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">Id</div>
            <div class="col-8 col-md-9 text-left value">{{ id }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{ $t('src.modules.chat.components.ProductPanel.product-view.age_group') }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ age_group }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{ $t('src.modules.chat.components.ProductPanel.product-view.gender') }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ gender }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{ $t('src.modules.chat.components.ProductPanel.product-view.brand') }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ brand }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{ $t('src.modules.chat.components.ProductPanel.product-view.condition') }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ condition }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{
                $t(
                  'src.modules.chat.components.ProductPanel.product-view.shipping_weight_value'
                )
              }}
            </div>
            <div class="col-8 col-md-9 text-left value">
              {{ shipping_weight_value }}
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{
                $t('src.modules.chat.components.ProductPanel.product-view.retailer_id')
              }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ retailer_id }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">Link</div>
            <div class="col-8 col-md-9 text-left value">{{ url }}</div>
          </div>
          <div class="row">
            <div class="col-4 col-md-3 text-left text-md-right label">
              {{
                $t('src.modules.chat.components.ProductPanel.product-view.availability')
              }}
            </div>
            <div class="col-8 col-md-9 text-left value">{{ availability }}</div>
          </div>
        </div>
      </div>
    </b-container>
    <div slot="modal-footer" class="w-100">
      <b-btn
        v-if="isSubmitSupported"
        class="float-right ml-10"
        variant="primary"
        @click="onSend"
        >{{ $t('common.text.send') }}</b-btn
      >
      <b-btn class="float-right" variant="default" @click="onClose">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { MessageType } from 'core/message';
import { mapState, mapActions, mapGetters } from 'vuex';
import { TICKET_STATE } from 'core/constants';

export default {
  props: {
    age_group: {
      type: String,
      default: ''
    },
    availability: {
      type: String,
      default: ''
    },
    brand: {
      type: String,
      default: ''
    },
    condition: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    image_url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    retailer_id: {
      type: String,
      default: ''
    },
    shipping_weight_value: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapGetters('chat', ['selectedConversation']),

    currencyFormatting() {
      return `${this.price}`;
    },

    productInfo() {
      return {
        id: this.id,
        image: this.image_url,
        name: this.name,
        price: this.price,
        url: this.url
      };
    },

    isSubmitSupported() {
      if (
        this.selectedConversation.ticket &&
        this.selectedConversation.ticket.state !== TICKET_STATE.FINISH &&
        this.selectedConversation.ticket.agentId === this.user.id
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    ...mapActions('chat', ['sendMessage']),

    buildMsgPayload(products) {
      let titleBtn = this.$t(
        'src.modules.chat.components.ProductPanel.product-view.view_in_website'
      );
      titleBtn =
        titleBtn && titleBtn.length > 20 ? titleBtn.substring(0, 17) + '...' : titleBtn;
      return {
        template_type: 'generic',
        elements: products.map(
          ({ image: image_url, name: title, price: subtitle, url }) => {
            return {
              title,
              image_url,
              subtitle,
              default_action: {
                type: 'web_url',
                url
              },
              buttons: [
                {
                  type: 'web_url',
                  url,
                  title: titleBtn
                }
              ]
            };
          }
        )
      };
    },

    onSend() {
      this.show = false;
      const msg = this.buildMsgPayload([this.productInfo]);
      const productIds = [];
      [this.productInfo].filter(res => {
        productIds.push(res.id);
      });
      this.sendMessage({
        sender: this.user.id,
        type: MessageType.TEMPLATE,
        body: msg,
        productIds: productIds.toString()
      });
    },

    onClose() {
      this.show = false;
    }
  }
};
</script>

<style>
#modalProductFeeds .image-section img {
  width: 100%;
}

#modalProductFeeds .product-main {
  margin: 15px;
  padding: 25px;
  border: 0.5px solid #ddd;
  border-radius: 10px;
}

#modalProductFeeds .product-section {
  text-align: left;
}

#modalProductFeeds .product-section .price {
  font-weight: bold;
}

#modalProductFeeds .product-section .availability {
  font-size: 20px;
}

#modalProductFeeds .product-section .availability {
  font-weight: bold;
}

#modalProductFeeds .product-main .row {
  padding: 5px 0;
}

#modalProductFeeds .product-main .label {
  font-weight: bold;
}

#modalProductFeeds .product-main .value {
  padding-left: 5px;
  overflow-wrap: break-word;
}
</style>
