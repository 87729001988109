<template>
  <form role="form">
    <div class="card">
      <div class="card-header text-left">
        {{ $t('src.modules.profile.components.setting.status_settings') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(status, index) in lstStatus"
            :key="index"
            class="col-lg-4 col-sm-6 mb-1"
          >
            <b-input-group>
              <b-input-group-prepend>
                <el-tooltip
                  :content="statusLabelDefault[status]"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div
                    :style="'background-color: ' + statusColor[status]"
                    class="status-indicator"
                  ></div>
                </el-tooltip>
              </b-input-group-prepend>
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <base-input
                    v-model="statusLabel[status]"
                    :align="'vertical-no-label'"
                    :label="statusPlaceholder[status]"
                    :name="'statusLabel'"
                    :maxlength="20"
                    :required="true"
                    @input="handleInput"
                  />
                </div>
              </div>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Notification setting -->
    <div class="card">
      <div class="card-header text-left">
        {{ $t('src.modules.profile.components.setting.notification_settings') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5 col-sm-12 mb-1"></div>
          <div class="col-md-2 col-sm-12 mb-1 text-left">
            <div class="sub-title">
              <b>{{ $t('src.modules.profile.components.setting.notification') }}</b>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 mb-1 text-left">
            <div class="sub-title">
              <b>{{ $t('src.modules.profile.components.setting.sound') }}</b>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in notificationSettings" :key="index" class="row">
          <div class="col-md-5 col-sm-12 mb-1">
            <div class="form-group">
              <div class="sub-title">{{ notificationSettingsTitle[item.KEY] }}</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-12 mb-1 text-left">
            <el-switch
              v-model="item.NOTIFY_ENABLE"
              @change="setSoundSwitch(item)"
            ></el-switch>
          </div>
          <div class="col-md-1 col-sm-12 mb-1 text-left">
            <el-switch
              v-model="item.SOUND_ENABLE"
              :disabled="item.NOTIFY_ENABLE ? false : true"
            ></el-switch>
          </div>
          <div class="col-md-3 col-sm-4 mb-1 mt-5 text-left">
            <el-select
              v-model="item.SOUND_FILE"
              :disabled="item.SOUND_ENABLE ? false : true"
              placeholder="Select"
              class="sound-list"
            >
              <el-option
                v-for="(item, index) in soundList"
                :key="item"
                :selected="index === 0"
                :label="item.replace('_', ' ')"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="col-md-1 col-sm-1 mb-1 text-left">
            <i class="fas fa-play-circle fa-lg" @click="playAudio(item.SOUND_FILE)"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- Action setting -->
    <div class="card">
      <div class="card-header text-left">
        {{ $t('src.modules.profile.components.setting.action_settings') }}
      </div>
      <div class="card-body">
        <div v-for="(item, index) in actionSettings" :key="index" class="row">
          <div class="col-lg-5 col-sm-5 mb-1">
            <div class="form-group">
              <div class="item-action">
                <div class="sub-title">
                  {{ actionSettingsTitle[item.KEY] }}
                  <span>
                    <i
                      v-b-tooltip.hover
                      :title="actionSettingsTooltip[item.TOOLTIP]"
                      class="far fa-question-circle"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-sm-2 mb-1 text-left">
            <el-switch v-model="item.CONFIRM_ENABLE"></el-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary my-4"
        @click="handleFormSubmit"
        :disabled="showBtnSave"
      >
        {{ $t('common.text.save') }}
        <i v-if="submitFormLoading" class="fas fa-spinner fa-spin fa-lg" />
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Message } from 'element-ui';
import { isEmpty, cloneDeep } from 'lodash';
import {
  AGENT_STATUS,
  AGENT_ROLE,
  AGENT_STATUS_COLOR,
  DEFAULT_AGENT_STATUS_LABEL,
  NOTIFICATION_SETTINGS_DEFAULT,
  SOUND_LIST,
  NOTIFICATION_SETTINGS_TITLE,
  ACTION_SETTINGS_DEFAULT,
  ACTION_SETTINGS_TITLE,
  ACTION_SETTINGS_TOOLTIP,
  AGENT_STATUS_PLACEHOLDER
} from 'core/constants';

export default {
  props: {
    pictureUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      submitFormLoading: false,
      formData: {},
      statusLabel: DEFAULT_AGENT_STATUS_LABEL,
      statusLabelDefault: DEFAULT_AGENT_STATUS_LABEL,
      lstStatus: [
        AGENT_STATUS.ONLINE,
        AGENT_STATUS.OFFLINE,
        AGENT_STATUS.BUSY,
        AGENT_STATUS.AWAY,
        AGENT_STATUS.LUNCH,
        AGENT_STATUS.BREAK
      ],
      statusColor: AGENT_STATUS_COLOR,
      statusPlaceholder: AGENT_STATUS_PLACEHOLDER,
      notificationSettings: NOTIFICATION_SETTINGS_DEFAULT,
      notificationSettingsTitle: NOTIFICATION_SETTINGS_TITLE,
      soundList: SOUND_LIST,
      actionSettings: ACTION_SETTINGS_DEFAULT,
      actionSettingsTitle: ACTION_SETTINGS_TITLE,
      actionSettingsTooltip: ACTION_SETTINGS_TOOLTIP,
      showBtnSave: false,
      displayLanguage: 'en_US'
    };
  },

  computed: {
    ...mapState('session', ['user']),
  },

  watch: {
    user(val) {
      if (!isEmpty(val)) {
        const { statusLabel = DEFAULT_AGENT_STATUS_LABEL } = val;
        const status = statusLabel[this.displayLanguage] ? statusLabel[this.displayLanguage] : DEFAULT_AGENT_STATUS_LABEL;
        const { notificationSettings = NOTIFICATION_SETTINGS_DEFAULT } = val;
        const { actionSettings = ACTION_SETTINGS_DEFAULT } = val;
        this.statusLabel = { ...status };
        this.notificationSettings = cloneDeep(notificationSettings);
        this.actionSettings = cloneDeep(actionSettings);
        this.updateSettingsWithRole();
      }
    },
    pictureUrl(val) {
      this.formData.pictureUrl = val;
    }
  },

  async created() {
    this.setGlobalReady(false);
    const user = await this.getSessionUser();
    if (!user) {
      this.setGlobalReady(true);
      return;
    }
    this.formData = { ...user };
    this.setGlobalReady(true);
  },

  async mounted() {    
    const lang = localStorage.getItem('language') || 'en_US';
    this.displayLanguage = lang.replace('-', '_');
    if (this.user) {
      const { statusLabel = DEFAULT_AGENT_STATUS_LABEL } = this.user;
      const status = statusLabel[this.displayLanguage] ? statusLabel[this.displayLanguage] : DEFAULT_AGENT_STATUS_LABEL;
      const { notificationSettings = NOTIFICATION_SETTINGS_DEFAULT } = this.user;
      const { actionSettings = ACTION_SETTINGS_DEFAULT } = this.user;
      this.statusLabel = { ...status };
      this.notificationSettings = cloneDeep(notificationSettings);
      this.actionSettings = cloneDeep(actionSettings);
      this.updateSettingsWithRole();
    }
  },

  methods: {
    ...mapActions('session', ['getSessionUser']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('profile', ['submitForm']),

    setSoundSwitch(item) {
      if (!item.NOTIFY_ENABLE) item.SOUND_ENABLE = false;
    },

    updateSettingsWithRole() {
      const { role = '' } = this.user;
      if(role === AGENT_ROLE.CLIENT) {
         return this.$router.push('/');
      }
      const settings = NOTIFICATION_SETTINGS_DEFAULT;
      const actSettings = ACTION_SETTINGS_DEFAULT;
      const oldActSettings = this.actionSettings.reduce((acc, i) => {
        acc[i.KEY] = i;
        return acc;
      }, {});
      const oldSettings = this.notificationSettings.reduce((acc, i) => {
        acc[i.KEY] = i;
        return acc;
      }, {});
      const newSeetings = settings
        .map((i) => {
          if (['Regular'].includes(role) && i.KEY === 'WARNING_WORD') {
            return false;
          } else if (oldSettings[i.KEY]) {
            return {
              ...i,
              ...oldSettings[i.KEY]
            };
          } else return { ...i };
        })
        .filter((i) => i);

      const newActSettings = actSettings
        .map((i) => {
          if (oldActSettings[i.KEY]) {
            return {
              ...i,
              ...oldActSettings[i.KEY]
            };
          } else return { ...i };
        })
        .filter((i) => i);

      this.actionSettings = newActSettings;
      this.notificationSettings = newSeetings;
    },

    async handleFormSubmit() {
      this.submitFormLoading = true;
      const valueStatus = Object.values(this.statusLabel).map((s) =>
        s.toLowerCase().trim()
      );
      if (this.checkDuplicateStatus(valueStatus)) {
        this.errorMessageStatus();
        this.submitFormLoading = false;
        return;
      }
      let { statusLabel = {} } = {...this.user};
      const status = {...statusLabel};
      status[this.displayLanguage] = this.statusLabel;
      const formUserUpdate = {
        id: this.formData.id,
        pictureUrl: this.formData.pictureUrl,
        statusLabel: status,
        notificationSettings: this.notificationSettings,
        actionSettings: this.actionSettings
      };
      this.submitForm(formUserUpdate).then((res) => {
        this.submitFormLoading = false;
        // revert old user data in case of error
        if (!res) {
          this.formData = { ...this.user };
        } else {
          this.getSessionUser(true);
          this.successMessage();
        }
      });
    },

    playAudio(fileName) {
      const audio = new Audio(require(`../../../assets/audio/${fileName}.mp3`));
      audio.play();
    },

    errorMessage() {
      Message.error(
        this.$t('src.modules.profile.components.setting.please_check_the_invalid_data')
      );
    },

    errorMessageStatus() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.setting.agent_status_must_be_unique'
        )
      });
    },

    successMessage() {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: this.$t('src.modules.profile.components.setting.update_successful')
      });
    },

    handleInput() {
      const emptyStatus = Object.keys(this.statusLabel).filter(
        (s) => this.statusLabel[s].trim() === ''
      );
      return emptyStatus.length > 0
        ? (this.showBtnSave = true)
        : (this.showBtnSave = false);
    },

    checkDuplicateStatus(arr) {
      return new Set(arr).size !== arr.length;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  border: 0px;
  height: 28px;
  line-height: 28px;
  padding-right: 35px;
  font-size: 0.875rem;
  font-weight: 100;
}

/deep/.custom-control-label {
  color: #606266;
  font-weight: 100;
}

.sound-list {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
  top: -6px;
}

.card {
  margin-bottom: 20px;
}

::placeholder {
  color: #cccccc;
}

.status-indicator {
  background-color: green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: auto 10px 15px;
}

.status-label-input {
  border: 1px solid #cad1d7 !important;
  border-radius: 0.25rem !important;
}

.form-control {
  padding-left: 0 !important;
}

.sub-title {
  margin-bottom: 4px;
  text-align: left;
  color: #606266;
  font-size: 0.875rem;
  font-weight: 100;
}

.fa-play-circle:hover {
  color: #409eff;
}

.fa-play-circle::before {
  cursor: pointer;
}

.item-action {
  display: flex;
}

/deep/.el-switch.is-checked {
  .el-switch__core {
    border-color: #fb6340 !important;
    background: #fb6340 !important;
  }
}

.input-group-alternative {
  position: relative;
  margin: 1.25rem 0;
  min-width: 200px;
  box-shadow: none;
  /deep/ .nat-invalid-data {
    position: absolute;
  }

  /deep/ .nat-maxlengthTag {
    position: absolute;
    top: -15px;
  }

  /deep/ .nat-input-verti-no-label {
    width: 210px;
  }

  /deep/ .red-border {
    width: 210px;
  }
}

.input-group {
  align-items: center;
}
</style>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';

.sound-list {
  .el-input {
    .el-input__suffix {
      height: 130% !important;
    }
  }
}
</style>
