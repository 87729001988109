import fetchAPI from 'core/fetchAPI';

export class AccessLogService extends fetchAPI {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_REPORT_API_URL}/`,
      headers: {
        'x-api-key': process.env.VUE_APP_REPORT_API_KEY
      },
      _options: {}
    });
  }

  async getAccessLogs({
    agentRole,
    fromDate,
    toDate,
    assignedProjects,
    projectId = null,
    loginStatus = null
  }) {
    if (!agentRole || !fromDate || !toDate) return [];
    return this.post('report/access_log', {
      agentRole,
      fromDate,
      toDate,
      assignedProjects,
      projectId,
      loginStatus
    })
      .then(res => res.data.data.data || [])
      .catch(error => {
        // eslint-disable-next-line
        console.log('getAccessLogs >> error', error);
        return [];
      });
  }
}
