<template>
  <span v-if="content" :id="name.replace(/ /g, '-') + '-' + id">
    <el-tooltip class="item" effect="dark" :content="content" placement="top-start">
      <i v-if="icon === 'question'" class="far fa-question-circle"></i>
      <i v-if="icon === 'info'" class="fas fa-info-circle"></i>
    </el-tooltip>
  </span>
</template>

<script>
import { uuid } from 'vue-uuid';
function defaultState() {
  return {};
}

export default {
  name: 'base-tooltip-icon',
  props: {
    boundary: {
      type: String,
      default: 'viewport'
    },
    content: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 100
    },
    icon: {
      type: String,
      default: 'question'
    },
    id: {
      type: String,
      default: uuid.v4()
    },
    name: {
      type: String,
      default: uuid.v4()
    },
    placement: {
      type: String,
      default: 'top'
    },
    triggers: {
      type: String,
      default: 'hover'
    }
  },

  data: defaultState,

  computed: {},

  created() {},

  destroyed() {}
};
</script>
<style lang="scss" scoped></style>
