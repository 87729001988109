import {
  SET_PROJECTS,
  SET_FILES,
  REMOVE_FILE_LOCAL,
  ADD_FILE_LOCAL,
  SET_LOADING
} from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  fileMap: {}, // {path: files}
  loading: false
};

const mutations = {
  [SET_PROJECTS]() {},
  [SET_FILES](state, { path, files }) {
    state.fileMap = { ...state.fileMap, [path]: files };
  },
  [REMOVE_FILE_LOCAL](state, { project_id, id }) {
    const { fileMap } = state;
    const files = fileMap[project_id];
    const index = files.findIndex(({ id: fileId }) => fileId === id);
    if (index >= 0) files.splice(index, 1);
    state.fileMap = { ...state.fileMap, [project_id]: files };
  },
  [ADD_FILE_LOCAL](state, row) {
    const { fileMap } = state;
    const { project_id } = row;
    const files = fileMap[project_id];
    files.push(row);
    state.fileMap = { ...state.fileMap, [project_id]: files };
  },
  [SET_LOADING](state, loading = false) {
    state.loading = loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
