import * as actions from './actions';
import { SET_PROJECT_LABELS } from './types';

const state = {
  currentUserLabels: [],
  currentConversationLabels: []
};

const mutations = {
  [SET_PROJECT_LABELS]() {}
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
