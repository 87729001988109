export const filterCampaignByScenarioName = state => {
  const { campaigns, searchScenarioName } = state;
  if (searchScenarioName === '') return campaigns;
  const filterCampaigns = [];
  campaigns.forEach(campaign => {
    const newCampaign = { ...campaign };
    newCampaign.scenarios = newCampaign.scenarios.filter(scenario =>
      scenario.name.toLowerCase().includes(searchScenarioName.toLowerCase().trim())
    );
    if (newCampaign.scenarios.length > 0) {
      filterCampaigns.push(newCampaign);
    }
  });
  return filterCampaigns;
};
