<template>
  <div class="message-video">
    <a :href="message.content" target="_blank">
      <i class="fas fa-cloud-download-alt mr-10" /> {{ content }}
    </a>
  </div>
</template>

<script>
const FILE_NAME_REGEX = /(?:\/)([\w,\s-]+\.[A-Za-z]{2,5})(?:\?)/;
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    content() {
      const { content, text } = this.message;
      const groups = FILE_NAME_REGEX.exec(content);
      if (!groups || groups.length < 2) return text || 'Download File';
      return groups[1];
    }
  }
};
</script>
