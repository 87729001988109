<template>
  <ValidationObserver ref="conversationLogSchedule">
    <div id="modal-select-expored-columns" class="modal-content div">
      <div class="modal-body">
        <b-tabs content-class="mt-3">
          <!-- Tabs Schedule -->
          <b-tab
            :title="
              $t('src.modules.report.components.cnv-scheduled-export.index.schedule_tab_title')
            "
          >
            <!-- Div list tags export -->
            <div class="mdl-grid mdl-grid center-items list-columns-export">
              <div id="sdt-selected-tags" class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet">
                <div class="panel-body sdt-teal-switch text-center">
                  <label>
                    <span class="mdl-switch__label remove-column">
                      {{
                        $t('src.modules.report.components.ColumnFilterModal.index.removed_columns')
                      }}
                    </span>
                  </label>
                </div>

                <button
                  class="
                    sdt-sortable-btn sdt-sort-button
                    mdl-button mdl-button--fab mdl-button--mini-fab
                    mdl-js-button
                    mdl-button--raised
                    mdl-js-ripple-effect
                    mdl-button--accent
                    mdl-color--teal-100
                  "
                  @click="moveUnclockTags(selected, available)"
                >
                  <i class="material-icons sdt-color-teal">
                    <arrow-right-icon />
                  </i>
                </button>

                <button
                  class="
                    sdt-removeable-btn sdt-remove-button
                    mdl-button mdl-button--fab mdl-button--mini-fab
                    mdl-js-button
                    mdl-button--raised
                    mdl-js-ripple-effect
                    mdl-button--accent
                    mdl-color--teal-100
                  "
                  @click="rmOrderList(selected.tags)"
                >
                  <i
                    class="
                      sdt-tag-icon sdt-tag-icon-left
                      material-icons
                      sdt-color-teal sdt-tag-icon
                    "
                  >
                    <sort-alphabetical-icon />
                  </i>
                </button>

                <div class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--2dp mdl-color--teal-500">
                  <draggable
                    v-model="selected.tags"
                    :options="getOptions(selected)"
                    :move="onMove"
                    element="span"
                    @start="isDragging = true"
                    @end="isDragging = true"
                  >
                    <transition-group
                      :name="'flip-list'"
                      type="transition"
                      class="mdl-list dragArea"
                      tag="ul"
                    >
                      <li
                        v-for="(element, index) in selected.tags"
                        :key="element.tag"
                        class="
                          sdt-tag
                          mdl-cell mdl-cell--12-col
                          sdt-tag-item
                          mdl-list__item
                          mdl-card
                          mdl-shadow--2dp
                        "
                      >
                        <span class="mdl-list__item-primary-content">
                          <i
                            :class="[element.fixed ? 'sdt-color-red' : 'sdt-color-gray']"
                            class="material-icons sdt-tag-icon sdt-tag-icon-left"
                            @click="element.fixed = !element.fixed"
                          >
                            <lock-icon />
                          </i>
                          {{ element.tag }}
                        </span>
                        <i
                          :class="[element.fixed ? 'sdt-color-gray' : 'sdt-color-teal']"
                          class="sdt-tag-icon sdt-tag-icon-right material-icons"
                          @click="onClick(index, selected, available)"
                        >
                          <arrow-right-icon />
                        </i>
                      </li>
                    </transition-group>
                  </draggable>
                </div>
              </div>

              <div id="sdt-available-tags" class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet">
                <div class="panel-body sdt-indigo-switch text-center">
                  <label>
                    <span class="mdl-switch__label export-column">
                      {{
                        $t('src.modules.report.components.ColumnFilterModal.index.exported_columns')
                      }}
                    </span>
                  </label>
                </div>
                <button
                  class="
                    sdt-removeable-btn sdt-remove-button
                    mdl-button mdl-button--fab mdl-button--mini-fab
                    mdl-js-button
                    mdl-button--raised
                    mdl-js-ripple-effect
                    mdl-button--accent
                    mdl-color--indigo-100
                  "
                  @click="moveUnclockTags(available, selected)"
                >
                  <i
                    class="
                      sdt-tag-icon sdt-tag-icon-left
                      material-icons
                      sdt-color-indigo sdt-tag-icon
                    "
                  >
                    <arrow-left-icon />
                  </i>
                </button>
                <button
                  class="
                    sdt-sortable-btn sdt-sort-button
                    mdl-button mdl-button--fab mdl-button--mini-fab
                    mdl-js-button
                    mdl-button--raised
                    mdl-js-ripple-effect
                    mdl-button--accent
                    mdl-color--indigo-100
                  "
                  @click="exOrderList(available.tags)"
                >
                  <i class="material-icons sdt-color-indigo">
                    <sort-alphabetical-icon />
                  </i>
                </button>
                <div
                  class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--2dp mdl-color--indigo-500"
                >
                  <draggable
                    v-model="available.tags"
                    :options="getOptions(available)"
                    :move="onMove"
                    element="span"
                    @start="isDragging = true"
                    @end="isDragging = true"
                  >
                    <transition-group
                      :name="'flip-list'"
                      type="transition"
                      class="mdl-list dragArea"
                      tag="ul"
                    >
                      <li
                        v-for="(element, index) in available.tags"
                        :key="element.tag"
                        class="
                          sdt-tag
                          mdl-cell mdl-cell--12-col
                          sdt-tag-item
                          mdl-list__item
                          mdl-card
                          mdl-shadow--2dp
                        "
                      >
                        <span class="mdl-list__item-primary-content">
                          <i
                            :class="[element.fixed ? 'sdt-color-gray' : 'sdt-color-indigo']"
                            class="sdt-tag-icon sdt-tag-icon-left material-icons"
                            @click="onClick(index, available, selected)"
                          >
                            <arrow-left-icon />
                          </i>
                          {{ element.tag }}
                        </span>
                        <i
                          :class="[element.fixed ? 'sdt-color-red' : 'sdt-color-gray']"
                          class="material-icons sdt-tag-icon sdt-tag-icon-right"
                          :style="element.disabled ? disabledRowExportStyle : ''"
                          @click="element.fixed = element.disabled ? element.fixed : !element.fixed"
                        >
                          <lock-icon />
                        </i>
                      </li>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
            <!-- END list tags export -->

            <!-- Table list export -->
            <el-row ref="refListExport">
              <el-table
                :data="listExportSchedules"
                :empty-text="
                  $t(
                    'src.modules.report.components.cnv-scheduled-export.index.schedule-table.empty-schedule'
                  )
                "
                style="width: 100%"
                max-height="500"
              >
                <el-table-column
                  :label="
                    $t('src.modules.report.components.cnv-scheduled-export.index.create_time')
                  "
                >
                  <template slot-scope="scope">
                    <span>{{ formatTime(scope.row.createdAt) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_by')"
                  prop="agentName"
                ></el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.type')"
                  prop="type"
                >
                  <template slot-scope="scope">
                    <span>{{ formatType(scope.row.type) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.report.components.cnv-scheduled-export.index.detail')"
                >
                  <template slot-scope="scope">
                    <template v-if="scope.row.type == 'immediate'">
                      <el-tag class="responsive-tag" effect="plain">{{
                        convertFormatTime(scope.row.fromDate)
                      }}</el-tag
                      >-
                      <el-tag class="responsive-tag" effect="plain">{{
                        convertFormatTime(scope.row.toDate)
                      }}</el-tag>
                    </template>
                    <el-tag
                      class="responsive-tag"
                      v-if="scope.row.type == 'weekly'"
                      effect="plain"
                      type="success"
                      >{{ getWeeklyMonthlyDetail(scope.row.weekDay, scope.row.type) }}</el-tag
                    >
                    <el-tag
                      class="responsive-tag"
                      v-if="scope.row.type == 'monthly'"
                      effect="plain"
                      type="warning"
                      >{{ getWeeklyMonthlyDetail(scope.row.monthDay, scope.row.type) }}</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('src.modules.search-management.index.status')"
                  prop="status"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.status == 'error'"
                      type="danger"
                      disable-transitions
                      v-b-tooltip.hover
                      :title="scope.row.exportError"
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.error'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'success'"
                      type="success"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.success'
                        )
                      }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.status == 'open'" type disable-transitions>
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.open'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'running'"
                      type="warning"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.running'
                        )
                      }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status == 'stop'"
                      type="danger"
                      disable-transitions
                    >
                      {{
                        $t(
                          'src.modules.report.components.cnv-scheduled-export.index.schedule.status.stop'
                        )
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.text.action')" width="200">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.type == 'immediate' && scope.row.status != 'running'"
                      style="margin-left: -20px"
                      type="text"
                      size="small"
                      @click.native.prevent="removeExportCustomerSchedule(scope.row)"
                      >{{ $t('common.text.delete') }}</el-button
                    >
                    <template v-if="scope.row.type == 'weekly' || scope.row.type == 'monthly'">
                      <el-button
                        v-if="scope.row.status != 'running'"
                        type="text"
                        size="12px"
                        @click.native.prevent="removeExportCustomerSchedule(scope.row)"
                        >{{ $t('common.text.delete') }}</el-button
                      >
                      <span
                        v-if="
                          scope.row.status == 'open' ||
                          scope.row.status == 'stop' ||
                          scope.row.status == 'error'
                        "
                        >|</span
                      >
                      <el-button
                        v-if="scope.row.status == 'open'"
                        type="text"
                        size="small"
                        style="color: red"
                        @click="updateExportCustomerSchedule(scope.row, 'status', 'stop')"
                      >
                        {{
                          $t(
                            'src.modules.report.components.cnv-scheduled-export.index.schedule.status.stop'
                          )
                        }}
                      </el-button>
                      <el-button
                        v-if="scope.row.status == 'stop' || scope.row.status == 'error'"
                        type="text"
                        size="small"
                        @click="updateExportCustomerSchedule(scope.row, 'status', 'open')"
                      >
                        {{
                          $t(
                            'src.modules.report.components.cnv-scheduled-export.index.schedule.status.open'
                          )
                        }}
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <!-- END Table list export -->
          </b-tab>
          <!-- END Tabs Schedule -->

          <!-- Tabs Files -->
          <b-tab :title="$t('src.modules.report.components.index.title.files')" @click="clickTab">
            <el-table
              :data="listExportFiles"
              :empty-text="
                $t(
                  'src.modules.report.components.cnv-scheduled-export.index.history-table.empty-history'
                )
              "
              style="width: 100%"
              max-height="500"
            >
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_time')"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.createdAt) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.create_by')"
                prop="agentName"
              ></el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.from_date')"
                prop="fromDate"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.fromDate, 'dateRange') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.to_date')"
                prop="toDate"
              >
                <template slot-scope="scope">
                  <span>{{ formatTime(scope.row.toDate, 'dateRange') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.cnv-scheduled-export.index.type')"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.type == 'child_schedule'">{{
                    scope.row.parentType
                  }}</template>
                  <template v-else>{{ formatType(scope.row.type) }}</template>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('src.modules.report.components.common.index.file_format')"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.exportFormat.toUpperCase() }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="150"
                :label="
                  $t('src.modules.report.components.cnv-scheduled-export.index.download_link')
                "
              >
                <template slot-scope="scope">
                  <el-tag
                    class="responsive-tag"
                    v-if="!scope.row.exportedFileLink"
                    type="warning"
                    disable-transitions
                    >{{ $t('common.text.no_data') }}</el-tag
                  >
                  <el-tag v-else type="success" disable-transitions>
                    <a
                      class="responsive-tag"
                      @click="downloadReport(scope.row.id)"
                      style="cursor: pointer"
                      >{{ $t('src.modules.report.components.index.button.download') }}</a
                    >
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </b-tab>
          <!-- END Tabs Files -->
        </b-tabs>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import 'material-design-lite/material.min.css';
import 'vue-material-design-icons/styles.css';
import draggable from 'vuedraggable';
import LockIcon from 'vue-material-design-icons/Lock.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';
import SortAlphabeticalIcon from 'vue-material-design-icons/SortAlphabetical.vue';
import 'material-design-lite/material.min.js';
import { mapActions, mapState } from 'vuex';
import { FORMAT_TIME_IMMEDIATE_REPORT, LOG_REPORT_TYPE, RESULT_REPORT_TYPE } from 'core/constants';
import moment from 'moment';
import * as defaultParams from '../../store/define';
import { weeklyMonthlyTitle } from '../../helper';
import { getAgentName, getTzByLocalFile } from 'core/helpers';
import { EventBus } from 'core/eventBus';
import { i18n } from 'locales';

export default {
  name: 'CustomerSupportLogSchedule',

  components: {
    draggable,
    LockIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    SortAlphabeticalIcon
  },

  props: {
    channelName: {
      type: String,
      default: ''
    },
    channelId: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    platform: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'en-US'
    },
    reportType: {
      type: String,
      default: ''
    },
    selectScheduleType: {
      type: String,
      default: 'immediate'
    },
    selectDayNameOfWeek: {
      type: String,
      default: '0'
    },
    selectDayOfMonth: {
      type: String,
      default: '1'
    },
    scheduleDateRange: {
      type: Array,
      default: () => []
    },
    selectFileFormat: {
      type: String,
      default: 'excel'
    }
  },

  data() {
    return {
      ...defaultParams.columnFilter(this.locale), // parameter for filter colums export
      dayOfMonthRow: defaultParams.schedule.dayOfMonthRow,
      dayOfWeekRow: defaultParams.schedule.dayOfWeekRow,
      listExportSchedules: [],
      listExportFiles: [],
      selectedScheduleToDeleted: {},
      rmSortClick: 0,
      exSortClick: 0,
      disabledRowExportStyle: {
        opacity: 0.8,
        cursor: 'not-allowed !important'
      },
      messageSuccess: i18n.t('src.modules.report.components.index.conversation_export_successful')
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapState('report', ['channelsFilterMap']),
    getUser() {
      return this.user;
    },

    channelTimezone() {
      const { advancedConfigs = {} } = this.channelsFilterMap[this.channelId] || {};
      let tz = advancedConfigs && advancedConfigs.general && advancedConfigs.general.timezone;
      return tz;
    }
  },

  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },

    channelId() {
      this.listExportConversationLog();
    },

    reportType() {
      this.syncAllData();
    }
  },

  async created() {
    await this.listExportCustomerSchedule();
    await this.listExportCustomerFiles();
    EventBus.$on('syncAllDataReport', () => this.syncAllData());
    EventBus.$on('setExportCustomerSchedule', () => this.setExportCustomerSchedule());
  },

  destroyed() {
    EventBus.$off('syncAllDataReport');
    EventBus.$off('setExportCustomerSchedule');
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('report', [
      'addConversationLogReportSchedule',
      'listConversationLogReportSchedule',
      'listConversationLogReportFiles',
      'removeConversationLogReportSchedule',
      'updateConversationLogReportSchedule',
      'getDownloadUrl',
      'createLogForExport'
    ]),

    clickTab() {
      this.syncAllData();
    },

    async downloadReport(id) {
      this.setGlobalReady(false);
      await this.getDownloadUrl({
        id: `${id}`,
        userId: this.user.id
      })
        .then(async res => {
          if (res.status === 'success') {
            window.open(res.downloadUrl);
          } else {
            this.$baseNotification.warning({
              title: this.$t('src.modules.report.component.report_not_found_title'),
              message: this.$t('src.modules.report.component.report_not_found_message')
            });
          }
        })
        .catch(() => {
          this.errorMessage();
        })
        .finally(() => {
          this.setGlobalReady(true);
        });
    },

    async listExportConversationLog() {
      this.setGlobalReady(false);
      this.listExportSchedules = await this.listConversationLogReportSchedule({
        userId: this.user.id,
        channelId: this.channelId,
        language: this.locale,
        reportType: this.reportType
      });
      this.listExportFiles = await this.listConversationLogReportFiles({
        userId: this.user.id,
        channelId: this.channelId,
        reportType: this.reportType
      });
      this.setGlobalReady(true);
    },

    async listExportCustomerFiles() {
      if (this.channelId) {
        this.setGlobalReady(false);
        const result = await this.listConversationLogReportFiles({
          userId: this.user.id,
          channelId: this.channelId,
          reportType: this.reportType
        });
        this.listExportFiles = result;
        this.setGlobalReady(true);
      }
    },

    async listExportCustomerSchedule() {
      if (this.channelId) {
        this.setGlobalReady(false);
        const result = await this.listConversationLogReportSchedule({
          userId: this.user.id,
          channelId: this.channelId,
          language: this.locale,
          reportType: this.reportType
        });
        this.listExportSchedules = result;
        this.setGlobalReady(true);
      }
    },

    async setExportCustomerSchedule() {
      this.$refs.conversationLogSchedule.validate().then(async submit => {
        if (!submit) {
          this.errorMessage();
        } else {
          let selectedColumns = [];
          this.available.tags.forEach(tag => {
            selectedColumns.push(tag.slug);
          });
          if (!selectedColumns.length > 0) {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: this.$t('src.modules.report.components.index.select_least_one_column')
            });
            return false;
          }
          // Create schedule
          const agent_id = this.getUser.id;
          const channel_id = this.channelId;
          const project_id = this.projectId;
          const selected_columns = selectedColumns;

          const data = {
            userId: agent_id,
            userName: getAgentName(this.user),
            channelId: channel_id,
            projectId: project_id,
            type: this.selectScheduleType,
            language: this.locale,
            columns: selected_columns,
            system: 'at',
            exportFormat: this.selectFileFormat
          };

          const { email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
          const actionLogCreate = {
            userId: agent_id,
            userRole: 'Agent',
            userName: getAgentName(this.user),
            userEmail: email,
            userOperation: operator.id,
            userRegion: region.id,
            userCountry: country.id,
            userCompany: company.id,
            channelId: channel_id,
            projectId: project_id,
            type: this.selectScheduleType,
            exportFormat: this.selectFileFormat,
            channelName: this.channelName,
            platform: this.platform,
            reportType: LOG_REPORT_TYPE.CONVERSATION_LOG,
            system: 'at'
          };

          if (this.selectScheduleType === 'immediate') {
            this.messageSuccess = this.$t(
              'src.modules.report.components.index.conversation_export_successful'
            );
            const scheduleRange = this.scheduleDateRange;
            if (scheduleRange.length >= 2) {
              const fromDate = moment(scheduleRange[0]);
              const toDate = moment(scheduleRange[1]);
              const diffDays = toDate.diff(fromDate, 'days');
              if (diffDays >= 31 || diffDays < 0) {
                this.$baseNotification.warning({
                  title: this.$t('src.core.App.warning'),
                  message: this.$t(
                    'src.modules.report.components.cnv-scheduled-export.index.date_range_warning.can_not_beyond'
                  )
                });
                return false;
              }
              const from_date = moment(scheduleRange[0]).format(
                FORMAT_TIME_IMMEDIATE_REPORT.EXPORT.START_DATE
              );
              const to_date = moment(scheduleRange[1]).format(
                FORMAT_TIME_IMMEDIATE_REPORT.EXPORT.END_DATE
              );
              data['fromDate'] = from_date;
              data['toDate'] = to_date;

              actionLogCreate['fromDate'] = from_date;
              actionLogCreate['toDate'] = to_date;
            } else {
              return false;
            }
          }

          if (this.selectScheduleType === 'weekly') {
            this.messageSuccess = this.$t(
              'src.modules.report.components.cnv-scheduled-export.index.weekly_schedule.add_sccessful'
            );
            const week_day = this.selectDayNameOfWeek;
            if (isNaN(week_day)) return false;
            data['weekDay'] = week_day;
          }

          if (this.selectScheduleType === 'monthly') {
            this.messageSuccess = this.$t(
              'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.add_sccessful'
            );
            const month_day = this.selectDayOfMonth;
            if (isNaN(month_day)) return false;
            data['monthDay'] = month_day;
          }

          this.setGlobalReady(false);
          const result = await this.addConversationLogReportSchedule({
            data,
            reportType: this.reportType
          });
          if (result) {
            if (result.status) {
              await this.listExportCustomerSchedule();
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.messageSuccess
              });
              this.createLogForExport({
                ...actionLogCreate,
                exportResult: RESULT_REPORT_TYPE.SUCCESS
              });
            } else if (!result.status && result.error_key === 'schedule_exist') {
              const messErr = this.$t(
                'src.modules.report.components.cnv-scheduled-export.index.this_schedule_already_exists_please_select_another_schedule'
              );
              this.$baseNotification.error({
                title: this.$t('src.core.App.error'),
                message: messErr
              });

              this.createLogForExport({
                ...actionLogCreate,
                exportResult: RESULT_REPORT_TYPE.ERROR,
                exportError: messErr
              });
            }
          } else {
            const messErr = this.$t(
              'src.modules.report.components.index.check_file_tab_to_download'
            );
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: messErr
            });
            this.createLogForExport({
              ...actionLogCreate,
              exportResult: RESULT_REPORT_TYPE.ERROR,
              exportError: messErr
            });
          }
          this.setGlobalReady(true);
        }
      });
    },

    async removeExportCustomerSchedule(schedule) {
      this.selectedScheduleToDeleted = schedule;
      this.$baseConfirm({
        message: this.$t(
          'src.modules.report.components.cnv-scheduled-export.index.are_you_sure_you_want_to_remove_this_schedule'
        )
      })
        .then(() => {
          this.okRemoveExportCustomerSchedule();
        })
        .catch(() => {});
    },

    async okRemoveExportCustomerSchedule() {
      const schedule = this.selectedScheduleToDeleted;
      const { id: scheduleId = '', userId } = schedule;
      if (scheduleId) {
        this.setGlobalReady(false);
        const result = await this.removeConversationLogReportSchedule({
          id: scheduleId,
          userId
        });
        if (result) {
          let res = this.listExportSchedules;
          res = res.filter(e => e.id !== scheduleId);
          this.listExportSchedules = res;
        }

        this.setGlobalReady(true);
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.index.remove_converstion_schedule_successful'
          )
        });
      }
    },

    async updateExportCustomerSchedule(schedule, type, value) {
      const { id, userId } = schedule;
      const data = { id };
      data[type] = value;
      data['userId'] = userId;
      this.setGlobalReady(false);
      const result = await this.updateConversationLogReportSchedule(data);
      if (result) {
        await this.listExportCustomerSchedule();
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.schedule.update_status.sccessful'
          )
        });
      } else {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.report.components.index.check_file_tab_to_download')
        });
      }
      this.setGlobalReady(true);
    },

    async syncAllData() {
      this.setGlobalReady(false);
      await this.listExportCustomerSchedule();
      await this.listExportCustomerFiles();
      this.setGlobalReady(true);
    },

    getWeeklyMonthlyDetail: (day, type) => weeklyMonthlyTitle(day, type),

    exOrderList(datalist) {
      this.exSortClick++;
      this.orderList(datalist, this.exSortClick);
    },

    rmOrderList(datalist) {
      this.rmSortClick++;
      this.orderList(datalist, this.rmSortClick);
    },

    orderList(datalist, click) {
      if (click % 2 === 1) {
        datalist.sort((one, two) => {
          return one.tag.localeCompare(two.tag);
        });
      } else {
        datalist.reverse(datalist);
      }
    },

    moveUnclockTags(exportList, removeList) {
      exportList.tags.forEach(exportTag => {
        if (!exportTag.fixed) {
          removeList.tags.push(exportTag);
        }
      });
      exportList.tags = exportList.tags.filter(exportTag => exportTag.fixed);
    },

    onClick(index, from, to) {
      let current = from.tags[index];
      if (!current.fixed && from.editable && to.editable && to.droppable) {
        if (
          from.cloneable ||
          to.tags.filter(function (e) {
            return e.tag == current.tag;
          }).length === 0
        ) {
          to.tags.push(current);
        }
        if (!from.cloneable) {
          from.tags.splice(index, 1);
        }
      }
    },

    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },

    getOptions(place) {
      return {
        sort: true,
        animation: 0,
        group: {
          name: 'tags',
          pull: place.cloneable ? 'clone' : true,
          put: place.droppable
        },
        disabled: !place.editable,
        ghostClass: 'ghost'
      };
    },

    formatTime(value, type) {
      if (!value) return null;
      if (type === 'dateRange')
        return value.split('T').length > 1
          ? moment(value.split('T')[0]).format('YYYY/MM/DD')
          : moment(value).format('YYYY/MM/DD');
      return getTzByLocalFile({ value, name: this.channelTimezone });
    },

    formatType(value) {
      return value == 'immediate'
        ? this.$t(
            'src.modules.report.components.cnv-scheduled-export.index.schedule_type.immediate'
          )
        : value == 'weekly'
        ? this.$t('src.modules.report.components.cnv-scheduled-export.index.schedule_type.weekly')
        : this.$t('src.modules.report.components.cnv-scheduled-export.index.schedule_type.monthly');
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    convertFormatTime(time) {
      return moment(time).format(FORMAT_TIME_IMMEDIATE_REPORT.DISPLAY);
    }
  }
};
</script>
<style lang="scss" scoped>
.nat-btn-add_schedule {
  float: left;
  height: 40px;
  margin-top: 5px;
  margin-left: 8px;
}
.nat-btn-refresh {
  float: left;
  height: 40px;
  margin-top: 5px;
}
.nat-date-range-schedule {
  float: left;
  /deep/ .el-range-editor {
    width: 100%;
  }
}
</style>
