<template>
  <div>
    <b-card header-tag="header">
      <h6 slot="header" v-b-toggle.clRequestHistory class="mb-0">
        <i :class="showCollapse ? `fa fa-chevron-down` : `fa fa-chevron-right`"></i>
        {{
          $t('src.modules.chat.components.CustomerSupport.RequestHistory.request_history')
        }}
      </h6>
      <b-collapse id="clRequestHistory" v-model="showCollapse">
        <b-row>
          <b-col md="6" />
          <b-col md="6" class="my-1">
            <b-form-group horizontal class="mb-0" prepend="Label">
              <b-input-group>
                <b-input-group-text slot="prepend">
                  <i class="fas fa-search"></i>
                </b-input-group-text>
                <b-form-input
                  v-model="searchTextSupport"
                  :placeholder="
                    $t(
                      'src.modules.chat.components.CustomerSupport.RequestHistory.search_by_category_or_title'
                    )
                  "
                  @input="handleSearchCustomer"
                  @keypress.enter="handleSearchCustomer"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          :current-page="pageNumber"
          :per-page="perPage"
          :items="paginatedData"
          :fields="historyFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          hover
          thead-class="history-table-header"
          show-empty
        >
          <template slot="empty">
            <div v-if="isBusy" class="text-center">
              <spinner />
            </div>
            <div v-else class="text-center">
              {{ $t('common.text.no_data') }}
            </div>
          </template>
          <template slot="index" slot-scope="data">
            {{ (pageNumber - 1) * perPage + data.index + 1 }}
          </template>

          <!-- updatedAt -->
          <template slot="updatedByAgent" slot-scope="data">
            {{ data.value }}
          </template>

          <!-- updatedAt -->
          <template slot="updatedAt" slot-scope="data">
            {{ formatTime(data.value) }}
          </template>

          <!-- categoryPath -->
          <template slot="categoryPath" slot-scope="data">
            <div v-b-tooltip.hover :title="data.value">
              {{ data.value | truncate(30) }}
            </div>
          </template>

          <template slot="title" slot-scope="data">{{
            data.value | truncate(30)
          }}</template>

          <!-- platform -->
          <template slot="platform" slot-scope="data">
            {{ standardizePlatformName[data.value.toLowerCase()] || data.value }}
          </template>

          <!-- channel -->
          <template slot="channelName" slot-scope="data">
            {{ channelsMap[data.item.channelId].name | truncate(30) }}
          </template>

          <template slot="action" slot-scope="data">
            <a
              v-if="data.item.status !== 'draft'|| (data.item.status === 'draft' && !data.item.isAllowEdit)"
              :id="`v-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
              v-b-toggle.clRequestHistory
              href="#"
              @click="handleView(data.item)"
            >
              <i class="fa fa-search"></i>
              <BootstrapTooltipCustom
                :id="`v-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
                :content="$t('common.text.view_form')"
              />
            </a>
            <a
              v-if="data.item.status === 'draft' && data.item.isAllowEdit"
              :id="`e-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
              v-b-toggle.clRequestHistory
              href="#"
              @click="handleEdit(data.item)"
            >
              <i class="fa fa-edit"></i>
              <BootstrapTooltipCustom
                :id="`e-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
                :content="$t('common.text.edit')"
              />
            </a>
            <a
              v-if="data.item.status === 'draft' && data.item.isAllowEdit"
              :id="`d-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
              href="javascript:void(0)"
              class="ml-2"
              @click="handleDeleteItemClick(data.item)"
            >
              <i class="fa fa-trash-alt"></i>
              <BootstrapTooltipCustom
                :id="`d-p-${data.index + 1 + perPage * (pageNumber - 1)}`"
                :content="$t('common.text.delete')"
              />
            </a>
          </template>
        </b-table>
        <b-row v-if="totalRows > 0">
          <b-col class="nat-paging">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              class="nat-rows-page"
            ></b-form-select>
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="nat-pagination"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapActions, mapState, mapGetters } from 'vuex';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import { convertTimeUTC } from 'core/helpers';
import { STANDARDIZE_PLATFORM_NAME } from 'core/constants';

export default {
  components: {
    BootstrapTooltipCustom
  },
  props: {
    conversation: { type: Object, default: () => {} },
    projectId: { type: String, default: '' }
  },
  data() {
    return {
      historyFields: {
        index: {
          label: this.$t('src.modules.customer-support-history.index.no'),
          class: 'nat-table-col-no'
        },
        updatedAt: {
          label: this.$t('src.modules.customer-support-history.index.updated'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        updatedByAgent: {
          label: this.$t('src.modules.customer-support-history.index.updatedBy'),
          sortable: true,
          class: 'nat-table-col-updated'
        },
        categoryPath: {
          label: this.$t('src.modules.customer-support-history.index.category')
        },
        title: {
          label: this.$t('src.modules.customer-support-history.index.title')
        },
        platform: {
          label: this.$t('src.modules.customer-support-history.index.platform')
        },
        channelName: {
          label: this.$t('src.modules.customer-support-history.index.channel_name')
        },
        action: {
          label: this.$t('common.text.action')
        }
      },
      sortBy: 'updatedAt',
      sortDesc: true,
      perPage: 5,
      pageNumber: 1,
      searchTextSupport: '',
      showCollapse: false,
      customerDelteteItem: {},
      pageOptions: [
        { value: 5, text: '5 /' + this.$t('base.grid.page') },
        { value: 10, text: '10 / ' + this.$t('base.grid.page') },
        { value: 50, text: '50 / ' + this.$t('base.grid.page') },
        { value: 100, text: '100 / ' + this.$t('base.grid.page') }
      ],
      isBusy: false,
      standardizePlatformName: STANDARDIZE_PLATFORM_NAME
    };
  },
  computed: {
    ...mapState('session', ['channelsMap']),
    ...mapGetters('customerSupport', ['customerSupportSearch']),
    ...mapState('customerSupport', ['customerSupportData']),
    paginatedData() {
      if (this.customerSupportSearch.length > 0) {
        return this.customerSupportSearch;
      }
      return [];
    },
    pageCount() {
      let l = this.customerSupportSearch.length,
        s = this.perPage;
      return Math.ceil(l / s);
    },
    totalRows() {
      return this.customerSupportSearch.length;
    }
  },
  async created() {
    const params = {
      conversationId: this.conversation.id,
      channelId: this.conversation.channelId,
      projectId: this.projectId
    };

    await this.getCustomerSupports(params);
    this.$emit('initFormData', this.customerSupportData);
    EventBus.$on('successSubmit', () => {
      this.showCollapse = true;
      this.getCustomerSupports(params);
    });
    EventBus.$on('newRequest', () => {
      this.showCollapse = false;
    });
    EventBus.$on('deleteFromHistory', () => {
      const params = {
        conversationId: this.conversation.id,
        channelId: this.conversation.channelId,
        projectId: this.projectId
      };

      this.getCustomerSupports(params);
      this.showCollapse = true;
    });
  },
  beforeDestroy() {
    this.setSearchText('');
    EventBus.$off('successSubmit');
    EventBus.$off('newRequest');
    EventBus.$off('deleteFromHistory');
    EventBus.$off('hideBaseConfirmCsh');
  },
  methods: {
    ...mapActions('customerSupport', [
      'getCustomerSupports',
      'setSearchText',
      'deleteCustomerSupport',
      'getCustomerSupportById',
      'setCSFMode'
    ]),
    handleView(data) {
      this.setCSFMode('view');
      this.$emit('select', data);
    },
    handleEdit(data) {
      this.setCSFMode('edit');
      this.$emit('select', data);
    },
    nextPage() {
      this.pageNumber++;
    },
    prevPage() {
      this.pageNumber--;
    },
    handleSearchCustomer() {
      this.setSearchText(this.searchTextSupport);
    },
    async handleYes() {
      const params = { id: this.customerDelteteItem.id };
      const checkData = await this.getCustomerSupportById(params);
      if (checkData.status !== 'submited') {
        this.deleteCustomerSupport(this.customerDelteteItem);
        this.$emit(
          'deleteSuccess',
          this.$t(
            'src.modules.chat.components.CustomerSupport.RequestHistory.request_was_deleted'
          ),
          'deleted'
        );
      } else {
        const params = {
          conversationId: this.conversation.id,
          channelId: this.conversation.channelId,
          projectId: this.projectId
        };

        this.getCustomerSupports(params);
        this.$emit(
          'deleteSubmitedBefore',
          this.$t(
            'common.CustomerSupport.the_request_was_submitted_you_can_not_change_or_delete_it'
          )
        );
      }
      this.customerDelteteItem = {};
    },
    handleCancel(e) {
      e.preventDefault();
      this.$refs.deleteModal.hide();
    },
    async handleDeleteItemClick(item) {
      this.customerDelteteItem = item;
      await EventBus.$on('hideBaseConfirmCsh', () => {
        this.$baseConfirm.close();
      });
      this.$baseConfirm({
        message: this.$t(
          'src.modules.chat.components.CustomerSupport.RequestHistory.do_you_want_to_delete_this_item'
        ),
        cancelButtonText: this.$t('common.confirmModal.cancel')
      })
        .then(() => {
          this.handleYes();
        })
        .catch(() => {});
    },
    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-pagination {
  display: inline-flex;
  margin-left: 10px;
}
.nat-rows-page {
  width: 90px;
}
.nat-paging {
  bottom: 5px;
  top: 5px;
}

.draggable {
  .search-box .input-group-text {
    border-radius: 30px 0 0 30px;
    border: none;
  }
  .search-box .form-control {
    border-radius: 0 30px 30px 0 !important;
    border: none;
  }
  .card-header {
    padding: 0.8rem 1.5rem;
  }
  .card-body {
    padding: 0.5rem;
  }
}

/deep/.nat-table-col-no {
  width: 60px;
}

/deep/ .nat-table-col-created {
  text-align: center;
  width: 135px !important;
}

/deep/ .nat-table-col-updated {
  text-align: center;
  width: 135px !important;
}
</style>
<style lang="scss">
.history-table-header {
  background-color: #2196f3;
  color: #ffffff;
}
</style>
