<template>
  <div class="login-wrapper">
    <div class="row no-gutters">
      <div class="d-none d-sm-none d-md-block col-md-5 col-lg-7">
        <div v-bind:style="{ 'background-image': 'url(' + bgUrl + ')' }" class="background">
          <div class="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
            <h3 class="semi-bold text-white">
              {{ $t('src.modules.session.index.easy_management_its_as_simple_as_that') }}
            </h3>
            <p class="small">
              {{
                $t('src.modules.session.index.copyright_transcosmos_inc_all_rights_reserved', {
                  fullYear: new Date().getFullYear()
                })
              }}
            </p>
          </div>
          <div class="backdrop" />
        </div>
      </div>
      <div class="col-sm-12 col-md-7 col-lg-5">
        <div class="login-container bg-white">
          <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-0 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <div class="row">
              <div class="col-6">
                <img :src="logoUrl" :alt="brand" />
              </div>
              <div class="col-6 text-right">
                <img :src="logoConnectPlus" :alt="brand" class="img-fluid" />
              </div>
            </div>
            <div class="login-component">
              <div class="p-t-15">
                <div class="mb-5 py-5 border-top">
                  <form role="form">
                    <!-- Current Password(S) -->
                    <div class="form-group mb-3">
                      <label class="change-password-label">
                        <span class="nat-required">*</span>
                        {{ $t('src.modules.password.index.current_password') }}
                      </label>
                      <ValidationObserver ref="currentPasswordBaseInput">
                        <base-input
                          v-model="formData.curPass"
                          :align="'vertical-no-label'"
                          :prefix="'changePassword'"
                          :required="true"
                          :maxlength="128"
                          :rules="'password'"
                          :name="'password'"
                          :label="$t('src.modules.password.index.current_password')"
                          :input-type="'password'"
                          :show-hint-text="false"
                          :show-word-limit="false"
                          :error="'src.modules.password.index.please_input_the_current_password'"
                        />
                      </ValidationObserver>
                    </div>
                    <!-- Current Password (E) -->

                    <!-- New Password (S) -->
                    <div class="form-group mt-10">
                      <label class="change-password-label">
                        <span class="nat-required">*</span>
                        {{ $t('src.modules.password.index.new_password') }}
                      </label>
                      <label class="change-password-label-policy">
                        {{
                          $t(
                            'src.modules.session.components.changePasswordFirstLogin.password_policy'
                          )
                        }}
                      </label>
                      <AppPasswordStrength
                        v-model="formData.newPass"
                        :default-class="'form-control form-group-default'"
                        :placeholder="$t('src.modules.password.index.enter_new_password')"
                        :toggle="true"
                        :secure-length="secureLength"
                        @secure="checkSecure"
                        @blur="handleBlurNewPassword"
                      />
                    </div>
                    <div v-if="newPasswordError !== ''" class="error-input">
                      {{ newPasswordError }}
                    </div>
                    <!-- New Password (E) -->

                    <!-- Confirm New Password (S) -->
                    <div class="form-group fixed-mr">
                      <label class="change-password-label">
                        <span class="nat-required">*</span>
                        {{ $t('src.modules.password.index.confirm_new_password') }}
                      </label>
                      <div class="form-group-default">
                        <input
                          v-model="formData.conPass"
                          :placeholder="$t('src.modules.password.index.enter_confirm_password')"
                          class="form-control"
                          type="password"
                          @keyup="checkValidConfirmNewPassword"
                          @keypress.enter="handleFormSubmit"
                          @blur="handleBlurConPassword"
                        />
                      </div>
                      <div v-if="confirmNewPasswordError !== ''" class="error-input">
                        {{ confirmNewPasswordError }}
                      </div>
                    </div>
                    <!-- Confirm New Password (E) -->

                    <!-- redirec login page -->
                    <div class="float-left forgot-password">
                      {{
                        $t(
                          'src.modules.session.components.forgotPassword.already_have_login_and_password'
                        )
                      }}
                      <a href="/login" @click="redirectSignInPage">{{
                        $t('src.modules.session.components.forgotPassword.sign_in')
                      }}</a>
                    </div>
                    <!-- end -->

                    <!-- Btn Submit (S) -->
                    <div class="text-center">
                      <button
                        :disabled="submitFormLoading"
                        type="button"
                        class="btn btn-primary btn-cons btn-block"
                        @click="handleFormSubmit"
                      >
                        {{ $t('src.modules.profile.index.change_password') }}
                        <i v-if="submitFormLoading" class="fas fa-spinner fa-spin fa-lg" />
                      </button>
                    </div>
                    <!-- Btn Submit (E) -->
                  </form>
                  <div class="row justify-content-center">
                    <div class="col-lg-9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppPasswordStrength from 'components/AppPasswordStrength';
import loginBg from 'assets/images/login/bg.jpg';
import logo from 'assets/images/logo.png';
import logoDec from 'assets/images/logo-dec-support.png';
import 'assets/scss/login.scss';
import {
  convert2OneByte,
  hasSpecialCharacter,
  hasEmoji,
  hasWhiteSpace
} from 'components/BaseComponents/vee-validate';

export default {
  components: {
    AppPasswordStrength
  },

  data() {
    return {
      submitFormLoading: false,
      formData: {},
      newPassSecure: false,
      secureLength: 8,
      bgUrl: loginBg,
      logoUrl: logo,
      logoConnectPlus: logoDec,
      brand: 'DEC Support',
      system: null,
      newPasswordError: '',
      confirmNewPasswordError: ''
    };
  },

  computed: {
    ...mapState('session', ['user', 'token']),

    validConfirmNewPasswordMatch() {
      if (this.formData.conPass && this.formData.newPass !== this.formData.conPass)
        return this.$t(
          'src.modules.password.store.actions.new_password_and_confirm_password_do_not_match'
        );

      return '';
    },

    loginStatus() {
      const lgstt = localStorage.getItem('login-status');
      switch (lgstt) {
        case 'password-expired':
          return this.$t('src.modules.password.index.password_expired');
        case 'first-login':
          return this.$t('src.modules.password.change_password_first_login');
        default:
          return this.$t('src.modules.password.index.please_input_the_new_password_secure', {
            passwordLength: 8
          });
      }
    }
  },

  async created() {
    const { sys } = this.$route.params || {};
    const { token, email } = this.$route.query || {};
    if (sys && sys == 'wiki') {
      this.system = sys;
      if (token && email) {
        localStorage.setItem('user-token', token);
        localStorage.setItem('user-email', email);
      } else {
        window.location.href = process.env.VUE_APP_WIKI_LOGIN;
      }
    } else {
      if (!this.user || (this.user && !this.user.hasOwnProperty('email'))) {
        this.$router.push('/login');
      }
      this.formData = { ...this.user };
    }
  },

  methods: {
    ...mapActions('password', ['submitForm']),

    handleBlurNewPassword(newValue, isSecure) {
      this.newPassSecure = isSecure;
      this.formData.newPass = newValue;
      this.validateNewPassword();
    },

    validateNewPassword() {
      this.newPasswordError = '';
      const fieldName = this.$t('src.modules.password.index.new_password');
      if (!this.formData.newPass)
        this.newPasswordError = this.$t('src.modules.password.index.please_input_the_new_password');

      if (hasWhiteSpace(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });

      if (hasEmoji(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });

      if (hasSpecialCharacter(this.formData.newPass))
        this.newPasswordError = this.$t('veevalidate.validations.common_invalid', { fieldName });

      if (this.formData.newPass && !this.newPassSecure)
        this.newPasswordError = this.$t(
          'src.modules.password.index.please_input_the_new_password_secure',
          { fieldName }
        );

      if (
        this.formData.newPass &&
        this.newPassSecure &&
        this.formData.newPass === this.formData.curPass
      )
        this.newPasswordError = this.$t(
          'src.modules.password.store.actions.new_password_different_old_password'
        );
    },

    handleBlurConPassword() {
      const newValue = convert2OneByte(this.formData.conPass);
      this.formData.conPass = newValue;
      this.confirmNewPasswordError = '';
      const fieldName = this.$t('src.modules.password.index.confirm_new_password');
      if (!this.formData.conPass)
        this.confirmNewPasswordError = this.$t(
          'src.modules.password.index.please_input_the_confirm_new_password'
        );

      if (hasWhiteSpace(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (hasEmoji(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (hasSpecialCharacter(this.formData.conPass))
        this.confirmNewPasswordError = this.$t('veevalidate.validations.common_invalid', {
          fieldName
        });

      if (this.formData.conPass && this.formData.newPass !== this.formData.conPass)
        this.confirmNewPasswordError = this.$t(
          'src.modules.password.store.actions.new_password_and_confirm_password_do_not_match'
        );
    },

    redirectSignInPage(e) {
      e.preventDefault();
      localStorage.setItem('user-token', '');
      localStorage.setItem('user-email', '');
      if (this.system && this.system == 'wiki') {
        window.location.href = process.env.VUE_APP_WIKI_LOGIN;
      } else {
        this.$router.go();
      }
    },

    checkValidConfirmNewPassword() {
      this.validConfirmNewPasswordMatch;
    },

    handleFormSubmit() {
      this.validateNewPassword();
      this.handleBlurConPassword();
      this.$refs.currentPasswordBaseInput &&
        this.$refs.currentPasswordBaseInput.validate().then(result => {
          if (!result || this.newPasswordError || this.confirmNewPasswordError) {
            return this.errorMessage();
          }

          this.submitFormLoading = true;
          const formUserUpdate = {
            id: this.formData.id,
            email: this.formData.email,
            curPass: this.formData.curPass,
            newPass: this.formData.newPass,
            conPass: this.formData.conPass,
            name: `${this.formData.firstName || ''} ${this.formData.lastName || ''}`,
            firstName: this.formData.firstName,
            lastName: this.formData.lastName
          };
          this.submitForm(formUserUpdate).then(result => {
            this.submitFormLoading = false;
            if (result === true) {
              if (this.system && this.system === 'wiki') {
                this.$alert(
                  this.$t(
                    'src.modules.password.index.your_password_has_been_changed_you_have_to_login_again'
                  ),
                  this.$t('src.modules.password.index.password_change'),
                  {
                    confirmButtonText: this.$t('src.core.App.login'),
                    customClass: 'nat-base-confirm-box',
                    callback: () => {
                      localStorage.setItem('user-token', '');
                      localStorage.setItem('user-email', '');
                      window.location.href = process.env.VUE_APP_WIKI_LOGIN;
                    },
                    showClose: false
                  }
                );
              } else {
                this.$alert(
                  this.$t(
                    'src.modules.password.index.your_password_has_been_changed_you_have_to_login_again'
                  ),
                  this.$t('src.modules.password.index.password_change'),
                  {
                    confirmButtonText: this.$t('src.core.App.login'),
                    customClass: 'nat-base-confirm-box',
                    callback: () => {
                      localStorage.setItem('user-token', '');
                      localStorage.setItem('user-email', '');
                      window.location.href = '/login';
                    },
                    showClose: false
                  }
                );
              }
            }
          });
        });
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t('src.modules.password.index.please_check_the_invalid_data')
      });
    },

    checkSecure(secure) {
      this.newPassSecure = secure;
    }
  }
};
</script>
<style lang="scss" scoped>
main {
  overflow: auto;
  height: calc(100vh - 0px);
  padding-bottom: 30px;
}
@import 'assets/scss/chat/chat.scss';

.change-password {
  top: 50px;
  position: relative;
}

.mr-10 {
  margin-right: 10px;
}

.mt-100 {
  margin-top: -60px;
}

.error-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
.p-t-25 {
  font-size: 1.2rem;
  font-weight: 600;
}
.change-password-label {
  display: -webkit-box;
}
.change-password-label-policy {
  display: -webkit-box;
  text-align: left !important ;
  font-size: 0.75rem !important;
  color: #8898aa !important ;
}

.forgot-password {
  font-size: 0.8rem;
  padding-bottom: 5px;
  & > a {
    color: #007bff !important;
  }
  & > a:hover {
    text-decoration: underline;
  }
}

.nat-required {
  color: red;
}

.form-group {
  margin-bottom: 0px;
}

.fixed-mr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
