<template>
  <div data-role="content" class="scrollable-content">
    <nav :class="['navbar', 'navbar-expand-lg', 'navbar-dark', selected ? 'active' : '']">
      <div class="container p-0">
        <div class="channel-header" @click="handleChannelClick(userInfo)">
          <a class="avatar avatar-xs shadow position-relative" href=" " @click.prevent.stop>
            <img :src="avatar" :alt="name" class="img-fluid" @error="imageLoadError" />
          </a>
          <v-clamp class="navbar-brand" :max-lines="1" autoresize>{{ name }}</v-clamp>
        </div>
        <div class="channel-icon-group">
          <img
            v-if="platform === 'webchat'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/webchat-logo.png"
            width="14"
            height="14"
          />
          <img
            v-else-if="platform === 'zalo'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/zalo-min.png"
            width="14"
            height="14"
          />
          <img
            v-else-if="platform === 'liffchat'"
            :class="[platformClass, 'platform']"
            src="~/assets/images/icons/liffchat.png"
            width="14"
            height="14"
          />
          <i v-else :class="[platformClass, 'platform']" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import noAvatar from 'assets/images/no-avatar-channel.jpg';
import VClamp from 'vue-clamp';
import { EventBus } from 'core/eventBus';

export default {
  components: {
    VClamp
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: () => ''
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    platform: {
      type: String,
      default: () => 'webchat'
    },
    pictureUrl: {
      type: String,
      default: noAvatar
    }
  },

  data() {
    return {
      imageError: false
    };
  },

  computed: {
    platformClass() {
      if (!this.platform) return 'fab';
      switch (this.platform) {
        case 'facebook':
        case 'external_facebook':
          return 'fab fa-facebook';
        case 'instagram':
          return 'fab fa-instagram';
        case 'line':
        case 'external_line':
          return 'fab fa-line';
        case 'webchat':
          return 'fas fa-comment-dots';
        default:
          return 'fa-comment-alt-smile';
      }
    },

    avatar() {
      return this.imageError || !this.pictureUrl ? noAvatar : this.pictureUrl;
    }
  },

  methods: {
    ...mapActions('phoneAgentChatBox', [
      'enableBtnWBH',
      'setSelectedUserInfo',
      'clearMessagesHistory',
      'getMessagesByConversationId'
    ]),
    ...mapActions('global', ['setGlobalLoadingMap']),
    ...mapActions('chat', ['setShowWebBrowsingHistoryPanel']),

    imageLoadError() {
      // eslint-disable-next-line
      console.log('Show image error: ', this.avatar);
      this.imageError = true;
    },

    async handleChannelClick(userInfo) {
      const { ticketId, id: conversationId, projectId, platform } = userInfo;

      if (platform === 'webchat') {
        this.enableBtnWBH(true);
        this.setShowWebBrowsingHistoryPanel(false);
        setTimeout(() => {
          EventBus.$emit('phoneAgentHandleWebBrowsingHistoryItemClick');
        }, 0);
      } else {
        this.enableBtnWBH(false);
        EventBus.$emit('phoneAgentHideAllPanel');
      }

      const path = `/c/${conversationId}/${ticketId}`;
      history.pushState(null, null, path);
      this.clearMessagesHistory();
      this.setSelectedUserInfo(userInfo);
      this.setGlobalLoadingMap({ action: 'GET_MESSAGES_HISTORY', flag: true });
      await this.getMessagesByConversationId({ conversationId, projectId });
      EventBus.$emit('scrollBottomMessagesHistory');
      this.setGlobalLoadingMap({ action: 'GET_MESSAGES_HISTORY', flag: false });
    }
  }
};
</script>
<style lang="scss" scoped>
nav.navbar {
  cursor: pointer;
}

.channel-header {
  display: inline-block;
  width: 100%;
  .avatar-xs {
    width: 30px;
    height: 30px;
  }

  .avatar {
    .badge {
      position: absolute;
      top: -7px;
      right: -10px;
      font-size: 8px;
    }
  }
}

.h--0 {
  height: 0 !important;
}

.h--100vh {
  height: 100% !important;
}

ul.list-group {
  overflow: hidden;
  transition: all 0.1s ease;
  -moz-transition: all 0.1s ease; /* Firefox 4 */
  -webkit-transition: all 0.1s ease; /* Safari and Chrome */
  -o-transition: all 0.1s ease; /* Opera */
}
.btnIco {
  margin: 3%;
}
.ico {
  width: 3.5%;
}
.fab.fa-facebook {
  color: white;
}
.fab.fa-line {
  color: #3ac521;
}
.fab.fa-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fab.fa-comment-alt-smile {
  color: #f1aa26;
}
.fas.fa-comment-dots {
  color: #2196f3;
}
.channel-icon-group {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row-reverse;

  > i,
  > span,
  > .el-dropdown {
    position: relative;
    margin: 0 5px;
    top: inherit;
    left: inherit;
    right: inherit;
  }
  .platform {
    order: 3;
  }
}
</style>