import CookiesModel from './cookies';
import Service from 'core/service';

class cSecurity {
  key = 'cplus-authorization';
  errorExpired = ['TokenExpiredError', 'jwt expired'];
  status = 401;
  static process = 0;

  constructor() {
    this.cookies = new CookiesModel();
  }

  static setProcess(flag) {
    this.process = flag;
  }

  static setTokenObject(data) {
    const cookies = new CookiesModel();
    this.accessToken = data.accessToken;
    this.accessTokenExpiresAt = data.accessTokenExpiresAt;
    cookies.storeAccessToken(data.accessToken, data.expiration);
    cookies.storeAccessTokenExpiration(data.accessTokenExpiresAt, data.expiration);
  }

  static token() {
    const cookies = new CookiesModel();
    this.accessToken = cookies.accessToken();
    return this.accessToken;
  }

  static tokenTime() {
    const cookies = new CookiesModel();
    this.accessTokenExpiresAt = cookies.accessTokenExpiration();
    return this.accessTokenExpiresAt;
  }

  getToken = async (reNew = 0) => {
    const my = this;
    const temp = my.constructor.token();
    if (temp && !reNew) {
      return temp;
    }
    const process = my.constructor.process;
    if (process) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      return await this.getToken();
    }
    let token = '';
    try {
      my.constructor.setProcess(1);
      const tokenObj = await this.requestAPIToGetAppToken();
      if (tokenObj && tokenObj.accessToken.length > 0) {
        my.constructor.setTokenObject(tokenObj);
        token = tokenObj.accessToken;
      }
    } catch (err) {
      console.error(err);
    }
    my.constructor.setProcess(0);
    return token;
  };

  requestAPIToGetAppToken = async () => {
    const service = new Service();
    return await service
      .rest('authorizer/renewCPlusToken', {}, { method: 'post' }, 0)
      .then(response => {
        if (!response) return false;
        const { token, email, tid, cToken } = response;
        localStorage.setItem('user-token', token);
        localStorage.setItem('user-email', email);
        localStorage.setItem('user-tid', tid);
        localStorage.removeItem('auth-status');
        return cToken;
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log(' renewCPlusToken ~ error', error);
        return false;
      });
  };

  // Check expired token after requesting
  checkTokenExpired = async data => {
    const v1 = data.status === this.status;
    const v2 = this.errorExpired.indexOf(data.message) !== -1;
    if (v1 || v2) {
      const process = this.constructor.process;
      if (process) return true;
      await this.getToken(1);
      return true;
    }
    return false;
  };

  // Check expired token before requesting
  isExpiredToken = () => {
    const time = this.constructor.tokenTime();
    if (!time) {
      return true; // Expired
    }
    const expDate = new Date(time);
    const curDate = new Date();
    const expireTime = expDate.getTime();
    const currentTime = curDate.getTime();
    const timeCheck = expireTime - 300000; // - 5 minutes
    const dateCheck = new Date(timeCheck);
    if (currentTime >= timeCheck) {
      console.log(
        'expireTime=',
        expDate,
        ',currentTime=',
        curDate,
        ', timeCheck:',
        dateCheck,
        ' -> Expired'
      );
      return true; // Expired
    }
    return false;
  };
}

export default cSecurity;
