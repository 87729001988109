<template>
  <div class="product-item card shadow">
    <img
      :src="image_url"
      :alt="name"
      class="card-img"
      data-holder-rendered="true"
      style="height: 200px; width: 100%; display: block;"
    />
    <div class="card-body">
      <span class="badge badge-pill badge-primary pull-right bg-white price">{{
        currencyFormatting
      }}</span>
      <div class="product-content w-100 text-center card-img-overlay">
        <div class="product-info bg-transparent w-100">
          <h6
            v-b-tooltip.hover
            :title="name"
            class="text-white text-uppercase product-title"
          >
            {{ name | truncate(10) }}
          </h6>
          <div v-if="showActions" class="actions">
            <button
              v-b-tooltip.hover
              :title="
                $t(
                  'src.modules.chat.components.ProductPanel.ProductList.product-item.view_in_website'
                )
              "
              type="button"
              class="btn btn-secondary btn-sm"
              @click="handelOpenProductUrl"
            >
              <i class="fa fa-link" />
            </button>
            <b-btn
              v-b-tooltip.hover
              v-b-modal.modalProductFeeds
              :title="
                $t(
                  'src.modules.chat.components.ProductPanel.ProductList.product-item.view_detail'
                )
              "
              class="btn btn-secondary btn-sm"
              @click="handelGetProductView(id)"
            >
              <i class="fa fa-eye" />
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    image_url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selectedProduct: ''
    };
  },
  computed: {
    currencyFormatting() {
      return `${this.price}`;
    }
  },

  methods: {
    handelGetProductView(id) {
      this.show = true;
      this.$emit('onSelectedItem', id);
    },
    handelOpenProductUrl() {
      window.open(this.url, '_blank');
    }
  }
};
</script>

<style scoped>
.pull-right {
  float: right;
}
.product-item {
  cursor: move;
}
</style>
