<template>
  <div class="chats">
    <div v-if="loading" class="text-center w-100">
      <i class="fas fa-spinner fa-spin fa-lg" />
    </div>
    <message-item v-for="msg in msgData" :key="msg.id" v-bind="msg" :conversation="conversation" />
  </div>
</template>

<script>
import { END_USER_PLATFORM } from 'core/constants';
import MessageItem from '../../chat/components/ChatBox/MessageList/message-item';

export default {
  components: {
    MessageItem
  },

  props: {
    messages: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {};
  },

  computed: {
    msgData() {
      let sender = -1;
      return this.messages.reduce((msgs, msg) => {
        if (msg.deleted) return msgs;
        if (msg.sender === sender) {
          msgs[msgs.length - 1].messages.push(msg);
        } else {
          msgs.push({
            sender: msg.sender,
            messages: [msg],
            isAgent: END_USER_PLATFORM.indexOf(msg.platform) === -1,
            isSystem: msg.isSystem || false,
            text: msg.text || ''
          });
          sender = msg.sender;
        }

        return msgs;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.chats {
  margin-top: 130px;
}
</style>
