<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-select-group-prepend">
        <div
          class="nat-select-group-text"
          :style="c_labelWidthStyle"
          v-if="label !== '' && showLabel == true"
        >
          <label>
            <span v-if="required" class="nat-required">*</span>
            {{ label }}
          </label>
          <div class="nat-select-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}-${prefix}`"
              :name="`tooltip-${name}-${prefix}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-select-group-value">
          <div class="nat-select-hori">
            <el-select
              v-bind:data-testid="dataTestId"
              :clearable="!required"
              :disabled="disabled"
              :default-first-option="defaultFirstOption"
              filterable
              :id="`${name}-${prefix}`"
              :name="`${name}-${prefix}`"
              :placeholder="c_placeholder"
              :value="content"
              value-key="key"
              @change="handleChange"
              @input="handleInput"
            >
              <template slot="prefix" v-if="prefixIcon" class="prefix-prefixIcon">
                <span v-html="prefixIcon" class="prefixIcon"></span>
              </template>
              <template v-if="valueType === 'String' && optionType === 'String'">
                <el-option v-for="item in options" :key="item" :label="item" :value="item" />
              </template>
              <template v-if="valueType === 'String' && optionType === 'Object'">
                <el-option
                  v-for="item in options"
                  :key="item[optionValue]"
                  :label="item[optionLable]"
                  :value="item[optionValue]"
                >
                  <template v-if="item.icon">
                    <span v-html="item.icon"></span>
                    <span class="p-l-5">{{ item[optionLable] }}</span>
                  </template>
                  <span v-if="usingFor === 'select-group'" style="float: left">{{
                    item[optionLable]
                  }}</span>
                  <span
                    v-if="usingFor === 'select-group' && !item.isEnable"
                    class="group-status-warning"
                    style="float: right"
                  >
                    <i
                      v-b-tooltip.hover
                      :title="$t('common.text.group_status')"
                      class="el-icon-warning-outline"
                    ></i>
                  </span>
                </el-option>
              </template>
              <template v-if="valueType === 'Object'">
                <el-option
                  v-for="item in options"
                  :key="item[optionValue]"
                  :label="item[optionLable]"
                  :value="item"
                />
              </template>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="!c_existValue" class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
    <div v-if="align === 'vertical'">
      <label v-if="label !== '' && showLabel == true">
        <span v-if="required" class="nat-required">*</span>
        {{ label }}
        <base-tooltip-icon
          :id="`tooltip-${name}-${prefix}`"
          :name="`tooltip-${name}-${prefix}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
      </label>
      <div class="nat-select-verti">
        <el-select
          v-bind:data-testid="dataTestId"
          :clearable="!required"
          :disabled="disabled"
          :default-first-option="defaultFirstOption"
          filterable
          :id="`${name}-${prefix}`"
          :name="`${name}-${prefix}`"
          :placeholder="c_placeholder"
          :value="content"
          value-key="key"
          @change="handleChange"
          @input="handleInput"
        >
          <template slot="prefix" v-if="prefixIcon" class="prefixIcon">
            <span v-html="prefixIcon" class="prefixIcon"></span>
          </template>
          <template v-if="valueType === 'String' && optionType === 'String'">
            <el-option v-for="item in options" :key="item" :label="item" :value="item" />
          </template>
          <template v-if="valueType === 'String' && optionType === 'Object'">
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionLable]"
              :value="item[optionValue]"
            >
              <template v-if="item.icon">
                <span v-html="item.icon"></span>
                <span class="p-l-5">{{ item[optionLable] }}</span>
              </template>
              <span v-if="usingFor === 'select-group'" style="float: left">{{
                item[optionLable]
              }}</span>
              <el-tooltip
                v-if="usingFor === 'select-group' && !item.isEnable"
                :content="$t('common.text.group_status')"
                trigger="hover"
                placement="top"
                class="item"
                effect="dark"
              >
                <span class="group-status-warning" style="float: right">
                  <i class="el-icon-warning-outline"></i>
                </span>
              </el-tooltip>
            </el-option>
          </template>
          <template v-if="valueType === 'Object'">
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionLable]"
              :value="item"
            />
          </template>
        </el-select>
      </div>
      <div v-if="!c_existValue" class="nat-invalid-data">{{ errors[0] }}</div>
    </div>

    <!-- -->
    <div v-if="align === 'vertical-no-label'">
      <div class="nat-select-verti-no-label">
        <el-select
          v-bind:data-testid="dataTestId"
          :clearable="!required"
          :disabled="disabled"
          :default-first-option="defaultFirstOption"
          filterable
          :id="`${name}-${prefix}`"
          :name="`${name}-${prefix}`"
          :placeholder="c_placeholder"
          :value="content"
          value-key="key"
          @change="handleChange"
          @input="handleInput"
        >
          <template slot="prefix" v-if="prefixIcon" class="prefix-prefixIcon">
            <span v-html="prefixIcon" class="prefixIcon"></span>
          </template>
          <template v-if="valueType === 'String' && optionType === 'String'">
            <el-option v-for="item in options" :key="item" :label="item" :value="item" />
          </template>
          <template v-if="valueType === 'String' && optionType === 'Object'">
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionLable]"
              :value="item[optionValue]"
            >
              <template v-if="item.icon">
                <span v-html="item.icon"></span>
                <span class="p-l-5">{{ item[optionLable] }}</span>
              </template>
            </el-option>
          </template>
          <template v-if="valueType === 'Object'">
            <el-option
              v-for="item in options"
              :key="item[optionValue]"
              :label="item[optionLable]"
              :value="item"
            />
          </template>
        </el-select>
      </div>
      <div v-if="!c_existValue" class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
    <!-- -->
  </ValidationProvider>
</template>

<script>
import './vee-validate';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

export default {
  name: 'base-select',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultFirstOption: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Object, String, Number],
      default: null
    },
    valueType: {
      type: String,
      default: 'Object'
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLable: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'key'
    },
    optionType: {
      type: String,
      default: 'Object'
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    /* eslint-disable vue/require-default-prop */
    dataTestId: {
      type: String,
      required: false
    },
    /* eslint-enable vue/require-default-prop */
    usingFor: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: this.c_value,
      prefixIcon: ''
    };
  },
  computed: {
    c_rules() {
      let allRule = {};
      if (this.required) {
        if (this.error.length) allRule.baseSelectCustomRequired = { label: this.error };
        else allRule.baseSelectRequired = { label: this.label };
      }
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.select.placeholder', { fieldName: this.label });
    },
    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    },
    c_value() {
      if (typeof this.value === 'number') {
        return this.value.toString();
      }
      return this.value;
    },
    c_existValue() {
      if (typeof this.content == 'object') return isEmpty(this.content) ? false : true;
      return !this.content || !this.content.length ? false : true;
    }
  },

  watch: {
    value() {
      this.content = this.c_value;
      const index = findIndex(this.options, o => o.value === this.content);
      if (index >= 0) {
        const { icon = '' } = this.options[index];
        this.prefixIcon = icon;
      }
    }
  },

  created() {
    this.content = this.c_value;
  },
  mounted() {
    if (this.c_value) {
      this.content = this.c_value;
    }
  },
  methods: {
    handleChange(selected) {
      this.content = selected;
      this.$emit('change', this.content);
    },
    handleInput(selected) {
      this.content = selected;
      this.$emit('input', this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-select-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-select-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-select-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-select-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-select-hori {
  /deep/.el-input__inner {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }

  /deep/.el-select {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}
.nat-select-verti {
  /deep/.el-select {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
  /deep/.el-input__prefix {
    width: 25px;
    text-align: center;
  }
}
.nat-select-verti-no-label {
  /deep/.el-select {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}
.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
.prefixIcon {
  display: inline-flex;
  height: 100%;
  align-items: center;
}
</style>
