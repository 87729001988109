import {
  SET_DATA_SEARCH,
  ADD_CONVERSATION,
  GET_AGENT_GROUPS,
  SET_USER_INFO,
  SET_CUSTOMER_SUPPORT,
  SET_FILES_TICKET_MAP,
  ADD_LOAD_MORE_SCROLL_ID,
  ADD_LOAD_SCROLL_ID,
  UPDATE_ALL_TICKET,
  GET_ACTION_CLICK_SEARCH
} from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  projects: {},
  categories: {},
  customerFields: {},
  customerData: [],
  treeStore: [],
  searchText: '',
  channels: {},
  platforms: {},
  groups: {},
  customerSupportReportFile: {},
  conversationReportData: [],
  ticketsFilterMap: {},
  templates: {},
  templateIdMaps: {},
  usersFilterMap: {},
  customerFilterMap: {},
  filesTicketMap: {},
  loadMoreScrollIds: {},

  /**
   * Search Data
   */
  channelScrollIds: {},
  allTicket: [],
  isLoadMoreSearchTickets: false,
  countAll: 0,
  //
  actionClickSearch: {}
};

const mutations = {
  [SET_DATA_SEARCH](state, customerData) {
    state.customerData = [...customerData];
  },

  [ADD_CONVERSATION](state, conversation) {
    const { ticket } = conversation;
    const { id } = ticket;
    state.ticketsFilterMap = {
      ...state.ticketsFilterMap,
      ...{ [id]: conversation }
    };
  },
  [SET_USER_INFO](state, userInfo) {
    const { userId } = userInfo;
    if (state.usersFilterMap.userId) {
      state.usersFilterMap.userId = userInfo;
      state.usersFilterMap = { ...state.usersFilterMap };
    } else {
      state.usersFilterMap = {
        ...state.usersFilterMap,
        ...{ [userId]: userInfo }
      };
    }
  },

  [SET_CUSTOMER_SUPPORT](state, customerSupport) {
    const { ticketId } = customerSupport || {};

    if (ticketId)
      state.customerFilterMap = {
        ...state.customerFilterMap,
        ...{ [ticketId]: customerSupport }
      };
  },

  [GET_AGENT_GROUPS](state, data) {
    state.agentGroups = data;
  },

  [SET_FILES_TICKET_MAP](state, data) {
    const { ticketId, files } = data || {};

    if (ticketId)
      state.filesTicketMap = {
        ...state.filesTicketMap,
        ...{ [ticketId]: files }
      };
  },

  [ADD_LOAD_SCROLL_ID](state, { allTicket, channelScrollIds, countAll }) {
    state.countAll = countAll;
    state.channelScrollIds = { ...channelScrollIds };

    if (allTicket.length > 1)
      allTicket = allTicket.sort((a, b) => {
        return -a.createdAt.localeCompare(b.createdAt);
      });

    state.allTicket = [...allTicket];
    if (allTicket.length > 0) state.isLoadMoreSearchTickets = true;
    else state.isLoadMoreSearchTickets = false;
  },

  [UPDATE_ALL_TICKET](state, { allTicket, countAll }) {
    state.allTicket = allTicket;
    state.countAll = countAll;
  },

  [GET_ACTION_CLICK_SEARCH](state, { userId, channelId }) {
    state.actionClickSearch = { userId, channelId };
  },

  [ADD_LOAD_MORE_SCROLL_ID](
    state,
    { allTicket, channelScrollIds, isLoadMoreSearchTickets }
  ) {
    const currentAllTicket = state.allTicket.slice(0);
    let newAllTickets = [...allTicket, ...currentAllTicket];
    if (newAllTickets.length > 1)
      newAllTickets = newAllTickets.sort((a, b) => {
        return -a.createdAt.localeCompare(b.createdAt);
      });

    state.channelScrollIds = { ...channelScrollIds };
    state.allTicket = [...newAllTickets];
    state.isLoadMoreSearchTickets = isLoadMoreSearchTickets;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
