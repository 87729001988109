<template>
  <div v-bar class="channels-wrap">
    <section class="pt-lg-0">
      <!--  Project Handling (S)  -->
      <div v-if="showAgentProject" class="container m-t-20">
        <div class="row">
          <div class="col-12">
            <h5>
              {{ $t('src.modules.chat.components.OverviewBox.agent.project_handling') }}
            </h5>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row">
              <el-table
                ref="projectHandling"
                v-loading="loading"
                :data="c_projectHandling"
                :header-cell-style="tableHeaderColor"
                class="shadow project-handling-table"
                border
                header-row-class-name="row-header"
              >
                <!-- Project Waiting (S) -->
                <el-table-column
                  :label="$t('src.modules.chat.components.OverviewBox.agent.waiting')"
                >
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.project_waiting_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.waiting') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">{{ scope.row['pjtWaiting'] }}</template>
                </el-table-column>
                <!-- Project Waiting (E) -->

                <!-- Project Supporting (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.project_supporting_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.supporting') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">{{ scope.row['pjtSupporting'] }}</template>
                </el-table-column>
                <!-- Project Supporting (E) -->

                <!-- Project Finished Ticket (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.project_finished_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.finished') }}
                      </span>
                    </el-tooltip>
                  </template>

                  <template slot-scope="scope">
                    {{ scope.row['pjtFinished'] }}
                  </template>
                </el-table-column>
                <!-- Project Finished Ticket (E) -->

                <!-- Project Completed Ticket (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.project_completed_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.completed') }}
                      </span>
                    </el-tooltip>
                  </template>

                  <template slot-scope="scope">
                    {{ scope.row['pjtCompleted'] }}
                  </template>
                </el-table-column>
                <!-- Project Completed Ticket (E) -->

                <!-- Project Available Slots (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.project_available_slots_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.available_slots') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row['pjtAvailableSlots'] !== 0 ? scope.row['pjtAvailableSlots'] : '0'
                    }}
                  </template>
                </el-table-column>
                <!-- Project Available Slots (E) -->

                <!-- Project Longest Waiting Time (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.project_longest_waiting_time_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{
                          $t('src.modules.chat.components.OverviewBox.agent.longest_waiting_time')
                        }}
                      </span>
                    </el-tooltip>
                  </template>

                  <template slot-scope="scope">
                    <el-tooltip
                      v-if="scope.row['pjtLongestWaitingTimeTicket']"
                      :content="
                        $t('src.modules.chat.components.OverviewBox.conversation_id') +
                          ': ' +
                          scope.row['pjtLongestWaitingTimeTicket']
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>{{ scope.row['pjtLongestWaitingTimeLabel'] }}</span>
                    </el-tooltip>
                    <template v-if="!scope.row['pjtLongestWaitingTimeTicket']">
                      {{ scope.row['pjtLongestWaitingTimeLabel'] }}
                    </template>
                  </template>
                </el-table-column>
                <!-- Project Longest Waiting Time (E) -->

                <!-- Project Longest Supporting Time (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.project_longest_supporting_time_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{
                          $t(
                            'src.modules.chat.components.OverviewBox.agent.longest_supporting_time'
                          )
                        }}
                      </span>
                    </el-tooltip>
                  </template>

                  <template slot-scope="scope">
                    <el-tooltip
                      v-if="scope.row['pjtLongestSupportingTimeTicket']"
                      :content="
                        $t('src.modules.chat.components.OverviewBox.conversation_id') +
                          ': ' +
                          scope.row['pjtLongestSupportingTimeTicket']
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>{{ scope.row['pjtLongestSupportingTimeLabel'] }}</span>
                    </el-tooltip>
                    <template v-if="!scope.row['pjtLongestSupportingTimeTicket']">
                      {{ scope.row['pjtLongestSupportingTimeLabel'] }}
                    </template>
                  </template>
                </el-table-column>
                <!-- Project Longest Supporting Time (E) -->
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <!--  Project Handling (E)  -->

      <!--  Agent Handling (S)  -->
      <div v-if="showAgentProject" class="container m-t-20">
        <div class="row">
          <div class="col-12">
            <h5>
              {{ $t('src.modules.chat.components.OverviewBox.agent.agent_handling') }}
            </h5>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row">
              <el-table
                ref="agentHandling"
                v-loading="loading"
                :default-sort="{
                  prop: 'agentName',
                  order: `${selectedSortList}`
                }"
                :data="c_agentHandling"
                :header-cell-style="tableHeaderColor"
                class="shadow agent-handling-table"
                border
                header-row-class-name="row-header"
                stripe="stripe"
                @sort-change="m_sortChange"
              >
                <!-- Agent Name (S) -->
                <el-table-column
                  :label="$t('src.modules.chat.components.OverviewBox.agent.agent_name')"
                  prop="agentName"
                  sortable
                  width="127"
                  :sort-orders="['ascending', 'descending']"
                >
                </el-table-column>
                <!-- Agent Name (E) -->

                <!-- Agent Group (S) -->
                <el-table-column
                  v-if="hasGroup"
                  :label="$t('src.modules.chat.components.OverviewBox.agent.group')"
                >
                  <template slot-scope="scope">
                    <div v-for="group in scope.row['groups']" :key="group.id">
                      {{ group.name }}
                      <span v-if="!group.isEnable" class="group-status-warning">
                        <i
                          v-b-tooltip.hover
                          :title="$t('common.text.group_status')"
                          class="el-icon-warning-outline"
                        ></i>
                      </span>
                      <el-divider></el-divider>
                    </div>
                  </template>
                </el-table-column>
                <!-- Agent Group (E) -->

                <!-- Agent Status (S) -->
                <el-table-column
                  :label="$t('src.modules.chat.components.OverviewBox.agent.status')"
                  width="85"
                >
                  <template slot-scope="scope">
                    <el-tag
                      class="responsive-tag"
                      :style="
                        'border-color:' +
                          statusColor[scope.row['status']] +
                          ';border-radius:15px;border-width:2px'
                      "
                      size="medium"
                      effect="plain"
                    >
                      <span style="color: #606266">{{ statusLabel[scope.row['status']] }}</span>
                    </el-tag>
                  </template>
                </el-table-column>
                <!-- Agent Status (E) -->

                <!-- Agent Waiting Count (S) -->
                <el-table-column width="74">
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.waiting_users_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.waiting_users') }}
                      </span>
                    </el-tooltip>
                  </template>

                  <template v-if="scope.row['waiting'] > 0" slot-scope="scope">
                    <el-popover v-if="scope.row['channels']" trigger="hover" placement="top">
                      <template v-for="channel in scope.row['channels']">
                        <div v-if="channel.waiting > 0" :key="channel.id">
                          {{ channel.name + ': ' + channel.waiting }}
                        </div>
                      </template>
                      <div slot="reference" class="name-wrapper">
                        <el-tag class="responsive-tag" size="medium">{{
                          scope.row['waiting']
                        }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <!-- Agent Waiting Count (E) -->

                <!-- Agent Supporting Count (S) -->
                <el-table-column width="94">
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.supporting_users_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.supporting_users') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-if="scope.row['supporting'] > 0" slot-scope="scope">
                    <el-popover v-if="scope.row['channels']" trigger="hover" placement="top">
                      <template v-for="channel in scope.row['channels']">
                        <div v-if="channel.supporting > 0" :key="channel.id">
                          {{ channel.name + ': ' + channel.supporting }}
                        </div>
                      </template>
                      <div slot="reference" class="name-wrapper">
                        <el-tag class="responsive-tag" size="medium">{{
                          scope.row['supporting']
                        }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <!-- Agent Supporting Count (S) -->

                <!-- Agent Finished Ticket (S) -->
                <el-table-column width="80">
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.finished_time_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.finished') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row['finished'] }}
                  </template>
                </el-table-column>
                <!-- Agent Finished Ticket (E) -->

                <!-- Agent Completed Ticket (S) -->
                <el-table-column width="94">
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.completed_time_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.completed') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row['completed'] }}
                  </template>
                </el-table-column>
                <!-- Agent Completed Ticket (E) -->

                <!-- Agent Available Slots (S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t('src.modules.chat.components.OverviewBox.agent.available_slots_tooltip')
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{ $t('src.modules.chat.components.OverviewBox.agent.available_slots') }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row['agentAvailableSlots'] !== 0
                        ? scope.row['agentAvailableSlots']
                        : '0'
                    }}
                  </template>
                </el-table-column>
                <!-- Agent Available Slots (E) -->

                <!-- Agent Longest Waiting Time(S) -->
                <el-table-column>
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.longest_waiting_time_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{
                          $t('src.modules.chat.components.OverviewBox.agent.longest_waiting_time')
                        }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <el-tooltip
                      v-if="scope.row['agentLongestWaitingTimeTicket']"
                      :content="
                        $t('src.modules.chat.components.OverviewBox.conversation_id') +
                          ': ' +
                          scope.row['agentLongestWaitingTimeTicket']
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>{{ scope.row['agentLongestWaitingTimeLabel'] }}</span>
                    </el-tooltip>
                    <template v-if="!scope.row['agentLongestWaitingTimeTicket']">
                      {{ scope.row['agentLongestWaitingTimeLabel'] }}
                    </template>
                  </template>
                </el-table-column>
                <!-- Agent Longest Waiting Time(E) -->

                <!-- Agent Longest Supporting Time(S) -->
                <el-table-column width="94">
                  <template slot="header">
                    <el-tooltip
                      :content="
                        $t(
                          'src.modules.chat.components.OverviewBox.agent.longest_supporting_time_tooltip'
                        )
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        {{
                          $t(
                            'src.modules.chat.components.OverviewBox.agent.longest_supporting_time'
                          )
                        }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <el-tooltip
                      v-if="scope.row['agentLongestSupportingTimeTicket']"
                      :content="
                        $t('src.modules.chat.components.OverviewBox.conversation_id') +
                          ': ' +
                          scope.row['agentLongestSupportingTimeTicket']
                      "
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span>{{ scope.row['agentLongestSupportingTimeLabel'] }}</span>
                    </el-tooltip>
                    <template v-if="!scope.row['agentLongestSupportingTimeTicket']">
                      {{ scope.row['agentLongestSupportingTimeLabel'] }}
                    </template>
                  </template>
                </el-table-column>
                <!-- Agent Longest Supporting Time(S) -->

                <!-- Agent Status History (S) -->
                <el-table-column
                  :label="$t('src.modules.chat.components.OverviewBox.agent.status_history')"
                  width="110"
                >
                  <template slot-scope="scope">
                    <div style="padding-top: 10px; padding-bottom: 10px">
                      <b-button
                        type="button"
                        variant="primary"
                        @click="agentWokingDetailsBox(scope.row)"
                      >
                        {{
                          $t('src.modules.chat.components.OverviewBox.agent.status_history_details')
                        }}
                      </b-button>
                    </div>
                  </template>
                </el-table-column>
                <!-- Agent Status History (E) -->
              </el-table>
            </div>
          </div>
        </div>
        <!-- Agent Handling Paging (S) -->
        <div style="text-align: center; margin-top: 30px">
          <el-pagination
            background
            ref="pagination"
            :total="total"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next"
            @current-change="currentChange"
          ></el-pagination>
        </div>
        <!-- Agent Handling Paging (E) -->
      </div>
      <!--  Agent Handling (E)  -->

      <!-- Agent Availabilities (S) -->
      <div class="container m-t-20">
        <div class="row">
          <div class="col-12">
            <h5>
              {{ $t('src.modules.chat.components.OverviewBox.agent.agent_availability') }}
            </h5>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row">
              <el-table
                ref="agentAvailabilities"
                v-loading="loading"
                :data="agentAvailabilities"
                :default-sort="{ prop: 'name', order: `${selectedSortList}` }"
                :span-method="spanMethod"
                :header-cell-style="tableHeaderColor"
                class="shadow"
                style="width: 100%"
                border
                header-row-class-name="row-header"
              >
                <el-table-column
                  :sort-method="(a, b) => sortData(a, b, 'name')"
                  :sort-orders="['ascending', 'descending']"
                  :label="$t('src.modules.chat.components.OverviewBox.agent.channel_name')"
                  prop="name"
                  sortable
                />
                <el-table-column
                  v-for="status in statusList"
                  :key="status"
                  :label="statusLabel[status]"
                  align="center"
                >
                  <template slot="header">
                    <el-tag
                      class="responsive-tag"
                      :style="
                        'border-color:' +
                          statusColor[status] +
                          ';border-radius:15px;border-width:2px'
                      "
                      size="medium"
                      effect="plain"
                    >
                      <span style="color: #606266">{{ statusLabel[status] }}</span>
                    </el-tag>
                  </template>
                  <template v-if="scope.row[status] > 0" slot-scope="scope">
                    <el-popover
                      v-for="({ agents, groups }, index) in getAgentsByStatus(status)"
                      :key="index"
                      trigger="hover"
                      placement="top"
                      popper-class="nat-agent-list"
                    >
                      <div v-for="(agent, agindex) in agents" :key="agent.id">
                        <div class="row">
                          <div
                            v-if="!agent.groups || agent.groups.length === 0"
                            class="col-12 agent-groups"
                          >
                            {{ agent.firstName + ' ' + agent.lastName }}
                          </div>
                          <div
                            v-if="agent.groups && agent.groups.length > 0"
                            class="col-6 agent-groups"
                          >
                            {{ agent.firstName + ' ' + agent.lastName }}:
                          </div>
                          <div
                            v-if="agent.groups && agent.groups.length > 0"
                            class="col-6 agent-groups"
                          >
                            <div v-for="group in agent.groups" :key="group.id">
                              - {{ group.name }}
                              <span v-if="!group.isEnable" class="group-status-warning">
                                <i
                                  v-b-tooltip.hover
                                  :title="$t('common.text.group_status')"
                                  class="el-icon-warning-outline"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <el-divider v-if="agindex < agents.length - 1"></el-divider>
                      </div>
                      <div slot="reference" class="name-wrapper">
                        <el-tag class="responsive-tag" size="medium">{{
                          scope.row[status]
                        }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <!-- Agent Availabilities (E) -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { EventBus } from 'core/eventBus';
import {
  AGENT_STATUS,
  DEFAULT_AGENT_STATUS_LABEL,
  AGENT_STATUS_COLOR,
  AGENT_ROLE
} from 'core/constants';

import { cloneDeep } from 'lodash';
export default {
  props: {
    selectedSortList: {
      type: String,
      default: 'ascending'
    },
    selectedProject: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      statusList: Object.values(AGENT_STATUS),
      statusLabel: DEFAULT_AGENT_STATUS_LABEL,
      statusColor: AGENT_STATUS_COLOR,
      loading: false,
      projectHandling: [],
      agentHandling: [],
      agentAvailabilities: [],
      activitiesData: {},

      // Agent Handling Paging
      total: 0,
      pagesize: 5,
      currentPage: 1
    };
  },

  computed: {
    ...mapState('session', ['projectMaps', 'channelsMap', 'user']),

    c_agentHandling() {
      const data = this.agentHandling.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      return data;
    },
    c_projectHandling() {
      return this.projectHandling;
    },

    agentsMap() {
      const { agents = [] } = this.activitiesData || {};
      const agentsMap = {};
      agents.map(agent => (agentsMap[agent.id] = agent));
      return agentsMap;
    },

    hasGroup() {
      return (
        this.projectMaps &&
        this.projectMaps[this.selectedProject] &&
        this.projectMaps[this.selectedProject].hasGroup
      );
    },

    agentStatusMap() {
      const map = {
        [AGENT_STATUS.ONLINE]: [],
        [AGENT_STATUS.OFFLINE]: [],
        [AGENT_STATUS.BUSY]: [],
        [AGENT_STATUS.AWAY]: [],
        [AGENT_STATUS.LUNCH]: [],
        [AGENT_STATUS.BREAK]: []
      };
      Object.values(this.agentsMap).map(agent => {
        if (agent.status && map[agent.status]) map[agent.status].push(agent.id);
      });

      return map;
    },

    channelAgents() {
      const channels = Object.values(this.channelsMap).filter(
        ({ platform, configs, projectId }) =>
          projectId === this.selectedProject &&
          !(platform === 'instagram' && !configs.instagramMessenger)
      );
      const { agents = [] } = this.activitiesData || {};

      let channelAgentsData = [];

      channels.map(channel => {
        const { id: channelId } = channel;
        const data = agents.map(agent => {
          const { id: agentId } = agent;
          return { channelId, agentId };
        });
        channelAgentsData = [...channelAgentsData, ...data];
      });

      return channelAgentsData;
    },

    c_projectName() {
      return this.projectMaps[this.selectedProject].name;
    },

    showAgentProject() {
      const { role } = this.user;
      return role === AGENT_ROLE.LEADER || (role === AGENT_ROLE.MODERATOR && this.hasGroup);
    }
  },

  watch: {
    async selectedProject() {
      await this.refresh();
    },

    selectedSortList(val) {
      if (this.$refs.agentHandling) {
        this.$refs.agentHandling.sort('agentName', val);
      }
      if (this.$refs.agentAvailabilities) {
        this.$refs.agentAvailabilities.sort('name', val);
      }
    }
  },

  async mounted() {
    await this.refresh();
  },

  methods: {
    ...mapActions('chat', ['getActivities', 'getDashboardReport']),

    m_agentMaxConversation({ conversationQuota, role, groups }) {
      if (groups.length > 0) {
        switch (role) {
          case AGENT_ROLE.LEADER:
            return conversationQuota['numberSupervisor'] || 0;
          case AGENT_ROLE.MODERATOR:
            return conversationQuota['numberLeader'] || 0;
          case AGENT_ROLE.REGULAR:
            return conversationQuota['numberRegular'] || 0;
          default:
            return 0;
        }
      } else {
        switch (role) {
          case AGENT_ROLE.LEADER:
            return conversationQuota['numberSupervisor'] || 0;
          case AGENT_ROLE.MODERATOR:
          case AGENT_ROLE.REGULAR:
            return conversationQuota['numberRegularLeader'] || 0;
          default:
            return 0;
        }
      }
    },

    async refresh() {
      this.loading = true;
      this.$emit('disableRefreshBtn', true);
      try {
        const now = new Date().toISOString();

        const [activities, dashboardReport] = await Promise.all([
          this.getActivities(this.selectedProject),
          this.getDashboardReport({ projectId: this.selectedProject, now })
        ]);
        const {
          project,
          supportingByAgent,
          waittingByAgent,
          finishTicketsByAgent,
          completeTicketsByAgent
        } = dashboardReport;
        this.activitiesData = activities || {};
        this.refreshProjectHandling({ project });
        this.refreshAgentHandling({
          supportingByAgent,
          waittingByAgent,
          finishTicketsByAgent,
          completeTicketsByAgent
        });
        this.refreshAgentAvailabilities();
        this.$emit('disableRefreshBtn', false);
        this.loading = false;
      } catch (error) {
        // eslint-disable-next-line
        console.log('refreshData -> error', error);
        this.$emit('disableRefreshBtn', false);
        this.loading = false;
      }
    },

    refreshProjectHandling({ project }) {
      const { conversationQuota = {} } = this.activitiesData || {};
      const itemProjectHandling = {};

      itemProjectHandling.pjtLongestWaitingTimeLabel = 0;
      itemProjectHandling.pjtLongestSupportingTimeLabel = 0;

      const {
        totalSupporting = 0,
        totalWaiting = 0,
        totalFinishTicket = 0,
        totalCompleteTicket = 0,
        maxSupportingTime = 0,
        maxWaitingTime = 0,
        totalToMetickets = 0
      } = project || {};

      itemProjectHandling.pjtWaiting = totalWaiting;
      itemProjectHandling.pjtSupporting = totalSupporting;
      itemProjectHandling.pjtFinished = totalFinishTicket;
      itemProjectHandling.pjtCompleted = totalCompleteTicket;

      const projectMaxConversation = conversationQuota['numberProject'];
      if (projectMaxConversation !== 0 ) {
        const als = Number(projectMaxConversation) - (Number(totalSupporting) + Number(totalToMetickets));
        itemProjectHandling.pjtAvailableSlots = als >= 0 ? als : 0;
      } else {
        itemProjectHandling.pjtAvailableSlots = 'N/A';
      }

      if (typeof maxWaitingTime === 'object') {
        const { waitingTime, id } = maxWaitingTime;
        itemProjectHandling.pjtLongestWaitingTimeLabel = this.secondsToTime(waitingTime);
        itemProjectHandling.pjtLongestWaitingTimeTicket = id;
      }

      if (typeof maxSupportingTime === 'object') {
        const { supprotingTime, id } = maxSupportingTime;
        itemProjectHandling.pjtLongestSupportingTimeLabel = this.secondsToTime(supprotingTime);
        itemProjectHandling.pjtLongestSupportingTimeTicket = id;
      }

      this.projectHandling = [itemProjectHandling];
    },

    refreshAgentHandling({
      supportingByAgent,
      waittingByAgent,
      finishTicketsByAgent,
      completeTicketsByAgent
    }) {
      const { conversationQuota = {} } = this.activitiesData || {};
      const { agents = [] } = this.activitiesData || {};
      const groups = Object.values(this.projectMaps[this.selectedProject].groups);
      let agentHandling = agents.map(agent => {
        agent.agentName = agent['firstName'] + ' ' + agent['lastName'];
        agent.waiting = 0;
        agent.supporting = 0;
        agent.finished = 0;
        agent.completed = 0;
        agent.agentLongestWaitingTimeLabel = 0;
        agent.agentLongestSupportingTimeLabel = 0;
        agent.channels = [];

        const { id } = agent;
        agent.groups = groups.filter(
          group =>
            (group.agents || []).includes(id) ||
            group.moderators.includes(id) ||
            group.leaders.includes(id)
        );

        if (waittingByAgent && waittingByAgent[id]) {
          const { totalWaiting, maxWaitingTime, waittingByChannel } = waittingByAgent[id];
          agent.waiting = totalWaiting;

          if (typeof maxWaitingTime === 'object') {
            const { waitingTime, id } = maxWaitingTime;
            agent.agentLongestWaitingTimeLabel = this.secondsToTime(waitingTime);
            agent.agentLongestWaitingTimeTicket = id;
          }

          if (Object.keys(waittingByChannel).length > 0) {
            Object.keys(waittingByChannel).map(channelId => {
              let channel = {};
              channel.id = channelId;
              channel.name =
                (this.channelsMap[channelId] && this.channelsMap[channelId].name) || channelId;
              channel.waiting = waittingByChannel[channelId];
              agent.channels.push(channel);
            });
          }
        }

        if (supportingByAgent && supportingByAgent[id]) {
          const { totalSupporting, maxSupportingTime, supportingByChannel } = supportingByAgent[id];
          agent.supporting = totalSupporting;

          if (typeof maxSupportingTime === 'object') {
            const { supprotingTime, id } = maxSupportingTime;
            agent.agentLongestSupportingTimeLabel = this.secondsToTime(supprotingTime);
            agent.agentLongestSupportingTimeTicket = id;
          }

          if (Object.keys(supportingByChannel).length > 0) {
            Object.keys(supportingByChannel).map(channelId => {
              let channel = {};
              channel.id = channelId;
              channel.name =
                (this.channelsMap[channelId] && this.channelsMap[channelId].name) || channelId;
              channel.supporting = supportingByChannel[channelId];
              agent.channels.push(channel);
            });
          }
        }

        const agentMaxConversation = this.m_agentMaxConversation({
          conversationQuota,
          role: agent.role,
          groups: agent.groups
        });

        if (agentMaxConversation !== 0) {
          const als = Number(agentMaxConversation) - (Number(agent.waiting) + Number(agent.supporting));
          agent.agentAvailableSlots = als >= 0 ? als : 0;
        } else {
          agent.agentAvailableSlots = 'N/A';
        }

        if (finishTicketsByAgent && finishTicketsByAgent[id]) {
          agent.finished = finishTicketsByAgent[id];
        }
        if (completeTicketsByAgent && completeTicketsByAgent[id]) {
          agent.completed = completeTicketsByAgent[id];
        }

        return agent;
      });
      if (this.user.role === AGENT_ROLE.MODERATOR)
        agentHandling = agentHandling.filter(({ role }) => role !== AGENT_ROLE.LEADER);
      agentHandling = this.processItems(agentHandling, 'agentName', this.selectedSortList);
      this.agentHandling = agentHandling.slice(0);
      this.total = this.agentHandling.length;
    },

    refreshAgentAvailabilities() {
      const items = [];
      if (!this.channelsMap) return items;

      const channels = Object.values(this.channelsMap).filter(
        ({ platform, configs, projectId }) =>
          projectId === this.selectedProject &&
          !(platform === 'instagram' && !configs.instagramMessenger)
      );

      const agentAvailabilities = channels.map(channel => {
        const countByChannel = this.channelAgents.filter(data => data.channelId === channel.id);
        const countStatus = {};
        Object.keys(this.agentStatusMap).map(status => {
          countStatus[status] = countByChannel.filter(countItem =>
            this.agentStatusMap[status].includes(countItem.agentId)
          ).length;
        });

        return { ...channel, ...countStatus };
      });

      this.agentAvailabilities = agentAvailabilities.slice(0);
    },

    spanMethod({ column, rowIndex }) {
      if (column.property !== 'name' && rowIndex === 0) {
        return { rowspan: this.agentAvailabilities.length, colspan: 1 };
      }
      return { rowspan: 1, colspan: 1 };
    },

    getAgentsByStatus(status) {
      const agentStatusMap = this.agentStatusMap || {};
      const agentIds = agentStatusMap[status] || [];
      const agents = agentIds.map(agentId => this.agentsMap[agentId]);
      const groups = Object.values(this.projectMaps[this.selectedProject].groups).filter(group =>
        (group.agents || [])
          .concat(group.leaders)
          .concat(group.moderators)
          .some(agentId => agentIds.includes(agentId))
      );
      return [{ agents, groups }];
    },

    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee;font-weight: 500;';
      }
    },

    sortData(a, b, field) {
      const x = a[field];
      const y = b[field];
      return x > y ? 1 : x < y ? -1 : 0;
    },

    secondsToTime(s, formatDate) {
      //full formatDate "y-m-d-hh-mm-ss"
      let format = 'd-hh-mm';
      if (formatDate) format = formatDate;
      if (s === 0) return '0';
      // create array of day, hour, minute and second values
      const [y, m, d, hh, mm, ss] = [
        Math.floor(s / (3600 * 24 * 365.24)),
        Math.floor((s % (3600 * 24 * 365.24)) / (3600 * 24 * 30.44)),
        Math.floor((s % (3600 * 24 * 30.44)) / (3600 * 24)),
        Math.floor((s % (3600 * 24)) / 3600),
        Math.floor((s % 3600) / 60),
        Math.floor(s % 60)
      ];
      let formatDateArrange = [];
      format.split('-').map(item => {
        switch (item) {
          case 'y':
            formatDateArrange.push({ v: y, k: 'y' });
            break;
          case 'm':
            formatDateArrange.push({ v: m, k: 'm' });
            break;
          case 'd':
            formatDateArrange.push({ v: d, k: 'd' });
            break;
          case 'hh':
            formatDateArrange.push({ v: hh, k: 'hh' });
            break;
          case 'mm':
            formatDateArrange.push({ v: mm, k: 'mm' });
            break;
          case 'ss':
            formatDateArrange.push({ v: ss, k: 'ss' });
            break;

          default:
            break;
        }
      });
      // map over array
      const time = formatDateArrange
        .map(item => {
          const { v, k } = item || {};
          if (v && k) {
            // add the relevant value suffix
            if (k === 'y' && format.indexOf('y') >= 0) {
              return this.plural(v, 'src.core.timeago.a_year', 'src.core.timeago.y');
            } else if (k === 'm' && format.indexOf('m') >= 0) {
              return this.plural(v, 'src.core.timeago.a_month', 'src.core.timeago.M');
            } else if (k === 'd' && format.indexOf('d') >= 0) {
              return this.plural(v, 'src.core.timeago.a_day', 'src.core.timeago.d');
            } else if (k === 'hh' && format.indexOf('hh') >= 0) {
              return this.plural(v, 'src.core.timeago.an_hour', 'src.core.timeago.h');
            } else if (k === 'mm' && format.indexOf('mm') >= 0) {
              return this.plural(v, 'src.core.timeago.a_min', 'src.core.timeago.m');
            } else if (k === 'ss' && format.indexOf('ss') >= 0) {
              return this.plural(v, 'src.core.timeago.a_sec', 'src.core.timeago.s');
            }
            return '';
          }
        })
        .join(' ');

      if (time.trim() === '') {
        return this.$t('src.modules.session.components.adfsSelect.loading');
      } else {
        return time;
      }
    },

    plural(value, singular, plural) {
      if (value === 1) {
        return this.$t(singular);
      } else if (value > 1) {
        return this.$t(plural, { val: value });
      }
    },

    currentChange(currentPage) {
      this.currentPage = currentPage;
    },

    m_sortChange(sortProps) {
      const { order, prop } = sortProps;
      this.sortOptions = sortProps;
      this.agentHandling = cloneDeep(this.processItems(this.agentHandling, prop, order));
    },

    processItems(data, prop, order) {
      let result = data;
      switch (order) {
        case 'descending':
          return result.sort((a, b) => this.sortData(b, a, prop));
        case 'ascending':
          return result.sort((a, b) => this.sortData(a, b, prop));
        default:
          return result.sort((a, b) => this.sortData(a, b, prop));
      }
    },

    agentWokingDetailsBox(row) {
      const { id, agentName } = row;
      EventBus.$emit('agentWorkingDetails', {
        agentId: id,
        projectId: this.selectedProject,
        agentName: agentName,
        projectName: this.c_projectName
      });
    }
  }
};
</script>
<style lang="scss">
.project-handling-table {
  width: 100%;
}

.agent-handling-table {
  width: 100%;
  .el-divider--horizontal {
    margin: 3px;
  }
}

.nat-agent-list {
  max-height: 30em !important;
  max-width: 30em !important;
  padding-right: 20px !important;
  overflow: auto;
  &.el-popper .popper__arrow {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.row-overview {
  padding-bottom: 30px;
}
.el-status-group {
  max-width: 140px;
}
.row-header {
  background-color: gray;
}
.shadow {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
}
.agent-groups {
  word-break: break-word !important;
  text-align: left !important;
}
/deep/ .el-table {
  .cell {
    word-break: break-word !important;
  }
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: unset;
  transition: background-color 0.25s ease;
}
</style>
