import { TICKET_STATUS, AGENT_ROLE, TICKET_STATE, TABS } from 'core/constants';
import { pickBy, uniqBy, isEmpty } from 'lodash';
import { configs as cf } from 'core/configs';

const me = state => state.user;
const isAuthenticated = state => !!state.token;
const authStatus = state => state.status;
const token = state => state.token;
const getAgentUser = state => state.user;
const isPhoneAgent = state => state.user?.role === AGENT_ROLE.PHONE_AGENT;
const configs = state => (state.user?.id ? cf : {});

const tagMaps = state => {
  const { projectTagMaps = {} } = state;
  return Object.keys(projectTagMaps).reduce((tagMaps, projectId) => {
    const ticketsTags = projectTagMaps[projectId].ticketsTags || [];

    tagMaps[projectId] = projectTagMaps[projectId].tags.reduce((ptMaps, tag) => {
      tag = tag || {};
      tag.ticketCount = ticketsTags.filter(tt => tt.tagId === tag.id).length;
      ptMaps[tag.id] = tag;
      return ptMaps;
    }, {});
    return tagMaps;
  }, {});
};

const ticketTagMaps = (state, getters) => {
  const { projectTagMaps = {} } = state;
  const { tagMaps = {} } = getters;
  return Object.keys(projectTagMaps).reduce((ttMaps, projectId) => {
    const ticketsTags = projectTagMaps[projectId].ticketsTags || [];
    const tags = tagMaps[projectId] || {};
    ticketsTags.map(tt => {
      if (tags[tt.tagId]) {
        const ticketTag = { ...tt, ...tags[tt.tagId] };
        ttMaps[tt.ticketId] = ttMaps[tt.ticketId]
          ? [...ttMaps[tt.ticketId], ticketTag]
          : [ticketTag];
      }
    });
    return ttMaps;
  }, {});
};

const labelMaps = state => {
  const { projectLabelMaps = {} } = state;
  return Object.keys(projectLabelMaps).reduce((labelMaps, projectId) => {
    const usersLabels = projectLabelMaps[projectId].usersLabels || [];

    labelMaps[projectId] = projectLabelMaps[projectId].labels.reduce((projectLabelMaps, label) => {
      label = label || {};
      label.userCount = usersLabels.filter(tt => tt.labelId === label.id).length;
      projectLabelMaps[label.id] = label;
      return projectLabelMaps;
    }, {});
    return labelMaps;
  }, {});
};

const tickets = (state, getters) => {
  const { tickets = {} } = state;
  const { isSearch } = getters;

  return Object.values(tickets).reduce((tks, tk) => {
    if (tk.deleted || (!isSearch && tk.notUsingAgentTerminal === true)) return tks;
    tks[tk.id] = { ...tk, labels: tk.labels || [] };
    return tks;
  }, {});
};

const sortConversations = conversations => {
  conversations.sort((a, b) => {
    if (a.message && a.message.createdAt && b.message && b.message.createdAt) {
      return b.message.createdAt.localeCompare(a.message.createdAt);
    }
    return 1;
  });

  return uniqBy(conversations, 'ticketId');
};

const conversations = (state, getters, rootState) => {
  const { conversations = [], channelsMap } = state;
  const { tickets } = getters;
  const { messages } = rootState.chat;

  if (!tickets || Object.keys(tickets).length === 0) return [];

  let convs = conversations.reduce((convs, conv) => {
    if (!conv.message && messages[conv.id]) {
      conv.message = messages[conv.id][messages[conv.id].length - 1];
    }

    const translations = _getTranslations(channelsMap, conv);

    if (conv.ticketId && tickets[conv.ticketId]) {
      convs.push({ ...conv, ticket: tickets[conv.ticketId], translations });
    }

    const ticketsByConversation = Object.values(tickets).filter(
      tk => tk.conversationId === conv.id && tk.id !== conv.ticketId
    );

    ticketsByConversation.forEach(tk =>
      convs.push({ ...conv, ticketId: tk.id, ticket: tk, translations })
    );

    return convs;
  }, []);

  convs = uniqBy(convs, 'ticketId');

  return sortConversations(convs);
};

const conversationsMap = (state, getters) => {
  return getters.conversations.reduce(
    (map, conversation) => ({ ...map, [conversation.id]: conversation }),
    {}
  );
};

const _getTranslations = (channelsMap, conversation) => {
  if (!conversation || !conversation.channelId) return {};
  const { translations, defaultLanguage } = channelsMap[conversation.channelId];
  if (!translations) return {};
  const { locale = '' } = (conversation && conversation.userInfo) || {};
  return Object.keys(translations).reduce(function (result, key) {
    if (locale && typeof translations[key] === 'object' && translations[key][locale]) {
      result[key] = translations[key][locale];
      return result;
    }
    if (!defaultLanguage) return undefined;

    result[key] = translations[key][defaultLanguage];
    return result;
  }, {});
};

const conversationsByBot = (state, getters) => {
  return getters.conversations.filter(
    c =>
      ((!c.ticket && c.botId) ||
        (c.ticket &&
          [
            TICKET_STATUS.REQUEST_USER,
            TICKET_STATUS.BOT_HANDLE,
            TICKET_STATUS.USER_MENTION
          ].includes(c.ticket.status) &&
          ![TICKET_STATE.COMPLETE].includes(c.ticket.state))) &&
      (!c.ticket.hasOwnProperty('grpNavTabIndex') ||
        (c.ticket.hasOwnProperty('grpNavTabIndex') && c.ticket.grpNavTabIndex !== TABS.MONITORING))
  );
};

const commonWaitingList = (_, getters) => {
  const commonStatus = [TICKET_STATUS.REQUEST_AGENT, TICKET_STATUS.WAITING_TIMEOUT];
  const waitingState = [TICKET_STATE.REQUEST];

  return getters.conversations.filter(
    c =>
      ((!c.ticket && !c.agentId && !c.botId) ||
        (c.ticket.assignee === TICKET_STATUS.FORWARD_GROUP && //SGCPLUS-4665
          c.ticket.state !== TICKET_STATE.COMPLETE &&
          c.ticket.groupId &&
          getters.meGroups.includes(c.ticket.groupId)) ||
        (c.ticket &&
          c.ticket.id === c.ticket.assignee &&
          c.ticket.id === c.ticket.agentId &&
          commonStatus.includes(c.ticket.status) &&
          waitingState.includes(c.ticket.state) &&
          ((c.ticket.groupId && getters.meGroups.includes(c.ticket.groupId)) ||
            !c.ticket.groupId))) &&
      (!c.ticket.hasOwnProperty('grpNavTabIndex') ||
        (c.ticket.hasOwnProperty('grpNavTabIndex') && c.ticket.grpNavTabIndex !== TABS.MONITORING))
  );
};

const meWaitingList = (state, getters) => {
  const { user } = state;
  const toMeStatus = [
    TICKET_STATUS.ASSIGN_TO,
    TICKET_STATUS.TRANSFER_TO,
    TICKET_STATUS.ESCALATE_TO,
    TICKET_STATUS.WAITING_TIMEOUT,
    TICKET_STATUS.RESPONSE_TIMEOUT
  ];
  const states = [TICKET_STATE.REQUEST, TICKET_STATE.SUPPORTING, TICKET_STATE.START];
  return getters.conversations.filter(
    c =>
      ((!c.ticket && !c.botId && c.agentId === user.id) ||
        (c.ticket &&
          c.ticket.assignee === user.id &&
          c.ticket.assignee !== c.ticket.agentId &&
          toMeStatus.includes(c.ticket.status) &&
          states.includes(c.ticket.state))) &&
      (!c.ticket.hasOwnProperty('grpNavTabIndex') ||
        (c.ticket.hasOwnProperty('grpNavTabIndex') && c.ticket.grpNavTabIndex !== TABS.MONITORING))
  );
};

const monitoringList = (state, getters) => {
  const { user, projectMaps } = state;
  const { isSearch, conversations, meGroups } = getters;
  if (user.role === AGENT_ROLE.CLIENT) return [];
  return conversations.filter(c => {
    const project = projectMaps[c.ticket.projectId];
    if (!project) return false;
    const { isAllowAllView = true } = project?.config.accessPermission || {};

    if (!c.ticket && c.botId) return false;

    if (
      c.ticket.hasOwnProperty('grpNavTabIndex') &&
      c.ticket.grpNavTabIndex !== TABS.MONITORING &&
      isSearch == false
    )
      return false;

    if (user.role === AGENT_ROLE.LEADER) {
      return c.ticket && ![TICKET_STATUS.BOT_HANDLE].includes(c.ticket.status);
    } else if (user.role === AGENT_ROLE.MODERATOR) {
      if (!c.ticket) return true;
      if (!c.ticket.projectId) return false;
      if ([TICKET_STATUS.BOT_HANDLE].includes(c.ticket.status)) return false;

      if (!project.groups || !project.hasGroup) {
        if (!isAllowAllView) {
          return c.ticket && (c.ticket.assignee === user.id || c.ticket.assignee === c.ticket.id);
        }
        return c.ticket;
      }

      if (!c.ticket.groupId) return true;

      const group = project.groups[c.ticket.groupId];
      if (!group || !Array.isArray(group.moderators)) return false;
      return group.moderators.includes(user.id);
    } else if (user.role === AGENT_ROLE.REGULAR) {
      if ([TICKET_STATUS.BOT_HANDLE].includes(c.ticket.status)) return false;
      if (!project.groups || !project.hasGroup) {
        if (!isAllowAllView) {
          return c.ticket && (c.ticket.assignee === user.id || c.ticket.assignee === c.ticket.id);
        }
        return c.ticket;
      }

      if (!isAllowAllView) {
        return (
          c.ticket &&
          ((c.ticket.groupId &&
            meGroups.includes(c.ticket.groupId) &&
            c.ticket.assignee === user.id) ||
            c.ticket.assignee === c.ticket.id)
        );
      }
      return (
        c.ticket &&
        ((c.ticket.groupId && meGroups.includes(c.ticket.groupId)) ||
          c.ticket.assignee === c.ticket.id)
      );
    }
  });
};

const conversationsByAgents = (state, getters) => {
  const { user } = state;
  const supportStatus = [
    TICKET_STATUS.AGENT_SUPPORTING,
    TICKET_STATUS.HOLDING,
    TICKET_STATUS.AGENT_IDLE,
    TICKET_STATUS.USER_IDLE,
    TICKET_STATUS.USER_FINISH,
    TICKET_STATUS.AGENT_FINISH,
    TICKET_STATUS.AGENT_START,
    TICKET_STATUS.FINISH_TIMEOUT,
    TICKET_STATUS.RESPONSE_TIMEOUT,
    TICKET_STATUS.MAINTENANCE_FINISH
  ];
  const noneState = [TICKET_STATE.COMPLETE];
  return getters.conversations.filter(
    c =>
      ((!c.ticket && c.agentId == user.id) ||
        (c.ticket &&
          c.ticket.assignee == user.id &&
          c.ticket.agentId == user.id &&
          supportStatus.includes(c.ticket.status) &&
          !noneState.includes(c.ticket.state))) &&
      (!c.ticket.hasOwnProperty('grpNavTabIndex') ||
        (c.ticket.hasOwnProperty('grpNavTabIndex') && c.ticket.grpNavTabIndex !== TABS.MONITORING))
  );
};

const socialLinks = state => {
  const { socialLinks } = state.user || {};
  if (!socialLinks)
    return {
      google: {},
      facebook: {}
    };
  const { google = {}, facebook = {} } = socialLinks;
  return {
    google,
    facebook
  };
};

const groupMaps = state => {
  const { projectMaps } = state;
  if (!projectMaps) return {};
  return Object.keys(projectMaps).reduce((maps, projectId) => {
    return {
      ...maps,
      [projectId]: Object.values(projectMaps[projectId].groups)
    };
  }, {});
};

const meGroups = (_, getters) => {
  const { id, role } = getters.me || {};
  let cats = getters.groupMaps;
  let meGrps = [];
  for (const pjt in cats) {
    const channels = cats[pjt];
    const groups = channels.filter(c => {
      switch (role) {
        case AGENT_ROLE.LEADER:
          return true;
        case AGENT_ROLE.MODERATOR:
          return Array.isArray(c.moderators) && c.moderators.includes(id);
        case AGENT_ROLE.REGULAR:
          return Array.isArray(c.agents) && c.agents.includes(id);
        default:
          return false;
      }
    });
    meGrps = [...meGrps, ...groups];
  }
  return meGrps.map(g => g.id);
};

const notificationSettings = state => {
  const { user } = state;
  if (!user) return null;

  const { notificationSettings } = user;
  if (!notificationSettings) return null;

  return notificationSettings.reduce((res, item) => ({ ...res, [item.KEY]: item }), {});
};

const actionSettings = state => {
  const { user } = state;
  if (!user) return null;

  const { actionSettings } = user;
  if (!actionSettings) return null;

  return actionSettings.reduce((res, item) => ({ ...res, [item.KEY]: item }), {});
};

const isSearch = (state, getters, rootState) => {
  if (
    rootState.chat.searchBoxFilter.constructor === Object &&
    Object.keys(rootState.chat.searchBoxFilter).length === 0
  )
    return false;
  const keys = Object.keys(rootState.chat.searchBoxFilter);
  for (const key of keys) {
    if (rootState.chat.searchBoxFilter[key].length > 0) return true;
  }

  return false;
};

const convsLabelsMNSearch = (state, getters) => {
  let object = {};
  const { convsLabelMNCondition } = state;

  const { tagMaps } = getters;

  if (
    !tagMaps ||
    Object.keys(tagMaps).length === 0 ||
    !convsLabelMNCondition ||
    Object.keys(convsLabelMNCondition).length === 0
  )
    return object;
  const { projectId, searchText } = convsLabelMNCondition;
  if (!projectId) return object;
  const convsLabelsProjectId = tagMaps[projectId];

  if (!convsLabelsProjectId || Object.keys(convsLabelsProjectId).length === 0) return object;

  if (searchText === '') return convsLabelsProjectId;

  return pickBy(convsLabelsProjectId, function (value) {
    return `${value['value']}`.toLowerCase().includes(searchText.toLowerCase());
  });
};

const usersLabelsMNSearch = (state, getters) => {
  let object = {};
  const { usersLabelsMNCondition } = state;

  const { labelMaps } = getters;

  if (
    !labelMaps ||
    Object.keys(labelMaps).length === 0 ||
    !usersLabelsMNCondition ||
    Object.keys(usersLabelsMNCondition).length === 0
  )
    return object;
  const { projectId, searchText } = usersLabelsMNCondition;
  if (!projectId) return object;
  const usersLabelsProjectId = labelMaps[projectId];

  if (!usersLabelsProjectId || Object.keys(usersLabelsProjectId).length === 0) return object;
  if (searchText === '') return usersLabelsProjectId;

  return pickBy(usersLabelsProjectId, function (value) {
    return `${value['value']}`.toLowerCase().includes(searchText.toLowerCase());
  });
};

const projectsExportPermission = state => {
  const { user, projectMaps } = state;
  if (isEmpty(projectMaps)) {
    return [];
  }
  let projects = Object.values(projectMaps);
  let projectIds = Object.keys(projectMaps);
  const mappingExportPermission = {
    Leader: 'isAllowSupervisor',
    Moderator: 'isAllowLeader',
    Regular: 'isAllowRegular'
  };
  projects = projects.filter((project, index) => {
    project.id = projectIds[index];
    if (user.role === AGENT_ROLE.CLIENT) {
      return project;
    }
    if (!project.config.accessPermission || !mappingExportPermission[user.role]) {
      return project;
    }
    let { exportReport } = project.config.accessPermission;
    if (
      !exportReport ||
      (exportReport && exportReport[mappingExportPermission[user.role]] !== false)
    ) {
      return project;
    }
  });
  return projects;
};

const channelsMessenger = state => {
  const { channels } = state;
  if (!channels.length) return [];
  return channels.filter(
    ({ platform, configs }) => !(platform === 'instagram' && !configs.instagramMessenger)
  );
};

const domainWebview = state => {
  const { projectMaps } = state;
  if (isEmpty(projectMaps)) return [];

  return Object.keys(projectMaps).reduce((obj, item) => {
    const project = projectMaps[item];
    if (isEmpty(project)) return false;
    const { personalInfo: { url: pUrl = null } = {}, surveyForm: { url: sUrl = null } = {} } =
      project.projectConfig || {};
    const lineDomain = 'liff.line.me';
    const result = new Set();
    result.add(lineDomain);
    if (pUrl) result.add(new URL(pUrl).hostname);
    if (sUrl) result.add(new URL(sUrl).hostname);
    return {
      ...obj,
      [item]: [...result]
    };
  }, {});
};

export {
  me,
  isAuthenticated,
  isPhoneAgent,
  configs,
  authStatus,
  token,
  getAgentUser,
  tickets,
  conversations,
  conversationsMap,
  conversationsByBot,
  commonWaitingList,
  meWaitingList,
  monitoringList,
  conversationsByAgents,
  socialLinks,
  groupMaps,
  meGroups,
  notificationSettings,
  actionSettings,
  tagMaps,
  labelMaps,
  ticketTagMaps,
  isSearch,
  usersLabelsMNSearch,
  convsLabelsMNSearch,
  projectsExportPermission,
  channelsMessenger,
  domainWebview
};
