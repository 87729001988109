<template>
  <ValidationObserver ref="report">
    <div class="container-fluid p-0 page quycss">
      <AppLeftMenu show-name="report" />

      <main class="profile-page report">
        <AppCover />
        <div class="page-sub">
          <section>
            <div class="csh-container page-cover-container">
              <!-- Title -->
              <b-row>
                <b-col sm="12">
                  <h2>{{ $t('src.modules.report.components.index.report') }}</h2>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col sm="12">
                  <form>
                    <div class="form-row">
                      <div class="form-group col-md-3 select-dth">
                        <base-select
                          :align="'vertical'"
                          v-model="projectSel"
                          :options="projectList"
                          :option-value="'value'"
                          :option-lable="'text'"
                          :name="'projectSel'"
                          :label="$t('src.modules.report.components.index.project')"
                          :required="true"
                          :value-type="'String'"
                          @change="handleChangeTypeExport"
                        />
                      </div>
                      <div class="form-group col-md-3 select-dth">
                        <base-select
                          :align="'vertical'"
                          v-model="channelSel"
                          :options="channelList"
                          :option-value="'value'"
                          :option-lable="'text'"
                          :name="'channelSel'"
                          :label="$t('src.modules.report.components.index.channel')"
                          :required="true"
                          :value-type="'String'"
                          @change="handleChangeTypeExport"
                        />
                      </div>
                      <div class="form-group col-md-3 select-dth">
                        <base-select
                          :align="'vertical'"
                          v-model="typeSel"
                          :options="typeList"
                          :option-value="'value'"
                          :option-lable="'text'"
                          :name="'typeSel'"
                          :label="$t('src.modules.report.components.index.report_name')"
                          :required="true"
                          :value-type="'String'"
                          @change="handleChangeTypeExport"
                        />
                      </div>
                      <div
                        v-if="typeSel === 'conversationLog'"
                        class="form-group col-md-3 select-dth"
                      >
                        <base-select
                          :align="'vertical'"
                          v-model="typeSelCnvLog"
                          :options="typeListCnvLog"
                          :option-value="'value'"
                          :option-lable="'text'"
                          :name="'typeSelCnvLog'"
                          :label="$t('src.modules.report.components.index.type')"
                          :required="true"
                          :value-type="'String'"
                          @change="handleChangeTypeExport"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-3 select-dth">
                        <base-select
                          class="nat-date-range-schedule"
                          :align="'vertical'"
                          v-model="selectScheduleType"
                          :options="scheduleTypes"
                          :option-value="'value'"
                          :option-lable="'label'"
                          :name="'selectScheduleType'"
                          :label="
                            $t(
                              'src.modules.report.components.cnv-scheduled-export.index.schedule_type'
                            )
                          "
                          :required="true"
                          :value-type="'String'"
                        />
                      </div>
                      <div class="form-group col-md-3 select-dth">
                        <template v-if="selectScheduleType == 'immediate'">
                          <base-date-picker-range
                            class="nat-date-range-schedule"
                            :align="'vertical'"
                            v-model="scheduleDateRange"
                            type="datetimerange"
                            :label="
                              $t(
                                'src.modules.report.components.cnv-scheduled-export.index.date_range'
                              )
                            "
                            :picker-options="pickerOptions"
                            :rules="'birthday'"
                            :required="true"
                          />
                        </template>
                        <template v-if="selectScheduleType == 'weekly'">
                          <base-select
                            class="nat-date-range-schedule"
                            :align="'vertical'"
                            v-model="selectDayNameOfWeek"
                            :options="dayNamesOfWeek"
                            :option-value="'value'"
                            :option-lable="'label'"
                            :name="'selectDayNameOfWeek'"
                            :label="
                              $t(
                                'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.exported_date'
                              )
                            "
                            :required="true"
                            :value-type="'String'"
                          />
                        </template>
                        <template v-if="selectScheduleType == 'monthly'">
                          <base-select
                            class="nat-date-range-schedule"
                            v-model="selectDayOfMonth"
                            :align="'vertical'"
                            :disabled="true"
                            :options="dayOfMonth"
                            :option-value="'value'"
                            :option-lable="'label'"
                            :name="'selectDayOfMonth'"
                            :label="
                              $t(
                                'src.modules.report.components.cnv-scheduled-export.index.monthly_schedule.exported_date'
                              )
                            "
                            :required="true"
                            :value-type="'String'"
                          />

                          <!-- Hint -->
                          <label
                            v-if="selectScheduleType == 'weekly' || selectScheduleType == 'monthly'"
                            style="width: 100%; font-size: 0.8rem; text-transform: initial"
                            ><i
                              >*
                              {{
                                $t(
                                  'common.text.report.hint.please_take_note_that_the_report_will_be_ready_on_the_next_day_of_the_report_end_date'
                                )
                              }}</i
                            ></label
                          >
                        </template>
                      </div>
                      <div class="form-group col-md-3 select-dth">
                        <base-select
                          class="nat-date-range-schedule"
                          :align="'vertical'"
                          v-model="selectFileFormat"
                          :options="fileFormat"
                          :option-value="'value'"
                          :option-lable="'text'"
                          :name="'selectFileFormat'"
                          :label="$t('src.modules.report.components.common.index.file_format')"
                          :required="true"
                          :value-type="'String'"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-12 select-dth">
                        <b-button
                          variant="primary"
                          @click="setExportCustomerSchedule()"
                          class="nat-btn-add_schedule"
                        >
                          {{
                            $t(
                              'src.modules.report.components.cnv-scheduled-export.index.add_schedule'
                            )
                          }}
                        </b-button>
                        <el-button type="default" @click="syncAllData()" class="nat-btn-refresh">
                          {{ $t('src.modules.report.components.index.title.refresh') }}
                        </el-button>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-3 d-flex align-items-end">
                        <b-button
                          variant="primary"
                          @click="handleClickDownLoad()"
                          v-show="exportMode === 'download'"
                          >{{ $t('src.modules.report.components.index.button.download') }}</b-button
                        >
                      </div>
                    </div>
                  </form>
                </b-col>
              </b-row>

              <b-row class="export-schedule-content">
                <b-col sm="12">
                  <ColumnFilterModal
                    v-if="viewAddon === 'download_conversationLog'"
                    ref="columnFilterModal"
                    :locale="this.language"
                    @clickOKButton="exportExcelFile"
                  ></ColumnFilterModal>
                  <MessageLogSchedule
                    v-if="viewAddon === 'schedule_messageLog'"
                    ref="modalCnvScheduledExport"
                    :message-log-scheduled-files="messageLogScheduledFiles"
                    :message-log-schedules="messageLogSchedules"
                    :channel-name="this.channelSelName"
                    :channel-id="this.channelSel"
                    :project-id="this.projectSel"
                    :platform="this.platformSel"
                    :locale="this.language"
                    :select-schedule-type="this.selectScheduleType"
                    :select-day-name-of-week="this.selectDayNameOfWeek"
                    :select-day-of-month="this.selectDayOfMonth"
                    :select-file-format="this.selectFileFormat"
                    :schedule-date-range="this.scheduleDateRange"
                    @addMsgLogExportSchedule="addMsgLogExportSchedule"
                    @deleteMsgLogExportSchedule="deleteMsgLogExportSchedule"
                  ></MessageLogSchedule>
                  <ConversationLogSchedule
                    v-if="viewAddon === 'schedule_conversationLog'"
                    :channel-name="this.channelSelName"
                    :channel-id="this.channelSel"
                    :project-id="this.projectSel"
                    :platform="this.platformSel"
                    :locale="this.language"
                    :report-type="typeSelCnvLog"
                    :select-schedule-type="this.selectScheduleType"
                    :select-day-name-of-week="this.selectDayNameOfWeek"
                    :select-day-of-month="this.selectDayOfMonth"
                    :select-file-format="this.selectFileFormat"
                    :schedule-date-range="this.scheduleDateRange"
                  ></ConversationLogSchedule>
                  <CommentLogSchedule
                    v-if="viewAddon === 'schedule_comment'"
                    :channel-name="this.channelSelName"
                    :channel-id="this.channelSel"
                    :project-id="this.projectSel"
                    :platform="this.platformSel"
                    :locale="this.language"
                    :select-schedule-type="this.selectScheduleType"
                    :select-day-name-of-week="this.selectDayNameOfWeek"
                    :select-day-of-month="this.selectDayOfMonth"
                    :select-file-format="this.selectFileFormat"
                    :schedule-date-range="this.scheduleDateRange"
                  ></CommentLogSchedule>
                  <SurveyLogSchedule
                    v-if="viewAddon === 'schedule_surveyLog'"
                    :channel-name="this.channelSelName"
                    :channel-id="this.channelSel"
                    :project-id="this.projectSel"
                    :locale="this.language"
                    :platform="this.platformSel"
                    :select-schedule-type="this.selectScheduleType"
                    :select-day-name-of-week="this.selectDayNameOfWeek"
                    :select-day-of-month="this.selectDayOfMonth"
                    :select-file-format="this.selectFileFormat"
                    :schedule-date-range="this.scheduleDateRange"
                  ></SurveyLogSchedule>
                </b-col>
              </b-row>
            </div>
          </section>
        </div>
        <AppFooter />
      </main>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import { EventBus } from 'core/eventBus';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import ColumnFilterModal from './components/ColumnFilterModal';
import ConversationLogSchedule from './components/ConversationLogSchedule';
import CommentLogSchedule from './components/CommentLogSchedule';
import MessageLogSchedule from './components/MessageLogSchedule';
import SurveyLogSchedule from './components/SurveyLogSchedule';
import {
  CHANNEL_MODE,
  FORMAT_TIME_IMMEDIATE_REPORT,
  LOG_REPORT_TYPE,
  REPORT_SCHEDULE_TYPE,
  RESULT_REPORT_TYPE
} from 'core/constants';
import moment from 'moment';
import * as defaultParams from './store/define';
import { getAgentName } from 'core/helpers';
export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    ColumnFilterModal,
    MessageLogSchedule,
    ConversationLogSchedule,
    CommentLogSchedule,
    SurveyLogSchedule
  },

  data() {
    return {
      ...defaultParams.schedule,
      scheduleDateRange: [
        moment().set({ hour: 0, minute: 0 }),
        moment().set({ hour: 23, minute: 59 })
      ],
      typeSel: 'comment',
      typeSelCnvLog: '',
      projectSel: '',
      channelSel: '',
      channelsFilter: [],
      datePickerType: 'month',
      datePickerFormat: '',
      datePickerPlaceholder: ' ',
      datePickerValue: new Date(),
      exportType: 'Export',
      exportKey: '',
      agentGroups: [],
      cnvPopupChannelName: '',
      cnvPopupChannelId: '',
      commentIntervalId: Number,
      exportMode: 'schedule',
      selectFileFormat: 'excel',
      typeList: [],
      typeListCnv: [
        {
          value: 'BOTCONVERSATIONLOG',
          text: this.$t('src.modules.report.components.index.bot_only')
        },
        {
          value: 'AGENTCONVERSATIONLOG',
          text: this.$t('src.modules.report.components.index.agent_only')
        },
        {
          value: 'CONVERSATIONLOG',
          text: this.$t('src.modules.report.components.index.bot_agent')
        }
      ],
      reportTypeList: [
        {
          value: 'schedule',
          text: this.$t('src.modules.report.components.index.button.schedule')
        }
      ],
      viewAddon: 'schedule_comment',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      fileFormat: [
        {
          value: 'excel',
          text: 'EXCEL'
        },
        {
          value: 'csv',
          text: 'CSV'
        }
      ],
      supportedTypes: [
        {
          value: 'comment',
          text: this.$t('src.modules.report.components.index.comment_log')
        },
        {
          value: 'messageLog',
          text: this.$t('src.modules.report.components.index.message_log')
        },
        {
          value: 'conversationLog',
          text: this.$t('src.modules.report.components.index.conversation_log')
        },
        {
          value: 'surveyLog',
          text: this.$t('src.modules.report.components.index.survey_log')
        }
      ]
    };
  },

  computed: {
    ...mapState('report', [
      'conversationLogReportFile',
      'messageLogReportData',
      'messageLogSchedules',
      'messageLogScheduledFiles',
      'channelsFilterMap',
      'commentReportData'
    ]),
    ...mapState('session', ['projectMaps', 'channelsMap']),
    ...mapState('session', ['user']),
    ...mapGetters('session', ['projectsExportPermission']),

    language() {
      return localStorage.getItem('language') || 'en-US';
    },

    isMaintain() {
      const channelAffected = Object.keys(this.channelsMap).filter(
        i => this.channelsMap[i].projectId === this.projectSel
      );

      let isValid = false;
      if (!channelAffected || channelAffected.length === 0) return isValid;
      for (let i = 0; i < channelAffected.length; i++) {
        const { mode = '' } = this.channelsMap[channelAffected[i]];
        if (mode === CHANNEL_MODE.MAINTAIN) isValid = true;
      }

      return isValid;
    },

    channelSelName() {
      let name = '';
      if (this.channelSel && this.channelSel != '') {
        const channel = this.channelList.find(channel => channel.value === this.channelSel);
        if (channel) {
          name = channel.text;
        }
      }
      return name;
    },

    channelTimezone() {
      const channel = this.channelSel;
      let tz =
        channel &&
        channel.advancedConfigs &&
        channel.advancedConfigs.general &&
        channel.advancedConfigs.general.timezone;
      return tz;
    },

    typeListCnvLog() {
      let list = this.typeListCnv;
      if (this.channelSel && this.channelSel != '') {
        const { advancedConfigs = {} } = this.channelsMap[this.channelSel];
        const { modulesActivation = {} } = advancedConfigs;
        const { bot = true } = modulesActivation;
        if (!bot) list = this.typeListCnv.filter(c => c.value !== 'BOTCONVERSATIONLOG');
      }
      return list;
    },

    projectList() {
      if (!this.projectsExportPermission.length) return [];
      let list = this.projectsExportPermission.map(project => {
        let channel = _.find(this.channelsMap, ['projectId', project.id]);
        let tz =
          channel &&
          channel.advancedConfigs &&
          channel.advancedConfigs.general &&
          channel.advancedConfigs.general.timezone;
        tz = tz ? '| (' + tz + ')' : '';
        return { value: project.id, text: project.name + tz };
      });

      return this.sortProperties(list);
    },

    channelList() {
      let list = [];
      if (this.projectSel === '') return list;

      const channels = _.filter(this.channelsMap, {
        projectId: this.projectSel
      });
      if (channels.length < 1) return list;

      list = channels.map(channel => ({
        value: channel.id,
        text: channel.name,
        icon: this.getPlatformHtmlIcon(channel.platform)
      }));
      return this.sortProperties(list);
    },

    platformSel() {
      const { platform = '' } = this.channelsFilterMap[this.channelSel] || {};
      return platform;
    },
  },

  watch: {
    projectSel() {
      if (this.channelList.length) {
        this.channelSel = this.channelList[0].value;
      } else {
        this.channelSel = '';
      }      
    },
    projectMaps(data) {
      if (data && Object.keys(data).length > 0 && !this.projectsExportPermission.length) {
        return this.$router.push('/');
      }
      return data;
    },
    projectList(data) {
      if (data && data.length > 0 && this.projectSel === '') {
        this.projectSel = data[0].value;
      }
    },
    channelSel() {
      this.typeList = this.fetchTypeList();
      if (!Object.values(this.typeList).find(({ value }) => value === this.typeSel)) {
        this.typeSel = this.typeList[0].value;
      }
    },
    typeSel() {
      if (this.typeSel === 'conversationLog') {
        const selected =
          this.typeListCnvLog.find(({ value }) => value === this.typeSelCnvLog) ||
          this.typeListCnvLog[0];
        this.typeSelCnvLog = selected.value;
      }
      this.viewAddon = 'schedule_' + this.typeSel;
    },
    channelList(channels) {
      if (!channels.length) this.channelSel = '';
      else if (this.channelSel !== channels[0].value) {
        this.channelSel = channels[0].value;
      }
    },

    async conversationLogReportFile(result) {
      this.getFileFromResData(result);
    },

    async commentReportData(result) {
      this.getFileFromResData(result);
    },

    async messageLogReportData(result) {
      this.getFileFromResData(result);
    }
  },

  async created() {
    this.channelsFilter = await this.getChannels();
    await this.setChannelsFilterMap(this.channelsFilter);
  },

  mounted() {
    if (this.projectSel === '' && this.projectList.length > 0) {
      this.projectSel = this.projectList[0].value;
    }
  },

  methods: {
    ...mapActions('session', ['getSessionUser', 'getChannels']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('report', [
      'getConversationLogReportFile',
      'getMessageLogReportFile',
      'getCommentReportFile',
      'getMessageLogReportSchedules',
      'getMessageLogReportScheduleFiles',
      'addNewMessageLogReportSchedule',
      'removeMessageLogReportSchedule',
      'setChannelsFilterMap',
      'createLogForExport'
    ]),
    switchMode() {
      if (this.exportMode === 'schedule') {
        this.viewAddon = 'schedule_' + this.typeSel;
      }
      // eslint-disable-next-line
      console.log('viewAddon switchMode', this.viewAddon);
    },
    getFileFromResData(result) {
      let fail = true;
      let empty = true;
      if (result) {
        if (result.status === 'success' && result.downloadUrl) {
          fail = false;
          if (result.downloadUrl === 'empty_link') {
            empty = true;
          } else {
            empty = false;
            window.location.href = result.downloadUrl;
          }
        }
      }
      if (fail) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.report.components.index.occur_error_exporting')
        });
      } else {
        if (empty) {
          this.$baseNotification.info({
            title: this.$t('src.core.App.info'),
            message: this.$t('src.modules.report.components.index.no_data_to_export')
          });
        }
      }
    },

    handleChangeTypeExport() {
      this.exportMode = 'schedule';
      this.switchMode();
    },

    handleClickDownLoad() {
      if (this.isMaintain) {
        EventBus.$emit('maintenanceModeModal');
        return true;
      }

      this.setGlobalReady(false);
      if (!this.validationExport()) return this.setGlobalReady(false);
      const type = this.typeSel;
      const def = function () {
        this.viewAddon = 'download_' + type;
      }.bind(this);
      const action = {
        comment: this.handleClickExportCommentLog,
        messageLog: this.handleClickExportMessageLog
      };
      this.setGlobalReady(true);
      return action[type] ? action[type]() : def();
    },

    async addMsgLogExportSchedule(schedule) {
      this.setGlobalReady(false);

      const { firstName = '', lastName = '' } = this.user;
      const user_name =
        firstName && lastName
          ? `${firstName} ${lastName}`
          : firstName
          ? firstName
          : lastName
          ? lastName
          : '';

      const from_date = moment(schedule[0]).format(FORMAT_TIME_IMMEDIATE_REPORT.EXPORT.START_DATE);
      const to_date = moment(schedule[1]).format(FORMAT_TIME_IMMEDIATE_REPORT.EXPORT.END_DATE);
      const agent_id = this.user.id;
      const channel_id = this.channelSel ? this.channelSel : '';
      const project_id = this.projectSel ? this.projectSel : '';
      const type = 'immediate';

      const data = {
        userId: agent_id,
        userName: user_name,
        channelId: channel_id,
        projectId: project_id,
        type,
        system: 'at',
        fromDate: from_date,
        toDate: to_date,
        language: this.language,
        exportFormat: this.selectFileFormat
      };

      const { email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
      const actionLogCreate = {
        userId: agent_id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        channelId: channel_id,
        projectId: project_id,
        type: REPORT_SCHEDULE_TYPE.IMMEDIATE,
        fromDate: from_date,
        toDate: to_date,
        exportFormat: this.selectFileFormat,
        channelName: this.channelSelName,
        platform: this.platformSel,
        reportType: LOG_REPORT_TYPE.MESSAGE_LOG,
        system: 'at'
      };

      const result = await this.addNewMessageLogReportSchedule(data);
      if (result) {
        await this.getMessageLogReportSchedules({
          userId: this.user.id,
          channelId: channel_id,
          language: this.language
        });
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t('src.modules.report.components.index.conversation_export_successful')
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.SUCCESS
        });
      } else {
        const messErr = this.$t('src.modules.report.components.index.check_file_tab_to_download');
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: messErr
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: messErr
        });
      }
      this.setGlobalReady(true);
    },

    async deleteMsgLogExportSchedule(schedule_id) {
      this.setGlobalReady(false);
      const result = await this.removeMessageLogReportSchedule({
        id: schedule_id,
        userId: this.user.id
      });
      if (result) {
        const channel_id = this.channelSel ? this.channelSel : '';
        await this.getMessageLogReportSchedules({
          userId: this.user.id,
          channelId: channel_id,
          language: this.language
        });
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.report.components.index.remove_converstion_schedule_successful'
          )
        });
      }
      this.setGlobalReady(true);
    },

    async exportExcelFile(selected_columns, selected_columns_name) {
      if (!selected_columns.length > 0) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.report.components.index.select_least_one_column')
        });
        return false;
      }
      this.setGlobalReady(false);
      let selectDate = this.datePickerValue;
      let year = new Date(selectDate).getFullYear();
      let month = new Date(selectDate).getMonth() + 1;
      let channel_id = this.channelSel ? this.channelSel : '';
      await this.getConversationLogReportFile({
        year,
        month,
        channel_id,
        selected_columns,
        selected_columns_name,
        timezone: this.channelTimezone,
        language: this.language,
        reportType: this.typeSelCnvLog
      });
      this.setGlobalReady(true);
    },

    sortProperties(arrValue) {
      if (!Array.isArray(arrValue)) return [];
      arrValue.sort(function (a, b) {
        let x = a.text.toLowerCase(),
          y = b.text.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue;
    },

    async handleClickExportMessageLog() {
      if (!this.validationExport()) return this.setGlobalReady(false);
      this.viewAddon = 'download_' + this.typeSel;

      this.setGlobalReady(false);
      let selectDate = this.datePickerValue;
      let year = new Date(selectDate).getFullYear();
      let month = new Date(selectDate).getMonth() + 1;
      let channel_id = this.channelSel ? this.channelSel : '';

      await this.getMessageLogReportFile({
        year,
        month,
        channel_id,
        timezone: this.channelTimezone
      });
      this.setGlobalReady(true);
    },

    async openConversationScheduleModal() {
      if (!this.validateScheduleExport()) return;
      this.viewAddon = 'schedule_' + this.typeSel;

      this.setGlobalReady(false);
      const channel_id = this.channelSel ? this.channelSel : '';
      const channel_name = this.channelSelName;

      this.cnvPopupChannelName = channel_name;
      this.cnvPopupChannelId = channel_id;
      await this.getMessageLogReportSchedules({
        userId: this.user.id,
        channelId: channel_id,
        language: this.language
      });
      await this.getMessageLogReportScheduleFiles({
        userId: this.user.id,
        channelId: channel_id
      });
      this.setGlobalReady(true);
    },

    validationExport() {
      if (!this.channelSel || this.channelSel === '') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.report.components.index.select_channel_for_export')
        });
        return false;
      }

      if (!this.datePickerValue || this.datePickerValue === '') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.datePickerPlaceholder
        });
        return false;
      }
      return true;
    },

    async handleClickExportCommentLog() {
      if (!this.validationCommentExport()) return;
      this.viewAddon = 'download_' + this.typeSel;
      this.setGlobalReady(false);
      const channel_id = this.channelSel ? this.channelSel : '';
      const selectDate = this.datePickerValue;
      const year = new Date(selectDate).getFullYear();
      const month = new Date(selectDate).getMonth() + 1;
      await this.getCommentReportFile({
        channel_id,
        year,
        month,
        timezone: this.channelTimezone
      }).then(res => res);
      this.setGlobalReady(true);
    },

    validationCommentExport() {
      if (!this.channelSel || this.channelSel === '') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.report.components.index.select_channel_for_export')
        });
        return false;
      }

      if (!this.datePickerValue || this.datePickerValue === '') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.datePickerPlaceholder
        });
        return false;
      }
      return true;
    },

    validateScheduleExport() {
      if (!this.channelSel || this.channelSel === '') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.report.components.index.select_channel_converstion_scheduled_export'
          )
        });
        return false;
      }
      return true;
    },

    syncAllData() {
      EventBus.$emit('syncAllDataReport');
    },

    setExportCustomerSchedule() {
      this.$refs.report.validate().then(async submit => {
        if (!submit) {
          this.errorMessage();
          return false;
        } else {
          EventBus.$emit('setExportCustomerSchedule');
        }
      });
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    fetchTypeList() {
      let reportTypes = this.supportedTypes;
      const channelList = _.filter(this.channelsMap, {
        projectId: this.projectSel
      }).filter(({ id, platform, configs }) => {
        if (this.channelSel && this.channelSel !== id) return false;
        if (platform === 'instagram' && ['comment', 'messageLog'].includes(this.typeSel)) {
          return (
            (this.typeSel === 'comment' && configs.instagramComment) ||
            (this.typeSel === 'messageLog' && configs.instagramMessenger)
          );
        }
        if (this.typeSel === 'comment') {
          return ['instagram', 'facebook'].includes(platform);
        }
        if (this.typeSel === 'surveyLog') {
          return !['instagram', 'zalo'].includes(platform);
        }
        return true;
      });
      const isShowCommentReport = channelList.some(({ platform }) =>
        ['facebook', 'instagram'].includes(platform)
      );
      // eslint-disable-next-line
      console.log('isShowCommentReport', isShowCommentReport);
      if (!isShowCommentReport) {
        reportTypes = reportTypes.filter(({ value }) => value !== 'comment');
      }

      if (['instagram', 'zalo'].includes(this.platformSel)) {
        reportTypes = reportTypes.filter(({ value }) => value !== 'surveyLog');
      }
      return reportTypes;
    },

    getPlatformHtmlIcon(platform) {
      switch (platform) {
        case 'webchat':
          return `<img src="${require('@/assets/images/icons/webchat-logo.png')}" width="14" height="14"/>`;
        case 'liffchat':
          return `<img src="${require('@/assets/images/icons/liffchat.png')}" width="14" height="14"/>`;
        case 'facebook':
        case 'external_facebook':
          return '<i class="fab fa-facebook" style="color: #0d47a1;"/>';
        case 'line':
        case 'external_line':
          return '<i class="fab fa-line" style="color: #3ac521;"/>';
        case 'zalo':
          return `<img src="${require('@/assets/images/icons/zalo-min.png')}" width="14" height="14"/>`;
        case 'instagram':
          return `<i class="fab fa-instagram" style="
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;"/>`;
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 1300px) {
  .report {
    .page-cover-container {
      .form-row {
        .el-range-input {
          font-size: 11px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1320px) {
  .report {
    .page-cover-container {
      .form-row {
        .el-range-input {
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1321px) and (max-width: 1450px) {
  .report {
    .page-cover-container {
      .form-row {
        .el-range-input {
          font-size: 13px;
        }
      }
    }
  }
}

@import 'assets/scss/chat/chat.scss';
</style>

<style lang="scss" scoped>
/deep/ .nat-date-picker-range-verti {
  /deep/ .el-date-editor--datetimerange {
    width: 100%;
    /deep/ .el-range-input {
      font-size: 0.875rem;
    }
  }
}

/deep/.is-checked .el-radio__inner {
  border-color: #fb6340 !important;
  background: #fb6340 !important;
}

/deep/.el-radio__label {
  font-weight: bold !important;
  color: #525f7f !important;
}
.report {
  font-size: 14px !important;
  overflow-y: auto !important;
}

.section-profile-cover {
  height: 100px !important;
}

.page-header {
  text-align: center;
}

.page-cover-container {
  margin: 0 50px 50px 50px;
}

body {
  overflow-y: auto !important;
  font-size: 12px !important;
}

tbody td {
  width: 12.5%;
  float: left;
  overflow-wrap: break-word;
}
tbody {
  overflow-wrap: auto;
}

thead th {
  width: 12.5%;
  float: left;
  height: 70px;
}

.card-body {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

/deep/ .el-date-editor.el-input {
  width: 100% !important;
}
/deep/.report .input-group-text {
  min-width: 100px;
}
/deep/.report .form-control .custom-select {
  padding-left: 10px !important;
}
/deep/.report .input-group .form-control:not(:first-child) {
  padding-left: 10px !important;
}

.profile-page {
  height: 100%;
}

.select-dth {
  /deep/ label {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  /deep/ .nat-select-hori {
    /deep/.el-input__inner {
      font-size: 0.875rem;
    }
  }

  .nat-date-range-schedule {
    /deep/.el-input__inner {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss">
#modal-select-expored-columns .modal-dialog {
  min-width: 1000px;
  width: 1000px;
}

#modal-cnv-schedule-export .modal-dialog {
  min-width: 1000px;
  width: 1000px;
}

.export-button {
  &.right {
    .el-icon-date {
      font-size: 25px;
    }
  }
}
</style>

<style lang="scss">
.export-schedule-content {
  width: 100%;
  margin-top: 10px;

  .modal-content {
    margin-bottom: 30px;
    &.div {
      box-shadow: none !important;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
      border-radius: 0.3rem !important;
    }
  }

  .list-columns-export {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .sdt-sortable-btn.sdt-sort-button {
    width: 40px;
  }
  .sdt-footer p {
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 16px;
  }
  .sdt-footer a {
    text-decoration: none;
  }
  .flip-list-move {
    transition: transform 0.3s;
  }
  .flip-list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .flip-list-enter-active,
  .flip-list-leave-active {
    transition: all 0.3s;
  }
  .flip-list-enter,
  .flip-list-leave-to {
    opacity: 0;
    transform: translateY(-50px);
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .dragArea {
    min-height: 500px;
    padding: 2px 15px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 535px;
  }
  .mdl-grid.center-items {
    justify-content: center;
  }
  .panel-body {
    margin-left: 10px;
    margin-bottom: -50px;
  }
  .forkme {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    width: 150px;
  }
  .sdt-tag-icon {
    cursor: pointer;
  }
  .sdt-tag-icon-left {
    margin-right: 10px;
  }
  .sdt-tag-icon-right {
    margin-left: 10px;
  }
  .sdt-sortable-btn {
    margin: 10px 10px !important;
  }
  .sdt-json-section {
    padding: 0px 13px;
    height: 557px;
  }
  .sdt-json-section:hover {
    overflow: auto !important;
  }
  .sdt-json-pre {
    font-family: monospace;
    font-weight: lighter;
    font-size: 12px;
  }
  .sdt-header {
    text-align: center;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 45px;
    margin-top: 30px;
  }
  .sdt-control-tag {
    visibility: hidden !important;
    width: 5px !important;
    background: transparent !important;
    box-shadow: none !important;
    color: transparent !important;
    margin-bottom: -64px !important;
  }
  .sdt-sort-button {
    float: right;
    float: right;
    z-index: 9;
    bottom: -29px;
    left: 14px;
    width: 100%;
  }
  .sdt-tag {
    cursor: -webkit-grab !important;
    padding: 8px !important;
    min-height: 5px !important;
  }
  .sdt-tag:hover {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.2);
  }
  .sdt-color-red {
    fill: #f44336;
    color: #f44336;
  }
  .sdt-color-gray {
    fill: #e0e0e0;
    color: #e0e0e0;
  }
  .sdt-color-teal {
    fill: #009688;
    color: #009688;
  }
  .sdt-color-indigo {
    fill: #3f51b5;
    color: #3f51b5;
  }
  .sdt-teal-switch > .mdl-switch.is-checked .mdl-switch__thumb {
    background-color: #009688 !important;
  }
  .sdt-teal-switch > .mdl-switch.is-checked .mdl-switch__track {
    background: rgba(0, 150, 136, 0.51) !important;
  }
  .mdl-switch__ripple-container .mdl-ripple {
    background: #009688 !important;
  }
  @media (min-width: 840px) {
    .sdt-json-section {
      top: 93px;
    }
  }

  label.mdl-switch {
    text-align: center;
    font-weight: 550;
  }

  label.mdl-switch span.mdl-switch__label {
    left: 0px;
    cursor: default;
    font-size: 16px;

    .export-column,
    .remove-column {
      color: inherit;
    }
  }

  #sdt-available-tags {
    height: 600px;
    padding-right: 10px;
  }

  button.sdt-removeable-btn {
    float: left;
    margin-top: 40px;
    margin-bottom: -50px;
    z-index: 9;
    position: relative;
  }

  #sdt-selected-tags div.mdl-color--teal-500 {
    background-color: #dedfde !important;
  }

  #sdt-available-tags div.mdl-color--indigo-500 {
    background-color: #dedfde !important;
  }

  .modal-body {
    padding-top: 15px !important;
    .nat-date-range-schedule {
      .nat-select-hori {
        .el-select {
          width: 100% !important;
          margin-left: 0px;
          margin-right: 0px;
        }

        .el-input__inner {
          font-size: 1rem;
        }
      }
    }
    .nat-select-hori {
      .el-select {
        margin-left: 0px;
        margin-right: 0px;
      }

      .el-input__inner {
        font-size: 1rem;
      }
    }
    label {
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  li.mdl-list__item span.mdl-list__item-primary-content {
    font-size: 14px;
    font-family: inherit;
  }

  .btn.btn-secondary {
    border-color: #ddd;
    color: #212529;
    background-color: white;
  }

  div.el-table__body-wrapper {
    height: 350px;
  }

  div.el-row:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  button.el-button.el-button--text {
    padding: 0px !important;
    margin-left: 0px !important;
  }

  .rowSelect {
    margin-left: 0px;
  }

  .el-button--default {
    border-color: #878282;
    color: #212529;
    background-color: #fff;
    &:hover {
      border-color: #878282;
      background-color: #fff;
    }
  }
}
</style>
