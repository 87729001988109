<template>
  <b-card no-body>
    <div class="chat-header p-10 pre-formatted">{{ title }}</div>
    <b-list-group flush>
      <b-badge
        v-for="(btn, index) in buttons"
        :key="`fb_tmpl_btn_${index + 1}`"
        :style="btn.image_url ? { 'text-align': 'left' } : ''"
        variant="secondary"
        pill
        class="list-group-item"
      >
        <img
          v-if="btn.image_url"
          :src="btn.image_url"
          style="height: 20px; width: 20px; margin-right: 6px"
        />
        {{ btn.title }}
      </b-badge>
    </b-list-group>
  </b-card>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    title() {
      return this.message.text;
    },

    buttons() {
      return this.message.buttons || [];
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.list-group-item {
  font-size: 12px;
  margin-bottom: 0;
  border: 0;
  border-bottom: 0.06rem solid #e9ecef;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/.badge {
  text-transform: unset !important;
}
.chat-header {
  background-color: #2196f3;
  border-radius: calc(0.25rem - 0.0625rem) calc(0.25rem - 0.0625rem) 0 0;
  margin-bottom: 0;
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.05);
}
.pre-formatted {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
