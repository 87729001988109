var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":(_vm.name + "-" + _vm.prefix),"name":(_vm.name + "-" + _vm.prefix),"rules":_vm.c_rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.align === 'horizontal')?_c('div',[_c('div',{staticClass:"nat-texarea-group-prepend"},[_c('div',{staticClass:"nat-texarea-group-text",style:(_vm.c_labelWidthStyle)},[(
            (_vm.c_contentLength > 0 && _vm.showWordLimit) ||
              _vm.showLabel === true ||
              _vm.tooltipText !== ''
          )?_c('label',[(_vm.required)?_c('span',{staticClass:"nat-required"},[_vm._v("*")]):_vm._e(),(_vm.label !== '' && _vm.showLabel === true)?_c('span',[_vm._v(" "+_vm._s(_vm.label))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"nat-texarea-group-tooltip"},[_c('base-tooltip-icon',{attrs:{"id":("tooltip-" + _vm.name + "-" + _vm.prefix),"name":("tooltip-" + _vm.name + "-" + _vm.prefix),"content":_vm.tooltipText,"icon":_vm.tooltipIcon}})],1)]),_c('div',{staticClass:"nat-texarea-group-value"},[_c('div',{staticClass:"nat-texarea-group-value-maxlength"},[(_vm.c_contentLength > 0 && _vm.showWordLimit)?_c('span',{class:['nat-maxlengthTag']},[_c('span',{class:[
                ((_vm.maxlength - _vm.c_contentLength < 0 || _vm.c_contentLength - _vm.minlength <= 0
                    ? 'error'
                    : '') + " ")
              ]},[_vm._v(" "+_vm._s(_vm.c_contentLength))]),_vm._v(" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()]),(_vm.vueTribute)?_c('vue-tribute',{key:_vm.tributeKeys,attrs:{"options":_vm.tributeOptions}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-texarea-hori",attrs:{"type":"textarea","name":(_vm.name + "-" + _vm.prefix),"disabled":_vm.disabled,"id":(_vm.name + "-" + _vm.prefix),"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"maxlength":_vm.maxlength,"rows":_vm.rows},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}],"change":function($event){return _vm.handleChange(_vm.content)},"keyup":_vm.handleKeyup}})]):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-texarea-hori",attrs:{"type":"textarea","name":(_vm.name + "-" + _vm.prefix),"disabled":_vm.disabled,"id":(_vm.name + "-" + _vm.prefix),"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"maxlength":_vm.maxlength,"rows":_vm.rows},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}],"change":function($event){return _vm.handleChange(_vm.content)},"keyup":_vm.handleKeyup}})],1)]),(errors[0] !== 'hidden')?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.hintText !== '')?_c('div',{staticClass:"nat-hint-text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]):_vm._e(),(_vm.align === 'vertical')?_c('div',[(
        (_vm.c_contentLength > 0 && _vm.showWordLimit) ||
          _vm.showLabel === true ||
          _vm.tooltipText !== ''
      )?_c('label',[(_vm.required)?_c('span',{staticClass:"nat-required"},[_vm._v("*")]):_vm._e(),(_vm.label !== '' && _vm.showLabel === true)?_c('span',[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),_c('base-tooltip-icon',{attrs:{"id":("tooltip-" + _vm.name + "-" + _vm.prefix),"name":("tooltip-" + _vm.name + "-" + _vm.prefix),"content":_vm.tooltipText,"icon":_vm.tooltipIcon}}),(_vm.c_contentLength > 0 && _vm.showWordLimit)?_c('span',{class:['nat-maxlengthTag']},[_c('span',{class:[
            ((_vm.maxlength - _vm.c_contentLength < 0 || _vm.c_contentLength - _vm.minlength <= 0
                ? 'error'
                : '') + " ")
          ]},[_vm._v(" "+_vm._s(_vm.c_contentLength))]),_vm._v(" / "+_vm._s(_vm.maxlength)+" ")]):_vm._e()],1):_vm._e(),(_vm.vueTribute)?_c('vue-tribute',{key:_vm.tributeKeys,attrs:{"options":_vm.tributeOptions}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-texarea-verti",attrs:{"type":"textarea","disabled":_vm.disabled,"name":(_vm.name + "-" + _vm.prefix),"id":(_vm.name + "-" + _vm.prefix),"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"maxlength":_vm.maxlength,"rows":_vm.rows},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}],"change":function($event){return _vm.handleChange(_vm.content)},"keyup":_vm.handleKeyup}})]):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"nat-texarea-verti",attrs:{"type":"textarea","disabled":_vm.disabled,"name":(_vm.name + "-" + _vm.prefix),"id":(_vm.name + "-" + _vm.prefix),"placeholder":_vm.disabled ? '' : _vm.c_placeholder,"maxlength":_vm.maxlength,"rows":_vm.rows},domProps:{"value":(_vm.content)},on:{"compositionupdate":_vm.handecomposeUpdate,"compositionend":_vm.handecomposeEnd,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},function($event){return _vm.handleInput(_vm.content)}],"change":function($event){return _vm.handleChange(_vm.content)},"keyup":_vm.handleKeyup}}),_vm._v(" "),(errors[0] !== 'hidden')?_c('div',{staticClass:"nat-invalid-data"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.hintText !== '')?_c('div',{staticClass:"nat-hint-text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }