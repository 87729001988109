<template>
  <div class="img-multiple-at">
    <div
      v-for="(i, index) in images"
      :key="`image_carousel_${index + 1}`"
      style="position: relative;"
    >
      <div :class="['image-container', index > 3 ? 'hiddenImage' : '']">
        <img v-img="{ group: imagesGroupId }" :src="i.url" class="image" alt="Image" />
      </div>
      <div
        v-if="index === 3 && itemCount > 4"
        class="image center placeholder img-multiple-more"
      >
        <span>+{{ itemCount - 4 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';

export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      imagesGroupId: uuid.v4()
    };
  },

  computed: {
    images() {
      return (this.message && this.message.elements && this.message.elements) || [];
    },

    itemCount() {
      return (this.message && this.message.elements && this.message.elements.length) || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.img-multiple-at {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  left: -5px;

  .image {
    margin: 0 0 5px 5px;
    vertical-align: middle;
    border-radius: 5px;
    width: 200px;
    height: 200px;
    float: none !important;
    background-size: cover !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
  }

  .hiddenImage {
    display: none;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .img-multiple-more {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: bold;
      font-size: 50px;
      color: white;
    }
  }
}
</style>
