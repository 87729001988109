<template>
  <div :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper">
      <div class="card-body">
        <PanelTools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="handleClose"
        />
        <h3 class="card-title">
          {{ $t('src.modules.chat.components.AsideRight.index.conversation_feeds') }}
        </h3>
        <b-tabs v-model="tabIndex" content-class="mt-3" class="tabs-faq-nlp" fill>
          <b-tab v-if="nlpActivated">
            <template slot="title">
              <h5>NLP</h5>
            </template>
          </b-tab>
          <b-tab v-if="faqActivated">
            <template slot="title">
              <h5>FAQ</h5>
            </template>
          </b-tab>
          <b-tab v-if="scenarioActivated">
            <template slot="title">
              <h5>
                {{ $t('src.modules.chat.components.ConversationalPanel.index.scenario') }}
              </h5>
            </template>
          </b-tab>
        </b-tabs>
        <div v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'faq'">
          <el-select
            v-model="selectCategory"
            filterable
            :placeholder="
              $t('src.modules.chat.components.ConversationalPanel.Faq.index.select_a_category')
            "
            style="margin-bottom: 20px; width: 100%"
            @change="setSubCategoryList"
            clearable
          >
            <el-option
              v-for="category in faqCategoryList"
              :key="category"
              :label="category"
              :value="category"
            ></el-option>
          </el-select>
          <el-select
            v-model="selectSubCategory"
            filterable
            :placeholder="
              $t('src.modules.chat.components.ConversationalPanel.Faq.index.select_a_sub_category')
            "
            style="margin-bottom: 20px; width: 100%"
            @change="getFaqList"
          >
            <el-option
              v-for="category in faqSubCategoryList"
              :key="category"
              :label="category"
              :value="category"
            ></el-option>
          </el-select>
        </div>
        <div v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'scenario'">
          <el-select
            v-model="selectCampainId"
            filterable
            :placeholder="
              $t(
                'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.selectACampaign'
              )
            "
            @change="getScenarioDataByCampaignId"
            style="margin-bottom: 20px; width: 100%"
          >
            <el-option
              v-for="campaign in campaignList"
              :key="campaign.id"
              :label="campaign.name"
              :value="campaign.id"
            ></el-option>
          </el-select>
        </div>
        <search-box
          v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname !== 'scenario'"
          :pinned="pinned"
          :list-faq-language="listFaqLanguage"
          :used-language="usedLangFAQ"
          @filterDataBylanguage="handleFilterDataBylanguage"
        />
        <scn-search-box
          v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'scenario'"
          :list-support-language="langLables"
          :used-language="usedLang"
          @changeScenarioSelectedLang="handleChangeScenarioSelectedLang"
        />
        <div v-bar class="scroll-container">
          <div class="conversation-feed-group">
            <vue-element-loading :active="!ready" :text-style="textStyle" spiner="line-scale" />
            <template v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'nlp'">
              <conversation-feed-list
                v-for="c in NLPcatelogs"
                :key="c"
                :catelog-key="c"
                :conversation-feeds="conversationItem[c]"
              />
              <div class="nat-nodata">
                <strong v-if="NLPcatelogs && NLPcatelogs.length === 0">{{
                  $t('common.text.no_data')
                }}</strong>
              </div>
            </template>
            <template v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'faq'">
              <conversation-feed-list
                v-for="c in FAQcatelogs"
                :key="c"
                :catelog-key="c"
                :conversation-feeds="conversationItem[c]"
              />
              <div class="nat-nodata">
                <strong
                  v-if="
                    (FAQcatelogs && FAQcatelogs.length === 0) ||
                    FAQcatelogs.every(i => conversationItem[i].length <= 0)
                  "
                  >{{ $t('common.text.no_data') }}</strong
                >
              </div>
            </template>
            <template v-if="tabs.length && tabs[tabIndex] && tabs[tabIndex].tname === 'scenario'">
              <campaign-feed-list
                v-for="cmp in campaigns"
                :key="cmp.id"
                :campaign-id="cmp.id"
                :campaign-name="cmp.name"
                :scenarios="cmp.scenarios"
                :sending-ref="sendingRef"
                :used-lang="usedLang"
                @clickButSendScenario="handleClickButSendScenario"
              />
              <div class="nat-nodata">
                <strong v-if="campaigns && campaigns.length == 0">{{
                  $t('common.text.no_data')
                }}</strong>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import PanelTools from 'modules/chat/components/PanelTools';
import CampaignFeedList from './CampaignFeedList';
import ConversationFeedList from './ConversationFeedList';
import ScnSearchBox from './ScnSearchBox';
import SearchBox from './search-box';
import { EventBus } from '../../../../core/eventBus';

export default {
  components: {
    ConversationFeedList,
    CampaignFeedList,
    SearchBox,
    ScnSearchBox,
    PanelTools
  },

  data() {
    return {
      textStyle: {},
      collapsed: false,
      options: {
        group: { name: 'chat-box', pull: 'clone', put: false },
        sort: false,
        animation: 150
      },
      pinned: false,
      ready: true,
      tabIndex: 0,
      NLPcatelogs: [],
      FAQcatelogs: [],
      conversationItem: {},
      tabs: [],
      usedLang: 'en_US',
      usedLangFAQ: 'en_US',
      supportLanguages: [],
      campaignList: [],
      selectCampainId: '',
      faqCategoryList: [],
      faqSubCategoryList: [],
      selectCategory: '',
      selectSubCategory: '',
      langLables: [],
      sendingRef: {},
      listFaqLanguage: [],
      campaigns: []
    };
  },

  computed: {
    ...mapState('chat', ['conversationFeeds', 'searchFaqWithLanguage']),
    ...mapGetters('conversationMng', ['filterCampaignByScenarioName']),
    ...mapGetters('session', ['actionSettings']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapGetters('chat', { conversations: 'conversationFeedsSearch' }),
    ...mapState('global', ['rightConversationPanelPinned']),
    ...mapState('session', ['channelsMap', 'projectMaps']),

    faqActivated() {
      try {
        const { channelId } = this.selectedConversation;
        const {
          advancedConfigs: {
            modulesActivation: { chatResponser, faq }
          }
        } = this.channelsMap[channelId];

        return !chatResponser || !faq ? false : true;
      } catch (error) {
        return false;
      }
    },

    nlpActivated() {
      return false;
    },

    scenarioActivated() {
      try {
        const { projectId = '' } = this.selectedConversation && this.selectedConversation.ticket;
        const { config = {} } = this.projectMaps[projectId] || {};
        const { agentScenario = {} } = config;
        const { enabled = true } = agentScenario;

        return enabled;
      } catch (error) {
        return false;
      }
    },

    collapseIcon() {
      return this.collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up';
    },

    toggleName() {
      return `conversation-feed-list-${this.group}`;
    },

    shadowClass() {
      return this.rightConversationPanelPinned ? 'shadow-sm' : 'shadow-lg unpinned';
    },

    pinPanelStatus() {
      const pinStatus = this.rightConversationPanelPinned || this.pinned;
      return pinStatus;
    }
  },

  watch: {
    filterCampaignByScenarioName(value) {
      this.campaigns = value;
    },

    tabIndex() {
      const {
        ticket: { projectId = '' }
      } = this.selectedConversation;

      if (
        this.tabIndex &&
        this.tabs.length &&
        this.tabs[this.tabIndex] &&
        this.tabs[this.tabIndex].tname === 'nlp'
      ) {
        this.fetchConversationFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage
        });
      }

      if (
        this.tabIndex &&
        this.tabs.length &&
        this.tabs[this.tabIndex] &&
        this.tabs[this.tabIndex].tname === 'faq'
      ) {
        if (this.selectCategory != '') {
          this.fetchFAQFeedsByChannel({
            page_id: projectId,
            language: this.usedLangFAQ,
            category: this.selectCategory,
            subcategory: this.selectSubCategory
          });
        } else {
          this.fetchFAQFeedsByChannel({
            page_id: projectId,
            language: this.usedLangFAQ,
            category: '',
            subcategory: ''
          });
        }
      }
    },

    selectCategory(newValue) {
      !newValue ? (this.selectSubCategory = '') : null;
    },

    conversations() {
      this.dectectData();
    },

    selectedConversation(value) {
      if (!value || isEmpty(value)) {
        this.$emit('hideAllPanel');
      }

      if (
        value &&
        value.channelId &&
        !this.conversationFeeds[value.channelId] &&
        this.searchFaqWithLanguage
      ) {
        const {
          ticket: { projectId = '' }
        } = value;
        this.fetchConversationFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage
        });
      }
    }
  },
  async created() {
    this.ready = false;
    const {
      channelId,
      ticket: { projectId = '', groupId = '' },
      userInfo: { locale }
    } = this.selectedConversation;
    const {
      defaultLanguage,
      advancedConfigs: { supportLanguages }
    } = this.channelsMap[channelId];
    const ticketLanguage = supportLanguages.includes(locale) ? locale : defaultLanguage;
    this.listFaqLanguage = this.getLanguageLabels(supportLanguages);
    this.langLables = this.getLanguageLabels(supportLanguages);
    if (this.faqActivated) {
      this.tabs.push({ tname: 'faq' });
      this.usedLangFAQ = ticketLanguage || 'en_US';
      this.faqCategoryList = await this.getListFAQCategory({
        pageId: projectId,
        language: this.usedLangFAQ
      });
      this.fetchFAQFeedsByChannel({
        page_id: projectId,
        language: this.usedLangFAQ,
        category: '',
        subcategory: ''
      });
    }
    if (this.scenarioActivated) this.tabs.push({ tname: 'scenario' });
    this.supportLanguages = supportLanguages;
    if (this.scenarioActivated) {
      this.usedLang = ticketLanguage || 'en_US';
      this.campaignList = await this.getCampainList({ projectId, groupId, type: 'AT' });
      this.setEmptyCampaign();
    }
    await this.setSearchFaqWithLanguage(this.usedLangFAQ);
    this.ready = true;
  },

  mounted() {
    this.campaigns = [];
  },

  methods: {
    ...mapActions('chat', [
      'sendMessage',
      'getConversationFeedsByChannel',
      'getFAQsByChannel',
      'setSearchFaqWithLanguage',
      'getListFAQCategory',
      'getlistFAQSubCategory'
    ]),
    ...mapActions('conversationMng', [
      'getCampainData',
      'getCampainList',
      'setEmptyCampaign',
      'sendScenario'
    ]),
    ...mapActions('global', ['pinRightConversationPanel']),

    async handleClickButSendScenario(params, sendWithConfirm = false) {
      if (
        this.actionSettings &&
        this.actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
        !sendWithConfirm
      ) {
        return this.$baseConfirm({
          message: this.$t(
            'src.modules.chat.store.actions.are_you_sure_to_send_this_scenario_to_user'
          )
        })
          .then(() => {
            this.handleClickButSendScenario(params, (sendWithConfirm = true));
          })
          .catch(() => {});
      }
      params.language = this.usedLang;
      this.sendingRef = { ...this.sendingRef, [params.scenarioId]: true };
      const data = await this.sendScenario(params);
      if (data === 'error') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.bot_service_is_not_available'
          )
        });
      } else {
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.chat.components.ConversationalPanel.CampaignFeedList.index.scenarioHasSent',
            { scnName: params.scenarioName.substring(0, 300) }
          )
        });
      }
      delete this.sendingRef[params.scenarioId];
      this.sendingRef = { ...this.sendingRef };
      EventBus.$emit('scrollBottomMessage');
    },

    async setSubCategoryList() {
      if (!this.selectCategory) {
        this.ready = false;
        const {
          ticket: { projectId = '' }
        } = this.selectedConversation;
        this.FAQcatelogs = this.fetchFAQFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage,
          category: '',
          subcategory: ''
        });
        this.faqSubCategoryList = [];
        this.faqCategoryList = await this.getListFAQCategory({
          pageId: projectId,
          language: this.searchFaqWithLanguage
        });
        this.ready = true;
      } else {
        const {
          ticket: { projectId = '' }
        } = this.selectedConversation;
        this.selectSubCategory = '';
        this.faqSubCategoryList = await this.getlistFAQSubCategory({
          pageId: projectId,
          language: this.searchFaqWithLanguage,
          category: this.selectCategory
        });
        this.fetchFAQFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage,
          category: this.selectCategory,
          subcategory: ''
        });
      }
    },

    getFaqList() {
      const {
        ticket: { projectId = '' }
      } = this.selectedConversation;
      if (!this.selectCategory) {
        this.fetchFAQFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage,
          category: '',
          subcategory: ''
        });
      } else {
        this.fetchFAQFeedsByChannel({
          page_id: projectId,
          language: this.searchFaqWithLanguage,
          category: this.selectCategory,
          subcategory: this.selectSubCategory
        });
      }
    },

    async getScenarioDataByCampaignId() {
      let campaign = this.campaignList.filter(
        campaign => campaign.id.toLowerCase().trim() === this.selectCampainId.toLowerCase().trim()
      );
      campaign = campaign && campaign.length > 0 ? campaign[0] : null;
      if (campaign) {
        this.ready = false;
        const { channelId } = this.selectedConversation;
        const { platform } = this.channelsMap[channelId];
        await this.getCampainData({
          campaignId: campaign.id,
          campaignName: campaign.name,
          platform,
          language: this.usedLang
        });
        this.ready = true;
      }
    },

    async fetchConversationFeedsByChannel(params) {
      try {
        this.ready = false;
        await this.getConversationFeedsByChannel(params);
      } catch (_) {
        this.ready = true;
      }
      this.ready = true;
    },

    async fetchFAQFeedsByChannel(params) {
      try {
        this.ready = false;
        await this.getFAQsByChannel(params);
      } catch (_) {
        this.ready = true;
      }
      this.ready = true;
    },

    createElement(element) {
      this.sendMessage(element.clone.innerText);
    },

    dragElement() {
      this.drag = true;
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.pinRightConversationPanel(this.pinned);
    },

    handleClose() {
      this.$emit('onConversationalItemClick');
    },

    async handleFilterDataBylanguage(language) {
      const {
        ticket: { projectId = '' }
      } = this.selectedConversation;

      if (
        this.tabs.length &&
        this.tabs[this.tabIndex] &&
        this.tabs[this.tabIndex].tname === 'nlp'
      ) {
        this.fetchConversationFeedsByChannel({
          page_id: projectId,
          language: language.value
        });
      }

      if (
        this.tabs.length &&
        this.tabs[this.tabIndex] &&
        this.tabs[this.tabIndex].tname === 'faq'
      ) {
        this.ready = false;
        this.selectCategory = '';
        this.selectSubCategory = '';
        this.usedLangFAQ = language;
        this.faqCategoryList = await this.getListFAQCategory({
          pageId: projectId,
          language: this.usedLangFAQ
        });
        if (this.selectCategory === '') {
          this.fetchFAQFeedsByChannel({
            page_id: projectId,
            language: this.usedLangFAQ,
            category: '',
            subcategory: ''
          });
        }
        this.ready = true;
      }
    },

    async handleChangeScenarioSelectedLang(language) {
      let campaign = this.campaignList.filter(
        campaign => campaign.id.toLowerCase().trim() === this.selectCampainId.toLowerCase().trim()
      );
      campaign = campaign && campaign.length > 0 ? campaign[0] : null;
      if (campaign) {
        this.ready = false;
        const { channelId } = this.selectedConversation;
        const { platform } = this.channelsMap[channelId];
        this.usedLang = language;
        await this.getCampainData({
          campaignId: campaign.id,
          campaignName: campaign.name,
          platform,
          language: this.usedLang
        });
        this.ready = true;
      } else {
        this.ready = false;
        this.usedLang = language;
        this.ready = true;
      }
    },

    dectectData() {
      if (!this.conversations) {
        this.NLPcatelogs = [];
        this.FAQcatelogs = [];
        this.conversationItem = {};
      } else if (this.faqActivated && !this.nlpActivated) {
        this.FAQcatelogs = Object.keys(this.conversations.conversationsFAQ);
        this.conversationItem = this.conversations.conversationsFAQ;
      } else if (!this.faqActivated && this.nlpActivated) {
        this.NLPcatelogs = Object.keys(this.conversations.conversationsNLP);
        this.conversationItem = this.conversations.conversationsNLP;
      } else {
        if (
          this.conversations &&
          this.conversations.conversationsFAQ &&
          this.tabs.length &&
          this.tabs[this.tabIndex] &&
          this.tabs[this.tabIndex].tname === 'faq'
        ) {
          this.FAQcatelogs = Object.keys(this.conversations.conversationsFAQ);
          this.conversationItem = this.conversations.conversationsFAQ;
        } else if (
          this.conversations &&
          this.conversations.conversationsNLP &&
          this.tabs.length &&
          this.tabs[this.tabIndex] &&
          this.tabs[this.tabIndex].tname === 'nlp'
        ) {
          this.NLPcatelogs = Object.keys(this.conversations.conversationsNLP);
          this.conversationItem = this.conversations.conversationsNLP;
        }
      }
    },

    getLanguageLabels(supportLanguages) {
      const langLables = [];
      supportLanguages.forEach(lang => {
        switch (lang) {
          case 'en_US':
            langLables.push({ key: 'en_US', label: 'English' });
            break;
          case 'ja_JP':
            langLables.push({ key: 'ja_JP', label: '日本語' });
            break;
          case 'vi_VN':
            langLables.push({ key: 'vi_VN', label: 'Tiếng Việt' });
            break;
          case 'zh_CN':
            langLables.push({ key: 'zh_CN', label: '中国語' });
            break;
          case 'id_ID':
            langLables.push({ key: 'id_ID', label: 'Bahasa indonesia' });
            break;
          case 'zh_TW':
            langLables.push({ key: 'zh_TW', label: '台灣語' });
            break;
          case 'ms_MY':
            langLables.push({ key: 'ms_MY', label: 'Melayu' });
            break;
          case 'ko_KR':
            langLables.push({ key: 'ko_KR', label: '한국어' });
            break;
          case 'tl_PH':
            langLables.push({ key: 'tl_PH', label: 'Pilipino' });
            break;
          case 'th_TH':
            langLables.push({ key: 'th_TH', label: 'ภาษาไทย' });
            break;
        }
      });
      return langLables;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  i {
    cursor: pointer;

    &.fa-custom {
      color: #e9ecef;
      transform: rotate(-45deg);
    }
  }
}

.conversation-feed-group {
  width: 100% !important;
  height: calc(100vh - 315px) !important;
  overflow: auto !important;
}
.nat-nodata {
  text-align: center;
}
</style>
