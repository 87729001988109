import { MessageBox } from 'element-ui';
import { i18n } from 'locales';

const BaseConfirm = function(options) {
  const {
    message,
    title = i18n.t('BaseConfirm.confirmation'),
    confirmButtonText = i18n.t('BaseConfirm.yes'),
    cancelButtonText = i18n.t('BaseConfirm.no'),
    iconClass = 'el-icon-question',
    distinguishCancelAndClose = false,
    closeOnPressEscape = true,
    closeOnClickModal = true,
    showClose = true,
    showCancelButton = true,
    showConfirmButton = true,
    dangerouslyUseHTMLString = false,
    beforeClose,
    preventKeyboard
  } = options;
  const _options = {};
  _options.center = true;
  _options.confirmButtonText = confirmButtonText;
  _options.cancelButtonText = cancelButtonText;
  _options.customClass = 'nat-base-confirm-box';
  _options.iconClass = iconClass;
  _options.distinguishCancelAndClose = distinguishCancelAndClose;
  _options.closeOnPressEscape = closeOnPressEscape;
  _options.closeOnClickModal = closeOnClickModal;
  _options.showClose = showClose;
  _options.showCancelButton = showCancelButton;
  _options.showConfirmButton = showConfirmButton;
  _options.dangerouslyUseHTMLString = dangerouslyUseHTMLString;
  if (preventKeyboard) _options.beforeClose = beforeClose;
  return MessageBox.confirm(message, title, _options);
};

BaseConfirm.close = () => {
  return MessageBox.close();
};

export default BaseConfirm;