<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group search-box shadow">
        <div class="input-group mb-4">
          <div class="input-group-prepend nat-search-input-icon">
            <span class="input-group-text">
              <i class="fa fa-search text-primary" />
            </span>
          </div>
          <drop class="drop position-relative nat-search-input">
            <b-input-group>
              <b-form-input
                v-model="scenarioName"
                :style="{ 'margin-right': '2px' }"
                :placeholder="
                  $t(
                    'src.modules.chat.components.ConversationalPanel.scn-search-box.searchScenarioName'
                  )
                "
                class="form-control placeholder-input-search"
                type="text"
                @input="handleSearchScenario"
                @keypress.enter="handleSearchScenario"
              >
              </b-form-input>
              <b-dropdown
                slot="append"
                class="b-dropdown-convsersation-panel"
                variant="default"
                :text="langText"
              >
                <b-dropdown-item
                  v-for="lang in listSupportLanguage"
                  :key="lang.key"
                  :value="lang.key"
                  @click="filterDataBylanguage(lang.key)"
                  :active="lang.key.toLowerCase() === selectedLang.toLowerCase()"
                >
                  {{ lang.label }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group>
          </drop>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    listSupportLanguage: {
      type: Array,
      default: () => []
    },
    usedLanguage: {
      type: String,
      default: 'en_US'
    }
  },

  data() {
    return {
      selectedLang: '',
      scenarioName: ''
    };
  },

  computed: {
    langText() {
      const lang = this.listSupportLanguage.find(lang => lang.key.toLowerCase() === this.selectedLang.toLowerCase());
      if(lang) return lang.label;
      return '';
    }
  },

  async created() {
    this.selectedLang = this.usedLanguage;
  },

  methods: {
    ...mapActions('conversationMng', ['searchScenarioName']),
    filterDataBylanguage(language) {
      this.selectedLang = language;
      this.$emit('changeScenarioSelectedLang', language);
    },
    handleSearchScenario() {
      this.searchScenarioName(this.scenarioName);
    },
  }  
};
</script>

<style lang="scss" scoped>
.nat-search-input {
  width: calc(100% - 46px);
}

.nat-search-input-icon {
  width: 46px;
}

.placeholder-input-search {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-group-append {
  .dropdown {
    display: inline-flex;
  }
}
</style>
