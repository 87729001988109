import {
  SET_PROJECTS_SETTING,
  SET_CUSTOMER_SUPPORT_HISTORY,
  UPDATE_TREE_STORE,
  SET_SEARCHTEXT,
  DELETE_CUSTOMER_SUPPORT_HISTORY,
  UPDATE_CUSTOMER_SUPPORT_HISTORY,
  CHANNELS_FILTER_MAP,
  GET_CUSTOMER_SUPPORT_REPORT,
  GET_CONVERSATION_REPORT,
  ADD_CONVERSATION,
  GET_AGENT_GROUPS,
  UPDATE_CUSTOMER_DATA,
  GET_ACTION_CLICK_CSH
} from './types';
import * as getters from './getters';
import * as actions from './actions';
import remove from 'lodash/remove';
import { getTranslationsMapping } from 'core/helpers';

const state = {
  settingReady: false,
  categories: {},
  customerFields: {},
  customerData: [],
  treeStore: [],
  searchText: '',
  channels: {},
  platforms: {},
  groups: {},
  channelsFilterMap: {},
  customerSupportReportFile: {},
  conversationReportData: [],
  ticketsFilterMap: {},
  userFilterMap: {},
  templates: {},
  templateIdMaps: {},
  actionClickCSH: {}
};

const mutations = {
  [SET_PROJECTS_SETTING](
    state,
    { categories, customerFields, templates, templateIdMaps }
  ) {
    state.categories = { ...categories };
    state.customerFields = { ...customerFields };
    state.templates = { ...templates };
    state.templateIdMaps = { ...templateIdMaps };
    state.settingReady = true;
  },
  [SET_CUSTOMER_SUPPORT_HISTORY](state, customerData) {
    state.customerData = [...customerData];
  },
  [UPDATE_TREE_STORE](state, newTree) {
    state.treeStore = newTree;
  },
  [SET_SEARCHTEXT](state, text) {
    state.searchText = text;
  },
  [DELETE_CUSTOMER_SUPPORT_HISTORY](state, id) {
    const temp = state.customerData;
    var rtl = remove(temp, function(t) {
      return t.id !== id;
    });
    state.customerData.splice(0);
    state.customerData = [...rtl];
  },

  [UPDATE_CUSTOMER_SUPPORT_HISTORY](state, conversation) {
    for (let i = 0; i < state.customerData.length; i++) {
      if (state.customerData[i].conversationId === conversation.id) {
        state.customerData[i].status = 'submited';
        break;
      }
    }
  },

  [CHANNELS_FILTER_MAP](state, data) {
    const channels = data || [];
    state.channelsFilterMap = channels.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [GET_CUSTOMER_SUPPORT_REPORT](state, file) {
    state.customerSupportReportFile = file;
  },

  [GET_CONVERSATION_REPORT](state, reportData) {
    state.conversationReportData = reportData;
  },

  [ADD_CONVERSATION](state, { conversationInfo, userInfo }) {
    const { userId, ticket, channelId } = conversationInfo;

    const { id } = ticket;
    let userInfoState = userInfo;

    // If userInfo is null => get userInfo from state
    if (userInfo) {
      state.userFilterMap = {
        ...state.userFilterMap,
        ...{ [userId]: userInfo }
      };
    } else {
      userInfoState = state.userFilterMap[userId];
    }

    const channelsFilterMap = state.channelsFilterMap;

    const translationsData = getTranslationsMapping(
      userInfoState,
      channelId,
      channelsFilterMap
    );
    conversationInfo.translations = translationsData;

    state.ticketsFilterMap = {
      ...state.ticketsFilterMap,
      ...{ [id]: conversationInfo }
    };
  },

  [GET_AGENT_GROUPS](state, data) {
    state.agentGroups = data;
  },

  [UPDATE_CUSTOMER_DATA](state, data) {
    state.customerData = data;
  },

  [GET_ACTION_CLICK_CSH](state, { userId, channelId }) {
    state.actionClickCSH = { userId, channelId };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
