export const customerSupportSearch = state => {
  const { customerSupportData, searchTextSupport } = state;

  if (searchTextSupport === '') return customerSupportData;

  const searchTextSupportTrim = searchTextSupport.trim();

  return customerSupportData.filter(
    c =>
      `${c.id}`.toLowerCase() == searchTextSupportTrim.toLowerCase() ||
      `${c.categoryPath}`.toLowerCase().includes(searchTextSupportTrim.toLowerCase()) ||
      `${c.title}`.toLowerCase().includes(searchTextSupportTrim.toLowerCase())
  );
};

export const tree = state => {
  return state.treeStore;
};
