<template>
  <!-- Chat list (S) -->
  <div class="app-message-list scrollable">
    <!-- Chat list of Agent (S) -->
    <div data-role="container">
      <!-- Comment tab (S)  -->
      <div
        v-bar
        :style="{
          'max-height': `calc(100vh - ${getSearchBoxHeight}px)`,
          height: `calc(100vh - ${getSearchBoxHeight}px)`
        }"
      >
        <div class="nat-project-list">
          <div>
            <el-menu
              :default-openeds="dflOpnPjtLst"
              :collapse="false"
              @open="handleOpenNavPjt"
              @close="handleCloseNavPjt"
            >
              <el-submenu v-for="(p, index) in projectList" :key="index" :index="p.key">
                <template slot="title">
                  <i class="el-icon-s-cooperation"></i>
                  <span>{{ p.name }}</span>
                  <span class="nat-project-id">{{ p.key }}</span>
                </template>
                <div class="channel-group">
                  <comment-item
                    v-for="c in channelList.filter(c => c.projectId === p.key)"
                    :key="c.id"
                    :channel-id="c.id"
                    :name="c.name"
                    :platform="c.platform"
                    :picture-url="c.pictureUrl"
                    :selected="selectedChannel && selectedChannel.id === c.id"
                    @onClick="e => $emit('onClick', e)"
                  />
                </div>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
      <!-- Comment tab (S)  -->
    </div>
    <!-- Chat list of Agent (E) -->
  </div>
  <!-- Chat list (E) -->
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SERVING_STATE } from 'core/constants';
import CommentItem from './comment-item';
import noAvatar from 'assets/images/no-avatar.png';

export default {
  components: {
    CommentItem
  },

  props: {
    commentFilter: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      SERVING_STATE: SERVING_STATE,
      testCount: 0,
      projectMapList: {},
      dflOpnPjtLstMap: {},
      dflOpnPjtLst: null,
      noAvatar
    };
  },

  computed: {
    ...mapState('session', ['channels', 'projectMaps']),
    ...mapState('chat', ['searchBoxHeight']),
    ...mapState('comment', ['selectedChannel', 'tickets']),

    projectList() {
      const channels = this.channelList || [];
      const ordered = [];
      const arrayKey = Object.keys(this.projectMapList) || [];
      for (let i = 0; i < arrayKey.length; i++) {
        const key = arrayKey[i];
        if (channels.some(c => c.projectId === key))
          ordered.push({ key, name: this.getProjectName(key) });
      }
      return ordered.sort((ca, cb) => ca.name.localeCompare(cb.name));
    },

    channelList() {
      return this.channels
        .filter(channel => {
          const { configs = {} } = channel;
          if (Object.keys(this.commentFilter).length > 0) {
            const lstText = this.commentFilter.text || [];
            if (
              lstText.length > 0 &&
              !lstText.some(text =>
                channel.name.toLowerCase().includes(text.toLowerCase())
              )
            )
              return false;

            const platform = this.commentFilter.platform;
            if (platform && platform.toLowerCase() !== channel.platform) return false;
          }
          return (
            (channel.platform === 'facebook' && configs.facebookComment) ||
            (channel.platform === 'instagram' && configs.instagramComment)
          );
        })
        .sort((ca, cb) => ca.name.localeCompare(cb.name));
    },

    getSearchBoxHeight() {
      return this.searchBoxHeight + 65;
    }
  },

  created() {
    this.getProjectMapList();
  },

  methods: {
    ...mapActions('chat', ['setSearchBoxFilter', 'setSearchBoxHeight']),

    handleOnSelectedChannel(channelId) {
      this.$emit('onSelectedChannel', channelId);
    },

    getProjectName(projectId) {
      if (this.projectMaps[projectId] && this.projectMaps[projectId].name)
        return this.projectMaps[projectId].name;
      return 'undefined';
    },

    getProjectMapList() {
      this.projectMapList = this.channels.reduce((pjts, chnl) => {
        const { projectId, configs } = chnl;
        if (!pjts[projectId]) pjts[projectId] = [];

        if (Object.keys(this.commentFilter).length > 0) {
          const lstText = this.commentFilter.text || [];
          if (
            lstText.length > 0 &&
            !lstText.some(text => chnl.name.toLowerCase().includes(text.toLowerCase()))
          ) {
            pjts[projectId] = pjts[projectId] || [];
            return pjts;
          }

          const platform = this.commentFilter.platform;
          if (platform && platform.toLowerCase() !== chnl.platform) {
            pjts[projectId] = pjts[projectId] || [];
            return pjts;
          }
        }
        if (
          (chnl.platform === 'facebook' && configs.facebookComment) ||
          (chnl.platform === 'instagram' && configs.instagramComment)
        ) {
          pjts[projectId] = [...(pjts[projectId] || []), ...[chnl]];
        }
        return pjts;
      }, {});

      if (this.dflOpnPjtLst == null && Object.keys(this.projectMapList).length > 0) {
        this.dflOpnPjtLst = Object.keys(this.projectMapList);
      }
    },

    handleOpenNavPjt(key) {
      this.dflOpnPjtLst.push(key);
    },
    handleCloseNavPjt(key) {
      this.dflOpnPjtLst = [...this.dflOpnPjtLst.filter(item => item != key)];
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-project-list {
  /deep/.el-submenu__title {
    background: rgba(233, 236, 239, 0.6);
  }

  .nat-project-id {
    position: absolute;
    left: 52px;
    top: 16px;
    font-size: 10px;
    color: darkgray;
  }
  /deep/.el-menu {
    width: 100% !important;
  }
}

.count-common {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 33px;
}
.count-tome {
  position: absolute;
  top: 77px;
  z-index: 1;
  left: 224px;
}
</style>
