import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';

export default {
  methods: {
    __u(field, value, mutation) {
      if (!field) return;
      let updateData = {};
      if (field.indexOf('.') > -1) {
        const fields = field.split('.');
        updateData[fields[0]] = {
          [fields[1]]: value
        };
      } else {
        updateData[field] = value;
      }
      if (isString(mutation) && this.$store) {
        this.$store.commit(mutation, updateData);
      }
      if (isFunction(mutation)) {
        mutation(updateData);
      }
    }
  }
};
