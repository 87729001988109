var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat"},[_c('div',{staticClass:"chat-body chat-left"},[(
        !['text', 'template_button', 'template_generic', 'template_list', 'quick_reply'].includes(
          _vm.scnr.typeClass
        )
      )?_c('div',{staticClass:"read-only"}):_vm._e(),_c('div',{staticClass:"text-uppercase d-block m-b-10 m-t-20 pb-2 font-weight-bold"},[_c('span',[_vm._v(" "+_vm._s(_vm.messageType)+" ")])]),_c('div',{class:['chat-content', 'shadow-sm', _vm.msgClass(_vm.scnr)]},[_c('div',{staticClass:"text-style"},[(_vm.scnr.typeClass === 'text')?_c('base-textarea',{attrs:{"show-label":false,"rows":5,"maxlength":2000,"label":_vm.$t('src.core.constants.scenarioTemplateTypeName.text_message'),"show-word-limit":true,"tribute-options":_vm.tributeOptions,"tribute-keys":_vm.tributeKeys,"vue-tribute":true,"required":true},on:{"change":_vm.handleOnChangeBaseTextarea},model:{value:(_vm.textMessage),callback:function ($$v) {_vm.textMessage=$$v},expression:"textMessage"}}):_vm._e()],1),(_vm.scnr.typeClass === 'image' && _vm.scnr.content && _vm._isImagesSupported(_vm.scnr.content))?_c('div',{staticClass:"message-image"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],style:({ 'max-width': '300px' }),attrs:{"src":_vm.scnr.content}})]):_vm._e(),(_vm.scnr.typeClass === 'sticker')?_c('div',{staticClass:"message-image"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],style:({ 'max-width': '300px' }),attrs:{"src":_vm.scnr.content}})]):_vm._e(),(_vm.scnr.typeClass === 'template_imagemap')?_c('msg-image-map',{attrs:{"message":_vm.scnr.content}}):_vm._e(),(_vm.scnr.typeClass === 'template_image_multiple')?_c('msg-image-multiple',{attrs:{"message":_vm.scnr.content}}):_vm._e(),(_vm.scnr.typeClass === 'video')?_c('msg-video',{attrs:{"video-url":_vm.scnr.content,"platform":_vm.scnr.platform}}):_vm._e(),(_vm.scnr.typeClass === 'audio')?_c('msg-audio',{attrs:{"audio-url":_vm.scnr.content}}):_vm._e(),(
          ['file'].includes(_vm.scnr.typeClass) ||
          (_vm.scnr.typeClass === 'image' &&
            _vm.scnr.content &&
            typeof _vm.scnr.content === 'string' &&
            !_vm._isImagesSupported(_vm.scnr.content))
        )?_c('msg-file',{attrs:{"message":_vm.scnr}}):_vm._e(),(
          _vm.scnr.typeClass === 'quick_reply' && _vm.scnr.content.type === 'USER_REQUEST_AGENT_CONFIRM'
        )?_c('msg-quickrep',{attrs:{"text":_vm.scnr.text,"message":_vm.scnr.content,"platform":_vm.scnr.platform,"selected-conversation":_vm.conversation}}):_vm._e(),(
          _vm.scnr.typeClass === 'quick_reply' && _vm.scnr.content.type !== 'USER_REQUEST_AGENT_CONFIRM'
        )?_c('msg-quickrep',{attrs:{"text":_vm.scnr.text,"message":_vm.scnr.content,"platform":_vm.scnr.platform,"selected-conversation":_vm.conversation}}):_vm._e(),(_vm.scnr.typeClass === 'template_button')?_c('fb-template-button',{attrs:{"message":_vm.scnr.content}}):_vm._e(),(_vm.scnr.typeClass === 'template_generic')?_c('fb-template-product',{attrs:{"message":_vm.scnr.content}}):_vm._e(),(_vm.scnr.typeClass === 'template_list')?_c('msg-list',{attrs:{"message":_vm.scnr.content,"channel-platform":_vm.channelPlatform}}):_vm._e(),(_vm.scnr.typeClass === 'location')?_c('div',{staticClass:"location-style"},[_c('label',[_vm._v(_vm._s(_vm.$t('src.core.constants.scenarioTemplateTypeName.location.URL')))]),_c('base-textarea',{attrs:{"show-label":false,"rows":5,"show-word-limit":false},model:{value:(_vm.scnr.content),callback:function ($$v) {_vm.$set(_vm.scnr, "content", $$v)},expression:"scnr.content"}})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }