export const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
export const UPDATE_COMMENT_IN_LIST = 'UPDATE_COMMENT_IN_LIST';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_READY = 'SET_READY';
export const SET_SELECTED_COMMENT = 'SET_SELECTED_COMMENT';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';
export const REPLY_COMMENT = 'REPLY_COMMENT';
export const DELETE_REPLY = 'DELETE_REPLY';
export const UPDATE_REPLY = 'UPDATE_REPLY';
export const MARK_READ_COMMENT = 'MARK_READ_COMMENT';
export const MARK_LIKE_COMMENT = 'MARK_LIKE_COMMENT';
export const MARK_UNLIKE_COMMENT = 'MARK_UNLIKE_COMMENT';
export const MARK_LIKE_REPLY = 'MARK_LIKE_REPLY';
export const MARK_UNLIKE_REPLY = 'MARK_UNLIKE_REPLY';
export const SET_COMMENT_NEXT_PAGING = 'SET_COMMENT_NEXT_PAGING';
export const SET_FIRST_PAGING = 'SET_FIRST_PAGING';
export const REALTIME_NEW_COMMENT = 'REALTIME_NEW_COMMENT';
export const REALTIME_EDIT_COMMENT = 'REALTIME_EDIT_COMMENT';
export const REALTIME_DELETE_COMMENT = 'REALTIME_DELETE_COMMENT';
export const REALTIME_NEW_REPLY = 'REALTIME_NEW_REPLY';
export const REALTIME_EDIT_REPLY = 'REALTIME_EDIT_REPLY';
export const REALTIME_DELETE_REPLY = 'REALTIME_DELETE_REPLY';
export const SET_LOAD_TIME = 'SET_LOAD_TIME';
export const SET_FILTER_MODE = 'SET_FILTER_MODE';
export const SET_FILTER_CONDITION = 'SET_FILTER_CONDITION';
export const SET_REPLIES_FOR_COMMENT = 'SET_REPLIES_FOR_COMMENT';
export const REALTIME_EDIT_POST = 'REALTIME_EDIT_POST';
export const REALTIME_DELETE_POST = 'REALTIME_DELETE_POST';
export const LOAD_COMMENT_TOKEN = 'LOAD_COMMENT_TOKEN';
export const LOAD_COMMENT_FILTER_TOKEN = 'LOAD_COMMENT_FILTER_TOKEN';
export const UPDATE_RELOAD_COMMENT_LIST = 'UPDATE_RELOAD_COMMENT_LIST';
