<template>
  <div class="msg-typing">
    <p class="text-style">
      <span>{{ content }}</span>
      <span class="jumping-dots">
        <span class="dot-1">.</span>
        <span class="dot-2">.</span>
        <span class="dot-3">.</span>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    content() {
      const { content = '', text = '' } = this.message;
      return content.trim() || text.trim() || '';
    }
  }
};
</script>
<style lang="scss" scoped>
.text-style {
  white-space: pre-wrap;
  word-break: break-word;
}

.jumping-dots {
  padding-left: 2px;
  font-weight: bolder;
}
.jumping-dots span {
  position: relative;
  bottom: 0px;
  -webkit-animation: jump 1500ms infinite;
  animation: jump 2s infinite;
}
.jumping-dots .dot-1 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.jumping-dots .dot-2 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.jumping-dots .dot-3 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

@-webkit-keyframes jump {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

@keyframes jump {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}
</style>
