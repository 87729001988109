<template>
  <li class="reply list-group-item shadow-sm">
    <div class="position-relative">
      <img
        :id="`app-img-${avatarId}`"
        :src="userpicture"
        :alt="sender_name"
        class="avatar avatar-online shadow"
        @error="$event.target.src = noAvatar"
      />
      <b-tooltip
        :target="`app-img-${avatarId}`"
        triggers="hover"
        :delay="100"
        boundary="viewport"
        placement="bottom"
      >
        {{ sender_name }}
      </b-tooltip>
      <b-dropdown class="b-dropdown-custom" variant="link" size="lg" no-caret>
        <template slot="button-content">
          <i class="fas fa-ellipsis-h" />
        </template>
        <b-dropdown-item
          v-if="mode !== 'edit' && can_edit"
          href
          @click.prevent="handleEditClick"
        >
          {{ $t('common.text.edit') }}
        </b-dropdown-item>
        <b-dropdown-item href @click.prevent="$emit('delete')">
          {{ $t('common.text.delete') }}
        </b-dropdown-item>
      </b-dropdown>

      <div class="row">
          <div class="col-12 pl-10">
            <h6 class="mt-0 mb-0 text-left pt-5">
              {{ agent_name || sender_name }}
              <small class="text-grey">
                -
                <el-tooltip
                  :content="createTime"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <span class="text-lowercase">{{ timeAgo }}</span>
                </el-tooltip>
              </small>
            </h6>
          </div>
        </div>

      <div v-if="mode !== 'edit'" style="white-space: pre-line;" class="emoji-font">
        <span v-html="urlify(escapeHtmlChar(message))"></span>
      </div>
      <div v-if="isAttachment" class="post-media">
        <div v-if="isImage">
          <app-img :src="attachmentSrc" class="img-comment" alt />
        </div>
        <iframe
          v-if="isMedia"
          :src="attachmentSrc"
          frameborder="0"
          data-width="300"
          height="100%"
        />
      </div>
      <div v-if="mode === 'edit'">
        <textarea v-model="text" :rows="rows" class="form-control" @keyup="handleKeyUp" />
        <div>
          <small class="hint">
            {{
              $t(
                'src.modules.chat.components.CommentBox.CommentDetail.ReplyList.reply-item.press_ctr_+_enter_to_send_or'
              )
            }}
            <a href @click.prevent="handleCancelClick">
              {{ $t('common.confirmModal.cancel') }}
            </a>
          </small>
        </div>
      </div>
      <div>
        <small>
          <i :class="['ml-10', likeClass, likeActive]" @click="like({})" />
          {{ likes_count }}
        </small>
      </div>
    </div>
  </li>
</template>

<script>
import { uuid } from 'vue-uuid';
import { mapActions, mapState } from 'vuex';
import { CHANNEL_MODE } from 'core/constants';
import noAvatar from 'assets/images/no-avatar.png';
import { urlify, escapeHtmlChar, getParamsFromUrl } from 'core/helpers';
import { convertTimeUTC } from 'core/helpers';
import moment from 'moment-timezone';

export default {
  props: {
    comment_id: {
      type: String,
      default: ''
    },
    is_like: {
      type: Number,
      default: 0
    },
    likes_count: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: ''
    },
    userpicture: {
      type: String,
      default: null
    },
    sender_name: {
      type: String,
      default: ''
    },
    agent_name: {
      type: String,
      default: ''
    },
    attachment: {
      type: String,
      default: ''
    },
    can_like: {
      type: Boolean,
      default: false
    },
    can_edit: {
      type: Boolean,
      default: false
    },
    created_time: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      noAvatar,
      mode: null,
      text: '',
      likeUnlikeLoading: false,
      avatarId: uuid.v4()
    };
  },

  computed: {
    ...mapState('session', ['channelsMap']),
    ...mapState('comment', ['selectedComment', 'selectedChannel']),

    isMaintain() {
      return (
        this.channelsMap &&
        this.selectedChannel &&
        this.channelsMap[this.selectedChannel.id].mode === CHANNEL_MODE.MAINTAIN
      );
    },

    rows() {
      const nText = this.text.trim();
      if (nText.replace(/\s/g, '').length === 0) return 1;
      const len = nText.split('\n').length;
      return len > 5 ? 5 : len;
    },

    isAttachment() {
      return this.attachment === '' ? false : true;
    },

    attachmentData() {
      if (!this.isAttachment) return {};
      let att = {};
      if (this.isJsonString(this.attachment)) att = { ...JSON.parse(this.attachment) };
      return att;
    },

    isMedia() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.video ? true : false;
    },

    isImage() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.photo || attachment.type === 'sticker' ? true : false;
    },

    attachmentSrc() {
      const attachment = this.attachmentData;
      if (this.isMedia) {
        return attachment.video;
      } else if (this.isImage) {
        const photo = attachment.type === 'sticker' ? attachment.url : attachment.photo;
        const params = this.getParamsFromUrl(photo);
        const { url = null } = params;
        return url || photo;
      }
      return '';
    },

    likeClass() {
      return this.isLike ? 'fas fa-thumbs-up fa-thumbs-up-custom' : 'far fa-thumbs-up';
    },
    likesCount() {
      return this.likes_count || 0;
    },
    isLike() {
      return this.is_like ? true : false;
    },
    timeAgo() {
      const at = convertTimeUTC(this.created_time, 'comment');
      const now = moment();
      return now.from(at, true);
    },
    createTime() {
      return convertTimeUTC(this.created_time);
    },
    likeActive() {
      return this.can_like ? '' : 'like-disabled';
    }
  },

  watch: {
    mode() {
      this.text = this.message;
    }
  },

  methods: {
    ...mapActions('comment', ['setLikeReply', 'setDisLikeReply']),

    handleEditClick() {
      !this.isMaintain ? (this.mode = 'edit') : null;
      this.$emit('edit');
    },

    handleCancelClick() {
      this.mode = null;
      this.$emit('cancel');
    },

    handleKeyUp(event) {
      const { ctrlKey, key } = event;
      if (ctrlKey && key === 'Enter') {
        this.mode = null;
        this.$emit('onUpdateClick', {
          comment_id: this.comment_id,
          message: this.text,
          sender: this.sender_name
        });
        this.text = '';
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    like() {
      if (!this.can_like || this.likeUnlikeLoading) return;
      if (this.isLike) {
        this.likeUnlikeLoading = true;
        this.setDisLikeReply(this.comment_id).then(
          () => (this.likeUnlikeLoading = false)
        );
      } else {
        this.likeUnlikeLoading = true;
        this.setLikeReply(this.comment_id).then(() => (this.likeUnlikeLoading = false));
      }
    },
    getParamsFromUrl(url) {
      return getParamsFromUrl(url);
    },
    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;

  &.fa-thumbs-up-custom {
    color: #365899;
  }
}

.img-comment {
  max-height: 250px;
  margin: 0 auto;
}

.reply {
  .avatar {
    position: absolute;
    left: -70px;
  }

  textarea {
    padding-left: 5px !important;
    resize: none;
    width: calc(100% - 22px);
  }

  .hint {
    font-size: 9px;
  }

  .btn-group {
    position: absolute;
    top: -10px;
    right: 0;
    cursor: pointer;

    /deep/ button {
      padding: 0;
      margin: 0;
    }
  }

  .b-dropdown-custom {
    /deep/.dropdown-menu {
      min-width: 5rem;
    }
  }
}
.emoji-font {
  font-family: 'Open Sans', sans-serif, 'Noto Color Emoji', 'Apple Color Emoji',
    'Segoe UI Emoji', Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica,
    serif;
}
.post-media {
  padding-top: 10px;
}
.like-disabled {
  cursor: default;
}
</style>
