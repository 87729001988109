export const multi_language_provider = {};

multi_language_provider['en-US'] = {
  no: 'No',
  updatedAt: 'Date Time',
  status: 'Status'
};

multi_language_provider['ja-JP'] = {
  no: 'No.',
  updatedAt: '日時',
  status: 'ステータス'
};

multi_language_provider['zh-CN'] = {
  no: 'No',
  updatedAt: 'Date Time',
  status: 'Status'
};

multi_language_provider['zh-TW'] = {
  no: 'No',
  updatedAt: 'Date Time',
  status: 'Status'
};

export const sub_title = {};

sub_title['en-US'] = {
  projectName: 'Project Name',
  fromDate: 'Start Date',
  toDate: 'End Date',
  agentName: 'Agent Name',
  workingHours: 'Working Hours'
};

sub_title['ja-JP'] = {
  projectName: 'プロジェクト名',
  fromDate: '開始日',
  toDate: '終了日',
  agentName: 'エージェント名',
  workingHours: 'エージェント勤務時間'
};

sub_title['zh-CN'] = {
  projectName: '項目名稱',
  fromDate: '日期起',
  toDate: '日期至',
  agentName: 'Agent Name',
  workingHours: 'Working Hours'
};

sub_title['zh-TW'] = {
  projectName: '項目名稱',
  fromDate: '日期起',
  toDate: '日期至',
  agentName: 'Agent Name',
  workingHours: 'Working Hours'
};

sub_title['vi-VN'] = {
  projectName: 'Project Name',
  fromDate: 'From Date',
  toDate: 'To Date',
  agentName: 'Agent Name',
  workingHours: 'Working Hours'
};

export const sheet_title = {};

sheet_title['en-US'] = {
  sheetName: 'Status Log',
  title: 'Agent Status Log'
};

sheet_title['ja-JP'] = {
  sheetName: 'エージェントステータスログ',
  title: 'エージェントステータスログ'
};

sheet_title['zh-CN'] = {
  sheetName: '人工客服狀況記錄',
  title: '人工客服狀況記錄'
};

sheet_title['zh-TW'] = {
  sheetName: '人工客服狀況記錄',
  title: '人工客服狀況記錄'
};

sheet_title['vi-VN'] = {
  sheetName: 'Status Log',
  title: 'Agent Status Log'
};

export const agent_status_labels = {};

agent_status_labels['en-US'] = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
  BUSY: 'BUSY',
  AWAY: 'AWAY',
  LUNCH: 'LUNCH',
  BREAK: 'BREAK'
};

agent_status_labels['ja-JP'] = {
  OFFLINE: 'オフライン',
  ONLINE: 'オンライン',
  BUSY: '取り込み中',
  AWAY: '離席',
  LUNCH: '昼休憩',
  BREAK: '休憩'
};

agent_status_labels['zh-CN'] = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
  BUSY: 'BUSY',
  AWAY: 'AWAY',
  LUNCH: 'LUNCH',
  BREAK: 'BREAK'
};

agent_status_labels['zh-TW'] = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
  BUSY: 'BUSY',
  AWAY: 'AWAY',
  LUNCH: 'LUNCH',
  BREAK: 'BREAK'
};

agent_status_labels['vi-VN'] = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
  BUSY: 'BUSY',
  AWAY: 'AWAY',
  LUNCH: 'LUNCH',
  BREAK: 'BREAK'
};
