<template>
  <div>
    <div v-if="loading" class="text-center w-100">
      <i class="fas fa-spinner fa-spin fa-lg" />
    </div>
    <scenario-item
      v-for="(scnr, idx) in scnrLst"
      :key="idx"
      :scenario="scnr"
      :id="idx"
      @onChangeData="handleOnChangeData"
    />
  </div>
</template>

<script>
import ScenarioItem from './scenario-item';

export default {
  components: {
    ScenarioItem
  },

  props: {
    scenarios: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {};
  },

  computed: {
    scnrLst() {
      return this.scenarios;
    }
  },

  methods: {
    handleOnChangeData(params) {
      this.$emit('onChangeData', params);
    }
  }
};
</script>

<style lang="scss" scoped>
.chats {
  margin-top: var(--margin-top);
  min-height: calc(100% - 0px);
}
</style>
