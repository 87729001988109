<template>
  <div class="login-component">
    <p class="p-t-25">
      {{ $t('src.modules.session.components.login.sign_into_your_account') }}
    </p>
    <ValidationObserver tag="form" ref="loginForm">
      <div class="p-t-15">
        <!--  Email Agent (S) -->
        <div class="form-group form-group-default">
          <label>{{ $t('src.modules.session.components.login.email') }}</label>
          <div class="controls">
            <base-input
              v-model="formData.email"
              data-testid="wrap-txtEmail"
              :data-test-id="'txtEmail'"
              :align="'vertical-no-label'"
              :prefix="'loginEmail'"
              :required="true"
              :maxlength="320"
              :rules="'email'"
              :name="'email'"
              :label="$t('src.modules.session.components.login.email')"
              :show-hint-text="false"
              :show-word-limit="false"
              @keypressEnter="handleFormSubmit"
            />
          </div>
        </div>
        <!--  Email Agent (E) -->

        <!--  Password (S) -->
        <div class="form-group form-group-default">
          <label>{{ $t('src.modules.session.components.login.password') }}</label>
          <div class="controls">
            <base-input
              v-model="formData.password"
              data-testid="wrap-txtPass"
              :data-test-id="'txtPass'"
              :align="'vertical-no-label'"
              :prefix="'loginPassword'"
              :required="true"
              :maxlength="128"
              :rules="'password'"
              :name="'password'"
              :label="$t('src.modules.session.components.login.password')"
              :input-type="'password'"
              :show-hint-text="false"
              :show-word-limit="false"
              @keypressEnter="handleFormSubmit"
            />
          </div>
        </div>
        <!--  Password (E) -->

        <!-- Forgot password (S) -->
        <div data-testid="wrap-forgot_pass" class="float-left forgot-password">
          <a data-testid="forgot_pass" href="/forgot/password" @click="redirectForgotPasswordPage">
            {{ $t('src.modules.session.components.login.forgot_password') }}
          </a>
        </div>
        <!-- Forgot password (E) -->

        <!-- Language (S) -->
        <div class="dropdown float-right">
          <el-dropdown data-testid="btnLangChange" trigger="click" @command="changeLocale">
            <span class="el-dropdown-link">
              <p class="title-language">
                {{ defaultLanguage.title }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </p>
            </span>
            <el-dropdown-menu data-testid="listLanguage" slot="dropdown">
              <template v-for="entry in languages">
                <el-dropdown-item
                  v-if="entry.language != defaultLanguage.language"
                  :data-testid="'btnLangChange-' + entry.language"
                  :key="entry.title"
                  :command="entry"
                >
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  {{ entry.title }}
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- Language (E) -->

        <!--  Login Agent (S) -->
        <div>
          <button
            data-testid="btnLogin"
            :disabled="loading"
            type="button"
            class="btn btn-primary btn-cons btn-block"
            @click="handleFormSubmit"
          >
            <span v-if="!loading">
              {{ $t('src.modules.session.components.login.sign_in') }}
            </span>
            <span v-else>
              {{ $t('src.modules.session.components.login.waiting') }}
              <i class="fas fa-spinner fa-spin fa-lg" />
            </span>
          </button>
        </div>
        <!--  Login Agent (E) -->

        <!--  Login Google (S) -->
        <div class="mt-5" data-testid="wrap-btnLoginGoogle">
          <GoogleSigninBtn @signIn="handleGoogleSignIn" />
        </div>
        <!--  Login Google (E) -->
        <!--  Login Facebook (S) -->
        <div class="mt-5" data-testid="wrap-btnLoginFB">
          <FacebookSigninBtn @signIn="handleFacebookSignIn" />
        </div>
        <!--  Login Facebook (E) -->

        <!--  Login LDAP (S) -->
        <div class="mt-5" data-testid="wrap-loginLDAP">
          <button
            data-testid="loginLDAP"
            type="button"
            class="btn btn-secondary btn-cons btn-block"
            @click="handleSignInOAuth"
          >
            <img style="height: 25px" src="~assets/images/icons/common/icon-active-directory.svg" />
            {{ $t('src.modules.session.components.login.sign_in_with_ldap') }}
          </button>
        </div>
        <!--  Login LDAP (E) -->
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GoogleSigninBtn from 'components/GoogleSignin';
import FacebookSigninBtn from 'components/FacebookSignin';
import { loadLanguageAsync } from 'locales';
import { DISPLAY_LANGUAGE_NAME } from 'core/constants';
import 'assets/scss/login.scss';

export default {
  components: {
    GoogleSigninBtn,
    FacebookSigninBtn
  },

  data() {
    return {
      loading: false,
      formData: {
        email: '',
        password: ''
      },
      googleSignInParams: {
        client_id: '1003951960852-fc33de5gvo5kv47an7ee94p6mgroivt3.apps.googleusercontent.com'
      },
      defaultLanguage: {
        flag: '',
        language: 'default',
        title: this.$t('src.modules.session.components.login.local_default_language')
      },
      languages: [
        { flag: 'gb', language: 'en-US', title: DISPLAY_LANGUAGE_NAME.en_US },
        { flag: 'jp', language: 'ja-JP', title: DISPLAY_LANGUAGE_NAME.ja_JP },
        { flag: 'tw', language: 'zh-TW', title: DISPLAY_LANGUAGE_NAME.zh_TW },
        { flag: 'kr', language: 'ko-KR', title: DISPLAY_LANGUAGE_NAME.ko_KR }
      ]
    };
  },

  computed: {
    appStage() {
      return process.env.VUE_APP_STAGE;
    }
  },

  mounted() {
    let lang = localStorage.getItem('language');
    if (!lang) {
      //https://www.metamodpro.com/browser-language-codes
      const navlang = navigator.language || navigator.userLanguage;
      const browserLanguagesMapping = [
        { key: 'ja', value: 'ja-JP' },
        { key: 'en-us', value: 'en-US' },
        { key: 'zh-tw', value: 'zh-TW' },
        { key: 'ko-kr', value: 'ko-KR' }
      ];
      const navLanguage = browserLanguagesMapping.filter(item => {
        return item.key.toLowerCase() == navlang.toLowerCase();
      });
      if (navLanguage.length > 0) lang = navLanguage[0].value;
      else lang = 'en-US';
    }

    const language = this.languages.filter(item => {
      return item.language == lang;
    });
    this.reloadStateInit();
    this.defaultLanguage = { ...language[0] };
    this.setGlobalReady(true);
  },

  methods: {
    ...mapActions('session', [
      'login',
      'loginGoogle',
      'loginFacebook',
      'setLanguage',
      'reloadStateInit'
    ]),
    ...mapActions('global', ['setGlobalReady']),

    changeLocale(objLanguage) {
      this.defaultLanguage = { ...objLanguage };
      localStorage.setItem('language', objLanguage.language);
      loadLanguageAsync(objLanguage.language);
    },

    async handleFormSubmit() {
      this.$refs.loginForm.validate().then(async result => {
        if (result) {
          if (this.loading) return;
          try {
            this.loading = true;
            await this.login(this.formData);
          } catch (e) {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: e.message
            });
          }
          this.loading = false;
        } else {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.session.components.login.email_or_password_is_invalid')
          });
        }
      });
    },

    handleGoogleSignIn(idToken) {
      if (!idToken) return;
      this.setGlobalReady(false);
      this.loginGoogle(idToken)
        .then(() => {
          this.setGlobalReady(true);
        })
        .catch(() => this.setGlobalReady(true));
    },

    handleFacebookSignIn(accessToken) {
      if (accessToken == null) return;
      this.setGlobalReady(false);
      this.loginFacebook(accessToken)
        .then(() => {
          this.setGlobalReady(true);
        })
        .catch(() => this.setGlobalReady(true));
    },

    redirectForgotPasswordPage(e) {
      e.preventDefault();
      this.$router.push({ path: '/forgot/password' });
    },

    handleSignInOAuth(e) {
      e.preventDefault();
      this.$router.push({ path: '/adfs/ls' });
    }
  }
};
</script>

<style lang="scss" scoped>
section.section {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh !important;
}

.p-t-25 {
  font-size: 1.2rem;
  font-weight: 600;
}

.forgot-password {
  font-size: 0.8rem;
  padding-bottom: 5px;
  & > a {
    color: #007bff !important;
  }
  & > a:hover {
    text-decoration: underline;
  }
}
.title-language {
  font-size: 0.8rem;
  padding-bottom: 5px;
  color: #007bff !important;
  cursor: pointer;
}
</style>
