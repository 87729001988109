import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en_US from 'languages/i18n/en-US/translation.json';
import ja_JP from 'languages/i18n/ja-JP/translation.json';
import zh_TW from 'languages/i18n/zh-TW/translation.json';
import ko_KR from 'languages/i18n/ko-KR/translation.json';

import element_locale from 'element-ui/lib/locale';
import element_ja_JP from 'element-ui/lib/locale/lang/ja';
import element_zh_TW from 'element-ui/lib/locale/lang/zh-TW';
import element_en_US from 'element-ui/lib/locale/lang/en';
import element_ko_KR from 'element-ui/lib/locale/lang/ko';

Vue.use(VueI18n);

const messages = {
  'en-US': { ...en_US, ...element_en_US },
  'ja-JP': { ...ja_JP, ...element_ja_JP },
  'zh-TW': { ...zh_TW, ...element_zh_TW },
  'ko-KR': { ...ko_KR, ...element_ko_KR }
};

const htmlLang = {
  'en-US': 'en',
  'ja-JP': 'ja',
  'zh-TW': 'zh-TW',
  'ko-KR': 'ko'
};

let defaultLanguage = 'en-US';
if (localStorage.getItem('language') != 'default') {
  defaultLanguage = localStorage.getItem('language');
}
export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en-US',
  messages, // set locale messages
  silentFallbackWarn: true
});

element_locale.i18n((key, value) => {
  return i18n.t(key, value);
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', htmlLang[lang] || 'en');

  return lang;
}

export function loadLanguageAsync(lang) {
  if (lang === 'default') {
    return Promise.resolve(setI18nLanguage(defaultLanguage));
  }
  if (i18n.locale !== lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(setI18nLanguage(lang));
}