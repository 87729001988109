<template>
  <div
    id="contactInformation"
    ref="contactInformation"
  >
    <contact-information-form
      id="CSPContactInformation"
      ref="CSPContactInformation"
      :components="components"
      :channel-id="channelId"
      @invalid="handleInvalidData"
      @submit="handleFormSubmit"
      @onSeclectRequestInformation="handleRequestInformation"
      @changeContactInfo="changeContactInfo"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ContactInformationForm from './form/contactInformationForm';
import moment from 'moment';
export default {
  components: {
    ContactInformationForm
  },

  props: {
    channelId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    platform: {
      type: String,
      default: ''
    },
    userInfoCvs: {
      type: Object,
      default: () => {}
    },
    isSupportForm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      submitFormLoading: false,
      isUpdate: false,
      components: {
        userInformation: {
          prop: 'userInformation',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.user_information'
          ),
          type: 'label',
          show: false
        },
        userId: {
          prop: 'id',
          label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.id'),
          type: 'text',
          maxlength: 60,
          group: 'text',
          show: false,
          disabled: true
        },
        email: {
          prop: 'email',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.email'
          ),
          type: 'text',
          rules: 'email',
          maxlength: 320,
          hintText: this.$t('veevalidate.email.ex'),
          group: 'text1',
          show: false
        },
        userPlatformName: {
          prop: 'userPlatformName',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.user_platform_name'
          ),
          type: 'text',
          maxlength: 60,
          group: 'text',
          show: false,
          disabled: true
        },
        fullName: {
          prop: 'fullName',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.full_name'
          ),
          type: 'text',
          maxlength: 60,
          group: 'text',
          show: false
        },
        firstName: {
          prop: 'firstName',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.first_name'
          ),
          type: 'text',
          show: false,
          maxlength: 30
        },
        lastName: {
          prop: 'lastName',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.last_name'
          ),
          type: 'text',
          maxlength: 30,
          show: false
        },
        birthday: {
          prop: 'birthday',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.birthday'
          ),
          placeholder: 'MM/DD/YYYY',
          type: 'date',
          maxlength: 255,
          rules: 'birthday',
          show: false
        },
        gender: {
          prop: 'gender',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.gender'
          ),
          type: 'radio',
          maxlength: 255,
          show: false
        },
        contactField: {
          prop: 'contactField',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.contact_fields'
          ),
          type: 'label',
          show: false
        },
        phoneNumber: {
          prop: 'phoneNumber',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.phone_number'
          ),
          type: 'text',
          rules: 'phoneNumber',
          hintText: this.$t('veevalidate.phoneNumber.ex'),
          maxlength: 25,
          show: false
        },
        address: {
          prop: 'address',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.address'
          ),
          type: 'text',
          show: false,
          maxlength: 2000
        },
        country: {
          prop: 'country',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.country'
          ),
          show: false,
          type: 'combo'
        },
        province: {
          prop: 'province',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.province'
          ),
          show: false,
          type: 'combo'
        },
        city: {
          prop: 'city',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.city'
          ),
          type: 'text',
          show: false,
          maxlength: 100
        },
        postCode: {
          prop: 'postCode',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.post_code'
          ),
          type: 'text',
          rules: 'postCode',
          hintText: this.$t('veevalidate.postCode.ex'),
          maxlength: 10
        },
        demographicQuestion: {
          prop: 'demographicQuestion',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.demographic_question'
          ),
          type: 'label',
          show: false
        },
        maritalStatus: {
          prop: 'maritalStatus',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.marital_status'
          ),
          type: 'combo',
          show: false
        },
        relationship: {
          prop: 'relationship',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.relationship'
          ),
          type: 'text',
          show: false,
          maxlength: 100
        },
        militaryStatus: {
          prop: 'militaryStatus',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.military_status'
          ),
          type: 'text',
          show: false,
          maxlength: 100
        },
        workInformation: {
          prop: 'workInformation',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_information'
          ),
          type: 'label',
          show: false
        },
        occupation: {
          prop: 'occupation',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.occupation'
          ),
          type: 'text',
          show: false,
          maxlength: 100
        },
        companyName: {
          prop: 'companyName',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.company_name'
          ),
          type: 'text',
          show: false,
          maxlength: 60
        },
        workPhoneNumber: {
          prop: 'workPhoneNumber',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_phone_number'
          ),
          type: 'text',
          rules: 'phoneNumber',
          hintText: this.$t('veevalidate.phoneNumber.ex'),
          show: false,
          maxlength: 25
        },
        workEmail: {
          prop: 'workEmail',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_email'
          ),
          type: 'text',
          rules: 'email',
          show: false,
          maxlength: 320,
          hintText: this.$t('veevalidate.email.ex')
        },
        permission: {
          prop: 'permission',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.permission'
          ),
          type: 'label',
          show: false
        },
        privacyPolicy: {
          prop: 'privacyPolicy',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.privacy_policy'
          ),
          show: false,
          type: 'checkbox'
        },
        tc: {
          prop: 'tc',
          label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.tc'),
          show: false,
          type: 'checkbox'
        },
        customize: {
          prop: 'others',
          label: this.$t(
            'src.modules.chat.components.CustomerSupportPanel.contactInformation.others'
          ),
          type: 'label',
          show: false
        }
      },
      errors: [],
      translateData: {},
      locales: {
        ar: 'ar',
        bg: 'bg',
        ca: 'ca',
        cs: 'cs',
        da: 'da',
        de: 'de',
        el: 'el',
        en: 'en',
        es: 'es',
        et: 'et',
        eu: 'eu',
        fa: 'fa',
        fi: 'fi',
        fr: 'fr',
        he: 'he',
        hr: 'hr',
        hu: 'hu',
        id: 'id',
        it: 'it',
        ja: 'ja',
        ka: 'ka',
        ko: 'ko',
        lt: 'lt',
        lv: 'lv',
        ms_MY: 'ms_MY',
        nb_NO: 'nb_NO',
        ne: 'ne',
        nl: 'nl',
        nn_NO: 'nn_NO',
        pl: 'pl',
        pt_BR: 'pt_BR',
        pt_PT: 'pt_PT',
        ro: 'ro',
        ru: 'ru',
        sk: 'sk',
        sl: 'sl',
        sq: 'sq',
        sr: 'sr',
        sr_Latin: 'sr_Latin',
        sv: 'sv',
        th: 'th',
        tr: 'tr',
        uk: 'uk',
        vi: 'vi',
        zh_HK: 'zh_CN',
        zh_CN: 'zh_CN',
        zh_TW: 'zh_TW'
      }
    };
  },

  computed: {
    ...mapState('personal', [
      'personalConfig',
      'personalInfo',
      'personalCustomizeDefault',
      'displayConfig',
      'requiredAgent',
      'translationsDefault',
      'defaultLanguage',
      'userInfo',
      'supportLanguages',
      'channelName',
      'personalFormOption',
      'personalInfoActive'
    ])
  },

  watch: {
    async userInfoCvs(newV, old) {
      this.setGlobalReady(true);
      if (newV?.userId !== old?.userId) {
        await this.handleAfterSDKLoading();
      }
    }
  },

  async created() {
    this.setGlobalReady(true);
    await this.handleAfterSDKLoading();
  },

  methods: {
    ...mapActions('personal', [
      'submitForm',
      'getPersonalInfo',
      'setPersonalInfoFB',
      'getPersonalSetting',
      'getPersonalSettingSupportForm'
    ]),
    ...mapActions('global', ['setGlobalReady']),

    listenEventSubmit() {
      const contactInformationForm = this.$refs['CSPContactInformation'];
      contactInformationForm && contactInformationForm.handleSubmitForm();
    },

    changeContactInfo(value) {
      this.$emit('changeContactInfo', value);
    },

    translatePersonalFields(translateData, key) {
      const { translations } = translateData;
      let res = '';
      if (translations[key]) {
        res = translations[key];
      } else if (this.components[key]) {
        res = this.components[key].label;
      } else res = key;
      return res;
    },

    buildCustomizePersonalComponent() {},

    validateForm: function () {},

    handleFormSubmit(formData) {
      this.submitFormLoading = true;
      const paramForm = {
        ...formData,
        userId: this.userId,
        channelId: this.channelId,
        projectId: this.projectId,
        platform: this.platform
      };

      const data = this.clearData({ ...paramForm });

      this.submitForm({
        formPersonalInfo: { ...data, birthday: this.m_formatTime(data.birthday) },
        isUpdate: this.isUpdate
      }).then(response => {
        this.$emit('changeContactInfo', false);
        this.submitFormLoading = false;
        if (response.error) {
          this.$emit('invalidData', response);
        } else {
          this.$emit('submitContactSuccess', true);
          this.saveSuccess(
            this.$t(
              'src.modules.chat.components.CustomerSupportPanel.contactInformation.contact_information_was_saved'
            )
          );
        }
      });
    },

    handleInvalidData(error) {
      this.$emit('submitContactSuccess', false);
      this.$emit('invalidData', error);
    },

    clearData(obj) {
      delete obj.updatedAt;
      delete obj.createdAt;
      if (this.isUpdate)
        for (let propName in obj) {
          if (obj[propName] === '') {
            obj[propName] = null;
          }
        }
      else
        for (let propName in obj) {
          if (obj[propName] === '' || obj[propName] === null) {
            delete obj[propName];
          }
        }
      return obj;
    },

    async handleAfterSDKLoading() {
      if (!this.userId || !this.channelId || !this.projectId || !this.platform) return null;
      const params = {
        userId: this.userId,
        channelId: this.channelId,
        platform: this.platform,
        projectId: this.projectId
      };

      if (this.isSupportForm) {
        await this.getPersonalSettingSupportForm(params);
      } else {
        await this.getPersonalSetting(params);
      }
      const personalInfo = await this.getPersonalInfo(params);
      this.translateData.translations = this.translationsDefault;
      if (Object.keys(personalInfo).length > 0) {
        this.isUpdate = true;
      } else {
        if (this.userInfoCvs) {
          let objGender = {};
          if (this.userInfoCvs.gender === 'male') {
            objGender = { key: 'male', value: this.personalFormOption['male'] };
          } else if (this.userInfoCvs.gender === 'female') {
            objGender = {
              key: 'female',
              value: this.personalFormOption['female']
            };
          }
          const allUserInfo = {
            ...{
              firstName: this.userInfoCvs.firstName || '',
              lastName: this.userInfoCvs.lastName || '',
              fullName: this.userInfoCvs.name || '',
              dateOfBirth: this.userInfoCvs.dateOfBirth || '',
              gender: { ...objGender },
              email: this.userInfoCvs.email || ''
            }
          };
          this.setPersonalInfoFB(allUserInfo);
        }
      }

      if (Object.keys(this.personalCustomizeDefault).length > 0) {
        this.components.customize.show = true;
        this.components.customize.label = this.translatePersonalFields(
          this.translateData,
          'others'
        );
      }
      const customizeFields = Object.keys(this.personalCustomizeDefault).sort().reduce((res, key) => {
        const field = this.personalCustomizeDefault[key];
        res[key] = {
          ...field,
          label:
            (this.personalCustomizeDefault[key] && this.personalCustomizeDefault[key].label) || key
        };
        return res;
      }, {});
      const thisComps = { ...this.components, ...customizeFields };
      Object.keys(this.displayConfig).forEach(cKey => {
        if (thisComps[cKey] && this.displayConfig[cKey]) {
          thisComps[cKey].show = this.displayConfig[cKey];
          switch (cKey) {
            case 'userId':
            case 'email':
            case 'fullName':
            case 'firstName':
            case 'lastName':
            case 'birthday':
            case 'gender':
              if (thisComps[cKey].show === true) {
                thisComps.userInformation.show = true;
                thisComps.userInformation.label = this.translatePersonalFields(
                  this.translateData,
                  'userInformation'
                );
              }
              break;
            case 'userPlatformName':
              if (thisComps[cKey].show === true) {
                thisComps.userInformation.show = true;
                thisComps.userInformation.label = this.translatePersonalFields(
                  this.translateData,
                  'userInformation'
                );
              }
              thisComps[cKey].ignoreValidate = true;
              break;
            case 'phoneNumber':
            case 'address':
            case 'country':
            case 'province':
            case 'postCode':
            case 'city':
              if (thisComps[cKey].show === true) {
                thisComps.contactField.show = true;
                thisComps.contactField.label = this.translatePersonalFields(
                  this.translateData,
                  'contactField'
                );
              }
              break;
            case 'maritalStatus':
            case 'relationship':
            case 'militaryStatus':
              if (thisComps[cKey].show === true) {
                thisComps.demographicQuestion.show = true;
                thisComps.demographicQuestion.label = this.translatePersonalFields(
                  this.translateData,
                  'demographicQuestion'
                );
              }
              break;

            case 'occupation':
            case 'workPhoneNumber':
            case 'workEmail':
            case 'companyName':
              if (thisComps[cKey].show === true) {
                thisComps.workInformation.show = true;
                thisComps.workInformation.label = this.translatePersonalFields(
                  this.translateData,
                  'workInformation'
                );
              }
              break;
            case 'privacyPolicy':
            case 'tc':
              if (thisComps[cKey].show === true) {
                thisComps.permission.show = true;
                thisComps.permission.label = this.translatePersonalFields(
                  this.translateData,
                  'permission'
                );
              }
              thisComps[cKey].disabled = true;
              thisComps[cKey].ignoreValidate = true;
              break;
          }
          thisComps[cKey].label = this.translatePersonalFields(this.translateData, cKey);
        }
      });

      Object.keys(this.requiredAgent).forEach(rKey => {
        if (thisComps[rKey] && thisComps[rKey].show && this.requiredAgent[rKey]) {
          thisComps[rKey].required = this.requiredAgent[rKey];
        }
      });
      this.components = { ...thisComps };
    },

    saveSuccess(value) {
      this.$emit('saveSuccess', value);
    },

    handleRequestInformation() {
      this.$emit('onSeclectRequestInformation');
    },

    m_formatTime(value) {
      return value ? moment(value).format('YYYY-MM-DD') : null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
