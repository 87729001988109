<template>
  <div id="modal-select-expored-columns" class="modal-content div">
    <header class="modal-header">
      <h5
        class="modal-title"
        v-html="
          $t('src.modules.report.components.ColumnFilterModal.index.select_fields_title')
        "
      ></h5>
    </header>
    <div class="modal-body">
      <div class="mdl-grid mdl-grid center-items">
        <div
          id="sdt-selected-tags"
          class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet"
        >
          <div class="panel-body sdt-teal-switch text-center">
            <label>
              <span class="mdl-switch__label remove-column"
                >{{
                  $t(
                    'src.modules.report.components.ColumnFilterModal.index.removed_columns'
                  )
                }}
              </span>
            </label>
          </div>

          <button
            class="sdt-sortable-btn sdt-sort-button mdl-button mdl-button--fab mdl-button--mini-fab mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent mdl-color--teal-100"
            @click="moveUnclockTags(selected, available)"
          >
            <i class="material-icons  sdt-color-teal">
              <arrow-right-icon />
            </i>
          </button>

          <button
            class="sdt-removeable-btn sdt-remove-button mdl-button mdl-button--fab mdl-button--mini-fab mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent mdl-color--teal-100"
            @click="rmOrderList(selected.tags)"
          >
            <i
              class="sdt-tag-icon sdt-tag-icon-left material-icons sdt-color-teal sdt-tag-icon"
            >
              <sort-alphabetical-icon />
            </i>
          </button>

          <div
            class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--2dp mdl-color--teal-500"
          >
            <draggable
              v-model="selected.tags"
              :options="getOptions(selected)"
              :move="onMove"
              element="span"
              @start="isDragging = true"
              @end="isDragging = true"
            >
              <transition-group
                :name="'flip-list'"
                type="transition"
                class="mdl-list dragArea"
                tag="ul"
              >
                <li
                  v-for="(element, index) in selected.tags"
                  :key="element.tag"
                  class="sdt-tag mdl-cell mdl-cell--12-col sdt-tag-item mdl-list__item mdl-card mdl-shadow--2dp"
                >
                  <span class="mdl-list__item-primary-content">
                    <i
                      :class="[element.fixed ? 'sdt-color-red' : 'sdt-color-gray']"
                      class="material-icons sdt-tag-icon sdt-tag-icon-left"
                      @click="element.fixed = !element.fixed"
                    >
                      <lock-icon />
                    </i>
                    {{ element.tag }}
                  </span>
                  <i
                    class="material-icons sdt-tag-icon sdt-tag-icon-right sdt-color-teal"
                    @click="onClick(index, selected, available)"
                  >
                    <arrow-right-icon />
                  </i>
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>

        <div
          id="sdt-available-tags"
          class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet"
        >
          <div class="panel-body sdt-indigo-switch text-center">
            <label>
              <span class="mdl-switch__label export-column"
                >{{
                  $t(
                    'src.modules.report.components.ColumnFilterModal.index.exported_columns'
                  )
                }}
              </span>
            </label>
          </div>
          <button
            class="sdt-removeable-btn sdt-remove-button mdl-button mdl-button--fab mdl-button--mini-fab mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent mdl-color--indigo-100"
            @click="moveUnclockTags(available, selected)"
          >
            <i
              class="sdt-tag-icon sdt-tag-icon-left material-icons sdt-color-indigo sdt-tag-icon"
            >
              <arrow-left-icon />
            </i>
          </button>
          <button
            class="sdt-sortable-btn sdt-sort-button mdl-button mdl-button--fab mdl-button--mini-fab mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent mdl-color--indigo-100"
            @click="exOrderList(available.tags)"
          >
            <i class="material-icons sdt-color-indigo">
              <sort-alphabetical-icon />
            </i>
          </button>
          <div
            class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--2dp mdl-color--indigo-500"
          >
            <draggable
              v-model="available.tags"
              :options="getOptions(available)"
              :move="onMove"
              element="span"
              @start="isDragging = true"
              @end="isDragging = true"
            >
              <transition-group
                :name="'flip-list'"
                type="transition"
                class="mdl-list dragArea"
                tag="ul"
              >
                <li
                  v-for="(element, index) in available.tags"
                  :key="element.tag"
                  class="sdt-tag mdl-cell mdl-cell--12-col sdt-tag-item mdl-list__item mdl-card mdl-shadow--2dp"
                  :style="element.disabled ? disabledRowExportStyle : ''"
                >
                  <span class="mdl-list__item-primary-content">
                    <i
                      :class="[element.fixed ? 'sdt-color-gray' : 'sdt-color-indigo']"
                      class="sdt-tag-icon sdt-tag-icon-left material-icons sdt-tag-icon"
                      @click="onClick(index, available, selected)"
                    >
                      <arrow-left-icon />
                    </i>
                    {{ element.tag }}
                  </span>
                  <i
                    :class="[element.fixed ? 'sdt-color-red' : 'sdt-color-gray']"
                    class="material-icons sdt-tag-icon sdt-tag-icon-right"
                    :style="element.disabled ? disabledRowExportStyle : ''"
                    @click="
                      element.fixed = element.disabled ? element.fixed : !element.fixed
                    "
                  >
                    <lock-icon />
                  </i>
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-primary" @click="selectExportedColumns">
        {{ $t('src.modules.report.components.ColumnFilterModal.index.ok_title_but') }}
      </button>
    </footer>
  </div>
  <!-- </b-modal> -->
</template>
<script>
import 'material-design-lite/material.min.css';
import 'vue-material-design-icons/styles.css';
import draggable from 'vuedraggable';
import LockIcon from 'vue-material-design-icons/Lock.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';
import SortAlphabeticalIcon from 'vue-material-design-icons/SortAlphabetical.vue';
import 'material-design-lite/material.min.js';
import * as defaultParams from '../../store/define';

export default {
  name: 'ColumnFilterModal',

  components: {
    draggable,
    LockIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    SortAlphabeticalIcon
  },
  props: {
    locale: {
      type: String,
      default: 'en-US'
    }
  },

  data() {
    return {
      ...defaultParams.columnFilter(this.locale), // parameter for filter colums export
      rmSortClick: 0,
      exSortClick: 0,
      disabledRowExportStyle: {
        opacity: 0.8,
        cursor: 'not-allowed !important'
      }
    };
  },

  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },

  methods: {
    selectExportedColumns(bvModalEvt) {
      bvModalEvt.preventDefault();
      let selectedColumns = [];
      let selectedColumnsName = [];
      this.available.tags.forEach(tag => {
        selectedColumns.push(tag.slug);
        selectedColumnsName.push(tag.tag);
      });
      this.$emit('clickOKButton', selectedColumns, selectedColumnsName);
    },

    show() {
      this.$refs.modalSelectExporedColumns.show();
    },

    exOrderList(datalist) {
      this.exSortClick++;
      this.orderList(datalist, this.exSortClick);
    },

    rmOrderList(datalist) {
      this.rmSortClick++;
      this.orderList(datalist, this.rmSortClick);
    },

    orderList(datalist, click) {
      if (click % 2 === 1) {
        datalist.sort((one, two) => {
          return one.tag.localeCompare(two.tag);
        });
      } else {
        datalist.reverse(datalist);
      }
    },

    moveUnclockTags(exportList, removeList) {
      exportList.tags.forEach(exportTag => {
        if (!exportTag.fixed) {
          removeList.tags.push(exportTag);
        }
      });
      exportList.tags = exportList.tags.filter(exportTag => exportTag.fixed);
    },

    onClick(index, from, to) {
      var current = from.tags[index];
      if (!current.fixed && from.editable && to.editable && to.droppable) {
        if (
          from.cloneable ||
          to.tags.filter(function(e) {
            return e.tag == current.tag;
          }).length === 0
        ) {
          to.tags.push(current);
        }
        if (!from.cloneable) {
          from.tags.splice(index, 1);
        }
      }
    },

    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },

    getOptions(place) {
      return {
        sort: true,
        animation: 0,
        group: {
          name: 'tags',
          pull: place.cloneable ? 'clone' : true,
          put: place.droppable
        },
        disabled: !place.editable,
        ghostClass: 'ghost'
      };
    }
  }
};
</script>
