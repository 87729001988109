<template>
  <div class="nat-grid">
    <div class="nat-grid-search">
      <base-input
        v-model="searchText"
        :name="'filter-file'"
        :placeholder="
          $t(
            'src.modules.customer-support-history.index.search_by_category_customer_name_or_title'
          )
        "
        @input="handleSearchCustomer"
        @keyup.enter="handleSearchCustomer"
        :icon="'search'"
        :align="'horizontal'"
        :maxlength="-1"
      />
    </div>
    <div class="nat-grid-result">
      <!-- Table (S) -->
      <el-table
        v-loading="loading"
        ref="singleTable"
        :data="c_agents"
        highlight-current-row
        style="width: 100%"
        @current-change="handleCurrentChange"
      >
        <div slot="empty">
          {{ $t('common.text.no_data') }}
        </div>
        <el-table-column
          :label="$t('src.modules.customer-support-history.index.no')"
          width="50"
        >
          <template slot-scope="scope">
            <span> {{ m_index(scope.$index) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('src.modules.chat.components.AssignBox.index.agent_name')"
          property="agentName"
        ></el-table-column>
        <el-table-column
          :label="$t('common.text.role')"
          property="role"
        ></el-table-column>
        <el-table-column
          :label="$t('src.modules.chat.components.AssignBox.index.users_per_agent')"
          property="usersPerAgent"
        ></el-table-column>
      </el-table>
    </div>
    <!-- Table (E) -->
    <!-- Pagination (S) -->
    <div class="nat-grid-pagination">
      <el-pagination
        ref="pagination"
        :total="c_cntAgents"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        @current-change="m_currentChange"
      ></el-pagination>
    </div>
    <!-- Pagination (E) -->
  </div>
</template>

<script>
import './vee-validate';
export default {
  name: 'base-gird',
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'name'
    },
    maxlength: {
      type: Number,
      default: 10
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  computed: {
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseRequired = { label: this.label };
      return allRule;
    },
    c_placeholder() {
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.placeholder', { fieldName: this.label });
    }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.content);
    },
    handecomposeUpdate(compositionEvent) {
      this.content = compositionEvent.data || '';
    }
  }
};
</script>

<style lang="scss" scoped>
.nat-grid-search {
  display: block;
  padding-top: 15px;
  padding-right: 0px;
}
.nat-grid-pagination {
  text-align: center;
  margin-top: 30px;
}
</style>
