import {
  SET_COMMENT_LIST,
  SET_SELECTED_CHANNEL,
  SET_READY,
  SET_SELECTED_COMMENT,
  REPLY_COMMENT,
  DELETE_REPLY,
  UPDATE_REPLY,
  MARK_READ_COMMENT,
  MARK_LIKE_COMMENT,
  MARK_UNLIKE_COMMENT,
  MARK_LIKE_REPLY,
  MARK_UNLIKE_REPLY,
  SET_COMMENT_NEXT_PAGING,
  SET_FIRST_PAGING,
  REALTIME_EDIT_POST,
  REALTIME_DELETE_POST,
  REALTIME_NEW_COMMENT,
  REALTIME_EDIT_COMMENT,
  REALTIME_DELETE_COMMENT,
  SET_LOAD_TIME,
  SET_FILTER_MODE,
  SET_FILTER_CONDITION,
  SET_REPLIES_FOR_COMMENT,
  LOAD_COMMENT_TOKEN,
  LOAD_COMMENT_FILTER_TOKEN,
  UPDATE_RELOAD_COMMENT_LIST
} from './types';
import { CommentService } from './service';

const cs = new CommentService();

export const setSelectedComment = ({ commit }, comment) => {
  commit(SET_SELECTED_COMMENT, comment);
};

export const setReadComment = async ({ commit }, comment_id) => {
  const comments = await cs.readComment({ comment_id });
  if (comments) {
    commit(MARK_READ_COMMENT);
  }
};

export const setLikeComment = async ({ commit }, comment_id) => {
  const res = await cs.likeComment({ comment_id });
  if (res) {
    commit(MARK_LIKE_COMMENT);
  }
  return res;
};
export const setDisLikeComment = async ({ commit }, comment_id) => {
  const res = await cs.disLikeComment({ comment_id });
  if (res) {
    commit(MARK_UNLIKE_COMMENT);
  }
  return res;
};
export const setLikeReply = async ({ commit }, comment_id) => {
  const res = await cs.likeComment({ comment_id });
  if (res) {
    commit(MARK_LIKE_REPLY, comment_id);
  }
  return res;
};
export const setDisLikeReply = async ({ commit }, comment_id) => {
  const res = await cs.disLikeComment({ comment_id });
  if (res) {
    commit(MARK_UNLIKE_REPLY, comment_id);
  }
  return res;
};

export const commentFilter = async ({ commit }, params) => {
  try {
    const [comments = [], next = ''] = await cs.commentFilter(params);
    commit(SET_COMMENT_LIST, { comments: comments || [], isFilter: true });
    commit(LOAD_COMMENT_FILTER_TOKEN, next);
    commit(SET_READY, true);
    return comments;
  } catch (_) {
    commit(SET_READY, true);
    return null;
  }
};

export const loadMoreCommentFilter = async ({ commit }, params) => {
  try {
    const [comments = [], next = ''] = await cs.commentFilter(params);
    commit(SET_COMMENT_LIST, {
      comments: comments || [],
      isFilter: true,
      loadMore: true
    });
    commit(LOAD_COMMENT_FILTER_TOKEN, next);
    commit(SET_READY, true);
    return comments;
  } catch (_) {
    commit(SET_READY, true);
    return null;
  }
};

export const getCommentsByChannel = async ({ commit, dispatch }, params) => {
  try {
    const [comments = [], next = ''] = await cs.getComments(params);
    commit(SET_COMMENT_LIST, { comments: comments || [] });
    commit(LOAD_COMMENT_TOKEN, next);
    dispatch('global/setGlobalReady', true, { root: true });
    commit(SET_READY, true);
    return comments;
  } catch (_) {
    dispatch('global/setGlobalReady', true, { root: true });
    commit(SET_READY, true);
    return null;
  }
};

export const getRepliesByCommentId = async (context, params) => {
  try {
    const { commit } = context;
    const { comment_id } = params;
    const replies = await cs.getReplies({ comment_id });
    commit(SET_REPLIES_FOR_COMMENT, { replies, comment_id });
    return replies;
  } catch (_) {
    return null;
  }
};

export const reloadCommentAndPost = async ({ commit }, params) => {
  try {
    const data = await cs.reloadCommentAndPost(params);
    commit(UPDATE_RELOAD_COMMENT_LIST, { data: data || {}, });
    return data;
  } catch (err) {
    return null;
  }
};

export const loadMoreComments = async ({ commit }, params) => {
  try {
    const [comments = [], next = ''] = await cs.getComments(params);
    if (!comments || comments.length === 0) return [];
    commit(SET_COMMENT_LIST, { comments: comments || [], loadMore: true });
    commit(LOAD_COMMENT_TOKEN, next);
    commit(SET_READY, true);
    return comments;
  } catch (_) {
    commit(SET_READY, true);
    return null;
  }
};

export const realTimeData = async (context, cmt) => {
  const { commit, state } = context;
  const { comments, selectedComment } = state;
  const { object, action, id } = cmt;

  switch (object) {
    case 'post':
      if (action === 'edited') {
        const params = { post_id: id };
        const post = await cs.getAPost(params);
        commit(REALTIME_EDIT_POST, { post, post_id: id });
      } else if (action === 'remove') commit(REALTIME_DELETE_POST, { post_id: id });
      break;
    case 'comment':
      if (action === 'add') {
        const comment = await cs.getAComment({ comment_id: id });
        commit(REALTIME_NEW_COMMENT, comment);
      } else if (action === 'edited') {
        const comment = await cs.getAComment({ comment_id: id });
        commit(REALTIME_EDIT_COMMENT, comment);
      } else if (action === 'remove') {
        commit(REALTIME_DELETE_COMMENT, { comment_id: id });
      } else if (action === 'like') {
        const isComment = comments.filter(c => c.comment_id === id).length;
        if (isComment) {
          const comment = await cs.getAComment({ comment_id: id });
          commit(REALTIME_EDIT_COMMENT, comment);
        } else {
          const commentHasReply = comments.filter(
            c =>
              c.reply_list && c.reply_list.length > 0 && c.reply_list.some(i => i.comment_id === id)
          );

          if (commentHasReply.length) {
            const { comment_id } = commentHasReply[0];
            await getRepliesByCommentId(context, { comment_id });
          }
        }
      }
      break;
    case 'reply':
      if (['add', 'edited', 'remove'].includes(action)) {
        const [replies] = await Promise.all([
          cs.getReplies({ comment_id: id }),
          selectedComment.comment_id === id && action === 'add' ? setReadComment(context, id) : null
        ]);
        commit(SET_REPLIES_FOR_COMMENT, { action, replies, comment_id: id });
      }
      break;
  }
};

export const commentNextPaging = async ({ commit }, channelId) => {
  commit(SET_COMMENT_NEXT_PAGING, channelId);
};

export const setSelectedChannel = ({ commit }, channel) => {
  commit(SET_SELECTED_CHANNEL, channel);
  if (channel && channel.id) {
    commit(SET_FIRST_PAGING, channel.id);
  }
};

export const clearComments = ({ commit }) => {
  commit(SET_COMMENT_LIST, { comments: [] });
};

export const setCommentReady = ({ commit }, flag) => {
  commit(SET_READY, flag);
};

export const replyToComment = async ({ commit }, comment) => {
  const {
    message,
    post_id,
    parent_comment_id,
    page_id,
    userpicture,
    agent_id,
    agent_name,
    platform
  } = comment;

  const res = await cs.replyToComment({
    page_id,
    parent_comment_id,
    message,
    platform,
    agent_id,
    agent_name
  });
  if (!res || !res.hasOwnProperty('id')) return;
  const c = {
    attachment: 'default',
    comment_id: res.id,
    created_time: new Date().toISOString(),
    is_read: '1',
    message,
    parent_comment_id,
    post_id,
    sender_id: agent_id,
    sender_name: agent_name,
    can_like: res.can_like,
    can_reply: res.can_reply,
    can_edit: res.can_edit,
    userpicture
  };
  commit(REPLY_COMMENT, c);
  return res;
};

export const updateReply = async ({ commit }, reply) => {
  const res = await cs.updateReply(reply);
  if (res) {
    commit(UPDATE_REPLY, reply);
  }
  return res;
};

export const deleteReply = async ({ commit }, reply) => {
  const res = await cs.deleteReply(reply);
  if (res) {
    commit(DELETE_REPLY, reply);
  }
  return res;
};

export const setLoadTime = ({ commit }, time) => {
  commit(SET_LOAD_TIME, time);
};

export const setFilterMode = ({ commit }, boolean) => {
  commit(SET_FILTER_MODE, boolean);
};

export const setFilterCondition = ({ commit }, condition) => {
  commit(SET_FILTER_CONDITION, condition);
};
