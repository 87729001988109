import { AGENT_ROLE } from 'core/constants';

export const configs = {
  initialSettingsDialog: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER],
  bellIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER],
  menus: {
    dashboardIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
    chatBoxIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER],
    chatBoxPhoneAgentIcon: [AGENT_ROLE.PHONE_AGENT],
    commentsIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER],
    customerSupportHistoryIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
    reportIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
    searchIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
    messageReplaceIcon: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
    fileManagementIcon: {
      projectWithGroup: [AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT],
      projectWithoutGroup: [AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT]
    },
    accessLogIcon: {
      projectWithGroup: [AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER],
      projectWithoutGroup: [AGENT_ROLE.LEADER],
    } 
  },
  profile: {
    profile: [],
    settingsPage: [AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR, AGENT_ROLE.LEADER]
  },
  dashboard: {},
  chat: {},
  comments: {},
  customerSupportHistory: {},
  report: {},
  search: {},
  messageReplace: {},
  fileManagement: {},
  accessLog: {},
};
