<template>
  <div class="forgot-password-component">
    <p class="p-t-25">
      {{ $t('src.modules.session.components.forgotPassword.reset_password') }}
    </p>
    <form class="p-t-15">
      <div class="form-group form-group-default">
        <label>{{ $t('src.modules.session.components.forgotPassword.email') }}</label>
        <div class="controls">
          <ValidationObserver tag="form" ref="forgotPassword">
            <base-input
              v-model="formData.email"
              :align="'vertical-no-label'"
              :prefix="'loginEmail'"
              :required="true"
              :maxlength="320"
              :rules="'email'"
              :name="'email'"
              :label="$t('src.modules.session.components.login.email')"
              :show-hint-text="false"
              :show-word-limit="false"
              @keypressEnter="handleFormSubmit"
            />
          </ValidationObserver>
        </div>
      </div>
      <div class="float-left forgot-password">
        {{
          $t(
            'src.modules.session.components.forgotPassword.already_have_login_and_password'
          )
        }}
        <a href="/login" @click="redirectSignInPage">
          {{ $t('src.modules.session.components.forgotPassword.sign_in') }}
        </a>
      </div>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary btn-cons m-t-10 btn-block"
        @click="handleFormSubmit"
      >
        <span v-if="!loading">{{
          $t('src.modules.session.components.forgotPassword.reset_password')
        }}</span>
        <span v-else>
          {{ $t('src.modules.session.components.forgotPassword.waiting') }}
          <i class="fas fa-spinner fa-spin fa-lg" />
        </span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import 'assets/scss/login.scss';

export default {
  data() {
    return {
      loading: false,
      fullYear: '',
      formData: {
        email: ''
      },
      fullscreenLoading: false
    };
  },

  mounted() {
    this.setGlobalReady(true);
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('session', ['submitFormForgotPassword']),

    async handleFormSubmit(e) {
      e.preventDefault();
      this.$refs.forgotPassword.validate().then(async result => {
        if (result) {
          try {
            const { email = '' } = this.formData;
            this.loading = true;
            const rltSubmitFormForgotPassword = await this.submitFormForgotPassword(
              email
            );
            if (rltSubmitFormForgotPassword) {
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t(
                  'src.modules.session.components.forgotPassword.the_link_to_reset_the_password_has_been_sent_to_your_mail'
                )
              });
            }
            this.loading = false;
          } catch (e) {
            this.loading = false;
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: e.message
            });
          }
        } else {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.password.index.please_check_the_invalid_data')
          });
        }
      });
    },

    openFullScreen(isLoading) {
      const loading = this.$loading({
        lock: true,
        background: 'rgba(255,255,255,0.9)'
      });

      if (!isLoading) loading.close();
    },

    redirectSignInPage(e) {
      e.preventDefault();
      this.$router.push({ path: '/login' });
    }
  }
};
</script>

<style lang="scss" scoped>
.p-t-25 {
  font-size: 1.2rem;
  font-weight: 600;
}

section.section {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh !important;
}

.forgot-password {
  font-size: 0.8rem;
  padding-bottom: 5px;
  & > a {
    color: #007bff !important;
  }
  & > a:hover {
    text-decoration: underline;
  }
}
</style>
