import {
  SET_USERS_INFO,
  SET_SELECTED_USER_INFO,
  SET_MESSAGES_HISTORY,
  ENABLE_BTN_WBH,
  SET_PARAMS_SEARCH_BOX
} from './types';
import { getTranslationsMapping } from 'core/helpers';

import * as getters from './getters';
import * as actions from './actions';

const state = {
  usersInfo: [],
  selectedUserInfo: {},
  messagesHistory: [],
  enableBtnWebBrowsingHistory: false,
  paramsFromPhoneAgentSearchBox: {}
};


const mutations = {
  [SET_USERS_INFO](state, usersInfo) {
    state.usersInfo = usersInfo || [];
  },

  [SET_SELECTED_USER_INFO](state, selectedUserInfo) {
    if (selectedUserInfo.userInfo) {
      const { userInfo, channelId } = selectedUserInfo;
      const translations = getTranslationsMapping(userInfo, channelId);
      selectedUserInfo.translations = translations;
    }
    state.selectedUserInfo = selectedUserInfo || {};
  },

  [SET_MESSAGES_HISTORY](state, { messages = [], clear = false }) {
    if (clear) state.messagesHistory = [];
    else state.messagesHistory = [...messages, ...state.messagesHistory];
  },

  [ENABLE_BTN_WBH](state, bool) {
    state.enableBtnWebBrowsingHistory = bool;
  },

  [SET_PARAMS_SEARCH_BOX](state, params) {
    state.paramsFromPhoneAgentSearchBox = params;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
