import {
  SET_PERSONAL_INFO,
  SET_PERSONAL_INFO_FB,
  SET_PERSONAL_GLOBAL,
  SET_PERSONAL_SETTING,
  SET_CHANNEL_NAME
} from './types';
import Service from 'core/service';
import { i18n } from 'locales';
import pick from 'lodash/pick';
const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;

const service = new Service(URL_USER, {
  headers: {
    'Content-Type': 'application/json'
  }
});

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const errorMessage = fields => {
  const fieldInvalid = [];
  fields.forEach(key => {
    fieldInvalid.push(`[${capitalizeFirstLetter(key)}]`);
  });
  const paramsInvalid =
    fieldInvalid.length <= 2 ? fieldInvalid.join() : `${fieldInvalid.slice(0, 2).join()} ...`;
  const msgInvalid = i18n.t(
    'src.modules.chat.components.CustomerSupportPanel.profile.wrong_input_fields',
    { fields: paramsInvalid }
  );

  return msgInvalid;
};

export const getPersonalInfo = async ({ commit }, params) => {
  if (!params.projectId || !params.channelId || !params.userId || !params.platform) return null;
  return service
    .post('personal/getPersonal', params)
    .then(data => {
      commit(SET_PERSONAL_INFO, { ...data, ...params });
      return data;
    })
    .catch(() => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: getPersonalInfo -> params', params);
      return {};
    });
};

export const setPersonalInfoFB = ({ commit }, params) => {
  commit(SET_PERSONAL_INFO_FB, params);
};

export const setPersonalGlobal = ({ commit }, params) => {
  commit(SET_PERSONAL_GLOBAL, params);
};

export const submitForm = ({ commit, state, dispatch }, params) => {
  const { displayConfig } = state;
  let { formPersonalInfo, isUpdate } = params;
  if (!formPersonalInfo) return {};

  const displayFields = Object.keys(displayConfig).filter(k => !!displayConfig[k]);
  formPersonalInfo = pick(formPersonalInfo, [
    'userId',
    'channelId',
    'projectId',
    'platform',
    'customizeInfo',
    ...displayFields
  ]);
  delete formPersonalInfo['userPlatformName'];
  formPersonalInfo.system = 'AGENT';
  const id = formPersonalInfo.userId;
  const userId = formPersonalInfo.userId;
  if (isUpdate) {
    return service
      .post('personal/updatePersonal', formPersonalInfo)
      .then(data => {
        const action = 'session/updatePersonal';
        dispatch(action, { ...data, id, userId }, { root: true });
        commit(SET_PERSONAL_INFO, { ...data, id, userId });
        return { data };
      })
      .catch(errors => {
        const MESS_ERR_KEY = {
          min: 'src.modules.personal.store.actions.personal_min_err',
          max: 'src.modules.personal.store.actions.personal_max_err',
          empty: 'src.modules.personal.store.actions.personal_empty_err',
          pattern: 'src.modules.personal.store.actions.personal_pattern_err',
          custom: 'src.modules.personal.store.actions.personal_custom_err'
        };

        const errorKeys = Object.keys(errors).map(key => {
          const { type, message, limit } = errors[key];
          const translateKey = MESS_ERR_KEY[type];
          return { translateKey, type, message, limit, fieldName: key };
        });
        if (!errorKeys || !errorKeys.length) {
          return {
            error: true,
            msgInvalid: i18n.t('src.modules.profile.store.actions.data_is_invalid')
          };
        }
        const { translateKey, limit, fieldName } = errorKeys[0] || {};
        return {
          error: true,
          msgInvalid: i18n.t(`${translateKey}`, { fieldName, limit })
        };
      });
  }
  return service
    .post('personal/addPersonal', formPersonalInfo)
    .then(data => {
      const action = 'session/updatePersonal';
      dispatch(action, { ...data, id, userId }, { root: true });
      commit(SET_PERSONAL_INFO, { data, id, userId });
      return data;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: submitForm >> addPersonal -> params', params);
      const fields =
        typeof error === 'object'
          ? Object.keys(error).filter(
              k => typeof error[k] === 'string' && ['invalid', 'empty'].includes(error[k])
            )
          : [];
      if (!fields.length) {
        return {
          error: true,
          msgInvalid: i18n.t('src.modules.profile.store.actions.data_is_invalid')
        };
      }
      return { error: true, msgInvalid: errorMessage(fields) };
    });
};

export const getPersonalSetting = async ({ commit }, params) => {
  if (!params.projectId || !params.channelId || !params.userId || !params.platform) return null;
  return service
    .post('personal/getPersonalSetting', params)
    .then(data => {
      commit(SET_PERSONAL_SETTING, data);
    })
    .catch(() => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: getPersonalSetting -> params', params);
      return {};
    });
};

export const getPersonalSettingSupportForm = async ({ commit }, params) => {
  if (!params.projectId || !params.channelId || !params.userId || !params.platform) return null;
  return service
    .post('personal/getPersonalSetting', params)
    .then(data => {
      commit(SET_PERSONAL_SETTING, data);
    })
    .catch(err => {
      // eslint-disable-next-line
      console.log('[C+ Debug]: getPersonalSettingSupportForm -> params', params, err);
      return {};
    });
};

export const getChannelsInfo = ({ commit }, channelId) => {
  return service
    .post('personal/getChannelsInfo', channelId)
    .then(channelName => {
      commit(SET_CHANNEL_NAME, channelName);
      return channelName;
    })
    .catch(() => {
      return 'Personal Info';
    });
};
