var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid p-0 page"},[_c('AppLeftMenu',{attrs:{"show-name":"chat"},on:{"onSelectedConversation":function($event){return _vm.handleOnSelectConversation('url', $event)}}}),(_vm.showView !== 'DashBoard')?_c('aside-left',{on:{"onSelectConversation":function($event){return _vm.handleOnSelectConversation('click', $event)},"onSelectedChannel":_vm.handleOnSelectedChannel,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(_vm.showView === 'DashBoard')?_c('overview-box',{on:{"onSelectedConversation":function($event){return _vm.handleOnSelectConversation('url', $event)}}}):_vm._e(),(_vm.isPhoneAgent)?_c('div',{staticClass:"page-main"},[_c('phone-agent-chat-box')],1):_c('div',{class:[
      'page-main',
      !_vm.isOpenConversation || _vm.isComment || _vm.showView === 'Overview' ? 'no-right' : '',
      _vm.showView === 'DashBoard' ? 'd-none' : ''
    ]},[(_vm.showView === 'Chat')?_c('chat-box',{ref:"chatBox",on:{"onSelectedConversation":function($event){return _vm.handleOnSelectConversation('click', $event)},"onProductItemClick":_vm.handleProductItemClick,"onConversationalItemClick":_vm.handleConversationalItemClick}}):_vm._e(),(_vm.showView === 'Overview')?_c('overview-box',{on:{"onSelectedConversation":function($event){return _vm.handleOnSelectConversation('url', $event)}}}):_vm._e(),(_vm.showView === 'Comment')?_c('comment-box'):_vm._e()],1),((!_vm.isComment && ['Chat', 'Conversation'].includes(_vm.showView)) || _vm.isPhoneAgent)?_c('aside-right',{attrs:{"show-overview-box":_vm.showOverviewBox,"show-support-panel":_vm.showSupportPanel,"show-survey-panel":_vm.showSurveyPanel,"show-product-panel":_vm.showProductPanel,"show-conversational-panel":_vm.showConversationalPanel,"show-web-browsing-history-panel":_vm.showWebBrowsingHistoryPanel,"show-status-history-panel":_vm.showStatusHistoryPanel,"conversation":_vm.selectedConversation},on:{"onStatusHistoryItemClick":_vm.handleStatusHistoryItemClick,"onProductItemClick":_vm.handleProductItemClick,"onSupportItemClick":_vm.handleSupportItemClick,"onSurveyItemClick":_vm.handleSurveyItemClick,"onWebBrowsingHistoryItemClick":_vm.handleWebBrowsingHistoryItemClick,"onConversationalItemClick":_vm.handleConversationalItemClick}}):_vm._e(),(
      (_vm.showSupportPanel &&
        !_vm.isComment &&
        ['Chat', 'Conversation'].includes(_vm.showView) &&
        _vm.isAgentHandle) ||
      (_vm.showSupportPanel && _vm.isPhoneAgent)
    )?_c('CustomerSupportPanel',{attrs:{"conversation":_vm.selectedConversation,"is-chat":true},on:{"close":_vm.hideSupportPanel,"savedData":_vm.handleSavedData,"onSupportItemClick":_vm.handleSupportItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(
      (_vm.showWebBrowsingHistoryPanel &&
        !_vm.isComment &&
        ['Chat', 'Conversation'].includes(_vm.showView) &&
        !_vm.isMonitoringHandle) ||
      (_vm.showWebBrowsingHistoryPanel && _vm.isPhoneAgent)
    )?_c('WebBrowsingHistoryPanel',{on:{"handleWebBrowsingHistoryItemClick":_vm.handleWebBrowsingHistoryItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(
      (_vm.showSurveyPanel && !_vm.isComment && ['Chat', 'Conversation'].includes(_vm.showView)) ||
      (_vm.showSurveyPanel && _vm.isPhoneAgent)
    )?_c('survey-form-panel',{attrs:{"conversation":_vm.selectedConversation,"is-chat":true},on:{"close":_vm.hideSupportPanel,"savedData":_vm.handleSavedData,"onSupportItemClick":_vm.handleSurveyItemClick,"onNextPageCSH":_vm.handleSupportItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(_vm.showProductPanel)?_c('product-panel',{on:{"onProductItemClick":_vm.handleProductItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(_vm.showConversationalPanel)?_c('conversational-panel',{on:{"onConversationalItemClick":_vm.handleConversationalItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),(
      _vm.showStatusHistoryPanel &&
      !_vm.isComment &&
      ['Chat', 'Conversation'].includes(_vm.showView)
    )?_c('StatusHistoryPanel',{attrs:{"conversation":_vm.selectedConversation},on:{"handleStatusHistoryItemClick":_vm.handleStatusHistoryItemClick,"hideAllPanel":_vm.hideAllPanel}}):_vm._e(),_c('transfer-box'),_c('assign-box'),_c('escalate-box'),_c('RequestForm'),_c('ReSupportWarning',{on:{"onSelectedConversation":function($event){return _vm.handleOnSelectConversation('click', $event)}}}),_c('ForwardGroup')],1)}
var staticRenderFns = []

export { render, staticRenderFns }