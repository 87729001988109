<template>
  <div class="col-12 col-lg-6 col-xl-4 mt-30">
    <div class="card border-0 card-lift--hover shadow card-summary">
      <div class="card-body">
        <!-- Header (S)-->
        <div class="row mb-20">
          <div class="col-3">
            <div class="position-relative">
              <b-badge variant="light">
                <img
                  v-if="channelsMap[id].platform === 'webchat'"
                  :class="[platformClass, 'platform']"
                  src="~/assets/images/icons/webchat-logo.png"
                  width="20"
                  height="20"
                />
                <img
                  v-else-if="channelsMap[id].platform === 'zalo'"
                  :class="[platformClass, 'platform']"
                  src="~/assets/images/icons/zalo-min.png"
                  width="20"
                  height="20"
                />
                <img
                  v-else-if="channelsMap[id].platform === 'liffchat'"
                  :class="[platformClass, 'platform']"
                  src="~/assets/images/icons/liffchat.png"
                  width="20"
                  height="20"
                />
                <i v-else :class="platformClass" />
              </b-badge>
              <a class="avatar avatar-xs shadow position-relative" @click.prevent.stop>
                <img :src="avatar" class="img-fluid" @error="imageLoadError" />
              </a>
            </div>
          </div>
          <div class="col-9">
            <v-clamp :max-lines="1" class="text-primary text-uppercase" tag="h6">{{
              name
            }}</v-clamp>
          </div>
        </div>
        <!-- Header (E) -->

        <!-- Bot Count (S) -->
        <div class="chat-badge-status d-flex">
          <div
            data-testid="numBot"
            :data-test-count="botHandleCount"
            v-b-tooltip.hover
            :title="
              `${botHandleCount}  ${$t(
                'src.modules.chat.components.OverviewBox.OverviewItem.index.in_bot_handling'
              )}`
            "
            class="status flex-fill"
            @click="handleBotConversationsShow"
          >
            <span class="badge">{{ botHandleCount }}</span>
            <span class="icon-text text-muted">
              <i class="material-icons">android</i>
            </span>
          </div>
          <!-- Bot Count (E) -->

          <!-- Waiting Count (S) -->
          <div
            data-testid="numWaiting"
            :data-test-count="conversationCount"
            v-b-tooltip.hover
            :title="
              `${conversationCount} ${$t(
                'src.modules.chat.components.OverviewBox.OverviewItem.index.in_waiting'
              )}`
            "
            class="status flex-fill"
            @click="handleWaitingConversationsShow"
          >
            <span class="badge">{{ conversationCount }}</span>
            <span class="icon-text text-muted">
              <i class="material-icons">alarm</i>
            </span>
          </div>
          <!-- Waiting Count (E) -->

          <!-- Agent Count (S) -->
          <div
            data-testid="numAgentHandle"
            :data-test-count="agentHandleCount"
            v-b-tooltip.hover
            :title="
              `${agentHandleCount} ${$t(
                'src.modules.chat.components.OverviewBox.OverviewItem.index.in_agent_handling'
              )}`
            "
            class="status flex-fill"
            @click="handleAgentConversationsShow"
          >
            <span class="badge">{{ agentHandleCount }}</span>
            <span class="icon-text text-muted">
              <i class="material-icons">people</i>
            </span>
          </div>
          <!-- Agent Count (E) -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import noAvatar from 'assets/images/no-avatar-channel.jpg';
import noUserAvatar from 'assets/images/no-avatar.png';
import VClamp from 'vue-clamp';
import { EventBus } from 'core/eventBus';
import { SERVING_STATE } from 'core/constants';

export default {
  components: {
    VClamp
  },

  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    pictureUrl: {
      type: String,
      default: noAvatar
    },
    conversations: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      noUserAvatar,
      imageUrl: noAvatar,
      imageError: false,
      selectedGroup: 'common'
    };
  },

  computed: {
    ...mapState('session', [
      'channelsMap',
      'user',
      'commonWaitingCountsMap',
      'meWaitingCountsMap',
      'botHandleCountsMap',
      'loadingMoreMaps',
      'agentHandleCountsMap',
      'projectMaps'
    ]),
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'monitoringList',
      'meWaitingList'
    ]),

    hasMore() {
      switch (this.selectedGroup) {
        case 'common':
          return this.commonWaitingCount > this.conversationsBySelectedGroup.length;

        case 'tome':
          return this.meWaitingCount > this.conversationsBySelectedGroup.length;

        default:
          return false;
      }
    },

    channelStatus() {
      return this.id + (this.selectedGroup === 'tome' ? '2' : '1');
    },

    loadingMore() {
      return this.loadingMoreMaps[this.channelStatus] || false;
    },

    totalCount() {
      return this.conversationCount + this.agentHandleCount + this.botHandleCount;
    },

    conversationCount() {
      return this.commonWaitingCount + this.meWaitingCount;
    },

    commonWaitingCount() {
      return this.commonWaitingCountsMap[this.id] || 0;
    },

    meWaitingCount() {
      return this.meWaitingCountsMap[this.id] || 0;
    },

    agentHandleCount() {
      return this.agentHandleCountsMap[this.id] || 0;
    },

    botHandleCount() {
      return this.botHandleCountsMap[this.id] || 0;
    },

    platformClass() {
      const platform = this.channelsMap[this.id].platform;
      switch (platform) {
        case 'facebook':
        case 'external_facebook':
          return 'fab fa-facebook';
        case 'instagram':
          return 'fab fa-instagram';
        case 'line':
        case 'external_line':
          return 'fab fa-line';
        case 'liffchat': 
        case 'webchat':
          return 'fas fa-comment-dots';
        default:
          return 'fab fa-comment-alt-smile';
      }
    },

    avatar() {
      return this.imageUrl;
    },

    conversationsBySelectedGroup() {
      return this.selectedGroup === 'tome'
        ? this.meWaitingList.filter(c => c.channelId === this.id)
        : this.commonWaitingList.filter(c => c.channelId === this.id);
    },
    c_projectName() {
      return this.projectMaps[this.projectId].name;
    }
  },

  watch: {
    pictureUrl(pictureUrl) {
      if (pictureUrl) this.imageUrl = pictureUrl;
    },

    selectedGroup() {
      this.handelLoadMoreConvs();
    }
  },

  mounted() {
    this.imageUrl = this.pictureUrl || noAvatar;
  },

  methods: {
    ...mapActions('session', ['loadMoreConversation', 'getGroupsOpts']),
    ...mapActions('chat', ['setSelectedGroupConversation']),

    handleOnSelectedCons(cons) {
      this.setSelectedGroupConversation(SERVING_STATE.WAITING); // trigger click waiting view
      this.$emit('onSelectedConversation', cons);
    },

    async handleBotConversationsShow() {
      const params = {};
      params.boxTitle = this.$t('src.core.constants.conversations_with_bots');
      params.boxTitleKey = 'BOT';
      params.projectName = this.c_projectName;
      params.channelName = this.name;
      params.projectId = this.projectId;
      params.channelId = this.id;
      params.agentId = this.user.id;
      params.groupIds = await this.getGroupsOpts({
        ticket: { projectId: this.projectId }
      });

      EventBus.$emit('conversationDetailBox', params);
    },

    async handleWaitingConversationsShow() {
      const params = {};

      params.boxTitle = this.$t('src.core.constants.waiting_conversations');
      params.boxTitleKey = 'WAITTING';
      params.projectName = this.c_projectName;
      params.channelName = this.name;
      params.projectId = this.projectId;
      params.channelId = this.id;
      params.agentId = this.user.id;
      params.groupIds = await this.getGroupsOpts({
        ticket: { projectId: this.projectId }
      });

      EventBus.$emit('conversationDetailBox', params);
    },

    handleAgentConversationsShow() {
      const params = {};
      params.boxTitle = this.$t('src.core.constants.conversations_with_agents');
      params.boxTitleKey = 'AGENT_HANDLE';
      params.projectName = this.c_projectName;
      params.channelName = this.name;
      params.projectId = this.projectId;
      params.channelId = this.id;
      params.agentId = this.user.id;

      EventBus.$emit('conversationDetailBox', params);
    },

    imageLoadError() {
      this.imageUrl = noAvatar;
    },

    userAvatar(cons) {
      return cons && cons.userInfo && cons.userInfo.pictureUrl
        ? cons.userInfo.pictureUrl
        : noUserAvatar;
    },

    handelLoadMoreConvs() {
      if (this.conversationsBySelectedGroup.length > 0) return;
      if (!this.hasMore) return;
      const { projectId } = this.channelsMap[this.id] || {};
      if (!projectId) return;
      let status = this.selectedGroup === 'tome' ? 2 : 1;

      /**
       * 0: botHandle
       * 1: commonWaiting
       * 2: meWaiting
       * 3: agentSupporting
       * 5: monitoring
       */
      this.loadMoreConversation({ projectId, channelId: this.id, status, page: 1 });
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-badge-status {
  margin: 0 auto;
  text-align: center;
  .status {
    display: inline-block;
    text-align: center;
    background-color: #eee;
    cursor: pointer;
  }
  .status:nth-child(2) {
    background-color: transparent;
  }
  .status:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .status:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .status .badge {
    display: block;
    font-size: 22px;
    border-radius: 0;
  }
  .status .icon-text {
    padding: 7px;
    display: block;
  }
  .status .icon-text > i {
    font-size: 18px;
  }
}
.card-body {
  min-height: 178px !important;
}
.avatar {
  &.mr-10 {
    margin-right: 10px !important;
  }
}
.mb-20 {
  margin-bottom: 20px;
}
.badge-light {
  position: absolute;
  margin-top: -15px;
  font-size: 1.5em;
  color: inherit;
  background-color: transparent;
}

.fab.fa-facebook {
  margin-right: -373%;
  color: #3483fe;
}
.fab.fa-line {
  margin-right: -373%;
  color: #3ac521;
}
.fab.fa-instagram {
  margin-right: -373%;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fab.fa-comment-alt-smile {
  margin-right: -330%;
  color: #f1aa26;
}
.fas.fa-comment-dots {
  position: absolute;
  color: #2196f3;
  left: 37px;
}
.waiting-user {
  font-weight: 600;
  padding-top: 10px;
}
</style>
