import {
  PIN_RIGHT_PANEL,
  PIN_RIGHT_PRODUCT_PANEL,
  PIN_RIGHT_CONVERSATION_PANEL,
  PIN_RIGHT_CUSTOMER_SUPPORT_PANEL,
  PIN_RIGHT_CUSTOMER_SURVEY_PANEL,
  PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL,
  PIN_RIGHT_STATUS_HISTORY_PANEL,
  SET_GLOBAL_READY,
  SET_OFFLINE,
  SET_TERMINATE,
  SET_SYNC_TIME,
  SET_SYNCING,
  SET_SYNC_ERROR,
  SET_GLOBAL_LOADING_MAP
} from './types';

import * as getters from './getters';
import * as actions from './actions';

const state = {
  rightProductPanelPinned: true,
  rightConversationPanelPinned: true,
  rightCustomerSupportPanelPinned: true,
  rightCustomerSurveyPanelPinned: true,
  rightWebBrowsingHistoryPanelPinned: true,
  rightStatusHistoryPanelPinned: true,
  rightPanelPinned: true,
  rightPanelPinnedActive: '',
  ready: false,
  isOffline: false,
  terminate: false,
  syncing: false,
  syncError: false,
  lastOfflineTime: new Date(),
  lastSyncTime: new Date(),
  globalLoadingMap: {}
};

const mutations = {
  [PIN_RIGHT_PANEL](state, flag = true) {
    state.rightPanelPinned = flag;
    state.rightPanelPinnedActive = 'PANEL';
  },

  [PIN_RIGHT_PRODUCT_PANEL](state, flag = true) {
    state.rightProductPanelPinned = flag;
    state.rightPanelPinnedActive = 'PRODUCT';
  },

  [PIN_RIGHT_CONVERSATION_PANEL](state, flag = true) {
    state.rightConversationPanelPinned = flag;
    state.rightPanelPinnedActive = 'CONVERSATION';
  },

  [PIN_RIGHT_CUSTOMER_SUPPORT_PANEL](state, flag = true) {
    state.rightCustomerSupportPanelPinned = flag;
    state.rightPanelPinnedActive = 'CUSTOMERSUPPORT';
  },

  [PIN_RIGHT_CUSTOMER_SURVEY_PANEL](state, flag = true) {
    state.rightCustomerSurveyPanelPinned = flag;
    state.rightPanelPinnedActive = 'SURVEYFORM';
  },

  [PIN_RIGHT_WEB_BROWSING_HISTORY_PANEL](state, flag = true) {
    state.rightWebBrowsingHistoryPanelPinned = flag;
    state.rightPanelPinnedActive = 'WEBBROWSINGHISTORY';
  },

  [PIN_RIGHT_STATUS_HISTORY_PANEL](state, flag = true) {
    state.rightStatusHistoryPanelPinned = flag;
    state.rightPanelPinnedActive = 'STATUSHISTORY';
  },

  [SET_GLOBAL_READY](state, flag = true) {
    state.ready = flag;
  },

  [SET_OFFLINE](state, isOffline) {
    const { lastOfflineTime, lastSyncTime } = state;

    if (lastSyncTime >= lastOfflineTime) {
      state.lastOfflineTime = new Date();
    }

    state.isOffline = isOffline;
  },

  [SET_TERMINATE](state, isTerminate) {
    state.terminate = isTerminate;
  },

  [SET_SYNC_TIME](state, syncTime) {
    state.lastSyncTime = syncTime;
  },

  [SET_SYNCING](state, isSyncing) {
    state.syncing = isSyncing;
  },

  [SET_SYNC_ERROR](state, error) {
    state.syncError = error;
  },

  [SET_GLOBAL_LOADING_MAP](state, { action, flag }) {
    state.globalLoadingMap = { ...state.globalLoadingMap, [action]: flag };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
