<template>
  <div class="comment-box-at">
    <!-- comment box Header (S) -->
    <CommentFilter @heightOfCommentFilter="detectHeightOfCommentFilter" />

    <div
      class="app-message-chats w-100 p-20"
      :style="`height: calc(100vh - ${heightCommentFilter}px) !important`"
    >
      <vue-element-loading
        :active="!ready && globalReady"
        :text-style="textStyle"
        spiner="line-scale"
      />
      <div v-if="!showCommentList" class="no-comments">
        <div class="container">
          <i class="fas fa-comments fa-3x" />
          <br />
          <span>{{ empty_message }}</span>
        </div>
      </div>
      <div v-if="showCommentList" class="row">
        <div
          ref="commentList"
          :style="`max-height: calc(100vh - ${heightCommentFilter}px - 34px)`"
          :class="['mh-max', commentClass]"
          style="overflow-x: hidden"
          @scroll="handleScroll"
        >
          <comment-list />
          <div v-if="loadingMoreComments" class="w-100 ml--10">
            <i class="fas fa-spinner fa-spin" />
          </div>
        </div>
        <div v-if="hasDetail" :class="[detailClass]">
          <comment-detail :height-comment-filter="heightCommentFilter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CommentList from './CommentList';
import CommentDetail from './CommentDetail';
import CommentFilter from './CommentFilter';
import SubscribePushComments from 'gql/SubscribePushComments.gql';

export default {
  components: {
    CommentList,
    CommentDetail,
    CommentFilter
  },

  data() {
    return {
      textStyle: {},
      channel: {},
      detailReady: true,
      loadingMoreComments: false,
      loading_time: 0,
      query_time: null,
      newComments: null,
      empty_message: this.$t('src.modules.chat.components.CommentBox.index.no_comments'),
      heightCommentFilter: 0,
      pushCommentsSubscriber: null
    };
  },

  computed: {
    ...mapState('comment', [
      'ready',
      'selectedChannel',
      'selectedComment',
      'comments',
      'commentPaging',
      'inFilterMode',
      'filterCondition',
      'loadCommentToken',
      'loadCommentFilterToken'
    ]),
    ...mapGetters('comment', ['pageId']),
    ...mapState('global', { globalReady: 'ready' }),
    ...mapState('chat', ['showView']),

    hasDetail() {
      const { comment_id } = this.selectedComment;
      return comment_id ? true : false;
    },

    commentClass() {
      return this.hasDetail ? 'col-4' : 'col-12';
    },

    detailClass() {
      return this.hasDetail ? 'col-8' : 'hide';
    },

    showCommentList() {
      return this.comments.length > 0;
    },

    isComment() {
      return this.showView === 'Comment';
    }
  },

  watch: {
    async selectedChannel(newChannel) {
      if (newChannel && newChannel.id) {
        this.setGlobalReady(false);
        this.getCommentsByChannel({})
          .then(comments => {
            if (comments.length) {
              const last = comments[comments.length - 1];
              this.query_time = last.system_updated_time;
            } else {
              this.empty_message = this.$t(
                'src.modules.chat.components.CommentBox.index.no_comments'
              );
            }
          })
          .catch(() => {
            this.empty_message = this.$t(
              'src.modules.chat.components.CommentBox.index.the_channel_has_not_enough_permission'
            );
          });
      }
    },

    pageId(newValue) {
      this.subscribePushComments(newValue);
    }
  },

  async created() {},

  mounted() {
    this.subscribePushComments(this.pageId);

    if (!this.isComment) return;
    this.setCommentReady(false);
    this.getCommentsByChannel({})
      .then(comments => {
        if (comments.length) {
          const last = comments[comments.length - 1];
          this.query_time = last.system_updated_time;
        }
      })
      .catch(() => {
        this.empty_message = this.$t(
          'src.modules.chat.components.CommentBox.index.the_channel_has_not_enough_permission'
        );
      });
  },

  beforeDestroy() {
    if (
      this.pushCommentsSubscriber &&
      typeof this.pushCommentsSubscriber.unsubscribe === 'function'
    ) {
      this.pushCommentsSubscriber.unsubscribe();
    }
    this.pushCommentsSubscriber = null;
  },

  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('comment', [
      'getCommentsByChannel',
      'loadMoreComments',
      'loadMoreCommentFilter',
      'checkUpdate',
      'setCommentReady',
      'commentNextPaging',
      'setLoadTime',
      'setSelectedChannel',
      'realTimeData'
    ]),

    handleScroll(evt) {
      const { clientHeight, scrollTop, scrollHeight } = evt.target;
      const isLoadMore = scrollHeight - scrollTop - clientHeight < 1;

      if (isLoadMore && this.selectedChannel && !this.loadingMoreComments) {
        this.loadingMoreComments = true;
        Promise.resolve()
          .then(() => (this.$refs.commentList.scrollTop = this.$refs.commentList.scrollTop - 20))
          .then(() => {
            if (this.inFilterMode && this.loadCommentFilterToken) {
              return this.loadMoreCommentFilter({
                /** Start: Load more comments by time */
                ...this.filterCondition,
                next: this.loadCommentFilterToken
                /** End: Load more comments by time */
              });
            } else if (!this.inFilterMode && this.loadCommentToken) {
              return this.loadMoreComments({
                /** Start: Load more comments by time */
                next: this.loadCommentToken
                /** End: Load more comments by time */
              });
            }
          })
          .then(() => (this.loadingMoreComments = false))
          .catch(() => (this.loadingMoreComments = false));
      }
    },

    detectHeightOfCommentFilter(height) {
      this.heightCommentFilter = height;
    },

    subscribePushComments(pageId) {
      if (!pageId) return false;
      const _this = this;

      if (
        this.pushCommentsSubscriber &&
        typeof this.pushCommentsSubscriber.unsubscribe === 'function'
      ) {
        this.pushCommentsSubscriber.unsubscribe();
      }
      this.pushCommentsSubscriber = null;

      this.pushCommentsSubscriber = this.$apollo.subscribe({
        query: SubscribePushComments,
        variables: { pageId }
      });

      return this.pushCommentsSubscriber.subscribe({
        next({ data }) {
          const comment = (data && data.subscribePushComments) || {};
          const { pageId = '' } = comment;
          if (pageId === _this.pageId) {
            _this.realTimeData(comment);
          }

          return true;
        },
        error(error) {
          _this.$log('[pushCommentsSubscriber] > error', error);
          if (
            _this.pushCommentsSubscriber &&
            typeof _this.pushCommentsSubscriber.unsubscribe === 'function'
          )
            _this.pushCommentsSubscriber.unsubscribe();
          _this.pushCommentsSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribePushComments(pageId);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/chat/chat.scss';

.app-message-chats {
  height: 100% !important;
  overflow: hidden !important;

  .mh-max {
    max-height: calc(100vh - 30px);
    overflow-y: scroll;
  }
}

.no-comments {
  text-align: center;
  width: 100%;
  height: 100%;
  .container {
    padding-top: 25%;
  }
}
</style>
