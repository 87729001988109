export const tree = state => state.treeStore;

export const customerSupportSearch = state => {
  const { customerData, searchText } = state;
  if (searchText === '') return customerData;
  return customerData.filter(
    c =>
      `${c.id}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.categoryPath}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.userId}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.userName}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.title}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.platform}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${c.channelName}`.toLowerCase().includes(searchText.toLowerCase())
  );
};
