<template>
  <div
    :class="['row', 'comment', 'mb-10', openClass]"
    @click="$emit('click', comment_id)"
  >
    <div :class="['left', 'pr-0', ...colClass.left]">
      <div class="media cm">
        <div>
          <a class="avatar avatar-online shadow" href @click.prevent.stop>
            <img
              :src="userpicture"
              :alt="sender_name"
              class="img-fluid"
              @error="$event.target.src = noAvatar"
            />
          </a>
        </div>
        <div class="body-wrapper ml-10 w-100">
          <div class="media-body bg-white shadow-sm">
            <div class="row">
              <div class="col-12">
                <h6 class="mt-0 mb-0 pl-15 text-left pt-5">
                  {{ sender_name }}
                  <small class="text-grey">
                    -
                    <el-tooltip
                      :content="createTime"
                      class="item"
                      effect="dark"
                      placement="top"
                    >
                      <span class="text-lowercase">{{ timeAgo }}</span>
                    </el-tooltip>
                  </small>
                </h6>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 text-left mb-5 pr-20 pl-30 mh-48 emoji-font"
                style="word-break: break-word; white-space: pre-line;"
              >
                <div class="emoji-font" style="display: flex">
                  <app-img
                    v-if="isImage"
                    :src="attachmentSrc"
                    class="img-fluid"
                    style="margin-right: 10px; float: left; width: 150px;"
                  />
                  <iframe
                    v-if="isMedia"
                    :src="attachmentSrc"
                    frameborder="0"
                    style="margin-right: 10px; float: left; width: 150px;"
                  />
                  <span v-html="urlify(escapeHtmlChar(message))"></span>
                </div>
                <div class="msg-stt pr-1">
                  <i v-if="!isRead" class="far fa-envelope fa-envelope-custom" />
                  <i v-if="isRead" class="far fa-envelope-open fa-envelope-open-custom" />
                  <i v-if="hasNewReply" class="far fa-comment ml-10" />
                  <span v-if="hasNewReply">{{ new_reply }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hasDetail" :class="['right', 'pl-10', ...colClass.right]">
      <div class="media cm-post">
        <div class="body-wrapper ml-10 w-100">
          <div class="media-body bg-white shadow-sm position-relative">
            <div class="row">
              <div class="col-12">
                <h6 class="mt-0 mb-0 pl-15 text-left pt-5">
                  <small>
                    {{ post.creator_name }}
                    {{
                      $t(
                        'src.modules.chat.components.CommentBox.CommentList.CommentItem.index.posted:'
                      )
                    }}
                  </small>
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-8 text-left mb-5 pr-20 pl-30 mh-48 cut-text">
                {{ post.message }}
              </div>
              <img
                v-if="post.picture"
                :src="post.picture"
                :alt="post.creator_name"
                @error="$event.target.src = noImage"
                class="img-fluid col-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import noAvatar from 'assets/images/no-avatar.png';
import noImage from 'assets/images/no-image.png';
import { urlify, escapeHtmlChar, getParamsFromUrl, convertTimeUTC } from 'core/helpers';

export default {
  props: {
    senderid: { type: String, default: '' },
    userpicture: { type: String, default: '' },
    message: { type: String, default: '' },
    sender_name: { type: String, default: '' },
    create_time: { type: String, default: '' },
    comment_id: { type: String, default: '' },
    page_id: { type: String, default: '' },
    post_id: { type: String, default: '' },
    is_read: { type: Number, default: 0 },
    is_important: { type: Number, default: 0 },
    reply_later: { type: Number, default: 0 },
    urllist: { type: Object, default: () => {} },
    reply_list: { type: Array, default: () => [] },
    post_detail: { type: Object, default: () => {} },
    like_status: { type: String, default: '' },
    commentremove: { type: String, default: 'icon-remove' },
    url_detail_comment: { type: String, default: '' },
    new_reply: { type: Number, default: 0 },
    attachment: { type: String, default: '{}' }
  },

  data() {
    return {
      noAvatar,
      noImage
    };
  },

  computed: {
    ...mapState('comment', ['selectedComment']),

    createTime() {
      return convertTimeUTC(this.create_time);
    },

    timeAgo() {
      const at = convertTimeUTC(this.create_time, 'comment');
      const now = moment();
      return now.from(at, true);
    },

    post() {
      return this.post_detail || {};
    },

    hasDetail() {
      const { comment_id } = this.selectedComment;
      return comment_id ? true : false;
    },

    openClass() {
      const { comment_id } = this.selectedComment;
      return this.comment_id === comment_id ? 'open' : '';
    },

    colClass() {
      const left = this.hasDetail ? 12 : 9;
      const right = 12 - left;
      return {
        left: [`col-${left}`],
        right: [`col-${right}`]
      };
    },

    isRead() {
      if (this.is_read == 0) return false;
      return true;
    },

    hasNewReply() {
      return this.new_reply;
    },

    attachmentData() {
      return this.attachment && typeof this.attachment === 'string'
        ? JSON.parse(this.attachment)
        : this.attachment;
    },

    isMedia() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.video ? true : false;
    },

    isImage() {
      const attachment = this.attachmentData;
      if (!attachment) return false;
      return attachment.photo || attachment.type === 'sticker' ? true : false;
    },

    attachmentSrc() {
      const attachment = this.attachmentData;
      if (this.isMedia) {
        return attachment.video;
      } else if (this.isImage) {
        const photo = attachment.type === 'sticker' ? attachment.url : attachment.photo;
        const params = this.getParamsFromUrl(photo);
        const { url = null } = params;
        return url || photo;
      }
      return '';
    }
  },
  methods: {
    getParamsFromUrl(url) {
      return getParamsFromUrl(url);
    },
    urlify(data) {
      return urlify(data);
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.comment {
  cursor: pointer;

  .media {
    &.cm-post {
      img {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        max-height: 60px;
      }
    }
  }

  .mh-48 {
    min-height: 48px !important;
  }

  .body-wrapper {
    position: relative;
  }

  &.open {
    .avatar {
      border: 2px #2196f3 solid;
    }

    .cm .media-body {
      &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #2196f3;
      }

      &::after {
        content: '';
        position: absolute;
        top: 40%;
        right: -20px;
        width: 0;
        height: 0;
        border-bottom: solid 10px transparent;
        border-top: solid 10px transparent;
        border-left: solid 10px #fff;
        border-right: solid 10px transparent;
      }
    }
  }

  .cm-post .media-body {
    &::before {
      content: ' ';
      right: 100%;
      top: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 40%;
      left: 0;
      margin-left: -20px;
      width: 0;
      height: 0;
      border-bottom: solid 10px transparent;
      border-top: solid 10px transparent;
      border-left: solid 10px transparent;
      border-right: solid 10px #fff;
    }
  }

  .media-body {
    border-radius: 5px;
    h6 {
      font-weight: 600;
    }
  }

  .text-grey {
    color: #999 !important;
  }
  .msg-stt {
    text-align: right;
  }

  i {
    cursor: pointer;

    &.fa-envelope-custom {
      color: #cc0000;
    }
    &.fa-envelope-open-custom {
      color: #e9ecef;
    }
  }
}
.emoji-font {
  font-family: 'Open Sans', sans-serif, 'Noto Color Emoji', 'Apple Color Emoji',
    'Segoe UI Emoji', Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica,
    serif;
}
.badge {
  font-size: 11px !important;
  font-weight: 300;
  height: 18px;
  color: #fff;
  padding: 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}
</style>
