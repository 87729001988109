'use strict';

import Vue from 'vue';
import Router from 'vue-router';
import Login from 'modules/session';
import ForgotPassword from 'modules/session';
import ChangePasswordFirstLogin from 'modules/session';
import Chat from 'modules/chat';
import Profile from 'modules/profile';
import Password from 'modules/password';
import CustomerSupportHistory from 'modules/customer-support-history';
import searchManagement from 'modules/search-management';
import replacingMessage from 'modules/replacing-message';
import fileManagement from 'modules/file-management';
import accessLog from 'modules/access-log';
import Report from 'modules/report';
import store from 'core/store';
import adfsCallback from 'modules/session';
import adfsSelect from 'modules/session';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  saveScrollPosition: true,
  linkActiveClass: 'open active',
  routes: [
    {
      path: '*',
      redirect: '/',
      name: 'Dashboard',
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Agent Terminal'
      }
    },
    {
      path: '/forgot/password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        title: 'Agent Terminal'
      }
    },
    {
      path: '/',
      name: 'Chat',
      component: Chat,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/com/:channelId',
      name: 'Comments',
      component: Chat,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/c/:conversationId/:ticketId',
      name: 'Conversation',
      component: Chat,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/profile/:type',
      name: 'Profile',
      component: Profile,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/password',
      name: 'Password',
      component: Password,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/change-password/:sys',
      name: 'Change Password',
      component: ChangePasswordFirstLogin,
      meta: {
        title: 'Agent Terminal'
      }
    },
    {
      path: '/customer-support-history',
      name: 'Customer Support',
      component: CustomerSupportHistory,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/report',
      name: 'Report',
      component: Report,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/search-management',
      name: 'Search Management',
      component: searchManagement,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/replacing-messages',
      name: 'Replacing messages',
      component: replacingMessage,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/file-management',
      name: 'File Management',
      component: fileManagement,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/access-log',
      name: 'Access Log',
      component: accessLog,
      meta: {
        title: 'Agent Terminal',
        requiresAuth: true
      }
    },
    {
      path: '/adfs/ls',
      name: 'Select ADFS',
      component: adfsSelect,
      meta: {
        title: 'Agent Terminal'
      }
    },
    {
      path: '/adfs/cb',
      name: 'Login with ADFS',
      component: adfsCallback,
      meta: {
        title: 'Agent Terminal'
      }
    }
  ]
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(error => error);
};

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  const isLoginPage = to.matched.some(p => p.path.indexOf('/login') === 0);

  try {
    const isAuthenticated = store.getters['session/isAuthenticated'];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (isAuthenticated && isLoginPage) {
      return next('/');
    }

    if (requiresAuth && !isAuthenticated) {
      if (isLoginPage) return next();
      return next('login');
    } else next();
  } catch (err) {
    if (isLoginPage) return next();
    next('login');
  }
});

export default router;
