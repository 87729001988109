<template>
  <div v-if="isOffline" class="alert alert-warning text-center p-1 offline" role="alert">
    {{ $t('common.text.you_are_offline') }}
  </div>
  <div v-else-if="syncing" class="alert alert-info text-center p-1 offline" role="alert">
    {{ $t('common.text.syncing_data') }}
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      timeoutId: null
    };
  },

  computed: {
    ...mapState('global', ['isOffline', 'terminate', 'syncing', 'syncError'])
  },

  mounted() {
    window.addEventListener('online', this.handleStatus);
    window.addEventListener('offline', this.handleStatus);
  },

  methods: {
    ...mapActions('global', ['setOffline', 'setTerminate', 'syncData']),

    async handleStatus() {
      const isOffline = navigator.onLine ? false : true;
      await this.checkOffline(isOffline);
    },

    async checkOffline(isOffline) {
      if (!isOffline) await new Promise(resolve => setTimeout(resolve, 3000));

      this.setOffline(isOffline);
      if (!isOffline) {
        clearTimeout(this.timeoutId);
        if (this.terminate) {
          this.setTerminate(false);
        }
        if (!this.syncing) this.syncData();
      } else {
        this.setTerminate(true);
      }
    },

    startPolling() {
      const url = 'https://ipv4.icanhazip.com/';
      const timeout = 5000;
      const intervalTime = 5000;

      setInterval(() => {
        this.ping({ url, timeout }).then(async online => {
          const isOffline = online ? false : true;
          if (isOffline) await this.checkOffline(isOffline);
          if (this.isOffline && !isOffline) await this.checkOffline(isOffline);
        });
      }, intervalTime);
    },

    ping({ url, timeout }) {
      return new Promise(resolve => {
        const isOnline = () => resolve(true);
        const isOffline = () => resolve(false);

        const xhr = new XMLHttpRequest();

        xhr.onerror = isOffline;
        xhr.ontimeout = isOffline;
        xhr.onreadystatechange = () => {
          if (xhr.readyState === xhr.HEADERS_RECEIVED) {
            if (xhr.status) {
              isOnline();
            } else {
              isOffline();
            }
          }
        };

        xhr.open('HEAD', url);
        xhr.timeout = timeout;
        xhr.send();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.offline {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
</style>
