<template>
  <div class="survey-form-item" :style="'width:' + getWidth">
    <div class="survey-title-item">
      <span v-if="item.rules && item.rules.required" class="icon-require">*</span>
      {{ item.question }}
    </div>
    <label class="d-block">
      {{ item.description }}
    </label>
    <div class="sv-form-root">
      <label
        v-for="(val, index) in (item.attributes && item.attributes.options) || []"
        :key="index"
        class="sv-form-label"
      >
        <b-form-radio
          v-model="item.answer"
          :name="'radio-size-' + index"
          :value="val.value"
          :disabled="true"
        />
        <span class="sv-span-text">{{ val.label }}</span>
      </label>
    </div>
    <div class="sv-radio-footer">
      <p class="sv-radio-footer-text">{{ item.attributes && item.attributes.start }}</p>
      <p class="sv-radio-footer-text">{{ item.attributes && item.attributes.end }}</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isSearch: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState('global', ['rightCustomerSurveyPanelPinned']),

    getWidth() {
      if (this.isSearch) {
        return '50%';
      }
      if (!this.rightCustomerSurveyPanelPinned) {
        return '33.3%';
      }
      return '';
    }
  }
};
</script>
<style lang="scss">
@import 'index.scss';
</style>
