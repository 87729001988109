<template>
  <b-modal
    id="fileManager"
    :ok-disabled="!canSend"
    :title="$t('src.modules.chat.components.ChatBox.file-manager.file_manager_title')"
    :ok-title="$t('common.text.send')"
    :cancel-title="$t('common.confirmModal.close')"
    cancel-variant="default"
    scrollable
    @ok="onOkButton"
    @show="onShowModal"
  >
    <b-row>
      <b-col cols="md-12">
        <b-row>
          <b-col style="display: flex">
            <button
              style="height: 40px"
              v-b-tooltip.hover
              :disabled="!selected_node || !canCreateUploadDelete"
              :title="
                $t('src.modules.chat.components.ChatBox.file-manager.btn_upload_file')
              "
              class="btn btn-outline btn-fea shadow-none"
              type="button"
              @click="openFile"
            >
              <i class="fas fa-paperclip" />
            </button>
            <button
              style="height: 40px"
              v-if="!showFolderName"
              v-b-tooltip.hover
              :disabled="
                !selected_node ||
                  !canCreateUploadDelete ||
                  selected_node.data.type === 'file'
              "
              :title="
                $t('src.modules.chat.components.ChatBox.file-manager.btn_create_folder')
              "
              class="btn btn-outline btn-fea shadow-none"
              type="button"
              @click="toggleFolderName"
            >
              <i class="fas fa-plus" />
            </button>
          </b-col>
          <b-col>
            <ValidationObserver ref="folderName">
              <base-input
                ref="inputFolderName"
                v-if="showFolderName"
                v-model="folder_name"
                :name="'folderName'"
                :placeholder="
                  $t('src.modules.file-management.index.input_new_folder_name')
                "
                @blur="toggleFolderName"
                @keypressEnter="createFolder"
                :label="$t('src.modules.file-management.index.folder_name')"
                :show-label="false"
                :maxlength="255"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <input
              id="fileSharingCreateButton"
              ref="resetInputFile"
              hidden
              type="file"
              multiple
              @change="fileUpload"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <base-input
              v-model="searchText"
              :name="'filter-file'"
              :placeholder="$t('src.modules.file-management.index.search_text')"
              @keyup="searchEvent"
              :icon="'search'"
              :align="'horizontal'"
              :maxlength="-1"
            />
          </b-col>
        </b-row>
        <div class="app-message-upload">
          <file-upload
            v-for="(file, idx) in uploadingFiles"
            :key="idx"
            :file="file"
            :path="projectId"
            :platform="'line'"
            @onSuccess="createFile"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="md-12" style="display: flex; padding: 15px">
        <label :class="[setWidth]" style="font-weight: 600">{{
          $t('src.modules.file-management.index.usage')
        }}</label>
        <el-progress
          :text-inside="true"
          :stroke-width="20"
          :percentage="percentage"
          :color="customColors"
          :class="'custom-color-text'"
        ></el-progress>
      </b-col>
    </b-row>
    <b-row>
      <!-- Category tree -->
      <b-col cols="md-12">
        <div class="tree-container" style="over-flow: hidden; max-height: 70vh">
          <tree
            ref="tree"
            :data="treeData"
            :filter="searchText"
            :options="{
              multiple: false,
              filter: {
                emptyText: this.$t('common.text.no_data'),
                matcher(query, node) {
                  return node.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                }
              }
            }"
            style="max-height: inherit"
            @node:selected="onNodeSelected"
            @tree:mounted="onMounted"
          >
            <div slot-scope="{ node }" class="tree-text">
              <template v-if="node.data.type == 'file'">
                <i class="ion-md-document"></i>
                {{ node.text | truncate(30) }}
                <i
                  v-if="node.id != projectId && canCreateUploadDelete"
                  class="fa fa-times item tree-icon-delete"
                  aria-hidden="true"
                  @click="confirmDeleteNode(node.id)"
                ></i>
                <i
                  v-if="node.data.type != 'folder'"
                  class="fa fa-link tree-icon-copy"
                  v-bind:class="{ isDisabledLinked: agentWorkAtHome }"
                  aria-hidden="true"
                  @click="handleTableActionCopy(node)"
                ></i>
              </template>

              <template v-else>
                <i
                  :class="[node.expanded() ? 'ion-md-folder-open' : 'ion-md-folder']"
                ></i>
                {{ node.text | truncate(30) }}
                <i
                  v-if="node.id != projectId && canCreateUploadDelete"
                  class="fa fa-times tree-icon-delete"
                  aria-hidden="true"
                  @click="confirmDeleteNode(node.id)"
                ></i>
                <i
                  v-if="node.data.type != 'folder'"
                  class="fa fa-link tree-icon-copy"
                  aria-hidden="true"
                  @click="handleTableActionCopy(node)"
                ></i>
              </template>
            </div>
          </tree>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FileUpload from './file-upload';
import { getFileType } from 'core/helpers';
import { FILE_MANAGEMENT_DEFAULT, AGENT_ROLE } from 'core/constants';

export default {
  components: {
    FileUpload
  },
  props: {
    projectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      messageConfirmDeleteFolder: this.$t(
        'src.modules.chat.components.ChatBox.file-manager.are_you_sure_to_delete_this_folder'
      ),
      canSend: false,
      uploadingFiles: [],
      folder_name: '',
      showFolderName: false,
      selected_node: null,
      deleting_node: null,
      searchText: '',
      treeData: [
        {
          id: this.projectId,
          text: this.$t('src.modules.chat.components.ChatBox.file-manager.root'),
          data: { type: 'folder' },
          parent_id: '0',
          state: { expanded: true, selected: true }
        }
      ],
      customColors: [
        { color: '#5cb87a', percentage: 80 },
        { color: '#e6a23c', percentage: 94 },
        { color: '#f56c6c', percentage: 100 }
      ],
      isDisabled: {
        cursor: 'not-allowed'
      }
    };
  },
  computed: {
    ...mapState('session', ['user', 'channelsMap', 'projectMaps', 'agentWorkAtHome']),
    ...mapState('file', ['fileMap']),

    setWidth() {
      const language = localStorage.getItem('language');
      const widthClass = language === 'ja-JP' ? 'w-75-px' : 'w-55-px';
      return widthClass;
    },
    files() {
      return this.fileMap[this.projectId] || [];
    },
    items() {
      const records = this.files.map(row => {
        return {
          id: row.id,
          text: row.file_name,
          parent_id: row.parent_id,
          data: {
            type: row.type,
            link: row.file_src,
            size: row.file_size || 0,
            payload: row.payload,
            file_type: row.file_type
          },
          options: { multiple: false }
        };
      });
      return this.getNestedChildren(records, this.projectId);
    },
    totalFileSize() {
      const items = this.files.map(({ file_size = 0 }) => file_size);
      return items.reduce((t, v) => (t += v), 0);
    },
    projectSetting() {
      if (!this.projectId) return null;
      const project = this.projectMaps[this.projectId] || {};
      const { config = null } = project;
      if (!config) return null;

      const { fileManagement = {} } = config;
      const {
        audioUploadSize,
        blockedExtensions = [],
        fileUploadSize,
        imageUploadSize,
        projectStorage,
        videoUploadSize
      } = { ...FILE_MANAGEMENT_DEFAULT, ...fileManagement };
      return {
        audioUploadSize,
        blockedExtensions,
        fileUploadSize,
        imageUploadSize,
        projectStorage,
        videoUploadSize
      };
    },
    blockedExtensions() {
      const projectSetting = this.projectSetting || {};
      const { blockedExtensions = [] } = projectSetting;
      return blockedExtensions.map(ex => ex.toLowerCase());
    },
    fileSizeLimitation() {
      const projectSetting = this.projectSetting || {};
      const { audioUploadSize, fileUploadSize, imageUploadSize, videoUploadSize } = {
        ...FILE_MANAGEMENT_DEFAULT,
        ...projectSetting
      };
      return {
        fileUploadSize,
        imageUploadSize,
        videoUploadSize,
        audioUploadSize
      };
    },
    totalProjectSize() {
      const projectSetting = this.projectSetting || {};
      const { projectStorage = 0 } = projectSetting; // GB
      return this.convertToByte(projectStorage * 1024);
    },
    canCreateUploadDelete() {
      const projectMaps = this.projectMaps;
      const project = projectMaps[this.projectId];
      const { groups = {} } = project;
      const hasGroup = Object.keys(groups).length;
      if ([AGENT_ROLE.LEADER].includes(this.user.role)) return true;
      if ([AGENT_ROLE.MODERATOR].includes(this.user.role) && hasGroup) return true;
      return false;
    },
    percentage() {
      const totalProjectSize = this.totalProjectSize;
      if (!totalProjectSize) return 100;
      if (totalFileSize > totalProjectSize) return 100;

      const totalFileSize = this.totalFileSize;
      const percentage = (totalFileSize / totalProjectSize) * 100;
      return Math.round((percentage + Number.EPSILON) * 100) / 100;
    }
  },

  created() {
    this.fetch(this.projectId);
  },
  methods: {
    ...mapActions('file', ['fetch', 'remove', 'create']),
    ...mapActions('chat', ['uploadFileSharing', 'deleteFileSharing']),
    ...mapActions('global', ['setGlobalReady']),

    async confirmDeleteNode(id) {
      const exists = this.existsChildren(id);
      const nodes = this.$refs.tree.find({ id });
      const node = nodes[0];

      if (node) {
        if (node.expanded()) {
          node.collapse(false);
        } else {
          node.expand(false);
        }
      }

      this.deleting_node = node;
      if (this.deleting_node.data.type == 'folder') {
        if (exists) {
          this.messageConfirmDeleteFolder = this.$t(
            'src.modules.file-management.index.do_you_still_want_to_delete_the_folder_and_its_children_items'
          );
        } else {
          this.messageConfirmDeleteFolder = this.$t(
            'src.modules.chat.components.ChatBox.file-manager.are_you_sure_to_delete_this_folder'
          );
        }
      } else {
        this.messageConfirmDeleteFolder = this.$t(
          'src.modules.chat.components.ChatBox.file-manager.are_you_sure_to_delete_this_file'
        );
      }
      this.$baseConfirm({
        message: this.messageConfirmDeleteFolder
      })
        .then(() => {
          this.doDeleteNode();
        })
        .catch(() => {});
      return;
    },
    existsChildren(id) {
      const items = this.files.filter(({ parent_id }) => {
        return parent_id == id;
      });

      return !items || items.length === 0 ? false : true;
    },
    async doDeleteNode() {
      this.setGlobalReady(false);
      if (!this.deleting_node) return;
      const id = this.deleting_node.id;
      await this.remove({
        project_id: this.projectId,
        id: id
      }).then(() => {
        let selection = this.$refs.tree.find({ id: id });
        selection.remove();
        this.setGlobalReady(true);
        this.$baseNotification.success({
          title: this.$t('src.core.App.success'),
          message: this.$t(
            'src.modules.chat.components.ChatBox.file-manager.item_was_deleted'
          )
        });
        this.deleting_node = null;
        this.selected_node = null;
      });
    },
    getNestedChildren(arr, parent) {
      let out = [];
      for (let i in arr) {
        if (arr[i].parent_id == parent) {
          let children = this.getNestedChildren(arr, arr[i].id);

          if (children.length) {
            arr[i].children = children;
          }
          out.push(arr[i]);
        }
      }
      return out;
    },
    treeSort(a, b) {
      // Tree Node objects
      if (a.data.type == b.data.type) {
        return a.data.text.toUpperCase().localeCompare(b.data.text.toUpperCase());
      }
      return a.data.type == 'folder' ? -1 : 1;
    },
    openFile() {
      const fileSharingCreateButton = document.getElementById('fileSharingCreateButton');
      fileSharingCreateButton.click();
    },
    convertToByte(mb) {
      return mb * 1024 * 1024;
    },
    async fileUpload(event) {
      let { files } = event && event.target;
      const uploadFileBtn = document.getElementById('fileSharingCreateButton');
      if (files && files.length > 0) {
        files = [...files];
        // Validate total size
        await this.fetch(this.projectId);
        const errors = this.validateFile(files);
        if (errors.length) {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: errors.join('<br/>')
          });
          uploadFileBtn.value = '';
          throw new Error('Error');
        }

        let newFiles = [...this.uploadingFiles, ...files];
        this.uploadingFiles = newFiles;
        uploadFileBtn.value = '';
      }
      this.resetInputFile();
    },
    /* files: [{
     *   name: "name.jpg"
     *   size: 18317
     *   type: "image/jpeg"
     * }]
     */
    validateFile(files) {
      const getExtension = f => f.name.split('.').pop();
      let errors = [];
      const blockedExtensions = this.blockedExtensions;
      const fileSizeLimitation = this.fileSizeLimitation;
      const totalProjectSize = this.totalProjectSize;

      // Check total project size
      const totalSize = files.reduce((t, f) => t + (f.size || 0), 0);
      if (totalSize + this.totalFileSize > totalProjectSize) {
        errors.push(
          this.$t('src.modules.file-management.index.error.exceeds_total_allowable_size')
        );
        return errors;
      }

      // Check file extension and size
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Check extensions
        if (blockedExtensions.length) {
          const ext = getExtension(file);
          if (
            blockedExtensions.includes(ext.toLowerCase()) ||
            blockedExtensions.includes('.' + ext.toLowerCase())
          ) {
            errors.push(
              `[${file.name}] ${this.$t(
                'src.modules.file-management.index.invalid_extension'
              )}`
            );
            return errors;
          }
        }

        // Check filesize
        const typeSize = getFileType(file);
        if (file.size > this.convertToByte(fileSizeLimitation[typeSize] || 0)) {
          const message = `[${file.name}] ${this.$t(
            'src.modules.file-management.index.error.size_exceeds_maximum_allowable_size'
          )} (${fileSizeLimitation[typeSize]}MB).`;
          errors.push(message);
          return errors;
        }
      }

      return errors;
    },
    resetInputFile() {
      this.$refs.resetInputFile.value = null;
    },
    getParentNodeId() {
      const node = this.selected_node;
      if (node) {
        const id = node.data.type == 'folder' ? node.id : node.parent.id;
        return id;
      }
      return this.projectId;
    },
    async createFile({ downloadURL: url, type, size, name, payload }) {
      await this.create({
        project_id: this.projectId,
        file_name: name.trim(),
        file_type: type,
        file_size: size,
        file_src: url,
        payload: payload,
        type: 'file',
        parent_id: this.getParentNodeId(),
        created_by: this.user.id
      })
        .then(({ row }) => {
          const node = {
            id: row.id,
            text: row.file_name,
            parent_id: row.parent_id,
            data: {
              type: row.type,
              link: row.file_src,
              file_type: row.file_type,
              payload: row.payload
            }
          };
          if (this.selected_node.data.type == 'file') {
            this.selected_node.parent.append(node);
            this.selected_node.parent.children.sort(this.treeSort);
            this.selected_node.parent.expand();
          } else {
            this.selected_node.append(node);
            this.selected_node.children.sort(this.treeSort);
            this.selected_node.expand();
          }
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t('src.modules.chat.components.ChatBox.file-manager.success')
          });
        })
        .catch(e => this.handleError(e));
    },

    // folder
    async toggleFolderName() {
      this.showFolderName = !this.showFolderName;
      if (this.showFolderName) {
        await this.$nextTick();
        setTimeout(() => {
          this.$refs.inputFolderName.$refs.input.focus();
        }, 25);
      }
    },
    onNodeSelected(node) {
      this.selected_node = node;
      this.canSend = node.data.type == 'file';
      if (node.data.type == 'folder') {
        node.children.sort(this.treeSort);
      }
    },
    async createFolder(event) {
      const { key } = event;
      if (key === 'Enter') {
        this.setGlobalReady(false);
        this.folder_name = this.folder_name.trim();
        if (!this.folder_name) {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.file-management.index.error.empty_name')
          });
          return this.setGlobalReady(true);
        } else {
          const selected_node_id = this.selected_node.id;
          let existFolders = this.files.filter(
            ({ type, parent_id }) => type == 'folder' && parent_id == selected_node_id
          );
          existFolders = existFolders.map(({ file_name }) => file_name.toUpperCase());
          if (existFolders.includes(this.folder_name.toUpperCase())) {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: this.$t('src.modules.file-management.index.error.exist_name')
            });
            return this.setGlobalReady(true);
          }

          await this.create({
            project_id: this.projectId,
            file_name: this.folder_name.trim(),
            file_src: '',
            type: 'folder',
            parent_id: this.getParentNodeId(),
            created_by: this.user.id
          })
            .then(({ row }) => {
              const node = {
                id: row.id,
                text: row.file_name,
                parent_id: row.parent_id,
                data: {
                  type: row.type
                }
              };
              this.folder_name = '';
              if (this.selected_node.data.type == 'file') {
                this.selected_node.parent.append(node);
                this.selected_node.parent.children.sort(this.treeSort);
                this.selected_node.parent.expand();
              } else {
                this.selected_node.append(node);
                this.selected_node.children.sort(this.treeSort);
                this.selected_node.expand();
              }
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t(
                  'src.modules.chat.components.ChatBox.file-manager.success'
                )
              });
            })
            .catch(e => this.handleError(e));
          this.setGlobalReady(true);
        }
      }
    },
    onOkButton() {
      if (!this.selected_node) return;
      if (this.selected_node.data.type != 'file') return;
      const { data = {} } = this.selected_node || {};
      const { text = '', size = '', file_type = '' } = data;
      const errors = this.validateFile([{ name: text, size, type: file_type }]);
      if (errors.length) {
        return this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: errors.join('<br/>')
        });
      }
      this.$emit('onSendFileManager', this.selected_node);
    },
    onShowModal() {
      this.uploadingFiles = [];
      this.fetch(this.projectId);
    },
    async onMounted() {
      const children = this.$refs.tree.find({ id: this.projectId });
      const root = children[0];
      this.selected_node = root;
      root.append(this.items);
      root.children.sort(this.treeSort);
    },
    handleTableActionCopy(item) {
      if (this.agentWorkAtHome) {
        return false;
      }
      const file_src = item.data.link;
      const $tempInput = document.createElement('INPUT');
      const $body = document.getElementById('fileManager');
      $body.appendChild($tempInput);
      $tempInput.setAttribute('value', file_src);
      $tempInput.select();
      document.execCommand('copy');
      $body.removeChild($tempInput);
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: this.$t('src.modules.file-management.index.link_was_copied')
      });
    },
    searchEvent(event) {
      const { keyCode } = event;
      if (keyCode == 27) this.searchText = '';
    },

    handleError(error) {
      if (error.message == 'PROJECT_EXCEED_LIMITATION') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(
            'src.modules.file-management.index.notify_reach_project_limitation'
          )
        });
      } else if (error.message == 'DUPLICATE_FOLDER') {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.file-management.index.error.exist_name')
        });
      } else {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(error.message)
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/cs/form.scss';
/deep/.tree-filter-empty {
  text-align: center;
}

.breadcrumb-item.active {
  color: #525f7f;
  font-weight: bold;
}

.tree {
  /deep/.tree-anchor {
    color: #525f7f !important;
  }

  /deep/.tree-node.selected > .tree-content {
    background-color: #c9daef;
  }
}

#clFileSharing {
  .info-container {
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }
  .col {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

/deep/ .v-select {
  display: -webkit-box;
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    &:after {
      content: none;
    }
    .selected-tag {
      color: #8898aa;
    }
  }
  .dropdown-menu {
    margin-top: 2px;
    padding: 0;
    li {
      a {
        padding: 10px;
        min-height: 23px;
        color: #8898aa;
        font-size: 0.875rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }
    .highlight {
      a {
        background: #4bd;
        color: #ffffff;
      }
    }
  }
  input {
    margin: -10px 0 0;
    width: 100%;
    height: 100%;
  }
  .no-options {
    display: none;
  }
}

/deep/ .vdp-datepicker {
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    line-height: 1.5;
    color: #8898aa;
    border: 0px;
    height: 100%;
    width: 100%;
  }
}

.tree-container {
  .tree-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    .tree-icon-delete {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .tree-icon-copy {
      position: absolute;
      right: 20px;
      top: 10px;
    }
    .isDisabledLinked {
      cursor: not-allowed;
    }
  }
}

/deep/.el-progress {
  width: 100%;
}

.custom-color-text {
  /deep/.el-progress-bar__innerText {
    color: #8c8c8c;
  }
}

.w-55-px {
  width: 55px;
}

.w-75-px {
  width: 75px;
}
</style>
