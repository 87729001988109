<template>
  <b-card-group deck>
    <b-card>
      <div slot="header" class="media clearfix">
        <a class="pull-left" href="" @click.prevent.stop>
          <img
            :src="creatorPicture"
            :alt="creatorPictureAlt"
            class="img-fluid avatar shadow-sm"
            @error="$event.target.src = noAvatar"
          />
        </a>
        <div class="pl-10" style="display: inline-block; line-height: 12px">
          <h6 class="mt-0 mb-0 text-left pt-5">
            <a href="javascript:void(0)">{{ selectedPost.creator_name }}</a>
          </h6>
          <small class="text-grey">
            {{ selectedPost.create_time }}
            <el-tooltip :content="createTime" class="item" effect="dark" placement="top">
              <span class="text-lowercase">{{ timeAgo }}</span>
            </el-tooltip>
          </small>
        </div>
        <el-tooltip
          :content="
            $t(
              'src.modules.chat.components.CommentBox.CommentDetail.index.reload-the-latest-content-from-facebook'
            )
          "
          class="item"
          effect="dark"
          placement="top"
        >
          <button class="btn-sm btn-outline-light btn-close btn-reload-2" @click="handleReload">
            <i v-if="loadIcon" class="fas fa-spinner fa-spin" />
            <i v-else class="fas fa-sync-alt" />
          </button>
        </el-tooltip>
        <button class="btn-sm btn-outline-light btn-close" @click="setSelectedComment({})">
          <i class="fas fa-times" />
        </button>
      </div>
      <div class="row pl-10">
        <div
          class="col-lg-5 col-md-5 col-sm-12 col-xs-12 bg-white p-0 post-mh-max"
          :style="`max-height: calc(100vh - ${heightCommentFilter}px - 120px)`"
        >
          <post v-bind="selectedPost" />
        </div>
        <div
          class="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-0 pr-10 pl-10 comment-mh-max"
          :style="`max-height: calc(100vh - ${heightCommentFilter}px - 120px)`"
        >
          <div>
            <comment-content />
            <reply-list />
          </div>
          <comment-input v-if="isEditComment" />
        </div>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
import moment from 'moment-timezone';
import ReplyList from './ReplyList';
import Post from './Post';
import CommentContent from './content';
import CommentInput from './CommentInput';
import noAvatar from 'assets/images/no-avatar.png';

import { mapState, mapActions, mapGetters } from 'vuex';
import { convertTimeUTC } from '../../../../../core/helpers';
export default {
  components: {
    ReplyList,
    CommentInput,
    Post,
    CommentContent
  },

  props: {
    heightCommentFilter: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      noAvatar,
      loadIcon: false,
      isEditComment: true
    };
  },

  computed: {
    ...mapState('comment', ['selectedComment']),
    ...mapGetters('comment', ['selectedPost', 'replies']),

    creatorPicture() {
      const { post_detail: { creator_picture = '' } = {} } = this.selectedComment;
      return creator_picture;
    },

    creatorPictureAlt() {
      const { post_detail: { creator_name = '' } = {} } = this.selectedComment;
      return creator_name;
    },

    timeAgo() {
      const at = convertTimeUTC(this.selectedPost.created_time, 'comment');
      const now = moment();
      return now.from(at, true);
    },

    createTime() {
      return convertTimeUTC(this.selectedPost.created_time);
    }
  },

  methods: {
    ...mapActions('comment', ['setSelectedComment', 'reloadCommentAndPost']),

    async handleReload() {
      try {
        this.loadIcon = true;
        const params = {
          comment_id: this.selectedComment.comment_id,
          post_id: this.selectedComment.post_id,
          platform: this.selectedComment.platform,
          page_id: this.selectedComment.page_id
        };
        await this.reloadCommentAndPost(params);
        this.loadIcon = false;
      } catch (err) {
        this.loadIcon = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;

  &.fa-thumbs-up-custom {
    color: #365899;
  }
}

.card-deck {
  .card {
    .card-header {
      padding: 10px !important;
      text-align: left;
      background-color: #fff;

      img {
        width: 42px;
        height: 42px;
      }
    }

    .card-body {
      padding: 10px !important;
      text-align: left;
      background-color: #f9f9fc;

      .post-mh-max {
        max-height: calc(100vh - 120px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .comment-mh-max {
        max-height: calc(100vh - 120px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}

.btn-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:active,
  &:focus {
    outline: 0;
    -moz-outline-style: none;
  }
}
.btn-reload-2 {
  margin-right: 30px;
}
</style>
