<template><div></div></template>
<script>
import fireStorage from 'core/firebase/storage';
import toastr from 'core/toastr';
import { getVideoImage } from 'core/helpers';
import { IMAGE_EXTENSIONS_SUPPORTED } from 'core/constants';

export default {
  props: {
    file: {
      type: File,
      default: () => null
    },

    path: {
      type: String,
      default: ''
    },

    platform: {
      type: String,
      default: 'facebook'
    },

    group: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      done: false,
      value: 0,
      text: '',
      sending: false,
      error: false,
      fileUrl: '',
      payload: null
    };
  },

  computed: {
    name() {
      let _name = (this.file && this.file.name) || '';
      if (this.sending && !this.error)
        _name = this.$t('src.modules.chat.components.ChatBox.file-upload.sending');
      return _name;
    },
    type() {
      let type = 'file';
      if (this.file) {
        if (this.file.type.startsWith('image')) {
          const extensionsSupported = IMAGE_EXTENSIONS_SUPPORTED.some(ex =>
            this.file.name.toLowerCase().endsWith(ex)
          );
          if (extensionsSupported) type = 'image';
        } else if (
          this.file.type === 'audio/mp3' ||
          this.file.type === 'audio/mpeg' ||
          this.file.type === 'audio/ogg' ||
          this.file.type === 'audio/wav' ||
          this.file.type === 'audio/flac'
        ) {
          type = 'audio';
        } else if (
          this.file.type === 'video/mp4' ||
          this.file.type === 'video/ogg' ||
          this.file.type === 'video/webm'
        ) {
          type = 'video';
        }
      }
      return type;
    }
  },

  async created() {
    const _self = this;
    const { type, name, group } = this;
    if (!this.file) return;
    const uploadTask = fireStorage.put(this.file, `/${this.path}/${this.name}`);
    if (this.platform === 'line' && type === 'video') {
      await getVideoImage(this.file).then(({ url, error }) => {
        if (error == null) {
          this.payload = { thumbnail: url };
        } else {
          this.payload = null;
          this.error = true;
        }
      });
    }
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        _self.value = progress;
        switch (snapshot.state) {
          case 'paused':
            _self.text = this.$t(
              'src.modules.chat.components.ChatBox.file-upload.upload_is_paused'
            );
            break;
          case 'running':
            _self.text = '';
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
        toastr.error(
          this.$t('src.modules.chat.components.ChatBox.file-upload.upload_failed') +
            '\r\n' +
            error.message
        );
        _self.done = true;
        // eslint-disable-next-line
        console.log('Upload error -> file: ', _self.file);
        // eslint-disable-next-line
        console.log('Upload error -> error: ', error);
      },
      function() {
        _self.value = 100;
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          if (_self.platform == 'line' && type === 'audio') {
            _self.sending = true;
            var audio = new Audio();
            _self.fileUrl = downloadURL;
            audio.src = downloadURL;
            audio.ondurationchange = () => {
              _self.$emit('onSuccess', {
                downloadURL,
                type,
                size: _self.file.size,
                name,
                payload: {
                  duration: audio.duration
                },
                group
              });
              _self.done = true;
            };
            audio.onerror = () => {
              _self.$emit('onSuccess', {
                downloadURL,
                type: 'file',
                size: _self.file.size,
                name,
                group
              });
              _self.done = true;
            };
          } else if (_self.platform === 'line' && type === 'video') {
            _self.fileUrl = downloadURL;
            _self.sendVideo();
          } else {
            _self.$emit('onSuccess', {
              downloadURL,
              type,
              size: _self.file.size,
              name,
              group
            });
            _self.done = true;
          }
        });
      }
    );
  },

  methods: {
    sendFile() {
      this.$emit('onSuccess', {
        downloadURL: this.fileUrl,
        type: 'file',
        size: this.file.size,
        name: this.file.name,
        group: this.group
      });
      this.done = true;
    },
    sendVideo() {
      if (this.fileUrl !== '' && this.payload) {
        this.$emit('onSuccess', {
          downloadURL: this.fileUrl,
          type: 'video',
          size: this.file.size,
          name: this.name,
          payload: this.payload,
          group: this.group
        });
        this.done = true;
      }
    }
  }
};
</script>

<style scoped>
.badge {
  cursor: pointer;
}
</style>
