/** * version: 0.0.1 * created: Phuong.Nguyen * Password Strength Metter **/
<template>
  <div class="password">
    <div class="password-group">
      <div v-if="showStrengthMeter" v-bind:class="[strengthMeterClass]">
        <div v-bind:class="[strengthMeterFillClass]" :data-score="passwordStrength"></div>
      </div>
      <input
        :id="id"
        :name="name"
        :type="inputType"
        v-model="value"
        :maxlength="128"
        :class="[havePasswordIcons, defaultClass, disabled ? disabledClass : '']"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div class="password-icons">
        <div
          v-if="badgePasswordCount"
          v-cloak
          v-bind:class="[isSecure ? successClass : '', !isSecure && isActive ? errorClass : '']"
          class="password-badge"
        >
          {{ passwordCount }}
        </div>
        <div v-if="toggle" class="password-toggle">
          <button type="button" class="btn-clean" @click.prevent="togglePassword()">
            <svg
              v-if="showPassword"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 9c1.641 0 3 1.359 3 3s-1.359 3-3 3-3-1.359-3-3 1.359-3 3-3zM12 17.016c2.766 0 5.016-2.25 5.016-5.016s-2.25-5.016-5.016-5.016-5.016 2.25-5.016 5.016 2.25 5.016 5.016 5.016zM12 4.5c5.016 0 9.281 3.094 11.016 7.5-1.734 4.406-6 7.5-11.016 7.5s-9.281-3.094-11.016-7.5c1.734-4.406 6-7.5 11.016-7.5z"
              />
            </svg>
            <svg
              v-else
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M11.859 9h0.141c1.641 0 3 1.359 3 3v0.188zM7.547 9.797c-0.328 0.656-0.563 1.406-0.563 2.203 0 2.766 2.25 5.016 5.016 5.016 0.797 0 1.547-0.234 2.203-0.563l-1.547-1.547c-0.188 0.047-0.422 0.094-0.656 0.094-1.641 0-3-1.359-3-3 0-0.234 0.047-0.469 0.094-0.656zM2.016 4.266l1.266-1.266 17.719 17.719-1.266 1.266c-1.124-1.11-2.256-2.213-3.375-3.328-1.359 0.563-2.813 0.844-4.359 0.844-5.016 0-9.281-3.094-11.016-7.5 0.797-1.969 2.109-3.656 3.75-4.969-0.914-0.914-1.812-1.844-2.719-2.766zM12 6.984c-0.656 0-1.266 0.141-1.828 0.375l-2.156-2.156c1.219-0.469 2.578-0.703 3.984-0.703 5.016 0 9.234 3.094 10.969 7.5-0.75 1.875-1.922 3.469-3.422 4.734l-2.906-2.906c0.234-0.563 0.375-1.172 0.375-1.828 0-2.766-2.25-5.016-5.016-5.016z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convert2OneByte } from 'components/BaseComponents/vee-validate';
export default {
  props: {
    id: {
      type: String,
      default: 'password'
    },
    placeholder: {
      type: String,
      default: 'Please enter your password'
    },
    name: {
      type: String,
      default: 'password'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    secureLength: {
      type: Number,
      default: 6
    },
    badgePasswordCount: {
      type: Boolean,
      default: true
    },
    toggle: {
      type: Boolean,
      default: false
    },
    showStrengthMeter: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      type: String,
      default: 'password-field'
    },
    disabledClass: {
      type: String,
      default: 'password-field--disabled'
    },
    errorClass: {
      type: String,
      default: 'password-badge--error'
    },
    successClass: {
      type: String,
      default: 'password-badge--success'
    },
    strengthMeterClass: {
      type: String,
      default: 'password-strength-meter'
    },
    strengthMeterFillClass: {
      type: String,
      default: 'password-strength-meter--fill'
    }
  },
  data() {
    return {
      value: '',
      password: '',

      contains_lovercase: false,
      contains_number: false,
      contains_uppercase: false,
      contains_long: false,
      contains_special: false,

      showPassword: false
    };
  },
  computed: {
    passwordStrength() {
      return (
        Number(this.contains_lovercase) +
        Number(this.contains_number) +
        Number(this.contains_uppercase) +
        Number(this.contains_long) +
        Number(this.contains_special)
      );
    },

    isSecure() {
      return (
        (this.password &&
          this.password.length >= this.secureLength &&
          this.contains_lovercase &&
          this.contains_number &&
          this.contains_special &&
          this.contains_uppercase) ||
        false
      );
    },

    isActive() {
      return this.password && this.password.length > 0;
    },

    passwordCount() {
      return (
        this.password &&
        (this.password.length > this.secureLength ? `${this.secureLength}+` : this.password.length)
      );
    },

    inputType() {
      return this.showPassword || this.showPassword ? 'text' : 'password';
    },

    havePasswordIcons() {
      return this.toggle ? 'password-show-toggle' : 'password-input';
    }
  },

  watch: {
    value(newValue) {
      this.emitValue('input', newValue);
    },

    isSecure(secure) {
      this.$emit('secure', secure);
    }
  },
  methods: {
    onBlur() {
      this.value = convert2OneByte(this.value);
      this.emitValue('blur', this.value);
    },
    onInput() {
      this.emitValue('input', this.value);
    },
    onFocus() {
      this.emitValue('focus', this.value);
    },
    checkSecure() {
      this.password = this.value;
      this.contains_lovercase = /[a-z]/.test(this.password);
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_long = this.password.length >= this.secureLength;
      const defineRegex = '[/!@#$%^&*(),.?"\'‘“”’:{}|<>\\[\\]]';
      const regexp = new RegExp(defineRegex, 'gi');
      this.contains_special = regexp.test(this.password);
      return (
        (this.password &&
          this.contains_long &&
          this.contains_lovercase &&
          this.contains_number &&
          this.contains_special &&
          this.contains_uppercase) ||
        false
      );
    },
    togglePassword() {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    emitValue(type, value) {
      const isSecure = this.checkSecure();
      this.$emit(type, value, isSecure);
    }
  }
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.password {
  width: 100%;
  margin: 0 auto;
}
.password-group {
  position: relative;
}
.password-show-toggle {
  padding-right: 85px !important;
}
.password-input {
  padding-right: 50px !important;
}
.password-strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 10px auto 5px;
  border-radius: 3px;
}
.password-strength-meter:before,
.password-strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 20%;
  z-index: 10;
}
.password-strength-meter:before {
  left: 20%;
}
.password-strength-meter:after {
  right: 20%;
}
.password-strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.password-strength-meter--fill[data-score='1'] {
  background: darkred;
  width: 20%;
}
.password-strength-meter--fill[data-score='2'] {
  background: orangered;
  width: 40%;
}
.password-strength-meter--fill[data-score='3'] {
  background: orange;
  width: 60%;
}
.password-strength-meter--fill[data-score='4'] {
  background: yellowgreen;
  width: 80%;
}
.password-strength-meter--fill[data-score='5'] {
  background: green;
  width: 100%;
}
.password-field {
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px;
  width: 100%;
}
.password-field--disabled {
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
}
.password-icons {
  position: absolute;
  top: 5px;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.password-toggle {
  line-height: 1.1;
  margin-right: 13px;
}
.password-badge {
  position: relative;
  color: white;
  border-radius: 15px;
  padding: 3px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 1.1;
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.password-badge--error {
  background: red;
}
.password-badge--success {
  background: #1bbf1b;
}
.btn-clean {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #777777;
  padding: 0;
  svg {
    fill: currentColor;
  }
  &:hover,
  &:focus {
    color: #404b69;
  }
}
</style>
