<template>
  <div class="container-fluid p-0 page">
    <vue-element-loading
      :active="ready && loading && !isRequireAuth"
      :is-full-screen="true"
      spiner="line-scale"
    />
    <!-- Left Menu -->
    <AppLeftMenu show-name="search" />

    <!-- Main Container -->
    <main class="nat-main-ctnr">
      <AppCover />

      <div class="nat-sub-ctnr">
        <section>
          <div class="nat-cover-ctnr">
            <!-- Title (S)-->
            <b-row class="nat-title-wrap">
              <b-col xl="12">
                <h2>{{ $t('src.modules.search-management.index.search') }}</h2>
              </b-col>
            </b-row>
            <!-- Title (E)-->

            <b-row class="nat-content-wrap">
              <b-col xl="12">
                <!-- Condition bar (S) -->
                <ValidationObserver tag="form" ref="searchManagementCondition">
                  <b-row class="nat-condition-bar">
                    <b-col xl="12">
                      <!-- Project panel -->
                      <b-row class="nat-condition-row">
                        <!-- Project Selection -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="projectSel"
                              :options="projectList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'projectSel'"
                              :required="true"
                              :label="$t('src.modules.search-management.index.project')"
                              :value-type="'String'"
                            />
                          </b-row>
                        </b-col>

                        <!-- Platform Selection -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="platformSel"
                              :options="platformList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'platformSel'"
                              :label="$t('src.modules.search-management.index.platform')"
                              :value-type="'String'"
                              @input="onChangePlatform"
                            />
                          </b-row>
                        </b-col>

                        <!-- Channel Selection -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="channelSel"
                              :options="channelList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'channelSel'"
                              :label="$t('src.modules.search-management.index.channel')"
                              :value-type="'String'"
                            />
                          </b-row>
                        </b-col>

                        <!-- Group Selection -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-if="groupList.length > 0"
                              v-model="groupSel"
                              :options="groupList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'groupSel'"
                              :label="$t('src.modules.search-management.index.group')"
                              :value-type="'String'"
                              :using-for="'select-group'"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- Conversation panel -->
                      <b-row class="nat-condition-row">
                        <!-- State -->

                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="stateValue"
                              :options="stateList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'stateValue'"
                              :label="$t('common.text.phase')"
                              :value-type="'String'"
                              @input="handleSlctStateChng"
                            />
                          </b-row>
                        </b-col>

                        <!-- Status -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="statusValue"
                              :options="statusList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'statusValue'"
                              :label="$t('src.modules.search-management.index.status')"
                              :value-type="'String'"
                            />
                          </b-row>
                        </b-col>

                        <!-- Conversation Id -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.conversation_id')"
                              :name="'ticketId'"
                              v-model="ticketId"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- Label panel -->
                      <b-row class="nat-condition-row">
                        <!-- Label -->

                        <b-col xl="6">
                          <b-row class="nat-condition-item">
                            <base-select-multi
                              v-model="conversationLabels"
                              :options="c_projectCnvLables"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'conversationLabels'"
                              :label="
                                $t('src.modules.search-management.index.label.conversation_labels')
                              "
                              :value-type="'String'"
                              :tooltip-text="
                                $t('src.modules.search-management.index.ticket_lable_help')
                              "
                              :tooltip-icon="'question'"
                            />
                          </b-row>
                        </b-col>

                        <!-- Folow up -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="selectedFollowUp"
                              :options="optionsFollowUp"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="selectedFollowUp"
                              :label="$t('src.modules.search-management.index.follow_up')"
                              :value-type="'String'"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- User panel -->
                      <b-row class="nat-condition-row">
                        <!-- User Id -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.user_id')"
                              :name="'userId'"
                              v-model="userId"
                              :maxlength="36"
                            />
                          </b-row>
                        </b-col>

                        <!-- User name-->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.user_name')"
                              :name="'userName'"
                              v-model="userName"
                              :maxlength="60"
                            />
                          </b-row>
                        </b-col>

                        <!-- User language-->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-select
                              v-model="userLanguage"
                              :options="c_languageList"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'userLanguage'"
                              :label="$t('src.modules.search-management.index.user_language')"
                              :value-type="'String'"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- User Label panel -->
                      <b-row class="nat-condition-row">
                        <!-- User Label -->
                        <b-col xl="6">
                          <b-row class="nat-condition-item">
                            <base-select-multi
                              v-model="userLabels"
                              :options="c_projectLables"
                              :option-value="'value'"
                              :option-lable="'text'"
                              :name="'userLabels'"
                              :label="$t('src.modules.search-management.index.label.user_labels')"
                              :value-type="'String'"
                              :tooltip-text="
                                $t('src.modules.search-management.index.user_lable_help')
                              "
                              :tooltip-icon="'question'"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- Agent panel -->
                      <b-row class="nat-condition-row" v-show="c_isShowAgentPanel">
                        <!-- Agent Id -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.agent_id')"
                              :name="'agentId'"
                              v-model="agentId"
                              :maxlength="36"
                            />
                          </b-row>
                        </b-col>

                        <!--Agent Name -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.agent_name')"
                              :name="'agentName'"
                              v-model="agentName"
                              :maxlength="60"
                            />
                          </b-row>
                        </b-col>

                        <!--Agent Id Login -->
                        <b-col xl="3">
                          <b-row class="nat-condition-item">
                            <base-input
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.login_id')"
                              :name="'idLogin'"
                              v-model="idLogin"
                              :maxlength="320"
                            />
                          </b-row>
                        </b-col>
                      </b-row>

                      <!-- Message panel -->
                      <b-row class="nat-condition-row">
                        <!-- Message Title -->
                        <b-col xl="6">
                          <b-row class="nat-condition-item">
                            <base-input-tag-select
                              :placeholder-type="'search'"
                              :label="$t('src.modules.search-management.index.message')"
                              :name="'tagsAndSelect'"
                              v-model="tagsAndSelect"
                              :tooltip-text="
                                $t('src.modules.search-management.index.message_lable_help')
                              "
                              :tooltip-icon="'question'"
                              :selected-default-key="'or'"
                              :options="[
                                { key: 'or', name: 'OR' },
                                { key: 'and', name: 'AND' }
                              ]"
                              :select-width="90"
                              :max-rows="3"
                            ></base-input-tag-select>
                          </b-row>
                        </b-col>
                        <b-col xl="3">
                          <b-row class="nat-condition-item-checkbox">
                            <b-form-checkbox v-model="warningWords">
                              {{ $t('src.modules.search-management.index.warning_words') }}
                            </b-form-checkbox>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="nat-condition-row" v-if="isInstagram">
                        <b-col class="nat-condition-checkbox-group">
                          <b-form-group
                            :label="$t('src.modules.search-management.index.instagram_event_type')"
                          >
                            <b-form-checkbox-group
                              id="story-type-group"
                              v-model="storyType"
                              name="storyType"
                              :options="storyTypes"
                            >
                            </b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </ValidationObserver>

                <!-- Condition bar (E) -->

                <!-- Action bar (S) -->
                <b-row class="nat-action-bar">
                  <b-col xl="12">
                    <b-row>
                      <b-col sm="12" lg="12">
                        <span id="search-download-excel-file" style="float: right">
                          <b-button
                            :disabled="agentWorkAtHome"
                            variant="secondary"
                            class="mb-5"
                            style="float: right"
                            @click="handleBtnDownLoadExcelFile"
                          >
                            <i class="el-icon-download" style="font-size: large"></i>
                            {{ $t('src.modules.search-management.index.download_excel_file') }}
                          </b-button>
                        </span>
                        <BootstrapTooltipCustom
                          id="search-download-excel-file"
                          :content="
                            agentWorkAtHome
                              ? $t('src.components.AppLeftMenu.index.workFromHome')
                              : $t(
                                  'src.modules.search-management.index.export_the_current_Search_table_content_into_excel_file'
                                )
                          "
                        />
                        <b-button
                          variant="secondary"
                          class="mb-5"
                          style="float: right; margin-right: 10px"
                          @click="handleBtnAddOptionalColumns"
                        >
                          <i class="el-icon-set-up" style="font-size: large"></i>
                          {{ $t('src.modules.search-management.index.add_optional_columns') }}
                        </b-button>
                        <b-button
                          :disabled="isloadEnd"
                          variant="primary"
                          class="mb-5"
                          style="float: right; margin-right: 10px"
                          @click="handleBtnLoadMore"
                        >
                          <!-- Using button refresh (confirmed)  -->
                          <i
                            class="fas fa-sync"
                            style="font-size: large; transform: rotate(90deg)"
                          ></i>
                          {{ $t('src.modules.search-management.index.load_more') }}
                          {{ c_cntMoreData }}
                        </b-button>
                        <b-button
                          variant="primary"
                          class="mb-5"
                          style="float: right; margin-right: 10px"
                          @click="handleBtnSearch"
                        >
                          <i class="el-icon-search" style="font-size: large"></i>
                          {{ $t('src.modules.search-management.index.search') }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Action bar (E) -->

                <!-- Grid Result (S) -->
                <b-row class="nat-grid-result">
                  <b-col xl="12">
                    <b-table
                      :items="c_paginatedData"
                      :fields="dataHeader"
                      :current-page="pageNumber"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :fixed="true"
                      responsive
                      striped
                      hover
                      thead-class="nat-table-header"
                      tbody-tr-class="nat-tbody-tr"
                      show-empty
                      style="background-color: #ffffff"
                    >
                      <!-- Show row empty data (S) -->
                      <template slot="empty">
                        <div v-if="isBusy" class="text-center">
                          <spinner />
                        </div>
                        <div v-else class="text-center">
                          {{ $t('common.text.no_data') }}
                        </div>
                      </template>
                      <!-- Show row empty data (E) -->

                      <!-- No. (S) -->
                      <template slot="index" slot-scope="data">
                        {{ data.index + 1 + perPage * (pageNumber - 1) }}
                      </template>
                      <!-- No. (E) -->

                      <!-- Ticket ID (S) -->
                      <template slot="id" slot-scope="data">
                        {{ data.value }}
                      </template>
                      <!-- Ticket ID (E) -->

                      <!-- CreatedAt (S) -->
                      <template slot="createdAt" slot-scope="data">
                        {{ m_formatTime(data.value) }}
                      </template>
                      <!-- CreatedAt (E) -->

                      <!-- group (S) -->
                      <template slot="groupName" slot-scope="data">
                        <span v-if="data.value">{{ data.value.text }}</span>
                        <span
                          v-if="data.value && !data.value.isEnable"
                          class="group-status-warning ml-1"
                        >
                          <i
                            v-b-tooltip.hover
                            :title="$t('common.text.group_status')"
                            class="el-icon-warning-outline"
                          ></i>
                        </span>
                      </template>
                      <!-- group (E) -->

                      <!-- State (S) -->
                      <template slot="state" slot-scope="data">
                        {{ data.value }}
                      </template>
                      <!-- State (S) -->

                      <!-- Status (S) -->
                      <template slot="status" slot-scope="data">
                        {{ data.value }}
                      </template>
                      <!-- Status (E) -->

                      <!-- User Id (S) -->
                      <template slot="userId" slot-scope="data">
                        <el-tooltip
                          :content="$t('src.modules.search-management.index.user_info')"
                          class="item"
                          effect="dark"
                          placement="top"
                        >
                          <a href="javascript:void(0)" @click="handleUserInfoClck(data.item)">
                            {{ data.value }}
                          </a>
                        </el-tooltip>
                      </template>
                      <!-- User Id (E) -->

                      <!-- User Name (S)-->
                      <template slot="userName" slot-scope="data">
                        {{ data.value }}
                      </template>
                      <!-- User Name (E)-->

                      <!-- Assignee (S) -->
                      <template slot="assignee" slot-scope="data">
                        <el-tooltip
                          :content="$t('src.modules.search-management.index.agent_info')"
                          class="item"
                          effect="dark"
                          placement="top"
                        >
                          <a
                            v-if="m_agentHandle(data.item) === 'AGENT'"
                            href="javascript:void(0)"
                            @click="handleAgentInfoClck(data.item)"
                          >
                            {{ data.value }}
                          </a>
                        </el-tooltip>
                        <span v-if="m_agentHandle(data.item) === 'AUTO_ASSIGN'" class="ml-2"
                          >AUTO_ASSIGN</span
                        >
                        <span v-if="m_agentHandle(data.item) === 'BOT'" class="ml-2">[BOT]</span>
                        <span v-if="m_agentHandle(data.item) === 'NON_BOT'" class="ml-2">N/A</span>
                        <span v-if="m_agentHandle(data.item) === 'FORWARD_GROUP'" class="ml-2"
                          >N/A</span
                        >
                      </template>
                      <!-- Assignee (E) -->

                      <!-- Agent Name (S) -->
                      <template slot="agentName" slot-scope="data">
                        <p v-if="m_agentHandle(data.item) === 'AGENT'" class="ml-2">
                          {{ data.value }}
                        </p>
                        <p v-if="m_agentHandle(data.item) === 'AUTO_ASSIGN'" class="ml-2">
                          AUTO_ASSIGN
                        </p>
                        <p v-if="m_agentHandle(data.item) === 'BOT'" class="ml-2">[BOT]</p>
                        <p v-if="m_agentHandle(data.item) === 'NON_BOT'" class="ml-2">N/A</p>
                        <p v-if="m_agentHandle(data.item) === 'FORWARD_GROUP'" class="ml-2">N/A</p>
                      </template>
                      <!-- Agent Name (S) -->

                      <!-- Agent Email (S) -->
                      <template slot="agentEmail" slot-scope="data">
                        <el-tooltip
                          v-if="data.value.length > m_truncate(data.value)"
                          :content="data.value"
                          placement="top"
                          class="item"
                          effect="dark"
                        >
                          <span>{{ data.value | truncate(m_truncate(data.value)) }}</span>
                        </el-tooltip>
                        <span v-else>{{ data.value }}</span>
                      </template>
                      <!-- Agent Email (E) -->

                      <!-- Feature (S) -->
                      <template slot="feature" slot-scope="data">
                        <center>
                          <b-form-checkbox v-model="data.value" :disabled="true"></b-form-checkbox>
                        </center>
                      </template>
                      <!-- Feature (E) -->

                      <!-- Tags (S) -->
                      <template slot="tags" slot-scope="data">
                        <span
                          v-for="tag in data.value &&
                          data.value.filter(l => l.sourceType === 'system')"
                          :key="tag.id"
                        >
                          <el-tag
                            slot="reference"
                            effect="dark"
                            size="medium"
                            color="#FF0000"
                            class="user-label-item"
                            style="border-color: #ff0000"
                          >
                            <span>{{ convLabelSysValue(tag.value) }}</span>
                          </el-tag>
                        </span>
                        <span
                          v-for="tag in data.value &&
                          data.value.filter(l => l.sourceType !== 'system')"
                          :key="tag.id"
                        >
                          <el-tag
                            slot="reference"
                            effect="dark"
                            size="medium"
                            :color="renderColor(tag.type)"
                            class="user-label-item"
                            :style="renderStyle(tag.type)"
                          >
                            <el-tooltip
                              v-if="tag.value.length > m_truncate(tag.value)"
                              :content="tag.value"
                              placement="top"
                              class="item"
                              effect="dark"
                            >
                              <span>{{ tag.value | truncate(m_truncate(tag.value)) }}</span>
                            </el-tooltip>
                            <span v-else>{{ tag.value }}</span>
                          </el-tag>
                        </span>
                      </template>
                      <!-- Tags (E) -->

                      <!-- User labels (S) -->
                      <template slot="labels" slot-scope="data">
                        <span
                          v-for="label in data.value.filter(l => l.sourceType === 'system')"
                          :key="label.id"
                        >
                          <el-tag
                            slot="reference"
                            effect="dark"
                            size="medium"
                            color="#FF0000"
                            class="user-label-item"
                            style="border-color: #ff0000"
                          >
                            <span>{{ userLabelSysValue(label.value) }}</span>
                          </el-tag>
                        </span>
                        <span
                          v-for="label in data.value.filter(l => l.sourceType !== 'system')"
                          :key="label.id"
                        >
                          <el-tag
                            slot="reference"
                            effect="dark"
                            size="medium"
                            :color="renderColor(label.type)"
                            :class="
                              'user-label-item ' +
                              (label.sourceType === 'bot' || label.sourceType === 'admin'
                                ? 'nat-tag-bot'
                                : '')
                            "
                            :style="renderStyle(label.type)"
                          >
                            <el-tooltip
                              v-if="label.value.length > m_truncate(label.value)"
                              :content="label.value"
                              placement="top"
                              class="item"
                              effect="dark"
                            >
                              <span>{{ label.value | truncate(m_truncate(label.value)) }}</span>
                            </el-tooltip>
                            <span v-else>{{ label.value }}</span>
                          </el-tag>
                        </span>
                      </template>
                      <!-- User labels (E) -->

                      <!-- Action (S) -->
                      <template slot="action" slot-scope="data">
                        <!-- Comments Action (S) -->
                        <el-tooltip
                          :content="$t('common.text.message_history')"
                          class="item"
                          effect="dark"
                          placement="top"
                        >
                          <a
                            href="javascript:void(0)"
                            class="ml-2"
                            @click="handleMessageHistoryClck(data.item)"
                          >
                            <i class="fa fa-comments"></i>
                          </a>
                        </el-tooltip>
                        <!-- Comments Action (E) -->

                        <!-- File List Action (S) -->
                        <el-tooltip
                          :content="$t('src.modules.search-management.index.file_list')"
                          class="item"
                          effect="dark"
                          placement="top"
                        >
                          <a
                            href="javascript:void(0)"
                            class="ml-2"
                            @click="handleFileListClck(data.item)"
                          >
                            <i class="fa fa-file"></i>
                          </a>
                        </el-tooltip>
                        <!-- File List Action  (E) -->

                        <!-- Survey Action (S) -->
                        <span v-if="data.item.hasSurveyData">
                          <el-tooltip
                            :content="$t('src.modules.search-management.index.survey_form')"
                            class="item"
                            effect="dark"
                            placement="top"
                          >
                            <a
                              href="javascript:void(0)"
                              class="ml-2"
                              @click="handleSurveyClick(data.item)"
                            >
                              <i class="fa fa-list-alt"></i>
                            </a>
                          </el-tooltip>
                        </span>
                        <!-- Customer Support Action (S) -->
                        <span v-if="m_hasCustomerSupport(data.item)">
                          <el-tooltip
                            :content="$t('src.modules.search-management.index.customer_support')"
                            class="item"
                            effect="dark"
                            placement="top"
                          >
                            <a
                              href="javascript:void(0)"
                              class="ml-2"
                              @click="handleCustomerSupportClck(data.item)"
                            >
                              <i class="fa fa-history"></i>
                            </a>
                          </el-tooltip>
                        </span>
                        <!-- Customer Support Action (E) -->

                        <!-- Re-Support Action (S) -->
                        <span v-if="data.item.canReSupport">
                          <el-tooltip
                            :content="
                              $t(
                                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.re_support'
                              )
                            "
                            class="item"
                            effect="dark"
                            placement="top"
                          >
                            <a
                              href="javascript:void(0)"
                              class="ml-2"
                              @click="m_reSupportTicket(data.item)"
                            >
                              <i class="fa fa-phone-square"></i>
                            </a>
                          </el-tooltip>
                        </span>
                        <!-- Re-Support Action (E) -->
                      </template>
                      <!-- Action (E) -->
                    </b-table>

                    <!-- Pagging (S) -->
                    <b-row>
                      <b-col></b-col>
                      <b-col v-if="c_totalRows > 0" md="12" class="bt-paging">
                        <b-pagination
                          v-model="pageNumber"
                          :total-rows="c_totalRows"
                          :per-page="perPage"
                          class="my-0 pull-right"
                        />
                      </b-col>
                    </b-row>
                    <!-- Pagging (E) -->
                  </b-col>
                </b-row>
                <!-- Grid Result (E) -->
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
      <AppFooter />
    </main>

    <!-- Component dialog box -->
    <b-modal
      id="dialogBox"
      ref="dialogBox"
      v-model="showDialogBox"
      :title="titleDialogBox"
      modal-class="integrated-audio"
      size="lg"
      scrollable
      @shown="m_shownScrollToBottomDialogBox"
      @hidden="handleBtnCancelDialogBox"
    >
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.MESSAGE_LIST">
        <div
          v-if="messageData.length > 0"
          id="appCardChat"
          ref="appCardChat"
          class="message-list-card"
          @scroll="handleMessageListScroll"
        >
          <MessageList
            :messages="historyMessages"
            :loading="loadingMore"
            :conversation="selectedConversation"
          />
        </div>
      </div>
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.AGENT_INFO">
        <Profile :profiles="agentInfo" />
      </div>
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.USER_INFO">
        <Profile
          :profiles="userInfo"
          :external-user-info="externalUserInfo"
          :enable-external-user-info="enableExternalUserInfo"
          :external-no-data="externalNoData"
          :external-error="externalError"
        />
      </div>
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.SURVEY_LIST">
        <survey-table
          :is-search="true"
          :data="surveyDatas"
          :item-selected="itemSelected"
          :questions="questionAndAnswers"
          @onChangeSurveyForm="changeSurveyForm"
        />
      </div>
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.CUSTOMER_SUPPORT">
        <Profile :profiles="customerSupportInfo" />
      </div>
      <div v-if="typeDialogBox === TYPE_DIALOG_BOX.FILE_LIST">
        <File :files="fileList" />
      </div>

      <div slot="modal-footer" class="w-100">
        <b-btn class="float-right" variant="default" @click="handleBtnCancelDialogBox">{{
          $t('common.confirmModal.close')
        }}</b-btn>
      </div>
    </b-modal>

    <!-- Column Filter Modal -->
    <ColumnsFilterModal
      :filter-columns="optionalColumns"
      @finishFilterColumns="handleEmitFinishFilterColumns"
    />

    <!-- Replacing Message - Request Form -->
    <RequestForm />

    <support-ticket
      :show="showSupportTicket"
      :conversation="selectedConversation"
      :agent-groups="spAgentGroups"
      :version="
        selectedConversation && selectedConversation.ticket && selectedConversation.ticket.version
      "
      @onClose="handleCloseSupportTicket"
    />
    <confirm-modal
      v-model="showModelconfirm"
      :title="titleConfirmModal"
      :type="typeConfirmModal"
      :enable-request-user="c_enableRequestUser"
      :is-story-conversation="c_isStoryConversation"
      :channel-has-bot="channelHasBot"
      @support="handleSupportWithoutUserAgreement"
      @yes="handleYes"
      @no="handleNo"
      @hidden="handleNo"
    />

    <!-- check ReSupportWarning -->
    <ReSupportWarning />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import map from 'lodash/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import _ from 'lodash';
import concat from 'lodash/concat';
import {
  AGENT_ROLE,
  TICKET_STATE,
  TICKET_STATUS,
  MAPPING_ROLE,
  DISPLAY_LANGUAGE_NAME,
  STANDARDIZE_PLATFORM_NAME,
  FOLLOW_UP_TICKET,
  PERSONAL_OPTIONS,
  TYPE_DIALOG_BOX,
  MAPPING_LABEL_COLOR,
  LOG_REPORT_TYPE,
  RESULT_REPORT_TYPE,
  REPORT_SCHEDULE_TYPE
} from 'core/constants';
import Profile from './profile';
import ReSupportWarning from 'components/Modal/ReSupportWarning/index';
import BootstrapTooltipCustom from 'components/Tooltip/BootstrapTooltipCustom';
import File from './file';
import MessageList from '../customer-support-history/MessageList';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';
import ColumnsFilterModal from './components/column-filter';
import RequestForm from 'modules/replacing-message/components/RequestForm';
import * as download from 'downloadjs';
import excel from 'excel4node';
import { addMessageStatus } from '../chat/helpers';
import { EventBus } from 'core/eventBus';
import { language } from './store/listLanguage';
import SupportTicket from 'modules/chat/components/SupportTicket';
import ConfirmModal from 'modules/chat/components/AsideLeft/Project/Channel/confirm-modal';
import { cloneDeep, pick } from 'lodash';
import {
  canReSupport,
  isRequireAuth,
  convertTimeUTC,
  hasSpecialCharacters,
  getAgentName
} from 'core/helpers';
import SurveyTable from 'modules/chat/components/SurveyFormPanel/SurveyTable';

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    MessageList,
    Profile,
    File,
    ColumnsFilterModal,
    RequestForm,
    SupportTicket,
    ConfirmModal,
    ReSupportWarning,
    BootstrapTooltipCustom,
    SurveyTable
  },

  data() {
    return {
      isInstagram: false,
      showSupportTicket: false,
      showModelconfirm: false,
      titleConfirmModal: '',
      typeConfirmModal: '',
      c_enableRequestUser: false,
      c_isStoryConversation: false,
      spAgentGroups: [],
      // Table
      dataHeader: {
        index: {
          label: this.$t('src.modules.search-management.index.no'),
          class: 'nat-table-col-no nat-cell-text text-right'
        },
        id: {
          label: this.$t('src.modules.search-management.index.conversation_id'),
          sortable: false,
          class: 'nat-table-col-conversation-id nat-cell-text text-left'
        },
        createdAt: {
          label: this.$t('src.modules.search-management.index.created_at'),
          sortable: true,
          class: 'nat-table-col-created-at nat-cell-text text-center'
        },
        state: {
          label: this.$t('common.text.phase'),
          sortable: true,
          class: 'nat-table-col-state nat-cell-text text-left'
        },
        status: {
          label: this.$t('src.modules.search-management.index.status'),
          sortable: true,
          class: 'nat-table-col-status nat-cell-text text-left'
        },
        userId: {
          label: this.$t('src.modules.search-management.index.user_id'),
          class: 'nat-table-col-user-id nat-cell-text text-left'
        },
        assignee: {
          label: this.$t('src.modules.search-management.index.agent_id'),
          class: 'nat-table-col-agent-id nat-cell-text text-left'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action nat-cell-text text-center'
        }
      },
      totalDataHeader: {
        index: {
          label: this.$t('src.modules.search-management.index.no'),
          class: 'nat-table-col-no nat-cell-text text-right'
        },
        id: {
          label: this.$t('src.modules.search-management.index.conversation_id'),
          sortable: false,
          class: 'nat-table-col-conversation-id nat-cell-text text-left'
        },
        createdAt: {
          label: this.$t('src.modules.search-management.index.created_at'),
          sortable: true,
          class: 'nat-table-col-created-at nat-cell-text text-center'
        },
        projectName: {
          label: this.$t('src.modules.search-management.index.project'),
          sortable: true,
          class: 'nat-table-col-project nat-cell-text text-left'
        },
        platform: {
          label: this.$t('src.modules.search-management.index.platform'),
          sortable: true,
          class: 'nat-table-col-platform nat-cell-text text-left'
        },
        channel: {
          label: this.$t('src.modules.customer-support-history.index.channel'),
          sortable: true,
          class: 'nat-table-col-channel nat-cell-text text-left'
        },
        groupName: {
          label: this.$t('src.modules.search-management.index.group'),
          sortable: true,
          class: 'nat-table-col-group nat-cell-text text-left'
        },
        state: {
          label: this.$t('src.modules.search-management.index.state'),
          sortable: true,
          class: 'nat-table-col-state nat-cell-text text-left'
        },
        status: {
          label: this.$t('src.modules.search-management.index.status'),
          sortable: true,
          class: 'nat-table-col-status nat-cell-text text-left'
        },
        userId: {
          label: this.$t('src.modules.search-management.index.user_id'),
          sortable: true,
          class: 'nat-table-col-user-id nat-cell-text text-left'
        },
        userName: {
          label: this.$t('src.modules.search-management.index.user_name'),
          sortable: true,
          class: 'nat-table-col-user-name nat-cell-text text-left'
        },
        userLanguage: {
          label: this.$t('src.modules.search-management.index.user_language'),
          class: 'nat-table-col-user-user-language nat-cell-text text-left'
        },
        assignee: {
          label: this.$t('src.modules.search-management.index.agent_id'),
          class: 'nat-table-col-agent-id nat-cell-text text-left'
        },
        agentName: {
          label: this.$t('src.modules.search-management.index.agent_name'),
          sortable: true,
          class: 'nat-table-col-agent-name nat-cell-text text-left'
        },
        agentEmail: {
          label: this.$t('src.modules.search-management.index.login_id'),
          sortable: true,
          class: 'nat-table-col-login-id nat-cell-text text-left'
        },
        feature: {
          label: this.$t('src.modules.search-management.index.follow_up'),
          class: 'nat-table-col-follow-up nat-cell-text text-center'
        },
        tags: {
          label: this.$t('src.modules.search-management.index.label.conversation_labels'),
          class: 'nat-table-col-labels nat-cell-text text-left'
        },
        labels: {
          label: this.$t('src.modules.search-management.index.user_labels'),
          class: 'nat-table-col-user-labels nat-cell-text text-left'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action nat-cell-text text-center'
        }
      },

      optionalColumns: [
        {
          key: 'projectName',
          selected: false,
          label: this.$t('src.modules.search-management.index.project')
        },
        {
          key: 'platform',
          selected: false,
          label: this.$t('src.modules.search-management.index.platform')
        },
        {
          key: 'channel',
          selected: false,
          label: this.$t('src.modules.customer-support-history.index.channel')
        },
        {
          key: 'groupName',
          selected: false,
          label: this.$t('src.modules.search-management.index.group')
        },
        {
          key: 'userName',
          selected: false,
          label: this.$t('src.modules.search-management.index.user_name')
        },
        {
          key: 'userLanguage',
          selected: false,
          label: this.$t('src.modules.search-management.index.user_language')
        },
        {
          key: 'agentName',
          selected: false,
          label: this.$t('src.modules.search-management.index.agent_name')
        },
        {
          key: 'agentEmail',
          selected: false,
          label: this.$t('src.modules.search-management.index.login_id')
        },
        {
          key: 'feature',
          selected: false,
          label: this.$t('src.modules.search-management.index.follow_up')
        },
        {
          key: 'tags',
          selected: false,
          label: this.$t('src.modules.search-management.index.label.conversation_labels')
        },
        {
          key: 'labels',
          selected: false,
          label: this.$t('src.modules.search-management.index.user_labels')
        }
      ],
      mandatoryColumns: [
        {
          key: 'index',
          selected: false,
          label: this.$t('src.modules.search-management.index.no')
        },
        {
          key: 'id',
          selected: false,
          label: this.$t('src.modules.search-management.index.conversation_id')
        },
        {
          key: 'createdAt',
          selected: false,
          label: this.$t('src.modules.search-management.index.created_at')
        },
        {
          key: 'state',
          selected: false,
          label: this.$t('src.modules.search-management.index.state')
        },
        {
          key: 'status',
          selected: false,
          label: this.$t('src.modules.search-management.index.status')
        },
        {
          key: 'userId',
          selected: false,
          label: this.$t('src.modules.search-management.index.user_id')
        },
        {
          key: 'assignee',
          selected: false,
          label: this.$t('src.modules.search-management.index.agent_id')
        },
        {
          key: 'action',
          selected: false,
          label: this.$t('common.text.action')
        }
      ],
      isBusy: false,
      sortBy: 'updatedAt',
      sortDesc: true,
      pageNumber: 1,
      perPage: 10,
      dataSearch: [],
      mssSearchCdn: 'or',

      // Poppup detail
      showSupportModal: false,

      // Project Combo
      projectSel: '',

      // Channel Combo
      channelSel: '',
      channelsFilter: [],
      channelsFilterMap: {},
      // Platform Combo
      platformSel: '',
      // Group Combo
      groupSel: '',
      agentGroups: [],

      // State Combo
      stateList: [
        ...Object.keys(TICKET_STATE)
          .filter(state => 'BOT' != state)
          .map(state => {
            return {
              value: state,
              text: TICKET_STATE[state]
            };
          })
      ],
      stateValue: '',
      // Status Combo
      statusList: [],
      statusValue: '',
      // ticketId
      ticketId: '',
      // User
      userId: '',
      userName: '',
      userLanguage: '',
      languageList: language,
      // Agent
      agentId: '',
      agentName: '',
      idLogin: '',
      displayLanguageName: DISPLAY_LANGUAGE_NAME || {},
      // Message
      messageContent: '',

      // Message detail
      selectedConversation: null,
      messageData: [],
      loadingMore: false,
      itemSelectedLoadMessages: {},
      warningWords: false,
      storyType: [],
      storyTypes: [
        {
          text: this.$t('src.modules.search-management.index.instagram_story_mention'),
          value: 'story_mention'
        },
        {
          text: this.$t('src.modules.search-management.index.instagram_story_reply'),
          value: 'story_reply'
        }
      ],
      label: [],
      agentInfo: [],
      userInfo: [],
      externalUserInfo: [],
      enableExternalUserInfo: false,
      externalNoData: false,
      externalError: false,
      customerSupportInfo: [],

      // Dialog Box
      showDialogBox: false,
      titleDialogBox: '',
      typeDialogBox: '',
      inputValueMessage: '',
      showInputValueMessage: false,
      maxlengthMessage: 25,
      filterMessageValue: [],
      tagsAndSelect: {},

      // User label
      userLabels: [],
      // Conversation label
      conversationLabels: [],

      // Search Data
      isloadEnd: true,
      countTableAll: 0,
      loading: false,
      channelHasBot: false,
      selectedFollowUp: 'all', // default search all
      optionsFollowUp: [
        { value: 'all', text: this.$t('src.modules.search-management.index.all.conversation') },
        { value: 'follow-up', text: this.$t('src.modules.search-management.index.follow_up') },
        { value: 'non-follow-up', text: this.$t('src.modules.search-management.index.unfollow_up') }
      ],
      countries: {},
      itemSelected: {},
      surveyDatas: [],
      questionAndAnswers: [],
      TYPE_DIALOG_BOX
    };
  },

  computed: {
    ...mapState('searchManagement', [
      'channels',
      'platforms',
      'groups',
      'ticketsFilterMap',
      'usersFilterMap',
      'customerFilterMap',
      'filesTicketMap',
      'allTicket',
      'channelScrollIds',
      'isLoadMoreSearchTickets',
      'countAll'
    ]),
    ...mapState('chat', ['messagesOfTicketId']),
    ...mapState('session', [
      'user',
      'people',
      'channelsMap',
      'projectMaps',
      'projectTagMaps',
      'projectLabelMaps',
      'agentWorkAtHome'
    ]),
    ...mapGetters('session', ['groupMaps', 'meGroups']),
    ...mapGetters('chat', ['userLabelsByProject', 'convsLabelsByProject']),
    ...mapState('global', ['ready']),

    isRequireAuth() {
      return isRequireAuth();
    },

    c_totalRows() {
      return this.dataSearch.length || 0;
    },

    c_paginatedData() {
      return (this.dataSearch.length > 0 && this.dataSearch) || [];
    },

    projectList() {
      if (!this.projectMaps || typeof this.projectMaps !== 'object') return [];

      let list = Object.keys(this.projectMaps).map(key => ({
        value: key,
        text: this.projectMaps[key].name
      }));

      return this.m_sortProperties(list);
    },

    platformList() {
      let list = [];
      if (this.projectSel === '') return [];

      const channels = _.filter(this.channelsMap, {
        projectId: this.projectSel
      }).filter(
        ({ platform, configs }) => !(platform === 'instagram' && !configs.instagramMessenger)
      );
      if (channels.length < 1) return [];

      list = _.reduce(
        channels,
        (lst, c) =>
          lst.map(i => i.value).includes(c.platform)
            ? lst
            : [...lst, { value: c.platform, text: c.platform }],
        list
      );

      // Standardize platform name
      list = list.map(i => ({
        ...i,
        text: STANDARDIZE_PLATFORM_NAME[i.text.toLowerCase()] || i.text
      }));

      return [...this.m_sortProperties(list)];
    },

    channelList() {
      let list = [];
      if (this.projectSel === '' || this.platformSel === '') return [];

      const channels = _.filter(this.channelsMap, {
        projectId: this.projectSel,
        platform: this.platformSel
      });
      if (channels.length < 1) return [];

      list = _.reduce(
        channels,
        (res, channel) => [...res, { value: channel.id, text: channel.name }],
        list
      );
      return [...this.m_sortProperties(list)];
    },

    groupList() {
      let list = [];
      if (this.projectSel === '') return [];
      const groups = this.groupMaps[this.projectSel] || [];
      if (groups.length < 1) return [];

      // Filter role
      let groupsRole = [...groups];
      if (!this.isAllowAllView) {
        if ([AGENT_ROLE.REGULAR, AGENT_ROLE.MODERATOR].includes(this.user.role)) {
          groupsRole = groups.filter(item => {
            const agentGroupsRole =
              this.user.role === AGENT_ROLE.REGULAR ? item.agents || [] : item.moderators || [];
            const exits = agentGroupsRole.find(element => element === this.user.id);
            if (exits !== undefined) {
              return item;
            }
          });
        }
      }

      list = _.reduce(
        groupsRole,
        (res, group) => [...res, { value: group.id, text: group.name, isEnable: group.isEnable }],
        list
      );
      return [...[], ...this.m_sortProperties(list)];
    },

    c_projectLable() {
      const pjtLbl =
        (this.projectLabelMaps &&
          this.projectLabelMaps[this.projectSel] &&
          this.projectLabelMaps[this.projectSel].labels) ||
        [];

      const listUserLabels = pjtLbl.map(item => ({
        value: item.id || '',
        text: item.value || ''
      }));
      return this.m_sortProperties(listUserLabels);
    },

    c_projectCnvLable() {
      const pjtLbl =
        (this.projectTagMaps &&
          this.projectTagMaps[this.projectSel] &&
          this.projectTagMaps[this.projectSel].tags) ||
        [];

      const listConvLabels = pjtLbl.map(item => ({
        value: item.id || '',
        text: item.value || ''
      }));
      return this.m_sortProperties(listConvLabels);
    },

    c_projectLables() {
      return this.userLabelsByProject.map(l => ({
        value: l.id,
        text: l.sourceType === 'system' ? this.userLabelSysValue(l.value) : l.value
      }));
    },

    c_projectCnvLables() {
      return this.convsLabelsByProject.map(l => ({
        value: l.id,
        text: l.sourceType === 'system' ? this.convLabelSysValue(l.value) : l.value
      }));
    },

    c_languageList() {
      const listLanguages = Object.keys(this.languageList).map(key => ({
        value: key,
        text: this.languageList[key]
      }));
      return this.m_sortProperties(listLanguages);
    },

    c_cntMoreData() {
      return Number(this.countTableAll) - Number(this.dataSearch.length) || 0;
    },

    historyMessages() {
      const messages = [...this.messageData];
      addMessageStatus(messages, this.selectedConversation);
      return messages;
    },
    c_isShowAgentPanel() {
      if (
        !this.isAllowAllView &&
        (this.user.role === AGENT_ROLE.REGULAR ||
          (this.user.role === AGENT_ROLE.MODERATOR && this.groupList.length === 0))
      ) {
        return false;
      }
      return true;
    },
    displayLanguage() {
      const lang = localStorage.getItem('language');
      const operationLanguage = lang.replace('-', '_');
      return operationLanguage;
    },
    isAllowAllView() {
      if (this.projectSel === '') {
        return true;
      }
      const project = this.projectMaps[this.projectSel];
      return project && project.config?.accessPermission
        ? project.config.accessPermission.isAllowAllView
        : true;
    },
    isClientAgent() {
      return this.user.role === AGENT_ROLE.CLIENT;
    }
  },

  watch: {
    countAll(value) {
      this.countTableAll = value;
    },
    channelsFilter() {
      this.channelsFilterMap = this.channelsFilter.reduce(
        (cMap, c) => ({
          ...cMap,
          [c.id]: c
        }),
        {}
      );
    },

    messagesOfTicketId(newvalue) {
      const { id: ticketId, conversationId } = this.itemSelectedLoadMessages;

      this.messageData =
        newvalue[conversationId] && newvalue[conversationId][ticketId]
          ? newvalue[conversationId][ticketId]
          : [];
    },

    platformSel() {
      this.channelSel = '';
    },

    projectList(data) {
      if (data && data.length == 0) {
        this.projectSel = '';
      } else {
        if (this.projectSel === '') {
          this.projectSel = this.projectList[0].value;
        }
      }
    },
    platformList(data) {
      if (data && data.length == 0) {
        this.platformSel = '';
      }
    },
    channelList(data) {
      if (data && data.length == 0) {
        this.channelSel = '';
      }
    },
    groupList(data) {
      if (data && data.length == 0) {
        this.groupSel = '';
      }
    },

    projectSel(val) {
      this.templateSel = '';
      this.platformSel = '';
      this.channelSel = '';
      this.groupSel = '';
      this.isInstagram = false;
      this.storyType = [];
      this.m_resetGrid();
      this.getConvLabelsByProjectId({
        projectIds: [val || ''],
        entity: 'tickets',
        sourceType: ['agent', 'system', 'bot']
      });
      this.getLabelsByProjectId({
        projectIds: [val || ''],
        entity: 'personals',
        sourceType: ['agent', 'system', 'admin', 'bot']
      });
    },

    groupSel() {},

    allTicket(value) {
      this.dataSearch = map(
        uniq(
          map(value, obj => {
            if (!obj.hasOwnProperty('platform')) {
              const channel = this.channelsMap[obj.channelId] || null;
              obj.channel = channel && channel.name ? channel.name : '';
              obj.platform =
                channel && channel.platform
                  ? STANDARDIZE_PLATFORM_NAME[channel.platform.toLowerCase()] || channel.platform
                  : '';
              obj.projectId = channel && channel.projectId ? channel.projectId : '';
              obj.projectName =
                obj.projectId && this.projectMaps[obj.projectId]
                  ? this.projectMaps[obj.projectId].name
                  : '';
              let groupsOfProject = [];
              groupsOfProject = this.groupList || [];
              let group = Array.isArray(groupsOfProject)
                ? groupsOfProject.filter(group => group.value == obj.groupId)
                : [];
              group = group.length > 0 ? group[0] : null;
              obj.groupName = group ? group : null;
              obj.userLanguage = '';
              obj.userName = '';
              if (obj.userData) {
                obj.userLanguage = obj.userData.locale
                  ? this.displayLanguageName[obj.userData.locale] || obj.userData.locale || ''
                  : '';
                obj.userName = obj.userData.name;
              }
              obj.agentEmail = '';
              if (obj.agentData) {
                obj.agentEmail = obj.agentData.email || '';
              }
            }
            let obj1 = { ...obj };
            if (obj.hasOwnProperty('labels')) {
              obj1.labels = compact(obj.labels);
            }
            if (obj.hasOwnProperty('tags')) {
              obj1.tags = compact(obj.tags);
            }
            return JSON.stringify(obj1);
          })
        ),
        obj => {
          const ticket = JSON.parse(obj);
          ticket.canReSupport = !this.isClientAgent ? canReSupport(ticket) : false;

          if (ticket.canReSupport && this.isAllowAllView) {
            if (
              this.user.role === AGENT_ROLE.REGULAR ||
              (this.user.role === AGENT_ROLE.MODERATOR && this.groupList.length === 0)
            ) {
              if (this.m_agentHandle(ticket) === 'AGENT') {
                ticket.canReSupport = ticket.assignee === this.user.id;
              }
            } else if (this.user.role === AGENT_ROLE.MODERATOR && this.groupList.length > 0) {
              if (ticket.groupId) {
                ticket.canReSupport = this.meGroups.includes(ticket.groupId);
              }
            }
          }
          return ticket;
        }
      );
    },

    projectMaps(obj) {
      this.loadTagByProjects(Object.keys(obj));
      this.loadLabelByProjects(Object.keys(obj));
    },

    c_cntMoreData(value) {
      if (value === 0) {
        this.isloadEnd = true;
      } else {
        this.isloadEnd = false;
      }
    }
  },

  async created() {
    const user = await this.getSessionUser();
    if (!user) {
      return;
    }
    this.channelsFilter = await this.getChannels();
    if (user.role === AGENT_ROLE.REGULAR || user.role === AGENT_ROLE.MODERATOR) {
      this.agentGroups = await this.m_getAgentGroups(user.id);
    }
    // reset history grid
    this.m_resetGrid();
  },

  async mounted() {
    /**[{code: "AF", name: "Afghanistan"}, {}, ...] */
    const countryList = await this.getCountries({ language: this.displayLanguage });
    this.countries = countryList.reduce((obj, { code, name }) => {
      obj[code] = name;
      return obj;
    }, {});
  },

  methods: {
    ...mapActions('session', [
      'getSessionUser',
      'logout',
      'getChannels',
      'addAgentToPeople',
      'loadTagByProjects',
      'loadLabelByProjects',
      'getGroupsOpts',
      'allowReSupport'
    ]),
    ...mapActions('global', ['setGlobalReady', 'pinRightPanel', 'setGlobalLoadingMap']),
    ...mapActions('searchManagement', [
      'getAgentGroupsByAgentId',
      'getTicketsBySearch',
      'getLoadMoreTicketByScrollId',
      'getConversationTicketById',
      'getPersonalInfo',
      'getUserInfo',
      'getCustomerSupportByTicketId',
      'getFileListTicket',
      'getExternalUserInfo',
      'getActionSearchClick'
    ]),
    ...mapActions('chat', [
      'getMessagesByTicketId',
      'loadMoreMessage',
      'loadMoreMessageOfTicketId',
      'setSupportWithoutUserAgreement',
      'supportTicket',
      'getSurveyDataAndTeamplateByTicket',
      'getLabelsByProjectId',
      'getConvLabelsByProjectId'
    ]),
    ...mapActions('customerSupport', ['getCountries', 'getProvinces']),
    ...mapActions('report', ['createLogForExport']),

    userLabelSysValue(value) {
      if (value === 'virus-sender') {
        return this.$t('src.modules.chat.components.ChatBox.chat-header.virus_sender');
      }
      return value;
    },

    convLabelSysValue(value) {
      if (value === 'virus') {
        return this.$t('src.modules.chat.components.ChatBox.chat-header.virus');
      }
      return value;
    },

    /*********************************************************************
     * Handle All Action in UI
     *********************************************************************/
    async handleBtnDownLoadExcelFile() {
      const { id, email, company = {}, country = {}, operator = {}, region = {} } = this.user || {};
      const actionLogCreate = {
        userId: id,
        userRole: 'Agent',
        userName: getAgentName(this.user),
        userEmail: email,
        userOperation: operator.id,
        userRegion: region.id,
        userCountry: country.id,
        userCompany: company.id,
        projectId: this.projectSel,
        type: REPORT_SCHEDULE_TYPE.IMMEDIATE,
        exportFormat: 'excel',
        platform: this.platformSel,
        reportType: LOG_REPORT_TYPE.CONVERSATION_LIST,
        system: 'at'
      };

      if (this.channelSel) {
        const channel = this.channelList.find(val => val.value === this.channelSel);
        actionLogCreate['channelId'] = this.channelSel;
        actionLogCreate['channelName'] = channel && channel.text;
      }

      if (this.dataSearch.length > 0) {
        try {
          const excelData = this.m_convertToExcelContructedData(this.dataSearch);
          const file = await this.m_exportExcelFileFromResultData(excelData);
          const fileName = 'searching_result.xlsx';
          const Blob = require('blob');
          const blob = new Blob([file], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
          });
          download(blob, fileName);
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.SUCCESS
          });
          return;
        } catch (err) {
          this.createLogForExport({
            ...actionLogCreate,
            exportResult: RESULT_REPORT_TYPE.ERROR,
            exportError: (err && err.message) || 'Export failed'
          });
        }
      } else {
        this.$baseNotification.info({
          title: this.$t('src.core.App.info'),
          message: this.$t('src.modules.report.components.index.no_data_to_export')
        });
        this.createLogForExport({
          ...actionLogCreate,
          exportResult: RESULT_REPORT_TYPE.ERROR,
          exportError: 'No data'
        });
      }
    },

    handleEmitFinishFilterColumns(multipleSelection) {
      const selectedColumns = [];
      this.optionalColumns = multipleSelection;
      this.optionalColumns.forEach(column => {
        if (column.selected) {
          selectedColumns.push(column);
        }
      });
      const filterColumns = [...selectedColumns, ...this.mandatoryColumns];
      const newDataHeader = {};
      for (const column in this.totalDataHeader) {
        let tempArr = filterColumns;
        if (tempArr.filter(tmpColumn => tmpColumn.key == column).length > 0) {
          newDataHeader[column] = this.totalDataHeader[column];
        }
      }
      this.dataHeader = newDataHeader;
    },

    handleBtnAddOptionalColumns() {
      EventBus.$emit('filterColumnsModal', true);
    },

    onChangePlatform(value = '') {
      this.isInstagram = value === STANDARDIZE_PLATFORM_NAME.instagram.toLowerCase();
      if (!this.isInstagram) {
        this.storyType = [];
      }
    },

    handleSlctStateChng(value) {
      let filteredStatus = [];
      switch (value) {
        case TICKET_STATE.REFER:
          filteredStatus = [TICKET_STATUS.USER_MENTION];
          break;
        case 'REQUEST':
          filteredStatus = [
            TICKET_STATUS.BOT_HANDLE,
            TICKET_STATUS.REQUEST_USER,
            TICKET_STATUS.REQUEST_AGENT,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.WAITING_TIMEOUT
          ];
          break;
        case 'START':
          filteredStatus = [
            TICKET_STATUS.AGENT_START,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.RESPONSE_TIMEOUT,
            TICKET_STATUS.AGENT_IDLE
          ];
          break;
        case 'SUPPORTING':
          filteredStatus = [
            TICKET_STATUS.AGENT_SUPPORTING,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.HOLDING,
            TICKET_STATUS.USER_IDLE,
            TICKET_STATUS.AGENT_IDLE,
            TICKET_STATUS.RESPONSE_TIMEOUT
          ];
          break;
        case 'FINISH':
          filteredStatus = [
            TICKET_STATUS.AGENT_FINISH,
            TICKET_STATUS.USER_FINISH,
            TICKET_STATUS.MAINTENANCE_FINISH,
            TICKET_STATUS.FINISH_TIMEOUT
          ];
          break;
        case 'COMPLETE':
          filteredStatus = [
            TICKET_STATUS.BOT_COMPLETE,
            TICKET_STATUS.BOT_TIMEOUT,
            TICKET_STATUS.COMPLETE,
            TICKET_STATUS.WAITING_TIMEOUT,
            TICKET_STATUS.RESPONSE_TIMEOUT,
            TICKET_STATUS.FINISH_TIMEOUT,
            TICKET_STATUS.NO_SUPPORT,
            TICKET_STATUS.SYSTEM_TERMINATE,
            TICKET_STATUS.SYSTEM_MAINTENANCE,
            TICKET_STATUS.BOT_TERMINATE,
            TICKET_STATUS.USER_MENTION,
            TICKET_STATUS.HOLIDAY,
            TICKET_STATUS.NON_WORKING,
            TICKET_STATUS.NO_ONLINE_AGENT
          ];
          break;
      }
      this.statusList = [
        ...filteredStatus.map(key => {
          return {
            value: key,
            text: TICKET_STATUS[key]
          };
        })
      ];
      this.statusValue = '';
    },

    async handleBtnSearch() {
      this.loading = true;
      this.$refs.searchManagementCondition && this.$refs.searchManagementCondition.validate();
      try {
        const params = { conditions: {} };
        if (!this.projectSel) {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t(
              'src.modules.profile.components.user-information.please_check_the_invalid_data'
            )
          });
          this.loading = false;
          return false;
        } else params.conditions.projectId = this.projectSel;
        if (this.channelSel) {
          // select channel
          params.channelIds = [this.channelSel];
        } else {
          // not select channel
          if (this.platformSel) {
            params.conditions.platforms = [this.platformSel];
            params.channelIds = Object.keys(this.channelsMap).filter(
              c =>
                this.channelsMap[c].projectId === this.projectSel &&
                this.channelsMap[c].platform === this.platformSel
            );
          } else {
            params.channelIds = Object.keys(this.channelsMap).filter(
              c => this.channelsMap[c].projectId === this.projectSel
            );
          }
        }

        //Filter Agent is [N/A]
        if (this.agentId.trim() === 'N/A' || this.agentId.trim() === '[N/A]') {
          const noBotChannelIds = [...params.channelIds].filter(item => {
            const { hasBot = true } = this.channelsFilterMap[item] || {};
            if (!hasBot) return item;
          });
          params.channelIds = noBotChannelIds;
        }

        // //Filter Agent is [BOT]
        if (this.agentId.trim() === 'BOT' || this.agentId.trim() === '[BOT]') {
          const botChannelIds = [...params.channelIds].filter(item => {
            const { hasBot = true } = this.channelsFilterMap[item] || {};
            if (hasBot) return item;
          });
          params.channelIds = botChannelIds;
        }

        if (this.groupSel.trim()) {
          params.conditions.groupId = [this.groupSel];
        } else {
          if (this.groupList.length > 0) {
            params.conditions.groupId = ['[ALL_GROUP]'];
            params.conditions.groupId = [
              ...params.conditions.groupId,
              ...this.groupList.map(item => item.value)
            ];
          }
        }
        if (this.stateValue.trim()) params.conditions.state = [this.stateValue];
        if (this.statusValue.trim()) params.conditions.status = [this.statusValue];
        if (this.ticketId.trim()) params.conditions.ticketId = [this.ticketId];
        if (this.userId.trim()) params.conditions.userId = [this.userId];
        if (this.userName.trim()) params.conditions.userName = [this.userName];
        if (this.userLanguage.trim()) params.conditions.userLocal = [this.userLanguage];
        if (this.agentId.trim()) {
          params.conditions.agentId = [this.agentId];
        } else {
          if (!this.isAllowAllView) {
            if (
              this.user.role === AGENT_ROLE.REGULAR ||
              (this.user.role === AGENT_ROLE.MODERATOR && this.groupList.length === 0)
            ) {
              params.conditions.agentId = [this.user.id, 'BOT', 'N/A', TICKET_STATUS.FORWARD_GROUP];
            }
          }
        }
        if (this.agentName.trim()) params.conditions.agentName = [this.agentName];
        if (this.idLogin.trim()) params.conditions.idLogin = [this.idLogin];
        if (this.conversationLabels.length > 0) params.conditions.labels = this.conversationLabels;
        if (this.userLabels.length > 0) params.conditions.userLabels = this.userLabels;
        if (this.selectedFollowUp === FOLLOW_UP_TICKET.FOLLOW_UP) {
          params.conditions.followUp = true;
        } else if (this.selectedFollowUp === FOLLOW_UP_TICKET.NON_FOLLOW_UP) {
          params.conditions.followUp = false;
        }
        if (this.warningWords) params.conditions.warningWords = this.warningWords;
        if (this.tagsAndSelect.tags && this.tagsAndSelect.tags.length > 0) {
          params.conditions.message = this.tagsAndSelect.tags;
          params.conditions.mssSearchCdn = this.tagsAndSelect.selected || 'or';
        }
        if (this.storyType) {
          params.conditions.storyType = this.storyType;
        }
        await this.getTicketsBySearch(params);
        this.countTableAll = this.countAll;
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: handleBtnSearch -> error', error);
        this.loading = false;
      }
      this.loading = false;
    },

    async handleBtnLoadMore() {
      this.setGlobalReady(false);
      await this.getLoadMoreTicketByScrollId({
        channelScrollIds: this.channelScrollIds
      });
      this.setGlobalReady(true);
    },

    async handleMessageHistoryClck(item) {
      this.setGlobalReady(false);
      try {
        await this.m_getSelectedConversation(item);
        const { userId, channelId } = item;
        await this.getActionSearchClick({ userId, channelId });
        const {
          ticket: { state }
        } = this.selectedConversation;
        const { projectId = '' } =
          (this.selectedConversation && this.selectedConversation.ticket) || {};

        this.itemSelectedLoadMessages = item;
        const { id: ticketId, conversationId: id } = item;
        await this.m_fetchMessagesByTicketId({ id, ticketId, state, projectId });

        let messages = [];
        if (
          this.messagesOfTicketId &&
          this.messagesOfTicketId[id] &&
          this.messagesOfTicketId[id][ticketId]
        )
          messages = this.messagesOfTicketId[id][ticketId];

        const messagesByTicketId = messages.filter(msg => msg.ticketId === ticketId);
        this.messageData = cloneDeep(messagesByTicketId);

        // Show Dialog Box
        this.showDialogBox = true;
        this.titleDialogBox = this.$t('common.text.message_history');
        this.typeDialogBox = TYPE_DIALOG_BOX.MESSAGE_LIST;
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        console.log('TCL: handleMessageHistoryClck -> error', error);
        this.setGlobalReady(true);
      }
    },

    async handleSurveyClick(item) {
      this.loading = true;
      try {
        this.titleDialogBox = this.$t('src.modules.search-management.index.survey_form');
        this.typeDialogBox = TYPE_DIALOG_BOX.SURVEY_LIST;

        const operationLanguage = localStorage.getItem('language');
        const language = operationLanguage.replace('-', '_');

        const data = await this.getSurveyDataAndTeamplateByTicket({
          ticketId: item.id,
          language
        });
        if (data && data.length > 0) {
          const list = data.map(val => {
            return {
              ...val,
              label: val.name,
              value: val.id
            };
          });
          this.surveyDatas = list;
          this.questionAndAnswers = list[0].questions;
          this.itemSelected = list[0];

          this.showDialogBox = true;
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        console.error('err', err); //eslint-disable-line
      }
    },

    changeSurveyForm(val = {}) {
      this.itemSelected = val;
      this.questionAndAnswers = val.questions;
    },

    async handleCustomerSupportClck(item) {
      this.setGlobalReady(false);
      try {
        const { id: ticketId } = item;
        const { userId, channelId } = item;
        await this.getActionSearchClick({ userId, channelId });
        let customerSupportRaw = this.customerFilterMap[ticketId];
        if (!customerSupportRaw) {
          const customerSupportRaw = await this.getCustomerSupportByTicketId({
            ticketId
          });
          if (
            Object.keys(customerSupportRaw).length !== 0 &&
            customerSupportRaw.constructor === Object
          ) {
            this.customerSupportInfo = await this.m_transformCustomerSupportInfo(
              customerSupportRaw
            );
            // Show Dialog Box
            this.showDialogBox = true;
            this.titleDialogBox = this.$t('src.modules.search-management.index.customer_support');
            this.typeDialogBox = TYPE_DIALOG_BOX.CUSTOMER_SUPPORT;
          } else {
            this.$baseNotification.error({
              title: this.$t('src.core.App.error'),
              message: this.$t(
                'src.modules.search-management.index.not_exist_customer_support_data'
              )
            });
          }
        }
        delete this.customerFilterMap[ticketId];
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: handleCustomerSupportClck -> item', item);
        this.setGlobalReady(true);
      }
    },

    async handleFileListClck(item) {
      this.setGlobalReady(false);
      try {
        const { userId, channelId } = item;
        await this.getActionSearchClick({ userId, channelId });
        if (this.filesTicketMap[item.id]) {
          this.fileList = this.filesTicketMap[item.id];
        } else {
          const params = {};
          params.channelId = item.channelId;
          params.ticketIds = [item.id];
          params.size = 20;
          const { tickets } = await this.getFileListTicket(params);
          this.fileList = tickets;
        }

        // Show Dialog Box
        this.showDialogBox = true;
        this.titleDialogBox = this.$t('src.modules.search-management.index.file_list');
        this.typeDialogBox = TYPE_DIALOG_BOX.FILE_LIST;
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: handleMessageHistoryClck -> item', item);
        this.setGlobalReady(true);
      }
    },

    async handleUserInfoClck(item) {
      this.setGlobalReady(false);
      try {
        const { userId, channelId, projectId, platform } = item;
        await this.getActionSearchClick({ userId, channelId });
        let userInfoRaw = this.usersFilterMap[userId];
        const channel = this.channelsMap[channelId];
        const {
          advancedConfigs: { displayAgent = {} }
        } = channel;
        if (!userInfoRaw) {
          const personalParams = { userId, channelId, projectId, platform: platform.toLowerCase() };
          const personalInfoRaw = await this.getPersonalInfo(personalParams);
          if (Object.keys(personalInfoRaw).length === 0) {
            const userParams = { userId, channelId };
            userInfoRaw = await this.getUserInfo(userParams);
            if (Object.keys(userInfoRaw).length > 0 && userInfoRaw.constructor === Object) {
              this.userInfo = this.m_transformUserInfo(userInfoRaw);
            } else {
              this.userInfo = [];
            }
          } else {
            this.userInfo = await this.m_transformPersonalInfo(personalInfoRaw, displayAgent);
          }
        } else {
          this.userInfo = await this.m_transformPersonalInfo(userInfoRaw, displayAgent);
        }
        const externalUserSystem =
          (channel &&
            channel.advancedConfigs &&
            channel.advancedConfigs.person &&
            channel.advancedConfigs.person.externalUserSystem) ||
          {};
        const { displayExternalUserSystem: enabled = {} } = externalUserSystem;
        if (enabled) {
          this.enableExternalUserInfo = true;
          const externalUserInfoRaw = await this.getExternalUserInfo({
            channel,
            userId
          });
          if (externalUserInfoRaw === 'error') {
            this.externalError = true;
          } else if (externalUserInfoRaw === 'data error') {
            this.externalNoData = true;
          } else if (externalUserInfoRaw.length > 0) {
            this.externalUserInfo = this.m_transformExternalUserInfo(externalUserInfoRaw);
          }
        } else {
          this.enableExternalUserInfo = false;
        }

        // Show Dialog Box
        this.showDialogBox = true;
        this.titleDialogBox = this.$t('src.modules.search-management.index.user_info');
        this.typeDialogBox = TYPE_DIALOG_BOX.USER_INFO;
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: handleUserInfoClck -> item', item);
        this.setGlobalReady(true);
      }
    },

    async handleAgentInfoClck(item) {
      this.setGlobalReady(false);
      try {
        const { assignee } = item;
        let agentInfoRaw = this.people[assignee];
        if (!agentInfoRaw || (agentInfoRaw && !agentInfoRaw.hasOwnProperty('company'))) {
          agentInfoRaw = await this.addAgentToPeople(assignee);
        }

        if (Object.keys(agentInfoRaw).length !== 0 && agentInfoRaw.constructor === Object) {
          this.agentInfo = this.m_transformAgentInfo(agentInfoRaw);
        } else {
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: this.$t('src.modules.search-management.index.not_exist_agent_data')
          });
          return;
        }

        // Show Dialog Box
        this.showDialogBox = true;
        this.titleDialogBox = this.$t('src.modules.search-management.index.agent_info');
        this.typeDialogBox = TYPE_DIALOG_BOX.AGENT_INFO;
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: handleAgentInfoClck -> item', item);
        this.setGlobalReady(true);
      }
    },

    async handleMessageListScroll(evt) {
      if (evt.target.id === 'appCardChat') {
        if (evt.target.scrollTop === 0) {
          const { id: ticketId, conversationId, projectId } = this.itemSelectedLoadMessages;
          const messageOfTicket =
            this.messagesOfTicketId[conversationId] &&
            this.messagesOfTicketId[conversationId][ticketId]
              ? this.messagesOfTicketId[conversationId][ticketId]
              : [];
          if (messageOfTicket === 0) return;
          const [msg] = messageOfTicket;
          await this.m_loadMoreMessage({
            ticketId,
            conversationId,
            projectId,
            nextKey: pick(msg, ['sk', 'conversationId'])
          });
        }
      }
    },

    handleBtnCancelDialogBox() {
      this.resetExternalInfoData();
      this.showDialogBox = false;
      this.getActionSearchClick({});
    },

    resetExternalInfoData() {
      this.externalUserInfo.length = 0;
      this.enableExternalUserInfo = false;
      this.externalNoData = false;
      this.externalError = false;
    },

    handleInputMessageConfirm() {
      if (this.inputValueMessage.length > this.maxlengthMessage) return;
      let inputValueMessage = this.inputValueMessage;
      if (inputValueMessage.trim()) {
        if (this.filterMessageValue.indexOf(inputValueMessage) < 0) {
          this.filterMessageValue.push(inputValueMessage);
        }
      }
      this.showInputValueMessage = false;
      this.inputValueMessage = '';
    },

    handleBtnCloseMessage(message) {
      this.filterMessageValue = this.filterMessageValue.filter(item => {
        return item !== message;
      });
    },

    /*********************************************************************
     * All Common function in UI
     *********************************************************************/
    m_convertToExcelContructedData(dataSearch) {
      const excelContructedDataArr = [];
      const sheetData = {};
      const sheetName = 'SEARCHING RESULT';
      const title = [];
      for (const prop in this.dataHeader) {
        if (prop != 'action' && prop != 'index') {
          title.push(this.dataHeader[prop].label);
        }
      }
      const sheetDataArr = [];
      dataSearch.forEach(data => {
        const itemData = [];
        for (const prop in this.dataHeader) {
          if (prop != 'action' && prop != 'index') {
            const value = data[prop] ? data[prop] : '';
            if (prop == 'tags') {
              itemData.push(this.m_renderLabelString(data[prop]));
            } else if (prop == 'labels') {
              itemData.push(this.m_renderUserLabelString(data[prop]));
            } else if (prop == 'createdAt') {
              let createdAt = convertTimeUTC(data[prop], 'export');
              createdAt = createdAt ? createdAt : '';
              itemData.push(createdAt);
            } else {
              itemData.push(value);
            }
          }
        }
        sheetDataArr.push(itemData);
      });
      sheetData.sheetName = sheetName;
      sheetData.title = title;
      sheetData.data = sheetDataArr;
      excelContructedDataArr.push(sheetData);
      return excelContructedDataArr;
    },

    async m_exportExcelFileFromResultData(excelData) {
      const workbook = new excel.Workbook();
      excelData.forEach(sheet => {
        const ws = workbook.addWorksheet(sheet.sheetName);
        let excelRowIndex = 1;
        for (let indexTitle = 0; indexTitle < sheet.title.length; indexTitle++) {
          ws.cell(excelRowIndex, indexTitle + 1).string(sheet.title[indexTitle]);
        }
        excelRowIndex += 1;
        for (let indexData = 0; indexData < sheet.data.length; indexData++) {
          let rowData = sheet.data[indexData];
          for (let indexRowData = 0; indexRowData < rowData.length; indexRowData++) {
            let data = rowData[indexRowData].toString();
            ws.cell(excelRowIndex + indexData, indexRowData + 1).string(data);
          }
        }
      });
      return workbook.writeToBuffer().then(function (buffer) {
        return buffer;
      });
    },

    m_renderLabelString(labels = []) {
      if (!labels || typeof labels === 'string') return '';
      let label_string = '';
      if (labels.length == 1) label_string = labels[0].value;
      if (labels.length > 1) {
        label_string = labels[0].value;
        for (let i = 1; i < labels.length; i++) {
          label_string += ', ' + labels[i].value;
        }
      }
      return label_string.trim();
    },

    m_renderUserLabelString(labels = []) {
      if (!labels || typeof labels === 'string') return '';
      let label_string = '';
      if (labels.length == 1) label_string = labels[0].value;
      if (labels.length > 1) {
        label_string = labels[0].value;
        for (let i = 1; i < labels.length; i++) {
          label_string += ', ' + labels[i].value;
        }
      }
      return label_string.trim();
    },

    m_formatTime(value, formatString = 'DD MMM YYYY LT') {
      if (value) {
        return formatString === 'YYYY/MM/DD'
          ? moment(value).format(formatString)
          : convertTimeUTC(value);
      }
      return null;
    },

    m_sortProperties(arrValue) {
      if (!arrValue || arrValue === null) return [];
      // sort items by value
      arrValue.sort(function (a, b) {
        let x = a.text.toLowerCase(),
          y = b.text.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return arrValue || []; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    },

    async m_getAgentGroups(agentId) {
      let groups = [];
      if (!agentId) {
        return groups;
      }
      await this.getAgentGroupsByAgentId({
        agentId: agentId
      })
        .then(conv => {
          const { error } = conv;
          if (error) {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: error.message
            });
          } else {
            for (let o in conv) {
              groups.push(conv[o].groupId);
            }
          }
          return true;
        })
        .catch(() => {
          this.$baseNotification.warning({
            title: this.$t('src.core.App.warning'),
            message: this.$t('src.modules.search-management.index.internal_error')
          });
        });
      return groups;
    },

    m_transformAgentInfo(rawData) {
      const newData = [];
      newData.push({
        id: 'lastName',
        label: this.$t('src.modules.search-management.index.last_name'),
        valueString: rawData.lastName || ''
      });
      newData.push({
        id: 'firstName',
        label: this.$t('src.modules.search-management.index.first_name'),
        valueString: rawData.firstName || ''
      });
      newData.push({
        id: 'operator',
        label: this.$t('src.modules.search-management.index.operation'),
        valueString: (rawData.operator && rawData.operator.name) || ''
      });

      newData.push({
        id: 'region',
        label: this.$t('src.modules.search-management.index.region'),
        valueString: (rawData.region && rawData.region.name) || ''
      });
      newData.push({
        id: 'country',
        label: this.$t('src.modules.search-management.index.country'),
        valueString: (rawData.country && rawData.country.name) || ''
      });
      newData.push({
        id: 'company',
        label: this.$t('src.modules.search-management.index.company'),
        valueString: (rawData.company && rawData.company.name) || ''
      });
      newData.push({
        id: 'division',
        label: this.$t('src.modules.search-management.index.division'),
        valueString: (rawData.division && rawData.division.name) || ''
      });
      newData.push({
        id: 'email',
        label: this.$t('src.modules.search-management.index.email'),
        valueString: rawData.email || ''
      });
      newData.push({
        id: 'gender',
        label: this.$t('src.modules.search-management.index.gender'),
        valueString:
          rawData.gender && rawData.gender.includes('other#')
            ? `${
                this.$t('src.modules.profile.components.user-information.others') +
                ': ' +
                rawData.gender.slice(6, rawData.gender.length)
              }`
            : rawData.gender || ''
      });
      newData.push({
        id: 'role',
        label: this.$t('common.text.role'),
        valueString: MAPPING_ROLE[rawData.role] || rawData.role || ''
      });
      newData.push({
        id: 'status',
        label: this.$t('src.modules.search-management.index.status'),
        valueString: rawData.status || ''
      });
      newData.push({
        id: 'contactNumber',
        label: this.$t('src.modules.search-management.index.contact_number'),
        valueString: rawData.contactNumber || ''
      });
      newData.push({
        id: 'active',
        label: this.$t('src.modules.search-management.index.active'),
        valueString: rawData.active || ''
      });
      newData.push({
        id: 'primaryLanguage',
        label: this.$t('src.modules.search-management.index.primary_language'),
        valueArray:
          rawData.primaryLanguage.map(
            item => this.displayLanguageName[item] || this.languageList[item] || item
          ) || []
      });
      newData.push({
        id: 'secondaryLanguage',
        label: this.$t('src.modules.search-management.index.secondary_language'),
        valueArray:
          rawData.secondaryLanguage.map(
            item => this.displayLanguageName[item] || this.languageList[item] || item
          ) || []
      });
      return newData;
    },

    m_transformUserInfo(rawData) {
      const newData = [];
      newData.push({
        id: 'name',
        label: this.$t('src.modules.search-management.index.name'),
        valueString: rawData.name
      });
      newData.push({
        id: 'locale',
        label: this.$t('src.modules.search-management.index.locale'),
        valueString: this.languageList[rawData.locale]
      });
      newData.push({
        id: 'platform',
        label: this.$t('src.modules.search-management.index.platform'),
        valueString: STANDARDIZE_PLATFORM_NAME[rawData.platform.toLowerCase()] || rawData.platform
      });
      newData.push({
        id: 'gender',
        label: this.$t('src.modules.search-management.index.gender'),
        valueString: rawData.gender
      });
      return newData;
    },

    m_transformExternalUserInfo(rawData) {
      return rawData
        .map(item => {
          const { label = '', value = '' } = item;
          if (!label || !value) return false;

          return {
            id: label,
            label,
            valueString: value
          };
        })
        .filter(i => i);
    },

    m_gender(key) {
      if (key === 'male') return this.$t('src.modules.profile.components.user-information.male');
      else return this.$t('src.modules.profile.components.user-information.female');
    },

    async m_transformPersonalInfo(rawData, displayAgent) {
      const displayLanguage = this.displayLanguage;
      const personalInfo = [];

      personalInfo.push({
        id: 'userId',
        label: this.$t('src.modules.search-management.index.user_id'),
        valueString: rawData.id || ''
      });
      personalInfo.push({
        id: 'name',
        label: this.$t('src.modules.search-management.index.user_name'),
        valueString: rawData.name || ''
      });
      personalInfo.push({
        id: 'locale',
        label: this.$t('src.modules.search-management.index.locale'),
        valueString: this.languageList[rawData.locale] || ''
      });
      personalInfo.push({
        id: 'platform',
        label: this.$t('src.modules.search-management.index.platform'),
        valueString:
          STANDARDIZE_PLATFORM_NAME[rawData.platform.toLowerCase()] || rawData.platform || ''
      });
      personalInfo.push({
        id: 'email',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.email'),
        valueString: rawData.email || ''
      });
      personalInfo.push({
        id: 'fullName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.full_name'
        ),
        valueString: rawData.fullName || ''
      });
      personalInfo.push({
        id: 'firstName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.first_name'
        ),
        valueString: rawData.firstName || ''
      });
      personalInfo.push({
        id: 'lastName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.last_name'
        ),
        valueString: rawData.lastName || ''
      });
      personalInfo.push({
        id: 'birthday',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.birthday'
        ),
        valueString: this.m_formatTime(rawData.birthday, 'YYYY/MM/DD') || rawData.birthday || ''
      });
      personalInfo.push({
        id: 'gender',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.gender'
        ),
        valueString:
          rawData.gender && rawData.gender.key
            ? PERSONAL_OPTIONS[displayLanguage][rawData.gender.key] +
              `${rawData.gender.key === 'other' ? `: ${rawData.gender.value}` : ''}`
            : ''
      });
      personalInfo.push({
        id: 'phoneNumber',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.phone_number'
        ),
        valueString: rawData.phoneNumber || ''
      });
      personalInfo.push({
        id: 'address',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.address'
        ),
        valueString: rawData.address || ''
      });
      personalInfo.push({
        id: 'city',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.city'),
        valueString: rawData.city || ''
      });
      let provinces = {};
      if (rawData.country && rawData.country.key) {
        const provinceList = await this.getProvinces({
          code: rawData.country.key,
          language: this.displayLanguage
        });
        provinces = provinceList.reduce((obj, { code, name }) => {
          obj[code] = name;
          return obj;
        }, {});
      }
      personalInfo.push({
        id: 'province',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.province'
        ),
        valueString:
          rawData.province && rawData.province.key ? provinces[rawData.province.key] || '' : ''
      });
      personalInfo.push({
        id: 'country',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.country'
        ),
        valueString:
          rawData.country && rawData.country.key ? this.countries[rawData.country.key] || '' : ''
      });
      personalInfo.push({
        id: 'postCode',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.post_code'
        ),
        valueString: rawData.postCode || ''
      });
      personalInfo.push({
        id: 'maritalStatus',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.marital_status'
        ),
        valueString:
          rawData.maritalStatus && rawData.maritalStatus.key
            ? PERSONAL_OPTIONS[displayLanguage][
                rawData.maritalStatus.key === 'S' ? 'single' : rawData.maritalStatus.key
              ]
            : ''
      });
      personalInfo.push({
        id: 'relationship',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.relationship'
        ),
        valueString: rawData.relationship || ''
      });
      personalInfo.push({
        id: 'militaryStatus',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.military_status'
        ),
        valueString: rawData.militaryStatus || ''
      });
      personalInfo.push({
        id: 'occupation',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.occupation'
        ),
        valueString: rawData.occupation || ''
      });
      personalInfo.push({
        id: 'companyName',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.company_name'
        ),
        valueString: rawData.companyName || ''
      });
      personalInfo.push({
        id: 'workPhoneNumber',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_phone_number'
        ),
        valueString: rawData.workPhoneNumber || ''
      });
      personalInfo.push({
        id: 'workEmail',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.work_email'
        ),
        valueString: rawData.workEmail || ''
      });
      personalInfo.push({
        id: 'privacyPolicy',
        label: this.$t(
          'src.modules.chat.components.CustomerSupportPanel.contactInformation.privacy_policy'
        ),
        valueString: rawData.privacyPolicy || ''
      });
      personalInfo.push({
        id: 'tc',
        label: this.$t('src.modules.chat.components.CustomerSupportPanel.contactInformation.tc'),
        valueString: rawData.tc || ''
      });

      const customFields = [];
      if (rawData.customizeInfo && Object.keys(rawData.customizeInfo).length > 0) {
        const { advancedConfigs: { customizeFields = {}, defaultLanguage } = {} } =
          this.channelsMap[rawData.channelId];

        Object.keys(customizeFields)
          .sort()
          .map(k => {
            if (!rawData.customizeInfo[customizeFields[k].prop]) return k;
            customFields.push({
              id: customizeFields[k].prop,
              label:
                customizeFields[k].label[displayLanguage] ||
                customizeFields[k].label[defaultLanguage] ||
                '',
              valueString: String(rawData.customizeInfo[customizeFields[k].prop]) || ''
            });
            return k;
          });
      }

      if (displayAgent && Object.keys(displayAgent).length > 0) {
        const filterDisplayAgent = Object.keys(displayAgent).filter(
          key => displayAgent[key] === true
        );
        filterDisplayAgent.unshift('name', 'locale');
        return concat(
          personalInfo.filter(item => {
            return filterDisplayAgent.indexOf(item.id) >= 0;
          }),
          customFields
        );
      } else return concat(personalInfo, customFields);
    },

    async m_transformCustomerSupportInfo(rawData) {
      const newData = [];
      newData.push({
        id: 'title',
        label: this.$t('src.modules.search-management.index.title'),
        valueString: rawData.title || ''
      });
      newData.push({
        id: 'status',
        label: this.$t('src.modules.search-management.index.status'),
        valueString: rawData.status || ''
      });
      newData.push({
        id: 'categoryPath',
        label: this.$t('src.modules.search-management.index.category'),
        valueString: rawData.categoryPath || ''
      });
      newData.push({
        id: 'userName',
        label: this.$t('src.modules.search-management.index.user_name'),
        valueString: rawData.userName || ''
      });
      newData.push({
        id: 'agentName',
        label: this.$t('src.modules.search-management.index.agent_name'),
        value: (await this.m_getAgentName(rawData.agentId)) || ''
      });
      newData.push({
        id: 'platform',
        label: this.$t('src.modules.search-management.index.platform'),
        valueString:
          STANDARDIZE_PLATFORM_NAME[rawData.platform.toLowerCase()] || rawData.platform || ''
      });
      return newData;
    },

    async m_getAgentName(agentId) {
      let name = '';
      let agent = this.people[agentId];
      if (!agent) {
        agent = await this.addAgentToPeople(agentId);
      }
      if (agent && agent.name) name = agent.name;
      if (name.toString().trim() === '') name = `${agent.firstName || ''} ${agent.lastName || ''}`;
      return name;
    },

    async m_fetchMessagesByTicketId({ id, ticketId, state, projectId }) {
      if (
        [TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state) &&
        this.messagesOfTicketId[id] &&
        this.messagesOfTicketId[id][ticketId]
      ) {
        // exist messages of old ticket
      } else {
        await this.getMessagesByTicketId({
          id,
          ticketId,
          hasNewMessage: true,
          projectId
        });
      }
    },

    async m_getSelectedConversation(item) {
      const selConv = this.ticketsFilterMap[item.id];
      // if item exist in sate
      if (selConv) {
        this.selectedConversation = selConv;
        return;
      }
      // if item clicked not yet.
      let conv = {
        ...(await this.getConversationTicketById({
          conversationId: item.conversationId,
          ticketId: item.id
        }))
      };
      const { error } = conv;
      if (error) {
        this.selectedConversation = null;
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: error.message
        });
      } else {
        let { userId, channelId, name, platform, projectId } = conv;
        let userInfo = this.usersFilterMap[userId];
        if (!userInfo) {
          const personalParams = { userId, channelId, platform, projectId };
          const personalInfo = await this.getPersonalInfo(personalParams);
          if (Object.keys(personalInfo).length === 0) {
            const userParams = { userId, channelId };
            userInfo = await this.getUserInfo(userParams);
            if (Object.keys(userInfo).length !== 0 && userInfo.constructor === Object) {
              conv.userInfo = userInfo;
            } else {
              conv.userInfo.id = userId;
              conv.userInfo.userId = userId;
              conv.userInfo.name = name;
            }
          } else {
            conv = { ...conv, userInfo: personalInfo };
          }
        }

        this.selectedConversation = conv;
      }
    },

    async m_loadMoreMessage({ ticketId, conversationId, projectId, nextKey }) {
      this.loadingMore = true;
      try {
        await this.loadMoreMessageOfTicketId({
          ticketId,
          conversationId,
          projectId,
          nextKey
        });
      } catch (e) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: `Could not load more message, ${e.message}`
        });
      }
      this.loadingMore = false;
    },

    m_shownScrollToBottomDialogBox() {
      this.$nextTick(() => {
        if (!this.$refs.appCardChat) return;
        const { scrollHeight } = this.$refs.appCardChat;
        this.$refs.appCardChat.scrollTop = scrollHeight;
      });
    },

    m_hasCustomerSupport(item) {
      if (
        [TICKET_STATE.COMPLETE].includes(item.state) &&
        [TICKET_STATUS.COMPLETE].includes(item.status)
      )
        return true;
      return false;
    },

    m_resetGrid() {
      this.dataSearch = [];
      this.countTableAll = 0;
    },

    async m_reSupportTicket(item) {
      this.setGlobalReady(false);
      const { userId, channelId, id } = item;
      await this.getActionSearchClick({ userId, channelId });
      const { conversationId = '' } = item;
      const result = await this.allowReSupport({ conversationId, id });
      if (result && result.error) {
        this.setGlobalReady(true);
        return EventBus.$emit('reSupportWarningModal', result);
      }

      await this.m_getSelectedConversation(item);
      try {
        const groups = await this.getGroupsOpts({
          ticket: { projectId: item.projectId },
          ticketId: item.id
        });
        this.spAgentGroups = await groups.filter(i => i.isEnable);
        this.c_enableRequestUser = this.m_enableRequestUser(item.channelId);
        this.c_isStoryConversation = this.m_isStoryConversation(item);
        if (!this.m_channelHasBot(item.channelId)) {
          this.setSupportWithoutUserAgreement(true);
          if (!this.spAgentGroups || this.spAgentGroups.length <= 1) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
            );
            this.typeConfirmModal = 're-support';
            this.showModelconfirm = true;
          } else this.showSupportTicket = true;
        } else {
          if (this.c_enableRequestUser || !this.spAgentGroups || this.spAgentGroups.length <= 1) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
            );
            this.typeConfirmModal = 're-support';
            this.showModelconfirm = true;
            this.setSupportWithoutUserAgreement(this.c_enableRequestUser ? false : true);
          } else {
            this.showSupportTicket = true;
            this.setSupportWithoutUserAgreement(true);
          }
        }
        this.setGlobalReady(true);
      } catch (error) {
        // eslint-disable-next-line
        this.$log('[C+ Debug]: m_reSupportTicket -> error', error);
        this.setGlobalReady(true);
      }
    },

    m_enableRequestUser(channelId) {
      const { advancedConfigs = {} } = this.channelsFilterMap[channelId];
      const { conversationDisplay = {} } = advancedConfigs;

      const { requestUser = {} } = conversationDisplay;
      return requestUser ? true : false;
    },

    m_isStoryConversation(item) {
      return [TICKET_STATUS.USER_MENTION].includes(item.status);
    },

    m_channelHasBot(channelId) {
      const { advancedConfigs = {} } = this.channelsFilterMap[channelId];
      const { modulesActivation = {} } = advancedConfigs;
      const { bot = false } = modulesActivation;
      this.channelHasBot = bot;
      return bot;
    },

    async handleSupportWithoutUserAgreement() {
      this.setGlobalLoadingMap({ action: 'without_users_agreement', flag: true });
      await this.setSupportWithoutUserAgreement(true);
      await this.getActionSearchClick({});
      await this.handleSupportTicket();
      this.showModelconfirm = false;
      this.setGlobalLoadingMap({ action: 'without_users_agreement', flag: false });
    },

    async handleYes(type) {
      this.setGlobalLoadingMap({ action: 'with_users_agreement_or_yes', flag: true });
      await this.getActionSearchClick({});
      switch (type) {
        case 're-support':
          await this.handleSupportTicket();
          break;
      }
      this.showModelconfirm = false;
      this.setGlobalLoadingMap({ action: 'with_users_agreement_or_yes', flag: false });
    },

    handleCloseSupportTicket() {
      this.showSupportTicket = false;
      this.getActionSearchClick({});
    },

    async handleNo() {
      this.showModelconfirm = false;
      this.getActionSearchClick({});
    },

    async handleSupportTicket() {
      if (!this.spAgentGroups || this.spAgentGroups.length === 0) {
        await this.supportTicket(this.selectedConversation).then(
          async () => await new Promise(resolve => setTimeout(() => resolve(true), 500))
        );
      } else {
        this.showSupportTicket = true;
      }
    },
    m_agentHandle(item) {
      if (item.assignee === item.id) {
        const { hasBot = true } = this.channelsFilterMap[item.channelId] || {};
        if (hasBot) return 'BOT';
        else return 'NON_BOT';
      } else {
        if (item.assignee === 'AUTO_ASSIGN') return 'AUTO_ASSIGN';
        else if (item.assignee === TICKET_STATUS.FORWARD_GROUP) return 'FORWARD_GROUP';
        else return 'AGENT';
      }
    },

    remoteMethodUserLabel(query) {
      if (query !== '') {
        this.loadingUserLabel = true;
        setTimeout(() => {
          this.loadingUserLabel = false;
          this.projectUserLabel = this.c_projectLables.filter(item => {
            return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.projectUserLabel = [];
      }
    },
    remoteMethodTicketLabel(query) {
      if (query !== '') {
        this.loadingTicketLabel = true;
        setTimeout(() => {
          this.loadingTicketLabel = false;
          this.options4 = this.states.filter(item => {
            return item.state.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options4 = [];
      }
    },

    m_selectMessageInput() {
      // eslint-disable-next-line
      this.showInputValueMessage = true;
      this.$nextTick(() => {
        this.$refs.saveMessageInput.$refs.input.focus();
      });
    },

    m_truncate(value) {
      if (hasSpecialCharacters(value)) {
        return 10;
      }
      return 25;
    },

    renderColor(type) {
      return MAPPING_LABEL_COLOR[type] || '';
    },

    renderStyle(type) {
      return `border-color: ${MAPPING_LABEL_COLOR[type]}` || '';
    }
    /**
     * All Common function in UI (E)
     */
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/chat/chat.scss';

/deep/.msg-success {
  background-color: #f0f9eb !important;
  border-color: #e1f3d8 !important;
}

.nat-main-ctnr {
  height: 100%;
  font-size: 14px !important;
  overflow-y: auto !important;
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .nat-sub-ctnr {
    overflow: auto;
    padding-left: 65px;
    padding-top: 30px;
    .nat-cover-ctnr {
      margin: 0 50px 50px 65px;
      .nat-title-wrap {
        display: block;
      }
      .nat-content-wrap {
        z-index: 1;
        height: calc(100vh - 130px);
        overflow-y: auto;
        overflow-x: hidden;
        .nat-condition-bar {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: 0px;
          .nat-condition-row {
            .nat-condition-item {
              display: block;
              padding-right: 10px;
              margin-bottom: 1rem;
              /deep/ label {
                font-weight: bold;
                text-transform: uppercase;
              }
            }
            .nat-condition-checkbox-group {
              display: block;
              padding-right: 10px;
              margin-bottom: 1rem;
              padding-left: 0px;
              /deep/ legend {
                font-weight: bold;
                text-transform: uppercase;
                padding-bottom: 0px;
              }
            }
            .nat-condition-item-checkbox {
              display: flex;
              padding-right: 10x !important;
              margin-bottom: 1rem;
              padding-top: 28px;
              div {
                padding-right: 10px;
              }
              /deep/ label {
                font-weight: bold;
                text-transform: uppercase;
              }
            }
          }
        }
        .nat-action-bar {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: -15px;
        }
        .nat-grid-result {
          margin-bottom: 1.5rem !important;
          margin-top: 1.5rem !important;
          text-align: left;
          margin-right: 0px;
          margin-left: -15px;

          /deep/.nat-tbody-tr {
            td {
              word-wrap: break-word;
            }
          }

          .nat-grid-tag-item {
            background-color: orange;
            padding: 5px;
            color: white;
            margin-right: 5px;
            line-height: 40px;
            white-space: nowrap;
            border-radius: 0.25rem;
          }
          .nat-cell-text {
            overflow-wrap: break-word;
          }

          .user-label-item {
            float: left;
            padding: 0 5px;
            line-height: 20px;
            height: 22px;
          }

          /deep/.nat-table-header {
            background-color: #2196f3;
            color: #ffffff;
            th {
              text-align: center !important;
            }
          }

          /deep/.nat-table-col-no {
            width: 55px !important;
          }
          /deep/.nat-table-col-action {
            width: 120px !important;
          }
          /deep/.nat-table-col-conversation-id {
            width: 260px !important;
          }
          /deep/.nat-table-col-created-at {
            width: 260px !important;
          }
          /deep/.nat-table-col-project {
            width: 150px !important;
          }
          /deep/.nat-table-col-platform {
            width: 150px !important;
          }
          /deep/.nat-table-col-channel {
            width: 150px !important;
          }
          /deep/.nat-table-col-group {
            width: 150px !important;
          }
          /deep/.nat-table-col-state {
            width: 150px !important;
          }
          /deep/.nat-table-col-status {
            width: 170px !important;
          }
          /deep/.nat-table-col-user-id {
            width: 200px !important;
            overflow-wrap: break-word !important;
          }
          /deep/.nat-table-col-user-name {
            width: 200px !important;
          }
          /deep/.nat-table-col-user-user-language {
            width: 120px !important;
          }
          /deep/.nat-table-col-agent-id {
            width: 200px !important;
          }
          /deep/.nat-table-col-agent-name {
            width: 200px !important;
          }
          /deep/.nat-table-col-login-id {
            width: 200px !important;
          }
          /deep/.nat-table-col-follow-up {
            width: 130px !important;
          }
          /deep/.nat-table-col-labels {
            width: 200px !important;
            word-break: break-word;
          }
          /deep/.nat-table-col-user-labels {
            width: 200px !important;
            word-break: break-word;
          }
        }
      }
    }
  }
}
</style>
