<template>
  <div v-if="responses.length > 0">
    <div
      v-for="(response, index) in responses"
      :key="`${response.url}_${index}`"
      class="m-b-10"
    >
      <a :href="response.url" target="_blank" rel="noopener noreferrer">
        <div :title="response.url" class="chat-type link-preview">
          <img v-if="response.image" :src="response.image" class="cover-image" />
          <div class="second-section">
            <div v-if="response.title" class="title one-line-text">
              {{ response.title }}
            </div>
            <div class="description-container">
              <div v-if="response.description" class="description two-line-text">
                {{ response.description }}
              </div>
            </div>
            <div class="url-container">
              <img v-if="response.icon" :src="response.icon" class="icon" />
              <div v-if="response.url" class="url one-line-text">{{ response.url }}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div v-else-if="isUserUrlOnly">
    <p class="text-style" v-html="formatEmailAddress(escapeHtmlChar(message))"></p>
  </div>
</template>

<script>
import fetchAPI from 'core/fetchAPI';
import { getURLLstFrmTxt } from 'core/helpers';
import { escapeHtmlChar } from 'core/helpers';
import HighlightUserPI from 'modules/chat/helpers/highlightUserPI';
import { EventBus } from 'core/eventBus';

export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    // limit 1 link preview per message
    slice: {
      type: Number,
      default: 1
    },
    isUserUrlOnly: {
      type: Boolean,
      default: false
    },
    isAgent: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      responses: [],
      apiUrl: process.env.VUE_APP_WEBCHAT_API_URL
    };
  },
  watch: {
    message: function() {
      this.responses = [];
      this._getLinkPreview();
    }
  },
  created() {
    this._getLinkPreview();
  },

  updated() {
    EventBus.$emit('scrollBottomMessage');
  },

  methods: {
    async _getLinkPreview() {
      const { urls } = await getURLLstFrmTxt(this.message);
      if (urls.length > 0) {
        const urlSlice = urls.slice(0, this.slice);
        const promises = urlSlice.filter(url => url.indexOf('https')===0).map(url => this._fetchData(url));
        const results = await Promise.all(promises.map(p => p.catch(e => e)));
        const validResults = results.filter(
          result => result && !(result instanceof Error)
        );

        if (validResults.length > 0) {
          this.responses = validResults;
        }
      }
    },

    async _fetchData(url) {
      const fetch = new fetchAPI({
        baseURL: this.apiUrl,
        headers: {
          'x-api-key': process.env.VUE_APP_WEBCHAT_API_KEY
        }
      });
      return fetch
        .get('/og', {
          url: url
        })
        .then(res => {
          return res.data;
        });
    },

    escapeHtmlChar(data) {
      return escapeHtmlChar(data);
    },

    formatEmailAddress(message) {
      if (this.isAgent) return message;
      const highlightUserPI = new HighlightUserPI(message);
      message = highlightUserPI.runRegexp();
      message = highlightUserPI.decode();
      return message;
    }
  }
};
</script>

<style lang="scss" scoped>
.link-pre {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0 20px 10px 0;
  clear: both;
  border-radius: 0.286rem;
}
.linkprevue-right {
  float: right;
}
.linkprevue-left {
  float: left;
}

a {
  color: #000000 !important;
}
.link-preview {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eeeeee;

  .second-section {
    padding: 8px;
    background-color: #f9f8f8;
    border: #eee 1px solid;
  }

  .cover-image {
    height: 110px;
    width: 100%;
    background-size: 100% 110px;
    background-color: #e0e0e0;
    background-repeat: no-repeat;
    background-position: top left;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    height: auto;
  }

  .one-line-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .two-line-text {
    overflow: hidden;
    position: relative;
    line-height: 20px;
    max-height: 40px;
  }

  .description {
    font-size: 14px;
  }

  .url-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: auto;
      height: 16px;
      margin-right: 5px;
    }

    .url {
      font-size: 14px;
    }
  }
}
</style>
