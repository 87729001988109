<template>
  <div class="container-fluid p-0 page">
    <AppLeftMenu show-name="file-management" />
    <main class="profile-page file-management">
      <AppCover />
      <div class="page-sub">
        <section>
          <div class="csh-container page-cover-container">
            <!-- Title -->
            <b-row class="page-header text-left">
              <b-col ld="12" xl="12">
                <h3>{{ $t('src.modules.file-management.index.file_management') }}</h3>
              </b-col>
            </b-row>
            <!-- Controls -->
            <b-row>
              <b-col ld="12" xl="3">
                <!-- Project Selection -->
                <base-select
                  v-model="currentProjectId"
                  :options="projects"
                  :option-value="'value'"
                  :option-lable="'text'"
                  :name="'currentProjectId'"
                  :label="$t('src.modules.file-management.index.project')"
                  :required="true"
                  :value-type="'String'"
                />
              </b-col>
              <b-col ld="12" xl="6" style="margin-top: 22px">
                <!-- Back button -->
                <span id="go-back-to-parent-folder" class="d-inline-block">
                  <b-button
                    variant="primary"
                    class="mb-3"
                    style="float: left"
                    @click="goUpEvent"
                    :disabled="!canGoUp || isModeSearch"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </b-button>
                </span>
                <b-tooltip
                  target="go-back-to-parent-folder"
                  triggers="hover"
                  :delay="100"
                  boundary="viewport"
                  placement="top"
                  >{{ $t('src.modules.file-management.index.up') }}</b-tooltip
                >
                <!-- Upload button -->
                <input
                  id="uploadFileBtn"
                  ref="resetInputFile"
                  hidden
                  type="file"
                  multiple
                  @change="triggerUploadFileChange"
                />
                <div style="display: none">
                  <file-upload
                    v-for="(file, idx) in uploadingFiles"
                    :key="idx"
                    :file="file"
                    :path="currentProjectId"
                    :platform="'line'"
                    @onSuccess="createFile"
                  />
                </div>

                <span id="upload-button" class="d-inline-block" v-if="canUpload">
                  <b-button
                    variant="primary"
                    class="mb-3"
                    style="float: left"
                    @click="clickUploadFileBrowser"
                    :disabled="!canCreateNewItem || isModeSearch"
                  >
                    <i class="fa fa-upload"></i>
                  </b-button>
                </span>
                <b-tooltip
                  target="upload-button"
                  triggers="hover"
                  :delay="100"
                  boundary="viewport"
                  placement="top"
                  >{{ $t('src.modules.file-management.index.upload') }}</b-tooltip
                >
                <!-- create button -->
                <span id="create-button" class="d-inline-block" v-if="canCreate">
                  <b-button
                    variant="primary"
                    class="mb-3"
                    style="float: left"
                    @click="openNewFolderModalEvent"
                    :disabled="!canCreateNewItem || isModeSearch"
                  >
                    <i class="fa fa-plus"></i>
                  </b-button>
                </span>
                <b-tooltip
                  target="create-button"
                  triggers="hover"
                  :delay="100"
                  boundary="viewport"
                  placement="top"
                >
                  {{ $t('src.modules.file-management.index.create_new_folder') }}
                </b-tooltip>
              </b-col>
              <b-col ld="12" xl="3" style="margin-top: 22px">
                <!-- Search file -->
                <base-input
                  v-model="searchText"
                  :name="'filter-file'"
                  :placeholder="$t('src.modules.file-management.index.search_text')"
                  @keyup="searchEvent"
                  :icon="'search'"
                  :align="'horizontal'"
                  :maxlength="-1"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col ld="12" xl="12" style="display: flex">
                <label style="width: 65px; font-weight: 600; text-transform: none">{{
                  $t('src.modules.file-management.index.usage')
                }}</label>
                <el-progress
                  v-if="currentProjectId"
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="percentage"
                  :color="customColors"
                  :class="'custom-color-text'"
                ></el-progress>
              </b-col>
            </b-row>
            <!-- Main table -->
            <div class="table-content-wrap">
              <b-row>
                <b-col ld="12" xl="12" style="padding-right: unset">
                  <!-- element -->
                  <b-table
                    :items="items"
                    :fields="columns"
                    :current-page="pageNumber"
                    :per-page="perPage"
                    v-loading="loading"
                    class="nat-table m-b-30"
                    responsive
                    striped
                    hover
                    thead-class="nat-table-header"
                    show-empty
                  >
                    <!-- Show row empty data  -->
                    <template slot="empty">
                      <div v-if="!items.length" class="text-center">
                        {{ $t('common.text.no_data') }}
                      </div>
                    </template>

                    <!-- file_name -->
                    <template slot="file_name" slot-scope="data">
                      <a
                        v-if="data.item.type == 'file'"
                        class="file-name"
                        href="javascript:void(0)"
                      >
                        <i class="fa fa-file icon-file"></i>
                        <b-tooltip
                          :target="'tooltip-target-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                          >{{ data.value }}</b-tooltip
                        >
                        <span :id="'tooltip-target-' + data.item.id">{{
                          data.value | truncate(30)
                        }}</span>
                      </a>
                      <a
                        v-if="data.item.type == 'folder'"
                        href="javascript:void(0)"
                        @click="handleTableActionGoFolder(data.item.id)"
                      >
                        <i class="fa fa-folder icon-file"></i>
                        <b-tooltip
                          :target="'tooltip-target-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                          >{{ data.value }}</b-tooltip
                        >
                        <span :id="'tooltip-target-' + data.item.id">{{
                          data.value | truncate(30)
                        }}</span>
                      </a>
                    </template>

                    <!-- created_time -->
                    <template slot="created_time" slot-scope="data">
                      {{ formatTime(data.value) }}
                    </template>

                    <!-- created_name -->
                    <template slot="created_name" slot-scope="data">
                      {{ data.value }}
                    </template>

                    <!-- file_size -->
                    <template slot="file_size" slot-scope="data">
                      <span v-if="data.item.type === 'file'">{{ formatNumber(data.value) }}</span>
                    </template>

                    <!-- action -->
                    <template slot="action" slot-scope="data">
                      <!-- icon search -->
                      <a
                        v-if="data.item.type == 'file'"
                        :id="'copy-' + data.item.id"
                        href="javascript:void(0)"
                        @click="handleTableActionCopy(data.item)"
                      >
                        <i
                          class="fa fa-link"
                          v-bind:class="{ isDisabledLinked: agentWorkAtHome }"
                        ></i>
                        <b-tooltip
                          :target="'copy-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                        >
                          {{ $t('src.modules.file-management.index.copy_link') }}
                        </b-tooltip>
                      </a>

                      <!-- icon trash -->
                      <a
                        v-if="canDelete"
                        :id="'delete-' + data.item.id"
                        href="javascript:void(0)"
                        class="ml-2"
                        @click="handleTableActionDelete(data.item)"
                      >
                        <i class="fa fa-trash-alt"></i>
                        <b-tooltip
                          :target="'delete-' + data.item.id"
                          triggers="hover"
                          :delay="100"
                          boundary="viewport"
                          placement="top"
                          >{{ $t('common.text.delete') }}</b-tooltip
                        >
                      </a>
                    </template>
                  </b-table>
                  <!-- pagging -->
                  <b-row>
                    <b-col></b-col>
                    <b-col v-if="totalRows > 0" md="12" class="bt-paging">
                      <b-pagination
                        @change="onPageNumberChange"
                        v-model="pageNumber"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        class="my-0 pull-right"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-modal
            id="newFolderModal"
            ref="newFolderModal"
            :title="$t('src.modules.file-management.index.create_new_folder')"
            @hidden="resetCreateFolderModal"
          >
            <ValidationObserver ref="newFolderName">
              <base-input
                v-model="newFolderName"
                :name="'newFolderName'"
                :placeholder="$t('src.modules.file-management.index.input_new_folder_name')"
                @keypressEnter="typeNewFolderNameEvent"
                :label="$t('src.modules.file-management.index.folder_name')"
                :show-label="false"
                :maxlength="255"
              />
            </ValidationObserver>
            <div slot="modal-footer" class="w-100">
              <b-btn
                class="float-right"
                variant="primary"
                style="margin-left: 7px"
                :disabled="isCreatingFolder || newFolderName === ''"
                @click="handleActionCreateFolder"
              >
                <span v-if="!isCreatingFolder">{{ $t('common.confirmModal.yes') }}</span>
                <span v-else>
                  {{ $t('src.modules.session.components.login.waiting') }}
                  <i class="fas fa-spinner fa-spin fa-lg" />
                </span>
              </b-btn>
              <b-btn
                class="float-right"
                variant="default"
                @click="handleActionCloseCreateFolderModal"
                >{{ $t('common.confirmModal.cancel') }}</b-btn
              >
            </div>
          </b-modal>
        </section>
      </div>
      <AppFooter />
    </main>
  </div>
</template>

<script>
// import library
import { mapActions, mapState } from 'vuex';
import { EventBus } from 'core/eventBus';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { getFileType, compareName, convertTimeUTC } from 'core/helpers';
import { FILE_MANAGEMENT_DEFAULT, AGENT_ROLE, CHANNEL_MODE } from 'core/constants';
import FileUpload from './components/file-upload';
import AppLeftMenu from 'components/AppLeftMenu';
import AppFooter from 'components/AppFooter';
import AppCover from 'components/AppCover';

export default {
  components: {
    AppLeftMenu,
    AppFooter,
    AppCover,
    FileUpload
  },

  data() {
    return {
      messageConfirmDelete: this.$t(
        'src.modules.file-management.index.do_you_want_to_delete_this_item'
      ),
      currentProjectId: '',
      path: '',
      uploadingFiles: [],
      columns: {
        file_name: {
          label: this.$t('src.modules.chat.components.ProductPanel.sort-combo.name'),
          sortable: true,
          class: 'nat-table-col-file_name'
        },
        created_time: {
          label: this.$t('src.modules.file-management.index.created_at'),
          sortable: true,
          class: 'nat-table-col-created_time'
        },
        created_name: {
          label: this.$t('src.modules.file-management.index.created_by'),
          sortable: true,
          class: 'nat-table-col-created_name'
        },
        file_size: {
          label: this.$t('src.modules.file-management.index.size_bytes'),
          sortable: true,
          class: 'nat-table-col-size'
        },
        action: {
          label: this.$t('common.text.action'),
          class: 'nat-table-col-action'
        }
      },
      pathPagination: [1],
      pageNumber: 1,
      perPage: 10,
      searchText: '',
      newFolderName: '',
      isCreatingFolder: false,
      customColors: [
        { color: '#5cb87a', percentage: 80 },
        { color: '#e6a23c', percentage: 94 },
        { color: '#f56c6c', percentage: 100 }
      ],
      overwriteMessage: '',
      overwriteActionData: null
    };
  },

  computed: {
    ...mapState('session', ['user', 'people', 'projectMaps', 'channelsMap', 'agentWorkAtHome']),
    ...mapState('file', ['fileMap', 'loading']),
    isMaintain() {
      const channelAffected = Object.keys(this.channelsMap).filter(
        i => this.channelsMap[i].projectId === this.currentProjectId
      );

      let isValid = false;
      if (!channelAffected || channelAffected.length === 0) return isValid;
      for (let i = 0; i < channelAffected.length; i++) {
        const { mode = '' } = this.channelsMap[channelAffected[i]];
        if (mode === CHANNEL_MODE.MAINTAIN) isValid = true;
      }

      return isValid;
    },
    files() {
      return this.fileMap[this.currentProjectId] || [];
    },
    items() {
      let items = [];
      if (this.isModeSearch) {
        items = this.files.filter(({ project_id, parent_id, file_name }) => {
          const search = this.searchText.toLowerCase();
          const name = file_name.toLowerCase();
          return (
            project_id === this.currentProjectId &&
            parent_id === this.path &&
            name.indexOf(search) >= 0
          );
        });
      } else {
        const path = this.path;
        items = this.files.filter(({ parent_id }) => {
          return parent_id == path;
        });
      }
      items = items.sort(this.sortItems);
      return items.map(item => {
        const { created_by } = item;
        const { name: created_name = created_by } = this.people[created_by] || {};
        if (created_name === created_by) this.addAgentToPeople(created_by);
        return { ...item, created_name };
      });
    },

    currFiles() {
      let items = [];
      const path = this.path;
      items = this.files.filter(({ parent_id, type }) => {
        return parent_id === path && type === 'file';
      });
      items = items.sort(this.sortItems);
      return items.map(item => {
        const { created_by } = item;
        const { name: created_name = created_by } = this.people[created_by] || {};
        if (created_name === created_by) this.addAgentToPeople(created_by);
        return { ...item, created_name };
      });
    },

    totalRows() {
      return this.items.length;
    },
    isRoot() {
      const projects = this.projects;
      const path = this.path;
      return projects.some(({ value }) => value == path);
    },
    canGoUp() {
      if (!this.path) return false;
      return !this.isRoot;
    },
    canCreateNewItem() {
      return this.path ? true : false;
    },
    isModeSearch() {
      return this.searchText ? true : false;
    },
    totalFileSize() {
      const items = this.files.map(({ file_size = 0 }) => file_size);
      return items.reduce((t, v) => (t += v), 0);
    },
    blockedExtensions() {
      const projectSetting = this.projectSetting || {};
      const { blockedExtensions = [] } = projectSetting;
      return blockedExtensions.map(ex => ex.toLowerCase());
    },
    fileSizeLimitation() {
      const projectSetting = this.projectSetting || {};
      const { audioUploadSize, fileUploadSize, imageUploadSize, videoUploadSize } = {
        ...FILE_MANAGEMENT_DEFAULT,
        ...projectSetting
      };
      return {
        fileUploadSize,
        imageUploadSize,
        videoUploadSize,
        audioUploadSize
      };
    },
    projectSetting() {
      if (!this.currentProjectId) return null;
      const project = this.projectMaps[this.currentProjectId] || {};
      const { config = null } = project;
      if (!config) return null;

      const { fileManagement = {} } = config;
      const {
        audioUploadSize,
        blockedExtensions = [],
        fileUploadSize,
        imageUploadSize,
        projectStorage,
        videoUploadSize
      } = { ...FILE_MANAGEMENT_DEFAULT, ...fileManagement };
      return {
        audioUploadSize,
        blockedExtensions,
        fileUploadSize,
        imageUploadSize,
        projectStorage,
        videoUploadSize
      };
    },
    totalProjectSize() {
      const projectSetting = this.projectSetting || {};
      const { projectStorage = 0 } = projectSetting; // GB
      return this.convertToByte(projectStorage * 1024);
    },
    projects() {
      if (!isEmpty(this.projectMaps)) {
        const result = [];
        Object.keys(this.projectMaps).map(k => {
          if ([AGENT_ROLE.MODERATOR].includes(this.user.role)) {
            if (this.projectMaps[k].hasGroup && this.projectMaps[k].hasGroup === true)
              result.push({
                value: k,
                text: this.projectMaps[k].name
              });
          } else {
            result.push({
              value: k,
              text: this.projectMaps[k].name
            });
          }
        });
        return result.sort((a, b) => compareName(a, b, 'text'));
      }
      return [];
    },
    canUpload() {
      return ![AGENT_ROLE.CLIENT].includes(this.user.role) ? true : false;
    },
    canCreate() {
      return ![AGENT_ROLE.CLIENT].includes(this.user.role) ? true : false;
    },
    canDelete() {
      const projectMaps = this.projectMaps;
      const project = projectMaps[this.currentProjectId];
      const { groups = {} } = project;
      const hasGroup = Object.keys(groups).length;
      if ([AGENT_ROLE.LEADER].includes(this.user.role)) return true;
      if ([AGENT_ROLE.MODERATOR].includes(this.user.role) && hasGroup) return true;
      return false;
    },
    percentage() {
      const totalProjectSize = this.totalProjectSize;
      if (!totalProjectSize) return 100;
      const totalFileSize = this.totalFileSize;
      if (totalFileSize > totalProjectSize) return 100;
      const percentage = (totalFileSize / totalProjectSize) * 100;
      return Math.round((percentage + Number.EPSILON) * 100) / 100;
    }
  },
  watch: {
    projectMaps() {
      this.m_checkShowFileManagement();
    },
    projects(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return null;
      const first = newValue && newValue.length ? newValue[0] : null;
      this.currentProjectId = first ? first.value : '';
    },
    async currentProjectId(path) {
      this.path = path;
      this.setGlobalReady(false);
      await this.fetch(path);
      this.setGlobalReady(true);
    }
  },

  mounted() {
    const projects = this.projects;
    const first = projects[0] || null;
    this.currentProjectId = first ? first.value : '';
  },

  methods: {
    ...mapActions('file', ['fetch', 'remove', 'create']),
    ...mapActions('session', ['addAgentToPeople']),
    ...mapActions('global', ['setGlobalReady']),
    onPageNumberChange: function onPageNumberChange(num) {
      this.pathPagination.splice(this.pathPagination.length - 1, 1, num);
    },
    getOverwriteMessage(fileName) {
      return this.$t('src.modules.file-management.index.overwriteFileModal.duplicateFileMessage', {
        file_name: fileName
      });
    },

    formatTime(value) {
      if (value) return convertTimeUTC(value);
      return null;
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    convertToByte(mb) {
      return mb * 1024 * 1024;
    },
    handleTableActionCopy(item) {
      if (this.agentWorkAtHome) {
        return false;
      }
      const { file_src } = item;
      const $tempInput = document.createElement('INPUT');
      const $body = document.getElementsByTagName('body')[0];
      $body.appendChild($tempInput);
      $tempInput.setAttribute('value', file_src);
      $tempInput.select();
      document.execCommand('copy');
      $body.removeChild($tempInput);
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: this.$t('src.modules.file-management.index.link_was_copied')
      });
    },

    handleTableActionDelete(item) {
      if (this.isMaintain) {
        EventBus.$emit('maintenanceModeModal');
      } else {
        const { id } = item;

        const exists = this.existsChildren(id);
        if (exists) {
          this.messageConfirmDelete = this.$t(
            'src.modules.file-management.index.do_you_still_want_to_delete_the_folder_and_its_children_items'
          );
        } else {
          this.messageConfirmDelete = this.$t(
            'src.modules.file-management.index.do_you_want_to_delete_this_item'
          );
        }
        this.deletingItem = item;
        this.$baseConfirm({
          message: this.messageConfirmDelete,
          cancelButtonText: this.$t('common.confirmModal.cancel')
        })
          .then(() => {
            this.handleActionDeleteFile();
          })
          .catch(() => {});
      }
    },
    existsChildren(id) {
      const items = this.files.filter(({ parent_id }) => {
        return parent_id == id;
      });

      return !items || items.length === 0 ? false : true;
    },
    sortItems(a, b) {
      // Tree Node objects
      if (a.type == b.type) {
        return a.file_name.toUpperCase().localeCompare(b.file_name.toUpperCase());
      }
      return a.type == 'folder' ? -1 : 1;
    },
    /** Deleting file modal */
    handleActionCloseDeleteFileModal(e) {
      e.preventDefault();
      this.$refs.deleleFileModal.hide();
      this.deletingItem = null;
    },
    async handleActionDeleteFile() {
      this.setGlobalReady(false);
      const item = this.deletingItem;
      if (!item) return;
      const { id, project_id } = item;
      this.deletingItem = null;
      await this.remove({ id, project_id })
        .then(() => {
          this.setGlobalReady(true);
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t('src.modules.file-management.index.file_was_deleted')
          });
        })
        .catch(err => {
          this.setGlobalReady(true);
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: err.message
          });
        });
      await this.refresh();
    },

    /** End deleting file modal */
    /** Creating new folder */
    openNewFolderModalEvent() {
      this.isMaintain ? EventBus.$emit('maintenanceModeModal') : this.$refs.newFolderModal.show();
    },
    handleActionCloseCreateFolderModal(e) {
      e.preventDefault();
      this.newFolderName = '';
      this.isCreatingFolder = false;
      this.$refs.newFolderModal.hide();
    },
    async typeNewFolderNameEvent(event) {
      const { key } = event;
      if (key === 'Enter') {
        await this.handleActionCreateFolder();
      }
    },
    async handleActionCreateFolder() {
      this.$refs.newFolderName.validate().then(async result => {
        if (!result) {
          this.errorMessage();
        } else {
          if (this.isCreatingFolder) return;
          this.isCreatingFolder = true;
          if (!this.validateFolderName()) {
            this.isCreatingFolder = false;
            return;
          }
          await this.create({
            project_id: this.currentProjectId,
            parent_id: this.path,
            file_name: this.newFolderName.trim(),
            created_by: this.user.id,
            type: 'folder'
          })
            .then(async () => {
              this.newFolderName = '';
              this.$refs.newFolderModal.hide();
              this.$baseNotification.success({
                title: this.$t('src.core.App.success'),
                message: this.$t('src.modules.file-management.index.create_folder_succes')
              });
              this.isCreatingFolder = false;
            })
            .catch(e => {
              this.handleError(e);
              this.isCreatingFolder = false;
            });
        }
      });
    },
    validateFolderName() {
      this.newFolderName = this.newFolderName.trim();
      let existFolders = this.files.filter(
        ({ type, parent_id }) => type == 'folder' && parent_id == this.path
      );
      existFolders = existFolders.map(({ file_name }) => file_name.toUpperCase());
      if (existFolders.includes(this.newFolderName.toUpperCase())) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.file-management.index.error.exist_name')
        });
        return false;
      }
      return true;
    },

    resetCreateFolderModal() {
      this.newFolderName = '';
    },
    /** End creating new folder */
    searchEvent(event) {
      const { keyCode } = event;
      if (keyCode == 27) this.searchText = '';
    },
    handleTableActionGoFolder(folderId) {
      this.path = folderId;
      this.searchText = '';
      this.pathPagination.push(1);
      this.pageNumber = 1;
    },
    goUpEvent() {
      const files = this.files || [];
      const path = this.path;
      const current = files.find(({ id }) => id == path);
      if (!current) return;
      const { parent_id } = current;
      this.path = parent_id;

      this.pathPagination.splice(this.pathPagination.length - 1, 1);
      this.$nextTick(function _nextTickGoUpEvent() {
        this.pageNumber = this.pathPagination[this.pathPagination.length - 1];
      });
    },
    async refresh() {
      this.fetch(this.currentProjectId);
    },

    /** Start uploading file */
    clickUploadFileBrowser() {
      if (this.isMaintain) {
        EventBus.$emit('maintenanceModeModal');
      } else {
        const fileSharingCreateButton = document.getElementById('uploadFileBtn');
        fileSharingCreateButton.click();
      }
    },
    async triggerUploadFileChange(event) {
      try {
        let { files } = event && event.target;

        const uploadFileBtn = document.getElementById('uploadFileBtn');
        if (files && files.length > 0) {
          this.setGlobalReady(false);
          await this.refresh();
          files = [...files];
          // Validate total size
          const errors = this.validateFile(files);
          if (errors.length) {
            this.$baseNotification.warning({
              title: this.$t('src.core.App.warning'),
              message: errors.join('<br/>')
            });
            uploadFileBtn.value = '';
            throw new Error('Error');
          }
          let newFiles = [...this.uploadingFiles, ...files];
          this.uploadingFiles = newFiles;
          this.resetInputFile();
        } else {
          this.resetInputFile();
        }
      } catch (error) {
        this.resetInputFile();
        this.setGlobalReady(true);
        return;
      }
    },
    /* files: [{
     *   name: "name.jpg"
     *   size: 18317
     *   type: "image/jpeg"
     * }]
     */
    validateFile(files) {
      const getExtension = f => f.name.split('.').pop();
      let errors = [];
      const blockedExtensions = this.blockedExtensions;
      const fileSizeLimitation = this.fileSizeLimitation;
      const totalProjectSize = this.totalProjectSize;

      // Check total project size
      const totalSize = files.reduce((t, f) => t + (f.size || 0), 0);
      if (totalSize + this.totalFileSize > totalProjectSize) {
        errors.push(
          this.$t('src.modules.file-management.index.error.exceeds_total_allowable_size')
        );
        return errors;
      }

      // Check file extension and size
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Check extensions
        if (blockedExtensions.length) {
          const ext = getExtension(file);
          if (
            blockedExtensions.includes(ext.toLowerCase()) ||
            blockedExtensions.includes('.' + ext.toLowerCase())
          ) {
            errors.push(
              `[${file.name}] ${this.$t('src.modules.file-management.index.invalid_extension')}`
            );
            return errors;
          }
        }

        // Check filesize
        const typeSize = getFileType(file);
        if (file.size > this.convertToByte(fileSizeLimitation[typeSize] || 0)) {
          const message = `[${file.name}] ${this.$t(
            'src.modules.file-management.index.error.size_exceeds_maximum_allowable_size'
          )} (${fileSizeLimitation[typeSize]}MB).`;
          errors.push(message);
          return errors;
        }
      }

      return errors;
    },
    resetInputFile() {
      this.$refs.resetInputFile.value = null;
    },

    async createFile(data) {
      try {
        this.setGlobalReady(false);
        const { downloadURL: url, type, size, name, payload } = data;
        const sameNameFile = this.currFiles.find(({ file_name }) => {
          return file_name === name;
        });

        if (sameNameFile) {
          this.setGlobalReady(true);
          this.overwriteActionData = {
            oldFile: sameNameFile,
            newFile: {
              project_id: this.currentProjectId,
              file_name: name,
              file_type: type,
              file_size: size,
              file_src: url,
              payload: payload,
              type: 'file',
              parent_id: this.path,
              created_by: this.user.id
            }
          };
          this.overwriteMessage = this.getOverwriteMessage(name);
          this.$baseConfirm({
            message: this.overwriteMessage,
            distinguishCancelAndClose: true,
            closeOnPressEscape: false
          })
            .then(() => {
              this.handleOverwriteAction(true);
            })
            .catch(action => {
              if (action === 'cancel') this.handleOverwriteAction(false);
            });
          return;
        }

        await this.create({
          project_id: this.currentProjectId,
          file_name: name,
          file_type: type,
          file_size: size,
          file_src: url,
          payload: payload,
          type: 'file',
          parent_id: this.path,
          created_by: this.user.id
        }).then(async () => {
          await this.refresh();
          this.$baseNotification.success({
            title: this.$t('src.core.App.success'),
            message: this.$t('src.modules.file-management.index.upload_file_succes')
          });
        });
        this.setGlobalReady(true);
      } catch (error) {
        this.handleError(error);
        this.setGlobalReady(true);
      }
    },
    /** End uploading file */

    async handleOverwriteAction(isOverWrite) {
      this.setGlobalReady(false);
      if (isOverWrite) {
        if (this.overwriteActionData) {
          if (this.overwriteActionData.oldFile) {
            const deleteFile = this.overwriteActionData.oldFile;
            if (deleteFile && deleteFile.id && deleteFile.project_id) {
              const deleteResult = await this.remove({
                id: deleteFile.id,
                project_id: deleteFile.project_id
              })
                .then(() => {
                  return true;
                })
                .catch(err => {
                  this.$baseNotification.error({
                    title: this.$t('src.core.App.error'),
                    message: err.message
                  });
                });
              if (deleteResult) {
                if (this.overwriteActionData.newFile) {
                  const newFile = this.overwriteActionData.newFile;
                  await this.create(newFile)
                    .then(async () => {
                      await this.refresh();
                      this.$baseNotification.success({
                        title: this.$t('src.core.App.success'),
                        message: this.$t('src.modules.file-management.index.upload_file_succes')
                      });
                    })
                    .catch(error => this.handleError(error));
                }
              }
            }
          }
        }
      } else {
        if (
          this.overwriteActionData &&
          this.overwriteActionData.newFile &&
          this.overwriteActionData.newFile.file_name &&
          this.overwriteActionData.newFile.file_name !== ''
        ) {
          const newFile = this.overwriteActionData.newFile;
          const fileRegex = /([^/]*)\.([a-z0-9]*)/;
          const match = newFile.file_name.match(fileRegex);
          if (match.length === 3) {
            newFile.file_name = `${match[1]}(${moment().format('YYYYMMDD_HHmmss')}).${match[2]}`;
            await this.create(newFile)
              .then(async () => {
                await this.refresh();
                this.$baseNotification.success({
                  title: this.$t('src.core.App.success'),
                  message: this.$t('src.modules.file-management.index.upload_file_succes')
                });
              })
              .catch(error => this.handleError(error));
          }
        }
      }
      this.setGlobalReady(true);
    },

    handleError(error) {
      if (error.message == 'PROJECT_EXCEED_LIMITATION') {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.modules.file-management.index.notify_reach_project_limitation')
        });
      } else if (error.message == 'DUPLICATE_FOLDER') {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.modules.file-management.index.error.exist_name')
        });
      } else {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t(error.message)
        });
      }
    },
    m_checkShowFileManagement() {
      const { role = '' } = this.user || {};
      if (isEmpty(this.projectMaps) || !role) {
        return this.$router.push('/');
      }
      if ([AGENT_ROLE.LEADER, AGENT_ROLE.CLIENT].includes(role)) return true;
      if (role === AGENT_ROLE.MODERATOR) {
        let cnt = 0;
        Object.keys(this.projectMaps).map(k => {
          if (this.projectMaps[k] && this.projectMaps[k].hasGroup) cnt++;
          return cnt;
        });
        return !cnt ? this.$router.push('/') : true;
      }
      return this.$router.push('/');
    }
  }
};
</script>

<style lang="scss" scoped>
.file-name {
  cursor: default !important;
}

.table-content-wrap {
  z-index: 1;
  height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: auto;

  .row {
    margin-right: unset !important;
  }
}

@media only screen and (max-width: 1200px) {
  .table-content-wrap {
    z-index: 1;
    height: calc(100vh - 355px);
    overflow-y: auto;
    overflow-x: auto;

    .row {
      margin-right: unset !important;
    }
  }
}

/deep/.row {
  padding-bottom: 15px;
}

.input-group-text {
  font-size: 0.875rem !important;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 0.875rem !important;
  line-height: inherit;
}

.file-management {
  font-size: 14px !important;
  overflow-y: auto !important;
}
.file-management .footer {
}
.section-profile-cover {
  height: 100px !important;
}

.page-header {
  text-align: center;
}

.page-cover-container {
  margin: 0 50px 50px 50px;
  /deep/ label {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.search-icon {
  width: 30px !important;
  min-width: 30px !important;
}

.table-control {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bt-paging {
  margin-bottom: 40px;
  padding-top: 15px;
}

.navigation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

body {
  overflow-y: auto !important;
  font-size: 12px !important;
}

tbody td {
  width: 12.5%;
  float: left;
  overflow-wrap: break-word;
}
tbody {
  overflow-wrap: auto;
}

thead th {
  width: 12.5%;
  float: left;
  height: 70px;
}

/deep/.file-management .input-group-text {
  min-width: 100px;
}
/deep/.file-management .form-control .custom-select {
  padding-left: 10px !important;
}
/deep/.file-management .input-group .form-control:not(:first-child) {
  padding-left: 10px !important;
}
/deep/.el-progress {
  width: 100%;
}

.nat-table {
  tbody {
    display: block !important;
    overflow: auto !important;
    background: white;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important; /* even columns width , fix width of table too*/
  }
}
/deep/.nat-table-header {
  background-color: #2196f3;
  color: #ffffff;
  text-align: center !important;
  /deep/.nat-table-col-no {
    text-align: center !important;
  }
  /deep/.nat-table-col-action {
    text-align: center !important;
  }
  /deep/.nat-table-col-created_name {
    text-align: center !important;
  }
}
/deep/.nat-table-col-no {
  text-align: left;
  width: 20px;
}
/deep/.nat-table-col-file_name {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
/deep/.nat-table-col-created {
  text-align: center;
  width: 130px;
}
/deep/.nat-table-col-updated {
  text-align: center;
  width: 130px;
}
/deep/.nat-table-col-category {
  text-align: center;
  width: 150px;
}
/deep/.nat-table-col-action {
  width: 100px;
  text-align: center !important;
}
/deep/.nat-table-col-customer-id {
  width: 350px;
  text-align: center;
}
/deep/.nat-table-col-file_name {
  width: 150px;
}
/deep/.nat-table-col-customer-name {
  width: 350px;
  text-align: center;
}
/deep/.nat-table-col-title {
  width: 200px;
  text-align: center;
}
/deep/.nat-table-col-flatform {
  width: 70px;
  text-align: center;
}
/deep/.nat-table-col-channel-name {
  width: 150px;
  text-align: center;
}
/deep/.nat-table-col-created_time {
  text-align: center;
  width: 150px;
}
/deep/td.nat-table-col-size {
  text-align: right;
}
/deep/.nat-table-col-size {
  width: 120px;
}
/deep/.nat-table-col-created_name {
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
  width: 150px;
}
#filter-file:focus {
  border-color: none !important;
}
.icon-file {
  margin-right: 5px;
}

.d-inline-block {
  margin-right: 0.5rem;
}

.custom-color-text {
  /deep/.el-progress-bar__innerText {
    color: #8c8c8c;
  }
}
.isDisabledLinked {
  cursor: not-allowed;
}
</style>
