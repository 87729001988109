<template>
  <b-modal
    id="confirmModal"
    ref="confirmModal"
    :visible="value"
    @change="v => $emit('input', v)"
    :title="$t('src.modules.chat.components.ChatBox.chat-header.confirmation')"
    @hidden="$emit('hidden')"
  >
    <p class="my-2" v-html="title" />
    <div slot="modal-footer" class="w-100">
      <b-btn
        v-if="supportWithUserConsent && !isStoryConversation"
        class="float-right"
        variant="primary"
        size="sm"
        @click="$emit('support')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.without_users_agreement'
          )
        }}
      </b-btn>
      <b-btn
        v-if="isStoryConversation"
        class="float-right"
        variant="primary"
        size="sm"
        style="margin-left: 7px"
        @click="$emit('support')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.support'
          )
        }}
      </b-btn>
      <b-btn
        v-if="!isStoryConversation"
        :size="supportWithUserConsent ? 'sm' : ''"
        class="float-right"
        variant="primary"
        @click="$emit('yes', type)"
      >
        {{
          supportWithUserConsent
            ? $t(
                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.with_users_agreement'
              )
            : $t('common.confirmModal.yes')
        }}
      </b-btn>
      <b-btn
        :size="supportWithUserConsent || isStoryConversation ? 'sm' : ''"
        class="float-right"
        variant="default"
        style="margin-right: 7px"
        @click="$emit('no', type)"
      >
        {{
          supportWithUserConsent || isStoryConversation
            ? $t('common.confirmModal.cancel')
            : $t('common.confirmModal.no')
        }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    enableRequestUser: {
      type: Boolean,
      default: true
    },
    channelHasBot: {
      type: Boolean,
      default: false
    },
    isStoryConversation: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    supportWithUserConsent() {
      return (
        (this.type === 'support-user' || (this.type === 're-support' && this.channelHasBot)) &&
        this.enableRequestUser
      );
    }
  }
};
</script>
