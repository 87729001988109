import {
  SET_USER,
  UPDATE_USER,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  SET_CHANNEL_LIST,
  SET_CONVERSATIONS,
  ADD_NEW_CONVERSATION,
  UPDATE_CONVERSATION_LATEST_MSG,
  UPDATE_CONVERSATION_LOCAL,
  ADD_PERSON,
  UPDATE_PERSON,
  LOAD_MORE_CONVERSATION,
  ADD_TRANSLATIONS,
  SET_WAITING_MODE,
  SET_PROJECT_MAPS,
  ADD_TICKET,
  SET_TICKETS,
  REMOVE_TICKETS,
  SET_LOADMORE_TIME,
  SET_LOADING_MORE,
  SET_TICKETS_SEARCH,
  SET_TICKETS_SEARCH_CLEAR,
  SET_COMMON_WAITING_COUNT,
  SET_ME_WAITING_COUNT,
  SET_BOT_HANDLE_COUNT,
  SET_AGENT_HANDLE_COUNT,
  REMOVE_TICKET_SEARCH,
  SET_LAST_RUN_TIME,
  SET_LAST_REFRESH_TOKEN,
  SET_PROJECT_TAG_MAP,
  ADD_NEW_TAG,
  DELETE_TAG,
  ADD_NEW_TICKET_TAG,
  ADD_TAG_TO_TICKET,
  DELETE_TICKET_TAG,
  SET_PROJECT_LABEL_MAP,
  SET_LDAP_LIST,
  DELETE_LABEL,
  ADD_NEW_USER_LABEL,
  ADD_LABEL_TO_USER,
  UPDATE_WC_READ,
  UPDATE_LC_READ,
  SET_READY,
  SET_REFRESHING,
  SET_SYNC_TICKET,
  CHANGE_MAINTENANCE_MODE,
  SET_CHAT_SEARCH_BOX_FILTER,
  ADD_USER_LABEL_TO_PROJECT_LABEL_MAP,
  UPDATE_CONVERSATION_UN_FOLLOW,
  UPDATE_TICKET_UN_FOLLOW,
  UPDATE_COMMON_WAITING_COUNT,
  UPDATE_ME_WAITING_COUNT,
  UPDATE_AGENT_HANDLE_COUNT,
  UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET,

  //====Notifications for agent====
  HAS_NEW_NOTIFICATION_CAME,
  NEW_NOTIFICATIONS_CAME,
  DISPLAY_NOTIFY_SCREEN,
  NUMBER_NOTIFICATIONS,
  NUMBER_UNREAD_MESSAGES,
  UNREAD_MESSAGES_TICKETIDS,
  NOTIFICATIONS_MAP,
  LAST_NOTIFY_CAME,
  DELETE_NOTIFICATION,
  FIRST_TIME_IN_NOTIFICATIONS,
  LAST_TIME_IN_NOTIFICATIONS,
  //====Notifications for agent====
  SET_TICKET_ES_COUNT,
  HAS_UPDATE_TICKET_COME,
  CHANNEL_TAB_RELOAD,
  CHANNEL_TAB_PAGE,
  SET_COMMON_WAITING_LOADED_COUNT,
  SET_ME_WAITING_LOADED_COUNT,
  REMOVE_OLD_TICKET,
  GO_TO_TICKET_COME,
  GO_TO_TICKET,
  SELECTED_CATEGORY,
  SELECTED_CHANNEL,
  SET_MONITORING_COUNT,

  // New Button
  SET_TICKET_COME_NEW_MAP,
  CLEAR_TICKET_COME_NEW_MAP,
  SYNC_USER_LABEL_COLOR,
  SYNC_CONVERSATION_LABEL_COLOR,
  UPDATE_STATUS_REGISTERED
} from './types';
import * as getters from './getters';
import * as actions from './actions';
import {
  TABS,
  TICKET_SEARCH_TYPE,
  TICKET_STATE,
  TICKET_STATUS,
  TICKET_TYPE_CODE
} from 'core/constants';
import { getTabIndex, isMatchSearchCondition } from 'core/helpers';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';

const state = {
  user: {},
  token: localStorage.getItem('user-token') || '',
  status: '',
  ready: true,
  hasLoadedOnce: false,
  channels: [],
  channelsMap: {},
  conversations: [],

  // Noted:
  // ID: { id: String, name: String, pictureUrl: String, platform: [facebook,line,...] }
  // if person is end-user:
  // ---> ID: `${userId}_${channelId}`
  //
  // if person is agent:
  // ---> ID: agentId
  //
  // Ex:
  // {
  //    [`${userId1}_${channelId1}`]: {id: String, name: String},
  //    [`${userId1}_${channelId2}`]: {id: String, name: String},
  //    [`${agentId1}`]: {id: String, name: String},
  //    [`${agentId2}`]: {id: String, name: String}
  // }
  people: {},
  //-------------------------------------------------------------------------------------//

  waitingMode: 'COMMON', // COMMON/TOME
  groups: {},
  projectMaps: {},
  tickets: {},
  loadMoreTimeMaps: {}, // { projectId: { status: lastTime }}
  loadPagingTimeMaps: {}, // { projectId: { status: lastTime }}
  loadingMoreMaps: {}, // { channelId: true/false }
  waitingCountsMap: {},
  ticketCountBySearchChannelMap: {},
  ticketGroupCountBySearchChannelMap: {},
  ticketsBySearchChannelMap: {},
  ticketsNextFromChannelMap: {},
  commonWaitingCountsMap: {},
  commonWaitingLoadedCountsMap: {},
  meWaitingCountsMap: {},
  meWaitingLoadedCountsMap: {},
  botHandleCountsMap: {},
  agentHandleCountsMap: {},
  ticketsBySearch: [],
  lastRunTime: 0,
  projectTagMaps: {},
  ldapList: [],
  users: {},
  projectLabelMaps: {},
  wcReadMap: {}, // Read map by conversation Id
  lcReadMap: {},
  isRefreshing: false,
  chatSearchBoxFilter: {},
  syncTicketsMap: {}, // Map by channelId+status
  agentWorkAtHome: false,
  instantSettings: localStorage.getItem('instant-settings'),
  notifyMaps: {},

  //====Notifications for agent====
  hasNewNotificationCame: false,
  newNotificationsCame: [],
  displayNotifyScreen: false,
  numberNotifications: 0,
  numberUnreadMessages: 0,
  unreadMessagesTicketIds: [],
  notificationsMap: {},
  lastNotifyCame: {},
  firstTimeInNotifications: '',
  lastTimeInNotifications: '',
  //====Notifications for agent====
  ticketESCount: {},
  hasUpdateTicketCome: {},
  goToTicketCome: {},
  goToTicket: {},
  channelTabReload: {},
  channelTabPage: {},
  selectedCategoryChannelMap: {},
  monitoringCountsMap: {},

  //---- Users Labels ----
  usersLabelsMNCondition: {},

  //---- Conversations Labels ----
  convsLabelMNCondition: {},
  selectedChannel: '',

  // New Button
  ticketComeNewMap: {},

  // ticketIds already loaded
  loadedTickets: new Set()
};

const mutations = {
  [CHANGE_MAINTENANCE_MODE](state, { projectId, data }) {
    if (!projectId) return false;
    let { channels } = state;
    let error = false;
    try {
      const { mode = '' } = (data && JSON.parse(data)) || {};
      channels = channels.map(channel => {
        if (projectId === channel.projectId) channel.mode = mode;
        return channel;
      });
    } catch (_) {
      error = true;
    }

    if (error) return false;
    state.channels = [...channels];
    state.channelsMap = channels.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  // ===== Agent info ====
  [SET_USER](state, user) {
    const { isWorkFromHome = false } = user || {};
    state.agentWorkAtHome = isWorkFromHome;
    state.user = user || {};
  },

  [UPDATE_USER](state, newInfo) {
    const { user = {} } = state;
    const { isWorkFromHome = false } = newInfo || {};
    state.agentWorkAtHome = isWorkFromHome;
    state.user = { ...user, ...newInfo };
  },
  // ===== Agent info ====

  [AUTH_REQUEST]: state => {
    state.status = 'loading';
  },

  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.token = resp.token;
    state.hasLoadedOnce = true;
    state.ready = false;
  },

  [AUTH_ERROR]: state => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },

  [AUTH_LOGOUT]: state => {
    state.token = '';
  },

  [SET_CHANNEL_LIST](state, data) {
    data = data.filter(channel => {
      const advancedConfigs = (channel && channel.advancedConfigs) || {};
      const modulesActivation = (advancedConfigs && advancedConfigs.modulesActivation) || {};
      const { agentTerminal = true } = modulesActivation;
      return agentTerminal;
    });
    const channels = data || [];
    state.channels.splice(0);
    state.channels = [...channels];
    state.channelsMap = channels.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [ADD_PERSON](state, { personId, person }) {
    if (!personId) return null;

    const { people } = state;
    people[personId] = { ...person, userPlatformName: person.userPlatformName || person.name };
    state.people = { ...people };
  },

  [UPDATE_PERSON](state, { personId, person }) {
    const { people } = state;
    const currentPerson = people[personId] || null;
    if (!currentPerson) return null;
    people[personId] = { ...currentPerson, ...person };
    state.people = { ...people };
  },

  [SET_CONVERSATIONS](state, conversations) {
    if (!conversations) return;
    const { conversations: oldConvs } = state;
    conversations.forEach(c => {
      let i = findIndex(oldConvs, { id: c.id });
      if (i >= 0) {
        const oc = oldConvs[i];
        oldConvs.splice(i, 1, { ...oc, ...c });
      } else {
        oldConvs.push(c);
      }
    });
    state.conversations = oldConvs;

    const newPeople = conversations.reduce(
      (people, { channelId, userId, userInfo, agentId, agentInfo = {} }) => ({
        ...people,
        [`${userId}_${channelId}`]: {
          id: userId,
          platform: state.channelsMap[channelId].platform,
          ...userInfo,
          userPlatformName: userInfo.userPlatformName || userInfo.name
        },
        ...(agentId &&
          !isEmpty(agentInfo) && {
            [agentId]: {
              id: agentId,
              name: `${agentInfo.firstName || ''} ${agentInfo.lastName || ''}`,
              platform: 'agent',
              ...agentInfo
            }
          })
      }),
      {}
    );

    state.people = {
      ...state.people,
      ...newPeople
    };
  },

  [ADD_NEW_CONVERSATION](state, conv) {
    const { conversations, tickets } = state;
    const { id, ticket } = conv;

    // If conversation exists ==> do nothing
    const index = conversations.findIndex(c => c.id === id);
    if (index >= 0) {
      const extConv = conversations[index];
      if (extConv.latestTime >= conv.latestTime) return;

      conversations.splice(index, 1, { ...extConv, ...conv });
      state.conversations = [...conversations];
      if (ticket && ticket.id) {
        if (!tickets[ticket.id]) {
          state.tickets = { ...tickets, [ticket.id]: ticket };
        }
      }
    } else {
      conversations.push(conv);
      state.conversations = [...conversations];
      if (ticket && ticket.id) {
        if (!tickets[ticket.id]) {
          state.tickets = { ...tickets, [ticket.id]: ticket };
        }
      }
    }
  },

  [UPDATE_STATUS_REGISTERED](state, data) {
    const { conversations = [] } = state || {};
    const { conversationId, registeredInfo = [] } = data || {};

    let newConvs = conversations.map(val => {
      if (val && val.id === conversationId) {
        let { userInfo = {} } = val;
        userInfo.registeredInfo = registeredInfo;
        return val;
      }
      return val;
    });
    state.conversations = [...newConvs];
  },

  [UPDATE_CONVERSATION_LATEST_MSG](state, msg) {
    const { conversationId } = msg || {};
    const { conversations } = state;
    const idx = conversations.findIndex(conv => conv.id === conversationId);
    if (idx >= 0) {
      const conv = conversations[idx];
      state.conversations.splice(idx, 1, { ...conv, message: { ...msg } });
    }
  },

  [UPDATE_CONVERSATION_LOCAL](state, conversation) {
    if (conversation && typeof conversation.userInfo === 'string') {
      try {
        conversation.userInfo = JSON.parse(conversation.userInfo);
      } catch (error) {
        // eslint-disable-next-line
        console.log('conversation.userInfo >> error: ', error);
        conversation.userInfo = {};
      }
    }
    const { id: conversationId } = conversation;
    const { conversations } = state;

    const idx = conversations.findIndex(c => c.id === conversationId);
    if (idx < 0) state.conversations.push(conversation);
    else {
      let oldConv = conversations[idx];
      if (!oldConv.userInfo || typeof oldConv.userInfo === 'string') {
        oldConv.userInfo = {};
      }
      const { registeredInfo = [] } = oldConv.userInfo;
      oldConv.userInfo = { ...oldConv.userInfo, ...(conversation.userInfo || {}), registeredInfo };
      state.conversations.splice(idx, 1, oldConv);
    }
  },

  [LOAD_MORE_CONVERSATION](state, { conversations: convList }) {
    const { conversations } = state;
    let idx = 0;
    convList.forEach(nc => {
      idx = findIndex(conversations, { id: nc.id });
      if (idx >= 0) {
        const oc = conversations[idx];
        conversations.splice(idx, 1, { ...oc, ...nc });
      } else {
        conversations.push(nc);
      }
    });
    state.conversations = conversations;
  },

  [ADD_TRANSLATIONS](state, conversation) {
    const { channelsMap } = state;
    if (!conversation || !conversation.channelId) return;
    const { translations, defaultLanguage } = channelsMap[conversation.channelId];
    if (!translations) {
      conversation.translations = translations;
      state.conversations = state.conversations.slice(0);
    } else {
      const { locale } = conversation.userInfo;
      const tlt = Object.keys(translations).reduce(function(result, key) {
        if (typeof translations[key] === 'object' && translations[key][locale]) {
          result[key] = translations[key][locale];
          return result;
        }
        if (!defaultLanguage) return undefined;

        result[key] = translations[key][defaultLanguage];
        return result;
      }, {});
      conversation.translations = tlt;
      state.conversations = state.conversations.slice(0);
    }
  },

  [UPDATE_CONVERSATION_UN_FOLLOW](state, conversations) {
    state.conversations = conversations;
  },

  [UPDATE_TICKET_UN_FOLLOW](state, tickets) {
    state.tickets = tickets;
  },

  [SET_WAITING_MODE](state, mode) {
    state.waitingMode = mode;
  },

  [SET_PROJECT_MAPS](state, projectMaps = {}) {
    state.projectMaps = projectMaps;
  },

  [UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET](state, message) {
    const { tickets = {} } = state;
    const { ticketId } = message;

    if (isEmpty(tickets)) return null;
    tickets[ticketId] ? (tickets[ticketId]['lastMessageContent'] = message) : null;

    state.tickets = { ...tickets };
  },

  [ADD_TICKET](
    state,
    {
      selectedTab,
      ticket,
      grpNavTabIndex,
      isSelectedTicket,
      isAgetHasPermission = false,
      isChangeTab = false,
      isComingTicket = false
    }
  ) {
    if (ticket) {
      const {
        tickets = {},
        conversations = {},
        hasUpdateTicketCome = {},
        chatSearchBoxFilter
      } = state;
      const { id, projectId, agentId, channelId } = ticket;
      const oldTicket = tickets[id] || {};
      const conversation = conversations.find(con => con.id === ticket.conversationId);
      let userInfo = {};
      if (conversation) {
        userInfo = conversation.userInfo;
      }
      tickets[id] = { ...oldTicket, ...ticket };

      // Case oldTicket  exist grpNavTabIndex but ticketCome is not exist
      if (grpNavTabIndex === TABS.ME || grpNavTabIndex === TABS.MONITORING || isSelectedTicket) {
        if (
          Object.keys(oldTicket).length > 0 &&
          oldTicket.hasOwnProperty('grpNavTabIndex') &&
          Object.keys(ticket).length > 0 &&
          !ticket.hasOwnProperty('grpNavTabIndex')
        ) {
          //eslint-disable-next-line
          console.log(
            'addTicketLocal -> grpNavTabIndex > deletegrpNavTabIndex2',
            JSON.stringify(tickets[id])
          );
          delete tickets[id]['grpNavTabIndex'];
        }
      }
      state.tickets = { ...tickets };

      const oldCount = state.ticketCountBySearchChannelMap;
      let ticketCount = oldCount[channelId];
      const { tags = [], ticketsTags = [] } = state.projectTagMaps[projectId] || {};
      const { agents } = state.projectMaps[projectId];
      const ticketTagsFilter = ticketsTags.filter(it => it.ticketId === id);
      // When have search condtion
      if (
        isMatchSearchCondition(chatSearchBoxFilter, {
          ticket,
          tags,
          ticketsTags: ticketTagsFilter,
          userInfo,
          agent: agents[agentId]
        })
      ) {
        if (selectedTab === TABS.ME) {
          state.tickets[id] = { ...oldTicket, ...ticket, search: 1, isSearch: 1 };
        }

        if (isChangeTab && grpNavTabIndex !== TABS.BOT && selectedTab === TABS.MONITORING) {
          if ((!isEmpty(oldTicket) && !oldTicket.search) || isEmpty(oldTicket)) {
            hasUpdateTicketCome[`${channelId}${TICKET_TYPE_CODE.SEARCH}`] = true;
          }
        }

        if (isComingTicket) {
          if (
            grpNavTabIndex !== TABS.BOT &&
            grpNavTabIndex !== TABS.ME &&
            selectedTab !== TABS.MONITORING &&
            ((!isEmpty(oldTicket) && !oldTicket.search) || isEmpty(oldTicket))
          ) {
            hasUpdateTicketCome[`${channelId}${TICKET_TYPE_CODE.SEARCH}`] = true;
          }

          if (grpNavTabIndex === TABS.ME) {
            ticketCount += 1;
          }
        } else if (isChangeTab && !isEmpty(oldTicket) && selectedTab !== TABS.MONITORING) {
          delete oldTicket.isSearch;
          delete oldTicket.search;
          state.tickets[id] = { ...oldTicket, ...ticket };
          ticketCount -= 1;
        }

        if (ticket.deleted || !isAgetHasPermission || grpNavTabIndex === -1) {
          ticketCount -= 1;
        }
      } else if (oldTicket && oldTicket.search) {
        delete oldTicket.isSearch;
        delete oldTicket.search;
        state.tickets[id] = { ...oldTicket, ...ticket };
        ticketCount -= 1;
      }

      if (ticket.deleted) {
        delete state.tickets[ticket.id];
      }

      state.ticketCountBySearchChannelMap[ticket.channelId] = ticketCount > 0 ? ticketCount : 0;
      state.ticketGroupCountBySearchChannelMap[ticket.channelId] =
        ticketCount > 0 ? ticketCount : 0;
    }
  },

  [SET_TICKETS](state, { projectId, channelId, tickets, page, status, grpNavTabIndex }) {
    try {
      let { tickets: curTickets = {} } = state;
      let allTicket = {};
      if (status !== 3) {
        Object.keys(curTickets).map(ticketId => {
          const curTicket = curTickets[ticketId];
          if (curTicket.projectId === projectId && curTicket.channelId === channelId) {
            if (!curTicket.hasOwnProperty('page')) {
              delete curTickets[ticketId];
            } else if (curTicket.hasOwnProperty('page')) {
              if (curTicket.page.length == 1) {
                if (
                  curTicket.page[0].page !== page &&
                  curTicket.page[0].grpNavTabIndex === grpNavTabIndex
                ) {
                  delete curTickets[ticketId];
                }
              }
              if (curTicket.page.length == 2) {
                const findNotExist = curTicket.page.find(
                  item => item.grpNavTabIndex !== grpNavTabIndex
                );
                const findExist = curTicket.page.find(
                  item => item.grpNavTabIndex === grpNavTabIndex
                );
                if (findNotExist && findExist) {
                  curTicket.page = [findNotExist];
                  curTicket.grpNavTabIndex = findNotExist.grpNavTabIndex;
                } else {
                  //eslint-disable-next-line
                  window.console.log(
                    '[C+ Debug] [WARNING] >>> SET_TICKETS >> Not found  grpNavTabIndex > curTicket',
                    curTicket
                  );
                  //eslint-disable-next-line
                  window.console.log(
                    '[C+ Debug] [WARNING] >>> SET_TICKETS >> Not found  grpNavTabIndex > grpNavTabIndex',
                    grpNavTabIndex
                  );
                }
              }
              if (curTicket.page.length > 2 || curTicket.page.length < 1) {
                //eslint-disable-next-line
                window.console.log(
                  '[C+ Debug] [WARNING] >>> SET_TICKETS >> Page more > ticket',
                  curTickets[ticketId]
                );
              }
            }
          }
        });
      }

      Object.keys(tickets).map(ticketId => {
        let newTicket = cloneDeep(tickets[ticketId]) || {};
        const curTicket = curTickets[ticketId] || {};
        if (Object.keys(curTicket).length > 0) {
          if (
            (((curTicket.hasOwnProperty('grpNavTabIndex') && curTicket['grpNavTabIndex'] == 4) ||
              !curTicket.hasOwnProperty('grpNavTabIndex')) &&
              newTicket.hasOwnProperty('grpNavTabIndex') &&
              newTicket['grpNavTabIndex'] !== 4) ||
            (newTicket.hasOwnProperty('grpNavTabIndex') &&
              newTicket['grpNavTabIndex'] == 4 &&
              ((curTicket.hasOwnProperty('grpNavTabIndex') && curTicket['grpNavTabIndex'] !== 4) ||
                !curTicket.hasOwnProperty('grpNavTabIndex')))
          ) {
            const { user } = state;
            const tabIndex = getTabIndex({ conv: { ticket: newTicket }, user });
            if (tabIndex !== TABS.MONITORING) {
              if (newTicket.hasOwnProperty('page') && curTicket.hasOwnProperty('page')) {
                const newPage = [...newTicket.page, ...curTicket.page];
                newTicket.page = uniqBy(newPage, 'grpNavTabIndex');
              }
              delete newTicket['grpNavTabIndex'];
            }
          }
          if (
            curTicket.hasOwnProperty('page') &&
            (curTicket.page.length > 2 || curTicket.page.length < 1)
          ) {
            //eslint-disable-next-line
            window.console.log(
              '[C+ Debug] [WARNING] >>> SET_TICKETS2 >> Page more > ticket',
              curTicket
            );
          }
        }
        allTicket = { ...allTicket, [ticketId]: newTicket };
      });
      state.tickets = { ...curTickets, ...allTicket };
      state.loadedTickets = new Set(Object.keys(state.tickets));
    } catch (error) {
      //eslint-disable-next-line
      window.console.log('[C+ Debug] [Error] >>> SET_TICKETS > error ', error);
    }
  },

  [SET_TICKETS_SEARCH](
    state,
    { tickets, channelId, nextFrom, cntAllTicket, conditions, cntGroupTicket }
  ) {
    for (const prop in tickets) {
      const ticket = tickets[prop];
      ticket.labels = ticket.tags;
    }
    let { tickets: curTickets, people } = state;

    let curTicketsCheckSearch = {};
    Object.values(curTickets).forEach(ticket => {
      if (tickets[ticket.id]) {
        tickets[ticket.id].page = curTickets[ticket.id].page;
        ticket.search = TICKET_SEARCH_TYPE.CURRENT_TICKET_SEARCH;
        curTickets[ticket.id] = ticket;
      } else {
        curTicketsCheckSearch = { ...curTicketsCheckSearch, ...{ [ticket.id]: ticket } };
      }
    });

    if (Object.keys(conditions).length > 0) {
      Object.values(curTicketsCheckSearch).map(ticket => {
        let cntCondition = 0;
        let valuesCondition = [];
        if (conditions.agentName && conditions.agentName.length > 0) {
          cntCondition++;
          valuesCondition.push(ticket.agentName === conditions.agentName[0]);
        }
        if (conditions.follow && conditions.follow.length > 0) {
          cntCondition++;
          if (ticket.feature && typeof ticket.feature === 'boolean') {
            valuesCondition.push(ticket.feature === conditions.follow[0]);
          } else if (typeof ticket.feature === 'string') {
            valuesCondition.push(JSON.parse(ticket.feature) === conditions.follow[0]);
          }
        }
        if (conditions.state && conditions.state.length > 0) {
          cntCondition++;
          valuesCondition.push(ticket.state && ticket.state === conditions.state[0]);
        }
        if (conditions.status && conditions.status.length > 0) {
          cntCondition++;
          valuesCondition.push(ticket.status && ticket.status === conditions.status[0]);
        }
        if (conditions.userLocal && conditions.userLocal.length > 0) {
          cntCondition++;
          if (ticket.userData && typeof ticket.userData.locale) {
            const filters = conditions.userLocal.filter(language => {
              return language === ticket.userData.locale;
            });
            valuesCondition.push(filters.length > 0);
          }
        }

        if (conditions.userName && conditions.userName.length > 0) {
          cntCondition++;
          const { userId, channelId } = ticket || {};
          if (
            people[`${userId}_${channelId}`] &&
            people[`${userId}_${channelId}`].channelId &&
            people[`${userId}_${channelId}`].channelId === channelId &&
            people[`${userId}_${channelId}`].name
          ) {
            const filters = conditions.userName.filter(name => {
              return (
                people[`${userId}_${channelId}`].name.toLowerCase().indexOf(name.toLowerCase()) >= 0
              );
            });
            valuesCondition.push(filters.length > 0);
          } else {
            if (ticket.userData) {
              const filters = conditions.userName.filter(name => {
                return name.toLowerCase().indexOf(ticket.userData.name.toLowerCase()) >= 0;
              });
              valuesCondition.push(filters.length > 0);
            }
          }
        }

        if (conditions.tagName && conditions.tagName.length > 0) {
          cntCondition++;
          if (ticket.tags) {
            let tagObj = [];
            if (typeof ticket.tags === 'string') {
              tagObj = JSON.parse(ticket.tags);
            } else {
              tagObj = ticket.tags;
            }
            const tagsValueString = tagObj.map(item => item.value).join('||');
            const filters = conditions.tagName.filter(tag => {
              return tag.toLowerCase().indexOf(tagsValueString.toLowerCase()) >= 0;
            });
            valuesCondition.push(filters.length > 0);
          }
        }

        if (valuesCondition.filter(value => value).length >= cntCondition) {
          ticket.isSearch = TICKET_SEARCH_TYPE.CURRENT_TICKET_SEARCH;
          curTickets[ticket.id] = ticket;
        }
        return ticket;
      });
    }

    state.tickets = { ...curTickets, ...tickets };
    const oldDataNextFrom = state.ticketsNextFromChannelMap;
    state.ticketsNextFromChannelMap = {
      ...oldDataNextFrom,
      [channelId]: nextFrom
    };

    const oldDataCount = state.ticketCountBySearchChannelMap;
    state.ticketCountBySearchChannelMap = {
      ...oldDataCount,
      [channelId]: cntAllTicket
    };

    const oldDataGroupCount = state.ticketGroupCountBySearchChannelMap;
    state.ticketGroupCountBySearchChannelMap = {
      ...oldDataGroupCount,
      [channelId]: cntGroupTicket
    };
  },

  [SET_TICKETS_SEARCH_CLEAR](state, { channelId, selectedGroupConv }) {
    let { tickets, loadedTickets, user } = state;
    if (channelId) {
      state.ticketsNextFromChannelMap[channelId] = 0;
      state.ticketCountBySearchChannelMap[channelId] = 0;

      state.tickets = Object.values(tickets).reduce((tks, tk) => {
        if (tk.search === TICKET_SEARCH_TYPE.TICKET_SEARCH) {
          if (tk.channelId === channelId && !loadedTickets.has(tk.id)) {
            return tks;
          } else {
            tks[tk.id] = { ...tk };
            return tks;
          }
        }

        tks[tk.id] = { ...tk, search: TICKET_SEARCH_TYPE.CURRENT_TICKET };
        return tks;
      }, {});
    } else {
      state.ticketsNextFromChannelMap = {};
      state.ticketCountBySearchChannelMap = {};
      state.tickets = Object.values(tickets).reduce((tks, tk) => {
        if (tk.search === TICKET_SEARCH_TYPE.TICKET_SEARCH && !loadedTickets.has(tk.id)) {
          return tks;
        }

        let tabIndex = getTabIndex({ conv: { ticket: tk }, user });
        const { page = [] } = tk;
        if (
          selectedGroupConv === TABS.MONITORING &&
          findIndex(page, i => i.grpNavTabIndex === TABS.MONITORING) !== -1
        ) {
          tabIndex = TABS.MONITORING;
        } else if (page.length === 1) {
          tabIndex = page[0].grpNavTabIndex;
        }

        tks[tk.id] = { ...tk, grpNavTabIndex: tabIndex, search: TICKET_SEARCH_TYPE.CURRENT_TICKET };
        return tks;
      }, {});
    }
  },

  [REMOVE_TICKETS](state, { tickets = [], grpNavTabIndex }) {
    let { tickets: curTickets } = state;
    Object.values(tickets).forEach(
      ticket => {
        const curTicket = curTickets[ticket.id];
        const ticketId = ticket.id;
        if (!curTicket.hasOwnProperty('grpNavTabIndex')) {
          if (curTicket.hasOwnProperty('page') && curTicket.page.length > 1) {
            const findNotExist = curTicket.page.find(
              item => item.grpNavTabIndex !== grpNavTabIndex
            );
            const findExist = curTicket.page.find(item => item.grpNavTabIndex);
            if (findNotExist && findExist) {
              curTickets[ticketId].page = [findNotExist];
              curTickets[ticketId].grpNavTabIndex = findNotExist.grpNavTabIndex;
            }
          } else {
            // eslint-disable-next-line
            console.log(
              '[C+ Debug] [WARNING] >>> REMOVE_TICKETS > REMOVE_OLD_TICKET >> Not exist grpNavTabIndex but page <= 1  > curTicket',
              curTicket
            );
          }
        } else {
          // eslint-disable-next-line
          console.log(
            '[C+ Debug] [WARNING] >>> REMOVE_TICKETS > REMOVE_OLD_TICKET >> > curTicket',
            ticketId,
            cloneDeep(curTicket)
          );
          if (curTicket.grpNavTabIndex === grpNavTabIndex && curTicket.page.length <= 1) {
            delete curTickets[ticketId];
          }
        }
      }
    );
    state.tickets = { ...curTickets };
  },

  [REMOVE_OLD_TICKET](state, { ticketId, grpNavTabIndex, forceDelete }) {
    let { tickets: curTickets } = state;
    const curTicket = curTickets[ticketId];
    if (curTicket) {
      if (grpNavTabIndex === -1) {
        // eslint-disable-next-line
        console.log('[C+ Debug] [WARNING] >>> REMOVE_OLD_TICKET >> > curTicket', {
          curTicket,
          grpNavTabIndex
        });
        delete curTickets[ticketId];
      } else {
        if (!curTicket.hasOwnProperty('grpNavTabIndex')) {
          if (curTicket.hasOwnProperty('page') && curTicket.page.length > 1) {
            const find = curTicket.page.find(item => item.grpNavTabIndex != grpNavTabIndex);
            if (find) {
              curTickets[ticketId].page = [find];
              curTickets[ticketId].grpNavTabIndex = find.grpNavTabIndex;
            }
          } else {
            // eslint-disable-next-line
            console.log(
              '[C+ Debug] [WARNING] >>> REMOVE_OLD_TICKET >> Not exist grpNavTabIndex but page <= 1  > curTicket',
              curTicket
            );
          }
        } else {
          // eslint-disable-next-line
          if (forceDelete || curTicket.grpNavTabIndex !== TABS.MONITORING) {
            console.log('[C+ Debug] [WARNING] >>> REMOVE_OLD_TICKET >> > curTicket', curTicket);
            delete curTickets[ticketId];
          }
        }
      }
    }
    state.tickets = { ...curTickets };
  },

  [SET_LOADMORE_TIME](
    state,
    { projectId, status, updatedAt, oldUpdatedAt, page, pagingDirection, currentTime, oldPage }
  ) {
    const loadMoreTimes = state.loadMoreTimeMaps;
    const project = loadMoreTimes[projectId] || {};
    project[status] = updatedAt;
    if (pagingDirection > 0) {
      if (state.loadPagingTimeMaps[projectId] && state.loadPagingTimeMaps[projectId][status]) {
        state.loadPagingTimeMaps[projectId][status] = {
          ...state.loadPagingTimeMaps[projectId][status],
          ...{ [page + '|' + '1']: updatedAt }
        };
        if (currentTime) {
          state.loadPagingTimeMaps[projectId][status] = {
            ...state.loadPagingTimeMaps[projectId][status],
            ...{ [oldPage + '|' + '-1']: currentTime }
          };
        }
        state.loadPagingTimeMaps[projectId][status] = {
          ...state.loadPagingTimeMaps[projectId][status],
          ...{ [page + '|' + '-1']: oldUpdatedAt }
        };
      } else {
        if (currentTime) {
          state.loadPagingTimeMaps[projectId][status] = {
            ...state.loadPagingTimeMaps[projectId][status],
            ...{ [oldPage + '|' + '-1']: currentTime }
          };
        }
        state.loadPagingTimeMaps = {
          ...state.loadPagingTimeMaps,
          ...{
            [projectId]: {
              [status]: {
                [page + '|' + '1']: updatedAt,
                [page + '|' + '-1']: oldUpdatedAt
              }
            }
          }
        };
      }
    }

    state.loadMoreTimeMaps = { ...loadMoreTimes, [projectId]: project };
  },

  [SET_LOADING_MORE](state, { channelStatus, loading }) {
    const loadingMoreMaps = state.loadingMoreMaps;
    if (loadingMoreMaps[channelStatus] === loading) return;
    loadingMoreMaps[channelStatus] = loading;
    state.loadingMoreMaps = { ...loadingMoreMaps };
  },

  [SET_TICKET_ES_COUNT](state, { channelStatus, ticketESCount }) {
    const _ticketESCount = state.ticketESCount;
    _ticketESCount[channelStatus] = ticketESCount;
    state.ticketESCount = { ..._ticketESCount };
  },

  [SET_COMMON_WAITING_COUNT](state, { channelId, count }) {
    const { commonWaitingCountsMap } = state;
    commonWaitingCountsMap[channelId] = count;
    state.commonWaitingCountsMap = { ...commonWaitingCountsMap };
  },
  [SET_ME_WAITING_COUNT](state, { channelId, count }) {
    state.meWaitingCountsMap[channelId] = count;
    state.meWaitingCountsMap = { ...state.meWaitingCountsMap };
  },
  [SET_BOT_HANDLE_COUNT](state, { channelId, count }) {
    state.botHandleCountsMap[channelId] = count;
    state.botHandleCountsMap = { ...state.botHandleCountsMap };
  },
  [SET_AGENT_HANDLE_COUNT](state, { channelId, count }) {
    state.agentHandleCountsMap[channelId] = count;
    state.agentHandleCountsMap = { ...state.agentHandleCountsMap };
  },

  [UPDATE_AGENT_HANDLE_COUNT](state, { channelId, count }) {
    state.agentHandleCountsMap[channelId] = count;
    state.agentHandleCountsMap = { ...state.agentHandleCountsMap };
  },
  [UPDATE_COMMON_WAITING_COUNT](state, { channelId, count }) {
    state.commonWaitingCountsMap[channelId] = count;
    state.commonWaitingCountsMap = { ...state.commonWaitingCountsMap };
  },
  [SET_COMMON_WAITING_LOADED_COUNT](state, { channelId, count }) {
    state.commonWaitingLoadedCountsMap[channelId] = count;
    state.commonWaitingLoadedCountsMap = { ...state.commonWaitingLoadedCountsMap };
  },
  [SET_ME_WAITING_LOADED_COUNT](state, { channelId, count }) {
    state.meWaitingLoadedCountsMap[channelId] = count;
    state.meWaitingLoadedCountsMap = { ...state.meWaitingLoadedCountsMap };
  },
  [UPDATE_ME_WAITING_COUNT](state, { channelId, count }) {
    state.meWaitingCountsMap[channelId] = count;
    state.meWaitingCountsMap = { ...state.meWaitingCountsMap };
  },

  [SET_MONITORING_COUNT](state, { channelId, count }) {
    state.monitoringCountsMap[channelId] = count;
    state.monitoringCountsMap = { ...state.monitoringCountsMap };
  },

  [REMOVE_TICKET_SEARCH](state, ticket) {
    const { channelId, id } = ticket;
    let oldData = [...state.ticketsBySearchChannelMap[channelId]];
    var filtered = oldData.filter(item => {
      return item.ticketId != id;
    });
    state.ticketsBySearchChannelMap[channelId] = filtered.slice(0);
  },

  [SET_LAST_RUN_TIME](state, lastTime) {
    state.lastRunTime = lastTime;
  },

  [SET_LAST_REFRESH_TOKEN](state, lastTime) {
    state.lastRefreshToken = lastTime;
  },

  [SET_PROJECT_TAG_MAP](state, projectTagMaps) {
    state.projectTagMaps = { ...projectTagMaps };
  },

  [SET_PROJECT_LABEL_MAP](state, projectLabelMaps) {
    state.projectLabelMaps = { ...projectLabelMaps };
  },
  [ADD_USER_LABEL_TO_PROJECT_LABEL_MAP](state, { projectId, userLabels }) {
    if (state.projectLabelMaps[projectId] && state.projectLabelMaps[projectId].usersLabels) {
      state.projectLabelMaps[projectId].usersLabels = userLabels.slice(0);
    }
  },

  [ADD_NEW_TAG](state, { projectId, newTag }) {
    newTag.projectId = projectId;
    const { id } = newTag;
    const { tags = [], ticketsTags = [] } = state.projectTagMaps[projectId];
    const index = tags.findIndex(t => t.id === id);

    if (index < 0) {
      tags.push(newTag);
    } else {
      tags.splice(index, 1, newTag);
    }
    state.projectTagMaps = {
      ...state.projectTagMaps,
      [projectId]: { tags: [...tags], ticketsTags }
    };
  },

  [ADD_NEW_TICKET_TAG](state, { projectId, ticketTag }) {
    const { tagId, ticketId } = ticketTag || {};
    const { tags = [], ticketsTags = [] } = state.projectTagMaps[projectId];

    if (
      !ticketsTags.some(ticketTag => ticketTag.tagId === tagId && ticketTag.ticketId === ticketId)
    ) {
      ticketsTags.push(ticketTag);
      state.projectTagMaps = {
        ...state.projectTagMaps,
        [projectId]: { tags, ticketsTags }
      };
    }
  },

  [ADD_TAG_TO_TICKET](state, { ticketTag, tag }) {
    const { tickets } = state;
    const { ticketId } = ticketTag || {};
    const ticket = tickets[ticketId];
    if (ticket) {
      let tags = [];
      try {
        tags = JSON.parse(ticket.tags);
      } catch (_) {
        tags = [];
      }
      if (!tags.some(t => t.id === tag.id)) {
        tags.push(tag);
      }
      ticket.tags = JSON.stringify(tags);
      state.tickets = { ...tickets, [ticket.id]: ticket };
    }
  },

  [DELETE_TAG](state, { projectId, newTag }) {
    const { id } = newTag;
    const { tags = [], ticketsTags = [] } = state.projectTagMaps[projectId];
    const index = tags.findIndex(tag => tag.id === id);
    if (index >= 0) {
      tags.splice(index, 1);
      state.projectTagMaps = {
        ...state.projectTagMaps,
        [projectId]: { tags, ticketsTags }
      };
    }
  },

  [DELETE_TICKET_TAG](state, { projectId, ticketTag }) {
    const { tagId, ticketId } = ticketTag || {};
    const { tags = [], ticketsTags = [] } = state.projectTagMaps[projectId];
    const index = ticketsTags.findIndex(
      ticketTag => ticketTag.tagId === tagId && ticketTag.ticketId === ticketId
    );
    if (index >= 0) {
      ticketsTags.splice(index, 1);
      state.projectTagMaps = {
        ...state.projectTagMaps,
        [projectId]: { tags, ticketsTags }
      };
    }
  },

  [SET_CHAT_SEARCH_BOX_FILTER](state, params) {
    state.chatSearchBoxFilter = params;
  },

  [ADD_NEW_USER_LABEL](state, { projectId, userLabel }) {
    const { labelId, userIdProjectId } = userLabel || {};
    const { labels = [], usersLabels = [] } = state.projectLabelMaps[projectId];
    if (
      !usersLabels.some(
        userLabel => userLabel.labelId === labelId && userLabel.userIdProjectId === userIdProjectId
      )
    ) {
      usersLabels.push(userLabel);
      state.projectLabelMaps = {
        ...state.projectLabelMaps,
        [projectId]: { labels, usersLabels }
      };
    }
  },

  [ADD_LABEL_TO_USER](state, { userLabel, label }) {
    const { users } = state;
    const { userId } = userLabel || {};
    const user = users[userId];
    if (user) {
      let labels = [];
      try {
        labels = JSON.parse(user.labels);
      } catch (_) {
        labels = [];
      }
      if (!labels.some(l => l.id === label.id)) {
        labels.push(label);
      }
      user.labels = JSON.stringify(labels);
      state.users = { ...users, [user.id]: user };
    }
  },

  [DELETE_LABEL](state, { projectId, newLabel }) {
    const { id } = newLabel;
    const { labels = [], usersLabels = [] } = state.projectLabelMaps[projectId];
    const index = labels.findIndex(label => label.id === id);
    if (index >= 0) {
      labels.splice(index, 1);
      state.projectLabelMaps = {
        ...state.projectLabelMaps,
        [projectId]: { labels, usersLabels }
      };
    }
  },

  [SET_LDAP_LIST](state, ldapList) {
    state.ldapList = ldapList;
  },

  [UPDATE_WC_READ](state, { conversationId, mid }) {
    state.wcReadMap[conversationId] = mid;
  },

  [UPDATE_LC_READ](state, { conversationId, mid }) {
    state.lcReadMap[conversationId] = mid;
  },

  [SET_READY](state) {
    if (Object.keys(state.channelsMap).length > 0 && Object.keys(state.projectMaps).length > 0)
      state.ready = true;
  },

  [SET_REFRESHING](state, isRefreshing) {
    state.isRefreshing = isRefreshing;
  },

  [SET_SYNC_TICKET](state, { channelId = '', status = 0, syncing = false }) {
    state.syncTicketsMap[channelId + status] = syncing;
  },

  [HAS_UPDATE_TICKET_COME](state, { channelStatus, flag }) {
    let { hasUpdateTicketCome } = state;
    if (hasUpdateTicketCome[channelStatus]) {
      hasUpdateTicketCome[channelStatus] = flag;
    } else {
      hasUpdateTicketCome = { ...hasUpdateTicketCome, ...{ [channelStatus]: flag } };
    }
    state.hasUpdateTicketCome = { ...hasUpdateTicketCome };
  },
  [GO_TO_TICKET_COME](state, { channelStatus, flag }) {
    let { goToTicketCome } = state;
    if (goToTicketCome[channelStatus]) {
      goToTicketCome[channelStatus] = flag;
    } else {
      goToTicketCome = { ...goToTicketCome, ...{ [channelStatus]: flag } };
    }
    state.goToTicketCome = { ...goToTicketCome };
  },
  [GO_TO_TICKET](state, { channelStatus, flag }) {
    let { goToTicket } = state;
    if (goToTicket[channelStatus]) {
      goToTicket[channelStatus] = flag;
    } else {
      goToTicket = { ...goToTicket, ...{ [channelStatus]: flag } };
    }
    state.goToTicket = { ...goToTicket };
  },
  [SELECTED_CATEGORY](state, { channelStatus, selectedCategoryId }) {
    let { selectedCategoryChannelMap } = state;
    if (selectedCategoryChannelMap[channelStatus]) {
      selectedCategoryChannelMap[channelStatus] = selectedCategoryId;
    } else {
      selectedCategoryChannelMap = {
        ...selectedCategoryChannelMap,
        ...{ [channelStatus]: selectedCategoryId }
      };
    }
    state.selectedCategoryChannelMap = { ...selectedCategoryChannelMap };
  },

  [SELECTED_CHANNEL](state, channelId) {
    state.selectedChannel = channelId;
  },

  [CHANNEL_TAB_RELOAD](state, { channelStatus, flag }) {
    let { channelTabReload } = state;
    if (channelTabReload[channelStatus]) {
      channelTabReload[channelStatus] = flag;
    } else {
      channelTabReload = { ...channelTabReload, ...{ [channelStatus]: flag } };
    }
    state.channelTabReload = { ...channelTabReload };
  },

  [CHANNEL_TAB_PAGE](state, { channelStatus, page }) {
    let { channelTabPage } = state;
    if (channelTabPage[channelStatus]) {
      channelTabPage[channelStatus] = page;
    } else {
      channelTabPage = { ...channelTabPage, ...{ [channelStatus]: page } };
    }
    state.channelTabPage = { ...channelTabPage };
  },

  //================Notifications for agent================
  [HAS_NEW_NOTIFICATION_CAME](state, boolean) {
    state.hasNewNotificationCame = boolean;
  },
  [NEW_NOTIFICATIONS_CAME](state, ticketIds) {
    if (!ticketIds.length) state.hasNewNotificationCame = false;
    state.newNotificationsCame = ticketIds;
  },
  [DISPLAY_NOTIFY_SCREEN](state, boolean) {
    state.displayNotifyScreen = boolean;
  },
  [NUMBER_NOTIFICATIONS](state, numberNotifications) {
    state.numberNotifications = numberNotifications;
  },
  [NUMBER_UNREAD_MESSAGES](state, numberUnreadMessages) {
    state.numberUnreadMessages = numberUnreadMessages;
  },
  [UNREAD_MESSAGES_TICKETIDS](state, unreadMessagesTicketIds) {
    state.unreadMessagesTicketIds = unreadMessagesTicketIds || [];
  },
  [NOTIFICATIONS_MAP](state, { notifications, keepItems, refresh }) {
    const { notificationsMap } = state;

    notifications = notifications.reduce((acc, i) => ({ ...acc, [i.ticket.id]: i }), {});
    if (refresh || keepItems) {
      state.notificationsMap = { ...notifications };
      const length = (state.notificationsMap && Object.keys(state.notificationsMap).length) || 0;
      !length ? (state.numberNotifications = 0) : null;
      length && state.numberNotifications < length ? (state.numberNotifications = length) : null;
    } else state.notificationsMap = { ...notificationsMap, ...notifications };
  },
  [DELETE_NOTIFICATION](state, ticketId) {
    const { notificationsMap } = state;
    delete notificationsMap[ticketId];
    state.notificationsMap = { ...notificationsMap };
  },
  [FIRST_TIME_IN_NOTIFICATIONS](state, firstTimeInNotifications) {
    state.firstTimeInNotifications = firstTimeInNotifications;
  },
  [LAST_TIME_IN_NOTIFICATIONS](state, lastTimeInNotifications) {
    state.lastTimeInNotifications = lastTimeInNotifications;
  },
  [LAST_NOTIFY_CAME](state, lastNotifyCame) {
    state.lastNotifyCame = lastNotifyCame;
  },
  //================Notifications for agent================

  //
  [SET_TICKET_COME_NEW_MAP](state, obj) {
    const { channelId, ticketId, tabIndex } = obj;

    const newTickets = state.ticketComeNewMap;
    const COMMON_KEY = `${channelId}${TICKET_TYPE_CODE.COMMON}`;
    const TOME_KEY = `${channelId}${TICKET_TYPE_CODE.TOME}`;
    const MONITORING_KEY = `${channelId}${TICKET_TYPE_CODE.MONITORING}`;
    const newMonitoringStatus = [
      TICKET_STATUS.REQUEST_USER,
      TICKET_STATUS.NO_SUPPORT,
      TICKET_STATUS.USER_MENTION
    ];
    const newMonitoringState = [TICKET_STATE.REQUEST, TICKET_STATE.COMPLETE, TICKET_STATE.REFER];

    if (ticketId) {
      const newTicketsInCommon = newTickets[COMMON_KEY] || new Set([]);
      const newTicketsInTome = newTickets[TOME_KEY] || new Set([]);
      const newTicketsInMonitoring = newTickets[MONITORING_KEY] || new Set([]);

      // Clear ticket when it don't belong a tab.
      newTicketsInCommon.delete(ticketId);
      newTicketsInTome.delete(ticketId);

      switch (tabIndex) {
        case TABS.WAITING_COMMON:
          newTicketsInCommon.add(ticketId);
          newTicketsInMonitoring.add(ticketId);
          break;
        case TABS.WAITING_ME:
          newTicketsInTome.add(ticketId);
          newTicketsInMonitoring.add(ticketId);
          break;
        case TABS.ME:
          newTicketsInMonitoring.add(ticketId);
          break;
        case TABS.MONITORING:
          newTicketsInMonitoring.add(ticketId);
          break;
        default:
          newTicketsInMonitoring.delete(ticketId);
      }

      if (newMonitoringState.includes(obj.state) && newMonitoringStatus.includes(obj.status)) {
        newTicketsInMonitoring.add(ticketId);
      }

      state.ticketComeNewMap = {
        ...newTickets,
        [COMMON_KEY]: newTicketsInCommon,
        [TOME_KEY]: newTicketsInTome,
        [MONITORING_KEY]: newTicketsInMonitoring
      };
    }
  },

  [CLEAR_TICKET_COME_NEW_MAP](state, { channelStatus }) {
    if (!channelStatus) return;

    const oldData = state.ticketComeNewMap;
    oldData[channelStatus] = new Set([]);
    state.ticketComeNewMap = { ...oldData };
  },

  [SYNC_USER_LABEL_COLOR](state, { newLabels, userId = null }) {
    const newConversations = [];
    state.conversations.forEach(con => {
      if (!con.userInfo.labels || !Array.isArray(con.userInfo.labels)) {
        con.userInfo.labels = [];
      }
      if (con.userInfo.labels) {
        if (userId && userId === con.userInfo.id) {
          con.userInfo.labels = newLabels;
        } else {
          newLabels.forEach(newLabel => {
            const { value, type, id } = newLabel;
            con.userInfo.labels = con.userInfo.labels.map(label => ({
              ...label,
              type: label.id === id ? type : label.type,
              value: label.id === id ? value : label.value
            }));
          });
        }
      }
      newConversations.push(con);
    });
    state.conversations = newConversations;
  },

  [SYNC_CONVERSATION_LABEL_COLOR](state, { projectId, newLabels, ticketId = null, updatedAt }) {
    for (const tic in state.tickets) {
      const ticket = state.tickets[tic];
      ticket.updatedAt = updatedAt;
      if (!ticket.labels || !Array.isArray(ticket.labels)) {
        ticket.labels = [];
      }
      if (ticket.labels && projectId === ticket.projectId) {
        if (ticketId && ticketId === ticket.id) {
          ticket.labels = newLabels;
        } else {
          newLabels.forEach(newLabel => {
            const { value, type, id } = newLabel;
            ticket.labels = ticket.labels.map(label => ({
              ...label,
              type: label.id === id ? type : label.type,
              value: label.id === id ? value : label.value
            }));
          });
        }
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
