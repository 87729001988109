<template>
  <b-modal
    id="MaintenanceModeModal"
    ref="maintenanceModeModal"
    v-model="show"
    :title="$t('src.core.App.warning')"
    @hidden="onCloseModel"
    size="md"
  >
    <span>{{ $t('common.channel_is_in_maintenance') }}</span>
    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right" variant="default" @click="onCloseModel">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapState } from 'vuex';

function defaultState() {
  return {
    show: false
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('session', ['channelsMap'])
  },

  created() {
    EventBus.$on('maintenanceModeModal', () => this.handlerOpenPopup(true));
  },

  destroyed() {
    EventBus.$off('maintenanceModeModal', () => this.handlerOpenPopup(false));
  },

  methods: {
    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    onCloseModel() {
      this.resetAllState();
      this.show = false;
    },

    async handlerOpenPopup(bool) {
      this.show = bool;
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
