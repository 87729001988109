<template>
  <change-password-first-login v-if="needChangePassword" />
  <instant-setting-popup
    v-else-if="needShowInstantSettings"
    id="instantSettingPage"
    ref="instantSettingPage"
  />
  <main
    v-else
    :class="['app-message', 'position-relative', rightPanelPinClass]"
    :style="absoluteStyle"
  >
    <real-time />
    <vue-element-loading
      :active="!requireAuth && (!ready || !sessionReady || terminate)"
      :is-full-screen="true"
      :text-style="textStyle"
      spiner="line-scale"
    />
    <vue-element-loading
      :active="c_globalLoading"
      :is-full-screen="true"
      :text-style="textStyle"
      spiner="line-scale"
    />
    <notifications group="subscription" class="subs-new-message" style="display: none">
      <template slot="body" slot-scope="props">
        <div class="notification-wrapper" @click="handleOnNotificationClick(props)">
          <div class="notification vue-notification">
            <div class="media">
              <div class="p-10">
                <a href class="avatar avatar-online shadow" @click.prevent.stop>
                  <img :src="props.item.data.pictureUrl" alt class="img-fluid" />
                </a>
              </div>
              <div class="media-body">
                <div class="row">
                  <div class="col-12">
                    <h6 class="mt-0 mb-0">{{ props.item.title }}</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="notification-content">
                      {{ props.item.text | truncate(25) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <notifications group="test" />
    <router-view :key="$route.path" />

    <!-- Sorry! You are not assigned to any project -->

    <!-- Your access token is invalid. Please login again! -->

    <!-- require user reload page -->

    <!-- check maintenance mode -->
    <MaintenanceMode />

    <!-- Display Agent detail working -->
    <AgentWorkingDetails />
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import $ from 'jquery';
import { EventBus } from 'core/eventBus';
import SubscribeNewMessage from 'gql/SubscribeNewMessage.gql';
import SubscribeNewMessages from 'gql/SubscribeNewMessages.gql';
import SubscribeUpdateMessage from 'gql/SubscribeUpdateMessage.gql';
import SubscribeNewTag from 'gql/SubscribeNewTag.gql';
import SubscribeDeleteTicketsTags from 'gql/SubscribeDeleteTicketsTags.gql';
import SubscribeDeleteTag from 'gql/SubscribeDeleteTag.gql';
import SubscribeNewTicketsTags from 'gql/SubscribeNewTicketsTags.gql';
import SubscribeNewLabel from 'gql/SubscribeNewLabel.gql';
import SubscribeDeleteUsersLabels from 'gql/SubscribeDeleteUsersLabels.gql';
import SubscribeDeleteLabel from 'gql/SubscribeDeleteLabel.gql';
import SubscribeNewUsersLabels from 'gql/SubscribeNewUsersLabels.gql';
import SubscribeUpdateConversation from 'gql/SubscribeUpdateConversation.gql';
import SubscribeUpdateTicket from 'gql/SubscribeUpdateTicket.gql';
import SubscribeUpdateToken from 'gql/SubscribeUpdateToken.gql';
import SubscribeTyping from 'gql/SubscribeTyping.gql';
import SubscribeNotify from 'gql/SubscribeNotify.gql';
import SubscribeTicketCountByAgentId from 'gql/SubscribeTicketCountByAgentId.gql';
import SubscribeTicketCountByProjectId from 'gql/SubscribeTicketCountByProjectId.gql';
import SubscribeNotificationForAgent from 'gql/SubscribeNotificationForAgent.gql';
import SubscribeUpdateAgent from 'gql/SubscribeUpdateAgent.gql';
import SubscribeUpdatePersonalForAgent from 'gql/SubscribeUpdatePersonalForAgent.gql';
import {
  AGENT_ROLE,
  TICKET_STATUS,
  TICKET_STATE,
  AGENT_STATUS,
  END_USER_PLATFORM,
  THIRDPARTY_PLATFORM,
  TELEWORK
} from 'core/constants';

import { isRequireAuth } from 'core/helpers';
import RealTime from '../modules/global/RealTime.vue';
import ChangePasswordFirstLogin from 'modules/session/components/changePasswordFirstLogin';
import MaintenanceMode from 'modules/session/components/maintenanceMode';
import InstantSettingPopup from 'components/Modal/InstantSettingPopup';
import {
  isSupportServiceWorker,
  registerSW,
  unregisterSW,
  readySW,
  cameraCapture,
  desktopCapture
} from 'core/helpers';
import AgentWorkingDetails from 'modules/chat/components/OverviewBox/agentWorkingDetails';
import CookiesModel from 'core/cookies';
import moment from 'moment';

import 'assets/scss/base.scss';
import 'assets/scss/style.scss';
import promiseLimit from 'promise-limit';
import adapter from 'webrtc-adapter';

const limit = promiseLimit(10);

export default {
  name: 'App',

  components: {
    RealTime,
    ChangePasswordFirstLogin,
    MaintenanceMode,
    InstantSettingPopup,
    AgentWorkingDetails
  },
  data() {
    return {
      textStyle: {},
      absoluteStyle: {},
      subscribeReady: false,
      conversationId: null,
      ticketId: null,
      hydrated: false,
      conversationSubscriber: null,
      ticketSubscriber: null,
      messageSubscriber: null,
      messageUpdateSubscriber: null,
      tagSubscriber: null,
      ticketsTagsSubscriber: null,
      ticketsTagsDeleteSubscriber: null,
      tagsDeleteSubscriber: null,
      labelSubscriber: null,
      usersLabelsSubscriber: null,
      usersLabelsDeleteSubscriber: null,
      labelsDeleteSubscriber: null,
      notifySubscriber: null,
      ticketCountDataByAgentIdSubscriber: null,
      ticketCountDataByProjectIdSubscriber: {},
      notificationForAgentSubscriber: null,
      updateAgentSubscriber: null,
      updatePersonalForAgentSubscriber: null,
      time: 0,
      requireAuth: false,
      noAssignedProject: false,
      requireReloadPage: false,
      projectChangeSettingName: '',
      refreshTokenTime: new Date('2020-01-01').getTime(),
      desktopsCapture: []
    };
  },

  computed: {
    ...mapState('global', [
      'ready',
      'terminate',
      'rightPanelPinned',
      'rightProductPanelPinned',
      'rightPanelPinnedActive',
      'rightConversationPanelPinned',
      'rightCustomerSupportPanelPinned',
      'rightCustomerSurveyPanelPinned',
      'rightStatusHistoryPanelPinned',
      'globalLoadingMap'
    ]),
    ...mapState('session', [
      'user',
      'people',
      'channels',
      'channelsMap',
      'projectMaps',
      'lastRunTime',
      'agentWorkAtHome',
      'instantSettings'
    ]),
    ...mapState('session', { sessionReady: 'ready' }),
    ...mapState('chat', [
      'productFeeds',
      'conversationFeeds',
      'messages',
      'initState',
      'selectedGroupConv',
      'showView'
    ]),
    ...mapState('comment', ['selectedChannel']),
    ...mapGetters('global', ['isOffline']),
    ...mapGetters('chat', ['currentConversationHandlingBy', 'selectedConversation']),
    ...mapGetters('session', ['tickets', 'isAuthenticated', 'conversations', 'isPhoneAgent']),

    rightPanelPinClass() {
      switch (this.rightPanelPinnedActive) {
        case 'CONVERSATION':
          return this.rightConversationPanelPinned ? 'right-panel-pinned' : '';
        case 'PRODUCT':
          return this.rightProductPanelPinned ? 'right-panel-pinned' : '';
        case 'PANEL':
          return this.rightPanelPinned ? 'right-panel-pinned' : '';
        case 'CUSTOMERSUPPORT':
          return this.rightCustomerSupportPanelPinned ? 'right-panel-pinned' : '';
        case 'SURVEYFORM':
          return this.rightCustomerSurveyPanelPinned ? 'right-panel-pinned' : '';
        case 'WEBBROWSINGHISTORY':
          return this.rightWebBrowsingHistoryPanelPinned ? 'right-panel-pinned' : '';
        case 'STATUSHISTORY':
          return this.rightStatusHistoryPanelPinned ? 'right-panel-pinned' : '';
        default:
          return '';
      }
    },
    needChangePassword() {
      const { path } = this.$route;
      return (this.user.needChangePassword || false) && path !== '/login';
    },
    needShowInstantSettings() {
      const { path } = this.$route;
      const instantSettings = localStorage.getItem('instant-settings') || '';
      return (
        !instantSettings &&
        !this.noAssignedProject &&
        !this.requireAuth &&
        (['/login', '/forgot/password', '/adfs/ls', '/adfs/cb', '/change-password/wiki'].every(
          i => i !== path
        )
          ? true
          : false)
      );
    },

    c_globalLoading() {
      const arrKeyGlobalLoading = Object.keys(this.globalLoadingMap);
      if (arrKeyGlobalLoading === 0) return false;
      let loading = false;
      arrKeyGlobalLoading.map(actionKey => {
        if (this.globalLoadingMap[actionKey] === true) {
          loading = true;
        }
      });
      return loading;
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/profile/userInformation' || to.path === '/password') {
        this.absoluteStyle = {
          position: 'absolute !important',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto'
        };
      } else {
        this.absoluteStyle = {};
      }
      // react to route changes...
    },

    requireAuth(value) {
      if (value) {
        this.$baseConfirm({
          title: this.$t('src.core.App.warning'),
          message: this.$t('src.core.App.your_access_token_is_invalid_please_login_again'),
          closeOnPressEscape: false,
          closeOnClickModal: false,
          showClose: false,
          showCancelButton: false,
          confirmButtonText: this.$t('src.core.App.login'),
          iconClass: 'el-icon-warning'
        })
          .then(() => {
            this.goLogin();
          })
          .catch(() => {});
      }
    },

    isAuthenticated() {
      this.checkAuthStatus();
      return !this.requireAuth && this.initSession();
    },

    isOffline(newVal) {
      if (!newVal) {
        this.setupSubscriber();
      }
    },

    agentWorkAtHome(newValue) {
      if (!newValue) return 'Not work at home';
      if (
        this.$router &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path === '/report'
      ) {
        this.$router.push('/');
      }
      $(document).ready(function () {
        $('body').bind('cut copy paste drag drop selectstart contextmenu', function (e) {
          e.preventDefault();
        });
      });
    }
  },

  async created() {
    const email = localStorage.getItem('user-email');
    const hasSetup = localStorage.getItem('instant-settings');
    const permission = window.Notification.permission;
    const isSupportSW = await isSupportServiceWorker();
    if (isSupportSW && email && hasSetup === 'hasSetup') {
      await registerSW();
      if (permission === 'granted') {
        await readySW();
      }
    }
  },

  mounted() {
    window.addEventListener('blur', this.stopService, true);
    window.addEventListener('focus', this.startService, true);
    window.addEventListener('authStatusChange', this.checkAuthStatus);
    this.checkAuthStatus();
    this.time = new Date().getTime();

    if (!this.requireAuth) {
      this.initSession();
      this.startService();
    }
  },

  methods: {
    ...mapActions('chat', [
      'addNewMessage',
      'setReady',
      'setChatReady',
      'getProductFeedsByChannel',
      'getConversationFeedsByChannel',
      'setNotifyMessage',
      'setInitState',
      'updateMessage',
      'getConversationTicketById',
      'updateTyping',
      'getMessagesByConversationId',
      'isOverLimitStorage',
      'addNewLabel',
      'getSystemDefinedWord',
      'updateSelectedConvrs'
    ]),

    ...mapActions('global', ['pinRightPanel', 'setGlobalReady']),
    ...mapActions('comment', ['setSelectedChannel', 'setSelectedComment']),

    ...mapActions('session', [
      'getSessionUser',
      'getChannels',
      'loadMoreConversation',
      'addNewConversation',
      'updateConversationLatestMessage',
      'updateConversationLocal',
      'addAgentToPeople',
      'addTranslations',
      'getProjectMaps',
      'addTicketLocal',
      'updateLinks',
      'getCountAll',
      'getTicketCount',
      'refreshToken',
      'setLastRunTime',
      'addNewTag',
      'deleteTag',
      'addNewTicketTag',
      'deleteTicketTag',
      'deleteLabel',
      'addNewUserLabel',
      'deleteUserLabel',
      'handleNewNotifyCome',
      'countNotifications',
      'handleNotificationForAgent',
      'updateAgentStatus',
      'updateUser',
      'updateLastMsgForTicket',
      'updateLastMessagesForTicket',
      'updateTicketCountByAgentId',
      'updateTicketCountByProjectId'
    ]),

    async getLimitProjectStorage() {
      const projectMaps = await this.getProjectMaps();
      if (projectMaps && Object.keys(projectMaps).length) {
        let { storage } = await this.isOverLimitStorage({ projectIds: Object.keys(projectMaps) });
        if (storage && storage.length > 0) {
          return Promise.all(
            storage.map(item => {
              const {
                projectId,
                limit,
                volume,
                turnOnCamera,
                turnOnScreenShot,
                isUsingTeleWork,
                frequency
              } = item;

              const projectStorage = { projectId: projectId, isOverLimit: false };
              if (!isUsingTeleWork) {
                projectStorage.isOverLimit = true;
                return projectStorage;
              }
              projectStorage.frequency = frequency;
              let storaged = parseFloat((limit.value - volume) * TELEWORK.GB_TO_KB);
              if (limit && limit.unit === TELEWORK.UNIT.MB) {
                storaged = parseFloat((limit.value - volume) * TELEWORK.MB_TO_KB);
              }
              projectStorage.isOverLimit = storaged <= TELEWORK.MAXIMUM_STORAGE;
              if (turnOnCamera) projectStorage.turnOnCamera = turnOnCamera;
              if (turnOnScreenShot) projectStorage.turnOnScreenShot = turnOnScreenShot;
              return projectStorage;
            })
          );
        }
      }
      return Promise.resolve([]);
    },

    async captureEvidenceCamera() {
      try {
        const projects = await this.getLimitProjectStorage();
        if (!projects || !projects.length) return;
        const camerasCapture = projects.filter(
          project => project && project.turnOnCamera && project.isOverLimit === false
        );
        if (!camerasCapture || !camerasCapture.length) return;
        const agentId = this.user.id;

        camerasCapture.forEach(project => {
          const { projectId, frequency } = project;
          if (projectId && frequency) {
            let interval = typeof frequency === 'object' ? frequency.value : frequency;
            setInterval(() => cameraCapture(projectId, agentId), parseInt(interval) * 60000);
          }
        });
      } catch (error) {
        // eslint-disable-next-line
        console.error('captureEvidenceCamera error', error);
      }
    },

    async captureEvidenceDesktop() {
      try {
        const projects = await this.getLimitProjectStorage();
        if (!projects || !projects.length) return;
        this.desktopsCapture = projects.filter(
          project => project && project.turnOnScreenShot && project.isOverLimit === false
        );
        if (!this.desktopsCapture || !this.desktopsCapture.length) return;
        const browser = adapter.browserDetails.browser;

        if (['chrome', 'edge'].includes(browser)) {
          navigator.mediaDevices.getDisplayMedia({ video: true }).then(this.handleSuccess);
        }

        if (browser == 'firefox') {
          navigator.mediaDevices
            .getUserMedia({ video: { mediaSource: 'screen' } })
            .then(this.handleSuccess);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('captureEvidenceDesktop error', error);
      }
    },

    async handleSuccess(stream) {
      const agentId = this.user.id;

      this.desktopsCapture.forEach(project => {
        const { projectId, frequency } = project;
        if (projectId && frequency) {
          let interval = typeof frequency === 'object' ? frequency.value : frequency;
          desktopCapture(projectId, agentId, stream, interval);
        }
      });
    },

    checkAuthStatus() {
      if (!this.$route.meta.requiresAuth) {
        this.requireAuth = false;
        return;
      }
      this.requireAuth = isRequireAuth();
    },

    goLogin() {
      const cookies = new CookiesModel();
      cookies.clearCookies();
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-email');
      localStorage.removeItem('auth-status');
      localStorage.removeItem('instant-settings');
      delete window.loggedInUserEmail;
      unregisterSW();
      location.replace('/login');
    },

    goChangePassword() {
      location.replace('/password');
    },

    handleOnNotificationClick(props) {
      const { close, item } = props;
      const { conversationId, ticketId } = item.data || {};
      if (conversationId) {
        const { conversationId: self } = this.$route.params || {};
        if (conversationId != self) {
          setTimeout(() => {
            this.$router.push('/c/' + conversationId + '/' + ticketId);
            this.setNotifyMessage(item.data);
          }, 1000);
        } else {
          this.setNotifyMessage(item.data);
        }
      }
      close();
    },

    async initSession() {
      if (this.isAuthenticated) {
        await this.$apollo.provider.defaultClient.hydrated();
        this.hydrated = true;
        this.$apollo.provider.defaultClient.cache.reset();

        if (!this.initState) {
          localStorage.removeItem('notyetShowFinishTimeOutMessage');
          const user = await this.getSessionUser();
          if (!user) {
            this.setGlobalReady(true);
            return Promise.resolve(false);
          }
          if (user.needChangePassword) {
            this.setGlobalReady(true);
            return Promise.resolve(false);
          }

          const { assignedProjects } = user || {};
          if (assignedProjects && assignedProjects.length === 0) {
            this.setGlobalReady(true);
            this.noAssignedProject = true;
            return this.$baseConfirm({
              title: this.$t('src.core.App.warning'),
              message: this.$t('src.core.App.sorry_You_are_not_assigned_to_any_project'),
              closeOnPressEscape: false,
              closeOnClickModal: false,
              showClose: false,
              showCancelButton: false,
              confirmButtonText: this.$t('src.core.App.login'),
              iconClass: 'el-icon-warning'
            })
              .then(() => {
                this.goLogin();
              })
              .catch(() => {});
          } else {
            this.noAssignedProject = false;
            const instantSettings = localStorage.getItem('instant-settings') || '';
            if (!instantSettings) {
              this.setGlobalReady(true);
              return this.$refs['instantSettingPage'] && this.$refs['instantSettingPage'].load();
            }
          }

          await Promise.all([
            this.getProjectMaps(),
            this.getChannels(),
            !this.isPhoneAgent ? this.countNotifications(this.user.id) : null,
            !this.isPhoneAgent ? this.getSystemDefinedWord() : null
          ]);

          this.setupSubscriber();
          let asyncLoads = [];
          asyncLoads.push(
            Promise.all(
              Object.values(this.channelsMap).map(channel =>
                limit(() =>
                  Promise.all(
                    [3].map(status =>
                      this.loadMoreConversation({
                        projectId: channel.projectId,
                        channelId: channel.id,
                        limit: -1,
                        status,
                        page: 1
                      })
                    )
                  )
                )
              )
            )
          );
          !this.isPhoneAgent ? Promise.all(asyncLoads) : null;
          this.setGlobalReady(true);
          this.setInitState();
        } else {
          this.setupSubscriber();
        }
        const isSupportSW = await isSupportServiceWorker();
        const hasSetup = localStorage.getItem('instant-settings');
        if (
          isSupportSW &&
          hasSetup === 'hasSetup' &&
          this.user &&
          ![AGENT_ROLE.CLIENT, AGENT_ROLE.PHONE_AGENT].includes(this.user.role)
        ) {
          await this.captureEvidenceCamera();
          await this.captureEvidenceDesktop();
        }

        this.getTicketCount({ projectIds: Object.keys(this.projectMaps) });
        !this.isPhoneAgent
          ? Object.values(this.channelsMap).forEach(channel =>
              this.getCountAll({
                projectId: channel.projectId,
                channelId: channel.id
              })
            )
          : null;
      }

      this.setGlobalReady(true);
      return Promise.resolve(true);
    },

    setupSubscriber() {
      if (this.isOffline) return;
      const isClientAgent = this.user.role === AGENT_ROLE.CLIENT;
      if (this.channels && this.channels.length > 0 && !isClientAgent && !this.isPhoneAgent) {
        const channelIds = this.channels.map(channel => channel.id);
        this.subscribeUpdateConversation(channelIds);
        this.subscribeNewMessage(this.channels);
        this.subscribeUpdateMessage(this.channels);
        this.subscribeNewMessages(this.channels);
      }

      const projectIds = Object.keys(this.projectMaps);
      if (!isClientAgent && !this.isPhoneAgent) {
        this.subscribeUpdateTicket(projectIds);
        this.subscribeNewTag(projectIds);
        this.subscribeNewTicketsTags(projectIds);
        this.subscribeDeleteTag(projectIds);
        this.subscribeDeleteTicketsTags(projectIds);
      } else {
        unregisterSW();
      }

      const tid = localStorage.getItem('user-tid');
      if (!tid) this.requireAuth = true;
      else this.subscribeUpdateToken(tid);
      if (!isClientAgent && !this.isPhoneAgent) {
        this.subscribeTyping();
        this.subscribeNewLabel(projectIds);
        this.subscribeNewUsersLabels(projectIds);
        this.subscribeDeleteUsersLabels(projectIds);
        this.subscribeDeleteLabel(projectIds);
        this.subscribeNotify();
        this.subscribeTicketCountByAgentId(this.user.id);
        this.subscribeTicketCountByProjectId(projectIds);
      }
      this.subscribeNotificationForAgent(this.user.id);
      this.subscribeUpdateAgent(this.user.id);
      this.subscribeUpdatePersonalForAgent(this.user.id);
    },

    async subscribeNewMessage(channels) {
      if (!channels || this.messageSubscriber) return;
      const _this = this;
      const channelIds = channels.map(c => c.id);

      this.messageSubscriber = this.$apollo.subscribe({
        query: SubscribeNewMessage
      });

      await this.messageSubscriber.subscribe({
        next(nextObject) {
          const { data } = nextObject;
          const newMessage = (data && data.subscribeMessage) || {};

          const { channelId } = newMessage;
          if (channelIds.includes(channelId)) {
            _this.handleNewMessageCome(channelId, newMessage);
            _this.updateLastMsgForTicket({ message: newMessage, action: 'new' });
          }
        },
        error(error) {
          _this.$log('[MessageSubscriber] > error', error);
          if (_this.messageSubscriber && typeof _this.messageSubscriber.unsubscribe === 'function')
            _this.messageSubscriber.unsubscribe();
          _this.messageSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewMessage(channels);
        }
      });
    },

    async subscribeNewMessages(channels) {
      if (!channels || this.messagesSubscriber) return;
      const _this = this;
      const channelIds = channels.map(c => c.id);

      this.messagesSubscriber = this.$apollo.subscribe({
        query: SubscribeNewMessages
      });

      await this.messagesSubscriber.subscribe({
        next(nextObject) {
          const { data } = nextObject;
          const newMessages = (data && data.subscribeMessages) || {};

          const { channelId, data: messages } = newMessages;
          if (channelIds.includes(channelId)) {
            _this.handleNewMessagesCome(channelId, JSON.parse(messages));
            _this.updateLastMessagesForTicket({
              messages: JSON.parse(messages),
              action: 'new'
            });
          }
        },
        error(error) {
          _this.$log('[MessagesSubscriber] > error', error);
          if (
            _this.messagesSubscriber &&
            typeof _this.messagesSubscriber.unsubscribe === 'function'
          )
            _this.messagesSubscriber.unsubscribe();
          _this.messagesSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewMessages(channels);
        }
      });
    },

    async subscribeUpdateMessage(channels) {
      if (!channels) return;
      const _this = this;

      if (this.messageUpdateSubscriber) return;

      this.messageUpdateSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdateMessage
      });

      await this.messageUpdateSubscriber.subscribe({
        next({ data }) {
          const newMessage = (data && data.subscribeUpdateMessage) || {};

          _this.handleUpdateMessageCome(newMessage);
          _this.updateLastMsgForTicket({ message: newMessage, action: 'update' });
        },
        error(error) {
          _this.$log('[MessageSubscriber] > error', error);
          if (
            _this.messageUpdateSubscriber &&
            typeof _this.messageUpdateSubscriber.unsubscribe === 'function'
          )
            _this.messageUpdateSubscriber.unsubscribe();
          _this.messageUpdateSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeUpdateMessage(channels);
        }
      });
    },

    async subscribeNotify() {
      const _this = this;

      if (this.notifySubscriber) return;

      this.notifySubscriber = this.$apollo.subscribe({
        query: SubscribeNotify
      });

      return this.notifySubscriber.subscribe({
        next({ data }) {
          const newNotify = (data && data.subscribeNotify) || {};
          _this.handleNewNotifyCome(newNotify);
        },
        error(error) {
          _this.$log('[notifySubscriber] > error', error);
          if (_this.notifySubscriber && typeof _this.notifySubscriber.unsubscribe === 'function')
            _this.notifySubscriber.unsubscribe();
          _this.notifySubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNotify();
        }
      });
    },

    async subscribeTicketCountByAgentId(agentId) {
      const _this = this;

      if (this.ticketCountDataByAgentIdSubscriber) return;

      this.ticketCountDataByAgentIdSubscriber = this.$apollo.subscribe({
        query: SubscribeTicketCountByAgentId,
        variables: { agentId }
      });

      return this.ticketCountDataByAgentIdSubscriber.subscribe({
        next({ data }) {
          const ticketCountData = (data && data.subscribeTicketCount) || {};
          if (ticketCountData) {
            const { items } = ticketCountData || {};
            if (items) {
              const datas = JSON.parse(items);
              // eslint-disable-next-line
              console.debug('[subscribeTicketCountByAgentId] > data', datas.length);
              datas.map(item => _this.updateTicketCountByAgentId(item));
            }
          }
        },
        error(error) {
          // eslint-disable-next-line
          console.error('[subscribeTicketCountByAgentId] > error', error);
          if (
            _this.ticketCountDataByAgentIdSubscriber &&
            typeof _this.ticketCountDataByAgentIdSubscriber.unsubscribe === 'function'
          )
            _this.ticketCountDataByAgentIdSubscriber.unsubscribe();
          _this.ticketCountDataByAgentIdSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeTicketCountByAgentId();
        }
      });
    },

    async subscribeTicketCountByProjectId(projectIds) {
      const _this = this;
      projectIds.forEach(projectId => {
        this.ticketCountDataByProjectIdSubscriber[projectId] = this.$apollo.subscribe({
          query: SubscribeTicketCountByProjectId,
          variables: { projectId }
        });

        this.ticketCountDataByProjectIdSubscriber[projectId].subscribe({
          next({ data }) {
            const ticketCountData = (data && data.subscribeTicketCount) || {};
            if (ticketCountData) {
              const { items } = ticketCountData || {};
              if (items) {
                const datas = JSON.parse(items);
                // eslint-disable-next-line
                console.debug('[updateTicketCountByProjectId] > data', datas.length);
                datas.map(item => _this.updateTicketCountByProjectId(item));
              }
            }
          },
          error(error) {
            _this.$log('[SubscribeTicketCountByProjectId] > error', error);
            if (
              _this.ticketCountDataByProjectIdSubscriber[projectId] &&
              typeof _this.ticketCountDataByProjectIdSubscriber[projectId].unsubscribe ===
                'function'
            )
              _this.ticketCountDataByProjectIdSubscriber[projectId].unsubscribe();
            _this.ticketCountDataByProjectIdSubscriber[projectId] = null;
            if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
              return;
            _this.SubscribeTicketCountByProjectId(projectIds);
          }
        });
      });
    },

    async subscribeNotificationForAgent(agentId) {
      const _this = this;

      if (this.notificationForAgentSubscriber) return;

      this.notificationForAgentSubscriber = this.$apollo.subscribe({
        query: SubscribeNotificationForAgent,
        variables: { agentId }
      });

      return this.notificationForAgentSubscriber.subscribe({
        next({ data }) {
          const info = data && data.subscribeNotificationForAgent;
          const { action = '' } = info || {};
          action === 'ms-update-setting-and-publish' || action === 'ms-delete-project-or-channel'
            ? _this.checkWhetherReloadPage(info)
            : null;

          _this.handleNotificationForAgent(info);
        },
        error(error) {
          _this.$log('[notificationForAgentSubscriber] > error', error);
          if (
            _this.notificationForAgentSubscriber &&
            typeof _this.notificationForAgentSubscriber.unsubscribe === 'function'
          )
            _this.notificationForAgentSubscriber.unsubscribe();
          _this.notificationForAgentSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNotificationForAgent(agentId);
        }
      });
    },

    async subscribeUpdateAgent(agentId) {
      const _this = this;

      if (this.updateAgentSubscriber) return;

      this.updateAgentSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdateAgent,
        variables: { id: agentId }
      });

      return this.updateAgentSubscriber.subscribe({
        next({ data }) {
          const record = (data && data.subscribeUpdateAgent) || {};
          let newInfor = null;
          try {
            newInfor = JSON.parse(record.data);
          } catch (_) {
            newInfor = null;
          }
          newInfor ? _this.updateUser(newInfor) : null;
        },
        error(error) {
          _this.$log('[updateAgentSubscriber] > error', error);
          if (
            _this.updateAgentSubscriber &&
            typeof _this.updateAgentSubscriber.unsubscribe === 'function'
          )
            _this.updateAgentSubscriber.unsubscribe();
          _this.updateAgentSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeUpdateAgent(agentId);
        }
      });
    },

    async subscribeUpdatePersonalForAgent(agentId) {
      const _this = this;

      if (this.updatePersonalForAgentSubscriber) return;

      this.updatePersonalForAgentSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdatePersonalForAgent,
        variables: { agentId }
      });

      return this.updatePersonalForAgentSubscriber.subscribe({
        next({ data }) {
          const record = (data && data.subscribeUpdatePersonalForAgent) || {};
          let personal = null;
          try {
            const { Items = [] } = JSON.parse(record.data) || {};
            personal = Items[0];
          } catch (_) {
            personal = null;
          }
          if (personal) {
            const { userId, channelId, registeredInfo = [] } = personal;
            EventBus.$emit('reloadUserRegistered', {
              userId,
              channelId,
              registeredInfo,
              key: 'REMOVED'
            });
            _this.updateSelectedConvrs(personal);
          }
        },
        error(error) {
          _this.$log('[updatePersonalForAgentSubscriber] > error', error);
          if (
            _this.updatePersonalForAgentSubscriber &&
            typeof _this.updatePersonalForAgentSubscriber.unsubscribe === 'function'
          )
            _this.updatePersonalForAgentSubscriber.unsubscribe();
          _this.updatePersonalForAgentSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeUpdatePersonalForAgent(agentId);
        }
      });
    },

    subscribeNewTag(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.tagSubscriber) return;

      this.tagSubscriber = this.$apollo.subscribe({
        query: SubscribeNewTag
      });

      return this.tagSubscriber.subscribe({
        next({ data }) {
          const newTag = (data && data.subscribeTag) || {};

          const { projectId } = newTag;
          if (projectIds.includes(projectId)) {
            _this.handleNewTagCome(projectId, newTag);
          }
        },
        error(error) {
          _this.$log('[tagSubscriber] > error', error);
          if (_this.tagSubscriber && typeof _this.tagSubscriber.unsubscribe === 'function')
            _this.tagSubscriber.unsubscribe();
          _this.tagSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewTag(projectIds);
        }
      });
    },

    subscribeNewTicketsTags(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.ticketsTagsSubscriber) return;

      this.ticketsTagsSubscriber = this.$apollo.subscribe({
        query: SubscribeNewTicketsTags
      });

      return this.ticketsTagsSubscriber.subscribe({
        next({ data }) {
          const newTicketsTags = (data && data.subscribeTicketsTags) || {};

          const { projectId } = newTicketsTags;
          if (projectIds.includes(projectId)) {
            // eslint-disable-next-line
            console.log(
              '[C+ Debug] [SUBSCRIBE] >>> subscribeNewTicketsTags >> next > newTicketsTags',
              newTicketsTags
            );
            _this.handleNewTicketsTagsCome(projectId, newTicketsTags);
          }
        },
        error(error) {
          _this.$log('[tagSubscriber] > error', error);
          if (
            _this.ticketsTagsSubscriber &&
            typeof _this.ticketsTagsSubscriber.unsubscribe === 'function'
          )
            _this.ticketsTagsSubscriber.unsubscribe();
          _this.ticketsTagsSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewTicketsTags(projectIds);
        }
      });
    },

    subscribeDeleteTicketsTags(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.ticketsTagsDeleteSubscriber) return;

      this.ticketsTagsDeleteSubscriber = this.$apollo.subscribe({
        query: SubscribeDeleteTicketsTags
      });

      return this.ticketsTagsDeleteSubscriber.subscribe({
        next({ data }) {
          const deleteTicketsTags = (data && data.subscribeDeleteTicketsTags) || {};

          const { projectId } = deleteTicketsTags;
          if (projectIds.includes(projectId)) {
            _this.handleDeleteTicketsTagsCome(projectId, deleteTicketsTags);
          }
        },
        error(error) {
          _this.$log('[subscribeNewTicketsTags] > error', error);
          if (
            _this.ticketsTagsDeleteSubscriber &&
            typeof _this.ticketsTagsDeleteSubscriber.unsubscribe === 'function'
          )
            _this.ticketsTagsDeleteSubscriber.unsubscribe();
          _this.ticketsTagsDeleteSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeDeleteTicketsTags(projectIds);
        }
      });
    },

    subscribeDeleteTag(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.tagDeleteSubscriber) return;
      this.tagDeleteSubscriber = this.$apollo.subscribe({
        query: SubscribeDeleteTag
      });

      return this.tagDeleteSubscriber.subscribe({
        next({ data }) {
          const deleteTag = (data && data.subscribeDeleteTag) || {};

          const { projectId } = deleteTag;
          if (projectIds.includes(projectId)) {
            _this.handleDeleteTagCome(projectId, deleteTag);
          }
        },
        error(error) {
          _this.$log('[tagDeleteSubscriber] > error', error);
          if (
            _this.tagDeleteSubscriber &&
            typeof _this.tagDeleteSubscriber.unsubscribe === 'function'
          )
            _this.tagDeleteSubscriber.unsubscribe();
          _this.tagDeleteSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeDeleteTag(projectIds);
        }
      });
    },

    subscribeNewLabel(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.labelSubscriber) return;

      this.labelSubscriber = this.$apollo.subscribe({
        query: SubscribeNewLabel
      });

      return this.labelSubscriber.subscribe({
        next({ data }) {
          const newLabel = (data && data.subscribeLabel) || {};

          const { projectId } = newLabel;
          if (projectIds.includes(projectId)) {
            _this.handleNewLabelCome(projectId, newLabel);
          }
        },
        error(error) {
          _this.$log('[labelSubscriber] > error', error);
          if (_this.labelSubscriber && typeof _this.labelSubscriber.unsubscribe === 'function')
            _this.labelSubscriber.unsubscribe();
          _this.labelSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewLabel(projectIds);
        }
      });
    },

    subscribeNewUsersLabels(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.usersLabelsSubscriber) return;

      this.usersLabelsSubscriber = this.$apollo.subscribe({
        query: SubscribeNewUsersLabels
      });

      return this.usersLabelsSubscriber.subscribe({
        next({ data }) {
          const newUsersLabels = (data && data.subscribeUsersLabels) || {};

          const { projectId } = newUsersLabels;
          if (projectIds.includes(projectId)) {
            _this.handleNewUsersLabelsCome(projectId, newUsersLabels);
          }
        },
        error(error) {
          _this.$log('[labelSubscriber] > error', error);
          if (
            _this.usersLabelsSubscriber &&
            typeof _this.usersLabelsSubscriber.unsubscribe === 'function'
          )
            _this.usersLabelsSubscriber.unsubscribe();
          _this.usersLabelsSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeNewUsersLabels(projectIds);
        }
      });
    },

    subscribeDeleteUsersLabels(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.usersLabelsDeleteSubscriber) return;

      this.usersLabelsDeleteSubscriber = this.$apollo.subscribe({
        query: SubscribeDeleteUsersLabels
      });

      return this.usersLabelsDeleteSubscriber.subscribe({
        next({ data }) {
          const deleteUsersLabels = (data && data.subscribeDeleteUsersLabels) || {};

          const { projectId } = deleteUsersLabels;
          if (projectIds.includes(projectId)) {
            _this.handleDeleteUsersLabelsCome(projectId, deleteUsersLabels);
          }
        },
        error(error) {
          _this.$log('[subscribeNewUsersLabels] > error', error);
          if (
            _this.usersLabelsDeleteSubscriber &&
            typeof _this.usersLabelsDeleteSubscriber.unsubscribe === 'function'
          )
            _this.usersLabelsDeleteSubscriber.unsubscribe();
          _this.usersLabelsDeleteSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeDeleteUsersLabels(projectIds);
        }
      });
    },

    subscribeDeleteLabel(projectIds) {
      if (!projectIds) return;
      const _this = this;

      if (this.labelDeleteSubscriber) return;
      this.labelDeleteSubscriber = this.$apollo.subscribe({
        query: SubscribeDeleteLabel
      });

      return this.labelDeleteSubscriber.subscribe({
        next({ data }) {
          const deleteLabel = (data && data.subscribeDeleteLabel) || {};

          const { projectId } = deleteLabel;
          if (projectIds.includes(projectId)) {
            _this.handleDeleteLabelCome(projectId, deleteLabel);
          }
        },
        error(error) {
          _this.$log('[labelDeleteSubscriber] > error', error);
          if (
            _this.labelDeleteSubscriber &&
            typeof _this.labelDeleteSubscriber.unsubscribe === 'function'
          )
            _this.labelDeleteSubscriber.unsubscribe();
          _this.labelDeleteSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeDeleteLabel(projectIds);
        }
      });
    },

    async subscribeUpdateConversation(channelIds) {
      const _this = this;
      if (this.conversationSubscriber) return;
      this.conversationSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdateConversation
      });
      await this.conversationSubscriber.subscribe({
        next({ data }) {
          const newConversation = (data && data.subscribeUpdateConversation) || {};
          if (newConversation && typeof newConversation.userInfo === 'string')
            newConversation.userInfo = JSON.parse(newConversation.userInfo);
          const { channelId } = newConversation;
          if (channelIds.includes(channelId))
            return _this.handleUpdateConversationCome(channelId, newConversation);
        },
        error(error) {
          _this.$log('[conversationSubscriber] > error', error);
          if (
            _this.conversationSubscriber &&
            typeof _this.conversationSubscriber.unsubscribe === 'function'
          )
            _this.conversationSubscriber.unsubscribe();
          _this.conversationSubscriber = null;
          if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
            return;
          _this.subscribeUpdateConversation(channelIds);
        }
      });
    },

    async subscribeUpdateTicket(projectIds) {
      const _this = this;
      if (this.ticketSubscriber) return;

      this.ticketSubscriber = this.$apollo.subscribe({
        query: SubscribeUpdateTicket
      });
      await this.ticketSubscriber.subscribe({
        next({ data }) {
          const newTicket = data && data.subscribeUpdateTicket;
          const { projectId } = newTicket;
          if (projectIds.includes(projectId)) {
            return _this.handleUpdateTicketCome(data && data.subscribeUpdateTicket);
          }
        },
        error(error) {
          try {
            if (_this.ticketSubscriber && typeof _this.ticketSubscriber.unsubscribe === 'function')
              _this.ticketSubscriber.unsubscribe();
            _this.ticketSubscriber = null;
            if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
              return;
            _this.subscribeUpdateTicket(projectIds);
          } catch (error) {
            // eslint-disable-next-line
            _this.$log('[ticketSubscriber] > [catch] > error', error);
          }
          // eslint-disable-next-line
          _this.$log('[ticketSubscriber] > error', error);
        }
      });
    },

    subscribeUpdateToken(tid) {
      const _this = this;
      const subscription = this.$apollo.subscribe({
        query: SubscribeUpdateToken,
        variables: {
          tid
        }
      });
      return subscription.subscribe({
        next({ data }) {
          return _this.handleUpdateTokenCome(data && data.onUpdateToken);
        },
        error(error) {
          try {
            if (subscription && typeof subscription.unsubscribe === 'function')
              subscription.unsubscribe();
            if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
              return;
            _this.subscribeUpdateToken(tid);
          } catch (error) {
            // eslint-disable-next-line
            _this.$log('[subscribeUpdateToken] > error', error);
          }
          // eslint-disable-next-line
          _this.$log('[subscribeUpdateToken] > error', error);
        }
      });
    },

    subscribeTyping() {
      const _this = this;
      const subscription = this.$apollo.subscribe({
        query: SubscribeTyping
      });
      return subscription.subscribe({
        next({ data } = {}) {
          if (data && data.subscribeTyping) {
            _this.updateTyping(data.subscribeTyping);
          }
        },
        error(error) {
          try {
            if (subscription && typeof subscription.unsubscribe === 'function')
              subscription.unsubscribe();
            if (error.errorMessage && error.errorMessage.startsWith('AMQJS0008I Socket closed'))
              return;
            _this.subscribeTyping();
          } catch (error) {
            // eslint-disable-next-line
            _this.$log('[subscribeTyping] > error', error);
          }
          // eslint-disable-next-line
          _this.$log('[subscribeTyping] > error', error);
        }
      });
    },

    handleNewConversationCome(channelId, conversation) {
      const _this = this;
      return this.addNewConversation(conversation)
        .then(({ error }) => {
          if (error) return;
        })
        .catch(error => {
          _this.$log('[handleNewConversationCome] > error', error);
        });
    },

    handleNewTagCome(projectId, newTag) {
      this.addNewTag({ projectId, newTag });
    },
    handleNewTicketsTagsCome(projectId, ticketTag) {
      this.addNewTicketTag({ projectId, ticketTag });
    },
    handleDeleteTagCome(projectId, newTag) {
      this.deleteTag({ projectId, newTag });
    },
    handleDeleteTicketsTagsCome(projectId, ticketTag) {
      this.deleteTicketTag({ projectId, ticketTag });
    },

    handleNewLabelCome(projectId, newLabel) {
      this.addNewLabel({ projectId, newLabel });
    },
    handleNewUsersLabelsCome(projectId, userLabel) {
      this.addNewUserLabel({ projectId, userLabel });
    },
    handleDeleteLabelCome(projectId, newLabel) {
      this.deleteLabel({ projectId, newLabel });
    },
    handleDeleteUsersLabelsCome(projectId, userLabel) {
      this.deleteUserLabel({ projectId, userLabel });
    },

    async handleUpdateConversationCome(channelId, conv) {
      if (!conv) return;
      const { agentId } = conv;

      // If exists conversation in store
      if (this.conversations.some(c => c.id === conv.id)) {
        this.updateConversationLocal(conv); // Update it
      } else {
        await this.addNewConversation(conv);
      }

      if (agentId && !this.people[agentId]) {
        await this.addAgentToPeople(agentId);
      }
    },

    async handleUpdateTicketCome(ticket) {
      const { id = '', status = '', state = '', assignee = '' } = ticket;
      const { conversationId = '', channelId = '' } = ticket;
      // eslint-disable-next-line
      this.$log('[C+ Debug] [NOTIFY] >>> handleUpdateTicketCome >> ticket >', {
        ticket,
        selectedConversation: this.selectedConversation
      });
      this.addTicketLocal({ ticket, event: 'UPDATE_TICKET_COME' });
      EventBus.$emit('ticketAlreadyUpdated', ticket);

      if (
        this.selectedConversation &&
        conversationId === this.selectedConversation.id &&
        id === this.selectedConversation.ticketId
      ) {
        if (assignee === this.user.id && status === TICKET_STATUS.FINISH_TIMEOUT) {
          if (state === TICKET_STATE.FINISH) {
            let notyetShowFTOM = localStorage.getItem('notyetShowFinishTimeOutMessage');
            notyetShowFTOM = notyetShowFTOM ? JSON.parse(notyetShowFTOM) : [];
            if (notyetShowFTOM.includes(ticket.id)) {
              notyetShowFTOM = notyetShowFTOM.filter(id => id !== ticket.id);
              localStorage.setItem(
                'notyetShowFinishTimeOutMessage',
                JSON.stringify(notyetShowFTOM)
              );
              const { advancedConfigs = {} } = this.channelsMap[channelId] || {};
              const { timeOut: { finish = {} } = {} } = advancedConfigs;
              const { timeOut, kickOutTime } = finish;
              this.$baseNotification.warning({
                title: this.$t('src.core.App.warning'),
                message: this.$t('src.core.App.message_finish_timeout', {
                  time: timeOut,
                  next_time: kickOutTime
                }),
                duration: 5000
              });
            }
          } else {
            this.$baseNotification.info({
              title: this.$t('src.core.App.info'),
              message: this.$t('src.core.App.message_finish_kickout')
            });
          }
        }
      }
    },

    /**
     *
     * @param {String} channel id
     * @param {Object} message
     */
    async handleNewMessageCome(_, message) {
      if (!message) return;
      const { conversationId: cId, platform, sender, isBot } = message;

      // if message is exists in store, then return;
      if (this.messages[cId] && this.messages[cId].some(msg => msg.id === message.id)) return;

      const { id: selectedConvId } = this.selectedConversation || {};
      const { id: userId } = this.user;

      if (sender === userId && selectedConvId === cId && !isBot) return;
      if (cId === selectedConvId) {
        this.addNewMessage(message);
      }

      // update Conversation Latest Message
      this.updateConversationLatestMessage(message);
      // Only notify when message come from platform (facebook, line, ...).
      if (!this.isEndUser(platform)) return;
      // Don't notify message when bot is handling.
      if (isBot && this.isThirdparty(platform)) return;
    },

    async handleNewMessagesCome(channelId, messages) {
      if (messages && messages.length > 0) {
        for (let message of messages) {
          if (message && !message.createdAt) {
            // eslint-disable-next-line
            console.log(
              '[C+ Debug] [ERROR] >>> [subscribeNewMessages] >> handleNewMessagesCome > message',
              message
            );
            message.createdAt = moment().toISOString();
          }
          this.handleNewMessageCome(channelId, message);
        }
      }
    },

    /**
     *
     * @param {String} channel id
     * @param {Object} message
     */
    async handleUpdateMessageCome(message) {
      if (!message) return;
      this.updateMessage(message);
    },

    handleUpdateTokenCome(token) {
      if (token && token.payload === 'PASS_CHANGED') {
        this.$alert(
          this.$t('src.core.App.your_password_has_been_changed_you_have_to_login_again'),
          this.$t('src.core.App.password_change'),
          {
            confirmButtonText: this.$t('src.core.App.login'),
            customClass: 'nat-base-confirm-box',
            callback: () => {
              const cookies = new CookiesModel();
              cookies.clearCookies();
              localStorage.removeItem('user-token');
              localStorage.removeItem('user-email');
              localStorage.removeItem('auth-status');

              location.replace('/login');
            },
            showClose: false
          }
        );
      }
    },

    isEndUser(platform) {
      return END_USER_PLATFORM.includes(platform);
    },

    isThirdparty(platform) {
      return THIRDPARTY_PLATFORM.includes(platform);
    },

    _updateAgentStatus() {
      const { status = AGENT_STATUS.ONLINE } = this.user || {};

      if (
        [AGENT_STATUS.LUNCH, AGENT_STATUS.BREAK, AGENT_STATUS.BUSY, AGENT_STATUS.AWAY].includes(
          status
        )
      )
        return;

      return this.updateAgentStatus({
        agentId: this.user.id,
        status: AGENT_STATUS.ONLINE
      });
    },

    startService() {
      let now = new Date().getTime();
      let isLoginPage = window.location.pathname.startsWith('/login');
      const duration = now - Math.max(this.lastRunTime, this.time);
      let requireLoadTime = 1800000; // Inactive more than 30 minutes
      if (duration > requireLoadTime) {
        window.location.reload();
      }
      if (!isLoginPage && duration > 60000) {
        this.time = now;
        this.setLastRunTime(now);
        this.refreshToken();
        this._updateAgentStatus();
      }

      if (window.tockenIntervalId) {
        clearInterval(window.tockenIntervalId);
      }

      const selft = this;
      window.tockenIntervalId = setInterval(function () {
        now = new Date().getTime();
        isLoginPage = window.location.pathname.startsWith('/login');

        if (!isLoginPage && now - selft.lastRunTime >= 60000) {
          selft.setLastRunTime(now);
          selft._updateAgentStatus();
        }
        if (!isLoginPage && now - selft.refreshTokenTime >= 1200000) {
          // 20 minutes
          selft.refreshToken();
        }
      }, 60000);
    },

    stopService() {
      clearInterval(window.tockenIntervalId);
      window.tockenIntervalId = null;
    },

    checkWhetherReloadPage(info) {
      const { data } = info;
      const parseData = JSON.parse(data);

      this.requireReloadPage = true;
      this.projectChangeSettingName = parseData.projectName;
      this.$baseConfirm({
        title: this.$t('src.core.App.warning'),
        message: this.$t('common.message.projectSettingsChanged', {
          name: this.projectChangeSettingName
        }),
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showClose: false,
        showCancelButton: false,
        confirmButtonText: this.$t('src.core.App.button_reload'),
        iconClass: 'el-icon-warning'
      })
        .then(() => {
          this.goReloadPage();
        })
        .catch(() => {});
    },

    goReloadPage() {
      if (this.showView === 'Chat') history.pushState(null, null, '/');
      location.reload(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.require-auth {
  z-index: 9999;
}
/deep/.el-dialog--center .el-dialog__body {
  text-align: center;
}
</style>

<style lang="scss">
@import '~assets/scss/variables';

.subs-new-message {
  right: 70px !important;
  top: 10px !important;
  cursor: pointer;

  .vue-notification {
    padding: 0 !important;
    background: #3b3a52;
    border-left: 5px solid #0d47a1;
  }

  .media-body {
    padding-top: 15px;
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  .notification-content {
    font-size: 15px;
  }
}

.right-panel-pinned {
  .page-main {
    margin-left: $sidebar-width;
    margin-right: calc(25% + 65px);
  }

  .chats-header.fixed {
    position: absolute !important;
    width: 100%;
    background-color: #ffffff;
  }
}

.footer .copyright {
  color: white;
}
</style>
