<template>
  <b-modal
    id="FAQTemplateBox"
    ref="faqTemplateBox"
    :title="$t('src.modules.chat.components.FAQTemplateBox.index.faq')"
    size="lg"
    v-model="show"
    :no-close-on-backdrop="true"
    @hidden="resetAllState"
  >
    <!-- Scenario Name (S) -->
    <div class="form-group">
      <label class="text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold __question">
        <i class="fas fa-grip-vertical m-r-10"></i>
        {{ faq.question || '' }}
      </label>
    </div>
    <!-- Scenario Name (E) -->

    <!-- Hint text (S) -->
    <div class="nat-hint-text">
      {{
        $t(
          'src.modules.chat.components.FAQTemplateBox.index.please_check_and_modify_faq_answer_before_sending'
        )
      }}
    </div>
    <!-- Hint text (E) -->

    <!-- FAQ answer (S) -->
    <ValidationObserver ref="faqAnswerVldtn">
      <div class="faq-answer-text-style">
        <div class="text-style">
          <base-textarea
            :show-label="false"
            :rows="5"
            :maxlength="2000"
            :label="$t('src.core.constants.scenarioTemplateTypeName.text_message')"
            v-model="message"
            :show-word-limit="true"
            :required="true"
            :tribute-options="tributeOptions"
            :tribute-keys="tributeKeys"
            :vue-tribute="true"
          />
        </div>
      </div>
    </ValidationObserver>
    <!-- FAQ answer  (E) -->

    <!-- Action List (S) -->
    <div slot="modal-footer" class="w-100">
      <b-btn class="float-right ml-10" variant="primary" @click="onSend" :disabled="loading"
        >{{ $t('common.text.send') }}
        <i v-if="sendingMessage" class="fas fa-spinner fa-spin fa-lg" />
      </b-btn>
      <b-btn class="float-right" variant="default" @click="onClose">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
    <!-- Action List (S) -->
  </b-modal>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapActions, mapState, mapGetters } from 'vuex';
import { getURLsFromText, getLinkPreview, buildLinkReviewMessage } from 'core/helpers';
import { MessageType } from 'core/message';
import Params from 'modules/chat/helpers/params';
import { orderBy } from 'lodash';

export default {
  components: {},
  data() {
    return {
      message: '',
      faq: {},
      loading: false,
      show: false,
      tributeOptions: {
        values: [],
        selectTemplate: item => this.selectTemplate(item)
      },
      tributeKeys: 0,
      sendingMessage: false
    };
  },

  computed: {
    ...mapState('session', ['user']),
    ...mapGetters('chat', ['selectedConversation'])
  },
  watch: {},

  async created() {
    this.message = '';
    this.tributeConfig();
    EventBus.$on('faqTemplateBox', this.handleOpenPopup);
  },

  destroyed() {
    EventBus.$off('faqTemplateBox', this.handleOpenPopup);
  },

  async mounted() {
    this.loading = false;
    this.message = '';
    this.tributeConfig();
  },

  methods: {
    ...mapActions('chat', ['sendMessage']),
    ...mapActions('session', ['pushPersonalDataToPeople']),

    resetAllState() {
      this.message = '';
      this.faq = {};
      this.loading = false;
      this.show = false;
    },

    async handleOpenPopup(faq) {
      this.message = this.convertKeys2Values(faq.answer);
      const params = {
        userId: this.selectedConversation.userId,
        channelId: this.selectedConversation.channelId,
        projectId: this.selectedConversation.projectId,
        platform: this.selectedConversation.platform
      };
      await this.pushPersonalDataToPeople(params);
      this.tributeConfig();
      this.faq = faq; //  { answer, id, question}
      this.loading = false;
    },

    async onSend() {
      this.$refs.faqAnswerVldtn &&
        this.$refs.faqAnswerVldtn.validate().then(async result => {
          if (result) {
            this.sendingMessage = true;
            this.loading = true;
            let linkPreviews = [];
            const { urls, isURLOnly } = getURLsFromText(this.message);
            if (urls.length > 0) {
              const promises = urls.slice(0, 2).map(url => getLinkPreview(url));
              const results = await Promise.all(promises.map(p => p.catch(e => e)));
              const validResults = results.filter(result => result && !(result instanceof Error));

              if (validResults.length > 0) {
                linkPreviews = validResults;
              }
            }

            const isSendingLinkPreview = linkPreviews && linkPreviews.length > 0;
            if (isSendingLinkPreview) {
              const linkReviews = buildLinkReviewMessage(linkPreviews);
              this.sendMessage({
                sender: this.user.id,
                type: MessageType.TEXT,
                body: this.message,
                payload: { link_reviews: linkReviews, isURLOnly: isURLOnly },
                faqId: this.faq.id
              });
            } else if (this.message && this.message.trim()) {
              this.sendMessage({
                sender: this.user.id,
                type: MessageType.TEXT,
                body: this.message,
                faqId: this.faq.id
              });
            }
            this.sendingMessage = false;
            this.loading = false;
            this.show = false;
          } else {
            this.errorMessage();
          }
        });
    },

    onClose() {
      this.show = false;
    },

    tributeConfig() {
      this.tributeOptions.values = [];
      let displayAgentHasTrue = this.getAgentParamsChannelId();
      let displayAgentKeys = Object.keys(displayAgentHasTrue).map(item => {
        return {
          value: item,
          key: '{' + item + '}'
        };
      });

      // Use Lodash to sort array by 'key'
      let displayAgentKeysSorted = orderBy(displayAgentKeys, ['key'], ['asc']);
      displayAgentKeysSorted.map(item => {
        this.tributeOptions.values.push(item);
        this.tributeKeys += 1;
      });
    },

    getAgentParamsChannelId() {
      return new Params().getAgentParams();
    },

    convertKeys2Values(message) {
      const invalidParams = [];
      const params = new Params();
      message = params.convertUserKeys2Values(message, invalidParams);
      message = params.convertAgentKeys2Values(message, invalidParams);
      if (invalidParams.length) {
        const altMsg = this.$t(
          'src.modules.chat.components.ChatBox.message-input.params_can_not_convert',
          {
            list_params: invalidParams.join(', ')
          }
        );
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: altMsg
        });
      }
      return message;
    },

    errorMessage() {
      this.$baseNotification.warning({
        title: this.$t('src.core.App.warning'),
        message: this.$t(
          'src.modules.profile.components.user-information.please_check_the_invalid_data'
        )
      });
    },

    selectTemplate(item) {
      const { original } = item;
      const { value } = original;
      return new Params().getValueFromKey(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.__question {
  word-wrap: break-word;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
  font-style: italic;
  text-align: center;
  margin: 10px;
}

.faq-answer-text-style {
  width: 95%;
  float: left;
  margin: 0 0 10px 20px;
  color: #465053;
  background-color: #eeeeee;
  border: 1px solid #ddd;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  position: relative;
  display: block;
  padding: 8px;
  clear: both;
  border-radius: 0.286rem;
  .text-style {
    white-space: pre-wrap;
    word-break: break-word;
    width: 100%;
  }
}

.tribute-container {
  position: absolute;
  height: auto;
  width: 190px;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  bottom: 80px !important;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: rgba(233, 236, 239, 1);
  }

  li {
    padding: 5px 5px;
    cursor: pointer;

    &.highlight {
      background: #2196f3;
    }

    span {
      font-weight: bold;
    }

    &.no-match {
      cursor: default;
    }
  }

  .menu-highlighted {
    font-weight: bold;
  }
}
</style>
