import Cookies from 'js-cookie';
import * as constants from './constants';

class CookiesModel {
  constructor() {}

  clearCookies() {
    Cookies.remove(constants.KEY_APP_TOKEN);
    Cookies.remove(constants.KEY_APP_TOKEN_EXP);
  }

  storeAccessToken(value, expiration = 3600) {
    this.setKey(constants.KEY_APP_TOKEN, value, expiration);
  }

  accessToken() {
    return this.getKey(constants.KEY_APP_TOKEN);
  }

  storeAccessTokenExpiration(value, expiration = 3600) {
    this.setKey(constants.KEY_APP_TOKEN_EXP, value, expiration);
  }

  accessTokenExpiration() {
    return this.getKey(constants.KEY_APP_TOKEN_EXP);
  }

  setKey(key, value, maxAge = 3600) {
    let expires = new Date();
    expires.setSeconds(expires.getSeconds() + maxAge);
    if (process.env.NODE_ENV === 'development') {
      Cookies.set(key, value, { path: '/', expires });
    } else {
      Cookies.set(key, value, { path: '/', expires, secure: true, sameSite: 'strict' });
    }
  }

  getKey(key) {
    return Cookies.get(key);
  }
}

export default CookiesModel;
