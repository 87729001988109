<template>
  <div
    ref="commentFilter"
    class="cmt-box-header position-relative fixed shadow-sm fix-label"
  >
    <form id="comment-filter-advance" @submit.prevent="() => {}">
      <el-row class="filter-comment-row">
        <el-col :span="12">
          <div class="w-98-p">
            <p></p>
            <div class="row">
              <div class="input-with-select-mvt">
                <base-select
                  v-model="searchType"
                  :align="'vertical'"
                  :label="translate.search_type"
                  :options="searchTypeList"
                  :option-value="'value'"
                  :option-lable="'label'"
                  :name="'searchType'"
                  :value-type="'String'"
                  :required="true"
                />
              </div>
              <div class="input-content-dth">
                <base-input
                  v-model="keyword"
                  :align="'vertical'"
                  :label="translate.content"
                  :maxlength="maxlength"
                  :placeholder="translate.enter_content"
                  :tooltip-text="translate.hint_text"
                  :tooltip-icon="'question'"
                  @input="content => (this.keyword = content)"
                />
              </div>
            </div>
          </div>
        </el-col>
        <el-col v-show="commentFilterType === translate.advance" :span="12">
          <div class="w-98-p">
            <p></p>
            <div class="input-content-dth">
              <base-input
                v-model="author"
                :align="'vertical'"
                :label="translate.author"
                :placeholder="translate.enter_author"
                :tooltip-text="translate.hint_text"
                :tooltip-icon="'question'"
                @input="content => (this.author = content)"
              />
            </div>
          </div>
        </el-col>
        <el-col
          v-show="commentFilterType === translate.basic"
          :span="12"
          class="el-col-fix"
        >
          <BtnGroup
            :comment-filter-type="commentFilterType"
            @search="searchComment"
            @reset="resetFilterComment"
            @setFilterType="type => (this.commentFilterType = type)"
          />
        </el-col>
      </el-row>
      <el-row v-show="commentFilterType === translate.advance" class="filter-comment-row">
        <el-col :span="10">
          <div class="custom-date w-98-p">
            <base-date-picker-range
              v-model="dateRange"
              :label="translate.date_range"
              :rules="'birthday'"
              :picker-options="pickerOptions"
            />
          </div>
        </el-col>
        <el-col :span="5">
          <base-checkbox
            v-model="isCommentWithNoReply"
            :label="translate.comment_with_no_reply"
            :disabled="searchType !== 'comment'"
            :name="'isCommentWithNoReply'"
            :prefix="'filter'"
          />
        </el-col>
        <el-col :span="7">
          <BtnGroup
            :comment-filter-type="commentFilterType"
            @search="searchComment"
            @reset="resetFilterComment"
            @setFilterType="type => (this.commentFilterType = type)"
          />
        </el-col>
      </el-row>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { uuid } from 'vue-uuid';
import { translate } from '../helpers';
import { mapState, mapActions } from 'vuex';
import { i18n } from 'locales';

import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

import BtnGroup from './btn-group';

function defaultState() {
  return {
    translate,
    searchTypeList: [
      {
        value: 'comment',
        label: translate.comment
      },
      {
        value: 'reply',
        label: translate.reply
      },
      {
        value: 'post',
        label: translate.post
      }
    ],
    keyword: '',
    author: '',
    maxlength: 500,
    disableAllBtn: false,
    searchType: 'comment',
    keywordType: {},
    hintTextId: uuid.v4(),
    commentFilterType: translate.basic,
    dateRange: [],
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    },
    datePickerStartPlaceholder: i18n.t('src.modules.report.components.index.start_date'),
    datePickerEndPlaceholder: i18n.t('src.modules.report.components.index.end_date'),
    isCommentWithNoReply: false
  };
}

export default {
  components: {
    BtnGroup
  },

  data: defaultState,

  computed: {
    ...mapState('comment', ['selectedChannel'])
  },

  watch: {
    async commentFilterType(newValue) {
      if (newValue === translate.basic) {
        const data = cloneDeep(this.$data);
        const holdState = pick(data, ['keyword', 'disableAllBtn']);
        await this.resetState();

        // keep some state when change filter type
        Object.assign(this.$data, holdState);
      } else this.setSelectedComment(null);

      this.$nextTick(() => {
        this.detectHeightOfCommentFilter();
      });
    },

    selectedChannel() {
      // reset state when select a channel
      this.resetState();
      this.setFilterMode(false);
    },

    searchType(newValue) {
      if (newValue !== 'comment') {
        this.isCommentWithNoReply = false;
      }
    }
  },

  mounted() {
    this.detectHeightOfCommentFilter();
  },

  methods: {
    ...mapActions('comment', [
      'commentFilter',
      'setCommentReady',
      'setFilterMode',
      'setFilterCondition',
      'setSelectedComment',
      'getCommentsByChannel'
    ]),

    async searchComment() {
      const valid = this.validteData();
      if (!valid) return true;

      const data = {};
      data.next = '';
      data.keyword = (this.keyword && this.keyword.trim()) || '';
      data.author = (this.author && this.author.trim()) || '';
      data.type = this.searchType || 'comment';

      data.page_id = this.selectedChannel && this.selectedChannel.id;
      data.platform = this.selectedChannel && this.selectedChannel.platform;

      data.date_from =
        this.dateRange && this.dateRange.length > 0
          ? moment(this.dateRange[0]).format('YYYY-MM-DD')
          : '';
      data.date_to =
        this.dateRange && this.dateRange.length > 1
          ? moment(this.dateRange[1]).format('YYYY-MM-DD')
          : '';
      data.comment_with_no_reply = this.commentFilterType === translate.advance 
        && this.searchType === 'comment' 
        && this.isCommentWithNoReply;

      this.disableAllBtn = true;
      this.setFilterMode(true);
      this.setFilterCondition(data);
      this.setCommentReady(false);
      await this.setSelectedComment(null);
      await this.commentFilter(data)
        .then(() => (this.disableAllBtn = false))
        .catch(() => (this.disableAllBtn = false));
    },

    validteData() {
      let valid = true;
      if (this.commentFilterType === translate.basic) {
        valid = this.keyword.trim() ? true : false;
      } else {
        valid =
          this.keyword.trim() ||
          this.author.trim() ||
          (this.dateRange && this.dateRange.length > 0)
            ? true
            : false;
      }
      if (!valid) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: translate.error_validate
        });
      }
      return valid;
    },

    async resetState() {
      Object.assign(this.$data, defaultState());
      await this.setSelectedComment(null);
    },

    setCommentFilterType(type) {
      this.$nextTick(() => {
        this.commentFilterType = type;
      });
    },

    async resetFilterComment() {
      const commentFilterType = this.commentFilterType || translate.basic;

      this.resetState();
      this.setFilterMode(false);
      this.setCommentFilterType(commentFilterType);

      this.setCommentReady(false);
      await this.getCommentsByChannel({})
        .then(() => this.setCommentReady(true))
        .catch(() => this.setCommentReady(true));
    },

    detectHeightOfCommentFilter() {
      const height = this.$refs.commentFilter.clientHeight;
      this.$emit('heightOfCommentFilter', height);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-content-dth {
  width: calc(100% - 135px);
}

.row {
  padding-left: 15px;
  padding-right: 15px;
}

.el-col-fix {
  margin-top: 15px;
}

.input-with-select-mvt {
  width: 120px;
  margin-right: 15px;
}

.fix-label {
  /deep/ label {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
}
</style>

<style lang="scss">
.cmt-box-header {
  z-index: 11;
  &.fixed {
    width: 100%;
    background-color: #ffffff;
    padding: 10px 12px !important;
    height: auto;
  }

  .w-98-p {
    width: 98%;
  }

  .custom-date {
    .el-date-editor {
      border: 1px solid #cad1d7 !important;
      width: 100%;
      /deep/.el-range-input {
        color: #8898aa !important;
        font-size: 0.875rem !important;
      }
    }
  }

  .input-author-mvt {
    /deep/.el-input__inner {
      color: #8898aa;
      font-size: 0.875rem;
    }
  }

  .filter-advance-a {
    color: #409eff !important;
    text-decoration: underline !important;
    margin-left: 10px;
  }

  .filter-advance-a:hover {
    color: #66b1ff !important;
    text-decoration: underline !important;
  }

  .filter-advance-a:hover:after {
    border-bottom: 0px !important;
  }

  .filter-comment-btn {
    /deep/.el-button--primary {
      background-color: #fb6340 !important;
      border-color: #fb6340 !important;
    }

    /deep/.is-disabled {
      opacity: 0.5;
    }

    .group-btn {
      padding-top: 8px;
    }

    /deep/.el-button {
      font-size: 0.7rem !important;
      height: 38px !important;
      line-height: 1 !important;
    }

    /deep/.bd-cs {
      border: 1px solid #dcdfe6 !important;
    }
  }

  .filter-comment-row {
    padding-bottom: 5px;
  }

  .hint-text {
    font-size: 0.76rem;
    color: #8898aa;
  }

  /deep/.el-range-separator {
    width: 10% !important;
  }

  /deep/.el-date-editor {
    width: 100% !important;
  }

  .nat-maxlengthTag {
    color: #909399;
    vertical-align: middle;
    z-index: 1;
    font-size: 10.5px;
    vertical-align: middle;
    margin-left: 10px;
    opacity: 0.7;

    .error {
      color: red;
    }
  }

  #isCommentWithNoReply-filter {
    padding-top: 30px;
  }
}
</style>
