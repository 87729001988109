<template>
  <div v-bar class="channels-wrap">
    <section class="pt-lg-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 conversation-overview">
            <div class="row row-grid row-overview">
              <overview-item
                v-for="channel in filterChannels"
                :key="channel.channelId"
                v-bind="[channel]"
                :conversations="getWaitingList(channel.id)"
                @onSelectedConversation="handleOnSelectedConversation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import OverviewItem from './OverviewItem';
import { mapState, mapGetters } from 'vuex';
import {
  sortByName,
  sortByCount,
  sortConversations,
  filterConversations
} from 'core/helpers';

export default {
  components: {
    OverviewItem
  },
  props: {
    selectedSortType: {
      type: String,
      default: 'channel_name'
    },
    selectedSortList: {
      type: String,
      default: 'ascending'
    },
    selectedProject: {
      type: String,
      default: 'all_project'
    }
  },

  computed: {
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'monitoringList',
      'meWaitingList',
      'me',
      'channelsMessenger'
    ]),
    ...mapState('session', [
      'channels',
      'commonWaitingCountsMap',
      'meWaitingCountsMap',
      'botHandleCountsMap',
      'agentHandleCountsMap',
      'projectMaps'
    ]),
    ...mapState('chat', ['localesFilter', 'searchTextConversation']),

    conversations() {
      return this.getConversations(this.selectedSortType);
    },
    commonWaitingCount() {
      return this.commonWaitingList.length;
    },
    meWaitingCount() {
      return this.meWaitingList.length;
    },
    waitingList() {
      return [...this.commonWaitingList, ...this.meWaitingList];
    },
    filterChannels() {
      let sort = this.selectedSortList === 'ascending' ? 1 : -1;
      if (this.selectedSortType === 'channel_name') {
        return this.channelsMessenger
          .filter(
            channel => (channel.name ? true : false)
          )
          .sort(function(firstChannel, secondChannel) {
            return sortByName(sort, firstChannel, secondChannel);
          });
      } else if (this.selectedSortType === 'bot') {
        let conversations = this.conversationsByBot;
        return this.channelsMessenger
          .filter(
            channel =>
              this.botHandleCountsMap[channel.id] &&
              this.botHandleCountsMap[channel.id] > 0
          )
          .sort((firstChannel, secondChannel) => {
            return sortByCount(conversations, sort, firstChannel, secondChannel);
          });
      } else if (this.selectedSortType === 'waiting') {
        let conversations = this.waitingList;
        return this.channelsMessenger
          .filter(
            channel => this.waitingCount(channel.id)
          )
          .sort(function(firstChannel, secondChannel) {
            return sortByCount(conversations, sort, firstChannel, secondChannel);
          });
      } else if (this.selectedSortType === 'agent') {
        let conversations = this.conversationsByAgents;
        return this.channelsMessenger
          .filter(
            channel =>
              this.agentHandleCountsMap[channel.id] &&
              this.agentHandleCountsMap[channel.id] > 0
          )
          .sort((firstChannel, secondChannel) => {
            return sortByCount(conversations, sort, firstChannel, secondChannel);
          });
      } else if (this.selectedSortType === 'monitoring') {
        const channels = this.channelsMessenger;
        return channels
          .filter(
            channel =>
              ((this.botHandleCountsMap[channel.id] &&
                this.botHandleCountsMap[channel.id] > 0) ||
                this.waitingCount(channel.id) ||
                (this.agentHandleCountsMap[channel.id] &&
                  this.agentHandleCountsMap[channel.id] > 0))
          )
          .sort((firstChannel, secondChannel) => {
            return sortByName(sort, firstChannel, secondChannel);
          });
      } else {
        return [];
      }
    }
  },

  methods: {
    getConversations(type) {
      switch (type) {
        case 'bot':
          return this.sortConversations(this.conversationsByBot);
        case 'waiting': {
          let conversations = this.waitingList;
          return this.sortConversations(conversations);
        }
        case 'agent':
          return this.sortConversations(this.conversationsByAgents);
        case 'monitoring':
          return this.sortConversations(this.monitoringList);
        default:
          return [];
      }
    },

    waitingCount(channelId) {
      return (
        (this.meWaitingCountsMap[channelId] || 0) +
        (this.commonWaitingCountsMap[channelId] || 0)
      );
    },

    totalConvsOfChannel(channelId) {
      return (
        (this.commonWaitingCountsMap[channelId] || 0) +
        (this.meWaitingCountsMap[channelId] || 0) +
        (this.agentHandleCountsMap[channelId] || 0) +
        (this.botHandleCountsMap[channelId] || 0)
      );
    },

    sortConversations(conversations) {
      return sortConversations(conversations);
    },

    filterConversations(cid, type) {
      let conversations = this.getConversations(type);
      return filterConversations(
        conversations,
        cid,
        this.localesFilter,
        this.searchTextConversation
      );
    },

    getWaitingList(cid) {
      return this.filterConversations(cid, 'waiting');
    },

    handleOnSelectedConversation(conversation) {
      this.$emit('onSelectedConversation', conversation);
    }
  }
};
</script>

<style lang="scss" scoped>
.fas {
  font-size: x-large;
}

.page-sub {
  overflow: hidden;
  padding-left: 0;
  padding-top: 80px;
}

.conversation-overview {
  padding: 0px;
}

.overview-box {
  z-index: 1;

  .section-shaped .shape.shape-skew + .shape-container {
    padding-top: 6rem;
    padding-bottom: 19rem;
  }
}
.page-cover {
  z-index: -1;
}
.header-container {
  padding: 10px;
}
.header-container > div {
  padding: 0;
}
.fix-header {
  position: absolute;
  top: -80px;
  width: 100%;
  max-width: 100%;
}
.row-overview {
  padding-bottom: 30px;
}
.el-status-group {
  max-width: 140px;
}
</style>
