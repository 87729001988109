<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
    v-slot="{ errors }"
  >
    <div v-if="align === 'horizontal'">
      <div class="nat-select-multi-group-prepend">
        <div class="nat-select-multi-group-text" :style="c_labelWidthStyle">
          <label v-if="label !== ''">
            <span v-if="required" class="nat-required">*</span>
            {{ label }}
          </label>
          <div class="nat-select-multi-group-tooltip">
            <base-tooltip-icon
              :id="`tooltip-${name}`"
              :name="`tooltip-${name}`"
              :content="tooltipText"
              :icon="tooltipIcon"
            />
          </div>
        </div>
        <div class="nat-select-multi-group-value">
          <div class="nat-select-multi-hori">
            <el-select
              :ref="`baseSelectMulti_${id}`"
              v-if="valueType === 'String'"
              :clearable="!required"
              :disabled="disabled"
              :default-first-option="defaultFirstOption"
              filterable
              multiple
              :id="name"
              :name="name"
              :placeholder="c_placeholder"
              :value="content"
              value-key="key"
              :reserve-keyword="reserveKeyword"
              :popper-class="`${isOnComposition ? 'on-composition-t' : ''}`"
              @change="s => handleStr('change', s)"
              @input="s => handleStr('input', s)"
              @focus="handleFocus"
              @clear="handleClear"
              @remove-tag="handleRemoveTag"
              @keyup.native="handleKeyUp"
            >
              <el-option
                v-for="item in c_options"
                :key="item[optionValue]"
                :label="item[optionLable]"
                :value="item.value"
              />
            </el-select>
            <el-select
              :ref="`baseSelectMulti_${id}`"
              v-if="valueType === 'Object'"
              :clearable="!required"
              :disabled="disabled"
              :default-first-option="defaultFirstOption"
              filterable
              multiple
              :id="name"
              :name="name"
              :placeholder="disabled ? '' : c_placeholder"
              :value="content"
              value-key="key"
              :reserve-keyword="reserveKeyword"
              :popper-class="`${isOnComposition ? 'on-composition-t' : ''}`"
              @change="s => handleOj('change', s)"
              @input="s => handleOj('input', s)"
              @focus="handleFocus"
              @clear="handleClear"
              @remove-tag="handleRemoveTag"
              @keyup.native="handleKeyUp"
            >
              <el-option
                v-for="item in c_options"
                :key="item[optionValue]"
                :label="item[optionLable]"
                :value="item"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
    <div v-if="align === 'vertical'">
      <label>
        <span v-if="required" class="nat-required">*</span>
        {{ label }}
        <base-tooltip-icon
          :id="`tooltip-${name}`"
          :name="`tooltip-${name}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
      </label>
      <div class="nat-select-multi-verti">
        <el-select
          :ref="`baseSelectMulti_${id}`"
          v-if="valueType === 'String'"
          :clearable="!required"
          :disabled="disabled"
          :default-first-option="defaultFirstOption"
          filterable
          multiple
          :id="name"
          :name="name"
          :placeholder="c_placeholder"
          :value="content"
          value-key="key"
          :reserve-keyword="reserveKeyword"
          :popper-class="`${isOnComposition ? 'on-composition-t' : ''}`"
          @change="s => handleStr('change', s)"
          @input="s => handleStr('input', s)"
          @focus="handleFocus"
          @clear="handleClear"
          @remove-tag="handleRemoveTag"
          @keyup.native="handleKeyUp"
        >
          <el-option
            v-for="item in c_options"
            :key="item[optionValue]"
            :label="item[optionLable]"
            :value="item.value"
          />
        </el-select>
        <el-select
          :ref="`baseSelectMulti_${id}`"
          v-if="valueType === 'Object'"
          :clearable="!required"
          :disabled="disabled"
          :default-first-option="defaultFirstOption"
          filterable
          multiple
          :id="name"
          :name="name"
          :placeholder="disabled ? '' : c_placeholder"
          :value="content"
          value-key="key"
          :reserve-keyword="reserveKeyword"
          :popper-class="`${isOnComposition ? 'on-composition-t' : ''}`"
          @change="s => handleOj('change', s)"
          @input="s => handleOj('input', s)"
          @focus="handleFocus"
          @clear="handleClear"
          @remove-tag="handleRemoveTag"
          @keyup.native="handleKeyUp"
        >
          <el-option
            v-for="item in c_options"
            :key="item[optionValue]"
            :label="item[optionLable]"
            :value="item"
          />
        </el-select>
      </div>
      <div class="nat-invalid-data">{{ errors[0] }}</div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate';
import { uuid } from 'vue-uuid';
import { compareName } from 'core/helpers';

export default {
  name: 'base-select-multi',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultFirstOption: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    },
    valueType: {
      type: String,
      default: 'Object'
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLable: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'key'
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: uuid.v4(),
      inputValue: '',
      content: this.value,
      reserveKeyword: false,
      isOnComposition: false
    };
  },
  computed: {
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseSelectRequired = { label: this.label };
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else return this.$t('veevalidate.select.placeholder', { fieldName: this.label });
    },
    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    },
    c_options() {
      if (this.options.length <= 0) return [];
      if (!this.inputValue)
        return this.options.filter(i => !this.content.includes(i[this.optionValue]));
      return this.options.filter(
        i =>
          i[this.optionLable].toLowerCase().includes(this.inputValue.toLowerCase()) &&
          !this.content.includes(i[this.optionValue])
      );
    }
  },

  watch: {
    value(value) {
      this.content = value;
    },
    inputValue(value) {
      !value ? this.hoverItem('firstItem') : null;
    }
  },
  mounted() {
    if (this.value) {
      this.content = this.value;
    }
  },
  methods: {
    handleKeyUp(key) {
      this.$nextTick(() => {
        const {
          $refs: { input: { value = '' } = {} } = {},
          isOnComposition,
          filteredOptionsCount,
          optionsCount
        } = this.$refs[`baseSelectMulti_${this.id}`] || {};
        if (isOnComposition) this.$refs[`baseSelectMulti_${this.id}`].hoverIndex = -1;
        this.isOnComposition = isOnComposition;
        this.inputValue = value;

        if (filteredOptionsCount < optionsCount) {
          this.$refs[`baseSelectMulti_${this.id}`].filteredOptionsCount = optionsCount;
        }

        //In case: Japanese, Korean, China,... We need to hover item after press enter btn.
        key.code == 'Enter' && !isOnComposition ? this.hoverItem('firstItem') : null;
      });
    },
    hoverItem(type) {
      this.$nextTick(() => {
        let baseSelectMulti = this.$refs[`baseSelectMulti_${this.id}`] || null;
        if (baseSelectMulti) {
          let { options = [] } = baseSelectMulti;
          const { $refs: { input: { value = '' } = {} } = {} } = baseSelectMulti;
          this.inputValue = value;
          options = options.sort((a, b) => compareName(a, b, 'currentLabel'));
          if (options.length) {
            switch (type) {
              case 'firstItem':
                setTimeout(() => (baseSelectMulti.hoverIndex = 0), 0);
                break;
            }
          }
        }
      });
    },
    handleStr(emit, selected) {
      const { isOnComposition } = this.$refs[`baseSelectMulti_${this.id}`] || {};
      if (isOnComposition) return true;

      this.content = selected;
      this.$emit(emit, this.content);
      this.hoverItem('firstItem');
    },
    handleOj(emit, selected) {
      const { isOnComposition } = this.$refs[`baseSelectMulti_${this.id}`] || {};
      if (isOnComposition) return true;

      this.content = selected;
      this.$emit(emit, this.content);
      this.hoverItem('firstItem');
    },
    handleFocus() {
      this.hoverItem('firstItem');
    },
    handleClear() {
      this.inputValue = '';
      this.$refs[`baseSelectMulti_${this.id}`].$refs.input.focus();
    },
    handleRemoveTag() {
      this.$refs[`baseSelectMulti_${this.id}`].$refs.input.focus();
      //We will keep the keyword after remove tag
      this.reserveKeyword = true;
      setTimeout(() => (this.reserveKeyword = false), 1);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-select-multi-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-select-multi-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-select-multi-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-select-multi-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-select-multi-hori {
  /deep/.el-input__inner {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
  }

  /deep/.el-select {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}
.nat-select-multi-verti {
  /deep/.el-select {
    width: 100% !important;
  }
  /deep/.el-input__inner {
    font-size: 0.875rem;
  }
}
.nat-required {
  color: red;
}
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}
</style>
