<template>
  <div v-loading="isLoadingCSF" :class="['panel-aside-right', shadowClass]">
    <div class="card wrapper cs-form">
      <div class="card-header">
        <PanelTools
          :pin-status="pinPanelStatus"
          @onPinOn="pinPanel(true)"
          @onPinOff="pinPanel(false)"
          @onClose="handleClose"
        />
        <h3 class="card-title mb-0">
          <span>
            {{
              isPhoneAgent
                ? $t('src.modules.chat.components.CustomerSupportPanel.index.user_info')
                : $t('src.modules.chat.components.CustomerSupportPanel.index.customer_support_form')
            }}
          </span>
        </h3>
      </div>
      <div class="card-body">
        <div id="abcd" class="cs-container" v-on:scroll.passive="handleScroll">
          <b-card v-if="!item" no-body>
            <h6 slot="header" v-b-toggle.clContactInformation class="mb-0">
              <i :class="showCollapse ? `fa fa-chevron-down` : `fa fa-chevron-right`"></i>
              {{ $t('src.components.AppLeftMenu.index.user_profile') }}
            </h6>
            <b-collapse id="clContactInformation" v-model="showCollapse">
              <b-card-body>
                <contact-information
                  id="contactInformation"
                  ref="contactInformation"
                  :user-id="conversation.userId"
                  :channel-id="conversation.channelId"
                  :project-id="conversation.projectId"
                  :platform="(conversation.ticket || {}).platform"
                  :user-info-cvs="conversation.userInfo"
                  :is-support-form="true"
                  @onSeclectRequestInformation="handleRequestInformation"
                  @saveSuccess="handleSuccessContact"
                  @invalidData="handleInvalidContact"
                  @changeContactInfo="changeContactInfo"
                  @submitContactSuccess="checkSubmitContact"
                />
              </b-card-body>
            </b-collapse>
          </b-card>
          <request-information
            id="requestInformation"
            ref="requestInformation"
            v-if="!isPhoneAgent"
            :information="itemSelected"
            :conversation="conversation"
            :item="item"
            :project-id="projectId"
            @success="handleSuccessRequest"
            @invalidData="handleInvalidRequest"
            @checkEditData="handleEditData"
            @submitToClose="handleSuccessToClose"
            @checkHandleSubmitToClose="checkHandleSubmitToClose"
            @checkHandleSubmitForm="checkHandleSubmitForm"
          />
          <div v-if="!isPhoneAgent" class="text-right form-group" style="margin-right: 5px">
            <b-button
              v-if="selectedCSFOld"
              type="button"
              variant="default"
              class="mt-3"
              @click="handleResetForm('back')"
              >{{ $t('src.modules.chat.components.CustomerSupportPanel.index.back') }}</b-button
            >
            <b-button
              data-testid="btnSubmit"
              v-if="!disabledFormInViewMode && selectedTicket.state === 'FINISH'"
              type="button"
              variant="primary"
              class="mt-3"
              @click="handleSubmitToClose"
            >
              {{ $t('common.text.submit') }}
            </b-button>
            <b-button
              data-testid="btnSaveDraft"
              v-if="!disabledFormInViewMode"
              type="button"
              variant="primary"
              class="mt-3"
              @click="handleSubmitForm"
            >
              {{ $t('src.modules.chat.components.CustomerSupportPanel.index.save_draft') }}
            </b-button>
            <b-button
              v-if="isAllowEditSubmittedForm && !isEditSubmittedForm"
              type="button"
              variant="primary"
              class="mt-3"
              @click="handleEditSubmitForm"
            >
              {{ $t('common.text.edit') }}
            </b-button>
            <b-button
              v-if="isAllowEditSubmittedForm && isEditSubmittedForm"
              type="button"
              variant="primary"
              class="mt-3"
              @click="handleSaveSubmitForm"
            >
              {{ $t('common.text.save') }}
            </b-button>
          </div>
          <request-history
            v-if="!item"
            :conversation="conversation"
            :project-id="projectId"
            @deleteSubmitedBefore="handleInvalidHistory"
            @deleteSuccess="handleSuccessHistory"
            @reloadFormAfterDeleteSuccess="handleResetForm('delete')"
            @select="getSelectItem"
            @selectedCSFOld="handleSelectedCSFOld"
            @initFormData="handleSupportFormData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { setTimeout } from 'timers';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { EventBus } from 'core/eventBus';
import RequestHistory from './requestHistory';
import RequestInformation from './requestInformation';
import PanelTools from 'modules/chat/components/PanelTools';
import ContactInformation from './contactInformation';
import { waiting } from 'core/helpers';
import { TICKET_STATE } from 'core/constants';

export default {
  components: {
    RequestHistory,
    RequestInformation,
    ContactInformation,
    PanelTools
  },
  props: {
    isChat: {
      type: Boolean,
      default: true
    },
    conversation: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pinned: false,
      drag: {
        w: 800,
        h: 500,
        minh: 500,
        minw: 600,
        x: 0,
        y: 100
      },
      isFull: false,
      formData: {},
      formDataClone: {},
      isRequestInformation: true,
      itemSelected: {},
      selectedTicket: {},
      selectedCSFOld: false,
      isUpdate: false,
      showCollapse: false,
      projectId: '',
      savedData: false,
      editData: false,
      supportFormData: [],
      isView: true,
      oldTicketId: '',
      submitCustomerSupportPanelClose: false,
      isLoadingCSF: false,
      isEditSubmittedForm: false,
      updateCustomerSupportPanel: false
    };
  },
  computed: {
    ...mapState('global', ['rightCustomerSupportPanelPinned']),
    ...mapState('session', ['user']),
    ...mapGetters('session', ['conversations', 'isPhoneAgent']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapState('chat', ['showSupportPanel', 'showConfirmSavedData']),
    ...mapState('customerSupport', ['customerSupportData', 'categories', 'openCSFMode']),

    disabledFormInViewMode() {
      return this.openCSFMode === 'view' ? true : false;
    },

    isAllowEditSubmittedForm() {
      return this.disabledFormInViewMode && this.itemSelected.isAllowEdit;
    },

    shadowClass() {
      return this.rightCustomerSupportPanelPinned ? 'shadow-sm' : 'shadow-lg unpinned full-width';
    },

    pinPanelStatus() {
      const pinStatus = this.rightCustomerSupportPanelPinned || this.pinned;
      return pinStatus;
    }
  },
  watch: {
    showConfirmSavedData() {
      if (this.showConfirmSavedData === true) {
        this.openSaved();
        this.seShowConfirmSavedData(false);
      }
    },

    savedData() {
      this.checkSavedData();
    },
    editData() {
      this.checkSavedData();
    },
    isRequestInformation() {
      this.checkSavedData();
    },
    item() {
      this.checkSavedData();
    },
    supportFormData(newValue) {
      if (newValue.length > 0 && !this.selectedCSFOld) {
        this.getItemSelected(newValue);
      }
    },
    customerSupportData(newValue) {
      this.supportFormData = [...newValue];
    },

    async conversation(newValue) {
      if (!newValue || isEmpty(newValue)) {
        this.$emit('hideAllPanel');
      }
      const { ticket = {} } = newValue || {};
      if (!this.selectedCSFOld) this.selectedTicket = ticket;

      /**
       * Check convertion Waiting timeout > Complete => hidden (S)
       */
      const { ticketId: ticketIdSlc } = this.selectedConversation || {};
      const { state } = ticket;
      if (
        newValue.ticketId == ticketIdSlc &&
        state == TICKET_STATE.COMPLETE &&
        this.showSupportPanel === true
      ) {
        this.$emit('onSupportItemClick', false);
      }
      /**
       * Check convertion Waiting timeout > Complete => hidden (E)
       */

      if (this.oldTicketId === newValue.ticketId) return;

      this.selectedCSFOld = false;
      await this.fetchDataCFSPanel();
      if (isEmpty(this.itemSelected) && ticket) {
        const { projectId, groupId } = ticket;
        await this.getCustomerSupportSettingsByKey({ projectId, groupId });
        await this.updateTree([...this.categories]);
      }
    }
  },
  async created() {
    const { ticket = {} } = this.conversation || {};
    if (!this.selectedCSFOld) this.selectedTicket = ticket;
    await this.fetchDataCFSPanel();

    if (this.isPhoneAgent) {
      this.showCollapse = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('confirmSavedData');
    EventBus.$off('deleteFromHistory');
    EventBus.$off('expandProfileCSP');
  },
  destroyed() {
    this.setCSFPChangeData(undefined);
  },
  methods: {
    ...mapActions('customerSupport', [
      'getCustomerSupports',
      'getCustomerSupportSettingsByKey',
      'updateTree',
      'getProjectByChannelId',
      'getCountries',
      'getProvinces'
    ]),
    ...mapActions('global', ['pinRightCustomerSupportPanel']),
    ...mapActions('personal', ['setPersonalGlobal']),
    ...mapActions('chat', [
      'seShowConfirmSavedData',
      'setCSFPChangeData',
      'getConversationTicketById'
    ]),

    handleScroll() {
      const contentElement = document.getElementById('abcd');
      if (!contentElement) return;
      contentElement.setAttribute('style', 'transform: translate3d(0px, 0px, 0px);');
    },
    async fetchDataCFSPanel() {
      this.isLoadingCSF = true;
      this.oldTicketId = this.conversation.ticketId;
      this.xPanelFunc();
      const { ticket: { projectId } = {} } = this.conversation;
      this.projectId = projectId || '';

      if (this.conversation.historyEditItem && !this.isChat) {
        this.isRequestInformation = true;
        this.itemSelected = { ...this.conversation.historyEditItem };

        this.showCollapse = false;
      } else {
        const { conversationId } = this.conversation.ticket || {};
        conversationId
          ? await this.getCustomerSupports({
              projectId: this.projectId,
              conversationId,
              channelId: this.conversation.channelId
            })
          : null;
        this.supportFormData = this.customerSupportData;
        this.showCollapse = false;
      }

      EventBus.$on('confirmSavedData', () => {
        this.openSaved();
      });
      EventBus.$on('deleteFromHistory', () => {
        this.isRequestInformation = false;
      });
      this.handleRequestInformation();
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    initRefAndSubmit(arr) {
      arr.forEach(val => {
        const refComponent = this.$refs[val.componentName];
        refComponent && refComponent[val.functionName]();
      });
    },

    handleSubmitForm() {
      this.isLoadingCSF = true;
      const arr = [
        {componentName: 'contactInformation', functionName: 'listenEventSubmit'},
        {componentName: 'requestInformation', functionName: 'handleSubmitForm'},
      ];
      this.initRefAndSubmit(arr);
    },

    handleSubmitToClose() {
      const arr = [
        {componentName: 'contactInformation', functionName: 'listenEventSubmit'}
      ];
      this.initRefAndSubmit(arr);
      this.isLoadingCSF = true;
      this.submitCustomerSupportPanelClose = true;
    },

    async handleResetForm(action) {
      this.isLoadingCSF = true;
      this.selectedCSFOld = false;
      this.getItemSelected(this.supportFormData);
      this.selectedTicket = (this.conversation && this.conversation.ticket) || {};
      if (action === 'delete') {
        await this.fetchDataCFSPanel();
      } else {
        this.isEditSubmittedForm = false;
        EventBus.$emit('editSubmittedFormMode', false);
        if (isEmpty(this.itemSelected)) {
          await this.fetchDataCFSPanel();
        }
      }
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    getItemSelected(supportFormData) {
      if (supportFormData.length > 0) {
        this.itemSelected = supportFormData.reduce((acc, item) => {
          if (item.ticketId === this.conversation.ticketId) {
            acc = item;
          }
          return acc;
        }, {});
      } else {
        this.itemSelected = {};
      }
    },

    saveContactInfo() {},

    handleSupportFormData(data) {
      this.supportFormData = data;

      const { ticketId } = this.conversation;
      const draftData = find(this.supportFormData, {
        status: 'draft',
        ticketId
      });
      if (draftData) {
        if (this.isChat) {
          this.showCollapse = false;
          this.getSelectItem(draftData);
        }
      }
    },

    changeContactInfo(value) {
      this.savedData = value;
    },

    openSaved() {
      const msgProfile = this.savedData
        ? `${this.$t('src.components.AppLeftMenu.index.user_profile')}`
        : '';
      const msgAnd =
        this.savedData && this.editData
          ? ` ${this.$t('src.modules.chat.components.CustomerSupport.index.and')} `
          : '';
      const msgRequestInfo = this.editData
        ? `${this.$t('src.modules.chat.components.CustomerSupportPanel.index.request_information')}`
        : '';
      this.$baseConfirm({
        message: `${this.$t(
          'src.modules.chat.components.CustomerSupportPanel.index.unsaved_changes_in',
          { params: ` ${msgProfile}${msgAnd}${msgRequestInfo}` }
        )}${this.$t(
          'src.modules.chat.components.CustomerSupportPanel.index.will_be_lost_if_you_decide_to_continue_are_you_sure_you_want_to_leave_this_form'
        )}`
      })
        .then(() => {
          this.handleYes();
        })
        .catch(() => {});
    },

    handleEditData(editData) {
      this.editData = editData;
    },

    checkSavedData() {
      if (this.savedData || this.editData) {
        this.$emit('savedData', true);
      } else {
        this.$emit('savedData', false);
      }
    },

    checkSubmitContact(status) {
      if (this.submitCustomerSupportPanelClose) {
        EventBus.$emit('submitCustomerSupportPanelClose', status);
        this.submitCustomerSupportPanelClose = false;
      }

      if (this.updateCustomerSupportPanel) {
        EventBus.$emit('updateCustomerSupportPanel', status);
        this.updateCustomerSupportPanel = false;
      }
    },

    async handleInvalidContact(message) {
      const { msgUnFill, msgInvalid } = message;
      EventBus.$on('expandProfileCSP', () => {
        if (!this.showCollapse) this.showCollapse = true;
      });
      if (msgUnFill) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgUnFill,
          multi: true
        });
      }
      setTimeout(() => {
        if (msgInvalid) {
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: msgInvalid,
            multi: true
          });
        }
      }, 10);
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    handleSuccessContact(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });
      this.isRequestInformation = false;
    },

    async handleInvalidRequest(message) {
      const { msgUnFill, msgInvalid, msgError } = message;
      if (msgError)
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgError,
          multi: true
        });
      if (msgUnFill)
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: msgUnFill,
          multi: true
        });

      setTimeout(() => {
        if (msgInvalid)
          this.$baseNotification.error({
            title: this.$t('src.core.App.error'),
            message: msgInvalid,
            multi: true
          });
      }, 10);
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    async handleSuccessRequest(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });

      this.isRequestInformation = false;
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    handleInvalidHistory(error) {
      this.$baseNotification.error({
        title: this.$t('src.core.App.error'),
        message: error,
        multi: true
      });
    },

    handleSuccessHistory(message) {
      this.$baseNotification.success({
        title: this.$t('src.core.App.success'),
        message: message,
        multi: true
      });
      this.isRequestInformation = false;
    },

    getValidateRule(component) {
      let rule = '';
      if (component.required) rule = 'required';

      if (component.rules && component.rules !== '') {
        rule = `${rule}|${component.rules}`;
      }
      return rule;
    },

    xPanelFunc() {
      let x = window.innerWidth;
      this.drag.x = x / 2 - this.drag.w / 2;
    },

    handleResize() {
      this.isFull = !this.isFull;
    },

    optionsData(key) {
      if (key === 'gender') {
        return [
          { value: 'Male', key: 'male' },
          { value: 'Female', key: 'female' }
        ];
      }
      return [];
    },

    handleRequestInformation() {
      const checkConversation = find(this.conversations, {
        id: this.conversation.id
      });
      if (checkConversation) {
        this.isRequestInformation = true;
        this.itemSelected = {};
        setTimeout(() => EventBus.$emit('newRequest'), 500);
      }
    },

    clearData(obj) {
      delete obj.updatedAt;
      delete obj.createdAt;
      if (this.isUpdate)
        for (let propName in obj) {
          if (obj[propName] === '') {
            obj[propName] = null;
          }
        }
      else
        for (let propName in obj) {
          if (obj[propName] === '' || obj[propName] === null) {
            delete obj[propName];
          }
        }
      return obj;
    },

    async getSelectItem(data) {
      this.isLoadingCSF = true;
      this.isRequestInformation = true;
      this.itemSelected = { ...data };

      const { ticket = {} } = this.conversation || {};
      const { ticketId, conversationId } = data || {};
      if (ticketId === ticket.id && conversationId === ticket.conversationId) {
        this.selectedTicket = ticket;
      } else {
        const conv = await this.getConversationTicketById({ ticketId, conversationId });
        this.selectedTicket = (conv && conv.ticket) || {};
      }
      await waiting(1000).then(() => (this.isLoadingCSF = false));
    },

    handleSelectedCSFOld(value) {
      this.selectedCSFOld = value;
    },

    closePanel() {
      this.$emit('onSupportItemClick', false);
    },

    handleClose() {
      if (this.savedData || this.editData) {
        const msgProfile = this.savedData
          ? `${this.$t('src.components.AppLeftMenu.index.user_profile')}`
          : '';
        const msgAnd =
          this.savedData && this.editData
            ? ` ${this.$t('src.modules.chat.components.CustomerSupport.index.and')} `
            : '';
        const msgRequestInfo = this.editData
          ? `${this.$t(
              'src.modules.chat.components.CustomerSupportPanel.index.request_information'
            )}`
          : '';
        this.$baseConfirm({
          message: `${this.$t(
            'src.modules.chat.components.CustomerSupportPanel.index.unsaved_changes_in',
            { params: ` ${msgProfile}${msgAnd}${msgRequestInfo}` }
          )}${this.$t(
            'src.modules.chat.components.CustomerSupportPanel.index.will_be_lost_if_you_decide_to_continue_are_you_sure_you_want_to_leave_this_form'
          )}`
        })
          .then(() => {
            this.handleYes();
          })
          .catch(() => {});
      } else {
        EventBus.$emit('editSubmittedFormMode', false);
        this.closePanel();
      }
    },

    handleNo() {
      this.$refs.savedConfirmModal.hide();
    },

    handleYes() {
      this.$emit('close');
    },

    handleSuccessToClose() {
      this.$emit('close', false);
    },

    pinPanel(flag) {
      this.pinned = flag;
      this.pinRightCustomerSupportPanel(this.pinned);
    },

    checkHandleSubmitToClose({ isView }) {
      this.isView = isView;
    },

    checkHandleSubmitForm({ isView }) {
      this.isView = isView;
    },

    handleEditSubmitForm() {
      this.isEditSubmittedForm = true;
      EventBus.$emit('editSubmittedFormMode', true);
    },

    handleSaveSubmitForm() {
      const arr = [
        {componentName: 'contactInformation', functionName: 'listenEventSubmit'}
      ];
      this.initRefAndSubmit(arr);
      this.isLoadingCSF = true;
      this.updateCustomerSupportPanel = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/cs/form.scss';

.cs-container {
  height: calc(100vh - 110px) !important;
  overflow: auto;
}
.draggable > .card > .card-body {
  overflow: auto;
}
.fullScreen {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
}
.draggable {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  .dragHeader > .card-header:first-child {
    padding: 10px;
    background-color: #2b2a3c;
    cursor: grabbing;
    h6,
    .close {
      color: #fff;
      font-weight: 600;
    }
  }
  .card-header {
    padding: 0.8rem 1.5rem;
  }
  .card-body {
    padding: 0.5rem;
  }
}
</style>
