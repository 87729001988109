<template>
  <li v-if="!hide" :data-original-title="title" data-toggle="tooltip" class="nav-item">
    <a :class="activeClass" href @click.prevent="$emit('onNavItemClick', id)">
      <span class="nav-link-icon d-block">
        <i :class="icon" />
        <span v-show="c_existTicket" class="m-r-5 nat-exist-ticket">
          <i
            style="font-size: 9px;
            line-height: 1px;
            color: #f5365c;"
            class="material-icons"
            >priority_high</i
          >
        </span>

        <span
          v-if="count > 99 && id === SERVING_STATE.AGENT"
          class="m-r-5 badge couter-badge"
          >99+</span
        >
        <span
          v-if="count > 0 && id === SERVING_STATE.AGENT"
          class="m-r-5 badge couter-badge"
          >{{ count }}</span
        >
      </span>
    </a>
  </li>
</template>

<script>
import { SERVING_STATE, TABS } from 'core/constants';
import { hasButtonNew } from 'core/helpers';
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fa fa-comments'
    },
    title: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    hide: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      SERVING_STATE
    };
  },

  computed: {
    ...mapState('session', ['hasUpdateTicketCome']),

    activeClass() {
      return this.active ? 'nav-link show active' : 'nav-link';
    },

    tabIndex() {
      return `tab_${this.id}`;
    },
    c_existTicket() {
      if (this.id === SERVING_STATE.WAITING) {
        const hasNewCommon = hasButtonNew({
          tabIndex: TABS.WAITING_COMMON,
          hasUpdateTicketCome: this.hasUpdateTicketCome
        });
        const hasNewToMe = hasButtonNew({
          tabIndex: TABS.WAITING_ME,
          hasUpdateTicketCome: this.hasUpdateTicketCome
        });
        if (this.count > 0 || hasNewCommon === true || hasNewToMe === true) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.d-block {
  position: relative;
  .couter-badge {
    position: absolute;
    top: -8px;
    right: -5px;
  }
}

.nat-exist-ticket {
  display: inline-block;
  padding: 0.35rem 0.375rem;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #2288ff;
  background-color: #ffeeee;
  position: absolute;
  top: -8px;
  right: -5px;
}

.fab.fa-android {
  font-size: 1.3rem;
}
</style>
