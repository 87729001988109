<template>
  <b-modal
    id="EscalateBox"
    ref="escalateBox"
    v-model="show"
    :title="$t('src.modules.chat.components.EscalateBox.index.escalate_box')"
    size="lg"
    @hidden="handleBtnCancel"
  >
    <!-- Alert Notification (S) -->
    <b-alert
      id="errorElement"
      :show="dismissCountDown"
      :variant="alertVariant"
      dismissible
      style="background-color: #bd362f; opacity: 0.8"
      @dismissed="dismissCountDown = 0"
    >
      <p>{{ displayMessage }}</p>
    </b-alert>
    <!-- Alert Notification (E) -->

    <!-- Title Channel Nane (S) -->
    <div class="form-group">
      <label class="text-uppercase d-block border-bottom m-b-20 pb-2 font-weight-bold">
        <i class="fas fa-grip-vertical m-r-10"></i>
        {{ channelName }}
      </label>
    </div>
    <!-- Title Channel Nane (E) -->

    <!-- Table (S) -->
    <div class="form-group">
      <label>{{ $t('common.text.select_online_agent') }}</label>
      <div>
        <el-table
          v-loading="loading"
          ref="singleTable"
          :data="c_agents"
          highlight-current-row
          style="width: 100%"
          @current-change="handleCurrentChange"
        >
          <div slot="empty">
            {{ $t('common.text.no_data') }}
          </div>
          <el-table-column
            :label="$t('src.modules.customer-support-history.index.no')"
            width="50"
          >
            <template slot-scope="scope">
              <span> {{ m_index(scope.$index) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('src.modules.chat.components.EscalateBox.index.agent_name')"
            property="agentName"
          ></el-table-column>
          <el-table-column
            :label="$t('common.text.role')"
            property="role"
          ></el-table-column>
          <el-table-column
            :label="$t('src.modules.chat.components.TransferBox.index.users_per_agent')"
            property="usersPerAgent"
          ></el-table-column>
          <el-table-column
            :label="
              $t('src.modules.chat.components.OverviewBox.agent.agent_availability')
            "
          >
            <template slot-scope="scope">
              <el-tag
                :style="
                  'border-color:' +
                    statusColor[scope.row['status']] +
                    ';border-radius:15px;border-width:2px'
                "
                size="medium"
                effect="plain"
              >
                <span style="color: #606266">{{ statusLabel[scope.row['status']] }}</span>
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- Pagination (S) -->
      <div style="text-align: center; margin-top: 30px">
        <el-pagination
          ref="pagination"
          :total="c_cntAgents"
          :current-page="currentPage"
          :page-size="pagesize"
          layout="total, prev, pager, next, jumper"
          @current-change="m_currentChange"
        ></el-pagination>
      </div>
      <!-- Pagination (E) -->
    </div>
    <!-- Table (E) -->

    <!-- Action Menu (S) -->
    <div slot="modal-footer" class="w-100">
      <b-btn
        :disabled="!c_agents.length || needToDisableBtn ? true : false"
        class="float-right"
        variant="primary"
        style="margin-left: 10px"
        @click="handleBtnEscalate"
        >{{ $t('src.modules.chat.components.EscalateBox.index.escalate') }}</b-btn
      >
      <b-btn class="float-right" variant="default" @click="handleBtnCancel">{{
        $t('common.confirmModal.close')
      }}</b-btn>
    </div>
    <!-- Action Menu (E) -->
  </b-modal>
</template>

<script>
import { EventBus } from 'core/eventBus';
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  TICKET_STATUS,
  TICKET_STATE,
  ACTION,
  AGENT_ROLE,
  MAPPING_ROLE,
  AGENT_STATUS,
  AGENT_STATUS_COLOR,
  DEFAULT_AGENT_STATUS_LABEL
} from 'core/constants';
import { countUsersPerAgent } from 'elastic/api';
import { getMaxUsersPerAgent, getActions } from 'core/helpers';
import { compareName, waiting } from 'core/helpers';

function defaultState() {
  return {
    show: false,
    channelName: '',
    projectId: '',
    channelId: '',
    role: '',
    servingState: 0,
    groupId: '',
    waitingState: 0,
    leaderList: [],
    moderatorList: [],
    currentRow: null,
    agents: [],
    dismissCountDown: 0,
    displayMessage: '',
    ticket: {},
    alertVariant: 'success',
    meId: null,
    mappingRole: MAPPING_ROLE || {},
    loading: false,
    usersPerAgentMap: {},
    //Pagination
    pagesize: 10,
    currentPage: 1,
    agentStatus: AGENT_STATUS,
    statusColor: AGENT_STATUS_COLOR,
    statusLabel: DEFAULT_AGENT_STATUS_LABEL,
    needToDisableBtn: false
  };
}

export default {
  data: defaultState,

  computed: {
    ...mapState('session', ['projectMaps', 'channelsMap', 'user']),
    ...mapState('chat', ['selectedGroupConv']),
    ...mapGetters('chat', ['selectedConversation']),

    c_agents() {
      let agents = JSON.parse(JSON.stringify(this.agents));
      agents = agents.sort((a, b) => compareName(a, b, 'agentName'));
      return agents.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    },

    c_cntAgents() {
      return this.agents.length;
    }
  },

  created() {
    EventBus.$on('escalateBox', this.handleOpenPopup);
    EventBus.$on('ticketAlreadyUpdated', newTicket => {
      const { id, state } = newTicket || {};
      const { id: oldId } = this.ticket || {};
      if (id === oldId) {
        if ([TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state))
          return (this.show = false);
        this.ticket = { ...this.ticket, ...newTicket };

        const actions = getActions(this.user, this.ticket, this.projectMaps);
        if (!actions[ACTION.ESCALATE]) return (this.show = false);
      }
    });
  },
  destroyed() {
    EventBus.$off('escalateBox', this.handleOpenPopup);
  },

  methods: {
    ...mapActions('chat', ['updateAndEmitTicket', 'setShowView', 'getAgentsMap']),
    ...mapActions('session', ['removeOldTicket']),
    ...mapActions('global', ['setGlobalReady']),

    m_resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handleCurrentChange(val) {
      this.currentRow = val;
    },

    handleBtnCancel() {
      this.m_resetAllState();
      this.show = false;
    },

    async m_countUsersPerAgent() {
      const { agents } = this.projectMaps[this.projectId] || {};
      if (!agents) return Promise.resolve({});
      const _this = this;
      await Promise.all(
        Object.keys(agents).map(agentId =>
          countUsersPerAgent({ projectId: this.projectId, agentId }).then(
            usersPerAgent => {
              _this.usersPerAgentMap[agentId] = usersPerAgent;
              return usersPerAgent;
            }
          )
        )
      );
    },

    m_usersPerAgent(agentId) {
      return this.usersPerAgentMap[agentId] || 0;
    },

    async handleOpenPopup({ meId, role, channelId, servingState, waitingState, ticket }) {
      this.loading = true;
      this.meId = meId;
      this.channelId = channelId;
      this.channelName = this.channelsMap[this.channelId].name || 'No name';
      this.role = role;
      this.servingState = servingState;
      this.waitingState = waitingState;
      this.projectId = ticket.projectId;
      this.groupId = ticket.groupId;
      this.ticket = ticket;

      try {
        await this.m_countUsersPerAgent();
        //  REGULAR
        if (this.role === AGENT_ROLE.REGULAR) {
          if (this.projectMaps[this.projectId].hasGroup === true && this.groupId) {
            const { moderators = [], leaders = [] } = this.projectMaps[
              this.projectId
            ].groups[this.groupId];

            const _agentsMap = await this.getAgentsMap({
              ids: [...moderators, ...leaders],
              claims: ['firstName', 'lastName', 'role', 'status']
            });

            // Load data for agents table supportingCount / maxUsersPerAgent
            const groupModerator = moderators.reduce((arr, agentId) => {
              const agentInfo = _agentsMap[agentId];

              // not escalate himself
              if (this.user && this.user.id === agentId) return arr;

              // check agent exits
              if (!agentInfo) return arr;

              arr.push({
                agentName: agentInfo.firstName + ' ' + agentInfo.lastName,
                role: this.mappingRole[agentInfo.role],
                status: agentInfo.status,
                agentId,
                usersPerAgent:
                  this.m_usersPerAgent(agentId) +
                  '/' +
                  getMaxUsersPerAgent(this.channelId, true, agentInfo.role)
              });
              return arr;
            }, []);

            if (groupModerator.length > 0) {
              this.agents = [...groupModerator];
              this.loading = false;
              return;
            }

            const groupLeader = leaders.reduce((arr, agentId) => {
              const agentInfo = _agentsMap[agentId];

              // not escalate himself
              if (this.user && this.user.id === agentId) return arr;

              // check agent exits
              if (!agentInfo) return arr;

              arr.push({
                agentName: agentInfo.firstName + ' ' + agentInfo.lastName,
                role: this.mappingRole[agentInfo.role],
                status: agentInfo.status,
                agentId,
                usersPerAgent:
                  this.m_usersPerAgent(agentId) +
                  '/' +
                  getMaxUsersPerAgent(this.channelId, true, agentInfo.role)
              });
              return arr;
            }, []);

            if (groupLeader.length > 0) {
              this.agents = [...groupLeader];
              this.loading = false;
              return;
            }
          } else {
            const agentList = Object.keys(this.projectMaps[this.projectId].agents) || [];

            const _agentsMap = await this.getAgentsMap({
              ids: agentList,
              claims: ['firstName', 'lastName', 'role', 'status']
            });

            this.agents = agentList.reduce((arr, agentId) => {
              const agentInfo = _agentsMap[agentId];

              // not escalate himself
              if (this.user && this.user.id === agentId) return arr;

              // check agent exits
              if (!agentInfo) return arr;

              // check agent role LEADER (SUPPER)
              if (agentInfo.role !== AGENT_ROLE.LEADER) return arr;

              arr.push({
                agentName: agentInfo.firstName + ' ' + agentInfo.lastName,
                role: this.mappingRole[agentInfo.role],
                status: agentInfo.status,
                agentId,
                usersPerAgent:
                  this.m_usersPerAgent(agentId) +
                  '/' +
                  getMaxUsersPerAgent(this.channelId, false, agentInfo.role)
              });

              return arr;
            }, []);
            if (this.agents.length > 0) {
              this.loading = false;
              return;
            }
          }
        }

        // MODERATOR
        else if (this.role === AGENT_ROLE.MODERATOR) {
          if (this.projectMaps[this.projectId].hasGroup === true && this.groupId) {
            const { leaders = [] } = this.projectMaps[this.projectId].groups[
              this.groupId
            ];

            const _agentsMap = await this.getAgentsMap({
              ids: leaders,
              claims: ['firstName', 'lastName', 'role', 'status']
            });

            const groupLeader = leaders.reduce((arr, agentId) => {
              const agentInfo = _agentsMap[agentId];

              // not escalate himself
              if (this.user && this.user.id === agentId) return arr;

              // check agent exits
              if (!agentInfo) return arr;
              arr.push({
                agentName: agentInfo.firstName + ' ' + agentInfo.lastName,
                role: this.mappingRole[agentInfo.role],
                status: agentInfo.status,
                agentId,
                usersPerAgent:
                  this.m_usersPerAgent(agentId) +
                  '/' +
                  getMaxUsersPerAgent(this.channelId, true, agentInfo.role)
              });
              return arr;
            }, []);

            if (groupLeader.length > 0) {
              this.agents = [...groupLeader];
              this.loading = false;
              return;
            }
          } else {
            const agentList = Object.keys(this.projectMaps[this.projectId].agents) || [];

            const _agentsMap = await this.getAgentsMap({
              ids: agentList,
              claims: ['firstName', 'lastName', 'role', 'status']
            });

            this.agents = agentList.reduce((arr, agentId) => {
              const agentInfo = _agentsMap[agentId];

              // not escalate himself
              if (this.user && this.user.id === agentId) return arr;

              // check agent exits
              if (!agentInfo) return arr;

              // check agent role LEADER (SUPPER)
              if (agentInfo.role !== AGENT_ROLE.LEADER) return arr;

              arr.push({
                agentName: agentInfo.firstName + ' ' + agentInfo.lastName,
                role: this.mappingRole[agentInfo.role],
                status: agentInfo.status,
                agentId,
                usersPerAgent:
                  this.m_usersPerAgent(agentId) +
                  '/' +
                  getMaxUsersPerAgent(this.channelId, false, agentInfo.role)
              });

              return arr;
            }, []);
            if (this.agents.length > 0) {
              this.loading = false;
              return;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        // eslint-disable-next-line
        console.log(
          '[C+ Debug] [ERROR] >> [EscalateBox] >> [handleOpenPopup] -> error',
          error
        );
        this.loading = false;
      }
    },

    async handleBtnEscalate() {
      this.needToDisableBtn = true;
      this.setGlobalReady(false);

      if (this.currentRow === null) {
        this.dismissCountDown = 5;
        this.displayMessage = this.$t('common.text.please_select_an_online_agent');
        this.alertVariant = 'warning';
        this.setGlobalReady(true);
        await waiting(1500).then(() => (this.needToDisableBtn = false));
        return;
      }

      const { agentId } = this.currentRow;
      const params = {
        ticket: this.ticket,
        status: TICKET_STATUS.ESCALATE_TO,
        agentId,
        meId: this.meId
      };
      if (this.groupId) {
        params.groupId = this.groupId;
      }
      return this.updateAndEmitTicket(params)
        .then(async data => {
          if (data.error) {
            const error = data.error;
            if (error && error.status === 'NUMBER_PROJECT_EXCEEDED') {
              this.dismissCountDown = 5;
              this.displayMessage = this.$t(
                'src.modules.chat.components.EscalateBox.index.the_number_of_conversation_exceeded_projects_capacity'
              );
              this.alertVariant = 'danger';
            } else if (error && error.status === 'NUMBER_USERS_EXCEEDED') {
              this.dismissCountDown = 5;
              this.displayMessage = this.$t(
                'src.modules.chat.components.EscalateBox.index.the_number_of_conversation_exceeded_agents_capacity'
              );
              this.alertVariant = 'danger';

              //handle reload data if need it
              this.reloadDataOfAgents();
            } else if (error && error.status === 'AGENT_NOT_ONLINE') {
              this.dismissCountDown = 5;
              this.displayMessage = this.$t(
                'src.modules.chat.store.actions.cannot_escalate_this_conversation_because_the_selected_agent_is_not_in_online_status_now'
              );
              this.alertVariant = 'danger';

              //handle reload data if need it
              this.reloadDataOfAgents();
            } else {
              this.dismissCountDown = 5;
              this.displayMessage = this.$t(
                'src.modules.chat.components.EscalateBox.index.escalate_to_failed'
              );
              this.alertVariant = 'danger';
            }
            this.setGlobalReady(true);
            await waiting(1500).then(() => (this.needToDisableBtn = false));
            return false;
          }

          this.show = false;
          this.setGlobalReady(true);
          await waiting(1500).then(() => (this.needToDisableBtn = false));
          return true;
        })
        .catch(async error => {
          // eslint-disable-next-line
          console.log(
            '[C+ Debug] [ERROR] >> [EscalateBox] >> [handleBtnEscalate] >> [updateAndEmitTicket] -> error',
            error
          );
          this.dismissCountDown = 5;
          this.displayMessage = this.$t(
            'src.modules.chat.components.EscalateBox.index.escalate_to_failed'
          );
          this.alertVariant = 'danger';
          this.setGlobalReady(true);
          await waiting(1500).then(() => (this.needToDisableBtn = false));
          return false;
        });
    },

    async reloadDataOfAgents() {
      const ids = this.agents.map(i => i.agentId);
      const [_agentsMap] = await Promise.all([
        await this.getAgentsMap({ ids, claims: ['status'] }),
        await Promise.all(
          ids.map(agentId =>
            countUsersPerAgent({ projectId: this.projectId, agentId }).then(
              usersPerAgent => {
                this.usersPerAgentMap[agentId] = usersPerAgent;
                return usersPerAgent;
              }
            )
          )
        )
      ]);

      this.agents = this.agents.map(i => ({
        ...i,
        status: _agentsMap[i.agentId].status,
        usersPerAgent:
          this.m_usersPerAgent(i.agentId) + '/' + i.usersPerAgent.split('/')[1]
      }));
    },

    m_currentChange(currentPage) {
      this.currentPage = currentPage;
    },

    m_index(index) {
      return Number(this.pagesize) * Number(this.currentPage - 1) + 1 + Number(index);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .modal-header {
  background-color: #f6f9fc;
}

/deep/ .v-select {
  display: -webkit-box;
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    &:after {
      content: none;
    }
    .selected-tag {
      color: #8898aa;
    }
  }
  .dropdown-menu {
    margin-top: 2px;
    li {
      a {
        min-height: 23px;
        color: #8898aa;
        font-size: 0.875rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }
    .highlight {
      a {
        background: #4bd;
        color: #ffffff;
      }
    }
  }
  input {
    margin: -10px 0 0;
    width: 100%;
    height: 100%;
  }
  .no-options {
    display: none;
  }
}
</style>
