import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import uniqueFilename from 'unique-filename';

export class FireStorage {
  constructor(conf) {
    if (!firebase.apps.length) {
      firebase.initializeApp(conf);
    }

    this.storage = firebase.storage();
    this.root = this.storage.ref();
  }

  /**
   * Upload file to firebase storage
   * @param {File} file A `Blob` or `File` to upload
   * @param {String} name [Optional] file name
   * @returns {String} url
   */
  upload(file, name) {
    const fileName = name || uniqueFilename('at');
    const fileRef = this.root.child(fileName);
    const metadata = { contentDisposition: 'attachment' };
    return fileRef.put(file, metadata).then(snapshot => {
      return snapshot.ref.getDownloadURL().then(downloadURL => {
        // eslint-disable-next-line
        console.log('File available at', downloadURL);
        return downloadURL;
      });
    });
  }

  put(file, name) {
    const fileName = name || uniqueFilename('t');
    const fileRef = this.root.child(fileName);
    const metadata = { contentDisposition: 'attachment' };
    return fileRef.put(file, metadata);
  }

  /**
   * Delete a fileStorage file\
   * @ref https://firebase.google.com/docs/storage/web/delete-files
   * @param {string} fileName
   */
  remove(fileName) {
    const fileRef = this.root.child(fileName);
    return fileRef.delete();
  }
}

const configJsonString =
  process.env.VUE_APP_FIREBASE_STORAGE || process.env.VUE_APP_FIREBASE_CONFIG;
const fbConfig = JSON.parse(configJsonString);
export default new FireStorage(fbConfig);
