export const GET_AGENT_GROUPS = 'GET_AGENT_GROUPS';
export const SET_DATA_SEARCH = 'SET_DATA_SEARCH';
export const ADD_LOAD_MORE_SCROLL_ID = 'ADD_LOAD_MORE_SCROLL_ID';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_CUSTOMER_SUPPORT = 'SET_CUSTOMER_SUPPORT';
export const SET_FILES_TICKET_MAP = 'SET_FILES_TICKET_MAP';
export const ADD_LOAD_SCROLL_ID = 'ADD_LOAD_SCROLL_ID';
export const UPDATE_ALL_TICKET = 'UPDATE_ALL_TICKET';
export const GET_ACTION_CLICK_SEARCH = 'SET_ACTION_USER_INFO';
