import {
  ASSIGN_PRODUCTFEEDS_TO_CHANNEL,
  SET_CONVERSATIONFEEDS,
  SET_SEARCH_FAQ_WITH_LANGUAGE,
  SET_LIST_FAQ_LANGUAGE,
  SET_SELECTED_CONVERSATION,
  UPDATE_SELECTED_CONVERSATION,
  ASSIGN_MESSAGES_TO_CONVERSATION,
  SEND_MESSAGE,
  ADD_NEW_MESSAGE_TO_CONVERSATION,
  CLEAR_NEW_MESSAGE,
  SET_CHAT_READY,
  SET_SELECT_GROUP_CONV,
  SET_CONVSEARCHTEXT,
  SET_PRODSEARCHTEXT,
  SET_PRODSEARCHTYPE,
  SET_PRODSEARCHSORT,
  LOAD_MORE_MESSAGE,
  UPDATE_MESSAGE_LOCAL,
  UPDATE_MESSAGE_STATUS,
  SET_LOCALES_FILTER,
  SET_NOTIFY_MESSAGE,
  SET_SEARCH_TEXT,
  SET_INIT_STATE,
  REMOVE_INIT_STATE,
  SET_SHOW_SUPPORT_PANEL,
  SET_SHOW_SURVEY_PANEL,
  SET_SHOW_CONVERSATIONAL_PANEL,
  SET_SHOW_PRODUCT_PANEL,
  SET_MONITORING,
  SET_SHOW_VIEW,
  SET_CHAT_VIEWING_GROUP,
  SET_SEARCH_BOX_FILTER,
  ASSIGN_MESSAGES_OF_TICKET_ID,
  SET_SEARCH_BOX_HEIGHT,
  SET_ACTIVITIES,
  SET_SORT_METHOD,
  SUPPORT_WITHOUT_USER_AGREEMENT,
  UPDATE_MESSAGE,
  SET_AGENT_CHAT_MESSAGE,
  UPDATE_TYPING,
  ADD_TICKET_ACTION_LOG,
  SET_SEARCH_BOX,
  FINISH_CONVERSATION,
  CLEAR_LONGEST_TIME_BY_AGENT,
  SET_CSFP_CHANGE_DATA,
  SET_SHOW_CONFIRM_SAVED_DATA,
  SET_PREVIOUS_ACTION,
  SET_MAYBE_NOT_AVAILABLE,
  SET_CONVERSATIONS_BOX,
  ADD_CONVERSATIONS_BOX,
  CLEAR_CONVERSATIONS_BOX,
  SET_SHOW_WEB_BROWSING_HISTORY_PANEL,
  SET_WEB_BROWSING_HISTORY_DATA,
  CLEAR_WEB_BROWSING_HISTORY_DATA,
  SET_USER_LABELS,
  SET_CONVERSATION_LABELS,
  UPDATE_CONV_IN_USERINFO,
  SET_SYSTEM_DEFINED_WORD,
  SET_SHOW_STATUS_HISTORY_PANEL,
  UPDATE_STATUS_FOR_SELECT_CONVRS,
  SET_IMAGE_OF_URL_PREVIEW
} from './types';
import { EventBus } from 'core/eventBus';
import Service from 'core/service';
import { MessageType, Message } from 'core/message';
import { WebChatService } from 'core/wc-service';
import { ProductFeedService, ConversationFeedService } from './service';
import { translationsMapping, getTranslationsMapping, getGroupConvId } from 'core/helpers';
import cloneDeep from 'lodash/cloneDeep';
import {
  MSG_STATE_COULD_NOT_SEND,
  TICKET_STATUS,
  TICKET_STATE,
  SORT_BY,
  SERVING_STATE,
  AGENT_ROLE,
  MAPPING_ROLE
} from 'core/constants';
import { listLocales } from './listLocales.js';
import { i18n } from 'locales';
import moment from 'moment';
import baseNotification from 'components/BaseComponents/BaseNotification';
import baseConfirm from 'components/BaseComponents/BaseConfirm';
import Router from 'core/router';

import { SEARCH_FILTER } from 'core/constants';
import { isEmpty } from 'lodash-es';

const pfs = new ProductFeedService();
const cfs = new ConversationFeedService();
const lstLocalClass = new listLocales();
const webChatService = new WebChatService();
const service = new Service();
const maintainService = new Service(process.env.VUE_APP_MAINTAIN_SERVICE_ENDPOINT);
const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;

const toMeStatus = [
  TICKET_STATUS.ASSIGN_TO,
  TICKET_STATUS.TRANSFER_TO,
  TICKET_STATUS.ESCALATE_TO,
  TICKET_STATUS.WAITING_TIMEOUT,
  TICKET_STATUS.RESPONSE_TIMEOUT
];
const toMeStates = [TICKET_STATE.REQUEST, TICKET_STATE.SUPPORTING, TICKET_STATE.START];

const supportStatus = [
  TICKET_STATUS.AGENT_SUPPORTING,
  TICKET_STATUS.HOLDING,
  TICKET_STATUS.AGENT_IDLE,
  TICKET_STATUS.USER_IDLE,
  TICKET_STATUS.USER_FINISH,
  TICKET_STATUS.AGENT_FINISH,
  TICKET_STATUS.AGENT_START,
  TICKET_STATUS.FINISH_TIMEOUT,
  TICKET_STATUS.RESPONSE_TIMEOUT
];
const noneSupportState = [TICKET_STATE.COMPLETE];
const interruptStateStatus = [
  `${TICKET_STATE.START}#${TICKET_STATUS.AGENT_START}`,
  `${TICKET_STATE.START}#${TICKET_STATUS.RESPONSE_TIMEOUT}`,
  `${TICKET_STATE.SUPPORTING}#${TICKET_STATUS.AGENT_SUPPORTING}`,
  `${TICKET_STATE.SUPPORTING}#${TICKET_STATUS.AGENT_IDLE}`,
  `${TICKET_STATE.SUPPORTING}#${TICKET_STATUS.USER_IDLE}`,
  `${TICKET_STATE.SUPPORTING}#${TICKET_STATUS.RESPONSE_TIMEOUT}`
];

/** new code */
export const getLabelsByProjectId = async ({ commit }, val) => {
  const data = await maintainService.post('labels/getLabelsByProjectId', val);
  commit(SET_USER_LABELS, data);
  return data;
};
export const getConvLabelsByProjectId = async ({ commit }, val) => {
  const data = await maintainService.post('labels/getLabelsByProjectId', val);
  commit(SET_CONVERSATION_LABELS, data);
  return data;
};

const updConversationInUserInfo = async ({ commit }, conversation) => {
  const convNew = await addUserInfoConversation(conversation);
  commit(UPDATE_CONV_IN_USERINFO, convNew);
};
/** end new code */

const setAgentChatMessage = ({ commit, state, rootState }, message) => {
  const { selectedConversation: { userId, channelId } = {} } = state;
  let msg = message;
  if (userId && channelId) {
    const { people, channelsMap = {}, user } = rootState.session;
    const channel = channelsMap[channelId] || {};
    const {
      advancedConfigs: { params: { paramFields } = {} }
    } = channel;
    const userInfo = people[`${userId}_${channelId}`];
    const invalidParams = [];
    if (userInfo && paramFields) {
      Object.keys(paramFields).forEach(attribute => {
        if (msg.includes(paramFields[attribute])) {
          let val = userInfo[attribute];
          if (val) {
            if (val.name) val = val.name;
            if (val.value) val = val.value;
            if (attribute === 'birthday') {
              val = moment(val).format('YYYY/MM/DD');
            }
            const reg = new RegExp(paramFields[attribute], 'g');
            msg = msg.replace(reg, val);
          } else {
            invalidParams.push(paramFields[attribute]);
          }
        }
      });
    }
    if (user) {
      const agentInfo = {
        ...user,
        role: MAPPING_ROLE[user.role]
      };
      const agentParamFields = {
        firstName: '{agent_first_name}',
        firstNameNativeLanguage: '{agent_native_first_name}',
        lastName: '{agent_last_name}',
        email: '{agent_email}',
        operator: '{agent_operation}',
        company: '{agent_company}',
        division: '{agent_division}',
        contactNumber: '{agent_phone_number}',
        role: '{agent_role}'
      };
      Object.keys(agentParamFields).forEach(attribute => {
        if (msg.includes(agentParamFields[attribute])) {
          let val = agentInfo[attribute];
          if (val) {
            if (val.name) val = val.name;
            const reg = new RegExp(agentParamFields[attribute], 'g');
            msg = msg.replace(reg, val);
          } else {
            invalidParams.push(agentParamFields[attribute]);
          }
        }
      });
    }
    if (invalidParams.length) {
      const altMsg = i18n.t(
        'src.modules.chat.components.ChatBox.message-input.params_can_not_convert',
        {
          list_params: invalidParams.join(', ')
        }
      );
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: altMsg
      });
    }
  }
  commit(SET_AGENT_CHAT_MESSAGE, msg);
};

const setReady = ({ commit }, flag) => {
  commit(SET_CHAT_READY, flag);
};

const setMaybeNotAvailable = ({ commit }, { ticketId, flag }) => {
  commit(SET_MAYBE_NOT_AVAILABLE, {
    ticketId,
    flag
  });
};

/**
 * Get Product List
 */
const getProductFeedsByChannel = async ({ commit }) => {
  const res = await pfs.getProducts({});
  if (res && res.data && res.data.error) return res;
  commit(ASSIGN_PRODUCTFEEDS_TO_CHANNEL, res.data || []);
};

/**
 * Get listLanguage
 */
const getListFaqLanguage = async ({ commit }) => {
  const res = await cfs.getListLanguage();
  commit(SET_LIST_FAQ_LANGUAGE, res || []);
  return res;
};

/**
 * Get chat responser List
 */
const getConversationFeedsByChannel = async ({ commit }, params) => {
  const [listQuestionNLP] = await Promise.all([
    cfs.getConversationsNLP(params)
  ]);
  commit(SET_CONVERSATIONFEEDS, {
    listQuestionFAQ: [],
    listQuestionNLP,
    ...params
  });
  return [[], listQuestionNLP];
};

const getFAQsByChannel = async ({ commit }, params) => {
  const listQuestionFAQ = await cfs.getConversationsFAQ(params);
  commit(SET_CONVERSATIONFEEDS, {
    listQuestionFAQ,
    listQuestionNLP: [],
    ...params
  });
  return [listQuestionFAQ, []];
};

// Get Message Chat List
const getMessagesByConversationId = async (
  { commit, dispatch, rootState: { chat } },
  { id, ticketId, projectId } = {}
) => {
  if (!id) return true;
  const { messages: stateMsgs, messagesUpdate, firstLoaded } = chat;
  const latestUpdate = messagesUpdate[id] || 0;
  const now = Date.now();
  const refreshTime = 30 * 60 * 1000;
  const diff = now - latestUpdate;
  if (!firstLoaded[id] && stateMsgs[id] && diff < refreshTime) {
    return stateMsgs[id];
  }
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId: id,
    ticketId,
    projectId
  });
  const messages = data.Items;

  updateLastMsgContentForTicket(dispatch, messages);
  commit(
    ASSIGN_MESSAGES_TO_CONVERSATION,
    {
      id,
      messages
    } || {}
  );

  return data;
};

const getMessagesByTicketId = async (
  { commit, dispatch },
  { id, ticketId, hasNewMessage, projectId } = {}
) => {
  if (!ticketId && !id) return true;
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId: id,
    ticketId,
    projectId
  });
  const messages = data.Items;

  updateLastMsgContentForTicket(dispatch, messages);
  commit(
    ASSIGN_MESSAGES_OF_TICKET_ID,
    {
      id,
      ticketId,
      messages,
      hasNewMessage
    } || {}
  );
  return data;
};

const loadMoreMessage = async (
  { commit, dispatch },
  { conversationId, ticketId, projectId, nextKey } = {}
) => {
  if (!conversationId || !projectId) return;
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId,
    nextKey,
    ticketId,
    projectId
  });
  const messages = data.Items;
  updateLastMsgContentForTicket(dispatch, messages);
  commit(
    LOAD_MORE_MESSAGE,
    {
      conversationId,
      ticketId,
      messages
    } || {}
  );
  return data;
};

const loadMoreMessageOfTicketId = async (
  { commit, dispatch },
  { conversationId, ticketId, projectId, nextKey } = {}
) => {
  if (!ticketId && !conversationId) return true;
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId,
    nextKey,
    ticketId,
    projectId
  });
  const messages = data.Items;
  updateLastMsgContentForTicket(dispatch, messages);
  commit(
    ASSIGN_MESSAGES_OF_TICKET_ID,
    {
      id: conversationId,
      ticketId,
      messages
    } || {}
  );
  return data;
};

const updateLastMsgContentForTicket = (dispatch, messages) => {
  if (!messages.length) return null;
  const message = messages[messages.length - 1];
  dispatch(
    'session/updateLastMsgForTicket',
    { message, action: 'new' },
    {
      root: true
    }
  );
};

const getConversationTicketById = (_, { conversationId, ticketId } = {}) => {
  return service.post('conversations/getConversationTicketById', {
    conversationId,
    ticketId
  });
};

const addNewMessage = ({ commit, rootState }, msg) => {
  if (!msg) return;
  const { tickets } = rootState.session;
  const { ticketId } = msg || {};
  const ticket = tickets && tickets[ticketId];
  if (ticket) {
    commit(ADD_NEW_MESSAGE_TO_CONVERSATION, {
      msg,
      ticket
    });
  } else {
    commit(ADD_NEW_MESSAGE_TO_CONVERSATION, {
      msg,
      ticket
    });
  }
};

const clearNewMessage = ({ commit }, conversationId) => {
  commit(CLEAR_NEW_MESSAGE, conversationId);
};

const setSelectedConversation = async (context, { conversation, groupId, eventType }) => {
  const newConversation = conversation?.userInfo
    ? conversation
    : await addUserInfoConversation(conversation);
  const { commit, rootState } = context;
  const { session } = rootState;
  const { user } = session;
  commit(SET_SELECTED_CONVERSATION, {
    conversation: newConversation,
    groupId,
    user,
    eventType
  });
  if (!newConversation || !newConversation.id) return false;
  clearNewMessage(context, newConversation.id);
};

const updateSelectedConversation = async (context, conversation) => {
  const { commit } = context;
  const newConversation = await addUserInfoConversation(conversation);
  commit(UPDATE_SELECTED_CONVERSATION, newConversation);
};

const addUserInfoConversation = async conversation => {
  if (!conversation) return conversation;
  const { channelId = '', userInfo, projectId = '', platform = '' } = conversation;
  if (userInfo && userInfo.id && userInfo.name) return conversation;
  const userService = new Service(URL_USER);
  const user = await userService.rest('personal/getPersonal', {
    userId: conversation.userId,
    channelId,
    projectId,
    platform
  });
  return {
    ...conversation,
    userInfo: {
      ...conversation.userInfo,
      ...user
    }
  };
};

const sendMessage = async (
  { getters, commit, dispatch, rootGetters },
  {
    sender,
    type,
    body,
    text,
    payload,
    conversation,
    payloadToken,
    faqId,
    productIds,
    sendWithConfirm = false
  }
) => {
  const {
    id: conversationId,
    channelId,
    userId,
    ticketId,
    translations,
    userInfo,
    ticket: { projectId } = {}
  } = conversation || getters.selectedConversation || {};
  if (!conversationId) return;
  const { platform } = userInfo;

  const tempMsgId = `${Date.now()}`;
  const msg = new Message()
    .type(type || MessageType.TEXT)
    .conversation(conversationId)
    .ticket(ticketId)
    .channel(channelId)
    .recipient(userId)
    .body(body)
    .text(text)
    .payload(payload)
    .faqId(faqId)
    .productIds(productIds)
    .render();

  const actionSettings = rootGetters['session/actionSettings'] || {};
  try {
    const messageToWebhook = cloneDeep(msg);
    messageToWebhook.projectId = projectId;
    messageToWebhook.channelPlatform = platform;

    if (payloadToken) messageToWebhook.payloadToken = payloadToken;
    delete messageToWebhook.createdAt;
    if (
      actionSettings.CONFIRMATION &&
      actionSettings.CONFIRMATION.CONFIRM_ENABLE &&
      !sendWithConfirm &&
      !['file', 'video', 'image', 'audio'].includes(type) &&
      !(type === 'template' && body.template_type === 'image_multiple') &&
      !(payload && payload.type === 'survey')
    ) {
      const sendWithConfirm = true;
      return sendMessageWithConfirm(
        { getters, commit, dispatch, rootGetters },
        {
          sender,
          type,
          body,
          text,
          payload,
          payloadToken,
          conversation,
          faqId,
          productIds,
          sendWithConfirm
        }
      );
    }

    commit(SEND_MESSAGE, {
      conversation: conversation || getters.selectedConversation,
      msg: {
        id: tempMsgId,
        state: 1,
        sender,
        isBot: null,
        ...msg
      }
    });
    dispatch('session/updateConversationLatestMessage', msg, {
      root: true
    });

    const msgResp = await service.post('messages/sendMessage', messageToWebhook);

    if (msgResp && msgResp.ngs && msgResp.ngs.length > 0) {
      // Show error toast
      let errorMsg = 'Cannot send text containing NG Keyword'; // Default
      let translateData = {};
      if (!translations || translations == null) {
        translateData = await getTranslationsMapping(userInfo, channelId);
      } else {
        translateData = translations;
      }
      if (translateData) {
        const { name = '' } = userInfo;
        const objCnvMapping = {
          '${USER_NAME}': name,
          '${USER_FIRSTNAME}': name,
          '${USER_LASTNAME}': name
        };
        errorMsg = translationsMapping(translateData, 'C_NG_KEYWORD', objCnvMapping);
      }
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: errorMsg
      });
    }

    if (!msgResp) {
      commit(UPDATE_MESSAGE_STATUS, {
        conversationId,
        tempId: tempMsgId,
        status: MSG_STATE_COULD_NOT_SEND
      });
      return;
    }
    commit(UPDATE_MESSAGE_LOCAL, {
      conversationId,
      tempId: tempMsgId,
      message: msgResp
    });
  } catch (e) {
    commit(UPDATE_MESSAGE_STATUS, {
      conversationId,
      tempId: tempMsgId,
      status: MSG_STATE_COULD_NOT_SEND
    });

    if (e.messageId) {
      if (e.message) {
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t(e.messageId, { fileName: e.message })
        });
        return;
      }
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t(e.messageId)
      });
      return;
    }

    if (msg && msg.type === 'text' && msg.content.length > 2000) {
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t(
          'src.modules.chat.store.actions.can_not_send_message_has_over_2000_characters_to_this_user'
        )
      });
    } else if (msg && msg.type !== 'text') {
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.chat.store.actions.processing.upload_file_failed', {
          fileName: msg.text || ''
        })
      });
    } else {
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.chat.store.actions.can_not_send_message')
      });
    }
  }
};

const setSelectedGroupConversation = ({ commit }, group) => {
  commit(SET_SELECT_GROUP_CONV, group);
};

const closeConversation = async ({ state }) => {
  const { selectedConversation = {} } = state;
  const { ticketId } = selectedConversation;
  return service.rest('conversations/exec', {
    payload: {
      id: ticketId,
      status: TICKET_STATUS.COMPLETE,
      state: TICKET_STATE.COMPLETE
    }
  });
};

const closeConversationByTicketId = async (_, { ticketId, updatedAt }) => {
  return service.rest('conversations/exec', {
    payload: {
      id: ticketId,
      updatedAt,
      status: TICKET_STATUS.COMPLETE,
      state: TICKET_STATE.COMPLETE
    }
  });
};

const setConvSearchText = ({ commit }, text) => {
  commit(SET_CONVSEARCHTEXT, text);
};

const setProdSearchText = ({ commit }, text) => {
  commit(SET_PRODSEARCHTEXT, text);
};
const setProdSearchType = ({ commit }, type) => {
  commit(SET_PRODSEARCHTYPE, type);
};
const setProdSearchSort = ({ commit }, sort) => {
  commit(SET_PRODSEARCHSORT, sort);
};

const setLocalesToStorage = () => {
  localStorage.removeItem('locales');
  let locales = lstLocalClass.getList();
  localStorage.setItem('locales', JSON.stringify(locales));
};

const setListLocaleFilter = ({ commit }, { localesFilter }) => {
  commit(SET_LOCALES_FILTER, {
    localesFilter
  });
};

const setSearchText = ({ commit }, { searchText }) => {
  commit(SET_SEARCH_TEXT, {
    searchText
  });
};

const setNotifyMessage = ({ commit }, message) => {
  commit(SET_NOTIFY_MESSAGE, message);
};

const finishConversation = async ({ commit }, { ticketId, flagFinishWithoutFeedBack }) => {
  return service
    .rest('conversations/finishConversation', {
      ticketId,
      flagFinishWithoutFeedBack
    })
    .then(res => {
      commit(FINISH_CONVERSATION);
      return res;
    })
    .catch(async error => {
      if (error && error.messageCode) {
        switch (error.messageCode) {
          case 'CHANNEL_NOT_FOUND':
            baseNotification.error({
              title: i18n.t('src.core.App.error'),
              message: i18n.t('common.channel_not_exists')
            });
            break;

          case 'CONVERSATION_NOT_FOUND':
            baseNotification.error({
              title: i18n.t('src.core.App.error'),
              message: i18n.t('common.conversation_not_exists')
            });
            break;

          case 'CONVERSATION_FINISHED':
            setReady({ commit }, true);
            await baseConfirm({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t(
                'src.modules.chat.store.actions.this_conversation_finished_but_not_updated_on_user_interface_yet_kindly_reload_your_page'
              ),
              closeOnPressEscape: false,
              closeOnClickModal: false,
              showClose: false,
              showCancelButton: false,
              confirmButtonText: i18n.t('common.confirmModal.close'),
              iconClass: 'el-icon-warning'
            }).catch(() => { });
            break;

          default:
            break;
        }
        return 'error';
      }

      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: error.messageId ? i18n.t(error.messageId) : error.message
      });
    });
};

const noSupportTicket = async (context, { ticket }) => {
  if (!ticket) return false;
  const { rootState } = context;
  const { session } = rootState;
  const { user } = session;
  const payload = { ...ticket, agentId: user.id, assignee: user.id };
  return service
    .rest('conversations/exec', {
      payload
    })
    .then(ticket => {
      if (ticket.error) {
        const { status = '' } = ticket.error;
        if (status === 'NUMBER_USERS_EXCEEDED') return ticket;
        else if (status === 'NUMBER_PROJECT_EXCEEDED') return ticket;
        else if (status === 'AGENT_NOT_ONLINE') return ticket;
        else {
          handleUpdateTicketError(ticket.error);
          return;
        }
      } else {
        return ticket;
      }
    });
};

//eslint-disable-next-line
const updateAndEmitTicket = async (context, { ticket, status, agentId, groupId, meId }) => {
  const payload = { ...ticket };
  if (!ticket) return false;
  payload.state = ticket.state;
  payload.id = ticket.id;
  payload.assignee = agentId;
  payload.status = status;

  if (groupId) payload.groupId = groupId;
  if (meId) payload.agentId = meId;
  return service
    .rest('conversations/exec', {
      payload
    })
    .then(ticket => {
      if (ticket.error) {
        const { status = '' } = ticket.error;
        if (status === 'NUMBER_USERS_EXCEEDED') return ticket;
        else if (status === 'NUMBER_PROJECT_EXCEEDED') return ticket;
        else if (status === 'AGENT_NOT_ONLINE') return ticket;
        else {
          handleUpdateTicketError(ticket.error);
          return;
        }
      } else {
        if (
          [
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.FORWARD_GROUP
          ].includes(ticket.status)
        ) {
          const { projectId, channelId, agentId } = ticket;

          const { selectedCategoryChannelMap } = context.rootState.session;
          let groupId = 'all';
          if (agentId === meId) {
            if (selectedCategoryChannelMap[channelId + '2'])
              groupId = selectedCategoryChannelMap[channelId + '2'];
            context.dispatch(
              'session/getMeWaitingCount',
              { projectId, channelId, groupId },
              {
                root: true
              }
            );
          } else {
            if (selectedCategoryChannelMap[channelId + '1'])
              groupId = selectedCategoryChannelMap[channelId + '1'];
            context.dispatch(
              'session/getCommonWaitingCount',
              { projectId, channelId, groupId },
              {
                root: true
              }
            );
          }
        }
        return ticket;
      }
    });
};

const supportTicket = async (context, conversation) => {
  const { state: chatState } = context;
  const { ticket, channelId } = conversation;
  const { projectId } = ticket;
  const { user, channelsMap, projectMaps } = context.rootState.session;
  const { advancedConfigs = {}, platform } = channelsMap[channelId];
  const { supportWithoutUserAgreement = false, chatViewingGroup, searchBoxFilter } = chatState;
  const { status, state } = ticket;

  /**
   * Check Agent limit support tickets (S)
   *
   */
  let maxUsersPerAgent = 0;
  let {
    conversationQuota: {
      numberProject = 0,
      numberLeader = 0,
      numberRegular = 0,
      numberRegularLeader = 0,
      numberSupervisor = 0
    } = {}
  } = advancedConfigs;
  const hasGroup = projectMaps[projectId].hasGroup;
  if (hasGroup) {
    switch (user.role) {
      case AGENT_ROLE.REGULAR:
        maxUsersPerAgent = numberRegular;
        break;
      case AGENT_ROLE.MODERATOR:
        maxUsersPerAgent = numberLeader;
        break;
      case AGENT_ROLE.LEADER:
        maxUsersPerAgent = numberSupervisor;
        break;
      default:
        maxUsersPerAgent = 0;
        break;
    }
  } else {
    switch (user.role) {
      case AGENT_ROLE.REGULAR:
      case AGENT_ROLE.MODERATOR:
        maxUsersPerAgent = numberRegularLeader;
        break;
      case AGENT_ROLE.LEADER:
        maxUsersPerAgent = numberSupervisor;
        break;
      default:
        maxUsersPerAgent = 0;
        break;
    }
  }
  if (maxUsersPerAgent && maxUsersPerAgent > 0) {
    const count = await service.post('project/getSupportingCount', {
      projectId,
      agentId: user.id
    });

    if (count && count.data) {
      let agentSupportingCount = count.data.agentSupportingCount;
      let projectSupportingCount = count.data.projectSupportingCount;
      if (
        (toMeStatus.includes(ticket.status) && toMeStates.includes(ticket.state)) ||
        (supportStatus.includes(ticket.status) && !noneSupportState.includes(ticket.state))
      ) {
        agentSupportingCount = agentSupportingCount > 0 ? agentSupportingCount - 1 : 0;
        projectSupportingCount = projectSupportingCount > 0 ? projectSupportingCount - 1 : 0;
      }
      if (projectSupportingCount >= numberProject) {
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t(
            'src.modules.session.store.actions.the_number_of_conversation_exceeded_projects_capacity'
          )
        });
        return;
      }
      if (agentSupportingCount >= maxUsersPerAgent) {
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t(
            'src.modules.session.store.actions.the_number_of_conversation_exceeded_agents_capacity'
          )
        });
        return;
      }
    } else if (count && count.error) {
      // eslint-disable-next-line
      console.log('[C+ Debug]: supportTicket -> error', count.error);
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.session.store.actions.internal_error')
      });
      return;
    } else {
      baseNotification.error({
        title: i18n.t('src.core.App.error'),
        message: i18n.t('src.modules.session.store.actions.internal_error')
      });
      return;
    }
  }
  /**
   * Check Agent limit support tickets (E)
   *
   */
  if (
    supportWithoutUserAgreement &&
    (state === TICKET_STATE.COMPLETE || state === TICKET_STATE.FINISH)
  ) {
    const payload = {
      channelId,
      oldTicketId: ticket.id,
      newState: TICKET_STATE.START,
      newStatus: TICKET_STATUS.AGENT_START,
      agentId: user.id,
      assignee: user.id,
      projectId,
      platform,
      supportWithoutUserAgreement
    };
    if (ticket.groupId) payload['newGroupId'] = ticket.groupId;
    return service
      .rest('conversations/supportCompleteTicket', {
        ticket: payload
      })
      .then(async ({ data, error }) => {
        if (!data || error) {
          if (error.code === 'support_by_other_agent') {
            return EventBus.$emit('reSupportWarningModal', {
              code: 'SUPPORT_BY_ANOTHER_AGENT'
            });
          }
          if (error && error.code === 'conversation-was-changed') {
            await getConversationTicketById(null, {
              conversationId: conversation.id,
              ticketId: ticket.id
            }).catch(() => { });
          }
          handleUpdateTicketError(error || data.error);
          return;
        } else {
          const newData = cloneDeep(data);
          const newConversation = cloneDeep(conversation);
          newConversation.ticket.id = newData.id;
          newConversation.ticketId = newData.id;

          if (isEmpty(searchBoxFilter)) {
            directRedirectToTicket(newData);
          }
          return { ticket: data, event: 'supportComplete' };
        }
      });
  } else if (
    !supportWithoutUserAgreement &&
    (state === TICKET_STATE.COMPLETE || state === TICKET_STATE.FINISH)
  ) {
    const payload = {
      channelId,
      oldTicketId: ticket.id,
      newState: TICKET_STATE.REQUEST,
      newStatus: TICKET_STATUS.REQUEST_USER,
      agentId: user.id,
      assignee: user.id,
      projectId,
      platform
    };
    if (ticket.groupId) payload['newGroupId'] = ticket.groupId;
    return service
      .rest('conversations/supportCompleteTicket', {
        ticket: payload
      })
      .then(async ({ data, error }) => {
        if (!data || error) {
          if (error.code === 'support_by_other_agent') {
            return EventBus.$emit('reSupportWarningModal', {
              code: 'SUPPORT_BY_ANOTHER_AGENT'
            });
          }
          if (error && error.code === 'conversation-was-changed') {
            await getConversationTicketById(null, {
              conversationId: conversation.id,
              ticketId: ticket.id
            })
              .then(conv => {
                if (conv && conv.ticket) {
                  context.dispatch(
                    'session/addTicketLocal',
                    { ticket: conv.ticket },
                    {
                      root: true
                    }
                  );
                  context.dispatch('session/updateConversationLocal', conv, {
                    root: true
                  });
                }
              })
              .catch(() => { });
          }
          handleUpdateTicketError(error || data.error);
          return;
        } else {
          const newData = cloneDeep(data);
          const newConversation = cloneDeep(conversation);
          newConversation.ticket.id = newData.id;
          newConversation.ticketId = newData.id;
          newConversation.ticket.version = newData.version;
          newConversation.ticket.state = newData.state;
          newConversation.ticket.status = newData.status;

          if (isEmpty(searchBoxFilter)) {
            directRedirectToTicket(newData);
          }
          return { ticket: data, event: 'supportComplete' };
        }
      });
  } else if (!supportWithoutUserAgreement && status === TICKET_STATUS.BOT_HANDLE) {
    return service
      .rest('conversations/supportNormalTicket', {
        ticket: {
          id: ticket.id,
          state: TICKET_STATE.REQUEST,
          status: TICKET_STATUS.REQUEST_USER,
          groupId: ticket.groupId,
          agentId: user.id,
          assignee: user.id,
          updatedAt: ticket.updatedAt,
          needSendSystemMessage: true
        }
      })
      .then(async ticket => {
        if (ticket.error) {
          handleUpdateTicketError(ticket.error);
          return;
        } else {
          context.dispatch(
            'session/addTicketLocal',
            { ticket },
            {
              root: true
            }
          );
          handleGoToTicket(context, ticket);
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('TCL: supportWithUserConsent -> error', error);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.internal-server-error')
        });
      });
  } else if (supportWithoutUserAgreement || state === TICKET_STATE.REQUEST) {
    const payload = {
      id: ticket.id,
      state: TICKET_STATE.START,
      status: TICKET_STATUS.AGENT_START,
      agentId: user.id,
      assignee: user.id,
      updatedAt: ticket.updatedAt,
      supportWithoutUserAgreement,
      needSendSystemMessage: true
    };
    if (ticket.groupId) payload['groupId'] = ticket.groupId;
    return service
      .rest('conversations/supportNormalTicket', {
        ticket: payload
      })
      .then(async ticket => {
        if (ticket.error) {
          const { status = '' } = ticket.error;
          if (status === 'ALREADY_SUPPORT' && payload.groupId) {
            baseNotification.error({
              title: i18n.t('src.core.App.error'),
              message: i18n.t('src.modules.chat.store.actions.this_user_selected_a_group')
            });
          } else {
            handleUpdateTicketError(ticket.error);
          }
          return;
        } else {
          context.dispatch(
            'session/addTicketLocal',
            { ticket },
            {
              root: true
            }
          );
          handleGoToTicket(context, ticket);
          if (chatViewingGroup === SERVING_STATE.MONITORING && isEmpty(searchBoxFilter)) {
            directRedirectToTicket(ticket);
          }
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('TCL: supportWithoutUserConsent -> e', error);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.internal-server-error')
        });
      });
  } else {
    const payload = {
      id: ticket.id,
      state: TICKET_STATE.SUPPORTING,
      status: TICKET_STATUS.AGENT_SUPPORTING,
      agentId: user.id,
      assignee: user.id,
      updatedAt: ticket.updatedAt,
      needSendSystemMessage: false
    };

    //SGCPLUS-4665 (S)
    if (
      state === TICKET_STATE.START &&
      [
        TICKET_STATUS.ASSIGN_TO,
        TICKET_STATUS.ESCALATE_TO,
        TICKET_STATUS.TRANSFER_TO,
        TICKET_STATUS.FORWARD_GROUP
      ].includes(status)
    ) {
      payload.state = state;
      payload.status = TICKET_STATUS.AGENT_START;
    }

    if (interruptStateStatus.includes(`${state}#${status}`)) {
      payload.state = state;
      payload.status = status;
      payload.isInterrupted = true;
    }

    return service
      .rest('conversations/supportNormalTicket', {
        ticket: payload
      })
      .then(ticket => {
        if (ticket.error) {
          handleUpdateTicketError(ticket.error);
          return;
        } else {
          context.dispatch(
            'session/addTicketLocal',
            { ticket },
            {
              root: true
            }
          );
          handleGoToTicket(context, ticket);
          if (chatViewingGroup === SERVING_STATE.MONITORING && isEmpty(searchBoxFilter)) {
            directRedirectToTicket(ticket);
          }
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('TCL: supportNormalTicket -> e', error);
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.internal-server-error')
        });
      });
  }
};

const directRedirectToTicket = ticket => {
  EventBus.$emit('reSupportWarningModal', {
    code: 'DIRECT_REDIRECT_TO_TICKET',
    ticket
  });
};

const handleGoToTicket = (context, ticket) => {
  const { selectedConversation, selectedGroupConv, chatViewingGroup } = context.rootState.chat;
  const { user } = context.rootState.session;
  const selectedTicketId =
    (selectedConversation &&
      ((selectedConversation.ticket && selectedConversation.ticket.id) ||
        selectedConversation.ticketId)) ||
    '';
  const groupId = getGroupConvId({ ticket }, user);
  if (selectedTicketId === ticket.id && selectedGroupConv !== chatViewingGroup) {
    return setSelectedConversation(context, {
      conversation: { ...selectedConversation, ticket: ticket },
      groupId
    });
  }
};

const handleUpdateTicketError = async error => {
  const { status = '', message = '', code, messageId } = error;
  if (status === 'NOT_FOUND') {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t('src.modules.chat.store.actions.user_not_found')
    });
  } else if (status === 'ALREADY_SUPPORT' || code === 'ALREADY_SUPPORT') {
    return baseConfirm({
      title: i18n.t('src.core.App.warning'),
      message: i18n.t('common.confirmModal.conversation_has_been_chaged_please_refresh_page'),
      closeOnPressEscape: false,
      closeOnClickModal: false,
      showClose: false,
      cancelButtonText: i18n.t('common.confirmModal.cancel'),
      confirmButtonText: i18n.t('src.components.AppLeftMenu.index.refresh'),
      iconClass: 'el-icon-warning'
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => { });
  } else if (status === 'CAN_NOT_UPDATE_TICKET') {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t('src.modules.chat.store.actions.can_not_update_ticket')
    });
  } else if (status === 'AGENT_NOT_ONLINE') {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t('src.modules.chat.store.actions.this_agent_not_online')
    });
  } else if (status === 'NUMBER_GROUP_WAITING_EXCEEDED') {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: i18n.t('src.modules.chat.store.actions.failed_to_forward_conversation')
    });
  } else if (code) {
    switch (code) {
      case 'channel-is-in-maintenance':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.channel_is_in_maintenance')
        });
        return false;
      case 'channel-not-exists':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.channel_not_exists')
        });
        return false;
      case 'conversation-not-exists':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.conversation_not_exists')
        });
        return false;
      case 'conversation-was-changed':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.conversation_was_changed')
        });
        return false;
      case 'internal-error':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.internal-server-error')
        });
        return false;
      case 'send-system-message-error':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t(messageId || 'common.internal-server-error')
        });
        return false;
      case 'group-not-exists':
      case 'group-is-disable':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('common.error.select-group')
        });
        return false;
      default:
        if (message) {
          baseNotification.error({
            title: i18n.t('src.core.App.error'),
            message: message
          });
        } else {
          baseNotification.error({
            title: i18n.t('src.core.App.error'),
            message: i18n.t('common.internal-server-error')
          });
        }
        return false;
    }
  } else {
    baseNotification.error({
      title: i18n.t('src.core.App.error'),
      message: message
    });
  }
};

const setInitState = ({ commit }) => {
  commit(SET_INIT_STATE);
};

const removeInitState = ({ commit }) => {
  commit(REMOVE_INIT_STATE);
};

const setShowSupportPanel = ({ commit }, val) => {
  commit(SET_SHOW_SUPPORT_PANEL, val);
};

const setShowSurveyPanel = ({ commit }, val) => {
  commit(SET_SHOW_SURVEY_PANEL, val);
};

const setShowStatusHistoryPanel = ({ commit }, val) => {
  commit(SET_SHOW_STATUS_HISTORY_PANEL, val);
};

const setShowWebBrowsingHistoryPanel = ({ commit }, val) => {
  commit(SET_SHOW_WEB_BROWSING_HISTORY_PANEL, val);
};

const setShowConfirmSavedData = ({ commit }, val) => {
  commit(SET_SHOW_CONFIRM_SAVED_DATA, val);
};
const setPreviousAction = ({ commit }, val) => {
  commit(SET_PREVIOUS_ACTION, val);
};

const setCSFPChangeData = ({ commit }, val) => {
  commit(SET_CSFP_CHANGE_DATA, val);
};

const setShowProductPanel = ({ commit }, val) => {
  commit(SET_SHOW_PRODUCT_PANEL, val);
};

const setShowConversationalPanel = ({ commit }, val) => {
  commit(SET_SHOW_CONVERSATIONAL_PANEL, val);
};

const setMonitoring = ({ commit }, val) => {
  commit(SET_MONITORING, val);
};
const setShowView = async ({ commit }, val) => {
  const path = window.location.pathname;
  if (val === 'DashBoard') {
    path !== '/' ? await Router.push('/').catch(() => { }) : null;
    setSelectedGroupConversation({ commit }, SERVING_STATE.INACTIVE);
  }
  if (val === 'Overview') {
    path !== '/' ? await Router.push('/').catch(() => { }) : null;
    setSelectedGroupConversation({ commit }, SERVING_STATE.AGENT);
  }

  if (val === 'SearchView') {
    path !== '/' ? await Router.push('/').catch(() => { }) : null;
    val = 'Overview';
  }
  return commit(SET_SHOW_VIEW, val);
};
const setChatViewingGroup = ({ commit }, val) => {
  commit(SET_CHAT_VIEWING_GROUP, val);
};
const setSearchBoxFilter = ({ commit }, val) => {
  commit(SET_SEARCH_BOX_FILTER, val);
};
const setSearchBoxHeight = ({ commit }, val) => {
  commit(SET_SEARCH_BOX_HEIGHT, val);
};
const setSearchBox = ({ commit }, val) => {
  commit(SET_SEARCH_BOX, val);
};

const getCreateTag = async (_, val) => {
  return service.rest('tags/getCreateTag', val);
};

const getTagsByProjectId = async (_, val) => {
  return service.rest('tags/getTagsByProjectId', val);
};

const removeTicketInTickets = async (_, val) => {
  return service.rest('tags/removeTicketInTickets', val);
};

const getCreateLabel = async (_, val) => {
  return service.rest('labels/getCreateLabel', val);
};

const updateUserLabels = async (_, val) => {
  return maintainService.rest('personal/assignPersonalLabels', val);
};

const updateTicketTags = async (_, val) => {
  return maintainService.post('tickets/assignTicketLabels', val);
};

const updateLabel = async (_, val) => {
  const service = new Service(process.env.VUE_APP_USER_SERVICE_ENDPOINT);
  return service.rest('labels/updateLabel', val);
};

const removeUserInUsers = async (_, val) => {
  return service.rest('labels/removeUserInUsers', val);
};

const setSearchFaqWithLanguage = ({ commit }, val) => {
  commit(SET_SEARCH_FAQ_WITH_LANGUAGE, val);
};

const getActivities = async ({ commit }, projectId) => {
  const time = new Date().getTime();
  const data = await service.post('dashboard/getActivities', {
    projectId
  });

  commit(SET_ACTIVITIES, {
    projectId,
    data,
    time
  });

  return data;
};

const getDashboardReport = async (_, params) => {
  let data = {};
  try {
    data = await service.post('dashboard/get_report', params);
  } catch (error) {
    return data;
  }
  return data;
};

const clearLongestTimeByAgent = async ({ commit }, projectId) =>
  commit(CLEAR_LONGEST_TIME_BY_AGENT, projectId);

const setSortBy = async (
  { commit, state, dispatch, rootState },
  { channelId, sortBy, direction }
) => {
  if (!channelId || !sortBy) return null;

  const { selectedGroupConv, sortBysMap, searchBoxFilter } = state;
  const { waitingMode } = rootState.session;

  if (searchBoxFilter.constructor === Object && Object.keys(searchBoxFilter).length > 0) {
    const _isSearch = true;
    const { user } = rootState.session;

    const searchParams = {};
    searchParams.conditions = searchBoxFilter;
    searchParams.from = 0;
    searchParams.size = SEARCH_FILTER.LOAD_MORE_TICKET_QTY;
    searchParams.channelId = channelId;
    searchParams.tab = selectedGroupConv;
    searchParams.waitingMode = waitingMode;
    searchParams.agentId = user.id;

    if (!selectedGroupConv || selectedGroupConv < 0) return null;

    const { sortBy: curSortBy, direction: curDirection } = sortBysMap[channelId + '9'] || {
      sortBy: SORT_BY.LAST_MESSAGE,
      direction: 0
    };
    if (curSortBy === sortBy && curDirection === direction) return null;

    commit(SET_SORT_METHOD, {
      channelId,
      sortBy,
      direction,
      isSearch: _isSearch
    });
    dispatch('session/getTicketsBySearch', searchParams, {
      root: true
    });
  } else {
    const { selectedGroupConv, sortBysMap } = state;
    const { waitingMode, channelsMap } = rootState.session;

    if (!selectedGroupConv || selectedGroupConv < 0) return null;

    let groupId = selectedGroupConv;
    if (groupId === SERVING_STATE.WAITING) {
      groupId += waitingMode;
    }

    const { sortBy: curSortBy, direction: curDirection } = sortBysMap[channelId + groupId] || {
      sortBy: SORT_BY.LAST_MESSAGE,
      direction: 0
    };
    if (curSortBy === sortBy && curDirection === direction) return null;

    dispatch(
      'session/clearList',
      {
        channelId
      },
      {
        root: true
      }
    );
    commit(SET_SORT_METHOD, {
      channelId,
      groupId,
      sortBy,
      direction
    });

    const { projectId } = channelsMap[channelId];
    let status = 3;
    if (selectedGroupConv === SERVING_STATE.WAITING) {
      status = waitingMode === 'COMMON' ? 1 : 2;
    }
    dispatch(
      'session/loadMoreConversation',
      {
        projectId,
        channelId,
        status
      },
      {
        root: true
      }
    );
  }
};

const setSupportWithoutUserAgreement = ({ commit }, value) => {
  commit(SUPPORT_WITHOUT_USER_AGREEMENT, value);
};

const uploadFileSharing = async (_, payload) => {
  return await service.post('project/file/create', payload);
};

const deleteFileSharing = async (_, params) => {
  return await service.post('project/file/delete', params);
};

const updateMessage = async ({ commit, dispatch }, params) => {
  commit(UPDATE_MESSAGE, {
    dispatch,
    msg: params
  });
};

const deleteMessage = async (_, params) => {
  return service.post('messages/deleteMessageById', params);
};

const typingTimer = {};
const updateTyping = ({ commit }, { userId, text }) => {
  if (typingTimer[userId]) {
    clearInterval(typingTimer[userId]);
  }
  commit(UPDATE_TYPING, {
    userId,
    text
  });
  typingTimer[userId] = setTimeout(() => {
    commit(UPDATE_TYPING, {
      userId
    });
  }, 6000);
};

const getListFAQCategory = async (_, params) => {
  const { pageId = '', language = '' } = params;
  return await cfs.getlistFAQCategory({
    pageId,
    language
  });
};

const getlistFAQSubCategory = async (_, params) => {
  const { pageId = '', language = '', category = '' } = params;
  return await cfs.getlistFAQSubCategory({
    pageId,
    language,
    category
  });
};

const getTicketActionLogsByConversationId = async ({ commit }, conversationId) => {
  service
    .post('ticketActionLogs/getTicketActionLogs', {
      conversationId
    })
    .then(ticketActionLogs => {
      commit(ADD_TICKET_ACTION_LOG, {
        conversationId,
        ticketActionLogs
      });
      return ticketActionLogs;
    });
};

const getUserStatusWC = (_, { userId, channelId }) => {
  return webChatService.getUserStatus(userId, channelId);
};

const getAgentsMap = (_, { ids, claims }) => {
  if (!ids || !ids.length) return {};
  return service
    .post('agents/getAgentsMap', {
      ids,
      claims
    })
    .catch(e => {
      // eslint-disable-next-line
      console.error('getAgentsMap -> e', e);
      return {};
    });
};

const sendMessageWithConfirm = async (
  { getters, commit, dispatch, rootGetters },
  {
    sender,
    type,
    body,
    text,
    payload,
    payloadToken,
    conversation,
    faqId,
    productIds,
    sendWithConfirm
  }
) => {
  const msg = i18n.t('src.modules.chat.store.actions.are_you_sure_to_send_this_message_to_user');
  const formatMsgText = `
        <ul class="infinite-list" style="overflow: auto">
          <li class="infinite-list-item">
              <p
                style="word-break: break-word; padding-right: 10px"
              >${body}</p>
          </li>
        </ul>`;
  const msgText = `${i18n.t(
    'src.modules.chat.store.actions.are_you_sure_to_send_this_message_to_user'
  )}<br>${formatMsgText}`;

  return baseConfirm({
    message: type === 'text' && !faqId ? msgText : msg,
    dangerouslyUseHTMLString: true,
    preventKeyboard: type === 'text' && !faqId,
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.$refs['confirm'].$el.onclick = (function (e) {
          e = e || window.event;
          if (e.detail !== 0) {
            done();
          }
        })();
      } else {
        done();
      }
    }
  })
    .then(() => {
      if (type === 'text' && !faqId) EventBus.$emit('isSendMessageWithConfirm', true);
      return sendMessage(
        { getters, commit, dispatch, rootGetters },
        {
          sender,
          type,
          body,
          text,
          payload,
          payloadToken,
          conversation,
          faqId,
          productIds,
          sendWithConfirm
        }
      );
    })
    .catch(() => {
      if (type === 'text' && !faqId) EventBus.$emit('isSendMessageWithConfirm', false);
    });
};

const registerUserAutoAssign = async (_, params) => {
  const userService = new Service(URL_USER);
  const data = await userService.post('personal/updatePersonal', { ...params, system: 'AGENT' });
  return data;
};

const getRegisteredUserAutoAssign = async (_, params) => {
  if (!params || !params.userId || !params.channelId || !params.platform || !params.projectId)
    return false;

  const userService = new Service(URL_USER);
  const data = await userService.post('personal/getPersonal', { ...params });
  return data;
};

const newHandleRegisterUser = async (_, params) => {
  if (!params || !params.userId || !params.channelId || !params.agentId || !params.ticketId)
    return false;

  if (!params.reason) {
    params.reason = null;
  }
  if (!params.groupId) {
    params.groupId = null;
  }

  const userService = new Service(URL_USER);
  const data = await userService.post('personal/userRegistration', { ...params });
  return data;
};

const removeUserRegistered = async (_, params) => {
  if (!params || !params.userId || !params.channelId || !params.agentId || !params.ticketId)
    return false;

  if (!params.groupId) {
    params.groupId = null;
  }

  const userService = new Service(URL_USER);
  const data = await userService.post('personal/removeUserRegistered', { ...params });
  return data;
};

const getAgentById = async (_, agentId) => {
  const { Items = [] } = await service.rest('agents/getAgentById', { id: agentId }).catch(() => []);
  const agent = Items && Items.length ? Items[0] : { id: agentId };
  if (!agent.name || !agent.name.trim()) {
    agent.name = `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
  }
  return agent;
};

const getTeleWorkEvidence = async (_, params) => {
  return maintainService.post('file/list', { ...params }).catch(e => {
    // eslint-disable-next-line
    console.error('getTeleWorkEvidence -> e', e);
    return {};
  });
};

const isOverLimitStorage = async (_, params) => {
  return maintainService.post('storage/check', { ...params }).catch(e => {
    // eslint-disable-next-line
    console.error('isOverLimitStorage -> e', e);
    return null;
  });
};

const sendScenarioAfterFinish = async (_, params) => {
  return service
    .rest('conversations/finishWithScenario', {
      payload: { ...params }
    })
    .catch(err => {
      if (err.error) return handleUpdateTicketError(err.error);
      return {};
    });
};
const getConversationsBox = async ({ commit }, params) => {
  try {
    const data = (await service.post('conversations/list_by_channel', params)) || {};
    commit(SET_CONVERSATIONS_BOX, data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('getConversationsBox >> error', error);
    commit(SET_CONVERSATIONS_BOX, {});
    return;
  }
  return;
};

const getConversationsBoxMore = async ({ commit }, params) => {
  try {
    const data = (await service.post('conversations/list_by_channel', params)) || {};
    commit(ADD_CONVERSATIONS_BOX, data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('getConversationsBoxMore >> error', error);
    commit(ADD_CONVERSATIONS_BOX, {});
    return;
  }
  return;
};

const getAgentStatusLog = async (_, params) => {
  return service.post('agents/status_logs_report', { ...params }).catch(e => {
    // eslint-disable-next-line
    console.error('getAgentStatusLog -> e', e);
    return {};
  });
};
const clearConversationsBox = async ({ commit }) => commit(CLEAR_CONVERSATIONS_BOX);

const updateSelectedConvrs = async ({ commit }, val) =>
  commit(UPDATE_STATUS_FOR_SELECT_CONVRS, val);

const sendAgentTyping = async (_, { ticketId, isTyping = false }) => {
  return service.post('messages/agentTypingIndicator', { ticketId, isTyping });
};

const getSurveyTemplate = async (_, params) => {
  const { projectId, language } = params;
  if (!projectId || !language) return null;
  return maintainService
    .get(`survey/fetchSurvey?projectId=${projectId}&language=${language}`)
    .catch(e => {
      // eslint-disable-next-line
      console.error('getSurveyTemplate >> e', e);
      return [];
    });
};

const getSurveyDataAndTeamplateByTicket = async (_, params) => {
  const { language, ticketId } = params;
  if (!language || !ticketId) {
    return null;
  }

  return maintainService
    .get(`survey/getSurveyDataByTicket?ticketId=${ticketId}&language=${language}`)
    .catch(err => {
      // eslint-disable-next-line
      console.error('getSurveyDataAndTeamplateByTicket ==>> err', err);
      return {};
    });
};

const getWebBrowsingHistory = async ({ commit }, params) => {
  return service
    .post('atGetWebHistory', params)
    .then(async res => {
      if (!res || res.errors) {
        console.log('getWebBrowsingHistory >> res.errors', res.errors);
        return {};
      }
      commit(SET_WEB_BROWSING_HISTORY_DATA, res);
      return res;
    })
    .catch(err => {
      // eslint-disable-next-line
      console.log('getWebBrowsingHistory >> err', err);
      return {};
    });
};

const clearWebBrowsingHistory = async ({ commit }) => commit(CLEAR_WEB_BROWSING_HISTORY_DATA);

const setImageOfUrlPreview = async ({ commit }, { url, image }) =>
  commit(SET_IMAGE_OF_URL_PREVIEW, { url, image });

const getSystemDefinedWord = async ({ commit }) => {
  const service = new Service(process.env.VUE_APP_MAINTAIN_SERVICE_ENDPOINT);
  return service
    .get('labels/systemDefinedWord')
    .then(res => {
      if (res.errors) {
        console.log('getSystemDefinedWordOfLabels >> res.errors', res.errors);
        return [];
      }
      commit(SET_SYSTEM_DEFINED_WORD, res);
      return res;
    })
    .catch(err => {
      // eslint-disable-next-line
      console.log('getSystemDefinedWordOfLabels >> err', err);
      return [];
    });
};

export {
  getSystemDefinedWord,
  getWebBrowsingHistory,
  clearWebBrowsingHistory,
  setShowWebBrowsingHistoryPanel,
  getAgentStatusLog,
  getTeleWorkEvidence,
  isOverLimitStorage,
  getAgentsMap,
  getUserStatusWC,
  getFAQsByChannel,
  getListFAQCategory,
  getlistFAQSubCategory,
  setReady,
  getProductFeedsByChannel,
  getListFaqLanguage,
  getConversationFeedsByChannel,
  getMessagesByConversationId,
  getMessagesByTicketId,
  loadMoreMessage,
  loadMoreMessageOfTicketId,
  getConversationTicketById,
  addNewMessage,
  clearNewMessage,
  setSelectedConversation,
  updateSelectedConversation,
  sendMessage,
  setSelectedGroupConversation,
  closeConversation,
  closeConversationByTicketId,
  setConvSearchText,
  setProdSearchText,
  setProdSearchType,
  setProdSearchSort,
  setLocalesToStorage,
  setListLocaleFilter,
  setSearchText,
  setNotifyMessage,
  finishConversation,
  noSupportTicket,
  updateAndEmitTicket,
  supportTicket,
  setInitState,
  removeInitState,
  setShowSupportPanel,
  setShowSurveyPanel,
  setShowProductPanel,
  setShowConversationalPanel,
  setMonitoring,
  setShowView,
  setChatViewingGroup,
  setSearchBoxFilter,
  setSearchBoxHeight,
  getCreateTag,
  getTagsByProjectId,
  removeTicketInTickets,
  getCreateLabel,
  removeUserInUsers,
  setSearchFaqWithLanguage,
  getActivities,
  setSortBy,
  setSupportWithoutUserAgreement,
  uploadFileSharing,
  deleteFileSharing,
  updateMessage,
  deleteMessage,
  setAgentChatMessage,
  updateTyping,
  getTicketActionLogsByConversationId,
  setSearchBox,
  getDashboardReport,
  clearLongestTimeByAgent,
  setCSFPChangeData,
  setShowConfirmSavedData,
  setPreviousAction,
  setMaybeNotAvailable,
  updateUserLabels,
  updateTicketTags,
  updateLabel,
  registerUserAutoAssign,
  getRegisteredUserAutoAssign,
  sendScenarioAfterFinish,
  getConversationsBox,
  getConversationsBoxMore,
  clearConversationsBox,
  sendAgentTyping,
  getSurveyTemplate,
  getSurveyDataAndTeamplateByTicket,
  updConversationInUserInfo,
  setShowStatusHistoryPanel,
  newHandleRegisterUser,
  removeUserRegistered,
  getAgentById,
  updateSelectedConvrs,
  setImageOfUrlPreview
};
