import Service from 'core/service';
const service = new Service();

export const countBotHandle = async ({ projectId, channelId, groupId }) => {
  const data = await service.rest('elasticsearch/count/countBotHandle', {
    projectId,
    channelId,
    groupId
  });
  return data;
};

export const countCommonWaiting = async ({ projectId, channelId, agentId, role, groupId }) => {
  const data = await service.rest('elasticsearch/count/countCommonWaiting', {
    projectId,
    channelId,
    agentId,
    role,
    groupId
  });
  return data;
};

export const countMeWaiting = async ({ projectId, channelId, agentId, groupId }) => {
  const data = await service.rest('elasticsearch/count/countMeWaiting', {
    projectId,
    channelId,
    agentId,
    groupId
  });
  return data;
};

export const countAgentHandle = async ({ projectId, channelId, agentId, groupId }) => {
  const data = await service.rest('elasticsearch/count/countAgentHandle', {
    projectId,
    channelId,
    agentId,
    groupId
  });
  return data;
};

export const countUsersPerAgent = async ({ projectId, agentId }) => {
  const data = await service.rest('elasticsearch/count/countUsersPerAgent', {
    projectId,
    agentId
  });
  return data;
};
export const countMonitoring = async ({ projectId, channelId, groupId }) => {
  const data = await service.rest('elasticsearch/count/countMonitoring', {
    projectId,
    channelId,
    groupId
  });
  return data;
};

export const getFileList = async (channelId, size, ticketIds) => {
  const data = await service.rest('elasticsearch/search/getFileList', {
    channelId,
    size,
    ticketIds
  });
  return data;
};

export const getSearchTickets = async (
  projectId,
  channelId,
  from,
  size,
  conditions,
  tab,
  waitingMode,
  agentId,
  groupId = null
) => {
  const data = await service.rest('elasticsearch/search/getSearchTickets', {
    projectId,
    channelId,
    from,
    size,
    conditions,
    tab,
    waitingMode,
    agentId,
    groupId
  });
  return data;
};

/**
 * Seach Management
 */
export const getTicketsIndexMessages = async (channelId, size, conditions) => {
  const data = await service.rest('elasticsearch/search/getTicketsIndexMessages', {
    channelId,
    size,
    conditions
  });
  return data;
};

export const getTicketsIndexTickets = async (channelId, size, conditions) => {
  const data = await service.rest('elasticsearch/search/getTicketsIndexTickets', {
    channelId,
    size,
    conditions
  });
  return data;
};

export const getTicketsScroll = async (scrollId, size) => {
  const data = await service.rest('elasticsearch/search/getTicketsScroll', {
    size,
    scrollId
  });
  return data;
};
