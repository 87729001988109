<template>
  <div class="container-fluid p-0 page">
    <AppLeftMenu
      show-name="chat"
      @onSelectedConversation="handleOnSelectConversation('url', $event)"
    />
    <aside-left
      v-if="showView !== 'DashBoard'"
      @onSelectConversation="handleOnSelectConversation('click', $event)"
      @onSelectedChannel="handleOnSelectedChannel"
      @hideAllPanel="hideAllPanel"
    />
    <overview-box
      v-if="showView === 'DashBoard'"
      @onSelectedConversation="handleOnSelectConversation('url', $event)"
    />
    <div v-if="isPhoneAgent" class="page-main">
      <phone-agent-chat-box />
    </div>
    <div
      v-else
      :class="[
        'page-main',
        !isOpenConversation || isComment || showView === 'Overview' ? 'no-right' : '',
        showView === 'DashBoard' ? 'd-none' : ''
      ]"
    >
      <chat-box
        v-if="showView === 'Chat'"
        ref="chatBox"
        @onSelectedConversation="handleOnSelectConversation('click', $event)"
        @onProductItemClick="handleProductItemClick"
        @onConversationalItemClick="handleConversationalItemClick"
      />
      <overview-box
        v-if="showView === 'Overview'"
        @onSelectedConversation="handleOnSelectConversation('url', $event)"
      />
      <comment-box v-if="showView === 'Comment'" />
    </div>
    <aside-right
      v-if="(!isComment && ['Chat', 'Conversation'].includes(showView)) || isPhoneAgent"
      :show-overview-box="showOverviewBox"
      :show-support-panel="showSupportPanel"
      :show-survey-panel="showSurveyPanel"
      :show-product-panel="showProductPanel"
      :show-conversational-panel="showConversationalPanel"
      :show-web-browsing-history-panel="showWebBrowsingHistoryPanel"
      :show-status-history-panel="showStatusHistoryPanel"
      :conversation="selectedConversation"
      @onStatusHistoryItemClick="handleStatusHistoryItemClick"
      @onProductItemClick="handleProductItemClick"
      @onSupportItemClick="handleSupportItemClick"
      @onSurveyItemClick="handleSurveyItemClick"
      @onWebBrowsingHistoryItemClick="handleWebBrowsingHistoryItemClick"
      @onConversationalItemClick="handleConversationalItemClick"
    />
    <CustomerSupportPanel
      v-if="
        (showSupportPanel &&
          !isComment &&
          ['Chat', 'Conversation'].includes(showView) &&
          isAgentHandle) ||
        (showSupportPanel && isPhoneAgent)
      "
      :conversation="selectedConversation"
      :is-chat="true"
      @close="hideSupportPanel"
      @savedData="handleSavedData"
      @onSupportItemClick="handleSupportItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <WebBrowsingHistoryPanel
      v-if="
        (showWebBrowsingHistoryPanel &&
          !isComment &&
          ['Chat', 'Conversation'].includes(showView) &&
          !isMonitoringHandle) ||
        (showWebBrowsingHistoryPanel && isPhoneAgent)
      "
      @handleWebBrowsingHistoryItemClick="handleWebBrowsingHistoryItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <survey-form-panel
      v-if="
        (showSurveyPanel && !isComment && ['Chat', 'Conversation'].includes(showView)) ||
        (showSurveyPanel && isPhoneAgent)
      "
      :conversation="selectedConversation"
      :is-chat="true"
      @close="hideSupportPanel"
      @savedData="handleSavedData"
      @onSupportItemClick="handleSurveyItemClick"
      @onNextPageCSH="handleSupportItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <product-panel
      v-if="showProductPanel"
      @onProductItemClick="handleProductItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <conversational-panel
      v-if="showConversationalPanel"
      @onConversationalItemClick="handleConversationalItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <StatusHistoryPanel
      v-if="
        showStatusHistoryPanel &&
        !isComment &&
        ['Chat', 'Conversation'].includes(showView)
      "
      :conversation="selectedConversation"
      @handleStatusHistoryItemClick="handleStatusHistoryItemClick"
      @hideAllPanel="hideAllPanel"
    />
    <transfer-box />
    <assign-box />
    <escalate-box />

    <!-- Replacing Message - Request Form -->
    <RequestForm />
    <!-- check ReSupportWarning -->
    <ReSupportWarning @onSelectedConversation="handleOnSelectConversation('click', $event)" />
    <!-- ForwardGroup -->
    <ForwardGroup />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import AppLeftMenu from 'components/AppLeftMenu';
import {
  AsideLeft,
  AsideRight,
  ChatBox,
  ProductPanel,
  ConversationalPanel,
  CommentBox,
  OverviewBox,
  CustomerSupportPanel,
  SurveyFormPanel,
  WebBrowsingHistoryPanel,
  TransferBox,
  AssignBox,
  EscalateBox,
  PhoneAgentChatBox,
  StatusHistoryPanel
} from './components';
import ForwardGroup from 'components/Modal/ForwardGroup/index';
import ReSupportWarning from 'components/Modal/ReSupportWarning/index';
import RequestForm from 'modules/replacing-message/components/RequestForm';
import { SERVING_STATE, TICKET_STATE, TICKET_STATUS, AGENT_ROLE, TABS } from 'core/constants';
import { END_USER_PLATFORM } from 'core/constants';
import { EventBus } from 'core/eventBus';
import isEmpty from 'lodash/isEmpty';
import { getTabIndex, convertTabIndexToGroupNav } from 'core/helpers';

export default {
  components: {
    AsideLeft,
    AsideRight,
    AppLeftMenu,
    ChatBox,
    ProductPanel,
    ConversationalPanel,
    CommentBox,
    OverviewBox,
    CustomerSupportPanel,
    SurveyFormPanel,
    WebBrowsingHistoryPanel,
    TransferBox,
    AssignBox,
    EscalateBox,
    RequestForm,
    ReSupportWarning,
    ForwardGroup,
    PhoneAgentChatBox,
    StatusHistoryPanel
  },

  data() {
    return {
      savedData: false,
      commentBoxSelected: false,
      conversation: {},
      conversationId: null,
      ticketId: null,
      hydrated: false,
      isComment: false,
      conversationSubscriber: [],
      ticketSubscriber: [],
      messageSubscriber: [],
      eventType: 'click'
    };
  },

  computed: {
    ...mapState('global', { globalReady: 'ready' }),
    ...mapState('session', ['user', 'channels', 'channelsMap', 'projectMaps', 'me']),
    ...mapState('chat', [
      'ready',
      'productFeeds',
      'conversationFeeds',
      'selectedGroupConv',
      'chatViewingGroup',
      'messages',
      'notifyMessage',
      'initState',
      'showSupportPanel',
      'showSurveyPanel',
      'showProductPanel',
      'showConversationalPanel',
      'showWebBrowsingHistoryPanel',
      'showStatusHistoryPanel',
      'showView',
      'finishCnvButClick',
      'csfpChangeData',
      'previousAction',
      'searchBoxFilter'
    ]),
    ...mapState('comment', ['selectedChannel']),
    ...mapState('global', [
      'rightPanelPinned',
      'rightProductPanelPinned',
      'rightConversationPanelPinned',
      'rightCustomerSupportPanelPinned',
      'rightCustomerSurveyPanelPinned',
      'rightWebBrowsingHistoryPanelPinned',
      'rightStatusHistoryPanelPinned'
    ]),
    ...mapGetters('chat', ['currentConversationHandlingBy']),
    ...mapGetters('session', ['conversations', 'isPhoneAgent']),
    ...mapGetters('chat', ['selectedConversation']),

    isAgentHandle() {
      return this.chatViewingGroup === SERVING_STATE.AGENT;
    },

    isMonitoringHandle() {
      return this.chatViewingGroup === SERVING_STATE.MONITORING;
    },

    showOverviewBox() {
      return !this.showChatList && !this.showCommentBox;
    },

    showChatList() {
      return !isEmpty(this.selectedConversation);
    },

    showCommentBox() {
      return this.channelId && this.selectedChannel;
    },

    isOpenConversation() {
      return !this.showOverviewBox;
    }
  },

  watch: {
    chatViewingGroup(value) {
      if (value !== SERVING_STATE.AGENT) {
        this.setShowProductPanel(false);
        this.setShowConversationalPanel(false);
        this.setShowStatusHistoryPanel(false);
        this.pinRightPanel(false);
      }
    },

    notifyMessage(value) {
      if (value && value.messageId) {
        this.scrollToBottom();
        this.setNotifyMessage(null);
      }
    },

    globalReady(value) {
      if (value && this.user && this.user.id && !this.hydrated) {
        if (this.user.role === AGENT_ROLE.CLIENT) {
          this.setShowView('DashBoard');
        }
        this.fetchData();
        this.hydrated = true;
      }
    },
    selectedConversation(newValue, oldValue) {
      if (
        newValue &&
        newValue.ticket &&
        newValue.ticket.assignee === this.user.id &&
        newValue.ticket.state === TICKET_STATE.FINISH &&
        newValue.ticket.status !== TICKET_STATUS.FINISH_TIMEOUT
      ) {
        let notyetShowFTOM = localStorage.getItem('notyetShowFinishTimeOutMessage');
        notyetShowFTOM = notyetShowFTOM ? JSON.parse(notyetShowFTOM) : [];
        if (!notyetShowFTOM.includes(newValue.ticket.id)) {
          notyetShowFTOM.push(newValue.ticket.id);
          localStorage.setItem('notyetShowFinishTimeOutMessage', JSON.stringify(notyetShowFTOM));
        }
      }
      const { ticket: newTicket = {} } = newValue || {};
      const { ticket: oldTicket = {} } = oldValue || {};
      const { state: newState } = newTicket;
      const { state: oldState } = oldTicket;
      if (this.chatViewingGroup !== SERVING_STATE.MONITORING) {
        if (
          (newState !== oldState && newState === TICKET_STATE.FINISH) ||
          (oldState === TICKET_STATE.FINISH && newState === TICKET_STATE.FINISH)
        ) {
          this.setShowProductPanel(false);
          this.setShowConversationalPanel(false);
          this.setShowSupportPanel(false);
          this.handleSupportItemClick();
        }
      }
    },
    isPhoneAgent(nVal, oVal) {
      if (nVal && nVal !== oVal) {
        history.pushState(null, null, '/');
        this.setShowView('PhoneAgentChatBox');
      }
    }
  },

  created() {
    EventBus.$on('phoneAgentHandleWebBrowsingHistoryItemClick', () =>
      this.handleWebBrowsingHistoryItemClick()
    );
    EventBus.$on('phoneAgentHideAllPanel', () => this.hideAllPanel());
  },

  async mounted() {
    const { channelId, conversationId, ticketId } = this.$route.params || {};
    this.eventType = 'url';
    if (Object.keys(this.searchBoxFilter).length > 0) {
      this.setSearchBoxFilter({});
      this.setSearchBoxHeight(95);
    }
    this.conversationId = conversationId;
    this.ticketId = ticketId;
    this.channelId = channelId;
    this.isComment = channelId ? true : false;

    if (this.isComment) {
      this.setShowView('Comment');
    } else {
      if (conversationId) {
        this.setShowView('Chat');
      }
    }
    this.scrollToBottom();
  },

  beforeDestroy() {
    this.setShowSupportPanel(false);
    this.setShowSurveyPanel(false);
    EventBus.$off('phoneAgentHandleWebBrowsingHistoryItemClick');
    EventBus.$off('phoneAgentHideAllPanel');
  },

  methods: {
    ...mapActions('chat', [
      'getMessagesByConversationId',
      'getTicketActionLogsByConversationId',
      'loadMoreMessage',
      'setSelectedConversation',
      'addNewMessage',
      'setReady',
      'setChatReady',
      'getProductFeedsByChannel',
      'getConversationFeedsByChannel',
      'getConversationTicketById',
      'setSelectedGroupConversation',
      'setNotifyMessage',
      'setInitState',
      'setShowSupportPanel',
      'setShowSurveyPanel',
      'setShowProductPanel',
      'setShowConversationalPanel',
      'setShowWebBrowsingHistoryPanel',
      'setShowStatusHistoryPanel',
      'setShowView',
      'setChatViewingGroup',
      'setSearchBoxFilter',
      'setSearchBoxHeight',
      'setCSFPChangeData',
      'setShowConfirmSavedData',
      'setPreviousAction'
    ]),
    ...mapActions('global', ['pinRightPanel', 'setGlobalReady']),
    ...mapActions('comment', ['setSelectedChannel', 'setSelectedComment', 'clearComments']),

    ...mapActions('session', [
      'addNewConversation',
      'addAgentToPeople',
      'addTranslations',
      'addTicketLocal',
      'setChannelTabReload',
      'setWaitingMode',
      'deleteNotification'
    ]),

    async fetchData(groupId) {
      try {
        if (this.conversationId && this.ticketId) {
          this.conversation = this.conversations.find(
            c => c.id === this.conversationId && c.ticketId === this.ticketId
          );
          if (!this.conversation) {
            const hasConversation = await this.getConversationTicketById({
              conversationId: this.conversationId,
              ticketId: this.ticketId
            }).then(conversation => {
              if (!conversation) {
                this.$baseNotification.error({
                  title: this.$t('src.core.App.error'),
                  message: this.$t('src.modules.chat.index.data_of_the_conversation_was_removed')
                });
                return false;
              }
              const { error } = conversation;
              if (error) {
                this.conversationId = null;
                this.conversation = null;
                this.setSelectedGroupConversation(SERVING_STATE.WAITING);
                this.$baseNotification.warning({
                  title: this.$t('src.core.App.warning'),
                  message: error.message
                });
                return this.$router.push('/');
              }

              return conversation;
            });

            if (!hasConversation) {
              this.setGlobalReady(true);
              this.setReady(true);
              return false;
            }

            const { ticket, channelId } = hasConversation || {};
            const { projectId } = ticket || {};
            const tabIndex = getTabIndex({ conv: { ticket }, user: this.user });
            if (this.eventType == 'url') {
              const status = tabIndex == 4 ? 5 : tabIndex;
              this.setSelectedConversation({
                conversation: hasConversation,
                eventType: this.eventType
              });
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: true
              });
            } else {
              const status = tabIndex == 4 ? 5 : tabIndex;
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: false
              });
            }

            this.setSelectedConversation({
              conversation: hasConversation,
              eventType: this.eventType,
              groupId: convertTabIndexToGroupNav(tabIndex)
            });

            if (tabIndex === TABS.WAITING_ME) {
              this.setWaitingMode('TOME');
            }
            if (tabIndex === TABS.WAITING_COMMON) {
              this.setWaitingMode('COMMON');
            }

            await this.getMessages({ id: this.conversationId, projectId }).catch(error => {
              this.$log('TCL: fetchData -> getMessages -> error', error);
            });
          } else {
            const { translations = '' } = this.conversation || {};
            if (!translations) {
              await this.addTranslations(this.conversation);
            }

            const { ticket, channelId } = this.conversation || {};
            const { projectId } = ticket || {};
            const tabIndex = getTabIndex({ conv: { ticket }, user: this.user });
            const status = tabIndex == 4 ? 5 : tabIndex;
            const { grpNavTabIndex } = ticket || {};
            if (
              grpNavTabIndex &&
              grpNavTabIndex === TABS.MONITORING &&
              grpNavTabIndex !== tabIndex
            ) {
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: true
              });
            } else {
              this.setChannelTabReload({
                channelStatus: channelId + status,
                flag: false
              });
            }
            this.setSelectedConversation({
              conversation: this.conversation,
              groupId,
              eventType: this.eventType
            });
            await this.getMessages({ id: this.conversationId, projectId }).catch(error => {
              this.$log('TCL: fetchData -> getMessages -> error', error);
            });
          }
        }

        if (this.channelId) {
          const channel = this.channelsMap[this.channelId];
          if (!channel) return this.$router.push('/');
          const { id = '', platform = '', facebookComment = false } = channel;
          if (platform === 'instagram' && id) {
            // Open comment instragram
          } else if (!facebookComment) return this.$router.push('/');
          this.setSelectedChannel(channel);
          this.setSelectedGroupConversation(SERVING_STATE.COMMENTS);
        }
      } catch (e) {
        this.$baseNotification.error({
          title: this.$t('src.core.App.error'),
          message: this.$t('src.core.App.system_could_not_find_the_selected_conversation')
        });
        this.$log('e: ', e);
        this.$router.push('/');
        this.setGlobalReady(true);
        this.setReady(true);
        return false;
      }
      this.setGlobalReady(true);
      this.setReady(true);
      return true;
    },

    handleProductItemClick() {
      const { channelId } = this.selectedConversation || {};
      const { id } = this.selectedChannel;
      if (!channelId && !id) {
        this.$baseNotification.info({
          title: this.$t('src.core.App.info'),
          message: this.$t('src.modules.chat.index.please_select_one_channel_or_conversation')
        });
        return;
      }
      this.showProductPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightProductPanelPinned);
      this.setShowProductPanel(!this.showProductPanel);
      this.setShowConversationalPanel(false);
      this.setShowSupportPanel(false);
      this.setShowSurveyPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
      this.setShowStatusHistoryPanel(false);
    },

    handleSupportItemClick() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.showSupportPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightCustomerSupportPanelPinned);
      this.setShowSupportPanel(!this.showSupportPanel);
      this.setShowProductPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowSurveyPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
      this.setShowStatusHistoryPanel(false);
    },

    handleSurveyItemClick() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.showSurveyPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightCustomerSurveyPanelPinned);
      this.setShowSurveyPanel(!this.showSurveyPanel);
      this.setShowProductPanel(false);
      this.setShowSupportPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
      this.setShowStatusHistoryPanel(false);
    },

    handleWebBrowsingHistoryItemClick() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.showWebBrowsingHistoryPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightWebBrowsingHistoryPanelPinned);
      this.setShowWebBrowsingHistoryPanel(!this.showWebBrowsingHistoryPanel);
      this.setShowSurveyPanel(false);
      this.setShowProductPanel(false);
      this.setShowSupportPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowStatusHistoryPanel(false);
    },

    handleStatusHistoryItemClick() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.showStatusHistoryPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightStatusHistoryPanelPinned);
      this.setShowStatusHistoryPanel(!this.showStatusHistoryPanel);
      this.setShowSurveyPanel(false);
      this.setShowProductPanel(false);
      this.setShowSupportPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
    },

    handleConversationalItemClick() {
      const { channelId } = this.selectedConversation || {};
      const { id } = this.selectedChannel;
      if (!channelId && !id) {
        this.$baseNotification.info({
          title: this.$t('src.core.App.info'),
          message: this.$t('src.modules.chat.index.please_select_one_channel_or_conversation')
        });
        return;
      }
      this.showConversationalPanel
        ? this.pinRightPanel(false)
        : this.pinRightPanel(this.rightConversationPanelPinned);
      this.setShowConversationalPanel(!this.showConversationalPanel);
      this.setShowSurveyPanel(false);
      this.setShowProductPanel(false);
      this.setShowSupportPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
      this.setShowStatusHistoryPanel(false);
    },

    hideAllPanel() {
      this.setShowStatusHistoryPanel(false);
      this.setShowWebBrowsingHistoryPanel(false);
      this.setShowSurveyPanel(false);
      this.setShowProductPanel(false);
      this.setShowSurveyPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowSupportPanel(false);
      this.pinRightPanel(false);
      this.savedData = undefined;
      this.setCSFPChangeData(undefined);
    },

    /**
     * Click on Navigation Group.
     */
    handleOnSelectConversation(eventType, { type, conversation, model, event }) {
      this.eventType = eventType;
      this.setPreviousAction({
        action: 'handleOnSelectConversation',
        params: { type, conversation }
      });

      if (this.csfpChangeData && !model) {
        EventBus.$emit('confirmSavedData', true);
      } else {
        this.hideAllPanel(); // Hide all panel when change conversation
        if (!this.user || !this.user.id) return;
        const { scrollToBottom = false } = conversation || {};
        if (scrollToBottom) {
          this.scrollToBottom();
        }

        let { ticketId: curTicketId } = this.$route.params || {};
        if (this.selectedConversation && this.selectedConversation.ticket)
          curTicketId = this.selectedConversation.ticket.id;

        const {
          id: conversationId,
          ticketId,
          groupId,
          ticket: { state = '' } = {}
        } = conversation || {};
        const toGroup = groupId >= 0 ? groupId : this.selectedGroupConv;
        if (curTicketId !== ticketId || toGroup !== this.chatViewingGroup) {
          this.isComment = false;
          this.channelId = null;
          this.setSelectedChannel({});
          this.pinRightPanel(false);
          this.setReady(false);
          this.setShowView('Chat');
          this.conversationId = conversationId;
          this.ticketId = ticketId;

          this.fetchData(toGroup).then(rltFetchData => {
            if (rltFetchData) {
              const path = `/c/${conversationId}/${ticketId}`;
              history.pushState(null, null, path);
            }
          });
        } else {
          if (event === 'hideSupportPanel') return null;
          const { ticket: { projectId = '' } = {} } = conversation || {};
          this.setReady(false);
          this.getMessages({ id: conversationId, projectId })
            .then(() => this.setReady(true))
            .catch(() => this.setReady(true));
        }
        if (![TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(state)) {
          this.deleteNotification({ agentId: this.user.id, ticketId });
        }
      }
    },

    handleOnSelectedChannel({ channelId, isComment }) {
      if (isComment) {
        this.setReady(false);
        this.isComment = true;
        this.setShowView('Comment');
        this.setChatViewingGroup(SERVING_STATE.COMMENTS);

        this.channelId = channelId;
        const channel = this.channels.find(c => c.id == channelId);
        this.setSelectedChannel(channel);
        this.setSelectedComment(null);
        this.clearComments();
        const path = `/com/${channelId}`;
        history.pushState(null, null, path);
      }
    },

    scrollToBottom() {
      if (!this.$refs.chatBox) return;
      this.$refs.chatBox.scrollToBottom();
    },

    getMessages(params) {
      return this.getMessagesByConversationId(params).then(() => {
        this.scrollToBottom();
        return Promise.resolve(true);
      });
    },
    hideSupportPanel(value) {
      this.savedData = value;
      this.handleSupportItemClick();
      this.setCSFPChangeData(value);
      switch (this.previousAction.action) {
        case 'handleOnSelectConversation':
          this.handleOnSelectConversation(
            this.previousAction.params.type,
            {
              type: 'click',
              conversation: this.previousAction.params.conversation,
              event: 'hideSupportPanel'
            },
            true
          );
          break;

        default:
          break;
      }
    },
    handleSavedData(value) {
      this.savedData = value;
      this.setCSFPChangeData(value);
    },
    isEndUser(platform) {
      return END_USER_PLATFORM.includes(platform);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    if (this.savedData) {
      EventBus.$emit('confirmSavedData');
    }
    return next();
  },

  async beforeRouteLeave(to, from, next) {
    this.hideAllPanel();
    if (this.savedData) {
      EventBus.$emit('confirmSavedData');
      return next(false);
    }
    return next();
  }
};
</script>

<style lang="scss">
@import 'assets/scss/chat/chat.scss';
</style>
