<template>
  <ValidationProvider
    :vid="`${name}-${prefix}`"
    :name="`${name}-${prefix}`"
    :rules="c_rules"
  >
    <div v-if="align === 'vertical'">
      <label
        class="nat-label small"
        v-if="
          (c_contentLength > 0 && showWordLimit) ||
            showLabel === true ||
            tooltipText !== ''
        "
      >
        <span v-if="required" class="nat-required">*</span>
        <span v-if="label !== '' && showLabel == true"> {{ label }}</span>

        <base-tooltip-icon
          :id="`tooltip-${name}-${prefix}`"
          :name="`tooltip-${name}-${prefix}`"
          :content="tooltipText"
          :icon="tooltipIcon"
        />
        <span
          v-if="c_contentLength > 0 && showWordLimit && maxlength > 0 && !disabled"
          :class="['nat-maxlengthTag', 'small']"
        >
          <span
            :class="[
              `${
                maxlength - c_contentLength < 0 || c_contentLength - minlength < 0
                  ? 'error'
                  : ''
              } `
            ]"
            >{{ c_contentLength }}</span
          >
          &nbsp;/&nbsp;{{ maxlength }}
        </span>
      </label>
      <div class="autocomplete">
        <input
          ref="input"
          class="nat-input-verti"
          :disabled="disabled"
          :maxlength="maxlength"
          :placeholder="disabled ? '' : c_placeholder"
          type="text"
          v-model="content"
          @compositionupdate="handecomposeUpdate"
          @compositionend="handecomposeEnd"
          @change="handleChange(content)"
          @input="handleInput(content)"
          @blur="handleBlur"
          @keyup="handleKeyup"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keypress.enter="onEnter"
          @focus="handleFocus"
        />
        <i v-show="c_isOpen" class="results-arrow up"></i>
        <ul
          ref="autocompleteResults"
          id="autocomplete-results"
          v-show="c_isOpen"
          class="autocomplete-results"
        >
          <li class="loading" v-if="isLoading">Loading results...</li>
          <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            @mousedown="setResult(result)"
            class="autocomplete-result"
            ref="autocompleteResult"
            :class="{ 'is-active': i === arrowCounter }"
          >
            {{ result }}
          </li>
        </ul>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import './vee-validate.js';
import { convert2OneByte } from './vee-validate';

export default {
  name: 'base-input-autocomplete',

  props: {
    align: {
      type: String,
      default: 'vertical'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'name'
    },
    prefix: {
      type: String,
      default: 'prefix'
    },
    maxlength: {
      type: Number,
      default: 100
    },
    minlength: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    placeholderType: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    tooltipIcon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    showWordLimit: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      isOpen: false,
      results: [],
      content: this.value,
      compositionData: '',
      isLoading: false,
      arrowCounter: 0,
      isSelect: false
    };
  },

  computed: {
    c_arrRule() {
      return this.rules.split('|');
    },
    c_contentLength() {
      return Number(this.content.length) + Number(this.compositionData.length);
    },
    c_isEmail() {
      return this.c_arrRule.includes('email');
    },
    c_isPhonenumber() {
      return this.c_arrRule.includes('phoneNumber');
    },
    c_isPostCode() {
      return this.c_arrRule.includes('postCode');
    },
    c_isCountryCode() {
      return this.c_arrRule.includes('countryCode');
    },
    c_rules() {
      let allRule = {};
      if (this.required) allRule.baseRequired = { label: this.label };

      if (
        !this.c_arrRule.includes('phoneNumber') &&
        !this.c_arrRule.includes('postCode') &&
        !this.c_arrRule.includes('countryCode')
      ) {
        if (this.maxlength && this.maxlength > 0) {
          allRule.baseMaxLength = { length: this.maxlength, label: this.label };
        }
        if (this.minlength && this.minlength > 0) {
          allRule.baseMinLength = { length: this.minlength, label: this.label };
        }
      }

      this.c_arrRule.map(rule => {
        switch (rule) {
          case 'email':
            allRule.baseEmail = { label: this.label };
            break;
          case 'phoneNumber':
            allRule.basePhoneNumber = { label: this.label };
            break;
          case 'numeric':
            allRule.baseNumeric = { label: this.label };
            break;
          case 'postCode':
            allRule.basePostCode = { label: this.label };
            break;
          case 'countryCode':
            allRule.baseCountryCode = { label: this.label };
            break;
          case 'folderName':
            allRule.baseFolderName = { label: this.label };
            break;

          default:
            break;
        }
      });
      return allRule;
    },
    c_placeholder() {
      if (this.disabled === true) return '';
      if (this.placeholder) return this.placeholder;
      else {
        if (this.placeholderType === 'search')
          return this.$t('veevalidate.search.placeholder', { fieldName: this.label });
        else return this.$t('veevalidate.placeholder', { fieldName: this.label });
      }
    },
    c_hintText() {
      if (this.hintText !== '') return this.hintText;
      else {
        if (this.c_arrRule.includes('phoneNumber'))
          return this.$t('veevalidate.phoneNumber.ex');
        if (this.c_arrRule.includes('postCode'))
          return this.$t('veevalidate.postCode.ex');
        if (this.c_arrRule.includes('countryCode'))
          return this.$t('veevalidate.countryCode.ex');
        if (this.c_arrRule.includes('email')) return this.$t('veevalidate.email.ex');
        return this.hintText;
      }
    },

    c_labelWidthStyle() {
      return this.labelWidth > 0
        ? {
            width: this.labelWidth + 'px'
          }
        : '';
    },
    c_isOpen() {
      return this.isOpen && this.results.length > 0;
    }
  },
  watch: {
    value(value) {
      this.content = value;
    },

    items: function(val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.results = val;
        this.isLoading = false;
      }
    }
  },

  created() {
    this.filterResults();
    this.isOpen = true;
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    scrollHighlight(index) {
      if (index < 0) {
        return;
      }
      if (index >= this.results.length) {
        index = this.results.length - 1;
      }
      let suggestion = this.$refs.autocompleteResults;
      let highlightItem = this.$refs.autocompleteResult[index];
      let scrollTop = suggestion.scrollTop;
      let offsetTop = highlightItem.offsetTop;

      if (offsetTop + highlightItem.scrollHeight > scrollTop + suggestion.clientHeight) {
        suggestion.scrollTop += highlightItem.scrollHeight;
      }
      if (offsetTop < scrollTop) {
        suggestion.scrollTop -= highlightItem.scrollHeight;
      }
    },

    filterResults() {
      if (this.content == '') {
        this.results = this.items;
      } else {
        this.results = this.items.filter(item => {
          return item.toLowerCase().indexOf(this.content.toLowerCase()) > -1;
        });
      }
    },

    setResult(result) {
      this.content = result;
      this.$emit('input', this.content);
      this.$emit('select', this.content);
      this.isOpen = false;
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
      this.scrollHighlight(this.arrowCounter);
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
      this.scrollHighlight(this.arrowCounter);
    },
    onEnter(e) {
      if (
        this.c_isOpen &&
        this.arrowCounter >= 0 &&
        this.arrowCounter < this.results.length
      ) {
        e.preventDefault();
        this.content = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
        this.$emit('select', this.content);
        this.$emit('input', this.content);
      } else {
        this.isOpen = false;
        this.arrowCounter = -1;
        this.$emit('select', this.content);
        this.$emit('input', this.content);
      }
    },

    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    handleInput(value) {
      let valiValue = value;
      /**
       * fixed rules
       */
      // 1. Accept to input one space between 2 words/characters only
      if (/\s\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\s\s+/g, ' ');
      }
      // 2. Must not accept tab
      if (/\t+/g.test(valiValue)) {
        valiValue = valiValue.replace(/\t+/g, ' ');
      }
      // 2.Must not accept space only without EN, JA, CH characters
      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      if (/^\s+/g.test(valiValue)) {
        valiValue = valiValue.replace(/^\s+/g, '');
      }

      if (this.c_isPhonenumber) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isPostCode) {
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isCountryCode) {
        valiValue = valiValue.toUpperCase();
        valiValue = convert2OneByte(valiValue);
      }
      if (this.c_isEmail) {
        valiValue = convert2OneByte(valiValue);

        //1. Not accept to input emojis in local part and domain name
      }

      this.content = valiValue;
      this.$emit('input', this.content);
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        // Let's  our flat array
        this.filterResults();
        this.isOpen = true;
      }
    },
    handleFocus() {
      this.filterResults();
      this.isOpen = true;
    },
    handleChange() {
      this.$emit('change', this.content);
    },
    handecomposeUpdate(compositionEvent) {
      this.compositionData = compositionEvent.data || '';
    },
    handecomposeEnd() {
      this.compositionData = '';
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    }
  }
};
</script>
<style lang="scss" scoped>
label {
  margin-bottom: 0px !important;
}
.nat-label {
  display: block;
  text-align: left;
  &.small {
    line-height: 8px;
  }
}

.nat-input-group-prepend {
  display: flex;
  margin-right: -1px;
  margin-top: 5px;
}
.nat-input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  border-right: 0;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.nat-input-group-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 5px;
  background-color: #fff;
  border: 0;
}
.nat-input-group-value {
  flex: 1 1 auto;
  width: 1%;
}
.nat-input-group-value-maxlength {
  position: absolute;
  top: -14px;
}
.nat-input-hori {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}
.nat-input-verti {
  font-size: 12px;
  height: 23px !important;
  line-height: 25px !important;
  width: 350px;
  padding-right: 40px;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 40px;
  min-height: 23px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.nat-input-verti-no-label {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid #409eff;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.red-border {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid red;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &:focus {
    outline: none;
    border: 1px solid red;
  }
  &:disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
  &::-moz-placeholder {
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    color: #cccccc;
  }
  &::-ms-input-placeholder {
    color: #cccccc;
  }
  &::placeholder {
    color: #cccccc;
  }
}

.nat-required {
  color: red;
}

.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 10.5px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: 0.7;
  &.small {
    font-size: 7px;
    font-weight: bold;
    opacity: 1;
  }
}
.nat-maxlengthTag .error {
  color: red;
}

.nat-hint-text {
  color: rgb(153, 153, 153);
  font-size: 13px;
}
</style>

<style lang="scss" scoped>
.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
  .results-arrow {
    border: solid #eeeeee;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    background: #fff;
    z-index: 9998;
    left: 35px;
    position: absolute;
    margin-top: 4px;
    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
  .autocomplete-results {
    position: absolute;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 185px;
    overflow: auto;
    width: 100%;
    background: #fff;
    z-index: 9997;
    margin-top: 9px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}
.autocomplete-results li {
  text-overflow: ellipsis;
  padding: 0 20px;
  margin: 0;
  line-height: 34px;
  cursor: pointer;
  color: #606266;
  font-size: 14px;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #eeeeee;
}
</style>
