<template>
  <b-card no-body header-tag="header">
    <h6 slot="header" v-b-toggle.clRequestInformation class="mb-0">
      <i :class="showCollapse ? `fa fa-chevron-down` : `fa fa-chevron-right`"></i>
      {{
        $t(
          'src.modules.chat.components.CustomerSupportPanel.requestInformation.request_information'
        )
      }}
    </h6>
    <b-collapse id="clRequestInformation" v-model="showCollapse">
      <b-card-body>
        <!-- Breadcrumb category tree -->
        <b-breadcrumb v-if="categoryPath && categoryPath.length > 0" :items="categoryPath" />

        <b-row>
          <!-- Category tree -->
          <b-col v-if="!item" cols="md-12">
            <div class="info-container">
              <label class="d-block mb-1">
                {{ labelCategory }}
                <strong style="color: red">*</strong>
              </label>
              <base-input
                v-model="treeFilter"
                :name="'filter-file'"
                :label="labelCategory"
                :icon="'search'"
                :align="'horizontal'"
                :maxlength="-1"
                :show-label="false"
                :placeholder-type="'search'"
                :autocomplete="'off'"
              />
              <tree
                class="zxczxczxc"
                ref="tree"
                v-model="selectedNode"
                :options="categoryTreeOptions"
                :filter="treeFilter"
              >
                <span slot-scope="{ node }" class="tree-text">
                  <template v-if="!node.hasChildren() && node.text !== '...'">
                    <i class="ion-md-document"></i>
                    {{ node.text }}
                  </template>

                  <template v-if="!node.hasChildren() && node.text === '...'">
                    <i class="ion-md-close-circle"></i>
                    {{ node.text }}
                  </template>

                  <template v-if="node.hasChildren()">
                    <i :class="[node.expanded() ? 'ion-md-folder-open' : 'ion-md-folder']"></i>
                    {{ node.text }}
                  </template>
                </span>
              </tree>
              <div v-if="!isSelectedNode" class="nat-invalid-data">
                {{ hintTextCategory }}
              </div>
            </div>
          </b-col>
          <!-- Customer Fields -->
          <b-col cols="md-12">
            <ValidationObserver ref="CSPRequestInformation">
              <form role="form" autocomplete="off">
                <div class="row">
                  <template v-for="caf in customerAllFields">
                    <div
                      v-for="(c, idx) in caf"
                      :key="c.key"
                      :class="[
                        'col-6',
                        c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label,
                        'nat-h-center'
                      ]"
                    >
                      <!-- Only Label -->
                      <p v-if="c.type === 'label'" class="font-weight-bold">
                        {{
                          c.label && c.label[displayLanguage] ? c.label[displayLanguage] : c.label
                        }}
                      </p>

                      <!-- textbox | combobox | textarea | datepicker-->
                      <div v-if="c.type !== 'label'" class="form-group">
                        <div class="form-group">
                          <base-input
                            :data-test-id="`csf-${c.rules}`"
                            :prefix="'rqtInfo'"
                            v-if="c.type === 'text' && c.key"
                            v-model="formData[c.key]"
                            :required="c.required"
                            :rules="c.rules"
                            :name="c.key"
                            :placeholder="c.placeholder"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            :maxlength="c.maxlength"
                            :disabled="disabledForm"
                            :autocomplete="'off'"
                          />
                          <base-input
                            :data-test-id="`csf-${c.rules}`"
                            :prefix="'rqtInfo'"
                            v-if="c.type === 'text' && !c.key"
                            v-model="formData[idx]"
                            :required="c.required"
                            :rules="c.rules"
                            :name="idx"
                            :placeholder="c.placeholder"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            :maxlength="c.maxlength"
                            :disabled="disabledForm"
                            :autocomplete="'off'"
                          />
                          <!-- textarea -->
                          <base-textarea
                            :prefix="'rqtInfo'"
                            v-if="c.type === 'textarea'"
                            v-model="formData[c.key]"
                            :required="c.required"
                            :rules="c.rules"
                            :name="c.key"
                            :id="c.key"
                            :placeholder="c.placeholder"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            :maxlength="c.maxlength"
                            :disabled="disabledForm"
                          />
                          <base-date-picker
                            :prefix="'rqtInfo'"
                            v-if="['datepicker', 'datetimepicker'].includes(c.type)"
                            v-model="formData[c.key]"
                            :required="c.required"
                            :rules="c.rules"
                            :name="c.key"
                            :id="c.key"
                            :locale="getLocale"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            :disabled="disabledForm"
                          />

                          <!-- Radio -->
                          <base-radio-group
                            :ref="c.prop === 'sex' ? c.key : ''"
                            :prefix="'rqtInfo'"
                            v-if="c.type === 'radio'"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            v-model="formData[c.key]"
                            :name="c.prop === 'sex' ? c.prop : c.key"
                            :options="
                              optionsDataRadio(
                                c.options && c.options[displayLanguage]
                                  ? c.options[displayLanguage]
                                  : c.options
                              )
                            "
                            :max-other-value="100"
                            :allow-show-word-limit="true"
                            :err-mess-other-value="
                              c.prop === 'sex'
                                ? $t(
                                    'src.modules.profile.components.user-information.please_enter_your_gender'
                                  )
                                : ''
                            "
                            :option-lable="'value'"
                            :option-value="'key'"
                            :disabled="disabledForm"
                            :required="c.required"
                            :radio-other-value-error="radioOtherValueError"
                            :full-width-radio="c.prop === 'sex' ? true : false"
                            @inputOtherValue="inputOtherValue"
                          />

                          <!-- checkbox -->
                          <base-checkbox
                            :prefix="'rqtInfo'"
                            :required="c.required"
                            v-if="c.type === 'checkbox'"
                            :name="c.key"
                            v-model="formData[c.key]"
                            :disabled="disabledForm"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                          />

                          <base-select
                            :data-test-id="`csf-${c.rules}`"
                            :prefix="'rqtInfo'"
                            v-if="c.type === 'combobox'"
                            v-model="formData[c.key]"
                            :options="optionsData(c)"
                            :name="c.key"
                            :id="c.key"
                            :value-type="'String'"
                            :option-type="'Object'"
                            :option-lable="'value'"
                            :option-value="'key'"
                            :label="
                              c.label && c.label[displayLanguage]
                                ? c.label[displayLanguage]
                                : c.label
                            "
                            :required="c.required"
                            :disabled="disabledForm"
                            @change="changeCombo(c, $event)"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import _ from 'lodash';
import store from 'core/store';
import { setTimeout } from 'timers';
import 'components/BaseComponents/vee-validate.js';
import { SERVING_STATE, STATUS_SUBMIT_CSF } from 'core/constants';
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    information: { type: Object, default: () => {} },
    conversation: { type: Object, default: () => {} },
    item: { type: Object, default: () => {} },
    projectId: { type: String, default: '' }
  },
  data() {
    return {
      initCategoryPath: '',
      initFormData: {},
      categoryPath: [],
      displayMessage: '',
      itemData: {},
      isView: true,
      isUpdate: false,
      selectedNode: null,
      components: {},
      formData: {},
      lstCountries: [],
      lstProvinces: [],
      categoryTreeOptions: {
        store: {
          store: this.$store,
          getter: () => {
            return store.getters['customerSupport/tree'];
          },
          dispatcher(tree) {
            store.dispatch('customerSupport/updateTree', tree);
          }
        },
        filter: {
          emptyText: this.$t('common.text.no_data'),
          matcher(query, node) {
            const queryTrim = query.trim();
            return node.text.toLowerCase().indexOf(queryTrim.toLowerCase()) >= 0;
          }
        }
      },
      showCollapse: true,
      customerSupportId: null,
      treeFilter: '',
      validPhoneNumber: true,
      invalidPhoneErrorMsg: this.$t(
        'src.modules.profile.components.user-information.please_input_the_contact_number'
      ),
      countryKey: '',
      statePrefectureKey: '',
      isSelectedNode: true,
      editSubmittedForm: false,
      radioOtherValueError: false
    };
  },
  computed: {
    ...mapState('customerSupport', ['openCSFMode']),
    ...mapState('customerSupport', [
      'customerFields',
      'categories',
      'fixedFields',
      'template',
      'countries',
      'provinces',
      'categoryLabel'
    ]),
    ...mapState('session', ['channelsMap', 'user', 'tickets']),
    ...mapState('chat', ['selectedGroupConv']),
    ...mapState('personal', ['supportLanguages', 'defaultLanguage']),
    ...mapGetters('customerSupport', ['tree']),
    ...mapGetters('session', ['conversations']),
    labelCategory() {
      return this.categoryLabel && this.categoryLabel[this.displayLanguage]
        ? this.categoryLabel[this.displayLanguage]
        : this.categoryLabel;
    },
    hintTextCategory() {
      return this.$t('veevalidate.category.ex', { categoryName: this.labelCategory });
    },
    disabledForm() {
      if (this.openCSFMode === 'view' && !this.editSubmittedForm) return true;
      return false;
    },
    defaultLanguageChannel() {
      const { channelId = '' } = this.conversation;
      const { defaultLanguage = 'en_US' } = this.channelsMap[channelId];
      return defaultLanguage;
    },
    displayLanguage() {
      const { channelId = '' } = this.conversation;
      const channel = this.channelsMap[channelId] || {};
      const { advancedConfigs: { supportLanguages = [] } = {}, defaultLanguage = 'en_US' } = channel;
      const lang = localStorage.getItem('language');
      const operationLanguage = lang.replace('-', '_');
      return supportLanguages.includes(operationLanguage) ? operationLanguage : defaultLanguage;
    },
    customerAllFields() {
      let sortOrderFixedFields = null;
      if (_.isEmpty(this.fixedFields)) sortOrderFixedFields = {};
      sortOrderFixedFields = Object.keys(this.fixedFields)
        .sort((a, b) => {
          return this.fixedFields[a]['order'] - this.fixedFields[b]['order'];
        })
        .reduce((res, i) => {
          res = {
            ...res,
            [this.fixedFields[i]['order']]: { ...this.fixedFields[i], prop: i }
          };
          return res;
        }, {});
      const raw = {
        ...[
          this.m_getFiledInUsed(sortOrderFixedFields, true),
          this.m_getFiledInUsed(this.customerFields, false)
        ]
      };
      return raw;
    },
    getLocale() {
      return this.displayLanguage.split('_')[0] || 'en';
    }
  },
  watch: {
    selectedGroupConv() {
      this.treeFilter = '';
    },

    async information(newValue, oldValue) {
      //start setCFSMode
      this.setCSFMode('edit');
      if (_.isEqual(newValue, oldValue)) return null;
      const { status = '', isAllowEdit } = newValue || {};
      if (
        status === 'submited' ||
        (status === 'draft' && (newValue || {}).hasOwnProperty('isAllowEdit') && !isAllowEdit)
      ) {
        this.setCSFMode('view');
      }
      //end setCFSMode

      const value = { ...newValue };
      if (value.id) {
        const params = { id: value.id };
        const editData = await this.getCustomerSupportById(params);
        await this.getTemplateContent(editData);
        await this.updateTree([...this.categories]);
        setTimeout(() => this.mapEditData(editData), 100);
        this.isUpdate = true;
        if (!this.showCollapse) this.showCollapse = true;
      } else {
        const { projectId, groupId } = this.conversation.ticket;
        await this.getCustomerSupportSettingsByKey({ projectId, groupId });
        await this.updateTree([...this.categories]);
        this.categoryPath = 0;
        this.formData = {};
        if (this.$refs.tree) this.$refs.tree.selected().unselect();
        this.isUpdate = false;
      }
    },

    selectedNode(value) {
      if (!value) return false;
      if (value.length > 0) this.isSelectedNode = true;
      if (this.selectedNode[0]) {
        const fullPath = [this.selectedNode[0].text];
        this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
        this.categoryPath = fullPath.map(category => {
          return {
            text: category,
            active: true
          };
        });
        if (fullPath.join(' ▶ ') !== this.initCategoryPath && !this.isView) {
          this.$emit('checkEditData', true);
        }
      }
    },

    formData: {
      async handler(newValue) {
        if (!_.isEqual(this.initFormData, newValue) && !this.isView) {
          this.$emit('checkEditData', true);
        } else {
          this.$emit('checkEditData', false);
        }
      },
      deep: true
    },

    isView(value) {
      this.$emit('checkHandleSubmitForm', {
        isView: value
      });
      this.$emit('checkHandleSubmitToClose', {
        isView: value
      });
    }
  },
  async created() {
    this.categoryPath = [];
    this.selectedNode = null;

    const { projectId, groupId } = this.conversation.ticket;
    this.initFormData = _.clone(this.initForm || {});
    if (!this.item) {
      if (this.information.id) {
        this.showCollapse = true;
        const params = { id: this.information.id };
        const editData = await this.getCustomerSupportById(params);
        await this.getTemplateContent(editData);
        await this.updateTree([...this.categories]);
        setTimeout(async () => {
          this.mapEditData(editData);
        }, 200);
        this.isUpdate = true;
      } else {
        await this.getCustomerSupportSettingsByKey({ projectId, groupId });
        await this.updateTree([...this.categories]);
        this.showCollapse = true;
        this.formData = {};
        if (this.$refs.tree) this.$refs.tree.selected().unselect();
        this.isUpdate = false;
      }
    } else {
      this.showCollapse = true;
      const params = { id: this.item.id };
      const editData = await this.getCustomerSupportById(params);
      this.projectId = editData.projectId;
      setTimeout(async () => {
        this.mapEditData(editData);
      }, 100);
    }
  },

  async mounted() {
    const countries = await this.getCountries({ language: this.displayLanguage });
    this.lstCountries = countries.map(country => {
      return { key: country.code, value: country.name };
    });
    EventBus.$on('newRequest', () => {
      this.isView = false;
    });
    EventBus.$on('submitCustomerSupportPanelClose', status => {
      this.handleSubmitToClose(status);
    });
    EventBus.$on('editSubmittedFormMode', value => {
      this.isView = !value;
      this.editSubmittedForm = value;
    });
    EventBus.$on('updateCustomerSupportPanel', status => {
      this.handleUpdateSubmitForm(status);
    });
  },
  beforeDestroy() {
    EventBus.$off('newRequest');
    EventBus.$off('submitCustomerSupportPanelClose');
    EventBus.$off('editSubmittedFormMode');
    EventBus.$off('updateCustomerSupportPanel');
    this.categoryPath = [];
    this.selectedNode = null;
    this.clearTree();
  },
  methods: {
    ...mapActions('customerSupport', [
      'submitForm',
      'getCustomerSupportById',
      'updateTree',
      'clearTree',
      'getCountries',
      'getProvinces',
      'getCustomerSupports',
      'setCSFMode',
      'getTemplateContent'
    ]),
    ...mapActions('customerSupport', ['getCustomerSupportSettingsByKey']),
    ...mapActions('chat', [
      'closeConversation',
      'closeConversationByTicketId',
      'setReady',
      'setSelectedConversation',
      'setShowView',
      'setShowSupportPanel',
      'setShowProductPanel',
      'setShowConversationalPanel'
    ]),
    ...mapActions('global', ['pinRightPanel']),

    async changeCombo(cpn, value) {
      const { prop } = cpn;
      if (prop === 'country') {
        this.formData = { ...this.formData, ...{ [this.statePrefectureKey]: '' } };
        if (value) {
          const provinces = await this.getProvinces({
            code: value,
            language: this.displayLanguage
          });
          this.lstProvinces = provinces.map(province => {
            return { key: province.code, value: province.name };
          });
        } else {
          this.lstProvinces = [];
        }
      }
    },
    optionsData(c) {
      if (c.prop && c.prop === 'country') {
        this.countryKey = c.key;
        return this.lstCountries;
      }
      if (c.prop && c.prop === 'statePrefecture') {
        this.statePrefectureKey = c.key;
        return this.lstProvinces;
      }
      if (c.type === 'combobox') {
        const options = c.options;
        const optionsDefaultLanguage = options[this.displayLanguage];
        if (optionsDefaultLanguage) {
          if (optionsDefaultLanguage && optionsDefaultLanguage.length > 0) {
            return optionsDefaultLanguage.map((item, index) => {
              return { value: item, key: index.toString() };
            });
          }
        } else if (options && Array.isArray(options)) {
          return options.map((item, index) => {
            return { value: item, key: index.toString() };
          });
        } else if (options && typeof options === 'object') {
          const a = Object.keys(options).map(key => {
            return { value: options[key], key: key };
          });
          return a;
        }
      }
    },
    optionsDataRadio(data) {
      if (!data && data.length <= 0) return [];
      const result = data.map((item, index) => {
        if (index.toString() === '2') {
          return { value: '', title: item, key: index.toString() };
        }
        return { value: item, key: index.toString() };
      });
      return result;
    },
    inputOtherValue(value) {
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      if (gender?.key !== '2') return (this.radioOtherValueError = true);
      this.radioOtherValueError = false;
      this.formData[key].value = value;
    },
    async handleSubmitForm() {
      this.handleDataAndSubmit('handleSubmitForm');
    },
    async handleSubmitToClose(status) {
      this.handleDataAndSubmit('handleSubmitToClose', status);
    },
    async handleDataAndSubmit(type, status) {
      /**
       * status
       * = true: when click submit and profile saved successfully
       * = false: when click submit and profile saved failed
       * = undefined: when click save draft
       */
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      const requestInformation = this.$refs['CSPRequestInformation'];

      requestInformation &&
        requestInformation.validate().then(async result => {
          if (
            !result ||
            !this.selectedNode ||
            (this.selectedNode && !this.selectedNode.length) ||
            (this.selectedNode &&
              this.selectedNode.length &&
              !this.selectedNode[0].data.activeNode) ||
            (gender && gender.key === '2' && !gender.value)
          ) {
            this.isSelectedNode =
              (this.selectedNode && !this.selectedNode.length) || !this.selectedNode ? false : true;
            setTimeout(async () => {
              const fields = requestInformation.fields;
              const { msgUnFill, msgInvalid } = await this.handleErrorMessage({
                fields,
                selectCategory: this.selectedNode
              });
              if (!this.showCollapse) this.showCollapse = true;
              this.$emit('invalidData', { msgUnFill, msgInvalid });
            }, 100);
          } else {
            if (this.selectedNode && this.selectedNode.length) {
              if (this.selectedNode[0].data.activeNode) {
                const fullPath = [this.selectedNode[0].text];
                this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
                const data = {
                  projectId: this.projectId,
                  categoryId: this.selectedNode[0].data.id,
                  categoryPath: fullPath.join(' ▶ '),
                  customerData: this.formData,
                  status: status ? STATUS_SUBMIT_CSF.SUBMITTED : STATUS_SUBMIT_CSF.DRAFT,
                  templateId: this.template.id
                };

                if (gender && gender.key === '2') {
                  delete data.customerData[key].title;
                } else if (gender && gender.key !== '2') {
                  this.$refs[key][0].otherValue = '';
                }

                const title = this.getTitle();
                if (title) data.title = title;

                const handleCFSData =
                  _.isEmpty(this.information) ||
                  this.information.ticketId === this.conversation.ticketId
                    ? true
                    : false;
                data.conversationId = handleCFSData
                  ? this.conversation.id
                  : this.information.conversationId;
                data.agentId = handleCFSData
                  ? this.conversation.ticket.assignee
                  : this.information.agentId;
                data.userId = handleCFSData ? this.conversation.userId : this.information.userId;
                data.userName = handleCFSData
                  ? this.conversation.name || 'No name'
                  : this.information.userName;
                data.ticketId = handleCFSData
                  ? this.conversation.ticketId
                  : this.information.ticketId;
                data.channelId = handleCFSData
                  ? this.conversation.channelId
                  : this.information.channelId;

                if (this.conversation.ticket.groupId && handleCFSData) {
                  data.groupId = this.conversation.ticket.groupId;
                } else if (this.information.groupId && !handleCFSData) {
                  data.groupId = this.information.groupId;
                }

                if (this.conversation && this.conversation.channelId && handleCFSData) {
                  if (this.channelsMap[this.conversation.channelId]) {
                    data.platform = this.channelsMap[this.conversation.channelId].platform;
                  }
                } else if (this.information && this.information.channelId && !handleCFSData) {
                  if (this.channelsMap[this.information.channelId]) {
                    data.platform = this.channelsMap[this.information.channelId].platform;
                  }
                }
                let isSubmited = true;
                let isClosed = false;
                let deletedData = false;
                if (this.isUpdate) {
                  data.id = this.information.id || this.customerSupportId;
                  const params = { id: data.id };
                  const checkData = await this.getCustomerSupportById(params);
                  if (Object.keys(checkData).length === 0) {
                    deletedData = true;
                  } else if (checkData && checkData.status === 'submited') {
                    isSubmited = false;
                  }
                } else {
                  const checkConversation = _.find(this.conversations, {
                    id: this.conversation.id
                  });
                  if (!checkConversation) {
                    isClosed = true;
                  } else {
                    isClosed = false;
                  }
                }
                if (isSubmited) {
                  if (isClosed) {
                    const msgError = this.$t(
                      'src.modules.chat.components.CustomerSupportPanel.requestInformation.this_conversation_was_closed_you_can_not_add/update_request'
                    );
                    this.$emit('invalidData', { msgError });
                  } else if (deletedData) {
                    const msgError = this.$t(
                      'common.CustomerSupport.RequestInformation.this_request_was_deleted_you_can_not_update_this_request'
                    );
                    this.$emit('invalidData', { msgError });
                  } else {
                    const resultCSF = await this.submitForm({
                      formCustomerSupport: data,
                      updatedByAgent: `${this.user.firstName} ${this.user.lastName}`,
                      isUpdate: this.isUpdate
                    });

                    if (_.isEmpty(resultCSF)) return null;
                    this.customerSupportId = resultCSF.id;
                    this.isUpdate = true;
                    if (!status) {
                      this.$emit(
                        'success',
                        this.$t(
                          'src.modules.chat.components.CustomerSupportPanel.requestInformation.request_was_added'
                        )
                      );

                      this.$emit('checkEditData', false);
                      EventBus.$emit('successSubmit');
                    } else {
                      if (type !== 'handleSubmitToClose') return null;
                      this.$baseNotification.success({
                        title: this.$t('src.core.App.success'),
                        message: this.$t(
                          'src.modules.chat.components.CustomerSupportPanel.requestInformation.request_was_submited_and_this_conversation_was_closed'
                        )
                      });
                      this.$emit('submitToClose');
                      if (handleCFSData) {
                        const { ticketId, ticket: { updatedAt } = {} } = this.conversation;
                        this.setSelectedConversation({ conversation: this.conversation });
                        this.onCloseConversation(ticketId, updatedAt);
                      } else {
                        const { ticketId, conversationId, channelId } = this.information;
                        const { updatedAt = '' } = this.tickets[ticketId] || {};
                        this.getCustomerSupports({
                          conversationId,
                          channelId,
                          projectId: this.projectId
                        });
                        this.onCloseConversation(ticketId, updatedAt);
                      }
                    }
                    if (type === 'handleSubmitForm') {
                      this.getCustomerSupports({
                        conversationId: this.information.conversationId,
                        channelId: this.information.channelId,
                        projectId: this.projectId
                      });
                    }
                  }
                } else {
                  const msgError = this.$t(
                    'common.CustomerSupport.the_request_was_submitted_you_can_not_change_or_delete_it'
                  );
                  this.$emit('invalidData', { msgError });
                }
              }
            } else {
              // eslint-disable-next-line
              console.log('handleDataAndSubmit -> false', this.selectedNode);
            }
          }
        });
    },

    async handleUpdateSubmitForm(status) {
      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      this.$refs.CSPRequestInformation &&
        this.$refs.CSPRequestInformation.validate().then(async result => {
          if (
            !result ||
            !this.selectedNode ||
            (this.selectedNode && !this.selectedNode.length) ||
            (this.selectedNode &&
              this.selectedNode.length &&
              !this.selectedNode[0].data.activeNode) ||
            (gender && gender.key === '2' && !gender.value)
          ) {
            this.isSelectedNode =
              (this.selectedNode && !this.selectedNode.length) || !this.selectedNode ? false : true;
            setTimeout(async () => {
              const fields = this.$refs.CSPRequestInformation.fields;
              const { msgUnFill, msgInvalid } = await this.handleErrorMessage({
                fields,
                selectCategory: this.selectedNode
              });
              if (!this.showCollapse) this.showCollapse = true;
              this.$emit('invalidData', { msgUnFill, msgInvalid });
            }, 100);
          } else {
            if (this.selectedNode && this.selectedNode.length) {
              if (this.selectedNode[0].data.activeNode) {
                const fullPath = [this.selectedNode[0].text];
                this.selectedNode[0].recurseUp(parentEl => fullPath.unshift(parentEl.text));
                const data = {
                  projectId: this.projectId,
                  categoryId: this.selectedNode[0].data.id,
                  categoryPath: fullPath.join(' ▶ '),
                  customerData: this.formData,
                  status: STATUS_SUBMIT_CSF.SUBMITTED,
                  templateId: this.template.id
                };

                if (gender && gender.key === '2') {
                  delete data.customerData[key].title;
                } else if (gender && gender.key !== '2') {
                  this.$refs[key] ? this.$refs[key][0].otherValue = '' : null;
                }

                const title = this.getTitle();
                if (title) data.title = title;

                const handleCFSData =
                  _.isEmpty(this.information) ||
                  this.information.ticketId === this.conversation.ticketId
                    ? true
                    : false;
                data.conversationId = handleCFSData
                  ? this.conversation.id
                  : this.information.conversationId;
                data.agentId = handleCFSData
                  ? this.conversation.ticket.assignee
                  : this.information.agentId;
                data.userId = handleCFSData ? this.conversation.userId : this.information.userId;
                data.userName = handleCFSData
                  ? this.conversation.name || 'No name'
                  : this.information.userName;
                data.ticketId = handleCFSData
                  ? this.conversation.ticketId
                  : this.information.ticketId;
                data.channelId = handleCFSData
                  ? this.conversation.channelId
                  : this.information.channelId;
                data.id = this.information.id;

                if (this.conversation.ticket.groupId && handleCFSData) {
                  data.groupId = this.conversation.ticket.groupId;
                } else if (this.information.groupId && !handleCFSData) {
                  data.groupId = this.information.groupId;
                }

                if (this.conversation && this.conversation.channelId && handleCFSData) {
                  if (this.channelsMap[this.conversation.channelId]) {
                    data.platform = this.channelsMap[this.conversation.channelId].platform;
                  }
                } else if (this.information && this.information.channelId && !handleCFSData) {
                  if (this.channelsMap[this.information.channelId]) {
                    data.platform = this.channelsMap[this.information.channelId].platform;
                  }
                }

                const resultCSF = await this.submitForm({
                  formCustomerSupport: data,
                  updatedByAgent: `${this.user.firstName} ${this.user.lastName}`,
                  isUpdate: this.isUpdate
                });

                if (_.isEmpty(resultCSF)) return null;
                if (status) {
                  this.$baseNotification.success({
                    title: this.$t('src.core.App.success'),
                    message: this.$t(
                      'src.modules.chat.components.CustomerSupport.RequestInformation.request_was_added'
                    ),
                    multi: true
                  });
                  this.$emit('submitToClose');
                } else {
                  this.$emit(
                    'success',
                    this.$t(
                      'src.modules.chat.components.CustomerSupportPanel.requestInformation.request_was_added'
                    )
                  );
                  this.$emit('checkEditData', false);
                  EventBus.$emit('successSubmit');
                }
              }
            } else {
              // eslint-disable-next-line
              console.log('handleUpdateSubmitForm >> false', this.selectedNode);
            }
          }
        });
    },

    async mapEditData(editData) {
      if (this.$refs && this.$refs.tree) {
        this.$refs.tree.selected().unselect();
      }
      if (editData.status === 'draft') {
        this.isView = false;
      } else {
        this.isView = true;
      }
      this.isUpdate = true;
      if (!this.item) {
        if (editData.categoryId) {
          if (this.$refs && this.$refs.tree) {
            const selection = this.$refs.tree.find({
              data: { id: editData.categoryId }
            });
            if (selection) {
              selection.select(true);

              let { parent = null } = selection[0];
              if (parent) {
                do {
                  parent.expand(true);
                  parent = parent.parent || null;
                } while(parent)
              }
            }
          }
        }
      } else {
        delete editData.updatedAt;
        delete editData.createdAt;
        this.itemData = editData;
      }

      this.formData = editData.customerData ? editData.customerData : {};

      const keyCountry = (this.formData && this.formData[this.countryKey]) || '';
      if (keyCountry) {
        const provinces = await this.getProvinces({
          code: keyCountry,
          language: this.displayLanguage
        });
        this.lstProvinces = provinces.map(province => {
          return { key: province.code, value: province.name };
        });
      }

      this.initFormData = _.clone(this.formData || {});
      this.initCategoryPath = editData.categoryPath || '';
    },

    onCloseConversation(ticketId, updatedAt) {
      this.setReady(false);
      this.closeConversationByTicketId({ ticketId, updatedAt });
    },

    getTitle() {
      let titleValue = '';
      const { title } = this.fixedFields;
      const { type, key, options } = title;
      if (!this.formData[key]) return false;

      if (type === 'combobox') {
        titleValue = options[this.displayLanguage][this.formData[key]];
      } else if (type === 'text') {
        titleValue = this.formData[key];
      }
      return titleValue;
    },

    goToDashboard() {
      this.$root.$emit('bv::hide::tooltip'); // Hide all tooltips
      this.setShowProductPanel(false);
      this.setShowConversationalPanel(false);
      this.setShowSupportPanel(false);
      this.pinRightPanel(false);
      this.setSelectedConversation({ conversation: null, groupId: SERVING_STATE.AGENT });
      this.setShowView('Overview');
    },
    m_getFiledInUsed(raw, isFixField = true) {
      const fieldCheckDisplay = isFixField ? 'inUsed' : 'display';
      return Object.keys(raw)
        .filter(key => {
          if (this.formData && raw[key] && isEmpty(this.formData[raw[key].key])) {
            const { [fieldCheckDisplay]: display = false } = raw[key] || {};
            return display;
          }
          return true;
        })
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
    handleErrorMessage(obj) {
      const { fields, selectCategory } = obj;
      const fieldInvalid = [];
      const fieldUnFill = [];
      if (selectCategory && selectCategory.length > 0) {
        if (!selectCategory[0].data.activeNode) fieldInvalid.push(`[${this.labelCategory}]`);
      } else fieldUnFill.push(`[${this.labelCategory}]`);
      const errUnFill = Object.values(fields).filter(
        c =>
          c.required &&
          c.failedRules &&
          (c.failedRules.baseSelectRequired ||
            c.failedRules.baseRequired ||
            c.failedRules.baseCheckRequired)
      );
      const errInvalid = Object.values(fields).filter(
        c =>
          c.failedRules &&
          (c.failedRules.baseEmail || c.failedRules.basePhoneNumber || c.failedRules.basePostCode)
      );
      const components = Object.values(this.customerAllFields).reduce(
        (acc, i) => [...acc, ...Object.values(i)],
        []
      );
      errUnFill.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.lastIndexOf('-'));
        components.forEach(c => {
          if (cKey === c.key) {
            label = typeof c.label === 'object' ? c.label[this.displayLanguage] : c.label;
          }
        });
        fieldUnFill.push(`[${label}]`);
      });
      errInvalid.forEach(item => {
        const str = item.id;
        let label = '';
        const cKey = str.slice(0, str.lastIndexOf('-'));
        components.forEach(c => {
          if (cKey === c.key) {
            label = typeof c.label === 'object' ? c.label[this.displayLanguage] : c.label;
          }
        });
        fieldInvalid.push(`[${label}]`);
      });

      const { sex: { key } = {} } = this.fixedFields;
      const gender = this.formData[key];
      if (gender && gender.key === '2' && !gender.value) {
        this.radioOtherValueError = true;
        fieldUnFill.push('[Gender]');
      }

      const paramsUnFill =
        fieldUnFill.length <= 2 ? fieldUnFill.join() : `${fieldUnFill.slice(0, 2).join()} ...`;
      const msgUnFill = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.requestInformation.missing_fulfil_required_fields',
        { fields: paramsUnFill }
      );
      const paramsInvalid =
        fieldInvalid.length <= 2 ? fieldInvalid.join() : `${fieldInvalid.slice(0, 2).join()} ...`;
      const msgInvalid = this.$t(
        'src.modules.chat.components.CustomerSupportPanel.requestInformation.wrong_input_fields',
        { fields: paramsInvalid }
      );

      return fieldUnFill.length > 0 && fieldInvalid.length > 0
        ? { msgUnFill, msgInvalid }
        : fieldUnFill.length > 0
        ? { msgUnFill }
        : { msgInvalid };
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/cs/form.scss';
.nat-invalid-data {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.nat-h-center {
  margin-top: auto;
  margin-bottom: auto;
}

.breadcrumb-item.active {
  color: #525f7f;
  font-weight: bold;
}

.tree {
  /deep/.tree-anchor {
    color: #525f7f !important;
  }

  /deep/.tree-node.selected > .tree-content {
    background-color: #c9daef;
  }
}

#clRequestInformation {
  .info-container {
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }
  .col {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

/deep/ .v-select {
  display: -webkit-box;
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    &:after {
      content: none;
    }
    .selected-tag {
      color: #8898aa;
      display: block;
      width: calc(100% - 50px);
      width: -webkit-calc(100% - 50px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .dropdown-menu {
    margin-top: 2px;
    padding: 0;
    li {
      a {
        padding: 10px;
        min-height: 23px;
        color: #8898aa;
        font-size: 0.875rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }
    .highlight {
      a {
        background: #4bd;
        color: #ffffff;
      }
    }
  }
  input {
    margin: -10px 0 0;
    width: 100%;
    height: 100%;
  }
  .no-options {
    display: none;
  }
}

/deep/ .vdp-datepicker {
  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    line-height: 1.5;
    color: #8898aa;
    border: 0px;
    height: 100%;
    width: 100%;
  }
}
</style>
