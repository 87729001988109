<template>
  <div class="message-audio">
    <audio ref="audioPlayer" :src="audioUrl" controls="true" />
  </div>
</template>

<script>
export default {
  props: {
    audioUrl: {
      type: String,
      default: null
    }
  },
  computed: {
    player() {
      return this.$refs.audioPlayer && this.$refs.audioPlayer.player;
    }
  },

  mounted() {}
};
</script>

<style></style>
