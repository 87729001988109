<template>
  <img :src="userAvatar" :alt="alt" @error="$event.target.src = noAvatar" />
</template>

<script>
import noAvatar from 'assets/images/no-avatar.png';
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      noAvatar
    };
  },
  computed: {
    userAvatar() {
      return this.src ? this.src : noAvatar;
    }
  }
};
</script>
