<template>
  <DynamicScroller
    :items="c_conversationsPaginated"
    :min-item-size="50"
    key-field="ticketId"
    class="scroller"
    v-bind:class="{ fix: checkLengthItem || checkLength }"
  >
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :size-dependencies="[item.ticket]"
        :data-index="index"
      >
        <c-conversation
          v-bind="item"
          :channel-id="item.channelId"
          :selected-conversation="selectedConversation"
          :conversation="item"
          @onClick="handleOnConversationClick(item)"
        />
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import CConversation from './conversation-item';
import { SORT_BY, TABS, TICKET_SEARCH_TYPE, PAGING_DIRECTION } from 'core/constants';
import { compareDateStr } from 'core/helpers';
import { mapGetters, mapActions, mapState } from 'vuex';
import { getTabIndex } from 'core/helpers';
import { findIndex, chunk } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    CConversation
  },

  props: {
    tabIndex: {
      type: Number,
      default: TABS.ME
    },
    id: {
      type: String,
      default: () => ''
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    selectedCategory: {
      type: String,
      default: 'all'
    },
    sortBy: {
      type: String,
      default: () => SORT_BY.LAST_MESSAGE
    },
    sortDirection: {
      type: Number,
      default: () => 0
    },
    isSearch: {
      type: Boolean,
      default: () => false
    },
    page: {
      type: Number,
      default: 1
    },
    setConversationLength: {
      type: Function,
      default: () => {}
    },
    setSelectedCategory: {
      type: Function,
      default: () => {}
    },
    conversationsByTabIndex: {
      type: Array,
      default: () => []
    },
    checkLength: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mode: false,
      firstMode: false,
      pageNumber: this.page,
      checkLengthItem: false
    };
  },

  computed: {
    ...mapGetters('session', [
      'conversationsByBot',
      'conversationsByAgents',
      'commonWaitingList',
      'monitoringList',
      'meWaitingList'
    ]),
    ...mapState('session', [
      'channelTabPage',
      'channelTabReload',
      'user',
      'goToTicket',
      'selectedCategoryChannelMap',
      'hasUpdateTicketCome'
    ]),

    conversations() {
      let response = [];
      response = this.conversationsByTabIndex.filter(c => {
        if (this.isSearch) {
          if (
            c.ticket &&
            c.ticket.hasOwnProperty('search') &&
            c.ticket.search !== TICKET_SEARCH_TYPE.CURRENT_TICKET &&
            c.channelId === this.id
          ) {
            if (this.selectedCategory === 'all') {
              return c;
            } else if (c.ticket && c.ticket.groupId && c.ticket.groupId === this.selectedCategory) {
              return c;
            }
          }
        } else {
          if (
            c.channelId === this.id &&
            ((c.ticket && !c.ticket.hasOwnProperty('search')) ||
              (c.ticket &&
                c.ticket.hasOwnProperty('search') &&
                c.ticket.search === TICKET_SEARCH_TYPE.CURRENT_TICKET))
          ) {
            if (this.selectedCategory === 'all') {
              return c;
            } else if (c.ticket && c.ticket.groupId && c.ticket.groupId === this.selectedCategory) {
              return c;
            }
          }
        }
      });

      //---sort default---//
      if (this.sortBy === SORT_BY.LAST_MESSAGE || [TABS.MONITORING].includes(this.tabIndex)) {
        response.sort((a, b) =>
          a.ticket && a.ticket.lastMessageTime && b.ticket && b.ticket.lastMessageTime
            ? compareDateStr(
                a.ticket.lastMessageTime,
                b.ticket.lastMessageTime,
                this.sortDirection
              )
            : 0
        );
      }

      if ([TABS.WAITING_COMMON, TABS.WAITING_ME, TABS.ME].includes(this.tabIndex)) {
        /**
         * TODO: Handle sort conversation
         */
        if (this.sortBy !== SORT_BY.LAST_MESSAGE || this.sortDirection > 0) {
          switch (this.sortBy) {
            case SORT_BY.LAST_MESSAGE:
              response.sort((a, b) =>
                a.ticket && a.ticket.lastMessageTime && b.ticket && b.ticket.lastMessageTime
                  ? compareDateStr(
                      a.ticket.lastMessageTime,
                      b.ticket.lastMessageTime,
                      this.sortDirection
                    )
                  : 0
              );
              break;
            case SORT_BY.REQUEST_TIME:
              response.sort((a, b) =>
                a.ticket && b.ticket
                  ? compareDateStr(a.ticket.requestTime, b.ticket.requestTime, this.sortDirection)
                  : 0
              );
              break;
            case SORT_BY.SUPPORT_TIME:
              response.sort((a, b) =>
                a.ticket && b.ticket
                  ? compareDateStr(a.ticket.supportTime, b.ticket.supportTime, this.sortDirection)
                  : 0
              );
              break;
            default:
              break;
          }
        }
      }
      this.setConversationLength({
        tabIndex: this.tabIndex,
        channelStatus: this.channelStatus,
        conversationCnt: response.length
      });
      return response;
    },

    loadMoreStatus() {
      if (this.tabIndex === TABS.MONITORING) return 5;
      return this.tabIndex;
    },

    channelStatus() {
      if (this.isSearch) return this.id + '9';
      return this.id + this.loadMoreStatus;
    },

    c_isLoaded() {
      const currentPage = this.channelTabPage[this.channelStatus] || 0;
      return currentPage > 0;
    },

    c_conversationsPaginated() {
      let conversationsPaginated = [];
      if (this.isSearch || this.tabIndex == TABS.ME) {
        conversationsPaginated = chunk(this.conversations, 10)[this.c_pageNumber - 1] || [];
      } else {
        conversationsPaginated =
          chunk(this.conversations, 10)[
            this.c_pageNumber % 5 != 0 ? (this.c_pageNumber % 5) - 1 : 5 - 1
          ] || [];
      }
      return conversationsPaginated;
    },

    c_conversationsPaginatedCnt() {
      return chunk(this.c_conversationsShow, 10).length;
    },

    c_conversationsShow() {
      return chunk(this.conversations, 50)[0];
    },

    c_pageNumber() {
      return this.channelTabPage[this.channelStatus] || 1;
    },

    c_sortByField() {
      switch (this.sortBy) {
        case SORT_BY.LAST_MESSAGE:
          return 'lastMessageTime';
        case SORT_BY.REQUEST_TIME:
          return 'requestTime';
        case SORT_BY.SUPPORT_TIME:
          return 'supportTime';
        default:
          return 'lastMessageTime';
      }
    },

    c_currentTab() {
      return this.tabIndex;
    }
  },

  watch: {
    c_conversationsPaginated(value) {
      if (value.length < 4) {
        this.checkLengthItem = true;
      } else {
        this.checkLengthItem = false;
      }
    },
    async conversations(newValue, oldValue) {
      // eslint-disable-next-line
      this.$log('[C+ Debug] [NOTIFY] >>> conversations -> data', {
        newValue,
        oldValue
      });

      if ((newValue.length === 0 && oldValue.length === 0) || newValue.length === 0) return;
      const { ticket = {} } = this.selectedConversation || {};
      if (this.goToTicket[this.channelStatus] === true && !ticket.deleted) {
        // Continue load api if cannot get ticket in list
        // eslint-disable-next-line
        this.$log('[C+ Debug] [NOTIFY] >>> conversations >> m_goToTicket');
        this.m_goToTicket();
      }
      if (
        this.c_isLoaded == true &&
        this.c_conversationsPaginated.length === 0 &&
        (this.c_pageNumber == this.c_conversationsPaginatedCnt + 1 ||
          (this.c_pageNumber - 1) % 5 == this.c_conversationsPaginatedCnt)
      ) {
        if (this.c_pageNumber > 1) {
          const page = this.c_pageNumber - 1 > 0 ? this.c_pageNumber - 1 : 1;

          if (this.c_conversationsPaginatedCnt % 5 == 0) {
            const prevPage = (Math.floor((this.c_pageNumber - 1) / 5) - 1) * 5 + 1;
            await this.m_pagePrev(prevPage);
            this.setChannelTabPage({
              channelStatus: this.channelStatus,
              page
            });
          } else {
            this.setChannelTabPage({
              channelStatus: this.channelStatus,
              page
            });
          }
        } else {
          this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
        }
      }
      if (this.sortBy !== SORT_BY.LAST_MESSAGE || this.sortDirection > 0) {
        if (newValue.length == 50 && this.c_pageNumber !== 0) {
          if (this.tabIndex != 3 && !this.isSearch) {
            let nextTime = null;
            let currentTime = null;
            if (newValue.length > 0) {
              currentTime = newValue[0].ticket[this.c_sortByField];
            }
            if (newValue.length > 50) {
              nextTime = newValue[49].ticket[this.c_sortByField];
            } else if (newValue.length > 0) {
              nextTime = newValue[newValue.length - 1].ticket[this.c_sortByField] || null;
            }
            let floor = 0;
            if (this.c_pageNumber % 5 == 0) {
              floor = Math.floor(this.c_pageNumber / 5) - 1;
            } else {
              floor = Math.floor(this.c_pageNumber / 5);
            }
            const blockPage = floor * 5 + 1;
            const { projectId } = this.channelsMap[this.id] || {};
            this.nextDataAuto({
              projectId,
              channelId: this.id,
              status: this.tabIndex == 4 ? 5 : this.tabIndex,
              page: blockPage,
              oldPage: 0,
              pagingDirection: PAGING_DIRECTION.NEXT,
              nextTime,
              currentTime
            });
          }
        }
      }
      this.setConversationLength({
        tabIndex: this.tabIndex,
        channelStatus: this.channelStatus,
        conversationCnt: newValue.length
      });
      this.setReady(true);
    },

    async isSearch(newValue) {
      if (
        newValue == false &&
        this.selectedConversation &&
        this.selectedConversation.hasOwnProperty('ticket') &&
        this.selectedConversation.ticket.channelId == this.id
      ) {
        this.firstMode = true;
        this.mode = true;
        // eslint-disable-next-line
        this.$log('[C+ Debug] [NOTIFY] >>> isSearch >> m_goToTicket');
        this.m_goToTicket();
      }
    },

    pageNumber(newPage) {
      this.setChannelTabPage({ channelStatus: this.channelStatus, page: newPage });
    },

    page(newPage, oldPage) {
      if (oldPage < newPage) {
        // Click Next Page
        if (newPage % 5 == 1) {
          this.m_pageNext(newPage, oldPage);
        }
      } else {
        // Click Prev Page
        if (newPage % 5 == 1 && oldPage >= newPage + 5) this.m_pagePrev(newPage, oldPage);
      }
      this.pageNumber = newPage;
    },

    channelTabReload() {
      this.firstMode = true;
      // eslint-disable-next-line
      this.$log('[C+ Debug] [NOTIFY] >>> channelTabReload >> m_goToTicket');
      this.m_goToTicket();
    }
  },
  created() {
    // eslint-disable-next-line
    this.$log('[C+ Debug] [NOTIFY] >>> created >> m_goToTicket');
    // When Update Search conditions => updated page = 1
    if (this.isSearch === true) {
      this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
    }
    // When Enter by URL
    this.m_goToTicket();
  },
  methods: {
    ...mapActions('session', [
      'setChannelHasUpdateTicketCome',
      'setChannelTabReload',
      'setChannelTabPage',
      'setGoToTicket',
      'loadMoreConversation'
    ]),
    ...mapActions('chat', ['setReady']),

    m_goToTicket() {
      try {
        if (
          (this.selectedConversation &&
            this.selectedConversation.hasOwnProperty('ticket') &&
            this.selectedConversation.eventType === 'url' &&
            this.channelTabReload[this.channelStatus]) ||
          this.mode == true
        ) {
          // eslint-disable-next-line
          this.$log('[C+ Debug] [NOTIFY] >>> goToTicket >> m_goToTicket > data', {
            channelStatus: this.channelStatus,
            conversations: this.conversations
          });
          const { ticket, channelId, ticketId, eventType } = this.selectedConversation || {};
          const tabIndex = getTabIndex({ conv: { ticket }, user: this.user });
          const findinconversations = chunk(this.conversations, 50)[0] || [];
          if (
            channelId === this.id &&
            this.tabIndex === tabIndex &&
            (eventType == 'url' || this.mode == true)
          ) {
            const hasNew = this.hasUpdateTicketCome[this.channelStatus];
            this.setChannelHasUpdateTicketCome({
              channelStatus: this.channelStatus,
              flag: false
            });

            if (tabIndex == 3) {
              const index = findIndex(findinconversations, function(o) {
                return o.ticketId == ticketId;
              });
              if (index !== -1) {
                let page = 1;
                const i = index + 1;
                if (i % 10 === 0) {
                  page = Math.floor(i / 10);
                } else {
                  page = Math.floor(i / 10) + 1;
                }
                this.setChannelTabPage({ channelStatus: this.channelStatus, page });
                return page;
              }
            }
            if (tabIndex == 0 || tabIndex == 1 || tabIndex == 2 || tabIndex == 4) {
              const index = findIndex(findinconversations, function(o) {
                return o.ticketId == ticketId && o.ticket && o.ticket.hasOwnProperty('page');
              });
              const checkExistPage =
                findinconversations[0] &&
                findinconversations[0].ticket &&
                findinconversations[0].ticket.hasOwnProperty('page');

              if (index == -1) {
                let page = 1;
                let nextTime = null;
                let currentTime = null;
                let oldPage = 1;

                // check case dang select 1 group => click notify => gotoTicket ve default all group
                if (this.selectedCategoryChannelMap[this.channelStatus] !== 'all') {
                  this.setSelectedCategory({
                    channelStatus: this.channelStatus,
                    selectedCategoryId: 'all'
                  });
                }

                if (
                  (this.mode == true || this.channelTabReload[this.channelStatus]) &&
                  this.firstMode == true
                ) {
                  this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
                  this.firstMode = false;
                } else {
                  if (this.conversations.length > 0 && checkExistPage) {
                    currentTime = this.conversations[0].ticket[this.c_sortByField] || null;
                  }
                  if (this.conversations.length > 50 && checkExistPage) {
                    nextTime = this.conversations[49].ticket[this.c_sortByField];
                    const temp = cloneDeep(this.conversations[49]);
                    const find = temp.ticket.page.find(item => (item.grpNavTabIndex = tabIndex));
                    if (find) {
                      page = find.page + 5;
                    }
                  }
                  if (
                    this.conversations.length > 0 &&
                    this.conversations.length < 50 &&
                    checkExistPage &&
                    !hasNew
                  ) {
                    this.setGoToTicket({
                      channelStatus: this.channelStatus,
                      flag: false
                    });
                    if (this.selectedCategory == 'all') {
                      // eslint-disable-next-line
                      console.log('[C+ Debug] [NOTIFY] >>> goToTicket >> return > channelStatus', {
                        channelStatus: this.channelStatus
                      });
                      return;
                    }
                  }
                  if (checkExistPage) {
                    oldPage = this.c_pageNumber;
                  }
                }

                this.setGoToTicket({
                  channelStatus: this.channelStatus,
                  flag: true
                });
                // eslint-disable-next-line
                this.$log('[C+ Debug] [NOTIFY] >>> goToTicket >> nextTime >', {
                  channelStatus: this.channelStatus,
                  nextTime
                });
                this.$emit('onLoadMoreConvs', {
                  channelId: this.id,
                  pagingDirection: PAGING_DIRECTION.NEXT,
                  page,
                  oldPage,
                  nextTime,
                  currentTime,
                  selectedCategory: this.selectedCategory
                });
              } else {
                this.setChannelTabReload({
                  channelStatus: this.channelStatus,
                  flag: false
                });
                const i = index + 1;
                let page = 1;
                const temp = cloneDeep(findinconversations[findinconversations.length - 1]);
                const find = temp.ticket.page.find(item => (item.grpNavTabIndex = tabIndex));

                if (find) {
                  page = find.page;
                } else {
                  // eslint-disable-next-line
                  console.log('[C+ Debug] [ERROR] >>> goToTicket >> Auto Next Done > not find ', {
                    channelStatus: this.channelStatus
                  });
                }
                if (i > 10) {
                  if (i % 10 === 0) {
                    page = page + Math.floor(i / 10) - 1;
                  } else {
                    page = page + Math.floor(i / 10);
                  }
                }
                // eslint-disable-next-line
                console.log('[C+ Debug] [NOTIFY] >>> goToTicket >> Auto Next Done > find > page', {
                  channelStatus: this.channelStatus,
                  page
                });
                this.mode = false;
                this.setGoToTicket({
                  channelStatus: this.channelStatus,
                  flag: false
                });
                this.setChannelTabPage({ channelStatus: this.channelStatus, page });
                return page;
              }
            }
          }
        }
      } catch (error) {
        this.mode = false;
        this.setGoToTicket({
          channelStatus: this.channelStatus,
          flag: false
        });
        this.setChannelTabPage({ channelStatus: this.channelStatus, page: 1 });
        this.setChannelTabReload({
          channelStatus: this.channelStatus,
          flag: false
        });
        // eslint-disable-next-line
        this.$log('[C+ Debug] [ERROR] >>> m_goToTicket > find ticket > error', error);
      }
    },

    m_pageNext(newPage, oldPage) {
      if (this.tabIndex != 3 && !this.isSearch) {
        let nextTime = null;
        let currentTime = null;
        if (this.conversations.length > 0) {
          currentTime = this.conversations[0].ticket[this.c_sortByField] || null;
        }
        if (this.conversations.length > 50) {
          nextTime = this.conversations[49].ticket[this.c_sortByField];
        } else if (this.conversations.length > 0) {
          nextTime =
            this.conversations[this.conversations.length - 1].ticket[this.c_sortByField] || null;
        }
        this.$emit('onLoadMoreConvs', {
          channelId: this.id,
          pagingDirection: PAGING_DIRECTION.NEXT,
          page: newPage,
          oldPage: oldPage,
          nextTime,
          currentTime,
          selectedCategory: this.selectedCategory
        });
      } else {
        if (this.isSearch) {
          if (newPage * 10 >= this.conversations.length) {
            this.$emit('onLoadMoreConvs', {
              channelId: this.id,
              selectedCategory: this.selectedCategory
            });
          }
        }
      }
    },

    m_pagePrev(newPage, oldPage) {
      if (this.tabIndex != 3 && !this.isSearch) {
        let nextTime = null;

        if (this.conversations.length > 50) {
          nextTime = this.conversations[49].ticket[this.c_sortByField];
        } else if (this.conversations.length > 0) {
          nextTime =
            this.conversations[this.conversations.length - 1].ticket[this.c_sortByField] || null;
        }
        this.$emit('onLoadMoreConvs', {
          channelId: this.id,
          pagingDirection: PAGING_DIRECTION.PREV,
          page: newPage,
          oldPage: oldPage,
          nextTime,
          selectedCategory: this.selectedCategory
        });
      }
    },

    nextDataAuto({
      projectId,
      channelId,
      status,
      page,
      oldPage,
      pagingDirection,
      nextTime,
      currentTime
    }) {
      return this.loadMoreConversation({
        projectId,
        channelId,
        status,
        page,
        oldPage,
        pagingDirection,
        nextTime,
        currentTime
      });
    },

    handleOnConversationClick(item) {
      this.$emit('onClick', item);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.vue-recycle-scroller.fix .vue-recycle-scroller__item-wrapper {
  overflow: visible;
}

/deep/.vue-recycle-scroller__item-view.hover {
  z-index: 10;
}
.scroller {
  max-height: 550px;
}
</style>
