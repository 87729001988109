import { i18n } from 'locales';
import timezones from 'core/timezones';
import { stringifyZone } from 'core/helpers';

export const translate = {
  comment: i18n.t('common.text.comment'),
  reply: i18n.t('common.text.reply'),
  post: i18n.t('common.text.post'),
  filter_comment: i18n.t('common.text.filter_comment'),
  basic: i18n.t('common.text.basic'),
  basic_search: i18n.t('common.text.basic_search'),
  advance: i18n.t('common.text.advance'),
  advance_search: i18n.t('common.text.advance_search'),
  keyword: i18n.t('common.text.keyword'),
  search_type: i18n.t('common.text.search_type'),
  content: i18n.t('common.text.content'),
  enter_content: i18n.t('common.text.enter_content'),
  author: i18n.t('common.text.author'),
  enter_author: i18n.t('common.text.enter_author'),
  type: i18n.t('common.text.type'),
  timezone: i18n.t('common.text.timezone'),
  date_range: i18n.t(
    'src.modules.report.components.cnv-scheduled-export.index.date_range'
  ),
  comment_with_no_reply: i18n.t('src.modules.chat.components.comment.filter.comment_with_no_reply'),
  start_date: i18n.t('src.modules.report.components.index.start_date'),
  end_date: i18n.t('src.modules.report.components.index.end_date'),
  search: i18n.t('src.modules.search-management.index.search'),
  clear: i18n.t('src.modules.chat.components.AsideLeft.SearchBox.index.clear'),
  reset: i18n.t('common.text.reset'),
  refresh: i18n.t('src.modules.report.components.index.title.refresh'),
  hint_text: i18n.t(
    'src.modules.chat.components.CommentBox.CommentFilter.index.ex_lower-case_and_upper-case_are_different_in_the_search_condition'
  ),
  select_type: i18n.t('common.text.select_type'),
  select_timezone: i18n.t('common.text.select_timezone'),
  error_validate: i18n.t('common.text.please_enter_or_select_a_search_condition')
};

export const timezoneOptions = timezones.map(t => ({
  name: stringifyZone(t, 'UTC'),
  key: t.name
}));
