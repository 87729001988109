const rightPanelPinned = state => state.rightPanelPinned;
const rightProductPanelPinned = state => state.rightProductPanelPinned;
const rightConversationPanelPinned = state => state.rightConversationPanelPinned;
const rightCustomerSupportPanelPinned = state => state.rightCustomerSupportPanelPinned;
const rightCustomerSurveyPanelPinned = state => state.rightCustomerSurveyPanelPinned;
const isOffline = state => state.isOffline;

export {
  rightPanelPinned,
  rightProductPanelPinned,
  rightConversationPanelPinned,
  rightCustomerSupportPanelPinned,
  rightCustomerSurveyPanelPinned,
  isOffline
};
