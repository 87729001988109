import {
  SET_USERS_INFO,
  SET_SELECTED_USER_INFO,
  SET_MESSAGES_HISTORY,
  ENABLE_BTN_WBH,
  SET_PARAMS_SEARCH_BOX
} from './types';
import Service from 'core/service';
const service = new Service();

export const getUsersInfo = async ({ commit }, params) =>
  service
    .post('conversations/getUsersInfoByProjectIdPhoneNumber', params)
    .then(res => {
      if (res.errors) {
        console.log('getUsersInfo >> res.errors', res.errors);
        return [];
      }
      commit(SET_USERS_INFO, res);
      return res;
    })
    .catch(err => {
      // eslint-disable-next-line
      console.log('getUsersInfo >> err', err);
      commit(SET_USERS_INFO, []);
      return [];
    });

export const setParamsFromSearchBox = ({ commit }, params) => commit(SET_PARAMS_SEARCH_BOX, params);

export const enableBtnWBH = ({ commit }, bool) => commit(ENABLE_BTN_WBH, bool);

export const clearUsersInfo = ({ commit }) => commit(SET_USERS_INFO, []);

export const clearMessagesHistory = ({ commit }) => commit(SET_MESSAGES_HISTORY, { clear: true });

export const setSelectedUserInfo = ({ commit }, selectedUserInfo) => commit(SET_SELECTED_USER_INFO, selectedUserInfo);

export const getMessagesByConversationId = async (
  { commit },
  { conversationId, projectId } = {}
) => {
  if (!conversationId || !projectId) return true;
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId,
    projectId
  });
  const messages = data.Items;
  commit(SET_MESSAGES_HISTORY, { messages });
  return data;
};

export const loadMoreMessage = async (
  { commit },
  { conversationId, projectId, nextKey } = {}
) => {
  if (!conversationId || !projectId) return;
  const data = await service.rest('messages/getMessagesByConversationId', {
    conversationId,
    nextKey,
    projectId
  });
  const messages = data.Items;
  commit(SET_MESSAGES_HISTORY, { messages });
  return data;
};