<template>
  <b-modal
    id="selectGroupModal"
    ref="selectGroupModal"
    :title="$t('common.text.select_group')"
    @hide="handleClose"
    @hidden="handleClose"
  >
    <h6>
      {{
        $t(
          'src.modules.chat.components.SupportTicket.index.select_a_group_you_want_to_support'
        )
      }}
    </h6>
    <p></p>
    <el-select
      v-model="selectedCategory"
      :placeholder="$t('src.modules.chat.components.SupportTicket.index.select')"
      class="select-category d-flex justify-content-center"
    >
      <el-option
        v-for="(item, index) in agentGroups"
        :key="item.value"
        v-bind:selected="index === 0"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>

    <div slot="modal-footer" class="w-100">
      <b-btn
        :disabled="selectedCategory === '' || loading"
        class="float-right"
        variant="primary"
        style="margin-left: 10px;"
        @click="handleSelectedGroup"
      >
        <span v-if="!loading">{{
          $t('src.modules.chat.components.SupportTicket.index.support')
        }}</span>
        <span v-else>
          {{ $t('src.modules.chat.components.SupportTicket.index.waiting') }}
          <i class="fas fa-spinner fa-spin fa-lg" />
        </span>
      </b-btn>
      <b-btn
        :disabled="loading"
        class="float-right"
        variant="default"
        @click="handleClose"
        >{{ $t('common.confirmModal.cancel') }}</b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TICKET_STATUS, TICKET_STATE } from 'core/constants';

function defaultState() {
  return { selectedCategory: '', open: false, loading: false };
}

export default {
  props: {
    conversation: {
      type: Object,
      default: () => null
    },
    agentGroups: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    },
    version: {
      type: Number,
      default: () => 0
    }
  },

  data: defaultState,

  computed: {
    ...mapGetters('session', ['groupMaps', 'me']),

    firstAgentGroup() {
      return this.agentGroups.length > 0 ? this.agentGroups[0] : {};
    }
  },

  watch: {
    show(isShow) {
      this.open = false;

      if (isShow) {
        const { ticket } = this.conversation || {};
        if (!ticket) {
          return this.handleClose();
        } else if (
          ticket.status === TICKET_STATUS.BOT_HANDLE ||
          ticket.state === TICKET_STATE.COMPLETE ||
          ticket.state === TICKET_STATE.FINISH ||
          !ticket.groupId
        ) {
          if (this.agentGroups.length === 1) {
            this.selectedCategory = this.agentGroups[0].value;
            return this.supportTicketNotShowModalConfirm({
              ...this.conversation,
              ticket: { ...ticket, groupId: this.selectedCategory }
            });
          } else if (this.agentGroups.length > 1) {
            this.selectedCategory = this.agentGroups[0].value;
            this.open = true;
          }
        } else {
          return this.supportTicketNotShowModalConfirm(this.conversation);
        }
      }
    },

    open(val) {
      if (val) this.$refs.selectGroupModal.show();
      else this.$refs.selectGroupModal.hide();
    },

    version(newVersion, oldVersion) {
      if (this.show && newVersion !== oldVersion) {
        //version changed
        this.handleClose();
      }
    }
  },

  methods: {
    ...mapActions('chat', ['setReady', 'supportTicket']),
    ...mapActions('global', ['setGlobalLoadingMap']),

    resetAllState() {
      Object.assign(this.$data, defaultState());
    },

    handleClose() {
      this.resetAllState();
      this.$emit('onClose');
    },

    handleSelectedGroup() {
      if (!this.show) return;
      const { ticket } = this.conversation || {};
      if (!ticket) return;
      this.loading = true;

      this.supportTicket({
        ...this.conversation,
        ticket: { ...ticket, groupId: this.selectedCategory }
      })
        .then(async () => {
          await new Promise(resolve => setTimeout(() => resolve(true), 500));
          this.handleClose();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    supportTicketNotShowModalConfirm(conversation) {
      this.setGlobalLoadingMap({
        action: 'SUPPORT_TICKET_NOT_SHOW_MODAL_SELECT_GROUP',
        flag: true
      });
      this.handleClose();
      return this.supportTicket(conversation)
        .then(async () => {
          this.setGlobalLoadingMap({
            action: 'SUPPORT_TICKET_NOT_SHOW_MODAL_SELECT_GROUP',
            flag: false
          });
        })
        .catch(() => {
          this.setGlobalLoadingMap({
            action: 'SUPPORT_TICKET_NOT_SHOW_MODAL_SELECT_GROUP',
            flag: false
          });
        });
    }
  }
};
</script>

<style scoped>
.support-dialog {
  z-index: 999999;
}
</style>
