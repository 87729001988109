<template>
  <el-dropdown class="sort-ticket" trigger="click" @command="handleSortMenu">
    <i :class="iconClass" @click="handleClick" />
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-show="sortBy !== SORT_BY.LAST_MESSAGE || sortDirection > 0"
        command="message-down"
      >
        <i class="fas fa-sort-amount-down text-warning"></i>
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.message_time')
        }}
      </el-dropdown-item>
      <el-dropdown-item
        v-show="sortBy !== SORT_BY.LAST_MESSAGE || sortDirection < 1"
        command="message-up"
      >
        <i class="fas fa-sort-amount-up text-warning" />
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.message_time')
        }}
      </el-dropdown-item>
      <el-dropdown-item
        v-show="sortBy !== SORT_BY.REQUEST_TIME || sortDirection > 0"
        command="request-down"
      >
        <i class="fas fa-sort-amount-down text-info"></i>
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.request_time')
        }}
      </el-dropdown-item>
      <el-dropdown-item
        v-show="sortBy !== SORT_BY.REQUEST_TIME || sortDirection < 1"
        command="request-up"
      >
        <i class="fas fa-sort-amount-up text-info" />
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.request_time')
        }}
      </el-dropdown-item>
      <el-dropdown-item
        v-show="isSupporting && (sortBy !== SORT_BY.SUPPORT_TIME || sortDirection > 0)"
        command="support-down"
      >
        <i class="fas fa-sort-amount-down text-secondary"></i>
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.support_time')
        }}
      </el-dropdown-item>
      <el-dropdown-item
        v-show="isSupporting && (sortBy !== SORT_BY.SUPPORT_TIME || sortDirection < 1)"
        command="support-up"
      >
        <i class="fas fa-sort-amount-up text-secondary" />
        {{
          $t('src.modules.chat.components.AsideLeft.ConversationList.sort.support_time')
        }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { SORT_BY, TABS } from 'core/constants';
import { mapActions } from 'vuex';

export default {
  props: {
    tabIndex: {
      type: Number,
      default: () => TABS.AGENT
    },
    channelId: {
      type: String,
      default: ''
    },
    sortBy: {
      type: String,
      default: () => SORT_BY.LAST_MESSAGE
    },
    sortDirection: {
      type: Number,
      default: () => 0
    },
    isSearch: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      SORT_BY
    };
  },

  computed: {
    iconClass() {
      let className = 'fas fa-sort-amount-';
      className += this.sortDirection > 0 ? 'up' : 'down';
      switch (this.sortBy) {
        case SORT_BY.LAST_MESSAGE:
          className += ' text-warning';
          break;
        case SORT_BY.SUPPORT_TIME:
          className += ' text-secondary';
          break;
        case SORT_BY.REQUEST_TIME:
          className += ' text-info';
          break;
        default:
          break;
      }
      return className;
    },

    isSupporting() {
      return this.tabIndex !== TABS.WAITING_COMMON;
    }
  },

  methods: {
    ...mapActions('chat', ['setSortBy']),
    handleSortMenu(command) {
      let sortOption = {
        channelId: this.channelId,
        sortBy: SORT_BY.LAST_MESSAGE,
        direction: 0
      };

      switch (command) {
        case 'message-up':
          sortOption.direction = 1;
          sortOption.isSearch = this.isSearch;
          break;
        case 'request-down':
          sortOption.direction = 0;
          sortOption.sortBy = SORT_BY.REQUEST_TIME;
          sortOption.isSearch = this.isSearch;
          break;
        case 'request-up':
          sortOption.direction = 1;
          sortOption.sortBy = SORT_BY.REQUEST_TIME;
          sortOption.isSearch = this.isSearch;
          break;
        case 'support-down':
          sortOption.direction = 0;
          sortOption.sortBy = SORT_BY.SUPPORT_TIME;
          sortOption.isSearch = this.isSearch;
          break;
        case 'support-up':
          sortOption.direction = 1;
          sortOption.sortBy = SORT_BY.SUPPORT_TIME;
          sortOption.isSearch = this.isSearch;
          break;
        default:
          break;
      }
      this.setSortBy(sortOption);
    },

    handleClick(e) {
      this.$emit('click', e);
    }
  }
};
</script>
<style lang="scss" scoped>
.sort-ticket {
  order: 2;
}
</style>
