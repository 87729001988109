<template>
  <b-card
    :title="currencyFormatting"
    :img-src="image_url"
    :img-alt="name"
    overlay
    img-top
    tag="article"
    style="max-width: 200px;"
    class="product-item"
  >
    <button
      type="button"
      class="btn btn-sm btn-outline-danger btn-remove"
      @click="$emit('onRemoveItem', id)"
    >
      <i class="fas fa-trash" />
    </button>
    <p v-b-tooltip.hover :title="name" class="card-text">
      {{ name | truncate(10) }}
    </p>
  </b-card>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    image_url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selectedProduct: ''
    };
  },
  computed: {
    currencyFormatting() {
      return `${this.price}`;
    }
  },

  methods: {
    handelGetProductView(id) {
      this.show = true;
      this.$emit('onSelectedItem', id);
    },
    handelOpenProductUrl() {
      window.open(this.url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.product-item {
  /deep/ img {
    max-width: 160px;
    max-height: 160px;
  }
  /deep/ .card-body {
    padding: 5px !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .card-title,
    .card-text {
      font-size: 12px !important;
      margin-bottom: 5px !important;
      color: #fff;
    }
  }
}

.pull-right {
  float: right;
}

.btn-remove {
  position: absolute;
  top: 7px;
  right: 0;
}

.card {
  >>> .card-body {
    background-image: linear-gradient(
      to bottom,
      rgba(43, 42, 60, 0),
      rgba(43, 42, 60, 0.2),
      rgba(43, 42, 60, 1)
    );
  }
}
</style>
