export const CREATE_CUSTOMER_SUPPORT = 'CREATE_CUSTOMER_SUPPORT';
export const UPDATE_CUSTOMER_SUPPORT = 'UPDATE_CUSTOMER_SUPPORT';
export const SET_CUSTOMER_SUPPORTS = 'SET_CUSTOMER_SUPPORTS';
export const SET_SUPPORTFORM_SEARCHTEXT = 'SET_SUPPORTFORM_SEARCHTEXT';
export const SET_CUSTOMER_SUPPORT = 'SET_CUSTOMER_SUPPORT';
export const DELETE_CUSTOMER_SUPPORT = 'DELETE_CUSTOMER_SUPPORT';
export const SET_CUSTOMER_SUPPORT_SETTING = 'SET_CUSTOMER_SUPPORT_SETTING';
export const UPDATE_CUSTOMER_SUPPORT_TREE_STORE = 'UPDATE_CUSTOMER_SUPPORT_TREE_STORE';
export const CLEAR_TREE_STORE = 'CLEAR_TREE_STORE';
export const SET_CSF_MODE = 'SET_CSF_MODE';
export const SET_CUSTOMER_SUPPORT_PANEL_SETTING = 'SET_CUSTOMER_SUPPORT_PANEL_SETTING';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_PROVINCES = 'SET_PROVINCES';
export const SET_TEMPLATE_CONTENT = 'SET_TEMPLATE_CONTENT';
