import Vue from 'vue';
import VueApollo from 'vue-apollo';
import AWSAppSyncClient from 'aws-appsync';
import * as localForage from 'localforage';

// Install the vue plugin
Vue.use(VueApollo);

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  const client = new AWSAppSyncClient(
    {
      url: process.env.VUE_APP_GRAPHQL_HTTP,
      region: process.env.VUE_APP_REGION,
      auth: {
        type: 'API_KEY',
        apiKey: process.env.VUE_APP_APPSYNC_API_KEY
      },
      offlineConfig: {
        storage: localForage
      }
    },
    {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache' //'cache-and-network'
        }
      }
    }
  );

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: client,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache' //'cache-and-network'
      }
    }
  });

  return apolloProvider;
}
