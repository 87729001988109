<template>
  <p v-html="content" />
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import {
  getTextFromMessageContent,
  urlify,
  escapeHtmlChar,
  getTranslationsMapping
} from 'core/helpers';

export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState('session', ['channelsMap']),

    content() {
      let selectedConversation = cloneDeep(this.selectedConversation);
      const { userInfo = {} } = selectedConversation || {};
      const { channelId = '', translations = null } = selectedConversation || {};
      if (channelId && !translations) {
        selectedConversation = {
          ...selectedConversation,
          translations: getTranslationsMapping(userInfo, channelId, this.channelsMap)
        };
      }

      const { content, type, text } = this.message || {};
      const textMsg = getTextFromMessageContent(
        content,
        type,
        text,
        selectedConversation
      );
      return urlify(escapeHtmlChar(textMsg));
    }
  }
};
</script>
