import Service from 'core/service';
import baseNotification from 'components/BaseComponents/BaseNotification';
import router from 'core/router';
import noAvatar from 'assets/images/no-avatar.png';
import { i18n } from 'locales';
import { pick, uniqBy, sortBy, uniq, isEmpty, cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { EventBus } from 'core/eventBus';
import baseConfirm from 'components/BaseComponents/BaseConfirm';
import { FirebaseFuncAPI } from 'core/firebase/firebaseFuncAPI';
import CookiesModel from 'core/cookies';
import wcService from 'core/webchatService';
import lcService from 'core/liffchatService';
import { ticketHandler } from './helpers';
import {
  convertGroupNavToTabIndex,
  getGroupConvId,
  buildMailOptions,
  getTabIndex,
  unregisterSW
} from 'core/helpers';
import { getSearchTickets, countMonitoring } from 'elastic/api';
import {
  AGENT_ROLE,
  TICKET_STATUS,
  SERVING_STATE,
  TICKET_STATE,
  SORT_BY,
  AGENT_STATUS,
  TICKET_SEARCH_TYPE,
  TABS,
  NOTIFICATION_SETTINGS_KEY,
  TICKET_TYPE_CODE,
  AGENT_ROLE_CORE
} from 'core/constants';
import {
  SET_USER,
  UPDATE_USER,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  SET_CHANNEL_LIST,
  SET_CONVERSATIONS,
  ADD_NEW_CONVERSATION,
  UPDATE_CONVERSATION_LATEST_MSG,
  UPDATE_CONVERSATION_LOCAL,
  ADD_PERSON,
  UPDATE_PERSON,
  ADD_TRANSLATIONS,
  SET_WAITING_MODE,
  SET_PROJECT_MAPS,
  ADD_TICKET,
  SET_TICKETS,
  REMOVE_TICKETS,
  SET_LOADMORE_TIME,
  SET_LOADING_MORE,
  SET_TICKETS_SEARCH,
  SET_TICKETS_SEARCH_CLEAR,
  SET_TICKETS_SEARCH_LOAD_MORE,
  SET_COMMON_WAITING_COUNT,
  SET_ME_WAITING_COUNT,
  SET_BOT_HANDLE_COUNT,
  SET_AGENT_HANDLE_COUNT,
  REMOVE_TICKET_SEARCH,
  SET_LAST_RUN_TIME,
  SET_PROJECT_TAG_MAP,
  ADD_NEW_TAG,
  DELETE_TAG,
  ADD_NEW_TICKET_TAG,
  DELETE_TICKET_TAG,
  SET_PROJECT_LABEL_MAP,
  SET_LDAP_LIST,
  ADD_NEW_LABEL,
  DELETE_LABEL,
  ADD_NEW_USER_LABEL,
  DELETE_USER_LABEL,
  UPDATE_WC_READ,
  UPDATE_LC_READ,
  SET_READY,
  SET_REFRESHING,
  CHANGE_MAINTENANCE_MODE,
  SET_CHAT_SEARCH_BOX_FILTER,
  ADD_USER_LABEL_TO_PROJECT_LABEL_MAP,
  UPDATE_CONVERSATION_UN_FOLLOW,
  UPDATE_TICKET_UN_FOLLOW,
  UPDATE_COMMON_WAITING_COUNT,
  UPDATE_ME_WAITING_COUNT,
  UPDATE_AGENT_HANDLE_COUNT,
  NUMBER_NOTIFICATIONS,
  NUMBER_UNREAD_MESSAGES,
  UNREAD_MESSAGES_TICKETIDS,
  NOTIFICATIONS_MAP,
  DELETE_NOTIFICATION,
  FIRST_TIME_IN_NOTIFICATIONS,
  LAST_TIME_IN_NOTIFICATIONS,
  DISPLAY_NOTIFY_SCREEN,
  UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET,
  HAS_NEW_NOTIFICATION_CAME,
  NEW_NOTIFICATIONS_CAME,
  LAST_NOTIFY_CAME,
  HAS_UPDATE_TICKET_COME,
  CHANNEL_TAB_RELOAD,
  CHANNEL_TAB_PAGE,
  SET_COMMON_WAITING_LOADED_COUNT,
  SET_ME_WAITING_LOADED_COUNT,
  REMOVE_OLD_TICKET,
  GO_TO_TICKET_COME,
  GO_TO_TICKET,
  SELECTED_CATEGORY,
  SET_MONITORING_COUNT,
  SELECTED_CHANNEL,
  SET_TICKET_COME_NEW_MAP,
  CLEAR_TICKET_COME_NEW_MAP,
  SYNC_USER_LABEL_COLOR,
  SYNC_CONVERSATION_LABEL_COLOR,
  UPDATE_STATUS_REGISTERED
} from './types';

const firebaseFuncAPI = new FirebaseFuncAPI({
  baseURL: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_URL
});
const URL_USER = process.env.VUE_APP_USER_SERVICE_ENDPOINT;
const service = new Service();

const renewCPlusToken = async () => {
  return await service
    .rest('authorizer/renewCPlusToken', {}, { method: 'post' }, 0)
    .then(response => saveLoginData(response))
    .catch(error => {
      // eslint-disable-next-line
      console.log(' renewCPlusToken ~ error', error);
      return false;
    });
};

const getSessionUser = ({ commit, state }, refresh = false) => {
  if (!refresh && state.user && Object.values(state.user).length > 0) return state.user;
  return service
    .get('agents/me')
    .then(({ me }) => {
      const socialLinks = {
        google: {
          allow: false,
          profileId: ''
        },
        facebook: {
          allow: false,
          profileId: ''
        }
      };
      if (!me.socialLinks) me.socialLinks = socialLinks;
      else
        me.socialLinks = {
          ...socialLinks,
          ...me.socialLinks
        };
      me.pictureUrl = me.pictureUrl || noAvatar;
      me.gender = me.gender || '';
      me.operator = me.operator ? me.operator : {};
      me.region = me.region ? me.region : {};
      me.country = me.country ? me.country : {};
      me.company = me.company ? me.company : {};
      me.assignedProjects.sort((project1, project2) => {
        return project1.name.localeCompare(project2.name, 'en', {
          sensitivity: 'case'
        });
      });
      commit(SET_USER, me);
      commit(ADD_PERSON, {
        personId: me.id,
        person: {
          id: me.id,
          name:
            me.firstName && me.lastName
              ? me.firstName + ' ' + me.lastName
              : me.firstName || me.lastName,
          firstName: me.firstName,
          lastName: me.lastName,
          pictureUrl: me.pictureUrl,
          platform: 'agent'
        }
      });
      return me;
    })
    .catch(error => {
      commit(AUTH_ERROR);
      // eslint-disable-next-line
      console.log('[getSessionUser] > error', error);
      return null;
    });
};

const setUser = ({ commit }, user) => commit(SET_USER, user);

const updateUser = ({ commit }, user) => commit(UPDATE_USER, user);

const updateAgentStatus = async (context, { agentId, status }) => {
  const { state } = context;
  const { user } = state;
  return service
    .rest('agents/updateAgentStatus', { agentId, status })
    .then(agent => {
      const { status = AGENT_STATUS.ONLINE } = agent;
      if (!isEqual(user.status, status)) {
        const newUser = { ...user, status };
        setUser(context, newUser);
      }
      return agent;
    })
    .catch(() => {});
};

const login = ({ commit }, payload) => {
  commit(AUTH_REQUEST);
  let { email, password } = payload;
  email = email
    .toString()
    .trim()
    .toLowerCase();

  return service
    .rest('auth/login', { provider: 'email', email, password }, { method: 'post' }, 0)
    .then(response => {
      const { cToken, token, tid, userInfo, errors } = response;
      let needChangePassword = false;
      if (errors) {
        const { messageCode, content } = errors;
        switch (messageCode) {
          case 'locked-account':
            baseNotification.warning({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t('src.modules.session.store.actions.account_locked')
            });
            return;
          case 'store-recent-passwords-fail':
            baseNotification.error({
              title: i18n.t('src.core.App.error'),
              message: i18n.t('src.modules.session.store.actions.internal_error')
            });
            return;
          case 'password-expired':
            localStorage.setItem('login-status', 'password-expired');
            needChangePassword = true;
            baseNotification.warning({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t('src.modules.session.store.actions.password_expired')
            });
            break;
          case 'need-change-password':
            localStorage.setItem('login-status', 'first-login');
            needChangePassword = true;
            break;
          case 'invalid-cnt-login-fail': {
            const { cntLoginFail, cntLoginFailIsLocked } = content;
            baseNotification.warning({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t('src.modules.session.store.actions.email_login_fail', {
                loginFail: Number(cntLoginFailIsLocked) - Number(cntLoginFail)
              })
            });
            return;
          }
          case 'too-many-requests':
            baseNotification.warning({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t('src.modules.session.store.actions.manyRequest_login_fail')
            });
            return;
          case 'warning-password-expired':
            {
              const { cntPasswordEffective } = content;
              baseNotification.warning({
                title: i18n.t('src.core.App.warning'),
                message: i18n.t('src.modules.session.store.actions.warning_password_expired', {
                  cntPasswordEffective
                })
              });
            }
            break;
          case 'email-or-password-incorrect':
            {
              baseNotification.warning({
                title: i18n.t('src.core.App.warning'),
                message: i18n.t('src.modules.session.store.actions.email_or_password_incorrect')
              });
            }
            return;
          case 'agent-not-assigned-project':
            baseNotification.warning({
              title: i18n.t('src.core.App.warning'),
              message: i18n.t('src.core.App.sorry_You_are_not_assigned_to_any_project')
            });
            return;
        }
      }
      if (!token) throw 'Login fail';
      if (userInfo.hasOwnProperty('isLocked') && userInfo['isLocked'] === true) {
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.email_is_blocked')
        });
        return;
      }
      if (
        userInfo.hasOwnProperty('needChangePassword') &&
        userInfo['needChangePassword'] === true
      ) {
        localStorage.setItem('login-status', 'first-login');
        needChangePassword = true;
      }
      const user = {
        token,
        needChangePassword
      };
      const { firstName = '', lastName = '' } = userInfo || {};
      if (userInfo && userInfo.email) {
        user.email = userInfo.email;
      } else {
        user.email = payload.email;
      }

      firstName ? (user['firstName'] = firstName) : null;
      lastName ? (user['lastName'] = lastName) : null;

      commit(AUTH_SUCCESS, user);
      commit(SET_USER, user);

      saveLoginData({ ...user, cToken, token, tid });
      if (needChangePassword) router.replace('/');
      else {
        if (
          [
            'demotester_oieueyj_demotester@tfbnw.net',
            'demosocialgear@gmail.com',
            'manage_jhmaoxi_user@tfbnw.net'
          ].includes(user.email)
        ) {
          window.location.href = '/com/17841405860502099';
        } else {
          window.location.href = '/';
        }
      }
      return user;
    })
    .catch(err => {
      commit(AUTH_ERROR, err);
      return handleLoginError(err);
    });
};

const saveLoginData = ({ cToken, token, email, tid }) => {
  const cookies = new CookiesModel();
  cookies.storeAccessToken(cToken.accessToken, cToken.expiration);
  cookies.storeAccessTokenExpiration(cToken.accessTokenExpiresAt, cToken.expiration);
  localStorage.setItem('user-token', token);
  localStorage.setItem('user-email', email);
  localStorage.setItem('user-tid', tid);
  localStorage.removeItem('auth-status');
  window.loggedInUserEmail = email;
};

const handleLoginSuccess = (user, commit) => {
  let needChangePassword = false;
  const { userInfo = {}, errors } = user;
  if (errors) {
    const { messageCode, content } = errors;
    switch (messageCode) {
      case 'locked-account':
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.account_locked')
        });
        return;
      case 'store-recent-passwords-fail':
        baseNotification.error({
          title: i18n.t('src.core.App.error'),
          message: i18n.t('src.modules.session.store.actions.internal_error')
        });
        return;
      case 'password-expired':
        localStorage.setItem('login-status', 'password-expired');
        needChangePassword = true;
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.password_expired')
        });
        break;
      case 'need-change-password':
        localStorage.setItem('login-status', 'first-login');
        needChangePassword = true;
        break;
      case 'invalid-cnt-login-fail': {
        const { cntLoginFail, cntLoginFailIsLocked } = content;
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.email_login_fail', {
            loginFail: Number(cntLoginFailIsLocked) - Number(cntLoginFail)
          })
        });
        return;
      }
      case 'warning-password-expired': {
        const { cntPasswordEffective } = content;
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.warning_password_expired', {
            cntPasswordEffective
          })
        });
        return;
      }
      case 'email-or-password-incorrect':
        {
          baseNotification.warning({
            title: i18n.t('src.core.App.warning'),
            message: i18n.t('src.modules.session.store.actions.email_or_password_incorrect')
          });
        }
        return;
      case 'agent-not-assigned-project':
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.core.App.sorry_You_are_not_assigned_to_any_project')
        });
        return;
      case 'account-does-not-exist':
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.core.App.account_does_not_exist')
        });
        return;
    }
  }
  if (userInfo.hasOwnProperty('isLocked') && userInfo['isLocked'] === true) {
    baseNotification.warning({
      title: i18n.t('src.core.App.warning'),
      message: i18n.t('src.modules.session.store.actions.email_is_blocked')
    });
    return false;
  }
  if (userInfo.hasOwnProperty('needChangePassword') && userInfo['needChangePassword'] === true) {
    localStorage.setItem('login-status', 'first-login');
    needChangePassword = true;
  }
  user['needChangePassword'] = needChangePassword;
  commit(AUTH_SUCCESS, user);
  commit(SET_USER, userInfo);

  saveLoginData({ ...user });
  if (needChangePassword) router.replace('/');
  else {
    if (
      [
        'demotester_oieueyj_demotester@tfbnw.net',
        'demosocialgear@gmail.com',
        'manage_jhmaoxi_user@tfbnw.net'
      ].includes(user.email)
    ) {
      window.location.href = '/com/17841405860502099';
    } else {
      window.location.href = '/';
    }
  }
  return true;
};

const handleLoginError = () => {
  const cookies = new CookiesModel();
  cookies.clearCookies();
  localStorage.removeItem('user-token');
  localStorage.removeItem('auth-status');

  baseNotification.warning({
    title: i18n.t('src.core.App.warning'),
    message: i18n.t('src.modules.session.store.actions.email_is_invalid')
  });
  return false;
};

const loginGoogle = ({ commit }, idToken) => {
  commit(AUTH_REQUEST);
  return service
    .rest(
      'auth/login',
      {
        provider: 'google',
        idToken
      },
      { method: 'post' },
      0
    )
    .then(user => handleLoginSuccess(user, commit))
    .catch(err => {
      commit(AUTH_ERROR, err);
      return handleLoginError(err);
    });
};

const loginFacebook = ({ commit }, accessToken) => {
  commit(AUTH_REQUEST);
  service
    .rest(
      'auth/login',
      {
        provider: 'facebook',
        accessToken
      },
      { method: 'post' },
      0
    )
    .then(user => handleLoginSuccess(user, commit))
    .catch(err => {
      commit(AUTH_ERROR, err);
      return handleLoginError(err);
    });
};

const loginOAuth = ({ commit }, { code, clientId }) => {
  return new Promise(resolve => {
    commit(AUTH_REQUEST);
    service
      .rest(
        'auth/login',
        {
          provider: 'oauth',
          clientId,
          code,
          redirect: window.location.origin + '/adfs/cb'
        },
        { method: 'post' },
        0
      )
      .then(user => {
        let needChangePassword = false;
        const { userInfo } = user;
        if (userInfo.hasOwnProperty('isLocked') && userInfo['isLocked'] === true) {
          baseNotification.warning({
            title: i18n.t('src.core.App.warning'),
            message: i18n.t('src.modules.session.store.actions.email_is_blocked')
          });
          resolve(false);
          return;
        }
        if (
          userInfo.hasOwnProperty('needChangePassword') &&
          userInfo['needChangePassword'] === true
        ) {
          localStorage.setItem('login-status', 'first-login');
          needChangePassword = true;
        }
        user['needChangePassword'] = needChangePassword;
        commit(AUTH_SUCCESS, user);
        commit(SET_USER, user);

        if (!needChangePassword) {
          saveLoginData({ ...user });
          window.location.href = '/';
          resolve(user);
        }

        localStorage.removeItem('user-token');
        localStorage.removeItem('user-email');
        router.replace('/');
        resolve(user);
      })
      .catch(err => {
        commit(AUTH_ERROR, err);
        const cookies = new CookiesModel();
        cookies.clearCookies();
        localStorage.removeItem('user-token');
        localStorage.removeItem('auth-status');

        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.store.actions.email_is_invalid')
        });
        resolve(false);
      });
  });
};

const reloadStateInit = ({ commit, dispatch }) => {
  commit(AUTH_LOGOUT);
  dispatch('chat/removeInitState', null, { root: true });
  localStorage.removeItem('instant-settings');
};

const logout = ({ commit, dispatch }, user) => {
  // Save Agent Track Time
  const tokenId = localStorage.getItem('user-tid');
  return service.rest('authorizer/logout', { user, tokenId }).then(() => {
    commit(AUTH_LOGOUT);
    dispatch('chat/removeInitState', null, { root: true });
    const cookies = new CookiesModel();
    cookies.clearCookies();
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-email');
    localStorage.removeItem('auth-status');
    localStorage.removeItem('instant-settings');
    delete window.loggedInUserEmail;
    unregisterSW();
    window.location.href = '/login';
    return true;
  });
};

const getChannels = async ({ commit, state }, refresh = false) => {
  if (refresh || state.channels.length < 1) {
    const data = await service.get('channels/channels');
    let channels = data.Items;
    let loadChannels = [...channels];
    var sortedLoadChannels = sortBy(loadChannels, 'name');
    commit(SET_CHANNEL_LIST, sortedLoadChannels);
    commit(SET_READY);
    return sortedLoadChannels;
  } else {
    return state.channels;
  }
};

const reloadBotList = async ({ commit, state, dispatch, rootState, rootGetters }) => {
  const { channels } = state;
  if (!channels || !channels.length) return false;
  commit(SET_REFRESHING, true);
  Promise.all(
    channels.map(channel => {
      const { id: channelId, projectId } = channel;
      dispatch(
        'session/clearList',
        {
          channelId
        },
        {
          root: true
        }
      );

      return loadMoreConversation(
        { commit, state, dispatch, rootState, rootGetters },
        { projectId, channelId, status: 0, refresh: true }
      );
    })
  )
    .then(() => commit(SET_REFRESHING, false))
    .catch(() => commit(SET_REFRESHING, false));
};

const reloadMonitoringList = async ({ commit, state, dispatch, rootState, rootGetters }) => {
  const { channels } = state;
  if (!channels || !channels.length) return false;
  commit(SET_REFRESHING, true);
  Promise.all(
    channels.map(channel => {
      const { id: channelId, projectId } = channel;
      dispatch(
        'session/clearList',
        {
          channelId
        },
        {
          root: true
        }
      );

      return loadMoreConversation(
        { commit, state, dispatch, rootState, rootGetters },
        { projectId, channelId, status: 5, refresh: true }
      );
    })
  )
    .then(() => commit(SET_REFRESHING, false))
    .catch(() => commit(SET_REFRESHING, false));
};

const reloadChannel = async (
  { commit, state, dispatch, rootState, rootGetters },
  { channelId, status = 0, limit = 10, selectedCategory = 'all' }
) => {
  // eslint-disable-next-line
  console.log('reloadChannel', { channelId, status });
  if (status === 9) return null;
  const { channelsMap } = state;
  const { id, projectId } = channelsMap[channelId] || {};

  commit(HAS_UPDATE_TICKET_COME, { channelStatus: `${channelId}${status}`, flag: false });
  dispatch(
    'session/clearList',
    {
      channelId: id
    },
    {
      root: true
    }
  );

  return loadMoreConversation(
    { commit, state, dispatch, rootState, rootGetters },
    { projectId, channelId, status, refresh: true, limit, selectedCategory }
  );
};

const loadMoreConversation = async (
  { commit, state, dispatch, rootState, rootGetters },
  {
    projectId,
    channelId,
    status,
    refresh = false,
    limit = 100,
    oldPage = 1,
    page = 1,
    pagingDirection = 1,
    nextTime,
    currentTime,
    selectedCategory
  }
) => {
  const isPhoneAgent = rootGetters && rootGetters['session/isPhoneAgent'];
  if (isPhoneAgent) return;

  if (currentTime) {
    const newCurrentTime = new Date(currentTime);
    newCurrentTime.setMilliseconds(newCurrentTime.getMilliseconds() + 1);
    currentTime = newCurrentTime;
  }
  const { loadingMoreMaps, loadPagingTimeMaps } = state;
  const channelStatus = channelId + status;

  if (loadingMoreMaps[channelStatus] && refresh === false) return 0;
  if (oldPage !== 0) {
    commit(SET_LOADING_MORE, { channelStatus, loading: true });
  }
  try {
    let updatedAt = null;
    let pageStatus = '1|1';
    if (pagingDirection > 0) {
      updatedAt = nextTime || null;
    } else {
      pageStatus = page + '|' + '-1';
      updatedAt =
        loadPagingTimeMaps[projectId] && loadPagingTimeMaps[projectId][channelStatus]
          ? loadPagingTimeMaps[projectId][channelStatus][pageStatus] || null
          : null;
    }

    const { selectedGroupConv, sortBysMap } = rootState.chat;
    const { waitingMode } = state;

    let grpNavTabIndex = 0;

    switch (selectedGroupConv) {
      case SERVING_STATE.WAITING:
        if (waitingMode === 'TOME') {
          grpNavTabIndex = 2;
        } else {
          grpNavTabIndex = 1;
        }
        break;
      case SERVING_STATE.AGENT:
        grpNavTabIndex = 3;
        break;
      case SERVING_STATE.MONITORING:
        grpNavTabIndex = 4;
        break;
      default:
        grpNavTabIndex = 0;
        break;
    }

    let groupId = selectedGroupConv;
    if (groupId === SERVING_STATE.WAITING) {
      groupId += waitingMode;
    }

    let { sortBy, direction } = sortBysMap[channelId + groupId] || {
      sortBy: SORT_BY.LAST_MESSAGE,
      direction: 0
    };

    let _selectedCategory = selectedCategory;
    if (selectedCategory === undefined) {
      // eslint-disable-next-line
      console.log(
        '[C+ Debug] [WARNING] >> loadMoreConversation >> selectedCategory',
        selectedCategory
      );
      _selectedCategory = 'all';
    }

    if (!updatedAt || refresh) {
      updatedAt =
        direction > 0
          ? moment(0).toISOString()
          : moment()
              .add(1, 'day')
              .toISOString();
    }

    const result = await service.post('conversations/getTicketsAndConversationsByProject', {
      projectId,
      channelId,
      updatedAt,
      status,
      sortBy,
      sortDirection: direction,
      pagingDirection,
      limit,
      groupId: _selectedCategory
    });

    let count = 0;
    if (oldPage === 0 && result && result.length == 0) {
      dispatch('setChannelTabReload', { channelStatus, flag: false });
      return 0;
    }
    switch (status) {
      case 5:
        dispatch('getMonitoringCount', {
          projectId,
          channelId,
          groupId: _selectedCategory
        });
        break;
      default:
        break;
    }
    if (result) {
      const { ticketMaps = {}, conversations, tickets } = result;
      if (tickets && Array.isArray(tickets) && tickets.length <= 0) return count;

      const newTicketMaps = addGrpNavTabIndex({
        ticketMaps,
        grpNavTabIndex,
        page
      });

      notyetShowFTOM(ticketMaps);
      commit(SET_TICKETS, {
        projectId,
        channelId,
        tickets: newTicketMaps,
        page,
        status,
        grpNavTabIndex
      });

      const convs = conversations || [];
      // fix locale
      convs.map(conv => {
        if (typeof conv.userInfo === 'string') {
          try {
            conv.userInfo = JSON.parse(conv.userInfo);
          } catch (error) {
            // eslint-disable-next-line
            console.log('loadMoreConversation >> userInfo >> error', error);
            conv.userInfo = { id: conv.id, name: conv.name };
          }
        }
        setDefaultLanguage(conv, state);
      });

      commit(SET_CONVERSATIONS, convs);

      if (oldPage !== 0) {
        if (direction > 0) {
          let lastTime = moment(0).toISOString();
          if (lastTime.localeCompare(updatedAt) > 0)
            commit(SET_LOADMORE_TIME, {
              projectId,
              status: channelStatus,
              oldUpdatedAt: updatedAt,
              updatedAt: lastTime,
              page,
              oldPage,
              pagingDirection,
              currentTime
            });
          else {
            commit(SET_LOADMORE_TIME, {
              projectId,
              status: channelStatus,
              oldUpdatedAt: updatedAt,
              updatedAt: '',
              page,
              oldPage,
              pagingDirection,
              currentTime
            });
          }
        } else {
          let lastTime = moment().toISOString();
          if (lastTime.localeCompare(updatedAt) < 0)
            commit(SET_LOADMORE_TIME, {
              projectId,
              status: channelStatus,
              updatedAt: lastTime,
              oldUpdatedAt: updatedAt,
              page,
              oldPage,
              pagingDirection,
              currentTime
            });
          else {
            commit(SET_LOADMORE_TIME, {
              projectId,
              status: channelStatus,
              oldUpdatedAt: updatedAt,
              updatedAt: '',
              page,
              oldPage,
              pagingDirection,
              currentTime
            });
          }
        }
      }
      count = Object.keys(ticketMaps).length;
    }
    return count;
  } catch (error) {
    // eslint-disable-next-line
    console.error('[C+ Debug]: [ERROR] >>> loadMoreConversation > error', error);
  } finally {
    commit(SET_LOADING_MORE, { channelStatus, loading: false });
  }
};

const notyetShowFTOM = ticketMaps => {
  let notyetShowFTOM = localStorage.getItem('notyetShowFinishTimeOutMessage');
  notyetShowFTOM = notyetShowFTOM ? JSON.parse(notyetShowFTOM) : [];

  for (let key in ticketMaps) {
    const { state, status } = ticketMaps[key];
    if (
      !notyetShowFTOM.includes(key) &&
      state === TICKET_STATE.FINISH &&
      status !== TICKET_STATUS.FINISH_TIMEOUT
    ) {
      notyetShowFTOM.push(key);
    }
  }
  localStorage.setItem('notyetShowFinishTimeOutMessage', JSON.stringify(notyetShowFTOM));
  return notyetShowFTOM;
};

const addGrpNavTabIndex = ({ ticketMaps, grpNavTabIndex, page }) => {
  let newTicketMaps = {};
  Object.keys(ticketMaps).map(ticketId => {
    const ticket = ticketMaps[ticketId];
    ticket.grpNavTabIndex = grpNavTabIndex;
    ticket.page = [{ page, grpNavTabIndex }];
    newTicketMaps[ticketId] = { ...ticket, labels: ticket.labels || [] };
    return newTicketMaps;
  });
  return newTicketMaps;
};

const addGrpNavTabIndexSearch = (ticketMaps, grpNavTabIndex) => {
  return Promise.all(
    Object.keys(ticketMaps).map(ticketId => {
      const ticket = ticketMaps[ticketId];
      if (ticket) {
        ticket.grpNavTabIndex = grpNavTabIndex;
        ticket.search = TICKET_SEARCH_TYPE.TICKET_SEARCH;
      }

      return ticket;
    })
  );
};

const addNewConversation = async ({ commit, state }, conversation) => {
  const { userId, channelId } = conversation;
  const { platform } = state.channelsMap[channelId];
  if (conversation && typeof conversation.userInfo === 'string') {
    try {
      conversation.userInfo = JSON.parse(conversation.userInfo);
    } catch (error) {
      // eslint-disable-next-line
      console.log('addConversationLocal -> error', error);
      conversation.userInfo = { id: conversation.userId, name: conversation.name };
    }
  }
  setDefaultLanguage(conversation, state);

  commit(ADD_NEW_CONVERSATION, conversation);
  if (conversation.userInfo && userId && channelId) {
    commit(ADD_PERSON, {
      personId: `${userId}_${channelId}`,
      person: {
        id: conversation.userId,
        ...conversation.userInfo,
        platform
      }
    });
  }
  return conversation;
};

const addConversationLocal = async ({ commit, state }, conversation) => {
  if (conversation && typeof conversation.userInfo === 'string') {
    try {
      conversation.userInfo = JSON.parse(conversation.userInfo);
    } catch (error) {
      // eslint-disable-next-line
      console.log('addConversationLocal -> error', error);
      conversation.userInfo = { id: conversation.userId, name: conversation.name };
    }
  }
  const { platform } = state.channelsMap[conversation.channelId];

  setDefaultLanguage(conversation, state);

  commit(ADD_NEW_CONVERSATION, conversation);
  const { userId, channelId } = conversation;
  if (conversation.userInfo && userId && channelId) {
    commit(ADD_PERSON, {
      personId: `${userId}_${channelId}`,
      person: {
        id: conversation.userId,
        ...conversation.userInfo,
        platform
      }
    });
  }
  return conversation;
};

const updateConversationLatestMessage = async ({ commit, state }, msg) => {
  const { tickets, people } = state;
  const { ticketId } = msg || {};
  const ticket = tickets[ticketId] || {};
  const { id, assignee = '', agentId = '' } = ticket;
  if (assignee && agentId && id != assignee && assignee === agentId && !people[agentId]) {
    await addAgentToPeople({ commit }, agentId);
  }

  commit(UPDATE_CONVERSATION_LATEST_MSG, msg);
};

const updateConversationLocal = async ({ commit, rootState }, conversation) => {
  await commit(UPDATE_CONVERSATION_LOCAL, conversation);
  const selectedConversation = rootState.chat.selectedConversation || {};

  if (selectedConversation.id === conversation.id) {
    const c = { ...conversation, ticketId: selectedConversation.ticketId };
    // Using commit over dispatch to force the change immediately
    commit('chat/UPDATE_SELECTED_CONVERSATION', c, { root: true });
  }
};

const addAgentToPeople = async ({ commit }, agentId) => {
  const { Items = [] } = await service.rest('agents/getAgentById', { id: agentId }).catch(() => []);
  const agent = Items && Items.length ? Items[0] : { id: agentId };
  if (!agent.name || !agent.name.trim()) {
    agent.name = `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
  }
  commit(ADD_PERSON, { personId: agentId, person: agent });
  return agent;
};

const addUserToPeople = async ({ commit }, { userId, channelId, projectId, platform }) => {
  const userService = new Service(URL_USER);
  return userService
    .rest('personal/getPersonal', { userId, channelId, projectId, platform })
    .then(response => {
      if (Object.keys(response).length > 0) {
        response.channelId = channelId;
        response.id = userId;
        commit(ADD_PERSON, { personId: `${userId}_${channelId}`, person: response });
      }
      return response;
    })
    .catch(error => {
      //eslint-disable-next-line
      console.log('[C+ Debug] [ERROR] >>> addUserToPeople >> error', error);
    });
};

const addTranslations = async ({ commit }, conversation) => {
  commit(ADD_TRANSLATIONS, conversation);
};

const setDefaultLanguage = (conversation, state) => {
  conversation.userInfo = conversation.userInfo || { name: conversation.name };
  if (!conversation.userInfo.locale) {
    conversation.userInfo.locale =
      state.channelsMap[conversation.channelId].defaultLanguage || 'en_US';
    conversation.userInfo.displayLanguage = 'Unknown';
  }
};

const setWaitingMode = ({ commit }, mode) => {
  commit(SET_WAITING_MODE, mode);
};

const getProjectMaps = async ({ commit }) => {
  const projectMaps = await service.post('project/getProjectMaps');
  commit(SET_PROJECT_MAPS, projectMaps);
  commit(SET_READY);
  return projectMaps;
};

const getTicketCount = async (context, { projectIds }) => {
  const data = await service.post('dashboard/getTicketCounter', { projectIds });
  const { ticketCount = [] } = data || {};

  ticketCount.map(item => {
    updateTicketCountByAgentId(context, item);
    updateTicketCountByProjectId(context, item);
  });
};

const updateTicketCountByAgentId = ({ commit }, { channelId, common, tome, supporting }) => {
  commit(SET_COMMON_WAITING_COUNT, { channelId, count: common });
  commit(SET_ME_WAITING_COUNT, { channelId, count: tome });
  commit(SET_AGENT_HANDLE_COUNT, { channelId, count: supporting });
};

const updateTicketCountByProjectId = ({ commit }, { channelId, botHandle }) => {
  commit(SET_BOT_HANDLE_COUNT, { channelId, count: botHandle });
};

const updateLastMsgForTicket = (context, { message, action }) => {
  const { ticketId } = message;
  const { tickets = {} } = context.state;
  if (isEmpty(tickets)) return null;

  let isCommit = false;
  switch (action) {
    case 'new':
      if (tickets[ticketId]) isCommit = true;
      break;

    case 'update':
      {
        let ticket = tickets[ticketId];
        if (!ticket) return null;

        const { lastMessageContent = {} } = ticket;
        const { id, conversationId } = lastMessageContent;
        if (!id && !conversationId) isCommit = true;
        if (id === message.id && conversationId === message.conversationId) isCommit = true;
      }
      break;
  }

  if (!isCommit) return null;
  context.commit(UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET, message);
};

const updateLastMessagesForTicket = (context, { messages, action }) => {
  if (messages && messages.length > 0) {
    for (let message of messages) {
      updateLastMsgForTicket(context, { message, action });
    }
  }
};

const checkAgentHasPermissionOnTicket = async ({ dispatch, ticket }) => {
  if (!ticket.groupId) return true;

  const meGroups = (await dispatch('getGroupsOpts', { ticket })).map(item => item.value);
  return meGroups.includes(ticket.groupId);
};

const checkTicketHasGroupUser = async ({ dispatch, ticket }) => {
  if (ticket.hasOwnProperty('groupId') && ticket.groupId !== null) {
    const meGroups = (await dispatch('getGroupsOpts', { ticket })).map(item => item.value);
    if (meGroups.includes(ticket.groupId)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const handleNewButton = async ({ commit, state, dispatch, getters }, params) => {
  const { ticket } = params;
  const { meGroups } = getters;
  const { user, tickets, projectMaps } = state;

  if (ticket) {
    const { id: ticketId, channelId, assignee, projectId } = ticket;
    let tabIndex = getTabIndex({ conv: { ticket }, user });
    const { hasGroup = false } = projectMaps[projectId] || {};
    const projectConfig = projectMaps[projectId] && projectMaps[projectId].config;
    const { accessPermission: { isAllowAllView } = {} } = projectConfig || {};
    let isNewButtonMonitoring = true;

    if (
      (
        AGENT_ROLE.REGULAR === user.role &&
        ticket.assignee !== user.id &&
        ticket.assignee !== ticket.id
      ) ||
      (ticket?.groupId && !meGroups.includes(ticket?.groupId))
    ) 
      isNewButtonMonitoring = false;
    
    if (AGENT_ROLE.MODERATOR === user.role) {
      if (!hasGroup && ticket.assignee !== user.id && ticket.assignee !== ticket.id)
        isNewButtonMonitoring = false;
      else if (hasGroup && !isAllowAllView && ticket.agentRole === AGENT_ROLE_CORE.LEADER)
        isNewButtonMonitoring = false;
      else if (ticket?.groupId && !meGroups.includes(ticket?.groupId))
        isNewButtonMonitoring = false;
      }
      
    if (
      tabIndex === '' ||
      (tabIndex === TABS.BOT &&
        ![TICKET_STATUS.REQUEST_USER, TICKET_STATUS.USER_MENTION].includes(ticket.status))
    )
      return;

    const handleTicket = tickets[ticketId];
    const channelStatus = `${channelId}${tabIndex}`;
    const isAgetHasPermission = await checkAgentHasPermissionOnTicket({ dispatch, ticket });
    const oldTabIndex = getTabIndex({ conv: { ticket: handleTicket }, user });

    if (!isAgetHasPermission) {
      // ticket add to group which is not support by current agent
      tabIndex = '-1';
    }
    // Add/remove ticket out of handle list
    setTicketComeNewMap(
      { commit },
      { ticketId, tabIndex, channelId, state: ticket.state, status: ticket.status }
    );

    const COMMON_KEY = `${channelId}${TICKET_TYPE_CODE.COMMON}`;
    const TOME_KEY = `${channelId}${TICKET_TYPE_CODE.TOME}`;
    const MONITORING_KEY = `${channelId}${TICKET_TYPE_CODE.MONITORING}`;
    const { ticketComeNewMap } = state;

    const monitoringTickets = ticketComeNewMap[MONITORING_KEY] || new Set([]);
    const commontTickets = ticketComeNewMap[COMMON_KEY] || new Set([]);
    const tomeTickets = ticketComeNewMap[TOME_KEY] || new Set([]);
    const newTickets = ticketComeNewMap[channelStatus] || new Set([]);

    // Turn off new button when no more new tickets
    if (monitoringTickets.size <= 0) {
      setChannelHasUpdateTicketCome({ commit }, { channelStatus: MONITORING_KEY, flag: false });
    }
    if (commontTickets.size === 0) {
      setChannelHasUpdateTicketCome({ commit }, { channelStatus: COMMON_KEY, flag: false });
    }
    if (tomeTickets.size === 0) {
      setChannelHasUpdateTicketCome({ commit }, { channelStatus: TOME_KEY, flag: false });
    }

    // Handld new button
    // With ticket isn't bot, always show New in monitoring Tab
    // Only show new button when ticket not yet add to monitoring tab
    if (
      monitoringTickets.size >= 1 &&
      (!handleTicket ||
        (tabIndex !== TABS.MONITORING &&
          handleTicket.page &&
          handleTicket.page.every(p => p.grpNavTabIndex !== TABS.MONITORING))) &&
      isNewButtonMonitoring
    ) {
      setChannelHasUpdateTicketCome(
        { commit },
        {
          channelStatus: MONITORING_KEY,
          flag: true
        }
      );
    }
    if (tabIndex !== TABS.ME) {
      // With ticket isn't bot and Agent tab then show new button.
      if (
        newTickets.size >= 1 &&
        (!handleTicket ||
          tabIndex !== oldTabIndex ||
          (assignee !== handleTicket.assignee && handleTicket.id !== handleTicket.assignee))
      ) {
        setChannelHasUpdateTicketCome({ commit }, { channelStatus, flag: true });
      }
    }
  }
};

const addTicketLocal = async ({ dispatch, commit, state, rootState }, params) => {
  let isFollowUp = false;
  let isAdd = false;
  let { ticket } = params;
  const { selectedConversation, selectedGroupConv, chatViewingGroup } = rootState.chat;
  const { user, tickets, waitingMode, chatSearchBoxFilter } = state;
  const { channelId, id: ticketId } = ticket;
  const oldTicket = tickets && tickets[ticket.id];
  let tabIndex = getTabIndex({ conv: { ticket }, user });
  const tabIndexOld = getTabIndex({ conv: { ticket: oldTicket }, user });
  const selectedTicketId =
    (selectedConversation &&
      ((selectedConversation.ticket && selectedConversation.ticket.id) ||
        selectedConversation.ticketId)) ||
    '';
  const groupId = getGroupConvId({ ticket }, user);
  const status = tabIndex === TABS.MONITORING ? TICKET_TYPE_CODE.MONITORING : tabIndex;
  const channelStatus = channelId + status;
  const isSearch = !isEmpty(chatSearchBoxFilter);

  // Handle display new button
  dispatch('handleNewButton', { ticket });

  try {
    if (
      oldTicket &&
      oldTicket.hasOwnProperty('feature') &&
      ticket.hasOwnProperty('feature') &&
      ticket.feature !== oldTicket.feature
    )
      isFollowUp = true;
    if (!oldTicket) {
      if (tabIndex !== TABS.BOT) {
        // New ticket Come => can show in tab [Agent]
        if (tabIndex === 3) {
          isAdd = true;
        }
      }
    } else if (tabIndex !== -1) {
      // old Ticket Come => Ticket is exist in State
      // Update old Ticket
      if (oldTicket.hasOwnProperty('grpNavTabIndex') && oldTicket.grpNavTabIndex !== 4) {
        // Set New Button for monitoring tab
        // check FORWARD_GROUP
        if ([TICKET_STATUS.FORWARD_GROUP].includes(ticket.status)) {
          const userHasGroup = await checkTicketHasGroupUser({ dispatch, ticket });
          if (userHasGroup) {
            //eslint-disable-next-line
            console.log('userHasGroup', userHasGroup);
          }
        } else {
          // exist ticket in (0,1,2,3) => go to tab4
          if (selectedTicketId === ticket.id && !isFollowUp) {
            isAdd = true;
            dispatch('setChannelTabReload', { channelStatus, flag: true });
          }
        }
      }

      if (tabIndex !== tabIndexOld) {
        // Ticket changed status and tabIndex
        if (tabIndex !== 3) {
          // In tab [Bot,Waiting Me, Waiting Common, Monitoring]
          // Agent Tab is realtime => only show New for another tab
          if (selectedTicketId === ticket.id) {
            if ([TICKET_STATUS.NO_SUPPORT].includes(ticket.status)) {
              if (oldTicket.hasOwnProperty('grpNavTabIndex')) {
                if (oldTicket.grpNavTabIndex === 4) {
                  isAdd = true;
                } else {
                  await dispatch('setDisplayView', { groupId: selectedGroupConv });
                }
              } else {
                await dispatch('setDisplayView', { groupId: selectedGroupConv });
                const temp = cloneDeep(oldTicket.page);
                ticket.page = temp.filter(item => item.grpNavTabIndex === 4);
                ticket.grpNavTabIndex = tabIndex;
                commit(ADD_TICKET, { ticket, grpNavTabIndex: tabIndexOld });
              }
            } else {
              // Agent role can see ticket ticket change is seleted => set gototicket = true and update selectedConversation
              if (isSearch && selectedGroupConv !== TABS.MONITORING) {
                await dispatch('setDisplayView', { groupId: selectedGroupConv });
              }

              dispatch('setChannelTabReload', { channelStatus, flag: true });
              setGoToTicketCome(
                { commit },
                { channelStatus, flag: true, ticketId, tabIndex, channelId }
              );
              isAdd = true;
            }
          } else {
            if (TICKET_STATUS.NO_SUPPORT === ticket.status) {
              isAdd = true;
            }
            if ([TICKET_STATUS.FORWARD_GROUP].includes(ticket.status)) {
              const userHasGroup = await checkTicketHasGroupUser({ dispatch, ticket });
              if (userHasGroup) {
                //eslint-disable-next-line
                console.log('userHasGroup NO_SUPPORT', userHasGroup);
              } else {
                // Ticket is not in Group

                await dispatch('removeOldTicket', {
                  ticketId: ticket.id,
                  grpNavTabIndex: -1
                });
              }

              // display new button only, remove ticket from state
              ticket.deleted = true;
              isAdd = true;
            } else {
              if (status === 5) {
                if (oldTicket.hasOwnProperty('grpNavTabIndex') && oldTicket.grpNavTabIndex != 4) {
                  // Ticket exits in waiting list => Ticket is supported => removeticket and re count
                } else {
                  // Ticket exits in monitoring and another tab
                  // Ticket exits in Agent list and monitoring => Ticket is Submit => add ticket + cound
                  ticket.grpNavTabIndex = SERVING_STATE.MONITORING;
                }
                isAdd = true;
              } else {
                if (oldTicket.hasOwnProperty('grpNavTabIndex')) {
                  if (oldTicket.grpNavTabIndex !== 4) {
                    // Ticket is not exist in monitoring
                    // Ticket exits in waiting list => Ticket is supported => removeticket and re count
                    if (
                      tabIndexOld === TABS.BOT ||
                      [
                        TICKET_STATUS.FORWARD_GROUP,
                        TICKET_STATUS.TRANSFER_TO,
                        TICKET_STATUS.ESCALATE_TO,
                        TICKET_STATUS.ASSIGN_TO
                      ].includes(ticket.status)
                    ) {
                      ticket.deleted = true;
                    }
                    isAdd = true;
                  } else {
                    if ([TABS.WAITING_ME, TABS.MONITORING].includes(tabIndex)) {
                      isAdd = true;
                    }
                  }
                } else {
                  // Ticket exits in monitoring and another tab
                  // Ticket exits in Agent list and monitoring => Ticket is Submit => add ticket + cound
                  isAdd = true;
                }
                if (
                  !oldTicket.hasOwnProperty('grpNavTabIndex') ||
                  (oldTicket.hasOwnProperty('grpNavTabIndex') && oldTicket.grpNavTabIndex !== 4)
                ) {
                  // If ticket exist in tab 4 => assign, escalate => no show new button
                }
              }
            }
          }
        } else {
          // Agent Tab is realtime => only show New for another tab
          if (isSearch && selectedGroupConv !== TABS.MONITORING) {
            await dispatch('setDisplayView', { groupId: selectedGroupConv });
          }
          isAdd = true;
        }
      } else {
        if (ticket.deleted) {
          await dispatch('setDisplayView', { groupId: selectedGroupConv });
        }
        // Ticket changed status, not changed tabIndex [TICKET_STATUS.ASSIGN_TO, TICKET_STATUS.ESCALATE_TO, TICKET_STATUS.TRANSFER_TO, TICKET_STATUS.FORWARD_GROUP] => CONTINUOUS add ticket
        if ([TICKET_STATUS.FORWARD_GROUP].includes(ticket.status)) {
          //Ticket [TICKET_STATUS.FORWARD_GROUP] If group of ticket !== group Agent => Convesations-list -> remove ticket
          if (ticket.hasOwnProperty('groupId')) {
            const meGroups = (await dispatch('getGroupsOpts', { ticket })).map(item => item.value);
            if (meGroups.includes(ticket.groupId)) {
              // Forward one group which is support by me
            } else {
              // Forward one group which is not support by me
              await dispatch('setDisplayView', { groupId: selectedGroupConv, view: 'DashBoard' });
            }
            isAdd = true;
          } else {
            //eslint-disable-next-line
            console.log(
              '[C+ Debug] [ERROR] >>> addTicketLocal >> Ticket is FORWARD_GROUP >> not exist GroupId',
              {
                oldTicket,
                ticket
              }
            );
          }
        } else {
          // Ticket [TICKET_STATUS.ASSIGN_TO, TICKET_STATUS.ESCALATE_TO, TICKET_STATUS.TRANSFER_TO]
          if (oldTicket.hasOwnProperty('grpNavTabIndex')) {
            // Ticket is exist only one tab [Bot, Me Waiting, Common Waiting, ]
            // Agent open Monitoring Tab => Ticket updated => only changed status

            if (oldTicket.grpNavTabIndex === 4) {
              isAdd = true;
            } else {
              isAdd = true;
            }
          } else {
            // Agent open Monitoring Tab => Ticket updated => Ticket can  update or remove (by GroupId, Role)
            isAdd = true;
          }
        }
      }
    }

    if (isAdd || isSearch) {
      let isSelectedTicket = false;
      if (
        selectedTicketId === ticket.id &&
        ticket.status !== TICKET_STATUS.NO_SUPPORT &&
        !isSearch
      ) {
        isSelectedTicket = true;
        if (
          selectedGroupConv === chatViewingGroup &&
          selectedGroupConv !== SERVING_STATE.COMMENTS
        ) {
          if (selectedGroupConv !== groupId && selectedGroupConv !== SERVING_STATE.MONITORING) {
            await dispatch(
              'chat/setSelectedConversation',
              {
                conversation: { ...selectedConversation, ticket: ticket },
                groupId
              },
              {
                root: true
              }
            );
          } else {
            await dispatch(
              'chat/updateSelectedConversation',
              { ...selectedConversation, ticket: ticket },
              {
                root: true
              }
            );
          }
        }
      }

      //  check grpNavTabIndex
      // Re-Support => add new ticket to Agent List
      if (tabIndex === TABS.ME || tabIndex === TABS.MONITORING || isSelectedTicket) {
        if (!ticket.hasOwnProperty('grpNavTabIndex')) {
          ticket.grpNavTabIndex = tabIndex;
        }
        if (!ticket.hasOwnProperty('page')) {
          ticket.page = [{ page: 1, grpNavTabIndex: tabIndex }];
        }

        if (oldTicket) {
          const temp = uniqBy(
            [...(oldTicket.page || []), ...[{ page: 1, grpNavTabIndex: tabIndex }]],
            'grpNavTabIndex'
          );
          ticket.page = temp;
        }

        // calcu ticket page
        if (ticket.page && ticket.page.length > 1) {
          const temp = cloneDeep(ticket.page);
          ticket.page = temp.filter(
            item => item.grpNavTabIndex == 4 || item.grpNavTabIndex === tabIndex
          );
          if (ticket.page.length > 1) {
            //eslint-disable-next-line
            console.log(
              'addTicketLocal -> grpNavTabIndex > deletegrpNavTabIndex1',
              JSON.stringify(ticket)
            );
            delete ticket['grpNavTabIndex'];
          }
        }
      } else if (tabIndex === TABS.BOT) {
        if (!ticket.hasOwnProperty('grpNavTabIndex')) {
          ticket.grpNavTabIndex = tabIndex;
        }
        if (!ticket.hasOwnProperty('page')) {
          ticket.page = [{ page: 1, grpNavTabIndex: tabIndex }];
        }
      }

      const selectedTab = convertGroupNavToTabIndex(selectedGroupConv, waitingMode);
      const isAgetHasPermission = await checkAgentHasPermissionOnTicket({ dispatch, ticket });
      commit(ADD_TICKET, {
        selectedTab,
        ticket,
        isAgetHasPermission,
        grpNavTabIndex: tabIndex,
        isSelectedTicket,
        isChangeTab: tabIndex !== tabIndexOld,
        isComingTicket: selectedTab === tabIndex
      });
    }

    // Remove ticket when other process already done
    if (tabIndex === -1 || tabIndex === -2) {
      // Agent role can not see ticket => removeOldTicket
      await dispatch('removeOldTicket', {
        ticketId: ticket.id,
        grpNavTabIndex: groupId,
        forceDelete: tabIndex === -2
      });

      if (selectedTicketId === ticket.id) {
        // ticket removed is selected
        await dispatch('setDisplayView', { groupId: selectedGroupConv });
      }
    }
  } catch (error) {
    //eslint-disable-next-line
    console.log('[C+ Debug] [ERROR] addTicketLocal >> error', error);
  }
};

const setDisplayView = async ({ state, dispatch, commit }, { view }) => {
  const { chatSearchBoxFilter } = state;
  let viewName = view || 'Overview';

  if (viewName === 'Overview') {
    commit('chat/UPDATE_SELECTED_CONVERSATION', null, { root: true });
  }

  if (!isEmpty(chatSearchBoxFilter)) {
    viewName = 'SearchView';
    commit('chat/UPDATE_SELECTED_CONVERSATION', null, { root: true });
  }
  await dispatch('chat/setShowView', viewName, {
    root: true
  });
};

const getGroupsOpts = ({ state, getters }, conversation) => {
  const { user } = state;
  const { projectId } = (conversation && conversation.ticket) || {};
  const { role, id } = getters.me || {};

  if (!projectId) return [];
  let cats = getters.groupMaps[projectId];
  cats = cats.filter(c => {
    switch (role) {
      case AGENT_ROLE.LEADER:
        return true;
      case AGENT_ROLE.MODERATOR:
        return Array.isArray(c.moderators) && c.moderators.includes(id);
      case AGENT_ROLE.REGULAR:
        return Array.isArray(c.agents) && c.agents.includes(id);
      default:
        return false;
    }
  });
  if (cats.length === 0) return [];

  const assignedGroups = user.assignedProjects.reduce((acc, project) => {
    if (project.id === projectId) {
      project.assignedGroups.forEach(group => {
        acc[group.id] = group;
      });
    }
    return acc;
  }, {});
  cats = cats.filter(cat => !assignedGroups[cat.id] || !assignedGroups[cat.id].deleted);

  if (cats.length === 0) return [];
  cats.sort((a, b) => a.name.localeCompare(b.name));
  const catOpts = [];
  cats.forEach(c => {
    catOpts.push({
      value: c.id,
      label: c.name,
      isEnable: c.isEnable
    });
  });
  return catOpts;
};

//eslint-disable-next-line
const getMonitoringCount = async ({ commit, state }, { projectId, channelId, groupId }) => {
  const { id: agentId } = state.user;
  const result = await countMonitoring({ projectId, channelId, agentId, groupId });
  commit(SET_MONITORING_COUNT, { channelId, count: result });
  return result;
};

const getCountAll = async ({ commit }, { projectId, channelId, groupId = null }) => {
  const results = await Promise.all([countMonitoring({ projectId, channelId, groupId })]);
  return (
    results &&
    results.map((count, listIndex) => updateCount({ commit }, { listIndex, channelId, count }))
  );
};

const updateCount = ({ commit }, { listIndex, channelId, count }) => {
  const list = [SET_MONITORING_COUNT];

  commit(list[listIndex], { channelId, count });
};

const getTicketsBySearch = async (context, params) => {
  const { commit, state } = context;
  const { conditions, from, size, channelId, tab, waitingMode, agentId, groupId = null } = params;
  const { projectId } = state.channelsMap[channelId] || {};
  const channelStatus = `${channelId}${TICKET_TYPE_CODE.SEARCH}`;

  commit(SET_LOADING_MORE, { channelStatus, loading: true });
  commit(HAS_UPDATE_TICKET_COME, { channelStatus, flag: false });

  if (from === 0) {
    const { dispatch } = context;
    dispatch('setChannelTabPage', { channelStatus: channelId + '9', page: 1 });
  }
  try {
    const { ticketMaps, conversations, cntAllTicket } = await getSearchTickets(
      projectId,
      channelId,
      from,
      size,
      conditions,
      tab,
      waitingMode,
      agentId,
      groupId
    ).catch(() => commit(SET_LOADING_MORE, { channelStatus: channelId + '9', loading: false }));

    let cntTotalTicket = cntAllTicket;
    if (groupId) {
      const result = await getSearchTickets(
        projectId,
        channelId,
        from,
        size,
        conditions,
        tab,
        waitingMode,
        agentId
      ).catch(() => {
        return {};
      });
      cntTotalTicket = result.cntAllTicket || cntAllTicket;
    }
    const nextFrom = from + size;
    await addGrpNavTabIndexSearch(ticketMaps, tab);
    commit(SET_TICKETS_SEARCH_CLEAR, { channelId, groupId });
    commit(SET_TICKETS_SEARCH, {
      tickets: ticketMaps,
      channelId,
      nextFrom,
      cntAllTicket: cntTotalTicket,
      conditions,
      cntGroupTicket: cntAllTicket
    });
    // fix locale
    conversations.map(conv => setDefaultLanguage(conv, state));

    commit(SET_CONVERSATIONS, conversations);
    commit(SET_LOADING_MORE, { channelStatus: channelId + '9', loading: false });
    return true;
  } catch (error) {
    commit(SET_LOADING_MORE, { channelStatus: channelId + '9', loading: false });
    // eslint-disable-next-line
    console.log('[Session] >>> getTicketsBySearch params, err', params, error);
  }
  return [];
};

const getTicketsBySearchLoadMore = async (context, params) => {
  const { commit, state } = context;
  const { conditions, from, size, channelId, tab, waitingMode, agentId } = params;
  const { projectId } = state.channelsMap[channelId] || {};
  const { tickets } = await getSearchTickets(
    projectId,
    channelId,
    from,
    size,
    conditions,
    tab,
    waitingMode,
    agentId
  );
  if (!tickets) return [];
  // check Ticket is show in Aside left
  const { tickets: stateTickets } = state;

  const missTickets = [];
  let newTicket = [];

  tickets.map(async ticket => {
    const { id, conversationId } = ticket;
    if (!stateTickets[id]) {
      missTickets.push({ ticketId: id, conversationId });
    } else {
      newTicket.push(stateTickets[id]);
    }
  });
  if (missTickets.length > 0) {
    const conversationTickets = await service.post('conversations/getConversationTicketByIds', {
      tickets: missTickets
    });

    newTicket = [...newTicket, ...conversationTickets];
  }

  const nextFrom = from + size;
  if (newTicket) {
    commit(SET_TICKETS_SEARCH_LOAD_MORE, { tickets: newTicket, channelId, nextFrom });
    return newTicket;
  }
};

const setTicketsSearchClear = ({ commit, rootState }, { ticket, channelId }) => {
  const { selectedGroupConv } = rootState.chat || {};
  commit(SET_TICKETS_SEARCH_CLEAR, { ticket, channelId, selectedGroupConv });
};

const setCommonWaitingLoadedCount = ({ commit }, { channelId, count }) => {
  commit(SET_COMMON_WAITING_LOADED_COUNT, { channelId, count });
};

const setMeWaitingLoadedCount = ({ commit }, { channelId, count }) => {
  commit(SET_ME_WAITING_LOADED_COUNT, { channelId, count });
};

const refreshToken = () => {
  return service.post('authorizer/token');
};

const setLastRunTime = ({ commit }, time) => {
  commit(SET_LAST_RUN_TIME, time);
};

const removeTicketBySearch = async (context, ticket) => {
  const { commit } = context;
  commit(REMOVE_TICKET_SEARCH, ticket);
};

const setTagsToTicket = async (_, { ticket, tags }) => {
  if (ticket) {
    const newTicket = { ...ticket };
    newTicket.tags = JSON.stringify(tags);
    return await service.rest('tags/updateTagsToTicket', { ticket: newTicket });
  }
  return {};
};

const updateTagById = async ({ commit }, tag) => {
  let variable = pick(tag, ['id', 'projectId', 'value', 'type', 'agentId', 'createdAt']);
  return await service.rest('tags/updateTagById', variable).then(res => {
    commit(ADD_NEW_TAG, { projectId: tag.projectId, newTag: res });
  });
};

const updateTag = async (_, val) => {
  return await service.rest('tags/getCreateTag', val);
};

const addNewTag = async ({ commit }, { projectId, newTag }) => {
  commit(ADD_NEW_TAG, { projectId, newTag });
};

const deleteTag = async ({ commit }, { projectId, newTag }) => {
  commit(DELETE_TAG, { projectId, newTag });
};

const addNewTicketTag = async ({ commit }, { projectId, ticketTag }) => {
  commit(ADD_NEW_TICKET_TAG, { projectId, ticketTag });
};

const deleteTicketTag = async ({ commit }, { projectId, ticketTag }) => {
  commit(DELETE_TICKET_TAG, { projectId, ticketTag });
};

const setChatSearchBoxFilter = ({ commit }, val) => {
  commit(SET_CHAT_SEARCH_BOX_FILTER, val);
};

const addNewLabel = async ({ commit }, { projectId, newLabel }) => {
  commit(ADD_NEW_LABEL, { projectId, newLabel });
};

const deleteLabel = async ({ commit }, { projectId, newLabel }) => {
  commit(DELETE_LABEL, { projectId, newLabel });
};

const addNewUserLabel = async ({ commit }, { projectId, userLabel }) => {
  commit(ADD_NEW_USER_LABEL, { projectId, userLabel });
};

const deleteUserLabel = async ({ commit }, { projectId, userLabel }) => {
  commit(DELETE_USER_LABEL, { projectId, userLabel });
};

const removeUserLabel = async ({ commit }, { user, label }) => {
  await service
    .rest('labels/removeUserLabel', {
      userId: user.id,
      channelId: user.channelId,
      labelId: label.id
    })
    .then(() => {
      commit(DELETE_USER_LABEL, {
        projectId: label.projectId,
        userLabel: { userIdChannelId: `${user.id}_${user.channelId}`, labelId: label.id }
      });
    });
};

export const updateLabel = async ({ commit }, val) => {
  const service = new Service(process.env.VUE_APP_USER_SERVICE_ENDPOINT);
  return service.rest('labels/updateLabel', val).then(res => {
    const { labels, projectId } = res;
    labels.map(item => {
      commit(ADD_NEW_LABEL, { projectId, newLabel: item });
    });
  });
};

const updateLabelById = async ({ commit }, label) => {
  let variable = pick(label, ['id', 'projectId', 'value', 'type', 'agentId', 'createdAt']);
  return await service.rest('labels/updateLabelById', variable).then(res => {
    commit(ADD_NEW_LABEL, { projectId: label.projectId, newLabel: res });
  });
};

const setFollowToTicket = async (context, { ticket, follow }) => {
  if (ticket) {
    const payload = { ...ticket };
    payload.feature = follow;
    return service
      .rest('conversations/exec', {
        payload
      })
      .then(ticket => {
        return ticket;
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('setFollowToTicket -> ', error);
      });
  }
  return false;
};

const clearList = ({ commit, state, getters, rootState }, { channelId }) => {
  const { waitingMode, channelsMap } = state;
  const { selectedGroupConv } = rootState.chat || {};
  const { id } = rootState.chat.selectedConversation || {};
  if (!channelId || !channelsMap[channelId]) return;
  const { projectId } = channelsMap[channelId];
  let removeTickets = [];

  const reduceFn = (list, conv) => {
    if (conv.id !== id && conv.channelId === channelId) list.push(conv.ticket);
    return list;
  };

  let grpNavTabIndex = 0;
  let status = 0;

  if (SERVING_STATE.WAITING === selectedGroupConv) {
    if (waitingMode === 'TOME') {
      grpNavTabIndex = 2;
      removeTickets = getters.meWaitingList.reduce(reduceFn, []);
    } else {
      grpNavTabIndex = 1;
      removeTickets = getters.commonWaitingList.reduce(reduceFn, []);
    }
  } else if (SERVING_STATE.AGENT === selectedGroupConv) {
    grpNavTabIndex = 3;
    removeTickets = getters.conversationsByAgents.reduce(reduceFn, []);
  } else if (SERVING_STATE.MONITORING === selectedGroupConv) {
    grpNavTabIndex = 4;
    removeTickets = getters.monitoringList.reduce(reduceFn, []);
  } else if (SERVING_STATE.BOT === selectedGroupConv) {
    removeTickets = getters.conversationsByBot.reduce(reduceFn, []);
  } else {
    return;
  }

  status = channelId + '' + grpNavTabIndex;
  commit(SET_LOADMORE_TIME, {
    projectId,
    status,
    updatedAt: null
  });
  commit(REMOVE_TICKETS, { tickets: removeTickets, grpNavTabIndex });
};

const loadTagByProjects = async ({ commit }, projectIds) => {
  let projectTagMaps = { tags: {}, ticketsTags: [] };
  try {
    projectTagMaps = (await service.rest('tags/loadTagByProjects', {
      projectIds
    })) || { tags: {}, ticketsTags: [] };
  } catch (error) {
    //eslint-disable-next-line
    console.log('[C+ Debug] >>> loadTagByProjects >> error  ', error);
  }
  commit(SET_PROJECT_TAG_MAP, projectTagMaps);
  return projectTagMaps;
};

const loadLabelByProjects = async ({ commit }, projectIds) => {
  const newService = new Service(process.env.VUE_APP_USER_SERVICE_ENDPOINT);
  const projectLabels = await Promise.all(
    projectIds.map(async projectId => {
      return Promise.all([
        await newService.rest('labels/getLabelsByProjectId', {
          projectId
        }),
        await newService.rest('labels/getUserLabelsByProjectId', {
          projectId
        })
      ])
        .then(([labels, usersLabels]) => ({ labels, usersLabels }))
        .catch(error => {
          //eslint-disable-next-line
          console.log('TCL: loadLabelByProjects >> error', error);
          return { labels: [], usersLabels: [] };
        });
    })
  );
  const projectLabelMaps = projectLabels.reduce((maps, item, index) => {
    maps[projectIds[index]] = item;
    return maps;
  }, {});
  if (projectLabelMaps) {
    commit(SET_PROJECT_LABEL_MAP, projectLabelMaps);
    return projectLabelMaps;
  }
};

const getPersonal = async ({ commit }, { userId, channelId, claims, projectId }) => {
  const userService = new Service(URL_USER);
  const usersLabels = await userService.rest('personal/getPersonal', {
    userId,
    channelId,
    claims
  });
  commit(ADD_USER_LABEL_TO_PROJECT_LABEL_MAP, { projectId, usersLabels });
};

const removeOldTicket = ({ commit }, { ticketId, grpNavTabIndex, forceDelete }) => {
  commit(REMOVE_OLD_TICKET, { ticketId, grpNavTabIndex, forceDelete });
};

const submitFormForgotPassword = async (_, email) => {
  try {
    /**
     * Update agent needed change password
     */
    const agentChangePassword = await service
      .rest(
        'agents/updateAgentChangePassword',
        {
          email,
          needChangePassword: true,
          checkAgentLocked: true
        },
        { method: 'post' },
        0
      )
      .then(response => {
        const { errors } = response;
        if (errors) {
          const { code } = errors;
          switch (code) {
            case 'locked-account':
              baseNotification.warning({
                title: i18n.t('src.core.App.warning'),
                message: i18n.t('src.modules.session.store.actions.account_locked')
              });
              return false;
            default:
              baseNotification.warning({
                title: i18n.t('src.core.App.warning'),
                message: i18n.t('src.modules.session.components.forgotPassword.email_is_invalid')
              });
              return false;
          }
        }
        return response;
      })
      .catch(errors => {
        //eslint-disable-next-line
        console.log('[C+ Debug]: submitFormForgotPassword -> errors', errors);
        baseNotification.warning({
          title: i18n.t('src.core.App.warning'),
          message: i18n.t('src.modules.session.components.forgotPassword.email_is_invalid')
        });
        return false;
      });

    /**
     * Update agent needed change password
     */
    if (agentChangePassword) {
      const { firstName = '', lastName = '', cToken } = agentChangePassword || {};

      const subject = i18n.t('agent.sendmail.reset.password.subject');
      const htmlContent = i18n.t('agent.sendmail.reset.password.description', {
        first_name: firstName,
        last_name: lastName,
        link_url: window.location.origin,
        newPassword: '$newPassword'
      });

      const mailOptions = buildMailOptions(email, subject, htmlContent);

      const forgotPassword = await firebaseFuncAPI.post(
        '/agents/resetPassword',
        [
          {
            loginId: email,
            ...mailOptions
          }
        ],
        {
          headers: {
            'cplus-authorization': `Bearer ${cToken.accessToken}`
          }
        },
        0
      );
      if (!forgotPassword) {
        // eslint-disable-next-line
        console.log('TCL: submitFormForgotPassword -> forgotPassword', forgotPassword);
        return false;
      }
      return true;
    }
    return false;
  } catch (error) {
    //eslint-disable-next-line
    console.log('[C+ Debug]: submitFormForgotPassword -> error', error);
    baseNotification.warning({
      title: i18n.t('src.core.App.warning'),
      message: i18n.t('src.modules.session.components.forgotPassword.email_is_invalid')
    });
    return false;
  }
};

const getLDAPList = async ({ commit }) => {
  const ldapList = await service
    .rest('auth/getLDAPList', {}, { method: 'post' }, 0)
    .catch(error => {
      //eslint-disable-next-line
      console.log('getLDAPList >> error: ', error);
      return [];
    });
  commit(SET_LDAP_LIST, ldapList);
  return ldapList;
};

const pushReadToWebChat = async ({ getters, state, rootState, commit }, ticketId) => {
  const conversation = getters.conversations.find(c => c.ticketId === ticketId);
  if (!conversation) return;
  const { id: conversationId, userId, channelId } = conversation;
  const channel = state.channelsMap[channelId];
  if (!channel || channel.platform !== 'webchat') return;
  const { wcReadMap } = state;
  const { messages } = rootState.chat;
  const convMessages = messages[conversationId] || [];
  let mid = null;
  for (let i = convMessages.length - 1; i >= 0; --i) {
    if (convMessages[i].platform === 'webchat') {
      mid = convMessages[i].id;
      break;
    }
  }
  if (!mid) return;
  if (wcReadMap[conversationId] && wcReadMap[conversationId] === mid) return;

  commit(UPDATE_WC_READ, { conversationId, mid });

  const data = {
    clientInfo: {
      userId: userId,
      channelId: channelId,
      conversationId,
      sender: channelId
    },
    data: [
      {
        type: 'action',
        payload: {
          action: 'read',
          timestamp: new Date().getTime(),
          mid
        }
      }
    ]
  };
  return wcService.post('messages', data).catch(error => {
    // eslint-disable-next-line
    console.log('TCL: pushReadToWebChat -> error', error);
    return false;
  });
};

const pushReadToLIFFChat = async ({ getters, state, rootState, commit }, ticketId) => {
  const conversation = getters.conversations.find(c => c.ticketId === ticketId);
  if (!conversation) return;
  const { id: conversationId, userId, channelId } = conversation;
  const channel = state.channelsMap[channelId];
  if (!channel || channel.platform !== 'liffchat') return;
  const { lcReadMap } = state;
  const { messages } = rootState.chat;
  const convMessages = messages[conversationId] || [];
  let mid = null;
  for (let i = convMessages.length - 1; i >= 0; --i) {
    if (convMessages[i].platform === 'liffchat') {
      mid = convMessages[i].id;
      break;
    }
  }
  if (!mid) return;
  if (lcReadMap[conversationId] && lcReadMap[conversationId] === mid) return;

  commit(UPDATE_LC_READ, { conversationId, mid });

  const data = {
    clientInfo: {
      userId: userId,
      channelId: channelId,
      conversationId,
      sender: channelId
    },
    data: [
      {
        type: 'action',
        payload: {
          action: 'read',
          timestamp: new Date().getTime()
        }
      }
    ]
  };
  return lcService.post('messages', data).catch(error => {
    // eslint-disable-next-line
    console.log('TCL: pushReadToLIFFChat -> error', error);
    return false;
  });
};

const markAsRead = async ({ getters, state }, ticketId) => {
  if (!ticketId) return;
  const conversation = getters.conversations.find(c => c.ticketId === ticketId);
  const { channelId } = conversation || {};
  const channel = state.channelsMap[channelId];
  if (!channel || channel.platform !== 'line') return;
  // eslint-disable-next-line
  console.log('TCL: send request to markAsRead API', ticketId);
  return service
    .post('messages/markAsRead', { ticketId, platform: channel.platform })
    .catch(error => {
      // eslint-disable-next-line
      console.log('TCL: markAsRead -> error', error);
      return false;
    });
};

const pushPersonalDataToPeople = async ({ commit }, params) => {
  if (!params || !params.channelId || !params.projectId || !params.platform) return;
  const userService = new Service(URL_USER);
  return userService.rest('personal/getPersonal', params).then(response => {
    const { userId, channelId } = params;
    response.id = userId;
    commit(ADD_PERSON, { personId: `${userId}_${channelId}`, person: response || {} });
  });
};

const updatePersonal = async ({ rootState, commit, dispatch }, data) => {
  const { userId, channelId } = data;
  commit(UPDATE_PERSON, { personId: `${userId}_${channelId}`, person: data });

  const { selectedConversation: slc } = rootState.chat;
  if (slc && slc.userId === userId && slc.channelId === channelId) {
    const { pictureUrl, platform } = data || {};
    let user = { ...slc.userInfo, ...data };
    user['pictureUrl'] = platform === 'facebook' && pictureUrl ? '' : pictureUrl;

    const action = 'chat/updateSelectedConversation';
    dispatch(action, { ...slc, userInfo: user }, { root: true });

    // Render image of facebook again
    await new Promise(res => setTimeout(() => res(true), 10));
    user['pictureUrl'] = pictureUrl;
    dispatch(action, { ...slc, userInfo: user }, { root: true });
  }
};

const handleNewNotifyCome = async (context, notifyCome) => {
  const { commit } = context;
  const { projectId = '', action = '', data = '' } = notifyCome || {};
  switch (action) {
    case 'maintenance_mode':
      commit(CHANGE_MAINTENANCE_MODE, { projectId, data });
      break;

    case 'update_person':
      setTimeout(() => handleNotifyUpdatePerson(context, notifyCome), 3000);
      break;

    case 'un_follow_line':
      handleNotifyUnFollowLine(context, notifyCome);
      break;

    case 'request_user_time_out':
      handleRequestUserTimeout(context, notifyCome);
      break;

    case 're_assign_ticket':
      handleNotifyReAssign(context, notifyCome);
      break;

    case 'deploying':
      handleDeploymentMode(context);
      break;

    default:
      return false;
  }
};

const handleNotifyUnFollowLine = async (context, notifyCome) => {
  const { state, dispatch, rootGetters, rootState, commit } = context;
  const { projectId = '', data = '' } = notifyCome || {};
  const { userId, channelId } = JSON.parse(data);
  const {
    tickets = {},
    conversations = [],
    projectMaps = {},
    channelsMap = {}
  } = rootState.session;
  if (!projectMaps[projectId] || !channelsMap[channelId]) return false;
  const { allTicket = [], actionClickSearch = {}, countAll = 0 } = rootState.searchManagement;
  const { customerData = [], actionClickCSH = {} } = rootState.customerSupportHistory;
  const selectedConversation = rootGetters['chat/selectedConversation'] || {};
  const { agentHandleCountsMap = {}, commonWaitingCountsMap = {}, meWaitingCountsMap = {} } = state;

  const newConversations = conversations.filter(
    c => !(c.userId === userId && c.channelId === channelId)
  );
  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter(key => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {});
  const newTickets = Object.filter(
    tickets,
    c => !(c.userId === userId && c.channelId === channelId)
  );
  const newAllTicket = allTicket.filter(c => !(c.userId === userId && c.channelId === channelId));
  const newCustomerData = customerData.filter(
    c => !(c.userId === userId && c.channelId === channelId)
  );
  const allTicketRemoved = allTicket.filter(c => c.userId === userId && c.channelId === channelId);
  const countAllTicketRemoved = Object.keys(allTicketRemoved);
  const newCountAll = countAll - countAllTicketRemoved.length;
  const countAgentHandle = ticketHandler.ticketAgentHandle(tickets, userId, channelId);
  const countCommonWaiting = ticketHandler.ticketCommonWaiting(tickets, userId, channelId);
  const countMeWaiting = ticketHandler.ticketMeWaiting(tickets, userId, channelId);
  const newAgentHandleCounts = agentHandleCountsMap[channelId] - countAgentHandle.length;
  const newCommonWaitingCounts = commonWaitingCountsMap[channelId] - countCommonWaiting.length;
  const newMeWaitingCounts = meWaitingCountsMap[channelId] - countMeWaiting.length;
  if (
    selectedConversation &&
    selectedConversation.userId === userId &&
    selectedConversation.channelId === channelId
  ) {
    EventBus.$emit('hideBaseConfirmCs');
    return showDialogWarning(rootState, 'left-panel');
  }
  if (actionClickSearch.userId === userId && actionClickSearch.channelId === channelId) {
    return showDialogWarning(rootState);
  }
  if (actionClickCSH.userId === userId && actionClickCSH.channelId === channelId) {
    EventBus.$emit('hideBaseConfirmCsh');
    return showDialogWarning(rootState);
  }

  dispatch('customerSupportHistory/updateCustomerData', newCustomerData, { root: true });
  dispatch(
    'searchManagement/updateSearchData',
    { allTicket: newAllTicket, countAll: newCountAll },
    { root: true }
  );
  commit(UPDATE_COMMON_WAITING_COUNT, { channelId, count: newCommonWaitingCounts });
  commit(UPDATE_ME_WAITING_COUNT, { channelId, count: newMeWaitingCounts });
  commit(UPDATE_AGENT_HANDLE_COUNT, { channelId, count: newAgentHandleCounts });
  commit(UPDATE_CONVERSATION_UN_FOLLOW, newConversations);
  commit(UPDATE_TICKET_UN_FOLLOW, newTickets);
  deleteNotificationWhenUnFollow(context, { userId, channelId });
};

const handleNotifyUpdatePerson = async (context, notifyCome) => {
  const { commit, dispatch, state, rootState } = context;
  const { projectMaps, conversations } = state;
  const { selectedConversation } = rootState.chat;
  const { projectId = '', data = '' } = notifyCome || {};
  if (!data) return null;
  if (!projectMaps[projectId]) return null;
  const { userInfo = {} } = JSON.parse(data);
  const { userId, channelId } = userInfo;

  let oldConversation = null;
  conversations.map(c => {
    if (c.userId === userId && c.channelId === channelId) {
      oldConversation = { ...c };
    }
    return c;
  });

  if (
    selectedConversation &&
    selectedConversation.userId === userId &&
    selectedConversation.channelId === channelId
  ) {
    const newSelectedConversation = {
      ...selectedConversation,
      name: userInfo.name,
      userInfo: { ...selectedConversation.userInfo, ...userInfo }
    };
    await dispatch('chat/updateSelectedConversation', newSelectedConversation, {
      root: true
    });
  }

  if (oldConversation) {
    const newConversation = {
      ...oldConversation,
      name: userInfo.name,
      userInfo: { ...oldConversation.userInfo, ...userInfo }
    };
    commit(UPDATE_CONVERSATION_LOCAL, newConversation);
  }
  commit(UPDATE_PERSON, { personId: `${userId}_${channelId}`, person: userInfo });
};

const handleRequestUserTimeout = async (context, notifyCome) => {
  const { state } = context;
  const { projectMaps, user } = state;
  const { projectId = '', data = '' } = notifyCome || {};
  if (!data) return null;
  const { ticketId, agentId } = JSON.parse(data);
  if (!projectMaps[projectId]) return null;
  if (agentId === user.id) {
    baseNotification.warning({
      title: i18n.t('src.core.App.warning'),
      message: i18n.t('src.core.App.message_request_user_bot_timeout', {
        conversation_id: ticketId
      }),
      duration: 5000
    });
  }
};

const handleNotifyReAssign = async (context, notifyCome) => {
  const { state } = context;
  const { projectMaps, user } = state;
  const { projectId = '', data = '' } = notifyCome || {};
  if (!data) return null;
  const { ticketId, agentId } = JSON.parse(data);
  if (!projectMaps[projectId]) return null;
  if (agentId === user.id) {
    baseNotification.warning({
      title: i18n.t('src.core.App.warning'),
      message: i18n.t('src.core.App.message_re_assign_conversation', {
        conversation_id: ticketId
      }),
      duration: 5000
    });
  }
};

const handleDeploymentMode = async () => {
  return baseConfirm({
    title: i18n.t('src.core.App.warning'),
    message: i18n.t('src.core.App.message_deployment_mode'),
    closeOnPressEscape: false,
    closeOnClickModal: false,
    showClose: false,
    showCancelButton: false,
    confirmButtonText: i18n.t('src.core.App.button_reload'),
    iconClass: 'el-icon-warning'
  })
    .then(() => {
      history.pushState(null, null, '/');
      location.reload();
    })
    .catch(() => {});
};

const clearProjectsChannels = async ({ commit }) => {
  commit(SET_PROJECT_MAPS, {});
  commit(SET_CHANNEL_LIST, []);
};

const allowReSupport = async (context, { conversationId, id }) => {
  const { state: { user = {} } = {} } = context;

  const res = {};
  res.error = false;

  let ticket = null;
  try {
    const path = 'conversations/getConversationTicketById';
    const conv = await service.post(path, { conversationId, ticketId: id });
    ticket = conv.latestTicket;
  } catch (error) {
    // eslint-disable-next-line
    console.log('getConversationTicketById -> error', error);
    res.error = true;
    res.code = 'TICKET_NOT_FOUND';
    return res;
  }

  if ([TICKET_STATE.FINISH, TICKET_STATE.COMPLETE].includes(ticket.state)) return res;

  const { id: agId = '' } = user;
  const { id: ticketId = '', assignee = '', agentId = '' } = ticket;
  res.error = true;
  res.ticket = ticket;
  if (ticketId === assignee && ticketId === agentId) {
    res.code = 'NEW_TICKET_CREATED';
  } else if (assignee === agId) {
    res.code = 'RE_SUPPORT_BY_ME';
  } else {
    res.code = 'SUPPORT_BY_ANOTHER_AGENT';
  }
  return res;
};

const showDialogWarning = (rootState, type) => {
  const { showView = '' } = rootState.chat;
  return baseConfirm({
    title: i18n.t('src.core.App.warning'),
    message: i18n.t('src.core.App.this_user_does_not_exist_anymore_please_reload_the_page'),
    closeOnPressEscape: false,
    closeOnClickModal: false,
    showClose: false,
    showCancelButton: false,
    confirmButtonText: i18n.t('src.core.App.button_reload'),
    iconClass: 'el-icon-warning'
  })
    .then(() => {
      if (type === 'left-panel') {
        if (showView === 'Chat') history.pushState(null, null, '/');
      }
      location.reload();
    })
    .catch(() => {});
};

/**
 * Set enable or disable button NEW
 */
const setChannelHasUpdateTicketCome = ({ commit }, { channelStatus, flag = true }) => {
  if (!flag) {
    commit(CLEAR_TICKET_COME_NEW_MAP, { channelStatus });
    const channelStatusSearch = channelStatus.replace(/.$/, TICKET_TYPE_CODE.SEARCH);
    commit(HAS_UPDATE_TICKET_COME, { channelStatus: channelStatusSearch, flag });
  }
  commit(HAS_UPDATE_TICKET_COME, { channelStatus, flag });
};

const setGoToTicketCome = ({ commit }, { channelStatus, flag = true }) => {
  commit(GO_TO_TICKET_COME, { channelStatus, flag });
};

const setGoToTicket = ({ commit }, { channelStatus, flag = true }) => {
  commit(GO_TO_TICKET, { channelStatus, flag });
};

const setSelectedCategory = ({ commit }, { channelStatus, selectedCategoryId = 'all' }) => {
  commit(SELECTED_CATEGORY, { channelStatus, selectedCategoryId });
};

const setSelectedChannel = ({ commit }, channelId) => {
  commit(SELECTED_CHANNEL, channelId);
};

const setChannelTabReload = ({ commit }, { channelStatus, flag = false }) => {
  commit(CHANNEL_TAB_RELOAD, { channelStatus, flag });
};

const setChannelTabPage = ({ commit }, { channelStatus, page = 1 }) => {
  commit(CHANNEL_TAB_PAGE, { channelStatus, page });
};

//================Notifications for agent================
const handleNotificationForAgent = async (context, info) => {
  const { action, data } = info;
  switch (action) {
    case 'count-emit-notifications':
      {
        const parseData = JSON.parse(data);
        _countAndEmitNotifications(context, parseData);
      }

      break;

    default:
      break;
  }
};

const _countAndEmitNotifications = async (context, data) => {
  const { commit, state, getters } = context;
  const { notificationSettings } = getters;
  const { numberNotifications: oldNumberNotifications = 0 } = state;
  const { newNotificationsCame, displayNotifyScreen, lastNotifyCame } = state;
  const { event, current, unreadMessagesTicketIds } = data;
  const { numberNotifications, numberUnreadMessages } = data;

  if (displayNotifyScreen && !numberNotifications) {
    commit(DISPLAY_NOTIFY_SCREEN, false);
    commit(NEW_NOTIFICATIONS_CAME, []);
  }

  const SEND_FILE = NOTIFICATION_SETTINGS_KEY.SEND_FILE;
  const WAITING_LIST = NOTIFICATION_SETTINGS_KEY.WAITING_LIST;
  const WARNING_WORD = NOTIFICATION_SETTINGS_KEY.WARNING_WORD;
  const NEW_MESSAGE = NOTIFICATION_SETTINGS_KEY.NEW_MESSAGE;
  const USER_MENTION = NOTIFICATION_SETTINGS_KEY.USER_MENTION;
  if (event === 'createLink') {
    const { type, ticketId, agentId, message } = (current && current.link) || {};

    if (
      type === NEW_MESSAGE &&
      (message.includes('"platform":"bot"') || message.includes('"platform":"agent"'))
    ) {
      return deleteNotification({}, { agentId, ticketId });
    }

    if (message.includes('"sender":"keyword"') || message.includes('"sender":"session"')) {
      if (type !== NEW_MESSAGE) deleteNotification({}, { agentId, ticketId });

      if (oldNumberNotifications > numberNotifications) {
        commit(NUMBER_NOTIFICATIONS, numberNotifications);
      }

      commit(NUMBER_UNREAD_MESSAGES, numberUnreadMessages);
      commit(UNREAD_MESSAGES_TICKETIDS, unreadMessagesTicketIds);
      return true;
    }

    switch (type) {
      case SEND_FILE:
        {
          const settings = notificationSettings.SEND_FILE;
          if (settings.NOTIFY_ENABLE) notifyWithSound(settings);
        }
        break;

      case WAITING_LIST:
        {
          const settings = notificationSettings.WAITING_LIST;
          if (settings.NOTIFY_ENABLE) {
            notifyWithSound(settings);
          }
        }
        break;

      case WARNING_WORD:
        {
          const settings = notificationSettings.WARNING_WORD;
          if (settings.NOTIFY_ENABLE) notifyWithSound(settings);
        }
        break;

      case NEW_MESSAGE:
        {
          const settings = notificationSettings.NEW_MESSAGE;
          if (settings.NOTIFY_ENABLE) notifyWithSound(settings);
        }
        break;
      case USER_MENTION:
        {
          const settings = notificationSettings.USER_MENTION;
          if (settings.NOTIFY_ENABLE) notifyWithSound(settings);
        }
        break;
    }
    commit(HAS_NEW_NOTIFICATION_CAME, true);
    commit(NEW_NOTIFICATIONS_CAME, uniq([...newNotificationsCame, ticketId]));
  } else if (event === 'deleteLink') {
    const { ticketId } = (current && current.link) || {};

    if (lastNotifyCame && lastNotifyCame.ticketId === ticketId) {
      commit(LAST_NOTIFY_CAME, {});
    }

    commit(DELETE_NOTIFICATION, ticketId);
    commit(
      NEW_NOTIFICATIONS_CAME,
      newNotificationsCame.filter(i => i !== ticketId)
    );
    commit(NUMBER_NOTIFICATIONS, numberNotifications);
    commit(NUMBER_UNREAD_MESSAGES, numberUnreadMessages);
    commit(UNREAD_MESSAGES_TICKETIDS, unreadMessagesTicketIds);
    return keepNotifications(context);
  } else if (event === 'delete-emit-links-for-agent') {
    commit(DISPLAY_NOTIFY_SCREEN, false);
    commit(NEW_NOTIFICATIONS_CAME, []);
  }

  commit(LAST_NOTIFY_CAME, current.link || {});
  commit(NUMBER_NOTIFICATIONS, numberNotifications);
  commit(NUMBER_UNREAD_MESSAGES, numberUnreadMessages);
  commit(UNREAD_MESSAGES_TICKETIDS, unreadMessagesTicketIds);
};

const notifyWithSound = settings => {
  if (!settings.SOUND_ENABLE) return null;
  const audio = new Audio(require(`../../../assets/audio/${settings.SOUND_FILE}.mp3`));
  audio.play();
};

const countNotifications = async ({ commit }, agentId) => {
  const result = await service.rest('links/count', { agentId }).catch(() => false);
  if (!result) return false;

  const data = result && JSON.parse(result.data);
  const { unreadMessagesTicketIds = [] } = data;
  const { numberNotifications, numberUnreadMessages } = data;
  commit(NUMBER_NOTIFICATIONS, numberNotifications);
  commit(NUMBER_UNREAD_MESSAGES, numberUnreadMessages);
  commit(UNREAD_MESSAGES_TICKETIDS, unreadMessagesTicketIds);
};

const loadNotifications = async ({ commit, state }, params) => {
  const { hasNewNotificationCame } = state;
  let { notificationsMap, newNotificationsCame } = state;
  let { agentId, action, time, refresh = true } = params || {};

  let newParams = { agentId };
  action && time ? (newParams = { ...newParams, action, time }) : null;
  let notifications = await service.rest('links/getLinks', newParams).catch(() => []);
  if (hasNewNotificationCame) {
    const ids = notifications.map(i => i.ticket.id);
    ids.map(i => delete notificationsMap[i] && i);
    newNotificationsCame = newNotificationsCame.filter(i => !ids.includes(i));
    if (!newNotificationsCame.length) {
      commit(NEW_NOTIFICATIONS_CAME, []);
    }
  }

  let keepItems = false;
  const temporaryNotifies = !isEmpty(notificationsMap) ? Object.values(notificationsMap) : [];

  if (action && temporaryNotifies.length + notifications.length > 50) {
    keepItems = true;
    notifications = [...temporaryNotifies, ...notifications];
    notifications = notifications.sort((a, b) => {
      return -a.link.lastMessageAtTicketId.localeCompare(b.link.lastMessageAtTicketId);
    });
  }

  switch (action) {
    case 'scroll-down':
      {
        if (keepItems) {
          const length = notifications.length;
          notifications = notifications.slice(length - 51, length - 1);
          const { lastMessageAtTicketId: firstTimeInList = '' } =
            notifications[0] && notifications[0].link;
          commit(FIRST_TIME_IN_NOTIFICATIONS, firstTimeInList);
        }

        if (notifications.length) {
          const { lastMessageAtTicketId: lastTimeInList = '' } =
            notifications[notifications.length - 1] && notifications[notifications.length - 1].link;
          commit(LAST_TIME_IN_NOTIFICATIONS, lastTimeInList);
        }
        refresh = false;
      }
      break;

    case 'scroll-up':
      {
        if (keepItems) {
          notifications = notifications.slice(0, 50);
          const { lastMessageAtTicketId: lastTimeInList = '' } =
            notifications[notifications.length - 1] && notifications[notifications.length - 1].link;
          commit(LAST_TIME_IN_NOTIFICATIONS, lastTimeInList);
        }

        if (notifications.length) {
          const { lastMessageAtTicketId } = notifications[0] && notifications[0].link;
          commit(FIRST_TIME_IN_NOTIFICATIONS, lastMessageAtTicketId);
        }
        refresh = false;
      }
      break;

    default:
      {
        if (notifications.length) {
          const { lastMessageAtTicketId: firstTimeInList = '' } =
            notifications[0] && notifications[0].link;
          const { lastMessageAtTicketId: lastTimeInList = '' } =
            notifications[notifications.length - 1] && notifications[notifications.length - 1].link;
          commit(FIRST_TIME_IN_NOTIFICATIONS, firstTimeInList);
          commit(LAST_TIME_IN_NOTIFICATIONS, lastTimeInList);
        }
      }
      break;
  }

  commit(NOTIFICATIONS_MAP, { notifications, keepItems, refresh });
  return notifications;
};

const deleteNotificationWhenUnFollow = async ({ state }, { userId, channelId }) => {
  let { user, notificationsMap } = state;
  let ticketId = '';
  if (isEmpty(notificationsMap)) return null;
  Object.values(notificationsMap).map(n => {
    if (n.ticket.userId === userId && n.ticket.channelId === channelId) ticketId = n.ticket.id;
    return n;
  });
  if (!ticketId) return null;
  return deleteNotification({}, { ticketId, agentId: user.id });
};

const deleteNotification = async (_, params) => {
  if (!params.ticketId) return null;
  params = { ...params, emit: true };
  return service.rest('links/deleteLink', params).catch(() => []);
};

//10 20 30 40 50
const keepNotifications = async ({ commit, state }) => {
  const { newNotificationsCame, numberNotifications } = state;
  const { user, displayNotifyScreen, notificationsMap } = state;
  const { firstTimeInNotifications, lastTimeInNotifications } = state;
  if (!displayNotifyScreen) return null;
  if (isEmpty(notificationsMap)) return null;

  const temporaryNotifies = Object.values(notificationsMap);
  if (numberNotifications <= temporaryNotifies.length) return null;

  const remain = temporaryNotifies.length % 10;
  if (!remain) return null;
  const params = {
    agentId: user.id,
    firstTime: firstTimeInNotifications,
    lastTime: lastTimeInNotifications,
    limit: 10 - remain,
    except: newNotificationsCame
  };

  let notifications = await service.rest('links/keepItems', params).catch(() => []);
  notifications = [...temporaryNotifies, ...notifications];
  if (notifications.length > 1)
    notifications = notifications.sort((a, b) => {
      return -a.link.lastMessageAtTicketId.localeCompare(b.link.lastMessageAtTicketId);
    });

  if (notifications.length) {
    const { lastMessageAtTicketId: firstTimeInList = '' } =
      notifications[0] && notifications[0].link;
    const { lastMessageAtTicketId: lastTimeInList = '' } =
      notifications[notifications.length - 1] && notifications[notifications.length - 1].link;
    commit(FIRST_TIME_IN_NOTIFICATIONS, firstTimeInList);
    commit(LAST_TIME_IN_NOTIFICATIONS, lastTimeInList);
    commit(NOTIFICATIONS_MAP, { notifications, keepItems: true });
  }
};

const updateStatusRegistered = async ({ commit }, data) => {
  commit(UPDATE_STATUS_REGISTERED, data);
};

const setHasNewNotificationCame = ({ commit }, boolean) =>
  commit(HAS_NEW_NOTIFICATION_CAME, boolean);

const setNewNotificationsCame = ({ commit }, ticketIds) =>
  commit(NEW_NOTIFICATIONS_CAME, ticketIds);

const setDisplayNotifyScreen = ({ commit }, boolean) => commit(DISPLAY_NOTIFY_SCREEN, boolean);

const setFirstTimeInNotifications = ({ commit }, time) => commit(FIRST_TIME_IN_NOTIFICATIONS, time);

const setLastTimeInNotifications = ({ commit }, time) => commit(LAST_TIME_IN_NOTIFICATIONS, time);
//================Notifications for agent================

// New Ticket Come
const setTicketComeNewMap = ({ commit }, { tabIndex, channelId, ticketId, state, status }) =>
  commit(SET_TICKET_COME_NEW_MAP, { tabIndex, channelId, ticketId, state, status });

const syncUserLabelColor = async ({ commit }, data) => commit(SYNC_USER_LABEL_COLOR, data);
const syncConversationLabelColor = async ({ commit }, data) =>
  commit(SYNC_CONVERSATION_LABEL_COLOR, data);

export {
  renewCPlusToken,
  updateLastMsgForTicket,
  updateLastMessagesForTicket,
  updateAgentStatus,
  clearProjectsChannels,
  getSessionUser,
  setUser,
  updateUser,
  login,
  loginGoogle,
  loginFacebook,
  loginOAuth,
  logout,
  getChannels,
  loadMoreConversation,
  addNewConversation,
  addConversationLocal,
  updateConversationLatestMessage,
  updateConversationLocal,
  addAgentToPeople,
  addUserToPeople,
  addTranslations,
  setWaitingMode,
  getProjectMaps,
  addTicketLocal,
  getGroupsOpts,
  getMonitoringCount,
  getCountAll,
  getTicketCount,
  updateCount,
  getTicketsBySearch,
  getTicketsBySearchLoadMore,
  setTicketsSearchClear,
  refreshToken,
  setLastRunTime,
  removeTicketBySearch,
  setTagsToTicket,
  updateTagById,
  updateTag,
  addNewTag,
  deleteTag,
  addNewTicketTag,
  deleteTicketTag,
  setFollowToTicket,
  clearList,
  loadTagByProjects,
  loadLabelByProjects,
  removeOldTicket,
  submitFormForgotPassword,
  getLDAPList,
  addNewLabel,
  deleteLabel,
  addNewUserLabel,
  deleteUserLabel,
  removeUserLabel,
  updateLabelById,
  pushReadToWebChat,
  pushReadToLIFFChat,
  markAsRead,
  pushPersonalDataToPeople,
  reloadBotList,
  reloadChannel,
  handleNewNotifyCome,
  allowReSupport,
  setChatSearchBoxFilter,
  setNewNotificationsCame,
  setHasNewNotificationCame,
  handleNotificationForAgent,
  countNotifications,
  loadNotifications,
  deleteNotification,
  setDisplayNotifyScreen,
  setFirstTimeInNotifications,
  setLastTimeInNotifications,
  updatePersonal,
  getPersonal,
  reloadMonitoringList,
  setChannelHasUpdateTicketCome,
  setChannelTabReload,
  setChannelTabPage,
  setCommonWaitingLoadedCount,
  setMeWaitingLoadedCount,
  setGoToTicketCome,
  setGoToTicket,
  setSelectedCategory,
  setSelectedChannel,
  updateTicketCountByAgentId,
  updateTicketCountByProjectId,
  setDisplayView,
  handleNewButton,
  syncUserLabelColor,
  syncConversationLabelColor,
  updateStatusRegistered,
  reloadStateInit
};
