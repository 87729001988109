import { Notification } from 'element-ui';

let instance;
const BaseNotification = function(options) {
  switch (options.type) {
    case 'success':
      options.customClass = 'nat-base-notification-success';
      break;
    case 'warning':
      options.customClass = 'nat-base-notification-warning';
      break;
    case 'info':
      options.customClass = 'nat-base-notification-info';
      break;
    case 'error':
      options.customClass = 'nat-base-notification-error';
      break;
    default:
      break;
  }
  if (!options.multi) {
    Notification.closeAll();
  }
  instance = new Notification(options);
  return instance;
};

['success', 'warning', 'info', 'error'].forEach(type => {
  BaseNotification[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: `<strong style="font-style: normal;">${options}<strong>`
      };
    } else if (typeof options === 'object') {
      options.message = `<strong style="font-style: normal;">${options.message}<strong>`
    }
    options.type = type;
    options.dangerouslyUseHTMLString = true;
    return BaseNotification(options);
  };
});

BaseNotification.close = function(id, userOnClose) {
  return Notification.close(id, userOnClose);
};
BaseNotification.closeAll = function() {
  return Notification.closeAll();
};
export default BaseNotification;