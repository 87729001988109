export const SET_USER = 'SET_SESSION_USER';
export const UPDATE_USER = 'UPDATE_SESSION_USER';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_CHANNEL_LIST = 'SET_CHANNEL_LIST';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const ADD_NEW_CONVERSATION = 'ADD_NEW_CONVERSATION';
export const UPDATE_CONVERSATION_LATEST_MSG = 'UPDATE_CONVERSATION_LATEST_MSG';
export const ADD_PERSON = 'ADD_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_CONVERSATION_LOCAL = 'UPDATE_CONVERSATION_LOCAL';
export const LOAD_MORE_CONVERSATION = 'LOAD_MORE_CONVERSATION';
export const ADD_TRANSLATIONS = 'ADD_TRANSLATIONS';
export const SET_WAITING_MODE = 'SET_WAITING_MODE';
export const SET_PROJECT_MAPS = 'SET_PROJECT_MAPS';
export const ADD_TICKET = 'ADD_TICKET';
export const SET_TICKETS = 'SET_TICKETS';
export const REMOVE_TICKETS = 'REMOVE_TICKETS';
export const SET_AGENT_SUPPORTING_COUNT = 'SET_AGENT_SUPPORTING_COUNT';
export const SET_LOADMORE_TIME = 'SET_LOADMORE_TIME';
export const SET_LOADING_MORE = 'SET_LOADING_MORE';
export const SET_WAITING_COUNT = 'SET_WAITING_COUNT';
export const SET_COMMON_WAITING_COUNT = 'SET_COMMON_WAITING_COUNT';
export const SET_ME_WAITING_COUNT = 'SET_ME_WAITING_COUNT';
export const SET_BOT_HANDLE_COUNT = 'SET_BOT_HANDLE_COUNT';
export const SET_AGENT_HANDLE_COUNT = 'SET_AGENT_HANDLE_COUNT';
export const SET_TICKETS_SEARCH = 'SET_TICKETS_SEARCH';
export const SET_TICKETS_SEARCH_CLEAR = 'SET_TICKETS_SEARCH_CLEAR';
export const REMOVE_TICKET_SEARCH = 'REMOVE_TICKET_SEARCH';
export const SET_LAST_RUN_TIME = 'SET_LAST_RUN_TIME';
export const SET_LAST_REFRESH_TOKEN = 'SET_LAST_REFRESH_TOKEN';
export const SET_PROJECT_TAG_MAP = 'SET_PROJECT_TAG_MAP';
export const ADD_NEW_TAG = 'ADD_NEW_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const ADD_NEW_TICKET_TAG = 'ADD_NEW_TICKET_TAG';
export const ADD_TAG_TO_TICKET = 'ADD_TAG_TO_TICKET';
export const DELETE_TICKET_TAG = 'DELETE_TICKET_TAG';
export const SET_CHAT_SEARCH_BOX_FILTER = 'SET_CHAT_SEARCH_BOX_FILTER';
export const SET_PROJECT_LABEL_MAP = 'SET_PROJECT_LABEL_MAP';
export const SET_LDAP_LIST = 'SET_LDAP_LIST';
export const ADD_NEW_LABEL = 'ADD_NEW_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const ADD_NEW_USER_LABEL = 'ADD_NEW_USER_LABEL';
export const ADD_LABEL_TO_USER = 'ADD_LABEL_TO_USER';
export const DELETE_USER_LABEL = 'DELETE_USER_LABEL';
export const UPDATE_WC_READ = 'UPDATE_WC_READ';
export const UPDATE_LC_READ = 'UPDATE_LC_READ';
export const SET_READY = 'SET_READY';
export const SET_REFRESHING = 'SET_REFRESHING';
export const SET_SYNC_TICKET = 'SET_SYNC_TICKET';
export const CHANGE_MAINTENANCE_MODE = 'CHANGE_MAINTENANCE_MODE';
export const SET_TICKETS_SEARCH_LOAD_MORE = 'SET_TICKETS_SEARCH_LOAD_MORE';
export const ADD_USER_LABEL_TO_PROJECT_LABEL_MAP = 'ADD_USER_LABEL_TO_PROJECT_LABEL_MAP';
export const UPDATE_CONVERSATION_UN_FOLLOW = 'UPDATE_CONVERSATION_UN_FOLLOW';
export const UPDATE_TICKET_UN_FOLLOW = 'UPDATE_TICKET_UN_FOLLOW';
export const UPDATE_COMMON_WAITING_COUNT = 'UPDATE_COMMON_WAITING_COUNT';
export const UPDATE_ME_WAITING_COUNT = 'UPDATE_ME_WAITING_COUNT';
export const UPDATE_AGENT_HANDLE_COUNT = 'UPDATE_AGENT_HANDLE_COUNT';
export const NUMBER_NOTIFICATIONS = 'NUMBER_NOTIFICATIONS';
export const NUMBER_UNREAD_MESSAGES = 'NUMBER_UNREAD_MESSAGES';
export const UNREAD_MESSAGES_TICKETIDS = 'UNREAD_MESSAGES_TICKETIDS';
export const NOTIFICATIONS_MAP = 'NOTIFICATIONS_MAP';
export const LAST_NOTIFY_CAME = 'LAST_NOTIFY_CAME';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const FIRST_TIME_IN_NOTIFICATIONS = 'FIRST_TIME_IN_NOTIFICATIONS';
export const LAST_TIME_IN_NOTIFICATIONS = 'LAST_TIME_IN_NOTIFICATIONS';
export const DISPLAY_NOTIFY_SCREEN = 'DISPLAY_NOTIFY_SCREEN';
export const UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET = 'UPDATE_LAST_MESSAGE_CONTENT_FOR_TICKET';
export const HAS_NEW_NOTIFICATION_CAME = 'HAS_NEW_NOTIFICATION_CAME';
export const NEW_NOTIFICATIONS_CAME = 'NEW_NOTIFICATIONS_CAME';
export const SET_TICKET_ES_COUNT = 'SET_TICKET_ES_COUNT';
export const HAS_UPDATE_TICKET_COME = 'HAS_UPDATE_TICKET_COME';
export const CHANNEL_TAB_RELOAD = 'CHANNEL_TAB_RELOAD';
export const CHANNEL_TAB_PAGE = 'CHANNEL_TAB_PAGE';
export const SET_COMMON_WAITING_LOADED_COUNT = 'SET_COMMON_WAITING_LOADED_COUNT';
export const SET_ME_WAITING_LOADED_COUNT = 'SET_ME_WAITING_LOADED_COUNT';
export const REMOVE_OLD_TICKET = 'REMOVE_OLD_TICKET';
export const SET_CONVERSATIONS_SEARCH = 'SET_CONVERSATIONS_SEARCH';
export const GO_TO_TICKET_COME = 'GO_TO_TICKET_COME';
export const GO_TO_TICKET = 'GO_TO_TICKET';
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
export const SELECTED_CHANNEL = 'SELECTED_CHANNEL';
export const SET_MONITORING_COUNT = 'SET_MONITORING_COUNT';

//---- Conversations Labels ----
export const SET_TICKET_COME_NEW_MAP = 'SET_TICKET_COME_NEW_MAP';
export const REMOVE_TICKET_COME_NEW_MAP = 'REMOVE_TICKET_COME_NEW_MAP';
export const CLEAR_TICKET_COME_NEW_MAP = 'CLEAR_TICKET_COME_NEW_MAP';
export const SYNC_USER_LABEL_COLOR = 'SYNC_USER_LABEL_COLOR';
export const SYNC_CONVERSATION_LABEL_COLOR = 'SYNC_CONVERSATION_LABEL_COLOR';

export const UPDATE_STATUS_REGISTERED = 'UPDATE_STATUS_REGISTERED';