export const SET_CHAT_LIST = 'SET_CHAT_LIST';
export const SET_HISTORIES_MESSAGES = 'SET_HISTORIES_MESSAGES';
export const SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION';
export const UPDATE_SELECTED_CONVERSATION = 'UPDATE_SELECTED_CONVERSATION';
export const ASSIGN_MESSAGES_TO_CONVERSATION = 'ASSIGN_MESSAGES_TO_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_NEW_MESSAGE_TO_CONVERSATION = 'ADD_NEW_MESSAGE_TO_CONVERSATION';
export const CLEAR_NEW_MESSAGE = 'CLEAR_NEW_MESSAGE';
export const SET_CHAT_READY = 'SET_CHAT_READY';
export const SET_SELECT_GROUP_CONV = 'SET_SELECT_GROUP_CONV';
export const ASSIGN_PRODUCTFEEDS_TO_CHANNEL = 'ASSIGN_PRODUCTFEEDS_TO_CHANNEL';
export const SET_CONVERSATIONFEEDS = 'SET_CONVERSATIONFEEDS';
export const SET_SEARCH_FAQ_WITH_LANGUAGE = 'SET_SEARCH_FAQ_WITH_LANGUAGE';
export const SET_LIST_FAQ_LANGUAGE = 'SET_LIST_FAQ_LANGUAGE';
export const SET_CONVSEARCHTEXT = 'SET_CONVSEARCHTEXT';
export const SET_PRODSEARCHTEXT = 'SET_PRODSEARCHTEXT';
export const SET_PRODSEARCHTYPE = 'SET_PRODSEARCHTYPE';
export const SET_PRODSEARCHSORT = 'SET_PRODSEARCHSORT';
export const LOAD_MORE_MESSAGE = 'LOAD_MORE_MESSAGE';
export const UPDATE_MESSAGE_LOCAL = 'UPDATE_MESSAGE_LOCAL';
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS';
export const SET_LOCALES_FILTER = 'SET_LOCALES_FILTER';
export const SET_NOTIFY_MESSAGE = 'SET_NOTIFY_MESSAGE';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_INIT_STATE = 'SET_INIT_STATE';
export const REMOVE_INIT_STATE = 'REMOVE_INIT_STATE';
export const SET_SHOW_SUPPORT_PANEL = 'SET_SHOW_SUPPORT_PANEL';
export const SET_SHOW_SURVEY_PANEL = 'SET_SHOW_SURVEY_PANEL';
export const SET_SHOW_CONVERSATIONAL_PANEL = 'SET_SHOW_CONVERSATIONAL_PANEL';
export const SET_SHOW_PRODUCT_PANEL = 'SET_SHOW_PRODUCT_PANEL';
export const SET_MONITORING = 'SET_MONITORING';
export const SET_SHOW_VIEW = 'SET_SHOW_VIEW';
export const SET_CHAT_VIEWING_GROUP = 'SET_CHAT_VIEWING_GROUP';
export const SET_SEARCH_BOX_FILTER = 'SET_SEARCH_BOX_FILTER';
export const ASSIGN_MESSAGES_OF_TICKET_ID = 'ASSIGN_MESSAGES_OF_TICKET_ID';
export const SET_SEARCH_BOX_HEIGHT = 'SET_SEARCH_BOX_HEIGHT';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_MESSAGES_NOTIFICATION = 'SET_MESSAGES_NOTIFICATION';
export const SET_SORT_METHOD = 'SET_SORT_METHOD';
export const SUPPORT_WITHOUT_USER_AGREEMENT = 'SUPPORT_WITHOUT_USER_AGREEMENT';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SET_AGENT_CHAT_MESSAGE = 'SET_AGENT_CHAT_MESSAGE';
export const UPDATE_TYPING = 'UPDATE_TYPING';
export const ADD_TICKET_ACTION_LOG = 'ADD_TICKET_ACTION_LOG';
export const SET_SEARCH_BOX = 'SET_SEARCH_BOX';
export const FINISH_CONVERSATION = 'FINISH_CONVERSATION';
export const SET_DASHBOARD_REPORT = 'SET_DASHBOARD_REPORT';
export const CLEAR_LONGEST_TIME_BY_AGENT = 'CLEAR_LONGEST_TIME_BY_AGENT';
export const SET_CSFP_CHANGE_DATA = 'SET_CSFP_CHANGE_DATA';
export const SET_SHOW_CONFIRM_SAVED_DATA = 'SET_SHOW_CONFIRM_SAVED_DATA';
export const SET_PREVIOUS_ACTION = 'SET_PREVIOUS_ACTION';
export const SET_MAYBE_NOT_AVAILABLE = 'SET_MAYBE_NOT_AVAILABLE';
export const SET_CONVERSATIONS_BOX = 'SET_CONVERSATIONS_BOX';
export const ADD_CONVERSATIONS_BOX = 'ADD_CONVERSATIONS_BOX';
export const CLEAR_CONVERSATIONS_BOX = 'CLEAR_CONVERSATIONS_BOX';
export const SET_SHOW_WEB_BROWSING_HISTORY_PANEL = 'SET_SHOW_WEB_BROWSING_HISTORY_PANEL';
export const SET_WEB_BROWSING_HISTORY_DATA = 'SET_WEB_BROWSING_HISTORY_DATA';
export const CLEAR_WEB_BROWSING_HISTORY_DATA = 'CLEAR_WEB_BROWSING_HISTORY_DATA';
export const SET_USER_LABELS = 'SET_USER_LABELS';
export const SET_CONVERSATION_LABELS = 'SET_CONVERSATION_LABELS';
export const ADD_NEW_LABEL = 'ADD_NEW_LABEL';
export const DELETE_USER_LABEL = 'DELETE_USER_LABEL';
export const UPDATE_USER_LABEL = 'UPDATE_USER_LABEL';
export const UPDATE_CONV_IN_USERINFO = 'UPDATE_USER_LABEL';
export const SET_SYSTEM_DEFINED_WORD = 'SET_SYSTEM_DEFINED_WORD';
export const SET_SHOW_STATUS_HISTORY_PANEL = 'SET_SHOW_STATUS_HISTORY_PANEL';
export const UPDATE_STATUS_FOR_SELECT_CONVRS = 'UPDATE_STATUS_FOR_SELECT_CONVRS';
export const SET_IMAGE_OF_URL_PREVIEW = 'SET_IMAGE_OF_URL_PREVIEW';
