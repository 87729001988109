import { i18n } from 'locales';
import { extend } from 'vee-validate';
import { required, numeric } from 'vee-validate/dist/rules';

export const validateFolderName = value => {
  // Check too long name
  if (value.length > 255) {
    return false;
  }
  // Check folder name valid
  let re = /[!@#$%^&*(),.?":{}|<>]/;
  if (re.test(value)) {
    return false;
  }
  return true;
};
export const validateCountryCode = value => {
  if (value.length < 2) {
    return false;
  }
  if (value.length > 3) {
    return false;
  }
  if (/^\d+$/g.test(value) || /^[A-Za-z]+$/g.test(value)) {
    return true;
  }
  return false;
};

export const validatePostCode = value => {
  if (value.length < 3) {
    return false;
  }
  if (value.length > 25) {
    return false;
  }
  const regexPostCode = /^[A-Za-z0-9-\s]+$/;
  if (value.toString().indexOf('-') === 0) {
    return false;
  } else if (value.toString().indexOf('-') === value.toString().length - 1) {
    return false;
  }
  if (value.toString().indexOf(' ') === 0) {
    return false;
  } else if (value.toString().indexOf(' ') === value.toString().length - 1) {
    return false;
  }

  const duplicated = value
    .toLowerCase()
    .split('')
    .sort()
    .join('')
    .match(/(-)\1+/g);
  if (duplicated !== null) return false;
  const duplicatedSpace = value
    .toLowerCase()
    .split('')
    .sort()
    .join('')
    .match(/(\s)\1+/g);
  if (duplicatedSpace !== null) return false;
  const regexNumber = /^\d+$/;
  if (value.toString().indexOf('-') != -1 && !regexNumber.test(value.split('-').join(''))) {
    return false;
  }

  if (!regexPostCode.test(value)) {
    return false;
  }
  return true;
};

export const validatePhone = phoneNumber => {
  const regexPhone = /^[+]*[(+]{0,2}[0-9]{0,4}[)]{0,1}[*#]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[*#]{0,1}([-]{0,1}\d([*#]{0,1}))*$/;
  return !phoneNumber ||
    !regexPhone.test(phoneNumber) ||
    phoneNumber.indexOf('()') !== -1 ||
    phoneNumber.indexOf('(+)') !== -1 ||
    (phoneNumber.split('(').length - 1 === 1 && phoneNumber.split(')').length - 1 < 1) ||
    (phoneNumber.split(')').length - 1 === 1 && phoneNumber.split('(').length - 1 < 1) ||
    phoneNumber.split(')').length - 1 > 1 ||
    phoneNumber.split('(').length - 1 > 1 ||
    phoneNumber.split('+').length - 1 > 1 ||
    phoneNumber.length < 3 ||
    phoneNumber.length > 25
    ? false
    : true;
};

// Ref: https://flyingsky.github.io/2018/01/26/javascript-detect-chinese-japanese/
// Ref: https://gist.github.com/ryanmcgrath/982242
// Ref: https://www.regextester.com/116401
// Verify special characters of Japanese & Chinese & Thai
export const hasSpecialCharacter = value => {
  if (!value) return false;
  const REGEX_JAPANESE = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
  const hasJapanese = REGEX_JAPANESE.test(value);

  const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
  const hasChinese = REGEX_CHINESE.test(value);

  const REGEX_THAI = /[\u0E00-\u0E7F']/g;
  const hasThai = REGEX_THAI.test(value);
  if (hasJapanese || hasChinese || hasThai) {
    return true;
  }
  return false;
};
export const hasEmoji = value => {
  if (!value) return false;
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return regex.test(value);
};
export const hasWhiteSpace = value => {
  return /\s/g.test(value);
};

export const convert2OneByte = value => {
  return !value
    ? value
    : value
        .replace(/[\uff01-\uff5e]/g, fullWidthChar =>
          String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
        )
        .replace(/ー|ｰ|−/g, '-');
};
export const convertEmojis = value => {
  return value.replace(
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
    ''
  );
};

extend('baseRequired', {
  ...required,
  params: ['label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.required', { fieldName: value.label });
  }
});

extend('baseEmail', {
  validate(value) {
    if (!value) return true;

    const dotsRegex = /\.\./g;
    const localLastDotRegex = /.*\.@/; //local part: not accept dot in the end
    const domainLastDotRegex = /@.*\.$/; //domain part : not accept dot in the end
    const atLeastOneDomain = /@[-!#-'*+/-9=?A-Z^-~]+(?:\.[-!#-'*+/-9=?A-Z^-~]+)+/; // domain part: at lease one domain (.com)
    const regexEmail = /^[0-9A-Za-z!#$%&'*+\-/=?^_`{|}~]([-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]{0,63})@[0-9A-Za-z][-0-9A-Za-z.]{0,254}$/;

    if (
      dotsRegex.test(value) ||
      value.endsWith('-') ||
      localLastDotRegex.test(value) ||
      domainLastDotRegex.test(value) ||
      !atLeastOneDomain.test(value) ||
      Buffer.byteLength(value, 'utf8') !== value.length ||
      !regexEmail.test(value) ||
      value.length < 5 ||
      value.length > 320
    ) {
      return false;
    }
    return true;
  },
  params: ['label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.email', { fieldName: value.label });
  }
});

extend('baseNumeric', {
  ...numeric,
  params: ['label'],
  message: i18n.t('veevalidate.validations.numeric')
});

extend('baseSelectRequired', {
  ...required,
  params: ['label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.select.required', { fieldName: value.label });
  }
});

extend('baseSelectCustomRequired', {
  ...required,
  params: ['label'],
  message: (_, value) => {
    return i18n.t(value.label);
  }
});

extend('baseInputCustomRequired', {
  ...required,
  params: ['label'],
  message: (_, value) => {
    return i18n.t(value.label);
  }
});

extend('baseCheckRequired', {
  ...required,
  params: ['label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.check.required', { fieldName: value.label });
  }
});

extend('basePhoneNumber', {
  validate(value) {
    return validatePhone(convert2OneByte(value));
  },
  params: ['label'],
  message: i18n.t('veevalidate.validations.phoneNumber')
});

extend('basePostCode', {
  validate(value) {
    return validatePostCode(convert2OneByte(value));
  },
  params: ['label'],

  message: i18n.t('veevalidate.validations.postCode')
});
extend('baseCountryCode', {
  validate(value) {
    return validateCountryCode(convert2OneByte(value));
  },
  params: ['label'],
  message: i18n.t('veevalidate.validations.countryCode')
});

extend('baseFolderName', {
  validate(value) {
    return validateFolderName(convert2OneByte(value));
  },
  params: ['label'],
  message: i18n.t('veevalidate.validations.FolderName')
});

extend('baseMaxLength', {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ['length', 'label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.maxlength', { fieldName: value.label });
  }
});

extend('baseMinLength', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length', 'label'],
  message: (_, value) => {
    return i18n.t('veevalidate.validations.minlength', { fieldName: value.label });
  }
});
