<template>
  <div id="searchBox" class="form-group search-box">
    <div id="filter" data-testid="searchConversation" class="input-group" variant="primary">
      <div class="input-group-prepend">
        <span class="input-group-text search-box-icon">
          <i class="fa fa-search" />
        </span>
      </div>
      <span class="input-group-text center search-box-input">
        {{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.search_conversation') }}
      </span>

      <div class="input-group-prepend">
        <span class="input-group-text last">
          <i class="fa fa-sort-amount-down" />
        </span>
      </div>
    </div>
    <div class="scroll-search">
      <ul class="nav nav-pills">
        <li
          v-for="(badgeItem, index) in listFilterBadges"
          :key="`${badgeItem}-${index}`"
          :type="badgeItem"
          style="white-space: pre-wrap"
        >
          <filter-badge-item
            :badge-name="badgeItem.badgeName"
            :badge-type="badgeItem.badgeType"
            :badge-name-translate="badgeItem.badgeNameTranslate || ''"
            @onRemoveFilterBadge="onRemoveFilterBadge(badgeItem)"
          />
        </li>
      </ul>
    </div>

    <b-popover
      :show="show"
      class="ppvFilter"
      triggers="click"
      placement="bottom"
      target="filter"
      @show="onPopoverShow"
      @hidden="closeFilterCondition"
      ref="myPopover"
    >
      <!-- Languages (S) -->
      <b-form-checkbox id="cbLanguage" v-model="filterByLanguage" @change="filterByLanguageChange">
        <strong>
          {{
            $t('src.modules.chat.components.AsideLeft.SearchBox.index.language_(facebook_webchat)')
          }}
        </strong>
      </b-form-checkbox>

      <b-form-group class="pl-30">
        <b-form-checkbox-group
          id="filterByLanguage"
          v-model="lstLangSelected"
          :options="handleLanguageSupport(langsupports)"
          :disabled="!filterByLanguage"
          :class="!filterByLanguage ? 'filterByLanguage-disable' : ''"
          value-field="localeCode"
          text-field="langName"
          stacked
        />
      </b-form-group>
      <!-- Languages (E) -->

      <!-- Agent (S) -->
      <b-form-checkbox
        id="cbAgent"
        v-model="filterByAgent"
        @change="filterByAgentChange"
        :disabled="isConversationWithBot"
      >
        <strong>{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.agent') }}</strong>
      </b-form-checkbox>
      <div class="w-100 pl-30">
        <base-input
          :placeholder-type="'search'"
          :disabled="!filterByAgent"
          v-model="filterAgentValue"
          :name="'filterAgentValue'"
          :label="$t('src.modules.chat.components.AsideLeft.SearchBox.index.agent_name')"
          :show-label="false"
        />
      </div>
      <!-- Agent (E) -->

      <!-- User (S) -->
      <b-form-checkbox id="cbUser" v-model="filterByUser" @change="filterByUserChange">
        <strong>{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.user') }}</strong>
      </b-form-checkbox>
      <div class="w-100 pl-30">
        <base-input-tag
          :placeholder-type="'search'"
          :label="$t('src.modules.chat.components.AsideLeft.SearchBox.index.user_name')"
          :name="'filterUserValue'"
          v-model="filterUserValue"
          :disabled="!filterByUser"
          :show-label="false"
          :maxlength="100"
          @close="value => handleClose('user', value)"
        />
      </div>
      <!-- User (E) -->

      <!-- Tags (S) -->
      <b-form-checkbox id="cbTag" v-model="filterByTag" class="mt-20" @change="filterByTagChange">
        <strong>{{
          $t('src.modules.chat.components.ChatBox.chat-header.title_conversation_labels')
        }}</strong>
      </b-form-checkbox>
      <div class="w-100 pl-30">
        <base-input-tag
          :placeholder-type="'search'"
          :label="$t('src.modules.chat.components.AsideLeft.SearchBox.index.conversation_label')"
          :name="'filterTagValue'"
          v-model="filterTagValue"
          :disabled="!filterByTag"
          :show-label="false"
          :maxlength="25"
          @close="value => handleClose('tag', value)"
        />
      </div>
      <!-- Tags (E) -->

      <!-- State Status (S) -->
      <b-form-checkbox id="cbSnS" v-model="filterBySnS" class="mt-20" @change="filterBySnSChange">
        <strong>
          {{ $t('common.text.phase_and_status') }}
        </strong>
      </b-form-checkbox>

      <!-- State (S) -->
      <div style="height: 35px" class="w-100 pl-30">
        <base-select
          :data-test-id="'drpPhase'"
          v-model="filterStateValue"
          :align="'vertical'"
          :label="$t('common.text.phase')"
          :options="ticketStateList"
          :option-value="'value'"
          :option-lable="'text'"
          :name="'popState'"
          :id="'popState'"
          :value-type="'String'"
          :disabled="!filterBySnS"
          :show-label="false"
          @change="ticketStateChange"
        />
      </div>
      <!-- State (E) -->

      <!-- Status (S) -->
      <div style="height: 35px" class="w-100 pl-30 mt-10">
        <base-select
          :data-test-id="'drpStatus'"
          :id="'popStatus'"
          v-model="filterStatusValue"
          :align="'vertical'"
          :label="$t('src.modules.chat.components.AsideLeft.SearchBox.index.status')"
          :options="ticketStatusList"
          :option-value="'value'"
          :option-lable="'text'"
          :name="'popStatus'"
          :value-type="'String'"
          :disabled="!filterBySnS || !filterStateValue"
          :show-label="false"
        />
      </div>
      <!-- Status (E) -->
      <!-- State Status (E) -->

      <!-- Is Follow (S) -->
      <b-form-checkbox id="cbFollow" v-model="filterFollowValue" class="mt-20">
        <strong>{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.follow') }}</strong>
      </b-form-checkbox>
      <!-- Is Follow (E) -->

      <div class="mt-10 mb-10" style="float: left">
        <b-button
          data-testid="btnClear"
          variant="secondary"
          size="sm"
          class="p-l-10 p-r-10"
          @click="clearFilterCondition"
          >{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.clear') }}</b-button
        >
      </div>
      <div class="mt-10 mb-10" style="float: right">
        <b-button
          data-testid="btnClose"
          variant="default"
          size="sm"
          class="p-l-10 p-r-10"
          @click="closeFilterCondition"
          >{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.close') }}</b-button
        >
        <b-button
          data-testid="btnSearch"
          variant="primary"
          size="sm"
          class="p-l-10 p-r-10"
          @click="applyFilterCondition"
          >{{ $t('src.modules.chat.components.AsideLeft.SearchBox.index.search') }}</b-button
        >
      </div>
    </b-popover>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FilterBadgeItem from './filter-badge-item.vue';
import { EventBus } from 'core/eventBus';

import {
  TICKET_STATE,
  TICKET_STATUS,
  SEARCH_FILTER,
  DISPLAY_LANGUAGE_NAME,
  TICKET_TYPE_CODE,
  TABS,
  SERVING_STATE
} from 'core/constants';

export default {
  components: {
    FilterBadgeItem
  },

  props: {
    langsupports: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      lstLangSelected: [],
      listFilterBadges: [],
      locales: [],
      lstSearchText: [],
      searchText: '',

      filterByLanguage: false,
      filterByAgent: false,
      filterByUser: false,
      filterByTag: false,
      filterBySnS: false,

      filterStateValue: '',
      filterStatusValue: '',
      filterAgentValue: '',
      filterUserValue: [],
      filterFollowValue: false,
      filterTagValue: [],

      ticketStateList: [
        ...Object.keys(TICKET_STATE)
          .filter(state => 'BOT' != state)
          .map(state => {
            return {
              value: state,
              text: TICKET_STATE[state]
            };
          })
      ],
      ticketStatusList: [],
      inputValueTag: '',
      maxlengthTag: 25
    };
  },
  computed: {
    ...mapState('chat', ['searchBoxFilter', 'selectedGroupConv']),
    ...mapGetters('chat', ['selectedConversation']),
    ...mapState('session', ['channels', 'waitingMode', 'user', 'selectedChannel']),

    isConversationWithBot() {
      return this.selectedGroupConv === 0 ? true : false;
    }
  },

  watch: {
    waitingMode() {
      this.listFilterBadges = [];
      this.setTicketsSearchClear({});
      this.setChatSearchBoxFilter({});
    },

    searchBoxFilter() {
      if (
        this.searchBoxFilter.constructor === Object &&
        Object.keys(this.searchBoxFilter).length === 0
      ) {
        this.listFilterBadges = [];
        this.clearFilterCondition();
        this.setChatSearchBoxFilter({});
      }
    },

    selectedGroupConv() {
      this.listFilterBadges = [];
      this.setTicketsSearchClear({});
      this.setChatSearchBoxFilter({});
    }
  },

  async created() {
    EventBus.$on('ReloadChannelIsSearch', async channelId => this.onOk(channelId));
    this.setLocalesToStorage();
    let _locales = localStorage.getItem('locales');
    if (_locales) {
      let locales = JSON.parse(_locales);
      this.locales = locales;
    }
  },

  methods: {
    ...mapActions('chat', [
      'setLocalesToStorage',
      'setSearchBoxFilter',
      'setSearchBoxHeight',
      'setSearchBox'
    ]),
    ...mapActions('session', [
      'getTicketsBySearch',
      'setTicketsSearchClear',
      'reloadChannel',
      'setChannelTabPage',
      'setChatSearchBoxFilter'
    ]),
    ...mapActions('global', ['setGlobalReady']),

    handleClose(type, value) {
      const newListFilterBadges = this.listFilterBadges.filter(
        item => !(item.badgeName === value && item.badgeType === type)
      );
      if (type === 'user') {
        const newfilterUserValue = this.filterUserValue.filter(item => item !== value);
        this.filterUserValue = newfilterUserValue;
      } else {
        const newfilterTagValue = this.filterTagValue.filter(item => item !== value);
        this.filterTagValue = newfilterTagValue;
      }
      this.listFilterBadges = newListFilterBadges;
    },

    applyFilterCondition() {
      this.$refs.myPopover.$emit('close');
      this.$refs.myPopover.$emit('enable');
      this.onOk();
    },

    closeFilterCondition() {
      this.$refs.myPopover.$emit('close');
      this.$refs.myPopover.$emit('enable');
      this.clearFilterCondition();
      this.keepFilterCondition();
    },

    updateSearchBoxHeight() {
      let height = 95;
      if (this.listFilterBadges && this.listFilterBadges.length > 0) {
        const searchBoxHeight = document.getElementById('searchBox');
        height = searchBoxHeight ? searchBoxHeight.offsetHeight : 0;
      }
      this.setSearchBoxHeight(height);
    },

    isFilter() {
      const keys = Object.keys(this.searchBoxFilter);
      for (const key of keys) {
        if (this.searchBoxFilter[key].length > 0) return true;
      }
      return false;
    },

    async onOk(channelId) {
      if (channelId) {
        this.setTicketsSearchClear({ channelId });
      } else {
        this.setTicketsSearchClear({});
      }

      // Build filter badges
      let allBadges = [];
      let filters = {};
      if (this.lstSearchText && this.lstSearchText.length > 0) {
        filters.ticketIds = this.lstSearchText;
        this.lstSearchText.map(text => {
          allBadges.push({
            badgeName: text,
            badgeType: 'text'
          });
        });
      }

      if (this.lstLangSelected && this.lstLangSelected.length > 0) {
        filters.userLocal = this.lstLangSelected;
        this.lstLangSelected.map(lang => {
          allBadges.push({
            badgeNameTranslate: DISPLAY_LANGUAGE_NAME[lang] || '',
            badgeName: this.parseLanguage('localeCode', 'langName', lang),
            badgeType: 'language'
          });
        });
      }

      this.filterAgentValue = this.filterAgentValue ? this.filterAgentValue.trim() : '';
      if (this.filterAgentValue) {
        filters.agentName = [this.filterAgentValue];
        allBadges.push({
          badgeName: this.filterAgentValue,
          badgeType: 'agent'
        });
      }

      if (this.filterUserValue && this.filterUserValue.length > 0) {
        filters.userName = this.filterUserValue;
        this.filterUserValue.map(text => {
          allBadges.push({
            badgeName: text,
            badgeType: 'user'
          });
        });
      }

      if (this.filterTagValue && this.filterTagValue.length > 0) {
        filters.tagName = this.filterTagValue;
        this.filterTagValue.map(text => {
          allBadges.push({
            badgeName: text,
            badgeType: 'tag'
          });
        });
      }

      if (this.filterStateValue) {
        filters.state = [this.filterStateValue];
        allBadges.push({
          badgeName: this.filterStateValue,
          badgeType: 'state'
        });
      }

      if (this.filterStatusValue) {
        filters.status = [this.filterStatusValue];
        allBadges.push({
          badgeName: this.filterStatusValue,
          badgeType: 'status'
        });
      }

      if (this.filterFollowValue) {
        filters.follow = [this.filterFollowValue];
        allBadges.push({
          badgeName: this.filterFollowValue ? 'true' : 'false',
          badgeType: 'follow'
        });
      }

      this.listFilterBadges = allBadges;
      this.setSearchBoxFilter({ ...filters });
      this.setChatSearchBoxFilter({ ...filters });
      if (this.isFilter()) {
        const params = {};
        params.conditions = this.searchBoxFilter;
        params.from = 0;
        params.size = SEARCH_FILTER.FIRST_LOAD_TICKET_QTY;
        params.tab = this.selectedGroupConv;
        params.waitingMode = this.waitingMode;
        params.agentId = this.user.id;
        this.setSearchBox(false);

        try {
          if (channelId) {
            params.channelId = channelId;
            await this.getTicketsBySearch(params);
          } else {
            await Promise.all(
              this.channels.map(async c => {
                const searchParams = { ...params, channelId: c.id };
                return this.getTicketsBySearch(searchParams);
              })
            );
          }
        } catch (error) {
          // eslint-disable-next-line
          this.$log('[C+ Debug] [ERROR] >> SearchBoxFilter -> error', error);
          this.setSearchBox(true);
        }

        this.setSearchBox(true);
      } else if (this.selectedChannel) {
        let status = this.selectedGroupConv;
        if (status === 1 && this.waitingMode === 'TOME') {
          status = TICKET_TYPE_CODE.TOME;
        }
        if (status === TABS.MONITORING) {
          status = TICKET_TYPE_CODE.MONITORING;
        }
        if (status === SERVING_STATE.AGENT) {
          status = TICKET_TYPE_CODE.AGENT;
        }

        const channelStatus = `${this.selectedChannel}${status}`;
        this.setChannelTabPage({ channelStatus, page: 1 });
        this.reloadChannel({
          status,
          limit: 100,
          channelId: this.selectedChannel
        });
      }
      this.updateSearchBoxHeight();
    },

    onRemoveFilterBadge(badgeItem) {
      this.setTicketsSearchClear({});
      let removeKey = badgeItem.badgeName;
      switch (badgeItem.badgeType) {
        case 'text':
          this.lstSearchText = this.lstSearchText.filter(item => {
            return item !== removeKey;
          });
          break;
        case 'language':
          this.lstLangSelected = this.lstLangSelected.filter(item => {
            return item !== this.parseLanguage('langName', 'localeCode', removeKey);
          });
          this.filterByLanguage = this.lstLangSelected.length > 0;
          break;
        case 'agent':
          this.filterAgentValue = '';
          this.filterByAgent = false;
          break;
        case 'user':
          {
            this.filterUserValue = this.filterUserValue.filter(item => {
              return item !== removeKey;
            });
            if (this.filterUserValue.length == 0) {
              this.filterByUser = false;
            }
          }
          break;
        case 'tag':
          {
            this.filterTagValue = this.filterTagValue.filter(item => {
              return item !== removeKey;
            });
            if (this.filterTagValue.length == 0) {
              this.filterByTag = false;
            }
          }
          break;
        case 'state':
          this.filterStateValue = '';
          this.filterStatusValue = '';
          this.filterBySnS = false;
          break;
        case 'status':
          this.filterStatusValue = '';
          if (this.filterStateValue == '') {
            this.filterBySnS = false;
          }
          break;
        case 'follow':
          this.filterFollowValue = false;
          break;
      }
      this.onOk();
    },

    onSearchtext() {
      this.searchText = this.searchText ? this.searchText.trim() : '';
      if (this.searchText === '') {
        this.searchText = '';
        return;
      }

      if (this.searchText.length < 2) {
        this.$baseNotification.warning({
          title: this.$t('src.core.App.warning'),
          message: this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.index.please_input_more_than_1_character'
          )
        });
        return;
      }

      if (!this.lstSearchText.includes(this.searchText)) {
        this.lstSearchText = [...this.lstSearchText, this.searchText];
        // add to filter badges

        this.listFilterBadges = [
          ...this.listFilterBadges,
          {
            badgeName: this.searchText,
            badgeType: 'text'
          }
        ];
      }

      this.searchText = '';
    },
    onFocusInputSearch() {
      this.$refs.myPopover.$emit('open');
      this.$refs.myPopover.$emit('disable');
    },
    onHidden() {
      this.onOk();
    },

    handleLanguageSupport(langs) {
      let optionsData = langs.map(i => this.splitLocale(i));

      return optionsData.map(i => {
        if (DISPLAY_LANGUAGE_NAME[i.localeCode]) {
          i['langName'] = DISPLAY_LANGUAGE_NAME[i.localeCode];
          return i;
        }
        return i;
      });
    },

    splitLocale(_locale) {
      _locale = _locale.replace('-', '_');
      let split = _locale.split('_');
      let data = {
        langCode: split[0],
        conutryCode: split[1] || '',
        localeCode: `${split[0]}_${split[1]}`,
        conutryName:
          split[1] && split[1] != undefined
            ? (this.locales.countrys[split[1]] && this.locales.countrys[split[1]].c) || _locale
            : _locale,
        langName:
          split[0] && split[0] != undefined
            ? (this.locales.isoLangs[split[0]] && this.locales.isoLangs[split[0]].name) || _locale
            : _locale
      };
      return data;
    },

    filterByLanguageChange(checked) {
      this.filterByLanguage = checked;
      if (!checked) {
        this.lstLangSelected = [];
      }
    },
    filterByAgentChange(checked) {
      this.filterByAgent = checked;
      if (!checked) {
        this.filterAgentValue = '';
      }
    },
    filterByUserChange(checked) {
      this.filterByUser = checked;
      if (!checked) {
        this.filterUserValue = [];
      }
    },
    filterByTagChange(checked) {
      this.filterByTag = checked;
      if (!checked) {
        this.filterTagValue = [];
      }
    },
    filterBySnSChange(checked) {
      this.filterBySnS = checked;
      if (!checked) {
        this.filterStateValue = '';
        this.filterStatusValue = '';
      }
    },
    clearFilterCondition() {
      this.filterByLanguage = false;
      this.filterByAgent = false;
      this.filterByUser = false;
      this.filterByTag = false;
      this.filterBySnS = false;
      this.inputVisibleUserName = false;
      this.filterFollowValue = false;
      this.filterStateValue = '';
      this.filterStatusValue = '';
      this.filterAgentValue = '';
      this.filterUserValue = [];
      this.filterTagValue = [];
      this.lstLangSelected = [];
      this.lstSearchText = [];
      this.inputValueTag = '';
    },
    ticketStateChange(value) {
      let filteredStatus = [];
      switch (value) {
        case TICKET_STATE.REFER:
          filteredStatus = [TICKET_STATUS.USER_MENTION];
          break;
        case 'REQUEST':
          filteredStatus = [
            TICKET_STATUS.BOT_HANDLE,
            TICKET_STATUS.REQUEST_USER,
            TICKET_STATUS.REQUEST_AGENT,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.WAITING_TIMEOUT
          ];
          break;
        case 'START':
          filteredStatus = [
            TICKET_STATUS.AGENT_START,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.RESPONSE_TIMEOUT,
            TICKET_STATUS.AGENT_IDLE
          ];
          break;
        case 'SUPPORTING':
          filteredStatus = [
            TICKET_STATUS.AGENT_SUPPORTING,
            TICKET_STATUS.ASSIGN_TO,
            TICKET_STATUS.TRANSFER_TO,
            TICKET_STATUS.ESCALATE_TO,
            TICKET_STATUS.FORWARD_GROUP,
            TICKET_STATUS.HOLDING,
            TICKET_STATUS.USER_IDLE,
            TICKET_STATUS.AGENT_IDLE,
            TICKET_STATUS.RESPONSE_TIMEOUT
          ];
          break;
        case 'FINISH':
          filteredStatus = [
            TICKET_STATUS.AGENT_FINISH,
            TICKET_STATUS.USER_FINISH,
            TICKET_STATUS.MAINTENANCE_FINISH,
            TICKET_STATUS.FINISH_TIMEOUT
          ];
          break;
        case 'COMPLETE':
          filteredStatus = [
            TICKET_STATUS.BOT_COMPLETE,
            TICKET_STATUS.BOT_TIMEOUT,
            TICKET_STATUS.COMPLETE,
            TICKET_STATUS.WAITING_TIMEOUT,
            TICKET_STATUS.RESPONSE_TIMEOUT,
            TICKET_STATUS.FINISH_TIMEOUT,
            TICKET_STATUS.NO_SUPPORT,
            TICKET_STATUS.SYSTEM_TERMINATE,
            TICKET_STATUS.SYSTEM_MAINTENANCE,
            TICKET_STATUS.BOT_TERMINATE,
            TICKET_STATUS.USER_MENTION,
            TICKET_STATUS.HOLIDAY,
            TICKET_STATUS.NON_WORKING,
            TICKET_STATUS.NO_ONLINE_AGENT
          ];
          break;
      }
      this.ticketStatusList = [
        ...filteredStatus.map(key => {
          return {
            value: key,
            text: TICKET_STATUS[key]
          };
        })
      ];
      this.filterStatusValue = '';
    },
    parseLanguage(iType, oType, data) {
      const mappingLanguages = this.langsupports.map(i => this.splitLocale(i));
      if (mappingLanguages && mappingLanguages.length > 0) {
        for (let index = 0; index < mappingLanguages.length; index++) {
          const langItem = mappingLanguages[index];
          if (langItem[iType] == data) {
            return langItem[oType];
          }
        }
      }
      return '';
    },

    onPopoverShow() {
      this.$refs.myPopover._toolpop.getTipElement().classList.add('my-popover-class');
    },

    keepFilterCondition() {
      if (!this.listFilterBadges.length) return null;
      this.listFilterBadges.map(({ badgeName, badgeType }) => {
        switch (badgeType) {
          case 'language':
            this.filterByLanguage = true;
            this.lstLangSelected.push(this.parseLanguage('langName', 'localeCode', badgeName));
            break;
          case 'agent':
            this.filterByAgent = true;
            this.filterAgentValue = badgeName;
            break;
          case 'user':
            this.filterByUser = true;
            this.filterUserValue = [...this.filterUserValue, badgeName];
            break;
          case 'tag':
            this.filterByTag = true;
            this.filterTagValue = [...this.filterTagValue, badgeName];
            break;
          case 'state':
            this.filterBySnS = true;
            this.filterStateValue = badgeName;
            break;
          case 'status':
            this.filterStatusValue = badgeName;
            break;
          case 'follow':
            this.filterFollowValue = badgeName;
            break;
        }
      });
    }
  }
};
</script>

<style lang="css">
.my-popover-class {
  min-width: 405px !important;
  width: 405px !important;
}
</style>

<style lang="css" scoped>
.input-group-text.center {
  border-radius: unset !important;
  width: 81%;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.popover > .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.app-message .page-aside .search-box a {
  padding: 3px 3px 2px 3px;
  margin: 5px;
  margin-top: 15px;
  padding-right: 15px;
  border: none;
  position: relative;
  text-transform: inherit;
  background-color: #f9f9fc;
  color: #172b4d;
}
.app-message .page-aside .search-box .badge {
  position: absolute;
  background: #f9f9fc;
  top: -6px;
  right: -15px;
  padding: 4px;
  color: #172b4d;
  border-radius: 50%;
}
.app-message .page-aside .search-box .badge i {
  font-size: 0.6rem;
}
.app-message .page-aside .search-box .custom-checkbox {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.filterByLanguage-disable {
  /deep/.custom-checkbox {
    /deep/.custom-control-label::after {
      cursor: not-allowed;
    }
  }
}

.asideleft-search-box {
  background-color: #adb5bd !important;
  color: #fff !important;
  width: 81%;
  & /deep/.nat-input-verti {
    color: #fff !important;
    background-color: #adb5bd !important;
    border: 1px solid #adb5bd !important;
    &:focus {
      outline: none !important;
      border: 1px solid #adb5bd !important;
    }
  }
}

.chat-status-tag {
  width: 350px;
  height: 100%;
  min-height: 35px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  & /deep/ .input-new-tag {
    width: 350px;
    left: -12px;
    height: 35px;
  }
  & /deep/ .el-input__inner {
    border: unset !important;
    height: 35px;
    line-height: 35px;
    font-size: 0.875rem;
  }
  .el-tag p {
    display: inline-block;
    height: 28px;
    line-height: 26px;
    font-size: 12px;
    white-space: nowrap;
    max-width: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
  }
  .el-tag {
    margin-right: 5px;
    margin-top: 5px;
  }
  & /deep/ .el-input.is-disabled .el-input__inner {
    background-color: #e9ecef;
    color: #8898aa;
  }
}

.nat-maxlengthTag {
  color: #909399;
  vertical-align: middle;
  z-index: 1;
  font-size: 8px;
  vertical-align: middle;
  margin-top: 2px;
  position: absolute;
  font-weight: 900;
}

.nat-maxlengthTag.error {
  color: red;
}

.scroll-search {
  max-height: 200px;
  overflow-y: auto;
}
</style>
