<template>
  <div
    v-if="ticket && ticket.state === TICKET_STATE.COMPLETE"
    class="chat-opt-menu remove-ticket-action hover"
    v-on:click.stop
  >
    <b-dropdown
      variant="default"
      size="sm"
      toggle-class="d-flex align-items-center dropdown-toggle leftpanel-1"
      ref="dropdown"
      no-caret
    >
      <template slot="button-content">
        <i class="material-icons">more_vert</i>
      </template>
      <b-dropdown-item
        class="disable-item"
        v-if="actions[ACTION.RE_SUPPORT]"
        @click="handleChatOptMenuComplete('re-support')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.re_support'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item class="disable-item" @click="handleChatOptMenuComplete('remove-ticket')">
        {{ $t('common.text.hide') }}
      </b-dropdown-item>
    </b-dropdown>

    <support-ticket
      :show="showSupportTicket"
      :conversation="conversation"
      :agent-groups="agentGroups"
      :version="ticket && ticket.version"
      @onClose="handleCloseSupportTicket"
    />
    <confirm-modal
      v-model="showModelconfirm"
      :title="titleConfirmModal"
      :type="typeConfirmModal"
      :enable-request-user="enableRequestUser"
      :channel-has-bot="channelHasBot"
      :is-story-conversation="isStoryConversation"
      @support="handleSupportWithoutUserAgreement"
      @yes="handleYes"
      @no="handleNo"
    />
  </div>
  <div v-else class="chat-opt-menu">
    <b-dropdown
      variant="default"
      size="sm"
      toggle-class="d-flex align-items-center dropdown-toggle leftpanel-1"
      ref="dropdown"
      no-caret
    >
      <template slot="button-content">
        <i class="material-icons">more_vert</i>
      </template>
      <b-dropdown-item
        data-option="transfer-to"
        class="disable-item"
        :disabled="!actions[ACTION.TRANSFER]"
        @click="handleChatOptMenu('transfer-to')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.transfer_to'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item
        data-option="assign-to"
        class="disable-item"
        :disabled="!actions[ACTION.ASSIGN]"
        @click="handleChatOptMenu('assign-to')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.assign_to'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item
        data-option="escalate-to"
        class="disable-item"
        :disabled="!actions[ACTION.ESCALATE]"
        @click="handleChatOptMenu('escalate-to')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.escalate_to'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item
        data-option="forward-group"
        class="disable-item"
        :disabled="!actions[ACTION.FORWARD_GROUP]"
        @click="handleChatOptMenu('forward-group')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.forward_group'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        data-option="support"
        v-if="!actions[ACTION.RE_SUPPORT]"
        :disabled="!actions[ACTION.SUPPORT]"
        @click="handleChatOptMenu('support-user')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.support'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item
        data-option="re-support"
        v-if="actions[ACTION.RE_SUPPORT]"
        @click="handleChatOptMenu('re-support')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.re_support'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-item
        data-option="no-support"
        :disabled="!actions[ACTION.NO_SUPPORT]"
        @click="handleChatOptMenu('no-support-user')"
      >
        {{
          $t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.dont_support'
          )
        }}
      </b-dropdown-item>
      <b-dropdown-divider />
    </b-dropdown>

    <support-ticket
      :show="showSupportTicket"
      :conversation="conversation"
      :agent-groups="agentGroups"
      :version="ticket && ticket.version"
      @onClose="handleCloseSupportTicket"
    />
    <confirm-modal
      v-model="showModelconfirm"
      :title="titleConfirmModal"
      :type="typeConfirmModal"
      :enable-request-user="enableRequestUser"
      :channel-has-bot="channelHasBot"
      :is-story-conversation="isStoryConversation"
      @support="handleSupportWithoutUserAgreement"
      @yes="handleYes"
      @no="handleNo"
    />
  </div>
</template>

<script>
import {
  SERVING_STATE,
  TICKET_STATE,
  TICKET_STATUS,
  ACTION,
  TABS,
  AGENT_ROLE
} from 'core/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import { EventBus } from 'core/eventBus';
import SupportTicket from '../../../SupportTicket';
import ConfirmModal from './confirm-modal';

export default {
  components: {
    SupportTicket,
    ConfirmModal
  },
  props: {
    channelId: {
      type: String,
      default: () => ''
    },
    actions: {
      type: Object,
      default: () => {}
    },
    ticket: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: Number,
      default: () => TABS.ME
    },
    conversation: {
      type: Object,
      default: () => {}
    },
    hover: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      transferBoxData: {},
      escalateBoxData: {},
      assignBoxData: {},
      ACTION,
      TICKET_STATE,
      showSupportTicket: false,
      agentGroups: [],
      showModelconfirm: false,
      titleConfirmModal: '',
      typeConfirmModal: ''
    };
  },

  computed: {
    ...mapState('chat', ['newMessages', 'selectedGroupConv']),
    ...mapState('session', ['user', 'waitingMode', 'channelsMap', 'projectMaps']),
    ...mapGetters('session', ['tickets', 'conversationsMap', 'meGroups']),

    enableRequestUser() {
      const { advancedConfigs = {} } = this.channelsMap[this.channelId] || {};
      const { conversationDisplay = {} } = advancedConfigs;
      const { requestUser = {} } = conversationDisplay;
      return requestUser ? true : false;
    },

    isStoryConversation() {
      return this.ticket.status === TICKET_STATUS.USER_MENTION;
    },

    channelHasBot() {
      const { advancedConfigs = {} } = this.channelsMap[this.channelId] || {};
      const { modulesActivation = {} } = advancedConfigs;
      const { bot = false } = modulesActivation;
      return bot;
    }
  },

  watch: {
    hover(newVal) {
      if (!newVal) {
        this.$refs.dropdown.visible = false;
      }
    }
  },

  methods: {
    ...mapActions('chat', [
      'supportTicket',
      'updateAndEmitTicket',
      'noSupportTicket',
      'setReady',
      'setSupportWithoutUserAgreement',
      'setSelectedConversation',
      'setShowView',
      'getConversationTicketById'
    ]),
    ...mapActions('session', ['getGroupsOpts', 'allowReSupport']),
    ...mapActions('global', ['setGlobalLoadingMap', 'setGlobalReady']),

    async handleChatOptMenuComplete(command) {
      this.$emit('setPreventClick');
      switch (command) {
        case 're-support': {
          this.setReady(false);

          const { id: conversationId = '', ticketId = '' } = this.$props.conversation;
          const result = await this.allowReSupport({ conversationId, id: ticketId });
          if (result && result.error) {
            this.setReady(true);
            return EventBus.$emit('reSupportWarningModal', result);
          }

          const groups = await this.getGroupsOpts(this.$props);
          this.agentGroups = groups.filter(i => i.isEnable);
          if (!this.channelHasBot) {
            this.setSupportWithoutUserAgreement(true);
            if (!this.agentGroups || this.agentGroups.length <= 1) {
              this.titleConfirmModal = this.$t(
                'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
              );
              this.typeConfirmModal = 're-support';
              this.showModelconfirm = true;
            } else this.showSupportTicket = true;
          } else {
            if (this.enableRequestUser || !this.agentGroups || this.agentGroups.length <= 1) {
              this.titleConfirmModal = this.$t(
                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.are_you_sure_you_want_to_support_this_user'
              );
              this.typeConfirmModal = 're-support';
              this.showModelconfirm = true;
              this.setSupportWithoutUserAgreement(this.enableRequestUser ? false : true);
            } else {
              this.showSupportTicket = true;
              this.setSupportWithoutUserAgreement(true);
            }
          }
          this.setReady(true);
          break;
        }

        case 'remove-ticket':
          this.typeConfirmModal = 'remove-ticket';
          this.$baseConfirm({
            message: this.$t(
              'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.do_you_want_to_hide_permanently_this_conversation_from_monitoring_list'
            )
          })
            .then(() => {
              this.handleYes(this.typeConfirmModal);
            })
            .catch(() => {});
          break;

        default:
          break;
      }
    },

    async handleChatOptMenu(command) {
      this.$emit('setPreventClick');
      const { role, id } = this.user;
      const servingState = this.selectedGroupConv;
      const waitingState = this.waitingMode;
      switch (command) {
        case 'transfer-to':
          this.transferBoxData = {
            servingState,
            waitingState,
            role,
            channelId: this.channelId,
            ticket: this.ticket,
            meId: id
          };
          if (this.ticket) {
            EventBus.$emit('transferBox', this.transferBoxData);
            this.$root.$emit('bv::show::modal', 'TransferBox', '#transferBox');
          }
          break;
        case 'assign-to':
          this.assignBoxData = {
            servingState,
            waitingState,
            role,
            channelId: this.channelId,
            ticket: this.ticket,
            meId: id
          };
          if (this.ticket) {
            EventBus.$emit('assignBox', this.assignBoxData);
            this.$root.$emit('bv::show::modal', 'AssignBox', '#assignBox');
          }
          break;
        case 'escalate-to':
          this.escalateBoxData = {
            servingState,
            waitingState,
            role,
            channelId: this.channelId,
            ticket: this.ticket,
            meId: id
          };
          if (this.ticket) {
            EventBus.$emit('escalateBox', this.escalateBoxData);
            this.$root.$emit('bv::show::modal', 'EscalateBox', '#escalateBox');
          }

          break;
        case 'support-user': {
          this.confirmSupportTicket();
          break;
        }

        case 're-support': {
          this.setReady(false);
          const { id: conversationId = '', ticketId = '' } = this.$props.conversation;
          const result = await this.allowReSupport({ conversationId, id: ticketId });
          if (result && result.error) {
            this.setReady(true);
            return EventBus.$emit('reSupportWarningModal', result);
          }

          const groups = await this.getGroupsOpts(this.$props);
          this.agentGroups = groups.filter(i => i.isEnable);
          if (!this.channelHasBot) {
            this.setSupportWithoutUserAgreement(true);
            if (!this.agentGroups || this.agentGroups.length <= 1) {
              this.titleConfirmModal = this.$t(
                'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_support_this_user'
              );
              this.typeConfirmModal = 're-support';
              this.showModelconfirm = true;
            } else this.showSupportTicket = true;
          } else {
            if (this.enableRequestUser || !this.agentGroups || this.agentGroups.length <= 1) {
              this.titleConfirmModal = this.$t(
                'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.are_you_sure_you_want_to_support_this_user'
              );
              this.typeConfirmModal = 're-support';
              this.showModelconfirm = true;
              this.setSupportWithoutUserAgreement(this.enableRequestUser ? false : true);
            } else {
              this.showSupportTicket = true;
              this.setSupportWithoutUserAgreement(true);
            }
          }
          this.setReady(true);
          break;
        }

        case 'no-support-user':
          this.typeConfirmModal = 'no-support-user';
          this.$baseConfirm({
            message: this.$t(
              'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.the_action_will_remove_this_user_from_the_system_are_you_sure_you_want_to_not_support_this_user'
            )
          })
            .then(() => {
              this.handleYes(this.typeConfirmModal);
            })
            .catch(() => {});
          break;

        case 'remove-ticket':
          this.typeConfirmModal = 'remove-ticket';
          this.$baseConfirm({
            message: this.$t(
              'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.do_you_want_to_hide_permanently_this_conversation_from_monitoring_list'
            )
          })
            .then(() => {
              this.handleYes(this.typeConfirmModal);
            })
            .catch(() => {});
          break;

        case 'forward-group':
          EventBus.$emit('forwardGroup', this.ticket);
          break;

        default:
          break;
      }
    },

    async confirmSupportTicket() {
      this.setGlobalReady(false);
      try {
        const { id: conversationId = '', ticketId = '', projectId = '' } = this.$props.conversation;
        const coversationTicket = await this.getConversationTicketById({
          conversationId,
          ticketId
        });
        const { ticket = {} } = coversationTicket || {};
        const { role = '', id } = this.user || {};
        const projectHasGroup = this.projectMaps[projectId] && this.projectMaps[projectId].hasGroup;
        if (
          [AGENT_ROLE.LEADER].includes(role) ||
          (projectHasGroup && [AGENT_ROLE.MODERATOR].includes(role))
        ) {
          if (
            [TICKET_STATE.START, TICKET_STATE.SUPPORTING].includes(ticket.state) &&
            ![id, ticket.id, TICKET_STATUS.FORWARD_GROUP].includes(ticket.assignee)
          ) {
            this.titleConfirmModal = this.$t(
              'src.modules.chat.components.ChatBox.chat-header.are_you_sure_you_want_to_take_over_the_conversation'
            );
            this.typeConfirmModal = 'validate-state-ticket';
            this.showModelconfirm = true;
          } else {
            this.supportThisUser();
          }
        } else {
          this.supportThisUser();
        }
        this.setGlobalReady(true);
      } catch (err) {
        this.setGlobalReady(true);
      }
    },

    async supportThisUser() {
      const { status } = this.ticket;
      this.setGlobalReady(false);
      await new Promise(resolve => setTimeout(() => resolve(true), 0));
      const groups = await this.getGroupsOpts(this.$props);
      this.agentGroups = groups.filter(i => i.isEnable);
      if ([TICKET_STATUS.BOT_HANDLE, TICKET_STATUS.USER_MENTION].includes(status)) {
        if (this.enableRequestUser || !this.agentGroups || this.agentGroups.length <= 1) {
          this.titleConfirmModal = this.$t(
            'src.modules.chat.components.AsideLeft.ConversationList.ConversationItem.index.are_you_sure_you_want_to_support_this_user'
          );
          this.typeConfirmModal = 'support-user';
          this.showModelconfirm = true;
          this.setSupportWithoutUserAgreement(this.enableRequestUser ? false : true);
        } else {
          this.showSupportTicket = true;
          this.setSupportWithoutUserAgreement(true);
        }
      } else {
        this.setSupportWithoutUserAgreement(false);
        if (!this.agentGroups || this.agentGroups.length === 0) {
          await this.supportTicket(this.conversation);
        } else this.showSupportTicket = true;
      }
      this.setGlobalReady(true);
    },

    handleCloseSupportTicket() {
      this.showSupportTicket = false;
    },

    async handleYes(type) {
      this.showModelconfirm = false;
      this.setReady(false);
      switch (type) {
        case 'remove-ticket':
          await this.updateAndEmitTicket({
            ticket: { ...this.ticket, deleted: true },
            ...this.ticket
          });
          this.setSelectedConversation({
            conversation: null,
            groupId: SERVING_STATE.MONITORING
          });
          this.setReady(true);
          break;

        case 'support-user':
          this.handleSupportTicket();
          break;

        case 're-support':
          this.handleSupportTicket();
          break;

        case 'no-support-user':
          await this.noSupportTicket({
            ticket: {
              ...this.ticket,
              state: TICKET_STATE.COMPLETE,
              status: TICKET_STATUS.NO_SUPPORT
            }
          });
          this.setReady(true);
          break;

        case 'validate-state-ticket':
          this.supportThisUser();
          break;

        default:
          break;
      }
    },

    async handleSupportWithoutUserAgreement() {
      await this.setSupportWithoutUserAgreement(true);
      this.showModelconfirm = false;
      this.handleSupportTicket();
    },

    async handleSupportTicket() {
      this.setReady(false);
      if (!this.agentGroups || this.agentGroups.length === 0) {
        await this.supportTicket(this.conversation);
      } else {
        this.showSupportTicket = true;
      }
      this.setReady(true);
    },

    async handleNo() {
      this.showModelconfirm = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-ticket-action {
  right: 10px !important;

  img {
    height: 25px;
    width: 25px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

/deep/.leftpanel-1 {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  padding: 0;
  color: #adb5bd;
  &:hover {
    color: #525f7f !important;
    box-shadow: none !important;
    background: transparent !important;
    border: none !important;
  }
  &:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  ~ .dropdown-menu {
    background: #fff;
    min-width: 10rem;

    .dropdown-item {
      font-size: 13.5px;
      padding: 0.5rem 1.4285rem;
    }
  }
}
</style>
