import * as actions from './actions';
import { SET_ACTION_LOGS } from './types';

const state = {
  actionLogsTicket: {}
};

const mutations = {
  [SET_ACTION_LOGS](state, { params, data }) {
    const { ticketId = '' } = params;
    const { actionLogsTicket = {} } = state;
    if (!ticketId) return;
    actionLogsTicket[ticketId] = data;

    state.actionLogsTicket = {
      ...actionLogsTicket
    };
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
