<template>
  <div class="filter-comment-btn w-98-p">
    <p></p>
    <div class="group-btn">
      <el-button
        type="primary"
        :disabled="disableAllBtn"
        @click="() => this.$emit('search')"
      >{{ translate.search }}</el-button>
      <el-button
        class="bd-cs"
        :disabled="!inFilterMode || disableAllBtn"
        @click="() => this.$emit('search')"
      >{{ translate.refresh }}</el-button>
      <el-button
        class="bd-cs mg-r-10"
        :disabled="!inFilterMode || disableAllBtn"
        @click="() => this.$emit('reset')"
      >{{ translate.reset }}</el-button>
      <el-link
        class="filter-advance-a"
        type="primary"
        @click="
          () => {
            const type =
              this.commentFilterType === translate.basic
                ? translate.advance
                : translate.basic;
            this.$emit('setFilterType', type);
          }
        "
      >{{ linkText }}</el-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { translate } from '../helpers';

export default {
  props: {
    disableAllBtn: {
      type: Boolean,
      default: false
    },
    commentFilterType: {
      type: String,
      default: translate.basic
    }
  },

  data() {
    return {
      translate
    };
  },

  computed: {
    ...mapState('comment', ['inFilterMode']),

    linkText() {
      return this.commentFilterType === translate.basic
        ? translate.advance_search
        : translate.basic_search;
    }
  }
};
</script>
