<template>
  <el-submenu :index="id">
    <template slot="title">
      <i class="el-icon-s-cooperation"></i>
      <span class="tr-project-name">{{ name }}</span>
      <span class="nat-project-id">{{ id }}</span>
    </template>
    <div class="channel-group">
      <c-channel
        v-for="c in channels"
        :key="`${tabIndex}-${c.id}`"
        v-bind="c"
        :tab-index="tabIndex"
        :total="(countMaps && countMaps[c.id]) || 0"
        :selected-conversation="selectedConversation"
        :keep-alive="keepAlive"
        :is-comment="isComment"
        :is-search="isSearch"
        :selected="
          keepAlive && keepAlive.tabIndex === tabIndex && keepAlive.channelId === c.id
        "
        @onSelectConversation="handleOnSelectConversation"
      />
    </div>
  </el-submenu>
</template>

<script>
import { mapState } from 'vuex';
import { TABS } from 'core/constants';
import CChannel from './Channel';
import { uuid } from 'vue-uuid';
export default {
  components: {
    CChannel
  },
  props: {
    isComment: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: String,
      default: () => uuid.v1()
    },
    name: {
      type: String,
      default: () => ''
    },
    tabIndex: {
      type: Number,
      default: () => TABS.ME
    },
    channels: {
      type: Array,
      default: () => []
    },
    collapse: {
      type: Boolean,
      default: () => true
    },
    selectedConversation: {
      type: Object,
      default: () => {}
    },
    keepAlive: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapState('session', [
      'commonWaitingCountsMap',
      'meWaitingCountsMap',
      'agentHandleCountsMap',
      'botHandleCountsMap',
      'monitoringCountsMap',
      'ticketCountBySearchChannelMap'
    ]),
    countMaps() {
      if (this.isSearch) {
        return this.ticketCountBySearchChannelMap;
      }
      switch (this.tabIndex) {
        case TABS.WAITING_COMMON:
          return this.commonWaitingCountsMap;
        case TABS.WAITING_ME:
          return this.meWaitingCountsMap;
        case TABS.ME:
          return this.agentHandleCountsMap;
        case TABS.BOT:
          return this.botHandleCountsMap;
        case TABS.MONITORING:
          return this.monitoringCountsMap;
        default:
          return {};
      }
    }
  },
  methods: {
    handleOnSelectConversation(conversation) {
      this.$emit('onSelectConversation', conversation);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-menu {
  width: 100% !important;
}

.nat-project-id {
  position: absolute;
  left: 52px;
  top: 16px;
  font-size: 10px;
  color: darkgray;
}

span.tr-project-name {
  display: inline-block;
}

.tr-project-name {
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
