<template>
  <GmapMap
    :center="position"
    :zoom="15"
    map-type-id="terrain"
    style="width: 400px; height: 300px"
  >
    <GmapMarker :position="position" :clickable="true" :draggable="true" />
  </GmapMap>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    position() {
      const { content } = this.message;
      if (!content) return {};

      try {
        const location = JSON.parse(content);
        return {
          lat: location.coordinates.lat,
          lng: location.coordinates.long
        };
      } catch (error) {
        // eslint-disable-next-line
        console.log("TCL: position -> error", error);
        return { lat: 0, lng: 0 };
      }
    }
  }
};
</script>
