var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['chat', _vm.direction]},[(!_vm.isSystem && _vm.localMessages.length > 0)?_c('div',{staticClass:"chat-avatar person_name_tooltip"},[_c('a',{staticClass:"avatar shadow",attrs:{"data-img":_vm.avatar,"data-toggle":"tooltip","data-placement":"right","title":"","data-original-title":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('img',{attrs:{"src":_vm.avatar,"alt":_vm.name},on:{"error":_vm.imageLoadError}})])]):_vm._e(),(!_vm.isSystem)?_c('div',{staticClass:"chat-body message-tooltip"},[(_vm.localMessages.length > 0)?_c('div',{staticClass:"chat-name"},[_vm._v(_vm._s(_vm.showName))]):_vm._e(),_vm._l((_vm.localMessages),function(msg,idx){return _c('div',{key:(idx + "_" + (msg.id))},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:['chat-content', 'shadow-sm', _vm.msgClass(msg)],attrs:{"id":'message_' + idx + "_" + (msg.id),"title":msg.isBroadcastMessage
            ? _vm.$t(
                'src.modules.chat.components.ChatBox.MessageList.message-item.tooltip_broadcast_message'
              )
            : ''}},[(
            !_vm.isPhoneAgent &&
              _vm.isAgent &&
              _vm.inChatBox &&
              msg.sender === _vm.me.id &&
              _vm.channelPlatform === 'webchat' &&
              _vm.conversation &&
              _vm.conversation.ticket &&
              _vm.ticketState.SUPPORTING === _vm.conversation.ticket.state
          )?_c('b-tooltip',{attrs:{"target":'message_' + idx + "_" + (msg.id),"container":'message_' + idx + "_" + (msg.id),"delay":100,"variant":"light","placement":"left","triggers":"hover"}},[(msg.content && msg.typeClass === 'audio' && _vm._isAudioUnPlayable(msg.content))?_c('i',{staticClass:"fas fa-download",style:({ cursor: 'pointer', paddingRight: '10px' }),on:{"click":function($event){return _vm._downloadFile(msg.content)}}}):_vm._e(),_c('i',{staticClass:"fas fa-trash",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.confirmDeleteMessage(msg)}}})]):(
            !_vm.isPhoneAgent &&
              !_vm.isAgent &&
              _vm.inChatBox &&
              _vm.conversation &&
              _vm.conversation.ticket &&
              _vm.ticketState.SUPPORTING === _vm.conversation.ticket.state &&
              msg.ticketId === _vm.conversation.ticket.id &&
              msg.typeClass === 'text'
          )?_c('b-tooltip',{attrs:{"target":'message_' + idx + "_" + (msg.id),"container":'message_' + idx + "_" + (msg.id),"delay":100,"variant":"light","placement":"right","triggers":"hover"}},[_c('i',{staticClass:"fas fa-edit",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.replacingMessageRequestForm(msg)}}})]):(
            !_vm.isPhoneAgent &&
              msg.content &&
              msg.typeClass === 'audio' &&
              _vm._isAudioUnPlayable(msg.content)
          )?_c('b-tooltip',{attrs:{"target":'message_' + idx + "_" + (msg.id),"container":'message_' + idx + "_" + (msg.id),"delay":100,"variant":"light","placement":_vm.isAgent ? 'left' : 'right',"triggers":"hover"}},[_c('i',{staticClass:"fas fa-download",style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm._downloadFile(msg.content)}}})]):_vm._e(),(msg.typeClass === 'follow')?_c('p',{staticClass:"text-style",domProps:{"innerHTML":_vm._s(_vm.escapeHtmlChar(msg.content || msg.text))}}):_vm._e(),(msg.typeClass === 'text' && (!msg.isUserURLOnly || !msg.isURLOnly))?_c('p',{staticClass:"text-style",domProps:{"innerHTML":_vm._s(
            _vm.urlify(
              _vm.formatMessage(
                _vm.escapeHtmlChar(msg.content || msg.text),
                msg.warnings,
                msg.ngs,
                msg.status
              )
            )
          )}}):_vm._e(),(
            (msg.typeClass === 'template_image_multiple' ||
              msg.typeClass === 'video' ||
              msg.typeClass === 'file' ||
              msg.typeClass === 'audio') &&
              msg.text
          )?_c('p',{staticClass:"text-style",domProps:{"innerHTML":_vm._s(
            _vm.urlify(_vm.formatMessage(_vm.escapeHtmlChar(msg.text), msg.warnings, msg.ngs, msg.status))
          )}}):_vm._e(),(msg.typeClass === 'template_image_multiple' && msg.text)?_c('div',{staticClass:"clearfix"}):_vm._e(),(msg.typeClass === 'fallback' && (!msg.isUserURLOnly || !msg.isURLOnly))?_c('p',{staticClass:"text-style",domProps:{"innerHTML":_vm._s(
            _vm.urlify(
              _vm.formatMessage(
                _vm.escapeHtmlChar(msg.content || msg.text),
                msg.warnings,
                msg.ngs,
                msg.status
              )
            )
          )}}):_vm._e(),(msg.typeClass === 'referral')?_c('p',[_vm._v(_vm._s(msg.text))]):_vm._e(),(msg.typeClass === 'postback')?_c('msg-postback',{attrs:{"message":msg,"selected-conversation":_vm.conversation}}):_vm._e(),(msg.typeClass === 'image' && msg.content && _vm._isImagesSupported(msg.content))?_c('div',{staticClass:"message-image"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],style:({ 'max-width': '300px' }),attrs:{"src":msg.content}})]):_vm._e(),(msg.typeClass === 'sticker')?_c('div',{staticClass:"message-image"},[_c('img',{directives:[{name:"img",rawName:"v-img"}],style:({ 'max-width': '300px' }),attrs:{"src":msg.content}})]):_vm._e(),(msg.typeClass === 'video')?_c('msg-video',{attrs:{"video-url":msg.content,"platform":msg.platform}}):_vm._e(),(msg.typeClass === 'audio')?_c('msg-audio',{attrs:{"audio-url":msg.content}}):_vm._e(),(
            msg.typeClass === 'quick_reply' && msg.content.type === 'USER_REQUEST_AGENT_CONFIRM'
          )?_c('msg-quickrep',{attrs:{"text":msg.text,"message":msg.content,"platform":msg.platform,"selected-conversation":_vm.conversation}}):_vm._e(),(
            msg.typeClass === 'quick_reply' && msg.content.type !== 'USER_REQUEST_AGENT_CONFIRM'
          )?_c('msg-quickrep',{attrs:{"text":msg.text,"message":msg.content,"platform":msg.platform,"selected-conversation":_vm.conversation}}):_vm._e(),(msg.typeClass === 'template_list')?_c('msg-list',{attrs:{"message":msg.content,"channel-platform":_vm.channelPlatform,"domain-webview":_vm.formDomain}}):_vm._e(),(msg.typeClass === 'template_imagemap')?_c('msg-image-map',{attrs:{"message":msg.content}}):_vm._e(),(msg.typeClass === 'template_image_multiple')?_c('msg-image-multiple',{attrs:{"message":msg.content}}):_vm._e(),(msg.typeClass === 'template_button')?_c('fb-template-button',{attrs:{"message":msg.content}}):_vm._e(),(msg.typeClass === 'template_generic')?_c('fb-template-product',{attrs:{"message":msg.content,"domain-webview":_vm.formDomain}}):_vm._e(),(['story_mention', 'story_reply'].includes(msg.typeClass))?_c('ins-template-story',{attrs:{"message":msg.content,"type":msg.typeClass}}):_vm._e(),(
            ['file'].includes(msg.typeClass) ||
              (msg.typeClass === 'image' &&
                msg.content &&
                typeof msg.content === 'string' &&
                !_vm._isImagesSupported(msg.content))
          )?_c('msg-file',{attrs:{"message":msg}}):_vm._e(),(msg.typeClass === 'location')?_c('msg-location',{attrs:{"message":msg}}):_vm._e(),(msg.typeClass === 'typing_text')?_c('msg-typing',{attrs:{"message":msg}}):_vm._e(),(
            typeof (msg.content || msg.text) === 'string' &&
              msg.type !== 'file' &&
              msg.type !== 'postback' &&
              msg.isUserURLOnly &&
              (msg.typeClass === 'text' || msg.typeClass === 'fallback')
          )?_c('div',{staticStyle:{"word-break":"break-word"}},[(msg.typeClass !== 'fallback')?_c('msg-link-preview',{attrs:{"message":msg.content || msg.text,"slice":2,"is-user-url-only":msg.isUserURLOnly,"is-agent":_vm.isAgent}}):_c('msg-link-preview',{attrs:{"message":msg.content || msg.text || '',"slice":2,"is-user-url-only":msg.isUserURLOnly,"is-agent":_vm.isAgent}})],1):_vm._e(),_c('p',{staticClass:"message-created-date"},[_vm._v(" "+_vm._s(msg.createdAtFormatted)+_vm._s(' ')+" "),(msg.isBroadcastMessage)?_c('i',{staticClass:"fa fa-podcast broadcast-message",attrs:{"aria-hidden":"true"}}):_vm._e(),(_vm.showReadMessage)?_c('i',{class:[
              'fas fa-check',
              msg.isRead && msg.status !== -1 && (!msg.ngs || msg.ngs.length === 0)
                ? 'read-message'
                : ''
            ]}):_vm._e()])],1),(
          typeof (msg.content || msg.text) === 'string' &&
            msg.type !== 'file' &&
            msg.type !== 'postback' &&
            !msg.isUserURLOnly
        )?_c('div',{class:['link-pre', _vm.directionLinkPreVue]},[(msg.typeClass !== 'fallback')?_c('msg-link-preview',{attrs:{"message":msg.content || msg.text,"slice":2,"is-user-url-only":msg.isUserURLOnly,"is-agent":_vm.isAgent}}):_c('msg-link-preview',{attrs:{"message":msg.content || msg.text || '',"slice":2,"is-user-url-only":msg.isUserURLOnly,"is-agent":_vm.isAgent}})],1):_vm._e()])})],2):_vm._e(),(_vm.isSystem)?_vm._l((_vm.messages),function(msg,idx){return _c('div',{key:(idx + "_" + (msg.id)),staticClass:"sys-msg"},[_c('hr'),_c('span',{staticClass:"msg-system badge badge-pill shadow"},[_vm._v(" "+_vm._s(_vm.$t(("src.modules.chat.components.ChatBox.MessageList.message-item." + (msg.text))))+" ")])])}):_vm._e(),_c('div',{staticClass:"clearfix"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }