<template>
  <el-table
    id="filterTable"
    ref="filterTable"
    :data="tableData"
    max-height="600"
    style="width: 100%"
  >
    <el-table-column
      type="index"
      :label="$t('src.modules.customer-support-history.index.no')"
      width="100px"
    ></el-table-column>
    <el-table-column
      :label="$t('src.modules.profile.components.user-project.project')"
      prop="project"
      width="450px"
    ></el-table-column>
    <el-table-column
      :filters="filterGroups"
      :filter-method="filtersGroup"
      :label="$t('src.modules.profile.components.user-project.groups')"
      prop="groups"
      width="400px"
      filter-placement="bottom-end"
    >
      <template slot-scope="scope">
        <el-tag v-for="group in scope.row.groups" :key="group.id" disable-transitions>
          <el-tooltip
            v-if="group.name.length > m_truncate(group.name)"
            :content="group.name"
            placement="top"
            class="item"
            effect="dark"
          >
            <span>{{ group.name | truncate(m_truncate(group.name)) }}</span>
          </el-tooltip>
          <span v-else>{{ group.name }}</span>
          <span 
            v-if="!group.isEnable"
            class="group-status-warning ml-1">
            <i
              v-b-tooltip.hover
              :title="$t('common.text.group_status')"
              class="el-icon-warning-outline"
            ></i>
          </span>
        </el-tag>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { hasSpecialCharacters } from 'core/helpers';

export default {
  name: 'user-projects',
  props: {
    assignedProjects: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    tableData() {
      const tableData = [];
      for (let i = 0; i < this.assignedProjects.length; i++) {
        const groupArr = [];
        if (this.assignedProjects[i].assignedGroups.length > 0) {
          this.assignedProjects[i].assignedGroups.forEach(assignedGroup => {
            if (!assignedGroup.deleted) {
              const group = {
                id: assignedGroup.id,
                name: assignedGroup.name,
                isEnable: assignedGroup.isEnable
              };
              groupArr.push(group);
            }
          });
        }
        groupArr.sort((group1, group2) => {
          return group1.name.localeCompare(group2.name, 'en', {
            sensitivity: 'case'
          });
        });
        const row = {
          no: i + 1,
          project: this.assignedProjects[i].name,
          groups: groupArr
        };
        tableData.push(row);
      }
      return tableData;
    },

    filterGroups() {
      let filterGroup = [
        {
          value: '-1',
          text: this.$t('src.modules.profile.components.user-project.no_groups')
        }
      ];
      for (let i = 0; i < this.assignedProjects.length; i++) {
        if (this.assignedProjects[i].assignedGroups.length > 0) {
          this.assignedProjects[i].assignedGroups.forEach(assignedGroup => {
            if (!assignedGroup.deleted) {
              const group = {
                value: assignedGroup.name,
                text: assignedGroup.name
              };
              filterGroup.push(group);
            }
          });
        }
      }

      filterGroup = uniqWith(filterGroup, isEqual);
      filterGroup = filterGroup.filter((group, index) => {
        const search = group.value;
        return filterGroup.findIndex(group => group.value === search) == index;
      });

      filterGroup.sort((group1, group2) => {
        return group1.text.localeCompare(group2.text, 'en', {
          sensitivity: 'case'
        });
      });
      return filterGroup;
    }
  },
  methods: {
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },

    filtersGroup(value, row) {
      if (value === '-1') {
        return row.groups.length == 0;
      }
      const groups = row.groups.filter(group => group.name == value);
      return groups.length > 0;
    },
    
    m_truncate(name) {
      if (hasSpecialCharacters(name)) {
        return 10;
      }
      return 25;
    }
  }
};
</script>
<style>
.el-tag {
  margin-right: 2px;
  margin-bottom: 2px;
}
</style>