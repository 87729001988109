<template>
  <li lass="nav-item">
    <a :class="activeClass" disabled>
      <span class="nav-link-icon d-block">
        <p class="item-label">{{ badgePrefix }} {{ textDisplay }}</p>
        <span
          :data-testid="`close-${badgeType}`"
          class="badge badge-pill badge-danger text-white bg-red"
          @click.prevent="$emit('onRemoveFilterBadge', badgeType)"
        >
          <i class="fa fa-times" />
        </span>
      </span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    badgeName: {
      type: String,
      default: ''
    },
    badgeType: {
      type: String,
      default: 'text'
    },
    badgeNameTranslate: {
      type: String,
      default: ''
    },
    onRemove: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    activeClass() {
      return this.active ? 'nav-link show active' : 'nav-link';
    },

    tabIndex() {
      return `tab_${this.index}`;
    },

    textDisplay() {
      return this.badgeNameTranslate ? this.badgeNameTranslate : this.badgeName;
    },

    badgePrefix() {
      let prefix = '';
      switch (this.badgeType) {
        case 'language':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.language'
          )}: `;
          break;
        case 'agent':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.agent'
          )}: `;
          break;
        case 'user':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.user'
          )}: `;
          break;
        case 'tag':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.label'
          )}: `;
          break;
        case 'state':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.state'
          )}: `;
          break;
        case 'status':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.status'
          )}: `;
          break;
        case 'follow':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.follow'
          )}: `;
          break;
        case 'platform':
          prefix = `${this.$t(
            'src.modules.chat.components.AsideLeft.SearchBox.filter-badge-item.platform'
          )}: `;
          break;
        case 'phoneNumber':
          prefix = `${this.$t(
            'src.modules.search-management.index.phonenumber'
          )}: `;
          break;
      }
      return prefix;
    }
  }
};
</script>

<style lang="scss" scoped>
.d-block {
  position: relative;
  .badge {
    position: absolute;
    top: -5px;
    right: -15px;
  }
}
.item-label {
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  margin-top: unset;
  margin-bottom: unset;
  word-break: break-all;
}
.app-message .page-aside .search-box a {
  padding: 3px 3px 2px 3px;
  margin: 5px;
  margin-top: 15px;
  padding-right: 15px;
  border: none;
  position: relative;
  text-transform: inherit;
  background-color: #f9f9fc;
  color: #172b4d;
}
.app-message .page-aside .search-box .badge {
  position: absolute;
  background: #f9f9fc;
  top: -6px;
  right: -15px;
  padding: 4px;
  color: #172b4d;
  border-radius: 50%;
}
.app-message .page-aside .search-box .badge i {
  font-size: 0.6rem;
  cursor: pointer;
}
</style>
