export const SET_PROJECTS_SETTING = 'SET_PROJECTS_SETTING';
export const SET_CUSTOMER_SUPPORT_HISTORY = 'SET_CUSTOMER_SUPPORT_HISTORY';
export const UPDATE_TREE_STORE = 'UPDATE_TREE_STORE';
export const SET_SEARCHTEXT = 'SET_SEARCHTEXT';
export const DELETE_CUSTOMER_SUPPORT_HISTORY = 'DELETE_CUSTOMER_SUPPORT_HISTORY';
export const UPDATE_CUSTOMER_SUPPORT_HISTORY = 'UPDATE_CUSTOMER_SUPPORT_HISTORY';
export const CHANNELS_FILTER_MAP = 'CHANNELS_FILTER_MAP';
export const GET_CUSTOMER_SUPPORT_REPORT = 'GET_CUSTOMER_SUPPORT_REPORT';
export const GET_CONVERSATION_REPORT = 'GET_CONVERSATION_REPORT';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const GET_AGENT_GROUPS = 'GET_AGENT_GROUPS';
export const UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA';
export const GET_ACTION_CLICK_CSH = 'GET_ACTION_CLICK_CSH';
