var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-box"},[_c('vue-element-loading',{attrs:{"active":_vm.isChatBoxLoading,"text-style":_vm.textStyle,"spiner":"line-scale"}}),_c('chat-header',_vm._b({attrs:{"is-being-supported":_vm.isBeingSupported,"show-support":_vm.showSupport,"show-waiting-support-form":_vm.showWaitingSupportForm,"show-support-panel":_vm.showSupportPanel,"is-submit-supported":_vm.isSubmitSupported,"show-status-conversation":_vm.showStatusConversation,"is-view":_vm.isView,"conversation":_vm.conversation,"is-header-close":_vm.isHeaderClose},on:{"onSupportItemClick":_vm.handleSupportItemClick,"handleOnSelectedConversation":_vm.handleOnSelectedConversation,"heightOfChatHeader":_vm.detectHeightOfChatHeader,"onToggleHeaderClose":_vm.toggleHeaderClose}},'chat-header',_vm.userInfo,false)),(_vm.showMessageBox)?_c('div',{ref:"appMsgChat",class:['app-message-chats'],style:({
      height: ("" + (_vm.nonChatInputClass
          ? ("-webkit-calc(100% - " + _vm.heightChatHeader + "px - 30px)")
          : ("-webkit-calc(100% - " + _vm.heightChatHeader + "px - 121px - 30px - " + (_vm.messageInputHeight - _vm.initialMessageInputHeight) + "px - " + _vm.proofReadingHeight + "px - 60*" + _vm.countFileUploading + "px)"))),
      height: ("" + (_vm.nonChatInputClass
          ? ("calc(100% - " + _vm.heightChatHeader + "px - 30px)")
          : ("calc(100% - " + _vm.heightChatHeader + "px - 121px - 30px - " + (_vm.messageInputHeight - _vm.initialMessageInputHeight) + "px - " + _vm.proofReadingHeight + "px - 60*" + _vm.countFileUploading + "px)"))),
      marginTop: ("calc(" + _vm.heightChatHeader + "px + 15px)"),
      visibility: _vm.visibility
    }),attrs:{"id":"appMsgChat"},on:{"scroll":_vm.handleScroll}},[_c('drop',{staticClass:"drop position-relative",on:{"drop":_vm.handleDrop}},[_c('message-list',{attrs:{"messages":_vm.messageDataSort,"loading":_vm.loading,"platform":_vm.platform,"in-chat-box":true}})],1)],1):_vm._e(),_c('div',{staticClass:"app-message-upload"},_vm._l((_vm.files),function(file,idx){return _c('file-upload',{key:idx,attrs:{"file":file.file,"path":(_vm.conversation && 'c/' + _vm.conversation.id) || '',"platform":_vm.platform,"group":file.group,"channel":_vm.channel},on:{"onSuccess":_vm.handleOnSuccess}})}),1),(_vm.showMessageInput)?_c('message-input',{key:_vm.conversation && _vm.conversation.ticketId,attrs:{"products":_vm.selectedProducts,"project-id":_vm.projectId,"show-support":_vm.showSupport,"show-waiting-support-form":_vm.showWaitingSupportForm,"is-being-supported":_vm.isBeingSupported,"is-submit-supported":_vm.isSubmitSupported,"show-support-panel":_vm.showSupportPanel},on:{"productChange":_vm.handleProductChange,"onSend":_vm.handleOnPressSendMessage,"onFileUpload":_vm.handleOnFileUpload,"onLoadingFileUpload":_vm.handleLoadingFileUpload,"onSupportItemClick":_vm.handleSupportItemClick,"onProductItemClick":function($event){return _vm.$emit('onProductItemClick')},"onConversationalItemClick":function($event){return _vm.$emit('onConversationalItemClick')},"resizeChatBox":_vm.resizeChatBox}}):_vm._e(),_c('close-conversation'),_c('state-overview',{attrs:{"conversation":_vm.conversation}}),_c('manage-labels',{attrs:{"conversation":_vm.conversation}}),_c('manage-user-labels',{attrs:{"conversation":_vm.conversation}}),_c('scenario-template-Box'),_c('f-a-q-template-box')],1)}
var staticRenderFns = []

export { render, staticRenderFns }