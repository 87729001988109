import * as actions from './actions';
import {
  CREATE_CUSTOMER_SUPPORT,
  UPDATE_CUSTOMER_SUPPORT,
  SET_CUSTOMER_SUPPORTS,
  SET_SUPPORTFORM_SEARCHTEXT,
  SET_CUSTOMER_SUPPORT,
  DELETE_CUSTOMER_SUPPORT,
  SET_CUSTOMER_SUPPORT_SETTING,
  UPDATE_CUSTOMER_SUPPORT_TREE_STORE,
  SET_CUSTOMER_SUPPORT_PANEL_SETTING,
  CLEAR_TREE_STORE,
  SET_CSF_MODE,
  SET_COUNTRIES,
  SET_PROVINCES,
  SET_TEMPLATE_CONTENT
} from './types';
import * as getters from './getters';
import remove from 'lodash/remove';

const state = {
  customerSupportData: [],
  searchTextSupport: '',
  projects: {},
  categories: {},
  customerFields: {},
  fixedFields: {},
  template: {},
  treeStore: [],
  categoryLabel: '',
  openCSFMode: 'edit',
  countries: [],
  provinces: []
};

const mutations = {
  [SET_CSF_MODE](state, mode) {
    state.openCSFMode = mode;
  },
  [CLEAR_TREE_STORE]() {
    state.treeStore = [];
  },
  [UPDATE_CUSTOMER_SUPPORT_TREE_STORE](state, newTree) {
    state.treeStore = newTree;
  },
  [SET_CUSTOMER_SUPPORT_SETTING](state, { projects, categories, customerFields }) {
    state.projects = { ...projects };
    state.categories = { ...categories };
    state.customerFields = { ...customerFields };
  },
  [CREATE_CUSTOMER_SUPPORT](state, customerData) {
    state.customerSupportData.push(customerData);
  },
  [UPDATE_CUSTOMER_SUPPORT](state, customerData) {
    let { customerSupportData } = state;
    const foundIndex = customerSupportData.findIndex(
      element => element.id === customerData.id
    );
    customerSupportData[foundIndex] = {
      ...customerSupportData[foundIndex],
      ...customerData
    };
    state.customerSupportData = [...customerSupportData];
  },
  [SET_CUSTOMER_SUPPORTS](state, customerData) {
    state.customerSupportData = [...customerData];
  },
  [SET_SUPPORTFORM_SEARCHTEXT](state, text) {
    state.searchTextSupport = text;
  },
  [SET_CUSTOMER_SUPPORT]() {},
  [SET_TEMPLATE_CONTENT](
    state,
    { categories, categoryLabel, customerFields, fixedFields, template }
  ) {
    state.categories = categories;
    state.categoryLabel = categoryLabel;
    state.customerFields = customerFields;
    state.fixedFields = fixedFields;
    state.template = template;
  },
  [DELETE_CUSTOMER_SUPPORT](state, id) {
    const temp = state.customerSupportData;
    var rtl = remove(temp, function(t) {
      return t.id !== id;
    });
    state.customerSupportData = [...rtl];
  },
  [SET_CUSTOMER_SUPPORT_PANEL_SETTING](
    state,
    { categories, categoryLabel, customerFields, fixedFields, template }
  ) {
    state.categories = categories;
    state.categoryLabel = categoryLabel;
    state.customerFields = customerFields;
    state.fixedFields = fixedFields;
    state.template = template;
  },
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_PROVINCES](state, provinces) {
    state.provinces = provinces;
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
