import axios from 'axios';
import cSecurity from './cSecurity';

/**
 * fetchAPI Class => Custom Execute API
 * auto recall accessToken when expired error
 */
class fetchAPI {
  baseURL = '';
  url = '';
  method = '';
  data = {};
  params = {};
  headers = {};
  option = {};
  _axios;

  constructor({ baseURL = '', headers = {}, _options = {} }) {
    const options = {
      baseURL: baseURL,
      headers: headers,
      ..._options
    };
    this.baseURL = baseURL;
    this.headers = headers;
    this.option = options;
    this._axios = axios.create(options);
  }

  getAxios() {
    return this._axios;
  }

  fetch = async (authorization = 0) => {
    const url = this.baseURL + this.url;
    const method = this.method;
    const data = this.data;
    const params = this.params;
    const headers = this.headers;
    const option = this.option;

    if (url && method) {
      const config = {
        method,
        url,
        params,
        data,
        headers,
        timeout: 30000
      };
      const obj = { ...config, ...option };
      const result = await this._axios(obj).catch(err => {
        const response = err.response;
        let mes = '';
        if (response) {
          mes = response.data.message || response.statusText;
        } else {
          mes = err.message;
        }
        if (!authorization) {
          throw new Error(mes);
        }
        return { error: true, message: mes, responseError: err };
      });
      if (!authorization) {
        return result;
      }
      return result;
    }

    return false;
  };

  /**
   * Execute custom API
   * @param {string} url
   * @param {string} method POST or GET
   * @param {Object} option
   * @param {boolean} authorization
   */
  run = async (url, method, option = {}, authorization = 0, recall = false) => {
    //defined variables and method
    this.url = url;
    this.method = method;
    this.data = option.data || {};
    this.params = option.params || {};
    this.option = option.option || {};
    const headers = { ...this.headers, ...option.headers } || {};

    // not use authorization => return api result
    if (!authorization) return await this.fetch();

    // use authorization => add cplus-token to headers
    const security = new cSecurity();
    if (authorization) {
      let token = await security.getToken();
      // Check expired token
      const isExpired = await security.isExpiredToken();
      if (isExpired) {
        token = await security.getToken(1);
      }

      if (this.url.includes(process.env.VUE_APP_SERVICE_ENDPOINT)) {
        headers['Authorization'] = `Token ${localStorage.getItem('user-token')}`;
      }

      if (!token) {
        throw new Error('Could not found cplusToken');
      }

      headers['cplus-authorization'] = `Bearer ${token}`;
    }
    this.headers = headers;

    // use authorization => Validate result api
    const result = await this.fetch(authorization);

    if (result && result.error === true) {
      //if error is TokenExpiredError
      if (result.message === 'jwt expired') {
        //Get new accessToken and recall api
        let token = await security.getToken(1);
        if (this.url.includes(process.env.VUE_APP_SERVICE_ENDPOINT)) {
          headers['Authorization'] = `Token ${localStorage.getItem('user-token')}`;
        }
        headers['cplus-authorization'] = `Bearer ${token}`;
        this.headers = headers;
        if (recall) throw result.responseError;
        return await this.run(url, method, option, authorization, true);
      } else {
        throw result.responseError;
      }
    }
    return result;
  };

  /**
   * Execute POST API (Always check authorization)
   * @param {string} url
   * @param {Object} option
   * @param {boolean} authorization
   */
  post = async (url, data = {}, option = {}, authorization = 1) => {
    return await this.run(url, 'POST', { data, option }, authorization).catch(err => {
      throw err;
    });
  };

  /**
   * Execute GET API (Always check authorization)
   * @param {string} url
   * @param {Object} option
   * @param {boolean} authorization
   */
  get = async (url, params = {}, option = {}, authorization = 1) => {
    return await this.run(url, 'GET', { params, option }, authorization);
  };
}

export default fetchAPI;
